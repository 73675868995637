import { AdminPanelAccessMap } from '@utils/accessMap';

type AdminSectionRoute = {
    key: string;
    path: string;
};

export type AdminPageRoute = AdminSectionRoute & {
    hasMultipleTabs: boolean;
};

enum ADMIN_SECTIONS {
    ADMINISTRATION = 'administration',
    RM = 'riskManagement',
    REPORTS = 'reports',
    MONITORING = 'monitoring',
    SETTINGS = 'settings',
    HLB = 'hlb',
}

export const adminSections: Record<ADMIN_SECTIONS, AdminSectionRoute> = {
    [ADMIN_SECTIONS.ADMINISTRATION]: {
        key: 'admin',
        path: '/administration',
    },
    [ADMIN_SECTIONS.RM]: {
        key: 'riskManagement',
        path: '/risk-management',
    },
    [ADMIN_SECTIONS.REPORTS]: {
        key: 'reports',
        path: '/reports',
    },
    [ADMIN_SECTIONS.MONITORING]: {
        key: 'monitoring',
        path: '/monitoring',
    },
    [ADMIN_SECTIONS.HLB]: {
        key: 'hlb',
        path: '/hlb',
    },
    [ADMIN_SECTIONS.SETTINGS]: {
        key: 'settings',
        path: '/settings',
    },
};

export const adminPages: Record<keyof AdminPanelAccessMap, AdminPageRoute> = {
    system: {
        key: 'system',
        path: `${adminSections.administration.path}/system`,
        hasMultipleTabs: true,
    },
    modules: {
        key: 'modules',
        path: `${adminSections.administration.path}/modules`,
        hasMultipleTabs: false,
    },
    platforms: {
        key: 'platforms',
        path: `${adminSections.administration.path}/platforms`,
        hasMultipleTabs: false,
    },
    currencies: {
        key: 'currencies',
        path: `${adminSections.administration.path}/currencies`,
        hasMultipleTabs: true,
    },
    symbols: {
        key: 'symbols',
        path: `${adminSections.administration.path}/symbols`,
        hasMultipleTabs: true,
    },
    accounts: {
        key: 'accounts',
        path: `${adminSections.administration.path}/accounts`,
        hasMultipleTabs: true,
    },
    managers: {
        key: 'managers',
        path: `${adminSections.administration.path}/managers`,
        hasMultipleTabs: true,
    },
    calendars: {
        key: 'calendars',
        path: `${adminSections.administration.path}/calendars`,
        hasMultipleTabs: true,
    },
    profiles: {
        key: 'profiles',
        path: `${adminSections.administration.path}/profiles`,
        hasMultipleTabs: true,
    },
    dataFeeds: {
        key: 'dataFeeds',
        path: `${adminSections.administration.path}/data-feeds`,
        hasMultipleTabs: false,
    },
    tradingRoutes: {
        key: 'tradingRoutes',
        path: `${adminSections.administration.path}/trading-routes`,
        hasMultipleTabs: false,
    },
    gateways: {
        key: 'gateways',
        path: `${adminSections.administration.path}/gateways`,
        hasMultipleTabs: false,
    },
    quoteHistory: {
        key: 'quoteHistory',
        path: `${adminSections.administration.path}/quote-history`,
        hasMultipleTabs: true,
    },
    sessions: {
        key: 'sessions',
        path: `${adminSections.administration.path}/sessions`,
        hasMultipleTabs: false,
    },
    market: {
        key: 'market',
        path: `${adminSections.monitoring.path}/market`,
        hasMultipleTabs: false,
    },
    journal: {
        key: 'journal',
        path: `${adminSections.monitoring.path}/journal`,
        hasMultipleTabs: false,
    },
    businessJournal: {
        key: 'businessJournal',
        path: `${adminSections.monitoring.path}/business-journal`,
        hasMultipleTabs: false,
    },
    balanceTransactions: {
        key: 'balanceTransactions',
        path: `${adminSections.reports.path}/balance-transactions`,
        hasMultipleTabs: false,
    },
    statements: {
        key: 'statements',
        path: `${adminSections.reports.path}/client-reports`,
        hasMultipleTabs: false,
    },
    modulesWatch: {
        key: 'modulesWatch',
        path: `${adminSections.monitoring.path}/modules-watch`,
        hasMultipleTabs: false,
    },
    riskManagement: {
        key: 'riskManagement',
        path: adminSections.riskManagement.path,
        hasMultipleTabs: true,
    },
    mail: {
        key: 'mail',
        path: `${adminSections.settings.path}/mail`,
        hasMultipleTabs: false,
    },
    leverageProfiles: {
        key: 'leverageProfiles',
        path: `${adminSections.hlb.path}/leverage-profiles`,
        hasMultipleTabs: false,
    },
    hlbProfiles: {
        key: 'hlbProfiles',
        path: `${adminSections.hlb.path}/profiles`,
        hasMultipleTabs: true,
    },
    emailTemplates: {
        key: 'emailTemplates',
        path: `${adminSections.settings.path}/email-templates`,
        hasMultipleTabs: false,
    },
    security: {
        key: 'security',
        path: `${adminSections.settings.path}/security`,
        hasMultipleTabs: true,
    }
};
