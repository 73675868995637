
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * NewsProfileImportSettings struct
 */
class NewsProfileImportSettings {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!Date=} dateTime
   * @param {!number=} beforeSeconds
   * @param {!number=} afterSeconds
   * @param {!string=} marginRateProfileName
   * @constructor
   */
  constructor (name = '', dateTime = new Date(0), beforeSeconds = new UInt64(0, 0), afterSeconds = new UInt64(0, 0), marginRateProfileName = '') {
    this.Name = name
    this.DateTime = dateTime
    this.BeforeSeconds = beforeSeconds
    this.AfterSeconds = afterSeconds
    this.MarginRateProfileName = marginRateProfileName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!NewsProfileImportSettings}
   * @param {!NewsProfileImportSettings} other Other struct
   * @returns {!NewsProfileImportSettings} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.DateTime != null) {
      if (other.DateTime instanceof Date) {
        this.DateTime = new Date(other.DateTime.getTime())
      } else {
        this.DateTime = new Date(Math.round(other.DateTime / 1000000))
      }
    } else {
      this.DateTime = null
    }
    if (other.BeforeSeconds != null) {
      this.BeforeSeconds = UInt64.fromNumber(other.BeforeSeconds)
    } else {
      this.BeforeSeconds = null
    }
    if (other.AfterSeconds != null) {
      this.AfterSeconds = UInt64.fromNumber(other.AfterSeconds)
    } else {
      this.AfterSeconds = null
    }
    if (other.MarginRateProfileName != null) {
      this.MarginRateProfileName = other.MarginRateProfileName
    } else {
      this.MarginRateProfileName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!NewsProfileImportSettings}
   * @returns {!NewsProfileImportSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new NewsProfileImportSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new NewsProfileImportSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!NewsProfileImportSettings}
   * @param {!NewsProfileImportSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof NewsProfileImportSettings)) {
      throw new TypeError('Instance of NewsProfileImportSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!NewsProfileImportSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      DateTime: ((this.DateTime != null) ? (this.DateTime.getTime() * 1000000) : null),
      BeforeSeconds: ((this.BeforeSeconds != null) ? this.BeforeSeconds.toNumber() : null),
      AfterSeconds: ((this.AfterSeconds != null) ? this.AfterSeconds.toNumber() : null),
      MarginRateProfileName: ((this.MarginRateProfileName != null) ? this.MarginRateProfileName : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return NewsProfileImportSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!NewsProfileImportSettings} other Object value
   * @returns {!NewsProfileImportSettings} Created struct
   */
  static fromObject (other) {
    return new NewsProfileImportSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!NewsProfileImportSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return NewsProfileImportSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!NewsProfileImportSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 188
  }
}

export { NewsProfileImportSettings };

/**
 * Fast Binary Encoding NewsProfileImportSettings field model
 */
class FieldModelNewsProfileImportSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.DateTime = new fbe.FieldModelTimestamp(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.BeforeSeconds = new fbe.FieldModelUInt64(buffer, this.DateTime.fbeOffset + this.DateTime.fbeSize)
    this.AfterSeconds = new fbe.FieldModelUInt64(buffer, this.BeforeSeconds.fbeOffset + this.BeforeSeconds.fbeSize)
    this.MarginRateProfileName = new fbe.FieldModelString(buffer, this.AfterSeconds.fbeOffset + this.AfterSeconds.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.DateTime.fbeSize + this.BeforeSeconds.fbeSize + this.AfterSeconds.fbeSize + this.MarginRateProfileName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.DateTime.fbeExtra + this.BeforeSeconds.fbeExtra + this.AfterSeconds.fbeExtra + this.MarginRateProfileName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelNewsProfileImportSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 188
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.DateTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DateTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DateTime.fbeSize

    if ((fbeCurrentSize + this.BeforeSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BeforeSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BeforeSeconds.fbeSize

    if ((fbeCurrentSize + this.AfterSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AfterSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AfterSeconds.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginRateProfileName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!NewsProfileImportSettings} fbeValue Default value, defaults is new NewsProfileImportSettings()
   * @returns {!NewsProfileImportSettings} NewsProfileImportSettings value
   */
  get (fbeValue = new NewsProfileImportSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!NewsProfileImportSettings} fbeValue NewsProfileImportSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.DateTime.fbeSize) <= fbeStructSize) {
      fbeValue.DateTime = this.DateTime.get()
    } else {
      fbeValue.DateTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DateTime.fbeSize

    if ((fbeCurrentSize + this.BeforeSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.BeforeSeconds = this.BeforeSeconds.get()
    } else {
      fbeValue.BeforeSeconds = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BeforeSeconds.fbeSize

    if ((fbeCurrentSize + this.AfterSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.AfterSeconds = this.AfterSeconds.get()
    } else {
      fbeValue.AfterSeconds = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AfterSeconds.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileName.fbeSize) <= fbeStructSize) {
      fbeValue.MarginRateProfileName = this.MarginRateProfileName.get()
    } else {
      fbeValue.MarginRateProfileName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelNewsProfileImportSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {!NewsProfileImportSettings} fbeValue NewsProfileImportSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelNewsProfileImportSettings}
   * @param {NewsProfileImportSettings} fbeValue NewsProfileImportSettings value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.DateTime.set(fbeValue.DateTime)
    this.BeforeSeconds.set(fbeValue.BeforeSeconds)
    this.AfterSeconds.set(fbeValue.AfterSeconds)
    this.MarginRateProfileName.set(fbeValue.MarginRateProfileName)
  }
}

export { FieldModelNewsProfileImportSettings };

/**
 * Fast Binary Encoding NewsProfileImportSettings model
 */
class NewsProfileImportSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelNewsProfileImportSettings(this.buffer, 4)
  }

  /**
   * Get the NewsProfileImportSettings model
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!FieldModelNewsProfileImportSettings} model NewsProfileImportSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return NewsProfileImportSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelNewsProfileImportSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!NewsProfileImportSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!NewsProfileImportSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!NewsProfileImportSettingsModel}
   * @param {!NewsProfileImportSettings} value NewsProfileImportSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!NewsProfileImportSettingsModel}
   * @param {!NewsProfileImportSettings} value NewsProfileImportSettings value, defaults is new NewsProfileImportSettings()
   * @return {!object} Deserialized NewsProfileImportSettings value and its size
   */
  deserialize (value = new NewsProfileImportSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new NewsProfileImportSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new NewsProfileImportSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!NewsProfileImportSettingsModel}
   * @param {!number} prev Previous NewsProfileImportSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { NewsProfileImportSettingsModel };
