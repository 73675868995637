
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {OrderFiltration} from '../core/OrderFiltration';
import {FieldModelOrderFiltration} from '../core/OrderFiltration';
import {OrderStateFiltration} from '../core/OrderStateFiltration';
import {FieldModelOrderStateFiltration} from '../core/OrderStateFiltration';
import {AccountFiltration} from '../core/AccountFiltration';
import {FieldModelAccountFiltration} from '../core/AccountFiltration';
import {SymbolFiltration} from '../core/SymbolFiltration';
import {FieldModelSymbolFiltration} from '../core/SymbolFiltration';
import {CurrencyFiltration} from '../core/CurrencyFiltration';
import {FieldModelCurrencyFiltration} from '../core/CurrencyFiltration';

/**
 * OrdersFiltration struct
 */
class OrdersFiltration {
  /**
   * Initialize struct
   * @param {OrderFiltration=} orderFiltration
   * @param {OrderStateFiltration=} orderStateFiltration
   * @param {AccountFiltration=} accountFiltration
   * @param {SymbolFiltration=} symbolFiltration
   * @param {CurrencyFiltration=} marginCurrencyFiltration
   * @constructor
   */
  constructor (orderFiltration = null, orderStateFiltration = null, accountFiltration = null, symbolFiltration = null, marginCurrencyFiltration = null) {
    this.OrderFiltration = orderFiltration
    this.OrderStateFiltration = orderStateFiltration
    this.AccountFiltration = accountFiltration
    this.SymbolFiltration = symbolFiltration
    this.MarginCurrencyFiltration = marginCurrencyFiltration
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrdersFiltration}
   * @param {!OrdersFiltration} other Other struct
   * @returns {!OrdersFiltration} This struct
   */
  copy (other) {
    if (other.OrderFiltration != null) {
      this.OrderFiltration = OrderFiltration.fromObject(other.OrderFiltration)
    } else {
      this.OrderFiltration = null
    }
    if (other.OrderStateFiltration != null) {
      this.OrderStateFiltration = OrderStateFiltration.fromObject(other.OrderStateFiltration)
    } else {
      this.OrderStateFiltration = null
    }
    if (other.AccountFiltration != null) {
      this.AccountFiltration = AccountFiltration.fromObject(other.AccountFiltration)
    } else {
      this.AccountFiltration = null
    }
    if (other.SymbolFiltration != null) {
      this.SymbolFiltration = SymbolFiltration.fromObject(other.SymbolFiltration)
    } else {
      this.SymbolFiltration = null
    }
    if (other.MarginCurrencyFiltration != null) {
      this.MarginCurrencyFiltration = CurrencyFiltration.fromObject(other.MarginCurrencyFiltration)
    } else {
      this.MarginCurrencyFiltration = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrdersFiltration}
   * @returns {!OrdersFiltration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrdersFiltrationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrdersFiltrationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrdersFiltration}
   * @param {!OrdersFiltration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrdersFiltration)) {
      throw new TypeError('Instance of OrdersFiltration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrdersFiltration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      OrderFiltration: ((this.OrderFiltration != null) ? this.OrderFiltration : null),
      OrderStateFiltration: ((this.OrderStateFiltration != null) ? this.OrderStateFiltration : null),
      AccountFiltration: ((this.AccountFiltration != null) ? this.AccountFiltration : null),
      SymbolFiltration: ((this.SymbolFiltration != null) ? this.SymbolFiltration : null),
      MarginCurrencyFiltration: ((this.MarginCurrencyFiltration != null) ? this.MarginCurrencyFiltration : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrdersFiltration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrdersFiltration} other Object value
   * @returns {!OrdersFiltration} Created struct
   */
  static fromObject (other) {
    return new OrdersFiltration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrdersFiltration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrdersFiltration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrdersFiltration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 172
  }
}

export { OrdersFiltration };

/**
 * Fast Binary Encoding OrdersFiltration field model
 */
class FieldModelOrdersFiltration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.OrderFiltration = new fbe.FieldModelOptional(new FieldModelOrderFiltration(buffer, 4 + 4), buffer, 4 + 4)
    this.OrderStateFiltration = new fbe.FieldModelOptional(new FieldModelOrderStateFiltration(buffer, this.OrderFiltration.fbeOffset + this.OrderFiltration.fbeSize), buffer, this.OrderFiltration.fbeOffset + this.OrderFiltration.fbeSize)
    this.AccountFiltration = new fbe.FieldModelOptional(new FieldModelAccountFiltration(buffer, this.OrderStateFiltration.fbeOffset + this.OrderStateFiltration.fbeSize), buffer, this.OrderStateFiltration.fbeOffset + this.OrderStateFiltration.fbeSize)
    this.SymbolFiltration = new fbe.FieldModelOptional(new FieldModelSymbolFiltration(buffer, this.AccountFiltration.fbeOffset + this.AccountFiltration.fbeSize), buffer, this.AccountFiltration.fbeOffset + this.AccountFiltration.fbeSize)
    this.MarginCurrencyFiltration = new fbe.FieldModelOptional(new FieldModelCurrencyFiltration(buffer, this.SymbolFiltration.fbeOffset + this.SymbolFiltration.fbeSize), buffer, this.SymbolFiltration.fbeOffset + this.SymbolFiltration.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.OrderFiltration.fbeSize + this.OrderStateFiltration.fbeSize + this.AccountFiltration.fbeSize + this.SymbolFiltration.fbeSize + this.MarginCurrencyFiltration.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.OrderFiltration.fbeExtra + this.OrderStateFiltration.fbeExtra + this.AccountFiltration.fbeExtra + this.SymbolFiltration.fbeExtra + this.MarginCurrencyFiltration.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrdersFiltration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 172
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrdersFiltration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrdersFiltration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderFiltration.fbeSize

    if ((fbeCurrentSize + this.OrderStateFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStateFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStateFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountFiltration.fbeSize

    if ((fbeCurrentSize + this.SymbolFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolFiltration.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyFiltration.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrdersFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrdersFiltration}
   * @param {!OrdersFiltration} fbeValue Default value, defaults is new OrdersFiltration()
   * @returns {!OrdersFiltration} OrdersFiltration value
   */
  get (fbeValue = new OrdersFiltration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrdersFiltration}
   * @param {!OrdersFiltration} fbeValue OrdersFiltration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.OrderFiltration = this.OrderFiltration.get()
    } else {
      fbeValue.OrderFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderFiltration.fbeSize

    if ((fbeCurrentSize + this.OrderStateFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStateFiltration = this.OrderStateFiltration.get()
    } else {
      fbeValue.OrderStateFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStateFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.AccountFiltration = this.AccountFiltration.get()
    } else {
      fbeValue.AccountFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountFiltration.fbeSize

    if ((fbeCurrentSize + this.SymbolFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolFiltration = this.SymbolFiltration.get()
    } else {
      fbeValue.SymbolFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolFiltration.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyFiltration = this.MarginCurrencyFiltration.get()
    } else {
      fbeValue.MarginCurrencyFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyFiltration.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrdersFiltration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrdersFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrdersFiltration}
   * @param {!OrdersFiltration} fbeValue OrdersFiltration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrdersFiltration}
   * @param {OrdersFiltration} fbeValue OrdersFiltration value
   */
  setFields (fbeValue) {
    this.OrderFiltration.set(fbeValue.OrderFiltration)
    this.OrderStateFiltration.set(fbeValue.OrderStateFiltration)
    this.AccountFiltration.set(fbeValue.AccountFiltration)
    this.SymbolFiltration.set(fbeValue.SymbolFiltration)
    this.MarginCurrencyFiltration.set(fbeValue.MarginCurrencyFiltration)
  }
}

export { FieldModelOrdersFiltration };

/**
 * Fast Binary Encoding OrdersFiltration model
 */
class OrdersFiltrationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrdersFiltration(this.buffer, 4)
  }

  /**
   * Get the OrdersFiltration model
   * @this {!OrdersFiltrationModel}
   * @returns {!FieldModelOrdersFiltration} model OrdersFiltration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrdersFiltrationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrdersFiltrationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrdersFiltrationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrdersFiltrationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrdersFiltration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrdersFiltrationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrdersFiltrationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrdersFiltrationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrdersFiltrationModel}
   * @param {!OrdersFiltration} value OrdersFiltration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrdersFiltrationModel}
   * @param {!OrdersFiltration} value OrdersFiltration value, defaults is new OrdersFiltration()
   * @return {!object} Deserialized OrdersFiltration value and its size
   */
  deserialize (value = new OrdersFiltration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrdersFiltration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrdersFiltration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrdersFiltrationModel}
   * @param {!number} prev Previous OrdersFiltration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrdersFiltrationModel };
