
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {MarginCalculationType} from '../core/MarginCalculationType';
import {FieldModelMarginCalculationType} from '../core/MarginCalculationType';
import {ProfitCalculationType} from '../core/ProfitCalculationType';
import {FieldModelProfitCalculationType} from '../core/ProfitCalculationType';
import {SwapSymbolSettings} from '../core/SwapSymbolSettings';
import {FieldModelSwapSymbolSettings} from '../core/SwapSymbolSettings';
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';

/**
 * SymbolInfo struct
 */
class SymbolInfo {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!string=} description
   * @param {!number=} precision
   * @param {string=} marginCurrency
   * @param {number=} marginCurrencyPrecision
   * @param {!MarginCalculationType=} marginCalculationType
   * @param {string=} profitCurrency
   * @param {number=} profitCurrencyPrecision
   * @param {!ProfitCalculationType=} profitCalculationType
   * @param {!number=} color
   * @param {!boolean=} isTradeAllowed
   * @param {!number=} contractSize
   * @param {!number=} minTradeAmount
   * @param {!number=} maxTradeAmount
   * @param {!number=} tradeAmountStep
   * @param {!number=} defaultExpiration
   * @param {!boolean=} isSwapEnabled
   * @param {SwapSymbolSettings=} swapSettings
   * @param {!TradingMode=} tradingMode
   * @param {!string=} symbolGroupName
   * @param {!number=} stopsLevel
   * @param {!boolean=} isFavorite
   * @param {number=} sortOrderFavorite
   * @param {!number=} initialMargin
   * @param {!number=} hedgedMargin
   * @constructor
   */
  constructor (name = '', description = '', precision = 0, marginCurrency = null, marginCurrencyPrecision = null, marginCalculationType = new MarginCalculationType(), profitCurrency = null, profitCurrencyPrecision = null, profitCalculationType = new ProfitCalculationType(), color = 0, isTradeAllowed = false, contractSize = 0.0, minTradeAmount = 0.0, maxTradeAmount = 0.0, tradeAmountStep = 0.0, defaultExpiration = new UInt64(0, 0), isSwapEnabled = false, swapSettings = null, tradingMode = new TradingMode(), symbolGroupName = '', stopsLevel = new UInt64(0, 0), isFavorite = false, sortOrderFavorite = null, initialMargin = 0.0, hedgedMargin = 0.0) {
    this.Name = name
    this.Description = description
    this.Precision = precision
    this.MarginCurrency = marginCurrency
    this.MarginCurrencyPrecision = marginCurrencyPrecision
    this.MarginCalculationType = marginCalculationType
    this.ProfitCurrency = profitCurrency
    this.ProfitCurrencyPrecision = profitCurrencyPrecision
    this.ProfitCalculationType = profitCalculationType
    this.Color = color
    this.IsTradeAllowed = isTradeAllowed
    this.ContractSize = contractSize
    this.MinTradeAmount = minTradeAmount
    this.MaxTradeAmount = maxTradeAmount
    this.TradeAmountStep = tradeAmountStep
    this.DefaultExpiration = defaultExpiration
    this.IsSwapEnabled = isSwapEnabled
    this.SwapSettings = swapSettings
    this.TradingMode = tradingMode
    this.SymbolGroupName = symbolGroupName
    this.StopsLevel = stopsLevel
    this.IsFavorite = isFavorite
    this.SortOrderFavorite = sortOrderFavorite
    this.InitialMargin = initialMargin
    this.HedgedMargin = hedgedMargin
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SymbolInfo}
   * @param {!SymbolInfo} other Other struct
   * @returns {!SymbolInfo} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Precision != null) {
      this.Precision = other.Precision
    } else {
      this.Precision = null
    }
    if (other.MarginCurrency != null) {
      this.MarginCurrency = other.MarginCurrency
    } else {
      this.MarginCurrency = null
    }
    if (other.MarginCurrencyPrecision != null) {
      this.MarginCurrencyPrecision = other.MarginCurrencyPrecision
    } else {
      this.MarginCurrencyPrecision = null
    }
    if (other.MarginCalculationType != null) {
      this.MarginCalculationType = MarginCalculationType.fromObject(other.MarginCalculationType)
    } else {
      this.MarginCalculationType = null
    }
    if (other.ProfitCurrency != null) {
      this.ProfitCurrency = other.ProfitCurrency
    } else {
      this.ProfitCurrency = null
    }
    if (other.ProfitCurrencyPrecision != null) {
      this.ProfitCurrencyPrecision = other.ProfitCurrencyPrecision
    } else {
      this.ProfitCurrencyPrecision = null
    }
    if (other.ProfitCalculationType != null) {
      this.ProfitCalculationType = ProfitCalculationType.fromObject(other.ProfitCalculationType)
    } else {
      this.ProfitCalculationType = null
    }
    if (other.Color != null) {
      this.Color = other.Color
    } else {
      this.Color = null
    }
    if (other.IsTradeAllowed != null) {
      this.IsTradeAllowed = other.IsTradeAllowed
    } else {
      this.IsTradeAllowed = null
    }
    if (other.ContractSize != null) {
      this.ContractSize = other.ContractSize
    } else {
      this.ContractSize = null
    }
    if (other.MinTradeAmount != null) {
      this.MinTradeAmount = other.MinTradeAmount
    } else {
      this.MinTradeAmount = null
    }
    if (other.MaxTradeAmount != null) {
      this.MaxTradeAmount = other.MaxTradeAmount
    } else {
      this.MaxTradeAmount = null
    }
    if (other.TradeAmountStep != null) {
      this.TradeAmountStep = other.TradeAmountStep
    } else {
      this.TradeAmountStep = null
    }
    if (other.DefaultExpiration != null) {
      this.DefaultExpiration = UInt64.fromNumber(other.DefaultExpiration)
    } else {
      this.DefaultExpiration = null
    }
    if (other.IsSwapEnabled != null) {
      this.IsSwapEnabled = other.IsSwapEnabled
    } else {
      this.IsSwapEnabled = null
    }
    if (other.SwapSettings != null) {
      this.SwapSettings = SwapSymbolSettings.fromObject(other.SwapSettings)
    } else {
      this.SwapSettings = null
    }
    if (other.TradingMode != null) {
      this.TradingMode = TradingMode.fromObject(other.TradingMode)
    } else {
      this.TradingMode = null
    }
    if (other.SymbolGroupName != null) {
      this.SymbolGroupName = other.SymbolGroupName
    } else {
      this.SymbolGroupName = null
    }
    if (other.StopsLevel != null) {
      this.StopsLevel = UInt64.fromNumber(other.StopsLevel)
    } else {
      this.StopsLevel = null
    }
    if (other.IsFavorite != null) {
      this.IsFavorite = other.IsFavorite
    } else {
      this.IsFavorite = null
    }
    if (other.SortOrderFavorite != null) {
      this.SortOrderFavorite = other.SortOrderFavorite
    } else {
      this.SortOrderFavorite = null
    }
    if (other.InitialMargin != null) {
      this.InitialMargin = other.InitialMargin
    } else {
      this.InitialMargin = null
    }
    if (other.HedgedMargin != null) {
      this.HedgedMargin = other.HedgedMargin
    } else {
      this.HedgedMargin = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SymbolInfo}
   * @returns {!SymbolInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SymbolInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SymbolInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SymbolInfo}
   * @param {!SymbolInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SymbolInfo)) {
      throw new TypeError('Instance of SymbolInfo is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.Name !== other.Name) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SymbolInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      Description: ((this.Description != null) ? this.Description : null),
      Precision: ((this.Precision != null) ? this.Precision : null),
      MarginCurrency: ((this.MarginCurrency != null) ? this.MarginCurrency : null),
      MarginCurrencyPrecision: ((this.MarginCurrencyPrecision != null) ? this.MarginCurrencyPrecision : null),
      MarginCalculationType: ((this.MarginCalculationType != null) ? this.MarginCalculationType : null),
      ProfitCurrency: ((this.ProfitCurrency != null) ? this.ProfitCurrency : null),
      ProfitCurrencyPrecision: ((this.ProfitCurrencyPrecision != null) ? this.ProfitCurrencyPrecision : null),
      ProfitCalculationType: ((this.ProfitCalculationType != null) ? this.ProfitCalculationType : null),
      Color: ((this.Color != null) ? this.Color : null),
      IsTradeAllowed: ((this.IsTradeAllowed != null) ? this.IsTradeAllowed : null),
      ContractSize: ((this.ContractSize != null) ? this.ContractSize : null),
      MinTradeAmount: ((this.MinTradeAmount != null) ? this.MinTradeAmount : null),
      MaxTradeAmount: ((this.MaxTradeAmount != null) ? this.MaxTradeAmount : null),
      TradeAmountStep: ((this.TradeAmountStep != null) ? this.TradeAmountStep : null),
      DefaultExpiration: ((this.DefaultExpiration != null) ? this.DefaultExpiration.toNumber() : null),
      IsSwapEnabled: ((this.IsSwapEnabled != null) ? this.IsSwapEnabled : null),
      SwapSettings: ((this.SwapSettings != null) ? this.SwapSettings : null),
      TradingMode: ((this.TradingMode != null) ? this.TradingMode : null),
      SymbolGroupName: ((this.SymbolGroupName != null) ? this.SymbolGroupName : null),
      StopsLevel: ((this.StopsLevel != null) ? this.StopsLevel.toNumber() : null),
      IsFavorite: ((this.IsFavorite != null) ? this.IsFavorite : null),
      SortOrderFavorite: ((this.SortOrderFavorite != null) ? this.SortOrderFavorite : null),
      InitialMargin: ((this.InitialMargin != null) ? this.InitialMargin : null),
      HedgedMargin: ((this.HedgedMargin != null) ? this.HedgedMargin : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SymbolInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SymbolInfo} other Object value
   * @returns {!SymbolInfo} Created struct
   */
  static fromObject (other) {
    return new SymbolInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SymbolInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SymbolInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SymbolInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5025
  }
}

export { SymbolInfo };

/**
 * Fast Binary Encoding SymbolInfo field model
 */
class FieldModelSymbolInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.Description = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Precision = new fbe.FieldModelUInt8(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.MarginCurrency = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Precision.fbeOffset + this.Precision.fbeSize), buffer, this.Precision.fbeOffset + this.Precision.fbeSize)
    this.MarginCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.MarginCurrency.fbeOffset + this.MarginCurrency.fbeSize), buffer, this.MarginCurrency.fbeOffset + this.MarginCurrency.fbeSize)
    this.MarginCalculationType = new FieldModelMarginCalculationType(buffer, this.MarginCurrencyPrecision.fbeOffset + this.MarginCurrencyPrecision.fbeSize)
    this.ProfitCurrency = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize), buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize)
    this.ProfitCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.ProfitCurrency.fbeOffset + this.ProfitCurrency.fbeSize), buffer, this.ProfitCurrency.fbeOffset + this.ProfitCurrency.fbeSize)
    this.ProfitCalculationType = new FieldModelProfitCalculationType(buffer, this.ProfitCurrencyPrecision.fbeOffset + this.ProfitCurrencyPrecision.fbeSize)
    this.Color = new fbe.FieldModelUInt32(buffer, this.ProfitCalculationType.fbeOffset + this.ProfitCalculationType.fbeSize)
    this.IsTradeAllowed = new fbe.FieldModelBool(buffer, this.Color.fbeOffset + this.Color.fbeSize)
    this.ContractSize = new fbe.FieldModelDouble(buffer, this.IsTradeAllowed.fbeOffset + this.IsTradeAllowed.fbeSize)
    this.MinTradeAmount = new fbe.FieldModelDouble(buffer, this.ContractSize.fbeOffset + this.ContractSize.fbeSize)
    this.MaxTradeAmount = new fbe.FieldModelDouble(buffer, this.MinTradeAmount.fbeOffset + this.MinTradeAmount.fbeSize)
    this.TradeAmountStep = new fbe.FieldModelDouble(buffer, this.MaxTradeAmount.fbeOffset + this.MaxTradeAmount.fbeSize)
    this.DefaultExpiration = new fbe.FieldModelUInt64(buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize)
    this.IsSwapEnabled = new fbe.FieldModelBool(buffer, this.DefaultExpiration.fbeOffset + this.DefaultExpiration.fbeSize)
    this.SwapSettings = new fbe.FieldModelOptional(new FieldModelSwapSymbolSettings(buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize), buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize)
    this.TradingMode = new FieldModelTradingMode(buffer, this.SwapSettings.fbeOffset + this.SwapSettings.fbeSize)
    this.SymbolGroupName = new fbe.FieldModelString(buffer, this.TradingMode.fbeOffset + this.TradingMode.fbeSize)
    this.StopsLevel = new fbe.FieldModelUInt64(buffer, this.SymbolGroupName.fbeOffset + this.SymbolGroupName.fbeSize)
    this.IsFavorite = new fbe.FieldModelBool(buffer, this.StopsLevel.fbeOffset + this.StopsLevel.fbeSize)
    this.SortOrderFavorite = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize), buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize)
    this.InitialMargin = new fbe.FieldModelDouble(buffer, this.SortOrderFavorite.fbeOffset + this.SortOrderFavorite.fbeSize)
    this.HedgedMargin = new fbe.FieldModelDouble(buffer, this.InitialMargin.fbeOffset + this.InitialMargin.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.Description.fbeSize + this.Precision.fbeSize + this.MarginCurrency.fbeSize + this.MarginCurrencyPrecision.fbeSize + this.MarginCalculationType.fbeSize + this.ProfitCurrency.fbeSize + this.ProfitCurrencyPrecision.fbeSize + this.ProfitCalculationType.fbeSize + this.Color.fbeSize + this.IsTradeAllowed.fbeSize + this.ContractSize.fbeSize + this.MinTradeAmount.fbeSize + this.MaxTradeAmount.fbeSize + this.TradeAmountStep.fbeSize + this.DefaultExpiration.fbeSize + this.IsSwapEnabled.fbeSize + this.SwapSettings.fbeSize + this.TradingMode.fbeSize + this.SymbolGroupName.fbeSize + this.StopsLevel.fbeSize + this.IsFavorite.fbeSize + this.SortOrderFavorite.fbeSize + this.InitialMargin.fbeSize + this.HedgedMargin.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.Description.fbeExtra + this.Precision.fbeExtra + this.MarginCurrency.fbeExtra + this.MarginCurrencyPrecision.fbeExtra + this.MarginCalculationType.fbeExtra + this.ProfitCurrency.fbeExtra + this.ProfitCurrencyPrecision.fbeExtra + this.ProfitCalculationType.fbeExtra + this.Color.fbeExtra + this.IsTradeAllowed.fbeExtra + this.ContractSize.fbeExtra + this.MinTradeAmount.fbeExtra + this.MaxTradeAmount.fbeExtra + this.TradeAmountStep.fbeExtra + this.DefaultExpiration.fbeExtra + this.IsSwapEnabled.fbeExtra + this.SwapSettings.fbeExtra + this.TradingMode.fbeExtra + this.SymbolGroupName.fbeExtra + this.StopsLevel.fbeExtra + this.IsFavorite.fbeExtra + this.SortOrderFavorite.fbeExtra + this.InitialMargin.fbeExtra + this.HedgedMargin.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSymbolInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5025
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSymbolInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSymbolInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Precision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrency.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrency.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Color.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeAllowed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ContractSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeAmountStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSettings.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopsLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrderFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InitialMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HedgedMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSymbolInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSymbolInfo}
   * @param {!SymbolInfo} fbeValue Default value, defaults is new SymbolInfo()
   * @returns {!SymbolInfo} SymbolInfo value
   */
  get (fbeValue = new SymbolInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSymbolInfo}
   * @param {!SymbolInfo} fbeValue SymbolInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) <= fbeStructSize) {
      fbeValue.Precision = this.Precision.get()
    } else {
      fbeValue.Precision = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrency.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrency = this.MarginCurrency.get()
    } else {
      fbeValue.MarginCurrency = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrency.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyPrecision = this.MarginCurrencyPrecision.get()
    } else {
      fbeValue.MarginCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCalculationType = this.MarginCalculationType.get()
    } else {
      fbeValue.MarginCalculationType = new MarginCalculationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrency.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrency = this.ProfitCurrency.get()
    } else {
      fbeValue.ProfitCurrency = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrency.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyPrecision = this.ProfitCurrencyPrecision.get()
    } else {
      fbeValue.ProfitCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCalculationType = this.ProfitCalculationType.get()
    } else {
      fbeValue.ProfitCalculationType = new ProfitCalculationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) <= fbeStructSize) {
      fbeValue.Color = this.Color.get()
    } else {
      fbeValue.Color = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeAllowed = this.IsTradeAllowed.get()
    } else {
      fbeValue.IsTradeAllowed = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) <= fbeStructSize) {
      fbeValue.ContractSize = this.ContractSize.get()
    } else {
      fbeValue.ContractSize = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MinTradeAmount = this.MinTradeAmount.get()
    } else {
      fbeValue.MinTradeAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MaxTradeAmount = this.MaxTradeAmount.get()
    } else {
      fbeValue.MaxTradeAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) <= fbeStructSize) {
      fbeValue.TradeAmountStep = this.TradeAmountStep.get()
    } else {
      fbeValue.TradeAmountStep = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultExpiration = this.DefaultExpiration.get()
    } else {
      fbeValue.DefaultExpiration = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSwapEnabled = this.IsSwapEnabled.get()
    } else {
      fbeValue.IsSwapEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapSettings.fbeSize) <= fbeStructSize) {
      fbeValue.SwapSettings = this.SwapSettings.get()
    } else {
      fbeValue.SwapSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSettings.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) <= fbeStructSize) {
      fbeValue.TradingMode = this.TradingMode.get()
    } else {
      fbeValue.TradingMode = new TradingMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolGroupName = this.SymbolGroupName.get()
    } else {
      fbeValue.SymbolGroupName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) <= fbeStructSize) {
      fbeValue.StopsLevel = this.StopsLevel.get()
    } else {
      fbeValue.StopsLevel = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.IsFavorite = this.IsFavorite.get()
    } else {
      fbeValue.IsFavorite = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrderFavorite = this.SortOrderFavorite.get()
    } else {
      fbeValue.SortOrderFavorite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) <= fbeStructSize) {
      fbeValue.InitialMargin = this.InitialMargin.get()
    } else {
      fbeValue.InitialMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) <= fbeStructSize) {
      fbeValue.HedgedMargin = this.HedgedMargin.get()
    } else {
      fbeValue.HedgedMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSymbolInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSymbolInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSymbolInfo}
   * @param {!SymbolInfo} fbeValue SymbolInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSymbolInfo}
   * @param {SymbolInfo} fbeValue SymbolInfo value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.Description.set(fbeValue.Description)
    this.Precision.set(fbeValue.Precision)
    this.MarginCurrency.set(fbeValue.MarginCurrency)
    this.MarginCurrencyPrecision.set(fbeValue.MarginCurrencyPrecision)
    this.MarginCalculationType.set(fbeValue.MarginCalculationType)
    this.ProfitCurrency.set(fbeValue.ProfitCurrency)
    this.ProfitCurrencyPrecision.set(fbeValue.ProfitCurrencyPrecision)
    this.ProfitCalculationType.set(fbeValue.ProfitCalculationType)
    this.Color.set(fbeValue.Color)
    this.IsTradeAllowed.set(fbeValue.IsTradeAllowed)
    this.ContractSize.set(fbeValue.ContractSize)
    this.MinTradeAmount.set(fbeValue.MinTradeAmount)
    this.MaxTradeAmount.set(fbeValue.MaxTradeAmount)
    this.TradeAmountStep.set(fbeValue.TradeAmountStep)
    this.DefaultExpiration.set(fbeValue.DefaultExpiration)
    this.IsSwapEnabled.set(fbeValue.IsSwapEnabled)
    this.SwapSettings.set(fbeValue.SwapSettings)
    this.TradingMode.set(fbeValue.TradingMode)
    this.SymbolGroupName.set(fbeValue.SymbolGroupName)
    this.StopsLevel.set(fbeValue.StopsLevel)
    this.IsFavorite.set(fbeValue.IsFavorite)
    this.SortOrderFavorite.set(fbeValue.SortOrderFavorite)
    this.InitialMargin.set(fbeValue.InitialMargin)
    this.HedgedMargin.set(fbeValue.HedgedMargin)
  }
}

export { FieldModelSymbolInfo };

/**
 * Fast Binary Encoding SymbolInfo model
 */
class SymbolInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSymbolInfo(this.buffer, 4)
  }

  /**
   * Get the SymbolInfo model
   * @this {!SymbolInfoModel}
   * @returns {!FieldModelSymbolInfo} model SymbolInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SymbolInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SymbolInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SymbolInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SymbolInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSymbolInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SymbolInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SymbolInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SymbolInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SymbolInfoModel}
   * @param {!SymbolInfo} value SymbolInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SymbolInfoModel}
   * @param {!SymbolInfo} value SymbolInfo value, defaults is new SymbolInfo()
   * @return {!object} Deserialized SymbolInfo value and its size
   */
  deserialize (value = new SymbolInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SymbolInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SymbolInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SymbolInfoModel}
   * @param {!number} prev Previous SymbolInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SymbolInfoModel };
