import React, { useState } from 'react';
import { keyMap, t } from '@localization';
import { Button, Form, Input } from 'antd';
import styles from './LoginForm.module.scss';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultLoginRecord, loginSchema } from './schemas';
import { LoginReject } from '@api/fbe/client/LoginReject';
import { LS_WS_CONNECTION_KEY } from '@constants';
import { LoginValues, isValidWsUrl, WS_PREFIX, getWSUrlWithoutPrefix } from '@models/Auth';
import StorageHelper from '@utils/StorageHelper';
import cn from 'classnames';
import { authStore } from '@store/auth';
import { useReaction } from '@App/pages/utils/commonUtils';

type Props = {
    sendAuth(values: LoginValues, onFail: (reason: LoginReject) => void): void;
};

function LoginForm({ sendAuth }: Props) {
    const [failedReason, setFailedReason] = useState<string | null>(null);
    const [isLoading] = useReaction<boolean>(() => authStore.isLoading);
    const wsUrl = StorageHelper.getFromLocal(LS_WS_CONNECTION_KEY);

    const onFinish = (values: LoginValues) => {
        sendAuth(values, (reason) => {
            setFailedReason(reason.Reason);
        });
    };

    const {
        handleSubmit,
        formState: { isValid, errors },
        control,
    } = useForm<LoginValues>({
        mode: 'all',
        defaultValues: {
            ...defaultLoginRecord,
            wsUrl: wsUrl && isValidWsUrl(wsUrl) ? wsUrl : defaultLoginRecord.wsUrl,
        },
        resolver: yupResolver(loginSchema),
    });

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>{t(keyMap.form.auth.title)}</h1>
            <Form name="login">
                <FieldContainer>
                    <FieldLabel title={t(keyMap.form.auth.apiSource)} required />
                    <Controller
                        control={control}
                        name="wsUrl"
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder={t(keyMap.form.auth.apiSourcePlaceholder)}
                                addonBefore={WS_PREFIX}
                                onBlur={({ target: { value } }) => {
                                    field.onChange(getWSUrlWithoutPrefix(value));
                                }}
                                className={cn({
                                    invalid: !!errors.wsUrl?.message,
                                })}
                            />
                        )}
                    />
                    <ValidationMessage message={errors.wsUrl?.message} />
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel title={t(keyMap.form.auth.login)} required />
                    <Controller
                        control={control}
                        name="login"
                        render={({ field }) => (
                            <Input
                                {...field}
                                className={cn({
                                    invalid: !!errors.login?.message,
                                })}
                            />
                        )}
                    />
                    <ValidationMessage message={errors.login?.message} />
                </FieldContainer>
                <FieldContainer>
                    <FieldLabel title={t(keyMap.form.auth.password)} required />
                    <Controller
                        control={control}
                        name="password"
                        render={({ field }) => <Input.Password {...field} className={styles.password} />}
                    />
                    <ValidationMessage message={errors.password?.message} />
                </FieldContainer>
                <div className={styles.formFooter}>
                    <Button
                        className={styles.submitBtn}
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        onClick={handleSubmit(onFinish)}
                        disabled={!isValid}
                    >
                        {isLoading ? null : t(keyMap.form.auth.submit)}
                    </Button>
                </div>
                <ValidationMessage message={failedReason || undefined} />
            </Form>
        </div>
    );
}

export default LoginForm;
