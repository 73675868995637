
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Account} from '../core/Account';
import {FieldModelAccount} from '../core/Account';
import {AccountMarginStable} from '../entity/AccountMarginStable';
import {FieldModelAccountMarginStable} from '../entity/AccountMarginStable';
import {TradeHistoryRecord} from '../core/TradeHistoryRecord';
import {FieldModelTradeHistoryRecord} from '../core/TradeHistoryRecord';

/**
 * DepositMargin2Response struct
 */
class DepositMargin2Response extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Account=} accountPrevious
   * @param {!Account=} accountCurrent
   * @param {!AccountMarginStable=} marginPrevious
   * @param {!AccountMarginStable=} marginCurrent
   * @param {!TradeHistoryRecord=} accountTradeHistoryRecord
   * @param {!TradeHistoryRecord=} depositTradeHistoryRecord
   * @constructor
   */
  constructor (parent = new Response(), accountPrevious = new Account(), accountCurrent = new Account(), marginPrevious = new AccountMarginStable(), marginCurrent = new AccountMarginStable(), accountTradeHistoryRecord = new TradeHistoryRecord(), depositTradeHistoryRecord = new TradeHistoryRecord()) {
    super()
    super.copy(parent)
    this.AccountPrevious = accountPrevious
    this.AccountCurrent = accountCurrent
    this.MarginPrevious = marginPrevious
    this.MarginCurrent = marginCurrent
    this.AccountTradeHistoryRecord = accountTradeHistoryRecord
    this.DepositTradeHistoryRecord = depositTradeHistoryRecord
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DepositMargin2Response}
   * @param {!DepositMargin2Response} other Other struct
   * @returns {!DepositMargin2Response} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.AccountPrevious != null) {
      this.AccountPrevious = Account.fromObject(other.AccountPrevious)
    } else {
      this.AccountPrevious = null
    }
    if (other.AccountCurrent != null) {
      this.AccountCurrent = Account.fromObject(other.AccountCurrent)
    } else {
      this.AccountCurrent = null
    }
    if (other.MarginPrevious != null) {
      this.MarginPrevious = AccountMarginStable.fromObject(other.MarginPrevious)
    } else {
      this.MarginPrevious = null
    }
    if (other.MarginCurrent != null) {
      this.MarginCurrent = AccountMarginStable.fromObject(other.MarginCurrent)
    } else {
      this.MarginCurrent = null
    }
    if (other.AccountTradeHistoryRecord != null) {
      this.AccountTradeHistoryRecord = TradeHistoryRecord.fromObject(other.AccountTradeHistoryRecord)
    } else {
      this.AccountTradeHistoryRecord = null
    }
    if (other.DepositTradeHistoryRecord != null) {
      this.DepositTradeHistoryRecord = TradeHistoryRecord.fromObject(other.DepositTradeHistoryRecord)
    } else {
      this.DepositTradeHistoryRecord = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DepositMargin2Response}
   * @returns {!DepositMargin2Response} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DepositMargin2ResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DepositMargin2ResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DepositMargin2Response}
   * @param {!DepositMargin2Response} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DepositMargin2Response)) {
      throw new TypeError('Instance of DepositMargin2Response is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DepositMargin2Response}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      AccountPrevious: ((this.AccountPrevious != null) ? this.AccountPrevious : null),
      AccountCurrent: ((this.AccountCurrent != null) ? this.AccountCurrent : null),
      MarginPrevious: ((this.MarginPrevious != null) ? this.MarginPrevious : null),
      MarginCurrent: ((this.MarginCurrent != null) ? this.MarginCurrent : null),
      AccountTradeHistoryRecord: ((this.AccountTradeHistoryRecord != null) ? this.AccountTradeHistoryRecord : null),
      DepositTradeHistoryRecord: ((this.DepositTradeHistoryRecord != null) ? this.DepositTradeHistoryRecord : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DepositMargin2Response.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DepositMargin2Response} other Object value
   * @returns {!DepositMargin2Response} Created struct
   */
  static fromObject (other) {
    return new DepositMargin2Response().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DepositMargin2Response}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DepositMargin2Response.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DepositMargin2Response}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4096
  }
}

export { DepositMargin2Response };

/**
 * Fast Binary Encoding DepositMargin2Response field model
 */
class FieldModelDepositMargin2Response extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.AccountPrevious = new FieldModelAccount(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountCurrent = new FieldModelAccount(buffer, this.AccountPrevious.fbeOffset + this.AccountPrevious.fbeSize)
    this.MarginPrevious = new FieldModelAccountMarginStable(buffer, this.AccountCurrent.fbeOffset + this.AccountCurrent.fbeSize)
    this.MarginCurrent = new FieldModelAccountMarginStable(buffer, this.MarginPrevious.fbeOffset + this.MarginPrevious.fbeSize)
    this.AccountTradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.MarginCurrent.fbeOffset + this.MarginCurrent.fbeSize)
    this.DepositTradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.AccountTradeHistoryRecord.fbeOffset + this.AccountTradeHistoryRecord.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.AccountPrevious.fbeSize + this.AccountCurrent.fbeSize + this.MarginPrevious.fbeSize + this.MarginCurrent.fbeSize + this.AccountTradeHistoryRecord.fbeSize + this.DepositTradeHistoryRecord.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.AccountPrevious.fbeExtra + this.AccountCurrent.fbeExtra + this.MarginPrevious.fbeExtra + this.MarginCurrent.fbeExtra + this.AccountTradeHistoryRecord.fbeExtra + this.DepositTradeHistoryRecord.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDepositMargin2Response.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4096
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDepositMargin2Response}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDepositMargin2Response}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountCurrent.fbeSize

    if ((fbeCurrentSize + this.MarginPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginPrevious.fbeSize

    if ((fbeCurrentSize + this.MarginCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrent.fbeSize

    if ((fbeCurrentSize + this.AccountTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.DepositTradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DepositTradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DepositTradeHistoryRecord.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDepositMargin2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDepositMargin2Response}
   * @param {!DepositMargin2Response} fbeValue Default value, defaults is new DepositMargin2Response()
   * @returns {!DepositMargin2Response} DepositMargin2Response value
   */
  get (fbeValue = new DepositMargin2Response()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDepositMargin2Response}
   * @param {!DepositMargin2Response} fbeValue DepositMargin2Response value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.AccountPrevious = this.AccountPrevious.get()
    } else {
      fbeValue.AccountPrevious = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.AccountCurrent = this.AccountCurrent.get()
    } else {
      fbeValue.AccountCurrent = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountCurrent.fbeSize

    if ((fbeCurrentSize + this.MarginPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.MarginPrevious = this.MarginPrevious.get()
    } else {
      fbeValue.MarginPrevious = new AccountMarginStable()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginPrevious.fbeSize

    if ((fbeCurrentSize + this.MarginCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrent = this.MarginCurrent.get()
    } else {
      fbeValue.MarginCurrent = new AccountMarginStable()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrent.fbeSize

    if ((fbeCurrentSize + this.AccountTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.AccountTradeHistoryRecord = this.AccountTradeHistoryRecord.get()
    } else {
      fbeValue.AccountTradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountTradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.DepositTradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.DepositTradeHistoryRecord = this.DepositTradeHistoryRecord.get()
    } else {
      fbeValue.DepositTradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DepositTradeHistoryRecord.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDepositMargin2Response}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDepositMargin2Response}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDepositMargin2Response}
   * @param {!DepositMargin2Response} fbeValue DepositMargin2Response value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDepositMargin2Response}
   * @param {DepositMargin2Response} fbeValue DepositMargin2Response value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.AccountPrevious.set(fbeValue.AccountPrevious)
    this.AccountCurrent.set(fbeValue.AccountCurrent)
    this.MarginPrevious.set(fbeValue.MarginPrevious)
    this.MarginCurrent.set(fbeValue.MarginCurrent)
    this.AccountTradeHistoryRecord.set(fbeValue.AccountTradeHistoryRecord)
    this.DepositTradeHistoryRecord.set(fbeValue.DepositTradeHistoryRecord)
  }
}

export { FieldModelDepositMargin2Response };

/**
 * Fast Binary Encoding DepositMargin2Response model
 */
class DepositMargin2ResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDepositMargin2Response(this.buffer, 4)
  }

  /**
   * Get the DepositMargin2Response model
   * @this {!DepositMargin2ResponseModel}
   * @returns {!FieldModelDepositMargin2Response} model DepositMargin2Response model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DepositMargin2ResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DepositMargin2ResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DepositMargin2ResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DepositMargin2ResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDepositMargin2Response.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DepositMargin2ResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DepositMargin2ResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DepositMargin2ResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DepositMargin2ResponseModel}
   * @param {!DepositMargin2Response} value DepositMargin2Response value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DepositMargin2ResponseModel}
   * @param {!DepositMargin2Response} value DepositMargin2Response value, defaults is new DepositMargin2Response()
   * @return {!object} Deserialized DepositMargin2Response value and its size
   */
  deserialize (value = new DepositMargin2Response()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DepositMargin2Response(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DepositMargin2Response(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DepositMargin2ResponseModel}
   * @param {!number} prev Previous DepositMargin2Response model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DepositMargin2ResponseModel };
