// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: history.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as entity from './entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ClientReportTemplatePlaceholder} from './history/ClientReportTemplatePlaceholder'
import {HistoryVersionRequest} from './history/HistoryVersionRequest'
import {HistoryVersionRequestModel} from './history/HistoryVersionRequest'
import {HistoryVersionResponse} from './history/HistoryVersionResponse'
import {HistoryVersionResponseModel} from './history/HistoryVersionResponse'
import {HistoryUpdateNotify} from './history/HistoryUpdateNotify'
import {HistoryUpdateNotifyModel} from './history/HistoryUpdateNotify'
import {ChartBarHistoryRequest} from './history/ChartBarHistoryRequest'
import {ChartBarHistoryRequestModel} from './history/ChartBarHistoryRequest'
import {ChartBarHistoryResponse} from './history/ChartBarHistoryResponse'
import {ChartBarHistoryResponseModel} from './history/ChartBarHistoryResponse'
import {ChartBarNotify} from './history/ChartBarNotify'
import {ChartBarNotifyModel} from './history/ChartBarNotify'
import {ChartBarUpdateNotify} from './history/ChartBarUpdateNotify'
import {ChartBarUpdateNotifyModel} from './history/ChartBarUpdateNotify'
import {TickHistoryRequest} from './history/TickHistoryRequest'
import {TickHistoryRequestModel} from './history/TickHistoryRequest'
import {TickHistoryResponse} from './history/TickHistoryResponse'
import {TickHistoryResponseModel} from './history/TickHistoryResponse'
import {ChartBarHistoryModifyRequest} from './history/ChartBarHistoryModifyRequest'
import {ChartBarHistoryModifyRequestModel} from './history/ChartBarHistoryModifyRequest'
import {ChartBarHistoryModifyResponse} from './history/ChartBarHistoryModifyResponse'
import {ChartBarHistoryModifyResponseModel} from './history/ChartBarHistoryModifyResponse'
import {ChartBarHistoryRangeRequest} from './history/ChartBarHistoryRangeRequest'
import {ChartBarHistoryRangeRequestModel} from './history/ChartBarHistoryRangeRequest'
import {ChartBarHistoryLastModifiedRangeRequest} from './history/ChartBarHistoryLastModifiedRangeRequest'
import {ChartBarHistoryLastModifiedRangeRequestModel} from './history/ChartBarHistoryLastModifiedRangeRequest'
import {ChartBar} from './history/ChartBar'
import {ChartBarModel} from './history/ChartBar'
import {ChartBarHistoryLastModifiedRangeResponse} from './history/ChartBarHistoryLastModifiedRangeResponse'
import {ChartBarHistoryLastModifiedRangeResponseModel} from './history/ChartBarHistoryLastModifiedRangeResponse'
import {ChartBarModifyNotify} from './history/ChartBarModifyNotify'
import {ChartBarModifyNotifyModel} from './history/ChartBarModifyNotify'
import {ClientReportsListGetRequest} from './history/ClientReportsListGetRequest'
import {ClientReportsListGetRequestModel} from './history/ClientReportsListGetRequest'
import {ClientReportStatus} from './history/ClientReportStatus'
import {ClientReportStatusModel} from './history/ClientReportStatus'
import {ClientReportsListGetResponse} from './history/ClientReportsListGetResponse'
import {ClientReportsListGetResponseModel} from './history/ClientReportsListGetResponse'
import {ClientReportGetRequest} from './history/ClientReportGetRequest'
import {ClientReportGetRequestModel} from './history/ClientReportGetRequest'
import {ClientReportGetResponse} from './history/ClientReportGetResponse'
import {ClientReportGetResponseModel} from './history/ClientReportGetResponse'
import {ClientReportsSendRequest} from './history/ClientReportsSendRequest'
import {ClientReportsSendRequestModel} from './history/ClientReportsSendRequest'
import {ClientReportsSendResponse} from './history/ClientReportsSendResponse'
import {ClientReportsSendResponseModel} from './history/ClientReportsSendResponse'
import {ChartBarsImportInfo} from './history/ChartBarsImportInfo'
import {ChartBarsImportInfoModel} from './history/ChartBarsImportInfo'
import {ChartBarsImportRequest} from './history/ChartBarsImportRequest'
import {ChartBarsImportRequestModel} from './history/ChartBarsImportRequest'
import {ChartBarsImportResponse} from './history/ChartBarsImportResponse'
import {ChartBarsImportResponseModel} from './history/ChartBarsImportResponse'
import {ChartBarsImportNotify} from './history/ChartBarsImportNotify'
import {ChartBarsImportNotifyModel} from './history/ChartBarsImportNotify'
import {ChartBarsImportFinishedNotify} from './history/ChartBarsImportFinishedNotify'
import {ChartBarsImportFinishedNotifyModel} from './history/ChartBarsImportFinishedNotify'
import {ClientReport} from './history/ClientReport'
import {ClientReportModel} from './history/ClientReport'
import {ClientReportsGetRequest} from './history/ClientReportsGetRequest'
import {ClientReportsGetRequestModel} from './history/ClientReportsGetRequest'
import {ClientReportsGetResponse} from './history/ClientReportsGetResponse'
import {ClientReportsGetResponseModel} from './history/ClientReportsGetResponse'
import {ClientReportsInsertRequest} from './history/ClientReportsInsertRequest'
import {ClientReportsInsertRequestModel} from './history/ClientReportsInsertRequest'
import {ClientReportsInsertResponse} from './history/ClientReportsInsertResponse'
import {ClientReportsInsertResponseModel} from './history/ClientReportsInsertResponse'
import {ClientReportsGetLastIdRequest} from './history/ClientReportsGetLastIdRequest'
import {ClientReportsGetLastIdRequestModel} from './history/ClientReportsGetLastIdRequest'
import {ClientReportsGetLastIdResponse} from './history/ClientReportsGetLastIdResponse'
import {ClientReportsGetLastIdResponseModel} from './history/ClientReportsGetLastIdResponse'
import {BlockedIPEventLogCreateParams} from './history/BlockedIPEventLogCreateParams'
import {BlockedIPEventLogCreateParamsModel} from './history/BlockedIPEventLogCreateParams'
import {BlockedIPEventLogCreateRequest} from './history/BlockedIPEventLogCreateRequest'
import {BlockedIPEventLogCreateRequestModel} from './history/BlockedIPEventLogCreateRequest'
import {BlockedIPEventLogCreateResult} from './history/BlockedIPEventLogCreateResult'
import {BlockedIPEventLogCreateResultModel} from './history/BlockedIPEventLogCreateResult'
import {BlockedIPEventLogCreateResponse} from './history/BlockedIPEventLogCreateResponse'
import {BlockedIPEventLogCreateResponseModel} from './history/BlockedIPEventLogCreateResponse'
import {BlockedIPNotify} from './history/BlockedIPNotify'
import {BlockedIPNotifyModel} from './history/BlockedIPNotify'
import {BlockedIPEventLogGetParams} from './history/BlockedIPEventLogGetParams'
import {BlockedIPEventLogGetParamsModel} from './history/BlockedIPEventLogGetParams'
import {BlockedIPEventLogGetRequest} from './history/BlockedIPEventLogGetRequest'
import {BlockedIPEventLogGetRequestModel} from './history/BlockedIPEventLogGetRequest'
import {BlockedIPEventLogGetResult} from './history/BlockedIPEventLogGetResult'
import {BlockedIPEventLogGetResultModel} from './history/BlockedIPEventLogGetResult'
import {BlockedIPEventLogGetResponse} from './history/BlockedIPEventLogGetResponse'
import {BlockedIPEventLogGetResponseModel} from './history/BlockedIPEventLogGetResponse'
import {BlockedIPEventLogGetFilterParams} from './history/BlockedIPEventLogGetFilterParams'
import {BlockedIPEventLogGetFilterParamsModel} from './history/BlockedIPEventLogGetFilterParams'
import {BlockedIPEventLogGetFilterRequest} from './history/BlockedIPEventLogGetFilterRequest'
import {BlockedIPEventLogGetFilterRequestModel} from './history/BlockedIPEventLogGetFilterRequest'
import {BlockedIPEventLogGetFilterResult} from './history/BlockedIPEventLogGetFilterResult'
import {BlockedIPEventLogGetFilterResultModel} from './history/BlockedIPEventLogGetFilterResult'
import {BlockedIPEventLogGetFilterResponse} from './history/BlockedIPEventLogGetFilterResponse'
import {BlockedIPEventLogGetFilterResponseModel} from './history/BlockedIPEventLogGetFilterResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding history sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize history sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._entitySender = new entity.Sender(this.buffer)
    this._HistoryVersionRequestModel = new HistoryVersionRequestModel(this.buffer)
    this._HistoryVersionResponseModel = new HistoryVersionResponseModel(this.buffer)
    this._HistoryUpdateNotifyModel = new HistoryUpdateNotifyModel(this.buffer)
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel(this.buffer)
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel(this.buffer)
    this._ChartBarNotifyModel = new ChartBarNotifyModel(this.buffer)
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel(this.buffer)
    this._TickHistoryRequestModel = new TickHistoryRequestModel(this.buffer)
    this._TickHistoryResponseModel = new TickHistoryResponseModel(this.buffer)
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel(this.buffer)
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel(this.buffer)
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel(this.buffer)
    this._ChartBarHistoryLastModifiedRangeRequestModel = new ChartBarHistoryLastModifiedRangeRequestModel(this.buffer)
    this._ChartBarHistoryLastModifiedRangeResponseModel = new ChartBarHistoryLastModifiedRangeResponseModel(this.buffer)
    this._ChartBarModifyNotifyModel = new ChartBarModifyNotifyModel(this.buffer)
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel(this.buffer)
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel(this.buffer)
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel(this.buffer)
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel(this.buffer)
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel(this.buffer)
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel(this.buffer)
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel(this.buffer)
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel(this.buffer)
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel(this.buffer)
    this._ChartBarsImportFinishedNotifyModel = new ChartBarsImportFinishedNotifyModel(this.buffer)
    this._ClientReportsGetRequestModel = new ClientReportsGetRequestModel(this.buffer)
    this._ClientReportsGetResponseModel = new ClientReportsGetResponseModel(this.buffer)
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel(this.buffer)
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel(this.buffer)
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel(this.buffer)
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel(this.buffer)
    this._BlockedIPEventLogCreateParamsModel = new BlockedIPEventLogCreateParamsModel(this.buffer)
    this._BlockedIPEventLogCreateRequestModel = new BlockedIPEventLogCreateRequestModel(this.buffer)
    this._BlockedIPEventLogCreateResultModel = new BlockedIPEventLogCreateResultModel(this.buffer)
    this._BlockedIPEventLogCreateResponseModel = new BlockedIPEventLogCreateResponseModel(this.buffer)
    this._BlockedIPNotifyModel = new BlockedIPNotifyModel(this.buffer)
    this._BlockedIPEventLogGetParamsModel = new BlockedIPEventLogGetParamsModel(this.buffer)
    this._BlockedIPEventLogGetRequestModel = new BlockedIPEventLogGetRequestModel(this.buffer)
    this._BlockedIPEventLogGetResultModel = new BlockedIPEventLogGetResultModel(this.buffer)
    this._BlockedIPEventLogGetResponseModel = new BlockedIPEventLogGetResponseModel(this.buffer)
    this._BlockedIPEventLogGetFilterParamsModel = new BlockedIPEventLogGetFilterParamsModel(this.buffer)
    this._BlockedIPEventLogGetFilterRequestModel = new BlockedIPEventLogGetFilterRequestModel(this.buffer)
    this._BlockedIPEventLogGetFilterResultModel = new BlockedIPEventLogGetFilterResultModel(this.buffer)
    this._BlockedIPEventLogGetFilterResponseModel = new BlockedIPEventLogGetFilterResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported entity sender
   * @this {!Sender}
   * @returns {!entity.Sender} entity sender
   */
  get entitySender () {
    return this._entitySender
  }

  // Sender models accessors

  /**
   * Get HistoryVersionRequest model
   * @this {!Sender}
   * @returns {!HistoryVersionRequestModel} HistoryVersionRequest model
   */
  get HistoryVersionRequestModel () {
    return this._HistoryVersionRequestModel
  }

  /**
   * Get HistoryVersionResponse model
   * @this {!Sender}
   * @returns {!HistoryVersionResponseModel} HistoryVersionResponse model
   */
  get HistoryVersionResponseModel () {
    return this._HistoryVersionResponseModel
  }

  /**
   * Get HistoryUpdateNotify model
   * @this {!Sender}
   * @returns {!HistoryUpdateNotifyModel} HistoryUpdateNotify model
   */
  get HistoryUpdateNotifyModel () {
    return this._HistoryUpdateNotifyModel
  }

  /**
   * Get ChartBarHistoryRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryRequestModel} ChartBarHistoryRequest model
   */
  get ChartBarHistoryRequestModel () {
    return this._ChartBarHistoryRequestModel
  }

  /**
   * Get ChartBarHistoryResponse model
   * @this {!Sender}
   * @returns {!ChartBarHistoryResponseModel} ChartBarHistoryResponse model
   */
  get ChartBarHistoryResponseModel () {
    return this._ChartBarHistoryResponseModel
  }

  /**
   * Get ChartBarNotify model
   * @this {!Sender}
   * @returns {!ChartBarNotifyModel} ChartBarNotify model
   */
  get ChartBarNotifyModel () {
    return this._ChartBarNotifyModel
  }

  /**
   * Get ChartBarUpdateNotify model
   * @this {!Sender}
   * @returns {!ChartBarUpdateNotifyModel} ChartBarUpdateNotify model
   */
  get ChartBarUpdateNotifyModel () {
    return this._ChartBarUpdateNotifyModel
  }

  /**
   * Get TickHistoryRequest model
   * @this {!Sender}
   * @returns {!TickHistoryRequestModel} TickHistoryRequest model
   */
  get TickHistoryRequestModel () {
    return this._TickHistoryRequestModel
  }

  /**
   * Get TickHistoryResponse model
   * @this {!Sender}
   * @returns {!TickHistoryResponseModel} TickHistoryResponse model
   */
  get TickHistoryResponseModel () {
    return this._TickHistoryResponseModel
  }

  /**
   * Get ChartBarHistoryModifyRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryModifyRequestModel} ChartBarHistoryModifyRequest model
   */
  get ChartBarHistoryModifyRequestModel () {
    return this._ChartBarHistoryModifyRequestModel
  }

  /**
   * Get ChartBarHistoryModifyResponse model
   * @this {!Sender}
   * @returns {!ChartBarHistoryModifyResponseModel} ChartBarHistoryModifyResponse model
   */
  get ChartBarHistoryModifyResponseModel () {
    return this._ChartBarHistoryModifyResponseModel
  }

  /**
   * Get ChartBarHistoryRangeRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryRangeRequestModel} ChartBarHistoryRangeRequest model
   */
  get ChartBarHistoryRangeRequestModel () {
    return this._ChartBarHistoryRangeRequestModel
  }

  /**
   * Get ChartBarHistoryLastModifiedRangeRequest model
   * @this {!Sender}
   * @returns {!ChartBarHistoryLastModifiedRangeRequestModel} ChartBarHistoryLastModifiedRangeRequest model
   */
  get ChartBarHistoryLastModifiedRangeRequestModel () {
    return this._ChartBarHistoryLastModifiedRangeRequestModel
  }

  /**
   * Get ChartBarHistoryLastModifiedRangeResponse model
   * @this {!Sender}
   * @returns {!ChartBarHistoryLastModifiedRangeResponseModel} ChartBarHistoryLastModifiedRangeResponse model
   */
  get ChartBarHistoryLastModifiedRangeResponseModel () {
    return this._ChartBarHistoryLastModifiedRangeResponseModel
  }

  /**
   * Get ChartBarModifyNotify model
   * @this {!Sender}
   * @returns {!ChartBarModifyNotifyModel} ChartBarModifyNotify model
   */
  get ChartBarModifyNotifyModel () {
    return this._ChartBarModifyNotifyModel
  }

  /**
   * Get ClientReportsListGetRequest model
   * @this {!Sender}
   * @returns {!ClientReportsListGetRequestModel} ClientReportsListGetRequest model
   */
  get ClientReportsListGetRequestModel () {
    return this._ClientReportsListGetRequestModel
  }

  /**
   * Get ClientReportsListGetResponse model
   * @this {!Sender}
   * @returns {!ClientReportsListGetResponseModel} ClientReportsListGetResponse model
   */
  get ClientReportsListGetResponseModel () {
    return this._ClientReportsListGetResponseModel
  }

  /**
   * Get ClientReportGetRequest model
   * @this {!Sender}
   * @returns {!ClientReportGetRequestModel} ClientReportGetRequest model
   */
  get ClientReportGetRequestModel () {
    return this._ClientReportGetRequestModel
  }

  /**
   * Get ClientReportGetResponse model
   * @this {!Sender}
   * @returns {!ClientReportGetResponseModel} ClientReportGetResponse model
   */
  get ClientReportGetResponseModel () {
    return this._ClientReportGetResponseModel
  }

  /**
   * Get ClientReportsSendRequest model
   * @this {!Sender}
   * @returns {!ClientReportsSendRequestModel} ClientReportsSendRequest model
   */
  get ClientReportsSendRequestModel () {
    return this._ClientReportsSendRequestModel
  }

  /**
   * Get ClientReportsSendResponse model
   * @this {!Sender}
   * @returns {!ClientReportsSendResponseModel} ClientReportsSendResponse model
   */
  get ClientReportsSendResponseModel () {
    return this._ClientReportsSendResponseModel
  }

  /**
   * Get ChartBarsImportRequest model
   * @this {!Sender}
   * @returns {!ChartBarsImportRequestModel} ChartBarsImportRequest model
   */
  get ChartBarsImportRequestModel () {
    return this._ChartBarsImportRequestModel
  }

  /**
   * Get ChartBarsImportResponse model
   * @this {!Sender}
   * @returns {!ChartBarsImportResponseModel} ChartBarsImportResponse model
   */
  get ChartBarsImportResponseModel () {
    return this._ChartBarsImportResponseModel
  }

  /**
   * Get ChartBarsImportNotify model
   * @this {!Sender}
   * @returns {!ChartBarsImportNotifyModel} ChartBarsImportNotify model
   */
  get ChartBarsImportNotifyModel () {
    return this._ChartBarsImportNotifyModel
  }

  /**
   * Get ChartBarsImportFinishedNotify model
   * @this {!Sender}
   * @returns {!ChartBarsImportFinishedNotifyModel} ChartBarsImportFinishedNotify model
   */
  get ChartBarsImportFinishedNotifyModel () {
    return this._ChartBarsImportFinishedNotifyModel
  }

  /**
   * Get ClientReportsGetRequest model
   * @this {!Sender}
   * @returns {!ClientReportsGetRequestModel} ClientReportsGetRequest model
   */
  get ClientReportsGetRequestModel () {
    return this._ClientReportsGetRequestModel
  }

  /**
   * Get ClientReportsGetResponse model
   * @this {!Sender}
   * @returns {!ClientReportsGetResponseModel} ClientReportsGetResponse model
   */
  get ClientReportsGetResponseModel () {
    return this._ClientReportsGetResponseModel
  }

  /**
   * Get ClientReportsInsertRequest model
   * @this {!Sender}
   * @returns {!ClientReportsInsertRequestModel} ClientReportsInsertRequest model
   */
  get ClientReportsInsertRequestModel () {
    return this._ClientReportsInsertRequestModel
  }

  /**
   * Get ClientReportsInsertResponse model
   * @this {!Sender}
   * @returns {!ClientReportsInsertResponseModel} ClientReportsInsertResponse model
   */
  get ClientReportsInsertResponseModel () {
    return this._ClientReportsInsertResponseModel
  }

  /**
   * Get ClientReportsGetLastIdRequest model
   * @this {!Sender}
   * @returns {!ClientReportsGetLastIdRequestModel} ClientReportsGetLastIdRequest model
   */
  get ClientReportsGetLastIdRequestModel () {
    return this._ClientReportsGetLastIdRequestModel
  }

  /**
   * Get ClientReportsGetLastIdResponse model
   * @this {!Sender}
   * @returns {!ClientReportsGetLastIdResponseModel} ClientReportsGetLastIdResponse model
   */
  get ClientReportsGetLastIdResponseModel () {
    return this._ClientReportsGetLastIdResponseModel
  }

  /**
   * Get BlockedIPEventLogCreateParams model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogCreateParamsModel} BlockedIPEventLogCreateParams model
   */
  get BlockedIPEventLogCreateParamsModel () {
    return this._BlockedIPEventLogCreateParamsModel
  }

  /**
   * Get BlockedIPEventLogCreateRequest model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogCreateRequestModel} BlockedIPEventLogCreateRequest model
   */
  get BlockedIPEventLogCreateRequestModel () {
    return this._BlockedIPEventLogCreateRequestModel
  }

  /**
   * Get BlockedIPEventLogCreateResult model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogCreateResultModel} BlockedIPEventLogCreateResult model
   */
  get BlockedIPEventLogCreateResultModel () {
    return this._BlockedIPEventLogCreateResultModel
  }

  /**
   * Get BlockedIPEventLogCreateResponse model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogCreateResponseModel} BlockedIPEventLogCreateResponse model
   */
  get BlockedIPEventLogCreateResponseModel () {
    return this._BlockedIPEventLogCreateResponseModel
  }

  /**
   * Get BlockedIPNotify model
   * @this {!Sender}
   * @returns {!BlockedIPNotifyModel} BlockedIPNotify model
   */
  get BlockedIPNotifyModel () {
    return this._BlockedIPNotifyModel
  }

  /**
   * Get BlockedIPEventLogGetParams model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetParamsModel} BlockedIPEventLogGetParams model
   */
  get BlockedIPEventLogGetParamsModel () {
    return this._BlockedIPEventLogGetParamsModel
  }

  /**
   * Get BlockedIPEventLogGetRequest model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetRequestModel} BlockedIPEventLogGetRequest model
   */
  get BlockedIPEventLogGetRequestModel () {
    return this._BlockedIPEventLogGetRequestModel
  }

  /**
   * Get BlockedIPEventLogGetResult model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetResultModel} BlockedIPEventLogGetResult model
   */
  get BlockedIPEventLogGetResultModel () {
    return this._BlockedIPEventLogGetResultModel
  }

  /**
   * Get BlockedIPEventLogGetResponse model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetResponseModel} BlockedIPEventLogGetResponse model
   */
  get BlockedIPEventLogGetResponseModel () {
    return this._BlockedIPEventLogGetResponseModel
  }

  /**
   * Get BlockedIPEventLogGetFilterParams model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetFilterParamsModel} BlockedIPEventLogGetFilterParams model
   */
  get BlockedIPEventLogGetFilterParamsModel () {
    return this._BlockedIPEventLogGetFilterParamsModel
  }

  /**
   * Get BlockedIPEventLogGetFilterRequest model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetFilterRequestModel} BlockedIPEventLogGetFilterRequest model
   */
  get BlockedIPEventLogGetFilterRequestModel () {
    return this._BlockedIPEventLogGetFilterRequestModel
  }

  /**
   * Get BlockedIPEventLogGetFilterResult model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetFilterResultModel} BlockedIPEventLogGetFilterResult model
   */
  get BlockedIPEventLogGetFilterResultModel () {
    return this._BlockedIPEventLogGetFilterResultModel
  }

  /**
   * Get BlockedIPEventLogGetFilterResponse model
   * @this {!Sender}
   * @returns {!BlockedIPEventLogGetFilterResponseModel} BlockedIPEventLogGetFilterResponse model
   */
  get BlockedIPEventLogGetFilterResponseModel () {
    return this._BlockedIPEventLogGetFilterResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof HistoryVersionRequest) && (value.fbeType === this.HistoryVersionRequestModel.fbeType)) {
      return this.send_HistoryVersionRequest(value)
    }
    if ((value instanceof HistoryVersionResponse) && (value.fbeType === this.HistoryVersionResponseModel.fbeType)) {
      return this.send_HistoryVersionResponse(value)
    }
    if ((value instanceof HistoryUpdateNotify) && (value.fbeType === this.HistoryUpdateNotifyModel.fbeType)) {
      return this.send_HistoryUpdateNotify(value)
    }
    if ((value instanceof ChartBarHistoryRequest) && (value.fbeType === this.ChartBarHistoryRequestModel.fbeType)) {
      return this.send_ChartBarHistoryRequest(value)
    }
    if ((value instanceof ChartBarHistoryResponse) && (value.fbeType === this.ChartBarHistoryResponseModel.fbeType)) {
      return this.send_ChartBarHistoryResponse(value)
    }
    if ((value instanceof ChartBarNotify) && (value.fbeType === this.ChartBarNotifyModel.fbeType)) {
      return this.send_ChartBarNotify(value)
    }
    if ((value instanceof ChartBarUpdateNotify) && (value.fbeType === this.ChartBarUpdateNotifyModel.fbeType)) {
      return this.send_ChartBarUpdateNotify(value)
    }
    if ((value instanceof TickHistoryRequest) && (value.fbeType === this.TickHistoryRequestModel.fbeType)) {
      return this.send_TickHistoryRequest(value)
    }
    if ((value instanceof TickHistoryResponse) && (value.fbeType === this.TickHistoryResponseModel.fbeType)) {
      return this.send_TickHistoryResponse(value)
    }
    if ((value instanceof ChartBarHistoryModifyRequest) && (value.fbeType === this.ChartBarHistoryModifyRequestModel.fbeType)) {
      return this.send_ChartBarHistoryModifyRequest(value)
    }
    if ((value instanceof ChartBarHistoryModifyResponse) && (value.fbeType === this.ChartBarHistoryModifyResponseModel.fbeType)) {
      return this.send_ChartBarHistoryModifyResponse(value)
    }
    if ((value instanceof ChartBarHistoryRangeRequest) && (value.fbeType === this.ChartBarHistoryRangeRequestModel.fbeType)) {
      return this.send_ChartBarHistoryRangeRequest(value)
    }
    if ((value instanceof ChartBarHistoryLastModifiedRangeRequest) && (value.fbeType === this.ChartBarHistoryLastModifiedRangeRequestModel.fbeType)) {
      return this.send_ChartBarHistoryLastModifiedRangeRequest(value)
    }
    if ((value instanceof ChartBarHistoryLastModifiedRangeResponse) && (value.fbeType === this.ChartBarHistoryLastModifiedRangeResponseModel.fbeType)) {
      return this.send_ChartBarHistoryLastModifiedRangeResponse(value)
    }
    if ((value instanceof ChartBarModifyNotify) && (value.fbeType === this.ChartBarModifyNotifyModel.fbeType)) {
      return this.send_ChartBarModifyNotify(value)
    }
    if ((value instanceof ClientReportsListGetRequest) && (value.fbeType === this.ClientReportsListGetRequestModel.fbeType)) {
      return this.send_ClientReportsListGetRequest(value)
    }
    if ((value instanceof ClientReportsListGetResponse) && (value.fbeType === this.ClientReportsListGetResponseModel.fbeType)) {
      return this.send_ClientReportsListGetResponse(value)
    }
    if ((value instanceof ClientReportGetRequest) && (value.fbeType === this.ClientReportGetRequestModel.fbeType)) {
      return this.send_ClientReportGetRequest(value)
    }
    if ((value instanceof ClientReportGetResponse) && (value.fbeType === this.ClientReportGetResponseModel.fbeType)) {
      return this.send_ClientReportGetResponse(value)
    }
    if ((value instanceof ClientReportsSendRequest) && (value.fbeType === this.ClientReportsSendRequestModel.fbeType)) {
      return this.send_ClientReportsSendRequest(value)
    }
    if ((value instanceof ClientReportsSendResponse) && (value.fbeType === this.ClientReportsSendResponseModel.fbeType)) {
      return this.send_ClientReportsSendResponse(value)
    }
    if ((value instanceof ChartBarsImportRequest) && (value.fbeType === this.ChartBarsImportRequestModel.fbeType)) {
      return this.send_ChartBarsImportRequest(value)
    }
    if ((value instanceof ChartBarsImportResponse) && (value.fbeType === this.ChartBarsImportResponseModel.fbeType)) {
      return this.send_ChartBarsImportResponse(value)
    }
    if ((value instanceof ChartBarsImportNotify) && (value.fbeType === this.ChartBarsImportNotifyModel.fbeType)) {
      return this.send_ChartBarsImportNotify(value)
    }
    if ((value instanceof ChartBarsImportFinishedNotify) && (value.fbeType === this.ChartBarsImportFinishedNotifyModel.fbeType)) {
      return this.send_ChartBarsImportFinishedNotify(value)
    }
    if ((value instanceof ClientReportsGetRequest) && (value.fbeType === this.ClientReportsGetRequestModel.fbeType)) {
      return this.send_ClientReportsGetRequest(value)
    }
    if ((value instanceof ClientReportsGetResponse) && (value.fbeType === this.ClientReportsGetResponseModel.fbeType)) {
      return this.send_ClientReportsGetResponse(value)
    }
    if ((value instanceof ClientReportsInsertRequest) && (value.fbeType === this.ClientReportsInsertRequestModel.fbeType)) {
      return this.send_ClientReportsInsertRequest(value)
    }
    if ((value instanceof ClientReportsInsertResponse) && (value.fbeType === this.ClientReportsInsertResponseModel.fbeType)) {
      return this.send_ClientReportsInsertResponse(value)
    }
    if ((value instanceof ClientReportsGetLastIdRequest) && (value.fbeType === this.ClientReportsGetLastIdRequestModel.fbeType)) {
      return this.send_ClientReportsGetLastIdRequest(value)
    }
    if ((value instanceof ClientReportsGetLastIdResponse) && (value.fbeType === this.ClientReportsGetLastIdResponseModel.fbeType)) {
      return this.send_ClientReportsGetLastIdResponse(value)
    }
    if ((value instanceof BlockedIPEventLogCreateParams) && (value.fbeType === this.BlockedIPEventLogCreateParamsModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateParams(value)
    }
    if ((value instanceof BlockedIPEventLogCreateRequest) && (value.fbeType === this.BlockedIPEventLogCreateRequestModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateRequest(value)
    }
    if ((value instanceof BlockedIPEventLogCreateResult) && (value.fbeType === this.BlockedIPEventLogCreateResultModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateResult(value)
    }
    if ((value instanceof BlockedIPEventLogCreateResponse) && (value.fbeType === this.BlockedIPEventLogCreateResponseModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateResponse(value)
    }
    if ((value instanceof BlockedIPNotify) && (value.fbeType === this.BlockedIPNotifyModel.fbeType)) {
      return this.send_BlockedIPNotify(value)
    }
    if ((value instanceof BlockedIPEventLogGetParams) && (value.fbeType === this.BlockedIPEventLogGetParamsModel.fbeType)) {
      return this.send_BlockedIPEventLogGetParams(value)
    }
    if ((value instanceof BlockedIPEventLogGetRequest) && (value.fbeType === this.BlockedIPEventLogGetRequestModel.fbeType)) {
      return this.send_BlockedIPEventLogGetRequest(value)
    }
    if ((value instanceof BlockedIPEventLogGetResult) && (value.fbeType === this.BlockedIPEventLogGetResultModel.fbeType)) {
      return this.send_BlockedIPEventLogGetResult(value)
    }
    if ((value instanceof BlockedIPEventLogGetResponse) && (value.fbeType === this.BlockedIPEventLogGetResponseModel.fbeType)) {
      return this.send_BlockedIPEventLogGetResponse(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterParams) && (value.fbeType === this.BlockedIPEventLogGetFilterParamsModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterParams(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterRequest) && (value.fbeType === this.BlockedIPEventLogGetFilterRequestModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterRequest(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterResult) && (value.fbeType === this.BlockedIPEventLogGetFilterResultModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterResult(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterResponse) && (value.fbeType === this.BlockedIPEventLogGetFilterResponseModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._entitySender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send HistoryVersionRequest value
   * @this {!Sender}
   * @param {!HistoryVersionRequest} value HistoryVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryVersionRequest serialization failed!')
    console.assert(this.HistoryVersionRequestModel.verify(), 'history.HistoryVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HistoryVersionResponse value
   * @this {!Sender}
   * @param {!HistoryVersionResponse} value HistoryVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryVersionResponse serialization failed!')
    console.assert(this.HistoryVersionResponseModel.verify(), 'history.HistoryVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HistoryUpdateNotify value
   * @this {!Sender}
   * @param {!HistoryUpdateNotify} value HistoryUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryUpdateNotify serialization failed!')
    console.assert(this.HistoryUpdateNotifyModel.verify(), 'history.HistoryUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryRequest serialization failed!')
    console.assert(this.ChartBarHistoryRequestModel.verify(), 'history.ChartBarHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryResponse value
   * @this {!Sender}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryResponse serialization failed!')
    console.assert(this.ChartBarHistoryResponseModel.verify(), 'history.ChartBarHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarNotify value
   * @this {!Sender}
   * @param {!ChartBarNotify} value ChartBarNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarNotify serialization failed!')
    console.assert(this.ChartBarNotifyModel.verify(), 'history.ChartBarNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUpdateNotify value
   * @this {!Sender}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarUpdateNotify serialization failed!')
    console.assert(this.ChartBarUpdateNotifyModel.verify(), 'history.ChartBarUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryRequest value
   * @this {!Sender}
   * @param {!TickHistoryRequest} value TickHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.TickHistoryRequest serialization failed!')
    console.assert(this.TickHistoryRequestModel.verify(), 'history.TickHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryResponse value
   * @this {!Sender}
   * @param {!TickHistoryResponse} value TickHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.TickHistoryResponse serialization failed!')
    console.assert(this.TickHistoryResponseModel.verify(), 'history.TickHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryModifyRequest serialization failed!')
    console.assert(this.ChartBarHistoryModifyRequestModel.verify(), 'history.ChartBarHistoryModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyResponse value
   * @this {!Sender}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryModifyResponse serialization failed!')
    console.assert(this.ChartBarHistoryModifyResponseModel.verify(), 'history.ChartBarHistoryModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRangeRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRangeRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryRangeRequestModel.verify(), 'history.ChartBarHistoryRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryLastModifiedRangeRequest value
   * @this {!Sender}
   * @param {!ChartBarHistoryLastModifiedRangeRequest} value ChartBarHistoryLastModifiedRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryLastModifiedRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryLastModifiedRangeRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryLastModifiedRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryLastModifiedRangeRequestModel.verify(), 'history.ChartBarHistoryLastModifiedRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryLastModifiedRangeResponse value
   * @this {!Sender}
   * @param {!ChartBarHistoryLastModifiedRangeResponse} value ChartBarHistoryLastModifiedRangeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryLastModifiedRangeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryLastModifiedRangeResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryLastModifiedRangeResponse serialization failed!')
    console.assert(this.ChartBarHistoryLastModifiedRangeResponseModel.verify(), 'history.ChartBarHistoryLastModifiedRangeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarModifyNotify value
   * @this {!Sender}
   * @param {!ChartBarModifyNotify} value ChartBarModifyNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarModifyNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarModifyNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarModifyNotify serialization failed!')
    console.assert(this.ChartBarModifyNotifyModel.verify(), 'history.ChartBarModifyNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetRequest value
   * @this {!Sender}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsListGetRequest serialization failed!')
    console.assert(this.ClientReportsListGetRequestModel.verify(), 'history.ClientReportsListGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetResponse value
   * @this {!Sender}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsListGetResponse serialization failed!')
    console.assert(this.ClientReportsListGetResponseModel.verify(), 'history.ClientReportsListGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetRequest value
   * @this {!Sender}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportGetRequest serialization failed!')
    console.assert(this.ClientReportGetRequestModel.verify(), 'history.ClientReportGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetResponse value
   * @this {!Sender}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportGetResponse serialization failed!')
    console.assert(this.ClientReportGetResponseModel.verify(), 'history.ClientReportGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendRequest value
   * @this {!Sender}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsSendRequest serialization failed!')
    console.assert(this.ClientReportsSendRequestModel.verify(), 'history.ClientReportsSendRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendResponse value
   * @this {!Sender}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsSendResponse serialization failed!')
    console.assert(this.ClientReportsSendResponseModel.verify(), 'history.ClientReportsSendResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportRequest value
   * @this {!Sender}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportRequest serialization failed!')
    console.assert(this.ChartBarsImportRequestModel.verify(), 'history.ChartBarsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportResponse value
   * @this {!Sender}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportResponse serialization failed!')
    console.assert(this.ChartBarsImportResponseModel.verify(), 'history.ChartBarsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportNotify value
   * @this {!Sender}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportNotify serialization failed!')
    console.assert(this.ChartBarsImportNotifyModel.verify(), 'history.ChartBarsImportNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportFinishedNotify value
   * @this {!Sender}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportFinishedNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportFinishedNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportFinishedNotify serialization failed!')
    console.assert(this.ChartBarsImportFinishedNotifyModel.verify(), 'history.ChartBarsImportFinishedNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetRequest value
   * @this {!Sender}
   * @param {!ClientReportsGetRequest} value ClientReportsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetRequest serialization failed!')
    console.assert(this.ClientReportsGetRequestModel.verify(), 'history.ClientReportsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetResponse value
   * @this {!Sender}
   * @param {!ClientReportsGetResponse} value ClientReportsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetResponse serialization failed!')
    console.assert(this.ClientReportsGetResponseModel.verify(), 'history.ClientReportsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertRequest value
   * @this {!Sender}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsInsertRequest serialization failed!')
    console.assert(this.ClientReportsInsertRequestModel.verify(), 'history.ClientReportsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertResponse value
   * @this {!Sender}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsInsertResponse serialization failed!')
    console.assert(this.ClientReportsInsertResponseModel.verify(), 'history.ClientReportsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdRequest value
   * @this {!Sender}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetLastIdRequest serialization failed!')
    console.assert(this.ClientReportsGetLastIdRequestModel.verify(), 'history.ClientReportsGetLastIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdResponse value
   * @this {!Sender}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetLastIdResponse serialization failed!')
    console.assert(this.ClientReportsGetLastIdResponseModel.verify(), 'history.ClientReportsGetLastIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateParams value
   * @this {!Sender}
   * @param {!BlockedIPEventLogCreateParams} value BlockedIPEventLogCreateParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateParamsModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateParams serialization failed!')
    console.assert(this.BlockedIPEventLogCreateParamsModel.verify(), 'history.BlockedIPEventLogCreateParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateRequest value
   * @this {!Sender}
   * @param {!BlockedIPEventLogCreateRequest} value BlockedIPEventLogCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateRequest serialization failed!')
    console.assert(this.BlockedIPEventLogCreateRequestModel.verify(), 'history.BlockedIPEventLogCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateResult value
   * @this {!Sender}
   * @param {!BlockedIPEventLogCreateResult} value BlockedIPEventLogCreateResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateResultModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateResult serialization failed!')
    console.assert(this.BlockedIPEventLogCreateResultModel.verify(), 'history.BlockedIPEventLogCreateResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateResponse value
   * @this {!Sender}
   * @param {!BlockedIPEventLogCreateResponse} value BlockedIPEventLogCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateResponse serialization failed!')
    console.assert(this.BlockedIPEventLogCreateResponseModel.verify(), 'history.BlockedIPEventLogCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPNotify value
   * @this {!Sender}
   * @param {!BlockedIPNotify} value BlockedIPNotify value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPNotifyModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPNotify serialization failed!')
    console.assert(this.BlockedIPNotifyModel.verify(), 'history.BlockedIPNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetParams value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetParams} value BlockedIPEventLogGetParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetParamsModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetParams serialization failed!')
    console.assert(this.BlockedIPEventLogGetParamsModel.verify(), 'history.BlockedIPEventLogGetParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetRequest value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetRequest} value BlockedIPEventLogGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetRequest serialization failed!')
    console.assert(this.BlockedIPEventLogGetRequestModel.verify(), 'history.BlockedIPEventLogGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetResult value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetResult} value BlockedIPEventLogGetResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetResultModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetResult serialization failed!')
    console.assert(this.BlockedIPEventLogGetResultModel.verify(), 'history.BlockedIPEventLogGetResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetResponse value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetResponse} value BlockedIPEventLogGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetResponse serialization failed!')
    console.assert(this.BlockedIPEventLogGetResponseModel.verify(), 'history.BlockedIPEventLogGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterParams value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetFilterParams} value BlockedIPEventLogGetFilterParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterParamsModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterParams serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterParamsModel.verify(), 'history.BlockedIPEventLogGetFilterParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterRequest value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetFilterRequest} value BlockedIPEventLogGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterRequest serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterRequestModel.verify(), 'history.BlockedIPEventLogGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterResult value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetFilterResult} value BlockedIPEventLogGetFilterResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterResultModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterResult serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterResultModel.verify(), 'history.BlockedIPEventLogGetFilterResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterResponse value
   * @this {!Sender}
   * @param {!BlockedIPEventLogGetFilterResponse} value BlockedIPEventLogGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterResponse serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterResponseModel.verify(), 'history.BlockedIPEventLogGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'history.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._entitySender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._entitySender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding history receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize history receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._entityReceiver = new entity.Receiver(this.buffer)
    this._HistoryVersionRequestValue = new HistoryVersionRequest()
    this._HistoryVersionRequestModel = new HistoryVersionRequestModel()
    this._HistoryVersionResponseValue = new HistoryVersionResponse()
    this._HistoryVersionResponseModel = new HistoryVersionResponseModel()
    this._HistoryUpdateNotifyValue = new HistoryUpdateNotify()
    this._HistoryUpdateNotifyModel = new HistoryUpdateNotifyModel()
    this._ChartBarHistoryRequestValue = new ChartBarHistoryRequest()
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseValue = new ChartBarHistoryResponse()
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel()
    this._ChartBarNotifyValue = new ChartBarNotify()
    this._ChartBarNotifyModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifyValue = new ChartBarUpdateNotify()
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel()
    this._TickHistoryRequestValue = new TickHistoryRequest()
    this._TickHistoryRequestModel = new TickHistoryRequestModel()
    this._TickHistoryResponseValue = new TickHistoryResponse()
    this._TickHistoryResponseModel = new TickHistoryResponseModel()
    this._ChartBarHistoryModifyRequestValue = new ChartBarHistoryModifyRequest()
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseValue = new ChartBarHistoryModifyResponse()
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel()
    this._ChartBarHistoryRangeRequestValue = new ChartBarHistoryRangeRequest()
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeRequestValue = new ChartBarHistoryLastModifiedRangeRequest()
    this._ChartBarHistoryLastModifiedRangeRequestModel = new ChartBarHistoryLastModifiedRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeResponseValue = new ChartBarHistoryLastModifiedRangeResponse()
    this._ChartBarHistoryLastModifiedRangeResponseModel = new ChartBarHistoryLastModifiedRangeResponseModel()
    this._ChartBarModifyNotifyValue = new ChartBarModifyNotify()
    this._ChartBarModifyNotifyModel = new ChartBarModifyNotifyModel()
    this._ClientReportsListGetRequestValue = new ClientReportsListGetRequest()
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseValue = new ClientReportsListGetResponse()
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestValue = new ClientReportGetRequest()
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseValue = new ClientReportGetResponse()
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestValue = new ClientReportsSendRequest()
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseValue = new ClientReportsSendResponse()
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel()
    this._ChartBarsImportRequestValue = new ChartBarsImportRequest()
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseValue = new ChartBarsImportResponse()
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifyValue = new ChartBarsImportNotify()
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel()
    this._ChartBarsImportFinishedNotifyValue = new ChartBarsImportFinishedNotify()
    this._ChartBarsImportFinishedNotifyModel = new ChartBarsImportFinishedNotifyModel()
    this._ClientReportsGetRequestValue = new ClientReportsGetRequest()
    this._ClientReportsGetRequestModel = new ClientReportsGetRequestModel()
    this._ClientReportsGetResponseValue = new ClientReportsGetResponse()
    this._ClientReportsGetResponseModel = new ClientReportsGetResponseModel()
    this._ClientReportsInsertRequestValue = new ClientReportsInsertRequest()
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseValue = new ClientReportsInsertResponse()
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestValue = new ClientReportsGetLastIdRequest()
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseValue = new ClientReportsGetLastIdResponse()
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel()
    this._BlockedIPEventLogCreateParamsValue = new BlockedIPEventLogCreateParams()
    this._BlockedIPEventLogCreateParamsModel = new BlockedIPEventLogCreateParamsModel()
    this._BlockedIPEventLogCreateRequestValue = new BlockedIPEventLogCreateRequest()
    this._BlockedIPEventLogCreateRequestModel = new BlockedIPEventLogCreateRequestModel()
    this._BlockedIPEventLogCreateResultValue = new BlockedIPEventLogCreateResult()
    this._BlockedIPEventLogCreateResultModel = new BlockedIPEventLogCreateResultModel()
    this._BlockedIPEventLogCreateResponseValue = new BlockedIPEventLogCreateResponse()
    this._BlockedIPEventLogCreateResponseModel = new BlockedIPEventLogCreateResponseModel()
    this._BlockedIPNotifyValue = new BlockedIPNotify()
    this._BlockedIPNotifyModel = new BlockedIPNotifyModel()
    this._BlockedIPEventLogGetParamsValue = new BlockedIPEventLogGetParams()
    this._BlockedIPEventLogGetParamsModel = new BlockedIPEventLogGetParamsModel()
    this._BlockedIPEventLogGetRequestValue = new BlockedIPEventLogGetRequest()
    this._BlockedIPEventLogGetRequestModel = new BlockedIPEventLogGetRequestModel()
    this._BlockedIPEventLogGetResultValue = new BlockedIPEventLogGetResult()
    this._BlockedIPEventLogGetResultModel = new BlockedIPEventLogGetResultModel()
    this._BlockedIPEventLogGetResponseValue = new BlockedIPEventLogGetResponse()
    this._BlockedIPEventLogGetResponseModel = new BlockedIPEventLogGetResponseModel()
    this._BlockedIPEventLogGetFilterParamsValue = new BlockedIPEventLogGetFilterParams()
    this._BlockedIPEventLogGetFilterParamsModel = new BlockedIPEventLogGetFilterParamsModel()
    this._BlockedIPEventLogGetFilterRequestValue = new BlockedIPEventLogGetFilterRequest()
    this._BlockedIPEventLogGetFilterRequestModel = new BlockedIPEventLogGetFilterRequestModel()
    this._BlockedIPEventLogGetFilterResultValue = new BlockedIPEventLogGetFilterResult()
    this._BlockedIPEventLogGetFilterResultModel = new BlockedIPEventLogGetFilterResultModel()
    this._BlockedIPEventLogGetFilterResponseValue = new BlockedIPEventLogGetFilterResponse()
    this._BlockedIPEventLogGetFilterResponseModel = new BlockedIPEventLogGetFilterResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported entity receiver
   * @this {!Receiver}
   * @returns {Receiver} entity receiver
   */
  get entityReceiver () {
    return this._entityReceiver
  }

  /**
   * Set imported entity receiver
   * @this {!Receiver}
   * @param {Receiver} receiver entity receiver
   */
  set entityReceiver (receiver) {
    this._entityReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * HistoryVersionRequest receive handler
   * @this {!Receiver}
   * @param {!HistoryVersionRequest} value HistoryVersionRequest received value
   */
  onReceive_HistoryVersionRequest (value) {}  // eslint-disable-line

  /**
   * HistoryVersionResponse receive handler
   * @this {!Receiver}
   * @param {!HistoryVersionResponse} value HistoryVersionResponse received value
   */
  onReceive_HistoryVersionResponse (value) {}  // eslint-disable-line

  /**
   * HistoryUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!HistoryUpdateNotify} value HistoryUpdateNotify received value
   */
  onReceive_HistoryUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest received value
   */
  onReceive_ChartBarHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse received value
   */
  onReceive_ChartBarHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarNotify} value ChartBarNotify received value
   */
  onReceive_ChartBarNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify received value
   */
  onReceive_ChartBarUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!TickHistoryRequest} value TickHistoryRequest received value
   */
  onReceive_TickHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TickHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!TickHistoryResponse} value TickHistoryResponse received value
   */
  onReceive_TickHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest received value
   */
  onReceive_ChartBarHistoryModifyRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse received value
   */
  onReceive_ChartBarHistoryModifyResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest received value
   */
  onReceive_ChartBarHistoryRangeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryLastModifiedRangeRequest} value ChartBarHistoryLastModifiedRangeRequest received value
   */
  onReceive_ChartBarHistoryLastModifiedRangeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarHistoryLastModifiedRangeResponse} value ChartBarHistoryLastModifiedRangeResponse received value
   */
  onReceive_ChartBarHistoryLastModifiedRangeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarModifyNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarModifyNotify} value ChartBarModifyNotify received value
   */
  onReceive_ChartBarModifyNotify (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest received value
   */
  onReceive_ClientReportsListGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse received value
   */
  onReceive_ClientReportsListGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest received value
   */
  onReceive_ClientReportGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse received value
   */
  onReceive_ClientReportGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest received value
   */
  onReceive_ClientReportsSendRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse received value
   */
  onReceive_ClientReportsSendResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest received value
   */
  onReceive_ChartBarsImportRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse received value
   */
  onReceive_ChartBarsImportResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify received value
   */
  onReceive_ChartBarsImportNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportFinishedNotify receive handler
   * @this {!Receiver}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify received value
   */
  onReceive_ChartBarsImportFinishedNotify (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetRequest} value ClientReportsGetRequest received value
   */
  onReceive_ClientReportsGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetResponse} value ClientReportsGetResponse received value
   */
  onReceive_ClientReportsGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest received value
   */
  onReceive_ClientReportsInsertRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse received value
   */
  onReceive_ClientReportsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest received value
   */
  onReceive_ClientReportsGetLastIdRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse received value
   */
  onReceive_ClientReportsGetLastIdResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateParams receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogCreateParams} value BlockedIPEventLogCreateParams received value
   */
  onReceive_BlockedIPEventLogCreateParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateRequest receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogCreateRequest} value BlockedIPEventLogCreateRequest received value
   */
  onReceive_BlockedIPEventLogCreateRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResult receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogCreateResult} value BlockedIPEventLogCreateResult received value
   */
  onReceive_BlockedIPEventLogCreateResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResponse receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogCreateResponse} value BlockedIPEventLogCreateResponse received value
   */
  onReceive_BlockedIPEventLogCreateResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPNotify receive handler
   * @this {!Receiver}
   * @param {!BlockedIPNotify} value BlockedIPNotify received value
   */
  onReceive_BlockedIPNotify (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetParams receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetParams} value BlockedIPEventLogGetParams received value
   */
  onReceive_BlockedIPEventLogGetParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetRequest receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetRequest} value BlockedIPEventLogGetRequest received value
   */
  onReceive_BlockedIPEventLogGetRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResult receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetResult} value BlockedIPEventLogGetResult received value
   */
  onReceive_BlockedIPEventLogGetResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResponse receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetResponse} value BlockedIPEventLogGetResponse received value
   */
  onReceive_BlockedIPEventLogGetResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterParams receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetFilterParams} value BlockedIPEventLogGetFilterParams received value
   */
  onReceive_BlockedIPEventLogGetFilterParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterRequest receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetFilterRequest} value BlockedIPEventLogGetFilterRequest received value
   */
  onReceive_BlockedIPEventLogGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResult receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetFilterResult} value BlockedIPEventLogGetFilterResult received value
   */
  onReceive_BlockedIPEventLogGetFilterResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResponse receive handler
   * @this {!Receiver}
   * @param {!BlockedIPEventLogGetFilterResponse} value BlockedIPEventLogGetFilterResponse received value
   */
  onReceive_BlockedIPEventLogGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * history receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case HistoryVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionRequestModel.verify(), 'history.HistoryVersionRequest validation failed!')
        let deserialized = this._HistoryVersionRequestModel.deserialize(this._HistoryVersionRequestValue)
        console.assert((deserialized.size > 0), 'history.HistoryVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryVersionRequestValue)
        this.onReceive_HistoryVersionRequest(this._HistoryVersionRequestValue)
        return true
      }
      case HistoryVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionResponseModel.verify(), 'history.HistoryVersionResponse validation failed!')
        let deserialized = this._HistoryVersionResponseModel.deserialize(this._HistoryVersionResponseValue)
        console.assert((deserialized.size > 0), 'history.HistoryVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryVersionResponseValue)
        this.onReceive_HistoryVersionResponse(this._HistoryVersionResponseValue)
        return true
      }
      case HistoryUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryUpdateNotifyModel.verify(), 'history.HistoryUpdateNotify validation failed!')
        let deserialized = this._HistoryUpdateNotifyModel.deserialize(this._HistoryUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'history.HistoryUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryUpdateNotifyValue)
        this.onReceive_HistoryUpdateNotify(this._HistoryUpdateNotifyValue)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestModel.verify(), 'history.ChartBarHistoryRequest validation failed!')
        let deserialized = this._ChartBarHistoryRequestModel.deserialize(this._ChartBarHistoryRequestValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRequestValue)
        this.onReceive_ChartBarHistoryRequest(this._ChartBarHistoryRequestValue)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseModel.verify(), 'history.ChartBarHistoryResponse validation failed!')
        let deserialized = this._ChartBarHistoryResponseModel.deserialize(this._ChartBarHistoryResponseValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryResponseValue)
        this.onReceive_ChartBarHistoryResponse(this._ChartBarHistoryResponseValue)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyModel.verify(), 'history.ChartBarNotify validation failed!')
        let deserialized = this._ChartBarNotifyModel.deserialize(this._ChartBarNotifyValue)
        console.assert((deserialized.size > 0), 'history.ChartBarNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarNotifyValue)
        this.onReceive_ChartBarNotify(this._ChartBarNotifyValue)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyModel.verify(), 'history.ChartBarUpdateNotify validation failed!')
        let deserialized = this._ChartBarUpdateNotifyModel.deserialize(this._ChartBarUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'history.ChartBarUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUpdateNotifyValue)
        this.onReceive_ChartBarUpdateNotify(this._ChartBarUpdateNotifyValue)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestModel.verify(), 'history.TickHistoryRequest validation failed!')
        let deserialized = this._TickHistoryRequestModel.deserialize(this._TickHistoryRequestValue)
        console.assert((deserialized.size > 0), 'history.TickHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryRequestValue)
        this.onReceive_TickHistoryRequest(this._TickHistoryRequestValue)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseModel.verify(), 'history.TickHistoryResponse validation failed!')
        let deserialized = this._TickHistoryResponseModel.deserialize(this._TickHistoryResponseValue)
        console.assert((deserialized.size > 0), 'history.TickHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryResponseValue)
        this.onReceive_TickHistoryResponse(this._TickHistoryResponseValue)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestModel.verify(), 'history.ChartBarHistoryModifyRequest validation failed!')
        let deserialized = this._ChartBarHistoryModifyRequestModel.deserialize(this._ChartBarHistoryModifyRequestValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyRequestValue)
        this.onReceive_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestValue)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseModel.verify(), 'history.ChartBarHistoryModifyResponse validation failed!')
        let deserialized = this._ChartBarHistoryModifyResponseModel.deserialize(this._ChartBarHistoryModifyResponseValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyResponseValue)
        this.onReceive_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseValue)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestModel.verify(), 'history.ChartBarHistoryRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryRangeRequestModel.deserialize(this._ChartBarHistoryRangeRequestValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRangeRequestValue)
        this.onReceive_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestValue)
        return true
      }
      case ChartBarHistoryLastModifiedRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryLastModifiedRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeRequestModel.verify(), 'history.ChartBarHistoryLastModifiedRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryLastModifiedRangeRequestModel.deserialize(this._ChartBarHistoryLastModifiedRangeRequestValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryLastModifiedRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryLastModifiedRangeRequestValue)
        this.onReceive_ChartBarHistoryLastModifiedRangeRequest(this._ChartBarHistoryLastModifiedRangeRequestValue)
        return true
      }
      case ChartBarHistoryLastModifiedRangeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryLastModifiedRangeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeResponseModel.verify(), 'history.ChartBarHistoryLastModifiedRangeResponse validation failed!')
        let deserialized = this._ChartBarHistoryLastModifiedRangeResponseModel.deserialize(this._ChartBarHistoryLastModifiedRangeResponseValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryLastModifiedRangeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryLastModifiedRangeResponseValue)
        this.onReceive_ChartBarHistoryLastModifiedRangeResponse(this._ChartBarHistoryLastModifiedRangeResponseValue)
        return true
      }
      case ChartBarModifyNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarModifyNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarModifyNotifyModel.verify(), 'history.ChartBarModifyNotify validation failed!')
        let deserialized = this._ChartBarModifyNotifyModel.deserialize(this._ChartBarModifyNotifyValue)
        console.assert((deserialized.size > 0), 'history.ChartBarModifyNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarModifyNotifyValue)
        this.onReceive_ChartBarModifyNotify(this._ChartBarModifyNotifyValue)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestModel.verify(), 'history.ClientReportsListGetRequest validation failed!')
        let deserialized = this._ClientReportsListGetRequestModel.deserialize(this._ClientReportsListGetRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsListGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetRequestValue)
        this.onReceive_ClientReportsListGetRequest(this._ClientReportsListGetRequestValue)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseModel.verify(), 'history.ClientReportsListGetResponse validation failed!')
        let deserialized = this._ClientReportsListGetResponseModel.deserialize(this._ClientReportsListGetResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsListGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetResponseValue)
        this.onReceive_ClientReportsListGetResponse(this._ClientReportsListGetResponseValue)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestModel.verify(), 'history.ClientReportGetRequest validation failed!')
        let deserialized = this._ClientReportGetRequestModel.deserialize(this._ClientReportGetRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetRequestValue)
        this.onReceive_ClientReportGetRequest(this._ClientReportGetRequestValue)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseModel.verify(), 'history.ClientReportGetResponse validation failed!')
        let deserialized = this._ClientReportGetResponseModel.deserialize(this._ClientReportGetResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetResponseValue)
        this.onReceive_ClientReportGetResponse(this._ClientReportGetResponseValue)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestModel.verify(), 'history.ClientReportsSendRequest validation failed!')
        let deserialized = this._ClientReportsSendRequestModel.deserialize(this._ClientReportsSendRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsSendRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendRequestValue)
        this.onReceive_ClientReportsSendRequest(this._ClientReportsSendRequestValue)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseModel.verify(), 'history.ClientReportsSendResponse validation failed!')
        let deserialized = this._ClientReportsSendResponseModel.deserialize(this._ClientReportsSendResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsSendResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendResponseValue)
        this.onReceive_ClientReportsSendResponse(this._ClientReportsSendResponseValue)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestModel.verify(), 'history.ChartBarsImportRequest validation failed!')
        let deserialized = this._ChartBarsImportRequestModel.deserialize(this._ChartBarsImportRequestValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportRequestValue)
        this.onReceive_ChartBarsImportRequest(this._ChartBarsImportRequestValue)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseModel.verify(), 'history.ChartBarsImportResponse validation failed!')
        let deserialized = this._ChartBarsImportResponseModel.deserialize(this._ChartBarsImportResponseValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportResponseValue)
        this.onReceive_ChartBarsImportResponse(this._ChartBarsImportResponseValue)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyModel.verify(), 'history.ChartBarsImportNotify validation failed!')
        let deserialized = this._ChartBarsImportNotifyModel.deserialize(this._ChartBarsImportNotifyValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportNotifyValue)
        this.onReceive_ChartBarsImportNotify(this._ChartBarsImportNotifyValue)
        return true
      }
      case ChartBarsImportFinishedNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportFinishedNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportFinishedNotifyModel.verify(), 'history.ChartBarsImportFinishedNotify validation failed!')
        let deserialized = this._ChartBarsImportFinishedNotifyModel.deserialize(this._ChartBarsImportFinishedNotifyValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportFinishedNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportFinishedNotifyValue)
        this.onReceive_ChartBarsImportFinishedNotify(this._ChartBarsImportFinishedNotifyValue)
        return true
      }
      case ClientReportsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetRequestModel.verify(), 'history.ClientReportsGetRequest validation failed!')
        let deserialized = this._ClientReportsGetRequestModel.deserialize(this._ClientReportsGetRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetRequestValue)
        this.onReceive_ClientReportsGetRequest(this._ClientReportsGetRequestValue)
        return true
      }
      case ClientReportsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetResponseModel.verify(), 'history.ClientReportsGetResponse validation failed!')
        let deserialized = this._ClientReportsGetResponseModel.deserialize(this._ClientReportsGetResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetResponseValue)
        this.onReceive_ClientReportsGetResponse(this._ClientReportsGetResponseValue)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestModel.verify(), 'history.ClientReportsInsertRequest validation failed!')
        let deserialized = this._ClientReportsInsertRequestModel.deserialize(this._ClientReportsInsertRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertRequestValue)
        this.onReceive_ClientReportsInsertRequest(this._ClientReportsInsertRequestValue)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseModel.verify(), 'history.ClientReportsInsertResponse validation failed!')
        let deserialized = this._ClientReportsInsertResponseModel.deserialize(this._ClientReportsInsertResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertResponseValue)
        this.onReceive_ClientReportsInsertResponse(this._ClientReportsInsertResponseValue)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestModel.verify(), 'history.ClientReportsGetLastIdRequest validation failed!')
        let deserialized = this._ClientReportsGetLastIdRequestModel.deserialize(this._ClientReportsGetLastIdRequestValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetLastIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdRequestValue)
        this.onReceive_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestValue)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseModel.verify(), 'history.ClientReportsGetLastIdResponse validation failed!')
        let deserialized = this._ClientReportsGetLastIdResponseModel.deserialize(this._ClientReportsGetLastIdResponseValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetLastIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdResponseValue)
        this.onReceive_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseValue)
        return true
      }
      case BlockedIPEventLogCreateParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateParamsModel.verify(), 'history.BlockedIPEventLogCreateParams validation failed!')
        let deserialized = this._BlockedIPEventLogCreateParamsModel.deserialize(this._BlockedIPEventLogCreateParamsValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateParamsValue)
        this.onReceive_BlockedIPEventLogCreateParams(this._BlockedIPEventLogCreateParamsValue)
        return true
      }
      case BlockedIPEventLogCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateRequestModel.verify(), 'history.BlockedIPEventLogCreateRequest validation failed!')
        let deserialized = this._BlockedIPEventLogCreateRequestModel.deserialize(this._BlockedIPEventLogCreateRequestValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateRequestValue)
        this.onReceive_BlockedIPEventLogCreateRequest(this._BlockedIPEventLogCreateRequestValue)
        return true
      }
      case BlockedIPEventLogCreateResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResultModel.verify(), 'history.BlockedIPEventLogCreateResult validation failed!')
        let deserialized = this._BlockedIPEventLogCreateResultModel.deserialize(this._BlockedIPEventLogCreateResultValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateResultValue)
        this.onReceive_BlockedIPEventLogCreateResult(this._BlockedIPEventLogCreateResultValue)
        return true
      }
      case BlockedIPEventLogCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResponseModel.verify(), 'history.BlockedIPEventLogCreateResponse validation failed!')
        let deserialized = this._BlockedIPEventLogCreateResponseModel.deserialize(this._BlockedIPEventLogCreateResponseValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateResponseValue)
        this.onReceive_BlockedIPEventLogCreateResponse(this._BlockedIPEventLogCreateResponseValue)
        return true
      }
      case BlockedIPNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPNotifyModel.verify(), 'history.BlockedIPNotify validation failed!')
        let deserialized = this._BlockedIPNotifyModel.deserialize(this._BlockedIPNotifyValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPNotifyValue)
        this.onReceive_BlockedIPNotify(this._BlockedIPNotifyValue)
        return true
      }
      case BlockedIPEventLogGetParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetParamsModel.verify(), 'history.BlockedIPEventLogGetParams validation failed!')
        let deserialized = this._BlockedIPEventLogGetParamsModel.deserialize(this._BlockedIPEventLogGetParamsValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetParamsValue)
        this.onReceive_BlockedIPEventLogGetParams(this._BlockedIPEventLogGetParamsValue)
        return true
      }
      case BlockedIPEventLogGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetRequestModel.verify(), 'history.BlockedIPEventLogGetRequest validation failed!')
        let deserialized = this._BlockedIPEventLogGetRequestModel.deserialize(this._BlockedIPEventLogGetRequestValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetRequestValue)
        this.onReceive_BlockedIPEventLogGetRequest(this._BlockedIPEventLogGetRequestValue)
        return true
      }
      case BlockedIPEventLogGetResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResultModel.verify(), 'history.BlockedIPEventLogGetResult validation failed!')
        let deserialized = this._BlockedIPEventLogGetResultModel.deserialize(this._BlockedIPEventLogGetResultValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetResultValue)
        this.onReceive_BlockedIPEventLogGetResult(this._BlockedIPEventLogGetResultValue)
        return true
      }
      case BlockedIPEventLogGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResponseModel.verify(), 'history.BlockedIPEventLogGetResponse validation failed!')
        let deserialized = this._BlockedIPEventLogGetResponseModel.deserialize(this._BlockedIPEventLogGetResponseValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetResponseValue)
        this.onReceive_BlockedIPEventLogGetResponse(this._BlockedIPEventLogGetResponseValue)
        return true
      }
      case BlockedIPEventLogGetFilterParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterParamsModel.verify(), 'history.BlockedIPEventLogGetFilterParams validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterParamsModel.deserialize(this._BlockedIPEventLogGetFilterParamsValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterParamsValue)
        this.onReceive_BlockedIPEventLogGetFilterParams(this._BlockedIPEventLogGetFilterParamsValue)
        return true
      }
      case BlockedIPEventLogGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterRequestModel.verify(), 'history.BlockedIPEventLogGetFilterRequest validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterRequestModel.deserialize(this._BlockedIPEventLogGetFilterRequestValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterRequestValue)
        this.onReceive_BlockedIPEventLogGetFilterRequest(this._BlockedIPEventLogGetFilterRequestValue)
        return true
      }
      case BlockedIPEventLogGetFilterResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResultModel.verify(), 'history.BlockedIPEventLogGetFilterResult validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterResultModel.deserialize(this._BlockedIPEventLogGetFilterResultValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterResultValue)
        this.onReceive_BlockedIPEventLogGetFilterResult(this._BlockedIPEventLogGetFilterResultValue)
        return true
      }
      case BlockedIPEventLogGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResponseModel.verify(), 'history.BlockedIPEventLogGetFilterResponse validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterResponseModel.deserialize(this._BlockedIPEventLogGetFilterResponseValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterResponseValue)
        this.onReceive_BlockedIPEventLogGetFilterResponse(this._BlockedIPEventLogGetFilterResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityReceiver != null) && this.entityReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._entityReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding history proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize history proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._entityProxy = new entity.Proxy(this.buffer)
    this._HistoryVersionRequestModel = new HistoryVersionRequestModel()
    this._HistoryVersionResponseModel = new HistoryVersionResponseModel()
    this._HistoryUpdateNotifyModel = new HistoryUpdateNotifyModel()
    this._ChartBarHistoryRequestModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseModel = new ChartBarHistoryResponseModel()
    this._ChartBarNotifyModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifyModel = new ChartBarUpdateNotifyModel()
    this._TickHistoryRequestModel = new TickHistoryRequestModel()
    this._TickHistoryResponseModel = new TickHistoryResponseModel()
    this._ChartBarHistoryModifyRequestModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseModel = new ChartBarHistoryModifyResponseModel()
    this._ChartBarHistoryRangeRequestModel = new ChartBarHistoryRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeRequestModel = new ChartBarHistoryLastModifiedRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeResponseModel = new ChartBarHistoryLastModifiedRangeResponseModel()
    this._ChartBarModifyNotifyModel = new ChartBarModifyNotifyModel()
    this._ClientReportsListGetRequestModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseModel = new ClientReportsSendResponseModel()
    this._ChartBarsImportRequestModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifyModel = new ChartBarsImportNotifyModel()
    this._ChartBarsImportFinishedNotifyModel = new ChartBarsImportFinishedNotifyModel()
    this._ClientReportsGetRequestModel = new ClientReportsGetRequestModel()
    this._ClientReportsGetResponseModel = new ClientReportsGetResponseModel()
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel()
    this._BlockedIPEventLogCreateParamsModel = new BlockedIPEventLogCreateParamsModel()
    this._BlockedIPEventLogCreateRequestModel = new BlockedIPEventLogCreateRequestModel()
    this._BlockedIPEventLogCreateResultModel = new BlockedIPEventLogCreateResultModel()
    this._BlockedIPEventLogCreateResponseModel = new BlockedIPEventLogCreateResponseModel()
    this._BlockedIPNotifyModel = new BlockedIPNotifyModel()
    this._BlockedIPEventLogGetParamsModel = new BlockedIPEventLogGetParamsModel()
    this._BlockedIPEventLogGetRequestModel = new BlockedIPEventLogGetRequestModel()
    this._BlockedIPEventLogGetResultModel = new BlockedIPEventLogGetResultModel()
    this._BlockedIPEventLogGetResponseModel = new BlockedIPEventLogGetResponseModel()
    this._BlockedIPEventLogGetFilterParamsModel = new BlockedIPEventLogGetFilterParamsModel()
    this._BlockedIPEventLogGetFilterRequestModel = new BlockedIPEventLogGetFilterRequestModel()
    this._BlockedIPEventLogGetFilterResultModel = new BlockedIPEventLogGetFilterResultModel()
    this._BlockedIPEventLogGetFilterResponseModel = new BlockedIPEventLogGetFilterResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported entity proxy
   * @this {!Proxy}
   * @returns {Proxy} entity proxy
   */
  get entityProxy () {
    return this._entityProxy
  }

  /**
   * Set imported entity proxy
   * @this {!Proxy}
   * @param {Proxy} proxy entity proxy
   */
  set entityProxy (proxy) {
    this._entityProxy = proxy
  }

  // Proxy handlers

  /**
   * HistoryVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!HistoryVersionRequest} model HistoryVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HistoryVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HistoryVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!HistoryVersionResponse} model HistoryVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HistoryVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * HistoryUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!HistoryUpdateNotify} model HistoryUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_HistoryUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryRequest} model ChartBarHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryResponse} model ChartBarHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarNotify} model ChartBarNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarUpdateNotify} model ChartBarUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!TickHistoryRequest} model TickHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!TickHistoryResponse} model TickHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryModifyRequest} model ChartBarHistoryModifyRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryModifyRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryModifyResponse} model ChartBarHistoryModifyResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryModifyResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryRangeRequest} model ChartBarHistoryRangeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryRangeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryLastModifiedRangeRequest} model ChartBarHistoryLastModifiedRangeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryLastModifiedRangeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarHistoryLastModifiedRangeResponse} model ChartBarHistoryLastModifiedRangeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarHistoryLastModifiedRangeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarModifyNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarModifyNotify} model ChartBarModifyNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarModifyNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsListGetRequest} model ClientReportsListGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsListGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsListGetResponse} model ClientReportsListGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsListGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportGetRequest} model ClientReportGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportGetResponse} model ClientReportGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsSendRequest} model ClientReportsSendRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsSendRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsSendResponse} model ClientReportsSendResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsSendResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportRequest} model ChartBarsImportRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportResponse} model ChartBarsImportResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportNotify} model ChartBarsImportNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ChartBarsImportFinishedNotify proxy handler
   * @this {!Proxy}
   * @param {!ChartBarsImportFinishedNotify} model ChartBarsImportFinishedNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ChartBarsImportFinishedNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetRequest} model ClientReportsGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetResponse} model ClientReportsGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsInsertRequest} model ClientReportsInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsInsertResponse} model ClientReportsInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetLastIdRequest} model ClientReportsGetLastIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetLastIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetLastIdResponse} model ClientReportsGetLastIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetLastIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateParams proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogCreateParams} model BlockedIPEventLogCreateParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogCreateParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogCreateRequest} model BlockedIPEventLogCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResult proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogCreateResult} model BlockedIPEventLogCreateResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogCreateResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogCreateResponse} model BlockedIPEventLogCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPNotify proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPNotify} model BlockedIPNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetParams proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetParams} model BlockedIPEventLogGetParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetRequest proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetRequest} model BlockedIPEventLogGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResult proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetResult} model BlockedIPEventLogGetResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResponse proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetResponse} model BlockedIPEventLogGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterParams proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetFilterParams} model BlockedIPEventLogGetFilterParams model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetFilterParams (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetFilterRequest} model BlockedIPEventLogGetFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResult proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetFilterResult} model BlockedIPEventLogGetFilterResult model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetFilterResult (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResponse proxy handler
   * @this {!Proxy}
   * @param {!BlockedIPEventLogGetFilterResponse} model BlockedIPEventLogGetFilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BlockedIPEventLogGetFilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * history receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case HistoryVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HistoryVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionRequestModel.verify(), 'history.HistoryVersionRequest validation failed!')

        let fbeBegin = this._HistoryVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HistoryVersionRequest(this._HistoryVersionRequestModel, type, buffer, offset, size)
        this._HistoryVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case HistoryVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HistoryVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionResponseModel.verify(), 'history.HistoryVersionResponse validation failed!')

        let fbeBegin = this._HistoryVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HistoryVersionResponse(this._HistoryVersionResponseModel, type, buffer, offset, size)
        this._HistoryVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case HistoryUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._HistoryUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryUpdateNotifyModel.verify(), 'history.HistoryUpdateNotify validation failed!')

        let fbeBegin = this._HistoryUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_HistoryUpdateNotify(this._HistoryUpdateNotifyModel, type, buffer, offset, size)
        this._HistoryUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestModel.verify(), 'history.ChartBarHistoryRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryRequest(this._ChartBarHistoryRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseModel.verify(), 'history.ChartBarHistoryResponse validation failed!')

        let fbeBegin = this._ChartBarHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryResponse(this._ChartBarHistoryResponseModel, type, buffer, offset, size)
        this._ChartBarHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyModel.verify(), 'history.ChartBarNotify validation failed!')

        let fbeBegin = this._ChartBarNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarNotify(this._ChartBarNotifyModel, type, buffer, offset, size)
        this._ChartBarNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyModel.verify(), 'history.ChartBarUpdateNotify validation failed!')

        let fbeBegin = this._ChartBarUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarUpdateNotify(this._ChartBarUpdateNotifyModel, type, buffer, offset, size)
        this._ChartBarUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestModel.verify(), 'history.TickHistoryRequest validation failed!')

        let fbeBegin = this._TickHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickHistoryRequest(this._TickHistoryRequestModel, type, buffer, offset, size)
        this._TickHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseModel.verify(), 'history.TickHistoryResponse validation failed!')

        let fbeBegin = this._TickHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickHistoryResponse(this._TickHistoryResponseModel, type, buffer, offset, size)
        this._TickHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryModifyRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestModel.verify(), 'history.ChartBarHistoryModifyRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryModifyRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryModifyRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryModifyResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseModel.verify(), 'history.ChartBarHistoryModifyResponse validation failed!')

        let fbeBegin = this._ChartBarHistoryModifyResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseModel, type, buffer, offset, size)
        this._ChartBarHistoryModifyResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestModel.verify(), 'history.ChartBarHistoryRangeRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryRangeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryRangeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryLastModifiedRangeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryLastModifiedRangeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeRequestModel.verify(), 'history.ChartBarHistoryLastModifiedRangeRequest validation failed!')

        let fbeBegin = this._ChartBarHistoryLastModifiedRangeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryLastModifiedRangeRequest(this._ChartBarHistoryLastModifiedRangeRequestModel, type, buffer, offset, size)
        this._ChartBarHistoryLastModifiedRangeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarHistoryLastModifiedRangeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarHistoryLastModifiedRangeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeResponseModel.verify(), 'history.ChartBarHistoryLastModifiedRangeResponse validation failed!')

        let fbeBegin = this._ChartBarHistoryLastModifiedRangeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarHistoryLastModifiedRangeResponse(this._ChartBarHistoryLastModifiedRangeResponseModel, type, buffer, offset, size)
        this._ChartBarHistoryLastModifiedRangeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarModifyNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarModifyNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarModifyNotifyModel.verify(), 'history.ChartBarModifyNotify validation failed!')

        let fbeBegin = this._ChartBarModifyNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarModifyNotify(this._ChartBarModifyNotifyModel, type, buffer, offset, size)
        this._ChartBarModifyNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsListGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestModel.verify(), 'history.ClientReportsListGetRequest validation failed!')

        let fbeBegin = this._ClientReportsListGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsListGetRequest(this._ClientReportsListGetRequestModel, type, buffer, offset, size)
        this._ClientReportsListGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsListGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseModel.verify(), 'history.ClientReportsListGetResponse validation failed!')

        let fbeBegin = this._ClientReportsListGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsListGetResponse(this._ClientReportsListGetResponseModel, type, buffer, offset, size)
        this._ClientReportsListGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestModel.verify(), 'history.ClientReportGetRequest validation failed!')

        let fbeBegin = this._ClientReportGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportGetRequest(this._ClientReportGetRequestModel, type, buffer, offset, size)
        this._ClientReportGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseModel.verify(), 'history.ClientReportGetResponse validation failed!')

        let fbeBegin = this._ClientReportGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportGetResponse(this._ClientReportGetResponseModel, type, buffer, offset, size)
        this._ClientReportGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsSendRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestModel.verify(), 'history.ClientReportsSendRequest validation failed!')

        let fbeBegin = this._ClientReportsSendRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsSendRequest(this._ClientReportsSendRequestModel, type, buffer, offset, size)
        this._ClientReportsSendRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsSendResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseModel.verify(), 'history.ClientReportsSendResponse validation failed!')

        let fbeBegin = this._ClientReportsSendResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsSendResponse(this._ClientReportsSendResponseModel, type, buffer, offset, size)
        this._ClientReportsSendResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestModel.verify(), 'history.ChartBarsImportRequest validation failed!')

        let fbeBegin = this._ChartBarsImportRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportRequest(this._ChartBarsImportRequestModel, type, buffer, offset, size)
        this._ChartBarsImportRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseModel.verify(), 'history.ChartBarsImportResponse validation failed!')

        let fbeBegin = this._ChartBarsImportResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportResponse(this._ChartBarsImportResponseModel, type, buffer, offset, size)
        this._ChartBarsImportResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyModel.verify(), 'history.ChartBarsImportNotify validation failed!')

        let fbeBegin = this._ChartBarsImportNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportNotify(this._ChartBarsImportNotifyModel, type, buffer, offset, size)
        this._ChartBarsImportNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ChartBarsImportFinishedNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ChartBarsImportFinishedNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportFinishedNotifyModel.verify(), 'history.ChartBarsImportFinishedNotify validation failed!')

        let fbeBegin = this._ChartBarsImportFinishedNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ChartBarsImportFinishedNotify(this._ChartBarsImportFinishedNotifyModel, type, buffer, offset, size)
        this._ChartBarsImportFinishedNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetRequestModel.verify(), 'history.ClientReportsGetRequest validation failed!')

        let fbeBegin = this._ClientReportsGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetRequest(this._ClientReportsGetRequestModel, type, buffer, offset, size)
        this._ClientReportsGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetResponseModel.verify(), 'history.ClientReportsGetResponse validation failed!')

        let fbeBegin = this._ClientReportsGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetResponse(this._ClientReportsGetResponseModel, type, buffer, offset, size)
        this._ClientReportsGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestModel.verify(), 'history.ClientReportsInsertRequest validation failed!')

        let fbeBegin = this._ClientReportsInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsInsertRequest(this._ClientReportsInsertRequestModel, type, buffer, offset, size)
        this._ClientReportsInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseModel.verify(), 'history.ClientReportsInsertResponse validation failed!')

        let fbeBegin = this._ClientReportsInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsInsertResponse(this._ClientReportsInsertResponseModel, type, buffer, offset, size)
        this._ClientReportsInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetLastIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestModel.verify(), 'history.ClientReportsGetLastIdRequest validation failed!')

        let fbeBegin = this._ClientReportsGetLastIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestModel, type, buffer, offset, size)
        this._ClientReportsGetLastIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetLastIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseModel.verify(), 'history.ClientReportsGetLastIdResponse validation failed!')

        let fbeBegin = this._ClientReportsGetLastIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseModel, type, buffer, offset, size)
        this._ClientReportsGetLastIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogCreateParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogCreateParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateParamsModel.verify(), 'history.BlockedIPEventLogCreateParams validation failed!')

        let fbeBegin = this._BlockedIPEventLogCreateParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogCreateParams(this._BlockedIPEventLogCreateParamsModel, type, buffer, offset, size)
        this._BlockedIPEventLogCreateParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateRequestModel.verify(), 'history.BlockedIPEventLogCreateRequest validation failed!')

        let fbeBegin = this._BlockedIPEventLogCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogCreateRequest(this._BlockedIPEventLogCreateRequestModel, type, buffer, offset, size)
        this._BlockedIPEventLogCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogCreateResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogCreateResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResultModel.verify(), 'history.BlockedIPEventLogCreateResult validation failed!')

        let fbeBegin = this._BlockedIPEventLogCreateResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogCreateResult(this._BlockedIPEventLogCreateResultModel, type, buffer, offset, size)
        this._BlockedIPEventLogCreateResultModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResponseModel.verify(), 'history.BlockedIPEventLogCreateResponse validation failed!')

        let fbeBegin = this._BlockedIPEventLogCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogCreateResponse(this._BlockedIPEventLogCreateResponseModel, type, buffer, offset, size)
        this._BlockedIPEventLogCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPNotifyModel.verify(), 'history.BlockedIPNotify validation failed!')

        let fbeBegin = this._BlockedIPNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPNotify(this._BlockedIPNotifyModel, type, buffer, offset, size)
        this._BlockedIPNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetParamsModel.verify(), 'history.BlockedIPEventLogGetParams validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetParams(this._BlockedIPEventLogGetParamsModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetRequestModel.verify(), 'history.BlockedIPEventLogGetRequest validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetRequest(this._BlockedIPEventLogGetRequestModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResultModel.verify(), 'history.BlockedIPEventLogGetResult validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetResult(this._BlockedIPEventLogGetResultModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetResultModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResponseModel.verify(), 'history.BlockedIPEventLogGetResponse validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetResponse(this._BlockedIPEventLogGetResponseModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetFilterParamsModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetFilterParamsModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterParamsModel.verify(), 'history.BlockedIPEventLogGetFilterParams validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetFilterParamsModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetFilterParams(this._BlockedIPEventLogGetFilterParamsModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetFilterParamsModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterRequestModel.verify(), 'history.BlockedIPEventLogGetFilterRequest validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetFilterRequest(this._BlockedIPEventLogGetFilterRequestModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetFilterResultModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetFilterResultModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResultModel.verify(), 'history.BlockedIPEventLogGetFilterResult validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetFilterResultModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetFilterResult(this._BlockedIPEventLogGetFilterResultModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetFilterResultModel.model.getEnd(fbeBegin)
        return true
      }
      case BlockedIPEventLogGetFilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BlockedIPEventLogGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResponseModel.verify(), 'history.BlockedIPEventLogGetFilterResponse validation failed!')

        let fbeBegin = this._BlockedIPEventLogGetFilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BlockedIPEventLogGetFilterResponse(this._BlockedIPEventLogGetFilterResponseModel, type, buffer, offset, size)
        this._BlockedIPEventLogGetFilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityProxy != null) && this.entityProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding history client
 */
class Client extends fbe.Client {
  /**
   * Initialize history client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._entityClient = new entity.Client(this.sendBuffer, this.receiveBuffer)
    this._HistoryVersionRequestSenderModel = new HistoryVersionRequestModel(this.sendBuffer)
    this._HistoryVersionRequestReceiverValue = new HistoryVersionRequest()
    this._HistoryVersionRequestReceiverModel = new HistoryVersionRequestModel()
    this._HistoryVersionResponseSenderModel = new HistoryVersionResponseModel(this.sendBuffer)
    this._HistoryVersionResponseReceiverValue = new HistoryVersionResponse()
    this._HistoryVersionResponseReceiverModel = new HistoryVersionResponseModel()
    this._HistoryUpdateNotifySenderModel = new HistoryUpdateNotifyModel(this.sendBuffer)
    this._HistoryUpdateNotifyReceiverValue = new HistoryUpdateNotify()
    this._HistoryUpdateNotifyReceiverModel = new HistoryUpdateNotifyModel()
    this._ChartBarHistoryRequestSenderModel = new ChartBarHistoryRequestModel(this.sendBuffer)
    this._ChartBarHistoryRequestReceiverValue = new ChartBarHistoryRequest()
    this._ChartBarHistoryRequestReceiverModel = new ChartBarHistoryRequestModel()
    this._ChartBarHistoryResponseSenderModel = new ChartBarHistoryResponseModel(this.sendBuffer)
    this._ChartBarHistoryResponseReceiverValue = new ChartBarHistoryResponse()
    this._ChartBarHistoryResponseReceiverModel = new ChartBarHistoryResponseModel()
    this._ChartBarNotifySenderModel = new ChartBarNotifyModel(this.sendBuffer)
    this._ChartBarNotifyReceiverValue = new ChartBarNotify()
    this._ChartBarNotifyReceiverModel = new ChartBarNotifyModel()
    this._ChartBarUpdateNotifySenderModel = new ChartBarUpdateNotifyModel(this.sendBuffer)
    this._ChartBarUpdateNotifyReceiverValue = new ChartBarUpdateNotify()
    this._ChartBarUpdateNotifyReceiverModel = new ChartBarUpdateNotifyModel()
    this._TickHistoryRequestSenderModel = new TickHistoryRequestModel(this.sendBuffer)
    this._TickHistoryRequestReceiverValue = new TickHistoryRequest()
    this._TickHistoryRequestReceiverModel = new TickHistoryRequestModel()
    this._TickHistoryResponseSenderModel = new TickHistoryResponseModel(this.sendBuffer)
    this._TickHistoryResponseReceiverValue = new TickHistoryResponse()
    this._TickHistoryResponseReceiverModel = new TickHistoryResponseModel()
    this._ChartBarHistoryModifyRequestSenderModel = new ChartBarHistoryModifyRequestModel(this.sendBuffer)
    this._ChartBarHistoryModifyRequestReceiverValue = new ChartBarHistoryModifyRequest()
    this._ChartBarHistoryModifyRequestReceiverModel = new ChartBarHistoryModifyRequestModel()
    this._ChartBarHistoryModifyResponseSenderModel = new ChartBarHistoryModifyResponseModel(this.sendBuffer)
    this._ChartBarHistoryModifyResponseReceiverValue = new ChartBarHistoryModifyResponse()
    this._ChartBarHistoryModifyResponseReceiverModel = new ChartBarHistoryModifyResponseModel()
    this._ChartBarHistoryRangeRequestSenderModel = new ChartBarHistoryRangeRequestModel(this.sendBuffer)
    this._ChartBarHistoryRangeRequestReceiverValue = new ChartBarHistoryRangeRequest()
    this._ChartBarHistoryRangeRequestReceiverModel = new ChartBarHistoryRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeRequestSenderModel = new ChartBarHistoryLastModifiedRangeRequestModel(this.sendBuffer)
    this._ChartBarHistoryLastModifiedRangeRequestReceiverValue = new ChartBarHistoryLastModifiedRangeRequest()
    this._ChartBarHistoryLastModifiedRangeRequestReceiverModel = new ChartBarHistoryLastModifiedRangeRequestModel()
    this._ChartBarHistoryLastModifiedRangeResponseSenderModel = new ChartBarHistoryLastModifiedRangeResponseModel(this.sendBuffer)
    this._ChartBarHistoryLastModifiedRangeResponseReceiverValue = new ChartBarHistoryLastModifiedRangeResponse()
    this._ChartBarHistoryLastModifiedRangeResponseReceiverModel = new ChartBarHistoryLastModifiedRangeResponseModel()
    this._ChartBarModifyNotifySenderModel = new ChartBarModifyNotifyModel(this.sendBuffer)
    this._ChartBarModifyNotifyReceiverValue = new ChartBarModifyNotify()
    this._ChartBarModifyNotifyReceiverModel = new ChartBarModifyNotifyModel()
    this._ClientReportsListGetRequestSenderModel = new ClientReportsListGetRequestModel(this.sendBuffer)
    this._ClientReportsListGetRequestReceiverValue = new ClientReportsListGetRequest()
    this._ClientReportsListGetRequestReceiverModel = new ClientReportsListGetRequestModel()
    this._ClientReportsListGetResponseSenderModel = new ClientReportsListGetResponseModel(this.sendBuffer)
    this._ClientReportsListGetResponseReceiverValue = new ClientReportsListGetResponse()
    this._ClientReportsListGetResponseReceiverModel = new ClientReportsListGetResponseModel()
    this._ClientReportGetRequestSenderModel = new ClientReportGetRequestModel(this.sendBuffer)
    this._ClientReportGetRequestReceiverValue = new ClientReportGetRequest()
    this._ClientReportGetRequestReceiverModel = new ClientReportGetRequestModel()
    this._ClientReportGetResponseSenderModel = new ClientReportGetResponseModel(this.sendBuffer)
    this._ClientReportGetResponseReceiverValue = new ClientReportGetResponse()
    this._ClientReportGetResponseReceiverModel = new ClientReportGetResponseModel()
    this._ClientReportsSendRequestSenderModel = new ClientReportsSendRequestModel(this.sendBuffer)
    this._ClientReportsSendRequestReceiverValue = new ClientReportsSendRequest()
    this._ClientReportsSendRequestReceiverModel = new ClientReportsSendRequestModel()
    this._ClientReportsSendResponseSenderModel = new ClientReportsSendResponseModel(this.sendBuffer)
    this._ClientReportsSendResponseReceiverValue = new ClientReportsSendResponse()
    this._ClientReportsSendResponseReceiverModel = new ClientReportsSendResponseModel()
    this._ChartBarsImportRequestSenderModel = new ChartBarsImportRequestModel(this.sendBuffer)
    this._ChartBarsImportRequestReceiverValue = new ChartBarsImportRequest()
    this._ChartBarsImportRequestReceiverModel = new ChartBarsImportRequestModel()
    this._ChartBarsImportResponseSenderModel = new ChartBarsImportResponseModel(this.sendBuffer)
    this._ChartBarsImportResponseReceiverValue = new ChartBarsImportResponse()
    this._ChartBarsImportResponseReceiverModel = new ChartBarsImportResponseModel()
    this._ChartBarsImportNotifySenderModel = new ChartBarsImportNotifyModel(this.sendBuffer)
    this._ChartBarsImportNotifyReceiverValue = new ChartBarsImportNotify()
    this._ChartBarsImportNotifyReceiverModel = new ChartBarsImportNotifyModel()
    this._ChartBarsImportFinishedNotifySenderModel = new ChartBarsImportFinishedNotifyModel(this.sendBuffer)
    this._ChartBarsImportFinishedNotifyReceiverValue = new ChartBarsImportFinishedNotify()
    this._ChartBarsImportFinishedNotifyReceiverModel = new ChartBarsImportFinishedNotifyModel()
    this._ClientReportsGetRequestSenderModel = new ClientReportsGetRequestModel(this.sendBuffer)
    this._ClientReportsGetRequestReceiverValue = new ClientReportsGetRequest()
    this._ClientReportsGetRequestReceiverModel = new ClientReportsGetRequestModel()
    this._ClientReportsGetResponseSenderModel = new ClientReportsGetResponseModel(this.sendBuffer)
    this._ClientReportsGetResponseReceiverValue = new ClientReportsGetResponse()
    this._ClientReportsGetResponseReceiverModel = new ClientReportsGetResponseModel()
    this._ClientReportsInsertRequestSenderModel = new ClientReportsInsertRequestModel(this.sendBuffer)
    this._ClientReportsInsertRequestReceiverValue = new ClientReportsInsertRequest()
    this._ClientReportsInsertRequestReceiverModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseSenderModel = new ClientReportsInsertResponseModel(this.sendBuffer)
    this._ClientReportsInsertResponseReceiverValue = new ClientReportsInsertResponse()
    this._ClientReportsInsertResponseReceiverModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestSenderModel = new ClientReportsGetLastIdRequestModel(this.sendBuffer)
    this._ClientReportsGetLastIdRequestReceiverValue = new ClientReportsGetLastIdRequest()
    this._ClientReportsGetLastIdRequestReceiverModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseSenderModel = new ClientReportsGetLastIdResponseModel(this.sendBuffer)
    this._ClientReportsGetLastIdResponseReceiverValue = new ClientReportsGetLastIdResponse()
    this._ClientReportsGetLastIdResponseReceiverModel = new ClientReportsGetLastIdResponseModel()
    this._BlockedIPEventLogCreateParamsSenderModel = new BlockedIPEventLogCreateParamsModel(this.sendBuffer)
    this._BlockedIPEventLogCreateParamsReceiverValue = new BlockedIPEventLogCreateParams()
    this._BlockedIPEventLogCreateParamsReceiverModel = new BlockedIPEventLogCreateParamsModel()
    this._BlockedIPEventLogCreateRequestSenderModel = new BlockedIPEventLogCreateRequestModel(this.sendBuffer)
    this._BlockedIPEventLogCreateRequestReceiverValue = new BlockedIPEventLogCreateRequest()
    this._BlockedIPEventLogCreateRequestReceiverModel = new BlockedIPEventLogCreateRequestModel()
    this._BlockedIPEventLogCreateResultSenderModel = new BlockedIPEventLogCreateResultModel(this.sendBuffer)
    this._BlockedIPEventLogCreateResultReceiverValue = new BlockedIPEventLogCreateResult()
    this._BlockedIPEventLogCreateResultReceiverModel = new BlockedIPEventLogCreateResultModel()
    this._BlockedIPEventLogCreateResponseSenderModel = new BlockedIPEventLogCreateResponseModel(this.sendBuffer)
    this._BlockedIPEventLogCreateResponseReceiverValue = new BlockedIPEventLogCreateResponse()
    this._BlockedIPEventLogCreateResponseReceiverModel = new BlockedIPEventLogCreateResponseModel()
    this._BlockedIPNotifySenderModel = new BlockedIPNotifyModel(this.sendBuffer)
    this._BlockedIPNotifyReceiverValue = new BlockedIPNotify()
    this._BlockedIPNotifyReceiverModel = new BlockedIPNotifyModel()
    this._BlockedIPEventLogGetParamsSenderModel = new BlockedIPEventLogGetParamsModel(this.sendBuffer)
    this._BlockedIPEventLogGetParamsReceiverValue = new BlockedIPEventLogGetParams()
    this._BlockedIPEventLogGetParamsReceiverModel = new BlockedIPEventLogGetParamsModel()
    this._BlockedIPEventLogGetRequestSenderModel = new BlockedIPEventLogGetRequestModel(this.sendBuffer)
    this._BlockedIPEventLogGetRequestReceiverValue = new BlockedIPEventLogGetRequest()
    this._BlockedIPEventLogGetRequestReceiverModel = new BlockedIPEventLogGetRequestModel()
    this._BlockedIPEventLogGetResultSenderModel = new BlockedIPEventLogGetResultModel(this.sendBuffer)
    this._BlockedIPEventLogGetResultReceiverValue = new BlockedIPEventLogGetResult()
    this._BlockedIPEventLogGetResultReceiverModel = new BlockedIPEventLogGetResultModel()
    this._BlockedIPEventLogGetResponseSenderModel = new BlockedIPEventLogGetResponseModel(this.sendBuffer)
    this._BlockedIPEventLogGetResponseReceiverValue = new BlockedIPEventLogGetResponse()
    this._BlockedIPEventLogGetResponseReceiverModel = new BlockedIPEventLogGetResponseModel()
    this._BlockedIPEventLogGetFilterParamsSenderModel = new BlockedIPEventLogGetFilterParamsModel(this.sendBuffer)
    this._BlockedIPEventLogGetFilterParamsReceiverValue = new BlockedIPEventLogGetFilterParams()
    this._BlockedIPEventLogGetFilterParamsReceiverModel = new BlockedIPEventLogGetFilterParamsModel()
    this._BlockedIPEventLogGetFilterRequestSenderModel = new BlockedIPEventLogGetFilterRequestModel(this.sendBuffer)
    this._BlockedIPEventLogGetFilterRequestReceiverValue = new BlockedIPEventLogGetFilterRequest()
    this._BlockedIPEventLogGetFilterRequestReceiverModel = new BlockedIPEventLogGetFilterRequestModel()
    this._BlockedIPEventLogGetFilterResultSenderModel = new BlockedIPEventLogGetFilterResultModel(this.sendBuffer)
    this._BlockedIPEventLogGetFilterResultReceiverValue = new BlockedIPEventLogGetFilterResult()
    this._BlockedIPEventLogGetFilterResultReceiverModel = new BlockedIPEventLogGetFilterResultModel()
    this._BlockedIPEventLogGetFilterResponseSenderModel = new BlockedIPEventLogGetFilterResponseModel(this.sendBuffer)
    this._BlockedIPEventLogGetFilterResponseReceiverValue = new BlockedIPEventLogGetFilterResponse()
    this._BlockedIPEventLogGetFilterResponseReceiverModel = new BlockedIPEventLogGetFilterResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported entity client
   * @this {!Client}
   * @returns {!entity.Client} entity client
   */
  get entityClient () {
    return this._entityClient
  }

  // Sender models accessors

  /**
   * Get HistoryVersionRequest model
   * @this {!Client}
   * @returns {!HistoryVersionRequestModel} HistoryVersionRequest sender model
   */
  get HistoryVersionRequestSenderModel () {
    return this._HistoryVersionRequestSenderModel
  }

  /**
   * Get HistoryVersionResponse model
   * @this {!Client}
   * @returns {!HistoryVersionResponseModel} HistoryVersionResponse sender model
   */
  get HistoryVersionResponseSenderModel () {
    return this._HistoryVersionResponseSenderModel
  }

  /**
   * Get HistoryUpdateNotify model
   * @this {!Client}
   * @returns {!HistoryUpdateNotifyModel} HistoryUpdateNotify sender model
   */
  get HistoryUpdateNotifySenderModel () {
    return this._HistoryUpdateNotifySenderModel
  }

  /**
   * Get ChartBarHistoryRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryRequestModel} ChartBarHistoryRequest sender model
   */
  get ChartBarHistoryRequestSenderModel () {
    return this._ChartBarHistoryRequestSenderModel
  }

  /**
   * Get ChartBarHistoryResponse model
   * @this {!Client}
   * @returns {!ChartBarHistoryResponseModel} ChartBarHistoryResponse sender model
   */
  get ChartBarHistoryResponseSenderModel () {
    return this._ChartBarHistoryResponseSenderModel
  }

  /**
   * Get ChartBarNotify model
   * @this {!Client}
   * @returns {!ChartBarNotifyModel} ChartBarNotify sender model
   */
  get ChartBarNotifySenderModel () {
    return this._ChartBarNotifySenderModel
  }

  /**
   * Get ChartBarUpdateNotify model
   * @this {!Client}
   * @returns {!ChartBarUpdateNotifyModel} ChartBarUpdateNotify sender model
   */
  get ChartBarUpdateNotifySenderModel () {
    return this._ChartBarUpdateNotifySenderModel
  }

  /**
   * Get TickHistoryRequest model
   * @this {!Client}
   * @returns {!TickHistoryRequestModel} TickHistoryRequest sender model
   */
  get TickHistoryRequestSenderModel () {
    return this._TickHistoryRequestSenderModel
  }

  /**
   * Get TickHistoryResponse model
   * @this {!Client}
   * @returns {!TickHistoryResponseModel} TickHistoryResponse sender model
   */
  get TickHistoryResponseSenderModel () {
    return this._TickHistoryResponseSenderModel
  }

  /**
   * Get ChartBarHistoryModifyRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryModifyRequestModel} ChartBarHistoryModifyRequest sender model
   */
  get ChartBarHistoryModifyRequestSenderModel () {
    return this._ChartBarHistoryModifyRequestSenderModel
  }

  /**
   * Get ChartBarHistoryModifyResponse model
   * @this {!Client}
   * @returns {!ChartBarHistoryModifyResponseModel} ChartBarHistoryModifyResponse sender model
   */
  get ChartBarHistoryModifyResponseSenderModel () {
    return this._ChartBarHistoryModifyResponseSenderModel
  }

  /**
   * Get ChartBarHistoryRangeRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryRangeRequestModel} ChartBarHistoryRangeRequest sender model
   */
  get ChartBarHistoryRangeRequestSenderModel () {
    return this._ChartBarHistoryRangeRequestSenderModel
  }

  /**
   * Get ChartBarHistoryLastModifiedRangeRequest model
   * @this {!Client}
   * @returns {!ChartBarHistoryLastModifiedRangeRequestModel} ChartBarHistoryLastModifiedRangeRequest sender model
   */
  get ChartBarHistoryLastModifiedRangeRequestSenderModel () {
    return this._ChartBarHistoryLastModifiedRangeRequestSenderModel
  }

  /**
   * Get ChartBarHistoryLastModifiedRangeResponse model
   * @this {!Client}
   * @returns {!ChartBarHistoryLastModifiedRangeResponseModel} ChartBarHistoryLastModifiedRangeResponse sender model
   */
  get ChartBarHistoryLastModifiedRangeResponseSenderModel () {
    return this._ChartBarHistoryLastModifiedRangeResponseSenderModel
  }

  /**
   * Get ChartBarModifyNotify model
   * @this {!Client}
   * @returns {!ChartBarModifyNotifyModel} ChartBarModifyNotify sender model
   */
  get ChartBarModifyNotifySenderModel () {
    return this._ChartBarModifyNotifySenderModel
  }

  /**
   * Get ClientReportsListGetRequest model
   * @this {!Client}
   * @returns {!ClientReportsListGetRequestModel} ClientReportsListGetRequest sender model
   */
  get ClientReportsListGetRequestSenderModel () {
    return this._ClientReportsListGetRequestSenderModel
  }

  /**
   * Get ClientReportsListGetResponse model
   * @this {!Client}
   * @returns {!ClientReportsListGetResponseModel} ClientReportsListGetResponse sender model
   */
  get ClientReportsListGetResponseSenderModel () {
    return this._ClientReportsListGetResponseSenderModel
  }

  /**
   * Get ClientReportGetRequest model
   * @this {!Client}
   * @returns {!ClientReportGetRequestModel} ClientReportGetRequest sender model
   */
  get ClientReportGetRequestSenderModel () {
    return this._ClientReportGetRequestSenderModel
  }

  /**
   * Get ClientReportGetResponse model
   * @this {!Client}
   * @returns {!ClientReportGetResponseModel} ClientReportGetResponse sender model
   */
  get ClientReportGetResponseSenderModel () {
    return this._ClientReportGetResponseSenderModel
  }

  /**
   * Get ClientReportsSendRequest model
   * @this {!Client}
   * @returns {!ClientReportsSendRequestModel} ClientReportsSendRequest sender model
   */
  get ClientReportsSendRequestSenderModel () {
    return this._ClientReportsSendRequestSenderModel
  }

  /**
   * Get ClientReportsSendResponse model
   * @this {!Client}
   * @returns {!ClientReportsSendResponseModel} ClientReportsSendResponse sender model
   */
  get ClientReportsSendResponseSenderModel () {
    return this._ClientReportsSendResponseSenderModel
  }

  /**
   * Get ChartBarsImportRequest model
   * @this {!Client}
   * @returns {!ChartBarsImportRequestModel} ChartBarsImportRequest sender model
   */
  get ChartBarsImportRequestSenderModel () {
    return this._ChartBarsImportRequestSenderModel
  }

  /**
   * Get ChartBarsImportResponse model
   * @this {!Client}
   * @returns {!ChartBarsImportResponseModel} ChartBarsImportResponse sender model
   */
  get ChartBarsImportResponseSenderModel () {
    return this._ChartBarsImportResponseSenderModel
  }

  /**
   * Get ChartBarsImportNotify model
   * @this {!Client}
   * @returns {!ChartBarsImportNotifyModel} ChartBarsImportNotify sender model
   */
  get ChartBarsImportNotifySenderModel () {
    return this._ChartBarsImportNotifySenderModel
  }

  /**
   * Get ChartBarsImportFinishedNotify model
   * @this {!Client}
   * @returns {!ChartBarsImportFinishedNotifyModel} ChartBarsImportFinishedNotify sender model
   */
  get ChartBarsImportFinishedNotifySenderModel () {
    return this._ChartBarsImportFinishedNotifySenderModel
  }

  /**
   * Get ClientReportsGetRequest model
   * @this {!Client}
   * @returns {!ClientReportsGetRequestModel} ClientReportsGetRequest sender model
   */
  get ClientReportsGetRequestSenderModel () {
    return this._ClientReportsGetRequestSenderModel
  }

  /**
   * Get ClientReportsGetResponse model
   * @this {!Client}
   * @returns {!ClientReportsGetResponseModel} ClientReportsGetResponse sender model
   */
  get ClientReportsGetResponseSenderModel () {
    return this._ClientReportsGetResponseSenderModel
  }

  /**
   * Get ClientReportsInsertRequest model
   * @this {!Client}
   * @returns {!ClientReportsInsertRequestModel} ClientReportsInsertRequest sender model
   */
  get ClientReportsInsertRequestSenderModel () {
    return this._ClientReportsInsertRequestSenderModel
  }

  /**
   * Get ClientReportsInsertResponse model
   * @this {!Client}
   * @returns {!ClientReportsInsertResponseModel} ClientReportsInsertResponse sender model
   */
  get ClientReportsInsertResponseSenderModel () {
    return this._ClientReportsInsertResponseSenderModel
  }

  /**
   * Get ClientReportsGetLastIdRequest model
   * @this {!Client}
   * @returns {!ClientReportsGetLastIdRequestModel} ClientReportsGetLastIdRequest sender model
   */
  get ClientReportsGetLastIdRequestSenderModel () {
    return this._ClientReportsGetLastIdRequestSenderModel
  }

  /**
   * Get ClientReportsGetLastIdResponse model
   * @this {!Client}
   * @returns {!ClientReportsGetLastIdResponseModel} ClientReportsGetLastIdResponse sender model
   */
  get ClientReportsGetLastIdResponseSenderModel () {
    return this._ClientReportsGetLastIdResponseSenderModel
  }

  /**
   * Get BlockedIPEventLogCreateParams model
   * @this {!Client}
   * @returns {!BlockedIPEventLogCreateParamsModel} BlockedIPEventLogCreateParams sender model
   */
  get BlockedIPEventLogCreateParamsSenderModel () {
    return this._BlockedIPEventLogCreateParamsSenderModel
  }

  /**
   * Get BlockedIPEventLogCreateRequest model
   * @this {!Client}
   * @returns {!BlockedIPEventLogCreateRequestModel} BlockedIPEventLogCreateRequest sender model
   */
  get BlockedIPEventLogCreateRequestSenderModel () {
    return this._BlockedIPEventLogCreateRequestSenderModel
  }

  /**
   * Get BlockedIPEventLogCreateResult model
   * @this {!Client}
   * @returns {!BlockedIPEventLogCreateResultModel} BlockedIPEventLogCreateResult sender model
   */
  get BlockedIPEventLogCreateResultSenderModel () {
    return this._BlockedIPEventLogCreateResultSenderModel
  }

  /**
   * Get BlockedIPEventLogCreateResponse model
   * @this {!Client}
   * @returns {!BlockedIPEventLogCreateResponseModel} BlockedIPEventLogCreateResponse sender model
   */
  get BlockedIPEventLogCreateResponseSenderModel () {
    return this._BlockedIPEventLogCreateResponseSenderModel
  }

  /**
   * Get BlockedIPNotify model
   * @this {!Client}
   * @returns {!BlockedIPNotifyModel} BlockedIPNotify sender model
   */
  get BlockedIPNotifySenderModel () {
    return this._BlockedIPNotifySenderModel
  }

  /**
   * Get BlockedIPEventLogGetParams model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetParamsModel} BlockedIPEventLogGetParams sender model
   */
  get BlockedIPEventLogGetParamsSenderModel () {
    return this._BlockedIPEventLogGetParamsSenderModel
  }

  /**
   * Get BlockedIPEventLogGetRequest model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetRequestModel} BlockedIPEventLogGetRequest sender model
   */
  get BlockedIPEventLogGetRequestSenderModel () {
    return this._BlockedIPEventLogGetRequestSenderModel
  }

  /**
   * Get BlockedIPEventLogGetResult model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetResultModel} BlockedIPEventLogGetResult sender model
   */
  get BlockedIPEventLogGetResultSenderModel () {
    return this._BlockedIPEventLogGetResultSenderModel
  }

  /**
   * Get BlockedIPEventLogGetResponse model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetResponseModel} BlockedIPEventLogGetResponse sender model
   */
  get BlockedIPEventLogGetResponseSenderModel () {
    return this._BlockedIPEventLogGetResponseSenderModel
  }

  /**
   * Get BlockedIPEventLogGetFilterParams model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetFilterParamsModel} BlockedIPEventLogGetFilterParams sender model
   */
  get BlockedIPEventLogGetFilterParamsSenderModel () {
    return this._BlockedIPEventLogGetFilterParamsSenderModel
  }

  /**
   * Get BlockedIPEventLogGetFilterRequest model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetFilterRequestModel} BlockedIPEventLogGetFilterRequest sender model
   */
  get BlockedIPEventLogGetFilterRequestSenderModel () {
    return this._BlockedIPEventLogGetFilterRequestSenderModel
  }

  /**
   * Get BlockedIPEventLogGetFilterResult model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetFilterResultModel} BlockedIPEventLogGetFilterResult sender model
   */
  get BlockedIPEventLogGetFilterResultSenderModel () {
    return this._BlockedIPEventLogGetFilterResultSenderModel
  }

  /**
   * Get BlockedIPEventLogGetFilterResponse model
   * @this {!Client}
   * @returns {!BlockedIPEventLogGetFilterResponseModel} BlockedIPEventLogGetFilterResponse sender model
   */
  get BlockedIPEventLogGetFilterResponseSenderModel () {
    return this._BlockedIPEventLogGetFilterResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof HistoryVersionRequest) && (value.fbeType === this.HistoryVersionRequestSenderModel.fbeType)) {
      return this.send_HistoryVersionRequest(value)
    }
    if ((value instanceof HistoryVersionResponse) && (value.fbeType === this.HistoryVersionResponseSenderModel.fbeType)) {
      return this.send_HistoryVersionResponse(value)
    }
    if ((value instanceof HistoryUpdateNotify) && (value.fbeType === this.HistoryUpdateNotifySenderModel.fbeType)) {
      return this.send_HistoryUpdateNotify(value)
    }
    if ((value instanceof ChartBarHistoryRequest) && (value.fbeType === this.ChartBarHistoryRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryRequest(value)
    }
    if ((value instanceof ChartBarHistoryResponse) && (value.fbeType === this.ChartBarHistoryResponseSenderModel.fbeType)) {
      return this.send_ChartBarHistoryResponse(value)
    }
    if ((value instanceof ChartBarNotify) && (value.fbeType === this.ChartBarNotifySenderModel.fbeType)) {
      return this.send_ChartBarNotify(value)
    }
    if ((value instanceof ChartBarUpdateNotify) && (value.fbeType === this.ChartBarUpdateNotifySenderModel.fbeType)) {
      return this.send_ChartBarUpdateNotify(value)
    }
    if ((value instanceof TickHistoryRequest) && (value.fbeType === this.TickHistoryRequestSenderModel.fbeType)) {
      return this.send_TickHistoryRequest(value)
    }
    if ((value instanceof TickHistoryResponse) && (value.fbeType === this.TickHistoryResponseSenderModel.fbeType)) {
      return this.send_TickHistoryResponse(value)
    }
    if ((value instanceof ChartBarHistoryModifyRequest) && (value.fbeType === this.ChartBarHistoryModifyRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryModifyRequest(value)
    }
    if ((value instanceof ChartBarHistoryModifyResponse) && (value.fbeType === this.ChartBarHistoryModifyResponseSenderModel.fbeType)) {
      return this.send_ChartBarHistoryModifyResponse(value)
    }
    if ((value instanceof ChartBarHistoryRangeRequest) && (value.fbeType === this.ChartBarHistoryRangeRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryRangeRequest(value)
    }
    if ((value instanceof ChartBarHistoryLastModifiedRangeRequest) && (value.fbeType === this.ChartBarHistoryLastModifiedRangeRequestSenderModel.fbeType)) {
      return this.send_ChartBarHistoryLastModifiedRangeRequest(value)
    }
    if ((value instanceof ChartBarHistoryLastModifiedRangeResponse) && (value.fbeType === this.ChartBarHistoryLastModifiedRangeResponseSenderModel.fbeType)) {
      return this.send_ChartBarHistoryLastModifiedRangeResponse(value)
    }
    if ((value instanceof ChartBarModifyNotify) && (value.fbeType === this.ChartBarModifyNotifySenderModel.fbeType)) {
      return this.send_ChartBarModifyNotify(value)
    }
    if ((value instanceof ClientReportsListGetRequest) && (value.fbeType === this.ClientReportsListGetRequestSenderModel.fbeType)) {
      return this.send_ClientReportsListGetRequest(value)
    }
    if ((value instanceof ClientReportsListGetResponse) && (value.fbeType === this.ClientReportsListGetResponseSenderModel.fbeType)) {
      return this.send_ClientReportsListGetResponse(value)
    }
    if ((value instanceof ClientReportGetRequest) && (value.fbeType === this.ClientReportGetRequestSenderModel.fbeType)) {
      return this.send_ClientReportGetRequest(value)
    }
    if ((value instanceof ClientReportGetResponse) && (value.fbeType === this.ClientReportGetResponseSenderModel.fbeType)) {
      return this.send_ClientReportGetResponse(value)
    }
    if ((value instanceof ClientReportsSendRequest) && (value.fbeType === this.ClientReportsSendRequestSenderModel.fbeType)) {
      return this.send_ClientReportsSendRequest(value)
    }
    if ((value instanceof ClientReportsSendResponse) && (value.fbeType === this.ClientReportsSendResponseSenderModel.fbeType)) {
      return this.send_ClientReportsSendResponse(value)
    }
    if ((value instanceof ChartBarsImportRequest) && (value.fbeType === this.ChartBarsImportRequestSenderModel.fbeType)) {
      return this.send_ChartBarsImportRequest(value)
    }
    if ((value instanceof ChartBarsImportResponse) && (value.fbeType === this.ChartBarsImportResponseSenderModel.fbeType)) {
      return this.send_ChartBarsImportResponse(value)
    }
    if ((value instanceof ChartBarsImportNotify) && (value.fbeType === this.ChartBarsImportNotifySenderModel.fbeType)) {
      return this.send_ChartBarsImportNotify(value)
    }
    if ((value instanceof ChartBarsImportFinishedNotify) && (value.fbeType === this.ChartBarsImportFinishedNotifySenderModel.fbeType)) {
      return this.send_ChartBarsImportFinishedNotify(value)
    }
    if ((value instanceof ClientReportsGetRequest) && (value.fbeType === this.ClientReportsGetRequestSenderModel.fbeType)) {
      return this.send_ClientReportsGetRequest(value)
    }
    if ((value instanceof ClientReportsGetResponse) && (value.fbeType === this.ClientReportsGetResponseSenderModel.fbeType)) {
      return this.send_ClientReportsGetResponse(value)
    }
    if ((value instanceof ClientReportsInsertRequest) && (value.fbeType === this.ClientReportsInsertRequestSenderModel.fbeType)) {
      return this.send_ClientReportsInsertRequest(value)
    }
    if ((value instanceof ClientReportsInsertResponse) && (value.fbeType === this.ClientReportsInsertResponseSenderModel.fbeType)) {
      return this.send_ClientReportsInsertResponse(value)
    }
    if ((value instanceof ClientReportsGetLastIdRequest) && (value.fbeType === this.ClientReportsGetLastIdRequestSenderModel.fbeType)) {
      return this.send_ClientReportsGetLastIdRequest(value)
    }
    if ((value instanceof ClientReportsGetLastIdResponse) && (value.fbeType === this.ClientReportsGetLastIdResponseSenderModel.fbeType)) {
      return this.send_ClientReportsGetLastIdResponse(value)
    }
    if ((value instanceof BlockedIPEventLogCreateParams) && (value.fbeType === this.BlockedIPEventLogCreateParamsSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateParams(value)
    }
    if ((value instanceof BlockedIPEventLogCreateRequest) && (value.fbeType === this.BlockedIPEventLogCreateRequestSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateRequest(value)
    }
    if ((value instanceof BlockedIPEventLogCreateResult) && (value.fbeType === this.BlockedIPEventLogCreateResultSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateResult(value)
    }
    if ((value instanceof BlockedIPEventLogCreateResponse) && (value.fbeType === this.BlockedIPEventLogCreateResponseSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogCreateResponse(value)
    }
    if ((value instanceof BlockedIPNotify) && (value.fbeType === this.BlockedIPNotifySenderModel.fbeType)) {
      return this.send_BlockedIPNotify(value)
    }
    if ((value instanceof BlockedIPEventLogGetParams) && (value.fbeType === this.BlockedIPEventLogGetParamsSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetParams(value)
    }
    if ((value instanceof BlockedIPEventLogGetRequest) && (value.fbeType === this.BlockedIPEventLogGetRequestSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetRequest(value)
    }
    if ((value instanceof BlockedIPEventLogGetResult) && (value.fbeType === this.BlockedIPEventLogGetResultSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetResult(value)
    }
    if ((value instanceof BlockedIPEventLogGetResponse) && (value.fbeType === this.BlockedIPEventLogGetResponseSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetResponse(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterParams) && (value.fbeType === this.BlockedIPEventLogGetFilterParamsSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterParams(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterRequest) && (value.fbeType === this.BlockedIPEventLogGetFilterRequestSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterRequest(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterResult) && (value.fbeType === this.BlockedIPEventLogGetFilterResultSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterResult(value)
    }
    if ((value instanceof BlockedIPEventLogGetFilterResponse) && (value.fbeType === this.BlockedIPEventLogGetFilterResponseSenderModel.fbeType)) {
      return this.send_BlockedIPEventLogGetFilterResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._entityClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send HistoryVersionRequest value
   * @this {!Client}
   * @param {!HistoryVersionRequest} value HistoryVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryVersionRequest serialization failed!')
    console.assert(this.HistoryVersionRequestSenderModel.verify(), 'history.HistoryVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HistoryVersionResponse value
   * @this {!Client}
   * @param {!HistoryVersionResponse} value HistoryVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryVersionResponse serialization failed!')
    console.assert(this.HistoryVersionResponseSenderModel.verify(), 'history.HistoryVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send HistoryUpdateNotify value
   * @this {!Client}
   * @param {!HistoryUpdateNotify} value HistoryUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_HistoryUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.HistoryUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.HistoryUpdateNotify serialization failed!')
    console.assert(this.HistoryUpdateNotifySenderModel.verify(), 'history.HistoryUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryRequest serialization failed!')
    console.assert(this.ChartBarHistoryRequestSenderModel.verify(), 'history.ChartBarHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryResponse value
   * @this {!Client}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryResponse serialization failed!')
    console.assert(this.ChartBarHistoryResponseSenderModel.verify(), 'history.ChartBarHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarNotify value
   * @this {!Client}
   * @param {!ChartBarNotify} value ChartBarNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarNotify serialization failed!')
    console.assert(this.ChartBarNotifySenderModel.verify(), 'history.ChartBarNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarUpdateNotify value
   * @this {!Client}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarUpdateNotify serialization failed!')
    console.assert(this.ChartBarUpdateNotifySenderModel.verify(), 'history.ChartBarUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryRequest value
   * @this {!Client}
   * @param {!TickHistoryRequest} value TickHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.TickHistoryRequest serialization failed!')
    console.assert(this.TickHistoryRequestSenderModel.verify(), 'history.TickHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickHistoryResponse value
   * @this {!Client}
   * @param {!TickHistoryResponse} value TickHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.TickHistoryResponse serialization failed!')
    console.assert(this.TickHistoryResponseSenderModel.verify(), 'history.TickHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryModifyRequest serialization failed!')
    console.assert(this.ChartBarHistoryModifyRequestSenderModel.verify(), 'history.ChartBarHistoryModifyRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryModifyResponse value
   * @this {!Client}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryModifyResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryModifyResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryModifyResponse serialization failed!')
    console.assert(this.ChartBarHistoryModifyResponseSenderModel.verify(), 'history.ChartBarHistoryModifyResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryRangeRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryRangeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryRangeRequestSenderModel.verify(), 'history.ChartBarHistoryRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryLastModifiedRangeRequest value
   * @this {!Client}
   * @param {!ChartBarHistoryLastModifiedRangeRequest} value ChartBarHistoryLastModifiedRangeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryLastModifiedRangeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryLastModifiedRangeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryLastModifiedRangeRequest serialization failed!')
    console.assert(this.ChartBarHistoryLastModifiedRangeRequestSenderModel.verify(), 'history.ChartBarHistoryLastModifiedRangeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarHistoryLastModifiedRangeResponse value
   * @this {!Client}
   * @param {!ChartBarHistoryLastModifiedRangeResponse} value ChartBarHistoryLastModifiedRangeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarHistoryLastModifiedRangeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarHistoryLastModifiedRangeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarHistoryLastModifiedRangeResponse serialization failed!')
    console.assert(this.ChartBarHistoryLastModifiedRangeResponseSenderModel.verify(), 'history.ChartBarHistoryLastModifiedRangeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarModifyNotify value
   * @this {!Client}
   * @param {!ChartBarModifyNotify} value ChartBarModifyNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarModifyNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarModifyNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarModifyNotify serialization failed!')
    console.assert(this.ChartBarModifyNotifySenderModel.verify(), 'history.ChartBarModifyNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetRequest value
   * @this {!Client}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsListGetRequest serialization failed!')
    console.assert(this.ClientReportsListGetRequestSenderModel.verify(), 'history.ClientReportsListGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsListGetResponse value
   * @this {!Client}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsListGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsListGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsListGetResponse serialization failed!')
    console.assert(this.ClientReportsListGetResponseSenderModel.verify(), 'history.ClientReportsListGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetRequest value
   * @this {!Client}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportGetRequest serialization failed!')
    console.assert(this.ClientReportGetRequestSenderModel.verify(), 'history.ClientReportGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportGetResponse value
   * @this {!Client}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportGetResponse serialization failed!')
    console.assert(this.ClientReportGetResponseSenderModel.verify(), 'history.ClientReportGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendRequest value
   * @this {!Client}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsSendRequest serialization failed!')
    console.assert(this.ClientReportsSendRequestSenderModel.verify(), 'history.ClientReportsSendRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsSendResponse value
   * @this {!Client}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsSendResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsSendResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsSendResponse serialization failed!')
    console.assert(this.ClientReportsSendResponseSenderModel.verify(), 'history.ClientReportsSendResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportRequest value
   * @this {!Client}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportRequest serialization failed!')
    console.assert(this.ChartBarsImportRequestSenderModel.verify(), 'history.ChartBarsImportRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportResponse value
   * @this {!Client}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportResponse serialization failed!')
    console.assert(this.ChartBarsImportResponseSenderModel.verify(), 'history.ChartBarsImportResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportNotify value
   * @this {!Client}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportNotify serialization failed!')
    console.assert(this.ChartBarsImportNotifySenderModel.verify(), 'history.ChartBarsImportNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ChartBarsImportFinishedNotify value
   * @this {!Client}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ChartBarsImportFinishedNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ChartBarsImportFinishedNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ChartBarsImportFinishedNotify serialization failed!')
    console.assert(this.ChartBarsImportFinishedNotifySenderModel.verify(), 'history.ChartBarsImportFinishedNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetRequest value
   * @this {!Client}
   * @param {!ClientReportsGetRequest} value ClientReportsGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetRequest serialization failed!')
    console.assert(this.ClientReportsGetRequestSenderModel.verify(), 'history.ClientReportsGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetResponse value
   * @this {!Client}
   * @param {!ClientReportsGetResponse} value ClientReportsGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetResponse serialization failed!')
    console.assert(this.ClientReportsGetResponseSenderModel.verify(), 'history.ClientReportsGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertRequest value
   * @this {!Client}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsInsertRequest serialization failed!')
    console.assert(this.ClientReportsInsertRequestSenderModel.verify(), 'history.ClientReportsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertResponse value
   * @this {!Client}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsInsertResponse serialization failed!')
    console.assert(this.ClientReportsInsertResponseSenderModel.verify(), 'history.ClientReportsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdRequest value
   * @this {!Client}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetLastIdRequest serialization failed!')
    console.assert(this.ClientReportsGetLastIdRequestSenderModel.verify(), 'history.ClientReportsGetLastIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdResponse value
   * @this {!Client}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.ClientReportsGetLastIdResponse serialization failed!')
    console.assert(this.ClientReportsGetLastIdResponseSenderModel.verify(), 'history.ClientReportsGetLastIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateParams value
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateParams} value BlockedIPEventLogCreateParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateParams serialization failed!')
    console.assert(this.BlockedIPEventLogCreateParamsSenderModel.verify(), 'history.BlockedIPEventLogCreateParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateRequest value
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateRequest} value BlockedIPEventLogCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateRequest serialization failed!')
    console.assert(this.BlockedIPEventLogCreateRequestSenderModel.verify(), 'history.BlockedIPEventLogCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateResult value
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateResult} value BlockedIPEventLogCreateResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateResult serialization failed!')
    console.assert(this.BlockedIPEventLogCreateResultSenderModel.verify(), 'history.BlockedIPEventLogCreateResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogCreateResponse value
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateResponse} value BlockedIPEventLogCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogCreateResponse serialization failed!')
    console.assert(this.BlockedIPEventLogCreateResponseSenderModel.verify(), 'history.BlockedIPEventLogCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPNotify value
   * @this {!Client}
   * @param {!BlockedIPNotify} value BlockedIPNotify value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPNotify serialization failed!')
    console.assert(this.BlockedIPNotifySenderModel.verify(), 'history.BlockedIPNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetParams value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetParams} value BlockedIPEventLogGetParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetParams serialization failed!')
    console.assert(this.BlockedIPEventLogGetParamsSenderModel.verify(), 'history.BlockedIPEventLogGetParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetRequest value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetRequest} value BlockedIPEventLogGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetRequest serialization failed!')
    console.assert(this.BlockedIPEventLogGetRequestSenderModel.verify(), 'history.BlockedIPEventLogGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetResult value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetResult} value BlockedIPEventLogGetResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetResult serialization failed!')
    console.assert(this.BlockedIPEventLogGetResultSenderModel.verify(), 'history.BlockedIPEventLogGetResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetResponse value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetResponse} value BlockedIPEventLogGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetResponse serialization failed!')
    console.assert(this.BlockedIPEventLogGetResponseSenderModel.verify(), 'history.BlockedIPEventLogGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterParams value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterParams} value BlockedIPEventLogGetFilterParams value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterParams (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterParamsSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterParams serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterParamsSenderModel.verify(), 'history.BlockedIPEventLogGetFilterParams validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterRequest value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterRequest} value BlockedIPEventLogGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterRequest serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterRequestSenderModel.verify(), 'history.BlockedIPEventLogGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterResult value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterResult} value BlockedIPEventLogGetFilterResult value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterResult (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterResultSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterResult serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterResultSenderModel.verify(), 'history.BlockedIPEventLogGetFilterResult validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BlockedIPEventLogGetFilterResponse value
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterResponse} value BlockedIPEventLogGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BlockedIPEventLogGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BlockedIPEventLogGetFilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'history.BlockedIPEventLogGetFilterResponse serialization failed!')
    console.assert(this.BlockedIPEventLogGetFilterResponseSenderModel.verify(), 'history.BlockedIPEventLogGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'history.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._entityClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._entityClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * HistoryVersionRequest receive handler
   * @this {!Client}
   * @param {!HistoryVersionRequest} value HistoryVersionRequest received value
   */
  onReceive_HistoryVersionRequest (value) {}  // eslint-disable-line

  /**
   * HistoryVersionResponse receive handler
   * @this {!Client}
   * @param {!HistoryVersionResponse} value HistoryVersionResponse received value
   */
  onReceive_HistoryVersionResponse (value) {}  // eslint-disable-line

  /**
   * HistoryUpdateNotify receive handler
   * @this {!Client}
   * @param {!HistoryUpdateNotify} value HistoryUpdateNotify received value
   */
  onReceive_HistoryUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryRequest} value ChartBarHistoryRequest received value
   */
  onReceive_ChartBarHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryResponse receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryResponse} value ChartBarHistoryResponse received value
   */
  onReceive_ChartBarHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarNotify receive handler
   * @this {!Client}
   * @param {!ChartBarNotify} value ChartBarNotify received value
   */
  onReceive_ChartBarNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarUpdateNotify receive handler
   * @this {!Client}
   * @param {!ChartBarUpdateNotify} value ChartBarUpdateNotify received value
   */
  onReceive_ChartBarUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickHistoryRequest receive handler
   * @this {!Client}
   * @param {!TickHistoryRequest} value TickHistoryRequest received value
   */
  onReceive_TickHistoryRequest (value) {}  // eslint-disable-line

  /**
   * TickHistoryResponse receive handler
   * @this {!Client}
   * @param {!TickHistoryResponse} value TickHistoryResponse received value
   */
  onReceive_TickHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryModifyRequest} value ChartBarHistoryModifyRequest received value
   */
  onReceive_ChartBarHistoryModifyRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryModifyResponse receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryModifyResponse} value ChartBarHistoryModifyResponse received value
   */
  onReceive_ChartBarHistoryModifyResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryRangeRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryRangeRequest} value ChartBarHistoryRangeRequest received value
   */
  onReceive_ChartBarHistoryRangeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeRequest receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryLastModifiedRangeRequest} value ChartBarHistoryLastModifiedRangeRequest received value
   */
  onReceive_ChartBarHistoryLastModifiedRangeRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarHistoryLastModifiedRangeResponse receive handler
   * @this {!Client}
   * @param {!ChartBarHistoryLastModifiedRangeResponse} value ChartBarHistoryLastModifiedRangeResponse received value
   */
  onReceive_ChartBarHistoryLastModifiedRangeResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarModifyNotify receive handler
   * @this {!Client}
   * @param {!ChartBarModifyNotify} value ChartBarModifyNotify received value
   */
  onReceive_ChartBarModifyNotify (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsListGetRequest} value ClientReportsListGetRequest received value
   */
  onReceive_ClientReportsListGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsListGetResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsListGetResponse} value ClientReportsListGetResponse received value
   */
  onReceive_ClientReportsListGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportGetRequest receive handler
   * @this {!Client}
   * @param {!ClientReportGetRequest} value ClientReportGetRequest received value
   */
  onReceive_ClientReportGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportGetResponse receive handler
   * @this {!Client}
   * @param {!ClientReportGetResponse} value ClientReportGetResponse received value
   */
  onReceive_ClientReportGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsSendRequest} value ClientReportsSendRequest received value
   */
  onReceive_ClientReportsSendRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsSendResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsSendResponse} value ClientReportsSendResponse received value
   */
  onReceive_ClientReportsSendResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportRequest receive handler
   * @this {!Client}
   * @param {!ChartBarsImportRequest} value ChartBarsImportRequest received value
   */
  onReceive_ChartBarsImportRequest (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportResponse receive handler
   * @this {!Client}
   * @param {!ChartBarsImportResponse} value ChartBarsImportResponse received value
   */
  onReceive_ChartBarsImportResponse (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportNotify receive handler
   * @this {!Client}
   * @param {!ChartBarsImportNotify} value ChartBarsImportNotify received value
   */
  onReceive_ChartBarsImportNotify (value) {}  // eslint-disable-line

  /**
   * ChartBarsImportFinishedNotify receive handler
   * @this {!Client}
   * @param {!ChartBarsImportFinishedNotify} value ChartBarsImportFinishedNotify received value
   */
  onReceive_ChartBarsImportFinishedNotify (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsGetRequest} value ClientReportsGetRequest received value
   */
  onReceive_ClientReportsGetRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsGetResponse} value ClientReportsGetResponse received value
   */
  onReceive_ClientReportsGetResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest received value
   */
  onReceive_ClientReportsInsertRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse received value
   */
  onReceive_ClientReportsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest received value
   */
  onReceive_ClientReportsGetLastIdRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse received value
   */
  onReceive_ClientReportsGetLastIdResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateParams receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateParams} value BlockedIPEventLogCreateParams received value
   */
  onReceive_BlockedIPEventLogCreateParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateRequest receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateRequest} value BlockedIPEventLogCreateRequest received value
   */
  onReceive_BlockedIPEventLogCreateRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResult receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateResult} value BlockedIPEventLogCreateResult received value
   */
  onReceive_BlockedIPEventLogCreateResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogCreateResponse receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogCreateResponse} value BlockedIPEventLogCreateResponse received value
   */
  onReceive_BlockedIPEventLogCreateResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPNotify receive handler
   * @this {!Client}
   * @param {!BlockedIPNotify} value BlockedIPNotify received value
   */
  onReceive_BlockedIPNotify (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetParams receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetParams} value BlockedIPEventLogGetParams received value
   */
  onReceive_BlockedIPEventLogGetParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetRequest receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetRequest} value BlockedIPEventLogGetRequest received value
   */
  onReceive_BlockedIPEventLogGetRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResult receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetResult} value BlockedIPEventLogGetResult received value
   */
  onReceive_BlockedIPEventLogGetResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetResponse receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetResponse} value BlockedIPEventLogGetResponse received value
   */
  onReceive_BlockedIPEventLogGetResponse (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterParams receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterParams} value BlockedIPEventLogGetFilterParams received value
   */
  onReceive_BlockedIPEventLogGetFilterParams (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterRequest receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterRequest} value BlockedIPEventLogGetFilterRequest received value
   */
  onReceive_BlockedIPEventLogGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResult receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterResult} value BlockedIPEventLogGetFilterResult received value
   */
  onReceive_BlockedIPEventLogGetFilterResult (value) {}  // eslint-disable-line

  /**
   * BlockedIPEventLogGetFilterResponse receive handler
   * @this {!Client}
   * @param {!BlockedIPEventLogGetFilterResponse} value BlockedIPEventLogGetFilterResponse received value
   */
  onReceive_BlockedIPEventLogGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * history receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case HistoryVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionRequestReceiverModel.verify(), 'history.HistoryVersionRequest validation failed!')
        let deserialized = this._HistoryVersionRequestReceiverModel.deserialize(this._HistoryVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.HistoryVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryVersionRequestReceiverValue)
        this.onReceive_HistoryVersionRequest(this._HistoryVersionRequestReceiverValue)
        return true
      }
      case HistoryVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryVersionResponseReceiverModel.verify(), 'history.HistoryVersionResponse validation failed!')
        let deserialized = this._HistoryVersionResponseReceiverModel.deserialize(this._HistoryVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.HistoryVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryVersionResponseReceiverValue)
        this.onReceive_HistoryVersionResponse(this._HistoryVersionResponseReceiverValue)
        return true
      }
      case HistoryUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._HistoryUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._HistoryUpdateNotifyReceiverModel.verify(), 'history.HistoryUpdateNotify validation failed!')
        let deserialized = this._HistoryUpdateNotifyReceiverModel.deserialize(this._HistoryUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.HistoryUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._HistoryUpdateNotifyReceiverValue)
        this.onReceive_HistoryUpdateNotify(this._HistoryUpdateNotifyReceiverValue)
        return true
      }
      case ChartBarHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRequestReceiverModel.verify(), 'history.ChartBarHistoryRequest validation failed!')
        let deserialized = this._ChartBarHistoryRequestReceiverModel.deserialize(this._ChartBarHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRequestReceiverValue)
        this.onReceive_ChartBarHistoryRequest(this._ChartBarHistoryRequestReceiverValue)
        return true
      }
      case ChartBarHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryResponseReceiverModel.verify(), 'history.ChartBarHistoryResponse validation failed!')
        let deserialized = this._ChartBarHistoryResponseReceiverModel.deserialize(this._ChartBarHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryResponseReceiverValue)
        this.onReceive_ChartBarHistoryResponse(this._ChartBarHistoryResponseReceiverValue)
        return true
      }
      case ChartBarNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarNotifyReceiverModel.verify(), 'history.ChartBarNotify validation failed!')
        let deserialized = this._ChartBarNotifyReceiverModel.deserialize(this._ChartBarNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarNotifyReceiverValue)
        this.onReceive_ChartBarNotify(this._ChartBarNotifyReceiverValue)
        return true
      }
      case ChartBarUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarUpdateNotifyReceiverModel.verify(), 'history.ChartBarUpdateNotify validation failed!')
        let deserialized = this._ChartBarUpdateNotifyReceiverModel.deserialize(this._ChartBarUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarUpdateNotifyReceiverValue)
        this.onReceive_ChartBarUpdateNotify(this._ChartBarUpdateNotifyReceiverValue)
        return true
      }
      case TickHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryRequestReceiverModel.verify(), 'history.TickHistoryRequest validation failed!')
        let deserialized = this._TickHistoryRequestReceiverModel.deserialize(this._TickHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.TickHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryRequestReceiverValue)
        this.onReceive_TickHistoryRequest(this._TickHistoryRequestReceiverValue)
        return true
      }
      case TickHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickHistoryResponseReceiverModel.verify(), 'history.TickHistoryResponse validation failed!')
        let deserialized = this._TickHistoryResponseReceiverModel.deserialize(this._TickHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.TickHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickHistoryResponseReceiverValue)
        this.onReceive_TickHistoryResponse(this._TickHistoryResponseReceiverValue)
        return true
      }
      case ChartBarHistoryModifyRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyRequestReceiverModel.verify(), 'history.ChartBarHistoryModifyRequest validation failed!')
        let deserialized = this._ChartBarHistoryModifyRequestReceiverModel.deserialize(this._ChartBarHistoryModifyRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryModifyRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyRequestReceiverValue)
        this.onReceive_ChartBarHistoryModifyRequest(this._ChartBarHistoryModifyRequestReceiverValue)
        return true
      }
      case ChartBarHistoryModifyResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryModifyResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryModifyResponseReceiverModel.verify(), 'history.ChartBarHistoryModifyResponse validation failed!')
        let deserialized = this._ChartBarHistoryModifyResponseReceiverModel.deserialize(this._ChartBarHistoryModifyResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryModifyResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryModifyResponseReceiverValue)
        this.onReceive_ChartBarHistoryModifyResponse(this._ChartBarHistoryModifyResponseReceiverValue)
        return true
      }
      case ChartBarHistoryRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryRangeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryRangeRequestReceiverModel.verify(), 'history.ChartBarHistoryRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryRangeRequestReceiverModel.deserialize(this._ChartBarHistoryRangeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryRangeRequestReceiverValue)
        this.onReceive_ChartBarHistoryRangeRequest(this._ChartBarHistoryRangeRequestReceiverValue)
        return true
      }
      case ChartBarHistoryLastModifiedRangeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryLastModifiedRangeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeRequestReceiverModel.verify(), 'history.ChartBarHistoryLastModifiedRangeRequest validation failed!')
        let deserialized = this._ChartBarHistoryLastModifiedRangeRequestReceiverModel.deserialize(this._ChartBarHistoryLastModifiedRangeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryLastModifiedRangeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryLastModifiedRangeRequestReceiverValue)
        this.onReceive_ChartBarHistoryLastModifiedRangeRequest(this._ChartBarHistoryLastModifiedRangeRequestReceiverValue)
        return true
      }
      case ChartBarHistoryLastModifiedRangeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarHistoryLastModifiedRangeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarHistoryLastModifiedRangeResponseReceiverModel.verify(), 'history.ChartBarHistoryLastModifiedRangeResponse validation failed!')
        let deserialized = this._ChartBarHistoryLastModifiedRangeResponseReceiverModel.deserialize(this._ChartBarHistoryLastModifiedRangeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarHistoryLastModifiedRangeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarHistoryLastModifiedRangeResponseReceiverValue)
        this.onReceive_ChartBarHistoryLastModifiedRangeResponse(this._ChartBarHistoryLastModifiedRangeResponseReceiverValue)
        return true
      }
      case ChartBarModifyNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarModifyNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarModifyNotifyReceiverModel.verify(), 'history.ChartBarModifyNotify validation failed!')
        let deserialized = this._ChartBarModifyNotifyReceiverModel.deserialize(this._ChartBarModifyNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarModifyNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarModifyNotifyReceiverValue)
        this.onReceive_ChartBarModifyNotify(this._ChartBarModifyNotifyReceiverValue)
        return true
      }
      case ClientReportsListGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetRequestReceiverModel.verify(), 'history.ClientReportsListGetRequest validation failed!')
        let deserialized = this._ClientReportsListGetRequestReceiverModel.deserialize(this._ClientReportsListGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsListGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetRequestReceiverValue)
        this.onReceive_ClientReportsListGetRequest(this._ClientReportsListGetRequestReceiverValue)
        return true
      }
      case ClientReportsListGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsListGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsListGetResponseReceiverModel.verify(), 'history.ClientReportsListGetResponse validation failed!')
        let deserialized = this._ClientReportsListGetResponseReceiverModel.deserialize(this._ClientReportsListGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsListGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsListGetResponseReceiverValue)
        this.onReceive_ClientReportsListGetResponse(this._ClientReportsListGetResponseReceiverValue)
        return true
      }
      case ClientReportGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetRequestReceiverModel.verify(), 'history.ClientReportGetRequest validation failed!')
        let deserialized = this._ClientReportGetRequestReceiverModel.deserialize(this._ClientReportGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetRequestReceiverValue)
        this.onReceive_ClientReportGetRequest(this._ClientReportGetRequestReceiverValue)
        return true
      }
      case ClientReportGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportGetResponseReceiverModel.verify(), 'history.ClientReportGetResponse validation failed!')
        let deserialized = this._ClientReportGetResponseReceiverModel.deserialize(this._ClientReportGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportGetResponseReceiverValue)
        this.onReceive_ClientReportGetResponse(this._ClientReportGetResponseReceiverValue)
        return true
      }
      case ClientReportsSendRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendRequestReceiverModel.verify(), 'history.ClientReportsSendRequest validation failed!')
        let deserialized = this._ClientReportsSendRequestReceiverModel.deserialize(this._ClientReportsSendRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsSendRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendRequestReceiverValue)
        this.onReceive_ClientReportsSendRequest(this._ClientReportsSendRequestReceiverValue)
        return true
      }
      case ClientReportsSendResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsSendResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsSendResponseReceiverModel.verify(), 'history.ClientReportsSendResponse validation failed!')
        let deserialized = this._ClientReportsSendResponseReceiverModel.deserialize(this._ClientReportsSendResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsSendResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsSendResponseReceiverValue)
        this.onReceive_ClientReportsSendResponse(this._ClientReportsSendResponseReceiverValue)
        return true
      }
      case ChartBarsImportRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportRequestReceiverModel.verify(), 'history.ChartBarsImportRequest validation failed!')
        let deserialized = this._ChartBarsImportRequestReceiverModel.deserialize(this._ChartBarsImportRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportRequestReceiverValue)
        this.onReceive_ChartBarsImportRequest(this._ChartBarsImportRequestReceiverValue)
        return true
      }
      case ChartBarsImportResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportResponseReceiverModel.verify(), 'history.ChartBarsImportResponse validation failed!')
        let deserialized = this._ChartBarsImportResponseReceiverModel.deserialize(this._ChartBarsImportResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportResponseReceiverValue)
        this.onReceive_ChartBarsImportResponse(this._ChartBarsImportResponseReceiverValue)
        return true
      }
      case ChartBarsImportNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportNotifyReceiverModel.verify(), 'history.ChartBarsImportNotify validation failed!')
        let deserialized = this._ChartBarsImportNotifyReceiverModel.deserialize(this._ChartBarsImportNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportNotifyReceiverValue)
        this.onReceive_ChartBarsImportNotify(this._ChartBarsImportNotifyReceiverValue)
        return true
      }
      case ChartBarsImportFinishedNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ChartBarsImportFinishedNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ChartBarsImportFinishedNotifyReceiverModel.verify(), 'history.ChartBarsImportFinishedNotify validation failed!')
        let deserialized = this._ChartBarsImportFinishedNotifyReceiverModel.deserialize(this._ChartBarsImportFinishedNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.ChartBarsImportFinishedNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ChartBarsImportFinishedNotifyReceiverValue)
        this.onReceive_ChartBarsImportFinishedNotify(this._ChartBarsImportFinishedNotifyReceiverValue)
        return true
      }
      case ClientReportsGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetRequestReceiverModel.verify(), 'history.ClientReportsGetRequest validation failed!')
        let deserialized = this._ClientReportsGetRequestReceiverModel.deserialize(this._ClientReportsGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetRequestReceiverValue)
        this.onReceive_ClientReportsGetRequest(this._ClientReportsGetRequestReceiverValue)
        return true
      }
      case ClientReportsGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetResponseReceiverModel.verify(), 'history.ClientReportsGetResponse validation failed!')
        let deserialized = this._ClientReportsGetResponseReceiverModel.deserialize(this._ClientReportsGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetResponseReceiverValue)
        this.onReceive_ClientReportsGetResponse(this._ClientReportsGetResponseReceiverValue)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestReceiverModel.verify(), 'history.ClientReportsInsertRequest validation failed!')
        let deserialized = this._ClientReportsInsertRequestReceiverModel.deserialize(this._ClientReportsInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertRequestReceiverValue)
        this.onReceive_ClientReportsInsertRequest(this._ClientReportsInsertRequestReceiverValue)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseReceiverModel.verify(), 'history.ClientReportsInsertResponse validation failed!')
        let deserialized = this._ClientReportsInsertResponseReceiverModel.deserialize(this._ClientReportsInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertResponseReceiverValue)
        this.onReceive_ClientReportsInsertResponse(this._ClientReportsInsertResponseReceiverValue)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestReceiverModel.verify(), 'history.ClientReportsGetLastIdRequest validation failed!')
        let deserialized = this._ClientReportsGetLastIdRequestReceiverModel.deserialize(this._ClientReportsGetLastIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetLastIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdRequestReceiverValue)
        this.onReceive_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestReceiverValue)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseReceiverModel.verify(), 'history.ClientReportsGetLastIdResponse validation failed!')
        let deserialized = this._ClientReportsGetLastIdResponseReceiverModel.deserialize(this._ClientReportsGetLastIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.ClientReportsGetLastIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdResponseReceiverValue)
        this.onReceive_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseReceiverValue)
        return true
      }
      case BlockedIPEventLogCreateParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateParamsReceiverModel.verify(), 'history.BlockedIPEventLogCreateParams validation failed!')
        let deserialized = this._BlockedIPEventLogCreateParamsReceiverModel.deserialize(this._BlockedIPEventLogCreateParamsReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateParamsReceiverValue)
        this.onReceive_BlockedIPEventLogCreateParams(this._BlockedIPEventLogCreateParamsReceiverValue)
        return true
      }
      case BlockedIPEventLogCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateRequestReceiverModel.verify(), 'history.BlockedIPEventLogCreateRequest validation failed!')
        let deserialized = this._BlockedIPEventLogCreateRequestReceiverModel.deserialize(this._BlockedIPEventLogCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateRequestReceiverValue)
        this.onReceive_BlockedIPEventLogCreateRequest(this._BlockedIPEventLogCreateRequestReceiverValue)
        return true
      }
      case BlockedIPEventLogCreateResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResultReceiverModel.verify(), 'history.BlockedIPEventLogCreateResult validation failed!')
        let deserialized = this._BlockedIPEventLogCreateResultReceiverModel.deserialize(this._BlockedIPEventLogCreateResultReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateResultReceiverValue)
        this.onReceive_BlockedIPEventLogCreateResult(this._BlockedIPEventLogCreateResultReceiverValue)
        return true
      }
      case BlockedIPEventLogCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogCreateResponseReceiverModel.verify(), 'history.BlockedIPEventLogCreateResponse validation failed!')
        let deserialized = this._BlockedIPEventLogCreateResponseReceiverModel.deserialize(this._BlockedIPEventLogCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogCreateResponseReceiverValue)
        this.onReceive_BlockedIPEventLogCreateResponse(this._BlockedIPEventLogCreateResponseReceiverValue)
        return true
      }
      case BlockedIPNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPNotifyReceiverModel.verify(), 'history.BlockedIPNotify validation failed!')
        let deserialized = this._BlockedIPNotifyReceiverModel.deserialize(this._BlockedIPNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPNotifyReceiverValue)
        this.onReceive_BlockedIPNotify(this._BlockedIPNotifyReceiverValue)
        return true
      }
      case BlockedIPEventLogGetParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetParamsReceiverModel.verify(), 'history.BlockedIPEventLogGetParams validation failed!')
        let deserialized = this._BlockedIPEventLogGetParamsReceiverModel.deserialize(this._BlockedIPEventLogGetParamsReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetParamsReceiverValue)
        this.onReceive_BlockedIPEventLogGetParams(this._BlockedIPEventLogGetParamsReceiverValue)
        return true
      }
      case BlockedIPEventLogGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetRequestReceiverModel.verify(), 'history.BlockedIPEventLogGetRequest validation failed!')
        let deserialized = this._BlockedIPEventLogGetRequestReceiverModel.deserialize(this._BlockedIPEventLogGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetRequestReceiverValue)
        this.onReceive_BlockedIPEventLogGetRequest(this._BlockedIPEventLogGetRequestReceiverValue)
        return true
      }
      case BlockedIPEventLogGetResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResultReceiverModel.verify(), 'history.BlockedIPEventLogGetResult validation failed!')
        let deserialized = this._BlockedIPEventLogGetResultReceiverModel.deserialize(this._BlockedIPEventLogGetResultReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetResultReceiverValue)
        this.onReceive_BlockedIPEventLogGetResult(this._BlockedIPEventLogGetResultReceiverValue)
        return true
      }
      case BlockedIPEventLogGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetResponseReceiverModel.verify(), 'history.BlockedIPEventLogGetResponse validation failed!')
        let deserialized = this._BlockedIPEventLogGetResponseReceiverModel.deserialize(this._BlockedIPEventLogGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetResponseReceiverValue)
        this.onReceive_BlockedIPEventLogGetResponse(this._BlockedIPEventLogGetResponseReceiverValue)
        return true
      }
      case BlockedIPEventLogGetFilterParamsModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterParamsReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterParamsReceiverModel.verify(), 'history.BlockedIPEventLogGetFilterParams validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterParamsReceiverModel.deserialize(this._BlockedIPEventLogGetFilterParamsReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterParams deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterParamsReceiverValue)
        this.onReceive_BlockedIPEventLogGetFilterParams(this._BlockedIPEventLogGetFilterParamsReceiverValue)
        return true
      }
      case BlockedIPEventLogGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterRequestReceiverModel.verify(), 'history.BlockedIPEventLogGetFilterRequest validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterRequestReceiverModel.deserialize(this._BlockedIPEventLogGetFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterRequestReceiverValue)
        this.onReceive_BlockedIPEventLogGetFilterRequest(this._BlockedIPEventLogGetFilterRequestReceiverValue)
        return true
      }
      case BlockedIPEventLogGetFilterResultModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterResultReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResultReceiverModel.verify(), 'history.BlockedIPEventLogGetFilterResult validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterResultReceiverModel.deserialize(this._BlockedIPEventLogGetFilterResultReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterResult deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterResultReceiverValue)
        this.onReceive_BlockedIPEventLogGetFilterResult(this._BlockedIPEventLogGetFilterResultReceiverValue)
        return true
      }
      case BlockedIPEventLogGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BlockedIPEventLogGetFilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BlockedIPEventLogGetFilterResponseReceiverModel.verify(), 'history.BlockedIPEventLogGetFilterResponse validation failed!')
        let deserialized = this._BlockedIPEventLogGetFilterResponseReceiverModel.deserialize(this._BlockedIPEventLogGetFilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'history.BlockedIPEventLogGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BlockedIPEventLogGetFilterResponseReceiverValue)
        this.onReceive_BlockedIPEventLogGetFilterResponse(this._BlockedIPEventLogGetFilterResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.entityClient != null) && this.entityClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._entityClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._entityClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._entityClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._entityClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._entityClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
