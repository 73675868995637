import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import {
    initReactI18next,
    TFunction,
    useTranslation as useI18next,
    withTranslation as withI18next,
} from 'react-i18next';
import translationEN from 'localization/translations/en-GB.json';
import translationCH from 'localization/translations/zh-CN.json';
import translationID from 'localization/translations/id-ID.json';
import translationMS from 'localization/translations/ms-MY.json';
import translationTH from 'localization/translations/th-TH.json';
import translationVI from 'localization/translations/vi-VN.json';
import { keyMapLocale as keyMap } from './TranslateWrapper';

const resources = {
    'en-GB': {
        translation: translationEN,
    },
    'zh-CN': {
        translation: translationCH,
    },
    'id-ID': {
        translation: translationID,
    },
    'ms-MY': {
        translation: translationMS,
    },
    'th-TH': {
        translation: translationTH,
    },
    'vi-VN': {
        translation: translationVI,
    },
};

const DEFAULT_LANGUAGE = 'en-GB';

const locales = Object.keys(resources) as (keyof typeof resources)[];

const useTranslation = (): TFunction<string> => useI18next().t;

const t = (key: string | undefined, params?): string => i18next.t(key ?? '', params);

const changeLanguage = (code: string): void => {
    if (!i18next.isInitialized) return;
    i18next.changeLanguage(code);
};

// @ts-ignore
i18next
    .use(LngDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: DEFAULT_LANGUAGE,
        debug: true,
        returnObjects: true,
        resources,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        },
    });

export { keyMap, locales, i18next, changeLanguage, useTranslation, t };

// TODO: delete after updating all translations
export default i18next;
