
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ManagerRights} from './ManagerRights';
import {FieldModelManagerRights} from './ManagerRights';
import {AuthenticationType} from './AuthenticationType';
import {FieldModelAuthenticationType} from './AuthenticationType';

/**
 * Manager struct
 */
class Manager {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} sortOrder
   * @param {!UUID=} managerGroupId
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} email
   * @param {boolean=} isEnabled
   * @param {boolean=} isOTPEnabled
   * @param {!boolean=} isManagerRightsSet
   * @param {ManagerRights=} managerRights
   * @param {!AuthenticationType=} authenticationType
   * @param {!string=} passwordDigest
   * @param {string=} oTPDigest
   * @param {!boolean=} isEnabledEffective
   * @param {!boolean=} isOTPEnabledEffective
   * @constructor
   */
  constructor (login = new UInt64(0, 0), sortOrder = 0, managerGroupId = new UUID(), name = '', comment = '', description = '', email = '', isEnabled = null, isOTPEnabled = null, isManagerRightsSet = false, managerRights = null, authenticationType = new AuthenticationType(), passwordDigest = '', oTPDigest = null, isEnabledEffective = false, isOTPEnabledEffective = false) {
    this.Login = login
    this.SortOrder = sortOrder
    this.ManagerGroupId = managerGroupId
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.Email = email
    this.IsEnabled = isEnabled
    this.IsOTPEnabled = isOTPEnabled
    this.IsManagerRightsSet = isManagerRightsSet
    this.ManagerRights = managerRights
    this.AuthenticationType = authenticationType
    this.PasswordDigest = passwordDigest
    this.OTPDigest = oTPDigest
    this.IsEnabledEffective = isEnabledEffective
    this.IsOTPEnabledEffective = isOTPEnabledEffective
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Manager}
   * @param {!Manager} other Other struct
   * @returns {!Manager} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.ManagerGroupId != null) {
      this.ManagerGroupId = new UUID(other.ManagerGroupId)
    } else {
      this.ManagerGroupId = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsOTPEnabled != null) {
      this.IsOTPEnabled = other.IsOTPEnabled
    } else {
      this.IsOTPEnabled = null
    }
    if (other.IsManagerRightsSet != null) {
      this.IsManagerRightsSet = other.IsManagerRightsSet
    } else {
      this.IsManagerRightsSet = null
    }
    if (other.ManagerRights != null) {
      this.ManagerRights = ManagerRights.fromObject(other.ManagerRights)
    } else {
      this.ManagerRights = null
    }
    if (other.AuthenticationType != null) {
      this.AuthenticationType = AuthenticationType.fromObject(other.AuthenticationType)
    } else {
      this.AuthenticationType = null
    }
    if (other.PasswordDigest != null) {
      this.PasswordDigest = other.PasswordDigest
    } else {
      this.PasswordDigest = null
    }
    if (other.OTPDigest != null) {
      this.OTPDigest = other.OTPDigest
    } else {
      this.OTPDigest = null
    }
    if (other.IsEnabledEffective != null) {
      this.IsEnabledEffective = other.IsEnabledEffective
    } else {
      this.IsEnabledEffective = null
    }
    if (other.IsOTPEnabledEffective != null) {
      this.IsOTPEnabledEffective = other.IsOTPEnabledEffective
    } else {
      this.IsOTPEnabledEffective = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Manager}
   * @returns {!Manager} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ManagerModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ManagerModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Manager}
   * @param {!Manager} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Manager)) {
      throw new TypeError('Instance of Manager is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.Login !== other.Login) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Manager}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      ManagerGroupId: ((this.ManagerGroupId != null) ? this.ManagerGroupId.toString() : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      Email: ((this.Email != null) ? this.Email : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsOTPEnabled: ((this.IsOTPEnabled != null) ? this.IsOTPEnabled : null),
      IsManagerRightsSet: ((this.IsManagerRightsSet != null) ? this.IsManagerRightsSet : null),
      ManagerRights: ((this.ManagerRights != null) ? this.ManagerRights : null),
      AuthenticationType: ((this.AuthenticationType != null) ? this.AuthenticationType : null),
      PasswordDigest: ((this.PasswordDigest != null) ? this.PasswordDigest : null),
      OTPDigest: ((this.OTPDigest != null) ? this.OTPDigest : null),
      IsEnabledEffective: ((this.IsEnabledEffective != null) ? this.IsEnabledEffective : null),
      IsOTPEnabledEffective: ((this.IsOTPEnabledEffective != null) ? this.IsOTPEnabledEffective : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Manager.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Manager} other Object value
   * @returns {!Manager} Created struct
   */
  static fromObject (other) {
    return new Manager().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Manager}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Manager.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Manager}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 42
  }
}

export { Manager };

/**
 * Fast Binary Encoding Manager field model
 */
class FieldModelManager extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelUInt32(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.ManagerGroupId = new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.ManagerGroupId.fbeOffset + this.ManagerGroupId.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Email.fbeOffset + this.Email.fbeSize), buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.IsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsManagerRightsSet = new fbe.FieldModelBool(buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize)
    this.ManagerRights = new fbe.FieldModelOptional(new FieldModelManagerRights(buffer, this.IsManagerRightsSet.fbeOffset + this.IsManagerRightsSet.fbeSize), buffer, this.IsManagerRightsSet.fbeOffset + this.IsManagerRightsSet.fbeSize)
    this.AuthenticationType = new FieldModelAuthenticationType(buffer, this.ManagerRights.fbeOffset + this.ManagerRights.fbeSize)
    this.PasswordDigest = new fbe.FieldModelString(buffer, this.AuthenticationType.fbeOffset + this.AuthenticationType.fbeSize)
    this.OTPDigest = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PasswordDigest.fbeOffset + this.PasswordDigest.fbeSize), buffer, this.PasswordDigest.fbeOffset + this.PasswordDigest.fbeSize)
    this.IsEnabledEffective = new fbe.FieldModelBool(buffer, this.OTPDigest.fbeOffset + this.OTPDigest.fbeSize)
    this.IsOTPEnabledEffective = new fbe.FieldModelBool(buffer, this.IsEnabledEffective.fbeOffset + this.IsEnabledEffective.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelManager}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelManager}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.SortOrder.fbeSize + this.ManagerGroupId.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.Email.fbeSize + this.IsEnabled.fbeSize + this.IsOTPEnabled.fbeSize + this.IsManagerRightsSet.fbeSize + this.ManagerRights.fbeSize + this.AuthenticationType.fbeSize + this.PasswordDigest.fbeSize + this.OTPDigest.fbeSize + this.IsEnabledEffective.fbeSize + this.IsOTPEnabledEffective.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelManager}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.SortOrder.fbeExtra + this.ManagerGroupId.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.Email.fbeExtra + this.IsEnabled.fbeExtra + this.IsOTPEnabled.fbeExtra + this.IsManagerRightsSet.fbeExtra + this.ManagerRights.fbeExtra + this.AuthenticationType.fbeExtra + this.PasswordDigest.fbeExtra + this.OTPDigest.fbeExtra + this.IsEnabledEffective.fbeExtra + this.IsOTPEnabledEffective.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelManager}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelManager.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelManager}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 42
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelManager}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelManager}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsManagerRightsSet.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsManagerRightsSet.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.ManagerRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerRights.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AuthenticationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.OTPDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OTPDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPDigest.fbeSize

    if ((fbeCurrentSize + this.IsEnabledEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabledEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabledEffective.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabledEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabledEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabledEffective.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelManager}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelManager}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelManager}
   * @param {!Manager} fbeValue Default value, defaults is new Manager()
   * @returns {!Manager} Manager value
   */
  get (fbeValue = new Manager()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelManager}
   * @param {!Manager} fbeValue Manager value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerGroupId = this.ManagerGroupId.get()
    } else {
      fbeValue.ManagerGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabled = this.IsOTPEnabled.get()
    } else {
      fbeValue.IsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsManagerRightsSet.fbeSize) <= fbeStructSize) {
      fbeValue.IsManagerRightsSet = this.IsManagerRightsSet.get()
    } else {
      fbeValue.IsManagerRightsSet = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.ManagerRights.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerRights = this.ManagerRights.get()
    } else {
      fbeValue.ManagerRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerRights.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) <= fbeStructSize) {
      fbeValue.AuthenticationType = this.AuthenticationType.get()
    } else {
      fbeValue.AuthenticationType = new AuthenticationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.PasswordDigest = this.PasswordDigest.get()
    } else {
      fbeValue.PasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.OTPDigest.fbeSize) <= fbeStructSize) {
      fbeValue.OTPDigest = this.OTPDigest.get()
    } else {
      fbeValue.OTPDigest = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPDigest.fbeSize

    if ((fbeCurrentSize + this.IsEnabledEffective.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabledEffective = this.IsEnabledEffective.get()
    } else {
      fbeValue.IsEnabledEffective = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabledEffective.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabledEffective.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabledEffective = this.IsOTPEnabledEffective.get()
    } else {
      fbeValue.IsOTPEnabledEffective = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabledEffective.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelManager}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelManager}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelManager}
   * @param {!Manager} fbeValue Manager value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelManager}
   * @param {Manager} fbeValue Manager value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.SortOrder.set(fbeValue.SortOrder)
    this.ManagerGroupId.set(fbeValue.ManagerGroupId)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.Email.set(fbeValue.Email)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsOTPEnabled.set(fbeValue.IsOTPEnabled)
    this.IsManagerRightsSet.set(fbeValue.IsManagerRightsSet)
    this.ManagerRights.set(fbeValue.ManagerRights)
    this.AuthenticationType.set(fbeValue.AuthenticationType)
    this.PasswordDigest.set(fbeValue.PasswordDigest)
    this.OTPDigest.set(fbeValue.OTPDigest)
    this.IsEnabledEffective.set(fbeValue.IsEnabledEffective)
    this.IsOTPEnabledEffective.set(fbeValue.IsOTPEnabledEffective)
  }
}

export { FieldModelManager };

/**
 * Fast Binary Encoding Manager model
 */
class ManagerModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelManager(this.buffer, 4)
  }

  /**
   * Get the Manager model
   * @this {!ManagerModel}
   * @returns {!FieldModelManager} model Manager model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ManagerModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ManagerModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ManagerModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ManagerModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelManager.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ManagerModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ManagerModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ManagerModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ManagerModel}
   * @param {!Manager} value Manager value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ManagerModel}
   * @param {!Manager} value Manager value, defaults is new Manager()
   * @return {!object} Deserialized Manager value and its size
   */
  deserialize (value = new Manager()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Manager(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Manager(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ManagerModel}
   * @param {!number} prev Previous Manager model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ManagerModel };
