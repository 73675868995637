import { makeAutoObservable } from 'mobx';
import { keyMap, t } from '@localization';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { UInt64 } from '@api/fbe/int64';
import { ErrorsTracker } from '@api/errorsTracker';
import { AccountGroup as AccountGroupApi } from '@api/fbe/core/AccountGroup';
import { AccountingType } from '@api/fbe/core/AccountingType';
import { StopOutType } from '@api/fbe/core/StopOutType';
import { SymbolGroupSettings as SymbolGroupSettingsApi } from '@api/fbe/core/SymbolGroupSettings';
import { TradingMode } from '@api/fbe/core/TradingMode';
import { AccountGroup, SymbolGroupSettings } from '@models/accounts/AccountGroup';
import { AccountGroupsGetAllRequest } from '@api/fbe/manager/AccountGroupsGetAllRequest';
import { AccountGroupCreateRequest } from '@api/fbe/manager/AccountGroupCreateRequest';
import { AccountGroupModifyRequest } from '@api/fbe/manager/AccountGroupModifyRequest';
import { AccountGroupDeleteRequest } from '@api/fbe/manager/AccountGroupDeleteRequest';

const convertFromAPI = (record: AccountGroupApi): AccountGroup => ({
    id: record.id.toString(),
    SortOrder: record.SortOrder,
    PlatformId: record.PlatformId.toString(),
    Name: record.Name,
    Comment: record.Comment,
    Description: record.Description,
    IsDemo: record.IsDemo,
    IsEnabled: record.IsEnabled,
    IsOTPEnabled: record.IsOTPEnabled,
    IsTradeEnabled: record.IsTradeEnabled,
    DefaultAccountingType: record.DefaultAccountingType.valueOf(),
    DefaultBalanceCurrencyId: record.DefaultBalanceCurrencyId.toString(),
    DefaultBalance: record.DefaultBalance,
    DefaultCredit: record.DefaultCredit,
    DefaultLeverage: record.DefaultLeverage,
    MarginCallLevel:
        record.MarginCallLevel !== undefined ? Number((record.MarginCallLevel * 100).toPrecision(10)) : null,
    MarginCallAccessType: record.MarginCallAccessType.valueOf(),
    StopOutLevel: record.StopOutLevel !== undefined ? Number((record.StopOutLevel * 100).toPrecision(10)) : null,
    StopOutType: record.StopOutType.valueOf(),
    StopOutAccessType: record.StopOutAccessType.valueOf(),
    DefaultMaxPendingOrders: +record.DefaultMaxPendingOrders.toNumber(),
    MaxDepositOperationAmount: record.MaxDepositOperationAmount ?? null,
    SymbolGroupSettings: Array.from(record.SymbolGroupSettings, ([key, value], index) => ({
        index,
        AccountGroupId: record.id.toString(),
        SymbolGroupId: key.toString(),
        IsEnabled: value.IsEnabled,
        IsTradeAllowed: value.IsTradeAllowed,
        MinTradeAmount: value.MinTradeAmount ?? null,
        MaxTradeAmount: value.MaxTradeAmount ?? null,
        TradeAmountStep: value.TradeAmountStep ?? null,
        UseDefaultSymbolTradeSettings: value.UseDefaultSymbolTradeSettings,
        // IsCommissionEnabled: value.IsCommissionEnabled,
        CommissionProfileId: value.CommissionProfileId ? value.CommissionProfileId.toString() : null,
        MarkupProfileId: value.MarkupProfileId ? value.MarkupProfileId.toString() : null,
    })),
    IsSwapEnabled: record.IsSwapEnabled,
    MinPasswordLength: +record.MinPasswordLength,
    // IsDailySnapshotsEnabled: record.IsDailySnapshotsEnabled,
    // IsEmailDailyShapshotsEnabled: record.IsEmailDailyShapshotsEnabled,
    IsSSLAccessEnabled: record.IsSSLAccessEnabled,
    IsHTTPSAccessEnabled: record.IsHTTPSAccessEnabled,
    IsWSSAccessEnabled: record.IsWSSAccessEnabled,
    IsFIXAccessEnabled: record.IsFIXAccessEnabled,
    Index: record.Index,
    MaxLeverage: record.MaxLeverage ?? null,
    DefaultMaxOrders: record.DefaultMaxOrders ? +record.DefaultMaxOrders.toNumber() : null,
    LeverageProfileId: record.LeverageProfileId ? record.LeverageProfileId.toString() : null,
    // MarginRateProfileId: record.MarginRateProfileId ? record.MarginRateProfileId.toString() : null,
});

const convertSymbolGroupSettings = (records: SymbolGroupSettings[]): Map<UUID, SymbolGroupSettingsApi> => {
    const map = new Map();
    records.forEach((item) => {
        if (item.SymbolGroupId) {
            map.set(
                UUID.fromString(item.SymbolGroupId),
                new SymbolGroupSettingsApi(
                    item.IsEnabled,
                    item.IsTradeAllowed,
                    item.MinTradeAmount,
                    item.MaxTradeAmount,
                    item.TradeAmountStep,
                    item.UseDefaultSymbolTradeSettings,
                    item.CommissionProfileId ? UUID.fromString(item.CommissionProfileId) : null,
                    item.MarkupProfileId ? UUID.fromString(item.MarkupProfileId) : null,
                ),
            );
        }
    });
    return map;
};

export class AccountsGroupsStore {
    errorTracker = new ErrorsTracker({ title: 'Accounts Groups' });

    constructor() {
        makeAutoObservable(this);
    }

    data: AccountGroup[] = [];

    isLoading = false;

    setData(value: AccountGroup[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    private getSymbolGroupsDeleteSet = (record: AccountGroup): Set<UUID> => {
        const targetRecord = this.data.find((item) => item.id === record.id);
        const SGStoDelete = targetRecord?.SymbolGroupSettings.filter(
            (item) => !record.SymbolGroupSettings.find((sgs) => sgs.SymbolGroupId === item.SymbolGroupId),
        ).map((item) => UUID.fromString(item.SymbolGroupId ?? ''));
        return targetRecord ? new Set(SGStoDelete) : new Set();
    };

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new AccountGroupsGetAllRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setIsLoading(false);
        this.setData(
            data.AccountGroups.filter((record) => record.DefaultAccountingType.eq(AccountingType.GROSS)).map(
                (record): AccountGroup => ({
                    ...convertFromAPI(record),
                }),
            ),
        );
    }

    @ErrorsTracker.wrapApi()
    async create(record: AccountGroup) {
        const request = new AccountGroupCreateRequest();
        if (record.PlatformId) request.PlatformId = new UUID(record.PlatformId);
        if (record.Name) request.Name = record.Name;
        if (record.Comment !== null) request.Comment = record.Comment;
        if (record.Description !== null) request.Description = record.Description;
        if (record.IsDemo !== null) request.IsDemo = record.IsDemo;
        if (record.IsEnabled !== null) request.IsEnabled = record.IsEnabled;
        if (record.IsOTPEnabled !== null) request.IsOTPEnabled = record.IsOTPEnabled;
        if (record.IsTradeEnabled !== null) request.IsTradeEnabled = record.IsTradeEnabled;
        request.DefaultAccountingType = AccountingType.GROSS;
        if (record.DefaultBalanceCurrencyId !== null)
            request.DefaultBalanceCurrencyId = new UUID(record.DefaultBalanceCurrencyId);
        if (record.DefaultBalance !== null) request.DefaultBalance = record.DefaultBalance;
        if (record.DefaultCredit !== null) request.DefaultCredit = record.DefaultCredit;
        if (record.DefaultLeverage !== null) request.DefaultLeverage = record.DefaultLeverage;
        if (record.MarginCallLevel !== null) request.MarginCallLevel = record.MarginCallLevel / 100;
        if (record.MarginCallAccessType !== null)
            request.MarginCallAccessType = new TradingMode(record.MarginCallAccessType);
        if (record.StopOutLevel !== null) request.StopOutLevel = record.StopOutLevel / 100;
        if (record.StopOutType !== null) request.StopOutType = new StopOutType(record.StopOutType);
        if (record.StopOutAccessType !== null) request.StopOutAccessType = new TradingMode(record.StopOutAccessType);
        if (record.DefaultMaxPendingOrders !== null)
            request.DefaultMaxPendingOrders = UInt64.fromNumber(record.DefaultMaxPendingOrders);
        if (record.MaxDepositOperationAmount !== null)
            request.MaxDepositOperationAmount = record.DefaultMaxPendingOrders;
        // MaxDepositOperationAmount is not set if group is not demo
        if (!record.IsDemo) {
            request.MaxDepositOperationAmount = null;
        }
        if (record.SymbolGroupSettings.length > 0)
            request.SymbolGroupSettings = convertSymbolGroupSettings(record.SymbolGroupSettings);
        if (record.IsSwapEnabled !== null) request.IsSwapEnabled = record.IsSwapEnabled;

        // hidden for First Release TP-2651
        if (record.MinPasswordLength !== null) request.MinPasswordLength = record.MinPasswordLength;
        // if (record.IsDailySnapshotsEnabled !== null) request.IsDailySnapshotsEnabled = record.IsDailySnapshotsEnabled;

        // if (record.IsEmailDailyShapshotsEnabled !== null)
        //     request.IsEmailDailyShapshotsEnabled = record.IsEmailDailyShapshotsEnabled;

        // hidden for First Release TP-2651
        // if (record.IsSSLAccessEnabled !== null) request.IsSSLAccessEnabled = record.IsSSLAccessEnabled;
        // if (record.IsHTTPSAccessEnabled !== null) request.IsHTTPSAccessEnabled = record.IsHTTPSAccessEnabled;
        // if (record.IsWSSAccessEnabled !== null) request.IsWSSAccessEnabled = record.IsWSSAccessEnabled;
        // if (record.IsFIXAccessEnabled !== null) request.IsFIXAccessEnabled = record.IsFIXAccessEnabled;

        if (record.MaxLeverage !== null) request.MaxLeverage = record.MaxLeverage;
        if (record.LeverageProfileId) request.LeverageProfileId = new UUID(record.LeverageProfileId);
        // if (record.MarginRateProfileId) request.MarginRateProfileId = new UUID(record.MarginRateProfileId);
        if (record.DefaultMaxOrders !== null) request.DefaultMaxOrders = UInt64.fromNumber(record.DefaultMaxOrders);

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: AccountGroup, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new AccountGroupModifyRequest();
        if (record.id) request.AccountGroupId = new UUID(record.id);
        if (dirtyFields?.PlatformId) request.NewPlatformId = record.PlatformId ? new UUID(record.PlatformId) : null;
        if (dirtyFields?.Name) request.NewName = record.Name;
        if (dirtyFields?.Comment) request.NewComment = record.Comment;
        if (dirtyFields?.Description) request.NewDescription = record.Description;
        if (dirtyFields?.IsDemo) request.NewIsDemo = record.IsDemo;
        if (dirtyFields?.IsEnabled) request.NewIsEnabled = record.IsEnabled;
        if (dirtyFields?.IsOTPEnabled) request.NewIsOTPEnabled = record.IsOTPEnabled;
        if (dirtyFields?.IsTradeEnabled) request.NewIsTradeEnabled = record.IsTradeEnabled;
        request.NewDefaultAccountingType = AccountingType.GROSS;
        if (dirtyFields?.DefaultBalanceCurrencyId)
            request.NewDefaultBalanceCurrencyId =
                record.DefaultBalanceCurrencyId === null ? null : new UUID(record.DefaultBalanceCurrencyId);
        if (dirtyFields?.DefaultBalance) request.NewDefaultBalance = record.DefaultBalance;
        if (dirtyFields?.DefaultCredit) request.NewDefaultCredit = record.DefaultCredit;
        if (dirtyFields?.DefaultLeverage) request.NewDefaultLeverage = record.DefaultLeverage;
        if (dirtyFields?.MarginCallLevel && record.MarginCallLevel !== null)
            request.NewMarginCallLevel = record.MarginCallLevel / 100;
        if (dirtyFields?.MarginCallAccessType)
            request.NewMarginCallAccessType =
                record.MarginCallAccessType === null ? null : new TradingMode(record.MarginCallAccessType);
        if (dirtyFields?.StopOutLevel && record.StopOutLevel) request.NewStopOutLevel = record.StopOutLevel / 100;
        if (dirtyFields?.StopOutType)
            request.NewStopOutType = record.StopOutType === null ? null : new StopOutType(record.StopOutType);
        if (dirtyFields?.StopOutAccessType)
            request.NewStopOutAccessType =
                record.StopOutAccessType === null ? null : new TradingMode(record.StopOutAccessType);
        if (dirtyFields?.DefaultMaxPendingOrders)
            request.NewDefaultMaxPendingOrders =
                record.DefaultMaxPendingOrders === null ? null : UInt64.fromNumber(record.DefaultMaxPendingOrders);
        if (dirtyFields?.MaxDepositOperationAmount) {
            request.NewMaxDepositOperationAmount = record.MaxDepositOperationAmount;
            if (record.MaxDepositOperationAmount === null) {
                request.NewMaxDepositOperationAmountReset = true;
            }
        }
        // MaxDepositOperationAmount is not set if group is not demo
        if (dirtyFields?.IsDemo && !record.IsDemo) {
            request.NewMaxDepositOperationAmountReset = true;
        }
        if (dirtyFields?.SymbolGroupSettings) {
            request.SymbolGroupSettingsToDelete = this.getSymbolGroupsDeleteSet(record);
            request.SymbolGroupSettingsToModify = convertSymbolGroupSettings(record.SymbolGroupSettings);
        }
        if (dirtyFields?.IsSwapEnabled) request.NewIsSwapEnabled = record.IsSwapEnabled;

        if (dirtyFields?.MinPasswordLength)
            request.NewMinPasswordLength = record.MinPasswordLength === null ? null : record.MinPasswordLength;
        // hidden for First Release TP-2651
        // if (dirtyFields?.IsDailySnapshotsEnabled) request.NewIsDailySnapshotsEnabled = record.IsDailySnapshotsEnabled;

        // if (dirtyFields?.IsEmailDailyShapshotsEnabled)
        //     request.NewIsEmailDailyShapshotsEnabled = record.IsEmailDailyShapshotsEnabled;

        // hidden for First Release TP-2651
        if (dirtyFields?.IsSSLAccessEnabled) request.NewIsSSLAccessEnabled = record.IsSSLAccessEnabled;
        if (dirtyFields?.IsHTTPSAccessEnabled) request.NewIsHTTPSAccessEnabled = record.IsHTTPSAccessEnabled;
        if (dirtyFields?.IsWSSAccessEnabled) request.NewIsWSSAccessEnabled = record.IsWSSAccessEnabled;
        if (dirtyFields?.IsFIXAccessEnabled) request.NewIsFIXAccessEnabled = record.IsFIXAccessEnabled;

        if (dirtyFields?.MaxLeverage) request.NewMaxLeverage = record.MaxLeverage;
        if (dirtyFields?.MaxLeverage && record.MaxLeverage === null) request.NewMaxLeverageReset = true;
        if (dirtyFields?.LeverageProfileId)
            request.NewLeverageProfileId = record.LeverageProfileId ? new UUID(record.LeverageProfileId) : null;
        if (dirtyFields?.LeverageProfileId && !record.LeverageProfileId) request.NewLeverageProfileIdReset = true;
        // if (dirtyFields?.MarginRateProfileId)
        //     request.NewMarginRateProfileId = record.MarginRateProfileId ? new UUID(record.MarginRateProfileId) : null;
        // if (dirtyFields?.MarginRateProfileId && !record.MarginRateProfileId)
        //     request.NewMarginRateProfileIdReset = true;
        if (dirtyFields?.DefaultMaxOrders)
            request.NewDefaultMaxOrders =
                record.DefaultMaxOrders !== null ? UInt64.fromNumber(record.DefaultMaxOrders) : null;
        await SocketClient.instance.request(request);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new AccountGroupDeleteRequest();
        request.AccountGroupId = UUID.fromString(id);
        await SocketClient.instance.request(request);
        await this.get();
    }

    async updateItemSortOrder(id: string | null, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (id && targetItem) {
            const request = new AccountGroupModifyRequest();
            request.AccountGroupId = new UUID(id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            await this.get();
        } else {
            throw new Error(t(keyMap.messages.accountGroups.reorderError));
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const accountsGroupsStore = new AccountsGroupsStore();

export { accountsGroupsStore };
