
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * OrdersClosedSummary struct
 */
class OrdersClosedSummary {
  /**
   * Initialize struct
   * @param {!string=} accountCurrencyName
   * @param {!number=} profit
   * @param {!number=} swap
   * @param {!number=} commission
   * @param {number=} bidConversionPrice
   * @param {number=} askConversionPrice
   * @constructor
   */
  constructor (accountCurrencyName = '', profit = 0.0, swap = 0.0, commission = 0.0, bidConversionPrice = null, askConversionPrice = null) {
    this.AccountCurrencyName = accountCurrencyName
    this.Profit = profit
    this.Swap = swap
    this.Commission = commission
    this.BidConversionPrice = bidConversionPrice
    this.AskConversionPrice = askConversionPrice
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrdersClosedSummary}
   * @param {!OrdersClosedSummary} other Other struct
   * @returns {!OrdersClosedSummary} This struct
   */
  copy (other) {
    if (other.AccountCurrencyName != null) {
      this.AccountCurrencyName = other.AccountCurrencyName
    } else {
      this.AccountCurrencyName = null
    }
    if (other.Profit != null) {
      this.Profit = other.Profit
    } else {
      this.Profit = null
    }
    if (other.Swap != null) {
      this.Swap = other.Swap
    } else {
      this.Swap = null
    }
    if (other.Commission != null) {
      this.Commission = other.Commission
    } else {
      this.Commission = null
    }
    if (other.BidConversionPrice != null) {
      this.BidConversionPrice = other.BidConversionPrice
    } else {
      this.BidConversionPrice = null
    }
    if (other.AskConversionPrice != null) {
      this.AskConversionPrice = other.AskConversionPrice
    } else {
      this.AskConversionPrice = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrdersClosedSummary}
   * @returns {!OrdersClosedSummary} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrdersClosedSummaryModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrdersClosedSummaryModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrdersClosedSummary}
   * @param {!OrdersClosedSummary} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrdersClosedSummary)) {
      throw new TypeError('Instance of OrdersClosedSummary is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrdersClosedSummary}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountCurrencyName: ((this.AccountCurrencyName != null) ? this.AccountCurrencyName : null),
      Profit: ((this.Profit != null) ? this.Profit : null),
      Swap: ((this.Swap != null) ? this.Swap : null),
      Commission: ((this.Commission != null) ? this.Commission : null),
      BidConversionPrice: ((this.BidConversionPrice != null) ? this.BidConversionPrice : null),
      AskConversionPrice: ((this.AskConversionPrice != null) ? this.AskConversionPrice : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrdersClosedSummary.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrdersClosedSummary} other Object value
   * @returns {!OrdersClosedSummary} Created struct
   */
  static fromObject (other) {
    return new OrdersClosedSummary().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrdersClosedSummary}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrdersClosedSummary.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrdersClosedSummary}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 189
  }
}

export { OrdersClosedSummary };

/**
 * Fast Binary Encoding OrdersClosedSummary field model
 */
class FieldModelOrdersClosedSummary extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountCurrencyName = new fbe.FieldModelString(buffer, 4 + 4)
    this.Profit = new fbe.FieldModelDouble(buffer, this.AccountCurrencyName.fbeOffset + this.AccountCurrencyName.fbeSize)
    this.Swap = new fbe.FieldModelDouble(buffer, this.Profit.fbeOffset + this.Profit.fbeSize)
    this.Commission = new fbe.FieldModelDouble(buffer, this.Swap.fbeOffset + this.Swap.fbeSize)
    this.BidConversionPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Commission.fbeOffset + this.Commission.fbeSize), buffer, this.Commission.fbeOffset + this.Commission.fbeSize)
    this.AskConversionPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BidConversionPrice.fbeOffset + this.BidConversionPrice.fbeSize), buffer, this.BidConversionPrice.fbeOffset + this.BidConversionPrice.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountCurrencyName.fbeSize + this.Profit.fbeSize + this.Swap.fbeSize + this.Commission.fbeSize + this.BidConversionPrice.fbeSize + this.AskConversionPrice.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountCurrencyName.fbeExtra + this.Profit.fbeExtra + this.Swap.fbeExtra + this.Commission.fbeExtra + this.BidConversionPrice.fbeExtra + this.AskConversionPrice.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrdersClosedSummary.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 189
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountCurrencyName.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Swap.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Commission.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.BidConversionPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BidConversionPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BidConversionPrice.fbeSize

    if ((fbeCurrentSize + this.AskConversionPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AskConversionPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AskConversionPrice.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!OrdersClosedSummary} fbeValue Default value, defaults is new OrdersClosedSummary()
   * @returns {!OrdersClosedSummary} OrdersClosedSummary value
   */
  get (fbeValue = new OrdersClosedSummary()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!OrdersClosedSummary} fbeValue OrdersClosedSummary value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountCurrencyName = this.AccountCurrencyName.get()
    } else {
      fbeValue.AccountCurrencyName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountCurrencyName.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) <= fbeStructSize) {
      fbeValue.Profit = this.Profit.get()
    } else {
      fbeValue.Profit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) <= fbeStructSize) {
      fbeValue.Swap = this.Swap.get()
    } else {
      fbeValue.Swap = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) <= fbeStructSize) {
      fbeValue.Commission = this.Commission.get()
    } else {
      fbeValue.Commission = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.BidConversionPrice.fbeSize) <= fbeStructSize) {
      fbeValue.BidConversionPrice = this.BidConversionPrice.get()
    } else {
      fbeValue.BidConversionPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BidConversionPrice.fbeSize

    if ((fbeCurrentSize + this.AskConversionPrice.fbeSize) <= fbeStructSize) {
      fbeValue.AskConversionPrice = this.AskConversionPrice.get()
    } else {
      fbeValue.AskConversionPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AskConversionPrice.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrdersClosedSummary}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrdersClosedSummary}
   * @param {!OrdersClosedSummary} fbeValue OrdersClosedSummary value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrdersClosedSummary}
   * @param {OrdersClosedSummary} fbeValue OrdersClosedSummary value
   */
  setFields (fbeValue) {
    this.AccountCurrencyName.set(fbeValue.AccountCurrencyName)
    this.Profit.set(fbeValue.Profit)
    this.Swap.set(fbeValue.Swap)
    this.Commission.set(fbeValue.Commission)
    this.BidConversionPrice.set(fbeValue.BidConversionPrice)
    this.AskConversionPrice.set(fbeValue.AskConversionPrice)
  }
}

export { FieldModelOrdersClosedSummary };

/**
 * Fast Binary Encoding OrdersClosedSummary model
 */
class OrdersClosedSummaryModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrdersClosedSummary(this.buffer, 4)
  }

  /**
   * Get the OrdersClosedSummary model
   * @this {!OrdersClosedSummaryModel}
   * @returns {!FieldModelOrdersClosedSummary} model OrdersClosedSummary model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrdersClosedSummaryModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrdersClosedSummaryModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrdersClosedSummaryModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrdersClosedSummaryModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrdersClosedSummary.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrdersClosedSummaryModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrdersClosedSummaryModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrdersClosedSummaryModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrdersClosedSummaryModel}
   * @param {!OrdersClosedSummary} value OrdersClosedSummary value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrdersClosedSummaryModel}
   * @param {!OrdersClosedSummary} value OrdersClosedSummary value, defaults is new OrdersClosedSummary()
   * @return {!object} Deserialized OrdersClosedSummary value and its size
   */
  deserialize (value = new OrdersClosedSummary()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrdersClosedSummary(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrdersClosedSummary(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrdersClosedSummaryModel}
   * @param {!number} prev Previous OrdersClosedSummary model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrdersClosedSummaryModel };
