import { ErrorsTracker } from '@api/errorsTracker';
import { ProtocolVersionMajor, ProtocolVersionMinor } from '@api/fbe/manager';
import { makeAutoObservable } from 'mobx';
import { ServerVersionRequest } from '@api/fbe/exposed/ServerVersionRequest';
import { SocketClient } from '@api/SocketClient';

type Version = {
    Major: number;
    Minor: number;
    Build: number;
};

declare const window: any;
export class ConfigStore {
    errorTracker = new ErrorsTracker({ title: 'Config' });

    constructor() {
        makeAutoObservable(this);
    }

    backendVersion: Version | null = null;

    backendVersionString: string = `${ProtocolVersionMajor}.${ProtocolVersionMinor}`;

    setBackendVersion(value: Version | null) {
        this.backendVersion = value;
    }

    setBackendVersionString(value: string) {
        this.backendVersionString = value;
    }

    @ErrorsTracker.wrapApi()
    async getBackendVersion() {
        const request = new ServerVersionRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        const { Major, Minor, Revision, Build } = data.ServerVersion;
        this.setBackendVersion({ Major, Minor, Build });
        this.setBackendVersionString(`${Major}.${Minor}.${Revision}.${Build}`);
    }
}

export const configStore = new ConfigStore();
