import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { OrderSummaryRequestParams, OrderSummaryState } from '@models/riskManagement/OrderSummary';
import { FilledOrdersSummaryInfoSubscribeRequest } from '@api/fbe/manager/FilledOrdersSummaryInfoSubscribeRequest';
import { FilledOrdersSummaryInfoUnsubscribeRequest } from '@api/fbe/manager/FilledOrdersSummaryInfoUnsubscribeRequest';
import { FilledOrdersSummaryInfoSubscribeResponse } from '@api/fbe/manager/FilledOrdersSummaryInfoSubscribeResponse';
import { FilledOrdersSummaryInfoNotify } from '@api/fbe/manager/FilledOrdersSummaryInfoNotify';
import { FilledOrdersSummaryInfoUpdateSubscriptionRequest } from '@api/fbe/manager/FilledOrdersSummaryInfoUpdateSubscriptionRequest';
import { adaptApi } from '@utils/models';
import { FilledOrdersSummaryInfo } from '@api/fbe/manager/FilledOrdersSummaryInfo';

const UPDATE_INTERVAL_DELAY = 3000;

class OrderSummaryStore {
    errorTracker = new ErrorsTracker({ title: 'Order Summary' });

    constructor() {
        makeAutoObservable(this);
    }

    data: OrderSummaryState = {
        items: [],
        total: null,
    };

    setData(value: OrderSummaryState) {
        this.data = value;
    }

    dataFromNotify: FilledOrdersSummaryInfo | null = null;

    setDataFromNotify(value: FilledOrdersSummaryInfo | null) {
        this.dataFromNotify = value;
    }

    isLoading = false;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    isSubscribed = false;

    setIsSubscribed(value: boolean) {
        this.isSubscribed = value;
    }

    requestParams: OrderSummaryRequestParams | null = null;

    setRequestParams = (params: OrderSummaryRequestParams | null): void => {
        this.requestParams = params;
    };

    updateInterval: NodeJS.Timeout | null = null;

    private notifyCallback = (data: FilledOrdersSummaryInfoNotify): void => {
        this.setIsLoading(false);
        this.setDataFromNotify(data.SummaryInfo);
    };

    private subscribeCallback = (data: FilledOrdersSummaryInfoNotify): void => {
        this.setIsLoading(false);
        this.setIsSubscribed(true);
        this.setData({
            items: data.SummaryInfo.SymbolsInfo.map((item, index) => ({ key: index, ...adaptApi(item) })),
            total: { ...adaptApi(data.SummaryInfo.Total) },
        });
    };

    private addListener() {
        SocketClient.instance.responseListener.addListener(
            FilledOrdersSummaryInfoSubscribeResponse,
            this.subscribeCallback,
        );
        SocketClient.instance.responseListener.addListener(FilledOrdersSummaryInfoNotify, this.notifyCallback);
    }

    private updateData = () => {
        if (this.dataFromNotify) {
            this.setData({
                items: this.dataFromNotify.SymbolsInfo.map((item, index) => ({ key: index, ...adaptApi(item) })),
                total: { ...adaptApi(this.dataFromNotify.Total) },
            });
        }
    };

    private clearIntervalNotifyUpd = () => {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    @ErrorsTracker.wrapApi()
    subscribe() {
        if (this.requestParams) {
            this.setIsLoading(true);
            this.setDataFromNotify(null);
            this.clearIntervalNotifyUpd();

            this.updateInterval = setInterval(() => {
                this.updateData();
            }, UPDATE_INTERVAL_DELAY);

            const request = new FilledOrdersSummaryInfoSubscribeRequest();
            request.IsCommissionAndSwapsIncluded = this.requestParams.isCommissionAndSwapsIncluded;
            request.ProfitCurrency = this.requestParams.profitCurrency;
            SocketClient.instance.sendRequest(request);
            this.addListener();
        }
    }

    @ErrorsTracker.wrapApi()
    updateSubscription() {
        if (!this.isSubscribed) {
            this.subscribe();
            return;
        }
        if (this.requestParams) {
            this.setIsLoading(true);
            this.setDataFromNotify(null);
            this.clearIntervalNotifyUpd();

            this.updateInterval = setInterval(() => {
                this.updateData();
            }, UPDATE_INTERVAL_DELAY);
            const request = new FilledOrdersSummaryInfoUpdateSubscriptionRequest();
            request.NewIsCommissionAndSwapsIncluded = this.requestParams.isCommissionAndSwapsIncluded;
            request.NewProfitCurrency = this.requestParams.profitCurrency;
            SocketClient.instance.sendRequest(request);
        }
    }

    @ErrorsTracker.wrapApi()
    unsubscribe() {
        this.setIsSubscribed(false);
        const request = new FilledOrdersSummaryInfoUnsubscribeRequest();
        SocketClient.instance.sendRequest(request);

        SocketClient.instance.responseListener.removeListener(
            FilledOrdersSummaryInfoSubscribeResponse,
            this.subscribeCallback,
        );
        SocketClient.instance.responseListener.removeListener(FilledOrdersSummaryInfoNotify, this.notifyCallback);
        this.reset();
    }

    reset() {
        this.data = {
            items: [],
            total: null,
        };
        this.dataFromNotify = null;
        this.clearIntervalNotifyUpd();
        this.isLoading = false;
        this.isSubscribed = false;
        this.requestParams = null;
    }
}

export const orderSummaryStore = new OrderSummaryStore();
