import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { TickSnapshotNotify } from '@api/fbe/feed/TickSnapshotNotify';
import { ArrowDirection, Tick } from '@App/pages/Market/types';
import { adaptApi } from '@utils/models';
import { TickHistoryRequest } from '@api/fbe/manager/TickHistoryRequest';
import { FeedSubscribeAllRequest } from '@api/fbe/feed/FeedSubscribeAllRequest';
import { FeedSubscribeResponse } from '@api/fbe/feed/FeedSubscribeResponse';
import { ITick, TicksRequestParams } from '@models/Tick';
import { UUID } from '@api/fbe/uuid';
import { FeedUnsubscribeAllRequest } from '@api/fbe/feed/FeedUnsubscribeAllRequest';
import { Message, MessageType } from '@components/Message/Message';

export class TicksStore {
    errorTracker = new ErrorsTracker({ title: 'Ticks' });

    constructor() {
        makeAutoObservable(this);
    }

    requestParams: TicksRequestParams | null = null;

    setTicksRequestParams = (params: TicksRequestParams | null): void => {
        this.requestParams = params;
    };

    data: ITick[] = [];

    setData(value: ITick[]) {
        this.data = value;
    }

    isLoading: boolean = false;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    liveTicks: Tick[] | null = null;

    setLiveTicks(value: Tick[] | null) {
        this.liveTicks = value;
    }

    private convertTicksResponse = (data: TickSnapshotNotify) => {
        const dataFromMap = Array.from(data.Ticks).map((item) => ({
            tickKey: item[0].toString(),
            tick: item[1],
        }));

        const copy = this.liveTicks ? [...this.liveTicks] : [];

        dataFromMap.forEach((notify) => {
            const matchingTick: Tick | undefined = copy.find((localTick) => localTick.tickKey === notify.tickKey);
            let arrow: ArrowDirection = ArrowDirection.Neutral;

            if (matchingTick && matchingTick.Bid?.Price && notify.tick.Bid?.Price) {
                switch (true) {
                    case matchingTick.Bid.Price > notify.tick.Bid.Price:
                        arrow = ArrowDirection.Down;
                        break;
                    case matchingTick.Bid.Price < notify.tick.Bid.Price:
                        arrow = ArrowDirection.Up;
                        break;
                    default:
                        arrow = matchingTick.arrow;
                }
            }
            const parsedNewTick = {
                ...adaptApi(notify.tick),
                tickKey: notify.tickKey,
                Time: notify.tick.Time,
                arrow,
            };
            if (matchingTick) {
                copy[copy.indexOf(matchingTick)] = parsedNewTick;
            } else copy.push(parsedNewTick);
        });
        this.setLiveTicks([...copy]);
    };

    private addListener() {
        SocketClient.instance.responseListener.addListener(FeedSubscribeResponse, this.convertTicksResponse);
        SocketClient.instance.responseListener.addListener(TickSnapshotNotify, this.convertTicksResponse);
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new TickHistoryRequest();
        if (this.requestParams && this.requestParams.TimeFrom && this.requestParams.TimeTo) {
            request.SymbolId = this.requestParams.SymbolId ? UUID.fromString(this.requestParams.SymbolId) : null;
            request.TimeFrom = this.requestParams.TimeFrom;
            request.TimeTo = this.requestParams.TimeTo;
        }

        try {
            const data = await SocketClient.instance.request(request);
            this.setData(
                data.Ticks.map((tick, index) => ({
                    index,
                    tickKey: index,
                    Time: tick.Time,
                    Ask: tick.Ask,
                    Bid: tick.Bid,
                    Spread: null,
                })),
            );
        } catch (error) {
            this.setData([]);
            Message(MessageType.error, 'Can\'t execute data');
        }
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async subscribe() {
        const request = new FeedSubscribeAllRequest();
        request.Level = 1;
        await SocketClient.instance.sendRequest(request);
        this.addListener();
    }

    @ErrorsTracker.wrapApi()
    async unsubscribe() {
        const request = new FeedUnsubscribeAllRequest();
        await SocketClient.instance.sendRequest(request);
        await SocketClient.instance.responseListener.removeListener(FeedSubscribeResponse, this.convertTicksResponse);
        await SocketClient.instance.responseListener.removeListener(TickSnapshotNotify, this.convertTicksResponse);
        this.setLiveTicks(null);
    }

    reset() {
        this.liveTicks = null;
        this.isLoading = false;
        this.data = [];
        this.requestParams = null;
    }
}

export const ticksStore = new TicksStore();
