import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { adaptApi } from '@utils/models';
import { Account } from '@models/accounts/Account';
import { AccountSessionInfo, SessionInfo } from '@models/Session';
import { UUID } from '@api/fbe/uuid';
import { OnlineSessionsRequest } from '@api/fbe/manager/OnlineSessionsRequest';
import { DropSessionRequest } from '@api/fbe/manager/DropSessionRequest';

export class SessionsStore {
    errorTracker = new ErrorsTracker({ title: 'Sessions' });

    constructor() {
        makeAutoObservable(this);
    }

    data: SessionInfo[] = [];

    sessionAccounts: AccountSessionInfo[] = [];

    isLoading = false;

    setData(value: SessionInfo[]) {
        this.data = value;
    }

    setSessionAccounts(value: AccountSessionInfo[]) {
        this.sessionAccounts = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get(): Promise<SessionInfo[]> {
        const request = new OnlineSessionsRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        const sessionsInfo: SessionInfo[] = data.OnlineSessions.map(
            (onlineSession): SessionInfo => ({
                ...adaptApi(onlineSession),
                Created: onlineSession.Created,
                ProtocolType: onlineSession.ProtocolType.valueOf(),
                TransportType: onlineSession.ProtocolType.valueOf(),
            }),
        )
        this.setData(sessionsInfo);
        return this.data;
    }

    @ErrorsTracker.wrapApi()
    async dropSession(sessionId?: string) {
        const request = new DropSessionRequest();
        request.DropSessionId = sessionId ? UUID.fromString(sessionId) : null;
        await SocketClient.instance.request(request, this.errorTracker);
    }

    mapAccountsToSessions(accounts: Account[]) {
        this.setSessionAccounts(
            this.data.map((session) => ({
                Account: accounts.find((account) => session.Login === account.Login)!,
                SessionInfo: session,
                SessionId: session.SessionId,
            })),
        );
    }

    reset() {
        this.data = [];
        this.sessionAccounts = [];
        this.isLoading = false;
    }
}

const sessionsStore = new SessionsStore();
export { sessionsStore };
