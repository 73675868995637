import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { Host } from '@models/Hosts';
import { ErrorsTracker } from '@api/errorsTracker';
import { keyMap, t } from '@localization';
import { UUID } from '@api/fbe/uuid';
import { HostsGetAllRequest } from '@api/fbe/manager/HostsGetAllRequest';
import { HostCreateRequest } from '@api/fbe/manager/HostCreateRequest';
import { HostDeleteRequest } from '@api/fbe/manager/HostDeleteRequest';
import { HostModifyRequest } from '@api/fbe/manager/HostModifyRequest';

export class HostsStore {
    errorTracker = new ErrorsTracker({ title: 'Hosts' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Host[] = [];

    isLoading: boolean = false;

    setData(value: Host[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            this.update({
                ...targetItem,
                SortOrder: newIndex,
            });
        } else {
            throw new Error(t(keyMap.messages.hosts.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new HostsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Hosts.map(
                (record): Host => ({
                    id: record.id.toString(),
                    SortOrder: record.SortOrder,
                    Name: record.Name,
                    Comment: record.Comment,
                    Description: record.Description,
                    Address: record.Address,
                    IsEnabled: record.IsEnabled,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: Host) {
        const request = new HostCreateRequest();
        request.Name = record.Name;
        request.SortOrder = record.SortOrder;
        request.Comment = record.Comment;
        request.Description = record.Description;
        request.Address = record.Address;
        request.IsEnabled = record.IsEnabled;
        request.Settings = new Map();
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new HostDeleteRequest();
        request.HostId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: Host) {
        const request = new HostModifyRequest();
        request.HostId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        request.NewAddress = record.Address;
        request.NewIsEnabled = record.IsEnabled;
        request.SettingsToModify = new Map();
        request.SettingsToDelete = new Set();
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const hostsStore = new HostsStore();

export { hostsStore };
