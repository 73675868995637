import { Spin } from 'antd';
import React, { FC, ReactNode } from 'react';
import styles from './style.module.scss';

export const LoadingOverlay: FC<{
    children: ReactNode;
    isLoading: boolean;
}> = ({ children, isLoading }) => (
    <>
        {isLoading ? (
            <>
                {children}
                <Spin className={styles.loader} />
                <div className={styles.overlay} />
            </>
        ) : (
            children
        )}
    </>
);
