import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { NewsProfile as NewsProfileAPI } from '@api/fbe/core/NewsProfile';
import { NewsProfile, NewsProfilesImport } from '@models/profiles/NewsProfile';
import { UInt64 } from '@api/fbe/int64';
import { NewsProfilesGetAllRequest } from '@api/fbe/manager/NewsProfilesGetAllRequest';
import { NewsProfileCreateRequest } from '@api/fbe/manager/NewsProfileCreateRequest';
import { NewsProfileModifyRequest } from '@api/fbe/manager/NewsProfileModifyRequest';
import { NewsProfileDeleteRequest } from '@api/fbe/manager/NewsProfileDeleteRequest';
import { NewsProfileImportRequest } from '@api/fbe/manager/NewsProfileImportRequest';
import { NewsProfileImportResponse } from '@api/fbe/manager/NewsProfileImportResponse';
import { NewsProfileImportSettings } from '@api/fbe/core/NewsProfileImportSettings';

class NewsProfileStore {
    errorTracker = new ErrorsTracker({ title: 'News Profiles' });

    constructor() {
        makeAutoObservable(this);
    }

    data: NewsProfile[] = [];

    isLoading = false;

    setData(value: NewsProfile[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new NewsProfilesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.NewsProfiles.map(
                (result: NewsProfileAPI): NewsProfile => ({
                    id: result.id.toString(),
                    Name: result.Name,
                    Comment: result.Comment,
                    Description: result.Description,
                    Time: result.Time,
                    BeforeSeconds: +result.BeforeSeconds.toNumber(),
                    AfterSeconds: +result.AfterSeconds.toNumber(),
                    MarginRateProfileId: result.MarginRateProfileId.toString(),
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: NewsProfile) {
        const request = new NewsProfileCreateRequest();
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        if (record.Time) request.Time = record.Time;
        if (record.BeforeSeconds !== null) request.BeforeSeconds = UInt64.fromNumber(record.BeforeSeconds);
        if (record.AfterSeconds !== null) request.AfterSeconds = UInt64.fromNumber(record.AfterSeconds);
        if (record.MarginRateProfileId) request.MarginRateProfileId = UUID.fromString(record.MarginRateProfileId);

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: NewsProfile, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new NewsProfileModifyRequest();
        request.NewsProfileId = UUID.fromString(record.id);
        if (dirtyFields?.Name) request.NewName = record.Name;
        if (dirtyFields?.Comment) request.NewComment = record.Comment;
        if (dirtyFields?.Description) request.NewDescription = record.Description;
        if (dirtyFields?.Time) request.NewTime = record.Time;
        if (dirtyFields?.BeforeSeconds)
            request.NewBeforeSeconds = record.BeforeSeconds !== null ? UInt64.fromNumber(record.BeforeSeconds) : null;
        if (dirtyFields?.AfterSeconds)
            request.NewAfterSeconds = record.AfterSeconds !== null ? UInt64.fromNumber(record.AfterSeconds) : null;
        if (dirtyFields?.MarginRateProfileId)
            request.NewMarginRateProfileId = record.MarginRateProfileId
                ? UUID.fromString(record.MarginRateProfileId)
                : null;

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new NewsProfileDeleteRequest();
        request.NewsProfileId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }
    
    @ErrorsTracker.wrapApi()
    async uploadCSVFile(profiles: NewsProfilesImport[]): Promise<NewsProfileImportResponse> {
        const request = new NewsProfileImportRequest();

        request.NewsProfileImportSettings = profiles.map(profile => ({
            AfterSeconds: UInt64.fromNumber(profile.AfterSeconds),
            BeforeSeconds: UInt64.fromNumber(profile.BeforeSeconds),
            DateTime: profile.Time,
            MarginRateProfileName: profile.MarginRateProfileName,
            Name: profile.Name,
        } as NewsProfileImportSettings));

        const response = await SocketClient.instance.request(request, this.errorTracker);
        return response;
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const newsProfileStore = new NewsProfileStore();
