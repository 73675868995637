
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * CounterInfo struct
 */
class CounterInfo {
  /**
   * Initialize struct
   * @param {!number=} total
   * @param {!number=} day
   * @param {!number=} hour
   * @param {!number=} minute
   * @constructor
   */
  constructor (total = new UInt64(0, 0), day = new UInt64(0, 0), hour = new UInt64(0, 0), minute = new UInt64(0, 0)) {
    this.Total = total
    this.Day = day
    this.Hour = hour
    this.Minute = minute
  }

  /**
   * Copy struct (shallow copy)
   * @this {!CounterInfo}
   * @param {!CounterInfo} other Other struct
   * @returns {!CounterInfo} This struct
   */
  copy (other) {
    if (other.Total != null) {
      this.Total = UInt64.fromNumber(other.Total)
    } else {
      this.Total = null
    }
    if (other.Day != null) {
      this.Day = UInt64.fromNumber(other.Day)
    } else {
      this.Day = null
    }
    if (other.Hour != null) {
      this.Hour = UInt64.fromNumber(other.Hour)
    } else {
      this.Hour = null
    }
    if (other.Minute != null) {
      this.Minute = UInt64.fromNumber(other.Minute)
    } else {
      this.Minute = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!CounterInfo}
   * @returns {!CounterInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new CounterInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new CounterInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!CounterInfo}
   * @param {!CounterInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof CounterInfo)) {
      throw new TypeError('Instance of CounterInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!CounterInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Total: ((this.Total != null) ? this.Total.toNumber() : null),
      Day: ((this.Day != null) ? this.Day.toNumber() : null),
      Hour: ((this.Hour != null) ? this.Hour.toNumber() : null),
      Minute: ((this.Minute != null) ? this.Minute.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return CounterInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!CounterInfo} other Object value
   * @returns {!CounterInfo} Created struct
   */
  static fromObject (other) {
    return new CounterInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!CounterInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return CounterInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!CounterInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 74
  }
}

export { CounterInfo };

/**
 * Fast Binary Encoding CounterInfo field model
 */
class FieldModelCounterInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Total = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Day = new fbe.FieldModelUInt64(buffer, this.Total.fbeOffset + this.Total.fbeSize)
    this.Hour = new fbe.FieldModelUInt64(buffer, this.Day.fbeOffset + this.Day.fbeSize)
    this.Minute = new fbe.FieldModelUInt64(buffer, this.Hour.fbeOffset + this.Hour.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Total.fbeSize + this.Day.fbeSize + this.Hour.fbeSize + this.Minute.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Total.fbeExtra + this.Day.fbeExtra + this.Hour.fbeExtra + this.Minute.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelCounterInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 74
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelCounterInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelCounterInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Total.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Total.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Total.fbeSize

    if ((fbeCurrentSize + this.Day.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Day.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.Hour.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Hour.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Hour.fbeSize

    if ((fbeCurrentSize + this.Minute.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Minute.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Minute.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelCounterInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelCounterInfo}
   * @param {!CounterInfo} fbeValue Default value, defaults is new CounterInfo()
   * @returns {!CounterInfo} CounterInfo value
   */
  get (fbeValue = new CounterInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelCounterInfo}
   * @param {!CounterInfo} fbeValue CounterInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Total.fbeSize) <= fbeStructSize) {
      fbeValue.Total = this.Total.get()
    } else {
      fbeValue.Total = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Total.fbeSize

    if ((fbeCurrentSize + this.Day.fbeSize) <= fbeStructSize) {
      fbeValue.Day = this.Day.get()
    } else {
      fbeValue.Day = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.Hour.fbeSize) <= fbeStructSize) {
      fbeValue.Hour = this.Hour.get()
    } else {
      fbeValue.Hour = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Hour.fbeSize

    if ((fbeCurrentSize + this.Minute.fbeSize) <= fbeStructSize) {
      fbeValue.Minute = this.Minute.get()
    } else {
      fbeValue.Minute = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Minute.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelCounterInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelCounterInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelCounterInfo}
   * @param {!CounterInfo} fbeValue CounterInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelCounterInfo}
   * @param {CounterInfo} fbeValue CounterInfo value
   */
  setFields (fbeValue) {
    this.Total.set(fbeValue.Total)
    this.Day.set(fbeValue.Day)
    this.Hour.set(fbeValue.Hour)
    this.Minute.set(fbeValue.Minute)
  }
}

export { FieldModelCounterInfo };

/**
 * Fast Binary Encoding CounterInfo model
 */
class CounterInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelCounterInfo(this.buffer, 4)
  }

  /**
   * Get the CounterInfo model
   * @this {!CounterInfoModel}
   * @returns {!FieldModelCounterInfo} model CounterInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!CounterInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!CounterInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return CounterInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!CounterInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelCounterInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!CounterInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!CounterInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!CounterInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!CounterInfoModel}
   * @param {!CounterInfo} value CounterInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!CounterInfoModel}
   * @param {!CounterInfo} value CounterInfo value, defaults is new CounterInfo()
   * @return {!object} Deserialized CounterInfo value and its size
   */
  deserialize (value = new CounterInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new CounterInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new CounterInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!CounterInfoModel}
   * @param {!number} prev Previous CounterInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { CounterInfoModel };
