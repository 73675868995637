
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ExportDatabaseType} from './ExportDatabaseType';
import {FieldModelExportDatabaseType} from './ExportDatabaseType';

/**
 * ExportDatabaseParams struct
 */
class ExportDatabaseParams {
  /**
   * Initialize struct
   * @param {!ExportDatabaseType=} databaseType
   * @param {!string=} databaseAddress
   * @param {!number=} databasePort
   * @param {!string=} databaseLogin
   * @param {!string=} databasePassword
   * @param {!string=} databaseName
   * @param {!number=} connectionTimeout
   * @param {!number=} commandTimeout
   * @constructor
   */
  constructor (databaseType = new ExportDatabaseType(), databaseAddress = '', databasePort = 0, databaseLogin = '', databasePassword = '', databaseName = '', connectionTimeout = 10, commandTimeout = 60) {
    this.DatabaseType = databaseType
    this.DatabaseAddress = databaseAddress
    this.DatabasePort = databasePort
    this.DatabaseLogin = databaseLogin
    this.DatabasePassword = databasePassword
    this.DatabaseName = databaseName
    this.ConnectionTimeout = connectionTimeout
    this.CommandTimeout = commandTimeout
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ExportDatabaseParams}
   * @param {!ExportDatabaseParams} other Other struct
   * @returns {!ExportDatabaseParams} This struct
   */
  copy (other) {
    if (other.DatabaseType != null) {
      this.DatabaseType = ExportDatabaseType.fromObject(other.DatabaseType)
    } else {
      this.DatabaseType = null
    }
    if (other.DatabaseAddress != null) {
      this.DatabaseAddress = other.DatabaseAddress
    } else {
      this.DatabaseAddress = null
    }
    if (other.DatabasePort != null) {
      this.DatabasePort = other.DatabasePort
    } else {
      this.DatabasePort = null
    }
    if (other.DatabaseLogin != null) {
      this.DatabaseLogin = other.DatabaseLogin
    } else {
      this.DatabaseLogin = null
    }
    if (other.DatabasePassword != null) {
      this.DatabasePassword = other.DatabasePassword
    } else {
      this.DatabasePassword = null
    }
    if (other.DatabaseName != null) {
      this.DatabaseName = other.DatabaseName
    } else {
      this.DatabaseName = null
    }
    if (other.ConnectionTimeout != null) {
      this.ConnectionTimeout = other.ConnectionTimeout
    } else {
      this.ConnectionTimeout = null
    }
    if (other.CommandTimeout != null) {
      this.CommandTimeout = other.CommandTimeout
    } else {
      this.CommandTimeout = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ExportDatabaseParams}
   * @returns {!ExportDatabaseParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ExportDatabaseParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ExportDatabaseParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ExportDatabaseParams}
   * @param {!ExportDatabaseParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ExportDatabaseParams)) {
      throw new TypeError('Instance of ExportDatabaseParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ExportDatabaseParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      DatabaseType: ((this.DatabaseType != null) ? this.DatabaseType : null),
      DatabaseAddress: ((this.DatabaseAddress != null) ? this.DatabaseAddress : null),
      DatabasePort: ((this.DatabasePort != null) ? this.DatabasePort : null),
      DatabaseLogin: ((this.DatabaseLogin != null) ? this.DatabaseLogin : null),
      DatabasePassword: ((this.DatabasePassword != null) ? this.DatabasePassword : null),
      DatabaseName: ((this.DatabaseName != null) ? this.DatabaseName : null),
      ConnectionTimeout: ((this.ConnectionTimeout != null) ? this.ConnectionTimeout : null),
      CommandTimeout: ((this.CommandTimeout != null) ? this.CommandTimeout : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ExportDatabaseParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ExportDatabaseParams} other Object value
   * @returns {!ExportDatabaseParams} Created struct
   */
  static fromObject (other) {
    return new ExportDatabaseParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ExportDatabaseParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ExportDatabaseParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ExportDatabaseParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 84
  }
}

export { ExportDatabaseParams };

/**
 * Fast Binary Encoding ExportDatabaseParams field model
 */
class FieldModelExportDatabaseParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.DatabaseType = new FieldModelExportDatabaseType(buffer, 4 + 4)
    this.DatabaseAddress = new fbe.FieldModelString(buffer, this.DatabaseType.fbeOffset + this.DatabaseType.fbeSize)
    this.DatabasePort = new fbe.FieldModelUInt16(buffer, this.DatabaseAddress.fbeOffset + this.DatabaseAddress.fbeSize)
    this.DatabaseLogin = new fbe.FieldModelString(buffer, this.DatabasePort.fbeOffset + this.DatabasePort.fbeSize)
    this.DatabasePassword = new fbe.FieldModelString(buffer, this.DatabaseLogin.fbeOffset + this.DatabaseLogin.fbeSize)
    this.DatabaseName = new fbe.FieldModelString(buffer, this.DatabasePassword.fbeOffset + this.DatabasePassword.fbeSize)
    this.ConnectionTimeout = new fbe.FieldModelUInt16(buffer, this.DatabaseName.fbeOffset + this.DatabaseName.fbeSize)
    this.CommandTimeout = new fbe.FieldModelUInt16(buffer, this.ConnectionTimeout.fbeOffset + this.ConnectionTimeout.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.DatabaseType.fbeSize + this.DatabaseAddress.fbeSize + this.DatabasePort.fbeSize + this.DatabaseLogin.fbeSize + this.DatabasePassword.fbeSize + this.DatabaseName.fbeSize + this.ConnectionTimeout.fbeSize + this.CommandTimeout.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.DatabaseType.fbeExtra + this.DatabaseAddress.fbeExtra + this.DatabasePort.fbeExtra + this.DatabaseLogin.fbeExtra + this.DatabasePassword.fbeExtra + this.DatabaseName.fbeExtra + this.ConnectionTimeout.fbeExtra + this.CommandTimeout.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelExportDatabaseParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 84
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelExportDatabaseParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelExportDatabaseParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.DatabaseType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabaseType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseType.fbeSize

    if ((fbeCurrentSize + this.DatabaseAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabaseAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseAddress.fbeSize

    if ((fbeCurrentSize + this.DatabasePort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabasePort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabasePort.fbeSize

    if ((fbeCurrentSize + this.DatabaseLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabaseLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseLogin.fbeSize

    if ((fbeCurrentSize + this.DatabasePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabasePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabasePassword.fbeSize

    if ((fbeCurrentSize + this.DatabaseName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabaseName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseName.fbeSize

    if ((fbeCurrentSize + this.ConnectionTimeout.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConnectionTimeout.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConnectionTimeout.fbeSize

    if ((fbeCurrentSize + this.CommandTimeout.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommandTimeout.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommandTimeout.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelExportDatabaseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelExportDatabaseParams}
   * @param {!ExportDatabaseParams} fbeValue Default value, defaults is new ExportDatabaseParams()
   * @returns {!ExportDatabaseParams} ExportDatabaseParams value
   */
  get (fbeValue = new ExportDatabaseParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelExportDatabaseParams}
   * @param {!ExportDatabaseParams} fbeValue ExportDatabaseParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.DatabaseType.fbeSize) <= fbeStructSize) {
      fbeValue.DatabaseType = this.DatabaseType.get()
    } else {
      fbeValue.DatabaseType = new ExportDatabaseType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseType.fbeSize

    if ((fbeCurrentSize + this.DatabaseAddress.fbeSize) <= fbeStructSize) {
      fbeValue.DatabaseAddress = this.DatabaseAddress.get()
    } else {
      fbeValue.DatabaseAddress = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseAddress.fbeSize

    if ((fbeCurrentSize + this.DatabasePort.fbeSize) <= fbeStructSize) {
      fbeValue.DatabasePort = this.DatabasePort.get()
    } else {
      fbeValue.DatabasePort = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabasePort.fbeSize

    if ((fbeCurrentSize + this.DatabaseLogin.fbeSize) <= fbeStructSize) {
      fbeValue.DatabaseLogin = this.DatabaseLogin.get()
    } else {
      fbeValue.DatabaseLogin = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseLogin.fbeSize

    if ((fbeCurrentSize + this.DatabasePassword.fbeSize) <= fbeStructSize) {
      fbeValue.DatabasePassword = this.DatabasePassword.get()
    } else {
      fbeValue.DatabasePassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabasePassword.fbeSize

    if ((fbeCurrentSize + this.DatabaseName.fbeSize) <= fbeStructSize) {
      fbeValue.DatabaseName = this.DatabaseName.get()
    } else {
      fbeValue.DatabaseName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseName.fbeSize

    if ((fbeCurrentSize + this.ConnectionTimeout.fbeSize) <= fbeStructSize) {
      fbeValue.ConnectionTimeout = this.ConnectionTimeout.get(10)
    } else {
      fbeValue.ConnectionTimeout = 10
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConnectionTimeout.fbeSize

    if ((fbeCurrentSize + this.CommandTimeout.fbeSize) <= fbeStructSize) {
      fbeValue.CommandTimeout = this.CommandTimeout.get(60)
    } else {
      fbeValue.CommandTimeout = 60
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommandTimeout.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelExportDatabaseParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelExportDatabaseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelExportDatabaseParams}
   * @param {!ExportDatabaseParams} fbeValue ExportDatabaseParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelExportDatabaseParams}
   * @param {ExportDatabaseParams} fbeValue ExportDatabaseParams value
   */
  setFields (fbeValue) {
    this.DatabaseType.set(fbeValue.DatabaseType)
    this.DatabaseAddress.set(fbeValue.DatabaseAddress)
    this.DatabasePort.set(fbeValue.DatabasePort)
    this.DatabaseLogin.set(fbeValue.DatabaseLogin)
    this.DatabasePassword.set(fbeValue.DatabasePassword)
    this.DatabaseName.set(fbeValue.DatabaseName)
    this.ConnectionTimeout.set(fbeValue.ConnectionTimeout)
    this.CommandTimeout.set(fbeValue.CommandTimeout)
  }
}

export { FieldModelExportDatabaseParams };

/**
 * Fast Binary Encoding ExportDatabaseParams model
 */
class ExportDatabaseParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelExportDatabaseParams(this.buffer, 4)
  }

  /**
   * Get the ExportDatabaseParams model
   * @this {!ExportDatabaseParamsModel}
   * @returns {!FieldModelExportDatabaseParams} model ExportDatabaseParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ExportDatabaseParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ExportDatabaseParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ExportDatabaseParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ExportDatabaseParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelExportDatabaseParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ExportDatabaseParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ExportDatabaseParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ExportDatabaseParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ExportDatabaseParamsModel}
   * @param {!ExportDatabaseParams} value ExportDatabaseParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ExportDatabaseParamsModel}
   * @param {!ExportDatabaseParams} value ExportDatabaseParams value, defaults is new ExportDatabaseParams()
   * @return {!object} Deserialized ExportDatabaseParams value and its size
   */
  deserialize (value = new ExportDatabaseParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ExportDatabaseParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ExportDatabaseParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ExportDatabaseParamsModel}
   * @param {!number} prev Previous ExportDatabaseParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ExportDatabaseParamsModel };
