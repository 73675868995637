
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {AssetUpdateResponse} from './AssetUpdateResponse';
import {AssetUpdate} from './AssetUpdate';
import {FieldModelAssetUpdate} from './AssetUpdate';

/**
 * AssetUpdateRequest struct
 */
class AssetUpdateRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!AssetUpdate=} asset
   * @constructor
   */
  constructor (parent = new Request(), asset = new AssetUpdate()) {
    super()
    super.copy(parent)
    this.Asset = asset
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AssetUpdateRequest}
   * @param {!AssetUpdateRequest} other Other struct
   * @returns {!AssetUpdateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Asset != null) {
      this.Asset = AssetUpdate.fromObject(other.Asset)
    } else {
      this.Asset = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AssetUpdateRequest}
   * @returns {!AssetUpdateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AssetUpdateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AssetUpdateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AssetUpdateRequest}
   * @param {!AssetUpdateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AssetUpdateRequest)) {
      throw new TypeError('Instance of AssetUpdateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AssetUpdateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Asset: ((this.Asset != null) ? this.Asset : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AssetUpdateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AssetUpdateRequest} other Object value
   * @returns {!AssetUpdateRequest} Created struct
   */
  static fromObject (other) {
    return new AssetUpdateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AssetUpdateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AssetUpdateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AssetUpdateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4018
  }
}

export { AssetUpdateRequest };

/**
 * Fast Binary Encoding AssetUpdateRequest field model
 */
class FieldModelAssetUpdateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Asset = new FieldModelAssetUpdate(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Asset.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Asset.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAssetUpdateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4018
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Asset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Asset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Asset.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!AssetUpdateRequest} fbeValue Default value, defaults is new AssetUpdateRequest()
   * @returns {!AssetUpdateRequest} AssetUpdateRequest value
   */
  get (fbeValue = new AssetUpdateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!AssetUpdateRequest} fbeValue AssetUpdateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Asset.fbeSize) <= fbeStructSize) {
      fbeValue.Asset = this.Asset.get()
    } else {
      fbeValue.Asset = new AssetUpdate()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Asset.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAssetUpdateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAssetUpdateRequest}
   * @param {!AssetUpdateRequest} fbeValue AssetUpdateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAssetUpdateRequest}
   * @param {AssetUpdateRequest} fbeValue AssetUpdateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Asset.set(fbeValue.Asset)
  }
}

export { FieldModelAssetUpdateRequest };

/**
 * Fast Binary Encoding AssetUpdateRequest model
 */
class AssetUpdateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAssetUpdateRequest(this.buffer, 4)
  }

  /**
   * Get the AssetUpdateRequest model
   * @this {!AssetUpdateRequestModel}
   * @returns {!FieldModelAssetUpdateRequest} model AssetUpdateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AssetUpdateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AssetUpdateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AssetUpdateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AssetUpdateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAssetUpdateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AssetUpdateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AssetUpdateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AssetUpdateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AssetUpdateRequestModel}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AssetUpdateRequestModel}
   * @param {!AssetUpdateRequest} value AssetUpdateRequest value, defaults is new AssetUpdateRequest()
   * @return {!object} Deserialized AssetUpdateRequest value and its size
   */
  deserialize (value = new AssetUpdateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AssetUpdateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AssetUpdateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AssetUpdateRequestModel}
   * @param {!number} prev Previous AssetUpdateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AssetUpdateRequestModel };
AssetUpdateRequest.__has_response = true;
AssetUpdateRequest.__response_class = AssetUpdateResponse;
