import React, { memo } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import { join as joinPaths } from 'path-browserify';
import { LocationDescriptor } from 'history';
import { RouteWithPageTitle } from './RouteWithPageTitle';
import { LazyLoad } from './LazyLoad';
import { ConsumeRouteProps } from './ProvideRouteProps';

export type RouteComponent<P = any> = React.ComponentType<P> | ((props: P) => JSX.Element);

export type DeferRouteComponent<P = any> =
    | (() => RouteComponent<P>)
    | (() => Promise<RouteComponent<P> | { default: RouteComponent<P> }>)
    | (() => RouteComponent<P>);

export type IRouteTree<Ext = {}> = {
    /**
     * get component for route
     *
     * direct import:
     * ```ts
     * import { MyPage } from 'pages/my-page';
     * component: MyPage;
     * ```
     *
     * for async import or loading, use `deferComponent`
     */
    component?: RouteComponent;

    /**
     * async import:
     * ```ts
     * deferComponent: () => import('pages/my-page').then(x => x.MyPage)
     * ```
     */
    deferComponent?: DeferRouteComponent;

    /** pass additional props to rendered route or transform current */
    props?: (routeProps: Record<string, any>) => Record<string, any>;
    placeholder?: () => any;
    path?: string;
    exact?: boolean;
    children?: IRouteTree<Ext>[];
    pageTitle?: string | (() => string);
    sensitive?: boolean;
    strict?: boolean;

    redirectTo?: LocationDescriptor;
} & Ext;

const RouteComponentRenderer = memo((props: { route: IRouteTree; children: any } & Partial<RouteComponentProps>) => {
    const { route, children } = props;

    if (!route.component && route.deferComponent) {
        return (
            <LazyLoad load={route.deferComponent} placeholder={route.placeholder}>
                {(loaded) => (
                    <ConsumeRouteProps
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        route={route}
                        component={loaded}
                    >
                        {children}
                    </ConsumeRouteProps>
                )}
            </LazyLoad>
        );
    }

    if (!route.component) {
        console.error('RouteRender, no component to render', props);
        return null;
    }

    return (
        <ConsumeRouteProps
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            route={route}
            component={route.component}
        >
            {children}
        </ConsumeRouteProps>
    );
});

type RouteTreeOpts = {
    /** false by default */
    joinPath?: boolean;
};

export const RouterTree: React.FC<{ key?: any; route: IRouteTree } & RouteTreeOpts> = ({ key, route, joinPath }) => {
    const childRoutes = route.children && (
        <Switch key={key}>
            {route.children.map((childR, childRInd) =>
                // !! Should be just function call, because otherwise routes will not switch
                RouterTree({
                    key: `${key}_${route.path}x${childRInd}`,
                    route: {
                        ...childR,
                        path: joinPath && route.path && childR.path ? joinPaths(route.path, childR.path) : childR.path,
                    },
                }),
            )}
        </Switch>
    );

    // route's content should be rendered by function or component
    // because it should be rendered only when route is mathced
    const RouteRenderer = (props: Partial<RouteComponentProps<any>>) =>
        ((route.component || route.deferComponent) && (
            <RouteComponentRenderer
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                route={route}
                key={key}
            >
                {childRoutes}
            </RouteComponentRenderer>
        )) ||
        childRoutes ||
        null;

    if (route.redirectTo) {
        return <Redirect key={key} to={route.redirectTo} />;
    }

    if (route.path) {
        const routeProps: RouteProps = {
            component: RouteRenderer,
            exact: route.exact,
            path: route.path,
            sensitive: route.sensitive,
            strict: route.strict,
        };

        return route.pageTitle ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RouteWithPageTitle key={key} pageTitle={route.pageTitle} {...routeProps} />
        ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Route key={key} {...routeProps} />
        );
    }
    return <RouteRenderer key={key} />;
};
