import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { BusinessJournalModel, BusinessJournalRequestParams } from '@models/BusinessJournal';
import { JournalBusinessRequest } from '@api/fbe/manager/JournalBusinessRequest';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export class BusinessJournalStore {
    errorTracker = new ErrorsTracker({ title: 'BusinessJournal' });

    constructor() {
        makeAutoObservable(this);
    }

    businessJournalRequestParams: BusinessJournalRequestParams | null = null;

    setBusinessJournalRequestParams = (params: BusinessJournalRequestParams | null): void => {
        this.businessJournalRequestParams = params;
    };

    data: BusinessJournalModel[] | null = null;

    isLoading: boolean = false;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setData(value: BusinessJournalModel[] | null) {
        this.data = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new JournalBusinessRequest();
        if (this.businessJournalRequestParams) {
            if (this.businessJournalRequestParams.TimeFrom) {
                request.TimeFrom = this.businessJournalRequestParams.TimeFrom;
            }
            if (this.businessJournalRequestParams.TimeTo) {
                request.TimeTo = this.businessJournalRequestParams.TimeTo;
            }
            request.Module = this.businessJournalRequestParams.Module;
            request.SearchText = this.businessJournalRequestParams.SearchText;
            request.CaseSensitive = this.businessJournalRequestParams.CaseSensitive;

            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.setIsLoading(false);
            this.setData(
                result.Records.map((record, index) => ({
                    key: index,
                    Time: record.Time ?? null,
                    Message: record.Message,
                }))
                    .sort((itemA, itemB) => baseSorter(itemA.Time, itemB.Time))
                    .reverse(),
            );
        }
    }

    reset() {
        this.data = null;
        this.isLoading = false;
        this.businessJournalRequestParams = null;
    }
}

export const businessJournalStore = new BusinessJournalStore();
