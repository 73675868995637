import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

export const RouteWithPageTitle = (props: any) => {
    const { pageTitle } = props;

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...props} />;
};
