
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {SwapCalculationType} from './SwapCalculationType';
import {FieldModelSwapCalculationType} from './SwapCalculationType';
import {DayOfWeek} from './DayOfWeek';
import {FieldModelDayOfWeek} from './DayOfWeek';

/**
 * SwapSymbolSettings struct
 */
class SwapSymbolSettings {
  /**
   * Initialize struct
   * @param {!SwapCalculationType=} type
   * @param {!number=} long
   * @param {!number=} short
   * @param {!DayOfWeek=} tripleDay
   * @param {!number=} daysInYear
   * @constructor
   */
  constructor (type = new SwapCalculationType(), long = 0.0, short = 0.0, tripleDay = new DayOfWeek(), daysInYear = new UInt64(360, 0)) {
    this.Type = type
    this.Long = long
    this.Short = short
    this.TripleDay = tripleDay
    this.DaysInYear = daysInYear
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SwapSymbolSettings}
   * @param {!SwapSymbolSettings} other Other struct
   * @returns {!SwapSymbolSettings} This struct
   */
  copy (other) {
    if (other.Type != null) {
      this.Type = SwapCalculationType.fromObject(other.Type)
    } else {
      this.Type = null
    }
    if (other.Long != null) {
      this.Long = other.Long
    } else {
      this.Long = null
    }
    if (other.Short != null) {
      this.Short = other.Short
    } else {
      this.Short = null
    }
    if (other.TripleDay != null) {
      this.TripleDay = DayOfWeek.fromObject(other.TripleDay)
    } else {
      this.TripleDay = null
    }
    if (other.DaysInYear != null) {
      this.DaysInYear = UInt64.fromNumber(other.DaysInYear)
    } else {
      this.DaysInYear = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SwapSymbolSettings}
   * @returns {!SwapSymbolSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SwapSymbolSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SwapSymbolSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SwapSymbolSettings}
   * @param {!SwapSymbolSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SwapSymbolSettings)) {
      throw new TypeError('Instance of SwapSymbolSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SwapSymbolSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Type: ((this.Type != null) ? this.Type : null),
      Long: ((this.Long != null) ? this.Long : null),
      Short: ((this.Short != null) ? this.Short : null),
      TripleDay: ((this.TripleDay != null) ? this.TripleDay : null),
      DaysInYear: ((this.DaysInYear != null) ? this.DaysInYear.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SwapSymbolSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SwapSymbolSettings} other Object value
   * @returns {!SwapSymbolSettings} Created struct
   */
  static fromObject (other) {
    return new SwapSymbolSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SwapSymbolSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SwapSymbolSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SwapSymbolSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 25
  }
}

export { SwapSymbolSettings };

/**
 * Fast Binary Encoding SwapSymbolSettings field model
 */
class FieldModelSwapSymbolSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Type = new FieldModelSwapCalculationType(buffer, 4 + 4)
    this.Long = new fbe.FieldModelDouble(buffer, this.Type.fbeOffset + this.Type.fbeSize)
    this.Short = new fbe.FieldModelDouble(buffer, this.Long.fbeOffset + this.Long.fbeSize)
    this.TripleDay = new FieldModelDayOfWeek(buffer, this.Short.fbeOffset + this.Short.fbeSize)
    this.DaysInYear = new fbe.FieldModelUInt64(buffer, this.TripleDay.fbeOffset + this.TripleDay.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Type.fbeSize + this.Long.fbeSize + this.Short.fbeSize + this.TripleDay.fbeSize + this.DaysInYear.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Type.fbeExtra + this.Long.fbeExtra + this.Short.fbeExtra + this.TripleDay.fbeExtra + this.DaysInYear.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSwapSymbolSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 25
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Type.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Type.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Type.fbeSize

    if ((fbeCurrentSize + this.Long.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Long.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Long.fbeSize

    if ((fbeCurrentSize + this.Short.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Short.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Short.fbeSize

    if ((fbeCurrentSize + this.TripleDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TripleDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TripleDay.fbeSize

    if ((fbeCurrentSize + this.DaysInYear.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DaysInYear.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DaysInYear.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!SwapSymbolSettings} fbeValue Default value, defaults is new SwapSymbolSettings()
   * @returns {!SwapSymbolSettings} SwapSymbolSettings value
   */
  get (fbeValue = new SwapSymbolSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!SwapSymbolSettings} fbeValue SwapSymbolSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Type.fbeSize) <= fbeStructSize) {
      fbeValue.Type = this.Type.get()
    } else {
      fbeValue.Type = new SwapCalculationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Type.fbeSize

    if ((fbeCurrentSize + this.Long.fbeSize) <= fbeStructSize) {
      fbeValue.Long = this.Long.get()
    } else {
      fbeValue.Long = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Long.fbeSize

    if ((fbeCurrentSize + this.Short.fbeSize) <= fbeStructSize) {
      fbeValue.Short = this.Short.get()
    } else {
      fbeValue.Short = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Short.fbeSize

    if ((fbeCurrentSize + this.TripleDay.fbeSize) <= fbeStructSize) {
      fbeValue.TripleDay = this.TripleDay.get()
    } else {
      fbeValue.TripleDay = new DayOfWeek()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TripleDay.fbeSize

    if ((fbeCurrentSize + this.DaysInYear.fbeSize) <= fbeStructSize) {
      fbeValue.DaysInYear = this.DaysInYear.get(new UInt64(360, 0))
    } else {
      fbeValue.DaysInYear = new UInt64(360, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DaysInYear.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSwapSymbolSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSwapSymbolSettings}
   * @param {!SwapSymbolSettings} fbeValue SwapSymbolSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSwapSymbolSettings}
   * @param {SwapSymbolSettings} fbeValue SwapSymbolSettings value
   */
  setFields (fbeValue) {
    this.Type.set(fbeValue.Type)
    this.Long.set(fbeValue.Long)
    this.Short.set(fbeValue.Short)
    this.TripleDay.set(fbeValue.TripleDay)
    this.DaysInYear.set(fbeValue.DaysInYear)
  }
}

export { FieldModelSwapSymbolSettings };

/**
 * Fast Binary Encoding SwapSymbolSettings model
 */
class SwapSymbolSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSwapSymbolSettings(this.buffer, 4)
  }

  /**
   * Get the SwapSymbolSettings model
   * @this {!SwapSymbolSettingsModel}
   * @returns {!FieldModelSwapSymbolSettings} model SwapSymbolSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SwapSymbolSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SwapSymbolSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SwapSymbolSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SwapSymbolSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSwapSymbolSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SwapSymbolSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SwapSymbolSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SwapSymbolSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SwapSymbolSettingsModel}
   * @param {!SwapSymbolSettings} value SwapSymbolSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SwapSymbolSettingsModel}
   * @param {!SwapSymbolSettings} value SwapSymbolSettings value, defaults is new SwapSymbolSettings()
   * @return {!object} Deserialized SwapSymbolSettings value and its size
   */
  deserialize (value = new SwapSymbolSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SwapSymbolSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SwapSymbolSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SwapSymbolSettingsModel}
   * @param {!number} prev Previous SwapSymbolSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SwapSymbolSettingsModel };
