import { CommissionChargingType } from '@api/fbe/core/CommissionChargingType';
import { CommissionMode } from '@api/fbe/core/CommissionMode';
import { CommissionProfile } from '@api/fbe/core/CommissionProfile';
import { CommissionValue } from '@api/fbe/core/CommissionValue';
import { ParsedCommissionProfile } from '@models/Commission';
import { CommissionProfileCreateRequest } from '@api/fbe/manager/CommissionProfileCreateRequest';
import { CommissionProfileModifyRequest } from '@api/fbe/manager/CommissionProfileModifyRequest';
import { UUID } from '@api/fbe/uuid';
import { CommissionType } from '@api/fbe/core/CommissionType';

export const fromApiFormat = (profile: CommissionProfile): ParsedCommissionProfile => ({
    id: profile.id.toString(),
    Name: profile.Name,
    Description: profile.Description,
    Comment: profile.Comment,
    Mode: profile.Mode.toJSON(),
    CommissionType: profile.Type.valueOf() || null, // 0 is not allowed
    ChargingType: profile.ChargingType.toJSON(),
    // CalculationType: profile.CalculationType.toJSON(),
    Values: profile.Values.map((val, index) => ({ ...val, id: index })),
});

export const toApiCreateFormat = (
    request: CommissionProfileCreateRequest,
    commission: ParsedCommissionProfile,
): CommissionProfileCreateRequest => {
    request.Name = commission.Name;
    request.Description = commission.Description;
    request.Comment = commission.Comment;
    request.Mode = new CommissionMode(commission.Mode!);
    request.CommissionType = new CommissionType(commission.CommissionType!);
    // request.CalculationType = new CommissionCalculationType(commission.CalculationType!);
    request.ChargingType = new CommissionChargingType(commission.ChargingType!);
    request.Values = commission.Values.map((value) => CommissionValue.fromObject(value));

    return request;
};

export const toApiModifyFormat = (
    request: CommissionProfileModifyRequest,
    commission: ParsedCommissionProfile,
): CommissionProfileModifyRequest => {
    request.id = UUID.fromString(commission.id!);
    request.NewName = commission.Name;
    request.NewDescription = commission.Description;
    request.NewComment = commission.Comment;
    request.NewMode = new CommissionMode(commission.Mode!);
    request.NewCommissionType = new CommissionType(commission.CommissionType!);
    // request.NewCalculationType = new CommissionCalculationType(commission.CalculationType!);
    request.NewChargingType = new CommissionChargingType(commission.ChargingType!);
    request.NewValues = commission.Values.map((value) => CommissionValue.fromObject(value));

    return request;
};
