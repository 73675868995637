
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as entity from '../entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * DrawingStorageNotFoundResponse struct
 */
class DrawingStorageNotFoundResponse {
  /**
   * Initialize struct
   * @param {!boolean=} isDashboardIdValid
   * @param {!boolean=} isChartIdValid
   * @constructor
   */
  constructor (isDashboardIdValid = false, isChartIdValid = false) {
    this.isDashboardIdValid = isDashboardIdValid
    this.isChartIdValid = isChartIdValid
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DrawingStorageNotFoundResponse}
   * @param {!DrawingStorageNotFoundResponse} other Other struct
   * @returns {!DrawingStorageNotFoundResponse} This struct
   */
  copy (other) {
    if (other.isDashboardIdValid != null) {
      this.isDashboardIdValid = other.isDashboardIdValid
    } else {
      this.isDashboardIdValid = null
    }
    if (other.isChartIdValid != null) {
      this.isChartIdValid = other.isChartIdValid
    } else {
      this.isChartIdValid = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DrawingStorageNotFoundResponse}
   * @returns {!DrawingStorageNotFoundResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DrawingStorageNotFoundResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DrawingStorageNotFoundResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DrawingStorageNotFoundResponse}
   * @param {!DrawingStorageNotFoundResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DrawingStorageNotFoundResponse)) {
      throw new TypeError('Instance of DrawingStorageNotFoundResponse is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DrawingStorageNotFoundResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      isDashboardIdValid: ((this.isDashboardIdValid != null) ? this.isDashboardIdValid : null),
      isChartIdValid: ((this.isChartIdValid != null) ? this.isChartIdValid : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DrawingStorageNotFoundResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DrawingStorageNotFoundResponse} other Object value
   * @returns {!DrawingStorageNotFoundResponse} Created struct
   */
  static fromObject (other) {
    return new DrawingStorageNotFoundResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DrawingStorageNotFoundResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DrawingStorageNotFoundResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DrawingStorageNotFoundResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 10007
  }
}

export { DrawingStorageNotFoundResponse };

/**
 * Fast Binary Encoding DrawingStorageNotFoundResponse field model
 */
class FieldModelDrawingStorageNotFoundResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.isDashboardIdValid = new fbe.FieldModelBool(buffer, 4 + 4)
    this.isChartIdValid = new fbe.FieldModelBool(buffer, this.isDashboardIdValid.fbeOffset + this.isDashboardIdValid.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.isDashboardIdValid.fbeSize + this.isChartIdValid.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.isDashboardIdValid.fbeExtra + this.isChartIdValid.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDrawingStorageNotFoundResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 10007
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.isDashboardIdValid.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.isDashboardIdValid.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.isDashboardIdValid.fbeSize

    if ((fbeCurrentSize + this.isChartIdValid.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.isChartIdValid.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.isChartIdValid.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!DrawingStorageNotFoundResponse} fbeValue Default value, defaults is new DrawingStorageNotFoundResponse()
   * @returns {!DrawingStorageNotFoundResponse} DrawingStorageNotFoundResponse value
   */
  get (fbeValue = new DrawingStorageNotFoundResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!DrawingStorageNotFoundResponse} fbeValue DrawingStorageNotFoundResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.isDashboardIdValid.fbeSize) <= fbeStructSize) {
      fbeValue.isDashboardIdValid = this.isDashboardIdValid.get()
    } else {
      fbeValue.isDashboardIdValid = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.isDashboardIdValid.fbeSize

    if ((fbeCurrentSize + this.isChartIdValid.fbeSize) <= fbeStructSize) {
      fbeValue.isChartIdValid = this.isChartIdValid.get()
    } else {
      fbeValue.isChartIdValid = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.isChartIdValid.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {!DrawingStorageNotFoundResponse} fbeValue DrawingStorageNotFoundResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDrawingStorageNotFoundResponse}
   * @param {DrawingStorageNotFoundResponse} fbeValue DrawingStorageNotFoundResponse value
   */
  setFields (fbeValue) {
    this.isDashboardIdValid.set(fbeValue.isDashboardIdValid)
    this.isChartIdValid.set(fbeValue.isChartIdValid)
  }
}

export { FieldModelDrawingStorageNotFoundResponse };

/**
 * Fast Binary Encoding DrawingStorageNotFoundResponse model
 */
class DrawingStorageNotFoundResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDrawingStorageNotFoundResponse(this.buffer, 4)
  }

  /**
   * Get the DrawingStorageNotFoundResponse model
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!FieldModelDrawingStorageNotFoundResponse} model DrawingStorageNotFoundResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DrawingStorageNotFoundResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDrawingStorageNotFoundResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DrawingStorageNotFoundResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DrawingStorageNotFoundResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DrawingStorageNotFoundResponseModel}
   * @param {!DrawingStorageNotFoundResponse} value DrawingStorageNotFoundResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DrawingStorageNotFoundResponseModel}
   * @param {!DrawingStorageNotFoundResponse} value DrawingStorageNotFoundResponse value, defaults is new DrawingStorageNotFoundResponse()
   * @return {!object} Deserialized DrawingStorageNotFoundResponse value and its size
   */
  deserialize (value = new DrawingStorageNotFoundResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DrawingStorageNotFoundResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DrawingStorageNotFoundResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DrawingStorageNotFoundResponseModel}
   * @param {!number} prev Previous DrawingStorageNotFoundResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DrawingStorageNotFoundResponseModel };
