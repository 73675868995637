
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {SwapImportAccountGroupSettings} from './SwapImportAccountGroupSettings';
import {FieldModelSwapImportAccountGroupSettings} from './SwapImportAccountGroupSettings';
import {SwapImportSymbolSettings} from './SwapImportSymbolSettings';
import {FieldModelSwapImportSymbolSettings} from './SwapImportSymbolSettings';
import {SwapSymbolSettings} from './SwapSymbolSettings';
import {FieldModelSwapSymbolSettings} from './SwapSymbolSettings';

/**
 * SwapImportSettings struct
 */
class SwapImportSettings {
  /**
   * Initialize struct
   * @param {!SwapImportAccountGroupSettings=} swapImportAccountGroupSettings
   * @param {!SwapImportSymbolSettings=} swapImportSymbolSettings
   * @param {SwapSymbolSettings=} swapSymbolSettings
   * @constructor
   */
  constructor (swapImportAccountGroupSettings = new SwapImportAccountGroupSettings(), swapImportSymbolSettings = new SwapImportSymbolSettings(), swapSymbolSettings = null) {
    this.SwapImportAccountGroupSettings = swapImportAccountGroupSettings
    this.SwapImportSymbolSettings = swapImportSymbolSettings
    this.SwapSymbolSettings = swapSymbolSettings
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SwapImportSettings}
   * @param {!SwapImportSettings} other Other struct
   * @returns {!SwapImportSettings} This struct
   */
  copy (other) {
    if (other.SwapImportAccountGroupSettings != null) {
      this.SwapImportAccountGroupSettings = SwapImportAccountGroupSettings.fromObject(other.SwapImportAccountGroupSettings)
    } else {
      this.SwapImportAccountGroupSettings = null
    }
    if (other.SwapImportSymbolSettings != null) {
      this.SwapImportSymbolSettings = SwapImportSymbolSettings.fromObject(other.SwapImportSymbolSettings)
    } else {
      this.SwapImportSymbolSettings = null
    }
    if (other.SwapSymbolSettings != null) {
      this.SwapSymbolSettings = SwapSymbolSettings.fromObject(other.SwapSymbolSettings)
    } else {
      this.SwapSymbolSettings = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SwapImportSettings}
   * @returns {!SwapImportSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SwapImportSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SwapImportSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SwapImportSettings}
   * @param {!SwapImportSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SwapImportSettings)) {
      throw new TypeError('Instance of SwapImportSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SwapImportSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SwapImportAccountGroupSettings: ((this.SwapImportAccountGroupSettings != null) ? this.SwapImportAccountGroupSettings : null),
      SwapImportSymbolSettings: ((this.SwapImportSymbolSettings != null) ? this.SwapImportSymbolSettings : null),
      SwapSymbolSettings: ((this.SwapSymbolSettings != null) ? this.SwapSymbolSettings : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SwapImportSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SwapImportSettings} other Object value
   * @returns {!SwapImportSettings} Created struct
   */
  static fromObject (other) {
    return new SwapImportSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SwapImportSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SwapImportSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SwapImportSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 187
  }
}

export { SwapImportSettings };

/**
 * Fast Binary Encoding SwapImportSettings field model
 */
class FieldModelSwapImportSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SwapImportAccountGroupSettings = new FieldModelSwapImportAccountGroupSettings(buffer, 4 + 4)
    this.SwapImportSymbolSettings = new FieldModelSwapImportSymbolSettings(buffer, this.SwapImportAccountGroupSettings.fbeOffset + this.SwapImportAccountGroupSettings.fbeSize)
    this.SwapSymbolSettings = new fbe.FieldModelOptional(new FieldModelSwapSymbolSettings(buffer, this.SwapImportSymbolSettings.fbeOffset + this.SwapImportSymbolSettings.fbeSize), buffer, this.SwapImportSymbolSettings.fbeOffset + this.SwapImportSymbolSettings.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SwapImportAccountGroupSettings.fbeSize + this.SwapImportSymbolSettings.fbeSize + this.SwapSymbolSettings.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SwapImportAccountGroupSettings.fbeExtra + this.SwapImportSymbolSettings.fbeExtra + this.SwapSymbolSettings.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSwapImportSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 187
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSwapImportSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSwapImportSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SwapImportAccountGroupSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapImportAccountGroupSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapImportAccountGroupSettings.fbeSize

    if ((fbeCurrentSize + this.SwapImportSymbolSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapImportSymbolSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapImportSymbolSettings.fbeSize

    if ((fbeCurrentSize + this.SwapSymbolSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapSymbolSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSymbolSettings.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSwapImportSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSwapImportSettings}
   * @param {!SwapImportSettings} fbeValue Default value, defaults is new SwapImportSettings()
   * @returns {!SwapImportSettings} SwapImportSettings value
   */
  get (fbeValue = new SwapImportSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSwapImportSettings}
   * @param {!SwapImportSettings} fbeValue SwapImportSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SwapImportAccountGroupSettings.fbeSize) <= fbeStructSize) {
      fbeValue.SwapImportAccountGroupSettings = this.SwapImportAccountGroupSettings.get()
    } else {
      fbeValue.SwapImportAccountGroupSettings = new SwapImportAccountGroupSettings()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapImportAccountGroupSettings.fbeSize

    if ((fbeCurrentSize + this.SwapImportSymbolSettings.fbeSize) <= fbeStructSize) {
      fbeValue.SwapImportSymbolSettings = this.SwapImportSymbolSettings.get()
    } else {
      fbeValue.SwapImportSymbolSettings = new SwapImportSymbolSettings()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapImportSymbolSettings.fbeSize

    if ((fbeCurrentSize + this.SwapSymbolSettings.fbeSize) <= fbeStructSize) {
      fbeValue.SwapSymbolSettings = this.SwapSymbolSettings.get()
    } else {
      fbeValue.SwapSymbolSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSymbolSettings.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSwapImportSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSwapImportSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSwapImportSettings}
   * @param {!SwapImportSettings} fbeValue SwapImportSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSwapImportSettings}
   * @param {SwapImportSettings} fbeValue SwapImportSettings value
   */
  setFields (fbeValue) {
    this.SwapImportAccountGroupSettings.set(fbeValue.SwapImportAccountGroupSettings)
    this.SwapImportSymbolSettings.set(fbeValue.SwapImportSymbolSettings)
    this.SwapSymbolSettings.set(fbeValue.SwapSymbolSettings)
  }
}

export { FieldModelSwapImportSettings };

/**
 * Fast Binary Encoding SwapImportSettings model
 */
class SwapImportSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSwapImportSettings(this.buffer, 4)
  }

  /**
   * Get the SwapImportSettings model
   * @this {!SwapImportSettingsModel}
   * @returns {!FieldModelSwapImportSettings} model SwapImportSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SwapImportSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SwapImportSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SwapImportSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SwapImportSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSwapImportSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SwapImportSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SwapImportSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SwapImportSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SwapImportSettingsModel}
   * @param {!SwapImportSettings} value SwapImportSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SwapImportSettingsModel}
   * @param {!SwapImportSettings} value SwapImportSettings value, defaults is new SwapImportSettings()
   * @return {!object} Deserialized SwapImportSettings value and its size
   */
  deserialize (value = new SwapImportSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SwapImportSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SwapImportSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SwapImportSettingsModel}
   * @param {!number} prev Previous SwapImportSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SwapImportSettingsModel };
