
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {OrderSorting} from '../core/OrderSorting';
import {FieldModelOrderSorting} from '../core/OrderSorting';
import {OrderStateSorting} from '../core/OrderStateSorting';
import {FieldModelOrderStateSorting} from '../core/OrderStateSorting';
import {AccountSorting} from '../core/AccountSorting';
import {FieldModelAccountSorting} from '../core/AccountSorting';
import {SymbolSorting} from '../core/SymbolSorting';
import {FieldModelSymbolSorting} from '../core/SymbolSorting';
import {CurrencySorting} from '../core/CurrencySorting';
import {FieldModelCurrencySorting} from '../core/CurrencySorting';

/**
 * OrdersSorting struct
 */
class OrdersSorting {
  /**
   * Initialize struct
   * @param {OrderSorting=} orderSorting
   * @param {OrderStateSorting=} orderStateSorting
   * @param {AccountSorting=} accountSorting
   * @param {SymbolSorting=} symbolSorting
   * @param {CurrencySorting=} marginCurrencySorting
   * @constructor
   */
  constructor (orderSorting = null, orderStateSorting = null, accountSorting = null, symbolSorting = null, marginCurrencySorting = null) {
    this.OrderSorting = orderSorting
    this.OrderStateSorting = orderStateSorting
    this.AccountSorting = accountSorting
    this.SymbolSorting = symbolSorting
    this.MarginCurrencySorting = marginCurrencySorting
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrdersSorting}
   * @param {!OrdersSorting} other Other struct
   * @returns {!OrdersSorting} This struct
   */
  copy (other) {
    if (other.OrderSorting != null) {
      this.OrderSorting = OrderSorting.fromObject(other.OrderSorting)
    } else {
      this.OrderSorting = null
    }
    if (other.OrderStateSorting != null) {
      this.OrderStateSorting = OrderStateSorting.fromObject(other.OrderStateSorting)
    } else {
      this.OrderStateSorting = null
    }
    if (other.AccountSorting != null) {
      this.AccountSorting = AccountSorting.fromObject(other.AccountSorting)
    } else {
      this.AccountSorting = null
    }
    if (other.SymbolSorting != null) {
      this.SymbolSorting = SymbolSorting.fromObject(other.SymbolSorting)
    } else {
      this.SymbolSorting = null
    }
    if (other.MarginCurrencySorting != null) {
      this.MarginCurrencySorting = CurrencySorting.fromObject(other.MarginCurrencySorting)
    } else {
      this.MarginCurrencySorting = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrdersSorting}
   * @returns {!OrdersSorting} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrdersSortingModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrdersSortingModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrdersSorting}
   * @param {!OrdersSorting} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrdersSorting)) {
      throw new TypeError('Instance of OrdersSorting is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrdersSorting}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      OrderSorting: ((this.OrderSorting != null) ? this.OrderSorting : null),
      OrderStateSorting: ((this.OrderStateSorting != null) ? this.OrderStateSorting : null),
      AccountSorting: ((this.AccountSorting != null) ? this.AccountSorting : null),
      SymbolSorting: ((this.SymbolSorting != null) ? this.SymbolSorting : null),
      MarginCurrencySorting: ((this.MarginCurrencySorting != null) ? this.MarginCurrencySorting : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrdersSorting.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrdersSorting} other Object value
   * @returns {!OrdersSorting} Created struct
   */
  static fromObject (other) {
    return new OrdersSorting().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrdersSorting}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrdersSorting.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrdersSorting}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 173
  }
}

export { OrdersSorting };

/**
 * Fast Binary Encoding OrdersSorting field model
 */
class FieldModelOrdersSorting extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.OrderSorting = new fbe.FieldModelOptional(new FieldModelOrderSorting(buffer, 4 + 4), buffer, 4 + 4)
    this.OrderStateSorting = new fbe.FieldModelOptional(new FieldModelOrderStateSorting(buffer, this.OrderSorting.fbeOffset + this.OrderSorting.fbeSize), buffer, this.OrderSorting.fbeOffset + this.OrderSorting.fbeSize)
    this.AccountSorting = new fbe.FieldModelOptional(new FieldModelAccountSorting(buffer, this.OrderStateSorting.fbeOffset + this.OrderStateSorting.fbeSize), buffer, this.OrderStateSorting.fbeOffset + this.OrderStateSorting.fbeSize)
    this.SymbolSorting = new fbe.FieldModelOptional(new FieldModelSymbolSorting(buffer, this.AccountSorting.fbeOffset + this.AccountSorting.fbeSize), buffer, this.AccountSorting.fbeOffset + this.AccountSorting.fbeSize)
    this.MarginCurrencySorting = new fbe.FieldModelOptional(new FieldModelCurrencySorting(buffer, this.SymbolSorting.fbeOffset + this.SymbolSorting.fbeSize), buffer, this.SymbolSorting.fbeOffset + this.SymbolSorting.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.OrderSorting.fbeSize + this.OrderStateSorting.fbeSize + this.AccountSorting.fbeSize + this.SymbolSorting.fbeSize + this.MarginCurrencySorting.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.OrderSorting.fbeExtra + this.OrderStateSorting.fbeExtra + this.AccountSorting.fbeExtra + this.SymbolSorting.fbeExtra + this.MarginCurrencySorting.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrdersSorting.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 173
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrdersSorting}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrdersSorting}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSorting.fbeSize

    if ((fbeCurrentSize + this.OrderStateSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStateSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStateSorting.fbeSize

    if ((fbeCurrentSize + this.AccountSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountSorting.fbeSize

    if ((fbeCurrentSize + this.SymbolSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolSorting.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencySorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencySorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencySorting.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrdersSorting}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrdersSorting}
   * @param {!OrdersSorting} fbeValue Default value, defaults is new OrdersSorting()
   * @returns {!OrdersSorting} OrdersSorting value
   */
  get (fbeValue = new OrdersSorting()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrdersSorting}
   * @param {!OrdersSorting} fbeValue OrdersSorting value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderSorting.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSorting = this.OrderSorting.get()
    } else {
      fbeValue.OrderSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSorting.fbeSize

    if ((fbeCurrentSize + this.OrderStateSorting.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStateSorting = this.OrderStateSorting.get()
    } else {
      fbeValue.OrderStateSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStateSorting.fbeSize

    if ((fbeCurrentSize + this.AccountSorting.fbeSize) <= fbeStructSize) {
      fbeValue.AccountSorting = this.AccountSorting.get()
    } else {
      fbeValue.AccountSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountSorting.fbeSize

    if ((fbeCurrentSize + this.SymbolSorting.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolSorting = this.SymbolSorting.get()
    } else {
      fbeValue.SymbolSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolSorting.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencySorting.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencySorting = this.MarginCurrencySorting.get()
    } else {
      fbeValue.MarginCurrencySorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencySorting.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrdersSorting}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrdersSorting}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrdersSorting}
   * @param {!OrdersSorting} fbeValue OrdersSorting value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrdersSorting}
   * @param {OrdersSorting} fbeValue OrdersSorting value
   */
  setFields (fbeValue) {
    this.OrderSorting.set(fbeValue.OrderSorting)
    this.OrderStateSorting.set(fbeValue.OrderStateSorting)
    this.AccountSorting.set(fbeValue.AccountSorting)
    this.SymbolSorting.set(fbeValue.SymbolSorting)
    this.MarginCurrencySorting.set(fbeValue.MarginCurrencySorting)
  }
}

export { FieldModelOrdersSorting };

/**
 * Fast Binary Encoding OrdersSorting model
 */
class OrdersSortingModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrdersSorting(this.buffer, 4)
  }

  /**
   * Get the OrdersSorting model
   * @this {!OrdersSortingModel}
   * @returns {!FieldModelOrdersSorting} model OrdersSorting model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrdersSortingModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrdersSortingModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrdersSortingModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrdersSortingModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrdersSorting.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrdersSortingModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrdersSortingModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrdersSortingModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrdersSortingModel}
   * @param {!OrdersSorting} value OrdersSorting value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrdersSortingModel}
   * @param {!OrdersSorting} value OrdersSorting value, defaults is new OrdersSorting()
   * @return {!object} Deserialized OrdersSorting value and its size
   */
  deserialize (value = new OrdersSorting()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrdersSorting(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrdersSorting(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrdersSortingModel}
   * @param {!number} prev Previous OrdersSorting model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrdersSortingModel };
