
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * PlatformInfo struct
 */
class PlatformInfo {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!string=} description
   * @param {!string=} companyName
   * @param {!string=} address
   * @param {Date=} rolloverTime
   * @param {Date=} snapshotTime
   * @param {!string=} companyFullName
   * @param {!string=} companyEmail
   * @param {!string=} companyPhone
   * @param {!string=} companyAddress
   * @param {!string=} companyWebsite
   * @param {!string=} companyDescription
   * @constructor
   */
  constructor (name = '', description = '', companyName = '', address = '', rolloverTime = null, snapshotTime = null, companyFullName = '', companyEmail = '', companyPhone = '', companyAddress = '', companyWebsite = '', companyDescription = '') {
    this.Name = name
    this.Description = description
    this.CompanyName = companyName
    this.Address = address
    this.RolloverTime = rolloverTime
    this.SnapshotTime = snapshotTime
    this.CompanyFullName = companyFullName
    this.CompanyEmail = companyEmail
    this.CompanyPhone = companyPhone
    this.CompanyAddress = companyAddress
    this.CompanyWebsite = companyWebsite
    this.CompanyDescription = companyDescription
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PlatformInfo}
   * @param {!PlatformInfo} other Other struct
   * @returns {!PlatformInfo} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.CompanyName != null) {
      this.CompanyName = other.CompanyName
    } else {
      this.CompanyName = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.RolloverTime != null) {
      if (other.RolloverTime instanceof Date) {
        this.RolloverTime = new Date(other.RolloverTime.getTime())
      } else {
        this.RolloverTime = new Date(Math.round(other.RolloverTime / 1000000))
      }
    } else {
      this.RolloverTime = null
    }
    if (other.SnapshotTime != null) {
      if (other.SnapshotTime instanceof Date) {
        this.SnapshotTime = new Date(other.SnapshotTime.getTime())
      } else {
        this.SnapshotTime = new Date(Math.round(other.SnapshotTime / 1000000))
      }
    } else {
      this.SnapshotTime = null
    }
    if (other.CompanyFullName != null) {
      this.CompanyFullName = other.CompanyFullName
    } else {
      this.CompanyFullName = null
    }
    if (other.CompanyEmail != null) {
      this.CompanyEmail = other.CompanyEmail
    } else {
      this.CompanyEmail = null
    }
    if (other.CompanyPhone != null) {
      this.CompanyPhone = other.CompanyPhone
    } else {
      this.CompanyPhone = null
    }
    if (other.CompanyAddress != null) {
      this.CompanyAddress = other.CompanyAddress
    } else {
      this.CompanyAddress = null
    }
    if (other.CompanyWebsite != null) {
      this.CompanyWebsite = other.CompanyWebsite
    } else {
      this.CompanyWebsite = null
    }
    if (other.CompanyDescription != null) {
      this.CompanyDescription = other.CompanyDescription
    } else {
      this.CompanyDescription = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PlatformInfo}
   * @returns {!PlatformInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PlatformInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PlatformInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PlatformInfo}
   * @param {!PlatformInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PlatformInfo)) {
      throw new TypeError('Instance of PlatformInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PlatformInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      Description: ((this.Description != null) ? this.Description : null),
      CompanyName: ((this.CompanyName != null) ? this.CompanyName : null),
      Address: ((this.Address != null) ? this.Address : null),
      RolloverTime: ((this.RolloverTime != null) ? (this.RolloverTime.getTime() * 1000000) : null),
      SnapshotTime: ((this.SnapshotTime != null) ? (this.SnapshotTime.getTime() * 1000000) : null),
      CompanyFullName: ((this.CompanyFullName != null) ? this.CompanyFullName : null),
      CompanyEmail: ((this.CompanyEmail != null) ? this.CompanyEmail : null),
      CompanyPhone: ((this.CompanyPhone != null) ? this.CompanyPhone : null),
      CompanyAddress: ((this.CompanyAddress != null) ? this.CompanyAddress : null),
      CompanyWebsite: ((this.CompanyWebsite != null) ? this.CompanyWebsite : null),
      CompanyDescription: ((this.CompanyDescription != null) ? this.CompanyDescription : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PlatformInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PlatformInfo} other Object value
   * @returns {!PlatformInfo} Created struct
   */
  static fromObject (other) {
    return new PlatformInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PlatformInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PlatformInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PlatformInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5063
  }
}

export { PlatformInfo };

/**
 * Fast Binary Encoding PlatformInfo field model
 */
class FieldModelPlatformInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.Description = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.CompanyName = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.CompanyName.fbeOffset + this.CompanyName.fbeSize)
    this.RolloverTime = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Address.fbeOffset + this.Address.fbeSize), buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.SnapshotTime = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.RolloverTime.fbeOffset + this.RolloverTime.fbeSize), buffer, this.RolloverTime.fbeOffset + this.RolloverTime.fbeSize)
    this.CompanyFullName = new fbe.FieldModelString(buffer, this.SnapshotTime.fbeOffset + this.SnapshotTime.fbeSize)
    this.CompanyEmail = new fbe.FieldModelString(buffer, this.CompanyFullName.fbeOffset + this.CompanyFullName.fbeSize)
    this.CompanyPhone = new fbe.FieldModelString(buffer, this.CompanyEmail.fbeOffset + this.CompanyEmail.fbeSize)
    this.CompanyAddress = new fbe.FieldModelString(buffer, this.CompanyPhone.fbeOffset + this.CompanyPhone.fbeSize)
    this.CompanyWebsite = new fbe.FieldModelString(buffer, this.CompanyAddress.fbeOffset + this.CompanyAddress.fbeSize)
    this.CompanyDescription = new fbe.FieldModelString(buffer, this.CompanyWebsite.fbeOffset + this.CompanyWebsite.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.Description.fbeSize + this.CompanyName.fbeSize + this.Address.fbeSize + this.RolloverTime.fbeSize + this.SnapshotTime.fbeSize + this.CompanyFullName.fbeSize + this.CompanyEmail.fbeSize + this.CompanyPhone.fbeSize + this.CompanyAddress.fbeSize + this.CompanyWebsite.fbeSize + this.CompanyDescription.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.Description.fbeExtra + this.CompanyName.fbeExtra + this.Address.fbeExtra + this.RolloverTime.fbeExtra + this.SnapshotTime.fbeExtra + this.CompanyFullName.fbeExtra + this.CompanyEmail.fbeExtra + this.CompanyPhone.fbeExtra + this.CompanyAddress.fbeExtra + this.CompanyWebsite.fbeExtra + this.CompanyDescription.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPlatformInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5063
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPlatformInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPlatformInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.CompanyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyName.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.RolloverTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RolloverTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RolloverTime.fbeSize

    if ((fbeCurrentSize + this.SnapshotTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SnapshotTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SnapshotTime.fbeSize

    if ((fbeCurrentSize + this.CompanyFullName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyFullName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyFullName.fbeSize

    if ((fbeCurrentSize + this.CompanyEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyEmail.fbeSize

    if ((fbeCurrentSize + this.CompanyPhone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyPhone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyPhone.fbeSize

    if ((fbeCurrentSize + this.CompanyAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyAddress.fbeSize

    if ((fbeCurrentSize + this.CompanyWebsite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyWebsite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.CompanyDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyDescription.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPlatformInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPlatformInfo}
   * @param {!PlatformInfo} fbeValue Default value, defaults is new PlatformInfo()
   * @returns {!PlatformInfo} PlatformInfo value
   */
  get (fbeValue = new PlatformInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPlatformInfo}
   * @param {!PlatformInfo} fbeValue PlatformInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.CompanyName.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyName = this.CompanyName.get()
    } else {
      fbeValue.CompanyName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyName.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.RolloverTime.fbeSize) <= fbeStructSize) {
      fbeValue.RolloverTime = this.RolloverTime.get()
    } else {
      fbeValue.RolloverTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RolloverTime.fbeSize

    if ((fbeCurrentSize + this.SnapshotTime.fbeSize) <= fbeStructSize) {
      fbeValue.SnapshotTime = this.SnapshotTime.get()
    } else {
      fbeValue.SnapshotTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SnapshotTime.fbeSize

    if ((fbeCurrentSize + this.CompanyFullName.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyFullName = this.CompanyFullName.get()
    } else {
      fbeValue.CompanyFullName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyFullName.fbeSize

    if ((fbeCurrentSize + this.CompanyEmail.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyEmail = this.CompanyEmail.get()
    } else {
      fbeValue.CompanyEmail = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyEmail.fbeSize

    if ((fbeCurrentSize + this.CompanyPhone.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyPhone = this.CompanyPhone.get()
    } else {
      fbeValue.CompanyPhone = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyPhone.fbeSize

    if ((fbeCurrentSize + this.CompanyAddress.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyAddress = this.CompanyAddress.get()
    } else {
      fbeValue.CompanyAddress = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyAddress.fbeSize

    if ((fbeCurrentSize + this.CompanyWebsite.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyWebsite = this.CompanyWebsite.get()
    } else {
      fbeValue.CompanyWebsite = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.CompanyDescription.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyDescription = this.CompanyDescription.get()
    } else {
      fbeValue.CompanyDescription = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyDescription.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPlatformInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPlatformInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPlatformInfo}
   * @param {!PlatformInfo} fbeValue PlatformInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPlatformInfo}
   * @param {PlatformInfo} fbeValue PlatformInfo value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.Description.set(fbeValue.Description)
    this.CompanyName.set(fbeValue.CompanyName)
    this.Address.set(fbeValue.Address)
    this.RolloverTime.set(fbeValue.RolloverTime)
    this.SnapshotTime.set(fbeValue.SnapshotTime)
    this.CompanyFullName.set(fbeValue.CompanyFullName)
    this.CompanyEmail.set(fbeValue.CompanyEmail)
    this.CompanyPhone.set(fbeValue.CompanyPhone)
    this.CompanyAddress.set(fbeValue.CompanyAddress)
    this.CompanyWebsite.set(fbeValue.CompanyWebsite)
    this.CompanyDescription.set(fbeValue.CompanyDescription)
  }
}

export { FieldModelPlatformInfo };

/**
 * Fast Binary Encoding PlatformInfo model
 */
class PlatformInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPlatformInfo(this.buffer, 4)
  }

  /**
   * Get the PlatformInfo model
   * @this {!PlatformInfoModel}
   * @returns {!FieldModelPlatformInfo} model PlatformInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PlatformInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PlatformInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PlatformInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PlatformInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPlatformInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PlatformInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PlatformInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PlatformInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PlatformInfoModel}
   * @param {!PlatformInfo} value PlatformInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PlatformInfoModel}
   * @param {!PlatformInfo} value PlatformInfo value, defaults is new PlatformInfo()
   * @return {!object} Deserialized PlatformInfo value and its size
   */
  deserialize (value = new PlatformInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PlatformInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PlatformInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PlatformInfoModel}
   * @param {!number} prev Previous PlatformInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PlatformInfoModel };
