
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Order2ResponseParams} from './Order2ResponseParams';
import {FieldModelOrder2ResponseParams} from './Order2ResponseParams';
import {Account2ResponseParams} from './Account2ResponseParams';
import {FieldModelAccount2ResponseParams} from './Account2ResponseParams';
import {SocialTradingMasterCommission2ResponseParams} from './SocialTradingMasterCommission2ResponseParams';
import {FieldModelSocialTradingMasterCommission2ResponseParams} from './SocialTradingMasterCommission2ResponseParams';

/**
 * Mass2ResponseParams struct
 */
class Mass2ResponseParams extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {Order2ResponseParams=} orderParams
   * @param {Account2ResponseParams=} accountParams
   * @param {SocialTradingMasterCommission2ResponseParams=} socialTradingCommissionsMaster
   * @constructor
   */
  constructor (parent = new Response(), orderParams = null, accountParams = null, socialTradingCommissionsMaster = null) {
    super()
    super.copy(parent)
    this.OrderParams = orderParams
    this.AccountParams = accountParams
    this.SocialTradingCommissionsMaster = socialTradingCommissionsMaster
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Mass2ResponseParams}
   * @param {!Mass2ResponseParams} other Other struct
   * @returns {!Mass2ResponseParams} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.OrderParams != null) {
      this.OrderParams = Order2ResponseParams.fromObject(other.OrderParams)
    } else {
      this.OrderParams = null
    }
    if (other.AccountParams != null) {
      this.AccountParams = Account2ResponseParams.fromObject(other.AccountParams)
    } else {
      this.AccountParams = null
    }
    if (other.SocialTradingCommissionsMaster != null) {
      this.SocialTradingCommissionsMaster = SocialTradingMasterCommission2ResponseParams.fromObject(other.SocialTradingCommissionsMaster)
    } else {
      this.SocialTradingCommissionsMaster = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Mass2ResponseParams}
   * @returns {!Mass2ResponseParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Mass2ResponseParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Mass2ResponseParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Mass2ResponseParams}
   * @param {!Mass2ResponseParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Mass2ResponseParams)) {
      throw new TypeError('Instance of Mass2ResponseParams is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Mass2ResponseParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      OrderParams: ((this.OrderParams != null) ? this.OrderParams : null),
      AccountParams: ((this.AccountParams != null) ? this.AccountParams : null),
      SocialTradingCommissionsMaster: ((this.SocialTradingCommissionsMaster != null) ? this.SocialTradingCommissionsMaster : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Mass2ResponseParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Mass2ResponseParams} other Object value
   * @returns {!Mass2ResponseParams} Created struct
   */
  static fromObject (other) {
    return new Mass2ResponseParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Mass2ResponseParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Mass2ResponseParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Mass2ResponseParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4172
  }
}

export { Mass2ResponseParams };

/**
 * Fast Binary Encoding Mass2ResponseParams field model
 */
class FieldModelMass2ResponseParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.OrderParams = new fbe.FieldModelOptional(new FieldModelOrder2ResponseParams(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountParams = new fbe.FieldModelOptional(new FieldModelAccount2ResponseParams(buffer, this.OrderParams.fbeOffset + this.OrderParams.fbeSize), buffer, this.OrderParams.fbeOffset + this.OrderParams.fbeSize)
    this.SocialTradingCommissionsMaster = new fbe.FieldModelOptional(new FieldModelSocialTradingMasterCommission2ResponseParams(buffer, this.AccountParams.fbeOffset + this.AccountParams.fbeSize), buffer, this.AccountParams.fbeOffset + this.AccountParams.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.OrderParams.fbeSize + this.AccountParams.fbeSize + this.SocialTradingCommissionsMaster.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.OrderParams.fbeExtra + this.AccountParams.fbeExtra + this.SocialTradingCommissionsMaster.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMass2ResponseParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4172
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMass2ResponseParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMass2ResponseParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderParams.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderParams.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderParams.fbeSize

    if ((fbeCurrentSize + this.AccountParams.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountParams.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountParams.fbeSize

    if ((fbeCurrentSize + this.SocialTradingCommissionsMaster.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingCommissionsMaster.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingCommissionsMaster.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMass2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMass2ResponseParams}
   * @param {!Mass2ResponseParams} fbeValue Default value, defaults is new Mass2ResponseParams()
   * @returns {!Mass2ResponseParams} Mass2ResponseParams value
   */
  get (fbeValue = new Mass2ResponseParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMass2ResponseParams}
   * @param {!Mass2ResponseParams} fbeValue Mass2ResponseParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderParams.fbeSize) <= fbeStructSize) {
      fbeValue.OrderParams = this.OrderParams.get()
    } else {
      fbeValue.OrderParams = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderParams.fbeSize

    if ((fbeCurrentSize + this.AccountParams.fbeSize) <= fbeStructSize) {
      fbeValue.AccountParams = this.AccountParams.get()
    } else {
      fbeValue.AccountParams = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountParams.fbeSize

    if ((fbeCurrentSize + this.SocialTradingCommissionsMaster.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingCommissionsMaster = this.SocialTradingCommissionsMaster.get()
    } else {
      fbeValue.SocialTradingCommissionsMaster = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingCommissionsMaster.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMass2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMass2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMass2ResponseParams}
   * @param {!Mass2ResponseParams} fbeValue Mass2ResponseParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMass2ResponseParams}
   * @param {Mass2ResponseParams} fbeValue Mass2ResponseParams value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.OrderParams.set(fbeValue.OrderParams)
    this.AccountParams.set(fbeValue.AccountParams)
    this.SocialTradingCommissionsMaster.set(fbeValue.SocialTradingCommissionsMaster)
  }
}

export { FieldModelMass2ResponseParams };

/**
 * Fast Binary Encoding Mass2ResponseParams model
 */
class Mass2ResponseParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMass2ResponseParams(this.buffer, 4)
  }

  /**
   * Get the Mass2ResponseParams model
   * @this {!Mass2ResponseParamsModel}
   * @returns {!FieldModelMass2ResponseParams} model Mass2ResponseParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Mass2ResponseParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Mass2ResponseParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Mass2ResponseParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Mass2ResponseParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMass2ResponseParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Mass2ResponseParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Mass2ResponseParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Mass2ResponseParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Mass2ResponseParamsModel}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Mass2ResponseParamsModel}
   * @param {!Mass2ResponseParams} value Mass2ResponseParams value, defaults is new Mass2ResponseParams()
   * @return {!object} Deserialized Mass2ResponseParams value and its size
   */
  deserialize (value = new Mass2ResponseParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Mass2ResponseParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Mass2ResponseParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Mass2ResponseParamsModel}
   * @param {!number} prev Previous Mass2ResponseParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Mass2ResponseParamsModel };
