import { AccountMargin as AccountMarginApi } from '@api/fbe/core/AccountMargin';

export type AccountMargin = {
    key?: number;
    Login: number | null;
    ActualVersion: number | null;
    BalanceCurrencyId: string | null;
    Balance: number | null;
    Credit: number | null;
    Leverage: number | null;
    Equity: number | null;
    MarginValue: number | null;
    MarginLevel: number | null;
    LevelType: number | null;
    DayOpenProfit: number | null;
    PrevDayBalance: number | null;
    PrevMonthBalance: number | null;
    DayCloseProfit: number | null;
    DayDeposit: number | null;
    MaxLeverage: number | null;
    PrevDayOpenProfit: number | null;
    OpenProfit: number | null;
    TotalCloseProfit: number | null;
    TotalDeposit: number | null;
    TotalWithdrawal: number | null;
};

export const convertMarginRecordToMarginModel = (record: AccountMarginApi): AccountMargin => ({
    Login: +record.Login.toNumber(),
    ActualVersion: +record.ActualVersion.toNumber(),
    BalanceCurrencyId: record.BalanceCurrencyId.toString(),
    Balance: record.Balance,
    Credit: record.Credit,
    Leverage: record.Leverage,
    Equity: record.Equity,
    MarginValue: record.MarginValue,
    MarginLevel: record.MarginLevel,
    LevelType: record.LevelType.valueOf(),
    DayOpenProfit: record.DayOpenProfit,
    PrevDayBalance: record.PrevDayBalance,
    PrevMonthBalance: record.PrevMonthBalance,
    DayCloseProfit: record.DayCloseProfit,
    DayDeposit: record.DayDeposit,
    MaxLeverage: record.MaxLeverage ?? null,
    PrevDayOpenProfit: record.PrevDayOpenProfit,
    OpenProfit: record.OpenProfit,
    TotalCloseProfit: record.TotalCloseProfit,
    TotalDeposit: record.TotalDeposit,
    TotalWithdrawal: record.TotalWithdrawal,
});
