import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { JournalModel, JournalRequestParams } from '@models/Journal';
import { JournalRequest } from '@api/fbe/manager/JournalRequest';
import { LogLevelType } from '@api/fbe/core/LogLevelType';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export class JournalStore {
    errorTracker = new ErrorsTracker({ title: 'Journal' });

    constructor() {
        makeAutoObservable(this);
    }

    journalRequestParams: JournalRequestParams | null = null;

    setJournalRequestParams = (params: JournalRequestParams | null): void => {
        this.journalRequestParams = params;
    };

    data: JournalModel[] | null = null;

    isLoading: boolean = false;

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setData(value: JournalModel[] | null) {
        this.data = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new JournalRequest();
        if (this.journalRequestParams) {
            if (typeof this.journalRequestParams.LogLevel === 'number') {
                request.LogLevel = new LogLevelType(this.journalRequestParams.LogLevel);
            }
            if (this.journalRequestParams.TimeFrom) {
                request.TimeFrom = this.journalRequestParams.TimeFrom;
            }
            if (this.journalRequestParams.TimeTo) {
                request.TimeTo = this.journalRequestParams.TimeTo;
            }
            request.Module = this.journalRequestParams.Module;
            request.SearchText = this.journalRequestParams.SearchText;
            request.CaseSensitive = this.journalRequestParams.CaseSensitive;
        }
        const result = await SocketClient.instance.request(request, this.errorTracker);
        this.setIsLoading(false);
        this.setData(
            result.Records.map((record, index) => ({
                key: index,
                Time: record.Time ?? null,
                LogLevel: record.LogLevel ? record.LogLevel.valueOf() : null,
                Message: record.Message,
            }))
                .sort((itemA, itemB) => baseSorter(itemA.Time, itemB.Time))
                .reverse(),
        );
    }

    reset() {
        this.data = null;
        this.isLoading = false;
        this.journalRequestParams = null;
    }
}

export const journalStore = new JournalStore();
