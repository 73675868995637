import { authStore } from '@store/auth';

const defaultSettings = {
    debug: false,

    automaticOpen: true,
    isInitConnection: true,

    reconnectInterval: 1000,
    maxReconnectInterval: 30000,
    reconnectDecay: 1.5,

    timeoutInterval: 2000,

    maxReconnectAttempts: null as number|null,

    binaryType: 'blob' as BinaryType
};

type ReconnectingWebSocketOpts = typeof defaultSettings;

export class ReconnectingWebSocket {
    constructor(
        url: string,
        protocols: any,
        options: Partial<ReconnectingWebSocketOpts> = {}
    ) {
        // Overwrite and define settings with options if they exist.
        for (const key in defaultSettings) {
            if (typeof (options as any)[key] !== 'undefined') {
                (this as any)[key] = (options as any)[key];
            } else {
                (this as any)[key] = (defaultSettings as any)[key];
            }
        }

        this.url = url;
        this._protocols = protocols || [];

        // Wire up "on*" properties as event handlers

        // TODO: remove this eventTarget omg
        this._eventTarget.addEventListener('open',       (event) => { this.onopen(event); });
        this._eventTarget.addEventListener('close',      (event) => { this.onclose(event); });
        this._eventTarget.addEventListener('connecting', (event) => { this.onconnecting(event); });
        this._eventTarget.addEventListener('message',    (event) => { this.onmessage(event); });
        this._eventTarget.addEventListener('error',      (event) => { this.onerror(event); });

        // Expose the API required by EventTarget

        this.addEventListener = this._eventTarget.addEventListener.bind(this._eventTarget);
        this.removeEventListener = this._eventTarget.removeEventListener.bind(this._eventTarget);
        this.dispatchEvent = this._eventTarget.dispatchEvent.bind(this._eventTarget);

        // Whether or not to create a websocket upon instantiation
        if (this.automaticOpen === true) {
            this.open(false);
        }
    }

    addEventListener(type: string, listener: any) {}
    removeEventListener(type: string, listener: any) {}
    dispatchEvent(event: Event): boolean { return true; }

    readonly url: string;
    _protocols = [];

    debug = defaultSettings.debug;
    automaticOpen = defaultSettings.automaticOpen;
    reconnectInterval = defaultSettings.reconnectInterval;
    maxReconnectInterval = defaultSettings.maxReconnectInterval;
    reconnectDecay = defaultSettings.reconnectDecay;
    timeoutInterval = defaultSettings.timeoutInterval;
    maxReconnectAttempts = defaultSettings.maxReconnectAttempts;
    binaryType = defaultSettings.binaryType;
    isInitConnection = defaultSettings.isInitConnection;

    _reconnectAttempts = 0;
    _readyState = WebSocket.CONNECTING;
    _protocol!: string;

    _ws!: WebSocket;
    _forcedClose = false;
    _timedOut = false;
    _eventTarget = document.createElement('div');

    _generateEvent = (s: string, args?: any) => {
        let evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(s, false, false, args);
        return evt;
    };

    open = (reconnectAttempt?: boolean) => {
        try {
            this._ws = new WebSocket(this.url, this._protocols);
            this._ws.binaryType = this.binaryType;
        } catch (error) {
            console.debug('CreatingWebSocket', error);
            return;
        }
        if (reconnectAttempt) {
            if (this.maxReconnectAttempts && this._reconnectAttempts > this.maxReconnectAttempts) {
                return;
            }
        } else {
            this._eventTarget.dispatchEvent(this._generateEvent('connecting'));
            this._reconnectAttempts = 0;
        }

        if (this.debug || ReconnectingWebSocket.debugAll) {
            console.debug('ReconnectingWebSocket', 'attempt-connect', this.url);
        }

        let localWs = this._ws;
        let timeout = setTimeout(() => {
            if (this.debug || ReconnectingWebSocket.debugAll) {
                console.debug('ReconnectingWebSocket', 'connection-timeout', this.url);
            }
            this._timedOut = true;
            localWs.close();
            this._timedOut = false;
        }, this.timeoutInterval);

        this._ws.onopen = (event) => {
            clearTimeout(timeout);
            if (this.debug || ReconnectingWebSocket.debugAll) {
                console.debug('ReconnectingWebSocket', 'onopen', this.url);
            }
            this._protocol = this._ws.protocol;
            this._readyState = WebSocket.OPEN;
            this._reconnectAttempts = 0;
            let e = this._generateEvent('open') as any;
            e.isReconnect = reconnectAttempt;
            reconnectAttempt = false;
            this._eventTarget.dispatchEvent(e);
            this.isInitConnection = false;
            if (authStore.auth && !authStore.isLoading) {
                authStore.restoreSession();
            }
        };

        this._ws.onclose = (event) => {
            clearTimeout(timeout);
            this._ws = null!;

            // When init connection failed
            if (this.isInitConnection) {
                this.manualClose(event)
                return;
            };
            if (this._forcedClose) {
                this._readyState = WebSocket.CLOSED;
                this._eventTarget.dispatchEvent(this._generateEvent('close'));
            } else {
                this._readyState = WebSocket.CONNECTING;
                let e = this._generateEvent('connecting') as any;
                e.code = event.code;
                e.reason = event.reason;
                e.wasClean = event.wasClean;
                this._eventTarget.dispatchEvent(e);
                if (!reconnectAttempt && !this._timedOut) {
                    if (this.debug || ReconnectingWebSocket.debugAll) {
                        console.debug('ReconnectingWebSocket', 'onclose', this.url);
                    }
                    this._eventTarget.dispatchEvent(this._generateEvent('close'));
                }

                let timeout = this.reconnectInterval * Math.pow(this.reconnectDecay, this._reconnectAttempts);
                setTimeout(() => {
                    this._reconnectAttempts++;
                    this.open(true);
                }, timeout > this.maxReconnectInterval ? this.maxReconnectInterval : timeout);
            }
        };
        this._ws.onmessage = (event) => {
            if (this.debug || ReconnectingWebSocket.debugAll) {
                console.debug('ReconnectingWebSocket', 'onmessage', this.url, event.data);
            }
            let e = this._generateEvent('message') as any;
            e.data = event.data;
            this._eventTarget.dispatchEvent(e);
        };
        this._ws.onerror = (event) => {
            if (this.debug || ReconnectingWebSocket.debugAll) {
                console.debug('ReconnectingWebSocket', 'onerror', this.url, event);
            }
            this._eventTarget.dispatchEvent(this._generateEvent('error'));
        };
    };

    send = (data: Parameters<WebSocket["send"]>[0]) => {
        if (this._ws) {
            if (this.debug || ReconnectingWebSocket.debugAll) {
                console.debug('ReconnectingWebSocket', 'send', this.url, data);
            }
            return this._ws.send(data);
        } else {
            // eslint-disable-next-line no-throw-literal
            throw 'INVALID_STATE_ERR : Pausing to reconnect websocket';
        }
    };

    close = (code: number | undefined, reason: string | undefined) => {
        // Default CLOSE_NORMAL code
        if (typeof code == 'undefined') {
            code = 1000;
        }
        this._forcedClose = true;
        if (this._ws) {
            this._ws.close(code, reason);
        }
    };

    manualClose = (event?: CloseEvent) => {
        let e = this._generateEvent('error') as any;
        e.code = 1000;
        e.reason = 'error';
        e.wasClean = event ? event.wasClean : true;
        this._eventTarget.dispatchEvent(e);
        this._readyState = WebSocket.CLOSED;
        this.onCantConnect(e)
    }

    refresh = () => {
        if (this._ws) {
            this._ws.close();
        }
    };

    onopen(event: Event) {}
    onclose(event: Event) {}
    onconnecting(event: Event) {}
    onCantConnect(event?: Event) {}
    onmessage(event: Event) {}
    onerror(event: Event) {}

    static debugAll = false;
    
    static CONNECTING = WebSocket.CONNECTING;
    static OPEN = WebSocket.OPEN;
    static CLOSING = WebSocket.CLOSING;
    static CLOSED = WebSocket.CLOSED;
}