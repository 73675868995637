
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * BackupServerSettings struct
 */
class BackupServerSettings {
  /**
   * Initialize struct
   * @param {!boolean=} isConfigSyncEnabled
   * @param {!boolean=} isStorageSyncEnabled
   * @param {!boolean=} isLastPricesSyncEnabled
   * @param {!boolean=} isChartBarHistorySyncEnabled
   * @param {!number=} chartBarSyncBatchSize
   * @param {!number=} chartBarSyncPeriodInSeconds
   * @param {!number=} backupPort
   * @param {!boolean=} useSsl
   * @param {!number=} storageSlowQueryLogThreshold
   * @param {!number=} chartBarsSlowQueryLogThreshold
   * @param {!number=} drawingSlowQueryLogThreshold
   * @param {!boolean=} isDrawingSyncEnabled
   * @param {!number=} drawingSyncBatchSize
   * @param {!number=} drawingSyncPeriodInSeconds
   * @param {!boolean=} isClientReportsSyncEnabled
   * @param {!number=} clientReportsSyncBatchSize
   * @param {!number=} clientReportsSyncPeriodInSeconds
   * @param {!number=} clientReportsSlowQueryLogThreshold
   * @constructor
   */
  constructor (isConfigSyncEnabled = false, isStorageSyncEnabled = false, isLastPricesSyncEnabled = false, isChartBarHistorySyncEnabled = false, chartBarSyncBatchSize = 20000, chartBarSyncPeriodInSeconds = 120, backupPort = 6160, useSsl = false, storageSlowQueryLogThreshold = new UInt64(100000000, 0), chartBarsSlowQueryLogThreshold = new UInt64(500000000, 0), drawingSlowQueryLogThreshold = new UInt64(100000000, 0), isDrawingSyncEnabled = true, drawingSyncBatchSize = 100, drawingSyncPeriodInSeconds = 60, isClientReportsSyncEnabled = true, clientReportsSyncBatchSize = 10000, clientReportsSyncPeriodInSeconds = 120, clientReportsSlowQueryLogThreshold = new UInt64(100000000, 0)) {
    this.IsConfigSyncEnabled = isConfigSyncEnabled
    this.IsStorageSyncEnabled = isStorageSyncEnabled
    this.IsLastPricesSyncEnabled = isLastPricesSyncEnabled
    this.IsChartBarHistorySyncEnabled = isChartBarHistorySyncEnabled
    this.ChartBarSyncBatchSize = chartBarSyncBatchSize
    this.ChartBarSyncPeriodInSeconds = chartBarSyncPeriodInSeconds
    this.BackupPort = backupPort
    this.UseSsl = useSsl
    this.StorageSlowQueryLogThreshold = storageSlowQueryLogThreshold
    this.ChartBarsSlowQueryLogThreshold = chartBarsSlowQueryLogThreshold
    this.DrawingSlowQueryLogThreshold = drawingSlowQueryLogThreshold
    this.IsDrawingSyncEnabled = isDrawingSyncEnabled
    this.DrawingSyncBatchSize = drawingSyncBatchSize
    this.DrawingSyncPeriodInSeconds = drawingSyncPeriodInSeconds
    this.IsClientReportsSyncEnabled = isClientReportsSyncEnabled
    this.ClientReportsSyncBatchSize = clientReportsSyncBatchSize
    this.ClientReportsSyncPeriodInSeconds = clientReportsSyncPeriodInSeconds
    this.ClientReportsSlowQueryLogThreshold = clientReportsSlowQueryLogThreshold
  }

  /**
   * Copy struct (shallow copy)
   * @this {!BackupServerSettings}
   * @param {!BackupServerSettings} other Other struct
   * @returns {!BackupServerSettings} This struct
   */
  copy (other) {
    if (other.IsConfigSyncEnabled != null) {
      this.IsConfigSyncEnabled = other.IsConfigSyncEnabled
    } else {
      this.IsConfigSyncEnabled = null
    }
    if (other.IsStorageSyncEnabled != null) {
      this.IsStorageSyncEnabled = other.IsStorageSyncEnabled
    } else {
      this.IsStorageSyncEnabled = null
    }
    if (other.IsLastPricesSyncEnabled != null) {
      this.IsLastPricesSyncEnabled = other.IsLastPricesSyncEnabled
    } else {
      this.IsLastPricesSyncEnabled = null
    }
    if (other.IsChartBarHistorySyncEnabled != null) {
      this.IsChartBarHistorySyncEnabled = other.IsChartBarHistorySyncEnabled
    } else {
      this.IsChartBarHistorySyncEnabled = null
    }
    if (other.ChartBarSyncBatchSize != null) {
      this.ChartBarSyncBatchSize = other.ChartBarSyncBatchSize
    } else {
      this.ChartBarSyncBatchSize = null
    }
    if (other.ChartBarSyncPeriodInSeconds != null) {
      this.ChartBarSyncPeriodInSeconds = other.ChartBarSyncPeriodInSeconds
    } else {
      this.ChartBarSyncPeriodInSeconds = null
    }
    if (other.BackupPort != null) {
      this.BackupPort = other.BackupPort
    } else {
      this.BackupPort = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    if (other.StorageSlowQueryLogThreshold != null) {
      this.StorageSlowQueryLogThreshold = UInt64.fromNumber(other.StorageSlowQueryLogThreshold)
    } else {
      this.StorageSlowQueryLogThreshold = null
    }
    if (other.ChartBarsSlowQueryLogThreshold != null) {
      this.ChartBarsSlowQueryLogThreshold = UInt64.fromNumber(other.ChartBarsSlowQueryLogThreshold)
    } else {
      this.ChartBarsSlowQueryLogThreshold = null
    }
    if (other.DrawingSlowQueryLogThreshold != null) {
      this.DrawingSlowQueryLogThreshold = UInt64.fromNumber(other.DrawingSlowQueryLogThreshold)
    } else {
      this.DrawingSlowQueryLogThreshold = null
    }
    if (other.IsDrawingSyncEnabled != null) {
      this.IsDrawingSyncEnabled = other.IsDrawingSyncEnabled
    } else {
      this.IsDrawingSyncEnabled = null
    }
    if (other.DrawingSyncBatchSize != null) {
      this.DrawingSyncBatchSize = other.DrawingSyncBatchSize
    } else {
      this.DrawingSyncBatchSize = null
    }
    if (other.DrawingSyncPeriodInSeconds != null) {
      this.DrawingSyncPeriodInSeconds = other.DrawingSyncPeriodInSeconds
    } else {
      this.DrawingSyncPeriodInSeconds = null
    }
    if (other.IsClientReportsSyncEnabled != null) {
      this.IsClientReportsSyncEnabled = other.IsClientReportsSyncEnabled
    } else {
      this.IsClientReportsSyncEnabled = null
    }
    if (other.ClientReportsSyncBatchSize != null) {
      this.ClientReportsSyncBatchSize = other.ClientReportsSyncBatchSize
    } else {
      this.ClientReportsSyncBatchSize = null
    }
    if (other.ClientReportsSyncPeriodInSeconds != null) {
      this.ClientReportsSyncPeriodInSeconds = other.ClientReportsSyncPeriodInSeconds
    } else {
      this.ClientReportsSyncPeriodInSeconds = null
    }
    if (other.ClientReportsSlowQueryLogThreshold != null) {
      this.ClientReportsSlowQueryLogThreshold = UInt64.fromNumber(other.ClientReportsSlowQueryLogThreshold)
    } else {
      this.ClientReportsSlowQueryLogThreshold = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!BackupServerSettings}
   * @returns {!BackupServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new BackupServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new BackupServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!BackupServerSettings}
   * @param {!BackupServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof BackupServerSettings)) {
      throw new TypeError('Instance of BackupServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!BackupServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      IsConfigSyncEnabled: ((this.IsConfigSyncEnabled != null) ? this.IsConfigSyncEnabled : null),
      IsStorageSyncEnabled: ((this.IsStorageSyncEnabled != null) ? this.IsStorageSyncEnabled : null),
      IsLastPricesSyncEnabled: ((this.IsLastPricesSyncEnabled != null) ? this.IsLastPricesSyncEnabled : null),
      IsChartBarHistorySyncEnabled: ((this.IsChartBarHistorySyncEnabled != null) ? this.IsChartBarHistorySyncEnabled : null),
      ChartBarSyncBatchSize: ((this.ChartBarSyncBatchSize != null) ? this.ChartBarSyncBatchSize : null),
      ChartBarSyncPeriodInSeconds: ((this.ChartBarSyncPeriodInSeconds != null) ? this.ChartBarSyncPeriodInSeconds : null),
      BackupPort: ((this.BackupPort != null) ? this.BackupPort : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null),
      StorageSlowQueryLogThreshold: ((this.StorageSlowQueryLogThreshold != null) ? this.StorageSlowQueryLogThreshold.toNumber() : null),
      ChartBarsSlowQueryLogThreshold: ((this.ChartBarsSlowQueryLogThreshold != null) ? this.ChartBarsSlowQueryLogThreshold.toNumber() : null),
      DrawingSlowQueryLogThreshold: ((this.DrawingSlowQueryLogThreshold != null) ? this.DrawingSlowQueryLogThreshold.toNumber() : null),
      IsDrawingSyncEnabled: ((this.IsDrawingSyncEnabled != null) ? this.IsDrawingSyncEnabled : null),
      DrawingSyncBatchSize: ((this.DrawingSyncBatchSize != null) ? this.DrawingSyncBatchSize : null),
      DrawingSyncPeriodInSeconds: ((this.DrawingSyncPeriodInSeconds != null) ? this.DrawingSyncPeriodInSeconds : null),
      IsClientReportsSyncEnabled: ((this.IsClientReportsSyncEnabled != null) ? this.IsClientReportsSyncEnabled : null),
      ClientReportsSyncBatchSize: ((this.ClientReportsSyncBatchSize != null) ? this.ClientReportsSyncBatchSize : null),
      ClientReportsSyncPeriodInSeconds: ((this.ClientReportsSyncPeriodInSeconds != null) ? this.ClientReportsSyncPeriodInSeconds : null),
      ClientReportsSlowQueryLogThreshold: ((this.ClientReportsSlowQueryLogThreshold != null) ? this.ClientReportsSlowQueryLogThreshold.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return BackupServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!BackupServerSettings} other Object value
   * @returns {!BackupServerSettings} Created struct
   */
  static fromObject (other) {
    return new BackupServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!BackupServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return BackupServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!BackupServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 83
  }
}

export { BackupServerSettings };

/**
 * Fast Binary Encoding BackupServerSettings field model
 */
class FieldModelBackupServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.IsConfigSyncEnabled = new fbe.FieldModelBool(buffer, 4 + 4)
    this.IsStorageSyncEnabled = new fbe.FieldModelBool(buffer, this.IsConfigSyncEnabled.fbeOffset + this.IsConfigSyncEnabled.fbeSize)
    this.IsLastPricesSyncEnabled = new fbe.FieldModelBool(buffer, this.IsStorageSyncEnabled.fbeOffset + this.IsStorageSyncEnabled.fbeSize)
    this.IsChartBarHistorySyncEnabled = new fbe.FieldModelBool(buffer, this.IsLastPricesSyncEnabled.fbeOffset + this.IsLastPricesSyncEnabled.fbeSize)
    this.ChartBarSyncBatchSize = new fbe.FieldModelUInt32(buffer, this.IsChartBarHistorySyncEnabled.fbeOffset + this.IsChartBarHistorySyncEnabled.fbeSize)
    this.ChartBarSyncPeriodInSeconds = new fbe.FieldModelUInt32(buffer, this.ChartBarSyncBatchSize.fbeOffset + this.ChartBarSyncBatchSize.fbeSize)
    this.BackupPort = new fbe.FieldModelUInt16(buffer, this.ChartBarSyncPeriodInSeconds.fbeOffset + this.ChartBarSyncPeriodInSeconds.fbeSize)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.BackupPort.fbeOffset + this.BackupPort.fbeSize)
    this.StorageSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize)
    this.ChartBarsSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.StorageSlowQueryLogThreshold.fbeOffset + this.StorageSlowQueryLogThreshold.fbeSize)
    this.DrawingSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.ChartBarsSlowQueryLogThreshold.fbeOffset + this.ChartBarsSlowQueryLogThreshold.fbeSize)
    this.IsDrawingSyncEnabled = new fbe.FieldModelBool(buffer, this.DrawingSlowQueryLogThreshold.fbeOffset + this.DrawingSlowQueryLogThreshold.fbeSize)
    this.DrawingSyncBatchSize = new fbe.FieldModelUInt32(buffer, this.IsDrawingSyncEnabled.fbeOffset + this.IsDrawingSyncEnabled.fbeSize)
    this.DrawingSyncPeriodInSeconds = new fbe.FieldModelUInt32(buffer, this.DrawingSyncBatchSize.fbeOffset + this.DrawingSyncBatchSize.fbeSize)
    this.IsClientReportsSyncEnabled = new fbe.FieldModelBool(buffer, this.DrawingSyncPeriodInSeconds.fbeOffset + this.DrawingSyncPeriodInSeconds.fbeSize)
    this.ClientReportsSyncBatchSize = new fbe.FieldModelUInt32(buffer, this.IsClientReportsSyncEnabled.fbeOffset + this.IsClientReportsSyncEnabled.fbeSize)
    this.ClientReportsSyncPeriodInSeconds = new fbe.FieldModelUInt32(buffer, this.ClientReportsSyncBatchSize.fbeOffset + this.ClientReportsSyncBatchSize.fbeSize)
    this.ClientReportsSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.ClientReportsSyncPeriodInSeconds.fbeOffset + this.ClientReportsSyncPeriodInSeconds.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.IsConfigSyncEnabled.fbeSize + this.IsStorageSyncEnabled.fbeSize + this.IsLastPricesSyncEnabled.fbeSize + this.IsChartBarHistorySyncEnabled.fbeSize + this.ChartBarSyncBatchSize.fbeSize + this.ChartBarSyncPeriodInSeconds.fbeSize + this.BackupPort.fbeSize + this.UseSsl.fbeSize + this.StorageSlowQueryLogThreshold.fbeSize + this.ChartBarsSlowQueryLogThreshold.fbeSize + this.DrawingSlowQueryLogThreshold.fbeSize + this.IsDrawingSyncEnabled.fbeSize + this.DrawingSyncBatchSize.fbeSize + this.DrawingSyncPeriodInSeconds.fbeSize + this.IsClientReportsSyncEnabled.fbeSize + this.ClientReportsSyncBatchSize.fbeSize + this.ClientReportsSyncPeriodInSeconds.fbeSize + this.ClientReportsSlowQueryLogThreshold.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.IsConfigSyncEnabled.fbeExtra + this.IsStorageSyncEnabled.fbeExtra + this.IsLastPricesSyncEnabled.fbeExtra + this.IsChartBarHistorySyncEnabled.fbeExtra + this.ChartBarSyncBatchSize.fbeExtra + this.ChartBarSyncPeriodInSeconds.fbeExtra + this.BackupPort.fbeExtra + this.UseSsl.fbeExtra + this.StorageSlowQueryLogThreshold.fbeExtra + this.ChartBarsSlowQueryLogThreshold.fbeExtra + this.DrawingSlowQueryLogThreshold.fbeExtra + this.IsDrawingSyncEnabled.fbeExtra + this.DrawingSyncBatchSize.fbeExtra + this.DrawingSyncPeriodInSeconds.fbeExtra + this.IsClientReportsSyncEnabled.fbeExtra + this.ClientReportsSyncBatchSize.fbeExtra + this.ClientReportsSyncPeriodInSeconds.fbeExtra + this.ClientReportsSlowQueryLogThreshold.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelBackupServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 83
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelBackupServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelBackupServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.IsConfigSyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsConfigSyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsConfigSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsStorageSyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsStorageSyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsStorageSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsLastPricesSyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsLastPricesSyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsLastPricesSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsChartBarHistorySyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsChartBarHistorySyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsChartBarHistorySyncEnabled.fbeSize

    if ((fbeCurrentSize + this.ChartBarSyncBatchSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartBarSyncBatchSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.ChartBarSyncPeriodInSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartBarSyncPeriodInSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.BackupPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BackupPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.StorageSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.ChartBarsSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartBarsSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.DrawingSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DrawingSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.IsDrawingSyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsDrawingSyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDrawingSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.DrawingSyncBatchSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DrawingSyncBatchSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.DrawingSyncPeriodInSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DrawingSyncPeriodInSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.IsClientReportsSyncEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsClientReportsSyncEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsClientReportsSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSyncBatchSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientReportsSyncBatchSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSyncPeriodInSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientReportsSyncPeriodInSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientReportsSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSlowQueryLogThreshold.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelBackupServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelBackupServerSettings}
   * @param {!BackupServerSettings} fbeValue Default value, defaults is new BackupServerSettings()
   * @returns {!BackupServerSettings} BackupServerSettings value
   */
  get (fbeValue = new BackupServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelBackupServerSettings}
   * @param {!BackupServerSettings} fbeValue BackupServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.IsConfigSyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsConfigSyncEnabled = this.IsConfigSyncEnabled.get(false)
    } else {
      fbeValue.IsConfigSyncEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsConfigSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsStorageSyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsStorageSyncEnabled = this.IsStorageSyncEnabled.get(false)
    } else {
      fbeValue.IsStorageSyncEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsStorageSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsLastPricesSyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsLastPricesSyncEnabled = this.IsLastPricesSyncEnabled.get(false)
    } else {
      fbeValue.IsLastPricesSyncEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsLastPricesSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.IsChartBarHistorySyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsChartBarHistorySyncEnabled = this.IsChartBarHistorySyncEnabled.get(false)
    } else {
      fbeValue.IsChartBarHistorySyncEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsChartBarHistorySyncEnabled.fbeSize

    if ((fbeCurrentSize + this.ChartBarSyncBatchSize.fbeSize) <= fbeStructSize) {
      fbeValue.ChartBarSyncBatchSize = this.ChartBarSyncBatchSize.get(20000)
    } else {
      fbeValue.ChartBarSyncBatchSize = 20000
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.ChartBarSyncPeriodInSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.ChartBarSyncPeriodInSeconds = this.ChartBarSyncPeriodInSeconds.get(120)
    } else {
      fbeValue.ChartBarSyncPeriodInSeconds = 120
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.BackupPort.fbeSize) <= fbeStructSize) {
      fbeValue.BackupPort = this.BackupPort.get(6160)
    } else {
      fbeValue.BackupPort = 6160
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get(false)
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.StorageSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.StorageSlowQueryLogThreshold = this.StorageSlowQueryLogThreshold.get(new UInt64(100000000, 0))
    } else {
      fbeValue.StorageSlowQueryLogThreshold = new UInt64(100000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.ChartBarsSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.ChartBarsSlowQueryLogThreshold = this.ChartBarsSlowQueryLogThreshold.get(new UInt64(500000000, 0))
    } else {
      fbeValue.ChartBarsSlowQueryLogThreshold = new UInt64(500000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.DrawingSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.DrawingSlowQueryLogThreshold = this.DrawingSlowQueryLogThreshold.get(new UInt64(100000000, 0))
    } else {
      fbeValue.DrawingSlowQueryLogThreshold = new UInt64(100000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.IsDrawingSyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsDrawingSyncEnabled = this.IsDrawingSyncEnabled.get(true)
    } else {
      fbeValue.IsDrawingSyncEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDrawingSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.DrawingSyncBatchSize.fbeSize) <= fbeStructSize) {
      fbeValue.DrawingSyncBatchSize = this.DrawingSyncBatchSize.get(100)
    } else {
      fbeValue.DrawingSyncBatchSize = 100
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.DrawingSyncPeriodInSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.DrawingSyncPeriodInSeconds = this.DrawingSyncPeriodInSeconds.get(60)
    } else {
      fbeValue.DrawingSyncPeriodInSeconds = 60
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.IsClientReportsSyncEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsClientReportsSyncEnabled = this.IsClientReportsSyncEnabled.get(true)
    } else {
      fbeValue.IsClientReportsSyncEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsClientReportsSyncEnabled.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSyncBatchSize.fbeSize) <= fbeStructSize) {
      fbeValue.ClientReportsSyncBatchSize = this.ClientReportsSyncBatchSize.get(10000)
    } else {
      fbeValue.ClientReportsSyncBatchSize = 10000
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSyncBatchSize.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSyncPeriodInSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.ClientReportsSyncPeriodInSeconds = this.ClientReportsSyncPeriodInSeconds.get(120)
    } else {
      fbeValue.ClientReportsSyncPeriodInSeconds = 120
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSyncPeriodInSeconds.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.ClientReportsSlowQueryLogThreshold = this.ClientReportsSlowQueryLogThreshold.get(new UInt64(100000000, 0))
    } else {
      fbeValue.ClientReportsSlowQueryLogThreshold = new UInt64(100000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSlowQueryLogThreshold.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelBackupServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelBackupServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelBackupServerSettings}
   * @param {!BackupServerSettings} fbeValue BackupServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelBackupServerSettings}
   * @param {BackupServerSettings} fbeValue BackupServerSettings value
   */
  setFields (fbeValue) {
    this.IsConfigSyncEnabled.set(fbeValue.IsConfigSyncEnabled)
    this.IsStorageSyncEnabled.set(fbeValue.IsStorageSyncEnabled)
    this.IsLastPricesSyncEnabled.set(fbeValue.IsLastPricesSyncEnabled)
    this.IsChartBarHistorySyncEnabled.set(fbeValue.IsChartBarHistorySyncEnabled)
    this.ChartBarSyncBatchSize.set(fbeValue.ChartBarSyncBatchSize)
    this.ChartBarSyncPeriodInSeconds.set(fbeValue.ChartBarSyncPeriodInSeconds)
    this.BackupPort.set(fbeValue.BackupPort)
    this.UseSsl.set(fbeValue.UseSsl)
    this.StorageSlowQueryLogThreshold.set(fbeValue.StorageSlowQueryLogThreshold)
    this.ChartBarsSlowQueryLogThreshold.set(fbeValue.ChartBarsSlowQueryLogThreshold)
    this.DrawingSlowQueryLogThreshold.set(fbeValue.DrawingSlowQueryLogThreshold)
    this.IsDrawingSyncEnabled.set(fbeValue.IsDrawingSyncEnabled)
    this.DrawingSyncBatchSize.set(fbeValue.DrawingSyncBatchSize)
    this.DrawingSyncPeriodInSeconds.set(fbeValue.DrawingSyncPeriodInSeconds)
    this.IsClientReportsSyncEnabled.set(fbeValue.IsClientReportsSyncEnabled)
    this.ClientReportsSyncBatchSize.set(fbeValue.ClientReportsSyncBatchSize)
    this.ClientReportsSyncPeriodInSeconds.set(fbeValue.ClientReportsSyncPeriodInSeconds)
    this.ClientReportsSlowQueryLogThreshold.set(fbeValue.ClientReportsSlowQueryLogThreshold)
  }
}

export { FieldModelBackupServerSettings };

/**
 * Fast Binary Encoding BackupServerSettings model
 */
class BackupServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelBackupServerSettings(this.buffer, 4)
  }

  /**
   * Get the BackupServerSettings model
   * @this {!BackupServerSettingsModel}
   * @returns {!FieldModelBackupServerSettings} model BackupServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!BackupServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!BackupServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return BackupServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!BackupServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelBackupServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!BackupServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!BackupServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!BackupServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!BackupServerSettingsModel}
   * @param {!BackupServerSettings} value BackupServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!BackupServerSettingsModel}
   * @param {!BackupServerSettings} value BackupServerSettings value, defaults is new BackupServerSettings()
   * @return {!object} Deserialized BackupServerSettings value and its size
   */
  deserialize (value = new BackupServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new BackupServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new BackupServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!BackupServerSettingsModel}
   * @param {!number} prev Previous BackupServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { BackupServerSettingsModel };
