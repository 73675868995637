import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { LeverageProfile as LeverageProfileAPI } from '@api/fbe/core/LeverageProfile';
import { LeverageProfileLeverageMode } from '@api/fbe/core/LeverageProfileLeverageMode';
import { LeverageProfileValue } from '@api/fbe/core/LeverageProfileValue';
import { LeverageProfile } from '@models/profiles/LeverageProfile';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { LeverageProfilesGetAllRequest } from '@api/fbe/manager/LeverageProfilesGetAllRequest';
import { LeverageProfileCreateRequest } from '@api/fbe/manager/LeverageProfileCreateRequest';
import { LeverageProfileModifyRequest } from '@api/fbe/manager/LeverageProfileModifyRequest';
import { LeverageProfileDeleteRequest } from '@api/fbe/manager/LeverageProfileDeleteRequest';

class LeverageProfileStore {
    errorTracker = new ErrorsTracker({ title: 'Leverage Profiles' });

    constructor() {
        makeAutoObservable(this);
    }

    data: LeverageProfile[] = [];

    isLoading = false;

    setData(value: LeverageProfile[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new LeverageProfilesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.LeverageProfiles.map(
                (result: LeverageProfileAPI): LeverageProfile => ({
                    id: result.id.toString(),
                    Name: result.Name,
                    Comment: result.Comment,
                    Description: result.Description,
                    LeverageMode: result.LeverageMode.valueOf(),
                    LeverageThreshold: result.LeverageThreshold,
                    RefreshTime: result.RefreshTime,
                    Values: result.Values.map((item) => ({
                        From: item.From,
                        To: item.To ?? null,
                        Leverage: item.Leverage,
                    })),
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: LeverageProfile) {
        const request = new LeverageProfileCreateRequest();
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        if (record.LeverageMode !== null) request.LeverageMode = new LeverageProfileLeverageMode(record.LeverageMode);
        if (record.LeverageThreshold !== null) request.LeverageThreshold = record.LeverageThreshold;
        if (record.RefreshTime !== null) request.RefreshTime = record.RefreshTime;
        request.Values = record.Values.map(
            (item) => new LeverageProfileValue(item.From ?? undefined, item.To, item.Leverage ?? undefined),
        );

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: LeverageProfile, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new LeverageProfileModifyRequest();
        request.LeverageProfileId = UUID.fromString(record.id);
        if (dirtyFields?.Name) request.NewName = record.Name;
        if (dirtyFields?.Comment) request.NewComment = record.Comment;
        if (dirtyFields?.Description) request.NewDescription = record.Description;
        if (dirtyFields?.LeverageMode)
            request.NewLeverageMode =
                record.LeverageMode !== null ? new LeverageProfileLeverageMode(record.LeverageMode) : null;
        if (dirtyFields?.LeverageThreshold) request.NewLeverageThreshold = record.LeverageThreshold;
        if (dirtyFields?.RefreshTime) request.NewRefreshTime = record.RefreshTime;
        if (dirtyFields?.Values)
            request.NewValues = record.Values.map(
                (item) => new LeverageProfileValue(item.From ?? undefined, item.To, item.Leverage ?? undefined),
            );
        if (!record.Values.length) request.ValuesReset = true;

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new LeverageProfileDeleteRequest();
        request.LeverageProfileId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const leverageProfileStore = new LeverageProfileStore();
