
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {TransferMoneyResponse} from './TransferMoneyResponse';

/**
 * TransferMoneyRequest struct
 */
class TransferMoneyRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} srcLogin
   * @param {!number=} dstLogin
   * @param {!number=} amount
   * @param {!string=} comment
   * @param {UUID=} currencyId
   * @param {string=} currencyName
   * @constructor
   */
  constructor (parent = new Request(), srcLogin = new UInt64(0, 0), dstLogin = new UInt64(0, 0), amount = 0.0, comment = '', currencyId = null, currencyName = null) {
    super()
    super.copy(parent)
    this.SrcLogin = srcLogin
    this.DstLogin = dstLogin
    this.Amount = amount
    this.Comment = comment
    this.CurrencyId = currencyId
    this.CurrencyName = currencyName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TransferMoneyRequest}
   * @param {!TransferMoneyRequest} other Other struct
   * @returns {!TransferMoneyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SrcLogin != null) {
      this.SrcLogin = UInt64.fromNumber(other.SrcLogin)
    } else {
      this.SrcLogin = null
    }
    if (other.DstLogin != null) {
      this.DstLogin = UInt64.fromNumber(other.DstLogin)
    } else {
      this.DstLogin = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.CurrencyId != null) {
      this.CurrencyId = new UUID(other.CurrencyId)
    } else {
      this.CurrencyId = null
    }
    if (other.CurrencyName != null) {
      this.CurrencyName = other.CurrencyName
    } else {
      this.CurrencyName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TransferMoneyRequest}
   * @returns {!TransferMoneyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TransferMoneyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TransferMoneyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TransferMoneyRequest}
   * @param {!TransferMoneyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TransferMoneyRequest)) {
      throw new TypeError('Instance of TransferMoneyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TransferMoneyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SrcLogin: ((this.SrcLogin != null) ? this.SrcLogin.toNumber() : null),
      DstLogin: ((this.DstLogin != null) ? this.DstLogin.toNumber() : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      CurrencyId: ((this.CurrencyId != null) ? this.CurrencyId.toString() : null),
      CurrencyName: ((this.CurrencyName != null) ? this.CurrencyName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TransferMoneyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TransferMoneyRequest} other Object value
   * @returns {!TransferMoneyRequest} Created struct
   */
  static fromObject (other) {
    return new TransferMoneyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TransferMoneyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TransferMoneyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TransferMoneyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6280
  }
}

export { TransferMoneyRequest };

/**
 * Fast Binary Encoding TransferMoneyRequest field model
 */
class FieldModelTransferMoneyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.SrcLogin = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.DstLogin = new fbe.FieldModelUInt64(buffer, this.SrcLogin.fbeOffset + this.SrcLogin.fbeSize)
    this.Amount = new fbe.FieldModelDouble(buffer, this.DstLogin.fbeOffset + this.DstLogin.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.CurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Comment.fbeOffset + this.Comment.fbeSize), buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.CurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CurrencyId.fbeOffset + this.CurrencyId.fbeSize), buffer, this.CurrencyId.fbeOffset + this.CurrencyId.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SrcLogin.fbeSize + this.DstLogin.fbeSize + this.Amount.fbeSize + this.Comment.fbeSize + this.CurrencyId.fbeSize + this.CurrencyName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SrcLogin.fbeExtra + this.DstLogin.fbeExtra + this.Amount.fbeExtra + this.Comment.fbeExtra + this.CurrencyId.fbeExtra + this.CurrencyName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTransferMoneyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6280
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SrcLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcLogin.fbeSize

    if ((fbeCurrentSize + this.DstLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstLogin.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.CurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyId.fbeSize

    if ((fbeCurrentSize + this.CurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!TransferMoneyRequest} fbeValue Default value, defaults is new TransferMoneyRequest()
   * @returns {!TransferMoneyRequest} TransferMoneyRequest value
   */
  get (fbeValue = new TransferMoneyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!TransferMoneyRequest} fbeValue TransferMoneyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SrcLogin.fbeSize) <= fbeStructSize) {
      fbeValue.SrcLogin = this.SrcLogin.get()
    } else {
      fbeValue.SrcLogin = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcLogin.fbeSize

    if ((fbeCurrentSize + this.DstLogin.fbeSize) <= fbeStructSize) {
      fbeValue.DstLogin = this.DstLogin.get()
    } else {
      fbeValue.DstLogin = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstLogin.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.CurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyId = this.CurrencyId.get()
    } else {
      fbeValue.CurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyId.fbeSize

    if ((fbeCurrentSize + this.CurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.CurrencyName = this.CurrencyName.get()
    } else {
      fbeValue.CurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrencyName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTransferMoneyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTransferMoneyRequest}
   * @param {!TransferMoneyRequest} fbeValue TransferMoneyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTransferMoneyRequest}
   * @param {TransferMoneyRequest} fbeValue TransferMoneyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SrcLogin.set(fbeValue.SrcLogin)
    this.DstLogin.set(fbeValue.DstLogin)
    this.Amount.set(fbeValue.Amount)
    this.Comment.set(fbeValue.Comment)
    this.CurrencyId.set(fbeValue.CurrencyId)
    this.CurrencyName.set(fbeValue.CurrencyName)
  }
}

export { FieldModelTransferMoneyRequest };

/**
 * Fast Binary Encoding TransferMoneyRequest model
 */
class TransferMoneyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTransferMoneyRequest(this.buffer, 4)
  }

  /**
   * Get the TransferMoneyRequest model
   * @this {!TransferMoneyRequestModel}
   * @returns {!FieldModelTransferMoneyRequest} model TransferMoneyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TransferMoneyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TransferMoneyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TransferMoneyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TransferMoneyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTransferMoneyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TransferMoneyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TransferMoneyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TransferMoneyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TransferMoneyRequestModel}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TransferMoneyRequestModel}
   * @param {!TransferMoneyRequest} value TransferMoneyRequest value, defaults is new TransferMoneyRequest()
   * @return {!object} Deserialized TransferMoneyRequest value and its size
   */
  deserialize (value = new TransferMoneyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TransferMoneyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TransferMoneyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TransferMoneyRequestModel}
   * @param {!number} prev Previous TransferMoneyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TransferMoneyRequestModel };
TransferMoneyRequest.__has_response = true;
TransferMoneyRequest.__response_class = TransferMoneyResponse;
