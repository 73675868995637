
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {SocialTradingAllocationMethod} from '../core/SocialTradingAllocationMethod';
import {FieldModelSocialTradingAllocationMethod} from '../core/SocialTradingAllocationMethod';
import {SocialTradingAccountMode} from '../core/SocialTradingAccountMode';
import {FieldModelSocialTradingAccountMode} from '../core/SocialTradingAccountMode';

/**
 * SocialTradingInvestorUpdate struct
 */
class SocialTradingInvestorUpdate {
  /**
   * Initialize struct
   * @param {SocialTradingAllocationMethod=} allocationMethod
   * @param {SocialTradingAccountMode=} accountMode
   * @param {number=} masterAccountLogin
   * @param {number=} lot
   * @param {number=} marginLevel
   * @param {number=} percentageLossLevel
   * @param {number=} hwmDelta
   * @constructor
   */
  constructor (allocationMethod = null, accountMode = null, masterAccountLogin = null, lot = null, marginLevel = null, percentageLossLevel = null, hwmDelta = null) {
    this.AllocationMethod = allocationMethod
    this.AccountMode = accountMode
    this.MasterAccountLogin = masterAccountLogin
    this.Lot = lot
    this.MarginLevel = marginLevel
    this.PercentageLossLevel = percentageLossLevel
    this.HwmDelta = hwmDelta
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SocialTradingInvestorUpdate}
   * @param {!SocialTradingInvestorUpdate} other Other struct
   * @returns {!SocialTradingInvestorUpdate} This struct
   */
  copy (other) {
    if (other.AllocationMethod != null) {
      this.AllocationMethod = SocialTradingAllocationMethod.fromObject(other.AllocationMethod)
    } else {
      this.AllocationMethod = null
    }
    if (other.AccountMode != null) {
      this.AccountMode = SocialTradingAccountMode.fromObject(other.AccountMode)
    } else {
      this.AccountMode = null
    }
    if (other.MasterAccountLogin != null) {
      this.MasterAccountLogin = UInt64.fromNumber(other.MasterAccountLogin)
    } else {
      this.MasterAccountLogin = null
    }
    if (other.Lot != null) {
      this.Lot = other.Lot
    } else {
      this.Lot = null
    }
    if (other.MarginLevel != null) {
      this.MarginLevel = other.MarginLevel
    } else {
      this.MarginLevel = null
    }
    if (other.PercentageLossLevel != null) {
      this.PercentageLossLevel = other.PercentageLossLevel
    } else {
      this.PercentageLossLevel = null
    }
    if (other.HwmDelta != null) {
      this.HwmDelta = other.HwmDelta
    } else {
      this.HwmDelta = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SocialTradingInvestorUpdate}
   * @returns {!SocialTradingInvestorUpdate} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SocialTradingInvestorUpdateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SocialTradingInvestorUpdateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SocialTradingInvestorUpdate}
   * @param {!SocialTradingInvestorUpdate} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SocialTradingInvestorUpdate)) {
      throw new TypeError('Instance of SocialTradingInvestorUpdate is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SocialTradingInvestorUpdate}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AllocationMethod: ((this.AllocationMethod != null) ? this.AllocationMethod : null),
      AccountMode: ((this.AccountMode != null) ? this.AccountMode : null),
      MasterAccountLogin: ((this.MasterAccountLogin != null) ? this.MasterAccountLogin.toNumber() : null),
      Lot: ((this.Lot != null) ? this.Lot : null),
      MarginLevel: ((this.MarginLevel != null) ? this.MarginLevel : null),
      PercentageLossLevel: ((this.PercentageLossLevel != null) ? this.PercentageLossLevel : null),
      HwmDelta: ((this.HwmDelta != null) ? this.HwmDelta : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SocialTradingInvestorUpdate.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SocialTradingInvestorUpdate} other Object value
   * @returns {!SocialTradingInvestorUpdate} Created struct
   */
  static fromObject (other) {
    return new SocialTradingInvestorUpdate().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SocialTradingInvestorUpdate}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SocialTradingInvestorUpdate.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SocialTradingInvestorUpdate}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4164
  }
}

export { SocialTradingInvestorUpdate };

/**
 * Fast Binary Encoding SocialTradingInvestorUpdate field model
 */
class FieldModelSocialTradingInvestorUpdate extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AllocationMethod = new fbe.FieldModelOptional(new FieldModelSocialTradingAllocationMethod(buffer, 4 + 4), buffer, 4 + 4)
    this.AccountMode = new fbe.FieldModelOptional(new FieldModelSocialTradingAccountMode(buffer, this.AllocationMethod.fbeOffset + this.AllocationMethod.fbeSize), buffer, this.AllocationMethod.fbeOffset + this.AllocationMethod.fbeSize)
    this.MasterAccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountMode.fbeOffset + this.AccountMode.fbeSize), buffer, this.AccountMode.fbeOffset + this.AccountMode.fbeSize)
    this.Lot = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MasterAccountLogin.fbeOffset + this.MasterAccountLogin.fbeSize), buffer, this.MasterAccountLogin.fbeOffset + this.MasterAccountLogin.fbeSize)
    this.MarginLevel = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Lot.fbeOffset + this.Lot.fbeSize), buffer, this.Lot.fbeOffset + this.Lot.fbeSize)
    this.PercentageLossLevel = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize), buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize)
    this.HwmDelta = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.PercentageLossLevel.fbeOffset + this.PercentageLossLevel.fbeSize), buffer, this.PercentageLossLevel.fbeOffset + this.PercentageLossLevel.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AllocationMethod.fbeSize + this.AccountMode.fbeSize + this.MasterAccountLogin.fbeSize + this.Lot.fbeSize + this.MarginLevel.fbeSize + this.PercentageLossLevel.fbeSize + this.HwmDelta.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AllocationMethod.fbeExtra + this.AccountMode.fbeExtra + this.MasterAccountLogin.fbeExtra + this.Lot.fbeExtra + this.MarginLevel.fbeExtra + this.PercentageLossLevel.fbeExtra + this.HwmDelta.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSocialTradingInvestorUpdate.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4164
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AllocationMethod.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AllocationMethod.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AllocationMethod.fbeSize

    if ((fbeCurrentSize + this.AccountMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMode.fbeSize

    if ((fbeCurrentSize + this.MasterAccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MasterAccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MasterAccountLogin.fbeSize

    if ((fbeCurrentSize + this.Lot.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Lot.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Lot.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.PercentageLossLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PercentageLossLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PercentageLossLevel.fbeSize

    if ((fbeCurrentSize + this.HwmDelta.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HwmDelta.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HwmDelta.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!SocialTradingInvestorUpdate} fbeValue Default value, defaults is new SocialTradingInvestorUpdate()
   * @returns {!SocialTradingInvestorUpdate} SocialTradingInvestorUpdate value
   */
  get (fbeValue = new SocialTradingInvestorUpdate()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!SocialTradingInvestorUpdate} fbeValue SocialTradingInvestorUpdate value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AllocationMethod.fbeSize) <= fbeStructSize) {
      fbeValue.AllocationMethod = this.AllocationMethod.get()
    } else {
      fbeValue.AllocationMethod = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AllocationMethod.fbeSize

    if ((fbeCurrentSize + this.AccountMode.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMode = this.AccountMode.get()
    } else {
      fbeValue.AccountMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMode.fbeSize

    if ((fbeCurrentSize + this.MasterAccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.MasterAccountLogin = this.MasterAccountLogin.get()
    } else {
      fbeValue.MasterAccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MasterAccountLogin.fbeSize

    if ((fbeCurrentSize + this.Lot.fbeSize) <= fbeStructSize) {
      fbeValue.Lot = this.Lot.get()
    } else {
      fbeValue.Lot = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Lot.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginLevel = this.MarginLevel.get()
    } else {
      fbeValue.MarginLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.PercentageLossLevel.fbeSize) <= fbeStructSize) {
      fbeValue.PercentageLossLevel = this.PercentageLossLevel.get()
    } else {
      fbeValue.PercentageLossLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PercentageLossLevel.fbeSize

    if ((fbeCurrentSize + this.HwmDelta.fbeSize) <= fbeStructSize) {
      fbeValue.HwmDelta = this.HwmDelta.get()
    } else {
      fbeValue.HwmDelta = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HwmDelta.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {!SocialTradingInvestorUpdate} fbeValue SocialTradingInvestorUpdate value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSocialTradingInvestorUpdate}
   * @param {SocialTradingInvestorUpdate} fbeValue SocialTradingInvestorUpdate value
   */
  setFields (fbeValue) {
    this.AllocationMethod.set(fbeValue.AllocationMethod)
    this.AccountMode.set(fbeValue.AccountMode)
    this.MasterAccountLogin.set(fbeValue.MasterAccountLogin)
    this.Lot.set(fbeValue.Lot)
    this.MarginLevel.set(fbeValue.MarginLevel)
    this.PercentageLossLevel.set(fbeValue.PercentageLossLevel)
    this.HwmDelta.set(fbeValue.HwmDelta)
  }
}

export { FieldModelSocialTradingInvestorUpdate };

/**
 * Fast Binary Encoding SocialTradingInvestorUpdate model
 */
class SocialTradingInvestorUpdateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSocialTradingInvestorUpdate(this.buffer, 4)
  }

  /**
   * Get the SocialTradingInvestorUpdate model
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!FieldModelSocialTradingInvestorUpdate} model SocialTradingInvestorUpdate model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SocialTradingInvestorUpdateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSocialTradingInvestorUpdate.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SocialTradingInvestorUpdateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SocialTradingInvestorUpdateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SocialTradingInvestorUpdateModel}
   * @param {!SocialTradingInvestorUpdate} value SocialTradingInvestorUpdate value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SocialTradingInvestorUpdateModel}
   * @param {!SocialTradingInvestorUpdate} value SocialTradingInvestorUpdate value, defaults is new SocialTradingInvestorUpdate()
   * @return {!object} Deserialized SocialTradingInvestorUpdate value and its size
   */
  deserialize (value = new SocialTradingInvestorUpdate()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SocialTradingInvestorUpdate(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SocialTradingInvestorUpdate(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SocialTradingInvestorUpdateModel}
   * @param {!number} prev Previous SocialTradingInvestorUpdate model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SocialTradingInvestorUpdateModel };
