import { Dispatch, SetStateAction } from 'react';

export enum OperationType {
    create = 'create',
    update = 'update',
    read = 'read',
}

export type WeekDay = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

export interface IFormData<EntityT> {
    type: OperationType;
    currentRow?: EntityT;
}

export interface IFormContext<EntityT> {
    reset: () => void;
    formData: IFormData<EntityT> | undefined;
    setFormData: Dispatch<SetStateAction<IFormData<EntityT> | undefined>>;
    currentTab?: string;
}
