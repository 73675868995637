export type LoginValues = {
    wsUrl: string;
    login: string;
    password: string;
};

export const WS_PREFIX = 'wss://';

export const isValidWsUrl = (wsUrl?: string | null): boolean => !(!wsUrl || wsUrl === '""' || wsUrl === 'undefined');

export const getPrepagedWsUrl = (wsUrl: string): string =>
    wsUrl.startsWith(WS_PREFIX) ? wsUrl.trim() : `${WS_PREFIX}${wsUrl.trim()}`;

export const getWSUrlWithoutPrefix = (wsUrl: string): string =>
    wsUrl.trim().slice(wsUrl.startsWith(WS_PREFIX) ? WS_PREFIX.length : 0);
