
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Authentication} from './Authentication';
import {FieldModelAuthentication} from './Authentication';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {ReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FieldModelReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FeaturesToggleForce} from '../core/FeaturesToggleForce';
import {FieldModelFeaturesToggleForce} from '../core/FeaturesToggleForce';
import {SocialTradingAccountType} from '../core/SocialTradingAccountType';
import {FieldModelSocialTradingAccountType} from '../core/SocialTradingAccountType';
import {SocialTradingMaster} from '../core/SocialTradingMaster';
import {FieldModelSocialTradingMaster} from '../core/SocialTradingMaster';
import {SocialTradingInvestor} from '../core/SocialTradingInvestor';
import {FieldModelSocialTradingInvestor} from '../core/SocialTradingInvestor';

/**
 * TradingAccount struct
 */
class TradingAccount {
  /**
   * Initialize struct
   * @param {!number=} accountId
   * @param {!number=} actualVersion
   * @param {!UUID=} accountGroupId
   * @param {!number=} personId
   * @param {!string=} comment
   * @param {!string=} description
   * @param {boolean=} isEnabled
   * @param {boolean=} isTradeEnabled
   * @param {!Authentication=} authentication
   * @param {!AccountingType=} accountingType
   * @param {number=} agentAccount
   * @param {!number=} maxOrders
   * @param {!number=} maxPendingOrders
   * @param {!Date=} created
   * @param {!Date=} modified
   * @param {!Date=} archived
   * @param {!Date=} deleted
   * @param {!Date=} lastLogin
   * @param {!string=} group
   * @param {!string=} platform
   * @param {!ReportsSubscriptionType=} reportsSubscriptionType
   * @param {string=} externalAccountId
   * @param {!FeaturesToggleForce=} featuresToggleForce
   * @param {!SocialTradingAccountType=} socialTradingAccountType
   * @param {SocialTradingMaster=} socialTradingMaster
   * @param {SocialTradingInvestor=} socialTradingInvestor
   * @constructor
   */
  constructor (accountId = new UInt64(0, 0), actualVersion = new UInt64(0, 0), accountGroupId = new UUID(), personId = new UInt64(0, 0), comment = '', description = '', isEnabled = null, isTradeEnabled = null, authentication = new Authentication(), accountingType = new AccountingType(), agentAccount = null, maxOrders = new UInt64(0, 0), maxPendingOrders = new UInt64(0, 0), created = new Date(0), modified = new Date(0), archived = new Date(0), deleted = new Date(0), lastLogin = new Date(0), group = '', platform = '', reportsSubscriptionType = new ReportsSubscriptionType(), externalAccountId = null, featuresToggleForce = new FeaturesToggleForce(), socialTradingAccountType = new SocialTradingAccountType(), socialTradingMaster = null, socialTradingInvestor = null) {
    this.AccountId = accountId
    this.ActualVersion = actualVersion
    this.AccountGroupId = accountGroupId
    this.PersonId = personId
    this.Comment = comment
    this.Description = description
    this.IsEnabled = isEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.Authentication = authentication
    this.AccountingType = accountingType
    this.AgentAccount = agentAccount
    this.MaxOrders = maxOrders
    this.MaxPendingOrders = maxPendingOrders
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.LastLogin = lastLogin
    this.Group = group
    this.Platform = platform
    this.ReportsSubscriptionType = reportsSubscriptionType
    this.ExternalAccountId = externalAccountId
    this.FeaturesToggleForce = featuresToggleForce
    this.SocialTradingAccountType = socialTradingAccountType
    this.SocialTradingMaster = socialTradingMaster
    this.SocialTradingInvestor = socialTradingInvestor
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingAccount}
   * @param {!TradingAccount} other Other struct
   * @returns {!TradingAccount} This struct
   */
  copy (other) {
    if (other.AccountId != null) {
      this.AccountId = UInt64.fromNumber(other.AccountId)
    } else {
      this.AccountId = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.PersonId != null) {
      this.PersonId = UInt64.fromNumber(other.PersonId)
    } else {
      this.PersonId = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = other.IsTradeEnabled
    } else {
      this.IsTradeEnabled = null
    }
    if (other.Authentication != null) {
      this.Authentication = Authentication.fromObject(other.Authentication)
    } else {
      this.Authentication = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = AccountingType.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.AgentAccount != null) {
      this.AgentAccount = UInt64.fromNumber(other.AgentAccount)
    } else {
      this.AgentAccount = null
    }
    if (other.MaxOrders != null) {
      this.MaxOrders = UInt64.fromNumber(other.MaxOrders)
    } else {
      this.MaxOrders = null
    }
    if (other.MaxPendingOrders != null) {
      this.MaxPendingOrders = UInt64.fromNumber(other.MaxPendingOrders)
    } else {
      this.MaxPendingOrders = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    if (other.LastLogin != null) {
      if (other.LastLogin instanceof Date) {
        this.LastLogin = new Date(other.LastLogin.getTime())
      } else {
        this.LastLogin = new Date(Math.round(other.LastLogin / 1000000))
      }
    } else {
      this.LastLogin = null
    }
    if (other.Group != null) {
      this.Group = other.Group
    } else {
      this.Group = null
    }
    if (other.Platform != null) {
      this.Platform = other.Platform
    } else {
      this.Platform = null
    }
    if (other.ReportsSubscriptionType != null) {
      this.ReportsSubscriptionType = ReportsSubscriptionType.fromObject(other.ReportsSubscriptionType)
    } else {
      this.ReportsSubscriptionType = null
    }
    if (other.ExternalAccountId != null) {
      this.ExternalAccountId = other.ExternalAccountId
    } else {
      this.ExternalAccountId = null
    }
    if (other.FeaturesToggleForce != null) {
      this.FeaturesToggleForce = FeaturesToggleForce.fromObject(other.FeaturesToggleForce)
    } else {
      this.FeaturesToggleForce = null
    }
    if (other.SocialTradingAccountType != null) {
      this.SocialTradingAccountType = SocialTradingAccountType.fromObject(other.SocialTradingAccountType)
    } else {
      this.SocialTradingAccountType = null
    }
    if (other.SocialTradingMaster != null) {
      this.SocialTradingMaster = SocialTradingMaster.fromObject(other.SocialTradingMaster)
    } else {
      this.SocialTradingMaster = null
    }
    if (other.SocialTradingInvestor != null) {
      this.SocialTradingInvestor = SocialTradingInvestor.fromObject(other.SocialTradingInvestor)
    } else {
      this.SocialTradingInvestor = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingAccount}
   * @returns {!TradingAccount} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingAccountModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingAccountModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingAccount}
   * @param {!TradingAccount} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingAccount)) {
      throw new TypeError('Instance of TradingAccount is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.AccountId !== other.AccountId) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingAccount}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountId: ((this.AccountId != null) ? this.AccountId.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      PersonId: ((this.PersonId != null) ? this.PersonId.toNumber() : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      Authentication: ((this.Authentication != null) ? this.Authentication : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      AgentAccount: ((this.AgentAccount != null) ? this.AgentAccount.toNumber() : null),
      MaxOrders: ((this.MaxOrders != null) ? this.MaxOrders.toNumber() : null),
      MaxPendingOrders: ((this.MaxPendingOrders != null) ? this.MaxPendingOrders.toNumber() : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null),
      LastLogin: ((this.LastLogin != null) ? (this.LastLogin.getTime() * 1000000) : null),
      Group: ((this.Group != null) ? this.Group : null),
      Platform: ((this.Platform != null) ? this.Platform : null),
      ReportsSubscriptionType: ((this.ReportsSubscriptionType != null) ? this.ReportsSubscriptionType : null),
      ExternalAccountId: ((this.ExternalAccountId != null) ? this.ExternalAccountId : null),
      FeaturesToggleForce: ((this.FeaturesToggleForce != null) ? this.FeaturesToggleForce : null),
      SocialTradingAccountType: ((this.SocialTradingAccountType != null) ? this.SocialTradingAccountType : null),
      SocialTradingMaster: ((this.SocialTradingMaster != null) ? this.SocialTradingMaster : null),
      SocialTradingInvestor: ((this.SocialTradingInvestor != null) ? this.SocialTradingInvestor : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingAccount.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingAccount} other Object value
   * @returns {!TradingAccount} Created struct
   */
  static fromObject (other) {
    return new TradingAccount().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingAccount}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingAccount.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingAccount}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 13015
  }
}

export { TradingAccount };

/**
 * Fast Binary Encoding TradingAccount field model
 */
class FieldModelTradingAccount extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.AccountId.fbeOffset + this.AccountId.fbeSize)
    this.AccountGroupId = new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.PersonId = new fbe.FieldModelUInt64(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.PersonId.fbeOffset + this.PersonId.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.Authentication = new FieldModelAuthentication(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.AccountingType = new FieldModelAccountingType(buffer, this.Authentication.fbeOffset + this.Authentication.fbeSize)
    this.AgentAccount = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize), buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.MaxOrders = new fbe.FieldModelUInt64(buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize)
    this.MaxPendingOrders = new fbe.FieldModelUInt64(buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize)
    this.Modified = new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.LastLogin = new fbe.FieldModelTimestamp(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.Group = new fbe.FieldModelString(buffer, this.LastLogin.fbeOffset + this.LastLogin.fbeSize)
    this.Platform = new fbe.FieldModelString(buffer, this.Group.fbeOffset + this.Group.fbeSize)
    this.ReportsSubscriptionType = new FieldModelReportsSubscriptionType(buffer, this.Platform.fbeOffset + this.Platform.fbeSize)
    this.ExternalAccountId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize), buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize)
    this.FeaturesToggleForce = new FieldModelFeaturesToggleForce(buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize)
    this.SocialTradingAccountType = new FieldModelSocialTradingAccountType(buffer, this.FeaturesToggleForce.fbeOffset + this.FeaturesToggleForce.fbeSize)
    this.SocialTradingMaster = new fbe.FieldModelOptional(new FieldModelSocialTradingMaster(buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize), buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize)
    this.SocialTradingInvestor = new fbe.FieldModelOptional(new FieldModelSocialTradingInvestor(buffer, this.SocialTradingMaster.fbeOffset + this.SocialTradingMaster.fbeSize), buffer, this.SocialTradingMaster.fbeOffset + this.SocialTradingMaster.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountId.fbeSize + this.ActualVersion.fbeSize + this.AccountGroupId.fbeSize + this.PersonId.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.IsEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.Authentication.fbeSize + this.AccountingType.fbeSize + this.AgentAccount.fbeSize + this.MaxOrders.fbeSize + this.MaxPendingOrders.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.LastLogin.fbeSize + this.Group.fbeSize + this.Platform.fbeSize + this.ReportsSubscriptionType.fbeSize + this.ExternalAccountId.fbeSize + this.FeaturesToggleForce.fbeSize + this.SocialTradingAccountType.fbeSize + this.SocialTradingMaster.fbeSize + this.SocialTradingInvestor.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountId.fbeExtra + this.ActualVersion.fbeExtra + this.AccountGroupId.fbeExtra + this.PersonId.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.IsEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.Authentication.fbeExtra + this.AccountingType.fbeExtra + this.AgentAccount.fbeExtra + this.MaxOrders.fbeExtra + this.MaxPendingOrders.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.LastLogin.fbeExtra + this.Group.fbeExtra + this.Platform.fbeExtra + this.ReportsSubscriptionType.fbeExtra + this.ExternalAccountId.fbeExtra + this.FeaturesToggleForce.fbeExtra + this.SocialTradingAccountType.fbeExtra + this.SocialTradingMaster.fbeExtra + this.SocialTradingInvestor.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingAccount.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 13015
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingAccount}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingAccount}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.PersonId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PersonId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.Authentication.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Authentication.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Authentication.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AgentAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.Group.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Group.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Group.fbeSize

    if ((fbeCurrentSize + this.Platform.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Platform.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platform.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggleForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingAccountType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMaster.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMaster.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMaster.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingInvestor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestor.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingAccount}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingAccount}
   * @param {!TradingAccount} fbeValue Default value, defaults is new TradingAccount()
   * @returns {!TradingAccount} TradingAccount value
   */
  get (fbeValue = new TradingAccount()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingAccount}
   * @param {!TradingAccount} fbeValue TradingAccount value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountId = this.AccountId.get()
    } else {
      fbeValue.AccountId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.PersonId.fbeSize) <= fbeStructSize) {
      fbeValue.PersonId = this.PersonId.get()
    } else {
      fbeValue.PersonId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PersonId.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get()
    } else {
      fbeValue.IsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.Authentication.fbeSize) <= fbeStructSize) {
      fbeValue.Authentication = this.Authentication.get()
    } else {
      fbeValue.Authentication = new Authentication()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Authentication.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = new AccountingType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) <= fbeStructSize) {
      fbeValue.AgentAccount = this.AgentAccount.get()
    } else {
      fbeValue.AgentAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxOrders = this.MaxOrders.get()
    } else {
      fbeValue.MaxOrders = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxPendingOrders = this.MaxPendingOrders.get()
    } else {
      fbeValue.MaxPendingOrders = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) <= fbeStructSize) {
      fbeValue.LastLogin = this.LastLogin.get()
    } else {
      fbeValue.LastLogin = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.Group.fbeSize) <= fbeStructSize) {
      fbeValue.Group = this.Group.get()
    } else {
      fbeValue.Group = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Group.fbeSize

    if ((fbeCurrentSize + this.Platform.fbeSize) <= fbeStructSize) {
      fbeValue.Platform = this.Platform.get()
    } else {
      fbeValue.Platform = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Platform.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsSubscriptionType = this.ReportsSubscriptionType.get()
    } else {
      fbeValue.ReportsSubscriptionType = new ReportsSubscriptionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAccountId = this.ExternalAccountId.get()
    } else {
      fbeValue.ExternalAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleForce.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggleForce = this.FeaturesToggleForce.get()
    } else {
      fbeValue.FeaturesToggleForce = new FeaturesToggleForce()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingAccountType = this.SocialTradingAccountType.get()
    } else {
      fbeValue.SocialTradingAccountType = new SocialTradingAccountType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMaster.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMaster = this.SocialTradingMaster.get()
    } else {
      fbeValue.SocialTradingMaster = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMaster.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestor.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingInvestor = this.SocialTradingInvestor.get()
    } else {
      fbeValue.SocialTradingInvestor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestor.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingAccount}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingAccount}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingAccount}
   * @param {!TradingAccount} fbeValue TradingAccount value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingAccount}
   * @param {TradingAccount} fbeValue TradingAccount value
   */
  setFields (fbeValue) {
    this.AccountId.set(fbeValue.AccountId)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.PersonId.set(fbeValue.PersonId)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.Authentication.set(fbeValue.Authentication)
    this.AccountingType.set(fbeValue.AccountingType)
    this.AgentAccount.set(fbeValue.AgentAccount)
    this.MaxOrders.set(fbeValue.MaxOrders)
    this.MaxPendingOrders.set(fbeValue.MaxPendingOrders)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.LastLogin.set(fbeValue.LastLogin)
    this.Group.set(fbeValue.Group)
    this.Platform.set(fbeValue.Platform)
    this.ReportsSubscriptionType.set(fbeValue.ReportsSubscriptionType)
    this.ExternalAccountId.set(fbeValue.ExternalAccountId)
    this.FeaturesToggleForce.set(fbeValue.FeaturesToggleForce)
    this.SocialTradingAccountType.set(fbeValue.SocialTradingAccountType)
    this.SocialTradingMaster.set(fbeValue.SocialTradingMaster)
    this.SocialTradingInvestor.set(fbeValue.SocialTradingInvestor)
  }
}

export { FieldModelTradingAccount };

/**
 * Fast Binary Encoding TradingAccount model
 */
class TradingAccountModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingAccount(this.buffer, 4)
  }

  /**
   * Get the TradingAccount model
   * @this {!TradingAccountModel}
   * @returns {!FieldModelTradingAccount} model TradingAccount model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingAccountModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingAccountModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingAccountModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingAccountModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingAccount.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingAccountModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingAccountModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingAccountModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingAccountModel}
   * @param {!TradingAccount} value TradingAccount value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingAccountModel}
   * @param {!TradingAccount} value TradingAccount value, defaults is new TradingAccount()
   * @return {!object} Deserialized TradingAccount value and its size
   */
  deserialize (value = new TradingAccount()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingAccount(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingAccount(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingAccountModel}
   * @param {!number} prev Previous TradingAccount model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingAccountModel };
