
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TransportType enum
 */
class TransportType {
  /**
   * Initialize enum with a given value
   * @param {TransportType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof TransportType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!TransportType}
   * @param {!TransportType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TransportType)) {
      throw new TypeError('Instance of TransportType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!TransportType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!TransportType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === TransportType.UNKNOWN.value) {
      return 'UNKNOWN'
    }
    if (this.value === TransportType.TCP.value) {
      return 'TCP'
    }
    if (this.value === TransportType.SSL.value) {
      return 'SSL'
    }
    if (this.value === TransportType.UDP.value) {
      return 'UDP'
    }
    if (this.value === TransportType.WS.value) {
      return 'WS'
    }
    if (this.value === TransportType.WSS.value) {
      return 'WSS'
    }
    if (this.value === TransportType.HTTP.value) {
      return 'HTTP'
    }
    if (this.value === TransportType.HTTPS.value) {
      return 'HTTPS'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!TransportType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!TransportType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!TransportType} Created enum
   */
  static fromObject (other) {
    return new TransportType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
TransportType.UNKNOWN = new TransportType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
TransportType.TCP = new TransportType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
TransportType.SSL = new TransportType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
TransportType.UDP = new TransportType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
TransportType.WS = new TransportType(0 + 4)
// noinspection PointlessArithmeticExpressionJS
TransportType.WSS = new TransportType(0 + 5)
// noinspection PointlessArithmeticExpressionJS
TransportType.HTTP = new TransportType(0 + 6)
// noinspection PointlessArithmeticExpressionJS
TransportType.HTTPS = new TransportType(0 + 7)

export { TransportType };

/**
 * Fast Binary Encoding TransportType field model
 */
class FieldModelTransportType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTransportType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelTransportType}
   * @param {TransportType=} defaults Default value, defaults is new TransportType()
   * @returns {!TransportType} Result value
   */
  get (defaults = new TransportType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new TransportType(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelTransportType}
   * @param {!TransportType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelTransportType };
