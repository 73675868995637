
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Reject} from '../core/Reject';
import {FieldModelReject} from '../core/Reject';

/**
 * LoginReject struct
 */
class LoginReject extends Reject {
  /**
   * Initialize struct
   * @param {!Reject=} parent
   * @param {!boolean=} isLoginRequired
   * @param {!boolean=} isPasswordRequired
   * @param {!boolean=} isOTPTokenRequired
   * @param {!boolean=} isProtocolVersionRequired
   * @param {!boolean=} internalError
   * @constructor
   */
  constructor (parent = new Reject(), isLoginRequired = false, isPasswordRequired = false, isOTPTokenRequired = false, isProtocolVersionRequired = false, internalError = false) {
    super()
    super.copy(parent)
    this.IsLoginRequired = isLoginRequired
    this.IsPasswordRequired = isPasswordRequired
    this.IsOTPTokenRequired = isOTPTokenRequired
    this.IsProtocolVersionRequired = isProtocolVersionRequired
    this.InternalError = internalError
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LoginReject}
   * @param {!LoginReject} other Other struct
   * @returns {!LoginReject} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.IsLoginRequired != null) {
      this.IsLoginRequired = other.IsLoginRequired
    } else {
      this.IsLoginRequired = null
    }
    if (other.IsPasswordRequired != null) {
      this.IsPasswordRequired = other.IsPasswordRequired
    } else {
      this.IsPasswordRequired = null
    }
    if (other.IsOTPTokenRequired != null) {
      this.IsOTPTokenRequired = other.IsOTPTokenRequired
    } else {
      this.IsOTPTokenRequired = null
    }
    if (other.IsProtocolVersionRequired != null) {
      this.IsProtocolVersionRequired = other.IsProtocolVersionRequired
    } else {
      this.IsProtocolVersionRequired = null
    }
    if (other.InternalError != null) {
      this.InternalError = other.InternalError
    } else {
      this.InternalError = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LoginReject}
   * @returns {!LoginReject} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LoginRejectModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LoginRejectModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LoginReject}
   * @param {!LoginReject} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LoginReject)) {
      throw new TypeError('Instance of LoginReject is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LoginReject}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      IsLoginRequired: ((this.IsLoginRequired != null) ? this.IsLoginRequired : null),
      IsPasswordRequired: ((this.IsPasswordRequired != null) ? this.IsPasswordRequired : null),
      IsOTPTokenRequired: ((this.IsOTPTokenRequired != null) ? this.IsOTPTokenRequired : null),
      IsProtocolVersionRequired: ((this.IsProtocolVersionRequired != null) ? this.IsProtocolVersionRequired : null),
      InternalError: ((this.InternalError != null) ? this.InternalError : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LoginReject.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LoginReject} other Object value
   * @returns {!LoginReject} Created struct
   */
  static fromObject (other) {
    return new LoginReject().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LoginReject}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LoginReject.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LoginReject}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5005
  }
}

export { LoginReject };

/**
 * Fast Binary Encoding LoginReject field model
 */
class FieldModelLoginReject extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelReject(buffer, 4 + 4)
    this.IsLoginRequired = new fbe.FieldModelBool(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.IsPasswordRequired = new fbe.FieldModelBool(buffer, this.IsLoginRequired.fbeOffset + this.IsLoginRequired.fbeSize)
    this.IsOTPTokenRequired = new fbe.FieldModelBool(buffer, this.IsPasswordRequired.fbeOffset + this.IsPasswordRequired.fbeSize)
    this.IsProtocolVersionRequired = new fbe.FieldModelBool(buffer, this.IsOTPTokenRequired.fbeOffset + this.IsOTPTokenRequired.fbeSize)
    this.InternalError = new fbe.FieldModelBool(buffer, this.IsProtocolVersionRequired.fbeOffset + this.IsProtocolVersionRequired.fbeSize)
  }

  /**
   * Get the core.Reject field model
   * @this {!FieldModelLoginReject}
   * @returns {!FieldModelReject} core.Reject field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.IsLoginRequired.fbeSize + this.IsPasswordRequired.fbeSize + this.IsOTPTokenRequired.fbeSize + this.IsProtocolVersionRequired.fbeSize + this.InternalError.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.IsLoginRequired.fbeExtra + this.IsPasswordRequired.fbeExtra + this.IsOTPTokenRequired.fbeExtra + this.IsProtocolVersionRequired.fbeExtra + this.InternalError.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLoginReject.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5005
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLoginReject}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLoginReject}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.IsLoginRequired.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsLoginRequired.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsLoginRequired.fbeSize

    if ((fbeCurrentSize + this.IsPasswordRequired.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsPasswordRequired.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsPasswordRequired.fbeSize

    if ((fbeCurrentSize + this.IsOTPTokenRequired.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPTokenRequired.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPTokenRequired.fbeSize

    if ((fbeCurrentSize + this.IsProtocolVersionRequired.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsProtocolVersionRequired.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsProtocolVersionRequired.fbeSize

    if ((fbeCurrentSize + this.InternalError.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InternalError.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InternalError.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLoginReject}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLoginReject}
   * @param {!LoginReject} fbeValue Default value, defaults is new LoginReject()
   * @returns {!LoginReject} LoginReject value
   */
  get (fbeValue = new LoginReject()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLoginReject}
   * @param {!LoginReject} fbeValue LoginReject value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.IsLoginRequired.fbeSize) <= fbeStructSize) {
      fbeValue.IsLoginRequired = this.IsLoginRequired.get()
    } else {
      fbeValue.IsLoginRequired = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsLoginRequired.fbeSize

    if ((fbeCurrentSize + this.IsPasswordRequired.fbeSize) <= fbeStructSize) {
      fbeValue.IsPasswordRequired = this.IsPasswordRequired.get()
    } else {
      fbeValue.IsPasswordRequired = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsPasswordRequired.fbeSize

    if ((fbeCurrentSize + this.IsOTPTokenRequired.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPTokenRequired = this.IsOTPTokenRequired.get()
    } else {
      fbeValue.IsOTPTokenRequired = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPTokenRequired.fbeSize

    if ((fbeCurrentSize + this.IsProtocolVersionRequired.fbeSize) <= fbeStructSize) {
      fbeValue.IsProtocolVersionRequired = this.IsProtocolVersionRequired.get()
    } else {
      fbeValue.IsProtocolVersionRequired = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsProtocolVersionRequired.fbeSize

    if ((fbeCurrentSize + this.InternalError.fbeSize) <= fbeStructSize) {
      fbeValue.InternalError = this.InternalError.get()
    } else {
      fbeValue.InternalError = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InternalError.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLoginReject}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLoginReject}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLoginReject}
   * @param {!LoginReject} fbeValue LoginReject value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLoginReject}
   * @param {LoginReject} fbeValue LoginReject value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.IsLoginRequired.set(fbeValue.IsLoginRequired)
    this.IsPasswordRequired.set(fbeValue.IsPasswordRequired)
    this.IsOTPTokenRequired.set(fbeValue.IsOTPTokenRequired)
    this.IsProtocolVersionRequired.set(fbeValue.IsProtocolVersionRequired)
    this.InternalError.set(fbeValue.InternalError)
  }
}

export { FieldModelLoginReject };

/**
 * Fast Binary Encoding LoginReject model
 */
class LoginRejectModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLoginReject(this.buffer, 4)
  }

  /**
   * Get the LoginReject model
   * @this {!LoginRejectModel}
   * @returns {!FieldModelLoginReject} model LoginReject model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LoginRejectModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LoginRejectModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LoginRejectModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LoginRejectModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLoginReject.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LoginRejectModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LoginRejectModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LoginRejectModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LoginRejectModel}
   * @param {!LoginReject} value LoginReject value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LoginRejectModel}
   * @param {!LoginReject} value LoginReject value, defaults is new LoginReject()
   * @return {!object} Deserialized LoginReject value and its size
   */
  deserialize (value = new LoginReject()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LoginReject(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LoginReject(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LoginRejectModel}
   * @param {!number} prev Previous LoginReject model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LoginRejectModel };
