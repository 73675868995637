
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {AccountModifyResponse} from './AccountModifyResponse';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {ReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FieldModelReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FeaturesToggleForce} from '../core/FeaturesToggleForce';
import {FieldModelFeaturesToggleForce} from '../core/FeaturesToggleForce';
import {SocialTradingMasterUpdate} from './SocialTradingMasterUpdate';
import {FieldModelSocialTradingMasterUpdate} from './SocialTradingMasterUpdate';
import {SocialTradingInvestorUpdate} from './SocialTradingInvestorUpdate';
import {FieldModelSocialTradingInvestorUpdate} from './SocialTradingInvestorUpdate';

/**
 * AccountModifyRequest struct
 */
class AccountModifyRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} login
   * @param {UUID=} newAccountGroupId
   * @param {string=} newAccountGroupName
   * @param {string=} newName
   * @param {string=} newCountry
   * @param {string=} newCity
   * @param {string=} newState
   * @param {string=} newZipCode
   * @param {string=} newAddress
   * @param {string=} newPhone
   * @param {string=} newEmail
   * @param {string=} newIdentifier
   * @param {string=} newStatus
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {boolean=} newIsEnabled
   * @param {!boolean=} newIsEnabledReset
   * @param {boolean=} deprecated_NewIsOTPEnabled
   * @param {!boolean=} deprecated_NewIsOTPEnabledReset
   * @param {boolean=} newIsTradeEnabled
   * @param {!boolean=} newIsTradeEnabledReset
   * @param {string=} newPassword
   * @param {string=} deprecated_NewInvestorPassword
   * @param {string=} newPhonePassword
   * @param {AccountingType=} newAccountingType
   * @param {number=} newAgentAccount
   * @param {!boolean=} newAgentAccountReset
   * @param {number=} newMaxPendingOrders
   * @param {UUID=} newBalanceCurrencyId
   * @param {string=} newBalanceCurrencyName
   * @param {number=} newLeverage
   * @param {Date=} newLastLogin
   * @param {number=} newPrevDayOpenProfit
   * @param {number=} newMaxLeverage
   * @param {!boolean=} newMaxLeverageReset
   * @param {ReportsSubscriptionType=} newReportsSubscriptionType
   * @param {number=} newMaxOrders
   * @param {string=} newExternalClientId
   * @param {!boolean=} newExternalClientIdReset
   * @param {string=} newExternalAccountId
   * @param {!boolean=} newExternalAccountIdReset
   * @param {string=} newLocalLanguageName
   * @param {FeaturesToggleForce=} newFeaturesToggleForce
   * @param {SocialTradingMasterUpdate=} socialTradingMasterUpdate
   * @param {SocialTradingInvestorUpdate=} socialTradingInvestorUpdate
   * @constructor
   */
  constructor (parent = new Request(), login = new UInt64(0, 0), newAccountGroupId = null, newAccountGroupName = null, newName = null, newCountry = null, newCity = null, newState = null, newZipCode = null, newAddress = null, newPhone = null, newEmail = null, newIdentifier = null, newStatus = null, newComment = null, newDescription = null, newIsEnabled = null, newIsEnabledReset = false, deprecated_NewIsOTPEnabled = null, deprecated_NewIsOTPEnabledReset = false, newIsTradeEnabled = null, newIsTradeEnabledReset = false, newPassword = null, deprecated_NewInvestorPassword = null, newPhonePassword = null, newAccountingType = null, newAgentAccount = null, newAgentAccountReset = false, newMaxPendingOrders = null, newBalanceCurrencyId = null, newBalanceCurrencyName = null, newLeverage = null, newLastLogin = null, newPrevDayOpenProfit = null, newMaxLeverage = null, newMaxLeverageReset = false, newReportsSubscriptionType = null, newMaxOrders = null, newExternalClientId = null, newExternalClientIdReset = false, newExternalAccountId = null, newExternalAccountIdReset = false, newLocalLanguageName = null, newFeaturesToggleForce = null, socialTradingMasterUpdate = null, socialTradingInvestorUpdate = null) {
    super()
    super.copy(parent)
    this.Login = login
    this.NewAccountGroupId = newAccountGroupId
    this.NewAccountGroupName = newAccountGroupName
    this.NewName = newName
    this.NewCountry = newCountry
    this.NewCity = newCity
    this.NewState = newState
    this.NewZipCode = newZipCode
    this.NewAddress = newAddress
    this.NewPhone = newPhone
    this.NewEmail = newEmail
    this.NewIdentifier = newIdentifier
    this.NewStatus = newStatus
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewIsEnabled = newIsEnabled
    this.NewIsEnabledReset = newIsEnabledReset
    this.Deprecated_NewIsOTPEnabled = deprecated_NewIsOTPEnabled
    this.Deprecated_NewIsOTPEnabledReset = deprecated_NewIsOTPEnabledReset
    this.NewIsTradeEnabled = newIsTradeEnabled
    this.NewIsTradeEnabledReset = newIsTradeEnabledReset
    this.NewPassword = newPassword
    this.Deprecated_NewInvestorPassword = deprecated_NewInvestorPassword
    this.NewPhonePassword = newPhonePassword
    this.NewAccountingType = newAccountingType
    this.NewAgentAccount = newAgentAccount
    this.NewAgentAccountReset = newAgentAccountReset
    this.NewMaxPendingOrders = newMaxPendingOrders
    this.NewBalanceCurrencyId = newBalanceCurrencyId
    this.NewBalanceCurrencyName = newBalanceCurrencyName
    this.NewLeverage = newLeverage
    this.NewLastLogin = newLastLogin
    this.NewPrevDayOpenProfit = newPrevDayOpenProfit
    this.NewMaxLeverage = newMaxLeverage
    this.NewMaxLeverageReset = newMaxLeverageReset
    this.NewReportsSubscriptionType = newReportsSubscriptionType
    this.NewMaxOrders = newMaxOrders
    this.NewExternalClientId = newExternalClientId
    this.NewExternalClientIdReset = newExternalClientIdReset
    this.NewExternalAccountId = newExternalAccountId
    this.NewExternalAccountIdReset = newExternalAccountIdReset
    this.NewLocalLanguageName = newLocalLanguageName
    this.NewFeaturesToggleForce = newFeaturesToggleForce
    this.SocialTradingMasterUpdate = socialTradingMasterUpdate
    this.SocialTradingInvestorUpdate = socialTradingInvestorUpdate
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountModifyRequest}
   * @param {!AccountModifyRequest} other Other struct
   * @returns {!AccountModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.NewAccountGroupId != null) {
      this.NewAccountGroupId = new UUID(other.NewAccountGroupId)
    } else {
      this.NewAccountGroupId = null
    }
    if (other.NewAccountGroupName != null) {
      this.NewAccountGroupName = other.NewAccountGroupName
    } else {
      this.NewAccountGroupName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewCountry != null) {
      this.NewCountry = other.NewCountry
    } else {
      this.NewCountry = null
    }
    if (other.NewCity != null) {
      this.NewCity = other.NewCity
    } else {
      this.NewCity = null
    }
    if (other.NewState != null) {
      this.NewState = other.NewState
    } else {
      this.NewState = null
    }
    if (other.NewZipCode != null) {
      this.NewZipCode = other.NewZipCode
    } else {
      this.NewZipCode = null
    }
    if (other.NewAddress != null) {
      this.NewAddress = other.NewAddress
    } else {
      this.NewAddress = null
    }
    if (other.NewPhone != null) {
      this.NewPhone = other.NewPhone
    } else {
      this.NewPhone = null
    }
    if (other.NewEmail != null) {
      this.NewEmail = other.NewEmail
    } else {
      this.NewEmail = null
    }
    if (other.NewIdentifier != null) {
      this.NewIdentifier = other.NewIdentifier
    } else {
      this.NewIdentifier = null
    }
    if (other.NewStatus != null) {
      this.NewStatus = other.NewStatus
    } else {
      this.NewStatus = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewIsEnabledReset != null) {
      this.NewIsEnabledReset = other.NewIsEnabledReset
    } else {
      this.NewIsEnabledReset = null
    }
    if (other.Deprecated_NewIsOTPEnabled != null) {
      this.Deprecated_NewIsOTPEnabled = other.Deprecated_NewIsOTPEnabled
    } else {
      this.Deprecated_NewIsOTPEnabled = null
    }
    if (other.Deprecated_NewIsOTPEnabledReset != null) {
      this.Deprecated_NewIsOTPEnabledReset = other.Deprecated_NewIsOTPEnabledReset
    } else {
      this.Deprecated_NewIsOTPEnabledReset = null
    }
    if (other.NewIsTradeEnabled != null) {
      this.NewIsTradeEnabled = other.NewIsTradeEnabled
    } else {
      this.NewIsTradeEnabled = null
    }
    if (other.NewIsTradeEnabledReset != null) {
      this.NewIsTradeEnabledReset = other.NewIsTradeEnabledReset
    } else {
      this.NewIsTradeEnabledReset = null
    }
    if (other.NewPassword != null) {
      this.NewPassword = other.NewPassword
    } else {
      this.NewPassword = null
    }
    if (other.Deprecated_NewInvestorPassword != null) {
      this.Deprecated_NewInvestorPassword = other.Deprecated_NewInvestorPassword
    } else {
      this.Deprecated_NewInvestorPassword = null
    }
    if (other.NewPhonePassword != null) {
      this.NewPhonePassword = other.NewPhonePassword
    } else {
      this.NewPhonePassword = null
    }
    if (other.NewAccountingType != null) {
      this.NewAccountingType = AccountingType.fromObject(other.NewAccountingType)
    } else {
      this.NewAccountingType = null
    }
    if (other.NewAgentAccount != null) {
      this.NewAgentAccount = UInt64.fromNumber(other.NewAgentAccount)
    } else {
      this.NewAgentAccount = null
    }
    if (other.NewAgentAccountReset != null) {
      this.NewAgentAccountReset = other.NewAgentAccountReset
    } else {
      this.NewAgentAccountReset = null
    }
    if (other.NewMaxPendingOrders != null) {
      this.NewMaxPendingOrders = UInt64.fromNumber(other.NewMaxPendingOrders)
    } else {
      this.NewMaxPendingOrders = null
    }
    if (other.NewBalanceCurrencyId != null) {
      this.NewBalanceCurrencyId = new UUID(other.NewBalanceCurrencyId)
    } else {
      this.NewBalanceCurrencyId = null
    }
    if (other.NewBalanceCurrencyName != null) {
      this.NewBalanceCurrencyName = other.NewBalanceCurrencyName
    } else {
      this.NewBalanceCurrencyName = null
    }
    if (other.NewLeverage != null) {
      this.NewLeverage = other.NewLeverage
    } else {
      this.NewLeverage = null
    }
    if (other.NewLastLogin != null) {
      if (other.NewLastLogin instanceof Date) {
        this.NewLastLogin = new Date(other.NewLastLogin.getTime())
      } else {
        this.NewLastLogin = new Date(Math.round(other.NewLastLogin / 1000000))
      }
    } else {
      this.NewLastLogin = null
    }
    if (other.NewPrevDayOpenProfit != null) {
      this.NewPrevDayOpenProfit = other.NewPrevDayOpenProfit
    } else {
      this.NewPrevDayOpenProfit = null
    }
    if (other.NewMaxLeverage != null) {
      this.NewMaxLeverage = other.NewMaxLeverage
    } else {
      this.NewMaxLeverage = null
    }
    if (other.NewMaxLeverageReset != null) {
      this.NewMaxLeverageReset = other.NewMaxLeverageReset
    } else {
      this.NewMaxLeverageReset = null
    }
    if (other.NewReportsSubscriptionType != null) {
      this.NewReportsSubscriptionType = ReportsSubscriptionType.fromObject(other.NewReportsSubscriptionType)
    } else {
      this.NewReportsSubscriptionType = null
    }
    if (other.NewMaxOrders != null) {
      this.NewMaxOrders = UInt64.fromNumber(other.NewMaxOrders)
    } else {
      this.NewMaxOrders = null
    }
    if (other.NewExternalClientId != null) {
      this.NewExternalClientId = other.NewExternalClientId
    } else {
      this.NewExternalClientId = null
    }
    if (other.NewExternalClientIdReset != null) {
      this.NewExternalClientIdReset = other.NewExternalClientIdReset
    } else {
      this.NewExternalClientIdReset = null
    }
    if (other.NewExternalAccountId != null) {
      this.NewExternalAccountId = other.NewExternalAccountId
    } else {
      this.NewExternalAccountId = null
    }
    if (other.NewExternalAccountIdReset != null) {
      this.NewExternalAccountIdReset = other.NewExternalAccountIdReset
    } else {
      this.NewExternalAccountIdReset = null
    }
    if (other.NewLocalLanguageName != null) {
      this.NewLocalLanguageName = other.NewLocalLanguageName
    } else {
      this.NewLocalLanguageName = null
    }
    if (other.NewFeaturesToggleForce != null) {
      this.NewFeaturesToggleForce = FeaturesToggleForce.fromObject(other.NewFeaturesToggleForce)
    } else {
      this.NewFeaturesToggleForce = null
    }
    if (other.SocialTradingMasterUpdate != null) {
      this.SocialTradingMasterUpdate = SocialTradingMasterUpdate.fromObject(other.SocialTradingMasterUpdate)
    } else {
      this.SocialTradingMasterUpdate = null
    }
    if (other.SocialTradingInvestorUpdate != null) {
      this.SocialTradingInvestorUpdate = SocialTradingInvestorUpdate.fromObject(other.SocialTradingInvestorUpdate)
    } else {
      this.SocialTradingInvestorUpdate = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountModifyRequest}
   * @returns {!AccountModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountModifyRequest}
   * @param {!AccountModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountModifyRequest)) {
      throw new TypeError('Instance of AccountModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      NewAccountGroupId: ((this.NewAccountGroupId != null) ? this.NewAccountGroupId.toString() : null),
      NewAccountGroupName: ((this.NewAccountGroupName != null) ? this.NewAccountGroupName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewCountry: ((this.NewCountry != null) ? this.NewCountry : null),
      NewCity: ((this.NewCity != null) ? this.NewCity : null),
      NewState: ((this.NewState != null) ? this.NewState : null),
      NewZipCode: ((this.NewZipCode != null) ? this.NewZipCode : null),
      NewAddress: ((this.NewAddress != null) ? this.NewAddress : null),
      NewPhone: ((this.NewPhone != null) ? this.NewPhone : null),
      NewEmail: ((this.NewEmail != null) ? this.NewEmail : null),
      NewIdentifier: ((this.NewIdentifier != null) ? this.NewIdentifier : null),
      NewStatus: ((this.NewStatus != null) ? this.NewStatus : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewIsEnabledReset: ((this.NewIsEnabledReset != null) ? this.NewIsEnabledReset : null),
      Deprecated_NewIsOTPEnabled: ((this.Deprecated_NewIsOTPEnabled != null) ? this.Deprecated_NewIsOTPEnabled : null),
      Deprecated_NewIsOTPEnabledReset: ((this.Deprecated_NewIsOTPEnabledReset != null) ? this.Deprecated_NewIsOTPEnabledReset : null),
      NewIsTradeEnabled: ((this.NewIsTradeEnabled != null) ? this.NewIsTradeEnabled : null),
      NewIsTradeEnabledReset: ((this.NewIsTradeEnabledReset != null) ? this.NewIsTradeEnabledReset : null),
      NewPassword: ((this.NewPassword != null) ? this.NewPassword : null),
      Deprecated_NewInvestorPassword: ((this.Deprecated_NewInvestorPassword != null) ? this.Deprecated_NewInvestorPassword : null),
      NewPhonePassword: ((this.NewPhonePassword != null) ? this.NewPhonePassword : null),
      NewAccountingType: ((this.NewAccountingType != null) ? this.NewAccountingType : null),
      NewAgentAccount: ((this.NewAgentAccount != null) ? this.NewAgentAccount.toNumber() : null),
      NewAgentAccountReset: ((this.NewAgentAccountReset != null) ? this.NewAgentAccountReset : null),
      NewMaxPendingOrders: ((this.NewMaxPendingOrders != null) ? this.NewMaxPendingOrders.toNumber() : null),
      NewBalanceCurrencyId: ((this.NewBalanceCurrencyId != null) ? this.NewBalanceCurrencyId.toString() : null),
      NewBalanceCurrencyName: ((this.NewBalanceCurrencyName != null) ? this.NewBalanceCurrencyName : null),
      NewLeverage: ((this.NewLeverage != null) ? this.NewLeverage : null),
      NewLastLogin: ((this.NewLastLogin != null) ? (this.NewLastLogin.getTime() * 1000000) : null),
      NewPrevDayOpenProfit: ((this.NewPrevDayOpenProfit != null) ? this.NewPrevDayOpenProfit : null),
      NewMaxLeverage: ((this.NewMaxLeverage != null) ? this.NewMaxLeverage : null),
      NewMaxLeverageReset: ((this.NewMaxLeverageReset != null) ? this.NewMaxLeverageReset : null),
      NewReportsSubscriptionType: ((this.NewReportsSubscriptionType != null) ? this.NewReportsSubscriptionType : null),
      NewMaxOrders: ((this.NewMaxOrders != null) ? this.NewMaxOrders.toNumber() : null),
      NewExternalClientId: ((this.NewExternalClientId != null) ? this.NewExternalClientId : null),
      NewExternalClientIdReset: ((this.NewExternalClientIdReset != null) ? this.NewExternalClientIdReset : null),
      NewExternalAccountId: ((this.NewExternalAccountId != null) ? this.NewExternalAccountId : null),
      NewExternalAccountIdReset: ((this.NewExternalAccountIdReset != null) ? this.NewExternalAccountIdReset : null),
      NewLocalLanguageName: ((this.NewLocalLanguageName != null) ? this.NewLocalLanguageName : null),
      NewFeaturesToggleForce: ((this.NewFeaturesToggleForce != null) ? this.NewFeaturesToggleForce : null),
      SocialTradingMasterUpdate: ((this.SocialTradingMasterUpdate != null) ? this.SocialTradingMasterUpdate : null),
      SocialTradingInvestorUpdate: ((this.SocialTradingInvestorUpdate != null) ? this.SocialTradingInvestorUpdate : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountModifyRequest} other Object value
   * @returns {!AccountModifyRequest} Created struct
   */
  static fromObject (other) {
    return new AccountModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6194
  }
}

export { AccountModifyRequest };

/**
 * Fast Binary Encoding AccountModifyRequest field model
 */
class FieldModelAccountModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewAccountGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.NewAccountGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAccountGroupId.fbeOffset + this.NewAccountGroupId.fbeSize), buffer, this.NewAccountGroupId.fbeOffset + this.NewAccountGroupId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAccountGroupName.fbeOffset + this.NewAccountGroupName.fbeSize), buffer, this.NewAccountGroupName.fbeOffset + this.NewAccountGroupName.fbeSize)
    this.NewCountry = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewCity = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCountry.fbeOffset + this.NewCountry.fbeSize), buffer, this.NewCountry.fbeOffset + this.NewCountry.fbeSize)
    this.NewState = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCity.fbeOffset + this.NewCity.fbeSize), buffer, this.NewCity.fbeOffset + this.NewCity.fbeSize)
    this.NewZipCode = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewState.fbeOffset + this.NewState.fbeSize), buffer, this.NewState.fbeOffset + this.NewState.fbeSize)
    this.NewAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewZipCode.fbeOffset + this.NewZipCode.fbeSize), buffer, this.NewZipCode.fbeOffset + this.NewZipCode.fbeSize)
    this.NewPhone = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize), buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize)
    this.NewEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewPhone.fbeOffset + this.NewPhone.fbeSize), buffer, this.NewPhone.fbeOffset + this.NewPhone.fbeSize)
    this.NewIdentifier = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize), buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize)
    this.NewStatus = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewIdentifier.fbeOffset + this.NewIdentifier.fbeSize), buffer, this.NewIdentifier.fbeOffset + this.NewIdentifier.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize), buffer, this.NewStatus.fbeOffset + this.NewStatus.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewIsEnabledReset = new fbe.FieldModelBool(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.Deprecated_NewIsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsEnabledReset.fbeOffset + this.NewIsEnabledReset.fbeSize), buffer, this.NewIsEnabledReset.fbeOffset + this.NewIsEnabledReset.fbeSize)
    this.Deprecated_NewIsOTPEnabledReset = new fbe.FieldModelBool(buffer, this.Deprecated_NewIsOTPEnabled.fbeOffset + this.Deprecated_NewIsOTPEnabled.fbeSize)
    this.NewIsTradeEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Deprecated_NewIsOTPEnabledReset.fbeOffset + this.Deprecated_NewIsOTPEnabledReset.fbeSize), buffer, this.Deprecated_NewIsOTPEnabledReset.fbeOffset + this.Deprecated_NewIsOTPEnabledReset.fbeSize)
    this.NewIsTradeEnabledReset = new fbe.FieldModelBool(buffer, this.NewIsTradeEnabled.fbeOffset + this.NewIsTradeEnabled.fbeSize)
    this.NewPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewIsTradeEnabledReset.fbeOffset + this.NewIsTradeEnabledReset.fbeSize), buffer, this.NewIsTradeEnabledReset.fbeOffset + this.NewIsTradeEnabledReset.fbeSize)
    this.Deprecated_NewInvestorPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewPassword.fbeOffset + this.NewPassword.fbeSize), buffer, this.NewPassword.fbeOffset + this.NewPassword.fbeSize)
    this.NewPhonePassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Deprecated_NewInvestorPassword.fbeOffset + this.Deprecated_NewInvestorPassword.fbeSize), buffer, this.Deprecated_NewInvestorPassword.fbeOffset + this.Deprecated_NewInvestorPassword.fbeSize)
    this.NewAccountingType = new fbe.FieldModelOptional(new FieldModelAccountingType(buffer, this.NewPhonePassword.fbeOffset + this.NewPhonePassword.fbeSize), buffer, this.NewPhonePassword.fbeOffset + this.NewPhonePassword.fbeSize)
    this.NewAgentAccount = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewAccountingType.fbeOffset + this.NewAccountingType.fbeSize), buffer, this.NewAccountingType.fbeOffset + this.NewAccountingType.fbeSize)
    this.NewAgentAccountReset = new fbe.FieldModelBool(buffer, this.NewAgentAccount.fbeOffset + this.NewAgentAccount.fbeSize)
    this.NewMaxPendingOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewAgentAccountReset.fbeOffset + this.NewAgentAccountReset.fbeSize), buffer, this.NewAgentAccountReset.fbeOffset + this.NewAgentAccountReset.fbeSize)
    this.NewBalanceCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewMaxPendingOrders.fbeOffset + this.NewMaxPendingOrders.fbeSize), buffer, this.NewMaxPendingOrders.fbeOffset + this.NewMaxPendingOrders.fbeSize)
    this.NewBalanceCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewBalanceCurrencyId.fbeOffset + this.NewBalanceCurrencyId.fbeSize), buffer, this.NewBalanceCurrencyId.fbeOffset + this.NewBalanceCurrencyId.fbeSize)
    this.NewLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewBalanceCurrencyName.fbeOffset + this.NewBalanceCurrencyName.fbeSize), buffer, this.NewBalanceCurrencyName.fbeOffset + this.NewBalanceCurrencyName.fbeSize)
    this.NewLastLogin = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.NewLeverage.fbeOffset + this.NewLeverage.fbeSize), buffer, this.NewLeverage.fbeOffset + this.NewLeverage.fbeSize)
    this.NewPrevDayOpenProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewLastLogin.fbeOffset + this.NewLastLogin.fbeSize), buffer, this.NewLastLogin.fbeOffset + this.NewLastLogin.fbeSize)
    this.NewMaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewPrevDayOpenProfit.fbeOffset + this.NewPrevDayOpenProfit.fbeSize), buffer, this.NewPrevDayOpenProfit.fbeOffset + this.NewPrevDayOpenProfit.fbeSize)
    this.NewMaxLeverageReset = new fbe.FieldModelBool(buffer, this.NewMaxLeverage.fbeOffset + this.NewMaxLeverage.fbeSize)
    this.NewReportsSubscriptionType = new fbe.FieldModelOptional(new FieldModelReportsSubscriptionType(buffer, this.NewMaxLeverageReset.fbeOffset + this.NewMaxLeverageReset.fbeSize), buffer, this.NewMaxLeverageReset.fbeOffset + this.NewMaxLeverageReset.fbeSize)
    this.NewMaxOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewReportsSubscriptionType.fbeOffset + this.NewReportsSubscriptionType.fbeSize), buffer, this.NewReportsSubscriptionType.fbeOffset + this.NewReportsSubscriptionType.fbeSize)
    this.NewExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewMaxOrders.fbeOffset + this.NewMaxOrders.fbeSize), buffer, this.NewMaxOrders.fbeOffset + this.NewMaxOrders.fbeSize)
    this.NewExternalClientIdReset = new fbe.FieldModelBool(buffer, this.NewExternalClientId.fbeOffset + this.NewExternalClientId.fbeSize)
    this.NewExternalAccountId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExternalClientIdReset.fbeOffset + this.NewExternalClientIdReset.fbeSize), buffer, this.NewExternalClientIdReset.fbeOffset + this.NewExternalClientIdReset.fbeSize)
    this.NewExternalAccountIdReset = new fbe.FieldModelBool(buffer, this.NewExternalAccountId.fbeOffset + this.NewExternalAccountId.fbeSize)
    this.NewLocalLanguageName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExternalAccountIdReset.fbeOffset + this.NewExternalAccountIdReset.fbeSize), buffer, this.NewExternalAccountIdReset.fbeOffset + this.NewExternalAccountIdReset.fbeSize)
    this.NewFeaturesToggleForce = new fbe.FieldModelOptional(new FieldModelFeaturesToggleForce(buffer, this.NewLocalLanguageName.fbeOffset + this.NewLocalLanguageName.fbeSize), buffer, this.NewLocalLanguageName.fbeOffset + this.NewLocalLanguageName.fbeSize)
    this.SocialTradingMasterUpdate = new fbe.FieldModelOptional(new FieldModelSocialTradingMasterUpdate(buffer, this.NewFeaturesToggleForce.fbeOffset + this.NewFeaturesToggleForce.fbeSize), buffer, this.NewFeaturesToggleForce.fbeOffset + this.NewFeaturesToggleForce.fbeSize)
    this.SocialTradingInvestorUpdate = new fbe.FieldModelOptional(new FieldModelSocialTradingInvestorUpdate(buffer, this.SocialTradingMasterUpdate.fbeOffset + this.SocialTradingMasterUpdate.fbeSize), buffer, this.SocialTradingMasterUpdate.fbeOffset + this.SocialTradingMasterUpdate.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Login.fbeSize + this.NewAccountGroupId.fbeSize + this.NewAccountGroupName.fbeSize + this.NewName.fbeSize + this.NewCountry.fbeSize + this.NewCity.fbeSize + this.NewState.fbeSize + this.NewZipCode.fbeSize + this.NewAddress.fbeSize + this.NewPhone.fbeSize + this.NewEmail.fbeSize + this.NewIdentifier.fbeSize + this.NewStatus.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewIsEnabled.fbeSize + this.NewIsEnabledReset.fbeSize + this.Deprecated_NewIsOTPEnabled.fbeSize + this.Deprecated_NewIsOTPEnabledReset.fbeSize + this.NewIsTradeEnabled.fbeSize + this.NewIsTradeEnabledReset.fbeSize + this.NewPassword.fbeSize + this.Deprecated_NewInvestorPassword.fbeSize + this.NewPhonePassword.fbeSize + this.NewAccountingType.fbeSize + this.NewAgentAccount.fbeSize + this.NewAgentAccountReset.fbeSize + this.NewMaxPendingOrders.fbeSize + this.NewBalanceCurrencyId.fbeSize + this.NewBalanceCurrencyName.fbeSize + this.NewLeverage.fbeSize + this.NewLastLogin.fbeSize + this.NewPrevDayOpenProfit.fbeSize + this.NewMaxLeverage.fbeSize + this.NewMaxLeverageReset.fbeSize + this.NewReportsSubscriptionType.fbeSize + this.NewMaxOrders.fbeSize + this.NewExternalClientId.fbeSize + this.NewExternalClientIdReset.fbeSize + this.NewExternalAccountId.fbeSize + this.NewExternalAccountIdReset.fbeSize + this.NewLocalLanguageName.fbeSize + this.NewFeaturesToggleForce.fbeSize + this.SocialTradingMasterUpdate.fbeSize + this.SocialTradingInvestorUpdate.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Login.fbeExtra + this.NewAccountGroupId.fbeExtra + this.NewAccountGroupName.fbeExtra + this.NewName.fbeExtra + this.NewCountry.fbeExtra + this.NewCity.fbeExtra + this.NewState.fbeExtra + this.NewZipCode.fbeExtra + this.NewAddress.fbeExtra + this.NewPhone.fbeExtra + this.NewEmail.fbeExtra + this.NewIdentifier.fbeExtra + this.NewStatus.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewIsEnabledReset.fbeExtra + this.Deprecated_NewIsOTPEnabled.fbeExtra + this.Deprecated_NewIsOTPEnabledReset.fbeExtra + this.NewIsTradeEnabled.fbeExtra + this.NewIsTradeEnabledReset.fbeExtra + this.NewPassword.fbeExtra + this.Deprecated_NewInvestorPassword.fbeExtra + this.NewPhonePassword.fbeExtra + this.NewAccountingType.fbeExtra + this.NewAgentAccount.fbeExtra + this.NewAgentAccountReset.fbeExtra + this.NewMaxPendingOrders.fbeExtra + this.NewBalanceCurrencyId.fbeExtra + this.NewBalanceCurrencyName.fbeExtra + this.NewLeverage.fbeExtra + this.NewLastLogin.fbeExtra + this.NewPrevDayOpenProfit.fbeExtra + this.NewMaxLeverage.fbeExtra + this.NewMaxLeverageReset.fbeExtra + this.NewReportsSubscriptionType.fbeExtra + this.NewMaxOrders.fbeExtra + this.NewExternalClientId.fbeExtra + this.NewExternalClientIdReset.fbeExtra + this.NewExternalAccountId.fbeExtra + this.NewExternalAccountIdReset.fbeExtra + this.NewLocalLanguageName.fbeExtra + this.NewFeaturesToggleForce.fbeExtra + this.SocialTradingMasterUpdate.fbeExtra + this.SocialTradingInvestorUpdate.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6194
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupId.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewCountry.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCountry.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCountry.fbeSize

    if ((fbeCurrentSize + this.NewCity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCity.fbeSize

    if ((fbeCurrentSize + this.NewState.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewState.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewState.fbeSize

    if ((fbeCurrentSize + this.NewZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewZipCode.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewPhone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPhone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhone.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIdentifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIdentifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIdentifier.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabledReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabledReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabledReset.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewIsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_NewIsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewIsOTPEnabledReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_NewIsOTPEnabledReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewIsOTPEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabledReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradeEnabledReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewInvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated_NewInvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewInvestorPassword.fbeSize

    if ((fbeCurrentSize + this.NewPhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhonePassword.fbeSize

    if ((fbeCurrentSize + this.NewAccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountingType.fbeSize

    if ((fbeCurrentSize + this.NewAgentAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAgentAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAgentAccount.fbeSize

    if ((fbeCurrentSize + this.NewAgentAccountReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAgentAccountReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAgentAccountReset.fbeSize

    if ((fbeCurrentSize + this.NewMaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.NewBalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewBalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewBalanceCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewBalanceCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverage.fbeSize

    if ((fbeCurrentSize + this.NewLastLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLastLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLastLogin.fbeSize

    if ((fbeCurrentSize + this.NewPrevDayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPrevDayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverageReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxLeverageReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.NewReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.NewMaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxOrders.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientId.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalClientIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientIdReset.fbeSize

    if ((fbeCurrentSize + this.NewExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.NewExternalAccountIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExternalAccountIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalAccountIdReset.fbeSize

    if ((fbeCurrentSize + this.NewLocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.NewFeaturesToggleForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewFeaturesToggleForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterUpdate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMasterUpdate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterUpdate.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorUpdate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingInvestorUpdate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorUpdate.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountModifyRequest}
   * @param {!AccountModifyRequest} fbeValue Default value, defaults is new AccountModifyRequest()
   * @returns {!AccountModifyRequest} AccountModifyRequest value
   */
  get (fbeValue = new AccountModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountModifyRequest}
   * @param {!AccountModifyRequest} fbeValue AccountModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountGroupId = this.NewAccountGroupId.get()
    } else {
      fbeValue.NewAccountGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupId.fbeSize

    if ((fbeCurrentSize + this.NewAccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountGroupName = this.NewAccountGroupName.get()
    } else {
      fbeValue.NewAccountGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewCountry.fbeSize) <= fbeStructSize) {
      fbeValue.NewCountry = this.NewCountry.get()
    } else {
      fbeValue.NewCountry = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCountry.fbeSize

    if ((fbeCurrentSize + this.NewCity.fbeSize) <= fbeStructSize) {
      fbeValue.NewCity = this.NewCity.get()
    } else {
      fbeValue.NewCity = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCity.fbeSize

    if ((fbeCurrentSize + this.NewState.fbeSize) <= fbeStructSize) {
      fbeValue.NewState = this.NewState.get()
    } else {
      fbeValue.NewState = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewState.fbeSize

    if ((fbeCurrentSize + this.NewZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.NewZipCode = this.NewZipCode.get()
    } else {
      fbeValue.NewZipCode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewZipCode.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) <= fbeStructSize) {
      fbeValue.NewAddress = this.NewAddress.get()
    } else {
      fbeValue.NewAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewPhone.fbeSize) <= fbeStructSize) {
      fbeValue.NewPhone = this.NewPhone.get()
    } else {
      fbeValue.NewPhone = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhone.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) <= fbeStructSize) {
      fbeValue.NewEmail = this.NewEmail.get()
    } else {
      fbeValue.NewEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIdentifier.fbeSize) <= fbeStructSize) {
      fbeValue.NewIdentifier = this.NewIdentifier.get()
    } else {
      fbeValue.NewIdentifier = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIdentifier.fbeSize

    if ((fbeCurrentSize + this.NewStatus.fbeSize) <= fbeStructSize) {
      fbeValue.NewStatus = this.NewStatus.get()
    } else {
      fbeValue.NewStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStatus.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabledReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabledReset = this.NewIsEnabledReset.get()
    } else {
      fbeValue.NewIsEnabledReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabledReset.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewIsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_NewIsOTPEnabled = this.Deprecated_NewIsOTPEnabled.get()
    } else {
      fbeValue.Deprecated_NewIsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewIsOTPEnabledReset.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_NewIsOTPEnabledReset = this.Deprecated_NewIsOTPEnabledReset.get()
    } else {
      fbeValue.Deprecated_NewIsOTPEnabledReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewIsOTPEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradeEnabled = this.NewIsTradeEnabled.get()
    } else {
      fbeValue.NewIsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabledReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradeEnabledReset = this.NewIsTradeEnabledReset.get()
    } else {
      fbeValue.NewIsTradeEnabledReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) <= fbeStructSize) {
      fbeValue.NewPassword = this.NewPassword.get()
    } else {
      fbeValue.NewPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize

    if ((fbeCurrentSize + this.Deprecated_NewInvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated_NewInvestorPassword = this.Deprecated_NewInvestorPassword.get()
    } else {
      fbeValue.Deprecated_NewInvestorPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated_NewInvestorPassword.fbeSize

    if ((fbeCurrentSize + this.NewPhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.NewPhonePassword = this.NewPhonePassword.get()
    } else {
      fbeValue.NewPhonePassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPhonePassword.fbeSize

    if ((fbeCurrentSize + this.NewAccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountingType = this.NewAccountingType.get()
    } else {
      fbeValue.NewAccountingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountingType.fbeSize

    if ((fbeCurrentSize + this.NewAgentAccount.fbeSize) <= fbeStructSize) {
      fbeValue.NewAgentAccount = this.NewAgentAccount.get()
    } else {
      fbeValue.NewAgentAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAgentAccount.fbeSize

    if ((fbeCurrentSize + this.NewAgentAccountReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewAgentAccountReset = this.NewAgentAccountReset.get()
    } else {
      fbeValue.NewAgentAccountReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAgentAccountReset.fbeSize

    if ((fbeCurrentSize + this.NewMaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxPendingOrders = this.NewMaxPendingOrders.get()
    } else {
      fbeValue.NewMaxPendingOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.NewBalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.NewBalanceCurrencyId = this.NewBalanceCurrencyId.get()
    } else {
      fbeValue.NewBalanceCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewBalanceCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.NewBalanceCurrencyName = this.NewBalanceCurrencyName.get()
    } else {
      fbeValue.NewBalanceCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.NewLeverage = this.NewLeverage.get()
    } else {
      fbeValue.NewLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverage.fbeSize

    if ((fbeCurrentSize + this.NewLastLogin.fbeSize) <= fbeStructSize) {
      fbeValue.NewLastLogin = this.NewLastLogin.get()
    } else {
      fbeValue.NewLastLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLastLogin.fbeSize

    if ((fbeCurrentSize + this.NewPrevDayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.NewPrevDayOpenProfit = this.NewPrevDayOpenProfit.get()
    } else {
      fbeValue.NewPrevDayOpenProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxLeverage = this.NewMaxLeverage.get()
    } else {
      fbeValue.NewMaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverageReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxLeverageReset = this.NewMaxLeverageReset.get()
    } else {
      fbeValue.NewMaxLeverageReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.NewReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.NewReportsSubscriptionType = this.NewReportsSubscriptionType.get()
    } else {
      fbeValue.NewReportsSubscriptionType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.NewMaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxOrders = this.NewMaxOrders.get()
    } else {
      fbeValue.NewMaxOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxOrders.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalClientId = this.NewExternalClientId.get()
    } else {
      fbeValue.NewExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientId.fbeSize

    if ((fbeCurrentSize + this.NewExternalClientIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalClientIdReset = this.NewExternalClientIdReset.get()
    } else {
      fbeValue.NewExternalClientIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalClientIdReset.fbeSize

    if ((fbeCurrentSize + this.NewExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalAccountId = this.NewExternalAccountId.get()
    } else {
      fbeValue.NewExternalAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.NewExternalAccountIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewExternalAccountIdReset = this.NewExternalAccountIdReset.get()
    } else {
      fbeValue.NewExternalAccountIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExternalAccountIdReset.fbeSize

    if ((fbeCurrentSize + this.NewLocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.NewLocalLanguageName = this.NewLocalLanguageName.get()
    } else {
      fbeValue.NewLocalLanguageName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.NewFeaturesToggleForce.fbeSize) <= fbeStructSize) {
      fbeValue.NewFeaturesToggleForce = this.NewFeaturesToggleForce.get()
    } else {
      fbeValue.NewFeaturesToggleForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeaturesToggleForce.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterUpdate.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMasterUpdate = this.SocialTradingMasterUpdate.get()
    } else {
      fbeValue.SocialTradingMasterUpdate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterUpdate.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorUpdate.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingInvestorUpdate = this.SocialTradingInvestorUpdate.get()
    } else {
      fbeValue.SocialTradingInvestorUpdate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorUpdate.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountModifyRequest}
   * @param {!AccountModifyRequest} fbeValue AccountModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountModifyRequest}
   * @param {AccountModifyRequest} fbeValue AccountModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Login.set(fbeValue.Login)
    this.NewAccountGroupId.set(fbeValue.NewAccountGroupId)
    this.NewAccountGroupName.set(fbeValue.NewAccountGroupName)
    this.NewName.set(fbeValue.NewName)
    this.NewCountry.set(fbeValue.NewCountry)
    this.NewCity.set(fbeValue.NewCity)
    this.NewState.set(fbeValue.NewState)
    this.NewZipCode.set(fbeValue.NewZipCode)
    this.NewAddress.set(fbeValue.NewAddress)
    this.NewPhone.set(fbeValue.NewPhone)
    this.NewEmail.set(fbeValue.NewEmail)
    this.NewIdentifier.set(fbeValue.NewIdentifier)
    this.NewStatus.set(fbeValue.NewStatus)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewIsEnabledReset.set(fbeValue.NewIsEnabledReset)
    this.Deprecated_NewIsOTPEnabled.set(fbeValue.Deprecated_NewIsOTPEnabled)
    this.Deprecated_NewIsOTPEnabledReset.set(fbeValue.Deprecated_NewIsOTPEnabledReset)
    this.NewIsTradeEnabled.set(fbeValue.NewIsTradeEnabled)
    this.NewIsTradeEnabledReset.set(fbeValue.NewIsTradeEnabledReset)
    this.NewPassword.set(fbeValue.NewPassword)
    this.Deprecated_NewInvestorPassword.set(fbeValue.Deprecated_NewInvestorPassword)
    this.NewPhonePassword.set(fbeValue.NewPhonePassword)
    this.NewAccountingType.set(fbeValue.NewAccountingType)
    this.NewAgentAccount.set(fbeValue.NewAgentAccount)
    this.NewAgentAccountReset.set(fbeValue.NewAgentAccountReset)
    this.NewMaxPendingOrders.set(fbeValue.NewMaxPendingOrders)
    this.NewBalanceCurrencyId.set(fbeValue.NewBalanceCurrencyId)
    this.NewBalanceCurrencyName.set(fbeValue.NewBalanceCurrencyName)
    this.NewLeverage.set(fbeValue.NewLeverage)
    this.NewLastLogin.set(fbeValue.NewLastLogin)
    this.NewPrevDayOpenProfit.set(fbeValue.NewPrevDayOpenProfit)
    this.NewMaxLeverage.set(fbeValue.NewMaxLeverage)
    this.NewMaxLeverageReset.set(fbeValue.NewMaxLeverageReset)
    this.NewReportsSubscriptionType.set(fbeValue.NewReportsSubscriptionType)
    this.NewMaxOrders.set(fbeValue.NewMaxOrders)
    this.NewExternalClientId.set(fbeValue.NewExternalClientId)
    this.NewExternalClientIdReset.set(fbeValue.NewExternalClientIdReset)
    this.NewExternalAccountId.set(fbeValue.NewExternalAccountId)
    this.NewExternalAccountIdReset.set(fbeValue.NewExternalAccountIdReset)
    this.NewLocalLanguageName.set(fbeValue.NewLocalLanguageName)
    this.NewFeaturesToggleForce.set(fbeValue.NewFeaturesToggleForce)
    this.SocialTradingMasterUpdate.set(fbeValue.SocialTradingMasterUpdate)
    this.SocialTradingInvestorUpdate.set(fbeValue.SocialTradingInvestorUpdate)
  }
}

export { FieldModelAccountModifyRequest };

/**
 * Fast Binary Encoding AccountModifyRequest model
 */
class AccountModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountModifyRequest(this.buffer, 4)
  }

  /**
   * Get the AccountModifyRequest model
   * @this {!AccountModifyRequestModel}
   * @returns {!FieldModelAccountModifyRequest} model AccountModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountModifyRequestModel}
   * @param {!AccountModifyRequest} value AccountModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountModifyRequestModel}
   * @param {!AccountModifyRequest} value AccountModifyRequest value, defaults is new AccountModifyRequest()
   * @return {!object} Deserialized AccountModifyRequest value and its size
   */
  deserialize (value = new AccountModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountModifyRequestModel}
   * @param {!number} prev Previous AccountModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountModifyRequestModel };
AccountModifyRequest.__has_response = true;
AccountModifyRequest.__response_class = AccountModifyResponse;
