import React, { useContext, useMemo } from 'react';
import { AccessContext, AdminPanelAccessMap, TabAccessLevel } from '@utils/accessMap';
import { Select } from 'antd';
import styles from './MenuShortcut.module.scss';
import { keyMap, t } from '@localization';
import { useHistory } from 'react-router-dom';
import { adminPages } from '../../routes/utils';
import { DefaultOptionType } from 'antd/lib/select';
import { kebabilize } from '@utils/string';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

type MenuItemObj = {
    title: string;
    route: string;
};

const getMenuItems = (accessMap: AdminPanelAccessMap): DefaultOptionType[] => {
    const items: MenuItemObj[] = [];
    Object.keys(accessMap).forEach((page) => {
        if (accessMap[page]?.accessible) {
            Object.keys(accessMap[page].tabs).forEach((tab) => {
                const tabInfo = accessMap[page].tabs[tab] as TabAccessLevel;
                if (tabInfo?.accessible)
                    items.push({
                        title: t(keyMap.menu[tab]) || kebabilize(tab),
                        route: adminPages[page].hasMultipleTabs
                            ? `${adminPages[page].path}/${kebabilize(tab)}`
                            : adminPages[page].path,
                    });
            });
        }
    });

    return items.map((item) => ({ value: item.route, label: item.title })).sort((a, b) => baseSorter(a.label, b.label));
};

export const MenuShortcut = () => {
    const accessMap = useContext<AdminPanelAccessMap | null>(AccessContext);
    const menuItems = useMemo(() => getMenuItems(accessMap!), [accessMap]);
    const history = useHistory();

    const handleSelect = (route) => {
        history.push(route);
    };

    return (
        <div className={styles.wrapper}>
            <Select
                showSearch
                onChange={handleSelect}
                optionFilterProp="label"
                placeholder={t(keyMap.pages.searchPlaceholder)}
                value={null}
                className={styles.select}
                options={menuItems}
            />
        </div>
    );
};
