import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { Gateway } from '@models/Gateways';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { objectParametersToMap, parameretsMapToObject } from '@models/DataFeeds';
import { GatewayModule } from '@api/fbe/core/GatewayModule';
import { GatewayModulesGetRequest } from '@api/fbe/execution/GatewayModulesGetRequest';
import { GatewaysGetRequest } from '@api/fbe/manager/GatewaysGetRequest';
import { GatewayCreateRequest } from '@api/fbe/manager/GatewayCreateRequest';
import { GatewayDeleteRequest } from '@api/fbe/manager/GatewayDeleteRequest';
import { GatewayModifyRequest } from '@api/fbe/manager/GatewayModifyRequest';

export class GatewaysStore {
    errorTracker = new ErrorsTracker({ title: 'GatewaysStore' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Gateway[] = [];

    modules: GatewayModule[] = [];

    isLoading: boolean = false;

    setData(value: Gateway[]) {
        this.data = value;
    }

    setModules(value: GatewayModule[]) {
        this.modules = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async getModules() {
        const request = new GatewayModulesGetRequest();
        const modules = await SocketClient.instance.request(request, this.errorTracker);
        this.setModules(modules.GatewayModule);
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new GatewaysGetRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setIsLoading(false);
        this.setData(
            data.Gateways.map((record) => ({
                id: record.id.toString(),
                ModuleName: record.ModuleName,
                Name: record.Name,
                Host: record.Host ?? null,
                Port: record.Port ?? null,
                IsEnabled: record.IsEnabled,
                GatewayStatus: +record.GatewayStatus.valueOf(),
                SymbolMapId: record.SymbolMapId?.toString() || null,
                Parameters: parameretsMapToObject(record.Parameters),
                IsRemote: record.IsRemote,
            })),
        );
    }

    @ErrorsTracker.wrapApi()
    async create(record: Gateway) {
        const request = new GatewayCreateRequest();
        if (record.ModuleName) request.ModuleName = record.ModuleName;
        if (record.Name) request.Name = record.Name;
        request.Host = record.Host;
        request.Port = record.Port;
        request.IsEnabled = record.IsEnabled;
        request.IsRemote = record.IsRemote;
        if (record.SymbolMapId) request.SymbolMapId = UUID.fromString(record.SymbolMapId);
        request.Parameters = objectParametersToMap(record.Parameters || {});
        await SocketClient.instance.request(request);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new GatewayDeleteRequest();
        request.GatewayId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: Gateway, dirtyFields: FieldNamesMarkedBoolean<FieldValues>) {
        if (record.id) {
            const request = new GatewayModifyRequest();
            request.GatewayId = UUID.fromString(record.id);
            if (dirtyFields.ModuleName) request.NewModuleName = record.ModuleName;
            if (dirtyFields.Name) request.NewName = record.Name;
            if (dirtyFields.Host) request.NewHost = record.Host;
            if (record.Host === null) request.NewHostReset = true;
            if (dirtyFields.Port) request.NewPort = record.Port;
            if (record.Port === null) request.NewPortReset = true;
            if (dirtyFields.IsEnabled) request.NewIsEnabled = record.IsEnabled;
            if (dirtyFields.IsRemote) request.NewIsRemote = record.IsRemote;
            if (dirtyFields.SymbolMapId && record.SymbolMapId)
                request.NewSymbolMapId = UUID.fromString(record.SymbolMapId);
            if (!record.SymbolMapId) request.NewSymbolMapIdReset = true;
            request.ParametersToModify = objectParametersToMap(record.Parameters || {});
            await SocketClient.instance.request(request, this.errorTracker);
            await this.get();
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.modules = [];
    }
}

export const gatewaysStore = new GatewaysStore();
