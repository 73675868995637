import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import {
    convertOrdersClosedSummary,
    convertTradeRecordToTradeHistoryRecord,
    TradeHistory,
    TradeHistoryRequestParams,
    TransactionTypesEnum,
} from '@models/riskManagement/TradeHistory';
import { TradeHistoryRecord as TradeHistoryRecordApi } from '@api/fbe/core/TradeHistoryRecord';
import { TradeTransactionType } from '@api/fbe/core/TradeTransactionType';
import { UInt64 } from '@api/fbe/int64';
import { TradeHistoryFilterRequest } from '@api/fbe/manager/TradeHistoryFilterRequest';
import { TradingRouteRuleDeleteRequest } from '@api/fbe/manager/TradingRouteRuleDeleteRequest';
import { Message, MessageType } from '@components/Message/Message';
import { OrdersClosedSummariesModel } from '@models/riskManagement/OrdersClosedSummary';
import { OrdersClosedSummariesGetRequest } from '@api/fbe/manager/OrdersClosedSummariesGetRequest';

const DEFAULT_PAGE_SIZE_TRADE_HISTORY = 1000;

const getTransactionTypesForFilterRequest = (
    value: TransactionTypesEnum[],
    symbolIds: string[],
): Set<TradeTransactionType> => {
    const result = new Set<TradeTransactionType>([]);
    if (value.includes(TransactionTypesEnum.trading)) {
        result.add(TradeTransactionType.ORDER_CLOSED);
        result.add(TradeTransactionType.ORDER_CLOSED_PARTIALLY);
        // result.add(TradeTransactionType.ORDER_FILLED);
        // result.add(TradeTransactionType.ORDER_FILLED_PARTIALLY);
        result.add(TradeTransactionType.ORDER_EXPIRED);
        result.add(TradeTransactionType.ORDER_REJECTED);
        result.add(TradeTransactionType.ORDER_CANCELED);
    }
    if (value.includes(TransactionTypesEnum.deposit)) {
        result.add(TradeTransactionType.DEPOSIT);
    }
    if (value.includes(TransactionTypesEnum.withdrawal)) {
        result.add(TradeTransactionType.DEPOSIT);
    }
    if (value.includes(TransactionTypesEnum.credit)) {
        result.add(TradeTransactionType.CREDIT);
    }
    if (value.includes(TransactionTypesEnum.transfer)) {
        result.add(TradeTransactionType.TRANSFER);
    }
    if (value.length === 0) {
        const moneyManagementTypes = [
            TradeTransactionType.DEPOSIT,
            TradeTransactionType.CREDIT,
            TradeTransactionType.TRANSFER,
        ];
        return new Set<TradeTransactionType>([
            TradeTransactionType.ORDER_CLOSED,
            TradeTransactionType.ORDER_CLOSED_PARTIALLY,
            TradeTransactionType.ORDER_EXPIRED,
            TradeTransactionType.ORDER_REJECTED,
            TradeTransactionType.ORDER_CANCELED,
            ...(symbolIds.length ? [] : moneyManagementTypes),
        ]);
    }
    return result;
};

const getFilteredTradeHistoryRecorsdApiByTransactionTypes = (
    history: TradeHistoryRecordApi[],
    types?: TransactionTypesEnum[],
): TradeHistoryRecordApi[] => {
    if (!types || !types.length) return history;

    if (types.includes(TransactionTypesEnum.deposit) && !types.includes(TransactionTypesEnum.withdrawal)) {
        return history.filter((item) => {
            if (!item.TransactionType.eq(TradeTransactionType.DEPOSIT)) return true;
            return typeof item.BalanceMovement !== 'number' ? false : item.BalanceMovement >= 0;
        });
    }
    if (types.includes(TransactionTypesEnum.withdrawal) && !types.includes(TransactionTypesEnum.deposit)) {
        return history.filter((item) => {
            if (!item.TransactionType.eq(TradeTransactionType.DEPOSIT)) return true;
            return typeof item.BalanceMovement !== 'number' ? false : item.BalanceMovement < 0;
        });
    }
    return history;
};

export class DealsStore {
    errorTracker = new ErrorsTracker({ title: 'Deals' });

    constructor() {
        makeAutoObservable(this);
    }

    data: TradeHistory[] = [];

    summary: OrdersClosedSummariesModel | null = null;

    private rawData: Array<TradeHistoryRecordApi> = [];

    isLoading = false;

    isLoadingSummary = false;

    requestParams: TradeHistoryRequestParams | null = null;

    setData(value: TradeHistory[]) {
        this.data = value;
    }

    private setRawData(value: TradeHistoryRecordApi[]) {
        this.rawData = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setRequestParams = (params: TradeHistoryRequestParams | null): void => {
        this.requestParams = params;
    };

    @ErrorsTracker.wrapApi()
    async get(limit: number | undefined = DEFAULT_PAGE_SIZE_TRADE_HISTORY) {
        try {
            this.setIsLoading(true);
            const request = new TradeHistoryFilterRequest();
            if (this.requestParams) {
                if (this.requestParams.SymbolIds.length) {
                    request.Symbols = new Set<UUID>(
                        this.requestParams.SymbolIds.map((item) => UUID.fromString(item ?? '')),
                    );
                }
                if (this.requestParams.OrderId !== null) {
                    request.OrderId = UInt64.fromNumber(this.requestParams.OrderId);
                }
                if (this.requestParams.Logins) {
                    const logins = this.requestParams.Logins.map((login) => UInt64.fromString(login.trim()));
                    request.AccountLogins = new Set(logins);
                }
                if (this.requestParams.AccountGroupIds.length) {
                    request.AccountGroup = new Set<UUID>(
                        this.requestParams.AccountGroupIds.map((item) => UUID.fromString(item ?? '')),
                    );
                }
                if (this.requestParams.RequestFrom) request.RequestFrom = this.requestParams.RequestFrom;
                if (this.requestParams.RequestTo) request.RequestTo = this.requestParams.RequestTo;

                request.TransactionTypes = getTransactionTypesForFilterRequest(
                    this.requestParams.TransactionTypes,
                    this.requestParams.SymbolIds,
                );
                request.LatestOrderState = true;
                if (this.requestParams.ExternalClientIds.length) {
                    request.ExternalClientIds = new Set(this.requestParams.ExternalClientIds);
                }
                request.PageSize = limit;
            }

            const result = await SocketClient.instance.request(request, this.errorTracker);
            const filteredRawData = getFilteredTradeHistoryRecorsdApiByTransactionTypes(
                result.TradeHistory,
                this.requestParams?.TransactionTypes,
            );
            this.setIsLoading(false);
            this.setData(
                filteredRawData.map((record, index) => ({
                    key: index,
                    ...convertTradeRecordToTradeHistoryRecord(record),
                })),
            );

            this.setRawData(filteredRawData);
            this.getOrdersClosedSummaries(request);
        } catch (e) {
            Message(MessageType.error, "Can't execute Trade History");
            this.setIsLoading(false);
        }
    }

    @ErrorsTracker.wrapApi()
    private async getOrdersClosedSummaries(requestParams: TradeHistoryFilterRequest) {
        try {
            this.isLoadingSummary = true;
            const request = new OrdersClosedSummariesGetRequest();
            request.Symbols = requestParams.Symbols;
            request.OrderId = requestParams.OrderId;
            request.AccountLogins = requestParams.AccountLogins;
            request.AccountGroup = requestParams.AccountGroup;
            request.RequestFrom = requestParams.RequestFrom;
            request.RequestTo = requestParams.RequestTo;
            request.ExternalClientIds = requestParams.ExternalClientIds;
            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.summary = {
                SummariesByCurrency: result.OrdersClosedSummaries.SummariesByCurrency.map((item, index) =>
                    convertOrdersClosedSummary(item, index),
                ),
                Total: convertOrdersClosedSummary(result.OrdersClosedSummaries.Total),
            };
            this.isLoadingSummary = false;
        } catch (e) {
            Message(MessageType.error, "Can't execute Orders Closed Summaries");
            this.isLoadingSummary = false;
        }
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new TradingRouteRuleDeleteRequest();
        request.id = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.rawData = [];
        this.isLoading = false;
        this.requestParams = null;
        this.summary = null;
        this.isLoadingSummary = false;
    }
}

export const dealsStore = new DealsStore();
