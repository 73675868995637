import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { authStore } from '@store/auth';

export const ProtectedRoute: FC<any> = observer(({ children, ...rest }) => (
    <Route
        {...rest}
        render={({ location }) =>
            authStore.auth ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: location },
                    }}
                />
            )
        }
    />
));

export const ProtectedRouteWrapper: FC<RouteComponentProps> = observer(({ location, children }) =>
    authStore.auth ? (
        <>{children}</>
    ) : (
        <Redirect
            to={{
                pathname: '/signin',
                state: { from: location },
            }}
        />
    ),
);
