
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * GatewayModule struct
 */
class GatewayModule {
  /**
   * Initialize struct
   * @param {!string=} moduleName
   * @param {!string=} moduleFileName
   * @param {!Map=} defaultParameters
   * @constructor
   */
  constructor (moduleName = '', moduleFileName = '', defaultParameters = new Map()) {
    this.ModuleName = moduleName
    this.ModuleFileName = moduleFileName
    this.DefaultParameters = defaultParameters
  }

  /**
   * Copy struct (shallow copy)
   * @this {!GatewayModule}
   * @param {!GatewayModule} other Other struct
   * @returns {!GatewayModule} This struct
   */
  copy (other) {
    if (other.ModuleName != null) {
      this.ModuleName = other.ModuleName
    } else {
      this.ModuleName = null
    }
    if (other.ModuleFileName != null) {
      this.ModuleFileName = other.ModuleFileName
    } else {
      this.ModuleFileName = null
    }
    if (other.DefaultParameters != null) {
      this.DefaultParameters = new Map()
      Object.keys(other.DefaultParameters).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.DefaultParameters[key] != null) {
            let tempValue
            tempValue = other.DefaultParameters[key]
            this.DefaultParameters.set(tempKey, tempValue)
          } else {
            this.DefaultParameters.set(tempKey, null)
          }
        }
      })
    } else {
      this.DefaultParameters = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!GatewayModule}
   * @returns {!GatewayModule} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new GatewayModuleModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new GatewayModuleModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!GatewayModule}
   * @param {!GatewayModule} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof GatewayModule)) {
      throw new TypeError('Instance of GatewayModule is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!GatewayModule}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ModuleName: ((this.ModuleName != null) ? this.ModuleName : null),
      ModuleFileName: ((this.ModuleFileName != null) ? this.ModuleFileName : null),
      DefaultParameters: ((this.DefaultParameters != null) ? fbe.MapToObject(new Map(Array.from(this.DefaultParameters, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return GatewayModule.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!GatewayModule} other Object value
   * @returns {!GatewayModule} Created struct
   */
  static fromObject (other) {
    return new GatewayModule().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!GatewayModule}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return GatewayModule.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!GatewayModule}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 69
  }
}

export { GatewayModule };

/**
 * Fast Binary Encoding GatewayModule field model
 */
class FieldModelGatewayModule extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ModuleName = new fbe.FieldModelString(buffer, 4 + 4)
    this.ModuleFileName = new fbe.FieldModelString(buffer, this.ModuleName.fbeOffset + this.ModuleName.fbeSize)
    this.DefaultParameters = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this.ModuleFileName.fbeOffset + this.ModuleFileName.fbeSize), new fbe.FieldModelString(buffer, this.ModuleFileName.fbeOffset + this.ModuleFileName.fbeSize), buffer, this.ModuleFileName.fbeOffset + this.ModuleFileName.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ModuleName.fbeSize + this.ModuleFileName.fbeSize + this.DefaultParameters.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ModuleName.fbeExtra + this.ModuleFileName.fbeExtra + this.DefaultParameters.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelGatewayModule.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 69
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelGatewayModule}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelGatewayModule}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ModuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.ModuleFileName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleFileName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleFileName.fbeSize

    if ((fbeCurrentSize + this.DefaultParameters.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultParameters.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultParameters.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelGatewayModule}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelGatewayModule}
   * @param {!GatewayModule} fbeValue Default value, defaults is new GatewayModule()
   * @returns {!GatewayModule} GatewayModule value
   */
  get (fbeValue = new GatewayModule()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelGatewayModule}
   * @param {!GatewayModule} fbeValue GatewayModule value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ModuleName.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleName = this.ModuleName.get()
    } else {
      fbeValue.ModuleName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.ModuleFileName.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleFileName = this.ModuleFileName.get()
    } else {
      fbeValue.ModuleFileName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleFileName.fbeSize

    if ((fbeCurrentSize + this.DefaultParameters.fbeSize) <= fbeStructSize) {
      this.DefaultParameters.get(fbeValue.DefaultParameters)
    } else {
      fbeValue.DefaultParameters.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultParameters.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelGatewayModule}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelGatewayModule}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelGatewayModule}
   * @param {!GatewayModule} fbeValue GatewayModule value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelGatewayModule}
   * @param {GatewayModule} fbeValue GatewayModule value
   */
  setFields (fbeValue) {
    this.ModuleName.set(fbeValue.ModuleName)
    this.ModuleFileName.set(fbeValue.ModuleFileName)
    this.DefaultParameters.set(fbeValue.DefaultParameters)
  }
}

export { FieldModelGatewayModule };

/**
 * Fast Binary Encoding GatewayModule model
 */
class GatewayModuleModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelGatewayModule(this.buffer, 4)
  }

  /**
   * Get the GatewayModule model
   * @this {!GatewayModuleModel}
   * @returns {!FieldModelGatewayModule} model GatewayModule model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!GatewayModuleModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!GatewayModuleModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return GatewayModuleModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!GatewayModuleModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelGatewayModule.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!GatewayModuleModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!GatewayModuleModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!GatewayModuleModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!GatewayModuleModel}
   * @param {!GatewayModule} value GatewayModule value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!GatewayModuleModel}
   * @param {!GatewayModule} value GatewayModule value, defaults is new GatewayModule()
   * @return {!object} Deserialized GatewayModule value and its size
   */
  deserialize (value = new GatewayModule()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new GatewayModule(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new GatewayModule(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!GatewayModuleModel}
   * @param {!number} prev Previous GatewayModule model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { GatewayModuleModel };
