// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: entity.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradeEventType} from './entity/TradeEventType'
import {ReasonCode} from './entity/ReasonCode'
import {ServiceStatus} from './entity/ServiceStatus'
import {BlockedIPReason} from './entity/BlockedIPReason'
import {OrderStable} from './entity/OrderStable'
import {OrderStableModel} from './entity/OrderStable'
import {AccountMarginStable} from './entity/AccountMarginStable'
import {AccountMarginStableModel} from './entity/AccountMarginStable'
import {OrderSnapshot} from './entity/OrderSnapshot'
import {OrderSnapshotModel} from './entity/OrderSnapshot'
import {AccountMarginSnapshot} from './entity/AccountMarginSnapshot'
import {AccountMarginSnapshotModel} from './entity/AccountMarginSnapshot'
import {TickSnapshot} from './entity/TickSnapshot'
import {TickSnapshotModel} from './entity/TickSnapshot'
import {TransactionDuration} from './entity/TransactionDuration'
import {TransactionDurationModel} from './entity/TransactionDuration'
import {OrderEvent} from './entity/OrderEvent'
import {OrderEventModel} from './entity/OrderEvent'
import {TradeEvent} from './entity/TradeEvent'
import {TradeEventModel} from './entity/TradeEvent'
import {ToBuildCsClientRequest} from './entity/ToBuildCsClientRequest'
import {ToBuildCsClientRequestModel} from './entity/ToBuildCsClientRequest'
import {ToBuildCsClientResponse} from './entity/ToBuildCsClientResponse'
import {ToBuildCsClientResponseModel} from './entity/ToBuildCsClientResponse'
import {Person} from './entity/Person'
import {PersonModel} from './entity/Person'
import {Authentication} from './entity/Authentication'
import {AuthenticationModel} from './entity/Authentication'
import {TradingAccount} from './entity/TradingAccount'
import {TradingAccountModel} from './entity/TradingAccount'
import {BlockedIP} from './entity/BlockedIP'
import {BlockedIPModel} from './entity/BlockedIP'
import {BlockedIPFiltered} from './entity/BlockedIPFiltered'
import {BlockedIPFilteredModel} from './entity/BlockedIPFiltered'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding entity sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize entity sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._ToBuildCsClientRequestModel = new ToBuildCsClientRequestModel(this.buffer)
    this._ToBuildCsClientResponseModel = new ToBuildCsClientResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get ToBuildCsClientRequest model
   * @this {!Sender}
   * @returns {!ToBuildCsClientRequestModel} ToBuildCsClientRequest model
   */
  get ToBuildCsClientRequestModel () {
    return this._ToBuildCsClientRequestModel
  }

  /**
   * Get ToBuildCsClientResponse model
   * @this {!Sender}
   * @returns {!ToBuildCsClientResponseModel} ToBuildCsClientResponse model
   */
  get ToBuildCsClientResponseModel () {
    return this._ToBuildCsClientResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ToBuildCsClientRequest) && (value.fbeType === this.ToBuildCsClientRequestModel.fbeType)) {
      return this.send_ToBuildCsClientRequest(value)
    }
    if ((value instanceof ToBuildCsClientResponse) && (value.fbeType === this.ToBuildCsClientResponseModel.fbeType)) {
      return this.send_ToBuildCsClientResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ToBuildCsClientRequest value
   * @this {!Sender}
   * @param {!ToBuildCsClientRequest} value ToBuildCsClientRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ToBuildCsClientRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ToBuildCsClientRequestModel.serialize(value)
    console.assert((serialized > 0), 'entity.ToBuildCsClientRequest serialization failed!')
    console.assert(this.ToBuildCsClientRequestModel.verify(), 'entity.ToBuildCsClientRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ToBuildCsClientResponse value
   * @this {!Sender}
   * @param {!ToBuildCsClientResponse} value ToBuildCsClientResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ToBuildCsClientResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ToBuildCsClientResponseModel.serialize(value)
    console.assert((serialized > 0), 'entity.ToBuildCsClientResponse serialization failed!')
    console.assert(this.ToBuildCsClientResponseModel.verify(), 'entity.ToBuildCsClientResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'entity.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding entity receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize entity receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._ToBuildCsClientRequestValue = new ToBuildCsClientRequest()
    this._ToBuildCsClientRequestModel = new ToBuildCsClientRequestModel()
    this._ToBuildCsClientResponseValue = new ToBuildCsClientResponse()
    this._ToBuildCsClientResponseModel = new ToBuildCsClientResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ToBuildCsClientRequest receive handler
   * @this {!Receiver}
   * @param {!ToBuildCsClientRequest} value ToBuildCsClientRequest received value
   */
  onReceive_ToBuildCsClientRequest (value) {}  // eslint-disable-line

  /**
   * ToBuildCsClientResponse receive handler
   * @this {!Receiver}
   * @param {!ToBuildCsClientResponse} value ToBuildCsClientResponse received value
   */
  onReceive_ToBuildCsClientResponse (value) {}  // eslint-disable-line

  /**
   * entity receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ToBuildCsClientRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ToBuildCsClientRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientRequestModel.verify(), 'entity.ToBuildCsClientRequest validation failed!')
        let deserialized = this._ToBuildCsClientRequestModel.deserialize(this._ToBuildCsClientRequestValue)
        console.assert((deserialized.size > 0), 'entity.ToBuildCsClientRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ToBuildCsClientRequestValue)
        this.onReceive_ToBuildCsClientRequest(this._ToBuildCsClientRequestValue)
        return true
      }
      case ToBuildCsClientResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ToBuildCsClientResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientResponseModel.verify(), 'entity.ToBuildCsClientResponse validation failed!')
        let deserialized = this._ToBuildCsClientResponseModel.deserialize(this._ToBuildCsClientResponseValue)
        console.assert((deserialized.size > 0), 'entity.ToBuildCsClientResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ToBuildCsClientResponseValue)
        this.onReceive_ToBuildCsClientResponse(this._ToBuildCsClientResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding entity proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize entity proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._ToBuildCsClientRequestModel = new ToBuildCsClientRequestModel()
    this._ToBuildCsClientResponseModel = new ToBuildCsClientResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * ToBuildCsClientRequest proxy handler
   * @this {!Proxy}
   * @param {!ToBuildCsClientRequest} model ToBuildCsClientRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ToBuildCsClientRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ToBuildCsClientResponse proxy handler
   * @this {!Proxy}
   * @param {!ToBuildCsClientResponse} model ToBuildCsClientResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ToBuildCsClientResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * entity receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ToBuildCsClientRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ToBuildCsClientRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientRequestModel.verify(), 'entity.ToBuildCsClientRequest validation failed!')

        let fbeBegin = this._ToBuildCsClientRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ToBuildCsClientRequest(this._ToBuildCsClientRequestModel, type, buffer, offset, size)
        this._ToBuildCsClientRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ToBuildCsClientResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ToBuildCsClientResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientResponseModel.verify(), 'entity.ToBuildCsClientResponse validation failed!')

        let fbeBegin = this._ToBuildCsClientResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ToBuildCsClientResponse(this._ToBuildCsClientResponseModel, type, buffer, offset, size)
        this._ToBuildCsClientResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding entity client
 */
class Client extends fbe.Client {
  /**
   * Initialize entity client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._ToBuildCsClientRequestSenderModel = new ToBuildCsClientRequestModel(this.sendBuffer)
    this._ToBuildCsClientRequestReceiverValue = new ToBuildCsClientRequest()
    this._ToBuildCsClientRequestReceiverModel = new ToBuildCsClientRequestModel()
    this._ToBuildCsClientResponseSenderModel = new ToBuildCsClientResponseModel(this.sendBuffer)
    this._ToBuildCsClientResponseReceiverValue = new ToBuildCsClientResponse()
    this._ToBuildCsClientResponseReceiverModel = new ToBuildCsClientResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get ToBuildCsClientRequest model
   * @this {!Client}
   * @returns {!ToBuildCsClientRequestModel} ToBuildCsClientRequest sender model
   */
  get ToBuildCsClientRequestSenderModel () {
    return this._ToBuildCsClientRequestSenderModel
  }

  /**
   * Get ToBuildCsClientResponse model
   * @this {!Client}
   * @returns {!ToBuildCsClientResponseModel} ToBuildCsClientResponse sender model
   */
  get ToBuildCsClientResponseSenderModel () {
    return this._ToBuildCsClientResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ToBuildCsClientRequest) && (value.fbeType === this.ToBuildCsClientRequestSenderModel.fbeType)) {
      return this.send_ToBuildCsClientRequest(value)
    }
    if ((value instanceof ToBuildCsClientResponse) && (value.fbeType === this.ToBuildCsClientResponseSenderModel.fbeType)) {
      return this.send_ToBuildCsClientResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ToBuildCsClientRequest value
   * @this {!Client}
   * @param {!ToBuildCsClientRequest} value ToBuildCsClientRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ToBuildCsClientRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ToBuildCsClientRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'entity.ToBuildCsClientRequest serialization failed!')
    console.assert(this.ToBuildCsClientRequestSenderModel.verify(), 'entity.ToBuildCsClientRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ToBuildCsClientResponse value
   * @this {!Client}
   * @param {!ToBuildCsClientResponse} value ToBuildCsClientResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ToBuildCsClientResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ToBuildCsClientResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'entity.ToBuildCsClientResponse serialization failed!')
    console.assert(this.ToBuildCsClientResponseSenderModel.verify(), 'entity.ToBuildCsClientResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'entity.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ToBuildCsClientRequest receive handler
   * @this {!Client}
   * @param {!ToBuildCsClientRequest} value ToBuildCsClientRequest received value
   */
  onReceive_ToBuildCsClientRequest (value) {}  // eslint-disable-line

  /**
   * ToBuildCsClientResponse receive handler
   * @this {!Client}
   * @param {!ToBuildCsClientResponse} value ToBuildCsClientResponse received value
   */
  onReceive_ToBuildCsClientResponse (value) {}  // eslint-disable-line

  /**
   * entity receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ToBuildCsClientRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ToBuildCsClientRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientRequestReceiverModel.verify(), 'entity.ToBuildCsClientRequest validation failed!')
        let deserialized = this._ToBuildCsClientRequestReceiverModel.deserialize(this._ToBuildCsClientRequestReceiverValue)
        console.assert((deserialized.size > 0), 'entity.ToBuildCsClientRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ToBuildCsClientRequestReceiverValue)
        this.onReceive_ToBuildCsClientRequest(this._ToBuildCsClientRequestReceiverValue)
        return true
      }
      case ToBuildCsClientResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ToBuildCsClientResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ToBuildCsClientResponseReceiverModel.verify(), 'entity.ToBuildCsClientResponse validation failed!')
        let deserialized = this._ToBuildCsClientResponseReceiverModel.deserialize(this._ToBuildCsClientResponseReceiverValue)
        console.assert((deserialized.size > 0), 'entity.ToBuildCsClientResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ToBuildCsClientResponseReceiverValue)
        this.onReceive_ToBuildCsClientResponse(this._ToBuildCsClientResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
