import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { Currency } from '@models';
import { ErrorsTracker } from '@api/errorsTracker';
import { adaptApi } from '@utils/models';
import { keyMap, t } from '@localization';
import { UUID } from '@api/fbe/uuid';
import { CurrenciesGetAllRequest } from '@api/fbe/manager/CurrenciesGetAllRequest';
import { CurrencyCreateRequest } from '@api/fbe/manager/CurrencyCreateRequest';
import { CurrencyDeleteRequest } from '@api/fbe/manager/CurrencyDeleteRequest';
import { CurrencyModifyRequest } from '@api/fbe/manager/CurrencyModifyRequest';

export class CurrenciesStore {
    errorTracker = new ErrorsTracker({ title: 'Currencies' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Currency[] = [];

    isLoading: boolean = false;

    setData(value: Currency[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            this.update({
                ...targetItem,
                SortOrder: newIndex,
            });
        } else {
            throw new Error(t(keyMap.messages.currencies.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new CurrenciesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Currencies.map(
                (module): Currency => ({
                    ...adaptApi(module),
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: Currency) {
        const request = new CurrencyCreateRequest();
        request.SortOrder = record.SortOrder;
        request.CurrencyGroupId = UUID.fromString(record.CurrencyGroupId);
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        request.Precision = record.Precision!;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new CurrencyDeleteRequest();
        request.CurrencyId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: Currency) {
        const request = new CurrencyModifyRequest();
        request.CurrencyId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewName = record.Name;
        request.NewCurrencyGroupId = UUID.fromString(record.CurrencyGroupId);
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        request.NewPrecision = record.Precision;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const currenciesStore = new CurrenciesStore();

export { currenciesStore };
