
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {DrawingStorageSqliteParams} from './DrawingStorageSqliteParams';
import {FieldModelDrawingStorageSqliteParams} from './DrawingStorageSqliteParams';

/**
 * DrawingStorageServerSettings struct
 */
class DrawingStorageServerSettings {
  /**
   * Initialize struct
   * @param {!number=} apiPort
   * @param {!boolean=} useSsl
   * @param {DrawingStorageSqliteParams=} sqliteParams
   * @param {!string=} sslCertificatePath
   * @param {string=} sslCertificatePassword
   * @param {!number=} slowQueryLogThreshold
   * @constructor
   */
  constructor (apiPort = 5175, useSsl = false, sqliteParams = null, sslCertificatePath = '../../tools/certificates/server.pem', sslCertificatePassword = null, slowQueryLogThreshold = new UInt64(100000000, 0)) {
    this.ApiPort = apiPort
    this.UseSsl = useSsl
    this.SqliteParams = sqliteParams
    this.SslCertificatePath = sslCertificatePath
    this.SslCertificatePassword = sslCertificatePassword
    this.SlowQueryLogThreshold = slowQueryLogThreshold
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DrawingStorageServerSettings}
   * @param {!DrawingStorageServerSettings} other Other struct
   * @returns {!DrawingStorageServerSettings} This struct
   */
  copy (other) {
    if (other.ApiPort != null) {
      this.ApiPort = other.ApiPort
    } else {
      this.ApiPort = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    if (other.SqliteParams != null) {
      this.SqliteParams = DrawingStorageSqliteParams.fromObject(other.SqliteParams)
    } else {
      this.SqliteParams = null
    }
    if (other.SslCertificatePath != null) {
      this.SslCertificatePath = other.SslCertificatePath
    } else {
      this.SslCertificatePath = null
    }
    if (other.SslCertificatePassword != null) {
      this.SslCertificatePassword = other.SslCertificatePassword
    } else {
      this.SslCertificatePassword = null
    }
    if (other.SlowQueryLogThreshold != null) {
      this.SlowQueryLogThreshold = UInt64.fromNumber(other.SlowQueryLogThreshold)
    } else {
      this.SlowQueryLogThreshold = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DrawingStorageServerSettings}
   * @returns {!DrawingStorageServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DrawingStorageServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DrawingStorageServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DrawingStorageServerSettings}
   * @param {!DrawingStorageServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DrawingStorageServerSettings)) {
      throw new TypeError('Instance of DrawingStorageServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DrawingStorageServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ApiPort: ((this.ApiPort != null) ? this.ApiPort : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null),
      SqliteParams: ((this.SqliteParams != null) ? this.SqliteParams : null),
      SslCertificatePath: ((this.SslCertificatePath != null) ? this.SslCertificatePath : null),
      SslCertificatePassword: ((this.SslCertificatePassword != null) ? this.SslCertificatePassword : null),
      SlowQueryLogThreshold: ((this.SlowQueryLogThreshold != null) ? this.SlowQueryLogThreshold.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DrawingStorageServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DrawingStorageServerSettings} other Object value
   * @returns {!DrawingStorageServerSettings} Created struct
   */
  static fromObject (other) {
    return new DrawingStorageServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DrawingStorageServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DrawingStorageServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DrawingStorageServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 194
  }
}

export { DrawingStorageServerSettings };

/**
 * Fast Binary Encoding DrawingStorageServerSettings field model
 */
class FieldModelDrawingStorageServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ApiPort = new fbe.FieldModelUInt16(buffer, 4 + 4)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.ApiPort.fbeOffset + this.ApiPort.fbeSize)
    this.SqliteParams = new fbe.FieldModelOptional(new FieldModelDrawingStorageSqliteParams(buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize), buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize)
    this.SslCertificatePath = new fbe.FieldModelString(buffer, this.SqliteParams.fbeOffset + this.SqliteParams.fbeSize)
    this.SslCertificatePassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SslCertificatePath.fbeOffset + this.SslCertificatePath.fbeSize), buffer, this.SslCertificatePath.fbeOffset + this.SslCertificatePath.fbeSize)
    this.SlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.SslCertificatePassword.fbeOffset + this.SslCertificatePassword.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ApiPort.fbeSize + this.UseSsl.fbeSize + this.SqliteParams.fbeSize + this.SslCertificatePath.fbeSize + this.SslCertificatePassword.fbeSize + this.SlowQueryLogThreshold.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ApiPort.fbeExtra + this.UseSsl.fbeExtra + this.SqliteParams.fbeExtra + this.SslCertificatePath.fbeExtra + this.SslCertificatePassword.fbeExtra + this.SlowQueryLogThreshold.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDrawingStorageServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 194
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ApiPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ApiPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ApiPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.SqliteParams.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SqliteParams.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SqliteParams.fbeSize

    if ((fbeCurrentSize + this.SslCertificatePath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslCertificatePath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslCertificatePath.fbeSize

    if ((fbeCurrentSize + this.SslCertificatePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SslCertificatePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslCertificatePassword.fbeSize

    if ((fbeCurrentSize + this.SlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SlowQueryLogThreshold.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!DrawingStorageServerSettings} fbeValue Default value, defaults is new DrawingStorageServerSettings()
   * @returns {!DrawingStorageServerSettings} DrawingStorageServerSettings value
   */
  get (fbeValue = new DrawingStorageServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!DrawingStorageServerSettings} fbeValue DrawingStorageServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ApiPort.fbeSize) <= fbeStructSize) {
      fbeValue.ApiPort = this.ApiPort.get(5175)
    } else {
      fbeValue.ApiPort = 5175
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ApiPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get(false)
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.SqliteParams.fbeSize) <= fbeStructSize) {
      fbeValue.SqliteParams = this.SqliteParams.get()
    } else {
      fbeValue.SqliteParams = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SqliteParams.fbeSize

    if ((fbeCurrentSize + this.SslCertificatePath.fbeSize) <= fbeStructSize) {
      fbeValue.SslCertificatePath = this.SslCertificatePath.get('../../tools/certificates/server.pem')
    } else {
      fbeValue.SslCertificatePath = '../../tools/certificates/server.pem'
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslCertificatePath.fbeSize

    if ((fbeCurrentSize + this.SslCertificatePassword.fbeSize) <= fbeStructSize) {
      fbeValue.SslCertificatePassword = this.SslCertificatePassword.get()
    } else {
      fbeValue.SslCertificatePassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SslCertificatePassword.fbeSize

    if ((fbeCurrentSize + this.SlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.SlowQueryLogThreshold = this.SlowQueryLogThreshold.get(new UInt64(100000000, 0))
    } else {
      fbeValue.SlowQueryLogThreshold = new UInt64(100000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SlowQueryLogThreshold.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDrawingStorageServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {!DrawingStorageServerSettings} fbeValue DrawingStorageServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDrawingStorageServerSettings}
   * @param {DrawingStorageServerSettings} fbeValue DrawingStorageServerSettings value
   */
  setFields (fbeValue) {
    this.ApiPort.set(fbeValue.ApiPort)
    this.UseSsl.set(fbeValue.UseSsl)
    this.SqliteParams.set(fbeValue.SqliteParams)
    this.SslCertificatePath.set(fbeValue.SslCertificatePath)
    this.SslCertificatePassword.set(fbeValue.SslCertificatePassword)
    this.SlowQueryLogThreshold.set(fbeValue.SlowQueryLogThreshold)
  }
}

export { FieldModelDrawingStorageServerSettings };

/**
 * Fast Binary Encoding DrawingStorageServerSettings model
 */
class DrawingStorageServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDrawingStorageServerSettings(this.buffer, 4)
  }

  /**
   * Get the DrawingStorageServerSettings model
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!FieldModelDrawingStorageServerSettings} model DrawingStorageServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DrawingStorageServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDrawingStorageServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DrawingStorageServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DrawingStorageServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DrawingStorageServerSettingsModel}
   * @param {!DrawingStorageServerSettings} value DrawingStorageServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DrawingStorageServerSettingsModel}
   * @param {!DrawingStorageServerSettings} value DrawingStorageServerSettings value, defaults is new DrawingStorageServerSettings()
   * @return {!object} Deserialized DrawingStorageServerSettings value and its size
   */
  deserialize (value = new DrawingStorageServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DrawingStorageServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DrawingStorageServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DrawingStorageServerSettingsModel}
   * @param {!number} prev Previous DrawingStorageServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DrawingStorageServerSettingsModel };
