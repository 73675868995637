
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TradeEventType enum
 */
class TradeEventType {
  /**
   * Initialize enum with a given value
   * @param {TradeEventType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof TradeEventType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!TradeEventType}
   * @param {!TradeEventType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeEventType)) {
      throw new TypeError('Instance of TradeEventType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!TradeEventType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!TradeEventType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === TradeEventType.UNKNOWN.value) {
      return 'UNKNOWN'
    }
    if (this.value === TradeEventType.ACCOUNT_DEPOSIT.value) {
      return 'ACCOUNT_DEPOSIT'
    }
    if (this.value === TradeEventType.ACCOUNT_CREDIT.value) {
      return 'ACCOUNT_CREDIT'
    }
    if (this.value === TradeEventType.ACCOUNT_TRANSFER.value) {
      return 'ACCOUNT_TRANSFER'
    }
    if (this.value === TradeEventType.ORDER_EXECUTION.value) {
      return 'ORDER_EXECUTION'
    }
    if (this.value === TradeEventType.ORDER_PROFIT.value) {
      return 'ORDER_PROFIT'
    }
    if (this.value === TradeEventType.ORDER_COMMISSION.value) {
      return 'ORDER_COMMISSION'
    }
    if (this.value === TradeEventType.ORDER_COMMISSION_CHARGE.value) {
      return 'ORDER_COMMISSION_CHARGE'
    }
    if (this.value === TradeEventType.ORDER_SWAP.value) {
      return 'ORDER_SWAP'
    }
    if (this.value === TradeEventType.ORDER_SWAP_CHARGE.value) {
      return 'ORDER_SWAP_CHARGE'
    }
    if (this.value === TradeEventType.ORDER_EXECUTION_OPEN.value) {
      return 'ORDER_EXECUTION_OPEN'
    }
    if (this.value === TradeEventType.ORDER_EXECUTION_CLOSE.value) {
      return 'ORDER_EXECUTION_CLOSE'
    }
    if (this.value === TradeEventType.ORDER_CREATE.value) {
      return 'ORDER_CREATE'
    }
    if (this.value === TradeEventType.ORDER_ACTIVATION.value) {
      return 'ORDER_ACTIVATION'
    }
    if (this.value === TradeEventType.ORDER_MODIFY.value) {
      return 'ORDER_MODIFY'
    }
    if (this.value === TradeEventType.ORDER_TERMINATION_REJECT.value) {
      return 'ORDER_TERMINATION_REJECT'
    }
    if (this.value === TradeEventType.ORDER_TERMINATION_CANCEL.value) {
      return 'ORDER_TERMINATION_CANCEL'
    }
    if (this.value === TradeEventType.ORDER_TERMINATION_EXPIRED.value) {
      return 'ORDER_TERMINATION_EXPIRED'
    }
    if (this.value === TradeEventType.EXECUTION_UNMATCHED.value) {
      return 'EXECUTION_UNMATCHED'
    }
    if (this.value === TradeEventType.ROLLOVER.value) {
      return 'ROLLOVER'
    }
    if (this.value === TradeEventType.ROLLOVER_MONTHLY.value) {
      return 'ROLLOVER_MONTHLY'
    }
    if (this.value === TradeEventType.SNAPSHOT_ACCOUNT_MARGIN.value) {
      return 'SNAPSHOT_ACCOUNT_MARGIN'
    }
    if (this.value === TradeEventType.SNAPSHOT_ORDER.value) {
      return 'SNAPSHOT_ORDER'
    }
    if (this.value === TradeEventType.SNAPSHOT_TICK.value) {
      return 'SNAPSHOT_TICK'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!TradeEventType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!TradeEventType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!TradeEventType} Created enum
   */
  static fromObject (other) {
    return new TradeEventType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
TradeEventType.UNKNOWN = new TradeEventType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ACCOUNT_DEPOSIT = new TradeEventType(1000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ACCOUNT_CREDIT = new TradeEventType(1000 + 1)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ACCOUNT_TRANSFER = new TradeEventType(1000 + 2)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_EXECUTION = new TradeEventType(2000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_PROFIT = new TradeEventType(2000 + 1)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_COMMISSION = new TradeEventType(2000 + 2)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_COMMISSION_CHARGE = new TradeEventType(2000 + 3)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_SWAP = new TradeEventType(2000 + 4)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_SWAP_CHARGE = new TradeEventType(2000 + 5)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_EXECUTION_OPEN = new TradeEventType(2000 + 6)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_EXECUTION_CLOSE = new TradeEventType(2000 + 7)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_CREATE = new TradeEventType(3000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_ACTIVATION = new TradeEventType(3000 + 1)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_MODIFY = new TradeEventType(3000 + 2)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_TERMINATION_REJECT = new TradeEventType(3000 + 3)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_TERMINATION_CANCEL = new TradeEventType(3000 + 4)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ORDER_TERMINATION_EXPIRED = new TradeEventType(3000 + 5)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.EXECUTION_UNMATCHED = new TradeEventType(4000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ROLLOVER = new TradeEventType(5000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.ROLLOVER_MONTHLY = new TradeEventType(5000 + 1)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.SNAPSHOT_ACCOUNT_MARGIN = new TradeEventType(6000 + 0)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.SNAPSHOT_ORDER = new TradeEventType(6000 + 1)
// noinspection PointlessArithmeticExpressionJS
TradeEventType.SNAPSHOT_TICK = new TradeEventType(6000 + 2)

export { TradeEventType };

/**
 * Fast Binary Encoding TradeEventType field model
 */
class FieldModelTradeEventType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTradeEventType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelTradeEventType}
   * @param {TradeEventType=} defaults Default value, defaults is new TradeEventType()
   * @returns {!TradeEventType} Result value
   */
  get (defaults = new TradeEventType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new TradeEventType(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelTradeEventType}
   * @param {!TradeEventType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelTradeEventType };
