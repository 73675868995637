import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { MenuItemConfig } from '.';

type MenuFromRoutesConfig = {
    path?: string;
    exact?: boolean;
    menu?: {
        title: any;
        key?: string;
        icon?: string | JSX.Element;
    };
    children?: MenuFromRoutesConfig[];
};

export function buildMenuFromRoutes(
    configItems: MenuFromRoutesConfig[],
    _outResultTo: MenuItemConfig[] = [],
): MenuItemConfig[] {
    for (const item of configItems) {
        if (item.menu) {
            const menuItem: MenuItemConfig = {
                title: item.children ? item.menu.title : <Link to={item.path || '/'}>{item.menu.title}</Link>,
                key: item.menu.key,
                icon: item.menu.icon,
                path: item.path,
                children: item.children && buildMenuFromRoutes(item.children),
                exact: item.exact,
            };
            _outResultTo.push(menuItem);
        } else if (item.children) {
            buildMenuFromRoutes(item.children, _outResultTo);
        }
    }

    return _outResultTo;
}

export function matchMenuKeys(
    path: string,
    menu: MenuItemConfig[],
    deep = true,
    _matchedOut: MenuItemConfig[] = [],
): MenuItemConfig[] {
    for (const item of menu) {
        const match = matchPath(path, item);
        if (match) {
            _matchedOut.push(item);
        }

        if (deep && item.children) {
            matchMenuKeys(path, item.children, deep, _matchedOut);
        }
    }
    return _matchedOut;
}

export function keyFromMenuItem(item: MenuItemConfig) {
    return item.key || item.path || item.title;
}
