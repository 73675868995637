
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TradingCentralLanguage enum
 */
class TradingCentralLanguage {
  /**
   * Initialize enum with a given value
   * @param {TradingCentralLanguage|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof TradingCentralLanguage) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!TradingCentralLanguage}
   * @param {!TradingCentralLanguage} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCentralLanguage)) {
      throw new TypeError('Instance of TradingCentralLanguage is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!TradingCentralLanguage}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!TradingCentralLanguage}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === TradingCentralLanguage.AF.value) {
      return 'AF'
    }
    if (this.value === TradingCentralLanguage.AR.value) {
      return 'AR'
    }
    if (this.value === TradingCentralLanguage.BG.value) {
      return 'BG'
    }
    if (this.value === TradingCentralLanguage.BN.value) {
      return 'BN'
    }
    if (this.value === TradingCentralLanguage.ZH_HANS.value) {
      return 'ZH_HANS'
    }
    if (this.value === TradingCentralLanguage.ZH_HANT.value) {
      return 'ZH_HANT'
    }
    if (this.value === TradingCentralLanguage.CS.value) {
      return 'CS'
    }
    if (this.value === TradingCentralLanguage.DA.value) {
      return 'DA'
    }
    if (this.value === TradingCentralLanguage.DE.value) {
      return 'DE'
    }
    if (this.value === TradingCentralLanguage.EL.value) {
      return 'EL'
    }
    if (this.value === TradingCentralLanguage.EN.value) {
      return 'EN'
    }
    if (this.value === TradingCentralLanguage.ES.value) {
      return 'ES'
    }
    if (this.value === TradingCentralLanguage.ET.value) {
      return 'ET'
    }
    if (this.value === TradingCentralLanguage.FA.value) {
      return 'FA'
    }
    if (this.value === TradingCentralLanguage.FR_FR.value) {
      return 'FR_FR'
    }
    if (this.value === TradingCentralLanguage.FI.value) {
      return 'FI'
    }
    if (this.value === TradingCentralLanguage.FIL.value) {
      return 'FIL'
    }
    if (this.value === TradingCentralLanguage.FR.value) {
      return 'FR'
    }
    if (this.value === TradingCentralLanguage.HE.value) {
      return 'HE'
    }
    if (this.value === TradingCentralLanguage.HI.value) {
      return 'HI'
    }
    if (this.value === TradingCentralLanguage.HR.value) {
      return 'HR'
    }
    if (this.value === TradingCentralLanguage.HU.value) {
      return 'HU'
    }
    if (this.value === TradingCentralLanguage.ID.value) {
      return 'ID'
    }
    if (this.value === TradingCentralLanguage.IS.value) {
      return 'IS'
    }
    if (this.value === TradingCentralLanguage.IT.value) {
      return 'IT'
    }
    if (this.value === TradingCentralLanguage.JA.value) {
      return 'JA'
    }
    if (this.value === TradingCentralLanguage.KM.value) {
      return 'KM'
    }
    if (this.value === TradingCentralLanguage.KO.value) {
      return 'KO'
    }
    if (this.value === TradingCentralLanguage.LV.value) {
      return 'LV'
    }
    if (this.value === TradingCentralLanguage.LT.value) {
      return 'LT'
    }
    if (this.value === TradingCentralLanguage.MS.value) {
      return 'MS'
    }
    if (this.value === TradingCentralLanguage.NL.value) {
      return 'NL'
    }
    if (this.value === TradingCentralLanguage.NO.value) {
      return 'NO'
    }
    if (this.value === TradingCentralLanguage.PT_BR.value) {
      return 'PT_BR'
    }
    if (this.value === TradingCentralLanguage.PL.value) {
      return 'PL'
    }
    if (this.value === TradingCentralLanguage.PT.value) {
      return 'PT'
    }
    if (this.value === TradingCentralLanguage.RO.value) {
      return 'RO'
    }
    if (this.value === TradingCentralLanguage.RU.value) {
      return 'RU'
    }
    if (this.value === TradingCentralLanguage.SK.value) {
      return 'SK'
    }
    if (this.value === TradingCentralLanguage.SL.value) {
      return 'SL'
    }
    if (this.value === TradingCentralLanguage.SV.value) {
      return 'SV'
    }
    if (this.value === TradingCentralLanguage.TH.value) {
      return 'TH'
    }
    if (this.value === TradingCentralLanguage.TR.value) {
      return 'TR'
    }
    if (this.value === TradingCentralLanguage.UR.value) {
      return 'UR'
    }
    if (this.value === TradingCentralLanguage.VI.value) {
      return 'VI'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!TradingCentralLanguage}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!TradingCentralLanguage}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!TradingCentralLanguage} Created enum
   */
  static fromObject (other) {
    return new TradingCentralLanguage(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.AF = new TradingCentralLanguage(0 + 0)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.AR = new TradingCentralLanguage(0 + 1)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.BG = new TradingCentralLanguage(0 + 2)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.BN = new TradingCentralLanguage(0 + 3)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.ZH_HANS = new TradingCentralLanguage(0 + 4)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.ZH_HANT = new TradingCentralLanguage(0 + 5)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.CS = new TradingCentralLanguage(0 + 6)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.DA = new TradingCentralLanguage(0 + 7)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.DE = new TradingCentralLanguage(0 + 8)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.EL = new TradingCentralLanguage(0 + 9)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.EN = new TradingCentralLanguage(0 + 10)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.ES = new TradingCentralLanguage(0 + 11)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.ET = new TradingCentralLanguage(0 + 12)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.FA = new TradingCentralLanguage(0 + 13)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.FR_FR = new TradingCentralLanguage(0 + 14)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.FI = new TradingCentralLanguage(0 + 15)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.FIL = new TradingCentralLanguage(0 + 16)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.FR = new TradingCentralLanguage(0 + 17)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.HE = new TradingCentralLanguage(0 + 18)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.HI = new TradingCentralLanguage(0 + 19)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.HR = new TradingCentralLanguage(0 + 20)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.HU = new TradingCentralLanguage(0 + 21)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.ID = new TradingCentralLanguage(0 + 22)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.IS = new TradingCentralLanguage(0 + 23)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.IT = new TradingCentralLanguage(0 + 24)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.JA = new TradingCentralLanguage(0 + 25)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.KM = new TradingCentralLanguage(0 + 26)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.KO = new TradingCentralLanguage(0 + 27)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.LV = new TradingCentralLanguage(0 + 28)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.LT = new TradingCentralLanguage(0 + 29)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.MS = new TradingCentralLanguage(0 + 30)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.NL = new TradingCentralLanguage(0 + 31)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.NO = new TradingCentralLanguage(0 + 32)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.PT_BR = new TradingCentralLanguage(0 + 33)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.PL = new TradingCentralLanguage(0 + 34)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.PT = new TradingCentralLanguage(0 + 35)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.RO = new TradingCentralLanguage(0 + 36)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.RU = new TradingCentralLanguage(0 + 37)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.SK = new TradingCentralLanguage(0 + 38)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.SL = new TradingCentralLanguage(0 + 39)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.SV = new TradingCentralLanguage(0 + 40)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.TH = new TradingCentralLanguage(0 + 41)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.TR = new TradingCentralLanguage(0 + 42)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.UR = new TradingCentralLanguage(0 + 43)
// noinspection PointlessArithmeticExpressionJS
TradingCentralLanguage.VI = new TradingCentralLanguage(0 + 44)

export { TradingCentralLanguage };

/**
 * Fast Binary Encoding TradingCentralLanguage field model
 */
class FieldModelTradingCentralLanguage extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTradingCentralLanguage}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelTradingCentralLanguage}
   * @param {TradingCentralLanguage=} defaults Default value, defaults is new TradingCentralLanguage()
   * @returns {!TradingCentralLanguage} Result value
   */
  get (defaults = new TradingCentralLanguage()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new TradingCentralLanguage(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelTradingCentralLanguage}
   * @param {!TradingCentralLanguage} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelTradingCentralLanguage };
