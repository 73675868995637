
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Quote} from './Quote';
import {FieldModelQuote} from './Quote';

/**
 * Level2 struct
 */
class Level2 {
  /**
   * Initialize struct
   * @param {!Date=} time
   * @param {!Array=} bids
   * @param {!Array=} asks
   * @constructor
   */
  constructor (time = new Date(0), bids = [], asks = []) {
    this.Time = time
    this.Bids = bids
    this.Asks = asks
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Level2}
   * @param {!Level2} other Other struct
   * @returns {!Level2} This struct
   */
  copy (other) {
    if (other.Time != null) {
      if (other.Time instanceof Date) {
        this.Time = new Date(other.Time.getTime())
      } else {
        this.Time = new Date(Math.round(other.Time / 1000000))
      }
    } else {
      this.Time = null
    }
    if (other.Bids != null) {
      this.Bids = []
      for (let item of other.Bids) {
        if (item != null) {
          let tempItem
          tempItem = Quote.fromObject(item)
          this.Bids.push(tempItem)
        } else {
          this.Bids.push(null)
        }
      }
    } else {
      this.Bids = null
    }
    if (other.Asks != null) {
      this.Asks = []
      for (let item of other.Asks) {
        if (item != null) {
          let tempItem
          tempItem = Quote.fromObject(item)
          this.Asks.push(tempItem)
        } else {
          this.Asks.push(null)
        }
      }
    } else {
      this.Asks = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Level2}
   * @returns {!Level2} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Level2Model(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Level2Model(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Level2}
   * @param {!Level2} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Level2)) {
      throw new TypeError('Instance of Level2 is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Level2}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Time: ((this.Time != null) ? (this.Time.getTime() * 1000000) : null),
      Bids: ((this.Bids != null) ? Array.from(this.Bids, item => ((item != null) ? item : null)) : null),
      Asks: ((this.Asks != null) ? Array.from(this.Asks, item => ((item != null) ? item : null)) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Level2.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Level2} other Object value
   * @returns {!Level2} Created struct
   */
  static fromObject (other) {
    return new Level2().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Level2}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Level2.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Level2}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 35
  }
}

export { Level2 };

/**
 * Fast Binary Encoding Level2 field model
 */
class FieldModelLevel2 extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Time = new fbe.FieldModelTimestamp(buffer, 4 + 4)
    this.Bids = new fbe.FieldModelVector(new FieldModelQuote(buffer, this.Time.fbeOffset + this.Time.fbeSize), buffer, this.Time.fbeOffset + this.Time.fbeSize)
    this.Asks = new fbe.FieldModelVector(new FieldModelQuote(buffer, this.Bids.fbeOffset + this.Bids.fbeSize), buffer, this.Bids.fbeOffset + this.Bids.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLevel2}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLevel2}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Time.fbeSize + this.Bids.fbeSize + this.Asks.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLevel2}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Time.fbeExtra + this.Bids.fbeExtra + this.Asks.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLevel2}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLevel2.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLevel2}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 35
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLevel2}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLevel2}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Time.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Time.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Time.fbeSize

    if ((fbeCurrentSize + this.Bids.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Bids.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Bids.fbeSize

    if ((fbeCurrentSize + this.Asks.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Asks.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Asks.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLevel2}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLevel2}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLevel2}
   * @param {!Level2} fbeValue Default value, defaults is new Level2()
   * @returns {!Level2} Level2 value
   */
  get (fbeValue = new Level2()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLevel2}
   * @param {!Level2} fbeValue Level2 value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Time.fbeSize) <= fbeStructSize) {
      fbeValue.Time = this.Time.get()
    } else {
      fbeValue.Time = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Time.fbeSize

    if ((fbeCurrentSize + this.Bids.fbeSize) <= fbeStructSize) {
      this.Bids.get(fbeValue.Bids)
    } else {
      fbeValue.Bids.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Bids.fbeSize

    if ((fbeCurrentSize + this.Asks.fbeSize) <= fbeStructSize) {
      this.Asks.get(fbeValue.Asks)
    } else {
      fbeValue.Asks.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Asks.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLevel2}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLevel2}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLevel2}
   * @param {!Level2} fbeValue Level2 value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLevel2}
   * @param {Level2} fbeValue Level2 value
   */
  setFields (fbeValue) {
    this.Time.set(fbeValue.Time)
    this.Bids.set(fbeValue.Bids)
    this.Asks.set(fbeValue.Asks)
  }
}

export { FieldModelLevel2 };

/**
 * Fast Binary Encoding Level2 model
 */
class Level2Model extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLevel2(this.buffer, 4)
  }

  /**
   * Get the Level2 model
   * @this {!Level2Model}
   * @returns {!FieldModelLevel2} model Level2 model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Level2Model}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Level2Model}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Level2Model.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Level2Model}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLevel2.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Level2Model}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Level2Model}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Level2Model}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Level2Model}
   * @param {!Level2} value Level2 value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Level2Model}
   * @param {!Level2} value Level2 value, defaults is new Level2()
   * @return {!object} Deserialized Level2 value and its size
   */
  deserialize (value = new Level2()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Level2(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Level2(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Level2Model}
   * @param {!number} prev Previous Level2 model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Level2Model };
