
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AccountFiltration} from '../core/AccountFiltration';
import {FieldModelAccountFiltration} from '../core/AccountFiltration';
import {AccountMarginFiltration} from '../core/AccountMarginFiltration';
import {FieldModelAccountMarginFiltration} from '../core/AccountMarginFiltration';
import {AccountGroupFiltration} from '../core/AccountGroupFiltration';
import {FieldModelAccountGroupFiltration} from '../core/AccountGroupFiltration';
import {PlatformFiltration} from '../core/PlatformFiltration';
import {FieldModelPlatformFiltration} from '../core/PlatformFiltration';
import {CurrencyFiltration} from '../core/CurrencyFiltration';
import {FieldModelCurrencyFiltration} from '../core/CurrencyFiltration';
import {FeaturesToggleFiltration} from '../core/FeaturesToggleFiltration';
import {FieldModelFeaturesToggleFiltration} from '../core/FeaturesToggleFiltration';

/**
 * AccountsFiltration struct
 */
class AccountsFiltration {
  /**
   * Initialize struct
   * @param {AccountFiltration=} accountFiltration
   * @param {AccountMarginFiltration=} accountMarginFiltration
   * @param {AccountGroupFiltration=} accountGroupFiltration
   * @param {PlatformFiltration=} platformFiltration
   * @param {CurrencyFiltration=} marginCurrencyFiltration
   * @param {FeaturesToggleFiltration=} featuresToggleFiltration
   * @constructor
   */
  constructor (accountFiltration = null, accountMarginFiltration = null, accountGroupFiltration = null, platformFiltration = null, marginCurrencyFiltration = null, featuresToggleFiltration = null) {
    this.AccountFiltration = accountFiltration
    this.AccountMarginFiltration = accountMarginFiltration
    this.AccountGroupFiltration = accountGroupFiltration
    this.PlatformFiltration = platformFiltration
    this.MarginCurrencyFiltration = marginCurrencyFiltration
    this.FeaturesToggleFiltration = featuresToggleFiltration
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountsFiltration}
   * @param {!AccountsFiltration} other Other struct
   * @returns {!AccountsFiltration} This struct
   */
  copy (other) {
    if (other.AccountFiltration != null) {
      this.AccountFiltration = AccountFiltration.fromObject(other.AccountFiltration)
    } else {
      this.AccountFiltration = null
    }
    if (other.AccountMarginFiltration != null) {
      this.AccountMarginFiltration = AccountMarginFiltration.fromObject(other.AccountMarginFiltration)
    } else {
      this.AccountMarginFiltration = null
    }
    if (other.AccountGroupFiltration != null) {
      this.AccountGroupFiltration = AccountGroupFiltration.fromObject(other.AccountGroupFiltration)
    } else {
      this.AccountGroupFiltration = null
    }
    if (other.PlatformFiltration != null) {
      this.PlatformFiltration = PlatformFiltration.fromObject(other.PlatformFiltration)
    } else {
      this.PlatformFiltration = null
    }
    if (other.MarginCurrencyFiltration != null) {
      this.MarginCurrencyFiltration = CurrencyFiltration.fromObject(other.MarginCurrencyFiltration)
    } else {
      this.MarginCurrencyFiltration = null
    }
    if (other.FeaturesToggleFiltration != null) {
      this.FeaturesToggleFiltration = FeaturesToggleFiltration.fromObject(other.FeaturesToggleFiltration)
    } else {
      this.FeaturesToggleFiltration = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountsFiltration}
   * @returns {!AccountsFiltration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountsFiltrationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountsFiltrationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountsFiltration}
   * @param {!AccountsFiltration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountsFiltration)) {
      throw new TypeError('Instance of AccountsFiltration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountsFiltration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountFiltration: ((this.AccountFiltration != null) ? this.AccountFiltration : null),
      AccountMarginFiltration: ((this.AccountMarginFiltration != null) ? this.AccountMarginFiltration : null),
      AccountGroupFiltration: ((this.AccountGroupFiltration != null) ? this.AccountGroupFiltration : null),
      PlatformFiltration: ((this.PlatformFiltration != null) ? this.PlatformFiltration : null),
      MarginCurrencyFiltration: ((this.MarginCurrencyFiltration != null) ? this.MarginCurrencyFiltration : null),
      FeaturesToggleFiltration: ((this.FeaturesToggleFiltration != null) ? this.FeaturesToggleFiltration : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountsFiltration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountsFiltration} other Object value
   * @returns {!AccountsFiltration} Created struct
   */
  static fromObject (other) {
    return new AccountsFiltration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountsFiltration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountsFiltration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountsFiltration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 170
  }
}

export { AccountsFiltration };

/**
 * Fast Binary Encoding AccountsFiltration field model
 */
class FieldModelAccountsFiltration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountFiltration = new fbe.FieldModelOptional(new FieldModelAccountFiltration(buffer, 4 + 4), buffer, 4 + 4)
    this.AccountMarginFiltration = new fbe.FieldModelOptional(new FieldModelAccountMarginFiltration(buffer, this.AccountFiltration.fbeOffset + this.AccountFiltration.fbeSize), buffer, this.AccountFiltration.fbeOffset + this.AccountFiltration.fbeSize)
    this.AccountGroupFiltration = new fbe.FieldModelOptional(new FieldModelAccountGroupFiltration(buffer, this.AccountMarginFiltration.fbeOffset + this.AccountMarginFiltration.fbeSize), buffer, this.AccountMarginFiltration.fbeOffset + this.AccountMarginFiltration.fbeSize)
    this.PlatformFiltration = new fbe.FieldModelOptional(new FieldModelPlatformFiltration(buffer, this.AccountGroupFiltration.fbeOffset + this.AccountGroupFiltration.fbeSize), buffer, this.AccountGroupFiltration.fbeOffset + this.AccountGroupFiltration.fbeSize)
    this.MarginCurrencyFiltration = new fbe.FieldModelOptional(new FieldModelCurrencyFiltration(buffer, this.PlatformFiltration.fbeOffset + this.PlatformFiltration.fbeSize), buffer, this.PlatformFiltration.fbeOffset + this.PlatformFiltration.fbeSize)
    this.FeaturesToggleFiltration = new fbe.FieldModelOptional(new FieldModelFeaturesToggleFiltration(buffer, this.MarginCurrencyFiltration.fbeOffset + this.MarginCurrencyFiltration.fbeSize), buffer, this.MarginCurrencyFiltration.fbeOffset + this.MarginCurrencyFiltration.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountFiltration.fbeSize + this.AccountMarginFiltration.fbeSize + this.AccountGroupFiltration.fbeSize + this.PlatformFiltration.fbeSize + this.MarginCurrencyFiltration.fbeSize + this.FeaturesToggleFiltration.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountFiltration.fbeExtra + this.AccountMarginFiltration.fbeExtra + this.AccountGroupFiltration.fbeExtra + this.PlatformFiltration.fbeExtra + this.MarginCurrencyFiltration.fbeExtra + this.FeaturesToggleFiltration.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountsFiltration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 170
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountsFiltration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountsFiltration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountMarginFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountGroupFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupFiltration.fbeSize

    if ((fbeCurrentSize + this.PlatformFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformFiltration.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyFiltration.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggleFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleFiltration.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountsFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountsFiltration}
   * @param {!AccountsFiltration} fbeValue Default value, defaults is new AccountsFiltration()
   * @returns {!AccountsFiltration} AccountsFiltration value
   */
  get (fbeValue = new AccountsFiltration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountsFiltration}
   * @param {!AccountsFiltration} fbeValue AccountsFiltration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.AccountFiltration = this.AccountFiltration.get()
    } else {
      fbeValue.AccountFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountMarginFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginFiltration = this.AccountMarginFiltration.get()
    } else {
      fbeValue.AccountMarginFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginFiltration.fbeSize

    if ((fbeCurrentSize + this.AccountGroupFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupFiltration = this.AccountGroupFiltration.get()
    } else {
      fbeValue.AccountGroupFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupFiltration.fbeSize

    if ((fbeCurrentSize + this.PlatformFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformFiltration = this.PlatformFiltration.get()
    } else {
      fbeValue.PlatformFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformFiltration.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyFiltration = this.MarginCurrencyFiltration.get()
    } else {
      fbeValue.MarginCurrencyFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyFiltration.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggleFiltration = this.FeaturesToggleFiltration.get()
    } else {
      fbeValue.FeaturesToggleFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleFiltration.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountsFiltration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountsFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountsFiltration}
   * @param {!AccountsFiltration} fbeValue AccountsFiltration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountsFiltration}
   * @param {AccountsFiltration} fbeValue AccountsFiltration value
   */
  setFields (fbeValue) {
    this.AccountFiltration.set(fbeValue.AccountFiltration)
    this.AccountMarginFiltration.set(fbeValue.AccountMarginFiltration)
    this.AccountGroupFiltration.set(fbeValue.AccountGroupFiltration)
    this.PlatformFiltration.set(fbeValue.PlatformFiltration)
    this.MarginCurrencyFiltration.set(fbeValue.MarginCurrencyFiltration)
    this.FeaturesToggleFiltration.set(fbeValue.FeaturesToggleFiltration)
  }
}

export { FieldModelAccountsFiltration };

/**
 * Fast Binary Encoding AccountsFiltration model
 */
class AccountsFiltrationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountsFiltration(this.buffer, 4)
  }

  /**
   * Get the AccountsFiltration model
   * @this {!AccountsFiltrationModel}
   * @returns {!FieldModelAccountsFiltration} model AccountsFiltration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountsFiltrationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountsFiltrationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountsFiltrationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountsFiltrationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountsFiltration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountsFiltrationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountsFiltrationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountsFiltrationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountsFiltrationModel}
   * @param {!AccountsFiltration} value AccountsFiltration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountsFiltrationModel}
   * @param {!AccountsFiltration} value AccountsFiltration value, defaults is new AccountsFiltration()
   * @return {!object} Deserialized AccountsFiltration value and its size
   */
  deserialize (value = new AccountsFiltration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountsFiltration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountsFiltration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountsFiltrationModel}
   * @param {!number} prev Previous AccountsFiltration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountsFiltrationModel };
