import React, { FC } from 'react';
import Text from 'antd/lib/typography/Text';
import styles from './FieldLabel.module.scss';
import cn from 'classnames';

export const FieldLabel: FC<{
    title: string;
    titleDescription?(): JSX.Element | string;
    controls?: JSX.Element;
    required?: boolean;
    className?: string;
}> = ({ title, titleDescription, required, controls, className }) => (
    <Text className={cn(styles.container, className)}>
        <span>
            {title}
            {titleDescription && titleDescription()}
            {required && <span className={styles.required}>*</span>}
        </span>
        {controls}
    </Text>
);
