import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UInt64 } from '@api/fbe/int64';
import { TradeHistoryFilterRequest } from '@api/fbe/manager/TradeHistoryFilterRequest';
import {
    BalanceTransaction,
    BalanceTransactionsRequestParamsWithTransactionType,
    convertTradeRecordToBalanceTransactionRecord,
} from '@models/reports/BalanceTransaction';
import { Account } from '@models/accounts/Account';

class BalanceTransactionsStore {
    errorTracker = new ErrorsTracker({ title: 'BalanceTransactions' });

    constructor() {
        makeAutoObservable(this);
    }

    data: BalanceTransaction[] = [];

    isLoading = false;

    requestParams: BalanceTransactionsRequestParamsWithTransactionType | null = null;

    setData(value: BalanceTransaction[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setRequestParams = (params: BalanceTransactionsRequestParamsWithTransactionType | null): void => {
        this.requestParams = params;
    };

    @ErrorsTracker.wrapApi()
    async get(): Promise<BalanceTransaction[]> {
        this.setIsLoading(true);
        const request = new TradeHistoryFilterRequest();
        if (this.requestParams) {
            if (this.requestParams.Login) {
                const logins = this.requestParams.Login.split(',').map((login) => UInt64.fromString(login.trim()));
                request.AccountLogins = new Set(logins);
            }
            if (this.requestParams.RequestFrom) request.RequestFrom = this.requestParams.RequestFrom;
            if (this.requestParams.RequestTo) request.RequestTo = this.requestParams.RequestTo;
            request.TransactionTypes = this.requestParams.TransactionTypes;
            request.LatestOrderState = true;
        }

        const result = await SocketClient.instance.request(request, this.errorTracker);
        const balanceTransactions: BalanceTransaction[] = result.TradeHistory.map((record, index) => ({
            key: index,
            ...convertTradeRecordToBalanceTransactionRecord(record),
        }));
        this.setData(balanceTransactions);
        this.setIsLoading(false);
        return balanceTransactions;
    }

    decorateBalanceTransactionsWithAccountName(accounts: Account[]) {
        this.setData(
            this.data.map((item) => ({
                ...item,
                AccountName: accounts.find((acc) => acc.Login === item.AccountLogin)?.Name ?? '',
            })),
        );
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.requestParams = null;
    }
}

export const balanceTransactionsStore = new BalanceTransactionsStore();
