
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {TradingCalendarRegularRuleModifyResponse} from './TradingCalendarRegularRuleModifyResponse';
import {DaysOfWeek} from '../core/DaysOfWeek';
import {FieldModelDaysOfWeek} from '../core/DaysOfWeek';

/**
 * TradingCalendarRegularRuleModifyRequest struct
 */
class TradingCalendarRegularRuleModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} ruleId
   * @param {string=} ruleName
   * @param {string=} newName
   * @param {DaysOfWeek=} newDays
   * @param {!Array=} newSymbolNames
   * @param {!Array=} newSymbolGroupNames
   * @param {boolean=} newIsDefault
   * @param {number=} newMarketOpen
   * @param {number=} newMarketClose
   * @param {number=} newTradeOpen
   * @param {number=} newTradeClose
   * @param {string=} newComment
   * @param {!boolean=} isOffTimeRulesChanged
   * @param {!Array=} newOffTimeRulesIds
   * @param {UUID=} newTimezoneId
   * @param {string=} newTimezoneName
   * @param {!boolean=} symbolNamesReset
   * @param {!boolean=} symbolGroupNamesReset
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), ruleId = null, ruleName = null, newName = null, newDays = null, newSymbolNames = [], newSymbolGroupNames = [], newIsDefault = null, newMarketOpen = null, newMarketClose = null, newTradeOpen = null, newTradeClose = null, newComment = null, isOffTimeRulesChanged = false, newOffTimeRulesIds = [], newTimezoneId = null, newTimezoneName = null, symbolNamesReset = false, symbolGroupNamesReset = false) {
    super()
    super.copy(parent)
    this.RuleId = ruleId
    this.RuleName = ruleName
    this.NewName = newName
    this.NewDays = newDays
    this.NewSymbolNames = newSymbolNames
    this.NewSymbolGroupNames = newSymbolGroupNames
    this.NewIsDefault = newIsDefault
    this.NewMarketOpen = newMarketOpen
    this.NewMarketClose = newMarketClose
    this.NewTradeOpen = newTradeOpen
    this.NewTradeClose = newTradeClose
    this.NewComment = newComment
    this.IsOffTimeRulesChanged = isOffTimeRulesChanged
    this.NewOffTimeRulesIds = newOffTimeRulesIds
    this.NewTimezoneId = newTimezoneId
    this.NewTimezoneName = newTimezoneName
    this.SymbolNamesReset = symbolNamesReset
    this.SymbolGroupNamesReset = symbolGroupNamesReset
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @param {!TradingCalendarRegularRuleModifyRequest} other Other struct
   * @returns {!TradingCalendarRegularRuleModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.RuleId != null) {
      this.RuleId = new UUID(other.RuleId)
    } else {
      this.RuleId = null
    }
    if (other.RuleName != null) {
      this.RuleName = other.RuleName
    } else {
      this.RuleName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewDays != null) {
      this.NewDays = DaysOfWeek.fromObject(other.NewDays)
    } else {
      this.NewDays = null
    }
    if (other.NewSymbolNames != null) {
      this.NewSymbolNames = []
      for (let item of other.NewSymbolNames) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.NewSymbolNames.push(tempItem)
        } else {
          this.NewSymbolNames.push(null)
        }
      }
    } else {
      this.NewSymbolNames = null
    }
    if (other.NewSymbolGroupNames != null) {
      this.NewSymbolGroupNames = []
      for (let item of other.NewSymbolGroupNames) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.NewSymbolGroupNames.push(tempItem)
        } else {
          this.NewSymbolGroupNames.push(null)
        }
      }
    } else {
      this.NewSymbolGroupNames = null
    }
    if (other.NewIsDefault != null) {
      this.NewIsDefault = other.NewIsDefault
    } else {
      this.NewIsDefault = null
    }
    if (other.NewMarketOpen != null) {
      this.NewMarketOpen = UInt64.fromNumber(other.NewMarketOpen)
    } else {
      this.NewMarketOpen = null
    }
    if (other.NewMarketClose != null) {
      this.NewMarketClose = UInt64.fromNumber(other.NewMarketClose)
    } else {
      this.NewMarketClose = null
    }
    if (other.NewTradeOpen != null) {
      this.NewTradeOpen = UInt64.fromNumber(other.NewTradeOpen)
    } else {
      this.NewTradeOpen = null
    }
    if (other.NewTradeClose != null) {
      this.NewTradeClose = UInt64.fromNumber(other.NewTradeClose)
    } else {
      this.NewTradeClose = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.IsOffTimeRulesChanged != null) {
      this.IsOffTimeRulesChanged = other.IsOffTimeRulesChanged
    } else {
      this.IsOffTimeRulesChanged = null
    }
    if (other.NewOffTimeRulesIds != null) {
      this.NewOffTimeRulesIds = []
      for (let item of other.NewOffTimeRulesIds) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.NewOffTimeRulesIds.push(tempItem)
        } else {
          this.NewOffTimeRulesIds.push(null)
        }
      }
    } else {
      this.NewOffTimeRulesIds = null
    }
    if (other.NewTimezoneId != null) {
      this.NewTimezoneId = new UUID(other.NewTimezoneId)
    } else {
      this.NewTimezoneId = null
    }
    if (other.NewTimezoneName != null) {
      this.NewTimezoneName = other.NewTimezoneName
    } else {
      this.NewTimezoneName = null
    }
    if (other.SymbolNamesReset != null) {
      this.SymbolNamesReset = other.SymbolNamesReset
    } else {
      this.SymbolNamesReset = null
    }
    if (other.SymbolGroupNamesReset != null) {
      this.SymbolGroupNamesReset = other.SymbolGroupNamesReset
    } else {
      this.SymbolGroupNamesReset = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @returns {!TradingCalendarRegularRuleModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingCalendarRegularRuleModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingCalendarRegularRuleModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @param {!TradingCalendarRegularRuleModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCalendarRegularRuleModifyRequest)) {
      throw new TypeError('Instance of TradingCalendarRegularRuleModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      RuleId: ((this.RuleId != null) ? this.RuleId.toString() : null),
      RuleName: ((this.RuleName != null) ? this.RuleName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewDays: ((this.NewDays != null) ? this.NewDays : null),
      NewSymbolNames: ((this.NewSymbolNames != null) ? Array.from(this.NewSymbolNames, item => ((item != null) ? item : null)) : null),
      NewSymbolGroupNames: ((this.NewSymbolGroupNames != null) ? Array.from(this.NewSymbolGroupNames, item => ((item != null) ? item : null)) : null),
      NewIsDefault: ((this.NewIsDefault != null) ? this.NewIsDefault : null),
      NewMarketOpen: ((this.NewMarketOpen != null) ? this.NewMarketOpen.toNumber() : null),
      NewMarketClose: ((this.NewMarketClose != null) ? this.NewMarketClose.toNumber() : null),
      NewTradeOpen: ((this.NewTradeOpen != null) ? this.NewTradeOpen.toNumber() : null),
      NewTradeClose: ((this.NewTradeClose != null) ? this.NewTradeClose.toNumber() : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      IsOffTimeRulesChanged: ((this.IsOffTimeRulesChanged != null) ? this.IsOffTimeRulesChanged : null),
      NewOffTimeRulesIds: ((this.NewOffTimeRulesIds != null) ? Array.from(this.NewOffTimeRulesIds, item => ((item != null) ? item.toString() : null)) : null),
      NewTimezoneId: ((this.NewTimezoneId != null) ? this.NewTimezoneId.toString() : null),
      NewTimezoneName: ((this.NewTimezoneName != null) ? this.NewTimezoneName : null),
      SymbolNamesReset: ((this.SymbolNamesReset != null) ? this.SymbolNamesReset : null),
      SymbolGroupNamesReset: ((this.SymbolGroupNamesReset != null) ? this.SymbolGroupNamesReset : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingCalendarRegularRuleModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingCalendarRegularRuleModifyRequest} other Object value
   * @returns {!TradingCalendarRegularRuleModifyRequest} Created struct
   */
  static fromObject (other) {
    return new TradingCalendarRegularRuleModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingCalendarRegularRuleModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingCalendarRegularRuleModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6288
  }
}

export { TradingCalendarRegularRuleModifyRequest };

/**
 * Fast Binary Encoding TradingCalendarRegularRuleModifyRequest field model
 */
class FieldModelTradingCalendarRegularRuleModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.RuleId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.RuleName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.RuleId.fbeOffset + this.RuleId.fbeSize), buffer, this.RuleId.fbeOffset + this.RuleId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.RuleName.fbeOffset + this.RuleName.fbeSize), buffer, this.RuleName.fbeOffset + this.RuleName.fbeSize)
    this.NewDays = new fbe.FieldModelOptional(new FieldModelDaysOfWeek(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewSymbolNames = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.NewDays.fbeOffset + this.NewDays.fbeSize), buffer, this.NewDays.fbeOffset + this.NewDays.fbeSize)
    this.NewSymbolGroupNames = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.NewSymbolNames.fbeOffset + this.NewSymbolNames.fbeSize), buffer, this.NewSymbolNames.fbeOffset + this.NewSymbolNames.fbeSize)
    this.NewIsDefault = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewSymbolGroupNames.fbeOffset + this.NewSymbolGroupNames.fbeSize), buffer, this.NewSymbolGroupNames.fbeOffset + this.NewSymbolGroupNames.fbeSize)
    this.NewMarketOpen = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewIsDefault.fbeOffset + this.NewIsDefault.fbeSize), buffer, this.NewIsDefault.fbeOffset + this.NewIsDefault.fbeSize)
    this.NewMarketClose = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewMarketOpen.fbeOffset + this.NewMarketOpen.fbeSize), buffer, this.NewMarketOpen.fbeOffset + this.NewMarketOpen.fbeSize)
    this.NewTradeOpen = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewMarketClose.fbeOffset + this.NewMarketClose.fbeSize), buffer, this.NewMarketClose.fbeOffset + this.NewMarketClose.fbeSize)
    this.NewTradeClose = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewTradeOpen.fbeOffset + this.NewTradeOpen.fbeSize), buffer, this.NewTradeOpen.fbeOffset + this.NewTradeOpen.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewTradeClose.fbeOffset + this.NewTradeClose.fbeSize), buffer, this.NewTradeClose.fbeOffset + this.NewTradeClose.fbeSize)
    this.IsOffTimeRulesChanged = new fbe.FieldModelBool(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewOffTimeRulesIds = new fbe.FieldModelVector(new fbe.FieldModelUUID(buffer, this.IsOffTimeRulesChanged.fbeOffset + this.IsOffTimeRulesChanged.fbeSize), buffer, this.IsOffTimeRulesChanged.fbeOffset + this.IsOffTimeRulesChanged.fbeSize)
    this.NewTimezoneId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewOffTimeRulesIds.fbeOffset + this.NewOffTimeRulesIds.fbeSize), buffer, this.NewOffTimeRulesIds.fbeOffset + this.NewOffTimeRulesIds.fbeSize)
    this.NewTimezoneName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewTimezoneId.fbeOffset + this.NewTimezoneId.fbeSize), buffer, this.NewTimezoneId.fbeOffset + this.NewTimezoneId.fbeSize)
    this.SymbolNamesReset = new fbe.FieldModelBool(buffer, this.NewTimezoneName.fbeOffset + this.NewTimezoneName.fbeSize)
    this.SymbolGroupNamesReset = new fbe.FieldModelBool(buffer, this.SymbolNamesReset.fbeOffset + this.SymbolNamesReset.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.RuleId.fbeSize + this.RuleName.fbeSize + this.NewName.fbeSize + this.NewDays.fbeSize + this.NewSymbolNames.fbeSize + this.NewSymbolGroupNames.fbeSize + this.NewIsDefault.fbeSize + this.NewMarketOpen.fbeSize + this.NewMarketClose.fbeSize + this.NewTradeOpen.fbeSize + this.NewTradeClose.fbeSize + this.NewComment.fbeSize + this.IsOffTimeRulesChanged.fbeSize + this.NewOffTimeRulesIds.fbeSize + this.NewTimezoneId.fbeSize + this.NewTimezoneName.fbeSize + this.SymbolNamesReset.fbeSize + this.SymbolGroupNamesReset.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.RuleId.fbeExtra + this.RuleName.fbeExtra + this.NewName.fbeExtra + this.NewDays.fbeExtra + this.NewSymbolNames.fbeExtra + this.NewSymbolGroupNames.fbeExtra + this.NewIsDefault.fbeExtra + this.NewMarketOpen.fbeExtra + this.NewMarketClose.fbeExtra + this.NewTradeOpen.fbeExtra + this.NewTradeClose.fbeExtra + this.NewComment.fbeExtra + this.IsOffTimeRulesChanged.fbeExtra + this.NewOffTimeRulesIds.fbeExtra + this.NewTimezoneId.fbeExtra + this.NewTimezoneName.fbeExtra + this.SymbolNamesReset.fbeExtra + this.SymbolGroupNamesReset.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingCalendarRegularRuleModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6288
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RuleId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RuleId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleId.fbeSize

    if ((fbeCurrentSize + this.RuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewDays.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDays.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDays.fbeSize

    if ((fbeCurrentSize + this.NewSymbolNames.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolNames.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolNames.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupNames.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolGroupNames.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupNames.fbeSize

    if ((fbeCurrentSize + this.NewIsDefault.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsDefault.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDefault.fbeSize

    if ((fbeCurrentSize + this.NewMarketOpen.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarketOpen.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketOpen.fbeSize

    if ((fbeCurrentSize + this.NewMarketClose.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarketClose.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketClose.fbeSize

    if ((fbeCurrentSize + this.NewTradeOpen.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradeOpen.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeOpen.fbeSize

    if ((fbeCurrentSize + this.NewTradeClose.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradeClose.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeClose.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.IsOffTimeRulesChanged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOffTimeRulesChanged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOffTimeRulesChanged.fbeSize

    if ((fbeCurrentSize + this.NewOffTimeRulesIds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOffTimeRulesIds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOffTimeRulesIds.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTimezoneId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneId.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTimezoneName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneName.fbeSize

    if ((fbeCurrentSize + this.SymbolNamesReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolNamesReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolNamesReset.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupNamesReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupNamesReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupNamesReset.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!TradingCalendarRegularRuleModifyRequest} fbeValue Default value, defaults is new TradingCalendarRegularRuleModifyRequest()
   * @returns {!TradingCalendarRegularRuleModifyRequest} TradingCalendarRegularRuleModifyRequest value
   */
  get (fbeValue = new TradingCalendarRegularRuleModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!TradingCalendarRegularRuleModifyRequest} fbeValue TradingCalendarRegularRuleModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RuleId.fbeSize) <= fbeStructSize) {
      fbeValue.RuleId = this.RuleId.get()
    } else {
      fbeValue.RuleId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleId.fbeSize

    if ((fbeCurrentSize + this.RuleName.fbeSize) <= fbeStructSize) {
      fbeValue.RuleName = this.RuleName.get()
    } else {
      fbeValue.RuleName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RuleName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewDays.fbeSize) <= fbeStructSize) {
      fbeValue.NewDays = this.NewDays.get()
    } else {
      fbeValue.NewDays = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDays.fbeSize

    if ((fbeCurrentSize + this.NewSymbolNames.fbeSize) <= fbeStructSize) {
      this.NewSymbolNames.get(fbeValue.NewSymbolNames)
    } else {
      fbeValue.NewSymbolNames.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolNames.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupNames.fbeSize) <= fbeStructSize) {
      this.NewSymbolGroupNames.get(fbeValue.NewSymbolGroupNames)
    } else {
      fbeValue.NewSymbolGroupNames.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupNames.fbeSize

    if ((fbeCurrentSize + this.NewIsDefault.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsDefault = this.NewIsDefault.get()
    } else {
      fbeValue.NewIsDefault = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDefault.fbeSize

    if ((fbeCurrentSize + this.NewMarketOpen.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarketOpen = this.NewMarketOpen.get()
    } else {
      fbeValue.NewMarketOpen = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketOpen.fbeSize

    if ((fbeCurrentSize + this.NewMarketClose.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarketClose = this.NewMarketClose.get()
    } else {
      fbeValue.NewMarketClose = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketClose.fbeSize

    if ((fbeCurrentSize + this.NewTradeOpen.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradeOpen = this.NewTradeOpen.get()
    } else {
      fbeValue.NewTradeOpen = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeOpen.fbeSize

    if ((fbeCurrentSize + this.NewTradeClose.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradeClose = this.NewTradeClose.get()
    } else {
      fbeValue.NewTradeClose = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeClose.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.IsOffTimeRulesChanged.fbeSize) <= fbeStructSize) {
      fbeValue.IsOffTimeRulesChanged = this.IsOffTimeRulesChanged.get(false)
    } else {
      fbeValue.IsOffTimeRulesChanged = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOffTimeRulesChanged.fbeSize

    if ((fbeCurrentSize + this.NewOffTimeRulesIds.fbeSize) <= fbeStructSize) {
      this.NewOffTimeRulesIds.get(fbeValue.NewOffTimeRulesIds)
    } else {
      fbeValue.NewOffTimeRulesIds.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOffTimeRulesIds.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneId.fbeSize) <= fbeStructSize) {
      fbeValue.NewTimezoneId = this.NewTimezoneId.get()
    } else {
      fbeValue.NewTimezoneId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneId.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneName.fbeSize) <= fbeStructSize) {
      fbeValue.NewTimezoneName = this.NewTimezoneName.get()
    } else {
      fbeValue.NewTimezoneName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneName.fbeSize

    if ((fbeCurrentSize + this.SymbolNamesReset.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolNamesReset = this.SymbolNamesReset.get()
    } else {
      fbeValue.SymbolNamesReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolNamesReset.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupNamesReset.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolGroupNamesReset = this.SymbolGroupNamesReset.get()
    } else {
      fbeValue.SymbolGroupNamesReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupNamesReset.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {!TradingCalendarRegularRuleModifyRequest} fbeValue TradingCalendarRegularRuleModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingCalendarRegularRuleModifyRequest}
   * @param {TradingCalendarRegularRuleModifyRequest} fbeValue TradingCalendarRegularRuleModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.RuleId.set(fbeValue.RuleId)
    this.RuleName.set(fbeValue.RuleName)
    this.NewName.set(fbeValue.NewName)
    this.NewDays.set(fbeValue.NewDays)
    this.NewSymbolNames.set(fbeValue.NewSymbolNames)
    this.NewSymbolGroupNames.set(fbeValue.NewSymbolGroupNames)
    this.NewIsDefault.set(fbeValue.NewIsDefault)
    this.NewMarketOpen.set(fbeValue.NewMarketOpen)
    this.NewMarketClose.set(fbeValue.NewMarketClose)
    this.NewTradeOpen.set(fbeValue.NewTradeOpen)
    this.NewTradeClose.set(fbeValue.NewTradeClose)
    this.NewComment.set(fbeValue.NewComment)
    this.IsOffTimeRulesChanged.set(fbeValue.IsOffTimeRulesChanged)
    this.NewOffTimeRulesIds.set(fbeValue.NewOffTimeRulesIds)
    this.NewTimezoneId.set(fbeValue.NewTimezoneId)
    this.NewTimezoneName.set(fbeValue.NewTimezoneName)
    this.SymbolNamesReset.set(fbeValue.SymbolNamesReset)
    this.SymbolGroupNamesReset.set(fbeValue.SymbolGroupNamesReset)
  }
}

export { FieldModelTradingCalendarRegularRuleModifyRequest };

/**
 * Fast Binary Encoding TradingCalendarRegularRuleModifyRequest model
 */
class TradingCalendarRegularRuleModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingCalendarRegularRuleModifyRequest(this.buffer, 4)
  }

  /**
   * Get the TradingCalendarRegularRuleModifyRequest model
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!FieldModelTradingCalendarRegularRuleModifyRequest} model TradingCalendarRegularRuleModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingCalendarRegularRuleModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingCalendarRegularRuleModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @param {!TradingCalendarRegularRuleModifyRequest} value TradingCalendarRegularRuleModifyRequest value, defaults is new TradingCalendarRegularRuleModifyRequest()
   * @return {!object} Deserialized TradingCalendarRegularRuleModifyRequest value and its size
   */
  deserialize (value = new TradingCalendarRegularRuleModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingCalendarRegularRuleModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingCalendarRegularRuleModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingCalendarRegularRuleModifyRequestModel}
   * @param {!number} prev Previous TradingCalendarRegularRuleModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingCalendarRegularRuleModifyRequestModel };
TradingCalendarRegularRuleModifyRequest.__has_response = true;
TradingCalendarRegularRuleModifyRequest.__response_class = TradingCalendarRegularRuleModifyResponse;
