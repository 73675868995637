import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { adaptApi } from '@utils/models';
import type { SymbolsGroup } from '@models/Symbols';
import { ErrorsTracker } from '@api/errorsTracker';
import { UUID } from '@api/fbe/uuid';
import { argbToRGB } from '@utils';
import { MarginRateCalculationType } from '@api/fbe/core/MarginRateCalculationType';
import { TradingMode } from '@api/fbe/core/TradingMode';
import { SymbolGroupsGetAllRequest } from '@api/fbe/manager/SymbolGroupsGetAllRequest';
import { SymbolGroupCreateRequest } from '@api/fbe/manager/SymbolGroupCreateRequest';
import { SymbolGroupDeleteRequest } from '@api/fbe/manager/SymbolGroupDeleteRequest';
import { SymbolGroupModifyRequest } from '@api/fbe/manager/SymbolGroupModifyRequest';

export class SymbolsGroupsStore {
    errorTracker = new ErrorsTracker({ title: 'Symbols Group' });

    constructor() {
        makeAutoObservable(this);
    }

    data: SymbolsGroup[] = [];

    isLoading: boolean = false;

    setData(value: SymbolsGroup[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new SymbolGroupsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.SymbolGroups.map(
                (record): SymbolsGroup => ({
                    id: record.id.toString(),
                    SortOrder: record.SortOrder,
                    Name: record.Name,
                    Comment: record.Comment,
                    Description: record.Description,
                    Color: argbToRGB(record.Color),
                    IsTradeAllowed: record.IsTradeAllowed,
                    DefaultExpiration: +record.DefaultExpiration.valueOf(),
                    MarginRateCalculationType: record.MarginRateCalculationType.valueOf(),
                    DefaultTradingMode: record.DefaultTradingMode.valueOf(),
                    ClientName: record.ClientName ?? null,
                    Symbols: [],
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(data: SymbolsGroup) {
        const request = new SymbolGroupCreateRequest();

        request.Name = data.Name;
        request.Comment = data.Comment || '';
        request.ClientName = data.ClientName;
        request.Description = data.Description || '';
        request.Color = data.Color ? parseInt(data.Color.slice(1), 16) : 0;
        request.IsTradeAllowed = data.IsTradeAllowed || false;
        // TP-3793 Hidden for first release
        // request.DefaultExpiration = UInt64.fromNumber(data.DefaultExpiration ?? 0);
        request.MarginRateCalculationType = new MarginRateCalculationType(data.MarginRateCalculationType ?? 0);
        request.DefaultTradingMode = new TradingMode(data.DefaultTradingMode ?? 0);

        const res = await SocketClient.instance.request(request, this.errorTracker);
        await this.get();

        return adaptApi(res.SymbolGroup);
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string | UUID) {
        const request = new SymbolGroupDeleteRequest();

        request.SymbolGroupId = new UUID(id);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: SymbolsGroup) {
        const request = new SymbolGroupModifyRequest();
        request.SymbolGroupId = new UUID(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewClientName = record.ClientName;
        request.NewDescription = record.Description;
        request.NewColor = record.Color ? parseInt(record.Color.slice(1), 16) : null;
        request.NewIsTradeAllowed = record.IsTradeAllowed;
        // TP-3793 Hidden for first release
        // request.NewDefaultExpiration =
        //     record.DefaultExpiration !== null ? UInt64.fromNumber(record.DefaultExpiration) : null;
        request.NewMarginRateCalculationType =
            record.MarginRateCalculationType !== null
                ? new MarginRateCalculationType(record.MarginRateCalculationType)
                : null;
        request.NewDefaultTradingMode =
            record.DefaultTradingMode !== null ? new TradingMode(record.DefaultTradingMode) : null;

        const res = await SocketClient.instance.request(request, this.errorTracker);
        await this.get();

        return adaptApi(res.SymbolGroup);
    }

    @ErrorsTracker.wrapApi()
    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new SymbolGroupModifyRequest();
            request.SymbolGroupId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const symbolsGroupsStore = new SymbolsGroupsStore();
export { symbolsGroupsStore };
