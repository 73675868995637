
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {ChartBarUnsubscribeResponse} from './ChartBarUnsubscribeResponse';
import {ChartBarPeriodType} from '../core/ChartBarPeriodType';
import {FieldModelChartBarPeriodType} from '../core/ChartBarPeriodType';

/**
 * ChartBarUnsubscribeRequest struct
 */
class ChartBarUnsubscribeRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!string=} symbol
   * @param {!ChartBarPeriodType=} periodType
   * @constructor
   */
  constructor (parent = new Request(), symbol = '', periodType = new ChartBarPeriodType()) {
    super()
    super.copy(parent)
    this.Symbol = symbol
    this.PeriodType = periodType
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartBarUnsubscribeRequest}
   * @param {!ChartBarUnsubscribeRequest} other Other struct
   * @returns {!ChartBarUnsubscribeRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Symbol != null) {
      this.Symbol = other.Symbol
    } else {
      this.Symbol = null
    }
    if (other.PeriodType != null) {
      this.PeriodType = ChartBarPeriodType.fromObject(other.PeriodType)
    } else {
      this.PeriodType = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartBarUnsubscribeRequest}
   * @returns {!ChartBarUnsubscribeRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartBarUnsubscribeRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartBarUnsubscribeRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartBarUnsubscribeRequest}
   * @param {!ChartBarUnsubscribeRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarUnsubscribeRequest)) {
      throw new TypeError('Instance of ChartBarUnsubscribeRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartBarUnsubscribeRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Symbol: ((this.Symbol != null) ? this.Symbol : null),
      PeriodType: ((this.PeriodType != null) ? this.PeriodType : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartBarUnsubscribeRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartBarUnsubscribeRequest} other Object value
   * @returns {!ChartBarUnsubscribeRequest} Created struct
   */
  static fromObject (other) {
    return new ChartBarUnsubscribeRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartBarUnsubscribeRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartBarUnsubscribeRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartBarUnsubscribeRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5059
  }
}

export { ChartBarUnsubscribeRequest };

/**
 * Fast Binary Encoding ChartBarUnsubscribeRequest field model
 */
class FieldModelChartBarUnsubscribeRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Symbol = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.PeriodType = new FieldModelChartBarPeriodType(buffer, this.Symbol.fbeOffset + this.Symbol.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Symbol.fbeSize + this.PeriodType.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Symbol.fbeExtra + this.PeriodType.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartBarUnsubscribeRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5059
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PeriodType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!ChartBarUnsubscribeRequest} fbeValue Default value, defaults is new ChartBarUnsubscribeRequest()
   * @returns {!ChartBarUnsubscribeRequest} ChartBarUnsubscribeRequest value
   */
  get (fbeValue = new ChartBarUnsubscribeRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!ChartBarUnsubscribeRequest} fbeValue ChartBarUnsubscribeRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) <= fbeStructSize) {
      fbeValue.Symbol = this.Symbol.get()
    } else {
      fbeValue.Symbol = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) <= fbeStructSize) {
      fbeValue.PeriodType = this.PeriodType.get()
    } else {
      fbeValue.PeriodType = new ChartBarPeriodType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {!ChartBarUnsubscribeRequest} fbeValue ChartBarUnsubscribeRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartBarUnsubscribeRequest}
   * @param {ChartBarUnsubscribeRequest} fbeValue ChartBarUnsubscribeRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Symbol.set(fbeValue.Symbol)
    this.PeriodType.set(fbeValue.PeriodType)
  }
}

export { FieldModelChartBarUnsubscribeRequest };

/**
 * Fast Binary Encoding ChartBarUnsubscribeRequest model
 */
class ChartBarUnsubscribeRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartBarUnsubscribeRequest(this.buffer, 4)
  }

  /**
   * Get the ChartBarUnsubscribeRequest model
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!FieldModelChartBarUnsubscribeRequest} model ChartBarUnsubscribeRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartBarUnsubscribeRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartBarUnsubscribeRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartBarUnsubscribeRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartBarUnsubscribeRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartBarUnsubscribeRequestModel}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartBarUnsubscribeRequestModel}
   * @param {!ChartBarUnsubscribeRequest} value ChartBarUnsubscribeRequest value, defaults is new ChartBarUnsubscribeRequest()
   * @return {!object} Deserialized ChartBarUnsubscribeRequest value and its size
   */
  deserialize (value = new ChartBarUnsubscribeRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartBarUnsubscribeRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartBarUnsubscribeRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartBarUnsubscribeRequestModel}
   * @param {!number} prev Previous ChartBarUnsubscribeRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartBarUnsubscribeRequestModel };
ChartBarUnsubscribeRequest.__has_response = true;
ChartBarUnsubscribeRequest.__response_class = ChartBarUnsubscribeResponse;
