
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {Deprecated006} from './Deprecated006';

/**
 * Deprecated005 struct
 */
class Deprecated005 extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {number=} timespan
   * @param {number=} accountLogin
   * @param {UUID=} accountGroupId
   * @param {string=} accountGroupName
   * @param {UUID=} platformId
   * @param {string=} platformName
   * @constructor
   */
  constructor (parent = new Request(), timespan = null, accountLogin = null, accountGroupId = null, accountGroupName = null, platformId = null, platformName = null) {
    super()
    super.copy(parent)
    this.Timespan = timespan
    this.AccountLogin = accountLogin
    this.AccountGroupId = accountGroupId
    this.AccountGroupName = accountGroupName
    this.PlatformId = platformId
    this.PlatformName = platformName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Deprecated005}
   * @param {!Deprecated005} other Other struct
   * @returns {!Deprecated005} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Timespan != null) {
      this.Timespan = UInt64.fromNumber(other.Timespan)
    } else {
      this.Timespan = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = UInt64.fromNumber(other.AccountLogin)
    } else {
      this.AccountLogin = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountGroupName != null) {
      this.AccountGroupName = other.AccountGroupName
    } else {
      this.AccountGroupName = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.PlatformName != null) {
      this.PlatformName = other.PlatformName
    } else {
      this.PlatformName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Deprecated005}
   * @returns {!Deprecated005} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Deprecated005Model(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Deprecated005Model(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Deprecated005}
   * @param {!Deprecated005} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Deprecated005)) {
      throw new TypeError('Instance of Deprecated005 is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Deprecated005}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Timespan: ((this.Timespan != null) ? this.Timespan.toNumber() : null),
      AccountLogin: ((this.AccountLogin != null) ? this.AccountLogin.toNumber() : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      AccountGroupName: ((this.AccountGroupName != null) ? this.AccountGroupName : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      PlatformName: ((this.PlatformName != null) ? this.PlatformName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Deprecated005.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Deprecated005} other Object value
   * @returns {!Deprecated005} Created struct
   */
  static fromObject (other) {
    return new Deprecated005().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Deprecated005}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Deprecated005.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Deprecated005}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6269
  }
}

export { Deprecated005 };

/**
 * Fast Binary Encoding Deprecated005 field model
 */
class FieldModelDeprecated005 extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Timespan = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Timespan.fbeOffset + this.Timespan.fbeSize), buffer, this.Timespan.fbeOffset + this.Timespan.fbeSize)
    this.AccountGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize), buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize)
    this.AccountGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize), buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize)
    this.PlatformName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize), buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelDeprecated005}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Timespan.fbeSize + this.AccountLogin.fbeSize + this.AccountGroupId.fbeSize + this.AccountGroupName.fbeSize + this.PlatformId.fbeSize + this.PlatformName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Timespan.fbeExtra + this.AccountLogin.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountGroupName.fbeExtra + this.PlatformId.fbeExtra + this.PlatformName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDeprecated005.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6269
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDeprecated005}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDeprecated005}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Timespan.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Timespan.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Timespan.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDeprecated005}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDeprecated005}
   * @param {!Deprecated005} fbeValue Default value, defaults is new Deprecated005()
   * @returns {!Deprecated005} Deprecated005 value
   */
  get (fbeValue = new Deprecated005()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDeprecated005}
   * @param {!Deprecated005} fbeValue Deprecated005 value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Timespan.fbeSize) <= fbeStructSize) {
      fbeValue.Timespan = this.Timespan.get()
    } else {
      fbeValue.Timespan = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Timespan.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLogin = this.AccountLogin.get()
    } else {
      fbeValue.AccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupName = this.AccountGroupName.get()
    } else {
      fbeValue.AccountGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformName = this.PlatformName.get()
    } else {
      fbeValue.PlatformName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDeprecated005}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDeprecated005}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDeprecated005}
   * @param {!Deprecated005} fbeValue Deprecated005 value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDeprecated005}
   * @param {Deprecated005} fbeValue Deprecated005 value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Timespan.set(fbeValue.Timespan)
    this.AccountLogin.set(fbeValue.AccountLogin)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountGroupName.set(fbeValue.AccountGroupName)
    this.PlatformId.set(fbeValue.PlatformId)
    this.PlatformName.set(fbeValue.PlatformName)
  }
}

export { FieldModelDeprecated005 };

/**
 * Fast Binary Encoding Deprecated005 model
 */
class Deprecated005Model extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDeprecated005(this.buffer, 4)
  }

  /**
   * Get the Deprecated005 model
   * @this {!Deprecated005Model}
   * @returns {!FieldModelDeprecated005} model Deprecated005 model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Deprecated005Model}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Deprecated005Model}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Deprecated005Model.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Deprecated005Model}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDeprecated005.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Deprecated005Model}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Deprecated005Model}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Deprecated005Model}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Deprecated005Model}
   * @param {!Deprecated005} value Deprecated005 value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Deprecated005Model}
   * @param {!Deprecated005} value Deprecated005 value, defaults is new Deprecated005()
   * @return {!object} Deserialized Deprecated005 value and its size
   */
  deserialize (value = new Deprecated005()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Deprecated005(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Deprecated005(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Deprecated005Model}
   * @param {!number} prev Previous Deprecated005 model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Deprecated005Model };
Deprecated005.__has_response = true;
Deprecated005.__response_class = Deprecated006;
