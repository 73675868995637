import { ConvertedSymbol } from '@models';
import { Tick as TickApi } from '@api/fbe/core/Tick';
import { ConvertFbeObjType } from '@utils/models';

export enum ArrowDirection {
    'Up',
    'Down',
    'Neutral',
}

export type Tick = ConvertFbeObjType<TickApi> & { tickKey: string; arrow: ArrowDirection; Spread?: number | null };

export type MarketPosition = Partial<ConvertedSymbol> & Tick & { Spread: number | null };
