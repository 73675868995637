import { ReconnectingWebSocket } from './ReconnectingWebSocket';
import { Reject } from './fbe/core/Reject';
import { ResponseListener } from './responseListener';
import { ErrorsTracker } from './errorsTracker';
import { getPrepagedWsUrl } from '@models/Auth';
import { Client as ClinetManagerApi } from './fbe/manager';
import { Client as ClinetExecutionApi } from './fbe/execution';
import { Client as ClinetExposedApi } from './fbe/exposed';
import { Client as ClientFeedApi } from './fbe/feed';
import { PingRequest } from './fbe/client/PingRequest';

// import * as ManagerApi from './manager';

export class SocketClient {
    websocket!: ReconnectingWebSocket;
    client!: ClinetManagerApi & { clientClient: any };
    exposedClient!: ClinetExposedApi;
    executionClient!: ClinetExecutionApi;
    feedClient!: ClientFeedApi;
    isInitted = false;
    log = false;

    responseListener = new ResponseListener();

    private _handleAnyClientResponse = (responseObj: any) => {
        const responseName = (responseObj as any).constructor.name;
        if (this.log && !responseName.includes('Notify')) {
            console.warn('received client ' + responseName + '\t\t', [responseObj]);
        }

        this.responseListener._handleResponse(responseObj);
    };

    request = <T extends Parameters<ClinetManagerApi['send']>[0]>(
        data: T,
        errorTracker?: ErrorsTracker,
    ): T extends { __type_response: infer R } ? Omit<Promise<R>, 'catch'> & { catch(err: Reject): void } : void => {
        const requestName = (data as any).constructor.name;
        if (this.log) {
            console.warn(`request ${requestName}`, data);
        }

        if (this.websocket && this.websocket._ws) {
            this.client.send(data);
        }

        if ((data as any).constructor.__has_response === true) {
            const responseClass = (data as any).constructor.__response_class;

            return new Promise<any>((resolve, reject) => {
                const fbeType = (responseClass as any).fbeType;
                const reqId = (data as any).id;

                const handlerOk = (responseObj: any) => {
                    if (responseObj.id.eq(reqId)) {
                        dispose();
                        resolve(responseObj);
                    }
                };
                const handlerReject = (rejectObj: Reject) => {
                    if (rejectObj.id.eq(reqId)) {
                        dispose();

                        if (errorTracker) {
                            errorTracker.apiReject(requestName, rejectObj);
                        }
                        reject(rejectObj);
                    }
                };
                const dispose = () => {
                    this.responseListener._removeListener(fbeType, handlerOk);
                    this.responseListener._removeListener(Reject.fbeType, handlerReject);
                };
                this.responseListener._addListener(fbeType, handlerOk);
                this.responseListener._addListener(Reject.fbeType, handlerReject);
            }) as any;
        }

        return undefined!;
    };

    sendRequest = (data: Parameters<ClinetManagerApi['send']>[0]) => {
        if (this.log) {
            console.warn('sendRequest ' + (data as any).constructor.name + '\t\t', [data]);
        }
        if (this.websocket && this.websocket._ws) {
            this.client.send(data);
        }
    };

    onceClientResponse = (responseObj: any, cb: (data: any) => void) => {
        console.warn('deprecated');
        this.responseListener.once(responseObj, cb);
    };

    coreReject = (a: any) => {
        console.warn('deprecated');
    };

    private _responseRequest = (message: any) => {
        (this.client as any).receive(new Uint8Array(message));
        (this.exposedClient as any).receive(new Uint8Array(message));
        (this.feedClient as any).receive(new Uint8Array(message));
        (this.executionClient as any).receive(new Uint8Array(message));
    };

    private _onOpen = () => {
        console.log('WS_OPEN');
        (this.client as any).clientClient.onReceive_PongResponse = () => {
            console.log('Pong Response');
        };
        (this.client as any).clientClient.onReceive_PongReject = () => {
            console.log('Pong Reject');
        };
        this.sendRequest(new PingRequest());
    };

    private _onCantConnect = (e) => {
        console.log('WS_ERROR_API');
        this.responseListener._handleResponse({ fbeType: e.code });
    };

    private _onMessage = (event: MessageEvent) => {
        this._responseRequest(event.data);
    };

    init = async (websocketUrl: string) => {
        if (!websocketUrl) {
            return new Promise<void>((_, reject) => {
                reject('No WS url');
            });
        }
        this.websocket = new ReconnectingWebSocket(getPrepagedWsUrl(websocketUrl), null, {
            binaryType: 'arraybuffer',
            reconnectInterval: 3000,
        });

        if (!this.websocket._ws) {
            return new Error('Initialization of Web Socket');
        }
        this.client = new ClinetManagerApi() as any;
        this.exposedClient = new ClinetExposedApi();
        this.feedClient = new ClientFeedApi();
        this.executionClient = new ClinetExecutionApi();

        (this.client as any).onSendHandler = (buffer: Uint8Array, offset: number, size: number) => {
            this.websocket.send(buffer.slice(offset, offset + size));
            return size;
        };
        (this.exposedClient as any).onSendHandler = (buffer: Uint8Array, offset: number, size: number) => {
            this.websocket.send(buffer.slice(offset, offset + size));
            return size;
        };
        (this.feedClient as any).onSendHandler = (buffer: Uint8Array, offset: number, size: number) => {
            this.websocket.send(buffer.slice(offset, offset + size));
            return size;
        };
        (this.executionClient as any).onSendHandler = (buffer: Uint8Array, offset: number, size: number) => {
            this.websocket.send(buffer.slice(offset, offset + size));
            return size;
        };

        (this.client as any).onReceive_any = this._handleAnyClientResponse;
        (this.client as any).coreClient.onReceive_any = this._handleAnyClientResponse;
        (this.client as any).clientClient.onReceive_any = this._handleAnyClientResponse;
        (this.feedClient as any).onReceive_any = this._handleAnyClientResponse;
        (this.executionClient as any).onReceive_any = this._handleAnyClientResponse;
        (this.exposedClient as any).onReceive_any = this._handleAnyClientResponse;

        this.websocket.onmessage = this._onMessage;
        this.websocket.onopen = this._onOpen;
        this.websocket.onCantConnect = this._onCantConnect;

        return new Promise<void>((resolve) => {
            const interval = setInterval(() => {
                if (this.websocket && this.websocket._readyState) {
                    clearInterval(interval);
                    this.isInitted = true;
                    resolve();
                }
            }, 10);
        });
    };

    private static _instance: SocketClient;

    static get instance() {
        if (!SocketClient._instance) SocketClient._instance = new SocketClient();
        return SocketClient._instance;
    }
}

(window as any).__log_fbe = (status: boolean) => {
    SocketClient.instance.log = status;
};
