import { PureComponent } from 'react';

const PLACEHOLDER = () => null;

type PickModuleDefaults<T> = T extends { default: infer D } ? D : T;

// should be class component, because we need fence for react hooks

export class LazyLoad<T> extends PureComponent<{
    load: () => Promise<T> | T;
    children: (loaded: PickModuleDefaults<T>) => any;
    placeholder?: () => any;
}> {
    state = {
        loaded: undefined as any,
    };

    componentDidMount() {
        const { load } = this.props;

        const result = load();
        if (result instanceof Promise) {
            result.then((x: any) => {
                // if user uses 'export default'
                this.setState({
                    // eslint-disable-next-line no-underscore-dangle
                    loaded: x.__esModule && x.default ? x.default : x,
                });
            });
        } else {
            this.setState({
                loaded: result,
            });
        }
    }

    render() {
        const { children, placeholder = PLACEHOLDER } = this.props;
        const { loaded } = this.state;

        if (!loaded) {
            return placeholder();
        }
        return children(loaded as any);
    }
}
