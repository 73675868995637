
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ChartBarPeriodType} from '../core/ChartBarPeriodType';
import {FieldModelChartBarPeriodType} from '../core/ChartBarPeriodType';

/**
 * ChartBarNotify struct
 */
class ChartBarNotify {
  /**
   * Initialize struct
   * @param {!string=} symbol
   * @param {!ChartBarPeriodType=} periodType
   * @param {!Date=} openTime
   * @param {!number=} openPrice
   * @param {!number=} highPrice
   * @param {!number=} lowPrice
   * @param {!number=} closePrice
   * @param {!number=} volume
   * @constructor
   */
  constructor (symbol = '', periodType = new ChartBarPeriodType(), openTime = new Date(0), openPrice = 0.0, highPrice = 0.0, lowPrice = 0.0, closePrice = 0.0, volume = new UInt64(0, 0)) {
    this.Symbol = symbol
    this.PeriodType = periodType
    this.OpenTime = openTime
    this.OpenPrice = openPrice
    this.HighPrice = highPrice
    this.LowPrice = lowPrice
    this.ClosePrice = closePrice
    this.Volume = volume
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartBarNotify}
   * @param {!ChartBarNotify} other Other struct
   * @returns {!ChartBarNotify} This struct
   */
  copy (other) {
    if (other.Symbol != null) {
      this.Symbol = other.Symbol
    } else {
      this.Symbol = null
    }
    if (other.PeriodType != null) {
      this.PeriodType = ChartBarPeriodType.fromObject(other.PeriodType)
    } else {
      this.PeriodType = null
    }
    if (other.OpenTime != null) {
      if (other.OpenTime instanceof Date) {
        this.OpenTime = new Date(other.OpenTime.getTime())
      } else {
        this.OpenTime = new Date(Math.round(other.OpenTime / 1000000))
      }
    } else {
      this.OpenTime = null
    }
    if (other.OpenPrice != null) {
      this.OpenPrice = other.OpenPrice
    } else {
      this.OpenPrice = null
    }
    if (other.HighPrice != null) {
      this.HighPrice = other.HighPrice
    } else {
      this.HighPrice = null
    }
    if (other.LowPrice != null) {
      this.LowPrice = other.LowPrice
    } else {
      this.LowPrice = null
    }
    if (other.ClosePrice != null) {
      this.ClosePrice = other.ClosePrice
    } else {
      this.ClosePrice = null
    }
    if (other.Volume != null) {
      this.Volume = UInt64.fromNumber(other.Volume)
    } else {
      this.Volume = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartBarNotify}
   * @returns {!ChartBarNotify} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartBarNotifyModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartBarNotifyModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartBarNotify}
   * @param {!ChartBarNotify} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarNotify)) {
      throw new TypeError('Instance of ChartBarNotify is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartBarNotify}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Symbol: ((this.Symbol != null) ? this.Symbol : null),
      PeriodType: ((this.PeriodType != null) ? this.PeriodType : null),
      OpenTime: ((this.OpenTime != null) ? (this.OpenTime.getTime() * 1000000) : null),
      OpenPrice: ((this.OpenPrice != null) ? this.OpenPrice : null),
      HighPrice: ((this.HighPrice != null) ? this.HighPrice : null),
      LowPrice: ((this.LowPrice != null) ? this.LowPrice : null),
      ClosePrice: ((this.ClosePrice != null) ? this.ClosePrice : null),
      Volume: ((this.Volume != null) ? this.Volume.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartBarNotify.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartBarNotify} other Object value
   * @returns {!ChartBarNotify} Created struct
   */
  static fromObject (other) {
    return new ChartBarNotify().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartBarNotify}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartBarNotify.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartBarNotify}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5061
  }
}

export { ChartBarNotify };

/**
 * Fast Binary Encoding ChartBarNotify field model
 */
class FieldModelChartBarNotify extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Symbol = new fbe.FieldModelString(buffer, 4 + 4)
    this.PeriodType = new FieldModelChartBarPeriodType(buffer, this.Symbol.fbeOffset + this.Symbol.fbeSize)
    this.OpenTime = new fbe.FieldModelTimestamp(buffer, this.PeriodType.fbeOffset + this.PeriodType.fbeSize)
    this.OpenPrice = new fbe.FieldModelDouble(buffer, this.OpenTime.fbeOffset + this.OpenTime.fbeSize)
    this.HighPrice = new fbe.FieldModelDouble(buffer, this.OpenPrice.fbeOffset + this.OpenPrice.fbeSize)
    this.LowPrice = new fbe.FieldModelDouble(buffer, this.HighPrice.fbeOffset + this.HighPrice.fbeSize)
    this.ClosePrice = new fbe.FieldModelDouble(buffer, this.LowPrice.fbeOffset + this.LowPrice.fbeSize)
    this.Volume = new fbe.FieldModelUInt64(buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Symbol.fbeSize + this.PeriodType.fbeSize + this.OpenTime.fbeSize + this.OpenPrice.fbeSize + this.HighPrice.fbeSize + this.LowPrice.fbeSize + this.ClosePrice.fbeSize + this.Volume.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Symbol.fbeExtra + this.PeriodType.fbeExtra + this.OpenTime.fbeExtra + this.OpenPrice.fbeExtra + this.HighPrice.fbeExtra + this.LowPrice.fbeExtra + this.ClosePrice.fbeExtra + this.Volume.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartBarNotify.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5061
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartBarNotify}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartBarNotify}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PeriodType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize

    if ((fbeCurrentSize + this.OpenTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenTime.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.HighPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HighPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HighPrice.fbeSize

    if ((fbeCurrentSize + this.LowPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LowPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LowPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.Volume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Volume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Volume.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartBarNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartBarNotify}
   * @param {!ChartBarNotify} fbeValue Default value, defaults is new ChartBarNotify()
   * @returns {!ChartBarNotify} ChartBarNotify value
   */
  get (fbeValue = new ChartBarNotify()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartBarNotify}
   * @param {!ChartBarNotify} fbeValue ChartBarNotify value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) <= fbeStructSize) {
      fbeValue.Symbol = this.Symbol.get()
    } else {
      fbeValue.Symbol = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) <= fbeStructSize) {
      fbeValue.PeriodType = this.PeriodType.get()
    } else {
      fbeValue.PeriodType = new ChartBarPeriodType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize

    if ((fbeCurrentSize + this.OpenTime.fbeSize) <= fbeStructSize) {
      fbeValue.OpenTime = this.OpenTime.get()
    } else {
      fbeValue.OpenTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenTime.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) <= fbeStructSize) {
      fbeValue.OpenPrice = this.OpenPrice.get()
    } else {
      fbeValue.OpenPrice = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.HighPrice.fbeSize) <= fbeStructSize) {
      fbeValue.HighPrice = this.HighPrice.get()
    } else {
      fbeValue.HighPrice = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HighPrice.fbeSize

    if ((fbeCurrentSize + this.LowPrice.fbeSize) <= fbeStructSize) {
      fbeValue.LowPrice = this.LowPrice.get()
    } else {
      fbeValue.LowPrice = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LowPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.ClosePrice = this.ClosePrice.get()
    } else {
      fbeValue.ClosePrice = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.Volume.fbeSize) <= fbeStructSize) {
      fbeValue.Volume = this.Volume.get()
    } else {
      fbeValue.Volume = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Volume.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartBarNotify}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartBarNotify}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartBarNotify}
   * @param {!ChartBarNotify} fbeValue ChartBarNotify value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartBarNotify}
   * @param {ChartBarNotify} fbeValue ChartBarNotify value
   */
  setFields (fbeValue) {
    this.Symbol.set(fbeValue.Symbol)
    this.PeriodType.set(fbeValue.PeriodType)
    this.OpenTime.set(fbeValue.OpenTime)
    this.OpenPrice.set(fbeValue.OpenPrice)
    this.HighPrice.set(fbeValue.HighPrice)
    this.LowPrice.set(fbeValue.LowPrice)
    this.ClosePrice.set(fbeValue.ClosePrice)
    this.Volume.set(fbeValue.Volume)
  }
}

export { FieldModelChartBarNotify };

/**
 * Fast Binary Encoding ChartBarNotify model
 */
class ChartBarNotifyModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartBarNotify(this.buffer, 4)
  }

  /**
   * Get the ChartBarNotify model
   * @this {!ChartBarNotifyModel}
   * @returns {!FieldModelChartBarNotify} model ChartBarNotify model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartBarNotifyModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartBarNotifyModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartBarNotifyModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartBarNotifyModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartBarNotify.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartBarNotifyModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartBarNotifyModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartBarNotifyModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartBarNotifyModel}
   * @param {!ChartBarNotify} value ChartBarNotify value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartBarNotifyModel}
   * @param {!ChartBarNotify} value ChartBarNotify value, defaults is new ChartBarNotify()
   * @return {!object} Deserialized ChartBarNotify value and its size
   */
  deserialize (value = new ChartBarNotify()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartBarNotify(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartBarNotify(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartBarNotifyModel}
   * @param {!number} prev Previous ChartBarNotify model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartBarNotifyModel };
