import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { ErrorsTracker } from '@api/errorsTracker';
import { ConvertedSymbol, convertSymbolToSymbolModel } from '@models/Symbols';
import { UInt64 } from '@api/fbe/int64';
import { SymbolsGetAllRequest } from '@api/fbe/manager/SymbolsGetAllRequest';
import { OrderCreateRequest } from '@api/fbe/manager/OrderCreateRequest';
import {
    ActivateOrderModel,
    convertOrderToOrderModel,
    CreateOrderModel,
    GetOrderRequestParams,
    ModifyOrderModelPartial,
    OrderModel,
} from '@models/riskManagement/Order';
import { OrderSide } from '@api/fbe/core/OrderSide';
import { OrderType } from '@api/fbe/core/OrderType';
import { OrderModifyRequest } from '@api/fbe/manager/OrderModifyRequest';
import { Message, MessageType } from '@components/Message/Message';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { OrderGetRequest } from '@api/fbe/manager/OrderGetRequest';
import { OrderCreateResponse } from '@api/fbe/manager/OrderCreateResponse';
import { OrderModifyResponse } from '@api/fbe/manager/OrderModifyResponse';
import { OrderActivateResponse } from '@api/fbe/manager/OrderActivateResponse';
import { OrderActivateRequest } from '@api/fbe/manager/OrderActivateRequest';
import { SymbolGetRequest } from '@api/fbe/manager/SymbolGetRequest';

export class OrderStore {
    errorTracker = new ErrorsTracker({ title: 'Order' });

    constructor() {
        makeAutoObservable(this);
    }

    symbol: ConvertedSymbol | null = null;

    symbols: ConvertedSymbol[] = [];

    isLoadingSymbols: boolean = false;

    isLoadingOrder: boolean = false;

    @ErrorsTracker.wrapApi()
    async getSymbolById(id: string): Promise<ConvertedSymbol> {
        const request = new SymbolGetRequest();
        request.SymbolId = UUID.fromString(id);
        this.isLoadingSymbols = true;
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.symbol = convertSymbolToSymbolModel(data.Symbol); 
        this.isLoadingSymbols = false;
        return this.symbol;
    }

    @ErrorsTracker.wrapApi()
    async getSymbols(): Promise<ConvertedSymbol[]> {
        const request = new SymbolsGetAllRequest();
        this.isLoadingSymbols = true;
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.symbols = data.Symbols.map(convertSymbolToSymbolModel); 
        this.isLoadingSymbols = false;
        return this.symbols;
    }

    @ErrorsTracker.wrapApi()
    async createOrder(data: CreateOrderModel): Promise<OrderCreateResponse> {
        const request = new OrderCreateRequest();
        request.Login = UInt64.fromNumber(data.Login);
        request.SymbolId = UUID.fromString(data.SymbolId);
        request.OrderSide = new OrderSide(data.OrderSide);
        request.OrderType = new OrderType(data.OrderType);
        request.Price = data.OrderType === OrderType.LIMIT.valueOf() ? data.Price : null;
        request.StopPrice = data.OrderType === OrderType.STOP.valueOf() ? data.StopPrice : null;
        request.ExecutionPrice = data.OrderType === OrderType.MARKET.valueOf() ? data.ExecutionPrice : null;
        request.TakeProfit = data.TakeProfit;
        request.StopLoss = data.StopLoss;
        request.Amount = data.Amount;
        request.ManagerComment = data.ManagerComment;
        const result = await SocketClient.instance.request(request, this.errorTracker);
        return result;
    }

    @ErrorsTracker.wrapApi()
    async modifyOrderPartial(
        record: ModifyOrderModelPartial,
        dirtyFields?: FieldNamesMarkedBoolean<FieldValues>,
    ): Promise<OrderModifyResponse> {
        const request = new OrderModifyRequest();
        if (record.OrderId !== null) {
            request.Login = UInt64.fromNumber(record.Login);
            request.OrderId = UInt64.fromNumber(record.OrderId);
            request.NewManagerComment = record.ManagerComment;
            if (dirtyFields?.TakeProfit) {
                request.NewTakeProfit = record.TakeProfit;
                if (record.TakeProfit === null) {
                    request.NewTakeProfitReset = true;
                }
            }
            if (dirtyFields?.StopLoss) {
                request.NewStopLoss = record.StopLoss;
                if (record.StopLoss === null) {
                    request.NewStopLossReset = true;
                }
            }
            if (record.OrderType === OrderType.LIMIT.valueOf()) {
                // Position
                if (dirtyFields?.Price) {
                    request.NewPrice = record.Price;
                    if (record.Price === null) {
                        request.NewPriceReset = true;
                    }
                }
            }
            if (record.OrderType === OrderType.STOP.valueOf()) {
                // PendingOrder
                if (dirtyFields?.StopPrice) {
                    request.NewStopPrice = record.StopPrice;
                    if (record.StopPrice === null) {
                        request.NewStopPriceReset = true;
                    }
                }
            }
            const result = await SocketClient.instance.request(request, this.errorTracker);
            return result;
        }
        Message(MessageType.error, 'OrderId are required');
        return Promise.reject();
    }

    @ErrorsTracker.wrapApi()
    async getOrderById({ Login, OrderId }: GetOrderRequestParams): Promise<OrderModel> {
        this.isLoadingOrder = true;
        if (OrderId === null) {
            Message(MessageType.error, 'Order ID are required');
            return Promise.reject();
        }
        try {
            const request = new OrderGetRequest();
            request.Login = UInt64.fromNumber(Login);
            request.OrderId = UInt64.fromNumber(OrderId);
            const result = await SocketClient.instance.request(request, this.errorTracker);
            const order = convertOrderToOrderModel(result.Order);
            this.isLoadingOrder = false;
            return order;
        } catch (error) {
            Message(MessageType.error, "Can't load Order");
            this.isLoadingOrder = false;
            return Promise.reject();
        }
    }

    @ErrorsTracker.wrapApi()
    async activateOrder(record: ActivateOrderModel): Promise<OrderActivateResponse> {
        const request = new OrderActivateRequest();
        if (record.OrderId !== null) {
            request.OrderId = UInt64.fromNumber(record.OrderId);
            request.ActivateAmount = record.ActivateAmount;
            request.ActivatePrice = record.ActivatePrice;
            const result = await SocketClient.instance.request(request, this.errorTracker);
            return result;
        }
        Message(MessageType.error, 'OrderId are required');
        return Promise.reject();
    }

    reset() {
        this.symbols = [];
        this.isLoadingSymbols = false;
        this.isLoadingOrder = false;
    }
}

const orderStore = new OrderStore();
export { orderStore };
