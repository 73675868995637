
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {AccountGroupModifyResponse} from './AccountGroupModifyResponse';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';
import {StopOutType} from '../core/StopOutType';
import {FieldModelStopOutType} from '../core/StopOutType';
import {SymbolGroupSettings} from '../core/SymbolGroupSettings';
import {FieldModelSymbolGroupSettings} from '../core/SymbolGroupSettings';

/**
 * AccountGroupModifyRequest struct
 */
class AccountGroupModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} accountGroupId
   * @param {string=} accountGroupName
   * @param {number=} newSortOrder
   * @param {UUID=} newPlatformId
   * @param {string=} newPlatformName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {boolean=} newIsDemo
   * @param {boolean=} newIsEnabled
   * @param {boolean=} newIsOTPEnabled
   * @param {boolean=} newIsTradeEnabled
   * @param {AccountingType=} newDefaultAccountingType
   * @param {UUID=} newDefaultBalanceCurrencyId
   * @param {string=} newDefaultBalanceCurrencyName
   * @param {number=} newDefaultBalance
   * @param {number=} newDefaultCredit
   * @param {number=} newDefaultLeverage
   * @param {number=} newMarginCallLevel
   * @param {TradingMode=} newMarginCallAccessType
   * @param {number=} newStopOutLevel
   * @param {StopOutType=} newStopOutType
   * @param {TradingMode=} newStopOutAccessType
   * @param {number=} newDefaultMaxPendingOrders
   * @param {!Map=} symbolGroupSettingsToModify
   * @param {boolean=} newIsSwapEnabled
   * @param {number=} newMinPasswordLength
   * @param {boolean=} deprecated1
   * @param {boolean=} deprecated2
   * @param {boolean=} newIsSSLAccessEnabled
   * @param {boolean=} newIsHTTPSAccessEnabled
   * @param {boolean=} newIsWSSAccessEnabled
   * @param {boolean=} newIsFIXAccessEnabled
   * @param {number=} newMaxLeverage
   * @param {!boolean=} newMaxLeverageReset
   * @param {UUID=} newLeverageProfileId
   * @param {!boolean=} newLeverageProfileIdReset
   * @param {!Set=} symbolGroupSettingsToDelete
   * @param {number=} newDefaultMaxOrders
   * @param {number=} newMaxDepositOperationAmount
   * @param {!boolean=} newMaxDepositOperationAmountReset
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), accountGroupId = null, accountGroupName = null, newSortOrder = null, newPlatformId = null, newPlatformName = null, newName = null, newComment = null, newDescription = null, newIsDemo = null, newIsEnabled = null, newIsOTPEnabled = null, newIsTradeEnabled = null, newDefaultAccountingType = null, newDefaultBalanceCurrencyId = null, newDefaultBalanceCurrencyName = null, newDefaultBalance = null, newDefaultCredit = null, newDefaultLeverage = null, newMarginCallLevel = null, newMarginCallAccessType = null, newStopOutLevel = null, newStopOutType = null, newStopOutAccessType = null, newDefaultMaxPendingOrders = null, symbolGroupSettingsToModify = new Map(), newIsSwapEnabled = null, newMinPasswordLength = null, deprecated1 = null, deprecated2 = null, newIsSSLAccessEnabled = null, newIsHTTPSAccessEnabled = null, newIsWSSAccessEnabled = null, newIsFIXAccessEnabled = null, newMaxLeverage = null, newMaxLeverageReset = false, newLeverageProfileId = null, newLeverageProfileIdReset = false, symbolGroupSettingsToDelete = new Set(), newDefaultMaxOrders = null, newMaxDepositOperationAmount = null, newMaxDepositOperationAmountReset = false) {
    super()
    super.copy(parent)
    this.AccountGroupId = accountGroupId
    this.AccountGroupName = accountGroupName
    this.NewSortOrder = newSortOrder
    this.NewPlatformId = newPlatformId
    this.NewPlatformName = newPlatformName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewIsDemo = newIsDemo
    this.NewIsEnabled = newIsEnabled
    this.NewIsOTPEnabled = newIsOTPEnabled
    this.NewIsTradeEnabled = newIsTradeEnabled
    this.NewDefaultAccountingType = newDefaultAccountingType
    this.NewDefaultBalanceCurrencyId = newDefaultBalanceCurrencyId
    this.NewDefaultBalanceCurrencyName = newDefaultBalanceCurrencyName
    this.NewDefaultBalance = newDefaultBalance
    this.NewDefaultCredit = newDefaultCredit
    this.NewDefaultLeverage = newDefaultLeverage
    this.NewMarginCallLevel = newMarginCallLevel
    this.NewMarginCallAccessType = newMarginCallAccessType
    this.NewStopOutLevel = newStopOutLevel
    this.NewStopOutType = newStopOutType
    this.NewStopOutAccessType = newStopOutAccessType
    this.NewDefaultMaxPendingOrders = newDefaultMaxPendingOrders
    this.SymbolGroupSettingsToModify = symbolGroupSettingsToModify
    this.NewIsSwapEnabled = newIsSwapEnabled
    this.NewMinPasswordLength = newMinPasswordLength
    this.Deprecated1 = deprecated1
    this.Deprecated2 = deprecated2
    this.NewIsSSLAccessEnabled = newIsSSLAccessEnabled
    this.NewIsHTTPSAccessEnabled = newIsHTTPSAccessEnabled
    this.NewIsWSSAccessEnabled = newIsWSSAccessEnabled
    this.NewIsFIXAccessEnabled = newIsFIXAccessEnabled
    this.NewMaxLeverage = newMaxLeverage
    this.NewMaxLeverageReset = newMaxLeverageReset
    this.NewLeverageProfileId = newLeverageProfileId
    this.NewLeverageProfileIdReset = newLeverageProfileIdReset
    this.SymbolGroupSettingsToDelete = symbolGroupSettingsToDelete
    this.NewDefaultMaxOrders = newDefaultMaxOrders
    this.NewMaxDepositOperationAmount = newMaxDepositOperationAmount
    this.NewMaxDepositOperationAmountReset = newMaxDepositOperationAmountReset
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountGroupModifyRequest}
   * @param {!AccountGroupModifyRequest} other Other struct
   * @returns {!AccountGroupModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountGroupName != null) {
      this.AccountGroupName = other.AccountGroupName
    } else {
      this.AccountGroupName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewPlatformId != null) {
      this.NewPlatformId = new UUID(other.NewPlatformId)
    } else {
      this.NewPlatformId = null
    }
    if (other.NewPlatformName != null) {
      this.NewPlatformName = other.NewPlatformName
    } else {
      this.NewPlatformName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewIsDemo != null) {
      this.NewIsDemo = other.NewIsDemo
    } else {
      this.NewIsDemo = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewIsOTPEnabled != null) {
      this.NewIsOTPEnabled = other.NewIsOTPEnabled
    } else {
      this.NewIsOTPEnabled = null
    }
    if (other.NewIsTradeEnabled != null) {
      this.NewIsTradeEnabled = other.NewIsTradeEnabled
    } else {
      this.NewIsTradeEnabled = null
    }
    if (other.NewDefaultAccountingType != null) {
      this.NewDefaultAccountingType = AccountingType.fromObject(other.NewDefaultAccountingType)
    } else {
      this.NewDefaultAccountingType = null
    }
    if (other.NewDefaultBalanceCurrencyId != null) {
      this.NewDefaultBalanceCurrencyId = new UUID(other.NewDefaultBalanceCurrencyId)
    } else {
      this.NewDefaultBalanceCurrencyId = null
    }
    if (other.NewDefaultBalanceCurrencyName != null) {
      this.NewDefaultBalanceCurrencyName = other.NewDefaultBalanceCurrencyName
    } else {
      this.NewDefaultBalanceCurrencyName = null
    }
    if (other.NewDefaultBalance != null) {
      this.NewDefaultBalance = other.NewDefaultBalance
    } else {
      this.NewDefaultBalance = null
    }
    if (other.NewDefaultCredit != null) {
      this.NewDefaultCredit = other.NewDefaultCredit
    } else {
      this.NewDefaultCredit = null
    }
    if (other.NewDefaultLeverage != null) {
      this.NewDefaultLeverage = other.NewDefaultLeverage
    } else {
      this.NewDefaultLeverage = null
    }
    if (other.NewMarginCallLevel != null) {
      this.NewMarginCallLevel = other.NewMarginCallLevel
    } else {
      this.NewMarginCallLevel = null
    }
    if (other.NewMarginCallAccessType != null) {
      this.NewMarginCallAccessType = TradingMode.fromObject(other.NewMarginCallAccessType)
    } else {
      this.NewMarginCallAccessType = null
    }
    if (other.NewStopOutLevel != null) {
      this.NewStopOutLevel = other.NewStopOutLevel
    } else {
      this.NewStopOutLevel = null
    }
    if (other.NewStopOutType != null) {
      this.NewStopOutType = StopOutType.fromObject(other.NewStopOutType)
    } else {
      this.NewStopOutType = null
    }
    if (other.NewStopOutAccessType != null) {
      this.NewStopOutAccessType = TradingMode.fromObject(other.NewStopOutAccessType)
    } else {
      this.NewStopOutAccessType = null
    }
    if (other.NewDefaultMaxPendingOrders != null) {
      this.NewDefaultMaxPendingOrders = UInt64.fromNumber(other.NewDefaultMaxPendingOrders)
    } else {
      this.NewDefaultMaxPendingOrders = null
    }
    if (other.SymbolGroupSettingsToModify != null) {
      this.SymbolGroupSettingsToModify = new Map()
      Object.keys(other.SymbolGroupSettingsToModify).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = new UUID(key)
          if (other.SymbolGroupSettingsToModify[key] != null) {
            let tempValue
            tempValue = SymbolGroupSettings.fromObject(other.SymbolGroupSettingsToModify[key])
            this.SymbolGroupSettingsToModify.set(tempKey, tempValue)
          } else {
            this.SymbolGroupSettingsToModify.set(tempKey, null)
          }
        }
      })
    } else {
      this.SymbolGroupSettingsToModify = null
    }
    if (other.NewIsSwapEnabled != null) {
      this.NewIsSwapEnabled = other.NewIsSwapEnabled
    } else {
      this.NewIsSwapEnabled = null
    }
    if (other.NewMinPasswordLength != null) {
      this.NewMinPasswordLength = other.NewMinPasswordLength
    } else {
      this.NewMinPasswordLength = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = other.Deprecated1
    } else {
      this.Deprecated1 = null
    }
    if (other.Deprecated2 != null) {
      this.Deprecated2 = other.Deprecated2
    } else {
      this.Deprecated2 = null
    }
    if (other.NewIsSSLAccessEnabled != null) {
      this.NewIsSSLAccessEnabled = other.NewIsSSLAccessEnabled
    } else {
      this.NewIsSSLAccessEnabled = null
    }
    if (other.NewIsHTTPSAccessEnabled != null) {
      this.NewIsHTTPSAccessEnabled = other.NewIsHTTPSAccessEnabled
    } else {
      this.NewIsHTTPSAccessEnabled = null
    }
    if (other.NewIsWSSAccessEnabled != null) {
      this.NewIsWSSAccessEnabled = other.NewIsWSSAccessEnabled
    } else {
      this.NewIsWSSAccessEnabled = null
    }
    if (other.NewIsFIXAccessEnabled != null) {
      this.NewIsFIXAccessEnabled = other.NewIsFIXAccessEnabled
    } else {
      this.NewIsFIXAccessEnabled = null
    }
    if (other.NewMaxLeverage != null) {
      this.NewMaxLeverage = other.NewMaxLeverage
    } else {
      this.NewMaxLeverage = null
    }
    if (other.NewMaxLeverageReset != null) {
      this.NewMaxLeverageReset = other.NewMaxLeverageReset
    } else {
      this.NewMaxLeverageReset = null
    }
    if (other.NewLeverageProfileId != null) {
      this.NewLeverageProfileId = new UUID(other.NewLeverageProfileId)
    } else {
      this.NewLeverageProfileId = null
    }
    if (other.NewLeverageProfileIdReset != null) {
      this.NewLeverageProfileIdReset = other.NewLeverageProfileIdReset
    } else {
      this.NewLeverageProfileIdReset = null
    }
    if (other.SymbolGroupSettingsToDelete != null) {
      this.SymbolGroupSettingsToDelete = new Set()
      for (let item of other.SymbolGroupSettingsToDelete) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.SymbolGroupSettingsToDelete.add(tempItem)
        } else {
          this.SymbolGroupSettingsToDelete.add(null)
        }
      }
    } else {
      this.SymbolGroupSettingsToDelete = null
    }
    if (other.NewDefaultMaxOrders != null) {
      this.NewDefaultMaxOrders = UInt64.fromNumber(other.NewDefaultMaxOrders)
    } else {
      this.NewDefaultMaxOrders = null
    }
    if (other.NewMaxDepositOperationAmount != null) {
      this.NewMaxDepositOperationAmount = other.NewMaxDepositOperationAmount
    } else {
      this.NewMaxDepositOperationAmount = null
    }
    if (other.NewMaxDepositOperationAmountReset != null) {
      this.NewMaxDepositOperationAmountReset = other.NewMaxDepositOperationAmountReset
    } else {
      this.NewMaxDepositOperationAmountReset = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountGroupModifyRequest}
   * @returns {!AccountGroupModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountGroupModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountGroupModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountGroupModifyRequest}
   * @param {!AccountGroupModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountGroupModifyRequest)) {
      throw new TypeError('Instance of AccountGroupModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountGroupModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      AccountGroupName: ((this.AccountGroupName != null) ? this.AccountGroupName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewPlatformId: ((this.NewPlatformId != null) ? this.NewPlatformId.toString() : null),
      NewPlatformName: ((this.NewPlatformName != null) ? this.NewPlatformName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewIsDemo: ((this.NewIsDemo != null) ? this.NewIsDemo : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewIsOTPEnabled: ((this.NewIsOTPEnabled != null) ? this.NewIsOTPEnabled : null),
      NewIsTradeEnabled: ((this.NewIsTradeEnabled != null) ? this.NewIsTradeEnabled : null),
      NewDefaultAccountingType: ((this.NewDefaultAccountingType != null) ? this.NewDefaultAccountingType : null),
      NewDefaultBalanceCurrencyId: ((this.NewDefaultBalanceCurrencyId != null) ? this.NewDefaultBalanceCurrencyId.toString() : null),
      NewDefaultBalanceCurrencyName: ((this.NewDefaultBalanceCurrencyName != null) ? this.NewDefaultBalanceCurrencyName : null),
      NewDefaultBalance: ((this.NewDefaultBalance != null) ? this.NewDefaultBalance : null),
      NewDefaultCredit: ((this.NewDefaultCredit != null) ? this.NewDefaultCredit : null),
      NewDefaultLeverage: ((this.NewDefaultLeverage != null) ? this.NewDefaultLeverage : null),
      NewMarginCallLevel: ((this.NewMarginCallLevel != null) ? this.NewMarginCallLevel : null),
      NewMarginCallAccessType: ((this.NewMarginCallAccessType != null) ? this.NewMarginCallAccessType : null),
      NewStopOutLevel: ((this.NewStopOutLevel != null) ? this.NewStopOutLevel : null),
      NewStopOutType: ((this.NewStopOutType != null) ? this.NewStopOutType : null),
      NewStopOutAccessType: ((this.NewStopOutAccessType != null) ? this.NewStopOutAccessType : null),
      NewDefaultMaxPendingOrders: ((this.NewDefaultMaxPendingOrders != null) ? this.NewDefaultMaxPendingOrders.toNumber() : null),
      SymbolGroupSettingsToModify: ((this.SymbolGroupSettingsToModify != null) ? fbe.MapToObject(new Map(Array.from(this.SymbolGroupSettingsToModify, item => [((item[0] != null) ? item[0].toString() : null), ((item[1] != null) ? item[1] : null)]))) : null),
      NewIsSwapEnabled: ((this.NewIsSwapEnabled != null) ? this.NewIsSwapEnabled : null),
      NewMinPasswordLength: ((this.NewMinPasswordLength != null) ? this.NewMinPasswordLength : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1 : null),
      Deprecated2: ((this.Deprecated2 != null) ? this.Deprecated2 : null),
      NewIsSSLAccessEnabled: ((this.NewIsSSLAccessEnabled != null) ? this.NewIsSSLAccessEnabled : null),
      NewIsHTTPSAccessEnabled: ((this.NewIsHTTPSAccessEnabled != null) ? this.NewIsHTTPSAccessEnabled : null),
      NewIsWSSAccessEnabled: ((this.NewIsWSSAccessEnabled != null) ? this.NewIsWSSAccessEnabled : null),
      NewIsFIXAccessEnabled: ((this.NewIsFIXAccessEnabled != null) ? this.NewIsFIXAccessEnabled : null),
      NewMaxLeverage: ((this.NewMaxLeverage != null) ? this.NewMaxLeverage : null),
      NewMaxLeverageReset: ((this.NewMaxLeverageReset != null) ? this.NewMaxLeverageReset : null),
      NewLeverageProfileId: ((this.NewLeverageProfileId != null) ? this.NewLeverageProfileId.toString() : null),
      NewLeverageProfileIdReset: ((this.NewLeverageProfileIdReset != null) ? this.NewLeverageProfileIdReset : null),
      SymbolGroupSettingsToDelete: ((this.SymbolGroupSettingsToDelete != null) ? Array.from(this.SymbolGroupSettingsToDelete, item => ((item != null) ? item.toString() : null)) : null),
      NewDefaultMaxOrders: ((this.NewDefaultMaxOrders != null) ? this.NewDefaultMaxOrders.toNumber() : null),
      NewMaxDepositOperationAmount: ((this.NewMaxDepositOperationAmount != null) ? this.NewMaxDepositOperationAmount : null),
      NewMaxDepositOperationAmountReset: ((this.NewMaxDepositOperationAmountReset != null) ? this.NewMaxDepositOperationAmountReset : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountGroupModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountGroupModifyRequest} other Object value
   * @returns {!AccountGroupModifyRequest} Created struct
   */
  static fromObject (other) {
    return new AccountGroupModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountGroupModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountGroupModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountGroupModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6183
  }
}

export { AccountGroupModifyRequest };

/**
 * Fast Binary Encoding AccountGroupModifyRequest field model
 */
class FieldModelAccountGroupModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.AccountGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.AccountGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize), buffer, this.AccountGroupName.fbeOffset + this.AccountGroupName.fbeSize)
    this.NewPlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewPlatformName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewPlatformId.fbeOffset + this.NewPlatformId.fbeSize), buffer, this.NewPlatformId.fbeOffset + this.NewPlatformId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewPlatformName.fbeOffset + this.NewPlatformName.fbeSize), buffer, this.NewPlatformName.fbeOffset + this.NewPlatformName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewIsDemo = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsDemo.fbeOffset + this.NewIsDemo.fbeSize), buffer, this.NewIsDemo.fbeOffset + this.NewIsDemo.fbeSize)
    this.NewIsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.NewIsTradeEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsOTPEnabled.fbeOffset + this.NewIsOTPEnabled.fbeSize), buffer, this.NewIsOTPEnabled.fbeOffset + this.NewIsOTPEnabled.fbeSize)
    this.NewDefaultAccountingType = new fbe.FieldModelOptional(new FieldModelAccountingType(buffer, this.NewIsTradeEnabled.fbeOffset + this.NewIsTradeEnabled.fbeSize), buffer, this.NewIsTradeEnabled.fbeOffset + this.NewIsTradeEnabled.fbeSize)
    this.NewDefaultBalanceCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewDefaultAccountingType.fbeOffset + this.NewDefaultAccountingType.fbeSize), buffer, this.NewDefaultAccountingType.fbeOffset + this.NewDefaultAccountingType.fbeSize)
    this.NewDefaultBalanceCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDefaultBalanceCurrencyId.fbeOffset + this.NewDefaultBalanceCurrencyId.fbeSize), buffer, this.NewDefaultBalanceCurrencyId.fbeOffset + this.NewDefaultBalanceCurrencyId.fbeSize)
    this.NewDefaultBalance = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewDefaultBalanceCurrencyName.fbeOffset + this.NewDefaultBalanceCurrencyName.fbeSize), buffer, this.NewDefaultBalanceCurrencyName.fbeOffset + this.NewDefaultBalanceCurrencyName.fbeSize)
    this.NewDefaultCredit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewDefaultBalance.fbeOffset + this.NewDefaultBalance.fbeSize), buffer, this.NewDefaultBalance.fbeOffset + this.NewDefaultBalance.fbeSize)
    this.NewDefaultLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewDefaultCredit.fbeOffset + this.NewDefaultCredit.fbeSize), buffer, this.NewDefaultCredit.fbeOffset + this.NewDefaultCredit.fbeSize)
    this.NewMarginCallLevel = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewDefaultLeverage.fbeOffset + this.NewDefaultLeverage.fbeSize), buffer, this.NewDefaultLeverage.fbeOffset + this.NewDefaultLeverage.fbeSize)
    this.NewMarginCallAccessType = new fbe.FieldModelOptional(new FieldModelTradingMode(buffer, this.NewMarginCallLevel.fbeOffset + this.NewMarginCallLevel.fbeSize), buffer, this.NewMarginCallLevel.fbeOffset + this.NewMarginCallLevel.fbeSize)
    this.NewStopOutLevel = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewMarginCallAccessType.fbeOffset + this.NewMarginCallAccessType.fbeSize), buffer, this.NewMarginCallAccessType.fbeOffset + this.NewMarginCallAccessType.fbeSize)
    this.NewStopOutType = new fbe.FieldModelOptional(new FieldModelStopOutType(buffer, this.NewStopOutLevel.fbeOffset + this.NewStopOutLevel.fbeSize), buffer, this.NewStopOutLevel.fbeOffset + this.NewStopOutLevel.fbeSize)
    this.NewStopOutAccessType = new fbe.FieldModelOptional(new FieldModelTradingMode(buffer, this.NewStopOutType.fbeOffset + this.NewStopOutType.fbeSize), buffer, this.NewStopOutType.fbeOffset + this.NewStopOutType.fbeSize)
    this.NewDefaultMaxPendingOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewStopOutAccessType.fbeOffset + this.NewStopOutAccessType.fbeSize), buffer, this.NewStopOutAccessType.fbeOffset + this.NewStopOutAccessType.fbeSize)
    this.SymbolGroupSettingsToModify = new fbe.FieldModelMap(new fbe.FieldModelUUID(buffer, this.NewDefaultMaxPendingOrders.fbeOffset + this.NewDefaultMaxPendingOrders.fbeSize), new FieldModelSymbolGroupSettings(buffer, this.NewDefaultMaxPendingOrders.fbeOffset + this.NewDefaultMaxPendingOrders.fbeSize), buffer, this.NewDefaultMaxPendingOrders.fbeOffset + this.NewDefaultMaxPendingOrders.fbeSize)
    this.NewIsSwapEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.SymbolGroupSettingsToModify.fbeOffset + this.SymbolGroupSettingsToModify.fbeSize), buffer, this.SymbolGroupSettingsToModify.fbeOffset + this.SymbolGroupSettingsToModify.fbeSize)
    this.NewMinPasswordLength = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewIsSwapEnabled.fbeOffset + this.NewIsSwapEnabled.fbeSize), buffer, this.NewIsSwapEnabled.fbeOffset + this.NewIsSwapEnabled.fbeSize)
    this.Deprecated1 = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewMinPasswordLength.fbeOffset + this.NewMinPasswordLength.fbeSize), buffer, this.NewMinPasswordLength.fbeOffset + this.NewMinPasswordLength.fbeSize)
    this.Deprecated2 = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize), buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.NewIsSSLAccessEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Deprecated2.fbeOffset + this.Deprecated2.fbeSize), buffer, this.Deprecated2.fbeOffset + this.Deprecated2.fbeSize)
    this.NewIsHTTPSAccessEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsSSLAccessEnabled.fbeOffset + this.NewIsSSLAccessEnabled.fbeSize), buffer, this.NewIsSSLAccessEnabled.fbeOffset + this.NewIsSSLAccessEnabled.fbeSize)
    this.NewIsWSSAccessEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsHTTPSAccessEnabled.fbeOffset + this.NewIsHTTPSAccessEnabled.fbeSize), buffer, this.NewIsHTTPSAccessEnabled.fbeOffset + this.NewIsHTTPSAccessEnabled.fbeSize)
    this.NewIsFIXAccessEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsWSSAccessEnabled.fbeOffset + this.NewIsWSSAccessEnabled.fbeSize), buffer, this.NewIsWSSAccessEnabled.fbeOffset + this.NewIsWSSAccessEnabled.fbeSize)
    this.NewMaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewIsFIXAccessEnabled.fbeOffset + this.NewIsFIXAccessEnabled.fbeSize), buffer, this.NewIsFIXAccessEnabled.fbeOffset + this.NewIsFIXAccessEnabled.fbeSize)
    this.NewMaxLeverageReset = new fbe.FieldModelBool(buffer, this.NewMaxLeverage.fbeOffset + this.NewMaxLeverage.fbeSize)
    this.NewLeverageProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewMaxLeverageReset.fbeOffset + this.NewMaxLeverageReset.fbeSize), buffer, this.NewMaxLeverageReset.fbeOffset + this.NewMaxLeverageReset.fbeSize)
    this.NewLeverageProfileIdReset = new fbe.FieldModelBool(buffer, this.NewLeverageProfileId.fbeOffset + this.NewLeverageProfileId.fbeSize)
    this.SymbolGroupSettingsToDelete = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.NewLeverageProfileIdReset.fbeOffset + this.NewLeverageProfileIdReset.fbeSize), buffer, this.NewLeverageProfileIdReset.fbeOffset + this.NewLeverageProfileIdReset.fbeSize)
    this.NewDefaultMaxOrders = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.SymbolGroupSettingsToDelete.fbeOffset + this.SymbolGroupSettingsToDelete.fbeSize), buffer, this.SymbolGroupSettingsToDelete.fbeOffset + this.SymbolGroupSettingsToDelete.fbeSize)
    this.NewMaxDepositOperationAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewDefaultMaxOrders.fbeOffset + this.NewDefaultMaxOrders.fbeSize), buffer, this.NewDefaultMaxOrders.fbeOffset + this.NewDefaultMaxOrders.fbeSize)
    this.NewMaxDepositOperationAmountReset = new fbe.FieldModelBool(buffer, this.NewMaxDepositOperationAmount.fbeOffset + this.NewMaxDepositOperationAmount.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.AccountGroupId.fbeSize + this.AccountGroupName.fbeSize + this.NewSortOrder.fbeSize + this.NewPlatformId.fbeSize + this.NewPlatformName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewIsDemo.fbeSize + this.NewIsEnabled.fbeSize + this.NewIsOTPEnabled.fbeSize + this.NewIsTradeEnabled.fbeSize + this.NewDefaultAccountingType.fbeSize + this.NewDefaultBalanceCurrencyId.fbeSize + this.NewDefaultBalanceCurrencyName.fbeSize + this.NewDefaultBalance.fbeSize + this.NewDefaultCredit.fbeSize + this.NewDefaultLeverage.fbeSize + this.NewMarginCallLevel.fbeSize + this.NewMarginCallAccessType.fbeSize + this.NewStopOutLevel.fbeSize + this.NewStopOutType.fbeSize + this.NewStopOutAccessType.fbeSize + this.NewDefaultMaxPendingOrders.fbeSize + this.SymbolGroupSettingsToModify.fbeSize + this.NewIsSwapEnabled.fbeSize + this.NewMinPasswordLength.fbeSize + this.Deprecated1.fbeSize + this.Deprecated2.fbeSize + this.NewIsSSLAccessEnabled.fbeSize + this.NewIsHTTPSAccessEnabled.fbeSize + this.NewIsWSSAccessEnabled.fbeSize + this.NewIsFIXAccessEnabled.fbeSize + this.NewMaxLeverage.fbeSize + this.NewMaxLeverageReset.fbeSize + this.NewLeverageProfileId.fbeSize + this.NewLeverageProfileIdReset.fbeSize + this.SymbolGroupSettingsToDelete.fbeSize + this.NewDefaultMaxOrders.fbeSize + this.NewMaxDepositOperationAmount.fbeSize + this.NewMaxDepositOperationAmountReset.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountGroupName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewPlatformId.fbeExtra + this.NewPlatformName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewIsDemo.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewIsOTPEnabled.fbeExtra + this.NewIsTradeEnabled.fbeExtra + this.NewDefaultAccountingType.fbeExtra + this.NewDefaultBalanceCurrencyId.fbeExtra + this.NewDefaultBalanceCurrencyName.fbeExtra + this.NewDefaultBalance.fbeExtra + this.NewDefaultCredit.fbeExtra + this.NewDefaultLeverage.fbeExtra + this.NewMarginCallLevel.fbeExtra + this.NewMarginCallAccessType.fbeExtra + this.NewStopOutLevel.fbeExtra + this.NewStopOutType.fbeExtra + this.NewStopOutAccessType.fbeExtra + this.NewDefaultMaxPendingOrders.fbeExtra + this.SymbolGroupSettingsToModify.fbeExtra + this.NewIsSwapEnabled.fbeExtra + this.NewMinPasswordLength.fbeExtra + this.Deprecated1.fbeExtra + this.Deprecated2.fbeExtra + this.NewIsSSLAccessEnabled.fbeExtra + this.NewIsHTTPSAccessEnabled.fbeExtra + this.NewIsWSSAccessEnabled.fbeExtra + this.NewIsFIXAccessEnabled.fbeExtra + this.NewMaxLeverage.fbeExtra + this.NewMaxLeverageReset.fbeExtra + this.NewLeverageProfileId.fbeExtra + this.NewLeverageProfileIdReset.fbeExtra + this.SymbolGroupSettingsToDelete.fbeExtra + this.NewDefaultMaxOrders.fbeExtra + this.NewMaxDepositOperationAmount.fbeExtra + this.NewMaxDepositOperationAmountReset.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountGroupModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6183
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewPlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformId.fbeSize

    if ((fbeCurrentSize + this.NewPlatformName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPlatformName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsDemo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsDemo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDemo.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.NewDefaultAccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultAccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultAccountingType.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultBalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalanceCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultBalanceCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalance.fbeSize

    if ((fbeCurrentSize + this.NewDefaultCredit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultCredit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultCredit.fbeSize

    if ((fbeCurrentSize + this.NewDefaultLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMarginCallLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCallLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.NewMarginCallAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCallAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.NewStopOutLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopOutLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutLevel.fbeSize

    if ((fbeCurrentSize + this.NewStopOutType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopOutType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutType.fbeSize

    if ((fbeCurrentSize + this.NewStopOutAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopOutAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.NewDefaultMaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultMaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettingsToModify.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupSettingsToModify.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettingsToModify.fbeSize

    if ((fbeCurrentSize + this.NewIsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.NewMinPasswordLength.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMinPasswordLength.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMinPasswordLength.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated2.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.NewIsSSLAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsSSLAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSSLAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsHTTPSAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsHTTPSAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsHTTPSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsWSSAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsWSSAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsWSSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsFIXAccessEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsFIXAccessEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsFIXAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverageReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxLeverageReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.NewLeverageProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLeverageProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.NewLeverageProfileIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLeverageProfileIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageProfileIdReset.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettingsToDelete.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupSettingsToDelete.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettingsToDelete.fbeSize

    if ((fbeCurrentSize + this.NewDefaultMaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultMaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultMaxOrders.fbeSize

    if ((fbeCurrentSize + this.NewMaxDepositOperationAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxDepositOperationAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxDepositOperationAmount.fbeSize

    if ((fbeCurrentSize + this.NewMaxDepositOperationAmountReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxDepositOperationAmountReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxDepositOperationAmountReset.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!AccountGroupModifyRequest} fbeValue Default value, defaults is new AccountGroupModifyRequest()
   * @returns {!AccountGroupModifyRequest} AccountGroupModifyRequest value
   */
  get (fbeValue = new AccountGroupModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!AccountGroupModifyRequest} fbeValue AccountGroupModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupName = this.AccountGroupName.get()
    } else {
      fbeValue.AccountGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewPlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.NewPlatformId = this.NewPlatformId.get()
    } else {
      fbeValue.NewPlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformId.fbeSize

    if ((fbeCurrentSize + this.NewPlatformName.fbeSize) <= fbeStructSize) {
      fbeValue.NewPlatformName = this.NewPlatformName.get()
    } else {
      fbeValue.NewPlatformName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewIsDemo.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsDemo = this.NewIsDemo.get()
    } else {
      fbeValue.NewIsDemo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsDemo.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsOTPEnabled = this.NewIsOTPEnabled.get()
    } else {
      fbeValue.NewIsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradeEnabled = this.NewIsTradeEnabled.get()
    } else {
      fbeValue.NewIsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.NewDefaultAccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultAccountingType = this.NewDefaultAccountingType.get()
    } else {
      fbeValue.NewDefaultAccountingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultAccountingType.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultBalanceCurrencyId = this.NewDefaultBalanceCurrencyId.get()
    } else {
      fbeValue.NewDefaultBalanceCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalanceCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultBalanceCurrencyName = this.NewDefaultBalanceCurrencyName.get()
    } else {
      fbeValue.NewDefaultBalanceCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewDefaultBalance.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultBalance = this.NewDefaultBalance.get()
    } else {
      fbeValue.NewDefaultBalance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultBalance.fbeSize

    if ((fbeCurrentSize + this.NewDefaultCredit.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultCredit = this.NewDefaultCredit.get()
    } else {
      fbeValue.NewDefaultCredit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultCredit.fbeSize

    if ((fbeCurrentSize + this.NewDefaultLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultLeverage = this.NewDefaultLeverage.get()
    } else {
      fbeValue.NewDefaultLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMarginCallLevel.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCallLevel = this.NewMarginCallLevel.get()
    } else {
      fbeValue.NewMarginCallLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.NewMarginCallAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCallAccessType = this.NewMarginCallAccessType.get()
    } else {
      fbeValue.NewMarginCallAccessType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.NewStopOutLevel.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopOutLevel = this.NewStopOutLevel.get()
    } else {
      fbeValue.NewStopOutLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutLevel.fbeSize

    if ((fbeCurrentSize + this.NewStopOutType.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopOutType = this.NewStopOutType.get()
    } else {
      fbeValue.NewStopOutType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutType.fbeSize

    if ((fbeCurrentSize + this.NewStopOutAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopOutAccessType = this.NewStopOutAccessType.get()
    } else {
      fbeValue.NewStopOutAccessType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.NewDefaultMaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultMaxPendingOrders = this.NewDefaultMaxPendingOrders.get()
    } else {
      fbeValue.NewDefaultMaxPendingOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultMaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettingsToModify.fbeSize) <= fbeStructSize) {
      this.SymbolGroupSettingsToModify.get(fbeValue.SymbolGroupSettingsToModify)
    } else {
      fbeValue.SymbolGroupSettingsToModify.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettingsToModify.fbeSize

    if ((fbeCurrentSize + this.NewIsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsSwapEnabled = this.NewIsSwapEnabled.get()
    } else {
      fbeValue.NewIsSwapEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.NewMinPasswordLength.fbeSize) <= fbeStructSize) {
      fbeValue.NewMinPasswordLength = this.NewMinPasswordLength.get()
    } else {
      fbeValue.NewMinPasswordLength = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMinPasswordLength.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated2 = this.Deprecated2.get()
    } else {
      fbeValue.Deprecated2 = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.NewIsSSLAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsSSLAccessEnabled = this.NewIsSSLAccessEnabled.get()
    } else {
      fbeValue.NewIsSSLAccessEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSSLAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsHTTPSAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsHTTPSAccessEnabled = this.NewIsHTTPSAccessEnabled.get()
    } else {
      fbeValue.NewIsHTTPSAccessEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsHTTPSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsWSSAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsWSSAccessEnabled = this.NewIsWSSAccessEnabled.get()
    } else {
      fbeValue.NewIsWSSAccessEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsWSSAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsFIXAccessEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsFIXAccessEnabled = this.NewIsFIXAccessEnabled.get()
    } else {
      fbeValue.NewIsFIXAccessEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsFIXAccessEnabled.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxLeverage = this.NewMaxLeverage.get()
    } else {
      fbeValue.NewMaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverage.fbeSize

    if ((fbeCurrentSize + this.NewMaxLeverageReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxLeverageReset = this.NewMaxLeverageReset.get()
    } else {
      fbeValue.NewMaxLeverageReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.NewLeverageProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.NewLeverageProfileId = this.NewLeverageProfileId.get()
    } else {
      fbeValue.NewLeverageProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.NewLeverageProfileIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewLeverageProfileIdReset = this.NewLeverageProfileIdReset.get()
    } else {
      fbeValue.NewLeverageProfileIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageProfileIdReset.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupSettingsToDelete.fbeSize) <= fbeStructSize) {
      this.SymbolGroupSettingsToDelete.get(fbeValue.SymbolGroupSettingsToDelete)
    } else {
      fbeValue.SymbolGroupSettingsToDelete.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupSettingsToDelete.fbeSize

    if ((fbeCurrentSize + this.NewDefaultMaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultMaxOrders = this.NewDefaultMaxOrders.get()
    } else {
      fbeValue.NewDefaultMaxOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultMaxOrders.fbeSize

    if ((fbeCurrentSize + this.NewMaxDepositOperationAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxDepositOperationAmount = this.NewMaxDepositOperationAmount.get()
    } else {
      fbeValue.NewMaxDepositOperationAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxDepositOperationAmount.fbeSize

    if ((fbeCurrentSize + this.NewMaxDepositOperationAmountReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxDepositOperationAmountReset = this.NewMaxDepositOperationAmountReset.get()
    } else {
      fbeValue.NewMaxDepositOperationAmountReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxDepositOperationAmountReset.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountGroupModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {!AccountGroupModifyRequest} fbeValue AccountGroupModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountGroupModifyRequest}
   * @param {AccountGroupModifyRequest} fbeValue AccountGroupModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountGroupName.set(fbeValue.AccountGroupName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewPlatformId.set(fbeValue.NewPlatformId)
    this.NewPlatformName.set(fbeValue.NewPlatformName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewIsDemo.set(fbeValue.NewIsDemo)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewIsOTPEnabled.set(fbeValue.NewIsOTPEnabled)
    this.NewIsTradeEnabled.set(fbeValue.NewIsTradeEnabled)
    this.NewDefaultAccountingType.set(fbeValue.NewDefaultAccountingType)
    this.NewDefaultBalanceCurrencyId.set(fbeValue.NewDefaultBalanceCurrencyId)
    this.NewDefaultBalanceCurrencyName.set(fbeValue.NewDefaultBalanceCurrencyName)
    this.NewDefaultBalance.set(fbeValue.NewDefaultBalance)
    this.NewDefaultCredit.set(fbeValue.NewDefaultCredit)
    this.NewDefaultLeverage.set(fbeValue.NewDefaultLeverage)
    this.NewMarginCallLevel.set(fbeValue.NewMarginCallLevel)
    this.NewMarginCallAccessType.set(fbeValue.NewMarginCallAccessType)
    this.NewStopOutLevel.set(fbeValue.NewStopOutLevel)
    this.NewStopOutType.set(fbeValue.NewStopOutType)
    this.NewStopOutAccessType.set(fbeValue.NewStopOutAccessType)
    this.NewDefaultMaxPendingOrders.set(fbeValue.NewDefaultMaxPendingOrders)
    this.SymbolGroupSettingsToModify.set(fbeValue.SymbolGroupSettingsToModify)
    this.NewIsSwapEnabled.set(fbeValue.NewIsSwapEnabled)
    this.NewMinPasswordLength.set(fbeValue.NewMinPasswordLength)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.Deprecated2.set(fbeValue.Deprecated2)
    this.NewIsSSLAccessEnabled.set(fbeValue.NewIsSSLAccessEnabled)
    this.NewIsHTTPSAccessEnabled.set(fbeValue.NewIsHTTPSAccessEnabled)
    this.NewIsWSSAccessEnabled.set(fbeValue.NewIsWSSAccessEnabled)
    this.NewIsFIXAccessEnabled.set(fbeValue.NewIsFIXAccessEnabled)
    this.NewMaxLeverage.set(fbeValue.NewMaxLeverage)
    this.NewMaxLeverageReset.set(fbeValue.NewMaxLeverageReset)
    this.NewLeverageProfileId.set(fbeValue.NewLeverageProfileId)
    this.NewLeverageProfileIdReset.set(fbeValue.NewLeverageProfileIdReset)
    this.SymbolGroupSettingsToDelete.set(fbeValue.SymbolGroupSettingsToDelete)
    this.NewDefaultMaxOrders.set(fbeValue.NewDefaultMaxOrders)
    this.NewMaxDepositOperationAmount.set(fbeValue.NewMaxDepositOperationAmount)
    this.NewMaxDepositOperationAmountReset.set(fbeValue.NewMaxDepositOperationAmountReset)
  }
}

export { FieldModelAccountGroupModifyRequest };

/**
 * Fast Binary Encoding AccountGroupModifyRequest model
 */
class AccountGroupModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountGroupModifyRequest(this.buffer, 4)
  }

  /**
   * Get the AccountGroupModifyRequest model
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!FieldModelAccountGroupModifyRequest} model AccountGroupModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountGroupModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountGroupModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountGroupModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountGroupModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountGroupModifyRequestModel}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountGroupModifyRequestModel}
   * @param {!AccountGroupModifyRequest} value AccountGroupModifyRequest value, defaults is new AccountGroupModifyRequest()
   * @return {!object} Deserialized AccountGroupModifyRequest value and its size
   */
  deserialize (value = new AccountGroupModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountGroupModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountGroupModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountGroupModifyRequestModel}
   * @param {!number} prev Previous AccountGroupModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountGroupModifyRequestModel };
AccountGroupModifyRequest.__has_response = true;
AccountGroupModifyRequest.__response_class = AccountGroupModifyResponse;
