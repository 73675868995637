
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {GatewayExecutionType} from './GatewayExecutionType';
import {FieldModelGatewayExecutionType} from './GatewayExecutionType';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';

/**
 * GatewayOrderExecutionResponse struct
 */
class GatewayOrderExecutionResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!UUID=} gatewayId
   * @param {!string=} gatewayClientOrderId
   * @param {!string=} gatewayOrderId
   * @param {!string=} gatewayExecutionId
   * @param {!GatewayExecutionType=} executionType
   * @param {number=} lastVolume
   * @param {number=} lastPrice
   * @param {number=} averagePrice
   * @param {string=} reason
   * @param {!OrderSide=} orderSide
   * @param {number=} totalExecutedVolume
   * @param {number=} remainingVolume
   * @constructor
   */
  constructor (parent = new Response(), gatewayId = new UUID(), gatewayClientOrderId = '', gatewayOrderId = '', gatewayExecutionId = '', executionType = new GatewayExecutionType(), lastVolume = null, lastPrice = null, averagePrice = null, reason = null, orderSide = new OrderSide(), totalExecutedVolume = null, remainingVolume = null) {
    super()
    super.copy(parent)
    this.GatewayId = gatewayId
    this.GatewayClientOrderId = gatewayClientOrderId
    this.GatewayOrderId = gatewayOrderId
    this.GatewayExecutionId = gatewayExecutionId
    this.ExecutionType = executionType
    this.LastVolume = lastVolume
    this.LastPrice = lastPrice
    this.AveragePrice = averagePrice
    this.Reason = reason
    this.OrderSide = orderSide
    this.TotalExecutedVolume = totalExecutedVolume
    this.RemainingVolume = remainingVolume
  }

  /**
   * Copy struct (shallow copy)
   * @this {!GatewayOrderExecutionResponse}
   * @param {!GatewayOrderExecutionResponse} other Other struct
   * @returns {!GatewayOrderExecutionResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.GatewayId != null) {
      this.GatewayId = new UUID(other.GatewayId)
    } else {
      this.GatewayId = null
    }
    if (other.GatewayClientOrderId != null) {
      this.GatewayClientOrderId = other.GatewayClientOrderId
    } else {
      this.GatewayClientOrderId = null
    }
    if (other.GatewayOrderId != null) {
      this.GatewayOrderId = other.GatewayOrderId
    } else {
      this.GatewayOrderId = null
    }
    if (other.GatewayExecutionId != null) {
      this.GatewayExecutionId = other.GatewayExecutionId
    } else {
      this.GatewayExecutionId = null
    }
    if (other.ExecutionType != null) {
      this.ExecutionType = GatewayExecutionType.fromObject(other.ExecutionType)
    } else {
      this.ExecutionType = null
    }
    if (other.LastVolume != null) {
      this.LastVolume = other.LastVolume
    } else {
      this.LastVolume = null
    }
    if (other.LastPrice != null) {
      this.LastPrice = other.LastPrice
    } else {
      this.LastPrice = null
    }
    if (other.AveragePrice != null) {
      this.AveragePrice = other.AveragePrice
    } else {
      this.AveragePrice = null
    }
    if (other.Reason != null) {
      this.Reason = other.Reason
    } else {
      this.Reason = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = OrderSide.fromObject(other.OrderSide)
    } else {
      this.OrderSide = null
    }
    if (other.TotalExecutedVolume != null) {
      this.TotalExecutedVolume = other.TotalExecutedVolume
    } else {
      this.TotalExecutedVolume = null
    }
    if (other.RemainingVolume != null) {
      this.RemainingVolume = other.RemainingVolume
    } else {
      this.RemainingVolume = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!GatewayOrderExecutionResponse}
   * @returns {!GatewayOrderExecutionResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new GatewayOrderExecutionResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new GatewayOrderExecutionResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!GatewayOrderExecutionResponse}
   * @param {!GatewayOrderExecutionResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof GatewayOrderExecutionResponse)) {
      throw new TypeError('Instance of GatewayOrderExecutionResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!GatewayOrderExecutionResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      GatewayId: ((this.GatewayId != null) ? this.GatewayId.toString() : null),
      GatewayClientOrderId: ((this.GatewayClientOrderId != null) ? this.GatewayClientOrderId : null),
      GatewayOrderId: ((this.GatewayOrderId != null) ? this.GatewayOrderId : null),
      GatewayExecutionId: ((this.GatewayExecutionId != null) ? this.GatewayExecutionId : null),
      ExecutionType: ((this.ExecutionType != null) ? this.ExecutionType : null),
      LastVolume: ((this.LastVolume != null) ? this.LastVolume : null),
      LastPrice: ((this.LastPrice != null) ? this.LastPrice : null),
      AveragePrice: ((this.AveragePrice != null) ? this.AveragePrice : null),
      Reason: ((this.Reason != null) ? this.Reason : null),
      OrderSide: ((this.OrderSide != null) ? this.OrderSide : null),
      TotalExecutedVolume: ((this.TotalExecutedVolume != null) ? this.TotalExecutedVolume : null),
      RemainingVolume: ((this.RemainingVolume != null) ? this.RemainingVolume : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return GatewayOrderExecutionResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!GatewayOrderExecutionResponse} other Object value
   * @returns {!GatewayOrderExecutionResponse} Created struct
   */
  static fromObject (other) {
    return new GatewayOrderExecutionResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!GatewayOrderExecutionResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return GatewayOrderExecutionResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!GatewayOrderExecutionResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 8012
  }
}

export { GatewayOrderExecutionResponse };

/**
 * Fast Binary Encoding GatewayOrderExecutionResponse field model
 */
class FieldModelGatewayOrderExecutionResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.GatewayId = new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.GatewayClientOrderId = new fbe.FieldModelString(buffer, this.GatewayId.fbeOffset + this.GatewayId.fbeSize)
    this.GatewayOrderId = new fbe.FieldModelString(buffer, this.GatewayClientOrderId.fbeOffset + this.GatewayClientOrderId.fbeSize)
    this.GatewayExecutionId = new fbe.FieldModelString(buffer, this.GatewayOrderId.fbeOffset + this.GatewayOrderId.fbeSize)
    this.ExecutionType = new FieldModelGatewayExecutionType(buffer, this.GatewayExecutionId.fbeOffset + this.GatewayExecutionId.fbeSize)
    this.LastVolume = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ExecutionType.fbeOffset + this.ExecutionType.fbeSize), buffer, this.ExecutionType.fbeOffset + this.ExecutionType.fbeSize)
    this.LastPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.LastVolume.fbeOffset + this.LastVolume.fbeSize), buffer, this.LastVolume.fbeOffset + this.LastVolume.fbeSize)
    this.AveragePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.LastPrice.fbeOffset + this.LastPrice.fbeSize), buffer, this.LastPrice.fbeOffset + this.LastPrice.fbeSize)
    this.Reason = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AveragePrice.fbeOffset + this.AveragePrice.fbeSize), buffer, this.AveragePrice.fbeOffset + this.AveragePrice.fbeSize)
    this.OrderSide = new FieldModelOrderSide(buffer, this.Reason.fbeOffset + this.Reason.fbeSize)
    this.TotalExecutedVolume = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize), buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.RemainingVolume = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalExecutedVolume.fbeOffset + this.TotalExecutedVolume.fbeSize), buffer, this.TotalExecutedVolume.fbeOffset + this.TotalExecutedVolume.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.GatewayId.fbeSize + this.GatewayClientOrderId.fbeSize + this.GatewayOrderId.fbeSize + this.GatewayExecutionId.fbeSize + this.ExecutionType.fbeSize + this.LastVolume.fbeSize + this.LastPrice.fbeSize + this.AveragePrice.fbeSize + this.Reason.fbeSize + this.OrderSide.fbeSize + this.TotalExecutedVolume.fbeSize + this.RemainingVolume.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.GatewayId.fbeExtra + this.GatewayClientOrderId.fbeExtra + this.GatewayOrderId.fbeExtra + this.GatewayExecutionId.fbeExtra + this.ExecutionType.fbeExtra + this.LastVolume.fbeExtra + this.LastPrice.fbeExtra + this.AveragePrice.fbeExtra + this.Reason.fbeExtra + this.OrderSide.fbeExtra + this.TotalExecutedVolume.fbeExtra + this.RemainingVolume.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelGatewayOrderExecutionResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 8012
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.GatewayId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.GatewayClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayClientOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayExecutionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayExecutionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayExecutionId.fbeSize

    if ((fbeCurrentSize + this.ExecutionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExecutionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutionType.fbeSize

    if ((fbeCurrentSize + this.LastVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastVolume.fbeSize

    if ((fbeCurrentSize + this.LastPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastPrice.fbeSize

    if ((fbeCurrentSize + this.AveragePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AveragePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AveragePrice.fbeSize

    if ((fbeCurrentSize + this.Reason.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Reason.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reason.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.TotalExecutedVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalExecutedVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalExecutedVolume.fbeSize

    if ((fbeCurrentSize + this.RemainingVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RemainingVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingVolume.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!GatewayOrderExecutionResponse} fbeValue Default value, defaults is new GatewayOrderExecutionResponse()
   * @returns {!GatewayOrderExecutionResponse} GatewayOrderExecutionResponse value
   */
  get (fbeValue = new GatewayOrderExecutionResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!GatewayOrderExecutionResponse} fbeValue GatewayOrderExecutionResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.GatewayId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayId = this.GatewayId.get()
    } else {
      fbeValue.GatewayId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayId.fbeSize

    if ((fbeCurrentSize + this.GatewayClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayClientOrderId = this.GatewayClientOrderId.get()
    } else {
      fbeValue.GatewayClientOrderId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayClientOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayOrderId = this.GatewayOrderId.get()
    } else {
      fbeValue.GatewayOrderId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayOrderId.fbeSize

    if ((fbeCurrentSize + this.GatewayExecutionId.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayExecutionId = this.GatewayExecutionId.get()
    } else {
      fbeValue.GatewayExecutionId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayExecutionId.fbeSize

    if ((fbeCurrentSize + this.ExecutionType.fbeSize) <= fbeStructSize) {
      fbeValue.ExecutionType = this.ExecutionType.get()
    } else {
      fbeValue.ExecutionType = new GatewayExecutionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutionType.fbeSize

    if ((fbeCurrentSize + this.LastVolume.fbeSize) <= fbeStructSize) {
      fbeValue.LastVolume = this.LastVolume.get()
    } else {
      fbeValue.LastVolume = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastVolume.fbeSize

    if ((fbeCurrentSize + this.LastPrice.fbeSize) <= fbeStructSize) {
      fbeValue.LastPrice = this.LastPrice.get()
    } else {
      fbeValue.LastPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastPrice.fbeSize

    if ((fbeCurrentSize + this.AveragePrice.fbeSize) <= fbeStructSize) {
      fbeValue.AveragePrice = this.AveragePrice.get()
    } else {
      fbeValue.AveragePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AveragePrice.fbeSize

    if ((fbeCurrentSize + this.Reason.fbeSize) <= fbeStructSize) {
      fbeValue.Reason = this.Reason.get()
    } else {
      fbeValue.Reason = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reason.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSide = this.OrderSide.get()
    } else {
      fbeValue.OrderSide = new OrderSide()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.TotalExecutedVolume.fbeSize) <= fbeStructSize) {
      fbeValue.TotalExecutedVolume = this.TotalExecutedVolume.get()
    } else {
      fbeValue.TotalExecutedVolume = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalExecutedVolume.fbeSize

    if ((fbeCurrentSize + this.RemainingVolume.fbeSize) <= fbeStructSize) {
      fbeValue.RemainingVolume = this.RemainingVolume.get()
    } else {
      fbeValue.RemainingVolume = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingVolume.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {!GatewayOrderExecutionResponse} fbeValue GatewayOrderExecutionResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelGatewayOrderExecutionResponse}
   * @param {GatewayOrderExecutionResponse} fbeValue GatewayOrderExecutionResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.GatewayId.set(fbeValue.GatewayId)
    this.GatewayClientOrderId.set(fbeValue.GatewayClientOrderId)
    this.GatewayOrderId.set(fbeValue.GatewayOrderId)
    this.GatewayExecutionId.set(fbeValue.GatewayExecutionId)
    this.ExecutionType.set(fbeValue.ExecutionType)
    this.LastVolume.set(fbeValue.LastVolume)
    this.LastPrice.set(fbeValue.LastPrice)
    this.AveragePrice.set(fbeValue.AveragePrice)
    this.Reason.set(fbeValue.Reason)
    this.OrderSide.set(fbeValue.OrderSide)
    this.TotalExecutedVolume.set(fbeValue.TotalExecutedVolume)
    this.RemainingVolume.set(fbeValue.RemainingVolume)
  }
}

export { FieldModelGatewayOrderExecutionResponse };

/**
 * Fast Binary Encoding GatewayOrderExecutionResponse model
 */
class GatewayOrderExecutionResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelGatewayOrderExecutionResponse(this.buffer, 4)
  }

  /**
   * Get the GatewayOrderExecutionResponse model
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!FieldModelGatewayOrderExecutionResponse} model GatewayOrderExecutionResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return GatewayOrderExecutionResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelGatewayOrderExecutionResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!GatewayOrderExecutionResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!GatewayOrderExecutionResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!GatewayOrderExecutionResponseModel}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!GatewayOrderExecutionResponseModel}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse value, defaults is new GatewayOrderExecutionResponse()
   * @return {!object} Deserialized GatewayOrderExecutionResponse value and its size
   */
  deserialize (value = new GatewayOrderExecutionResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new GatewayOrderExecutionResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new GatewayOrderExecutionResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!GatewayOrderExecutionResponseModel}
   * @param {!number} prev Previous GatewayOrderExecutionResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { GatewayOrderExecutionResponseModel };
