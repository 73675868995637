import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { convertSecuritySettingToModel, SecurityProfileModel } from '@models/security/SecurityProfiles';
import { SecuritySettingsModifyRequest } from '@api/fbe/manager/SecuritySettingsModifyRequest';
import { SecuritySettingsGetAllRequest } from '@api/fbe/manager/SecuritySettingsGetAllRequest';
import { SecuritySettingsCreateRequest } from '@api/fbe/manager/SecuritySettingsCreateRequest';
import { SecuritySettingsDeleteRequest } from '@api/fbe/manager/SecuritySettingsDeleteRequest';

export class SecurityProfilesStore {
    errorTracker = new ErrorsTracker({ title: 'Security Profiles' });

    constructor() {
        makeAutoObservable(this);
    }

    data: SecurityProfileModel[] = [];

    isLoading = false;

    setData(value: SecurityProfileModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new SecuritySettingsModifyRequest();
            request.SecuritySettingsId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error("Can't find Security Profile to reorder");
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new SecuritySettingsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.SecuritySettings.map(convertSecuritySettingToModel));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newRecord: SecurityProfileModel) {
        const request = new SecuritySettingsCreateRequest();

        request.Name = newRecord.Name;
        request.Description = newRecord.Description;
        request.IsFirewallEnabled = newRecord.IsFirewallEnabled;
        request.IsAntifloodEnabled = newRecord.IsAntifloodEnabled;
        request.ConnectionRateThreshold = newRecord.ConnectionRateThreshold;
        request.FailedLoginRateThreshold = newRecord.FailedLoginRateThreshold;

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(updatedRecord: SecurityProfileModel, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new SecuritySettingsModifyRequest();

        request.SecuritySettingsId = UUID.fromString(updatedRecord.id);
        request.NewName = updatedRecord.Name;
        request.NewDescription = updatedRecord.Description;
        request.NewIsFirewallEnabled = updatedRecord.IsFirewallEnabled;
        request.NewIsAntifloodEnabled = updatedRecord.IsAntifloodEnabled;
        request.NewConnectionRateThreshold = updatedRecord.ConnectionRateThreshold;
        request.NewFailedLoginRateThreshold = updatedRecord.FailedLoginRateThreshold;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: UUID) {
        const request = new SecuritySettingsDeleteRequest();
        request.SecuritySettingsId = id;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const securityProfilesStore = new SecurityProfilesStore();
export { securityProfilesStore };
