import React, { useState } from 'react';
import styles from './ExportCSV.module.scss';
import { Message, MessageType } from '@components/Message/Message';
import { t, keyMap } from '@localization';
import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import { Button } from 'antd';

type Props = {
    getDataToExport: () => Record<string, string>[];
    isDisabled?: boolean;
    fileName?: string;
};

const ExportCSV = ({ getDataToExport, isDisabled, fileName = 'Export' }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const makeAnExport = (): Promise<void> =>
        new Promise((resolve) => {
            setTimeout(() => {
                const dataToExport = getDataToExport();

                const json2csvParser = new Parser({ delimiter: ',' });
                const csv = json2csvParser.parse(dataToExport);
    
                const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blob, `${fileName}.csv`);
                Message(MessageType.success, `${fileName}.csv`);
                return resolve();
            }, 500);
        });

    const clickHandler = (): void => {
        setIsLoading(true);
        makeAnExport().finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Button
            type="primary"
            className={styles.exportButton}
            disabled={isDisabled}
            onClick={clickHandler}
            loading={isLoading}
        >
            {t(keyMap.common.export)}
        </Button>
    );
};

export default ExportCSV;
