import { FeatureToggleForce } from "@api/fbe/core/FeatureToggleForce";

export type FeatureToggleForceModel = boolean | null;

export type FeaturesToggleForceModel = {
    MultipleDashboards: FeatureToggleForceModel;
};

export type FeaturesToggleModel = {
    MultipleDashboards: boolean;
    SocialTradingAccountsManipulation: boolean;
};

export const getFeatureToggleForceModel = (value: FeatureToggleForce): FeatureToggleForceModel => {
    if (value.eq(FeatureToggleForce.NONE)) return null;
    if (value.eq(FeatureToggleForce.FORCE_ENABLE)) return true;
    return false;
};

export const getFeatureToggleForce = (value: FeatureToggleForceModel): FeatureToggleForce => {
    if (value === null) return FeatureToggleForce.NONE;
    return value ? FeatureToggleForce.FORCE_ENABLE : FeatureToggleForce.FORCE_DISABLE;
};
