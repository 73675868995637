// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: feed.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FeedVersionRequest} from './feed/FeedVersionRequest'
import {FeedVersionRequestModel} from './feed/FeedVersionRequest'
import {FeedVersionResponse} from './feed/FeedVersionResponse'
import {FeedVersionResponseModel} from './feed/FeedVersionResponse'
import {FeedUpdateNotify} from './feed/FeedUpdateNotify'
import {FeedUpdateNotifyModel} from './feed/FeedUpdateNotify'
import {CurrencyGroupGetRequest} from './feed/CurrencyGroupGetRequest'
import {CurrencyGroupGetRequestModel} from './feed/CurrencyGroupGetRequest'
import {CurrencyGroupGetResponse} from './feed/CurrencyGroupGetResponse'
import {CurrencyGroupGetResponseModel} from './feed/CurrencyGroupGetResponse'
import {CurrencyGroupsGetAllRequest} from './feed/CurrencyGroupsGetAllRequest'
import {CurrencyGroupsGetAllRequestModel} from './feed/CurrencyGroupsGetAllRequest'
import {CurrencyGroupsGetAllResponse} from './feed/CurrencyGroupsGetAllResponse'
import {CurrencyGroupsGetAllResponseModel} from './feed/CurrencyGroupsGetAllResponse'
import {CurrencyGroupUpdateNotify} from './feed/CurrencyGroupUpdateNotify'
import {CurrencyGroupUpdateNotifyModel} from './feed/CurrencyGroupUpdateNotify'
import {CurrencyGetRequest} from './feed/CurrencyGetRequest'
import {CurrencyGetRequestModel} from './feed/CurrencyGetRequest'
import {CurrencyGetResponse} from './feed/CurrencyGetResponse'
import {CurrencyGetResponseModel} from './feed/CurrencyGetResponse'
import {CurrenciesGetAllRequest} from './feed/CurrenciesGetAllRequest'
import {CurrenciesGetAllRequestModel} from './feed/CurrenciesGetAllRequest'
import {CurrenciesGetAllResponse} from './feed/CurrenciesGetAllResponse'
import {CurrenciesGetAllResponseModel} from './feed/CurrenciesGetAllResponse'
import {CurrencyUpdateNotify} from './feed/CurrencyUpdateNotify'
import {CurrencyUpdateNotifyModel} from './feed/CurrencyUpdateNotify'
import {SymbolGroupGetRequest} from './feed/SymbolGroupGetRequest'
import {SymbolGroupGetRequestModel} from './feed/SymbolGroupGetRequest'
import {SymbolGroupGetResponse} from './feed/SymbolGroupGetResponse'
import {SymbolGroupGetResponseModel} from './feed/SymbolGroupGetResponse'
import {SymbolGroupsGetAllRequest} from './feed/SymbolGroupsGetAllRequest'
import {SymbolGroupsGetAllRequestModel} from './feed/SymbolGroupsGetAllRequest'
import {SymbolGroupsGetAllResponse} from './feed/SymbolGroupsGetAllResponse'
import {SymbolGroupsGetAllResponseModel} from './feed/SymbolGroupsGetAllResponse'
import {SymbolGroupUpdateNotify} from './feed/SymbolGroupUpdateNotify'
import {SymbolGroupUpdateNotifyModel} from './feed/SymbolGroupUpdateNotify'
import {SymbolGetRequest} from './feed/SymbolGetRequest'
import {SymbolGetRequestModel} from './feed/SymbolGetRequest'
import {SymbolGetResponse} from './feed/SymbolGetResponse'
import {SymbolGetResponseModel} from './feed/SymbolGetResponse'
import {SymbolsGetAllRequest} from './feed/SymbolsGetAllRequest'
import {SymbolsGetAllRequestModel} from './feed/SymbolsGetAllRequest'
import {SymbolsGetAllResponse} from './feed/SymbolsGetAllResponse'
import {SymbolsGetAllResponseModel} from './feed/SymbolsGetAllResponse'
import {SymbolUpdateNotify} from './feed/SymbolUpdateNotify'
import {SymbolUpdateNotifyModel} from './feed/SymbolUpdateNotify'
import {TickGetRequest} from './feed/TickGetRequest'
import {TickGetRequestModel} from './feed/TickGetRequest'
import {TickGetResponse} from './feed/TickGetResponse'
import {TickGetResponseModel} from './feed/TickGetResponse'
import {TickGetFilterRequest} from './feed/TickGetFilterRequest'
import {TickGetFilterRequestModel} from './feed/TickGetFilterRequest'
import {TickGetFilterResponse} from './feed/TickGetFilterResponse'
import {TickGetFilterResponseModel} from './feed/TickGetFilterResponse'
import {TickGetAllRequest} from './feed/TickGetAllRequest'
import {TickGetAllRequestModel} from './feed/TickGetAllRequest'
import {TickGetAllResponse} from './feed/TickGetAllResponse'
import {TickGetAllResponseModel} from './feed/TickGetAllResponse'
import {Level2GetRequest} from './feed/Level2GetRequest'
import {Level2GetRequestModel} from './feed/Level2GetRequest'
import {Level2GetResponse} from './feed/Level2GetResponse'
import {Level2GetResponseModel} from './feed/Level2GetResponse'
import {Level2GetFilterRequest} from './feed/Level2GetFilterRequest'
import {Level2GetFilterRequestModel} from './feed/Level2GetFilterRequest'
import {Level2GetFilterResponse} from './feed/Level2GetFilterResponse'
import {Level2GetFilterResponseModel} from './feed/Level2GetFilterResponse'
import {Level2GetAllRequest} from './feed/Level2GetAllRequest'
import {Level2GetAllRequestModel} from './feed/Level2GetAllRequest'
import {Level2GetAllResponse} from './feed/Level2GetAllResponse'
import {Level2GetAllResponseModel} from './feed/Level2GetAllResponse'
import {FeedSubscriptionGetRequest} from './feed/FeedSubscriptionGetRequest'
import {FeedSubscriptionGetRequestModel} from './feed/FeedSubscriptionGetRequest'
import {FeedSubscriptionGetResponse} from './feed/FeedSubscriptionGetResponse'
import {FeedSubscriptionGetResponseModel} from './feed/FeedSubscriptionGetResponse'
import {FeedSubscribeRequest} from './feed/FeedSubscribeRequest'
import {FeedSubscribeRequestModel} from './feed/FeedSubscribeRequest'
import {FeedSubscribeAllRequest} from './feed/FeedSubscribeAllRequest'
import {FeedSubscribeAllRequestModel} from './feed/FeedSubscribeAllRequest'
import {FeedSubscribeResponse} from './feed/FeedSubscribeResponse'
import {FeedSubscribeResponseModel} from './feed/FeedSubscribeResponse'
import {FeedUnsubscribeRequest} from './feed/FeedUnsubscribeRequest'
import {FeedUnsubscribeRequestModel} from './feed/FeedUnsubscribeRequest'
import {FeedUnsubscribeAllRequest} from './feed/FeedUnsubscribeAllRequest'
import {FeedUnsubscribeAllRequestModel} from './feed/FeedUnsubscribeAllRequest'
import {FeedUnsubscribeResponse} from './feed/FeedUnsubscribeResponse'
import {FeedUnsubscribeResponseModel} from './feed/FeedUnsubscribeResponse'
import {FeedUpdateTickSnapshotRequest} from './feed/FeedUpdateTickSnapshotRequest'
import {FeedUpdateTickSnapshotRequestModel} from './feed/FeedUpdateTickSnapshotRequest'
import {FeedUpdateTickRequest} from './feed/FeedUpdateTickRequest'
import {FeedUpdateTickRequestModel} from './feed/FeedUpdateTickRequest'
import {FeedUpdateLevel2SnapshotRequest} from './feed/FeedUpdateLevel2SnapshotRequest'
import {FeedUpdateLevel2SnapshotRequestModel} from './feed/FeedUpdateLevel2SnapshotRequest'
import {FeedUpdateLevel2Request} from './feed/FeedUpdateLevel2Request'
import {FeedUpdateLevel2RequestModel} from './feed/FeedUpdateLevel2Request'
import {FeedUpdateResponse} from './feed/FeedUpdateResponse'
import {FeedUpdateResponseModel} from './feed/FeedUpdateResponse'
import {TickSnapshotNotify} from './feed/TickSnapshotNotify'
import {TickSnapshotNotifyModel} from './feed/TickSnapshotNotify'
import {TickUpdateNotify} from './feed/TickUpdateNotify'
import {TickUpdateNotifyModel} from './feed/TickUpdateNotify'
import {Level2SnapshotNotify} from './feed/Level2SnapshotNotify'
import {Level2SnapshotNotifyModel} from './feed/Level2SnapshotNotify'
import {Level2UpdateNotify} from './feed/Level2UpdateNotify'
import {Level2UpdateNotifyModel} from './feed/Level2UpdateNotify'
import {DataFeedModulesGetRequest} from './feed/DataFeedModulesGetRequest'
import {DataFeedModulesGetRequestModel} from './feed/DataFeedModulesGetRequest'
import {DataFeedModulesGetResponse} from './feed/DataFeedModulesGetResponse'
import {DataFeedModulesGetResponseModel} from './feed/DataFeedModulesGetResponse'
import {DataFeedModuleUpdateNotify} from './feed/DataFeedModuleUpdateNotify'
import {DataFeedModuleUpdateNotifyModel} from './feed/DataFeedModuleUpdateNotify'
import {DataFeedSubscription} from './feed/DataFeedSubscription'
import {DataFeedSubscriptionModel} from './feed/DataFeedSubscription'
import {DataFeedStartRequest} from './feed/DataFeedStartRequest'
import {DataFeedStartRequestModel} from './feed/DataFeedStartRequest'
import {DataFeedStopRequest} from './feed/DataFeedStopRequest'
import {DataFeedStopRequestModel} from './feed/DataFeedStopRequest'
import {DataFeedShutdownRequest} from './feed/DataFeedShutdownRequest'
import {DataFeedShutdownRequestModel} from './feed/DataFeedShutdownRequest'
import {DataFeedUpdateSubscriptionsRequest} from './feed/DataFeedUpdateSubscriptionsRequest'
import {DataFeedUpdateSubscriptionsRequestModel} from './feed/DataFeedUpdateSubscriptionsRequest'
import {DataFeedStatusNotify} from './feed/DataFeedStatusNotify'
import {DataFeedStatusNotifyModel} from './feed/DataFeedStatusNotify'
import {FeedUpdateLevel2SnapshotNotify} from './feed/FeedUpdateLevel2SnapshotNotify'
import {FeedUpdateLevel2SnapshotNotifyModel} from './feed/FeedUpdateLevel2SnapshotNotify'
import {SymbolsUpdateNotify} from './feed/SymbolsUpdateNotify'
import {SymbolsUpdateNotifyModel} from './feed/SymbolsUpdateNotify'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding feed sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize feed sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._FeedVersionRequestModel = new FeedVersionRequestModel(this.buffer)
    this._FeedVersionResponseModel = new FeedVersionResponseModel(this.buffer)
    this._FeedUpdateNotifyModel = new FeedUpdateNotifyModel(this.buffer)
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel(this.buffer)
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel(this.buffer)
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel(this.buffer)
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel(this.buffer)
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel(this.buffer)
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel(this.buffer)
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel(this.buffer)
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel(this.buffer)
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel(this.buffer)
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel(this.buffer)
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel(this.buffer)
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel(this.buffer)
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel(this.buffer)
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel(this.buffer)
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel(this.buffer)
    this._SymbolGetRequestModel = new SymbolGetRequestModel(this.buffer)
    this._SymbolGetResponseModel = new SymbolGetResponseModel(this.buffer)
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel(this.buffer)
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel(this.buffer)
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel(this.buffer)
    this._TickGetRequestModel = new TickGetRequestModel(this.buffer)
    this._TickGetResponseModel = new TickGetResponseModel(this.buffer)
    this._TickGetFilterRequestModel = new TickGetFilterRequestModel(this.buffer)
    this._TickGetFilterResponseModel = new TickGetFilterResponseModel(this.buffer)
    this._TickGetAllRequestModel = new TickGetAllRequestModel(this.buffer)
    this._TickGetAllResponseModel = new TickGetAllResponseModel(this.buffer)
    this._Level2GetRequestModel = new Level2GetRequestModel(this.buffer)
    this._Level2GetResponseModel = new Level2GetResponseModel(this.buffer)
    this._Level2GetFilterRequestModel = new Level2GetFilterRequestModel(this.buffer)
    this._Level2GetFilterResponseModel = new Level2GetFilterResponseModel(this.buffer)
    this._Level2GetAllRequestModel = new Level2GetAllRequestModel(this.buffer)
    this._Level2GetAllResponseModel = new Level2GetAllResponseModel(this.buffer)
    this._FeedSubscriptionGetRequestModel = new FeedSubscriptionGetRequestModel(this.buffer)
    this._FeedSubscriptionGetResponseModel = new FeedSubscriptionGetResponseModel(this.buffer)
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel(this.buffer)
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel(this.buffer)
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel(this.buffer)
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel(this.buffer)
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel(this.buffer)
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel(this.buffer)
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel(this.buffer)
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel(this.buffer)
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel(this.buffer)
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel(this.buffer)
    this._FeedUpdateResponseModel = new FeedUpdateResponseModel(this.buffer)
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel(this.buffer)
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel(this.buffer)
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel(this.buffer)
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel(this.buffer)
    this._DataFeedModulesGetRequestModel = new DataFeedModulesGetRequestModel(this.buffer)
    this._DataFeedModulesGetResponseModel = new DataFeedModulesGetResponseModel(this.buffer)
    this._DataFeedModuleUpdateNotifyModel = new DataFeedModuleUpdateNotifyModel(this.buffer)
    this._DataFeedStartRequestModel = new DataFeedStartRequestModel(this.buffer)
    this._DataFeedStopRequestModel = new DataFeedStopRequestModel(this.buffer)
    this._DataFeedShutdownRequestModel = new DataFeedShutdownRequestModel(this.buffer)
    this._DataFeedUpdateSubscriptionsRequestModel = new DataFeedUpdateSubscriptionsRequestModel(this.buffer)
    this._DataFeedStatusNotifyModel = new DataFeedStatusNotifyModel(this.buffer)
    this._FeedUpdateLevel2SnapshotNotifyModel = new FeedUpdateLevel2SnapshotNotifyModel(this.buffer)
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get FeedVersionRequest model
   * @this {!Sender}
   * @returns {!FeedVersionRequestModel} FeedVersionRequest model
   */
  get FeedVersionRequestModel () {
    return this._FeedVersionRequestModel
  }

  /**
   * Get FeedVersionResponse model
   * @this {!Sender}
   * @returns {!FeedVersionResponseModel} FeedVersionResponse model
   */
  get FeedVersionResponseModel () {
    return this._FeedVersionResponseModel
  }

  /**
   * Get FeedUpdateNotify model
   * @this {!Sender}
   * @returns {!FeedUpdateNotifyModel} FeedUpdateNotify model
   */
  get FeedUpdateNotifyModel () {
    return this._FeedUpdateNotifyModel
  }

  /**
   * Get CurrencyGroupGetRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupGetRequestModel} CurrencyGroupGetRequest model
   */
  get CurrencyGroupGetRequestModel () {
    return this._CurrencyGroupGetRequestModel
  }

  /**
   * Get CurrencyGroupGetResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupGetResponseModel} CurrencyGroupGetResponse model
   */
  get CurrencyGroupGetResponseModel () {
    return this._CurrencyGroupGetResponseModel
  }

  /**
   * Get CurrencyGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!CurrencyGroupsGetAllRequestModel} CurrencyGroupsGetAllRequest model
   */
  get CurrencyGroupsGetAllRequestModel () {
    return this._CurrencyGroupsGetAllRequestModel
  }

  /**
   * Get CurrencyGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!CurrencyGroupsGetAllResponseModel} CurrencyGroupsGetAllResponse model
   */
  get CurrencyGroupsGetAllResponseModel () {
    return this._CurrencyGroupsGetAllResponseModel
  }

  /**
   * Get CurrencyGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!CurrencyGroupUpdateNotifyModel} CurrencyGroupUpdateNotify model
   */
  get CurrencyGroupUpdateNotifyModel () {
    return this._CurrencyGroupUpdateNotifyModel
  }

  /**
   * Get CurrencyGetRequest model
   * @this {!Sender}
   * @returns {!CurrencyGetRequestModel} CurrencyGetRequest model
   */
  get CurrencyGetRequestModel () {
    return this._CurrencyGetRequestModel
  }

  /**
   * Get CurrencyGetResponse model
   * @this {!Sender}
   * @returns {!CurrencyGetResponseModel} CurrencyGetResponse model
   */
  get CurrencyGetResponseModel () {
    return this._CurrencyGetResponseModel
  }

  /**
   * Get CurrenciesGetAllRequest model
   * @this {!Sender}
   * @returns {!CurrenciesGetAllRequestModel} CurrenciesGetAllRequest model
   */
  get CurrenciesGetAllRequestModel () {
    return this._CurrenciesGetAllRequestModel
  }

  /**
   * Get CurrenciesGetAllResponse model
   * @this {!Sender}
   * @returns {!CurrenciesGetAllResponseModel} CurrenciesGetAllResponse model
   */
  get CurrenciesGetAllResponseModel () {
    return this._CurrenciesGetAllResponseModel
  }

  /**
   * Get CurrencyUpdateNotify model
   * @this {!Sender}
   * @returns {!CurrencyUpdateNotifyModel} CurrencyUpdateNotify model
   */
  get CurrencyUpdateNotifyModel () {
    return this._CurrencyUpdateNotifyModel
  }

  /**
   * Get SymbolGroupGetRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupGetRequestModel} SymbolGroupGetRequest model
   */
  get SymbolGroupGetRequestModel () {
    return this._SymbolGroupGetRequestModel
  }

  /**
   * Get SymbolGroupGetResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupGetResponseModel} SymbolGroupGetResponse model
   */
  get SymbolGroupGetResponseModel () {
    return this._SymbolGroupGetResponseModel
  }

  /**
   * Get SymbolGroupsGetAllRequest model
   * @this {!Sender}
   * @returns {!SymbolGroupsGetAllRequestModel} SymbolGroupsGetAllRequest model
   */
  get SymbolGroupsGetAllRequestModel () {
    return this._SymbolGroupsGetAllRequestModel
  }

  /**
   * Get SymbolGroupsGetAllResponse model
   * @this {!Sender}
   * @returns {!SymbolGroupsGetAllResponseModel} SymbolGroupsGetAllResponse model
   */
  get SymbolGroupsGetAllResponseModel () {
    return this._SymbolGroupsGetAllResponseModel
  }

  /**
   * Get SymbolGroupUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolGroupUpdateNotifyModel} SymbolGroupUpdateNotify model
   */
  get SymbolGroupUpdateNotifyModel () {
    return this._SymbolGroupUpdateNotifyModel
  }

  /**
   * Get SymbolGetRequest model
   * @this {!Sender}
   * @returns {!SymbolGetRequestModel} SymbolGetRequest model
   */
  get SymbolGetRequestModel () {
    return this._SymbolGetRequestModel
  }

  /**
   * Get SymbolGetResponse model
   * @this {!Sender}
   * @returns {!SymbolGetResponseModel} SymbolGetResponse model
   */
  get SymbolGetResponseModel () {
    return this._SymbolGetResponseModel
  }

  /**
   * Get SymbolsGetAllRequest model
   * @this {!Sender}
   * @returns {!SymbolsGetAllRequestModel} SymbolsGetAllRequest model
   */
  get SymbolsGetAllRequestModel () {
    return this._SymbolsGetAllRequestModel
  }

  /**
   * Get SymbolsGetAllResponse model
   * @this {!Sender}
   * @returns {!SymbolsGetAllResponseModel} SymbolsGetAllResponse model
   */
  get SymbolsGetAllResponseModel () {
    return this._SymbolsGetAllResponseModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify model
   */
  get SymbolUpdateNotifyModel () {
    return this._SymbolUpdateNotifyModel
  }

  /**
   * Get TickGetRequest model
   * @this {!Sender}
   * @returns {!TickGetRequestModel} TickGetRequest model
   */
  get TickGetRequestModel () {
    return this._TickGetRequestModel
  }

  /**
   * Get TickGetResponse model
   * @this {!Sender}
   * @returns {!TickGetResponseModel} TickGetResponse model
   */
  get TickGetResponseModel () {
    return this._TickGetResponseModel
  }

  /**
   * Get TickGetFilterRequest model
   * @this {!Sender}
   * @returns {!TickGetFilterRequestModel} TickGetFilterRequest model
   */
  get TickGetFilterRequestModel () {
    return this._TickGetFilterRequestModel
  }

  /**
   * Get TickGetFilterResponse model
   * @this {!Sender}
   * @returns {!TickGetFilterResponseModel} TickGetFilterResponse model
   */
  get TickGetFilterResponseModel () {
    return this._TickGetFilterResponseModel
  }

  /**
   * Get TickGetAllRequest model
   * @this {!Sender}
   * @returns {!TickGetAllRequestModel} TickGetAllRequest model
   */
  get TickGetAllRequestModel () {
    return this._TickGetAllRequestModel
  }

  /**
   * Get TickGetAllResponse model
   * @this {!Sender}
   * @returns {!TickGetAllResponseModel} TickGetAllResponse model
   */
  get TickGetAllResponseModel () {
    return this._TickGetAllResponseModel
  }

  /**
   * Get Level2GetRequest model
   * @this {!Sender}
   * @returns {!Level2GetRequestModel} Level2GetRequest model
   */
  get Level2GetRequestModel () {
    return this._Level2GetRequestModel
  }

  /**
   * Get Level2GetResponse model
   * @this {!Sender}
   * @returns {!Level2GetResponseModel} Level2GetResponse model
   */
  get Level2GetResponseModel () {
    return this._Level2GetResponseModel
  }

  /**
   * Get Level2GetFilterRequest model
   * @this {!Sender}
   * @returns {!Level2GetFilterRequestModel} Level2GetFilterRequest model
   */
  get Level2GetFilterRequestModel () {
    return this._Level2GetFilterRequestModel
  }

  /**
   * Get Level2GetFilterResponse model
   * @this {!Sender}
   * @returns {!Level2GetFilterResponseModel} Level2GetFilterResponse model
   */
  get Level2GetFilterResponseModel () {
    return this._Level2GetFilterResponseModel
  }

  /**
   * Get Level2GetAllRequest model
   * @this {!Sender}
   * @returns {!Level2GetAllRequestModel} Level2GetAllRequest model
   */
  get Level2GetAllRequestModel () {
    return this._Level2GetAllRequestModel
  }

  /**
   * Get Level2GetAllResponse model
   * @this {!Sender}
   * @returns {!Level2GetAllResponseModel} Level2GetAllResponse model
   */
  get Level2GetAllResponseModel () {
    return this._Level2GetAllResponseModel
  }

  /**
   * Get FeedSubscriptionGetRequest model
   * @this {!Sender}
   * @returns {!FeedSubscriptionGetRequestModel} FeedSubscriptionGetRequest model
   */
  get FeedSubscriptionGetRequestModel () {
    return this._FeedSubscriptionGetRequestModel
  }

  /**
   * Get FeedSubscriptionGetResponse model
   * @this {!Sender}
   * @returns {!FeedSubscriptionGetResponseModel} FeedSubscriptionGetResponse model
   */
  get FeedSubscriptionGetResponseModel () {
    return this._FeedSubscriptionGetResponseModel
  }

  /**
   * Get FeedSubscribeRequest model
   * @this {!Sender}
   * @returns {!FeedSubscribeRequestModel} FeedSubscribeRequest model
   */
  get FeedSubscribeRequestModel () {
    return this._FeedSubscribeRequestModel
  }

  /**
   * Get FeedSubscribeAllRequest model
   * @this {!Sender}
   * @returns {!FeedSubscribeAllRequestModel} FeedSubscribeAllRequest model
   */
  get FeedSubscribeAllRequestModel () {
    return this._FeedSubscribeAllRequestModel
  }

  /**
   * Get FeedSubscribeResponse model
   * @this {!Sender}
   * @returns {!FeedSubscribeResponseModel} FeedSubscribeResponse model
   */
  get FeedSubscribeResponseModel () {
    return this._FeedSubscribeResponseModel
  }

  /**
   * Get FeedUnsubscribeRequest model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeRequestModel} FeedUnsubscribeRequest model
   */
  get FeedUnsubscribeRequestModel () {
    return this._FeedUnsubscribeRequestModel
  }

  /**
   * Get FeedUnsubscribeAllRequest model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeAllRequestModel} FeedUnsubscribeAllRequest model
   */
  get FeedUnsubscribeAllRequestModel () {
    return this._FeedUnsubscribeAllRequestModel
  }

  /**
   * Get FeedUnsubscribeResponse model
   * @this {!Sender}
   * @returns {!FeedUnsubscribeResponseModel} FeedUnsubscribeResponse model
   */
  get FeedUnsubscribeResponseModel () {
    return this._FeedUnsubscribeResponseModel
  }

  /**
   * Get FeedUpdateTickSnapshotRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateTickSnapshotRequestModel} FeedUpdateTickSnapshotRequest model
   */
  get FeedUpdateTickSnapshotRequestModel () {
    return this._FeedUpdateTickSnapshotRequestModel
  }

  /**
   * Get FeedUpdateTickRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateTickRequestModel} FeedUpdateTickRequest model
   */
  get FeedUpdateTickRequestModel () {
    return this._FeedUpdateTickRequestModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotRequest model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2SnapshotRequestModel} FeedUpdateLevel2SnapshotRequest model
   */
  get FeedUpdateLevel2SnapshotRequestModel () {
    return this._FeedUpdateLevel2SnapshotRequestModel
  }

  /**
   * Get FeedUpdateLevel2Request model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2RequestModel} FeedUpdateLevel2Request model
   */
  get FeedUpdateLevel2RequestModel () {
    return this._FeedUpdateLevel2RequestModel
  }

  /**
   * Get FeedUpdateResponse model
   * @this {!Sender}
   * @returns {!FeedUpdateResponseModel} FeedUpdateResponse model
   */
  get FeedUpdateResponseModel () {
    return this._FeedUpdateResponseModel
  }

  /**
   * Get TickSnapshotNotify model
   * @this {!Sender}
   * @returns {!TickSnapshotNotifyModel} TickSnapshotNotify model
   */
  get TickSnapshotNotifyModel () {
    return this._TickSnapshotNotifyModel
  }

  /**
   * Get TickUpdateNotify model
   * @this {!Sender}
   * @returns {!TickUpdateNotifyModel} TickUpdateNotify model
   */
  get TickUpdateNotifyModel () {
    return this._TickUpdateNotifyModel
  }

  /**
   * Get Level2SnapshotNotify model
   * @this {!Sender}
   * @returns {!Level2SnapshotNotifyModel} Level2SnapshotNotify model
   */
  get Level2SnapshotNotifyModel () {
    return this._Level2SnapshotNotifyModel
  }

  /**
   * Get Level2UpdateNotify model
   * @this {!Sender}
   * @returns {!Level2UpdateNotifyModel} Level2UpdateNotify model
   */
  get Level2UpdateNotifyModel () {
    return this._Level2UpdateNotifyModel
  }

  /**
   * Get DataFeedModulesGetRequest model
   * @this {!Sender}
   * @returns {!DataFeedModulesGetRequestModel} DataFeedModulesGetRequest model
   */
  get DataFeedModulesGetRequestModel () {
    return this._DataFeedModulesGetRequestModel
  }

  /**
   * Get DataFeedModulesGetResponse model
   * @this {!Sender}
   * @returns {!DataFeedModulesGetResponseModel} DataFeedModulesGetResponse model
   */
  get DataFeedModulesGetResponseModel () {
    return this._DataFeedModulesGetResponseModel
  }

  /**
   * Get DataFeedModuleUpdateNotify model
   * @this {!Sender}
   * @returns {!DataFeedModuleUpdateNotifyModel} DataFeedModuleUpdateNotify model
   */
  get DataFeedModuleUpdateNotifyModel () {
    return this._DataFeedModuleUpdateNotifyModel
  }

  /**
   * Get DataFeedStartRequest model
   * @this {!Sender}
   * @returns {!DataFeedStartRequestModel} DataFeedStartRequest model
   */
  get DataFeedStartRequestModel () {
    return this._DataFeedStartRequestModel
  }

  /**
   * Get DataFeedStopRequest model
   * @this {!Sender}
   * @returns {!DataFeedStopRequestModel} DataFeedStopRequest model
   */
  get DataFeedStopRequestModel () {
    return this._DataFeedStopRequestModel
  }

  /**
   * Get DataFeedShutdownRequest model
   * @this {!Sender}
   * @returns {!DataFeedShutdownRequestModel} DataFeedShutdownRequest model
   */
  get DataFeedShutdownRequestModel () {
    return this._DataFeedShutdownRequestModel
  }

  /**
   * Get DataFeedUpdateSubscriptionsRequest model
   * @this {!Sender}
   * @returns {!DataFeedUpdateSubscriptionsRequestModel} DataFeedUpdateSubscriptionsRequest model
   */
  get DataFeedUpdateSubscriptionsRequestModel () {
    return this._DataFeedUpdateSubscriptionsRequestModel
  }

  /**
   * Get DataFeedStatusNotify model
   * @this {!Sender}
   * @returns {!DataFeedStatusNotifyModel} DataFeedStatusNotify model
   */
  get DataFeedStatusNotifyModel () {
    return this._DataFeedStatusNotifyModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotNotify model
   * @this {!Sender}
   * @returns {!FeedUpdateLevel2SnapshotNotifyModel} FeedUpdateLevel2SnapshotNotify model
   */
  get FeedUpdateLevel2SnapshotNotifyModel () {
    return this._FeedUpdateLevel2SnapshotNotifyModel
  }

  /**
   * Get SymbolsUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolsUpdateNotifyModel} SymbolsUpdateNotify model
   */
  get SymbolsUpdateNotifyModel () {
    return this._SymbolsUpdateNotifyModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof FeedVersionRequest) && (value.fbeType === this.FeedVersionRequestModel.fbeType)) {
      return this.send_FeedVersionRequest(value)
    }
    if ((value instanceof FeedVersionResponse) && (value.fbeType === this.FeedVersionResponseModel.fbeType)) {
      return this.send_FeedVersionResponse(value)
    }
    if ((value instanceof FeedUpdateNotify) && (value.fbeType === this.FeedUpdateNotifyModel.fbeType)) {
      return this.send_FeedUpdateNotify(value)
    }
    if ((value instanceof CurrencyGroupGetRequest) && (value.fbeType === this.CurrencyGroupGetRequestModel.fbeType)) {
      return this.send_CurrencyGroupGetRequest(value)
    }
    if ((value instanceof CurrencyGroupGetResponse) && (value.fbeType === this.CurrencyGroupGetResponseModel.fbeType)) {
      return this.send_CurrencyGroupGetResponse(value)
    }
    if ((value instanceof CurrencyGroupsGetAllRequest) && (value.fbeType === this.CurrencyGroupsGetAllRequestModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllRequest(value)
    }
    if ((value instanceof CurrencyGroupsGetAllResponse) && (value.fbeType === this.CurrencyGroupsGetAllResponseModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllResponse(value)
    }
    if ((value instanceof CurrencyGroupUpdateNotify) && (value.fbeType === this.CurrencyGroupUpdateNotifyModel.fbeType)) {
      return this.send_CurrencyGroupUpdateNotify(value)
    }
    if ((value instanceof CurrencyGetRequest) && (value.fbeType === this.CurrencyGetRequestModel.fbeType)) {
      return this.send_CurrencyGetRequest(value)
    }
    if ((value instanceof CurrencyGetResponse) && (value.fbeType === this.CurrencyGetResponseModel.fbeType)) {
      return this.send_CurrencyGetResponse(value)
    }
    if ((value instanceof CurrenciesGetAllRequest) && (value.fbeType === this.CurrenciesGetAllRequestModel.fbeType)) {
      return this.send_CurrenciesGetAllRequest(value)
    }
    if ((value instanceof CurrenciesGetAllResponse) && (value.fbeType === this.CurrenciesGetAllResponseModel.fbeType)) {
      return this.send_CurrenciesGetAllResponse(value)
    }
    if ((value instanceof CurrencyUpdateNotify) && (value.fbeType === this.CurrencyUpdateNotifyModel.fbeType)) {
      return this.send_CurrencyUpdateNotify(value)
    }
    if ((value instanceof SymbolGroupGetRequest) && (value.fbeType === this.SymbolGroupGetRequestModel.fbeType)) {
      return this.send_SymbolGroupGetRequest(value)
    }
    if ((value instanceof SymbolGroupGetResponse) && (value.fbeType === this.SymbolGroupGetResponseModel.fbeType)) {
      return this.send_SymbolGroupGetResponse(value)
    }
    if ((value instanceof SymbolGroupsGetAllRequest) && (value.fbeType === this.SymbolGroupsGetAllRequestModel.fbeType)) {
      return this.send_SymbolGroupsGetAllRequest(value)
    }
    if ((value instanceof SymbolGroupsGetAllResponse) && (value.fbeType === this.SymbolGroupsGetAllResponseModel.fbeType)) {
      return this.send_SymbolGroupsGetAllResponse(value)
    }
    if ((value instanceof SymbolGroupUpdateNotify) && (value.fbeType === this.SymbolGroupUpdateNotifyModel.fbeType)) {
      return this.send_SymbolGroupUpdateNotify(value)
    }
    if ((value instanceof SymbolGetRequest) && (value.fbeType === this.SymbolGetRequestModel.fbeType)) {
      return this.send_SymbolGetRequest(value)
    }
    if ((value instanceof SymbolGetResponse) && (value.fbeType === this.SymbolGetResponseModel.fbeType)) {
      return this.send_SymbolGetResponse(value)
    }
    if ((value instanceof SymbolsGetAllRequest) && (value.fbeType === this.SymbolsGetAllRequestModel.fbeType)) {
      return this.send_SymbolsGetAllRequest(value)
    }
    if ((value instanceof SymbolsGetAllResponse) && (value.fbeType === this.SymbolsGetAllResponseModel.fbeType)) {
      return this.send_SymbolsGetAllResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifyModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof TickGetRequest) && (value.fbeType === this.TickGetRequestModel.fbeType)) {
      return this.send_TickGetRequest(value)
    }
    if ((value instanceof TickGetResponse) && (value.fbeType === this.TickGetResponseModel.fbeType)) {
      return this.send_TickGetResponse(value)
    }
    if ((value instanceof TickGetFilterRequest) && (value.fbeType === this.TickGetFilterRequestModel.fbeType)) {
      return this.send_TickGetFilterRequest(value)
    }
    if ((value instanceof TickGetFilterResponse) && (value.fbeType === this.TickGetFilterResponseModel.fbeType)) {
      return this.send_TickGetFilterResponse(value)
    }
    if ((value instanceof TickGetAllRequest) && (value.fbeType === this.TickGetAllRequestModel.fbeType)) {
      return this.send_TickGetAllRequest(value)
    }
    if ((value instanceof TickGetAllResponse) && (value.fbeType === this.TickGetAllResponseModel.fbeType)) {
      return this.send_TickGetAllResponse(value)
    }
    if ((value instanceof Level2GetRequest) && (value.fbeType === this.Level2GetRequestModel.fbeType)) {
      return this.send_Level2GetRequest(value)
    }
    if ((value instanceof Level2GetResponse) && (value.fbeType === this.Level2GetResponseModel.fbeType)) {
      return this.send_Level2GetResponse(value)
    }
    if ((value instanceof Level2GetFilterRequest) && (value.fbeType === this.Level2GetFilterRequestModel.fbeType)) {
      return this.send_Level2GetFilterRequest(value)
    }
    if ((value instanceof Level2GetFilterResponse) && (value.fbeType === this.Level2GetFilterResponseModel.fbeType)) {
      return this.send_Level2GetFilterResponse(value)
    }
    if ((value instanceof Level2GetAllRequest) && (value.fbeType === this.Level2GetAllRequestModel.fbeType)) {
      return this.send_Level2GetAllRequest(value)
    }
    if ((value instanceof Level2GetAllResponse) && (value.fbeType === this.Level2GetAllResponseModel.fbeType)) {
      return this.send_Level2GetAllResponse(value)
    }
    if ((value instanceof FeedSubscriptionGetRequest) && (value.fbeType === this.FeedSubscriptionGetRequestModel.fbeType)) {
      return this.send_FeedSubscriptionGetRequest(value)
    }
    if ((value instanceof FeedSubscriptionGetResponse) && (value.fbeType === this.FeedSubscriptionGetResponseModel.fbeType)) {
      return this.send_FeedSubscriptionGetResponse(value)
    }
    if ((value instanceof FeedSubscribeRequest) && (value.fbeType === this.FeedSubscribeRequestModel.fbeType)) {
      return this.send_FeedSubscribeRequest(value)
    }
    if ((value instanceof FeedSubscribeAllRequest) && (value.fbeType === this.FeedSubscribeAllRequestModel.fbeType)) {
      return this.send_FeedSubscribeAllRequest(value)
    }
    if ((value instanceof FeedSubscribeResponse) && (value.fbeType === this.FeedSubscribeResponseModel.fbeType)) {
      return this.send_FeedSubscribeResponse(value)
    }
    if ((value instanceof FeedUnsubscribeRequest) && (value.fbeType === this.FeedUnsubscribeRequestModel.fbeType)) {
      return this.send_FeedUnsubscribeRequest(value)
    }
    if ((value instanceof FeedUnsubscribeAllRequest) && (value.fbeType === this.FeedUnsubscribeAllRequestModel.fbeType)) {
      return this.send_FeedUnsubscribeAllRequest(value)
    }
    if ((value instanceof FeedUnsubscribeResponse) && (value.fbeType === this.FeedUnsubscribeResponseModel.fbeType)) {
      return this.send_FeedUnsubscribeResponse(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotRequest) && (value.fbeType === this.FeedUpdateTickSnapshotRequestModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateTickRequest) && (value.fbeType === this.FeedUpdateTickRequestModel.fbeType)) {
      return this.send_FeedUpdateTickRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotRequest) && (value.fbeType === this.FeedUpdateLevel2SnapshotRequestModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2Request) && (value.fbeType === this.FeedUpdateLevel2RequestModel.fbeType)) {
      return this.send_FeedUpdateLevel2Request(value)
    }
    if ((value instanceof FeedUpdateResponse) && (value.fbeType === this.FeedUpdateResponseModel.fbeType)) {
      return this.send_FeedUpdateResponse(value)
    }
    if ((value instanceof TickSnapshotNotify) && (value.fbeType === this.TickSnapshotNotifyModel.fbeType)) {
      return this.send_TickSnapshotNotify(value)
    }
    if ((value instanceof TickUpdateNotify) && (value.fbeType === this.TickUpdateNotifyModel.fbeType)) {
      return this.send_TickUpdateNotify(value)
    }
    if ((value instanceof Level2SnapshotNotify) && (value.fbeType === this.Level2SnapshotNotifyModel.fbeType)) {
      return this.send_Level2SnapshotNotify(value)
    }
    if ((value instanceof Level2UpdateNotify) && (value.fbeType === this.Level2UpdateNotifyModel.fbeType)) {
      return this.send_Level2UpdateNotify(value)
    }
    if ((value instanceof DataFeedModulesGetRequest) && (value.fbeType === this.DataFeedModulesGetRequestModel.fbeType)) {
      return this.send_DataFeedModulesGetRequest(value)
    }
    if ((value instanceof DataFeedModulesGetResponse) && (value.fbeType === this.DataFeedModulesGetResponseModel.fbeType)) {
      return this.send_DataFeedModulesGetResponse(value)
    }
    if ((value instanceof DataFeedModuleUpdateNotify) && (value.fbeType === this.DataFeedModuleUpdateNotifyModel.fbeType)) {
      return this.send_DataFeedModuleUpdateNotify(value)
    }
    if ((value instanceof DataFeedStartRequest) && (value.fbeType === this.DataFeedStartRequestModel.fbeType)) {
      return this.send_DataFeedStartRequest(value)
    }
    if ((value instanceof DataFeedStopRequest) && (value.fbeType === this.DataFeedStopRequestModel.fbeType)) {
      return this.send_DataFeedStopRequest(value)
    }
    if ((value instanceof DataFeedShutdownRequest) && (value.fbeType === this.DataFeedShutdownRequestModel.fbeType)) {
      return this.send_DataFeedShutdownRequest(value)
    }
    if ((value instanceof DataFeedUpdateSubscriptionsRequest) && (value.fbeType === this.DataFeedUpdateSubscriptionsRequestModel.fbeType)) {
      return this.send_DataFeedUpdateSubscriptionsRequest(value)
    }
    if ((value instanceof DataFeedStatusNotify) && (value.fbeType === this.DataFeedStatusNotifyModel.fbeType)) {
      return this.send_DataFeedStatusNotify(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotNotify) && (value.fbeType === this.FeedUpdateLevel2SnapshotNotifyModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotNotify(value)
    }
    if ((value instanceof SymbolsUpdateNotify) && (value.fbeType === this.SymbolsUpdateNotifyModel.fbeType)) {
      return this.send_SymbolsUpdateNotify(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send FeedVersionRequest value
   * @this {!Sender}
   * @param {!FeedVersionRequest} value FeedVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedVersionRequest serialization failed!')
    console.assert(this.FeedVersionRequestModel.verify(), 'feed.FeedVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedVersionResponse value
   * @this {!Sender}
   * @param {!FeedVersionResponse} value FeedVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedVersionResponse serialization failed!')
    console.assert(this.FeedVersionResponseModel.verify(), 'feed.FeedVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateNotify value
   * @this {!Sender}
   * @param {!FeedUpdateNotify} value FeedUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateNotify serialization failed!')
    console.assert(this.FeedUpdateNotifyModel.verify(), 'feed.FeedUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupGetRequest serialization failed!')
    console.assert(this.CurrencyGroupGetRequestModel.verify(), 'feed.CurrencyGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupGetResponse serialization failed!')
    console.assert(this.CurrencyGroupGetResponseModel.verify(), 'feed.CurrencyGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupsGetAllRequest serialization failed!')
    console.assert(this.CurrencyGroupsGetAllRequestModel.verify(), 'feed.CurrencyGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupsGetAllResponse serialization failed!')
    console.assert(this.CurrencyGroupsGetAllResponseModel.verify(), 'feed.CurrencyGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupUpdateNotify value
   * @this {!Sender}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupUpdateNotify serialization failed!')
    console.assert(this.CurrencyGroupUpdateNotifyModel.verify(), 'feed.CurrencyGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetRequest value
   * @this {!Sender}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGetRequest serialization failed!')
    console.assert(this.CurrencyGetRequestModel.verify(), 'feed.CurrencyGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetResponse value
   * @this {!Sender}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGetResponse serialization failed!')
    console.assert(this.CurrencyGetResponseModel.verify(), 'feed.CurrencyGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllRequest value
   * @this {!Sender}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrenciesGetAllRequest serialization failed!')
    console.assert(this.CurrenciesGetAllRequestModel.verify(), 'feed.CurrenciesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllResponse value
   * @this {!Sender}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrenciesGetAllResponse serialization failed!')
    console.assert(this.CurrenciesGetAllResponseModel.verify(), 'feed.CurrenciesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyUpdateNotify value
   * @this {!Sender}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyUpdateNotify serialization failed!')
    console.assert(this.CurrencyUpdateNotifyModel.verify(), 'feed.CurrencyUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetRequest value
   * @this {!Sender}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupGetRequest serialization failed!')
    console.assert(this.SymbolGroupGetRequestModel.verify(), 'feed.SymbolGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetResponse value
   * @this {!Sender}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupGetResponse serialization failed!')
    console.assert(this.SymbolGroupGetResponseModel.verify(), 'feed.SymbolGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllRequest value
   * @this {!Sender}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupsGetAllRequest serialization failed!')
    console.assert(this.SymbolGroupsGetAllRequestModel.verify(), 'feed.SymbolGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllResponse value
   * @this {!Sender}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupsGetAllResponse serialization failed!')
    console.assert(this.SymbolGroupsGetAllResponseModel.verify(), 'feed.SymbolGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupUpdateNotify serialization failed!')
    console.assert(this.SymbolGroupUpdateNotifyModel.verify(), 'feed.SymbolGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetRequest value
   * @this {!Sender}
   * @param {!SymbolGetRequest} value SymbolGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGetRequest serialization failed!')
    console.assert(this.SymbolGetRequestModel.verify(), 'feed.SymbolGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetResponse value
   * @this {!Sender}
   * @param {!SymbolGetResponse} value SymbolGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGetResponse serialization failed!')
    console.assert(this.SymbolGetResponseModel.verify(), 'feed.SymbolGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllRequest value
   * @this {!Sender}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsGetAllRequest serialization failed!')
    console.assert(this.SymbolsGetAllRequestModel.verify(), 'feed.SymbolsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllResponse value
   * @this {!Sender}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsGetAllResponse serialization failed!')
    console.assert(this.SymbolsGetAllResponseModel.verify(), 'feed.SymbolsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifyModel.verify(), 'feed.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetRequest value
   * @this {!Sender}
   * @param {!TickGetRequest} value TickGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetRequest serialization failed!')
    console.assert(this.TickGetRequestModel.verify(), 'feed.TickGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetResponse value
   * @this {!Sender}
   * @param {!TickGetResponse} value TickGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetResponse serialization failed!')
    console.assert(this.TickGetResponseModel.verify(), 'feed.TickGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetFilterRequest value
   * @this {!Sender}
   * @param {!TickGetFilterRequest} value TickGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetFilterRequest serialization failed!')
    console.assert(this.TickGetFilterRequestModel.verify(), 'feed.TickGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetFilterResponse value
   * @this {!Sender}
   * @param {!TickGetFilterResponse} value TickGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetFilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetFilterResponse serialization failed!')
    console.assert(this.TickGetFilterResponseModel.verify(), 'feed.TickGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetAllRequest value
   * @this {!Sender}
   * @param {!TickGetAllRequest} value TickGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetAllRequest serialization failed!')
    console.assert(this.TickGetAllRequestModel.verify(), 'feed.TickGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetAllResponse value
   * @this {!Sender}
   * @param {!TickGetAllResponse} value TickGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetAllResponse serialization failed!')
    console.assert(this.TickGetAllResponseModel.verify(), 'feed.TickGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetRequest value
   * @this {!Sender}
   * @param {!Level2GetRequest} value Level2GetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetRequest serialization failed!')
    console.assert(this.Level2GetRequestModel.verify(), 'feed.Level2GetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetResponse value
   * @this {!Sender}
   * @param {!Level2GetResponse} value Level2GetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetResponse serialization failed!')
    console.assert(this.Level2GetResponseModel.verify(), 'feed.Level2GetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetFilterRequest value
   * @this {!Sender}
   * @param {!Level2GetFilterRequest} value Level2GetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetFilterRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetFilterRequest serialization failed!')
    console.assert(this.Level2GetFilterRequestModel.verify(), 'feed.Level2GetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetFilterResponse value
   * @this {!Sender}
   * @param {!Level2GetFilterResponse} value Level2GetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetFilterResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetFilterResponse serialization failed!')
    console.assert(this.Level2GetFilterResponseModel.verify(), 'feed.Level2GetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetAllRequest value
   * @this {!Sender}
   * @param {!Level2GetAllRequest} value Level2GetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetAllRequest serialization failed!')
    console.assert(this.Level2GetAllRequestModel.verify(), 'feed.Level2GetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetAllResponse value
   * @this {!Sender}
   * @param {!Level2GetAllResponse} value Level2GetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetAllResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetAllResponse serialization failed!')
    console.assert(this.Level2GetAllResponseModel.verify(), 'feed.Level2GetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionGetRequest value
   * @this {!Sender}
   * @param {!FeedSubscriptionGetRequest} value FeedSubscriptionGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscriptionGetRequest serialization failed!')
    console.assert(this.FeedSubscriptionGetRequestModel.verify(), 'feed.FeedSubscriptionGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionGetResponse value
   * @this {!Sender}
   * @param {!FeedSubscriptionGetResponse} value FeedSubscriptionGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscriptionGetResponse serialization failed!')
    console.assert(this.FeedSubscriptionGetResponseModel.verify(), 'feed.FeedSubscriptionGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeRequest value
   * @this {!Sender}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeRequest serialization failed!')
    console.assert(this.FeedSubscribeRequestModel.verify(), 'feed.FeedSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeAllRequest value
   * @this {!Sender}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeAllRequest serialization failed!')
    console.assert(this.FeedSubscribeAllRequestModel.verify(), 'feed.FeedSubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeResponse value
   * @this {!Sender}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeResponse serialization failed!')
    console.assert(this.FeedSubscribeResponseModel.verify(), 'feed.FeedSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeRequest value
   * @this {!Sender}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeRequest serialization failed!')
    console.assert(this.FeedUnsubscribeRequestModel.verify(), 'feed.FeedUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeAllRequest value
   * @this {!Sender}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeAllRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeAllRequest serialization failed!')
    console.assert(this.FeedUnsubscribeAllRequestModel.verify(), 'feed.FeedUnsubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeResponse value
   * @this {!Sender}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeResponse serialization failed!')
    console.assert(this.FeedUnsubscribeResponseModel.verify(), 'feed.FeedUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotRequest value
   * @this {!Sender}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateTickSnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotRequestModel.verify(), 'feed.FeedUpdateTickSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickRequest value
   * @this {!Sender}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateTickRequest serialization failed!')
    console.assert(this.FeedUpdateTickRequestModel.verify(), 'feed.FeedUpdateTickRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotRequest value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2SnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotRequestModel.verify(), 'feed.FeedUpdateLevel2SnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Request value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2RequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2Request serialization failed!')
    console.assert(this.FeedUpdateLevel2RequestModel.verify(), 'feed.FeedUpdateLevel2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateResponse value
   * @this {!Sender}
   * @param {!FeedUpdateResponse} value FeedUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateResponse serialization failed!')
    console.assert(this.FeedUpdateResponseModel.verify(), 'feed.FeedUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickSnapshotNotify value
   * @this {!Sender}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickSnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickSnapshotNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickSnapshotNotify serialization failed!')
    console.assert(this.TickSnapshotNotifyModel.verify(), 'feed.TickSnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickUpdateNotify value
   * @this {!Sender}
   * @param {!TickUpdateNotify} value TickUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickUpdateNotify serialization failed!')
    console.assert(this.TickUpdateNotifyModel.verify(), 'feed.TickUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2SnapshotNotify value
   * @this {!Sender}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2SnapshotNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2SnapshotNotify serialization failed!')
    console.assert(this.Level2SnapshotNotifyModel.verify(), 'feed.Level2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2UpdateNotify value
   * @this {!Sender}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2UpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2UpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2UpdateNotify serialization failed!')
    console.assert(this.Level2UpdateNotifyModel.verify(), 'feed.Level2UpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModulesGetRequest value
   * @this {!Sender}
   * @param {!DataFeedModulesGetRequest} value DataFeedModulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModulesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModulesGetRequest serialization failed!')
    console.assert(this.DataFeedModulesGetRequestModel.verify(), 'feed.DataFeedModulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModulesGetResponse value
   * @this {!Sender}
   * @param {!DataFeedModulesGetResponse} value DataFeedModulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModulesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModulesGetResponse serialization failed!')
    console.assert(this.DataFeedModulesGetResponseModel.verify(), 'feed.DataFeedModulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModuleUpdateNotify value
   * @this {!Sender}
   * @param {!DataFeedModuleUpdateNotify} value DataFeedModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModuleUpdateNotify serialization failed!')
    console.assert(this.DataFeedModuleUpdateNotifyModel.verify(), 'feed.DataFeedModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStartRequest value
   * @this {!Sender}
   * @param {!DataFeedStartRequest} value DataFeedStartRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStartRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStartRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStartRequest serialization failed!')
    console.assert(this.DataFeedStartRequestModel.verify(), 'feed.DataFeedStartRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStopRequest value
   * @this {!Sender}
   * @param {!DataFeedStopRequest} value DataFeedStopRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStopRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStopRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStopRequest serialization failed!')
    console.assert(this.DataFeedStopRequestModel.verify(), 'feed.DataFeedStopRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedShutdownRequest value
   * @this {!Sender}
   * @param {!DataFeedShutdownRequest} value DataFeedShutdownRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedShutdownRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedShutdownRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedShutdownRequest serialization failed!')
    console.assert(this.DataFeedShutdownRequestModel.verify(), 'feed.DataFeedShutdownRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedUpdateSubscriptionsRequest value
   * @this {!Sender}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedUpdateSubscriptionsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedUpdateSubscriptionsRequestModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedUpdateSubscriptionsRequest serialization failed!')
    console.assert(this.DataFeedUpdateSubscriptionsRequestModel.verify(), 'feed.DataFeedUpdateSubscriptionsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStatusNotify value
   * @this {!Sender}
   * @param {!DataFeedStatusNotify} value DataFeedStatusNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStatusNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStatusNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStatusNotify serialization failed!')
    console.assert(this.DataFeedStatusNotifyModel.verify(), 'feed.DataFeedStatusNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotNotify value
   * @this {!Sender}
   * @param {!FeedUpdateLevel2SnapshotNotify} value FeedUpdateLevel2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2SnapshotNotify serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotNotifyModel.verify(), 'feed.FeedUpdateLevel2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsUpdateNotify serialization failed!')
    console.assert(this.SymbolsUpdateNotifyModel.verify(), 'feed.SymbolsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'feed.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding feed receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize feed receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._FeedVersionRequestValue = new FeedVersionRequest()
    this._FeedVersionRequestModel = new FeedVersionRequestModel()
    this._FeedVersionResponseValue = new FeedVersionResponse()
    this._FeedVersionResponseModel = new FeedVersionResponseModel()
    this._FeedUpdateNotifyValue = new FeedUpdateNotify()
    this._FeedUpdateNotifyModel = new FeedUpdateNotifyModel()
    this._CurrencyGroupGetRequestValue = new CurrencyGroupGetRequest()
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseValue = new CurrencyGroupGetResponse()
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestValue = new CurrencyGroupsGetAllRequest()
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseValue = new CurrencyGroupsGetAllResponse()
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupUpdateNotifyValue = new CurrencyGroupUpdateNotify()
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestValue = new CurrencyGetRequest()
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseValue = new CurrencyGetResponse()
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestValue = new CurrenciesGetAllRequest()
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseValue = new CurrenciesGetAllResponse()
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel()
    this._CurrencyUpdateNotifyValue = new CurrencyUpdateNotify()
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestValue = new SymbolGroupGetRequest()
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseValue = new SymbolGroupGetResponse()
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestValue = new SymbolGroupsGetAllRequest()
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseValue = new SymbolGroupsGetAllResponse()
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupUpdateNotifyValue = new SymbolGroupUpdateNotify()
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestValue = new SymbolGetRequest()
    this._SymbolGetRequestModel = new SymbolGetRequestModel()
    this._SymbolGetResponseValue = new SymbolGetResponse()
    this._SymbolGetResponseModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestValue = new SymbolsGetAllRequest()
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseValue = new SymbolsGetAllResponse()
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel()
    this._SymbolUpdateNotifyValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._TickGetRequestValue = new TickGetRequest()
    this._TickGetRequestModel = new TickGetRequestModel()
    this._TickGetResponseValue = new TickGetResponse()
    this._TickGetResponseModel = new TickGetResponseModel()
    this._TickGetFilterRequestValue = new TickGetFilterRequest()
    this._TickGetFilterRequestModel = new TickGetFilterRequestModel()
    this._TickGetFilterResponseValue = new TickGetFilterResponse()
    this._TickGetFilterResponseModel = new TickGetFilterResponseModel()
    this._TickGetAllRequestValue = new TickGetAllRequest()
    this._TickGetAllRequestModel = new TickGetAllRequestModel()
    this._TickGetAllResponseValue = new TickGetAllResponse()
    this._TickGetAllResponseModel = new TickGetAllResponseModel()
    this._Level2GetRequestValue = new Level2GetRequest()
    this._Level2GetRequestModel = new Level2GetRequestModel()
    this._Level2GetResponseValue = new Level2GetResponse()
    this._Level2GetResponseModel = new Level2GetResponseModel()
    this._Level2GetFilterRequestValue = new Level2GetFilterRequest()
    this._Level2GetFilterRequestModel = new Level2GetFilterRequestModel()
    this._Level2GetFilterResponseValue = new Level2GetFilterResponse()
    this._Level2GetFilterResponseModel = new Level2GetFilterResponseModel()
    this._Level2GetAllRequestValue = new Level2GetAllRequest()
    this._Level2GetAllRequestModel = new Level2GetAllRequestModel()
    this._Level2GetAllResponseValue = new Level2GetAllResponse()
    this._Level2GetAllResponseModel = new Level2GetAllResponseModel()
    this._FeedSubscriptionGetRequestValue = new FeedSubscriptionGetRequest()
    this._FeedSubscriptionGetRequestModel = new FeedSubscriptionGetRequestModel()
    this._FeedSubscriptionGetResponseValue = new FeedSubscriptionGetResponse()
    this._FeedSubscriptionGetResponseModel = new FeedSubscriptionGetResponseModel()
    this._FeedSubscribeRequestValue = new FeedSubscribeRequest()
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestValue = new FeedSubscribeAllRequest()
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseValue = new FeedSubscribeResponse()
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestValue = new FeedUnsubscribeRequest()
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestValue = new FeedUnsubscribeAllRequest()
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseValue = new FeedUnsubscribeResponse()
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel()
    this._FeedUpdateTickSnapshotRequestValue = new FeedUpdateTickSnapshotRequest()
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickRequestValue = new FeedUpdateTickRequest()
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateLevel2SnapshotRequestValue = new FeedUpdateLevel2SnapshotRequest()
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2RequestValue = new FeedUpdateLevel2Request()
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateResponseValue = new FeedUpdateResponse()
    this._FeedUpdateResponseModel = new FeedUpdateResponseModel()
    this._TickSnapshotNotifyValue = new TickSnapshotNotify()
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifyValue = new TickUpdateNotify()
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifyValue = new Level2SnapshotNotify()
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifyValue = new Level2UpdateNotify()
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel()
    this._DataFeedModulesGetRequestValue = new DataFeedModulesGetRequest()
    this._DataFeedModulesGetRequestModel = new DataFeedModulesGetRequestModel()
    this._DataFeedModulesGetResponseValue = new DataFeedModulesGetResponse()
    this._DataFeedModulesGetResponseModel = new DataFeedModulesGetResponseModel()
    this._DataFeedModuleUpdateNotifyValue = new DataFeedModuleUpdateNotify()
    this._DataFeedModuleUpdateNotifyModel = new DataFeedModuleUpdateNotifyModel()
    this._DataFeedStartRequestValue = new DataFeedStartRequest()
    this._DataFeedStartRequestModel = new DataFeedStartRequestModel()
    this._DataFeedStopRequestValue = new DataFeedStopRequest()
    this._DataFeedStopRequestModel = new DataFeedStopRequestModel()
    this._DataFeedShutdownRequestValue = new DataFeedShutdownRequest()
    this._DataFeedShutdownRequestModel = new DataFeedShutdownRequestModel()
    this._DataFeedUpdateSubscriptionsRequestValue = new DataFeedUpdateSubscriptionsRequest()
    this._DataFeedUpdateSubscriptionsRequestModel = new DataFeedUpdateSubscriptionsRequestModel()
    this._DataFeedStatusNotifyValue = new DataFeedStatusNotify()
    this._DataFeedStatusNotifyModel = new DataFeedStatusNotifyModel()
    this._FeedUpdateLevel2SnapshotNotifyValue = new FeedUpdateLevel2SnapshotNotify()
    this._FeedUpdateLevel2SnapshotNotifyModel = new FeedUpdateLevel2SnapshotNotifyModel()
    this._SymbolsUpdateNotifyValue = new SymbolsUpdateNotify()
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * FeedVersionRequest receive handler
   * @this {!Receiver}
   * @param {!FeedVersionRequest} value FeedVersionRequest received value
   */
  onReceive_FeedVersionRequest (value) {}  // eslint-disable-line

  /**
   * FeedVersionResponse receive handler
   * @this {!Receiver}
   * @param {!FeedVersionResponse} value FeedVersionResponse received value
   */
  onReceive_FeedVersionResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateNotify} value FeedUpdateNotify received value
   */
  onReceive_FeedUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest received value
   */
  onReceive_CurrencyGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse received value
   */
  onReceive_CurrencyGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest received value
   */
  onReceive_CurrencyGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse received value
   */
  onReceive_CurrencyGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify received value
   */
  onReceive_CurrencyGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest receive handler
   * @this {!Receiver}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest received value
   */
  onReceive_CurrencyGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse receive handler
   * @this {!Receiver}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse received value
   */
  onReceive_CurrencyGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest received value
   */
  onReceive_CurrenciesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse received value
   */
  onReceive_CurrenciesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify received value
   */
  onReceive_CurrencyUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest received value
   */
  onReceive_SymbolGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse received value
   */
  onReceive_SymbolGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest received value
   */
  onReceive_SymbolGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse received value
   */
  onReceive_SymbolGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify received value
   */
  onReceive_SymbolGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGetRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolGetRequest} value SymbolGetRequest received value
   */
  onReceive_SymbolGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGetResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolGetResponse} value SymbolGetResponse received value
   */
  onReceive_SymbolGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest received value
   */
  onReceive_SymbolsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse received value
   */
  onReceive_SymbolsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickGetRequest receive handler
   * @this {!Receiver}
   * @param {!TickGetRequest} value TickGetRequest received value
   */
  onReceive_TickGetRequest (value) {}  // eslint-disable-line

  /**
   * TickGetResponse receive handler
   * @this {!Receiver}
   * @param {!TickGetResponse} value TickGetResponse received value
   */
  onReceive_TickGetResponse (value) {}  // eslint-disable-line

  /**
   * TickGetFilterRequest receive handler
   * @this {!Receiver}
   * @param {!TickGetFilterRequest} value TickGetFilterRequest received value
   */
  onReceive_TickGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * TickGetFilterResponse receive handler
   * @this {!Receiver}
   * @param {!TickGetFilterResponse} value TickGetFilterResponse received value
   */
  onReceive_TickGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * TickGetAllRequest receive handler
   * @this {!Receiver}
   * @param {!TickGetAllRequest} value TickGetAllRequest received value
   */
  onReceive_TickGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TickGetAllResponse receive handler
   * @this {!Receiver}
   * @param {!TickGetAllResponse} value TickGetAllResponse received value
   */
  onReceive_TickGetAllResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetRequest receive handler
   * @this {!Receiver}
   * @param {!Level2GetRequest} value Level2GetRequest received value
   */
  onReceive_Level2GetRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetResponse receive handler
   * @this {!Receiver}
   * @param {!Level2GetResponse} value Level2GetResponse received value
   */
  onReceive_Level2GetResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetFilterRequest receive handler
   * @this {!Receiver}
   * @param {!Level2GetFilterRequest} value Level2GetFilterRequest received value
   */
  onReceive_Level2GetFilterRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetFilterResponse receive handler
   * @this {!Receiver}
   * @param {!Level2GetFilterResponse} value Level2GetFilterResponse received value
   */
  onReceive_Level2GetFilterResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetAllRequest receive handler
   * @this {!Receiver}
   * @param {!Level2GetAllRequest} value Level2GetAllRequest received value
   */
  onReceive_Level2GetAllRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetAllResponse receive handler
   * @this {!Receiver}
   * @param {!Level2GetAllResponse} value Level2GetAllResponse received value
   */
  onReceive_Level2GetAllResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscriptionGetRequest} value FeedSubscriptionGetRequest received value
   */
  onReceive_FeedSubscriptionGetRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetResponse receive handler
   * @this {!Receiver}
   * @param {!FeedSubscriptionGetResponse} value FeedSubscriptionGetResponse received value
   */
  onReceive_FeedSubscriptionGetResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest received value
   */
  onReceive_FeedSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest received value
   */
  onReceive_FeedSubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse received value
   */
  onReceive_FeedSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest received value
   */
  onReceive_FeedUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest received value
   */
  onReceive_FeedUnsubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse received value
   */
  onReceive_FeedUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest received value
   */
  onReceive_FeedUpdateTickSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest received value
   */
  onReceive_FeedUpdateTickRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest received value
   */
  onReceive_FeedUpdateLevel2SnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request received value
   */
  onReceive_FeedUpdateLevel2Request (value) {}  // eslint-disable-line

  /**
   * FeedUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateResponse} value FeedUpdateResponse received value
   */
  onReceive_FeedUpdateResponse (value) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify receive handler
   * @this {!Receiver}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify received value
   */
  onReceive_TickSnapshotNotify (value) {}  // eslint-disable-line

  /**
   * TickUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!TickUpdateNotify} value TickUpdateNotify received value
   */
  onReceive_TickUpdateNotify (value) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify receive handler
   * @this {!Receiver}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify received value
   */
  onReceive_Level2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify receive handler
   * @this {!Receiver}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify received value
   */
  onReceive_Level2UpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedModulesGetRequest} value DataFeedModulesGetRequest received value
   */
  onReceive_DataFeedModulesGetRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetResponse receive handler
   * @this {!Receiver}
   * @param {!DataFeedModulesGetResponse} value DataFeedModulesGetResponse received value
   */
  onReceive_DataFeedModulesGetResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedModuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!DataFeedModuleUpdateNotify} value DataFeedModuleUpdateNotify received value
   */
  onReceive_DataFeedModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedStartRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedStartRequest} value DataFeedStartRequest received value
   */
  onReceive_DataFeedStartRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedStopRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedStopRequest} value DataFeedStopRequest received value
   */
  onReceive_DataFeedStopRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedShutdownRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedShutdownRequest} value DataFeedShutdownRequest received value
   */
  onReceive_DataFeedShutdownRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedUpdateSubscriptionsRequest receive handler
   * @this {!Receiver}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest received value
   */
  onReceive_DataFeedUpdateSubscriptionsRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedStatusNotify receive handler
   * @this {!Receiver}
   * @param {!DataFeedStatusNotify} value DataFeedStatusNotify received value
   */
  onReceive_DataFeedStatusNotify (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotNotify receive handler
   * @this {!Receiver}
   * @param {!FeedUpdateLevel2SnapshotNotify} value FeedUpdateLevel2SnapshotNotify received value
   */
  onReceive_FeedUpdateLevel2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify received value
   */
  onReceive_SymbolsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * feed receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case FeedVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionRequestModel.verify(), 'feed.FeedVersionRequest validation failed!')
        let deserialized = this._FeedVersionRequestModel.deserialize(this._FeedVersionRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedVersionRequestValue)
        this.onReceive_FeedVersionRequest(this._FeedVersionRequestValue)
        return true
      }
      case FeedVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionResponseModel.verify(), 'feed.FeedVersionResponse validation failed!')
        let deserialized = this._FeedVersionResponseModel.deserialize(this._FeedVersionResponseValue)
        console.assert((deserialized.size > 0), 'feed.FeedVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedVersionResponseValue)
        this.onReceive_FeedVersionResponse(this._FeedVersionResponseValue)
        return true
      }
      case FeedUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateNotifyModel.verify(), 'feed.FeedUpdateNotify validation failed!')
        let deserialized = this._FeedUpdateNotifyModel.deserialize(this._FeedUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateNotifyValue)
        this.onReceive_FeedUpdateNotify(this._FeedUpdateNotifyValue)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestModel.verify(), 'feed.CurrencyGroupGetRequest validation failed!')
        let deserialized = this._CurrencyGroupGetRequestModel.deserialize(this._CurrencyGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetRequestValue)
        this.onReceive_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestValue)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseModel.verify(), 'feed.CurrencyGroupGetResponse validation failed!')
        let deserialized = this._CurrencyGroupGetResponseModel.deserialize(this._CurrencyGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetResponseValue)
        this.onReceive_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseValue)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestModel.verify(), 'feed.CurrencyGroupsGetAllRequest validation failed!')
        let deserialized = this._CurrencyGroupsGetAllRequestModel.deserialize(this._CurrencyGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllRequestValue)
        this.onReceive_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestValue)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseModel.verify(), 'feed.CurrencyGroupsGetAllResponse validation failed!')
        let deserialized = this._CurrencyGroupsGetAllResponseModel.deserialize(this._CurrencyGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllResponseValue)
        this.onReceive_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseValue)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyModel.verify(), 'feed.CurrencyGroupUpdateNotify validation failed!')
        let deserialized = this._CurrencyGroupUpdateNotifyModel.deserialize(this._CurrencyGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupUpdateNotifyValue)
        this.onReceive_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyValue)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestModel.verify(), 'feed.CurrencyGetRequest validation failed!')
        let deserialized = this._CurrencyGetRequestModel.deserialize(this._CurrencyGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetRequestValue)
        this.onReceive_CurrencyGetRequest(this._CurrencyGetRequestValue)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseModel.verify(), 'feed.CurrencyGetResponse validation failed!')
        let deserialized = this._CurrencyGetResponseModel.deserialize(this._CurrencyGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetResponseValue)
        this.onReceive_CurrencyGetResponse(this._CurrencyGetResponseValue)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestModel.verify(), 'feed.CurrenciesGetAllRequest validation failed!')
        let deserialized = this._CurrenciesGetAllRequestModel.deserialize(this._CurrenciesGetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.CurrenciesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllRequestValue)
        this.onReceive_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestValue)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseModel.verify(), 'feed.CurrenciesGetAllResponse validation failed!')
        let deserialized = this._CurrenciesGetAllResponseModel.deserialize(this._CurrenciesGetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.CurrenciesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllResponseValue)
        this.onReceive_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseValue)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyModel.verify(), 'feed.CurrencyUpdateNotify validation failed!')
        let deserialized = this._CurrencyUpdateNotifyModel.deserialize(this._CurrencyUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyUpdateNotifyValue)
        this.onReceive_CurrencyUpdateNotify(this._CurrencyUpdateNotifyValue)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestModel.verify(), 'feed.SymbolGroupGetRequest validation failed!')
        let deserialized = this._SymbolGroupGetRequestModel.deserialize(this._SymbolGroupGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetRequestValue)
        this.onReceive_SymbolGroupGetRequest(this._SymbolGroupGetRequestValue)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseModel.verify(), 'feed.SymbolGroupGetResponse validation failed!')
        let deserialized = this._SymbolGroupGetResponseModel.deserialize(this._SymbolGroupGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetResponseValue)
        this.onReceive_SymbolGroupGetResponse(this._SymbolGroupGetResponseValue)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestModel.verify(), 'feed.SymbolGroupsGetAllRequest validation failed!')
        let deserialized = this._SymbolGroupsGetAllRequestModel.deserialize(this._SymbolGroupsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllRequestValue)
        this.onReceive_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestValue)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseModel.verify(), 'feed.SymbolGroupsGetAllResponse validation failed!')
        let deserialized = this._SymbolGroupsGetAllResponseModel.deserialize(this._SymbolGroupsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllResponseValue)
        this.onReceive_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseValue)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyModel.verify(), 'feed.SymbolGroupUpdateNotify validation failed!')
        let deserialized = this._SymbolGroupUpdateNotifyModel.deserialize(this._SymbolGroupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupUpdateNotifyValue)
        this.onReceive_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyValue)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestModel.verify(), 'feed.SymbolGetRequest validation failed!')
        let deserialized = this._SymbolGetRequestModel.deserialize(this._SymbolGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetRequestValue)
        this.onReceive_SymbolGetRequest(this._SymbolGetRequestValue)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseModel.verify(), 'feed.SymbolGetResponse validation failed!')
        let deserialized = this._SymbolGetResponseModel.deserialize(this._SymbolGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetResponseValue)
        this.onReceive_SymbolGetResponse(this._SymbolGetResponseValue)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestModel.verify(), 'feed.SymbolsGetAllRequest validation failed!')
        let deserialized = this._SymbolsGetAllRequestModel.deserialize(this._SymbolsGetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllRequestValue)
        this.onReceive_SymbolsGetAllRequest(this._SymbolsGetAllRequestValue)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseModel.verify(), 'feed.SymbolsGetAllResponse validation failed!')
        let deserialized = this._SymbolsGetAllResponseModel.deserialize(this._SymbolsGetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllResponseValue)
        this.onReceive_SymbolsGetAllResponse(this._SymbolsGetAllResponseValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'feed.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyModel.deserialize(this._SymbolUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyValue)
        return true
      }
      case TickGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetRequestModel.verify(), 'feed.TickGetRequest validation failed!')
        let deserialized = this._TickGetRequestModel.deserialize(this._TickGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.TickGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetRequestValue)
        this.onReceive_TickGetRequest(this._TickGetRequestValue)
        return true
      }
      case TickGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetResponseModel.verify(), 'feed.TickGetResponse validation failed!')
        let deserialized = this._TickGetResponseModel.deserialize(this._TickGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.TickGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetResponseValue)
        this.onReceive_TickGetResponse(this._TickGetResponseValue)
        return true
      }
      case TickGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterRequestModel.verify(), 'feed.TickGetFilterRequest validation failed!')
        let deserialized = this._TickGetFilterRequestModel.deserialize(this._TickGetFilterRequestValue)
        console.assert((deserialized.size > 0), 'feed.TickGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetFilterRequestValue)
        this.onReceive_TickGetFilterRequest(this._TickGetFilterRequestValue)
        return true
      }
      case TickGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterResponseModel.verify(), 'feed.TickGetFilterResponse validation failed!')
        let deserialized = this._TickGetFilterResponseModel.deserialize(this._TickGetFilterResponseValue)
        console.assert((deserialized.size > 0), 'feed.TickGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetFilterResponseValue)
        this.onReceive_TickGetFilterResponse(this._TickGetFilterResponseValue)
        return true
      }
      case TickGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllRequestModel.verify(), 'feed.TickGetAllRequest validation failed!')
        let deserialized = this._TickGetAllRequestModel.deserialize(this._TickGetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.TickGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetAllRequestValue)
        this.onReceive_TickGetAllRequest(this._TickGetAllRequestValue)
        return true
      }
      case TickGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllResponseModel.verify(), 'feed.TickGetAllResponse validation failed!')
        let deserialized = this._TickGetAllResponseModel.deserialize(this._TickGetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.TickGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetAllResponseValue)
        this.onReceive_TickGetAllResponse(this._TickGetAllResponseValue)
        return true
      }
      case Level2GetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetRequestModel.verify(), 'feed.Level2GetRequest validation failed!')
        let deserialized = this._Level2GetRequestModel.deserialize(this._Level2GetRequestValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetRequestValue)
        this.onReceive_Level2GetRequest(this._Level2GetRequestValue)
        return true
      }
      case Level2GetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetResponseModel.verify(), 'feed.Level2GetResponse validation failed!')
        let deserialized = this._Level2GetResponseModel.deserialize(this._Level2GetResponseValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetResponseValue)
        this.onReceive_Level2GetResponse(this._Level2GetResponseValue)
        return true
      }
      case Level2GetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterRequestModel.verify(), 'feed.Level2GetFilterRequest validation failed!')
        let deserialized = this._Level2GetFilterRequestModel.deserialize(this._Level2GetFilterRequestValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetFilterRequestValue)
        this.onReceive_Level2GetFilterRequest(this._Level2GetFilterRequestValue)
        return true
      }
      case Level2GetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterResponseModel.verify(), 'feed.Level2GetFilterResponse validation failed!')
        let deserialized = this._Level2GetFilterResponseModel.deserialize(this._Level2GetFilterResponseValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetFilterResponseValue)
        this.onReceive_Level2GetFilterResponse(this._Level2GetFilterResponseValue)
        return true
      }
      case Level2GetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllRequestModel.verify(), 'feed.Level2GetAllRequest validation failed!')
        let deserialized = this._Level2GetAllRequestModel.deserialize(this._Level2GetAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetAllRequestValue)
        this.onReceive_Level2GetAllRequest(this._Level2GetAllRequestValue)
        return true
      }
      case Level2GetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllResponseModel.verify(), 'feed.Level2GetAllResponse validation failed!')
        let deserialized = this._Level2GetAllResponseModel.deserialize(this._Level2GetAllResponseValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetAllResponseValue)
        this.onReceive_Level2GetAllResponse(this._Level2GetAllResponseValue)
        return true
      }
      case FeedSubscriptionGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetRequestModel.verify(), 'feed.FeedSubscriptionGetRequest validation failed!')
        let deserialized = this._FeedSubscriptionGetRequestModel.deserialize(this._FeedSubscriptionGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscriptionGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionGetRequestValue)
        this.onReceive_FeedSubscriptionGetRequest(this._FeedSubscriptionGetRequestValue)
        return true
      }
      case FeedSubscriptionGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetResponseModel.verify(), 'feed.FeedSubscriptionGetResponse validation failed!')
        let deserialized = this._FeedSubscriptionGetResponseModel.deserialize(this._FeedSubscriptionGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscriptionGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionGetResponseValue)
        this.onReceive_FeedSubscriptionGetResponse(this._FeedSubscriptionGetResponseValue)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestModel.verify(), 'feed.FeedSubscribeRequest validation failed!')
        let deserialized = this._FeedSubscribeRequestModel.deserialize(this._FeedSubscribeRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeRequestValue)
        this.onReceive_FeedSubscribeRequest(this._FeedSubscribeRequestValue)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestModel.verify(), 'feed.FeedSubscribeAllRequest validation failed!')
        let deserialized = this._FeedSubscribeAllRequestModel.deserialize(this._FeedSubscribeAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeAllRequestValue)
        this.onReceive_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestValue)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseModel.verify(), 'feed.FeedSubscribeResponse validation failed!')
        let deserialized = this._FeedSubscribeResponseModel.deserialize(this._FeedSubscribeResponseValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeResponseValue)
        this.onReceive_FeedSubscribeResponse(this._FeedSubscribeResponseValue)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestModel.verify(), 'feed.FeedUnsubscribeRequest validation failed!')
        let deserialized = this._FeedUnsubscribeRequestModel.deserialize(this._FeedUnsubscribeRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeRequestValue)
        this.onReceive_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestValue)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestModel.verify(), 'feed.FeedUnsubscribeAllRequest validation failed!')
        let deserialized = this._FeedUnsubscribeAllRequestModel.deserialize(this._FeedUnsubscribeAllRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeAllRequestValue)
        this.onReceive_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestValue)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseModel.verify(), 'feed.FeedUnsubscribeResponse validation failed!')
        let deserialized = this._FeedUnsubscribeResponseModel.deserialize(this._FeedUnsubscribeResponseValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeResponseValue)
        this.onReceive_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseValue)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestModel.verify(), 'feed.FeedUpdateTickSnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotRequestModel.deserialize(this._FeedUpdateTickSnapshotRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateTickSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotRequestValue)
        this.onReceive_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestValue)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestModel.verify(), 'feed.FeedUpdateTickRequest validation failed!')
        let deserialized = this._FeedUpdateTickRequestModel.deserialize(this._FeedUpdateTickRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateTickRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickRequestValue)
        this.onReceive_FeedUpdateTickRequest(this._FeedUpdateTickRequestValue)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestModel.verify(), 'feed.FeedUpdateLevel2SnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotRequestModel.deserialize(this._FeedUpdateLevel2SnapshotRequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2SnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotRequestValue)
        this.onReceive_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestValue)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestModel.verify(), 'feed.FeedUpdateLevel2Request validation failed!')
        let deserialized = this._FeedUpdateLevel2RequestModel.deserialize(this._FeedUpdateLevel2RequestValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2RequestValue)
        this.onReceive_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestValue)
        return true
      }
      case FeedUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateResponseModel.verify(), 'feed.FeedUpdateResponse validation failed!')
        let deserialized = this._FeedUpdateResponseModel.deserialize(this._FeedUpdateResponseValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateResponseValue)
        this.onReceive_FeedUpdateResponse(this._FeedUpdateResponseValue)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickSnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyModel.verify(), 'feed.TickSnapshotNotify validation failed!')
        let deserialized = this._TickSnapshotNotifyModel.deserialize(this._TickSnapshotNotifyValue)
        console.assert((deserialized.size > 0), 'feed.TickSnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickSnapshotNotifyValue)
        this.onReceive_TickSnapshotNotify(this._TickSnapshotNotifyValue)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyModel.verify(), 'feed.TickUpdateNotify validation failed!')
        let deserialized = this._TickUpdateNotifyModel.deserialize(this._TickUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.TickUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickUpdateNotifyValue)
        this.onReceive_TickUpdateNotify(this._TickUpdateNotifyValue)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyModel.verify(), 'feed.Level2SnapshotNotify validation failed!')
        let deserialized = this._Level2SnapshotNotifyModel.deserialize(this._Level2SnapshotNotifyValue)
        console.assert((deserialized.size > 0), 'feed.Level2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2SnapshotNotifyValue)
        this.onReceive_Level2SnapshotNotify(this._Level2SnapshotNotifyValue)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2UpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyModel.verify(), 'feed.Level2UpdateNotify validation failed!')
        let deserialized = this._Level2UpdateNotifyModel.deserialize(this._Level2UpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.Level2UpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2UpdateNotifyValue)
        this.onReceive_Level2UpdateNotify(this._Level2UpdateNotifyValue)
        return true
      }
      case DataFeedModulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetRequestModel.verify(), 'feed.DataFeedModulesGetRequest validation failed!')
        let deserialized = this._DataFeedModulesGetRequestModel.deserialize(this._DataFeedModulesGetRequestValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModulesGetRequestValue)
        this.onReceive_DataFeedModulesGetRequest(this._DataFeedModulesGetRequestValue)
        return true
      }
      case DataFeedModulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetResponseModel.verify(), 'feed.DataFeedModulesGetResponse validation failed!')
        let deserialized = this._DataFeedModulesGetResponseModel.deserialize(this._DataFeedModulesGetResponseValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModulesGetResponseValue)
        this.onReceive_DataFeedModulesGetResponse(this._DataFeedModulesGetResponseValue)
        return true
      }
      case DataFeedModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModuleUpdateNotifyModel.verify(), 'feed.DataFeedModuleUpdateNotify validation failed!')
        let deserialized = this._DataFeedModuleUpdateNotifyModel.deserialize(this._DataFeedModuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModuleUpdateNotifyValue)
        this.onReceive_DataFeedModuleUpdateNotify(this._DataFeedModuleUpdateNotifyValue)
        return true
      }
      case DataFeedStartRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStartRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStartRequestModel.verify(), 'feed.DataFeedStartRequest validation failed!')
        let deserialized = this._DataFeedStartRequestModel.deserialize(this._DataFeedStartRequestValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStartRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStartRequestValue)
        this.onReceive_DataFeedStartRequest(this._DataFeedStartRequestValue)
        return true
      }
      case DataFeedStopRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStopRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStopRequestModel.verify(), 'feed.DataFeedStopRequest validation failed!')
        let deserialized = this._DataFeedStopRequestModel.deserialize(this._DataFeedStopRequestValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStopRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStopRequestValue)
        this.onReceive_DataFeedStopRequest(this._DataFeedStopRequestValue)
        return true
      }
      case DataFeedShutdownRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedShutdownRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedShutdownRequestModel.verify(), 'feed.DataFeedShutdownRequest validation failed!')
        let deserialized = this._DataFeedShutdownRequestModel.deserialize(this._DataFeedShutdownRequestValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedShutdownRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedShutdownRequestValue)
        this.onReceive_DataFeedShutdownRequest(this._DataFeedShutdownRequestValue)
        return true
      }
      case DataFeedUpdateSubscriptionsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedUpdateSubscriptionsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateSubscriptionsRequestModel.verify(), 'feed.DataFeedUpdateSubscriptionsRequest validation failed!')
        let deserialized = this._DataFeedUpdateSubscriptionsRequestModel.deserialize(this._DataFeedUpdateSubscriptionsRequestValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedUpdateSubscriptionsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedUpdateSubscriptionsRequestValue)
        this.onReceive_DataFeedUpdateSubscriptionsRequest(this._DataFeedUpdateSubscriptionsRequestValue)
        return true
      }
      case DataFeedStatusNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStatusNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStatusNotifyModel.verify(), 'feed.DataFeedStatusNotify validation failed!')
        let deserialized = this._DataFeedStatusNotifyModel.deserialize(this._DataFeedStatusNotifyValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStatusNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStatusNotifyValue)
        this.onReceive_DataFeedStatusNotify(this._DataFeedStatusNotifyValue)
        return true
      }
      case FeedUpdateLevel2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotNotifyModel.verify(), 'feed.FeedUpdateLevel2SnapshotNotify validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotNotifyModel.deserialize(this._FeedUpdateLevel2SnapshotNotifyValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotNotifyValue)
        this.onReceive_FeedUpdateLevel2SnapshotNotify(this._FeedUpdateLevel2SnapshotNotifyValue)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyModel.verify(), 'feed.SymbolsUpdateNotify validation failed!')
        let deserialized = this._SymbolsUpdateNotifyModel.deserialize(this._SymbolsUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsUpdateNotifyValue)
        this.onReceive_SymbolsUpdateNotify(this._SymbolsUpdateNotifyValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding feed proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize feed proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._FeedVersionRequestModel = new FeedVersionRequestModel()
    this._FeedVersionResponseModel = new FeedVersionResponseModel()
    this._FeedUpdateNotifyModel = new FeedUpdateNotifyModel()
    this._CurrencyGroupGetRequestModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupUpdateNotifyModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseModel = new CurrenciesGetAllResponseModel()
    this._CurrencyUpdateNotifyModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupUpdateNotifyModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestModel = new SymbolGetRequestModel()
    this._SymbolGetResponseModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseModel = new SymbolsGetAllResponseModel()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._TickGetRequestModel = new TickGetRequestModel()
    this._TickGetResponseModel = new TickGetResponseModel()
    this._TickGetFilterRequestModel = new TickGetFilterRequestModel()
    this._TickGetFilterResponseModel = new TickGetFilterResponseModel()
    this._TickGetAllRequestModel = new TickGetAllRequestModel()
    this._TickGetAllResponseModel = new TickGetAllResponseModel()
    this._Level2GetRequestModel = new Level2GetRequestModel()
    this._Level2GetResponseModel = new Level2GetResponseModel()
    this._Level2GetFilterRequestModel = new Level2GetFilterRequestModel()
    this._Level2GetFilterResponseModel = new Level2GetFilterResponseModel()
    this._Level2GetAllRequestModel = new Level2GetAllRequestModel()
    this._Level2GetAllResponseModel = new Level2GetAllResponseModel()
    this._FeedSubscriptionGetRequestModel = new FeedSubscriptionGetRequestModel()
    this._FeedSubscriptionGetResponseModel = new FeedSubscriptionGetResponseModel()
    this._FeedSubscribeRequestModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseModel = new FeedUnsubscribeResponseModel()
    this._FeedUpdateTickSnapshotRequestModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickRequestModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateLevel2SnapshotRequestModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2RequestModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateResponseModel = new FeedUpdateResponseModel()
    this._TickSnapshotNotifyModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifyModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifyModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifyModel = new Level2UpdateNotifyModel()
    this._DataFeedModulesGetRequestModel = new DataFeedModulesGetRequestModel()
    this._DataFeedModulesGetResponseModel = new DataFeedModulesGetResponseModel()
    this._DataFeedModuleUpdateNotifyModel = new DataFeedModuleUpdateNotifyModel()
    this._DataFeedStartRequestModel = new DataFeedStartRequestModel()
    this._DataFeedStopRequestModel = new DataFeedStopRequestModel()
    this._DataFeedShutdownRequestModel = new DataFeedShutdownRequestModel()
    this._DataFeedUpdateSubscriptionsRequestModel = new DataFeedUpdateSubscriptionsRequestModel()
    this._DataFeedStatusNotifyModel = new DataFeedStatusNotifyModel()
    this._FeedUpdateLevel2SnapshotNotifyModel = new FeedUpdateLevel2SnapshotNotifyModel()
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * FeedVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedVersionRequest} model FeedVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedVersionResponse} model FeedVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateNotify} model FeedUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupGetRequest} model CurrencyGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupGetResponse} model CurrencyGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupsGetAllRequest} model CurrencyGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupsGetAllResponse} model CurrencyGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGroupUpdateNotify} model CurrencyGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGetRequest} model CurrencyGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrencyGetResponse} model CurrencyGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!CurrenciesGetAllRequest} model CurrenciesGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrenciesGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!CurrenciesGetAllResponse} model CurrenciesGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrenciesGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!CurrencyUpdateNotify} model CurrencyUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_CurrencyUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupGetRequest} model SymbolGroupGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupGetResponse} model SymbolGroupGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupsGetAllRequest} model SymbolGroupsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupsGetAllResponse} model SymbolGroupsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolGroupUpdateNotify} model SymbolGroupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGroupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGetRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolGetRequest} model SymbolGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolGetResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolGetResponse} model SymbolGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!SymbolsGetAllRequest} model SymbolsGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!SymbolsGetAllResponse} model SymbolsGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolUpdateNotify} model SymbolUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetRequest proxy handler
   * @this {!Proxy}
   * @param {!TickGetRequest} model TickGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetResponse proxy handler
   * @this {!Proxy}
   * @param {!TickGetResponse} model TickGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!TickGetFilterRequest} model TickGetFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetFilterResponse proxy handler
   * @this {!Proxy}
   * @param {!TickGetFilterResponse} model TickGetFilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetFilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!TickGetAllRequest} model TickGetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickGetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!TickGetAllResponse} model TickGetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickGetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2GetRequest} model Level2GetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2GetResponse} model Level2GetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetFilterRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2GetFilterRequest} model Level2GetFilterRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetFilterRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetFilterResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2GetFilterResponse} model Level2GetFilterResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetFilterResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetAllRequest proxy handler
   * @this {!Proxy}
   * @param {!Level2GetAllRequest} model Level2GetAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2GetAllResponse proxy handler
   * @this {!Proxy}
   * @param {!Level2GetAllResponse} model Level2GetAllResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2GetAllResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscriptionGetRequest} model FeedSubscriptionGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscriptionGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscriptionGetResponse} model FeedSubscriptionGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscriptionGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeRequest} model FeedSubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeAllRequest} model FeedSubscribeAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedSubscribeResponse} model FeedSubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedSubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeRequest} model FeedUnsubscribeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeAllRequest} model FeedUnsubscribeAllRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeAllRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUnsubscribeResponse} model FeedUnsubscribeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUnsubscribeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickSnapshotRequest} model FeedUpdateTickSnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickSnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateTickRequest} model FeedUpdateTickRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateTickRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2SnapshotRequest} model FeedUpdateLevel2SnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2SnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2Request} model FeedUpdateLevel2Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateResponse} model FeedUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify proxy handler
   * @this {!Proxy}
   * @param {!TickSnapshotNotify} model TickSnapshotNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickSnapshotNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * TickUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!TickUpdateNotify} model TickUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_TickUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify proxy handler
   * @this {!Proxy}
   * @param {!Level2SnapshotNotify} model Level2SnapshotNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2SnapshotNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!Level2UpdateNotify} model Level2UpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Level2UpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedModulesGetRequest} model DataFeedModulesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedModulesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!DataFeedModulesGetResponse} model DataFeedModulesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedModulesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedModuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!DataFeedModuleUpdateNotify} model DataFeedModuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedModuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedStartRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedStartRequest} model DataFeedStartRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedStartRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedStopRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedStopRequest} model DataFeedStopRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedStopRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedShutdownRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedShutdownRequest} model DataFeedShutdownRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedShutdownRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedUpdateSubscriptionsRequest proxy handler
   * @this {!Proxy}
   * @param {!DataFeedUpdateSubscriptionsRequest} model DataFeedUpdateSubscriptionsRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedUpdateSubscriptionsRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DataFeedStatusNotify proxy handler
   * @this {!Proxy}
   * @param {!DataFeedStatusNotify} model DataFeedStatusNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DataFeedStatusNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotNotify proxy handler
   * @this {!Proxy}
   * @param {!FeedUpdateLevel2SnapshotNotify} model FeedUpdateLevel2SnapshotNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_FeedUpdateLevel2SnapshotNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolsUpdateNotify} model SymbolsUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * feed receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case FeedVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionRequestModel.verify(), 'feed.FeedVersionRequest validation failed!')

        let fbeBegin = this._FeedVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedVersionRequest(this._FeedVersionRequestModel, type, buffer, offset, size)
        this._FeedVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionResponseModel.verify(), 'feed.FeedVersionResponse validation failed!')

        let fbeBegin = this._FeedVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedVersionResponse(this._FeedVersionResponseModel, type, buffer, offset, size)
        this._FeedVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateNotifyModel.verify(), 'feed.FeedUpdateNotify validation failed!')

        let fbeBegin = this._FeedUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateNotify(this._FeedUpdateNotifyModel, type, buffer, offset, size)
        this._FeedUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestModel.verify(), 'feed.CurrencyGroupGetRequest validation failed!')

        let fbeBegin = this._CurrencyGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestModel, type, buffer, offset, size)
        this._CurrencyGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseModel.verify(), 'feed.CurrencyGroupGetResponse validation failed!')

        let fbeBegin = this._CurrencyGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseModel, type, buffer, offset, size)
        this._CurrencyGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestModel.verify(), 'feed.CurrencyGroupsGetAllRequest validation failed!')

        let fbeBegin = this._CurrencyGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestModel, type, buffer, offset, size)
        this._CurrencyGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseModel.verify(), 'feed.CurrencyGroupsGetAllResponse validation failed!')

        let fbeBegin = this._CurrencyGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseModel, type, buffer, offset, size)
        this._CurrencyGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyModel.verify(), 'feed.CurrencyGroupUpdateNotify validation failed!')

        let fbeBegin = this._CurrencyGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyModel, type, buffer, offset, size)
        this._CurrencyGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestModel.verify(), 'feed.CurrencyGetRequest validation failed!')

        let fbeBegin = this._CurrencyGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGetRequest(this._CurrencyGetRequestModel, type, buffer, offset, size)
        this._CurrencyGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseModel.verify(), 'feed.CurrencyGetResponse validation failed!')

        let fbeBegin = this._CurrencyGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyGetResponse(this._CurrencyGetResponseModel, type, buffer, offset, size)
        this._CurrencyGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrenciesGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestModel.verify(), 'feed.CurrenciesGetAllRequest validation failed!')

        let fbeBegin = this._CurrenciesGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestModel, type, buffer, offset, size)
        this._CurrenciesGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrenciesGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseModel.verify(), 'feed.CurrenciesGetAllResponse validation failed!')

        let fbeBegin = this._CurrenciesGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseModel, type, buffer, offset, size)
        this._CurrenciesGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._CurrencyUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyModel.verify(), 'feed.CurrencyUpdateNotify validation failed!')

        let fbeBegin = this._CurrencyUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_CurrencyUpdateNotify(this._CurrencyUpdateNotifyModel, type, buffer, offset, size)
        this._CurrencyUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestModel.verify(), 'feed.SymbolGroupGetRequest validation failed!')

        let fbeBegin = this._SymbolGroupGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupGetRequest(this._SymbolGroupGetRequestModel, type, buffer, offset, size)
        this._SymbolGroupGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseModel.verify(), 'feed.SymbolGroupGetResponse validation failed!')

        let fbeBegin = this._SymbolGroupGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupGetResponse(this._SymbolGroupGetResponseModel, type, buffer, offset, size)
        this._SymbolGroupGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestModel.verify(), 'feed.SymbolGroupsGetAllRequest validation failed!')

        let fbeBegin = this._SymbolGroupsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestModel, type, buffer, offset, size)
        this._SymbolGroupsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseModel.verify(), 'feed.SymbolGroupsGetAllResponse validation failed!')

        let fbeBegin = this._SymbolGroupsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseModel, type, buffer, offset, size)
        this._SymbolGroupsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGroupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyModel.verify(), 'feed.SymbolGroupUpdateNotify validation failed!')

        let fbeBegin = this._SymbolGroupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolGroupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestModel.verify(), 'feed.SymbolGetRequest validation failed!')

        let fbeBegin = this._SymbolGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGetRequest(this._SymbolGetRequestModel, type, buffer, offset, size)
        this._SymbolGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseModel.verify(), 'feed.SymbolGetResponse validation failed!')

        let fbeBegin = this._SymbolGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolGetResponse(this._SymbolGetResponseModel, type, buffer, offset, size)
        this._SymbolGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestModel.verify(), 'feed.SymbolsGetAllRequest validation failed!')

        let fbeBegin = this._SymbolsGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsGetAllRequest(this._SymbolsGetAllRequestModel, type, buffer, offset, size)
        this._SymbolsGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseModel.verify(), 'feed.SymbolsGetAllResponse validation failed!')

        let fbeBegin = this._SymbolsGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsGetAllResponse(this._SymbolsGetAllResponseModel, type, buffer, offset, size)
        this._SymbolsGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'feed.SymbolUpdateNotify validation failed!')

        let fbeBegin = this._SymbolUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolUpdateNotify(this._SymbolUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetRequestModel.verify(), 'feed.TickGetRequest validation failed!')

        let fbeBegin = this._TickGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetRequest(this._TickGetRequestModel, type, buffer, offset, size)
        this._TickGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetResponseModel.verify(), 'feed.TickGetResponse validation failed!')

        let fbeBegin = this._TickGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetResponse(this._TickGetResponseModel, type, buffer, offset, size)
        this._TickGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterRequestModel.verify(), 'feed.TickGetFilterRequest validation failed!')

        let fbeBegin = this._TickGetFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetFilterRequest(this._TickGetFilterRequestModel, type, buffer, offset, size)
        this._TickGetFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetFilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterResponseModel.verify(), 'feed.TickGetFilterResponse validation failed!')

        let fbeBegin = this._TickGetFilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetFilterResponse(this._TickGetFilterResponseModel, type, buffer, offset, size)
        this._TickGetFilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllRequestModel.verify(), 'feed.TickGetAllRequest validation failed!')

        let fbeBegin = this._TickGetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetAllRequest(this._TickGetAllRequestModel, type, buffer, offset, size)
        this._TickGetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case TickGetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickGetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllResponseModel.verify(), 'feed.TickGetAllResponse validation failed!')

        let fbeBegin = this._TickGetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickGetAllResponse(this._TickGetAllResponseModel, type, buffer, offset, size)
        this._TickGetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetRequestModel.verify(), 'feed.Level2GetRequest validation failed!')

        let fbeBegin = this._Level2GetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetRequest(this._Level2GetRequestModel, type, buffer, offset, size)
        this._Level2GetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetResponseModel.verify(), 'feed.Level2GetResponse validation failed!')

        let fbeBegin = this._Level2GetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetResponse(this._Level2GetResponseModel, type, buffer, offset, size)
        this._Level2GetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetFilterRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetFilterRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterRequestModel.verify(), 'feed.Level2GetFilterRequest validation failed!')

        let fbeBegin = this._Level2GetFilterRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetFilterRequest(this._Level2GetFilterRequestModel, type, buffer, offset, size)
        this._Level2GetFilterRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetFilterResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetFilterResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterResponseModel.verify(), 'feed.Level2GetFilterResponse validation failed!')

        let fbeBegin = this._Level2GetFilterResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetFilterResponse(this._Level2GetFilterResponseModel, type, buffer, offset, size)
        this._Level2GetFilterResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllRequestModel.verify(), 'feed.Level2GetAllRequest validation failed!')

        let fbeBegin = this._Level2GetAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetAllRequest(this._Level2GetAllRequestModel, type, buffer, offset, size)
        this._Level2GetAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2GetAllResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2GetAllResponseModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllResponseModel.verify(), 'feed.Level2GetAllResponse validation failed!')

        let fbeBegin = this._Level2GetAllResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2GetAllResponse(this._Level2GetAllResponseModel, type, buffer, offset, size)
        this._Level2GetAllResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscriptionGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscriptionGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetRequestModel.verify(), 'feed.FeedSubscriptionGetRequest validation failed!')

        let fbeBegin = this._FeedSubscriptionGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscriptionGetRequest(this._FeedSubscriptionGetRequestModel, type, buffer, offset, size)
        this._FeedSubscriptionGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscriptionGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscriptionGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetResponseModel.verify(), 'feed.FeedSubscriptionGetResponse validation failed!')

        let fbeBegin = this._FeedSubscriptionGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscriptionGetResponse(this._FeedSubscriptionGetResponseModel, type, buffer, offset, size)
        this._FeedSubscriptionGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestModel.verify(), 'feed.FeedSubscribeRequest validation failed!')

        let fbeBegin = this._FeedSubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeRequest(this._FeedSubscribeRequestModel, type, buffer, offset, size)
        this._FeedSubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestModel.verify(), 'feed.FeedSubscribeAllRequest validation failed!')

        let fbeBegin = this._FeedSubscribeAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestModel, type, buffer, offset, size)
        this._FeedSubscribeAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedSubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseModel.verify(), 'feed.FeedSubscribeResponse validation failed!')

        let fbeBegin = this._FeedSubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedSubscribeResponse(this._FeedSubscribeResponseModel, type, buffer, offset, size)
        this._FeedSubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestModel.verify(), 'feed.FeedUnsubscribeRequest validation failed!')

        let fbeBegin = this._FeedUnsubscribeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestModel, type, buffer, offset, size)
        this._FeedUnsubscribeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeAllRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestModel.verify(), 'feed.FeedUnsubscribeAllRequest validation failed!')

        let fbeBegin = this._FeedUnsubscribeAllRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestModel, type, buffer, offset, size)
        this._FeedUnsubscribeAllRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUnsubscribeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseModel.verify(), 'feed.FeedUnsubscribeResponse validation failed!')

        let fbeBegin = this._FeedUnsubscribeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseModel, type, buffer, offset, size)
        this._FeedUnsubscribeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestModel.verify(), 'feed.FeedUpdateTickSnapshotRequest validation failed!')

        let fbeBegin = this._FeedUpdateTickSnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestModel, type, buffer, offset, size)
        this._FeedUpdateTickSnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateTickRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestModel.verify(), 'feed.FeedUpdateTickRequest validation failed!')

        let fbeBegin = this._FeedUpdateTickRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateTickRequest(this._FeedUpdateTickRequestModel, type, buffer, offset, size)
        this._FeedUpdateTickRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2SnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestModel.verify(), 'feed.FeedUpdateLevel2SnapshotRequest validation failed!')

        let fbeBegin = this._FeedUpdateLevel2SnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestModel, type, buffer, offset, size)
        this._FeedUpdateLevel2SnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2RequestModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestModel.verify(), 'feed.FeedUpdateLevel2Request validation failed!')

        let fbeBegin = this._FeedUpdateLevel2RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestModel, type, buffer, offset, size)
        this._FeedUpdateLevel2RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateResponseModel.verify(), 'feed.FeedUpdateResponse validation failed!')

        let fbeBegin = this._FeedUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateResponse(this._FeedUpdateResponseModel, type, buffer, offset, size)
        this._FeedUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickSnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyModel.verify(), 'feed.TickSnapshotNotify validation failed!')

        let fbeBegin = this._TickSnapshotNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickSnapshotNotify(this._TickSnapshotNotifyModel, type, buffer, offset, size)
        this._TickSnapshotNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._TickUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyModel.verify(), 'feed.TickUpdateNotify validation failed!')

        let fbeBegin = this._TickUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_TickUpdateNotify(this._TickUpdateNotifyModel, type, buffer, offset, size)
        this._TickUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyModel.verify(), 'feed.Level2SnapshotNotify validation failed!')

        let fbeBegin = this._Level2SnapshotNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2SnapshotNotify(this._Level2SnapshotNotifyModel, type, buffer, offset, size)
        this._Level2SnapshotNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._Level2UpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyModel.verify(), 'feed.Level2UpdateNotify validation failed!')

        let fbeBegin = this._Level2UpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Level2UpdateNotify(this._Level2UpdateNotifyModel, type, buffer, offset, size)
        this._Level2UpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedModulesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedModulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetRequestModel.verify(), 'feed.DataFeedModulesGetRequest validation failed!')

        let fbeBegin = this._DataFeedModulesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedModulesGetRequest(this._DataFeedModulesGetRequestModel, type, buffer, offset, size)
        this._DataFeedModulesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedModulesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedModulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetResponseModel.verify(), 'feed.DataFeedModulesGetResponse validation failed!')

        let fbeBegin = this._DataFeedModulesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedModulesGetResponse(this._DataFeedModulesGetResponseModel, type, buffer, offset, size)
        this._DataFeedModulesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedModuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModuleUpdateNotifyModel.verify(), 'feed.DataFeedModuleUpdateNotify validation failed!')

        let fbeBegin = this._DataFeedModuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedModuleUpdateNotify(this._DataFeedModuleUpdateNotifyModel, type, buffer, offset, size)
        this._DataFeedModuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedStartRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedStartRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStartRequestModel.verify(), 'feed.DataFeedStartRequest validation failed!')

        let fbeBegin = this._DataFeedStartRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedStartRequest(this._DataFeedStartRequestModel, type, buffer, offset, size)
        this._DataFeedStartRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedStopRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedStopRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStopRequestModel.verify(), 'feed.DataFeedStopRequest validation failed!')

        let fbeBegin = this._DataFeedStopRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedStopRequest(this._DataFeedStopRequestModel, type, buffer, offset, size)
        this._DataFeedStopRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedShutdownRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedShutdownRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedShutdownRequestModel.verify(), 'feed.DataFeedShutdownRequest validation failed!')

        let fbeBegin = this._DataFeedShutdownRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedShutdownRequest(this._DataFeedShutdownRequestModel, type, buffer, offset, size)
        this._DataFeedShutdownRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedUpdateSubscriptionsRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedUpdateSubscriptionsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateSubscriptionsRequestModel.verify(), 'feed.DataFeedUpdateSubscriptionsRequest validation failed!')

        let fbeBegin = this._DataFeedUpdateSubscriptionsRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedUpdateSubscriptionsRequest(this._DataFeedUpdateSubscriptionsRequestModel, type, buffer, offset, size)
        this._DataFeedUpdateSubscriptionsRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DataFeedStatusNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DataFeedStatusNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStatusNotifyModel.verify(), 'feed.DataFeedStatusNotify validation failed!')

        let fbeBegin = this._DataFeedStatusNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DataFeedStatusNotify(this._DataFeedStatusNotifyModel, type, buffer, offset, size)
        this._DataFeedStatusNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case FeedUpdateLevel2SnapshotNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._FeedUpdateLevel2SnapshotNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotNotifyModel.verify(), 'feed.FeedUpdateLevel2SnapshotNotify validation failed!')

        let fbeBegin = this._FeedUpdateLevel2SnapshotNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_FeedUpdateLevel2SnapshotNotify(this._FeedUpdateLevel2SnapshotNotifyModel, type, buffer, offset, size)
        this._FeedUpdateLevel2SnapshotNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyModel.verify(), 'feed.SymbolsUpdateNotify validation failed!')

        let fbeBegin = this._SymbolsUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsUpdateNotify(this._SymbolsUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolsUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding feed client
 */
class Client extends fbe.Client {
  /**
   * Initialize feed client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._FeedVersionRequestSenderModel = new FeedVersionRequestModel(this.sendBuffer)
    this._FeedVersionRequestReceiverValue = new FeedVersionRequest()
    this._FeedVersionRequestReceiverModel = new FeedVersionRequestModel()
    this._FeedVersionResponseSenderModel = new FeedVersionResponseModel(this.sendBuffer)
    this._FeedVersionResponseReceiverValue = new FeedVersionResponse()
    this._FeedVersionResponseReceiverModel = new FeedVersionResponseModel()
    this._FeedUpdateNotifySenderModel = new FeedUpdateNotifyModel(this.sendBuffer)
    this._FeedUpdateNotifyReceiverValue = new FeedUpdateNotify()
    this._FeedUpdateNotifyReceiverModel = new FeedUpdateNotifyModel()
    this._CurrencyGroupGetRequestSenderModel = new CurrencyGroupGetRequestModel(this.sendBuffer)
    this._CurrencyGroupGetRequestReceiverValue = new CurrencyGroupGetRequest()
    this._CurrencyGroupGetRequestReceiverModel = new CurrencyGroupGetRequestModel()
    this._CurrencyGroupGetResponseSenderModel = new CurrencyGroupGetResponseModel(this.sendBuffer)
    this._CurrencyGroupGetResponseReceiverValue = new CurrencyGroupGetResponse()
    this._CurrencyGroupGetResponseReceiverModel = new CurrencyGroupGetResponseModel()
    this._CurrencyGroupsGetAllRequestSenderModel = new CurrencyGroupsGetAllRequestModel(this.sendBuffer)
    this._CurrencyGroupsGetAllRequestReceiverValue = new CurrencyGroupsGetAllRequest()
    this._CurrencyGroupsGetAllRequestReceiverModel = new CurrencyGroupsGetAllRequestModel()
    this._CurrencyGroupsGetAllResponseSenderModel = new CurrencyGroupsGetAllResponseModel(this.sendBuffer)
    this._CurrencyGroupsGetAllResponseReceiverValue = new CurrencyGroupsGetAllResponse()
    this._CurrencyGroupsGetAllResponseReceiverModel = new CurrencyGroupsGetAllResponseModel()
    this._CurrencyGroupUpdateNotifySenderModel = new CurrencyGroupUpdateNotifyModel(this.sendBuffer)
    this._CurrencyGroupUpdateNotifyReceiverValue = new CurrencyGroupUpdateNotify()
    this._CurrencyGroupUpdateNotifyReceiverModel = new CurrencyGroupUpdateNotifyModel()
    this._CurrencyGetRequestSenderModel = new CurrencyGetRequestModel(this.sendBuffer)
    this._CurrencyGetRequestReceiverValue = new CurrencyGetRequest()
    this._CurrencyGetRequestReceiverModel = new CurrencyGetRequestModel()
    this._CurrencyGetResponseSenderModel = new CurrencyGetResponseModel(this.sendBuffer)
    this._CurrencyGetResponseReceiverValue = new CurrencyGetResponse()
    this._CurrencyGetResponseReceiverModel = new CurrencyGetResponseModel()
    this._CurrenciesGetAllRequestSenderModel = new CurrenciesGetAllRequestModel(this.sendBuffer)
    this._CurrenciesGetAllRequestReceiverValue = new CurrenciesGetAllRequest()
    this._CurrenciesGetAllRequestReceiverModel = new CurrenciesGetAllRequestModel()
    this._CurrenciesGetAllResponseSenderModel = new CurrenciesGetAllResponseModel(this.sendBuffer)
    this._CurrenciesGetAllResponseReceiverValue = new CurrenciesGetAllResponse()
    this._CurrenciesGetAllResponseReceiverModel = new CurrenciesGetAllResponseModel()
    this._CurrencyUpdateNotifySenderModel = new CurrencyUpdateNotifyModel(this.sendBuffer)
    this._CurrencyUpdateNotifyReceiverValue = new CurrencyUpdateNotify()
    this._CurrencyUpdateNotifyReceiverModel = new CurrencyUpdateNotifyModel()
    this._SymbolGroupGetRequestSenderModel = new SymbolGroupGetRequestModel(this.sendBuffer)
    this._SymbolGroupGetRequestReceiverValue = new SymbolGroupGetRequest()
    this._SymbolGroupGetRequestReceiverModel = new SymbolGroupGetRequestModel()
    this._SymbolGroupGetResponseSenderModel = new SymbolGroupGetResponseModel(this.sendBuffer)
    this._SymbolGroupGetResponseReceiverValue = new SymbolGroupGetResponse()
    this._SymbolGroupGetResponseReceiverModel = new SymbolGroupGetResponseModel()
    this._SymbolGroupsGetAllRequestSenderModel = new SymbolGroupsGetAllRequestModel(this.sendBuffer)
    this._SymbolGroupsGetAllRequestReceiverValue = new SymbolGroupsGetAllRequest()
    this._SymbolGroupsGetAllRequestReceiverModel = new SymbolGroupsGetAllRequestModel()
    this._SymbolGroupsGetAllResponseSenderModel = new SymbolGroupsGetAllResponseModel(this.sendBuffer)
    this._SymbolGroupsGetAllResponseReceiverValue = new SymbolGroupsGetAllResponse()
    this._SymbolGroupsGetAllResponseReceiverModel = new SymbolGroupsGetAllResponseModel()
    this._SymbolGroupUpdateNotifySenderModel = new SymbolGroupUpdateNotifyModel(this.sendBuffer)
    this._SymbolGroupUpdateNotifyReceiverValue = new SymbolGroupUpdateNotify()
    this._SymbolGroupUpdateNotifyReceiverModel = new SymbolGroupUpdateNotifyModel()
    this._SymbolGetRequestSenderModel = new SymbolGetRequestModel(this.sendBuffer)
    this._SymbolGetRequestReceiverValue = new SymbolGetRequest()
    this._SymbolGetRequestReceiverModel = new SymbolGetRequestModel()
    this._SymbolGetResponseSenderModel = new SymbolGetResponseModel(this.sendBuffer)
    this._SymbolGetResponseReceiverValue = new SymbolGetResponse()
    this._SymbolGetResponseReceiverModel = new SymbolGetResponseModel()
    this._SymbolsGetAllRequestSenderModel = new SymbolsGetAllRequestModel(this.sendBuffer)
    this._SymbolsGetAllRequestReceiverValue = new SymbolsGetAllRequest()
    this._SymbolsGetAllRequestReceiverModel = new SymbolsGetAllRequestModel()
    this._SymbolsGetAllResponseSenderModel = new SymbolsGetAllResponseModel(this.sendBuffer)
    this._SymbolsGetAllResponseReceiverValue = new SymbolsGetAllResponse()
    this._SymbolsGetAllResponseReceiverModel = new SymbolsGetAllResponseModel()
    this._SymbolUpdateNotifySenderModel = new SymbolUpdateNotifyModel(this.sendBuffer)
    this._SymbolUpdateNotifyReceiverValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyReceiverModel = new SymbolUpdateNotifyModel()
    this._TickGetRequestSenderModel = new TickGetRequestModel(this.sendBuffer)
    this._TickGetRequestReceiverValue = new TickGetRequest()
    this._TickGetRequestReceiverModel = new TickGetRequestModel()
    this._TickGetResponseSenderModel = new TickGetResponseModel(this.sendBuffer)
    this._TickGetResponseReceiverValue = new TickGetResponse()
    this._TickGetResponseReceiverModel = new TickGetResponseModel()
    this._TickGetFilterRequestSenderModel = new TickGetFilterRequestModel(this.sendBuffer)
    this._TickGetFilterRequestReceiverValue = new TickGetFilterRequest()
    this._TickGetFilterRequestReceiverModel = new TickGetFilterRequestModel()
    this._TickGetFilterResponseSenderModel = new TickGetFilterResponseModel(this.sendBuffer)
    this._TickGetFilterResponseReceiverValue = new TickGetFilterResponse()
    this._TickGetFilterResponseReceiverModel = new TickGetFilterResponseModel()
    this._TickGetAllRequestSenderModel = new TickGetAllRequestModel(this.sendBuffer)
    this._TickGetAllRequestReceiverValue = new TickGetAllRequest()
    this._TickGetAllRequestReceiverModel = new TickGetAllRequestModel()
    this._TickGetAllResponseSenderModel = new TickGetAllResponseModel(this.sendBuffer)
    this._TickGetAllResponseReceiverValue = new TickGetAllResponse()
    this._TickGetAllResponseReceiverModel = new TickGetAllResponseModel()
    this._Level2GetRequestSenderModel = new Level2GetRequestModel(this.sendBuffer)
    this._Level2GetRequestReceiverValue = new Level2GetRequest()
    this._Level2GetRequestReceiverModel = new Level2GetRequestModel()
    this._Level2GetResponseSenderModel = new Level2GetResponseModel(this.sendBuffer)
    this._Level2GetResponseReceiverValue = new Level2GetResponse()
    this._Level2GetResponseReceiverModel = new Level2GetResponseModel()
    this._Level2GetFilterRequestSenderModel = new Level2GetFilterRequestModel(this.sendBuffer)
    this._Level2GetFilterRequestReceiverValue = new Level2GetFilterRequest()
    this._Level2GetFilterRequestReceiverModel = new Level2GetFilterRequestModel()
    this._Level2GetFilterResponseSenderModel = new Level2GetFilterResponseModel(this.sendBuffer)
    this._Level2GetFilterResponseReceiverValue = new Level2GetFilterResponse()
    this._Level2GetFilterResponseReceiverModel = new Level2GetFilterResponseModel()
    this._Level2GetAllRequestSenderModel = new Level2GetAllRequestModel(this.sendBuffer)
    this._Level2GetAllRequestReceiverValue = new Level2GetAllRequest()
    this._Level2GetAllRequestReceiverModel = new Level2GetAllRequestModel()
    this._Level2GetAllResponseSenderModel = new Level2GetAllResponseModel(this.sendBuffer)
    this._Level2GetAllResponseReceiverValue = new Level2GetAllResponse()
    this._Level2GetAllResponseReceiverModel = new Level2GetAllResponseModel()
    this._FeedSubscriptionGetRequestSenderModel = new FeedSubscriptionGetRequestModel(this.sendBuffer)
    this._FeedSubscriptionGetRequestReceiverValue = new FeedSubscriptionGetRequest()
    this._FeedSubscriptionGetRequestReceiverModel = new FeedSubscriptionGetRequestModel()
    this._FeedSubscriptionGetResponseSenderModel = new FeedSubscriptionGetResponseModel(this.sendBuffer)
    this._FeedSubscriptionGetResponseReceiverValue = new FeedSubscriptionGetResponse()
    this._FeedSubscriptionGetResponseReceiverModel = new FeedSubscriptionGetResponseModel()
    this._FeedSubscribeRequestSenderModel = new FeedSubscribeRequestModel(this.sendBuffer)
    this._FeedSubscribeRequestReceiverValue = new FeedSubscribeRequest()
    this._FeedSubscribeRequestReceiverModel = new FeedSubscribeRequestModel()
    this._FeedSubscribeAllRequestSenderModel = new FeedSubscribeAllRequestModel(this.sendBuffer)
    this._FeedSubscribeAllRequestReceiverValue = new FeedSubscribeAllRequest()
    this._FeedSubscribeAllRequestReceiverModel = new FeedSubscribeAllRequestModel()
    this._FeedSubscribeResponseSenderModel = new FeedSubscribeResponseModel(this.sendBuffer)
    this._FeedSubscribeResponseReceiverValue = new FeedSubscribeResponse()
    this._FeedSubscribeResponseReceiverModel = new FeedSubscribeResponseModel()
    this._FeedUnsubscribeRequestSenderModel = new FeedUnsubscribeRequestModel(this.sendBuffer)
    this._FeedUnsubscribeRequestReceiverValue = new FeedUnsubscribeRequest()
    this._FeedUnsubscribeRequestReceiverModel = new FeedUnsubscribeRequestModel()
    this._FeedUnsubscribeAllRequestSenderModel = new FeedUnsubscribeAllRequestModel(this.sendBuffer)
    this._FeedUnsubscribeAllRequestReceiverValue = new FeedUnsubscribeAllRequest()
    this._FeedUnsubscribeAllRequestReceiverModel = new FeedUnsubscribeAllRequestModel()
    this._FeedUnsubscribeResponseSenderModel = new FeedUnsubscribeResponseModel(this.sendBuffer)
    this._FeedUnsubscribeResponseReceiverValue = new FeedUnsubscribeResponse()
    this._FeedUnsubscribeResponseReceiverModel = new FeedUnsubscribeResponseModel()
    this._FeedUpdateTickSnapshotRequestSenderModel = new FeedUpdateTickSnapshotRequestModel(this.sendBuffer)
    this._FeedUpdateTickSnapshotRequestReceiverValue = new FeedUpdateTickSnapshotRequest()
    this._FeedUpdateTickSnapshotRequestReceiverModel = new FeedUpdateTickSnapshotRequestModel()
    this._FeedUpdateTickRequestSenderModel = new FeedUpdateTickRequestModel(this.sendBuffer)
    this._FeedUpdateTickRequestReceiverValue = new FeedUpdateTickRequest()
    this._FeedUpdateTickRequestReceiverModel = new FeedUpdateTickRequestModel()
    this._FeedUpdateLevel2SnapshotRequestSenderModel = new FeedUpdateLevel2SnapshotRequestModel(this.sendBuffer)
    this._FeedUpdateLevel2SnapshotRequestReceiverValue = new FeedUpdateLevel2SnapshotRequest()
    this._FeedUpdateLevel2SnapshotRequestReceiverModel = new FeedUpdateLevel2SnapshotRequestModel()
    this._FeedUpdateLevel2RequestSenderModel = new FeedUpdateLevel2RequestModel(this.sendBuffer)
    this._FeedUpdateLevel2RequestReceiverValue = new FeedUpdateLevel2Request()
    this._FeedUpdateLevel2RequestReceiverModel = new FeedUpdateLevel2RequestModel()
    this._FeedUpdateResponseSenderModel = new FeedUpdateResponseModel(this.sendBuffer)
    this._FeedUpdateResponseReceiverValue = new FeedUpdateResponse()
    this._FeedUpdateResponseReceiverModel = new FeedUpdateResponseModel()
    this._TickSnapshotNotifySenderModel = new TickSnapshotNotifyModel(this.sendBuffer)
    this._TickSnapshotNotifyReceiverValue = new TickSnapshotNotify()
    this._TickSnapshotNotifyReceiverModel = new TickSnapshotNotifyModel()
    this._TickUpdateNotifySenderModel = new TickUpdateNotifyModel(this.sendBuffer)
    this._TickUpdateNotifyReceiverValue = new TickUpdateNotify()
    this._TickUpdateNotifyReceiverModel = new TickUpdateNotifyModel()
    this._Level2SnapshotNotifySenderModel = new Level2SnapshotNotifyModel(this.sendBuffer)
    this._Level2SnapshotNotifyReceiverValue = new Level2SnapshotNotify()
    this._Level2SnapshotNotifyReceiverModel = new Level2SnapshotNotifyModel()
    this._Level2UpdateNotifySenderModel = new Level2UpdateNotifyModel(this.sendBuffer)
    this._Level2UpdateNotifyReceiverValue = new Level2UpdateNotify()
    this._Level2UpdateNotifyReceiverModel = new Level2UpdateNotifyModel()
    this._DataFeedModulesGetRequestSenderModel = new DataFeedModulesGetRequestModel(this.sendBuffer)
    this._DataFeedModulesGetRequestReceiverValue = new DataFeedModulesGetRequest()
    this._DataFeedModulesGetRequestReceiverModel = new DataFeedModulesGetRequestModel()
    this._DataFeedModulesGetResponseSenderModel = new DataFeedModulesGetResponseModel(this.sendBuffer)
    this._DataFeedModulesGetResponseReceiverValue = new DataFeedModulesGetResponse()
    this._DataFeedModulesGetResponseReceiverModel = new DataFeedModulesGetResponseModel()
    this._DataFeedModuleUpdateNotifySenderModel = new DataFeedModuleUpdateNotifyModel(this.sendBuffer)
    this._DataFeedModuleUpdateNotifyReceiverValue = new DataFeedModuleUpdateNotify()
    this._DataFeedModuleUpdateNotifyReceiverModel = new DataFeedModuleUpdateNotifyModel()
    this._DataFeedStartRequestSenderModel = new DataFeedStartRequestModel(this.sendBuffer)
    this._DataFeedStartRequestReceiverValue = new DataFeedStartRequest()
    this._DataFeedStartRequestReceiverModel = new DataFeedStartRequestModel()
    this._DataFeedStopRequestSenderModel = new DataFeedStopRequestModel(this.sendBuffer)
    this._DataFeedStopRequestReceiverValue = new DataFeedStopRequest()
    this._DataFeedStopRequestReceiverModel = new DataFeedStopRequestModel()
    this._DataFeedShutdownRequestSenderModel = new DataFeedShutdownRequestModel(this.sendBuffer)
    this._DataFeedShutdownRequestReceiverValue = new DataFeedShutdownRequest()
    this._DataFeedShutdownRequestReceiverModel = new DataFeedShutdownRequestModel()
    this._DataFeedUpdateSubscriptionsRequestSenderModel = new DataFeedUpdateSubscriptionsRequestModel(this.sendBuffer)
    this._DataFeedUpdateSubscriptionsRequestReceiverValue = new DataFeedUpdateSubscriptionsRequest()
    this._DataFeedUpdateSubscriptionsRequestReceiverModel = new DataFeedUpdateSubscriptionsRequestModel()
    this._DataFeedStatusNotifySenderModel = new DataFeedStatusNotifyModel(this.sendBuffer)
    this._DataFeedStatusNotifyReceiverValue = new DataFeedStatusNotify()
    this._DataFeedStatusNotifyReceiverModel = new DataFeedStatusNotifyModel()
    this._FeedUpdateLevel2SnapshotNotifySenderModel = new FeedUpdateLevel2SnapshotNotifyModel(this.sendBuffer)
    this._FeedUpdateLevel2SnapshotNotifyReceiverValue = new FeedUpdateLevel2SnapshotNotify()
    this._FeedUpdateLevel2SnapshotNotifyReceiverModel = new FeedUpdateLevel2SnapshotNotifyModel()
    this._SymbolsUpdateNotifySenderModel = new SymbolsUpdateNotifyModel(this.sendBuffer)
    this._SymbolsUpdateNotifyReceiverValue = new SymbolsUpdateNotify()
    this._SymbolsUpdateNotifyReceiverModel = new SymbolsUpdateNotifyModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get FeedVersionRequest model
   * @this {!Client}
   * @returns {!FeedVersionRequestModel} FeedVersionRequest sender model
   */
  get FeedVersionRequestSenderModel () {
    return this._FeedVersionRequestSenderModel
  }

  /**
   * Get FeedVersionResponse model
   * @this {!Client}
   * @returns {!FeedVersionResponseModel} FeedVersionResponse sender model
   */
  get FeedVersionResponseSenderModel () {
    return this._FeedVersionResponseSenderModel
  }

  /**
   * Get FeedUpdateNotify model
   * @this {!Client}
   * @returns {!FeedUpdateNotifyModel} FeedUpdateNotify sender model
   */
  get FeedUpdateNotifySenderModel () {
    return this._FeedUpdateNotifySenderModel
  }

  /**
   * Get CurrencyGroupGetRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupGetRequestModel} CurrencyGroupGetRequest sender model
   */
  get CurrencyGroupGetRequestSenderModel () {
    return this._CurrencyGroupGetRequestSenderModel
  }

  /**
   * Get CurrencyGroupGetResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupGetResponseModel} CurrencyGroupGetResponse sender model
   */
  get CurrencyGroupGetResponseSenderModel () {
    return this._CurrencyGroupGetResponseSenderModel
  }

  /**
   * Get CurrencyGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!CurrencyGroupsGetAllRequestModel} CurrencyGroupsGetAllRequest sender model
   */
  get CurrencyGroupsGetAllRequestSenderModel () {
    return this._CurrencyGroupsGetAllRequestSenderModel
  }

  /**
   * Get CurrencyGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!CurrencyGroupsGetAllResponseModel} CurrencyGroupsGetAllResponse sender model
   */
  get CurrencyGroupsGetAllResponseSenderModel () {
    return this._CurrencyGroupsGetAllResponseSenderModel
  }

  /**
   * Get CurrencyGroupUpdateNotify model
   * @this {!Client}
   * @returns {!CurrencyGroupUpdateNotifyModel} CurrencyGroupUpdateNotify sender model
   */
  get CurrencyGroupUpdateNotifySenderModel () {
    return this._CurrencyGroupUpdateNotifySenderModel
  }

  /**
   * Get CurrencyGetRequest model
   * @this {!Client}
   * @returns {!CurrencyGetRequestModel} CurrencyGetRequest sender model
   */
  get CurrencyGetRequestSenderModel () {
    return this._CurrencyGetRequestSenderModel
  }

  /**
   * Get CurrencyGetResponse model
   * @this {!Client}
   * @returns {!CurrencyGetResponseModel} CurrencyGetResponse sender model
   */
  get CurrencyGetResponseSenderModel () {
    return this._CurrencyGetResponseSenderModel
  }

  /**
   * Get CurrenciesGetAllRequest model
   * @this {!Client}
   * @returns {!CurrenciesGetAllRequestModel} CurrenciesGetAllRequest sender model
   */
  get CurrenciesGetAllRequestSenderModel () {
    return this._CurrenciesGetAllRequestSenderModel
  }

  /**
   * Get CurrenciesGetAllResponse model
   * @this {!Client}
   * @returns {!CurrenciesGetAllResponseModel} CurrenciesGetAllResponse sender model
   */
  get CurrenciesGetAllResponseSenderModel () {
    return this._CurrenciesGetAllResponseSenderModel
  }

  /**
   * Get CurrencyUpdateNotify model
   * @this {!Client}
   * @returns {!CurrencyUpdateNotifyModel} CurrencyUpdateNotify sender model
   */
  get CurrencyUpdateNotifySenderModel () {
    return this._CurrencyUpdateNotifySenderModel
  }

  /**
   * Get SymbolGroupGetRequest model
   * @this {!Client}
   * @returns {!SymbolGroupGetRequestModel} SymbolGroupGetRequest sender model
   */
  get SymbolGroupGetRequestSenderModel () {
    return this._SymbolGroupGetRequestSenderModel
  }

  /**
   * Get SymbolGroupGetResponse model
   * @this {!Client}
   * @returns {!SymbolGroupGetResponseModel} SymbolGroupGetResponse sender model
   */
  get SymbolGroupGetResponseSenderModel () {
    return this._SymbolGroupGetResponseSenderModel
  }

  /**
   * Get SymbolGroupsGetAllRequest model
   * @this {!Client}
   * @returns {!SymbolGroupsGetAllRequestModel} SymbolGroupsGetAllRequest sender model
   */
  get SymbolGroupsGetAllRequestSenderModel () {
    return this._SymbolGroupsGetAllRequestSenderModel
  }

  /**
   * Get SymbolGroupsGetAllResponse model
   * @this {!Client}
   * @returns {!SymbolGroupsGetAllResponseModel} SymbolGroupsGetAllResponse sender model
   */
  get SymbolGroupsGetAllResponseSenderModel () {
    return this._SymbolGroupsGetAllResponseSenderModel
  }

  /**
   * Get SymbolGroupUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolGroupUpdateNotifyModel} SymbolGroupUpdateNotify sender model
   */
  get SymbolGroupUpdateNotifySenderModel () {
    return this._SymbolGroupUpdateNotifySenderModel
  }

  /**
   * Get SymbolGetRequest model
   * @this {!Client}
   * @returns {!SymbolGetRequestModel} SymbolGetRequest sender model
   */
  get SymbolGetRequestSenderModel () {
    return this._SymbolGetRequestSenderModel
  }

  /**
   * Get SymbolGetResponse model
   * @this {!Client}
   * @returns {!SymbolGetResponseModel} SymbolGetResponse sender model
   */
  get SymbolGetResponseSenderModel () {
    return this._SymbolGetResponseSenderModel
  }

  /**
   * Get SymbolsGetAllRequest model
   * @this {!Client}
   * @returns {!SymbolsGetAllRequestModel} SymbolsGetAllRequest sender model
   */
  get SymbolsGetAllRequestSenderModel () {
    return this._SymbolsGetAllRequestSenderModel
  }

  /**
   * Get SymbolsGetAllResponse model
   * @this {!Client}
   * @returns {!SymbolsGetAllResponseModel} SymbolsGetAllResponse sender model
   */
  get SymbolsGetAllResponseSenderModel () {
    return this._SymbolsGetAllResponseSenderModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify sender model
   */
  get SymbolUpdateNotifySenderModel () {
    return this._SymbolUpdateNotifySenderModel
  }

  /**
   * Get TickGetRequest model
   * @this {!Client}
   * @returns {!TickGetRequestModel} TickGetRequest sender model
   */
  get TickGetRequestSenderModel () {
    return this._TickGetRequestSenderModel
  }

  /**
   * Get TickGetResponse model
   * @this {!Client}
   * @returns {!TickGetResponseModel} TickGetResponse sender model
   */
  get TickGetResponseSenderModel () {
    return this._TickGetResponseSenderModel
  }

  /**
   * Get TickGetFilterRequest model
   * @this {!Client}
   * @returns {!TickGetFilterRequestModel} TickGetFilterRequest sender model
   */
  get TickGetFilterRequestSenderModel () {
    return this._TickGetFilterRequestSenderModel
  }

  /**
   * Get TickGetFilterResponse model
   * @this {!Client}
   * @returns {!TickGetFilterResponseModel} TickGetFilterResponse sender model
   */
  get TickGetFilterResponseSenderModel () {
    return this._TickGetFilterResponseSenderModel
  }

  /**
   * Get TickGetAllRequest model
   * @this {!Client}
   * @returns {!TickGetAllRequestModel} TickGetAllRequest sender model
   */
  get TickGetAllRequestSenderModel () {
    return this._TickGetAllRequestSenderModel
  }

  /**
   * Get TickGetAllResponse model
   * @this {!Client}
   * @returns {!TickGetAllResponseModel} TickGetAllResponse sender model
   */
  get TickGetAllResponseSenderModel () {
    return this._TickGetAllResponseSenderModel
  }

  /**
   * Get Level2GetRequest model
   * @this {!Client}
   * @returns {!Level2GetRequestModel} Level2GetRequest sender model
   */
  get Level2GetRequestSenderModel () {
    return this._Level2GetRequestSenderModel
  }

  /**
   * Get Level2GetResponse model
   * @this {!Client}
   * @returns {!Level2GetResponseModel} Level2GetResponse sender model
   */
  get Level2GetResponseSenderModel () {
    return this._Level2GetResponseSenderModel
  }

  /**
   * Get Level2GetFilterRequest model
   * @this {!Client}
   * @returns {!Level2GetFilterRequestModel} Level2GetFilterRequest sender model
   */
  get Level2GetFilterRequestSenderModel () {
    return this._Level2GetFilterRequestSenderModel
  }

  /**
   * Get Level2GetFilterResponse model
   * @this {!Client}
   * @returns {!Level2GetFilterResponseModel} Level2GetFilterResponse sender model
   */
  get Level2GetFilterResponseSenderModel () {
    return this._Level2GetFilterResponseSenderModel
  }

  /**
   * Get Level2GetAllRequest model
   * @this {!Client}
   * @returns {!Level2GetAllRequestModel} Level2GetAllRequest sender model
   */
  get Level2GetAllRequestSenderModel () {
    return this._Level2GetAllRequestSenderModel
  }

  /**
   * Get Level2GetAllResponse model
   * @this {!Client}
   * @returns {!Level2GetAllResponseModel} Level2GetAllResponse sender model
   */
  get Level2GetAllResponseSenderModel () {
    return this._Level2GetAllResponseSenderModel
  }

  /**
   * Get FeedSubscriptionGetRequest model
   * @this {!Client}
   * @returns {!FeedSubscriptionGetRequestModel} FeedSubscriptionGetRequest sender model
   */
  get FeedSubscriptionGetRequestSenderModel () {
    return this._FeedSubscriptionGetRequestSenderModel
  }

  /**
   * Get FeedSubscriptionGetResponse model
   * @this {!Client}
   * @returns {!FeedSubscriptionGetResponseModel} FeedSubscriptionGetResponse sender model
   */
  get FeedSubscriptionGetResponseSenderModel () {
    return this._FeedSubscriptionGetResponseSenderModel
  }

  /**
   * Get FeedSubscribeRequest model
   * @this {!Client}
   * @returns {!FeedSubscribeRequestModel} FeedSubscribeRequest sender model
   */
  get FeedSubscribeRequestSenderModel () {
    return this._FeedSubscribeRequestSenderModel
  }

  /**
   * Get FeedSubscribeAllRequest model
   * @this {!Client}
   * @returns {!FeedSubscribeAllRequestModel} FeedSubscribeAllRequest sender model
   */
  get FeedSubscribeAllRequestSenderModel () {
    return this._FeedSubscribeAllRequestSenderModel
  }

  /**
   * Get FeedSubscribeResponse model
   * @this {!Client}
   * @returns {!FeedSubscribeResponseModel} FeedSubscribeResponse sender model
   */
  get FeedSubscribeResponseSenderModel () {
    return this._FeedSubscribeResponseSenderModel
  }

  /**
   * Get FeedUnsubscribeRequest model
   * @this {!Client}
   * @returns {!FeedUnsubscribeRequestModel} FeedUnsubscribeRequest sender model
   */
  get FeedUnsubscribeRequestSenderModel () {
    return this._FeedUnsubscribeRequestSenderModel
  }

  /**
   * Get FeedUnsubscribeAllRequest model
   * @this {!Client}
   * @returns {!FeedUnsubscribeAllRequestModel} FeedUnsubscribeAllRequest sender model
   */
  get FeedUnsubscribeAllRequestSenderModel () {
    return this._FeedUnsubscribeAllRequestSenderModel
  }

  /**
   * Get FeedUnsubscribeResponse model
   * @this {!Client}
   * @returns {!FeedUnsubscribeResponseModel} FeedUnsubscribeResponse sender model
   */
  get FeedUnsubscribeResponseSenderModel () {
    return this._FeedUnsubscribeResponseSenderModel
  }

  /**
   * Get FeedUpdateTickSnapshotRequest model
   * @this {!Client}
   * @returns {!FeedUpdateTickSnapshotRequestModel} FeedUpdateTickSnapshotRequest sender model
   */
  get FeedUpdateTickSnapshotRequestSenderModel () {
    return this._FeedUpdateTickSnapshotRequestSenderModel
  }

  /**
   * Get FeedUpdateTickRequest model
   * @this {!Client}
   * @returns {!FeedUpdateTickRequestModel} FeedUpdateTickRequest sender model
   */
  get FeedUpdateTickRequestSenderModel () {
    return this._FeedUpdateTickRequestSenderModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotRequest model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2SnapshotRequestModel} FeedUpdateLevel2SnapshotRequest sender model
   */
  get FeedUpdateLevel2SnapshotRequestSenderModel () {
    return this._FeedUpdateLevel2SnapshotRequestSenderModel
  }

  /**
   * Get FeedUpdateLevel2Request model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2RequestModel} FeedUpdateLevel2Request sender model
   */
  get FeedUpdateLevel2RequestSenderModel () {
    return this._FeedUpdateLevel2RequestSenderModel
  }

  /**
   * Get FeedUpdateResponse model
   * @this {!Client}
   * @returns {!FeedUpdateResponseModel} FeedUpdateResponse sender model
   */
  get FeedUpdateResponseSenderModel () {
    return this._FeedUpdateResponseSenderModel
  }

  /**
   * Get TickSnapshotNotify model
   * @this {!Client}
   * @returns {!TickSnapshotNotifyModel} TickSnapshotNotify sender model
   */
  get TickSnapshotNotifySenderModel () {
    return this._TickSnapshotNotifySenderModel
  }

  /**
   * Get TickUpdateNotify model
   * @this {!Client}
   * @returns {!TickUpdateNotifyModel} TickUpdateNotify sender model
   */
  get TickUpdateNotifySenderModel () {
    return this._TickUpdateNotifySenderModel
  }

  /**
   * Get Level2SnapshotNotify model
   * @this {!Client}
   * @returns {!Level2SnapshotNotifyModel} Level2SnapshotNotify sender model
   */
  get Level2SnapshotNotifySenderModel () {
    return this._Level2SnapshotNotifySenderModel
  }

  /**
   * Get Level2UpdateNotify model
   * @this {!Client}
   * @returns {!Level2UpdateNotifyModel} Level2UpdateNotify sender model
   */
  get Level2UpdateNotifySenderModel () {
    return this._Level2UpdateNotifySenderModel
  }

  /**
   * Get DataFeedModulesGetRequest model
   * @this {!Client}
   * @returns {!DataFeedModulesGetRequestModel} DataFeedModulesGetRequest sender model
   */
  get DataFeedModulesGetRequestSenderModel () {
    return this._DataFeedModulesGetRequestSenderModel
  }

  /**
   * Get DataFeedModulesGetResponse model
   * @this {!Client}
   * @returns {!DataFeedModulesGetResponseModel} DataFeedModulesGetResponse sender model
   */
  get DataFeedModulesGetResponseSenderModel () {
    return this._DataFeedModulesGetResponseSenderModel
  }

  /**
   * Get DataFeedModuleUpdateNotify model
   * @this {!Client}
   * @returns {!DataFeedModuleUpdateNotifyModel} DataFeedModuleUpdateNotify sender model
   */
  get DataFeedModuleUpdateNotifySenderModel () {
    return this._DataFeedModuleUpdateNotifySenderModel
  }

  /**
   * Get DataFeedStartRequest model
   * @this {!Client}
   * @returns {!DataFeedStartRequestModel} DataFeedStartRequest sender model
   */
  get DataFeedStartRequestSenderModel () {
    return this._DataFeedStartRequestSenderModel
  }

  /**
   * Get DataFeedStopRequest model
   * @this {!Client}
   * @returns {!DataFeedStopRequestModel} DataFeedStopRequest sender model
   */
  get DataFeedStopRequestSenderModel () {
    return this._DataFeedStopRequestSenderModel
  }

  /**
   * Get DataFeedShutdownRequest model
   * @this {!Client}
   * @returns {!DataFeedShutdownRequestModel} DataFeedShutdownRequest sender model
   */
  get DataFeedShutdownRequestSenderModel () {
    return this._DataFeedShutdownRequestSenderModel
  }

  /**
   * Get DataFeedUpdateSubscriptionsRequest model
   * @this {!Client}
   * @returns {!DataFeedUpdateSubscriptionsRequestModel} DataFeedUpdateSubscriptionsRequest sender model
   */
  get DataFeedUpdateSubscriptionsRequestSenderModel () {
    return this._DataFeedUpdateSubscriptionsRequestSenderModel
  }

  /**
   * Get DataFeedStatusNotify model
   * @this {!Client}
   * @returns {!DataFeedStatusNotifyModel} DataFeedStatusNotify sender model
   */
  get DataFeedStatusNotifySenderModel () {
    return this._DataFeedStatusNotifySenderModel
  }

  /**
   * Get FeedUpdateLevel2SnapshotNotify model
   * @this {!Client}
   * @returns {!FeedUpdateLevel2SnapshotNotifyModel} FeedUpdateLevel2SnapshotNotify sender model
   */
  get FeedUpdateLevel2SnapshotNotifySenderModel () {
    return this._FeedUpdateLevel2SnapshotNotifySenderModel
  }

  /**
   * Get SymbolsUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolsUpdateNotifyModel} SymbolsUpdateNotify sender model
   */
  get SymbolsUpdateNotifySenderModel () {
    return this._SymbolsUpdateNotifySenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof FeedVersionRequest) && (value.fbeType === this.FeedVersionRequestSenderModel.fbeType)) {
      return this.send_FeedVersionRequest(value)
    }
    if ((value instanceof FeedVersionResponse) && (value.fbeType === this.FeedVersionResponseSenderModel.fbeType)) {
      return this.send_FeedVersionResponse(value)
    }
    if ((value instanceof FeedUpdateNotify) && (value.fbeType === this.FeedUpdateNotifySenderModel.fbeType)) {
      return this.send_FeedUpdateNotify(value)
    }
    if ((value instanceof CurrencyGroupGetRequest) && (value.fbeType === this.CurrencyGroupGetRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupGetRequest(value)
    }
    if ((value instanceof CurrencyGroupGetResponse) && (value.fbeType === this.CurrencyGroupGetResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupGetResponse(value)
    }
    if ((value instanceof CurrencyGroupsGetAllRequest) && (value.fbeType === this.CurrencyGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllRequest(value)
    }
    if ((value instanceof CurrencyGroupsGetAllResponse) && (value.fbeType === this.CurrencyGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_CurrencyGroupsGetAllResponse(value)
    }
    if ((value instanceof CurrencyGroupUpdateNotify) && (value.fbeType === this.CurrencyGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_CurrencyGroupUpdateNotify(value)
    }
    if ((value instanceof CurrencyGetRequest) && (value.fbeType === this.CurrencyGetRequestSenderModel.fbeType)) {
      return this.send_CurrencyGetRequest(value)
    }
    if ((value instanceof CurrencyGetResponse) && (value.fbeType === this.CurrencyGetResponseSenderModel.fbeType)) {
      return this.send_CurrencyGetResponse(value)
    }
    if ((value instanceof CurrenciesGetAllRequest) && (value.fbeType === this.CurrenciesGetAllRequestSenderModel.fbeType)) {
      return this.send_CurrenciesGetAllRequest(value)
    }
    if ((value instanceof CurrenciesGetAllResponse) && (value.fbeType === this.CurrenciesGetAllResponseSenderModel.fbeType)) {
      return this.send_CurrenciesGetAllResponse(value)
    }
    if ((value instanceof CurrencyUpdateNotify) && (value.fbeType === this.CurrencyUpdateNotifySenderModel.fbeType)) {
      return this.send_CurrencyUpdateNotify(value)
    }
    if ((value instanceof SymbolGroupGetRequest) && (value.fbeType === this.SymbolGroupGetRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupGetRequest(value)
    }
    if ((value instanceof SymbolGroupGetResponse) && (value.fbeType === this.SymbolGroupGetResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupGetResponse(value)
    }
    if ((value instanceof SymbolGroupsGetAllRequest) && (value.fbeType === this.SymbolGroupsGetAllRequestSenderModel.fbeType)) {
      return this.send_SymbolGroupsGetAllRequest(value)
    }
    if ((value instanceof SymbolGroupsGetAllResponse) && (value.fbeType === this.SymbolGroupsGetAllResponseSenderModel.fbeType)) {
      return this.send_SymbolGroupsGetAllResponse(value)
    }
    if ((value instanceof SymbolGroupUpdateNotify) && (value.fbeType === this.SymbolGroupUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolGroupUpdateNotify(value)
    }
    if ((value instanceof SymbolGetRequest) && (value.fbeType === this.SymbolGetRequestSenderModel.fbeType)) {
      return this.send_SymbolGetRequest(value)
    }
    if ((value instanceof SymbolGetResponse) && (value.fbeType === this.SymbolGetResponseSenderModel.fbeType)) {
      return this.send_SymbolGetResponse(value)
    }
    if ((value instanceof SymbolsGetAllRequest) && (value.fbeType === this.SymbolsGetAllRequestSenderModel.fbeType)) {
      return this.send_SymbolsGetAllRequest(value)
    }
    if ((value instanceof SymbolsGetAllResponse) && (value.fbeType === this.SymbolsGetAllResponseSenderModel.fbeType)) {
      return this.send_SymbolsGetAllResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof TickGetRequest) && (value.fbeType === this.TickGetRequestSenderModel.fbeType)) {
      return this.send_TickGetRequest(value)
    }
    if ((value instanceof TickGetResponse) && (value.fbeType === this.TickGetResponseSenderModel.fbeType)) {
      return this.send_TickGetResponse(value)
    }
    if ((value instanceof TickGetFilterRequest) && (value.fbeType === this.TickGetFilterRequestSenderModel.fbeType)) {
      return this.send_TickGetFilterRequest(value)
    }
    if ((value instanceof TickGetFilterResponse) && (value.fbeType === this.TickGetFilterResponseSenderModel.fbeType)) {
      return this.send_TickGetFilterResponse(value)
    }
    if ((value instanceof TickGetAllRequest) && (value.fbeType === this.TickGetAllRequestSenderModel.fbeType)) {
      return this.send_TickGetAllRequest(value)
    }
    if ((value instanceof TickGetAllResponse) && (value.fbeType === this.TickGetAllResponseSenderModel.fbeType)) {
      return this.send_TickGetAllResponse(value)
    }
    if ((value instanceof Level2GetRequest) && (value.fbeType === this.Level2GetRequestSenderModel.fbeType)) {
      return this.send_Level2GetRequest(value)
    }
    if ((value instanceof Level2GetResponse) && (value.fbeType === this.Level2GetResponseSenderModel.fbeType)) {
      return this.send_Level2GetResponse(value)
    }
    if ((value instanceof Level2GetFilterRequest) && (value.fbeType === this.Level2GetFilterRequestSenderModel.fbeType)) {
      return this.send_Level2GetFilterRequest(value)
    }
    if ((value instanceof Level2GetFilterResponse) && (value.fbeType === this.Level2GetFilterResponseSenderModel.fbeType)) {
      return this.send_Level2GetFilterResponse(value)
    }
    if ((value instanceof Level2GetAllRequest) && (value.fbeType === this.Level2GetAllRequestSenderModel.fbeType)) {
      return this.send_Level2GetAllRequest(value)
    }
    if ((value instanceof Level2GetAllResponse) && (value.fbeType === this.Level2GetAllResponseSenderModel.fbeType)) {
      return this.send_Level2GetAllResponse(value)
    }
    if ((value instanceof FeedSubscriptionGetRequest) && (value.fbeType === this.FeedSubscriptionGetRequestSenderModel.fbeType)) {
      return this.send_FeedSubscriptionGetRequest(value)
    }
    if ((value instanceof FeedSubscriptionGetResponse) && (value.fbeType === this.FeedSubscriptionGetResponseSenderModel.fbeType)) {
      return this.send_FeedSubscriptionGetResponse(value)
    }
    if ((value instanceof FeedSubscribeRequest) && (value.fbeType === this.FeedSubscribeRequestSenderModel.fbeType)) {
      return this.send_FeedSubscribeRequest(value)
    }
    if ((value instanceof FeedSubscribeAllRequest) && (value.fbeType === this.FeedSubscribeAllRequestSenderModel.fbeType)) {
      return this.send_FeedSubscribeAllRequest(value)
    }
    if ((value instanceof FeedSubscribeResponse) && (value.fbeType === this.FeedSubscribeResponseSenderModel.fbeType)) {
      return this.send_FeedSubscribeResponse(value)
    }
    if ((value instanceof FeedUnsubscribeRequest) && (value.fbeType === this.FeedUnsubscribeRequestSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeRequest(value)
    }
    if ((value instanceof FeedUnsubscribeAllRequest) && (value.fbeType === this.FeedUnsubscribeAllRequestSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeAllRequest(value)
    }
    if ((value instanceof FeedUnsubscribeResponse) && (value.fbeType === this.FeedUnsubscribeResponseSenderModel.fbeType)) {
      return this.send_FeedUnsubscribeResponse(value)
    }
    if ((value instanceof FeedUpdateTickSnapshotRequest) && (value.fbeType === this.FeedUpdateTickSnapshotRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateTickSnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateTickRequest) && (value.fbeType === this.FeedUpdateTickRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateTickRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotRequest) && (value.fbeType === this.FeedUpdateLevel2SnapshotRequestSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotRequest(value)
    }
    if ((value instanceof FeedUpdateLevel2Request) && (value.fbeType === this.FeedUpdateLevel2RequestSenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2Request(value)
    }
    if ((value instanceof FeedUpdateResponse) && (value.fbeType === this.FeedUpdateResponseSenderModel.fbeType)) {
      return this.send_FeedUpdateResponse(value)
    }
    if ((value instanceof TickSnapshotNotify) && (value.fbeType === this.TickSnapshotNotifySenderModel.fbeType)) {
      return this.send_TickSnapshotNotify(value)
    }
    if ((value instanceof TickUpdateNotify) && (value.fbeType === this.TickUpdateNotifySenderModel.fbeType)) {
      return this.send_TickUpdateNotify(value)
    }
    if ((value instanceof Level2SnapshotNotify) && (value.fbeType === this.Level2SnapshotNotifySenderModel.fbeType)) {
      return this.send_Level2SnapshotNotify(value)
    }
    if ((value instanceof Level2UpdateNotify) && (value.fbeType === this.Level2UpdateNotifySenderModel.fbeType)) {
      return this.send_Level2UpdateNotify(value)
    }
    if ((value instanceof DataFeedModulesGetRequest) && (value.fbeType === this.DataFeedModulesGetRequestSenderModel.fbeType)) {
      return this.send_DataFeedModulesGetRequest(value)
    }
    if ((value instanceof DataFeedModulesGetResponse) && (value.fbeType === this.DataFeedModulesGetResponseSenderModel.fbeType)) {
      return this.send_DataFeedModulesGetResponse(value)
    }
    if ((value instanceof DataFeedModuleUpdateNotify) && (value.fbeType === this.DataFeedModuleUpdateNotifySenderModel.fbeType)) {
      return this.send_DataFeedModuleUpdateNotify(value)
    }
    if ((value instanceof DataFeedStartRequest) && (value.fbeType === this.DataFeedStartRequestSenderModel.fbeType)) {
      return this.send_DataFeedStartRequest(value)
    }
    if ((value instanceof DataFeedStopRequest) && (value.fbeType === this.DataFeedStopRequestSenderModel.fbeType)) {
      return this.send_DataFeedStopRequest(value)
    }
    if ((value instanceof DataFeedShutdownRequest) && (value.fbeType === this.DataFeedShutdownRequestSenderModel.fbeType)) {
      return this.send_DataFeedShutdownRequest(value)
    }
    if ((value instanceof DataFeedUpdateSubscriptionsRequest) && (value.fbeType === this.DataFeedUpdateSubscriptionsRequestSenderModel.fbeType)) {
      return this.send_DataFeedUpdateSubscriptionsRequest(value)
    }
    if ((value instanceof DataFeedStatusNotify) && (value.fbeType === this.DataFeedStatusNotifySenderModel.fbeType)) {
      return this.send_DataFeedStatusNotify(value)
    }
    if ((value instanceof FeedUpdateLevel2SnapshotNotify) && (value.fbeType === this.FeedUpdateLevel2SnapshotNotifySenderModel.fbeType)) {
      return this.send_FeedUpdateLevel2SnapshotNotify(value)
    }
    if ((value instanceof SymbolsUpdateNotify) && (value.fbeType === this.SymbolsUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolsUpdateNotify(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send FeedVersionRequest value
   * @this {!Client}
   * @param {!FeedVersionRequest} value FeedVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedVersionRequest serialization failed!')
    console.assert(this.FeedVersionRequestSenderModel.verify(), 'feed.FeedVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedVersionResponse value
   * @this {!Client}
   * @param {!FeedVersionResponse} value FeedVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedVersionResponse serialization failed!')
    console.assert(this.FeedVersionResponseSenderModel.verify(), 'feed.FeedVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateNotify value
   * @this {!Client}
   * @param {!FeedUpdateNotify} value FeedUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateNotify serialization failed!')
    console.assert(this.FeedUpdateNotifySenderModel.verify(), 'feed.FeedUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetRequest value
   * @this {!Client}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupGetRequest serialization failed!')
    console.assert(this.CurrencyGroupGetRequestSenderModel.verify(), 'feed.CurrencyGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupGetResponse value
   * @this {!Client}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupGetResponse serialization failed!')
    console.assert(this.CurrencyGroupGetResponseSenderModel.verify(), 'feed.CurrencyGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllRequest value
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupsGetAllRequest serialization failed!')
    console.assert(this.CurrencyGroupsGetAllRequestSenderModel.verify(), 'feed.CurrencyGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupsGetAllResponse value
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupsGetAllResponse serialization failed!')
    console.assert(this.CurrencyGroupsGetAllResponseSenderModel.verify(), 'feed.CurrencyGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGroupUpdateNotify value
   * @this {!Client}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGroupUpdateNotify serialization failed!')
    console.assert(this.CurrencyGroupUpdateNotifySenderModel.verify(), 'feed.CurrencyGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetRequest value
   * @this {!Client}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGetRequest serialization failed!')
    console.assert(this.CurrencyGetRequestSenderModel.verify(), 'feed.CurrencyGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyGetResponse value
   * @this {!Client}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyGetResponse serialization failed!')
    console.assert(this.CurrencyGetResponseSenderModel.verify(), 'feed.CurrencyGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllRequest value
   * @this {!Client}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrenciesGetAllRequest serialization failed!')
    console.assert(this.CurrenciesGetAllRequestSenderModel.verify(), 'feed.CurrenciesGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrenciesGetAllResponse value
   * @this {!Client}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_CurrenciesGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrenciesGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrenciesGetAllResponse serialization failed!')
    console.assert(this.CurrenciesGetAllResponseSenderModel.verify(), 'feed.CurrenciesGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send CurrencyUpdateNotify value
   * @this {!Client}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_CurrencyUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.CurrencyUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.CurrencyUpdateNotify serialization failed!')
    console.assert(this.CurrencyUpdateNotifySenderModel.verify(), 'feed.CurrencyUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetRequest value
   * @this {!Client}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupGetRequest serialization failed!')
    console.assert(this.SymbolGroupGetRequestSenderModel.verify(), 'feed.SymbolGroupGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupGetResponse value
   * @this {!Client}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupGetResponse serialization failed!')
    console.assert(this.SymbolGroupGetResponseSenderModel.verify(), 'feed.SymbolGroupGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllRequest value
   * @this {!Client}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupsGetAllRequest serialization failed!')
    console.assert(this.SymbolGroupsGetAllRequestSenderModel.verify(), 'feed.SymbolGroupsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupsGetAllResponse value
   * @this {!Client}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupsGetAllResponse serialization failed!')
    console.assert(this.SymbolGroupsGetAllResponseSenderModel.verify(), 'feed.SymbolGroupsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGroupUpdateNotify value
   * @this {!Client}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGroupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGroupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGroupUpdateNotify serialization failed!')
    console.assert(this.SymbolGroupUpdateNotifySenderModel.verify(), 'feed.SymbolGroupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetRequest value
   * @this {!Client}
   * @param {!SymbolGetRequest} value SymbolGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGetRequest serialization failed!')
    console.assert(this.SymbolGetRequestSenderModel.verify(), 'feed.SymbolGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolGetResponse value
   * @this {!Client}
   * @param {!SymbolGetResponse} value SymbolGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolGetResponse serialization failed!')
    console.assert(this.SymbolGetResponseSenderModel.verify(), 'feed.SymbolGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllRequest value
   * @this {!Client}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsGetAllRequest serialization failed!')
    console.assert(this.SymbolsGetAllRequestSenderModel.verify(), 'feed.SymbolsGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsGetAllResponse value
   * @this {!Client}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsGetAllResponse serialization failed!')
    console.assert(this.SymbolsGetAllResponseSenderModel.verify(), 'feed.SymbolsGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifySenderModel.verify(), 'feed.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetRequest value
   * @this {!Client}
   * @param {!TickGetRequest} value TickGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetRequest serialization failed!')
    console.assert(this.TickGetRequestSenderModel.verify(), 'feed.TickGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetResponse value
   * @this {!Client}
   * @param {!TickGetResponse} value TickGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetResponse serialization failed!')
    console.assert(this.TickGetResponseSenderModel.verify(), 'feed.TickGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetFilterRequest value
   * @this {!Client}
   * @param {!TickGetFilterRequest} value TickGetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetFilterRequest serialization failed!')
    console.assert(this.TickGetFilterRequestSenderModel.verify(), 'feed.TickGetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetFilterResponse value
   * @this {!Client}
   * @param {!TickGetFilterResponse} value TickGetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetFilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetFilterResponse serialization failed!')
    console.assert(this.TickGetFilterResponseSenderModel.verify(), 'feed.TickGetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetAllRequest value
   * @this {!Client}
   * @param {!TickGetAllRequest} value TickGetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetAllRequest serialization failed!')
    console.assert(this.TickGetAllRequestSenderModel.verify(), 'feed.TickGetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickGetAllResponse value
   * @this {!Client}
   * @param {!TickGetAllResponse} value TickGetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_TickGetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickGetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickGetAllResponse serialization failed!')
    console.assert(this.TickGetAllResponseSenderModel.verify(), 'feed.TickGetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetRequest value
   * @this {!Client}
   * @param {!Level2GetRequest} value Level2GetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetRequest serialization failed!')
    console.assert(this.Level2GetRequestSenderModel.verify(), 'feed.Level2GetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetResponse value
   * @this {!Client}
   * @param {!Level2GetResponse} value Level2GetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetResponse serialization failed!')
    console.assert(this.Level2GetResponseSenderModel.verify(), 'feed.Level2GetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetFilterRequest value
   * @this {!Client}
   * @param {!Level2GetFilterRequest} value Level2GetFilterRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetFilterRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetFilterRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetFilterRequest serialization failed!')
    console.assert(this.Level2GetFilterRequestSenderModel.verify(), 'feed.Level2GetFilterRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetFilterResponse value
   * @this {!Client}
   * @param {!Level2GetFilterResponse} value Level2GetFilterResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetFilterResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetFilterResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetFilterResponse serialization failed!')
    console.assert(this.Level2GetFilterResponseSenderModel.verify(), 'feed.Level2GetFilterResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetAllRequest value
   * @this {!Client}
   * @param {!Level2GetAllRequest} value Level2GetAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetAllRequest serialization failed!')
    console.assert(this.Level2GetAllRequestSenderModel.verify(), 'feed.Level2GetAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2GetAllResponse value
   * @this {!Client}
   * @param {!Level2GetAllResponse} value Level2GetAllResponse value to send
   * @returns {!number} Sent bytes
   */
  send_Level2GetAllResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2GetAllResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2GetAllResponse serialization failed!')
    console.assert(this.Level2GetAllResponseSenderModel.verify(), 'feed.Level2GetAllResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionGetRequest value
   * @this {!Client}
   * @param {!FeedSubscriptionGetRequest} value FeedSubscriptionGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscriptionGetRequest serialization failed!')
    console.assert(this.FeedSubscriptionGetRequestSenderModel.verify(), 'feed.FeedSubscriptionGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscriptionGetResponse value
   * @this {!Client}
   * @param {!FeedSubscriptionGetResponse} value FeedSubscriptionGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscriptionGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscriptionGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscriptionGetResponse serialization failed!')
    console.assert(this.FeedSubscriptionGetResponseSenderModel.verify(), 'feed.FeedSubscriptionGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeRequest value
   * @this {!Client}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeRequest serialization failed!')
    console.assert(this.FeedSubscribeRequestSenderModel.verify(), 'feed.FeedSubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeAllRequest value
   * @this {!Client}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeAllRequest serialization failed!')
    console.assert(this.FeedSubscribeAllRequestSenderModel.verify(), 'feed.FeedSubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedSubscribeResponse value
   * @this {!Client}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedSubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedSubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedSubscribeResponse serialization failed!')
    console.assert(this.FeedSubscribeResponseSenderModel.verify(), 'feed.FeedSubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeRequest value
   * @this {!Client}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeRequest serialization failed!')
    console.assert(this.FeedUnsubscribeRequestSenderModel.verify(), 'feed.FeedUnsubscribeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeAllRequest value
   * @this {!Client}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeAllRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeAllRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeAllRequest serialization failed!')
    console.assert(this.FeedUnsubscribeAllRequestSenderModel.verify(), 'feed.FeedUnsubscribeAllRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUnsubscribeResponse value
   * @this {!Client}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUnsubscribeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUnsubscribeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUnsubscribeResponse serialization failed!')
    console.assert(this.FeedUnsubscribeResponseSenderModel.verify(), 'feed.FeedUnsubscribeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickSnapshotRequest value
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickSnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateTickSnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateTickSnapshotRequestSenderModel.verify(), 'feed.FeedUpdateTickSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateTickRequest value
   * @this {!Client}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateTickRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateTickRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateTickRequest serialization failed!')
    console.assert(this.FeedUpdateTickRequestSenderModel.verify(), 'feed.FeedUpdateTickRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotRequest value
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2SnapshotRequest serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotRequestSenderModel.verify(), 'feed.FeedUpdateLevel2SnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2Request value
   * @this {!Client}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2Request serialization failed!')
    console.assert(this.FeedUpdateLevel2RequestSenderModel.verify(), 'feed.FeedUpdateLevel2Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateResponse value
   * @this {!Client}
   * @param {!FeedUpdateResponse} value FeedUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateResponse serialization failed!')
    console.assert(this.FeedUpdateResponseSenderModel.verify(), 'feed.FeedUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickSnapshotNotify value
   * @this {!Client}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickSnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickSnapshotNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickSnapshotNotify serialization failed!')
    console.assert(this.TickSnapshotNotifySenderModel.verify(), 'feed.TickSnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send TickUpdateNotify value
   * @this {!Client}
   * @param {!TickUpdateNotify} value TickUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_TickUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.TickUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.TickUpdateNotify serialization failed!')
    console.assert(this.TickUpdateNotifySenderModel.verify(), 'feed.TickUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2SnapshotNotify value
   * @this {!Client}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2SnapshotNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2SnapshotNotify serialization failed!')
    console.assert(this.Level2SnapshotNotifySenderModel.verify(), 'feed.Level2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Level2UpdateNotify value
   * @this {!Client}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_Level2UpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.Level2UpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.Level2UpdateNotify serialization failed!')
    console.assert(this.Level2UpdateNotifySenderModel.verify(), 'feed.Level2UpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModulesGetRequest value
   * @this {!Client}
   * @param {!DataFeedModulesGetRequest} value DataFeedModulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModulesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModulesGetRequest serialization failed!')
    console.assert(this.DataFeedModulesGetRequestSenderModel.verify(), 'feed.DataFeedModulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModulesGetResponse value
   * @this {!Client}
   * @param {!DataFeedModulesGetResponse} value DataFeedModulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModulesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModulesGetResponse serialization failed!')
    console.assert(this.DataFeedModulesGetResponseSenderModel.verify(), 'feed.DataFeedModulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedModuleUpdateNotify value
   * @this {!Client}
   * @param {!DataFeedModuleUpdateNotify} value DataFeedModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedModuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedModuleUpdateNotify serialization failed!')
    console.assert(this.DataFeedModuleUpdateNotifySenderModel.verify(), 'feed.DataFeedModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStartRequest value
   * @this {!Client}
   * @param {!DataFeedStartRequest} value DataFeedStartRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStartRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStartRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStartRequest serialization failed!')
    console.assert(this.DataFeedStartRequestSenderModel.verify(), 'feed.DataFeedStartRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStopRequest value
   * @this {!Client}
   * @param {!DataFeedStopRequest} value DataFeedStopRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStopRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStopRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStopRequest serialization failed!')
    console.assert(this.DataFeedStopRequestSenderModel.verify(), 'feed.DataFeedStopRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedShutdownRequest value
   * @this {!Client}
   * @param {!DataFeedShutdownRequest} value DataFeedShutdownRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedShutdownRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedShutdownRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedShutdownRequest serialization failed!')
    console.assert(this.DataFeedShutdownRequestSenderModel.verify(), 'feed.DataFeedShutdownRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedUpdateSubscriptionsRequest value
   * @this {!Client}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedUpdateSubscriptionsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedUpdateSubscriptionsRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedUpdateSubscriptionsRequest serialization failed!')
    console.assert(this.DataFeedUpdateSubscriptionsRequestSenderModel.verify(), 'feed.DataFeedUpdateSubscriptionsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DataFeedStatusNotify value
   * @this {!Client}
   * @param {!DataFeedStatusNotify} value DataFeedStatusNotify value to send
   * @returns {!number} Sent bytes
   */
  send_DataFeedStatusNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DataFeedStatusNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.DataFeedStatusNotify serialization failed!')
    console.assert(this.DataFeedStatusNotifySenderModel.verify(), 'feed.DataFeedStatusNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send FeedUpdateLevel2SnapshotNotify value
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotNotify} value FeedUpdateLevel2SnapshotNotify value to send
   * @returns {!number} Sent bytes
   */
  send_FeedUpdateLevel2SnapshotNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.FeedUpdateLevel2SnapshotNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.FeedUpdateLevel2SnapshotNotify serialization failed!')
    console.assert(this.FeedUpdateLevel2SnapshotNotifySenderModel.verify(), 'feed.FeedUpdateLevel2SnapshotNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsUpdateNotify value
   * @this {!Client}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'feed.SymbolsUpdateNotify serialization failed!')
    console.assert(this.SymbolsUpdateNotifySenderModel.verify(), 'feed.SymbolsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'feed.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * FeedVersionRequest receive handler
   * @this {!Client}
   * @param {!FeedVersionRequest} value FeedVersionRequest received value
   */
  onReceive_FeedVersionRequest (value) {}  // eslint-disable-line

  /**
   * FeedVersionResponse receive handler
   * @this {!Client}
   * @param {!FeedVersionResponse} value FeedVersionResponse received value
   */
  onReceive_FeedVersionResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateNotify receive handler
   * @this {!Client}
   * @param {!FeedUpdateNotify} value FeedUpdateNotify received value
   */
  onReceive_FeedUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupGetRequest} value CurrencyGroupGetRequest received value
   */
  onReceive_CurrencyGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupGetResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupGetResponse} value CurrencyGroupGetResponse received value
   */
  onReceive_CurrencyGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllRequest} value CurrencyGroupsGetAllRequest received value
   */
  onReceive_CurrencyGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGroupsGetAllResponse} value CurrencyGroupsGetAllResponse received value
   */
  onReceive_CurrencyGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!CurrencyGroupUpdateNotify} value CurrencyGroupUpdateNotify received value
   */
  onReceive_CurrencyGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * CurrencyGetRequest receive handler
   * @this {!Client}
   * @param {!CurrencyGetRequest} value CurrencyGetRequest received value
   */
  onReceive_CurrencyGetRequest (value) {}  // eslint-disable-line

  /**
   * CurrencyGetResponse receive handler
   * @this {!Client}
   * @param {!CurrencyGetResponse} value CurrencyGetResponse received value
   */
  onReceive_CurrencyGetResponse (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllRequest receive handler
   * @this {!Client}
   * @param {!CurrenciesGetAllRequest} value CurrenciesGetAllRequest received value
   */
  onReceive_CurrenciesGetAllRequest (value) {}  // eslint-disable-line

  /**
   * CurrenciesGetAllResponse receive handler
   * @this {!Client}
   * @param {!CurrenciesGetAllResponse} value CurrenciesGetAllResponse received value
   */
  onReceive_CurrenciesGetAllResponse (value) {}  // eslint-disable-line

  /**
   * CurrencyUpdateNotify receive handler
   * @this {!Client}
   * @param {!CurrencyUpdateNotify} value CurrencyUpdateNotify received value
   */
  onReceive_CurrencyUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupGetRequest} value SymbolGroupGetRequest received value
   */
  onReceive_SymbolGroupGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupGetResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupGetResponse} value SymbolGroupGetResponse received value
   */
  onReceive_SymbolGroupGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllRequest receive handler
   * @this {!Client}
   * @param {!SymbolGroupsGetAllRequest} value SymbolGroupsGetAllRequest received value
   */
  onReceive_SymbolGroupsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGroupsGetAllResponse receive handler
   * @this {!Client}
   * @param {!SymbolGroupsGetAllResponse} value SymbolGroupsGetAllResponse received value
   */
  onReceive_SymbolGroupsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolGroupUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolGroupUpdateNotify} value SymbolGroupUpdateNotify received value
   */
  onReceive_SymbolGroupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolGetRequest receive handler
   * @this {!Client}
   * @param {!SymbolGetRequest} value SymbolGetRequest received value
   */
  onReceive_SymbolGetRequest (value) {}  // eslint-disable-line

  /**
   * SymbolGetResponse receive handler
   * @this {!Client}
   * @param {!SymbolGetResponse} value SymbolGetResponse received value
   */
  onReceive_SymbolGetResponse (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllRequest receive handler
   * @this {!Client}
   * @param {!SymbolsGetAllRequest} value SymbolsGetAllRequest received value
   */
  onReceive_SymbolsGetAllRequest (value) {}  // eslint-disable-line

  /**
   * SymbolsGetAllResponse receive handler
   * @this {!Client}
   * @param {!SymbolsGetAllResponse} value SymbolsGetAllResponse received value
   */
  onReceive_SymbolsGetAllResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * TickGetRequest receive handler
   * @this {!Client}
   * @param {!TickGetRequest} value TickGetRequest received value
   */
  onReceive_TickGetRequest (value) {}  // eslint-disable-line

  /**
   * TickGetResponse receive handler
   * @this {!Client}
   * @param {!TickGetResponse} value TickGetResponse received value
   */
  onReceive_TickGetResponse (value) {}  // eslint-disable-line

  /**
   * TickGetFilterRequest receive handler
   * @this {!Client}
   * @param {!TickGetFilterRequest} value TickGetFilterRequest received value
   */
  onReceive_TickGetFilterRequest (value) {}  // eslint-disable-line

  /**
   * TickGetFilterResponse receive handler
   * @this {!Client}
   * @param {!TickGetFilterResponse} value TickGetFilterResponse received value
   */
  onReceive_TickGetFilterResponse (value) {}  // eslint-disable-line

  /**
   * TickGetAllRequest receive handler
   * @this {!Client}
   * @param {!TickGetAllRequest} value TickGetAllRequest received value
   */
  onReceive_TickGetAllRequest (value) {}  // eslint-disable-line

  /**
   * TickGetAllResponse receive handler
   * @this {!Client}
   * @param {!TickGetAllResponse} value TickGetAllResponse received value
   */
  onReceive_TickGetAllResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetRequest receive handler
   * @this {!Client}
   * @param {!Level2GetRequest} value Level2GetRequest received value
   */
  onReceive_Level2GetRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetResponse receive handler
   * @this {!Client}
   * @param {!Level2GetResponse} value Level2GetResponse received value
   */
  onReceive_Level2GetResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetFilterRequest receive handler
   * @this {!Client}
   * @param {!Level2GetFilterRequest} value Level2GetFilterRequest received value
   */
  onReceive_Level2GetFilterRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetFilterResponse receive handler
   * @this {!Client}
   * @param {!Level2GetFilterResponse} value Level2GetFilterResponse received value
   */
  onReceive_Level2GetFilterResponse (value) {}  // eslint-disable-line

  /**
   * Level2GetAllRequest receive handler
   * @this {!Client}
   * @param {!Level2GetAllRequest} value Level2GetAllRequest received value
   */
  onReceive_Level2GetAllRequest (value) {}  // eslint-disable-line

  /**
   * Level2GetAllResponse receive handler
   * @this {!Client}
   * @param {!Level2GetAllResponse} value Level2GetAllResponse received value
   */
  onReceive_Level2GetAllResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscriptionGetRequest} value FeedSubscriptionGetRequest received value
   */
  onReceive_FeedSubscriptionGetRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscriptionGetResponse receive handler
   * @this {!Client}
   * @param {!FeedSubscriptionGetResponse} value FeedSubscriptionGetResponse received value
   */
  onReceive_FeedSubscriptionGetResponse (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscribeRequest} value FeedSubscribeRequest received value
   */
  onReceive_FeedSubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeAllRequest receive handler
   * @this {!Client}
   * @param {!FeedSubscribeAllRequest} value FeedSubscribeAllRequest received value
   */
  onReceive_FeedSubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedSubscribeResponse receive handler
   * @this {!Client}
   * @param {!FeedSubscribeResponse} value FeedSubscribeResponse received value
   */
  onReceive_FeedSubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeRequest receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeRequest} value FeedUnsubscribeRequest received value
   */
  onReceive_FeedUnsubscribeRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeAllRequest receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeAllRequest} value FeedUnsubscribeAllRequest received value
   */
  onReceive_FeedUnsubscribeAllRequest (value) {}  // eslint-disable-line

  /**
   * FeedUnsubscribeResponse receive handler
   * @this {!Client}
   * @param {!FeedUnsubscribeResponse} value FeedUnsubscribeResponse received value
   */
  onReceive_FeedUnsubscribeResponse (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickSnapshotRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickSnapshotRequest} value FeedUpdateTickSnapshotRequest received value
   */
  onReceive_FeedUpdateTickSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateTickRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateTickRequest} value FeedUpdateTickRequest received value
   */
  onReceive_FeedUpdateTickRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotRequest receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotRequest} value FeedUpdateLevel2SnapshotRequest received value
   */
  onReceive_FeedUpdateLevel2SnapshotRequest (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2Request receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2Request} value FeedUpdateLevel2Request received value
   */
  onReceive_FeedUpdateLevel2Request (value) {}  // eslint-disable-line

  /**
   * FeedUpdateResponse receive handler
   * @this {!Client}
   * @param {!FeedUpdateResponse} value FeedUpdateResponse received value
   */
  onReceive_FeedUpdateResponse (value) {}  // eslint-disable-line

  /**
   * TickSnapshotNotify receive handler
   * @this {!Client}
   * @param {!TickSnapshotNotify} value TickSnapshotNotify received value
   */
  onReceive_TickSnapshotNotify (value) {}  // eslint-disable-line

  /**
   * TickUpdateNotify receive handler
   * @this {!Client}
   * @param {!TickUpdateNotify} value TickUpdateNotify received value
   */
  onReceive_TickUpdateNotify (value) {}  // eslint-disable-line

  /**
   * Level2SnapshotNotify receive handler
   * @this {!Client}
   * @param {!Level2SnapshotNotify} value Level2SnapshotNotify received value
   */
  onReceive_Level2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * Level2UpdateNotify receive handler
   * @this {!Client}
   * @param {!Level2UpdateNotify} value Level2UpdateNotify received value
   */
  onReceive_Level2UpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetRequest receive handler
   * @this {!Client}
   * @param {!DataFeedModulesGetRequest} value DataFeedModulesGetRequest received value
   */
  onReceive_DataFeedModulesGetRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedModulesGetResponse receive handler
   * @this {!Client}
   * @param {!DataFeedModulesGetResponse} value DataFeedModulesGetResponse received value
   */
  onReceive_DataFeedModulesGetResponse (value) {}  // eslint-disable-line

  /**
   * DataFeedModuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!DataFeedModuleUpdateNotify} value DataFeedModuleUpdateNotify received value
   */
  onReceive_DataFeedModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * DataFeedStartRequest receive handler
   * @this {!Client}
   * @param {!DataFeedStartRequest} value DataFeedStartRequest received value
   */
  onReceive_DataFeedStartRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedStopRequest receive handler
   * @this {!Client}
   * @param {!DataFeedStopRequest} value DataFeedStopRequest received value
   */
  onReceive_DataFeedStopRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedShutdownRequest receive handler
   * @this {!Client}
   * @param {!DataFeedShutdownRequest} value DataFeedShutdownRequest received value
   */
  onReceive_DataFeedShutdownRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedUpdateSubscriptionsRequest receive handler
   * @this {!Client}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest received value
   */
  onReceive_DataFeedUpdateSubscriptionsRequest (value) {}  // eslint-disable-line

  /**
   * DataFeedStatusNotify receive handler
   * @this {!Client}
   * @param {!DataFeedStatusNotify} value DataFeedStatusNotify received value
   */
  onReceive_DataFeedStatusNotify (value) {}  // eslint-disable-line

  /**
   * FeedUpdateLevel2SnapshotNotify receive handler
   * @this {!Client}
   * @param {!FeedUpdateLevel2SnapshotNotify} value FeedUpdateLevel2SnapshotNotify received value
   */
  onReceive_FeedUpdateLevel2SnapshotNotify (value) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify received value
   */
  onReceive_SymbolsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * feed receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case FeedVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionRequestReceiverModel.verify(), 'feed.FeedVersionRequest validation failed!')
        let deserialized = this._FeedVersionRequestReceiverModel.deserialize(this._FeedVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedVersionRequestReceiverValue)
        this.onReceive_FeedVersionRequest(this._FeedVersionRequestReceiverValue)
        return true
      }
      case FeedVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedVersionResponseReceiverModel.verify(), 'feed.FeedVersionResponse validation failed!')
        let deserialized = this._FeedVersionResponseReceiverModel.deserialize(this._FeedVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedVersionResponseReceiverValue)
        this.onReceive_FeedVersionResponse(this._FeedVersionResponseReceiverValue)
        return true
      }
      case FeedUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateNotifyReceiverModel.verify(), 'feed.FeedUpdateNotify validation failed!')
        let deserialized = this._FeedUpdateNotifyReceiverModel.deserialize(this._FeedUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateNotifyReceiverValue)
        this.onReceive_FeedUpdateNotify(this._FeedUpdateNotifyReceiverValue)
        return true
      }
      case CurrencyGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetRequestReceiverModel.verify(), 'feed.CurrencyGroupGetRequest validation failed!')
        let deserialized = this._CurrencyGroupGetRequestReceiverModel.deserialize(this._CurrencyGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetRequestReceiverValue)
        this.onReceive_CurrencyGroupGetRequest(this._CurrencyGroupGetRequestReceiverValue)
        return true
      }
      case CurrencyGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupGetResponseReceiverModel.verify(), 'feed.CurrencyGroupGetResponse validation failed!')
        let deserialized = this._CurrencyGroupGetResponseReceiverModel.deserialize(this._CurrencyGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupGetResponseReceiverValue)
        this.onReceive_CurrencyGroupGetResponse(this._CurrencyGroupGetResponseReceiverValue)
        return true
      }
      case CurrencyGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllRequestReceiverModel.verify(), 'feed.CurrencyGroupsGetAllRequest validation failed!')
        let deserialized = this._CurrencyGroupsGetAllRequestReceiverModel.deserialize(this._CurrencyGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllRequestReceiverValue)
        this.onReceive_CurrencyGroupsGetAllRequest(this._CurrencyGroupsGetAllRequestReceiverValue)
        return true
      }
      case CurrencyGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupsGetAllResponseReceiverModel.verify(), 'feed.CurrencyGroupsGetAllResponse validation failed!')
        let deserialized = this._CurrencyGroupsGetAllResponseReceiverModel.deserialize(this._CurrencyGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupsGetAllResponseReceiverValue)
        this.onReceive_CurrencyGroupsGetAllResponse(this._CurrencyGroupsGetAllResponseReceiverValue)
        return true
      }
      case CurrencyGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGroupUpdateNotifyReceiverModel.verify(), 'feed.CurrencyGroupUpdateNotify validation failed!')
        let deserialized = this._CurrencyGroupUpdateNotifyReceiverModel.deserialize(this._CurrencyGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGroupUpdateNotifyReceiverValue)
        this.onReceive_CurrencyGroupUpdateNotify(this._CurrencyGroupUpdateNotifyReceiverValue)
        return true
      }
      case CurrencyGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetRequestReceiverModel.verify(), 'feed.CurrencyGetRequest validation failed!')
        let deserialized = this._CurrencyGetRequestReceiverModel.deserialize(this._CurrencyGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetRequestReceiverValue)
        this.onReceive_CurrencyGetRequest(this._CurrencyGetRequestReceiverValue)
        return true
      }
      case CurrencyGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyGetResponseReceiverModel.verify(), 'feed.CurrencyGetResponse validation failed!')
        let deserialized = this._CurrencyGetResponseReceiverModel.deserialize(this._CurrencyGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyGetResponseReceiverValue)
        this.onReceive_CurrencyGetResponse(this._CurrencyGetResponseReceiverValue)
        return true
      }
      case CurrenciesGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllRequestReceiverModel.verify(), 'feed.CurrenciesGetAllRequest validation failed!')
        let deserialized = this._CurrenciesGetAllRequestReceiverModel.deserialize(this._CurrenciesGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrenciesGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllRequestReceiverValue)
        this.onReceive_CurrenciesGetAllRequest(this._CurrenciesGetAllRequestReceiverValue)
        return true
      }
      case CurrenciesGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrenciesGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrenciesGetAllResponseReceiverModel.verify(), 'feed.CurrenciesGetAllResponse validation failed!')
        let deserialized = this._CurrenciesGetAllResponseReceiverModel.deserialize(this._CurrenciesGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrenciesGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrenciesGetAllResponseReceiverValue)
        this.onReceive_CurrenciesGetAllResponse(this._CurrenciesGetAllResponseReceiverValue)
        return true
      }
      case CurrencyUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._CurrencyUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._CurrencyUpdateNotifyReceiverModel.verify(), 'feed.CurrencyUpdateNotify validation failed!')
        let deserialized = this._CurrencyUpdateNotifyReceiverModel.deserialize(this._CurrencyUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.CurrencyUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._CurrencyUpdateNotifyReceiverValue)
        this.onReceive_CurrencyUpdateNotify(this._CurrencyUpdateNotifyReceiverValue)
        return true
      }
      case SymbolGroupGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetRequestReceiverModel.verify(), 'feed.SymbolGroupGetRequest validation failed!')
        let deserialized = this._SymbolGroupGetRequestReceiverModel.deserialize(this._SymbolGroupGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetRequestReceiverValue)
        this.onReceive_SymbolGroupGetRequest(this._SymbolGroupGetRequestReceiverValue)
        return true
      }
      case SymbolGroupGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupGetResponseReceiverModel.verify(), 'feed.SymbolGroupGetResponse validation failed!')
        let deserialized = this._SymbolGroupGetResponseReceiverModel.deserialize(this._SymbolGroupGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupGetResponseReceiverValue)
        this.onReceive_SymbolGroupGetResponse(this._SymbolGroupGetResponseReceiverValue)
        return true
      }
      case SymbolGroupsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllRequestReceiverModel.verify(), 'feed.SymbolGroupsGetAllRequest validation failed!')
        let deserialized = this._SymbolGroupsGetAllRequestReceiverModel.deserialize(this._SymbolGroupsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllRequestReceiverValue)
        this.onReceive_SymbolGroupsGetAllRequest(this._SymbolGroupsGetAllRequestReceiverValue)
        return true
      }
      case SymbolGroupsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupsGetAllResponseReceiverModel.verify(), 'feed.SymbolGroupsGetAllResponse validation failed!')
        let deserialized = this._SymbolGroupsGetAllResponseReceiverModel.deserialize(this._SymbolGroupsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupsGetAllResponseReceiverValue)
        this.onReceive_SymbolGroupsGetAllResponse(this._SymbolGroupsGetAllResponseReceiverValue)
        return true
      }
      case SymbolGroupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGroupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGroupUpdateNotifyReceiverModel.verify(), 'feed.SymbolGroupUpdateNotify validation failed!')
        let deserialized = this._SymbolGroupUpdateNotifyReceiverModel.deserialize(this._SymbolGroupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGroupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGroupUpdateNotifyReceiverValue)
        this.onReceive_SymbolGroupUpdateNotify(this._SymbolGroupUpdateNotifyReceiverValue)
        return true
      }
      case SymbolGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetRequestReceiverModel.verify(), 'feed.SymbolGetRequest validation failed!')
        let deserialized = this._SymbolGetRequestReceiverModel.deserialize(this._SymbolGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetRequestReceiverValue)
        this.onReceive_SymbolGetRequest(this._SymbolGetRequestReceiverValue)
        return true
      }
      case SymbolGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolGetResponseReceiverModel.verify(), 'feed.SymbolGetResponse validation failed!')
        let deserialized = this._SymbolGetResponseReceiverModel.deserialize(this._SymbolGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolGetResponseReceiverValue)
        this.onReceive_SymbolGetResponse(this._SymbolGetResponseReceiverValue)
        return true
      }
      case SymbolsGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllRequestReceiverModel.verify(), 'feed.SymbolsGetAllRequest validation failed!')
        let deserialized = this._SymbolsGetAllRequestReceiverModel.deserialize(this._SymbolsGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllRequestReceiverValue)
        this.onReceive_SymbolsGetAllRequest(this._SymbolsGetAllRequestReceiverValue)
        return true
      }
      case SymbolsGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsGetAllResponseReceiverModel.verify(), 'feed.SymbolsGetAllResponse validation failed!')
        let deserialized = this._SymbolsGetAllResponseReceiverModel.deserialize(this._SymbolsGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsGetAllResponseReceiverValue)
        this.onReceive_SymbolsGetAllResponse(this._SymbolsGetAllResponseReceiverValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyReceiverModel.verify(), 'feed.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyReceiverModel.deserialize(this._SymbolUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyReceiverValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyReceiverValue)
        return true
      }
      case TickGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetRequestReceiverModel.verify(), 'feed.TickGetRequest validation failed!')
        let deserialized = this._TickGetRequestReceiverModel.deserialize(this._TickGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetRequestReceiverValue)
        this.onReceive_TickGetRequest(this._TickGetRequestReceiverValue)
        return true
      }
      case TickGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetResponseReceiverModel.verify(), 'feed.TickGetResponse validation failed!')
        let deserialized = this._TickGetResponseReceiverModel.deserialize(this._TickGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetResponseReceiverValue)
        this.onReceive_TickGetResponse(this._TickGetResponseReceiverValue)
        return true
      }
      case TickGetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterRequestReceiverModel.verify(), 'feed.TickGetFilterRequest validation failed!')
        let deserialized = this._TickGetFilterRequestReceiverModel.deserialize(this._TickGetFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetFilterRequestReceiverValue)
        this.onReceive_TickGetFilterRequest(this._TickGetFilterRequestReceiverValue)
        return true
      }
      case TickGetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetFilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetFilterResponseReceiverModel.verify(), 'feed.TickGetFilterResponse validation failed!')
        let deserialized = this._TickGetFilterResponseReceiverModel.deserialize(this._TickGetFilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetFilterResponseReceiverValue)
        this.onReceive_TickGetFilterResponse(this._TickGetFilterResponseReceiverValue)
        return true
      }
      case TickGetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllRequestReceiverModel.verify(), 'feed.TickGetAllRequest validation failed!')
        let deserialized = this._TickGetAllRequestReceiverModel.deserialize(this._TickGetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetAllRequestReceiverValue)
        this.onReceive_TickGetAllRequest(this._TickGetAllRequestReceiverValue)
        return true
      }
      case TickGetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickGetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickGetAllResponseReceiverModel.verify(), 'feed.TickGetAllResponse validation failed!')
        let deserialized = this._TickGetAllResponseReceiverModel.deserialize(this._TickGetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickGetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickGetAllResponseReceiverValue)
        this.onReceive_TickGetAllResponse(this._TickGetAllResponseReceiverValue)
        return true
      }
      case Level2GetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetRequestReceiverModel.verify(), 'feed.Level2GetRequest validation failed!')
        let deserialized = this._Level2GetRequestReceiverModel.deserialize(this._Level2GetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetRequestReceiverValue)
        this.onReceive_Level2GetRequest(this._Level2GetRequestReceiverValue)
        return true
      }
      case Level2GetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetResponseReceiverModel.verify(), 'feed.Level2GetResponse validation failed!')
        let deserialized = this._Level2GetResponseReceiverModel.deserialize(this._Level2GetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetResponseReceiverValue)
        this.onReceive_Level2GetResponse(this._Level2GetResponseReceiverValue)
        return true
      }
      case Level2GetFilterRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetFilterRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterRequestReceiverModel.verify(), 'feed.Level2GetFilterRequest validation failed!')
        let deserialized = this._Level2GetFilterRequestReceiverModel.deserialize(this._Level2GetFilterRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetFilterRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetFilterRequestReceiverValue)
        this.onReceive_Level2GetFilterRequest(this._Level2GetFilterRequestReceiverValue)
        return true
      }
      case Level2GetFilterResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetFilterResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetFilterResponseReceiverModel.verify(), 'feed.Level2GetFilterResponse validation failed!')
        let deserialized = this._Level2GetFilterResponseReceiverModel.deserialize(this._Level2GetFilterResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetFilterResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetFilterResponseReceiverValue)
        this.onReceive_Level2GetFilterResponse(this._Level2GetFilterResponseReceiverValue)
        return true
      }
      case Level2GetAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllRequestReceiverModel.verify(), 'feed.Level2GetAllRequest validation failed!')
        let deserialized = this._Level2GetAllRequestReceiverModel.deserialize(this._Level2GetAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetAllRequestReceiverValue)
        this.onReceive_Level2GetAllRequest(this._Level2GetAllRequestReceiverValue)
        return true
      }
      case Level2GetAllResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2GetAllResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2GetAllResponseReceiverModel.verify(), 'feed.Level2GetAllResponse validation failed!')
        let deserialized = this._Level2GetAllResponseReceiverModel.deserialize(this._Level2GetAllResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2GetAllResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2GetAllResponseReceiverValue)
        this.onReceive_Level2GetAllResponse(this._Level2GetAllResponseReceiverValue)
        return true
      }
      case FeedSubscriptionGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetRequestReceiverModel.verify(), 'feed.FeedSubscriptionGetRequest validation failed!')
        let deserialized = this._FeedSubscriptionGetRequestReceiverModel.deserialize(this._FeedSubscriptionGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscriptionGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionGetRequestReceiverValue)
        this.onReceive_FeedSubscriptionGetRequest(this._FeedSubscriptionGetRequestReceiverValue)
        return true
      }
      case FeedSubscriptionGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscriptionGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscriptionGetResponseReceiverModel.verify(), 'feed.FeedSubscriptionGetResponse validation failed!')
        let deserialized = this._FeedSubscriptionGetResponseReceiverModel.deserialize(this._FeedSubscriptionGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscriptionGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscriptionGetResponseReceiverValue)
        this.onReceive_FeedSubscriptionGetResponse(this._FeedSubscriptionGetResponseReceiverValue)
        return true
      }
      case FeedSubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeRequestReceiverModel.verify(), 'feed.FeedSubscribeRequest validation failed!')
        let deserialized = this._FeedSubscribeRequestReceiverModel.deserialize(this._FeedSubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeRequestReceiverValue)
        this.onReceive_FeedSubscribeRequest(this._FeedSubscribeRequestReceiverValue)
        return true
      }
      case FeedSubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeAllRequestReceiverModel.verify(), 'feed.FeedSubscribeAllRequest validation failed!')
        let deserialized = this._FeedSubscribeAllRequestReceiverModel.deserialize(this._FeedSubscribeAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeAllRequestReceiverValue)
        this.onReceive_FeedSubscribeAllRequest(this._FeedSubscribeAllRequestReceiverValue)
        return true
      }
      case FeedSubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedSubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedSubscribeResponseReceiverModel.verify(), 'feed.FeedSubscribeResponse validation failed!')
        let deserialized = this._FeedSubscribeResponseReceiverModel.deserialize(this._FeedSubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedSubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedSubscribeResponseReceiverValue)
        this.onReceive_FeedSubscribeResponse(this._FeedSubscribeResponseReceiverValue)
        return true
      }
      case FeedUnsubscribeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeRequestReceiverModel.verify(), 'feed.FeedUnsubscribeRequest validation failed!')
        let deserialized = this._FeedUnsubscribeRequestReceiverModel.deserialize(this._FeedUnsubscribeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeRequestReceiverValue)
        this.onReceive_FeedUnsubscribeRequest(this._FeedUnsubscribeRequestReceiverValue)
        return true
      }
      case FeedUnsubscribeAllRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeAllRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeAllRequestReceiverModel.verify(), 'feed.FeedUnsubscribeAllRequest validation failed!')
        let deserialized = this._FeedUnsubscribeAllRequestReceiverModel.deserialize(this._FeedUnsubscribeAllRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeAllRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeAllRequestReceiverValue)
        this.onReceive_FeedUnsubscribeAllRequest(this._FeedUnsubscribeAllRequestReceiverValue)
        return true
      }
      case FeedUnsubscribeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUnsubscribeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUnsubscribeResponseReceiverModel.verify(), 'feed.FeedUnsubscribeResponse validation failed!')
        let deserialized = this._FeedUnsubscribeResponseReceiverModel.deserialize(this._FeedUnsubscribeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUnsubscribeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUnsubscribeResponseReceiverValue)
        this.onReceive_FeedUnsubscribeResponse(this._FeedUnsubscribeResponseReceiverValue)
        return true
      }
      case FeedUpdateTickSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickSnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickSnapshotRequestReceiverModel.verify(), 'feed.FeedUpdateTickSnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateTickSnapshotRequestReceiverModel.deserialize(this._FeedUpdateTickSnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateTickSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickSnapshotRequestReceiverValue)
        this.onReceive_FeedUpdateTickSnapshotRequest(this._FeedUpdateTickSnapshotRequestReceiverValue)
        return true
      }
      case FeedUpdateTickRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateTickRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateTickRequestReceiverModel.verify(), 'feed.FeedUpdateTickRequest validation failed!')
        let deserialized = this._FeedUpdateTickRequestReceiverModel.deserialize(this._FeedUpdateTickRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateTickRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateTickRequestReceiverValue)
        this.onReceive_FeedUpdateTickRequest(this._FeedUpdateTickRequestReceiverValue)
        return true
      }
      case FeedUpdateLevel2SnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotRequestReceiverModel.verify(), 'feed.FeedUpdateLevel2SnapshotRequest validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotRequestReceiverModel.deserialize(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2SnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        this.onReceive_FeedUpdateLevel2SnapshotRequest(this._FeedUpdateLevel2SnapshotRequestReceiverValue)
        return true
      }
      case FeedUpdateLevel2RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2RequestReceiverModel.verify(), 'feed.FeedUpdateLevel2Request validation failed!')
        let deserialized = this._FeedUpdateLevel2RequestReceiverModel.deserialize(this._FeedUpdateLevel2RequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2RequestReceiverValue)
        this.onReceive_FeedUpdateLevel2Request(this._FeedUpdateLevel2RequestReceiverValue)
        return true
      }
      case FeedUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateResponseReceiverModel.verify(), 'feed.FeedUpdateResponse validation failed!')
        let deserialized = this._FeedUpdateResponseReceiverModel.deserialize(this._FeedUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateResponseReceiverValue)
        this.onReceive_FeedUpdateResponse(this._FeedUpdateResponseReceiverValue)
        return true
      }
      case TickSnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickSnapshotNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickSnapshotNotifyReceiverModel.verify(), 'feed.TickSnapshotNotify validation failed!')
        let deserialized = this._TickSnapshotNotifyReceiverModel.deserialize(this._TickSnapshotNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickSnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickSnapshotNotifyReceiverValue)
        this.onReceive_TickSnapshotNotify(this._TickSnapshotNotifyReceiverValue)
        return true
      }
      case TickUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._TickUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._TickUpdateNotifyReceiverModel.verify(), 'feed.TickUpdateNotify validation failed!')
        let deserialized = this._TickUpdateNotifyReceiverModel.deserialize(this._TickUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.TickUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._TickUpdateNotifyReceiverValue)
        this.onReceive_TickUpdateNotify(this._TickUpdateNotifyReceiverValue)
        return true
      }
      case Level2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2SnapshotNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2SnapshotNotifyReceiverModel.verify(), 'feed.Level2SnapshotNotify validation failed!')
        let deserialized = this._Level2SnapshotNotifyReceiverModel.deserialize(this._Level2SnapshotNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2SnapshotNotifyReceiverValue)
        this.onReceive_Level2SnapshotNotify(this._Level2SnapshotNotifyReceiverValue)
        return true
      }
      case Level2UpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._Level2UpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._Level2UpdateNotifyReceiverModel.verify(), 'feed.Level2UpdateNotify validation failed!')
        let deserialized = this._Level2UpdateNotifyReceiverModel.deserialize(this._Level2UpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.Level2UpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._Level2UpdateNotifyReceiverValue)
        this.onReceive_Level2UpdateNotify(this._Level2UpdateNotifyReceiverValue)
        return true
      }
      case DataFeedModulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModulesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetRequestReceiverModel.verify(), 'feed.DataFeedModulesGetRequest validation failed!')
        let deserialized = this._DataFeedModulesGetRequestReceiverModel.deserialize(this._DataFeedModulesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModulesGetRequestReceiverValue)
        this.onReceive_DataFeedModulesGetRequest(this._DataFeedModulesGetRequestReceiverValue)
        return true
      }
      case DataFeedModulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModulesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModulesGetResponseReceiverModel.verify(), 'feed.DataFeedModulesGetResponse validation failed!')
        let deserialized = this._DataFeedModulesGetResponseReceiverModel.deserialize(this._DataFeedModulesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModulesGetResponseReceiverValue)
        this.onReceive_DataFeedModulesGetResponse(this._DataFeedModulesGetResponseReceiverValue)
        return true
      }
      case DataFeedModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedModuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedModuleUpdateNotifyReceiverModel.verify(), 'feed.DataFeedModuleUpdateNotify validation failed!')
        let deserialized = this._DataFeedModuleUpdateNotifyReceiverModel.deserialize(this._DataFeedModuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedModuleUpdateNotifyReceiverValue)
        this.onReceive_DataFeedModuleUpdateNotify(this._DataFeedModuleUpdateNotifyReceiverValue)
        return true
      }
      case DataFeedStartRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStartRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStartRequestReceiverModel.verify(), 'feed.DataFeedStartRequest validation failed!')
        let deserialized = this._DataFeedStartRequestReceiverModel.deserialize(this._DataFeedStartRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStartRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStartRequestReceiverValue)
        this.onReceive_DataFeedStartRequest(this._DataFeedStartRequestReceiverValue)
        return true
      }
      case DataFeedStopRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStopRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStopRequestReceiverModel.verify(), 'feed.DataFeedStopRequest validation failed!')
        let deserialized = this._DataFeedStopRequestReceiverModel.deserialize(this._DataFeedStopRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStopRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStopRequestReceiverValue)
        this.onReceive_DataFeedStopRequest(this._DataFeedStopRequestReceiverValue)
        return true
      }
      case DataFeedShutdownRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedShutdownRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedShutdownRequestReceiverModel.verify(), 'feed.DataFeedShutdownRequest validation failed!')
        let deserialized = this._DataFeedShutdownRequestReceiverModel.deserialize(this._DataFeedShutdownRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedShutdownRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedShutdownRequestReceiverValue)
        this.onReceive_DataFeedShutdownRequest(this._DataFeedShutdownRequestReceiverValue)
        return true
      }
      case DataFeedUpdateSubscriptionsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedUpdateSubscriptionsRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedUpdateSubscriptionsRequestReceiverModel.verify(), 'feed.DataFeedUpdateSubscriptionsRequest validation failed!')
        let deserialized = this._DataFeedUpdateSubscriptionsRequestReceiverModel.deserialize(this._DataFeedUpdateSubscriptionsRequestReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedUpdateSubscriptionsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedUpdateSubscriptionsRequestReceiverValue)
        this.onReceive_DataFeedUpdateSubscriptionsRequest(this._DataFeedUpdateSubscriptionsRequestReceiverValue)
        return true
      }
      case DataFeedStatusNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DataFeedStatusNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DataFeedStatusNotifyReceiverModel.verify(), 'feed.DataFeedStatusNotify validation failed!')
        let deserialized = this._DataFeedStatusNotifyReceiverModel.deserialize(this._DataFeedStatusNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.DataFeedStatusNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DataFeedStatusNotifyReceiverValue)
        this.onReceive_DataFeedStatusNotify(this._DataFeedStatusNotifyReceiverValue)
        return true
      }
      case FeedUpdateLevel2SnapshotNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._FeedUpdateLevel2SnapshotNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._FeedUpdateLevel2SnapshotNotifyReceiverModel.verify(), 'feed.FeedUpdateLevel2SnapshotNotify validation failed!')
        let deserialized = this._FeedUpdateLevel2SnapshotNotifyReceiverModel.deserialize(this._FeedUpdateLevel2SnapshotNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.FeedUpdateLevel2SnapshotNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._FeedUpdateLevel2SnapshotNotifyReceiverValue)
        this.onReceive_FeedUpdateLevel2SnapshotNotify(this._FeedUpdateLevel2SnapshotNotifyReceiverValue)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyReceiverModel.verify(), 'feed.SymbolsUpdateNotify validation failed!')
        let deserialized = this._SymbolsUpdateNotifyReceiverModel.deserialize(this._SymbolsUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'feed.SymbolsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsUpdateNotifyReceiverValue)
        this.onReceive_SymbolsUpdateNotify(this._SymbolsUpdateNotifyReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
