import { useCallback, useContext, useEffect, useState } from 'react';
import { AccessContext, AdminPanelAccessMap } from '@utils/accessMap';
import { useLocation } from 'react-router-dom';
import { AdminPageRoute } from '../routes/utils';
import { camelify, kebabilize } from '@utils/string';

export const useRefreshableData = <T, U>(
    refreshGet: T | (() => Promise<T>),
    initial: U | (() => U),
): [
    data: T | U,
    refresh: () => Promise<T>,
    isLoading: boolean,
    forceSetData: React.Dispatch<React.SetStateAction<T | U>>,
] => {
    const [data, setData] = useState<T | U>(initial);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (typeof refreshGet === 'function') {
            (refreshGet as () => Promise<T>)().then((newData) => {
                setData(newData);
                setIsLoading(false);
            });
        } else {
            setData(refreshGet);
            setIsLoading(false);
        }
    }, [refreshGet]);

    const refresh = () => {
        setIsLoading(true);
        if (typeof refreshGet === 'function') {
            return (refreshGet as () => Promise<T>)().then((newData) => {
                setData(newData);
                setIsLoading(false);
                return newData;
            });
        }
        setData(refreshGet);
        setIsLoading(false);
        return Promise.resolve(refreshGet);
    };

    return [data, refresh, isLoading, setData];
};

export const useLocalStorage = (key?: string, defaultValue?: any) => {
    const [value, setValue] = useState(() => {
        const lsValue = key ? localStorage.getItem(key) : undefined;
        return lsValue ? JSON.parse(lsValue) : defaultValue;
    });

    useEffect(() => {
        if (key) localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
};

export function useWindowSize() {
    const [size, setSize] = useState<[number, number]>([0, 0]);

    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

type CurrentTabHook = { tab: string; setTab: (tabKey: string) => void };

export function useCurrentTab(pageInfo: AdminPageRoute, tabs: Record<string, string>): CurrentTabHook {
    const accessMap = useContext<AdminPanelAccessMap | null>(AccessContext);
    const location = useLocation();
    const [current, setCurrent] = useState<string>(tabs[Object.keys(tabs)[0]]);
    const firstAccessibleTab = Object.values(tabs).find((tab) => accessMap![pageInfo.key]?.tabs[tab]?.accessible);

    const updateCurrentTab = useCallback(
        (newTab: string) => {
            if (Object.values(tabs).includes(newTab) && accessMap![pageInfo.key]?.tabs[newTab]?.accessible) {
                window.history.replaceState(undefined, '', `${pageInfo.path}/${kebabilize(newTab)}`);
                setCurrent(newTab);
            }
        },
        [accessMap, pageInfo, tabs],
    );

    useEffect(() => {
        if (location.pathname === pageInfo.path && firstAccessibleTab) {
            window.history.replaceState(undefined, '', `${pageInfo.path}/${kebabilize(firstAccessibleTab)}`);
            setCurrent(firstAccessibleTab);
        }
    }, [firstAccessibleTab, location, pageInfo]);

    useEffect(() => {
        let tabFromUrl = location.pathname
            .split(pageInfo.path)
            .filter((path) => !!path)[0]
            ?.replace(window.location.search, '')
            .replace('/', '');
        const kebabbedTabKey = kebabilize(tabFromUrl);
        if (tabFromUrl === kebabbedTabKey) {
            tabFromUrl = camelify(tabFromUrl);
        } else tabFromUrl = kebabbedTabKey;
        if (tabFromUrl && accessMap![pageInfo.key]?.tabs[tabFromUrl]?.accessible) {
            setCurrent(tabFromUrl);
        } else if (firstAccessibleTab) {
            const newRoute = `${pageInfo.path}/${kebabilize(firstAccessibleTab)}`;
            window.history.replaceState(undefined, '', newRoute);
            setCurrent(firstAccessibleTab);
        } else {
            window.history.replaceState(undefined, '', pageInfo.path);
            setCurrent('');
        }
    }, [location.pathname]);

    return { tab: current, setTab: updateCurrentTab };
}
