/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */

import { UI_VERSION } from 'env/config';

const getHash = (key: string) => {
    if (!key) return 0;
    const remainder = key.length & 3; // key.length % 4
    const bytes = key.length - remainder;
    const c1 = 0xcc9e2d51;
    const c2 = 0x1b873593;

    let h1;
    let h1b;
    let k1;

    for (let i = 0; i < bytes; i++) {
        k1 =
            (key.charCodeAt(i) & 0xff) |
            ((key.charCodeAt(++i) & 0xff) << 8) |
            ((key.charCodeAt(++i) & 0xff) << 16) |
            ((key.charCodeAt(++i) & 0xff) << 24);
        ++i;

        k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
        k1 = (k1 << 15) | (k1 >>> 17);
        k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;

        h1 ^= k1;
        h1 = (h1 << 13) | (h1 >>> 19);
        h1b = ((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) & 0xffffffff;
        h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
    }

    const i = bytes - 1;

    k1 = 0;

    switch (remainder) {
        case 3: {
            k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
            break;
        }
        case 2: {
            k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
            break;
        }
        case 1: {
            k1 ^= key.charCodeAt(i) & 0xff;
            break;
        }
        default:
            break;
    }

    k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= k1;

    h1 ^= key.length;

    h1 ^= h1 >>> 16;
    h1 = ((h1 & 0xffff) * 0x85ebca6b + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 13;
    h1 = ((h1 & 0xffff) * 0xc2b2ae35 + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) & 0xffffffff;
    h1 ^= h1 >>> 16;

    return h1 >>> 0;
};

export const getBrowserFingerprint = (): Map<string, string> => {
    const { devicePixelRatio } = window;

    const {
        appName,
        appCodeName,
        appVersion,
        cookieEnabled,
        doNotTrack,
        hardwareConcurrency,
        language,
        languages,
        maxTouchPoints,
        platform,
        product,
        productSub,
        userAgent,
        vendor,
        vendorSub,
    } = window.navigator;

    const { width, height, colorDepth, pixelDepth } = window.screen;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const touchSupport = 'ontouchstart' in window;

    const data = {
        appCodeName,
        appName,
        appVersion,
        colorDepth,
        cookieEnabled,
        devicePixelRatio,
        doNotTrack,
        hardwareConcurrency,
        height,
        language,
        languages,
        maxTouchPoints,
        pixelDepth,
        platform,
        product,
        productSub,
        timezone,
        timezoneOffset,
        touchSupport,
        userAgent,
        vendor,
        vendorSub,
        width,
    };

    const hash = getHash(JSON.stringify(data, null, 4));

    return new Map([
        ['UIVersion', UI_VERSION],
        ['deviceId', hash.toString()],
        ['agent', `${data.vendor} | ${data.userAgent}`],
        ['platform', `${data.platform} | ${data.product}`],
        ['resolution', `${data.width}x${data.height}`],
        ['timezone', data.timezone],
    ]);
};
