import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { ParsedManager } from '@models';
import { SocketClient } from '@api/SocketClient';
import { adaptFromApi, adaptToApiCreate, adaptToApiModify } from './utils';
import { UInt64 } from '@api/fbe/int64';
import { ManagersGetAllRequest } from '@api/fbe/manager/ManagersGetAllRequest';
import { ManagerCreateRequest } from '@api/fbe/manager/ManagerCreateRequest';
import { ManagerDeleteRequest } from '@api/fbe/manager/ManagerDeleteRequest';
import { ManagerModifyRequest } from '@api/fbe/manager/ManagerModifyRequest';

export class ManagerStore {
    errorTracker = new ErrorsTracker({ title: 'Managers' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ParsedManager[] = [];

    isLoading: boolean = false;

    setData(value: ParsedManager[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new ManagersGetAllRequest();

        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.Managers.map((manager) => adaptFromApi(manager)));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(manager: ParsedManager) {
        const request = adaptToApiCreate(new ManagerCreateRequest(), manager);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(parsedLogin: string) {
        const request = new ManagerDeleteRequest();
        request.Login = UInt64.fromValue(parsedLogin);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(newManager: ParsedManager) {
        const request = adaptToApiModify(new ManagerModifyRequest(), newManager);
        SocketClient.instance.request(request, this.errorTracker).then(() => this.get());
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const managersStore = new ManagerStore();
export { managersStore };
