import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { FilterPropsRangeDate } from './types';
import styles from './RowFilters.module.scss';
import { FilterValuesContext } from '../TableExt';
import { IRangeInputD } from '../types';
import { typedMemo } from '@utils';
import { FORMAT_DT, rangePickerRanges, FORMAT_D, FORMAT_T, momentValueFromDate } from '@utils/datetime';
import { TimeRangePickerAutoaccept } from '@components/Form/RangePickerAutoaccept/TimeRangePickerAutoaccept';
import { RangePickerAutoaccept } from '@components/Form/RangePickerAutoaccept/RangePickerAutoaccept';

export const RowFilterRangeDate = typedMemo((props: FilterPropsRangeDate) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IRangeInputD>(
        filterValues.values[props.dataIndex]
            ? (filterValues.values[props.dataIndex] as IRangeInputD)
            : { from: null, to: null },
    );

    const onTimeRangeChange = (rangeValue) =>
        setValue({ from: rangeValue![0]!.toDate(), to: rangeValue![1]!.toDate() });

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: null, to: null });
        props.confirmCallback();
    };

    const onFilter = () => {
        filterValues.setValues({
            ...filterValues.values,
            [props.dataIndex]: !value.from && !value.to ? null : value,
        });
        props.confirmCallback();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles['site-input-group-wrapper']}>
                {props.timeOnly ? (
                    <TimeRangePickerAutoaccept
                        format={FORMAT_T}
                        onChange={onTimeRangeChange}
                        value={[momentValueFromDate(value.from), momentValueFromDate(value.to)]}
                    />
                ) : (
                    <RangePickerAutoaccept
                        format={props.withTimePicker !== false ? FORMAT_DT : FORMAT_D}
                        ranges={rangePickerRanges}
                        onChange={onTimeRangeChange}
                        value={[momentValueFromDate(value.from), momentValueFromDate(value.to)]}
                        showTime={props.withTimePicker !== false}
                    />
                )}
            </div>
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={onFilter} size="small" className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
