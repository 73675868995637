
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {MarginCalculationType} from './MarginCalculationType';
import {FieldModelMarginCalculationType} from './MarginCalculationType';
import {ProfitCalculationType} from './ProfitCalculationType';
import {FieldModelProfitCalculationType} from './ProfitCalculationType';
import {SwapSymbolSettings} from './SwapSymbolSettings';
import {FieldModelSwapSymbolSettings} from './SwapSymbolSettings';
import {TradingMode} from './TradingMode';
import {FieldModelTradingMode} from './TradingMode';

/**
 * Symbol struct
 */
class Symbol {
  /**
   * Initialize struct
   * @param {!UUID=} id
   * @param {!number=} sortOrder
   * @param {!UUID=} symbolGroupId
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} alias
   * @param {!string=} source
   * @param {!number=} precision
   * @param {UUID=} marginCurrencyId
   * @param {number=} marginCurrencyPrecision
   * @param {!MarginCalculationType=} marginCalculationType
   * @param {UUID=} profitCurrencyId
   * @param {number=} profitCurrencyPrecision
   * @param {!ProfitCalculationType=} profitCalculationType
   * @param {number=} color
   * @param {boolean=} isTradeAllowed
   * @param {!number=} contractSize
   * @param {!number=} minTradeAmount
   * @param {!number=} maxTradeAmount
   * @param {!number=} tradeAmountStep
   * @param {number=} defaultExpiration
   * @param {!boolean=} isSwapEnabled
   * @param {SwapSymbolSettings=} swapSettings
   * @param {!number=} marketOrderMarginRate
   * @param {!number=} limitOrderMarginRate
   * @param {!number=} stopOrderMarginRate
   * @param {TradingMode=} tradingMode
   * @param {!number=} index
   * @param {!number=} stopsLevel
   * @param {!boolean=} isFavorite
   * @param {number=} sortOrderFavorite
   * @param {!string=} postfix
   * @param {!number=} initialMargin
   * @param {!number=} hedgedMargin
   * @param {!number=} colorEffective
   * @param {!boolean=} isTradeAllowedEffective
   * @param {!number=} defaultExpirationEffective
   * @param {!TradingMode=} tradingModeEffective
   * @param {number=} marginCurrencyPrecisionEffective
   * @param {number=} profitCurrencyPrecisionEffective
   * @constructor
   */
  constructor (id = UUID.sequential(), sortOrder = 0, symbolGroupId = new UUID(), name = '', comment = '', description = '', alias = '', source = '', precision = 0, marginCurrencyId = null, marginCurrencyPrecision = null, marginCalculationType = new MarginCalculationType(), profitCurrencyId = null, profitCurrencyPrecision = null, profitCalculationType = new ProfitCalculationType(), color = null, isTradeAllowed = null, contractSize = 0.0, minTradeAmount = 0.0, maxTradeAmount = 0.0, tradeAmountStep = 0.0, defaultExpiration = null, isSwapEnabled = false, swapSettings = null, marketOrderMarginRate = 1.0, limitOrderMarginRate = 1.0, stopOrderMarginRate = 1.0, tradingMode = null, index = 0, stopsLevel = new UInt64(0, 0), isFavorite = false, sortOrderFavorite = null, postfix = '', initialMargin = 0.0, hedgedMargin = 0.0, colorEffective = 0, isTradeAllowedEffective = false, defaultExpirationEffective = new UInt64(0, 0), tradingModeEffective = new TradingMode(), marginCurrencyPrecisionEffective = null, profitCurrencyPrecisionEffective = null) {
    this.id = id
    this.SortOrder = sortOrder
    this.SymbolGroupId = symbolGroupId
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.Alias = alias
    this.Source = source
    this.Precision = precision
    this.MarginCurrencyId = marginCurrencyId
    this.MarginCurrencyPrecision = marginCurrencyPrecision
    this.MarginCalculationType = marginCalculationType
    this.ProfitCurrencyId = profitCurrencyId
    this.ProfitCurrencyPrecision = profitCurrencyPrecision
    this.ProfitCalculationType = profitCalculationType
    this.Color = color
    this.IsTradeAllowed = isTradeAllowed
    this.ContractSize = contractSize
    this.MinTradeAmount = minTradeAmount
    this.MaxTradeAmount = maxTradeAmount
    this.TradeAmountStep = tradeAmountStep
    this.DefaultExpiration = defaultExpiration
    this.IsSwapEnabled = isSwapEnabled
    this.SwapSettings = swapSettings
    this.MarketOrderMarginRate = marketOrderMarginRate
    this.LimitOrderMarginRate = limitOrderMarginRate
    this.StopOrderMarginRate = stopOrderMarginRate
    this.TradingMode = tradingMode
    this.Index = index
    this.StopsLevel = stopsLevel
    this.IsFavorite = isFavorite
    this.SortOrderFavorite = sortOrderFavorite
    this.Postfix = postfix
    this.InitialMargin = initialMargin
    this.HedgedMargin = hedgedMargin
    this.ColorEffective = colorEffective
    this.IsTradeAllowedEffective = isTradeAllowedEffective
    this.DefaultExpirationEffective = defaultExpirationEffective
    this.TradingModeEffective = tradingModeEffective
    this.MarginCurrencyPrecisionEffective = marginCurrencyPrecisionEffective
    this.ProfitCurrencyPrecisionEffective = profitCurrencyPrecisionEffective
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Symbol}
   * @param {!Symbol} other Other struct
   * @returns {!Symbol} This struct
   */
  copy (other) {
    if (other.id != null) {
      this.id = new UUID(other.id)
    } else {
      this.id = null
    }
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.SymbolGroupId != null) {
      this.SymbolGroupId = new UUID(other.SymbolGroupId)
    } else {
      this.SymbolGroupId = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Alias != null) {
      this.Alias = other.Alias
    } else {
      this.Alias = null
    }
    if (other.Source != null) {
      this.Source = other.Source
    } else {
      this.Source = null
    }
    if (other.Precision != null) {
      this.Precision = other.Precision
    } else {
      this.Precision = null
    }
    if (other.MarginCurrencyId != null) {
      this.MarginCurrencyId = new UUID(other.MarginCurrencyId)
    } else {
      this.MarginCurrencyId = null
    }
    if (other.MarginCurrencyPrecision != null) {
      this.MarginCurrencyPrecision = other.MarginCurrencyPrecision
    } else {
      this.MarginCurrencyPrecision = null
    }
    if (other.MarginCalculationType != null) {
      this.MarginCalculationType = MarginCalculationType.fromObject(other.MarginCalculationType)
    } else {
      this.MarginCalculationType = null
    }
    if (other.ProfitCurrencyId != null) {
      this.ProfitCurrencyId = new UUID(other.ProfitCurrencyId)
    } else {
      this.ProfitCurrencyId = null
    }
    if (other.ProfitCurrencyPrecision != null) {
      this.ProfitCurrencyPrecision = other.ProfitCurrencyPrecision
    } else {
      this.ProfitCurrencyPrecision = null
    }
    if (other.ProfitCalculationType != null) {
      this.ProfitCalculationType = ProfitCalculationType.fromObject(other.ProfitCalculationType)
    } else {
      this.ProfitCalculationType = null
    }
    if (other.Color != null) {
      this.Color = other.Color
    } else {
      this.Color = null
    }
    if (other.IsTradeAllowed != null) {
      this.IsTradeAllowed = other.IsTradeAllowed
    } else {
      this.IsTradeAllowed = null
    }
    if (other.ContractSize != null) {
      this.ContractSize = other.ContractSize
    } else {
      this.ContractSize = null
    }
    if (other.MinTradeAmount != null) {
      this.MinTradeAmount = other.MinTradeAmount
    } else {
      this.MinTradeAmount = null
    }
    if (other.MaxTradeAmount != null) {
      this.MaxTradeAmount = other.MaxTradeAmount
    } else {
      this.MaxTradeAmount = null
    }
    if (other.TradeAmountStep != null) {
      this.TradeAmountStep = other.TradeAmountStep
    } else {
      this.TradeAmountStep = null
    }
    if (other.DefaultExpiration != null) {
      this.DefaultExpiration = UInt64.fromNumber(other.DefaultExpiration)
    } else {
      this.DefaultExpiration = null
    }
    if (other.IsSwapEnabled != null) {
      this.IsSwapEnabled = other.IsSwapEnabled
    } else {
      this.IsSwapEnabled = null
    }
    if (other.SwapSettings != null) {
      this.SwapSettings = SwapSymbolSettings.fromObject(other.SwapSettings)
    } else {
      this.SwapSettings = null
    }
    if (other.MarketOrderMarginRate != null) {
      this.MarketOrderMarginRate = other.MarketOrderMarginRate
    } else {
      this.MarketOrderMarginRate = null
    }
    if (other.LimitOrderMarginRate != null) {
      this.LimitOrderMarginRate = other.LimitOrderMarginRate
    } else {
      this.LimitOrderMarginRate = null
    }
    if (other.StopOrderMarginRate != null) {
      this.StopOrderMarginRate = other.StopOrderMarginRate
    } else {
      this.StopOrderMarginRate = null
    }
    if (other.TradingMode != null) {
      this.TradingMode = TradingMode.fromObject(other.TradingMode)
    } else {
      this.TradingMode = null
    }
    if (other.Index != null) {
      this.Index = other.Index
    } else {
      this.Index = null
    }
    if (other.StopsLevel != null) {
      this.StopsLevel = UInt64.fromNumber(other.StopsLevel)
    } else {
      this.StopsLevel = null
    }
    if (other.IsFavorite != null) {
      this.IsFavorite = other.IsFavorite
    } else {
      this.IsFavorite = null
    }
    if (other.SortOrderFavorite != null) {
      this.SortOrderFavorite = other.SortOrderFavorite
    } else {
      this.SortOrderFavorite = null
    }
    if (other.Postfix != null) {
      this.Postfix = other.Postfix
    } else {
      this.Postfix = null
    }
    if (other.InitialMargin != null) {
      this.InitialMargin = other.InitialMargin
    } else {
      this.InitialMargin = null
    }
    if (other.HedgedMargin != null) {
      this.HedgedMargin = other.HedgedMargin
    } else {
      this.HedgedMargin = null
    }
    if (other.ColorEffective != null) {
      this.ColorEffective = other.ColorEffective
    } else {
      this.ColorEffective = null
    }
    if (other.IsTradeAllowedEffective != null) {
      this.IsTradeAllowedEffective = other.IsTradeAllowedEffective
    } else {
      this.IsTradeAllowedEffective = null
    }
    if (other.DefaultExpirationEffective != null) {
      this.DefaultExpirationEffective = UInt64.fromNumber(other.DefaultExpirationEffective)
    } else {
      this.DefaultExpirationEffective = null
    }
    if (other.TradingModeEffective != null) {
      this.TradingModeEffective = TradingMode.fromObject(other.TradingModeEffective)
    } else {
      this.TradingModeEffective = null
    }
    if (other.MarginCurrencyPrecisionEffective != null) {
      this.MarginCurrencyPrecisionEffective = other.MarginCurrencyPrecisionEffective
    } else {
      this.MarginCurrencyPrecisionEffective = null
    }
    if (other.ProfitCurrencyPrecisionEffective != null) {
      this.ProfitCurrencyPrecisionEffective = other.ProfitCurrencyPrecisionEffective
    } else {
      this.ProfitCurrencyPrecisionEffective = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Symbol}
   * @returns {!Symbol} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SymbolModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SymbolModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Symbol}
   * @param {!Symbol} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Symbol)) {
      throw new TypeError('Instance of Symbol is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.id != null) {
      if ((other.id == null) || !this.id.eq(other.id)) {
        return false
      }
    } else if (other.id != null) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Symbol}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      id: ((this.id != null) ? this.id.toString() : null),
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      SymbolGroupId: ((this.SymbolGroupId != null) ? this.SymbolGroupId.toString() : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      Alias: ((this.Alias != null) ? this.Alias : null),
      Source: ((this.Source != null) ? this.Source : null),
      Precision: ((this.Precision != null) ? this.Precision : null),
      MarginCurrencyId: ((this.MarginCurrencyId != null) ? this.MarginCurrencyId.toString() : null),
      MarginCurrencyPrecision: ((this.MarginCurrencyPrecision != null) ? this.MarginCurrencyPrecision : null),
      MarginCalculationType: ((this.MarginCalculationType != null) ? this.MarginCalculationType : null),
      ProfitCurrencyId: ((this.ProfitCurrencyId != null) ? this.ProfitCurrencyId.toString() : null),
      ProfitCurrencyPrecision: ((this.ProfitCurrencyPrecision != null) ? this.ProfitCurrencyPrecision : null),
      ProfitCalculationType: ((this.ProfitCalculationType != null) ? this.ProfitCalculationType : null),
      Color: ((this.Color != null) ? this.Color : null),
      IsTradeAllowed: ((this.IsTradeAllowed != null) ? this.IsTradeAllowed : null),
      ContractSize: ((this.ContractSize != null) ? this.ContractSize : null),
      MinTradeAmount: ((this.MinTradeAmount != null) ? this.MinTradeAmount : null),
      MaxTradeAmount: ((this.MaxTradeAmount != null) ? this.MaxTradeAmount : null),
      TradeAmountStep: ((this.TradeAmountStep != null) ? this.TradeAmountStep : null),
      DefaultExpiration: ((this.DefaultExpiration != null) ? this.DefaultExpiration.toNumber() : null),
      IsSwapEnabled: ((this.IsSwapEnabled != null) ? this.IsSwapEnabled : null),
      SwapSettings: ((this.SwapSettings != null) ? this.SwapSettings : null),
      MarketOrderMarginRate: ((this.MarketOrderMarginRate != null) ? this.MarketOrderMarginRate : null),
      LimitOrderMarginRate: ((this.LimitOrderMarginRate != null) ? this.LimitOrderMarginRate : null),
      StopOrderMarginRate: ((this.StopOrderMarginRate != null) ? this.StopOrderMarginRate : null),
      TradingMode: ((this.TradingMode != null) ? this.TradingMode : null),
      Index: ((this.Index != null) ? this.Index : null),
      StopsLevel: ((this.StopsLevel != null) ? this.StopsLevel.toNumber() : null),
      IsFavorite: ((this.IsFavorite != null) ? this.IsFavorite : null),
      SortOrderFavorite: ((this.SortOrderFavorite != null) ? this.SortOrderFavorite : null),
      Postfix: ((this.Postfix != null) ? this.Postfix : null),
      InitialMargin: ((this.InitialMargin != null) ? this.InitialMargin : null),
      HedgedMargin: ((this.HedgedMargin != null) ? this.HedgedMargin : null),
      ColorEffective: ((this.ColorEffective != null) ? this.ColorEffective : null),
      IsTradeAllowedEffective: ((this.IsTradeAllowedEffective != null) ? this.IsTradeAllowedEffective : null),
      DefaultExpirationEffective: ((this.DefaultExpirationEffective != null) ? this.DefaultExpirationEffective.toNumber() : null),
      TradingModeEffective: ((this.TradingModeEffective != null) ? this.TradingModeEffective : null),
      MarginCurrencyPrecisionEffective: ((this.MarginCurrencyPrecisionEffective != null) ? this.MarginCurrencyPrecisionEffective : null),
      ProfitCurrencyPrecisionEffective: ((this.ProfitCurrencyPrecisionEffective != null) ? this.ProfitCurrencyPrecisionEffective : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Symbol.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Symbol} other Object value
   * @returns {!Symbol} Created struct
   */
  static fromObject (other) {
    return new Symbol().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Symbol}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Symbol.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Symbol}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 26
  }
}

export { Symbol };

/**
 * Fast Binary Encoding Symbol field model
 */
class FieldModelSymbol extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.id = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelUInt32(buffer, this.id.fbeOffset + this.id.fbeSize)
    this.SymbolGroupId = new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.SymbolGroupId.fbeOffset + this.SymbolGroupId.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.Alias = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Source = new fbe.FieldModelString(buffer, this.Alias.fbeOffset + this.Alias.fbeSize)
    this.Precision = new fbe.FieldModelUInt8(buffer, this.Source.fbeOffset + this.Source.fbeSize)
    this.MarginCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Precision.fbeOffset + this.Precision.fbeSize), buffer, this.Precision.fbeOffset + this.Precision.fbeSize)
    this.MarginCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.MarginCurrencyId.fbeOffset + this.MarginCurrencyId.fbeSize), buffer, this.MarginCurrencyId.fbeOffset + this.MarginCurrencyId.fbeSize)
    this.MarginCalculationType = new FieldModelMarginCalculationType(buffer, this.MarginCurrencyPrecision.fbeOffset + this.MarginCurrencyPrecision.fbeSize)
    this.ProfitCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize), buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize)
    this.ProfitCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.ProfitCurrencyId.fbeOffset + this.ProfitCurrencyId.fbeSize), buffer, this.ProfitCurrencyId.fbeOffset + this.ProfitCurrencyId.fbeSize)
    this.ProfitCalculationType = new FieldModelProfitCalculationType(buffer, this.ProfitCurrencyPrecision.fbeOffset + this.ProfitCurrencyPrecision.fbeSize)
    this.Color = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.ProfitCalculationType.fbeOffset + this.ProfitCalculationType.fbeSize), buffer, this.ProfitCalculationType.fbeOffset + this.ProfitCalculationType.fbeSize)
    this.IsTradeAllowed = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Color.fbeOffset + this.Color.fbeSize), buffer, this.Color.fbeOffset + this.Color.fbeSize)
    this.ContractSize = new fbe.FieldModelDouble(buffer, this.IsTradeAllowed.fbeOffset + this.IsTradeAllowed.fbeSize)
    this.MinTradeAmount = new fbe.FieldModelDouble(buffer, this.ContractSize.fbeOffset + this.ContractSize.fbeSize)
    this.MaxTradeAmount = new fbe.FieldModelDouble(buffer, this.MinTradeAmount.fbeOffset + this.MinTradeAmount.fbeSize)
    this.TradeAmountStep = new fbe.FieldModelDouble(buffer, this.MaxTradeAmount.fbeOffset + this.MaxTradeAmount.fbeSize)
    this.DefaultExpiration = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize), buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize)
    this.IsSwapEnabled = new fbe.FieldModelBool(buffer, this.DefaultExpiration.fbeOffset + this.DefaultExpiration.fbeSize)
    this.SwapSettings = new fbe.FieldModelOptional(new FieldModelSwapSymbolSettings(buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize), buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize)
    this.MarketOrderMarginRate = new fbe.FieldModelDouble(buffer, this.SwapSettings.fbeOffset + this.SwapSettings.fbeSize)
    this.LimitOrderMarginRate = new fbe.FieldModelDouble(buffer, this.MarketOrderMarginRate.fbeOffset + this.MarketOrderMarginRate.fbeSize)
    this.StopOrderMarginRate = new fbe.FieldModelDouble(buffer, this.LimitOrderMarginRate.fbeOffset + this.LimitOrderMarginRate.fbeSize)
    this.TradingMode = new fbe.FieldModelOptional(new FieldModelTradingMode(buffer, this.StopOrderMarginRate.fbeOffset + this.StopOrderMarginRate.fbeSize), buffer, this.StopOrderMarginRate.fbeOffset + this.StopOrderMarginRate.fbeSize)
    this.Index = new fbe.FieldModelUInt16(buffer, this.TradingMode.fbeOffset + this.TradingMode.fbeSize)
    this.StopsLevel = new fbe.FieldModelUInt64(buffer, this.Index.fbeOffset + this.Index.fbeSize)
    this.IsFavorite = new fbe.FieldModelBool(buffer, this.StopsLevel.fbeOffset + this.StopsLevel.fbeSize)
    this.SortOrderFavorite = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize), buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize)
    this.Postfix = new fbe.FieldModelString(buffer, this.SortOrderFavorite.fbeOffset + this.SortOrderFavorite.fbeSize)
    this.InitialMargin = new fbe.FieldModelDouble(buffer, this.Postfix.fbeOffset + this.Postfix.fbeSize)
    this.HedgedMargin = new fbe.FieldModelDouble(buffer, this.InitialMargin.fbeOffset + this.InitialMargin.fbeSize)
    this.ColorEffective = new fbe.FieldModelUInt32(buffer, this.HedgedMargin.fbeOffset + this.HedgedMargin.fbeSize)
    this.IsTradeAllowedEffective = new fbe.FieldModelBool(buffer, this.ColorEffective.fbeOffset + this.ColorEffective.fbeSize)
    this.DefaultExpirationEffective = new fbe.FieldModelUInt64(buffer, this.IsTradeAllowedEffective.fbeOffset + this.IsTradeAllowedEffective.fbeSize)
    this.TradingModeEffective = new FieldModelTradingMode(buffer, this.DefaultExpirationEffective.fbeOffset + this.DefaultExpirationEffective.fbeSize)
    this.MarginCurrencyPrecisionEffective = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.TradingModeEffective.fbeOffset + this.TradingModeEffective.fbeSize), buffer, this.TradingModeEffective.fbeOffset + this.TradingModeEffective.fbeSize)
    this.ProfitCurrencyPrecisionEffective = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.MarginCurrencyPrecisionEffective.fbeOffset + this.MarginCurrencyPrecisionEffective.fbeSize), buffer, this.MarginCurrencyPrecisionEffective.fbeOffset + this.MarginCurrencyPrecisionEffective.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSymbol}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSymbol}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.id.fbeSize + this.SortOrder.fbeSize + this.SymbolGroupId.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.Alias.fbeSize + this.Source.fbeSize + this.Precision.fbeSize + this.MarginCurrencyId.fbeSize + this.MarginCurrencyPrecision.fbeSize + this.MarginCalculationType.fbeSize + this.ProfitCurrencyId.fbeSize + this.ProfitCurrencyPrecision.fbeSize + this.ProfitCalculationType.fbeSize + this.Color.fbeSize + this.IsTradeAllowed.fbeSize + this.ContractSize.fbeSize + this.MinTradeAmount.fbeSize + this.MaxTradeAmount.fbeSize + this.TradeAmountStep.fbeSize + this.DefaultExpiration.fbeSize + this.IsSwapEnabled.fbeSize + this.SwapSettings.fbeSize + this.MarketOrderMarginRate.fbeSize + this.LimitOrderMarginRate.fbeSize + this.StopOrderMarginRate.fbeSize + this.TradingMode.fbeSize + this.Index.fbeSize + this.StopsLevel.fbeSize + this.IsFavorite.fbeSize + this.SortOrderFavorite.fbeSize + this.Postfix.fbeSize + this.InitialMargin.fbeSize + this.HedgedMargin.fbeSize + this.ColorEffective.fbeSize + this.IsTradeAllowedEffective.fbeSize + this.DefaultExpirationEffective.fbeSize + this.TradingModeEffective.fbeSize + this.MarginCurrencyPrecisionEffective.fbeSize + this.ProfitCurrencyPrecisionEffective.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSymbol}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.id.fbeExtra + this.SortOrder.fbeExtra + this.SymbolGroupId.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.Alias.fbeExtra + this.Source.fbeExtra + this.Precision.fbeExtra + this.MarginCurrencyId.fbeExtra + this.MarginCurrencyPrecision.fbeExtra + this.MarginCalculationType.fbeExtra + this.ProfitCurrencyId.fbeExtra + this.ProfitCurrencyPrecision.fbeExtra + this.ProfitCalculationType.fbeExtra + this.Color.fbeExtra + this.IsTradeAllowed.fbeExtra + this.ContractSize.fbeExtra + this.MinTradeAmount.fbeExtra + this.MaxTradeAmount.fbeExtra + this.TradeAmountStep.fbeExtra + this.DefaultExpiration.fbeExtra + this.IsSwapEnabled.fbeExtra + this.SwapSettings.fbeExtra + this.MarketOrderMarginRate.fbeExtra + this.LimitOrderMarginRate.fbeExtra + this.StopOrderMarginRate.fbeExtra + this.TradingMode.fbeExtra + this.Index.fbeExtra + this.StopsLevel.fbeExtra + this.IsFavorite.fbeExtra + this.SortOrderFavorite.fbeExtra + this.Postfix.fbeExtra + this.InitialMargin.fbeExtra + this.HedgedMargin.fbeExtra + this.ColorEffective.fbeExtra + this.IsTradeAllowedEffective.fbeExtra + this.DefaultExpirationEffective.fbeExtra + this.TradingModeEffective.fbeExtra + this.MarginCurrencyPrecisionEffective.fbeExtra + this.ProfitCurrencyPrecisionEffective.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSymbol}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSymbol.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSymbol}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 26
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSymbol}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSymbol}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.id.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Alias.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Alias.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Alias.fbeSize

    if ((fbeCurrentSize + this.Source.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Source.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Source.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Precision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Color.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeAllowed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ContractSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeAmountStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSettings.fbeSize

    if ((fbeCurrentSize + this.MarketOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarketOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.LimitOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LimitOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.StopOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.Index.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Index.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Index.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopsLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrderFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.Postfix.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Postfix.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Postfix.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InitialMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HedgedMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize

    if ((fbeCurrentSize + this.ColorEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ColorEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ColorEffective.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowedEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeAllowedEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowedEffective.fbeSize

    if ((fbeCurrentSize + this.DefaultExpirationEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultExpirationEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpirationEffective.fbeSize

    if ((fbeCurrentSize + this.TradingModeEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingModeEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingModeEffective.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecisionEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyPrecisionEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecisionEffective.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecisionEffective.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyPrecisionEffective.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecisionEffective.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSymbol}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSymbol}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSymbol}
   * @param {!Symbol} fbeValue Default value, defaults is new Symbol()
   * @returns {!Symbol} Symbol value
   */
  get (fbeValue = new Symbol()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSymbol}
   * @param {!Symbol} fbeValue Symbol value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) <= fbeStructSize) {
      fbeValue.id = this.id.get(UUID.sequential())
    } else {
      fbeValue.id = UUID.sequential()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolGroupId = this.SymbolGroupId.get()
    } else {
      fbeValue.SymbolGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Alias.fbeSize) <= fbeStructSize) {
      fbeValue.Alias = this.Alias.get()
    } else {
      fbeValue.Alias = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Alias.fbeSize

    if ((fbeCurrentSize + this.Source.fbeSize) <= fbeStructSize) {
      fbeValue.Source = this.Source.get()
    } else {
      fbeValue.Source = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Source.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) <= fbeStructSize) {
      fbeValue.Precision = this.Precision.get()
    } else {
      fbeValue.Precision = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyId = this.MarginCurrencyId.get()
    } else {
      fbeValue.MarginCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyPrecision = this.MarginCurrencyPrecision.get()
    } else {
      fbeValue.MarginCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCalculationType = this.MarginCalculationType.get()
    } else {
      fbeValue.MarginCalculationType = new MarginCalculationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyId = this.ProfitCurrencyId.get()
    } else {
      fbeValue.ProfitCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyPrecision = this.ProfitCurrencyPrecision.get()
    } else {
      fbeValue.ProfitCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCalculationType = this.ProfitCalculationType.get()
    } else {
      fbeValue.ProfitCalculationType = new ProfitCalculationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) <= fbeStructSize) {
      fbeValue.Color = this.Color.get()
    } else {
      fbeValue.Color = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeAllowed = this.IsTradeAllowed.get()
    } else {
      fbeValue.IsTradeAllowed = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) <= fbeStructSize) {
      fbeValue.ContractSize = this.ContractSize.get()
    } else {
      fbeValue.ContractSize = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MinTradeAmount = this.MinTradeAmount.get()
    } else {
      fbeValue.MinTradeAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MaxTradeAmount = this.MaxTradeAmount.get()
    } else {
      fbeValue.MaxTradeAmount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) <= fbeStructSize) {
      fbeValue.TradeAmountStep = this.TradeAmountStep.get()
    } else {
      fbeValue.TradeAmountStep = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultExpiration = this.DefaultExpiration.get()
    } else {
      fbeValue.DefaultExpiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSwapEnabled = this.IsSwapEnabled.get(false)
    } else {
      fbeValue.IsSwapEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapSettings.fbeSize) <= fbeStructSize) {
      fbeValue.SwapSettings = this.SwapSettings.get()
    } else {
      fbeValue.SwapSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapSettings.fbeSize

    if ((fbeCurrentSize + this.MarketOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.MarketOrderMarginRate = this.MarketOrderMarginRate.get(1.0)
    } else {
      fbeValue.MarketOrderMarginRate = 1.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.LimitOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.LimitOrderMarginRate = this.LimitOrderMarginRate.get(1.0)
    } else {
      fbeValue.LimitOrderMarginRate = 1.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.StopOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.StopOrderMarginRate = this.StopOrderMarginRate.get(1.0)
    } else {
      fbeValue.StopOrderMarginRate = 1.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) <= fbeStructSize) {
      fbeValue.TradingMode = this.TradingMode.get()
    } else {
      fbeValue.TradingMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.Index.fbeSize) <= fbeStructSize) {
      fbeValue.Index = this.Index.get()
    } else {
      fbeValue.Index = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Index.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) <= fbeStructSize) {
      fbeValue.StopsLevel = this.StopsLevel.get()
    } else {
      fbeValue.StopsLevel = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.IsFavorite = this.IsFavorite.get()
    } else {
      fbeValue.IsFavorite = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrderFavorite = this.SortOrderFavorite.get()
    } else {
      fbeValue.SortOrderFavorite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.Postfix.fbeSize) <= fbeStructSize) {
      fbeValue.Postfix = this.Postfix.get()
    } else {
      fbeValue.Postfix = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Postfix.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) <= fbeStructSize) {
      fbeValue.InitialMargin = this.InitialMargin.get()
    } else {
      fbeValue.InitialMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) <= fbeStructSize) {
      fbeValue.HedgedMargin = this.HedgedMargin.get()
    } else {
      fbeValue.HedgedMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize

    if ((fbeCurrentSize + this.ColorEffective.fbeSize) <= fbeStructSize) {
      fbeValue.ColorEffective = this.ColorEffective.get()
    } else {
      fbeValue.ColorEffective = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ColorEffective.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowedEffective.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeAllowedEffective = this.IsTradeAllowedEffective.get()
    } else {
      fbeValue.IsTradeAllowedEffective = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowedEffective.fbeSize

    if ((fbeCurrentSize + this.DefaultExpirationEffective.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultExpirationEffective = this.DefaultExpirationEffective.get()
    } else {
      fbeValue.DefaultExpirationEffective = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpirationEffective.fbeSize

    if ((fbeCurrentSize + this.TradingModeEffective.fbeSize) <= fbeStructSize) {
      fbeValue.TradingModeEffective = this.TradingModeEffective.get()
    } else {
      fbeValue.TradingModeEffective = new TradingMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingModeEffective.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecisionEffective.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyPrecisionEffective = this.MarginCurrencyPrecisionEffective.get()
    } else {
      fbeValue.MarginCurrencyPrecisionEffective = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecisionEffective.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecisionEffective.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyPrecisionEffective = this.ProfitCurrencyPrecisionEffective.get()
    } else {
      fbeValue.ProfitCurrencyPrecisionEffective = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecisionEffective.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSymbol}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSymbol}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSymbol}
   * @param {!Symbol} fbeValue Symbol value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSymbol}
   * @param {Symbol} fbeValue Symbol value
   */
  setFields (fbeValue) {
    this.id.set(fbeValue.id)
    this.SortOrder.set(fbeValue.SortOrder)
    this.SymbolGroupId.set(fbeValue.SymbolGroupId)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.Alias.set(fbeValue.Alias)
    this.Source.set(fbeValue.Source)
    this.Precision.set(fbeValue.Precision)
    this.MarginCurrencyId.set(fbeValue.MarginCurrencyId)
    this.MarginCurrencyPrecision.set(fbeValue.MarginCurrencyPrecision)
    this.MarginCalculationType.set(fbeValue.MarginCalculationType)
    this.ProfitCurrencyId.set(fbeValue.ProfitCurrencyId)
    this.ProfitCurrencyPrecision.set(fbeValue.ProfitCurrencyPrecision)
    this.ProfitCalculationType.set(fbeValue.ProfitCalculationType)
    this.Color.set(fbeValue.Color)
    this.IsTradeAllowed.set(fbeValue.IsTradeAllowed)
    this.ContractSize.set(fbeValue.ContractSize)
    this.MinTradeAmount.set(fbeValue.MinTradeAmount)
    this.MaxTradeAmount.set(fbeValue.MaxTradeAmount)
    this.TradeAmountStep.set(fbeValue.TradeAmountStep)
    this.DefaultExpiration.set(fbeValue.DefaultExpiration)
    this.IsSwapEnabled.set(fbeValue.IsSwapEnabled)
    this.SwapSettings.set(fbeValue.SwapSettings)
    this.MarketOrderMarginRate.set(fbeValue.MarketOrderMarginRate)
    this.LimitOrderMarginRate.set(fbeValue.LimitOrderMarginRate)
    this.StopOrderMarginRate.set(fbeValue.StopOrderMarginRate)
    this.TradingMode.set(fbeValue.TradingMode)
    this.Index.set(fbeValue.Index)
    this.StopsLevel.set(fbeValue.StopsLevel)
    this.IsFavorite.set(fbeValue.IsFavorite)
    this.SortOrderFavorite.set(fbeValue.SortOrderFavorite)
    this.Postfix.set(fbeValue.Postfix)
    this.InitialMargin.set(fbeValue.InitialMargin)
    this.HedgedMargin.set(fbeValue.HedgedMargin)
    this.ColorEffective.set(fbeValue.ColorEffective)
    this.IsTradeAllowedEffective.set(fbeValue.IsTradeAllowedEffective)
    this.DefaultExpirationEffective.set(fbeValue.DefaultExpirationEffective)
    this.TradingModeEffective.set(fbeValue.TradingModeEffective)
    this.MarginCurrencyPrecisionEffective.set(fbeValue.MarginCurrencyPrecisionEffective)
    this.ProfitCurrencyPrecisionEffective.set(fbeValue.ProfitCurrencyPrecisionEffective)
  }
}

export { FieldModelSymbol };

/**
 * Fast Binary Encoding Symbol model
 */
class SymbolModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSymbol(this.buffer, 4)
  }

  /**
   * Get the Symbol model
   * @this {!SymbolModel}
   * @returns {!FieldModelSymbol} model Symbol model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SymbolModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SymbolModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SymbolModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SymbolModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSymbol.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SymbolModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SymbolModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SymbolModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SymbolModel}
   * @param {!Symbol} value Symbol value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SymbolModel}
   * @param {!Symbol} value Symbol value, defaults is new Symbol()
   * @return {!object} Deserialized Symbol value and its size
   */
  deserialize (value = new Symbol()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Symbol(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Symbol(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SymbolModel}
   * @param {!number} prev Previous Symbol model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SymbolModel };
