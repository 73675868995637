import React, { useState, useEffect } from 'react';

import styles from './CurrentTime.module.scss';
import { getFormattedDate, getOffset, FORMAT_DT } from '@utils/datetime';

export const CurrentTime = () => {
    const [time, setTime] = useState<Date>(new Date());

    useEffect(() => {
        const secTimer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(secTimer);
    }, []);

    const offset = getOffset() / 60;
    const sign = offset > 0 ? '+' : offset < 0 ? '-' :  '';

    return <div className={styles.currentTime} title={`UTC ${sign}${offset}h`}>{getFormattedDate(time, FORMAT_DT)}</div>;
};
