import React, { Context } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { CustomColumnType } from './types';
import { keyMap, t } from '@localization';
import { Filter, FilterIcon, getFlatDataIndex } from './RowFilters/utils';
import { CheckboxValuesT, IFilterContext } from './RowFilters/types';
import styles from './TableExt.module.scss';
import { formatNumber } from '@utils/number';

export declare type DataIndex = undefined | string[] | string | number | readonly (string | number)[];

export function renderColumns<ContextDataT>(
    columns: CustomColumnType<ContextDataT>[],
    draggable: boolean,
    modified: boolean,
    filterValues: Context<IFilterContext<ContextDataT>>,
    dragIcon?: JSX.Element,
): CustomColumnType<ContextDataT>[] {
    const renderedColumns: CustomColumnType<ContextDataT>[] = columns.map((column) => ({
        ...column,
        filterDropdown: column.filterOptions
            ? (props) => (
                <Filter {...props} options={column.filterOptions} dataIndex={getFlatDataIndex(column.dataIndex)} />
            )
            : undefined,
        filterIcon: column.filterOptions ? <FilterIcon dataIndex={getFlatDataIndex(column.dataIndex)} /> : undefined,
        render: (value, record, index) => {
            const renderOption = column.render ? column.render(value, record, index) : value;
            if (column.alignContent) {
                return <div className={styles[`colContentAlign_${column.alignContent}`]}>{renderOption}</div>;
            }
            return renderOption;
        },
    }));
    if (draggable) {
        const staticIcon = dragIcon || (
            <MenuOutlined style={{ color: modified ? '#bfbfbf' : '#47c8ff' }} className={styles.dragIcon} />
        );
        const DragHandle = SortableHandle(() => staticIcon);
        renderedColumns.splice(0, 0, {
            title: t(keyMap.table.order),
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => (modified ? staticIcon : <DragHandle />),
        });
    }
    return renderedColumns;
}

export const getFilterValuesFromConstants = (enumObj): CheckboxValuesT =>
    Object.keys(enumObj).map((key) => ({ title: enumObj[key], compareValue: +key }));


export const stringifyDataIndex = (dataIndex: DataIndex): string => {
    if (dataIndex === undefined) return '';
    return Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex.toString();
};

export const renderNumberCellByPrecision = (
    value: number | string | null | undefined,
    precision?: number,
    placeholder?: string,
    fillZeros?: boolean,
) => formatNumber(value, precision, placeholder, fillZeros);
