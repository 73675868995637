
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {MailServerCreateResponse} from './MailServerCreateResponse';
import {SSLMode} from '../core/SSLMode';
import {FieldModelSSLMode} from '../core/SSLMode';
import {EventType} from '../core/EventType';
import {FieldModelEventType} from '../core/EventType';

/**
 * MailServerCreateRequest struct
 */
class MailServerCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} sMTPServer
   * @param {!string=} sMTPLogin
   * @param {!string=} sMTPPassword
   * @param {!string=} senderEmail
   * @param {!string=} senderName
   * @param {!SSLMode=} sSLMode
   * @param {!Set=} eventTypes
   * @param {!boolean=} isEnabled
   * @param {!number=} sMTPServerPort
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), name = '', comment = '', description = '', sMTPServer = '', sMTPLogin = '', sMTPPassword = '', senderEmail = '', senderName = '', sSLMode = new SSLMode(), eventTypes = new Set(), isEnabled = false, sMTPServerPort = 0) {
    super()
    super.copy(parent)
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.SMTPServer = sMTPServer
    this.SMTPLogin = sMTPLogin
    this.SMTPPassword = sMTPPassword
    this.SenderEmail = senderEmail
    this.SenderName = senderName
    this.SSLMode = sSLMode
    this.EventTypes = eventTypes
    this.IsEnabled = isEnabled
    this.SMTPServerPort = sMTPServerPort
  }

  /**
   * Copy struct (shallow copy)
   * @this {!MailServerCreateRequest}
   * @param {!MailServerCreateRequest} other Other struct
   * @returns {!MailServerCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.SMTPServer != null) {
      this.SMTPServer = other.SMTPServer
    } else {
      this.SMTPServer = null
    }
    if (other.SMTPLogin != null) {
      this.SMTPLogin = other.SMTPLogin
    } else {
      this.SMTPLogin = null
    }
    if (other.SMTPPassword != null) {
      this.SMTPPassword = other.SMTPPassword
    } else {
      this.SMTPPassword = null
    }
    if (other.SenderEmail != null) {
      this.SenderEmail = other.SenderEmail
    } else {
      this.SenderEmail = null
    }
    if (other.SenderName != null) {
      this.SenderName = other.SenderName
    } else {
      this.SenderName = null
    }
    if (other.SSLMode != null) {
      this.SSLMode = SSLMode.fromObject(other.SSLMode)
    } else {
      this.SSLMode = null
    }
    if (other.EventTypes != null) {
      this.EventTypes = new Set()
      for (let item of other.EventTypes) {
        if (item != null) {
          let tempItem
          tempItem = EventType.fromObject(item)
          this.EventTypes.add(tempItem)
        } else {
          this.EventTypes.add(null)
        }
      }
    } else {
      this.EventTypes = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.SMTPServerPort != null) {
      this.SMTPServerPort = other.SMTPServerPort
    } else {
      this.SMTPServerPort = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!MailServerCreateRequest}
   * @returns {!MailServerCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new MailServerCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new MailServerCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!MailServerCreateRequest}
   * @param {!MailServerCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof MailServerCreateRequest)) {
      throw new TypeError('Instance of MailServerCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!MailServerCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      SMTPServer: ((this.SMTPServer != null) ? this.SMTPServer : null),
      SMTPLogin: ((this.SMTPLogin != null) ? this.SMTPLogin : null),
      SMTPPassword: ((this.SMTPPassword != null) ? this.SMTPPassword : null),
      SenderEmail: ((this.SenderEmail != null) ? this.SenderEmail : null),
      SenderName: ((this.SenderName != null) ? this.SenderName : null),
      SSLMode: ((this.SSLMode != null) ? this.SSLMode : null),
      EventTypes: ((this.EventTypes != null) ? Array.from(this.EventTypes, item => ((item != null) ? item : null)) : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      SMTPServerPort: ((this.SMTPServerPort != null) ? this.SMTPServerPort : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return MailServerCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!MailServerCreateRequest} other Object value
   * @returns {!MailServerCreateRequest} Created struct
   */
  static fromObject (other) {
    return new MailServerCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!MailServerCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return MailServerCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!MailServerCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6360
  }
}

export { MailServerCreateRequest };

/**
 * Fast Binary Encoding MailServerCreateRequest field model
 */
class FieldModelMailServerCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Name = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.SMTPServer = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.SMTPLogin = new fbe.FieldModelString(buffer, this.SMTPServer.fbeOffset + this.SMTPServer.fbeSize)
    this.SMTPPassword = new fbe.FieldModelString(buffer, this.SMTPLogin.fbeOffset + this.SMTPLogin.fbeSize)
    this.SenderEmail = new fbe.FieldModelString(buffer, this.SMTPPassword.fbeOffset + this.SMTPPassword.fbeSize)
    this.SenderName = new fbe.FieldModelString(buffer, this.SenderEmail.fbeOffset + this.SenderEmail.fbeSize)
    this.SSLMode = new FieldModelSSLMode(buffer, this.SenderName.fbeOffset + this.SenderName.fbeSize)
    this.EventTypes = new fbe.FieldModelSet(new FieldModelEventType(buffer, this.SSLMode.fbeOffset + this.SSLMode.fbeSize), buffer, this.SSLMode.fbeOffset + this.SSLMode.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.EventTypes.fbeOffset + this.EventTypes.fbeSize)
    this.SMTPServerPort = new fbe.FieldModelUInt16(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.SMTPServer.fbeSize + this.SMTPLogin.fbeSize + this.SMTPPassword.fbeSize + this.SenderEmail.fbeSize + this.SenderName.fbeSize + this.SSLMode.fbeSize + this.EventTypes.fbeSize + this.IsEnabled.fbeSize + this.SMTPServerPort.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.SMTPServer.fbeExtra + this.SMTPLogin.fbeExtra + this.SMTPPassword.fbeExtra + this.SenderEmail.fbeExtra + this.SenderName.fbeExtra + this.SSLMode.fbeExtra + this.EventTypes.fbeExtra + this.IsEnabled.fbeExtra + this.SMTPServerPort.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMailServerCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6360
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.SMTPServer.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SMTPServer.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPServer.fbeSize

    if ((fbeCurrentSize + this.SMTPLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SMTPLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPLogin.fbeSize

    if ((fbeCurrentSize + this.SMTPPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SMTPPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPPassword.fbeSize

    if ((fbeCurrentSize + this.SenderEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SenderEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SenderEmail.fbeSize

    if ((fbeCurrentSize + this.SenderName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SenderName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SenderName.fbeSize

    if ((fbeCurrentSize + this.SSLMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SSLMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SSLMode.fbeSize

    if ((fbeCurrentSize + this.EventTypes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.EventTypes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventTypes.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.SMTPServerPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SMTPServerPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPServerPort.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!MailServerCreateRequest} fbeValue Default value, defaults is new MailServerCreateRequest()
   * @returns {!MailServerCreateRequest} MailServerCreateRequest value
   */
  get (fbeValue = new MailServerCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!MailServerCreateRequest} fbeValue MailServerCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.SMTPServer.fbeSize) <= fbeStructSize) {
      fbeValue.SMTPServer = this.SMTPServer.get()
    } else {
      fbeValue.SMTPServer = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPServer.fbeSize

    if ((fbeCurrentSize + this.SMTPLogin.fbeSize) <= fbeStructSize) {
      fbeValue.SMTPLogin = this.SMTPLogin.get()
    } else {
      fbeValue.SMTPLogin = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPLogin.fbeSize

    if ((fbeCurrentSize + this.SMTPPassword.fbeSize) <= fbeStructSize) {
      fbeValue.SMTPPassword = this.SMTPPassword.get()
    } else {
      fbeValue.SMTPPassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPPassword.fbeSize

    if ((fbeCurrentSize + this.SenderEmail.fbeSize) <= fbeStructSize) {
      fbeValue.SenderEmail = this.SenderEmail.get()
    } else {
      fbeValue.SenderEmail = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SenderEmail.fbeSize

    if ((fbeCurrentSize + this.SenderName.fbeSize) <= fbeStructSize) {
      fbeValue.SenderName = this.SenderName.get()
    } else {
      fbeValue.SenderName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SenderName.fbeSize

    if ((fbeCurrentSize + this.SSLMode.fbeSize) <= fbeStructSize) {
      fbeValue.SSLMode = this.SSLMode.get()
    } else {
      fbeValue.SSLMode = new SSLMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SSLMode.fbeSize

    if ((fbeCurrentSize + this.EventTypes.fbeSize) <= fbeStructSize) {
      this.EventTypes.get(fbeValue.EventTypes)
    } else {
      fbeValue.EventTypes.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventTypes.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.SMTPServerPort.fbeSize) <= fbeStructSize) {
      fbeValue.SMTPServerPort = this.SMTPServerPort.get()
    } else {
      fbeValue.SMTPServerPort = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SMTPServerPort.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMailServerCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMailServerCreateRequest}
   * @param {!MailServerCreateRequest} fbeValue MailServerCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMailServerCreateRequest}
   * @param {MailServerCreateRequest} fbeValue MailServerCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.SMTPServer.set(fbeValue.SMTPServer)
    this.SMTPLogin.set(fbeValue.SMTPLogin)
    this.SMTPPassword.set(fbeValue.SMTPPassword)
    this.SenderEmail.set(fbeValue.SenderEmail)
    this.SenderName.set(fbeValue.SenderName)
    this.SSLMode.set(fbeValue.SSLMode)
    this.EventTypes.set(fbeValue.EventTypes)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.SMTPServerPort.set(fbeValue.SMTPServerPort)
  }
}

export { FieldModelMailServerCreateRequest };

/**
 * Fast Binary Encoding MailServerCreateRequest model
 */
class MailServerCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMailServerCreateRequest(this.buffer, 4)
  }

  /**
   * Get the MailServerCreateRequest model
   * @this {!MailServerCreateRequestModel}
   * @returns {!FieldModelMailServerCreateRequest} model MailServerCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!MailServerCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!MailServerCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return MailServerCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!MailServerCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMailServerCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!MailServerCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!MailServerCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!MailServerCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!MailServerCreateRequestModel}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!MailServerCreateRequestModel}
   * @param {!MailServerCreateRequest} value MailServerCreateRequest value, defaults is new MailServerCreateRequest()
   * @return {!object} Deserialized MailServerCreateRequest value and its size
   */
  deserialize (value = new MailServerCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new MailServerCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new MailServerCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!MailServerCreateRequestModel}
   * @param {!number} prev Previous MailServerCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { MailServerCreateRequestModel };
MailServerCreateRequest.__has_response = true;
MailServerCreateRequest.__response_class = MailServerCreateResponse;
