
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {LoginResponse} from './LoginResponse';
import {LoginType} from './LoginType';
import {FieldModelLoginType} from './LoginType';
import {LoginMAParams} from './LoginMAParams';
import {FieldModelLoginMAParams} from './LoginMAParams';
import {LoginInfo} from './LoginInfo';
import {FieldModelLoginInfo} from './LoginInfo';

/**
 * LoginRequest struct
 */
class LoginRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {string=} authToken
   * @param {number=} login
   * @param {string=} password
   * @param {string=} oTPToken
   * @param {number=} protocolVersionMajor
   * @param {number=} protocolVersionMinor
   * @param {!LoginType=} loginType
   * @param {!Map=} additionalParameters
   * @param {LoginMAParams=} loginMA
   * @param {LoginInfo=} loginInfo
   * @constructor
   */
  constructor (parent = new Request(), authToken = null, login = null, password = null, oTPToken = null, protocolVersionMajor = null, protocolVersionMinor = null, loginType = new LoginType(), additionalParameters = new Map(), loginMA = null, loginInfo = null) {
    super()
    super.copy(parent)
    this.AuthToken = authToken
    this.Login = login
    this.Password = password
    this.OTPToken = oTPToken
    this.ProtocolVersionMajor = protocolVersionMajor
    this.ProtocolVersionMinor = protocolVersionMinor
    this.LoginType = loginType
    this.AdditionalParameters = additionalParameters
    this.LoginMA = loginMA
    this.LoginInfo = loginInfo
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LoginRequest}
   * @param {!LoginRequest} other Other struct
   * @returns {!LoginRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.AuthToken != null) {
      this.AuthToken = other.AuthToken
    } else {
      this.AuthToken = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.OTPToken != null) {
      this.OTPToken = other.OTPToken
    } else {
      this.OTPToken = null
    }
    if (other.ProtocolVersionMajor != null) {
      this.ProtocolVersionMajor = other.ProtocolVersionMajor
    } else {
      this.ProtocolVersionMajor = null
    }
    if (other.ProtocolVersionMinor != null) {
      this.ProtocolVersionMinor = other.ProtocolVersionMinor
    } else {
      this.ProtocolVersionMinor = null
    }
    if (other.LoginType != null) {
      this.LoginType = LoginType.fromObject(other.LoginType)
    } else {
      this.LoginType = null
    }
    if (other.AdditionalParameters != null) {
      this.AdditionalParameters = new Map()
      Object.keys(other.AdditionalParameters).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.AdditionalParameters[key] != null) {
            let tempValue
            tempValue = other.AdditionalParameters[key]
            this.AdditionalParameters.set(tempKey, tempValue)
          } else {
            this.AdditionalParameters.set(tempKey, null)
          }
        }
      })
    } else {
      this.AdditionalParameters = null
    }
    if (other.LoginMA != null) {
      this.LoginMA = LoginMAParams.fromObject(other.LoginMA)
    } else {
      this.LoginMA = null
    }
    if (other.LoginInfo != null) {
      this.LoginInfo = LoginInfo.fromObject(other.LoginInfo)
    } else {
      this.LoginInfo = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LoginRequest}
   * @returns {!LoginRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LoginRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LoginRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LoginRequest}
   * @param {!LoginRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LoginRequest)) {
      throw new TypeError('Instance of LoginRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LoginRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      AuthToken: ((this.AuthToken != null) ? this.AuthToken : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      Password: ((this.Password != null) ? this.Password : null),
      OTPToken: ((this.OTPToken != null) ? this.OTPToken : null),
      ProtocolVersionMajor: ((this.ProtocolVersionMajor != null) ? this.ProtocolVersionMajor : null),
      ProtocolVersionMinor: ((this.ProtocolVersionMinor != null) ? this.ProtocolVersionMinor : null),
      LoginType: ((this.LoginType != null) ? this.LoginType : null),
      AdditionalParameters: ((this.AdditionalParameters != null) ? fbe.MapToObject(new Map(Array.from(this.AdditionalParameters, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null),
      LoginMA: ((this.LoginMA != null) ? this.LoginMA : null),
      LoginInfo: ((this.LoginInfo != null) ? this.LoginInfo : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LoginRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LoginRequest} other Object value
   * @returns {!LoginRequest} Created struct
   */
  static fromObject (other) {
    return new LoginRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LoginRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LoginRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LoginRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5003
  }
}

export { LoginRequest };

/**
 * Fast Binary Encoding LoginRequest field model
 */
class FieldModelLoginRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.AuthToken = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Login = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AuthToken.fbeOffset + this.AuthToken.fbeSize), buffer, this.AuthToken.fbeOffset + this.AuthToken.fbeSize)
    this.Password = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.OTPToken = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize), buffer, this.Password.fbeOffset + this.Password.fbeSize)
    this.ProtocolVersionMajor = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.OTPToken.fbeOffset + this.OTPToken.fbeSize), buffer, this.OTPToken.fbeOffset + this.OTPToken.fbeSize)
    this.ProtocolVersionMinor = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize), buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize)
    this.LoginType = new FieldModelLoginType(buffer, this.ProtocolVersionMinor.fbeOffset + this.ProtocolVersionMinor.fbeSize)
    this.AdditionalParameters = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize), new fbe.FieldModelString(buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize), buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize)
    this.LoginMA = new fbe.FieldModelOptional(new FieldModelLoginMAParams(buffer, this.AdditionalParameters.fbeOffset + this.AdditionalParameters.fbeSize), buffer, this.AdditionalParameters.fbeOffset + this.AdditionalParameters.fbeSize)
    this.LoginInfo = new fbe.FieldModelOptional(new FieldModelLoginInfo(buffer, this.LoginMA.fbeOffset + this.LoginMA.fbeSize), buffer, this.LoginMA.fbeOffset + this.LoginMA.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelLoginRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.AuthToken.fbeSize + this.Login.fbeSize + this.Password.fbeSize + this.OTPToken.fbeSize + this.ProtocolVersionMajor.fbeSize + this.ProtocolVersionMinor.fbeSize + this.LoginType.fbeSize + this.AdditionalParameters.fbeSize + this.LoginMA.fbeSize + this.LoginInfo.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.AuthToken.fbeExtra + this.Login.fbeExtra + this.Password.fbeExtra + this.OTPToken.fbeExtra + this.ProtocolVersionMajor.fbeExtra + this.ProtocolVersionMinor.fbeExtra + this.LoginType.fbeExtra + this.AdditionalParameters.fbeExtra + this.LoginMA.fbeExtra + this.LoginInfo.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLoginRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5003
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLoginRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLoginRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AuthToken.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AuthToken.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthToken.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.OTPToken.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OTPToken.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPToken.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.AdditionalParameters.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AdditionalParameters.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AdditionalParameters.fbeSize

    if ((fbeCurrentSize + this.LoginMA.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginMA.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginMA.fbeSize

    if ((fbeCurrentSize + this.LoginInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginInfo.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLoginRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLoginRequest}
   * @param {!LoginRequest} fbeValue Default value, defaults is new LoginRequest()
   * @returns {!LoginRequest} LoginRequest value
   */
  get (fbeValue = new LoginRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLoginRequest}
   * @param {!LoginRequest} fbeValue LoginRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AuthToken.fbeSize) <= fbeStructSize) {
      fbeValue.AuthToken = this.AuthToken.get()
    } else {
      fbeValue.AuthToken = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthToken.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.OTPToken.fbeSize) <= fbeStructSize) {
      fbeValue.OTPToken = this.OTPToken.get()
    } else {
      fbeValue.OTPToken = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPToken.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMajor = this.ProtocolVersionMajor.get()
    } else {
      fbeValue.ProtocolVersionMajor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMinor = this.ProtocolVersionMinor.get()
    } else {
      fbeValue.ProtocolVersionMinor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) <= fbeStructSize) {
      fbeValue.LoginType = this.LoginType.get()
    } else {
      fbeValue.LoginType = new LoginType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.AdditionalParameters.fbeSize) <= fbeStructSize) {
      this.AdditionalParameters.get(fbeValue.AdditionalParameters)
    } else {
      fbeValue.AdditionalParameters.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AdditionalParameters.fbeSize

    if ((fbeCurrentSize + this.LoginMA.fbeSize) <= fbeStructSize) {
      fbeValue.LoginMA = this.LoginMA.get()
    } else {
      fbeValue.LoginMA = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginMA.fbeSize

    if ((fbeCurrentSize + this.LoginInfo.fbeSize) <= fbeStructSize) {
      fbeValue.LoginInfo = this.LoginInfo.get()
    } else {
      fbeValue.LoginInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginInfo.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLoginRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLoginRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLoginRequest}
   * @param {!LoginRequest} fbeValue LoginRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLoginRequest}
   * @param {LoginRequest} fbeValue LoginRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.AuthToken.set(fbeValue.AuthToken)
    this.Login.set(fbeValue.Login)
    this.Password.set(fbeValue.Password)
    this.OTPToken.set(fbeValue.OTPToken)
    this.ProtocolVersionMajor.set(fbeValue.ProtocolVersionMajor)
    this.ProtocolVersionMinor.set(fbeValue.ProtocolVersionMinor)
    this.LoginType.set(fbeValue.LoginType)
    this.AdditionalParameters.set(fbeValue.AdditionalParameters)
    this.LoginMA.set(fbeValue.LoginMA)
    this.LoginInfo.set(fbeValue.LoginInfo)
  }
}

export { FieldModelLoginRequest };

/**
 * Fast Binary Encoding LoginRequest model
 */
class LoginRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLoginRequest(this.buffer, 4)
  }

  /**
   * Get the LoginRequest model
   * @this {!LoginRequestModel}
   * @returns {!FieldModelLoginRequest} model LoginRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LoginRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LoginRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LoginRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LoginRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLoginRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LoginRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LoginRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LoginRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LoginRequestModel}
   * @param {!LoginRequest} value LoginRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LoginRequestModel}
   * @param {!LoginRequest} value LoginRequest value, defaults is new LoginRequest()
   * @return {!object} Deserialized LoginRequest value and its size
   */
  deserialize (value = new LoginRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LoginRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LoginRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LoginRequestModel}
   * @param {!number} prev Previous LoginRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LoginRequestModel };
LoginRequest.__has_response = true;
LoginRequest.__response_class = LoginResponse;
