import locale from './translations/en-GB.json';
import { Locale } from './translations/locale';

let resource: Locale | null = null;

const updatedResourceValue = (object: any, parentTmp: string) => {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'string') {
      const resultTmp = `${parentTmp ? `${parentTmp}.` : ''}${key}`;
      object[key] = resultTmp;
    } else {
      const childTmp = `${parentTmp ? `${parentTmp}.` : ''}${key}`;
      updatedResourceValue(object[key], childTmp);
    }
  });
};

const loadData = () => {
  if (resource === null) {
    resource = JSON.parse(JSON.stringify(locale));
    updatedResourceValue(resource, '');
  }
  return resource!;
};

export const keyMapLocale: Locale = loadData();
