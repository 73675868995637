
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FilterUint64} from './FilterUint64';
import {FieldModelFilterUint64} from './FilterUint64';
import {FilterDouble} from './FilterDouble';
import {FieldModelFilterDouble} from './FilterDouble';
import {FilterUint32} from './FilterUint32';
import {FieldModelFilterUint32} from './FilterUint32';
import {FilterEnum} from './FilterEnum';
import {FieldModelFilterEnum} from './FilterEnum';

/**
 * AccountMarginFiltration struct
 */
class AccountMarginFiltration {
  /**
   * Initialize struct
   * @param {FilterUint64=} login
   * @param {FilterDouble=} balance
   * @param {FilterDouble=} credit
   * @param {FilterUint32=} leverage
   * @param {FilterDouble=} equity
   * @param {FilterDouble=} marginValue
   * @param {FilterDouble=} marginLevel
   * @param {FilterEnum=} levelType
   * @param {FilterDouble=} dayOpenProfit
   * @param {FilterDouble=} prevDayBalance
   * @param {FilterDouble=} prevMonthBalance
   * @param {FilterDouble=} dayCloseProfit
   * @param {FilterDouble=} dayDeposit
   * @param {FilterUint32=} maxLeverage
   * @param {FilterDouble=} prevDayOpenProfit
   * @param {FilterDouble=} openProfit
   * @param {FilterDouble=} totalCloseProfit
   * @param {FilterDouble=} totalDeposit
   * @param {FilterDouble=} totalWithdrawal
   * @constructor
   */
  constructor (login = null, balance = null, credit = null, leverage = null, equity = null, marginValue = null, marginLevel = null, levelType = null, dayOpenProfit = null, prevDayBalance = null, prevMonthBalance = null, dayCloseProfit = null, dayDeposit = null, maxLeverage = null, prevDayOpenProfit = null, openProfit = null, totalCloseProfit = null, totalDeposit = null, totalWithdrawal = null) {
    this.Login = login
    this.Balance = balance
    this.Credit = credit
    this.Leverage = leverage
    this.Equity = equity
    this.MarginValue = marginValue
    this.MarginLevel = marginLevel
    this.LevelType = levelType
    this.DayOpenProfit = dayOpenProfit
    this.PrevDayBalance = prevDayBalance
    this.PrevMonthBalance = prevMonthBalance
    this.DayCloseProfit = dayCloseProfit
    this.DayDeposit = dayDeposit
    this.MaxLeverage = maxLeverage
    this.PrevDayOpenProfit = prevDayOpenProfit
    this.OpenProfit = openProfit
    this.TotalCloseProfit = totalCloseProfit
    this.TotalDeposit = totalDeposit
    this.TotalWithdrawal = totalWithdrawal
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountMarginFiltration}
   * @param {!AccountMarginFiltration} other Other struct
   * @returns {!AccountMarginFiltration} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = FilterUint64.fromObject(other.Login)
    } else {
      this.Login = null
    }
    if (other.Balance != null) {
      this.Balance = FilterDouble.fromObject(other.Balance)
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = FilterDouble.fromObject(other.Credit)
    } else {
      this.Credit = null
    }
    if (other.Leverage != null) {
      this.Leverage = FilterUint32.fromObject(other.Leverage)
    } else {
      this.Leverage = null
    }
    if (other.Equity != null) {
      this.Equity = FilterDouble.fromObject(other.Equity)
    } else {
      this.Equity = null
    }
    if (other.MarginValue != null) {
      this.MarginValue = FilterDouble.fromObject(other.MarginValue)
    } else {
      this.MarginValue = null
    }
    if (other.MarginLevel != null) {
      this.MarginLevel = FilterDouble.fromObject(other.MarginLevel)
    } else {
      this.MarginLevel = null
    }
    if (other.LevelType != null) {
      this.LevelType = FilterEnum.fromObject(other.LevelType)
    } else {
      this.LevelType = null
    }
    if (other.DayOpenProfit != null) {
      this.DayOpenProfit = FilterDouble.fromObject(other.DayOpenProfit)
    } else {
      this.DayOpenProfit = null
    }
    if (other.PrevDayBalance != null) {
      this.PrevDayBalance = FilterDouble.fromObject(other.PrevDayBalance)
    } else {
      this.PrevDayBalance = null
    }
    if (other.PrevMonthBalance != null) {
      this.PrevMonthBalance = FilterDouble.fromObject(other.PrevMonthBalance)
    } else {
      this.PrevMonthBalance = null
    }
    if (other.DayCloseProfit != null) {
      this.DayCloseProfit = FilterDouble.fromObject(other.DayCloseProfit)
    } else {
      this.DayCloseProfit = null
    }
    if (other.DayDeposit != null) {
      this.DayDeposit = FilterDouble.fromObject(other.DayDeposit)
    } else {
      this.DayDeposit = null
    }
    if (other.MaxLeverage != null) {
      this.MaxLeverage = FilterUint32.fromObject(other.MaxLeverage)
    } else {
      this.MaxLeverage = null
    }
    if (other.PrevDayOpenProfit != null) {
      this.PrevDayOpenProfit = FilterDouble.fromObject(other.PrevDayOpenProfit)
    } else {
      this.PrevDayOpenProfit = null
    }
    if (other.OpenProfit != null) {
      this.OpenProfit = FilterDouble.fromObject(other.OpenProfit)
    } else {
      this.OpenProfit = null
    }
    if (other.TotalCloseProfit != null) {
      this.TotalCloseProfit = FilterDouble.fromObject(other.TotalCloseProfit)
    } else {
      this.TotalCloseProfit = null
    }
    if (other.TotalDeposit != null) {
      this.TotalDeposit = FilterDouble.fromObject(other.TotalDeposit)
    } else {
      this.TotalDeposit = null
    }
    if (other.TotalWithdrawal != null) {
      this.TotalWithdrawal = FilterDouble.fromObject(other.TotalWithdrawal)
    } else {
      this.TotalWithdrawal = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountMarginFiltration}
   * @returns {!AccountMarginFiltration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountMarginFiltrationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountMarginFiltrationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountMarginFiltration}
   * @param {!AccountMarginFiltration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountMarginFiltration)) {
      throw new TypeError('Instance of AccountMarginFiltration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountMarginFiltration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      Equity: ((this.Equity != null) ? this.Equity : null),
      MarginValue: ((this.MarginValue != null) ? this.MarginValue : null),
      MarginLevel: ((this.MarginLevel != null) ? this.MarginLevel : null),
      LevelType: ((this.LevelType != null) ? this.LevelType : null),
      DayOpenProfit: ((this.DayOpenProfit != null) ? this.DayOpenProfit : null),
      PrevDayBalance: ((this.PrevDayBalance != null) ? this.PrevDayBalance : null),
      PrevMonthBalance: ((this.PrevMonthBalance != null) ? this.PrevMonthBalance : null),
      DayCloseProfit: ((this.DayCloseProfit != null) ? this.DayCloseProfit : null),
      DayDeposit: ((this.DayDeposit != null) ? this.DayDeposit : null),
      MaxLeverage: ((this.MaxLeverage != null) ? this.MaxLeverage : null),
      PrevDayOpenProfit: ((this.PrevDayOpenProfit != null) ? this.PrevDayOpenProfit : null),
      OpenProfit: ((this.OpenProfit != null) ? this.OpenProfit : null),
      TotalCloseProfit: ((this.TotalCloseProfit != null) ? this.TotalCloseProfit : null),
      TotalDeposit: ((this.TotalDeposit != null) ? this.TotalDeposit : null),
      TotalWithdrawal: ((this.TotalWithdrawal != null) ? this.TotalWithdrawal : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountMarginFiltration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountMarginFiltration} other Object value
   * @returns {!AccountMarginFiltration} Created struct
   */
  static fromObject (other) {
    return new AccountMarginFiltration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountMarginFiltration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountMarginFiltration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountMarginFiltration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 121
  }
}

export { AccountMarginFiltration };

/**
 * Fast Binary Encoding AccountMarginFiltration field model
 */
class FieldModelAccountMarginFiltration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, 4 + 4), buffer, 4 + 4)
    this.Balance = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.Credit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize), buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Leverage = new fbe.FieldModelOptional(new FieldModelFilterUint32(buffer, this.Credit.fbeOffset + this.Credit.fbeSize), buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.Equity = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize), buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
    this.MarginValue = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.Equity.fbeOffset + this.Equity.fbeSize), buffer, this.Equity.fbeOffset + this.Equity.fbeSize)
    this.MarginLevel = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.MarginValue.fbeOffset + this.MarginValue.fbeSize), buffer, this.MarginValue.fbeOffset + this.MarginValue.fbeSize)
    this.LevelType = new fbe.FieldModelOptional(new FieldModelFilterEnum(buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize), buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize)
    this.DayOpenProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.LevelType.fbeOffset + this.LevelType.fbeSize), buffer, this.LevelType.fbeOffset + this.LevelType.fbeSize)
    this.PrevDayBalance = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.DayOpenProfit.fbeOffset + this.DayOpenProfit.fbeSize), buffer, this.DayOpenProfit.fbeOffset + this.DayOpenProfit.fbeSize)
    this.PrevMonthBalance = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize), buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize)
    this.DayCloseProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.PrevMonthBalance.fbeOffset + this.PrevMonthBalance.fbeSize), buffer, this.PrevMonthBalance.fbeOffset + this.PrevMonthBalance.fbeSize)
    this.DayDeposit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize), buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize)
    this.MaxLeverage = new fbe.FieldModelOptional(new FieldModelFilterUint32(buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize), buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize)
    this.PrevDayOpenProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize), buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize)
    this.OpenProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.PrevDayOpenProfit.fbeOffset + this.PrevDayOpenProfit.fbeSize), buffer, this.PrevDayOpenProfit.fbeOffset + this.PrevDayOpenProfit.fbeSize)
    this.TotalCloseProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.OpenProfit.fbeOffset + this.OpenProfit.fbeSize), buffer, this.OpenProfit.fbeOffset + this.OpenProfit.fbeSize)
    this.TotalDeposit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.TotalCloseProfit.fbeOffset + this.TotalCloseProfit.fbeSize), buffer, this.TotalCloseProfit.fbeOffset + this.TotalCloseProfit.fbeSize)
    this.TotalWithdrawal = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.TotalDeposit.fbeOffset + this.TotalDeposit.fbeSize), buffer, this.TotalDeposit.fbeOffset + this.TotalDeposit.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Leverage.fbeSize + this.Equity.fbeSize + this.MarginValue.fbeSize + this.MarginLevel.fbeSize + this.LevelType.fbeSize + this.DayOpenProfit.fbeSize + this.PrevDayBalance.fbeSize + this.PrevMonthBalance.fbeSize + this.DayCloseProfit.fbeSize + this.DayDeposit.fbeSize + this.MaxLeverage.fbeSize + this.PrevDayOpenProfit.fbeSize + this.OpenProfit.fbeSize + this.TotalCloseProfit.fbeSize + this.TotalDeposit.fbeSize + this.TotalWithdrawal.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Leverage.fbeExtra + this.Equity.fbeExtra + this.MarginValue.fbeExtra + this.MarginLevel.fbeExtra + this.LevelType.fbeExtra + this.DayOpenProfit.fbeExtra + this.PrevDayBalance.fbeExtra + this.PrevMonthBalance.fbeExtra + this.DayCloseProfit.fbeExtra + this.DayDeposit.fbeExtra + this.MaxLeverage.fbeExtra + this.PrevDayOpenProfit.fbeExtra + this.OpenProfit.fbeExtra + this.TotalCloseProfit.fbeExtra + this.TotalDeposit.fbeExtra + this.TotalWithdrawal.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountMarginFiltration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 121
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Equity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.MarginValue.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginValue.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginValue.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.LevelType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LevelType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LevelType.fbeSize

    if ((fbeCurrentSize + this.DayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevMonthBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalWithdrawal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!AccountMarginFiltration} fbeValue Default value, defaults is new AccountMarginFiltration()
   * @returns {!AccountMarginFiltration} AccountMarginFiltration value
   */
  get (fbeValue = new AccountMarginFiltration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!AccountMarginFiltration} fbeValue AccountMarginFiltration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) <= fbeStructSize) {
      fbeValue.Equity = this.Equity.get()
    } else {
      fbeValue.Equity = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.MarginValue.fbeSize) <= fbeStructSize) {
      fbeValue.MarginValue = this.MarginValue.get()
    } else {
      fbeValue.MarginValue = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginValue.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginLevel = this.MarginLevel.get()
    } else {
      fbeValue.MarginLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.LevelType.fbeSize) <= fbeStructSize) {
      fbeValue.LevelType = this.LevelType.get()
    } else {
      fbeValue.LevelType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LevelType.fbeSize

    if ((fbeCurrentSize + this.DayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayOpenProfit = this.DayOpenProfit.get()
    } else {
      fbeValue.DayOpenProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayBalance = this.PrevDayBalance.get()
    } else {
      fbeValue.PrevDayBalance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevMonthBalance = this.PrevMonthBalance.get()
    } else {
      fbeValue.PrevMonthBalance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayCloseProfit = this.DayCloseProfit.get()
    } else {
      fbeValue.DayCloseProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.DayDeposit = this.DayDeposit.get()
    } else {
      fbeValue.DayDeposit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverage = this.MaxLeverage.get()
    } else {
      fbeValue.MaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayOpenProfit = this.PrevDayOpenProfit.get()
    } else {
      fbeValue.PrevDayOpenProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.OpenProfit = this.OpenProfit.get()
    } else {
      fbeValue.OpenProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalCloseProfit = this.TotalCloseProfit.get()
    } else {
      fbeValue.TotalCloseProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalDeposit = this.TotalDeposit.get()
    } else {
      fbeValue.TotalDeposit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalWithdrawal = this.TotalWithdrawal.get()
    } else {
      fbeValue.TotalWithdrawal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountMarginFiltration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountMarginFiltration}
   * @param {!AccountMarginFiltration} fbeValue AccountMarginFiltration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountMarginFiltration}
   * @param {AccountMarginFiltration} fbeValue AccountMarginFiltration value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Leverage.set(fbeValue.Leverage)
    this.Equity.set(fbeValue.Equity)
    this.MarginValue.set(fbeValue.MarginValue)
    this.MarginLevel.set(fbeValue.MarginLevel)
    this.LevelType.set(fbeValue.LevelType)
    this.DayOpenProfit.set(fbeValue.DayOpenProfit)
    this.PrevDayBalance.set(fbeValue.PrevDayBalance)
    this.PrevMonthBalance.set(fbeValue.PrevMonthBalance)
    this.DayCloseProfit.set(fbeValue.DayCloseProfit)
    this.DayDeposit.set(fbeValue.DayDeposit)
    this.MaxLeverage.set(fbeValue.MaxLeverage)
    this.PrevDayOpenProfit.set(fbeValue.PrevDayOpenProfit)
    this.OpenProfit.set(fbeValue.OpenProfit)
    this.TotalCloseProfit.set(fbeValue.TotalCloseProfit)
    this.TotalDeposit.set(fbeValue.TotalDeposit)
    this.TotalWithdrawal.set(fbeValue.TotalWithdrawal)
  }
}

export { FieldModelAccountMarginFiltration };

/**
 * Fast Binary Encoding AccountMarginFiltration model
 */
class AccountMarginFiltrationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountMarginFiltration(this.buffer, 4)
  }

  /**
   * Get the AccountMarginFiltration model
   * @this {!AccountMarginFiltrationModel}
   * @returns {!FieldModelAccountMarginFiltration} model AccountMarginFiltration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountMarginFiltrationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountMarginFiltrationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountMarginFiltrationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountMarginFiltrationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountMarginFiltration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountMarginFiltrationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountMarginFiltrationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountMarginFiltrationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountMarginFiltrationModel}
   * @param {!AccountMarginFiltration} value AccountMarginFiltration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountMarginFiltrationModel}
   * @param {!AccountMarginFiltration} value AccountMarginFiltration value, defaults is new AccountMarginFiltration()
   * @return {!object} Deserialized AccountMarginFiltration value and its size
   */
  deserialize (value = new AccountMarginFiltration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountMarginFiltration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountMarginFiltration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountMarginFiltrationModel}
   * @param {!number} prev Previous AccountMarginFiltration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountMarginFiltrationModel };
