
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FeedModuleInfo} from './FeedModuleInfo';
import {FieldModelFeedModuleInfo} from './FeedModuleInfo';
import {TradeModuleInfo} from './TradeModuleInfo';
import {FieldModelTradeModuleInfo} from './TradeModuleInfo';
import {ModuleConnectionInfo} from './ModuleConnectionInfo';
import {FieldModelModuleConnectionInfo} from './ModuleConnectionInfo';
import {ExportModuleInfo} from './ExportModuleInfo';
import {FieldModelExportModuleInfo} from './ExportModuleInfo';
import {BackupModuleInfo} from './BackupModuleInfo';
import {FieldModelBackupModuleInfo} from './BackupModuleInfo';

/**
 * ModuleSystemInfo struct
 */
class ModuleSystemInfo {
  /**
   * Initialize struct
   * @param {!UUID=} moduleId
   * @param {!number=} cpuLoad
   * @param {!number=} memoryUsage
   * @param {!number=} networkReceiving
   * @param {!number=} networkSending
   * @param {!string=} modulePath
   * @param {!Date=} startTime
   * @param {FeedModuleInfo=} feedModuleInfo
   * @param {TradeModuleInfo=} tradeModuleInfo
   * @param {!Array=} modulesConnectionInfo
   * @param {ExportModuleInfo=} exportModuleInfo
   * @param {BackupModuleInfo=} backupModuleInfo
   * @param {!Date=} updateTime
   * @constructor
   */
  constructor (moduleId = new UUID(), cpuLoad = 0.0, memoryUsage = new UInt64(0, 0), networkReceiving = new UInt64(0, 0), networkSending = new UInt64(0, 0), modulePath = '', startTime = new Date(0), feedModuleInfo = null, tradeModuleInfo = null, modulesConnectionInfo = [], exportModuleInfo = null, backupModuleInfo = null, updateTime = new Date(0)) {
    this.ModuleId = moduleId
    this.CpuLoad = cpuLoad
    this.MemoryUsage = memoryUsage
    this.NetworkReceiving = networkReceiving
    this.NetworkSending = networkSending
    this.ModulePath = modulePath
    this.StartTime = startTime
    this.FeedModuleInfo = feedModuleInfo
    this.TradeModuleInfo = tradeModuleInfo
    this.ModulesConnectionInfo = modulesConnectionInfo
    this.ExportModuleInfo = exportModuleInfo
    this.BackupModuleInfo = backupModuleInfo
    this.UpdateTime = updateTime
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ModuleSystemInfo}
   * @param {!ModuleSystemInfo} other Other struct
   * @returns {!ModuleSystemInfo} This struct
   */
  copy (other) {
    if (other.ModuleId != null) {
      this.ModuleId = new UUID(other.ModuleId)
    } else {
      this.ModuleId = null
    }
    if (other.CpuLoad != null) {
      this.CpuLoad = other.CpuLoad
    } else {
      this.CpuLoad = null
    }
    if (other.MemoryUsage != null) {
      this.MemoryUsage = UInt64.fromNumber(other.MemoryUsage)
    } else {
      this.MemoryUsage = null
    }
    if (other.NetworkReceiving != null) {
      this.NetworkReceiving = UInt64.fromNumber(other.NetworkReceiving)
    } else {
      this.NetworkReceiving = null
    }
    if (other.NetworkSending != null) {
      this.NetworkSending = UInt64.fromNumber(other.NetworkSending)
    } else {
      this.NetworkSending = null
    }
    if (other.ModulePath != null) {
      this.ModulePath = other.ModulePath
    } else {
      this.ModulePath = null
    }
    if (other.StartTime != null) {
      if (other.StartTime instanceof Date) {
        this.StartTime = new Date(other.StartTime.getTime())
      } else {
        this.StartTime = new Date(Math.round(other.StartTime / 1000000))
      }
    } else {
      this.StartTime = null
    }
    if (other.FeedModuleInfo != null) {
      this.FeedModuleInfo = FeedModuleInfo.fromObject(other.FeedModuleInfo)
    } else {
      this.FeedModuleInfo = null
    }
    if (other.TradeModuleInfo != null) {
      this.TradeModuleInfo = TradeModuleInfo.fromObject(other.TradeModuleInfo)
    } else {
      this.TradeModuleInfo = null
    }
    if (other.ModulesConnectionInfo != null) {
      this.ModulesConnectionInfo = []
      for (let item of other.ModulesConnectionInfo) {
        if (item != null) {
          let tempItem
          tempItem = ModuleConnectionInfo.fromObject(item)
          this.ModulesConnectionInfo.push(tempItem)
        } else {
          this.ModulesConnectionInfo.push(null)
        }
      }
    } else {
      this.ModulesConnectionInfo = null
    }
    if (other.ExportModuleInfo != null) {
      this.ExportModuleInfo = ExportModuleInfo.fromObject(other.ExportModuleInfo)
    } else {
      this.ExportModuleInfo = null
    }
    if (other.BackupModuleInfo != null) {
      this.BackupModuleInfo = BackupModuleInfo.fromObject(other.BackupModuleInfo)
    } else {
      this.BackupModuleInfo = null
    }
    if (other.UpdateTime != null) {
      if (other.UpdateTime instanceof Date) {
        this.UpdateTime = new Date(other.UpdateTime.getTime())
      } else {
        this.UpdateTime = new Date(Math.round(other.UpdateTime / 1000000))
      }
    } else {
      this.UpdateTime = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ModuleSystemInfo}
   * @returns {!ModuleSystemInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ModuleSystemInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ModuleSystemInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ModuleSystemInfo}
   * @param {!ModuleSystemInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ModuleSystemInfo)) {
      throw new TypeError('Instance of ModuleSystemInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ModuleSystemInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ModuleId: ((this.ModuleId != null) ? this.ModuleId.toString() : null),
      CpuLoad: ((this.CpuLoad != null) ? this.CpuLoad : null),
      MemoryUsage: ((this.MemoryUsage != null) ? this.MemoryUsage.toNumber() : null),
      NetworkReceiving: ((this.NetworkReceiving != null) ? this.NetworkReceiving.toNumber() : null),
      NetworkSending: ((this.NetworkSending != null) ? this.NetworkSending.toNumber() : null),
      ModulePath: ((this.ModulePath != null) ? this.ModulePath : null),
      StartTime: ((this.StartTime != null) ? (this.StartTime.getTime() * 1000000) : null),
      FeedModuleInfo: ((this.FeedModuleInfo != null) ? this.FeedModuleInfo : null),
      TradeModuleInfo: ((this.TradeModuleInfo != null) ? this.TradeModuleInfo : null),
      ModulesConnectionInfo: ((this.ModulesConnectionInfo != null) ? Array.from(this.ModulesConnectionInfo, item => ((item != null) ? item : null)) : null),
      ExportModuleInfo: ((this.ExportModuleInfo != null) ? this.ExportModuleInfo : null),
      BackupModuleInfo: ((this.BackupModuleInfo != null) ? this.BackupModuleInfo : null),
      UpdateTime: ((this.UpdateTime != null) ? (this.UpdateTime.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ModuleSystemInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ModuleSystemInfo} other Object value
   * @returns {!ModuleSystemInfo} Created struct
   */
  static fromObject (other) {
    return new ModuleSystemInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ModuleSystemInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ModuleSystemInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ModuleSystemInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 79
  }
}

export { ModuleSystemInfo };

/**
 * Fast Binary Encoding ModuleSystemInfo field model
 */
class FieldModelModuleSystemInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ModuleId = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.CpuLoad = new fbe.FieldModelDouble(buffer, this.ModuleId.fbeOffset + this.ModuleId.fbeSize)
    this.MemoryUsage = new fbe.FieldModelUInt64(buffer, this.CpuLoad.fbeOffset + this.CpuLoad.fbeSize)
    this.NetworkReceiving = new fbe.FieldModelUInt64(buffer, this.MemoryUsage.fbeOffset + this.MemoryUsage.fbeSize)
    this.NetworkSending = new fbe.FieldModelUInt64(buffer, this.NetworkReceiving.fbeOffset + this.NetworkReceiving.fbeSize)
    this.ModulePath = new fbe.FieldModelString(buffer, this.NetworkSending.fbeOffset + this.NetworkSending.fbeSize)
    this.StartTime = new fbe.FieldModelTimestamp(buffer, this.ModulePath.fbeOffset + this.ModulePath.fbeSize)
    this.FeedModuleInfo = new fbe.FieldModelOptional(new FieldModelFeedModuleInfo(buffer, this.StartTime.fbeOffset + this.StartTime.fbeSize), buffer, this.StartTime.fbeOffset + this.StartTime.fbeSize)
    this.TradeModuleInfo = new fbe.FieldModelOptional(new FieldModelTradeModuleInfo(buffer, this.FeedModuleInfo.fbeOffset + this.FeedModuleInfo.fbeSize), buffer, this.FeedModuleInfo.fbeOffset + this.FeedModuleInfo.fbeSize)
    this.ModulesConnectionInfo = new fbe.FieldModelVector(new FieldModelModuleConnectionInfo(buffer, this.TradeModuleInfo.fbeOffset + this.TradeModuleInfo.fbeSize), buffer, this.TradeModuleInfo.fbeOffset + this.TradeModuleInfo.fbeSize)
    this.ExportModuleInfo = new fbe.FieldModelOptional(new FieldModelExportModuleInfo(buffer, this.ModulesConnectionInfo.fbeOffset + this.ModulesConnectionInfo.fbeSize), buffer, this.ModulesConnectionInfo.fbeOffset + this.ModulesConnectionInfo.fbeSize)
    this.BackupModuleInfo = new fbe.FieldModelOptional(new FieldModelBackupModuleInfo(buffer, this.ExportModuleInfo.fbeOffset + this.ExportModuleInfo.fbeSize), buffer, this.ExportModuleInfo.fbeOffset + this.ExportModuleInfo.fbeSize)
    this.UpdateTime = new fbe.FieldModelTimestamp(buffer, this.BackupModuleInfo.fbeOffset + this.BackupModuleInfo.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ModuleId.fbeSize + this.CpuLoad.fbeSize + this.MemoryUsage.fbeSize + this.NetworkReceiving.fbeSize + this.NetworkSending.fbeSize + this.ModulePath.fbeSize + this.StartTime.fbeSize + this.FeedModuleInfo.fbeSize + this.TradeModuleInfo.fbeSize + this.ModulesConnectionInfo.fbeSize + this.ExportModuleInfo.fbeSize + this.BackupModuleInfo.fbeSize + this.UpdateTime.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ModuleId.fbeExtra + this.CpuLoad.fbeExtra + this.MemoryUsage.fbeExtra + this.NetworkReceiving.fbeExtra + this.NetworkSending.fbeExtra + this.ModulePath.fbeExtra + this.StartTime.fbeExtra + this.FeedModuleInfo.fbeExtra + this.TradeModuleInfo.fbeExtra + this.ModulesConnectionInfo.fbeExtra + this.ExportModuleInfo.fbeExtra + this.BackupModuleInfo.fbeExtra + this.UpdateTime.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelModuleSystemInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 79
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelModuleSystemInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelModuleSystemInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ModuleId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleId.fbeSize

    if ((fbeCurrentSize + this.CpuLoad.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CpuLoad.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CpuLoad.fbeSize

    if ((fbeCurrentSize + this.MemoryUsage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MemoryUsage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MemoryUsage.fbeSize

    if ((fbeCurrentSize + this.NetworkReceiving.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NetworkReceiving.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetworkReceiving.fbeSize

    if ((fbeCurrentSize + this.NetworkSending.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NetworkSending.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetworkSending.fbeSize

    if ((fbeCurrentSize + this.ModulePath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModulePath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulePath.fbeSize

    if ((fbeCurrentSize + this.StartTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StartTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.FeedModuleInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeedModuleInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedModuleInfo.fbeSize

    if ((fbeCurrentSize + this.TradeModuleInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeModuleInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeModuleInfo.fbeSize

    if ((fbeCurrentSize + this.ModulesConnectionInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModulesConnectionInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulesConnectionInfo.fbeSize

    if ((fbeCurrentSize + this.ExportModuleInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExportModuleInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportModuleInfo.fbeSize

    if ((fbeCurrentSize + this.BackupModuleInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BackupModuleInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupModuleInfo.fbeSize

    if ((fbeCurrentSize + this.UpdateTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UpdateTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateTime.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelModuleSystemInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelModuleSystemInfo}
   * @param {!ModuleSystemInfo} fbeValue Default value, defaults is new ModuleSystemInfo()
   * @returns {!ModuleSystemInfo} ModuleSystemInfo value
   */
  get (fbeValue = new ModuleSystemInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelModuleSystemInfo}
   * @param {!ModuleSystemInfo} fbeValue ModuleSystemInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ModuleId.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleId = this.ModuleId.get()
    } else {
      fbeValue.ModuleId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleId.fbeSize

    if ((fbeCurrentSize + this.CpuLoad.fbeSize) <= fbeStructSize) {
      fbeValue.CpuLoad = this.CpuLoad.get()
    } else {
      fbeValue.CpuLoad = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CpuLoad.fbeSize

    if ((fbeCurrentSize + this.MemoryUsage.fbeSize) <= fbeStructSize) {
      fbeValue.MemoryUsage = this.MemoryUsage.get()
    } else {
      fbeValue.MemoryUsage = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MemoryUsage.fbeSize

    if ((fbeCurrentSize + this.NetworkReceiving.fbeSize) <= fbeStructSize) {
      fbeValue.NetworkReceiving = this.NetworkReceiving.get()
    } else {
      fbeValue.NetworkReceiving = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetworkReceiving.fbeSize

    if ((fbeCurrentSize + this.NetworkSending.fbeSize) <= fbeStructSize) {
      fbeValue.NetworkSending = this.NetworkSending.get()
    } else {
      fbeValue.NetworkSending = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetworkSending.fbeSize

    if ((fbeCurrentSize + this.ModulePath.fbeSize) <= fbeStructSize) {
      fbeValue.ModulePath = this.ModulePath.get()
    } else {
      fbeValue.ModulePath = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulePath.fbeSize

    if ((fbeCurrentSize + this.StartTime.fbeSize) <= fbeStructSize) {
      fbeValue.StartTime = this.StartTime.get()
    } else {
      fbeValue.StartTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.FeedModuleInfo.fbeSize) <= fbeStructSize) {
      fbeValue.FeedModuleInfo = this.FeedModuleInfo.get()
    } else {
      fbeValue.FeedModuleInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedModuleInfo.fbeSize

    if ((fbeCurrentSize + this.TradeModuleInfo.fbeSize) <= fbeStructSize) {
      fbeValue.TradeModuleInfo = this.TradeModuleInfo.get()
    } else {
      fbeValue.TradeModuleInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeModuleInfo.fbeSize

    if ((fbeCurrentSize + this.ModulesConnectionInfo.fbeSize) <= fbeStructSize) {
      this.ModulesConnectionInfo.get(fbeValue.ModulesConnectionInfo)
    } else {
      fbeValue.ModulesConnectionInfo.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulesConnectionInfo.fbeSize

    if ((fbeCurrentSize + this.ExportModuleInfo.fbeSize) <= fbeStructSize) {
      fbeValue.ExportModuleInfo = this.ExportModuleInfo.get()
    } else {
      fbeValue.ExportModuleInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportModuleInfo.fbeSize

    if ((fbeCurrentSize + this.BackupModuleInfo.fbeSize) <= fbeStructSize) {
      fbeValue.BackupModuleInfo = this.BackupModuleInfo.get()
    } else {
      fbeValue.BackupModuleInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupModuleInfo.fbeSize

    if ((fbeCurrentSize + this.UpdateTime.fbeSize) <= fbeStructSize) {
      fbeValue.UpdateTime = this.UpdateTime.get()
    } else {
      fbeValue.UpdateTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateTime.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelModuleSystemInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelModuleSystemInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelModuleSystemInfo}
   * @param {!ModuleSystemInfo} fbeValue ModuleSystemInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelModuleSystemInfo}
   * @param {ModuleSystemInfo} fbeValue ModuleSystemInfo value
   */
  setFields (fbeValue) {
    this.ModuleId.set(fbeValue.ModuleId)
    this.CpuLoad.set(fbeValue.CpuLoad)
    this.MemoryUsage.set(fbeValue.MemoryUsage)
    this.NetworkReceiving.set(fbeValue.NetworkReceiving)
    this.NetworkSending.set(fbeValue.NetworkSending)
    this.ModulePath.set(fbeValue.ModulePath)
    this.StartTime.set(fbeValue.StartTime)
    this.FeedModuleInfo.set(fbeValue.FeedModuleInfo)
    this.TradeModuleInfo.set(fbeValue.TradeModuleInfo)
    this.ModulesConnectionInfo.set(fbeValue.ModulesConnectionInfo)
    this.ExportModuleInfo.set(fbeValue.ExportModuleInfo)
    this.BackupModuleInfo.set(fbeValue.BackupModuleInfo)
    this.UpdateTime.set(fbeValue.UpdateTime)
  }
}

export { FieldModelModuleSystemInfo };

/**
 * Fast Binary Encoding ModuleSystemInfo model
 */
class ModuleSystemInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelModuleSystemInfo(this.buffer, 4)
  }

  /**
   * Get the ModuleSystemInfo model
   * @this {!ModuleSystemInfoModel}
   * @returns {!FieldModelModuleSystemInfo} model ModuleSystemInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ModuleSystemInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ModuleSystemInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ModuleSystemInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ModuleSystemInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelModuleSystemInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ModuleSystemInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ModuleSystemInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ModuleSystemInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ModuleSystemInfoModel}
   * @param {!ModuleSystemInfo} value ModuleSystemInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ModuleSystemInfoModel}
   * @param {!ModuleSystemInfo} value ModuleSystemInfo value, defaults is new ModuleSystemInfo()
   * @return {!object} Deserialized ModuleSystemInfo value and its size
   */
  deserialize (value = new ModuleSystemInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ModuleSystemInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ModuleSystemInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ModuleSystemInfoModel}
   * @param {!number} prev Previous ModuleSystemInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ModuleSystemInfoModel };
