
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FilterUint64} from './FilterUint64';
import {FieldModelFilterUint64} from './FilterUint64';
import {FilterDouble} from './FilterDouble';
import {FieldModelFilterDouble} from './FilterDouble';

/**
 * OrderStateFiltration struct
 */
class OrderStateFiltration {
  /**
   * Initialize struct
   * @param {FilterUint64=} orderId
   * @param {FilterDouble=} marginRate
   * @param {FilterDouble=} profit
   * @param {FilterDouble=} currentPrice
   * @param {FilterDouble=} dayProfit
   * @constructor
   */
  constructor (orderId = null, marginRate = null, profit = null, currentPrice = null, dayProfit = null) {
    this.OrderId = orderId
    this.MarginRate = marginRate
    this.Profit = profit
    this.CurrentPrice = currentPrice
    this.DayProfit = dayProfit
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderStateFiltration}
   * @param {!OrderStateFiltration} other Other struct
   * @returns {!OrderStateFiltration} This struct
   */
  copy (other) {
    if (other.OrderId != null) {
      this.OrderId = FilterUint64.fromObject(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.MarginRate != null) {
      this.MarginRate = FilterDouble.fromObject(other.MarginRate)
    } else {
      this.MarginRate = null
    }
    if (other.Profit != null) {
      this.Profit = FilterDouble.fromObject(other.Profit)
    } else {
      this.Profit = null
    }
    if (other.CurrentPrice != null) {
      this.CurrentPrice = FilterDouble.fromObject(other.CurrentPrice)
    } else {
      this.CurrentPrice = null
    }
    if (other.DayProfit != null) {
      this.DayProfit = FilterDouble.fromObject(other.DayProfit)
    } else {
      this.DayProfit = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderStateFiltration}
   * @returns {!OrderStateFiltration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderStateFiltrationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderStateFiltrationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderStateFiltration}
   * @param {!OrderStateFiltration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderStateFiltration)) {
      throw new TypeError('Instance of OrderStateFiltration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderStateFiltration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      OrderId: ((this.OrderId != null) ? this.OrderId : null),
      MarginRate: ((this.MarginRate != null) ? this.MarginRate : null),
      Profit: ((this.Profit != null) ? this.Profit : null),
      CurrentPrice: ((this.CurrentPrice != null) ? this.CurrentPrice : null),
      DayProfit: ((this.DayProfit != null) ? this.DayProfit : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderStateFiltration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderStateFiltration} other Object value
   * @returns {!OrderStateFiltration} Created struct
   */
  static fromObject (other) {
    return new OrderStateFiltration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderStateFiltration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderStateFiltration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderStateFiltration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 132
  }
}

export { OrderStateFiltration };

/**
 * Fast Binary Encoding OrderStateFiltration field model
 */
class FieldModelOrderStateFiltration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.OrderId = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, 4 + 4), buffer, 4 + 4)
    this.MarginRate = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.Profit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.MarginRate.fbeOffset + this.MarginRate.fbeSize), buffer, this.MarginRate.fbeOffset + this.MarginRate.fbeSize)
    this.CurrentPrice = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.Profit.fbeOffset + this.Profit.fbeSize), buffer, this.Profit.fbeOffset + this.Profit.fbeSize)
    this.DayProfit = new fbe.FieldModelOptional(new FieldModelFilterDouble(buffer, this.CurrentPrice.fbeOffset + this.CurrentPrice.fbeSize), buffer, this.CurrentPrice.fbeOffset + this.CurrentPrice.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.OrderId.fbeSize + this.MarginRate.fbeSize + this.Profit.fbeSize + this.CurrentPrice.fbeSize + this.DayProfit.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.OrderId.fbeExtra + this.MarginRate.fbeExtra + this.Profit.fbeExtra + this.CurrentPrice.fbeExtra + this.DayProfit.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderStateFiltration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 132
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderStateFiltration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderStateFiltration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.MarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRate.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.CurrentPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrentPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrentPrice.fbeSize

    if ((fbeCurrentSize + this.DayProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayProfit.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderStateFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderStateFiltration}
   * @param {!OrderStateFiltration} fbeValue Default value, defaults is new OrderStateFiltration()
   * @returns {!OrderStateFiltration} OrderStateFiltration value
   */
  get (fbeValue = new OrderStateFiltration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderStateFiltration}
   * @param {!OrderStateFiltration} fbeValue OrderStateFiltration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.MarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.MarginRate = this.MarginRate.get()
    } else {
      fbeValue.MarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRate.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) <= fbeStructSize) {
      fbeValue.Profit = this.Profit.get()
    } else {
      fbeValue.Profit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.CurrentPrice.fbeSize) <= fbeStructSize) {
      fbeValue.CurrentPrice = this.CurrentPrice.get()
    } else {
      fbeValue.CurrentPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrentPrice.fbeSize

    if ((fbeCurrentSize + this.DayProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayProfit = this.DayProfit.get()
    } else {
      fbeValue.DayProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayProfit.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderStateFiltration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderStateFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderStateFiltration}
   * @param {!OrderStateFiltration} fbeValue OrderStateFiltration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderStateFiltration}
   * @param {OrderStateFiltration} fbeValue OrderStateFiltration value
   */
  setFields (fbeValue) {
    this.OrderId.set(fbeValue.OrderId)
    this.MarginRate.set(fbeValue.MarginRate)
    this.Profit.set(fbeValue.Profit)
    this.CurrentPrice.set(fbeValue.CurrentPrice)
    this.DayProfit.set(fbeValue.DayProfit)
  }
}

export { FieldModelOrderStateFiltration };

/**
 * Fast Binary Encoding OrderStateFiltration model
 */
class OrderStateFiltrationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderStateFiltration(this.buffer, 4)
  }

  /**
   * Get the OrderStateFiltration model
   * @this {!OrderStateFiltrationModel}
   * @returns {!FieldModelOrderStateFiltration} model OrderStateFiltration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderStateFiltrationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderStateFiltrationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderStateFiltrationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderStateFiltrationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderStateFiltration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderStateFiltrationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderStateFiltrationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderStateFiltrationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderStateFiltrationModel}
   * @param {!OrderStateFiltration} value OrderStateFiltration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderStateFiltrationModel}
   * @param {!OrderStateFiltration} value OrderStateFiltration value, defaults is new OrderStateFiltration()
   * @return {!object} Deserialized OrderStateFiltration value and its size
   */
  deserialize (value = new OrderStateFiltration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderStateFiltration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderStateFiltration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderStateFiltrationModel}
   * @param {!number} prev Previous OrderStateFiltration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderStateFiltrationModel };
