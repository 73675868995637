import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@styles/antd.less';
import '@styles/global.scss';
import '@styles/form.scss';
import '@assets/fonts/fonts.scss';

import enUSIntl from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';

import { SocketClient } from '@api/SocketClient';
import App from './App';

import { ApplicationInsightsProvider } from '@components/ApplicationInsights/provider';
import { APP_INSIGHTS_KEY } from 'env/config';
import { LS_WS_CONNECTION_KEY } from '@constants';
import { authStore } from '@store/auth';
import { isValidWsUrl } from '@models/Auth';
import StorageHelper from '@utils/StorageHelper';
import moment from 'moment-timezone';

declare const window: any;

const Root = () => (
    // TODO Remove StrictMode in production
    <BrowserRouter>
        <ApplicationInsightsProvider instrumentationKey={APP_INSIGHTS_KEY}>
            <ConfigProvider locale={enUSIntl}>
                <App />
            </ConfigProvider>
        </ApplicationInsightsProvider>
    </BrowserRouter>
);

const mount = async () => {
    const apiUrl = StorageHelper.getFromLocal(LS_WS_CONNECTION_KEY) || '';
    if (isValidWsUrl(apiUrl)) {
        await SocketClient.instance.init(apiUrl);
        authStore.wsUrl = apiUrl;
    } else {
        authStore.logout();
    }

    moment.tz.setDefault('UTC');
    const MOUNT_NODE = document.getElementById('root');
    ReactDOM.render(<Root />, MOUNT_NODE);
};

mount();

// from webpack DefinePlugin
// eslint-disable-next-line
declare const __COMMIT_HASH__: string;
// eslint-disable-next-line
(window as any).__version = __COMMIT_HASH__;
