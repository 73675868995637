// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: backup.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {BackupVersionRequest} from './backup/BackupVersionRequest'
import {BackupVersionRequestModel} from './backup/BackupVersionRequest'
import {BackupVersionResponse} from './backup/BackupVersionResponse'
import {BackupVersionResponseModel} from './backup/BackupVersionResponse'
import {BackupUpdateNotify} from './backup/BackupUpdateNotify'
import {BackupUpdateNotifyModel} from './backup/BackupUpdateNotify'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding backup sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize backup sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._BackupVersionRequestModel = new BackupVersionRequestModel(this.buffer)
    this._BackupVersionResponseModel = new BackupVersionResponseModel(this.buffer)
    this._BackupUpdateNotifyModel = new BackupUpdateNotifyModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get BackupVersionRequest model
   * @this {!Sender}
   * @returns {!BackupVersionRequestModel} BackupVersionRequest model
   */
  get BackupVersionRequestModel () {
    return this._BackupVersionRequestModel
  }

  /**
   * Get BackupVersionResponse model
   * @this {!Sender}
   * @returns {!BackupVersionResponseModel} BackupVersionResponse model
   */
  get BackupVersionResponseModel () {
    return this._BackupVersionResponseModel
  }

  /**
   * Get BackupUpdateNotify model
   * @this {!Sender}
   * @returns {!BackupUpdateNotifyModel} BackupUpdateNotify model
   */
  get BackupUpdateNotifyModel () {
    return this._BackupUpdateNotifyModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof BackupVersionRequest) && (value.fbeType === this.BackupVersionRequestModel.fbeType)) {
      return this.send_BackupVersionRequest(value)
    }
    if ((value instanceof BackupVersionResponse) && (value.fbeType === this.BackupVersionResponseModel.fbeType)) {
      return this.send_BackupVersionResponse(value)
    }
    if ((value instanceof BackupUpdateNotify) && (value.fbeType === this.BackupUpdateNotifyModel.fbeType)) {
      return this.send_BackupUpdateNotify(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send BackupVersionRequest value
   * @this {!Sender}
   * @param {!BackupVersionRequest} value BackupVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BackupVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupVersionRequest serialization failed!')
    console.assert(this.BackupVersionRequestModel.verify(), 'backup.BackupVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BackupVersionResponse value
   * @this {!Sender}
   * @param {!BackupVersionResponse} value BackupVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BackupVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupVersionResponse serialization failed!')
    console.assert(this.BackupVersionResponseModel.verify(), 'backup.BackupVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BackupUpdateNotify value
   * @this {!Sender}
   * @param {!BackupUpdateNotify} value BackupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_BackupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupUpdateNotify serialization failed!')
    console.assert(this.BackupUpdateNotifyModel.verify(), 'backup.BackupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'backup.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding backup receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize backup receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._BackupVersionRequestValue = new BackupVersionRequest()
    this._BackupVersionRequestModel = new BackupVersionRequestModel()
    this._BackupVersionResponseValue = new BackupVersionResponse()
    this._BackupVersionResponseModel = new BackupVersionResponseModel()
    this._BackupUpdateNotifyValue = new BackupUpdateNotify()
    this._BackupUpdateNotifyModel = new BackupUpdateNotifyModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * BackupVersionRequest receive handler
   * @this {!Receiver}
   * @param {!BackupVersionRequest} value BackupVersionRequest received value
   */
  onReceive_BackupVersionRequest (value) {}  // eslint-disable-line

  /**
   * BackupVersionResponse receive handler
   * @this {!Receiver}
   * @param {!BackupVersionResponse} value BackupVersionResponse received value
   */
  onReceive_BackupVersionResponse (value) {}  // eslint-disable-line

  /**
   * BackupUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!BackupUpdateNotify} value BackupUpdateNotify received value
   */
  onReceive_BackupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * backup receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BackupVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionRequestModel.verify(), 'backup.BackupVersionRequest validation failed!')
        let deserialized = this._BackupVersionRequestModel.deserialize(this._BackupVersionRequestValue)
        console.assert((deserialized.size > 0), 'backup.BackupVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupVersionRequestValue)
        this.onReceive_BackupVersionRequest(this._BackupVersionRequestValue)
        return true
      }
      case BackupVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionResponseModel.verify(), 'backup.BackupVersionResponse validation failed!')
        let deserialized = this._BackupVersionResponseModel.deserialize(this._BackupVersionResponseValue)
        console.assert((deserialized.size > 0), 'backup.BackupVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupVersionResponseValue)
        this.onReceive_BackupVersionResponse(this._BackupVersionResponseValue)
        return true
      }
      case BackupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._BackupUpdateNotifyModel.verify(), 'backup.BackupUpdateNotify validation failed!')
        let deserialized = this._BackupUpdateNotifyModel.deserialize(this._BackupUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'backup.BackupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupUpdateNotifyValue)
        this.onReceive_BackupUpdateNotify(this._BackupUpdateNotifyValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding backup proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize backup proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._BackupVersionRequestModel = new BackupVersionRequestModel()
    this._BackupVersionResponseModel = new BackupVersionResponseModel()
    this._BackupUpdateNotifyModel = new BackupUpdateNotifyModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * BackupVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!BackupVersionRequest} model BackupVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BackupVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BackupVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!BackupVersionResponse} model BackupVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BackupVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BackupUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!BackupUpdateNotify} model BackupUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BackupUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * backup receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BackupVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BackupVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionRequestModel.verify(), 'backup.BackupVersionRequest validation failed!')

        let fbeBegin = this._BackupVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BackupVersionRequest(this._BackupVersionRequestModel, type, buffer, offset, size)
        this._BackupVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BackupVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BackupVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionResponseModel.verify(), 'backup.BackupVersionResponse validation failed!')

        let fbeBegin = this._BackupVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BackupVersionResponse(this._BackupVersionResponseModel, type, buffer, offset, size)
        this._BackupVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case BackupUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BackupUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._BackupUpdateNotifyModel.verify(), 'backup.BackupUpdateNotify validation failed!')

        let fbeBegin = this._BackupUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BackupUpdateNotify(this._BackupUpdateNotifyModel, type, buffer, offset, size)
        this._BackupUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding backup client
 */
class Client extends fbe.Client {
  /**
   * Initialize backup client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._BackupVersionRequestSenderModel = new BackupVersionRequestModel(this.sendBuffer)
    this._BackupVersionRequestReceiverValue = new BackupVersionRequest()
    this._BackupVersionRequestReceiverModel = new BackupVersionRequestModel()
    this._BackupVersionResponseSenderModel = new BackupVersionResponseModel(this.sendBuffer)
    this._BackupVersionResponseReceiverValue = new BackupVersionResponse()
    this._BackupVersionResponseReceiverModel = new BackupVersionResponseModel()
    this._BackupUpdateNotifySenderModel = new BackupUpdateNotifyModel(this.sendBuffer)
    this._BackupUpdateNotifyReceiverValue = new BackupUpdateNotify()
    this._BackupUpdateNotifyReceiverModel = new BackupUpdateNotifyModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get BackupVersionRequest model
   * @this {!Client}
   * @returns {!BackupVersionRequestModel} BackupVersionRequest sender model
   */
  get BackupVersionRequestSenderModel () {
    return this._BackupVersionRequestSenderModel
  }

  /**
   * Get BackupVersionResponse model
   * @this {!Client}
   * @returns {!BackupVersionResponseModel} BackupVersionResponse sender model
   */
  get BackupVersionResponseSenderModel () {
    return this._BackupVersionResponseSenderModel
  }

  /**
   * Get BackupUpdateNotify model
   * @this {!Client}
   * @returns {!BackupUpdateNotifyModel} BackupUpdateNotify sender model
   */
  get BackupUpdateNotifySenderModel () {
    return this._BackupUpdateNotifySenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof BackupVersionRequest) && (value.fbeType === this.BackupVersionRequestSenderModel.fbeType)) {
      return this.send_BackupVersionRequest(value)
    }
    if ((value instanceof BackupVersionResponse) && (value.fbeType === this.BackupVersionResponseSenderModel.fbeType)) {
      return this.send_BackupVersionResponse(value)
    }
    if ((value instanceof BackupUpdateNotify) && (value.fbeType === this.BackupUpdateNotifySenderModel.fbeType)) {
      return this.send_BackupUpdateNotify(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send BackupVersionRequest value
   * @this {!Client}
   * @param {!BackupVersionRequest} value BackupVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BackupVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupVersionRequest serialization failed!')
    console.assert(this.BackupVersionRequestSenderModel.verify(), 'backup.BackupVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BackupVersionResponse value
   * @this {!Client}
   * @param {!BackupVersionResponse} value BackupVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BackupVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupVersionResponse serialization failed!')
    console.assert(this.BackupVersionResponseSenderModel.verify(), 'backup.BackupVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BackupUpdateNotify value
   * @this {!Client}
   * @param {!BackupUpdateNotify} value BackupUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_BackupUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BackupUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'backup.BackupUpdateNotify serialization failed!')
    console.assert(this.BackupUpdateNotifySenderModel.verify(), 'backup.BackupUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'backup.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * BackupVersionRequest receive handler
   * @this {!Client}
   * @param {!BackupVersionRequest} value BackupVersionRequest received value
   */
  onReceive_BackupVersionRequest (value) {}  // eslint-disable-line

  /**
   * BackupVersionResponse receive handler
   * @this {!Client}
   * @param {!BackupVersionResponse} value BackupVersionResponse received value
   */
  onReceive_BackupVersionResponse (value) {}  // eslint-disable-line

  /**
   * BackupUpdateNotify receive handler
   * @this {!Client}
   * @param {!BackupUpdateNotify} value BackupUpdateNotify received value
   */
  onReceive_BackupUpdateNotify (value) {}  // eslint-disable-line

  /**
   * backup receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BackupVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionRequestReceiverModel.verify(), 'backup.BackupVersionRequest validation failed!')
        let deserialized = this._BackupVersionRequestReceiverModel.deserialize(this._BackupVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'backup.BackupVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupVersionRequestReceiverValue)
        this.onReceive_BackupVersionRequest(this._BackupVersionRequestReceiverValue)
        return true
      }
      case BackupVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BackupVersionResponseReceiverModel.verify(), 'backup.BackupVersionResponse validation failed!')
        let deserialized = this._BackupVersionResponseReceiverModel.deserialize(this._BackupVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'backup.BackupVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupVersionResponseReceiverValue)
        this.onReceive_BackupVersionResponse(this._BackupVersionResponseReceiverValue)
        return true
      }
      case BackupUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BackupUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BackupUpdateNotifyReceiverModel.verify(), 'backup.BackupUpdateNotify validation failed!')
        let deserialized = this._BackupUpdateNotifyReceiverModel.deserialize(this._BackupUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'backup.BackupUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BackupUpdateNotifyReceiverValue)
        this.onReceive_BackupUpdateNotify(this._BackupUpdateNotifyReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
