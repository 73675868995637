import { UInt64 } from '@api/fbe/int64';
import { Message, MessageType } from '@components/Message/Message';
import { TRADING_ROUTES_ORDER_SIDE } from '@constants/models';
import React from 'react';

export const LS_DISABLED_COLS_PREFIX = '.DisabledCols';
export const LS_COLS_ORDER_PREFIX = '.ColsOrder';
export const LS_FORM_WIDTH = '.FormWidth';

export const clearUserTablesColSettings = () => {
    const lsKeys = Object.keys(localStorage).filter((key) => key.endsWith(LS_DISABLED_COLS_PREFIX));
    lsKeys.forEach((key) => {
        localStorage.removeItem(key);
    });
};

export const argbToRGB = (color) =>
    // eslint-disable-next-line no-bitwise
    `#${`000000${(color & 0xffffff).toString(16)}`.slice(-6)}`;

export const getTextColorByBGC = (bgColorHEX: string): string => {
    switch (bgColorHEX) {
        case '#000000':
            return 'white';
        case '#ffffff':
            return 'black';
        default:
            return 'unset';
    }
};

export const getClassStaticFields = (enumBase) =>
    Object.keys(enumBase).reduce(
        (acc, propName: string) => ({
            ...acc,
            [enumBase[propName].valueOf()]: propName,
        }),
        {},
    );

export const getEnumMnemonicToUiName = (enumBase, model: Record<number, string>): Map<string, string> | undefined => {
    try {
        const staticFields = getClassStaticFields(enumBase);
        const tempRes = Object.keys(staticFields).map(key => {
            if (model[key] !== undefined) {
                const baseKey = staticFields[key] !== undefined ? `${staticFields[key]}` : ''
                const localKey = model[key] !== undefined ? `${model[key]}` : ''
                if (!baseKey) return ['', ''];
                return [baseKey, localKey || baseKey]
            }
            return ['', ''];
        }).filter(item => !!item) as [string, string][]
        return new Map(tempRes);
    } catch (error) {
        Message(MessageType.error, 'Can\'t parse enum model')
    }
    return new Map();
};

export const hexToRGBA = (hex, alpha?: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return {
            r,
            g,
            b,
            a: alpha,
        };
    }
    return {
        r,
        g,
        b,
        a: 1,
    };
};

export const RGBAToARGB = (rgba) =>
    // eslint-disable-next-line no-bitwise
    rgba.a * 0x1000000 + (rgba.r << 16) + (rgba.g << 8) + rgba.b;

export const typedMemo: <T>(component: T) => T = React.memo;

export const numberSortFunction = (a: number | null | undefined, b: number | null | undefined): number => {
    if (typeof a !== 'number' && typeof a !== 'number') return 0;
    if (a === b) return 0;
    return (a ?? 0) > (b ?? 0) ? 1 : -1;
};

export const numberOrUInt64ToNumber = (value: number | UInt64): number =>
    typeof value === 'number' ? value : +value.toNumber();
