
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';
import {StopOutType} from '../core/StopOutType';
import {FieldModelStopOutType} from '../core/StopOutType';
import {LevelType} from '../core/LevelType';
import {FieldModelLevelType} from '../core/LevelType';

/**
 * MarginInfo struct
 */
class MarginInfo {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} actualVersion
   * @param {!string=} balanceCurrency
   * @param {!number=} balance
   * @param {!number=} credit
   * @param {!number=} leverage
   * @param {!number=} equity
   * @param {!number=} marginValue
   * @param {!number=} marginLevel
   * @param {!number=} marginCallLevel
   * @param {!TradingMode=} marginCallAccessType
   * @param {!number=} stopOutLevel
   * @param {!StopOutType=} stopOutType
   * @param {!TradingMode=} stopOutAccessType
   * @param {!LevelType=} levelType
   * @param {!number=} dayOpenProfit
   * @param {!number=} openProfit
   * @constructor
   */
  constructor (login = new UInt64(0, 0), actualVersion = new UInt64(0, 0), balanceCurrency = '', balance = 0.0, credit = 0.0, leverage = 0, equity = 0.0, marginValue = 0.0, marginLevel = 0.0, marginCallLevel = 0.0, marginCallAccessType = new TradingMode(), stopOutLevel = 0.0, stopOutType = new StopOutType(), stopOutAccessType = new TradingMode(), levelType = new LevelType(), dayOpenProfit = 0.0, openProfit = 0.0) {
    this.Login = login
    this.ActualVersion = actualVersion
    this.BalanceCurrency = balanceCurrency
    this.Balance = balance
    this.Credit = credit
    this.Leverage = leverage
    this.Equity = equity
    this.MarginValue = marginValue
    this.MarginLevel = marginLevel
    this.MarginCallLevel = marginCallLevel
    this.MarginCallAccessType = marginCallAccessType
    this.StopOutLevel = stopOutLevel
    this.StopOutType = stopOutType
    this.StopOutAccessType = stopOutAccessType
    this.LevelType = levelType
    this.DayOpenProfit = dayOpenProfit
    this.OpenProfit = openProfit
  }

  /**
   * Copy struct (shallow copy)
   * @this {!MarginInfo}
   * @param {!MarginInfo} other Other struct
   * @returns {!MarginInfo} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.BalanceCurrency != null) {
      this.BalanceCurrency = other.BalanceCurrency
    } else {
      this.BalanceCurrency = null
    }
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    if (other.Equity != null) {
      this.Equity = other.Equity
    } else {
      this.Equity = null
    }
    if (other.MarginValue != null) {
      this.MarginValue = other.MarginValue
    } else {
      this.MarginValue = null
    }
    if (other.MarginLevel != null) {
      this.MarginLevel = other.MarginLevel
    } else {
      this.MarginLevel = null
    }
    if (other.MarginCallLevel != null) {
      this.MarginCallLevel = other.MarginCallLevel
    } else {
      this.MarginCallLevel = null
    }
    if (other.MarginCallAccessType != null) {
      this.MarginCallAccessType = TradingMode.fromObject(other.MarginCallAccessType)
    } else {
      this.MarginCallAccessType = null
    }
    if (other.StopOutLevel != null) {
      this.StopOutLevel = other.StopOutLevel
    } else {
      this.StopOutLevel = null
    }
    if (other.StopOutType != null) {
      this.StopOutType = StopOutType.fromObject(other.StopOutType)
    } else {
      this.StopOutType = null
    }
    if (other.StopOutAccessType != null) {
      this.StopOutAccessType = TradingMode.fromObject(other.StopOutAccessType)
    } else {
      this.StopOutAccessType = null
    }
    if (other.LevelType != null) {
      this.LevelType = LevelType.fromObject(other.LevelType)
    } else {
      this.LevelType = null
    }
    if (other.DayOpenProfit != null) {
      this.DayOpenProfit = other.DayOpenProfit
    } else {
      this.DayOpenProfit = null
    }
    if (other.OpenProfit != null) {
      this.OpenProfit = other.OpenProfit
    } else {
      this.OpenProfit = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!MarginInfo}
   * @returns {!MarginInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new MarginInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new MarginInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!MarginInfo}
   * @param {!MarginInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof MarginInfo)) {
      throw new TypeError('Instance of MarginInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!MarginInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      BalanceCurrency: ((this.BalanceCurrency != null) ? this.BalanceCurrency : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      Equity: ((this.Equity != null) ? this.Equity : null),
      MarginValue: ((this.MarginValue != null) ? this.MarginValue : null),
      MarginLevel: ((this.MarginLevel != null) ? this.MarginLevel : null),
      MarginCallLevel: ((this.MarginCallLevel != null) ? this.MarginCallLevel : null),
      MarginCallAccessType: ((this.MarginCallAccessType != null) ? this.MarginCallAccessType : null),
      StopOutLevel: ((this.StopOutLevel != null) ? this.StopOutLevel : null),
      StopOutType: ((this.StopOutType != null) ? this.StopOutType : null),
      StopOutAccessType: ((this.StopOutAccessType != null) ? this.StopOutAccessType : null),
      LevelType: ((this.LevelType != null) ? this.LevelType : null),
      DayOpenProfit: ((this.DayOpenProfit != null) ? this.DayOpenProfit : null),
      OpenProfit: ((this.OpenProfit != null) ? this.OpenProfit : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return MarginInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!MarginInfo} other Object value
   * @returns {!MarginInfo} Created struct
   */
  static fromObject (other) {
    return new MarginInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!MarginInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return MarginInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!MarginInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5071
  }
}

export { MarginInfo };

/**
 * Fast Binary Encoding MarginInfo field model
 */
class FieldModelMarginInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.BalanceCurrency = new fbe.FieldModelString(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Balance = new fbe.FieldModelDouble(buffer, this.BalanceCurrency.fbeOffset + this.BalanceCurrency.fbeSize)
    this.Credit = new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Leverage = new fbe.FieldModelUInt32(buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.Equity = new fbe.FieldModelDouble(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
    this.MarginValue = new fbe.FieldModelDouble(buffer, this.Equity.fbeOffset + this.Equity.fbeSize)
    this.MarginLevel = new fbe.FieldModelDouble(buffer, this.MarginValue.fbeOffset + this.MarginValue.fbeSize)
    this.MarginCallLevel = new fbe.FieldModelDouble(buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize)
    this.MarginCallAccessType = new FieldModelTradingMode(buffer, this.MarginCallLevel.fbeOffset + this.MarginCallLevel.fbeSize)
    this.StopOutLevel = new fbe.FieldModelDouble(buffer, this.MarginCallAccessType.fbeOffset + this.MarginCallAccessType.fbeSize)
    this.StopOutType = new FieldModelStopOutType(buffer, this.StopOutLevel.fbeOffset + this.StopOutLevel.fbeSize)
    this.StopOutAccessType = new FieldModelTradingMode(buffer, this.StopOutType.fbeOffset + this.StopOutType.fbeSize)
    this.LevelType = new FieldModelLevelType(buffer, this.StopOutAccessType.fbeOffset + this.StopOutAccessType.fbeSize)
    this.DayOpenProfit = new fbe.FieldModelDouble(buffer, this.LevelType.fbeOffset + this.LevelType.fbeSize)
    this.OpenProfit = new fbe.FieldModelDouble(buffer, this.DayOpenProfit.fbeOffset + this.DayOpenProfit.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.ActualVersion.fbeSize + this.BalanceCurrency.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Leverage.fbeSize + this.Equity.fbeSize + this.MarginValue.fbeSize + this.MarginLevel.fbeSize + this.MarginCallLevel.fbeSize + this.MarginCallAccessType.fbeSize + this.StopOutLevel.fbeSize + this.StopOutType.fbeSize + this.StopOutAccessType.fbeSize + this.LevelType.fbeSize + this.DayOpenProfit.fbeSize + this.OpenProfit.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.ActualVersion.fbeExtra + this.BalanceCurrency.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Leverage.fbeExtra + this.Equity.fbeExtra + this.MarginValue.fbeExtra + this.MarginLevel.fbeExtra + this.MarginCallLevel.fbeExtra + this.MarginCallAccessType.fbeExtra + this.StopOutLevel.fbeExtra + this.StopOutType.fbeExtra + this.StopOutAccessType.fbeExtra + this.LevelType.fbeExtra + this.DayOpenProfit.fbeExtra + this.OpenProfit.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMarginInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5071
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMarginInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMarginInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrency.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Equity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.MarginValue.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginValue.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginValue.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCallLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCallAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.StopOutLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutLevel.fbeSize

    if ((fbeCurrentSize + this.StopOutType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutType.fbeSize

    if ((fbeCurrentSize + this.StopOutAccessType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOutAccessType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.LevelType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LevelType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LevelType.fbeSize

    if ((fbeCurrentSize + this.DayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMarginInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMarginInfo}
   * @param {!MarginInfo} fbeValue Default value, defaults is new MarginInfo()
   * @returns {!MarginInfo} MarginInfo value
   */
  get (fbeValue = new MarginInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMarginInfo}
   * @param {!MarginInfo} fbeValue MarginInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrency.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrency = this.BalanceCurrency.get()
    } else {
      fbeValue.BalanceCurrency = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrency.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) <= fbeStructSize) {
      fbeValue.Equity = this.Equity.get()
    } else {
      fbeValue.Equity = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.MarginValue.fbeSize) <= fbeStructSize) {
      fbeValue.MarginValue = this.MarginValue.get()
    } else {
      fbeValue.MarginValue = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginValue.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginLevel = this.MarginLevel.get()
    } else {
      fbeValue.MarginLevel = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCallLevel = this.MarginCallLevel.get()
    } else {
      fbeValue.MarginCallLevel = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallLevel.fbeSize

    if ((fbeCurrentSize + this.MarginCallAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCallAccessType = this.MarginCallAccessType.get()
    } else {
      fbeValue.MarginCallAccessType = new TradingMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCallAccessType.fbeSize

    if ((fbeCurrentSize + this.StopOutLevel.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutLevel = this.StopOutLevel.get()
    } else {
      fbeValue.StopOutLevel = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutLevel.fbeSize

    if ((fbeCurrentSize + this.StopOutType.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutType = this.StopOutType.get()
    } else {
      fbeValue.StopOutType = new StopOutType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutType.fbeSize

    if ((fbeCurrentSize + this.StopOutAccessType.fbeSize) <= fbeStructSize) {
      fbeValue.StopOutAccessType = this.StopOutAccessType.get()
    } else {
      fbeValue.StopOutAccessType = new TradingMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOutAccessType.fbeSize

    if ((fbeCurrentSize + this.LevelType.fbeSize) <= fbeStructSize) {
      fbeValue.LevelType = this.LevelType.get()
    } else {
      fbeValue.LevelType = new LevelType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LevelType.fbeSize

    if ((fbeCurrentSize + this.DayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayOpenProfit = this.DayOpenProfit.get()
    } else {
      fbeValue.DayOpenProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.OpenProfit = this.OpenProfit.get()
    } else {
      fbeValue.OpenProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMarginInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMarginInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMarginInfo}
   * @param {!MarginInfo} fbeValue MarginInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMarginInfo}
   * @param {MarginInfo} fbeValue MarginInfo value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.BalanceCurrency.set(fbeValue.BalanceCurrency)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Leverage.set(fbeValue.Leverage)
    this.Equity.set(fbeValue.Equity)
    this.MarginValue.set(fbeValue.MarginValue)
    this.MarginLevel.set(fbeValue.MarginLevel)
    this.MarginCallLevel.set(fbeValue.MarginCallLevel)
    this.MarginCallAccessType.set(fbeValue.MarginCallAccessType)
    this.StopOutLevel.set(fbeValue.StopOutLevel)
    this.StopOutType.set(fbeValue.StopOutType)
    this.StopOutAccessType.set(fbeValue.StopOutAccessType)
    this.LevelType.set(fbeValue.LevelType)
    this.DayOpenProfit.set(fbeValue.DayOpenProfit)
    this.OpenProfit.set(fbeValue.OpenProfit)
  }
}

export { FieldModelMarginInfo };

/**
 * Fast Binary Encoding MarginInfo model
 */
class MarginInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMarginInfo(this.buffer, 4)
  }

  /**
   * Get the MarginInfo model
   * @this {!MarginInfoModel}
   * @returns {!FieldModelMarginInfo} model MarginInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!MarginInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!MarginInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return MarginInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!MarginInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMarginInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!MarginInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!MarginInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!MarginInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!MarginInfoModel}
   * @param {!MarginInfo} value MarginInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!MarginInfoModel}
   * @param {!MarginInfo} value MarginInfo value, defaults is new MarginInfo()
   * @return {!object} Deserialized MarginInfo value and its size
   */
  deserialize (value = new MarginInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new MarginInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new MarginInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!MarginInfoModel}
   * @param {!number} prev Previous MarginInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { MarginInfoModel };
