
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AuthenticationType} from '../core/AuthenticationType';
import {FieldModelAuthenticationType} from '../core/AuthenticationType';

/**
 * Authentication struct
 */
class Authentication {
  /**
   * Initialize struct
   * @param {!AuthenticationType=} authenticationType
   * @param {!string=} passwordDigest
   * @param {!string=} phonePasswordDigest
   * @constructor
   */
  constructor (authenticationType = new AuthenticationType(), passwordDigest = '', phonePasswordDigest = '') {
    this.AuthenticationType = authenticationType
    this.PasswordDigest = passwordDigest
    this.PhonePasswordDigest = phonePasswordDigest
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Authentication}
   * @param {!Authentication} other Other struct
   * @returns {!Authentication} This struct
   */
  copy (other) {
    if (other.AuthenticationType != null) {
      this.AuthenticationType = AuthenticationType.fromObject(other.AuthenticationType)
    } else {
      this.AuthenticationType = null
    }
    if (other.PasswordDigest != null) {
      this.PasswordDigest = other.PasswordDigest
    } else {
      this.PasswordDigest = null
    }
    if (other.PhonePasswordDigest != null) {
      this.PhonePasswordDigest = other.PhonePasswordDigest
    } else {
      this.PhonePasswordDigest = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Authentication}
   * @returns {!Authentication} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AuthenticationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AuthenticationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Authentication}
   * @param {!Authentication} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Authentication)) {
      throw new TypeError('Instance of Authentication is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Authentication}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AuthenticationType: ((this.AuthenticationType != null) ? this.AuthenticationType : null),
      PasswordDigest: ((this.PasswordDigest != null) ? this.PasswordDigest : null),
      PhonePasswordDigest: ((this.PhonePasswordDigest != null) ? this.PhonePasswordDigest : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Authentication.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Authentication} other Object value
   * @returns {!Authentication} Created struct
   */
  static fromObject (other) {
    return new Authentication().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Authentication}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Authentication.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Authentication}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 13014
  }
}

export { Authentication };

/**
 * Fast Binary Encoding Authentication field model
 */
class FieldModelAuthentication extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AuthenticationType = new FieldModelAuthenticationType(buffer, 4 + 4)
    this.PasswordDigest = new fbe.FieldModelString(buffer, this.AuthenticationType.fbeOffset + this.AuthenticationType.fbeSize)
    this.PhonePasswordDigest = new fbe.FieldModelString(buffer, this.PasswordDigest.fbeOffset + this.PasswordDigest.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AuthenticationType.fbeSize + this.PasswordDigest.fbeSize + this.PhonePasswordDigest.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AuthenticationType.fbeExtra + this.PasswordDigest.fbeExtra + this.PhonePasswordDigest.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAuthentication.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 13014
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAuthentication}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAuthentication}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AuthenticationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.PhonePasswordDigest.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePasswordDigest.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePasswordDigest.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAuthentication}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAuthentication}
   * @param {!Authentication} fbeValue Default value, defaults is new Authentication()
   * @returns {!Authentication} Authentication value
   */
  get (fbeValue = new Authentication()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAuthentication}
   * @param {!Authentication} fbeValue Authentication value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) <= fbeStructSize) {
      fbeValue.AuthenticationType = this.AuthenticationType.get()
    } else {
      fbeValue.AuthenticationType = new AuthenticationType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.PasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.PasswordDigest = this.PasswordDigest.get()
    } else {
      fbeValue.PasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PasswordDigest.fbeSize

    if ((fbeCurrentSize + this.PhonePasswordDigest.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePasswordDigest = this.PhonePasswordDigest.get()
    } else {
      fbeValue.PhonePasswordDigest = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePasswordDigest.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAuthentication}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAuthentication}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAuthentication}
   * @param {!Authentication} fbeValue Authentication value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAuthentication}
   * @param {Authentication} fbeValue Authentication value
   */
  setFields (fbeValue) {
    this.AuthenticationType.set(fbeValue.AuthenticationType)
    this.PasswordDigest.set(fbeValue.PasswordDigest)
    this.PhonePasswordDigest.set(fbeValue.PhonePasswordDigest)
  }
}

export { FieldModelAuthentication };

/**
 * Fast Binary Encoding Authentication model
 */
class AuthenticationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAuthentication(this.buffer, 4)
  }

  /**
   * Get the Authentication model
   * @this {!AuthenticationModel}
   * @returns {!FieldModelAuthentication} model Authentication model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AuthenticationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AuthenticationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AuthenticationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AuthenticationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAuthentication.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AuthenticationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AuthenticationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AuthenticationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AuthenticationModel}
   * @param {!Authentication} value Authentication value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AuthenticationModel}
   * @param {!Authentication} value Authentication value, defaults is new Authentication()
   * @return {!object} Deserialized Authentication value and its size
   */
  deserialize (value = new Authentication()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Authentication(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Authentication(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AuthenticationModel}
   * @param {!number} prev Previous Authentication model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AuthenticationModel };
