import React, { ReactNode } from 'react';
import { message } from 'antd';
import styles from './Message.module.scss';

export enum MessageType {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

export const Message = (
    type: MessageType,
    content: string | ReactNode,
    durationSec?: number,
    className?: string,
): void => {
    message[type]({
        content: <span className={styles.message}>{content}</span>,
        duration: durationSec || 4,
        className,
    });
};
