
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ConfigServerSettings struct
 */
class ConfigServerSettings {
  /**
   * Initialize struct
   * @param {!number=} configPort
   * @param {!string=} configPath
   * @param {!boolean=} configGit
   * @param {!boolean=} useSsl
   * @constructor
   */
  constructor (configPort = 6100, configPath = '../config', configGit = false, useSsl = false) {
    this.ConfigPort = configPort
    this.ConfigPath = configPath
    this.ConfigGit = configGit
    this.UseSsl = useSsl
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ConfigServerSettings}
   * @param {!ConfigServerSettings} other Other struct
   * @returns {!ConfigServerSettings} This struct
   */
  copy (other) {
    if (other.ConfigPort != null) {
      this.ConfigPort = other.ConfigPort
    } else {
      this.ConfigPort = null
    }
    if (other.ConfigPath != null) {
      this.ConfigPath = other.ConfigPath
    } else {
      this.ConfigPath = null
    }
    if (other.ConfigGit != null) {
      this.ConfigGit = other.ConfigGit
    } else {
      this.ConfigGit = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ConfigServerSettings}
   * @returns {!ConfigServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ConfigServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ConfigServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ConfigServerSettings}
   * @param {!ConfigServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ConfigServerSettings)) {
      throw new TypeError('Instance of ConfigServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ConfigServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ConfigPort: ((this.ConfigPort != null) ? this.ConfigPort : null),
      ConfigPath: ((this.ConfigPath != null) ? this.ConfigPath : null),
      ConfigGit: ((this.ConfigGit != null) ? this.ConfigGit : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ConfigServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ConfigServerSettings} other Object value
   * @returns {!ConfigServerSettings} Created struct
   */
  static fromObject (other) {
    return new ConfigServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ConfigServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ConfigServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ConfigServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 8
  }
}

export { ConfigServerSettings };

/**
 * Fast Binary Encoding ConfigServerSettings field model
 */
class FieldModelConfigServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ConfigPort = new fbe.FieldModelUInt16(buffer, 4 + 4)
    this.ConfigPath = new fbe.FieldModelString(buffer, this.ConfigPort.fbeOffset + this.ConfigPort.fbeSize)
    this.ConfigGit = new fbe.FieldModelBool(buffer, this.ConfigPath.fbeOffset + this.ConfigPath.fbeSize)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.ConfigGit.fbeOffset + this.ConfigGit.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ConfigPort.fbeSize + this.ConfigPath.fbeSize + this.ConfigGit.fbeSize + this.UseSsl.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ConfigPort.fbeExtra + this.ConfigPath.fbeExtra + this.ConfigGit.fbeExtra + this.UseSsl.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelConfigServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 8
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelConfigServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelConfigServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConfigPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConfigPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigPort.fbeSize

    if ((fbeCurrentSize + this.ConfigPath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConfigPath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigPath.fbeSize

    if ((fbeCurrentSize + this.ConfigGit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConfigGit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigGit.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelConfigServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelConfigServerSettings}
   * @param {!ConfigServerSettings} fbeValue Default value, defaults is new ConfigServerSettings()
   * @returns {!ConfigServerSettings} ConfigServerSettings value
   */
  get (fbeValue = new ConfigServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelConfigServerSettings}
   * @param {!ConfigServerSettings} fbeValue ConfigServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConfigPort.fbeSize) <= fbeStructSize) {
      fbeValue.ConfigPort = this.ConfigPort.get(6100)
    } else {
      fbeValue.ConfigPort = 6100
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigPort.fbeSize

    if ((fbeCurrentSize + this.ConfigPath.fbeSize) <= fbeStructSize) {
      fbeValue.ConfigPath = this.ConfigPath.get('../config')
    } else {
      fbeValue.ConfigPath = '../config'
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigPath.fbeSize

    if ((fbeCurrentSize + this.ConfigGit.fbeSize) <= fbeStructSize) {
      fbeValue.ConfigGit = this.ConfigGit.get(false)
    } else {
      fbeValue.ConfigGit = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigGit.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get(false)
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelConfigServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelConfigServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelConfigServerSettings}
   * @param {!ConfigServerSettings} fbeValue ConfigServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelConfigServerSettings}
   * @param {ConfigServerSettings} fbeValue ConfigServerSettings value
   */
  setFields (fbeValue) {
    this.ConfigPort.set(fbeValue.ConfigPort)
    this.ConfigPath.set(fbeValue.ConfigPath)
    this.ConfigGit.set(fbeValue.ConfigGit)
    this.UseSsl.set(fbeValue.UseSsl)
  }
}

export { FieldModelConfigServerSettings };

/**
 * Fast Binary Encoding ConfigServerSettings model
 */
class ConfigServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelConfigServerSettings(this.buffer, 4)
  }

  /**
   * Get the ConfigServerSettings model
   * @this {!ConfigServerSettingsModel}
   * @returns {!FieldModelConfigServerSettings} model ConfigServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ConfigServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ConfigServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ConfigServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ConfigServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelConfigServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ConfigServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ConfigServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ConfigServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ConfigServerSettingsModel}
   * @param {!ConfigServerSettings} value ConfigServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ConfigServerSettingsModel}
   * @param {!ConfigServerSettings} value ConfigServerSettings value, defaults is new ConfigServerSettings()
   * @return {!object} Deserialized ConfigServerSettings value and its size
   */
  deserialize (value = new ConfigServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ConfigServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ConfigServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ConfigServerSettingsModel}
   * @param {!number} prev Previous ConfigServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ConfigServerSettingsModel };
