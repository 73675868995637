
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {SymbolCreateResponse} from './SymbolCreateResponse';
import {MarginCalculationType} from '../core/MarginCalculationType';
import {FieldModelMarginCalculationType} from '../core/MarginCalculationType';
import {ProfitCalculationType} from '../core/ProfitCalculationType';
import {FieldModelProfitCalculationType} from '../core/ProfitCalculationType';
import {SwapCalculationType} from '../core/SwapCalculationType';
import {FieldModelSwapCalculationType} from '../core/SwapCalculationType';
import {DayOfWeek} from '../core/DayOfWeek';
import {FieldModelDayOfWeek} from '../core/DayOfWeek';
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';

/**
 * SymbolCreateRequest struct
 */
class SymbolCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {UUID=} symbolGroupId
   * @param {string=} symbolGroupName
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} alias
   * @param {!string=} source
   * @param {!number=} precision
   * @param {UUID=} marginCurrencyId
   * @param {string=} marginCurrencyName
   * @param {number=} marginCurrencyPrecision
   * @param {MarginCalculationType=} marginCalculationType
   * @param {UUID=} profitCurrencyId
   * @param {string=} profitCurrencyName
   * @param {number=} profitCurrencyPrecision
   * @param {ProfitCalculationType=} profitCalculationType
   * @param {number=} color
   * @param {boolean=} isTradeAllowed
   * @param {!number=} contractSize
   * @param {!number=} minTradeAmount
   * @param {!number=} maxTradeAmount
   * @param {!number=} tradeAmountStep
   * @param {number=} defaultExpiration
   * @param {boolean=} isSwapEnabled
   * @param {SwapCalculationType=} swapType
   * @param {number=} swapLong
   * @param {number=} swapShort
   * @param {DayOfWeek=} swapTripleDay
   * @param {number=} marketOrderMarginRate
   * @param {number=} limitOrderMarginRate
   * @param {number=} stopOrderMarginRate
   * @param {TradingMode=} tradingMode
   * @param {!number=} stopsLevel
   * @param {!boolean=} isFavorite
   * @param {number=} sortOrderFavorite
   * @param {string=} postfix
   * @param {!number=} initialMargin
   * @param {!number=} hedgedMargin
   * @param {number=} swapDaysInYear
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, symbolGroupId = null, symbolGroupName = null, name = '', comment = '', description = '', alias = '', source = '', precision = 2, marginCurrencyId = null, marginCurrencyName = null, marginCurrencyPrecision = null, marginCalculationType = null, profitCurrencyId = null, profitCurrencyName = null, profitCurrencyPrecision = null, profitCalculationType = null, color = null, isTradeAllowed = null, contractSize = 1.0, minTradeAmount = 0.1, maxTradeAmount = 1000000.0, tradeAmountStep = 0.01, defaultExpiration = null, isSwapEnabled = null, swapType = null, swapLong = null, swapShort = null, swapTripleDay = null, marketOrderMarginRate = null, limitOrderMarginRate = null, stopOrderMarginRate = null, tradingMode = null, stopsLevel = new UInt64(0, 0), isFavorite = false, sortOrderFavorite = null, postfix = null, initialMargin = 0.0, hedgedMargin = 0.0, swapDaysInYear = null) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.SymbolGroupId = symbolGroupId
    this.SymbolGroupName = symbolGroupName
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.Alias = alias
    this.Source = source
    this.Precision = precision
    this.MarginCurrencyId = marginCurrencyId
    this.MarginCurrencyName = marginCurrencyName
    this.MarginCurrencyPrecision = marginCurrencyPrecision
    this.MarginCalculationType = marginCalculationType
    this.ProfitCurrencyId = profitCurrencyId
    this.ProfitCurrencyName = profitCurrencyName
    this.ProfitCurrencyPrecision = profitCurrencyPrecision
    this.ProfitCalculationType = profitCalculationType
    this.Color = color
    this.IsTradeAllowed = isTradeAllowed
    this.ContractSize = contractSize
    this.MinTradeAmount = minTradeAmount
    this.MaxTradeAmount = maxTradeAmount
    this.TradeAmountStep = tradeAmountStep
    this.DefaultExpiration = defaultExpiration
    this.IsSwapEnabled = isSwapEnabled
    this.SwapType = swapType
    this.SwapLong = swapLong
    this.SwapShort = swapShort
    this.SwapTripleDay = swapTripleDay
    this.MarketOrderMarginRate = marketOrderMarginRate
    this.LimitOrderMarginRate = limitOrderMarginRate
    this.StopOrderMarginRate = stopOrderMarginRate
    this.TradingMode = tradingMode
    this.StopsLevel = stopsLevel
    this.IsFavorite = isFavorite
    this.SortOrderFavorite = sortOrderFavorite
    this.Postfix = postfix
    this.InitialMargin = initialMargin
    this.HedgedMargin = hedgedMargin
    this.SwapDaysInYear = swapDaysInYear
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SymbolCreateRequest}
   * @param {!SymbolCreateRequest} other Other struct
   * @returns {!SymbolCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.SymbolGroupId != null) {
      this.SymbolGroupId = new UUID(other.SymbolGroupId)
    } else {
      this.SymbolGroupId = null
    }
    if (other.SymbolGroupName != null) {
      this.SymbolGroupName = other.SymbolGroupName
    } else {
      this.SymbolGroupName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Alias != null) {
      this.Alias = other.Alias
    } else {
      this.Alias = null
    }
    if (other.Source != null) {
      this.Source = other.Source
    } else {
      this.Source = null
    }
    if (other.Precision != null) {
      this.Precision = other.Precision
    } else {
      this.Precision = null
    }
    if (other.MarginCurrencyId != null) {
      this.MarginCurrencyId = new UUID(other.MarginCurrencyId)
    } else {
      this.MarginCurrencyId = null
    }
    if (other.MarginCurrencyName != null) {
      this.MarginCurrencyName = other.MarginCurrencyName
    } else {
      this.MarginCurrencyName = null
    }
    if (other.MarginCurrencyPrecision != null) {
      this.MarginCurrencyPrecision = other.MarginCurrencyPrecision
    } else {
      this.MarginCurrencyPrecision = null
    }
    if (other.MarginCalculationType != null) {
      this.MarginCalculationType = MarginCalculationType.fromObject(other.MarginCalculationType)
    } else {
      this.MarginCalculationType = null
    }
    if (other.ProfitCurrencyId != null) {
      this.ProfitCurrencyId = new UUID(other.ProfitCurrencyId)
    } else {
      this.ProfitCurrencyId = null
    }
    if (other.ProfitCurrencyName != null) {
      this.ProfitCurrencyName = other.ProfitCurrencyName
    } else {
      this.ProfitCurrencyName = null
    }
    if (other.ProfitCurrencyPrecision != null) {
      this.ProfitCurrencyPrecision = other.ProfitCurrencyPrecision
    } else {
      this.ProfitCurrencyPrecision = null
    }
    if (other.ProfitCalculationType != null) {
      this.ProfitCalculationType = ProfitCalculationType.fromObject(other.ProfitCalculationType)
    } else {
      this.ProfitCalculationType = null
    }
    if (other.Color != null) {
      this.Color = other.Color
    } else {
      this.Color = null
    }
    if (other.IsTradeAllowed != null) {
      this.IsTradeAllowed = other.IsTradeAllowed
    } else {
      this.IsTradeAllowed = null
    }
    if (other.ContractSize != null) {
      this.ContractSize = other.ContractSize
    } else {
      this.ContractSize = null
    }
    if (other.MinTradeAmount != null) {
      this.MinTradeAmount = other.MinTradeAmount
    } else {
      this.MinTradeAmount = null
    }
    if (other.MaxTradeAmount != null) {
      this.MaxTradeAmount = other.MaxTradeAmount
    } else {
      this.MaxTradeAmount = null
    }
    if (other.TradeAmountStep != null) {
      this.TradeAmountStep = other.TradeAmountStep
    } else {
      this.TradeAmountStep = null
    }
    if (other.DefaultExpiration != null) {
      this.DefaultExpiration = UInt64.fromNumber(other.DefaultExpiration)
    } else {
      this.DefaultExpiration = null
    }
    if (other.IsSwapEnabled != null) {
      this.IsSwapEnabled = other.IsSwapEnabled
    } else {
      this.IsSwapEnabled = null
    }
    if (other.SwapType != null) {
      this.SwapType = SwapCalculationType.fromObject(other.SwapType)
    } else {
      this.SwapType = null
    }
    if (other.SwapLong != null) {
      this.SwapLong = other.SwapLong
    } else {
      this.SwapLong = null
    }
    if (other.SwapShort != null) {
      this.SwapShort = other.SwapShort
    } else {
      this.SwapShort = null
    }
    if (other.SwapTripleDay != null) {
      this.SwapTripleDay = DayOfWeek.fromObject(other.SwapTripleDay)
    } else {
      this.SwapTripleDay = null
    }
    if (other.MarketOrderMarginRate != null) {
      this.MarketOrderMarginRate = other.MarketOrderMarginRate
    } else {
      this.MarketOrderMarginRate = null
    }
    if (other.LimitOrderMarginRate != null) {
      this.LimitOrderMarginRate = other.LimitOrderMarginRate
    } else {
      this.LimitOrderMarginRate = null
    }
    if (other.StopOrderMarginRate != null) {
      this.StopOrderMarginRate = other.StopOrderMarginRate
    } else {
      this.StopOrderMarginRate = null
    }
    if (other.TradingMode != null) {
      this.TradingMode = TradingMode.fromObject(other.TradingMode)
    } else {
      this.TradingMode = null
    }
    if (other.StopsLevel != null) {
      this.StopsLevel = UInt64.fromNumber(other.StopsLevel)
    } else {
      this.StopsLevel = null
    }
    if (other.IsFavorite != null) {
      this.IsFavorite = other.IsFavorite
    } else {
      this.IsFavorite = null
    }
    if (other.SortOrderFavorite != null) {
      this.SortOrderFavorite = other.SortOrderFavorite
    } else {
      this.SortOrderFavorite = null
    }
    if (other.Postfix != null) {
      this.Postfix = other.Postfix
    } else {
      this.Postfix = null
    }
    if (other.InitialMargin != null) {
      this.InitialMargin = other.InitialMargin
    } else {
      this.InitialMargin = null
    }
    if (other.HedgedMargin != null) {
      this.HedgedMargin = other.HedgedMargin
    } else {
      this.HedgedMargin = null
    }
    if (other.SwapDaysInYear != null) {
      this.SwapDaysInYear = UInt64.fromNumber(other.SwapDaysInYear)
    } else {
      this.SwapDaysInYear = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SymbolCreateRequest}
   * @returns {!SymbolCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SymbolCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SymbolCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SymbolCreateRequest}
   * @param {!SymbolCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SymbolCreateRequest)) {
      throw new TypeError('Instance of SymbolCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SymbolCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      SymbolGroupId: ((this.SymbolGroupId != null) ? this.SymbolGroupId.toString() : null),
      SymbolGroupName: ((this.SymbolGroupName != null) ? this.SymbolGroupName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      Alias: ((this.Alias != null) ? this.Alias : null),
      Source: ((this.Source != null) ? this.Source : null),
      Precision: ((this.Precision != null) ? this.Precision : null),
      MarginCurrencyId: ((this.MarginCurrencyId != null) ? this.MarginCurrencyId.toString() : null),
      MarginCurrencyName: ((this.MarginCurrencyName != null) ? this.MarginCurrencyName : null),
      MarginCurrencyPrecision: ((this.MarginCurrencyPrecision != null) ? this.MarginCurrencyPrecision : null),
      MarginCalculationType: ((this.MarginCalculationType != null) ? this.MarginCalculationType : null),
      ProfitCurrencyId: ((this.ProfitCurrencyId != null) ? this.ProfitCurrencyId.toString() : null),
      ProfitCurrencyName: ((this.ProfitCurrencyName != null) ? this.ProfitCurrencyName : null),
      ProfitCurrencyPrecision: ((this.ProfitCurrencyPrecision != null) ? this.ProfitCurrencyPrecision : null),
      ProfitCalculationType: ((this.ProfitCalculationType != null) ? this.ProfitCalculationType : null),
      Color: ((this.Color != null) ? this.Color : null),
      IsTradeAllowed: ((this.IsTradeAllowed != null) ? this.IsTradeAllowed : null),
      ContractSize: ((this.ContractSize != null) ? this.ContractSize : null),
      MinTradeAmount: ((this.MinTradeAmount != null) ? this.MinTradeAmount : null),
      MaxTradeAmount: ((this.MaxTradeAmount != null) ? this.MaxTradeAmount : null),
      TradeAmountStep: ((this.TradeAmountStep != null) ? this.TradeAmountStep : null),
      DefaultExpiration: ((this.DefaultExpiration != null) ? this.DefaultExpiration.toNumber() : null),
      IsSwapEnabled: ((this.IsSwapEnabled != null) ? this.IsSwapEnabled : null),
      SwapType: ((this.SwapType != null) ? this.SwapType : null),
      SwapLong: ((this.SwapLong != null) ? this.SwapLong : null),
      SwapShort: ((this.SwapShort != null) ? this.SwapShort : null),
      SwapTripleDay: ((this.SwapTripleDay != null) ? this.SwapTripleDay : null),
      MarketOrderMarginRate: ((this.MarketOrderMarginRate != null) ? this.MarketOrderMarginRate : null),
      LimitOrderMarginRate: ((this.LimitOrderMarginRate != null) ? this.LimitOrderMarginRate : null),
      StopOrderMarginRate: ((this.StopOrderMarginRate != null) ? this.StopOrderMarginRate : null),
      TradingMode: ((this.TradingMode != null) ? this.TradingMode : null),
      StopsLevel: ((this.StopsLevel != null) ? this.StopsLevel.toNumber() : null),
      IsFavorite: ((this.IsFavorite != null) ? this.IsFavorite : null),
      SortOrderFavorite: ((this.SortOrderFavorite != null) ? this.SortOrderFavorite : null),
      Postfix: ((this.Postfix != null) ? this.Postfix : null),
      InitialMargin: ((this.InitialMargin != null) ? this.InitialMargin : null),
      HedgedMargin: ((this.HedgedMargin != null) ? this.HedgedMargin : null),
      SwapDaysInYear: ((this.SwapDaysInYear != null) ? this.SwapDaysInYear.toNumber() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SymbolCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SymbolCreateRequest} other Object value
   * @returns {!SymbolCreateRequest} Created struct
   */
  static fromObject (other) {
    return new SymbolCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SymbolCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SymbolCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SymbolCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6111
  }
}

export { SymbolCreateRequest };

/**
 * Fast Binary Encoding SymbolCreateRequest field model
 */
class FieldModelSymbolCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.SymbolGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize), buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.SymbolGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SymbolGroupId.fbeOffset + this.SymbolGroupId.fbeSize), buffer, this.SymbolGroupId.fbeOffset + this.SymbolGroupId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.SymbolGroupName.fbeOffset + this.SymbolGroupName.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.Alias = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Source = new fbe.FieldModelString(buffer, this.Alias.fbeOffset + this.Alias.fbeSize)
    this.Precision = new fbe.FieldModelUInt8(buffer, this.Source.fbeOffset + this.Source.fbeSize)
    this.MarginCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Precision.fbeOffset + this.Precision.fbeSize), buffer, this.Precision.fbeOffset + this.Precision.fbeSize)
    this.MarginCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MarginCurrencyId.fbeOffset + this.MarginCurrencyId.fbeSize), buffer, this.MarginCurrencyId.fbeOffset + this.MarginCurrencyId.fbeSize)
    this.MarginCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.MarginCurrencyName.fbeOffset + this.MarginCurrencyName.fbeSize), buffer, this.MarginCurrencyName.fbeOffset + this.MarginCurrencyName.fbeSize)
    this.MarginCalculationType = new fbe.FieldModelOptional(new FieldModelMarginCalculationType(buffer, this.MarginCurrencyPrecision.fbeOffset + this.MarginCurrencyPrecision.fbeSize), buffer, this.MarginCurrencyPrecision.fbeOffset + this.MarginCurrencyPrecision.fbeSize)
    this.ProfitCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize), buffer, this.MarginCalculationType.fbeOffset + this.MarginCalculationType.fbeSize)
    this.ProfitCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ProfitCurrencyId.fbeOffset + this.ProfitCurrencyId.fbeSize), buffer, this.ProfitCurrencyId.fbeOffset + this.ProfitCurrencyId.fbeSize)
    this.ProfitCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.ProfitCurrencyName.fbeOffset + this.ProfitCurrencyName.fbeSize), buffer, this.ProfitCurrencyName.fbeOffset + this.ProfitCurrencyName.fbeSize)
    this.ProfitCalculationType = new fbe.FieldModelOptional(new FieldModelProfitCalculationType(buffer, this.ProfitCurrencyPrecision.fbeOffset + this.ProfitCurrencyPrecision.fbeSize), buffer, this.ProfitCurrencyPrecision.fbeOffset + this.ProfitCurrencyPrecision.fbeSize)
    this.Color = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.ProfitCalculationType.fbeOffset + this.ProfitCalculationType.fbeSize), buffer, this.ProfitCalculationType.fbeOffset + this.ProfitCalculationType.fbeSize)
    this.IsTradeAllowed = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Color.fbeOffset + this.Color.fbeSize), buffer, this.Color.fbeOffset + this.Color.fbeSize)
    this.ContractSize = new fbe.FieldModelDouble(buffer, this.IsTradeAllowed.fbeOffset + this.IsTradeAllowed.fbeSize)
    this.MinTradeAmount = new fbe.FieldModelDouble(buffer, this.ContractSize.fbeOffset + this.ContractSize.fbeSize)
    this.MaxTradeAmount = new fbe.FieldModelDouble(buffer, this.MinTradeAmount.fbeOffset + this.MinTradeAmount.fbeSize)
    this.TradeAmountStep = new fbe.FieldModelDouble(buffer, this.MaxTradeAmount.fbeOffset + this.MaxTradeAmount.fbeSize)
    this.DefaultExpiration = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize), buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize)
    this.IsSwapEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.DefaultExpiration.fbeOffset + this.DefaultExpiration.fbeSize), buffer, this.DefaultExpiration.fbeOffset + this.DefaultExpiration.fbeSize)
    this.SwapType = new fbe.FieldModelOptional(new FieldModelSwapCalculationType(buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize), buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize)
    this.SwapLong = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SwapType.fbeOffset + this.SwapType.fbeSize), buffer, this.SwapType.fbeOffset + this.SwapType.fbeSize)
    this.SwapShort = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SwapLong.fbeOffset + this.SwapLong.fbeSize), buffer, this.SwapLong.fbeOffset + this.SwapLong.fbeSize)
    this.SwapTripleDay = new fbe.FieldModelOptional(new FieldModelDayOfWeek(buffer, this.SwapShort.fbeOffset + this.SwapShort.fbeSize), buffer, this.SwapShort.fbeOffset + this.SwapShort.fbeSize)
    this.MarketOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SwapTripleDay.fbeOffset + this.SwapTripleDay.fbeSize), buffer, this.SwapTripleDay.fbeOffset + this.SwapTripleDay.fbeSize)
    this.LimitOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MarketOrderMarginRate.fbeOffset + this.MarketOrderMarginRate.fbeSize), buffer, this.MarketOrderMarginRate.fbeOffset + this.MarketOrderMarginRate.fbeSize)
    this.StopOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.LimitOrderMarginRate.fbeOffset + this.LimitOrderMarginRate.fbeSize), buffer, this.LimitOrderMarginRate.fbeOffset + this.LimitOrderMarginRate.fbeSize)
    this.TradingMode = new fbe.FieldModelOptional(new FieldModelTradingMode(buffer, this.StopOrderMarginRate.fbeOffset + this.StopOrderMarginRate.fbeSize), buffer, this.StopOrderMarginRate.fbeOffset + this.StopOrderMarginRate.fbeSize)
    this.StopsLevel = new fbe.FieldModelUInt64(buffer, this.TradingMode.fbeOffset + this.TradingMode.fbeSize)
    this.IsFavorite = new fbe.FieldModelBool(buffer, this.StopsLevel.fbeOffset + this.StopsLevel.fbeSize)
    this.SortOrderFavorite = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize), buffer, this.IsFavorite.fbeOffset + this.IsFavorite.fbeSize)
    this.Postfix = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SortOrderFavorite.fbeOffset + this.SortOrderFavorite.fbeSize), buffer, this.SortOrderFavorite.fbeOffset + this.SortOrderFavorite.fbeSize)
    this.InitialMargin = new fbe.FieldModelDouble(buffer, this.Postfix.fbeOffset + this.Postfix.fbeSize)
    this.HedgedMargin = new fbe.FieldModelDouble(buffer, this.InitialMargin.fbeOffset + this.InitialMargin.fbeSize)
    this.SwapDaysInYear = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.HedgedMargin.fbeOffset + this.HedgedMargin.fbeSize), buffer, this.HedgedMargin.fbeOffset + this.HedgedMargin.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.SymbolGroupId.fbeSize + this.SymbolGroupName.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.Alias.fbeSize + this.Source.fbeSize + this.Precision.fbeSize + this.MarginCurrencyId.fbeSize + this.MarginCurrencyName.fbeSize + this.MarginCurrencyPrecision.fbeSize + this.MarginCalculationType.fbeSize + this.ProfitCurrencyId.fbeSize + this.ProfitCurrencyName.fbeSize + this.ProfitCurrencyPrecision.fbeSize + this.ProfitCalculationType.fbeSize + this.Color.fbeSize + this.IsTradeAllowed.fbeSize + this.ContractSize.fbeSize + this.MinTradeAmount.fbeSize + this.MaxTradeAmount.fbeSize + this.TradeAmountStep.fbeSize + this.DefaultExpiration.fbeSize + this.IsSwapEnabled.fbeSize + this.SwapType.fbeSize + this.SwapLong.fbeSize + this.SwapShort.fbeSize + this.SwapTripleDay.fbeSize + this.MarketOrderMarginRate.fbeSize + this.LimitOrderMarginRate.fbeSize + this.StopOrderMarginRate.fbeSize + this.TradingMode.fbeSize + this.StopsLevel.fbeSize + this.IsFavorite.fbeSize + this.SortOrderFavorite.fbeSize + this.Postfix.fbeSize + this.InitialMargin.fbeSize + this.HedgedMargin.fbeSize + this.SwapDaysInYear.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.SymbolGroupId.fbeExtra + this.SymbolGroupName.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.Alias.fbeExtra + this.Source.fbeExtra + this.Precision.fbeExtra + this.MarginCurrencyId.fbeExtra + this.MarginCurrencyName.fbeExtra + this.MarginCurrencyPrecision.fbeExtra + this.MarginCalculationType.fbeExtra + this.ProfitCurrencyId.fbeExtra + this.ProfitCurrencyName.fbeExtra + this.ProfitCurrencyPrecision.fbeExtra + this.ProfitCalculationType.fbeExtra + this.Color.fbeExtra + this.IsTradeAllowed.fbeExtra + this.ContractSize.fbeExtra + this.MinTradeAmount.fbeExtra + this.MaxTradeAmount.fbeExtra + this.TradeAmountStep.fbeExtra + this.DefaultExpiration.fbeExtra + this.IsSwapEnabled.fbeExtra + this.SwapType.fbeExtra + this.SwapLong.fbeExtra + this.SwapShort.fbeExtra + this.SwapTripleDay.fbeExtra + this.MarketOrderMarginRate.fbeExtra + this.LimitOrderMarginRate.fbeExtra + this.StopOrderMarginRate.fbeExtra + this.TradingMode.fbeExtra + this.StopsLevel.fbeExtra + this.IsFavorite.fbeExtra + this.SortOrderFavorite.fbeExtra + this.Postfix.fbeExtra + this.InitialMargin.fbeExtra + this.HedgedMargin.fbeExtra + this.SwapDaysInYear.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSymbolCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6111
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Alias.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Alias.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Alias.fbeSize

    if ((fbeCurrentSize + this.Source.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Source.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Source.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Precision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyName.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyName.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Color.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeAllowed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ContractSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeAmountStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DefaultExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapType.fbeSize

    if ((fbeCurrentSize + this.SwapLong.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapLong.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapLong.fbeSize

    if ((fbeCurrentSize + this.SwapShort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapShort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapShort.fbeSize

    if ((fbeCurrentSize + this.SwapTripleDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapTripleDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapTripleDay.fbeSize

    if ((fbeCurrentSize + this.MarketOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarketOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.LimitOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LimitOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.StopOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopsLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrderFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.Postfix.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Postfix.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Postfix.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InitialMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HedgedMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize

    if ((fbeCurrentSize + this.SwapDaysInYear.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SwapDaysInYear.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapDaysInYear.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!SymbolCreateRequest} fbeValue Default value, defaults is new SymbolCreateRequest()
   * @returns {!SymbolCreateRequest} SymbolCreateRequest value
   */
  get (fbeValue = new SymbolCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!SymbolCreateRequest} fbeValue SymbolCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolGroupId = this.SymbolGroupId.get()
    } else {
      fbeValue.SymbolGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolGroupName = this.SymbolGroupName.get()
    } else {
      fbeValue.SymbolGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Alias.fbeSize) <= fbeStructSize) {
      fbeValue.Alias = this.Alias.get()
    } else {
      fbeValue.Alias = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Alias.fbeSize

    if ((fbeCurrentSize + this.Source.fbeSize) <= fbeStructSize) {
      fbeValue.Source = this.Source.get()
    } else {
      fbeValue.Source = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Source.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) <= fbeStructSize) {
      fbeValue.Precision = this.Precision.get(2)
    } else {
      fbeValue.Precision = 2
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyId = this.MarginCurrencyId.get()
    } else {
      fbeValue.MarginCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyName = this.MarginCurrencyName.get()
    } else {
      fbeValue.MarginCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyName.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencyPrecision = this.MarginCurrencyPrecision.get()
    } else {
      fbeValue.MarginCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.MarginCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCalculationType = this.MarginCalculationType.get()
    } else {
      fbeValue.MarginCalculationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyId = this.ProfitCurrencyId.get()
    } else {
      fbeValue.ProfitCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyName = this.ProfitCurrencyName.get()
    } else {
      fbeValue.ProfitCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyName.fbeSize

    if ((fbeCurrentSize + this.ProfitCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCurrencyPrecision = this.ProfitCurrencyPrecision.get()
    } else {
      fbeValue.ProfitCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.ProfitCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitCalculationType = this.ProfitCalculationType.get()
    } else {
      fbeValue.ProfitCalculationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.Color.fbeSize) <= fbeStructSize) {
      fbeValue.Color = this.Color.get()
    } else {
      fbeValue.Color = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Color.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeAllowed = this.IsTradeAllowed.get()
    } else {
      fbeValue.IsTradeAllowed = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.ContractSize.fbeSize) <= fbeStructSize) {
      fbeValue.ContractSize = this.ContractSize.get(1.0)
    } else {
      fbeValue.ContractSize = 1.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ContractSize.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MinTradeAmount = this.MinTradeAmount.get(0.1)
    } else {
      fbeValue.MinTradeAmount = 0.1
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MaxTradeAmount = this.MaxTradeAmount.get(1000000.0)
    } else {
      fbeValue.MaxTradeAmount = 1000000.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) <= fbeStructSize) {
      fbeValue.TradeAmountStep = this.TradeAmountStep.get(0.01)
    } else {
      fbeValue.TradeAmountStep = 0.01
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.DefaultExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.DefaultExpiration = this.DefaultExpiration.get()
    } else {
      fbeValue.DefaultExpiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSwapEnabled = this.IsSwapEnabled.get()
    } else {
      fbeValue.IsSwapEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SwapType.fbeSize) <= fbeStructSize) {
      fbeValue.SwapType = this.SwapType.get()
    } else {
      fbeValue.SwapType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapType.fbeSize

    if ((fbeCurrentSize + this.SwapLong.fbeSize) <= fbeStructSize) {
      fbeValue.SwapLong = this.SwapLong.get()
    } else {
      fbeValue.SwapLong = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapLong.fbeSize

    if ((fbeCurrentSize + this.SwapShort.fbeSize) <= fbeStructSize) {
      fbeValue.SwapShort = this.SwapShort.get()
    } else {
      fbeValue.SwapShort = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapShort.fbeSize

    if ((fbeCurrentSize + this.SwapTripleDay.fbeSize) <= fbeStructSize) {
      fbeValue.SwapTripleDay = this.SwapTripleDay.get()
    } else {
      fbeValue.SwapTripleDay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapTripleDay.fbeSize

    if ((fbeCurrentSize + this.MarketOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.MarketOrderMarginRate = this.MarketOrderMarginRate.get()
    } else {
      fbeValue.MarketOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.LimitOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.LimitOrderMarginRate = this.LimitOrderMarginRate.get()
    } else {
      fbeValue.LimitOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.StopOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.StopOrderMarginRate = this.StopOrderMarginRate.get()
    } else {
      fbeValue.StopOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.TradingMode.fbeSize) <= fbeStructSize) {
      fbeValue.TradingMode = this.TradingMode.get()
    } else {
      fbeValue.TradingMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingMode.fbeSize

    if ((fbeCurrentSize + this.StopsLevel.fbeSize) <= fbeStructSize) {
      fbeValue.StopsLevel = this.StopsLevel.get(new UInt64(0, 0))
    } else {
      fbeValue.StopsLevel = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopsLevel.fbeSize

    if ((fbeCurrentSize + this.IsFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.IsFavorite = this.IsFavorite.get()
    } else {
      fbeValue.IsFavorite = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFavorite.fbeSize

    if ((fbeCurrentSize + this.SortOrderFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrderFavorite = this.SortOrderFavorite.get()
    } else {
      fbeValue.SortOrderFavorite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.Postfix.fbeSize) <= fbeStructSize) {
      fbeValue.Postfix = this.Postfix.get()
    } else {
      fbeValue.Postfix = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Postfix.fbeSize

    if ((fbeCurrentSize + this.InitialMargin.fbeSize) <= fbeStructSize) {
      fbeValue.InitialMargin = this.InitialMargin.get()
    } else {
      fbeValue.InitialMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InitialMargin.fbeSize

    if ((fbeCurrentSize + this.HedgedMargin.fbeSize) <= fbeStructSize) {
      fbeValue.HedgedMargin = this.HedgedMargin.get()
    } else {
      fbeValue.HedgedMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HedgedMargin.fbeSize

    if ((fbeCurrentSize + this.SwapDaysInYear.fbeSize) <= fbeStructSize) {
      fbeValue.SwapDaysInYear = this.SwapDaysInYear.get()
    } else {
      fbeValue.SwapDaysInYear = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SwapDaysInYear.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSymbolCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSymbolCreateRequest}
   * @param {!SymbolCreateRequest} fbeValue SymbolCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSymbolCreateRequest}
   * @param {SymbolCreateRequest} fbeValue SymbolCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.SymbolGroupId.set(fbeValue.SymbolGroupId)
    this.SymbolGroupName.set(fbeValue.SymbolGroupName)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.Alias.set(fbeValue.Alias)
    this.Source.set(fbeValue.Source)
    this.Precision.set(fbeValue.Precision)
    this.MarginCurrencyId.set(fbeValue.MarginCurrencyId)
    this.MarginCurrencyName.set(fbeValue.MarginCurrencyName)
    this.MarginCurrencyPrecision.set(fbeValue.MarginCurrencyPrecision)
    this.MarginCalculationType.set(fbeValue.MarginCalculationType)
    this.ProfitCurrencyId.set(fbeValue.ProfitCurrencyId)
    this.ProfitCurrencyName.set(fbeValue.ProfitCurrencyName)
    this.ProfitCurrencyPrecision.set(fbeValue.ProfitCurrencyPrecision)
    this.ProfitCalculationType.set(fbeValue.ProfitCalculationType)
    this.Color.set(fbeValue.Color)
    this.IsTradeAllowed.set(fbeValue.IsTradeAllowed)
    this.ContractSize.set(fbeValue.ContractSize)
    this.MinTradeAmount.set(fbeValue.MinTradeAmount)
    this.MaxTradeAmount.set(fbeValue.MaxTradeAmount)
    this.TradeAmountStep.set(fbeValue.TradeAmountStep)
    this.DefaultExpiration.set(fbeValue.DefaultExpiration)
    this.IsSwapEnabled.set(fbeValue.IsSwapEnabled)
    this.SwapType.set(fbeValue.SwapType)
    this.SwapLong.set(fbeValue.SwapLong)
    this.SwapShort.set(fbeValue.SwapShort)
    this.SwapTripleDay.set(fbeValue.SwapTripleDay)
    this.MarketOrderMarginRate.set(fbeValue.MarketOrderMarginRate)
    this.LimitOrderMarginRate.set(fbeValue.LimitOrderMarginRate)
    this.StopOrderMarginRate.set(fbeValue.StopOrderMarginRate)
    this.TradingMode.set(fbeValue.TradingMode)
    this.StopsLevel.set(fbeValue.StopsLevel)
    this.IsFavorite.set(fbeValue.IsFavorite)
    this.SortOrderFavorite.set(fbeValue.SortOrderFavorite)
    this.Postfix.set(fbeValue.Postfix)
    this.InitialMargin.set(fbeValue.InitialMargin)
    this.HedgedMargin.set(fbeValue.HedgedMargin)
    this.SwapDaysInYear.set(fbeValue.SwapDaysInYear)
  }
}

export { FieldModelSymbolCreateRequest };

/**
 * Fast Binary Encoding SymbolCreateRequest model
 */
class SymbolCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSymbolCreateRequest(this.buffer, 4)
  }

  /**
   * Get the SymbolCreateRequest model
   * @this {!SymbolCreateRequestModel}
   * @returns {!FieldModelSymbolCreateRequest} model SymbolCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SymbolCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SymbolCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SymbolCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SymbolCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSymbolCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SymbolCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SymbolCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SymbolCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SymbolCreateRequestModel}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SymbolCreateRequestModel}
   * @param {!SymbolCreateRequest} value SymbolCreateRequest value, defaults is new SymbolCreateRequest()
   * @return {!object} Deserialized SymbolCreateRequest value and its size
   */
  deserialize (value = new SymbolCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SymbolCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SymbolCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SymbolCreateRequestModel}
   * @param {!number} prev Previous SymbolCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SymbolCreateRequestModel };
SymbolCreateRequest.__has_response = true;
SymbolCreateRequest.__response_class = SymbolCreateResponse;
