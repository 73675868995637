
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ClientLoginType} from './ClientLoginType';
import {FieldModelClientLoginType} from './ClientLoginType';

/**
 * LoginInfoRecord struct
 */
class LoginInfoRecord {
  /**
   * Initialize struct
   * @param {!number=} recordId
   * @param {!Date=} loginTimestamp
   * @param {!ClientLoginType=} loginType
   * @param {!string=} device
   * @param {!string=} deviceModel
   * @param {!string=} browser
   * @param {!string=} oS
   * @param {!string=} oSVersionMajor
   * @param {!string=} oSVersionMinor
   * @param {!string=} oSVersionBuild
   * @param {!string=} oSVersionPatch
   * @param {!string=} clientVersionMajor
   * @param {!string=} clientVersionMinor
   * @param {!string=} clientVersionBuild
   * @param {!string=} clientVersionPatch
   * @param {!string=} clientBuildId
   * @param {!string=} uniqueId
   * @param {!string=} iPAddress
   * @constructor
   */
  constructor (recordId = new UInt64(0, 0), loginTimestamp = new Date(0), loginType = new ClientLoginType(), device = '', deviceModel = '', browser = '', oS = '', oSVersionMajor = '', oSVersionMinor = '', oSVersionBuild = '', oSVersionPatch = '', clientVersionMajor = '', clientVersionMinor = '', clientVersionBuild = '', clientVersionPatch = '', clientBuildId = '', uniqueId = '', iPAddress = '') {
    this.RecordId = recordId
    this.LoginTimestamp = loginTimestamp
    this.LoginType = loginType
    this.Device = device
    this.DeviceModel = deviceModel
    this.Browser = browser
    this.OS = oS
    this.OSVersionMajor = oSVersionMajor
    this.OSVersionMinor = oSVersionMinor
    this.OSVersionBuild = oSVersionBuild
    this.OSVersionPatch = oSVersionPatch
    this.ClientVersionMajor = clientVersionMajor
    this.ClientVersionMinor = clientVersionMinor
    this.ClientVersionBuild = clientVersionBuild
    this.ClientVersionPatch = clientVersionPatch
    this.ClientBuildId = clientBuildId
    this.UniqueId = uniqueId
    this.IPAddress = iPAddress
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LoginInfoRecord}
   * @param {!LoginInfoRecord} other Other struct
   * @returns {!LoginInfoRecord} This struct
   */
  copy (other) {
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    if (other.LoginTimestamp != null) {
      if (other.LoginTimestamp instanceof Date) {
        this.LoginTimestamp = new Date(other.LoginTimestamp.getTime())
      } else {
        this.LoginTimestamp = new Date(Math.round(other.LoginTimestamp / 1000000))
      }
    } else {
      this.LoginTimestamp = null
    }
    if (other.LoginType != null) {
      this.LoginType = ClientLoginType.fromObject(other.LoginType)
    } else {
      this.LoginType = null
    }
    if (other.Device != null) {
      this.Device = other.Device
    } else {
      this.Device = null
    }
    if (other.DeviceModel != null) {
      this.DeviceModel = other.DeviceModel
    } else {
      this.DeviceModel = null
    }
    if (other.Browser != null) {
      this.Browser = other.Browser
    } else {
      this.Browser = null
    }
    if (other.OS != null) {
      this.OS = other.OS
    } else {
      this.OS = null
    }
    if (other.OSVersionMajor != null) {
      this.OSVersionMajor = other.OSVersionMajor
    } else {
      this.OSVersionMajor = null
    }
    if (other.OSVersionMinor != null) {
      this.OSVersionMinor = other.OSVersionMinor
    } else {
      this.OSVersionMinor = null
    }
    if (other.OSVersionBuild != null) {
      this.OSVersionBuild = other.OSVersionBuild
    } else {
      this.OSVersionBuild = null
    }
    if (other.OSVersionPatch != null) {
      this.OSVersionPatch = other.OSVersionPatch
    } else {
      this.OSVersionPatch = null
    }
    if (other.ClientVersionMajor != null) {
      this.ClientVersionMajor = other.ClientVersionMajor
    } else {
      this.ClientVersionMajor = null
    }
    if (other.ClientVersionMinor != null) {
      this.ClientVersionMinor = other.ClientVersionMinor
    } else {
      this.ClientVersionMinor = null
    }
    if (other.ClientVersionBuild != null) {
      this.ClientVersionBuild = other.ClientVersionBuild
    } else {
      this.ClientVersionBuild = null
    }
    if (other.ClientVersionPatch != null) {
      this.ClientVersionPatch = other.ClientVersionPatch
    } else {
      this.ClientVersionPatch = null
    }
    if (other.ClientBuildId != null) {
      this.ClientBuildId = other.ClientBuildId
    } else {
      this.ClientBuildId = null
    }
    if (other.UniqueId != null) {
      this.UniqueId = other.UniqueId
    } else {
      this.UniqueId = null
    }
    if (other.IPAddress != null) {
      this.IPAddress = other.IPAddress
    } else {
      this.IPAddress = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LoginInfoRecord}
   * @returns {!LoginInfoRecord} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LoginInfoRecordModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LoginInfoRecordModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LoginInfoRecord}
   * @param {!LoginInfoRecord} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LoginInfoRecord)) {
      throw new TypeError('Instance of LoginInfoRecord is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LoginInfoRecord}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null),
      LoginTimestamp: ((this.LoginTimestamp != null) ? (this.LoginTimestamp.getTime() * 1000000) : null),
      LoginType: ((this.LoginType != null) ? this.LoginType : null),
      Device: ((this.Device != null) ? this.Device : null),
      DeviceModel: ((this.DeviceModel != null) ? this.DeviceModel : null),
      Browser: ((this.Browser != null) ? this.Browser : null),
      OS: ((this.OS != null) ? this.OS : null),
      OSVersionMajor: ((this.OSVersionMajor != null) ? this.OSVersionMajor : null),
      OSVersionMinor: ((this.OSVersionMinor != null) ? this.OSVersionMinor : null),
      OSVersionBuild: ((this.OSVersionBuild != null) ? this.OSVersionBuild : null),
      OSVersionPatch: ((this.OSVersionPatch != null) ? this.OSVersionPatch : null),
      ClientVersionMajor: ((this.ClientVersionMajor != null) ? this.ClientVersionMajor : null),
      ClientVersionMinor: ((this.ClientVersionMinor != null) ? this.ClientVersionMinor : null),
      ClientVersionBuild: ((this.ClientVersionBuild != null) ? this.ClientVersionBuild : null),
      ClientVersionPatch: ((this.ClientVersionPatch != null) ? this.ClientVersionPatch : null),
      ClientBuildId: ((this.ClientBuildId != null) ? this.ClientBuildId : null),
      UniqueId: ((this.UniqueId != null) ? this.UniqueId : null),
      IPAddress: ((this.IPAddress != null) ? this.IPAddress : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LoginInfoRecord.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LoginInfoRecord} other Object value
   * @returns {!LoginInfoRecord} Created struct
   */
  static fromObject (other) {
    return new LoginInfoRecord().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LoginInfoRecord}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LoginInfoRecord.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LoginInfoRecord}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5172
  }
}

export { LoginInfoRecord };

/**
 * Fast Binary Encoding LoginInfoRecord field model
 */
class FieldModelLoginInfoRecord extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.RecordId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.LoginTimestamp = new fbe.FieldModelTimestamp(buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize)
    this.LoginType = new FieldModelClientLoginType(buffer, this.LoginTimestamp.fbeOffset + this.LoginTimestamp.fbeSize)
    this.Device = new fbe.FieldModelString(buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize)
    this.DeviceModel = new fbe.FieldModelString(buffer, this.Device.fbeOffset + this.Device.fbeSize)
    this.Browser = new fbe.FieldModelString(buffer, this.DeviceModel.fbeOffset + this.DeviceModel.fbeSize)
    this.OS = new fbe.FieldModelString(buffer, this.Browser.fbeOffset + this.Browser.fbeSize)
    this.OSVersionMajor = new fbe.FieldModelString(buffer, this.OS.fbeOffset + this.OS.fbeSize)
    this.OSVersionMinor = new fbe.FieldModelString(buffer, this.OSVersionMajor.fbeOffset + this.OSVersionMajor.fbeSize)
    this.OSVersionBuild = new fbe.FieldModelString(buffer, this.OSVersionMinor.fbeOffset + this.OSVersionMinor.fbeSize)
    this.OSVersionPatch = new fbe.FieldModelString(buffer, this.OSVersionBuild.fbeOffset + this.OSVersionBuild.fbeSize)
    this.ClientVersionMajor = new fbe.FieldModelString(buffer, this.OSVersionPatch.fbeOffset + this.OSVersionPatch.fbeSize)
    this.ClientVersionMinor = new fbe.FieldModelString(buffer, this.ClientVersionMajor.fbeOffset + this.ClientVersionMajor.fbeSize)
    this.ClientVersionBuild = new fbe.FieldModelString(buffer, this.ClientVersionMinor.fbeOffset + this.ClientVersionMinor.fbeSize)
    this.ClientVersionPatch = new fbe.FieldModelString(buffer, this.ClientVersionBuild.fbeOffset + this.ClientVersionBuild.fbeSize)
    this.ClientBuildId = new fbe.FieldModelString(buffer, this.ClientVersionPatch.fbeOffset + this.ClientVersionPatch.fbeSize)
    this.UniqueId = new fbe.FieldModelString(buffer, this.ClientBuildId.fbeOffset + this.ClientBuildId.fbeSize)
    this.IPAddress = new fbe.FieldModelString(buffer, this.UniqueId.fbeOffset + this.UniqueId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.RecordId.fbeSize + this.LoginTimestamp.fbeSize + this.LoginType.fbeSize + this.Device.fbeSize + this.DeviceModel.fbeSize + this.Browser.fbeSize + this.OS.fbeSize + this.OSVersionMajor.fbeSize + this.OSVersionMinor.fbeSize + this.OSVersionBuild.fbeSize + this.OSVersionPatch.fbeSize + this.ClientVersionMajor.fbeSize + this.ClientVersionMinor.fbeSize + this.ClientVersionBuild.fbeSize + this.ClientVersionPatch.fbeSize + this.ClientBuildId.fbeSize + this.UniqueId.fbeSize + this.IPAddress.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.RecordId.fbeExtra + this.LoginTimestamp.fbeExtra + this.LoginType.fbeExtra + this.Device.fbeExtra + this.DeviceModel.fbeExtra + this.Browser.fbeExtra + this.OS.fbeExtra + this.OSVersionMajor.fbeExtra + this.OSVersionMinor.fbeExtra + this.OSVersionBuild.fbeExtra + this.OSVersionPatch.fbeExtra + this.ClientVersionMajor.fbeExtra + this.ClientVersionMinor.fbeExtra + this.ClientVersionBuild.fbeExtra + this.ClientVersionPatch.fbeExtra + this.ClientBuildId.fbeExtra + this.UniqueId.fbeExtra + this.IPAddress.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLoginInfoRecord.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5172
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLoginInfoRecord}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLoginInfoRecord}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.LoginTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginTimestamp.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Device.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.DeviceModel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DeviceModel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DeviceModel.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Browser.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OS.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMajor.fbeSize

    if ((fbeCurrentSize + this.OSVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMinor.fbeSize

    if ((fbeCurrentSize + this.OSVersionBuild.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionBuild.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionBuild.fbeSize

    if ((fbeCurrentSize + this.OSVersionPatch.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionPatch.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMinor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionBuild.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionBuild.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionBuild.fbeSize

    if ((fbeCurrentSize + this.ClientVersionPatch.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionPatch.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientBuildId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientBuildId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientBuildId.fbeSize

    if ((fbeCurrentSize + this.UniqueId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UniqueId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IPAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLoginInfoRecord}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLoginInfoRecord}
   * @param {!LoginInfoRecord} fbeValue Default value, defaults is new LoginInfoRecord()
   * @returns {!LoginInfoRecord} LoginInfoRecord value
   */
  get (fbeValue = new LoginInfoRecord()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLoginInfoRecord}
   * @param {!LoginInfoRecord} fbeValue LoginInfoRecord value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.LoginTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.LoginTimestamp = this.LoginTimestamp.get()
    } else {
      fbeValue.LoginTimestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginTimestamp.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) <= fbeStructSize) {
      fbeValue.LoginType = this.LoginType.get()
    } else {
      fbeValue.LoginType = new ClientLoginType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) <= fbeStructSize) {
      fbeValue.Device = this.Device.get()
    } else {
      fbeValue.Device = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.DeviceModel.fbeSize) <= fbeStructSize) {
      fbeValue.DeviceModel = this.DeviceModel.get()
    } else {
      fbeValue.DeviceModel = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DeviceModel.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) <= fbeStructSize) {
      fbeValue.Browser = this.Browser.get()
    } else {
      fbeValue.Browser = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) <= fbeStructSize) {
      fbeValue.OS = this.OS.get()
    } else {
      fbeValue.OS = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionMajor = this.OSVersionMajor.get()
    } else {
      fbeValue.OSVersionMajor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMajor.fbeSize

    if ((fbeCurrentSize + this.OSVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionMinor = this.OSVersionMinor.get()
    } else {
      fbeValue.OSVersionMinor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMinor.fbeSize

    if ((fbeCurrentSize + this.OSVersionBuild.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionBuild = this.OSVersionBuild.get()
    } else {
      fbeValue.OSVersionBuild = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionBuild.fbeSize

    if ((fbeCurrentSize + this.OSVersionPatch.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionPatch = this.OSVersionPatch.get()
    } else {
      fbeValue.OSVersionPatch = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionMajor = this.ClientVersionMajor.get()
    } else {
      fbeValue.ClientVersionMajor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionMinor = this.ClientVersionMinor.get()
    } else {
      fbeValue.ClientVersionMinor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMinor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionBuild.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionBuild = this.ClientVersionBuild.get()
    } else {
      fbeValue.ClientVersionBuild = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionBuild.fbeSize

    if ((fbeCurrentSize + this.ClientVersionPatch.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionPatch = this.ClientVersionPatch.get()
    } else {
      fbeValue.ClientVersionPatch = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientBuildId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientBuildId = this.ClientBuildId.get()
    } else {
      fbeValue.ClientBuildId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientBuildId.fbeSize

    if ((fbeCurrentSize + this.UniqueId.fbeSize) <= fbeStructSize) {
      fbeValue.UniqueId = this.UniqueId.get()
    } else {
      fbeValue.UniqueId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) <= fbeStructSize) {
      fbeValue.IPAddress = this.IPAddress.get()
    } else {
      fbeValue.IPAddress = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLoginInfoRecord}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLoginInfoRecord}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLoginInfoRecord}
   * @param {!LoginInfoRecord} fbeValue LoginInfoRecord value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLoginInfoRecord}
   * @param {LoginInfoRecord} fbeValue LoginInfoRecord value
   */
  setFields (fbeValue) {
    this.RecordId.set(fbeValue.RecordId)
    this.LoginTimestamp.set(fbeValue.LoginTimestamp)
    this.LoginType.set(fbeValue.LoginType)
    this.Device.set(fbeValue.Device)
    this.DeviceModel.set(fbeValue.DeviceModel)
    this.Browser.set(fbeValue.Browser)
    this.OS.set(fbeValue.OS)
    this.OSVersionMajor.set(fbeValue.OSVersionMajor)
    this.OSVersionMinor.set(fbeValue.OSVersionMinor)
    this.OSVersionBuild.set(fbeValue.OSVersionBuild)
    this.OSVersionPatch.set(fbeValue.OSVersionPatch)
    this.ClientVersionMajor.set(fbeValue.ClientVersionMajor)
    this.ClientVersionMinor.set(fbeValue.ClientVersionMinor)
    this.ClientVersionBuild.set(fbeValue.ClientVersionBuild)
    this.ClientVersionPatch.set(fbeValue.ClientVersionPatch)
    this.ClientBuildId.set(fbeValue.ClientBuildId)
    this.UniqueId.set(fbeValue.UniqueId)
    this.IPAddress.set(fbeValue.IPAddress)
  }
}

export { FieldModelLoginInfoRecord };

/**
 * Fast Binary Encoding LoginInfoRecord model
 */
class LoginInfoRecordModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLoginInfoRecord(this.buffer, 4)
  }

  /**
   * Get the LoginInfoRecord model
   * @this {!LoginInfoRecordModel}
   * @returns {!FieldModelLoginInfoRecord} model LoginInfoRecord model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LoginInfoRecordModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LoginInfoRecordModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LoginInfoRecordModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LoginInfoRecordModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLoginInfoRecord.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LoginInfoRecordModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LoginInfoRecordModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LoginInfoRecordModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LoginInfoRecordModel}
   * @param {!LoginInfoRecord} value LoginInfoRecord value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LoginInfoRecordModel}
   * @param {!LoginInfoRecord} value LoginInfoRecord value, defaults is new LoginInfoRecord()
   * @return {!object} Deserialized LoginInfoRecord value and its size
   */
  deserialize (value = new LoginInfoRecord()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LoginInfoRecord(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LoginInfoRecord(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LoginInfoRecordModel}
   * @param {!number} prev Previous LoginInfoRecord model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LoginInfoRecordModel };
