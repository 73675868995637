import React, { FC } from 'react';
import styles from './ConnectionSource.module.scss';
import { useReaction } from '../../App/pages/utils/commonUtils';
import { authStore } from '@store/auth';
import { keyMap, t } from '@localization';

export const ConnectionSource: FC = () => {
    const [wsUrl] = useReaction<string>(() => authStore.wsUrl);

    return <div className={styles.connectionSource}>
        <span className={styles.label}>{`${t(keyMap.header.source)}: `}</span>
        <span className={styles.source}>{wsUrl}</span></div>;
};
