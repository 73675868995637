import { ChartBarsImportInfo } from '@api/fbe/history/ChartBarsImportInfo';

export type ChartBarInfo = {
    key?: number;
    Symbol: string;
    PeriodType: number;
    OpenTime: Date;
    OpenPrice: number;
    HighPrice: number;
    LowPrice: number;
    ClosePrice: number;
    Volume: number;
};

export type ChartBarsRequestParams = {
    Symbol: string;
    Period: number;
    TimeFrom: Date | null;
    TimeTo: Date | null;
};

export type ChartBarsImportRequestParams = {
    TimeFrom: Date | null;
    TimeTo: Date | null;
    SymbolPatterns: string[];
    SymbolMapId: string | null;
    ServerType: number;
    Limit: number | null;
    Address: string;
    ManagerLogin: number | null;
    ManagerPassword: string;
};

export type ImportBarsInfo = {
    key?: number;
    Symbol: string;
    M1BarsReceived: number;
    M1BarsImported: number;
    M5BarsImported: number;
    M15BarsImported: number;
    M30BarsImported: number;
    H1BarsImported: number;
    H4BarsImported: number;
    D1BarsImported: number;
    W1BarsImported: number;
    MNBarsImported: number;
};

export type ImportBarsResponseDetails = {
    ImportSymbolsSucceed: Set<string>;
    ImportSymbolsFailed: Set<string>;
}

export const convertChartBarInfoToModel = (data: ChartBarsImportInfo): ImportBarsInfo => ({
    M1BarsReceived: +data.M1BarsReceived.toNumber(),
    M1BarsImported: +data.M1BarsImported.toNumber(),
    M5BarsImported: +data.M5BarsImported.toNumber(),
    M15BarsImported: +data.M15BarsImported.toNumber(),
    M30BarsImported: +data.M30BarsImported.toNumber(),
    H1BarsImported: +data.H1BarsImported.toNumber(),
    H4BarsImported: +data.H4BarsImported.toNumber(),
    D1BarsImported: +data.D1BarsImported.toNumber(),
    W1BarsImported: +data.W1BarsImported.toNumber(),
    MNBarsImported: +data.MNBarsImported.toNumber(),
    Symbol: data.Symbol,
});
