
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderOpenResponse} from './OrderOpenResponse';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';
import {OrderTimeInForce} from '../core/OrderTimeInForce';
import {FieldModelOrderTimeInForce} from '../core/OrderTimeInForce';
import {OrderRequestClientSourceType} from './OrderRequestClientSourceType';
import {FieldModelOrderRequestClientSourceType} from './OrderRequestClientSourceType';

/**
 * OrderOpenRequest struct
 */
class OrderOpenRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {string=} clientOrderId
   * @param {!string=} symbol
   * @param {!OrderSide=} orderSide
   * @param {!OrderType=} orderType
   * @param {OrderTimeInForce=} orderTimeInForce
   * @param {number=} price
   * @param {number=} stopPrice
   * @param {number=} takeProfit
   * @param {number=} stopLoss
   * @param {!number=} amount
   * @param {Date=} expiration
   * @param {!string=} comment
   * @param {number=} magic
   * @param {!OrderRequestClientSourceType=} orderRequestClientSourceType
   * @param {boolean=} isCreatedViaQuickTrading
   * @param {boolean=} isCreatedViaFastTrader
   * @constructor
   */
  constructor (parent = new Request(), clientOrderId = null, symbol = '', orderSide = new OrderSide(), orderType = new OrderType(), orderTimeInForce = null, price = null, stopPrice = null, takeProfit = null, stopLoss = null, amount = 0.0, expiration = null, comment = '', magic = null, orderRequestClientSourceType = new OrderRequestClientSourceType(), isCreatedViaQuickTrading = null, isCreatedViaFastTrader = null) {
    super()
    super.copy(parent)
    this.ClientOrderId = clientOrderId
    this.Symbol = symbol
    this.OrderSide = orderSide
    this.OrderType = orderType
    this.OrderTimeInForce = orderTimeInForce
    this.Price = price
    this.StopPrice = stopPrice
    this.TakeProfit = takeProfit
    this.StopLoss = stopLoss
    this.Amount = amount
    this.Expiration = expiration
    this.Comment = comment
    this.Magic = magic
    this.OrderRequestClientSourceType = orderRequestClientSourceType
    this.IsCreatedViaQuickTrading = isCreatedViaQuickTrading
    this.IsCreatedViaFastTrader = isCreatedViaFastTrader
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderOpenRequest}
   * @param {!OrderOpenRequest} other Other struct
   * @returns {!OrderOpenRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.Symbol != null) {
      this.Symbol = other.Symbol
    } else {
      this.Symbol = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = OrderSide.fromObject(other.OrderSide)
    } else {
      this.OrderSide = null
    }
    if (other.OrderType != null) {
      this.OrderType = OrderType.fromObject(other.OrderType)
    } else {
      this.OrderType = null
    }
    if (other.OrderTimeInForce != null) {
      this.OrderTimeInForce = OrderTimeInForce.fromObject(other.OrderTimeInForce)
    } else {
      this.OrderTimeInForce = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    if (other.StopPrice != null) {
      this.StopPrice = other.StopPrice
    } else {
      this.StopPrice = null
    }
    if (other.TakeProfit != null) {
      this.TakeProfit = other.TakeProfit
    } else {
      this.TakeProfit = null
    }
    if (other.StopLoss != null) {
      this.StopLoss = other.StopLoss
    } else {
      this.StopLoss = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.Expiration != null) {
      if (other.Expiration instanceof Date) {
        this.Expiration = new Date(other.Expiration.getTime())
      } else {
        this.Expiration = new Date(Math.round(other.Expiration / 1000000))
      }
    } else {
      this.Expiration = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Magic != null) {
      this.Magic = other.Magic
    } else {
      this.Magic = null
    }
    if (other.OrderRequestClientSourceType != null) {
      this.OrderRequestClientSourceType = OrderRequestClientSourceType.fromObject(other.OrderRequestClientSourceType)
    } else {
      this.OrderRequestClientSourceType = null
    }
    if (other.IsCreatedViaQuickTrading != null) {
      this.IsCreatedViaQuickTrading = other.IsCreatedViaQuickTrading
    } else {
      this.IsCreatedViaQuickTrading = null
    }
    if (other.IsCreatedViaFastTrader != null) {
      this.IsCreatedViaFastTrader = other.IsCreatedViaFastTrader
    } else {
      this.IsCreatedViaFastTrader = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderOpenRequest}
   * @returns {!OrderOpenRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderOpenRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderOpenRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderOpenRequest}
   * @param {!OrderOpenRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderOpenRequest)) {
      throw new TypeError('Instance of OrderOpenRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderOpenRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      Symbol: ((this.Symbol != null) ? this.Symbol : null),
      OrderSide: ((this.OrderSide != null) ? this.OrderSide : null),
      OrderType: ((this.OrderType != null) ? this.OrderType : null),
      OrderTimeInForce: ((this.OrderTimeInForce != null) ? this.OrderTimeInForce : null),
      Price: ((this.Price != null) ? this.Price : null),
      StopPrice: ((this.StopPrice != null) ? this.StopPrice : null),
      TakeProfit: ((this.TakeProfit != null) ? this.TakeProfit : null),
      StopLoss: ((this.StopLoss != null) ? this.StopLoss : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      Expiration: ((this.Expiration != null) ? (this.Expiration.getTime() * 1000000) : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Magic: ((this.Magic != null) ? this.Magic : null),
      OrderRequestClientSourceType: ((this.OrderRequestClientSourceType != null) ? this.OrderRequestClientSourceType : null),
      IsCreatedViaQuickTrading: ((this.IsCreatedViaQuickTrading != null) ? this.IsCreatedViaQuickTrading : null),
      IsCreatedViaFastTrader: ((this.IsCreatedViaFastTrader != null) ? this.IsCreatedViaFastTrader : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderOpenRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderOpenRequest} other Object value
   * @returns {!OrderOpenRequest} Created struct
   */
  static fromObject (other) {
    return new OrderOpenRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderOpenRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderOpenRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderOpenRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5088
  }
}

export { OrderOpenRequest };

/**
 * Fast Binary Encoding OrderOpenRequest field model
 */
class FieldModelOrderOpenRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Symbol = new fbe.FieldModelString(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.OrderSide = new FieldModelOrderSide(buffer, this.Symbol.fbeOffset + this.Symbol.fbeSize)
    this.OrderType = new FieldModelOrderType(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.OrderTimeInForce = new fbe.FieldModelOptional(new FieldModelOrderTimeInForce(buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize), buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize)
    this.Price = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize), buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize)
    this.StopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Price.fbeOffset + this.Price.fbeSize), buffer, this.Price.fbeOffset + this.Price.fbeSize)
    this.TakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize), buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize)
    this.StopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize), buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize)
    this.Amount = new fbe.FieldModelDouble(buffer, this.StopLoss.fbeOffset + this.StopLoss.fbeSize)
    this.Expiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Amount.fbeOffset + this.Amount.fbeSize), buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Expiration.fbeOffset + this.Expiration.fbeSize)
    this.Magic = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.Comment.fbeOffset + this.Comment.fbeSize), buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.OrderRequestClientSourceType = new FieldModelOrderRequestClientSourceType(buffer, this.Magic.fbeOffset + this.Magic.fbeSize)
    this.IsCreatedViaQuickTrading = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.OrderRequestClientSourceType.fbeOffset + this.OrderRequestClientSourceType.fbeSize), buffer, this.OrderRequestClientSourceType.fbeOffset + this.OrderRequestClientSourceType.fbeSize)
    this.IsCreatedViaFastTrader = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsCreatedViaQuickTrading.fbeOffset + this.IsCreatedViaQuickTrading.fbeSize), buffer, this.IsCreatedViaQuickTrading.fbeOffset + this.IsCreatedViaQuickTrading.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.ClientOrderId.fbeSize + this.Symbol.fbeSize + this.OrderSide.fbeSize + this.OrderType.fbeSize + this.OrderTimeInForce.fbeSize + this.Price.fbeSize + this.StopPrice.fbeSize + this.TakeProfit.fbeSize + this.StopLoss.fbeSize + this.Amount.fbeSize + this.Expiration.fbeSize + this.Comment.fbeSize + this.Magic.fbeSize + this.OrderRequestClientSourceType.fbeSize + this.IsCreatedViaQuickTrading.fbeSize + this.IsCreatedViaFastTrader.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.ClientOrderId.fbeExtra + this.Symbol.fbeExtra + this.OrderSide.fbeExtra + this.OrderType.fbeExtra + this.OrderTimeInForce.fbeExtra + this.Price.fbeExtra + this.StopPrice.fbeExtra + this.TakeProfit.fbeExtra + this.StopLoss.fbeExtra + this.Amount.fbeExtra + this.Expiration.fbeExtra + this.Comment.fbeExtra + this.Magic.fbeExtra + this.OrderRequestClientSourceType.fbeExtra + this.IsCreatedViaQuickTrading.fbeExtra + this.IsCreatedViaFastTrader.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderOpenRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5088
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderOpenRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderOpenRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.Symbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderTimeInForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Expiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Magic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.OrderRequestClientSourceType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderRequestClientSourceType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderRequestClientSourceType.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaQuickTrading.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsCreatedViaQuickTrading.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaQuickTrading.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaFastTrader.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsCreatedViaFastTrader.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaFastTrader.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderOpenRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderOpenRequest}
   * @param {!OrderOpenRequest} fbeValue Default value, defaults is new OrderOpenRequest()
   * @returns {!OrderOpenRequest} OrderOpenRequest value
   */
  get (fbeValue = new OrderOpenRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderOpenRequest}
   * @param {!OrderOpenRequest} fbeValue OrderOpenRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.Symbol.fbeSize) <= fbeStructSize) {
      fbeValue.Symbol = this.Symbol.get()
    } else {
      fbeValue.Symbol = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSide = this.OrderSide.get()
    } else {
      fbeValue.OrderSide = new OrderSide()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderType = this.OrderType.get()
    } else {
      fbeValue.OrderType = new OrderType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) <= fbeStructSize) {
      fbeValue.OrderTimeInForce = this.OrderTimeInForce.get()
    } else {
      fbeValue.OrderTimeInForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.StopPrice = this.StopPrice.get()
    } else {
      fbeValue.StopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TakeProfit = this.TakeProfit.get()
    } else {
      fbeValue.TakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.StopLoss = this.StopLoss.get()
    } else {
      fbeValue.StopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) <= fbeStructSize) {
      fbeValue.Expiration = this.Expiration.get()
    } else {
      fbeValue.Expiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) <= fbeStructSize) {
      fbeValue.Magic = this.Magic.get()
    } else {
      fbeValue.Magic = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.OrderRequestClientSourceType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderRequestClientSourceType = this.OrderRequestClientSourceType.get()
    } else {
      fbeValue.OrderRequestClientSourceType = new OrderRequestClientSourceType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderRequestClientSourceType.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaQuickTrading.fbeSize) <= fbeStructSize) {
      fbeValue.IsCreatedViaQuickTrading = this.IsCreatedViaQuickTrading.get()
    } else {
      fbeValue.IsCreatedViaQuickTrading = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaQuickTrading.fbeSize

    if ((fbeCurrentSize + this.IsCreatedViaFastTrader.fbeSize) <= fbeStructSize) {
      fbeValue.IsCreatedViaFastTrader = this.IsCreatedViaFastTrader.get()
    } else {
      fbeValue.IsCreatedViaFastTrader = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsCreatedViaFastTrader.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderOpenRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderOpenRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderOpenRequest}
   * @param {!OrderOpenRequest} fbeValue OrderOpenRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderOpenRequest}
   * @param {OrderOpenRequest} fbeValue OrderOpenRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.Symbol.set(fbeValue.Symbol)
    this.OrderSide.set(fbeValue.OrderSide)
    this.OrderType.set(fbeValue.OrderType)
    this.OrderTimeInForce.set(fbeValue.OrderTimeInForce)
    this.Price.set(fbeValue.Price)
    this.StopPrice.set(fbeValue.StopPrice)
    this.TakeProfit.set(fbeValue.TakeProfit)
    this.StopLoss.set(fbeValue.StopLoss)
    this.Amount.set(fbeValue.Amount)
    this.Expiration.set(fbeValue.Expiration)
    this.Comment.set(fbeValue.Comment)
    this.Magic.set(fbeValue.Magic)
    this.OrderRequestClientSourceType.set(fbeValue.OrderRequestClientSourceType)
    this.IsCreatedViaQuickTrading.set(fbeValue.IsCreatedViaQuickTrading)
    this.IsCreatedViaFastTrader.set(fbeValue.IsCreatedViaFastTrader)
  }
}

export { FieldModelOrderOpenRequest };

/**
 * Fast Binary Encoding OrderOpenRequest model
 */
class OrderOpenRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderOpenRequest(this.buffer, 4)
  }

  /**
   * Get the OrderOpenRequest model
   * @this {!OrderOpenRequestModel}
   * @returns {!FieldModelOrderOpenRequest} model OrderOpenRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderOpenRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderOpenRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderOpenRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderOpenRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderOpenRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderOpenRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderOpenRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderOpenRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderOpenRequestModel}
   * @param {!OrderOpenRequest} value OrderOpenRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderOpenRequestModel}
   * @param {!OrderOpenRequest} value OrderOpenRequest value, defaults is new OrderOpenRequest()
   * @return {!object} Deserialized OrderOpenRequest value and its size
   */
  deserialize (value = new OrderOpenRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderOpenRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderOpenRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderOpenRequestModel}
   * @param {!number} prev Previous OrderOpenRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderOpenRequestModel };
OrderOpenRequest.__has_response = true;
OrderOpenRequest.__response_class = OrderOpenResponse;
