
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Account} from './Account';
import {FieldModelAccount} from './Account';
import {AccountMargin} from './AccountMargin';
import {FieldModelAccountMargin} from './AccountMargin';
import {AccountAssets} from './AccountAssets';
import {FieldModelAccountAssets} from './AccountAssets';

/**
 * Deprecated001 struct
 */
class Deprecated001 {
  /**
   * Initialize struct
   * @param {!number=} recordId
   * @param {!Date=} timestamp
   * @param {!Account=} account
   * @param {AccountMargin=} margin
   * @param {AccountAssets=} assets
   * @param {!UUID=} platformId
   * @constructor
   */
  constructor (recordId = new UInt64(0, 0), timestamp = new Date(0), account = new Account(), margin = null, assets = null, platformId = new UUID()) {
    this.RecordId = recordId
    this.Timestamp = timestamp
    this.Account = account
    this.Margin = margin
    this.Assets = assets
    this.PlatformId = platformId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Deprecated001}
   * @param {!Deprecated001} other Other struct
   * @returns {!Deprecated001} This struct
   */
  copy (other) {
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    if (other.Timestamp != null) {
      if (other.Timestamp instanceof Date) {
        this.Timestamp = new Date(other.Timestamp.getTime())
      } else {
        this.Timestamp = new Date(Math.round(other.Timestamp / 1000000))
      }
    } else {
      this.Timestamp = null
    }
    if (other.Account != null) {
      this.Account = Account.fromObject(other.Account)
    } else {
      this.Account = null
    }
    if (other.Margin != null) {
      this.Margin = AccountMargin.fromObject(other.Margin)
    } else {
      this.Margin = null
    }
    if (other.Assets != null) {
      this.Assets = AccountAssets.fromObject(other.Assets)
    } else {
      this.Assets = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Deprecated001}
   * @returns {!Deprecated001} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Deprecated001Model(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Deprecated001Model(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Deprecated001}
   * @param {!Deprecated001} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Deprecated001)) {
      throw new TypeError('Instance of Deprecated001 is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Deprecated001}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null),
      Timestamp: ((this.Timestamp != null) ? (this.Timestamp.getTime() * 1000000) : null),
      Account: ((this.Account != null) ? this.Account : null),
      Margin: ((this.Margin != null) ? this.Margin : null),
      Assets: ((this.Assets != null) ? this.Assets : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Deprecated001.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Deprecated001} other Object value
   * @returns {!Deprecated001} Created struct
   */
  static fromObject (other) {
    return new Deprecated001().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Deprecated001}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Deprecated001.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Deprecated001}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 58
  }
}

export { Deprecated001 };

/**
 * Fast Binary Encoding Deprecated001 field model
 */
class FieldModelDeprecated001 extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.RecordId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Timestamp = new fbe.FieldModelTimestamp(buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize)
    this.Account = new FieldModelAccount(buffer, this.Timestamp.fbeOffset + this.Timestamp.fbeSize)
    this.Margin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.Account.fbeOffset + this.Account.fbeSize), buffer, this.Account.fbeOffset + this.Account.fbeSize)
    this.Assets = new fbe.FieldModelOptional(new FieldModelAccountAssets(buffer, this.Margin.fbeOffset + this.Margin.fbeSize), buffer, this.Margin.fbeOffset + this.Margin.fbeSize)
    this.PlatformId = new fbe.FieldModelUUID(buffer, this.Assets.fbeOffset + this.Assets.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.RecordId.fbeSize + this.Timestamp.fbeSize + this.Account.fbeSize + this.Margin.fbeSize + this.Assets.fbeSize + this.PlatformId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.RecordId.fbeExtra + this.Timestamp.fbeExtra + this.Account.fbeExtra + this.Margin.fbeExtra + this.Assets.fbeExtra + this.PlatformId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDeprecated001.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 58
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDeprecated001}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDeprecated001}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.Timestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Timestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Timestamp.fbeSize

    if ((fbeCurrentSize + this.Account.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Account.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Margin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Assets.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Assets.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Assets.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDeprecated001}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDeprecated001}
   * @param {!Deprecated001} fbeValue Default value, defaults is new Deprecated001()
   * @returns {!Deprecated001} Deprecated001 value
   */
  get (fbeValue = new Deprecated001()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDeprecated001}
   * @param {!Deprecated001} fbeValue Deprecated001 value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.Timestamp.fbeSize) <= fbeStructSize) {
      fbeValue.Timestamp = this.Timestamp.get()
    } else {
      fbeValue.Timestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Timestamp.fbeSize

    if ((fbeCurrentSize + this.Account.fbeSize) <= fbeStructSize) {
      fbeValue.Account = this.Account.get()
    } else {
      fbeValue.Account = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) <= fbeStructSize) {
      fbeValue.Margin = this.Margin.get()
    } else {
      fbeValue.Margin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Assets.fbeSize) <= fbeStructSize) {
      fbeValue.Assets = this.Assets.get()
    } else {
      fbeValue.Assets = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Assets.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDeprecated001}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDeprecated001}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDeprecated001}
   * @param {!Deprecated001} fbeValue Deprecated001 value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDeprecated001}
   * @param {Deprecated001} fbeValue Deprecated001 value
   */
  setFields (fbeValue) {
    this.RecordId.set(fbeValue.RecordId)
    this.Timestamp.set(fbeValue.Timestamp)
    this.Account.set(fbeValue.Account)
    this.Margin.set(fbeValue.Margin)
    this.Assets.set(fbeValue.Assets)
    this.PlatformId.set(fbeValue.PlatformId)
  }
}

export { FieldModelDeprecated001 };

/**
 * Fast Binary Encoding Deprecated001 model
 */
class Deprecated001Model extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDeprecated001(this.buffer, 4)
  }

  /**
   * Get the Deprecated001 model
   * @this {!Deprecated001Model}
   * @returns {!FieldModelDeprecated001} model Deprecated001 model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Deprecated001Model}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Deprecated001Model}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Deprecated001Model.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Deprecated001Model}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDeprecated001.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Deprecated001Model}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Deprecated001Model}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Deprecated001Model}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Deprecated001Model}
   * @param {!Deprecated001} value Deprecated001 value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Deprecated001Model}
   * @param {!Deprecated001} value Deprecated001 value, defaults is new Deprecated001()
   * @return {!object} Deserialized Deprecated001 value and its size
   */
  deserialize (value = new Deprecated001()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Deprecated001(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Deprecated001(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Deprecated001Model}
   * @param {!number} prev Previous Deprecated001 model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Deprecated001Model };
