
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ModuleType enum
 */
class ModuleType {
  /**
   * Initialize enum with a given value
   * @param {ModuleType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof ModuleType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!ModuleType}
   * @param {!ModuleType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ModuleType)) {
      throw new TypeError('Instance of ModuleType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!ModuleType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!ModuleType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === ModuleType.AccessServer.value) {
      return 'AccessServer'
    }
    if (this.value === ModuleType.ConfigServer.value) {
      return 'ConfigServer'
    }
    if (this.value === ModuleType.FeedServer.value) {
      return 'FeedServer'
    }
    if (this.value === ModuleType.HistoryServer.value) {
      return 'HistoryServer'
    }
    if (this.value === ModuleType.StorageServer.value) {
      return 'StorageServer'
    }
    if (this.value === ModuleType.TradeServer.value) {
      return 'TradeServer'
    }
    if (this.value === ModuleType.ExecutionServer.value) {
      return 'ExecutionServer'
    }
    if (this.value === ModuleType.BackupServer.value) {
      return 'BackupServer'
    }
    if (this.value === ModuleType.ExportServer.value) {
      return 'ExportServer'
    }
    if (this.value === ModuleType.DrawingStorageServer.value) {
      return 'DrawingStorageServer'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!ModuleType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!ModuleType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!ModuleType} Created enum
   */
  static fromObject (other) {
    return new ModuleType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
ModuleType.AccessServer = new ModuleType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
ModuleType.ConfigServer = new ModuleType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
ModuleType.FeedServer = new ModuleType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
ModuleType.HistoryServer = new ModuleType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
ModuleType.StorageServer = new ModuleType(0 + 4)
// noinspection PointlessArithmeticExpressionJS
ModuleType.TradeServer = new ModuleType(0 + 5)
// noinspection PointlessArithmeticExpressionJS
ModuleType.ExecutionServer = new ModuleType(0 + 6)
// noinspection PointlessArithmeticExpressionJS
ModuleType.BackupServer = new ModuleType(0 + 7)
// noinspection PointlessArithmeticExpressionJS
ModuleType.ExportServer = new ModuleType(0 + 8)
// noinspection PointlessArithmeticExpressionJS
ModuleType.DrawingStorageServer = new ModuleType(0 + 9)

export { ModuleType };

/**
 * Fast Binary Encoding ModuleType field model
 */
class FieldModelModuleType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelModuleType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelModuleType}
   * @param {ModuleType=} defaults Default value, defaults is new ModuleType()
   * @returns {!ModuleType} Result value
   */
  get (defaults = new ModuleType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new ModuleType(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelModuleType}
   * @param {!ModuleType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelModuleType };
