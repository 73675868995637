// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: core.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {MessageType} from './core/MessageType'
import {RequestDirection} from './core/RequestDirection'
import {RejectCode} from './core/RejectCode'
import {RejectInfoCode} from './core/RejectInfoCode'
import {AuthenticationType} from './core/AuthenticationType'
import {ProtocolType} from './core/ProtocolType'
import {TransportType} from './core/TransportType'
import {UpdateType} from './core/UpdateType'
import {ExecutionDecision} from './core/ExecutionDecision'
import {ModuleType} from './core/ModuleType'
import {LogLevelType} from './core/LogLevelType'
import {FeatureToggleForce} from './core/FeatureToggleForce'
import {SocialTradingAccountType} from './core/SocialTradingAccountType'
import {SocialTradingAllocationMethod} from './core/SocialTradingAllocationMethod'
import {SocialTradingAccountMode} from './core/SocialTradingAccountMode'
import {StorageDatabaseType} from './core/StorageDatabaseType'
import {ExportDatabaseType} from './core/ExportDatabaseType'
import {MarginCalculationType} from './core/MarginCalculationType'
import {MarginRateCalculationType} from './core/MarginRateCalculationType'
import {ProfitCalculationType} from './core/ProfitCalculationType'
import {TradingMode} from './core/TradingMode'
import {CommissionMode} from './core/CommissionMode'
import {CommissionChargingType} from './core/CommissionChargingType'
import {CommissionType} from './core/CommissionType'
import {CommissionCalculationType} from './core/CommissionCalculationType'
import {SwapCalculationType} from './core/SwapCalculationType'
import {DayOfWeek} from './core/DayOfWeek'
import {ChartBarPeriodType} from './core/ChartBarPeriodType'
import {QuoteType} from './core/QuoteType'
import {ManagerRight} from './core/ManagerRight'
import {RightsMode} from './core/RightsMode'
import {AccountingType} from './core/AccountingType'
import {StopOutType} from './core/StopOutType'
import {LevelType} from './core/LevelType'
import {ReportsSubscriptionType} from './core/ReportsSubscriptionType'
import {OrderSide} from './core/OrderSide'
import {OrderType} from './core/OrderType'
import {OrderTimeInForce} from './core/OrderTimeInForce'
import {OrderStatus} from './core/OrderStatus'
import {OrderRequestSourceType} from './core/OrderRequestSourceType'
import {SocialTradingOrderType} from './core/SocialTradingOrderType'
import {TradeTransactionType} from './core/TradeTransactionType'
import {TradeTransactionReason} from './core/TradeTransactionReason'
import {OffTimeType} from './core/OffTimeType'
import {TradingRouteRequestType} from './core/TradingRouteRequestType'
import {BinaryOperatorType} from './core/BinaryOperatorType'
import {TradingRouteConditionType} from './core/TradingRouteConditionType'
import {TradingRouteActionType} from './core/TradingRouteActionType'
import {GatewayStatus} from './core/GatewayStatus'
import {DataFeedStatus} from './core/DataFeedStatus'
import {ExportModuleSyncStatus} from './core/ExportModuleSyncStatus'
import {BackupModuleSyncStatus} from './core/BackupModuleSyncStatus'
import {SSLMode} from './core/SSLMode'
import {EventType} from './core/EventType'
import {LeverageProfileLeverageMode} from './core/LeverageProfileLeverageMode'
import {MarginRateProfileStatus} from './core/MarginRateProfileStatus'
import {MarginRateProfilePositionMode} from './core/MarginRateProfilePositionMode'
import {ClientReportType} from './core/ClientReportType'
import {ClientReportSendType} from './core/ClientReportSendType'
import {ChartBarsImportServerType} from './core/ChartBarsImportServerType'
import {FiltrationOfNullable} from './core/FiltrationOfNullable'
import {SortDirection} from './core/SortDirection'
import {LoginType} from './core/LoginType'
import {NewsServiceId} from './core/NewsServiceId'
import {TradingCentralTimeFrame} from './core/TradingCentralTimeFrame'
import {TradingCentralLanguage} from './core/TradingCentralLanguage'
import {FirewallActionType} from './core/FirewallActionType'
import {TraceFlags} from './core/TraceFlags'
import {DaysOfWeek} from './core/DaysOfWeek'
import {Message} from './core/Message'
import {MessageModel} from './core/Message'
import {Request} from './core/Request'
import {RequestModel} from './core/Request'
import {Response} from './core/Response'
import {ResponseModel} from './core/Response'
import {Reject} from './core/Reject'
import {RejectModel} from './core/Reject'
import {Host} from './core/Host'
import {HostModel} from './core/Host'
import {FeaturesToggleForce} from './core/FeaturesToggleForce'
import {FeaturesToggleForceModel} from './core/FeaturesToggleForce'
import {FeaturesToggle} from './core/FeaturesToggle'
import {FeaturesToggleModel} from './core/FeaturesToggle'
import {LoggingSettings} from './core/LoggingSettings'
import {LoggingSettingsModel} from './core/LoggingSettings'
import {ExternalAuthParams} from './core/ExternalAuthParams'
import {ExternalAuthParamsModel} from './core/ExternalAuthParams'
import {TradingCentralSettings} from './core/TradingCentralSettings'
import {TradingCentralSettingsModel} from './core/TradingCentralSettings'
import {NewsServicesSettings} from './core/NewsServicesSettings'
import {NewsServicesSettingsModel} from './core/NewsServicesSettings'
import {SecuritySettings} from './core/SecuritySettings'
import {SecuritySettingsModel} from './core/SecuritySettings'
import {AccessServerSettings} from './core/AccessServerSettings'
import {AccessServerSettingsModel} from './core/AccessServerSettings'
import {ConfigServerSettings} from './core/ConfigServerSettings'
import {ConfigServerSettingsModel} from './core/ConfigServerSettings'
import {FeedServerSettings} from './core/FeedServerSettings'
import {FeedServerSettingsModel} from './core/FeedServerSettings'
import {HistoryServerSettings} from './core/HistoryServerSettings'
import {HistoryServerSettingsModel} from './core/HistoryServerSettings'
import {StorageServerSettings} from './core/StorageServerSettings'
import {StorageServerSettingsModel} from './core/StorageServerSettings'
import {TradeServerSettings} from './core/TradeServerSettings'
import {TradeServerSettingsModel} from './core/TradeServerSettings'
import {ExecutionServerSettings} from './core/ExecutionServerSettings'
import {ExecutionServerSettingsModel} from './core/ExecutionServerSettings'
import {BackupServerSettings} from './core/BackupServerSettings'
import {BackupServerSettingsModel} from './core/BackupServerSettings'
import {ExportDatabaseParams} from './core/ExportDatabaseParams'
import {ExportDatabaseParamsModel} from './core/ExportDatabaseParams'
import {ExportServerSettings} from './core/ExportServerSettings'
import {ExportServerSettingsModel} from './core/ExportServerSettings'
import {DrawingStorageSqliteParams} from './core/DrawingStorageSqliteParams'
import {DrawingStorageSqliteParamsModel} from './core/DrawingStorageSqliteParams'
import {DrawingStorageServerSettings} from './core/DrawingStorageServerSettings'
import {DrawingStorageServerSettingsModel} from './core/DrawingStorageServerSettings'
import {Module} from './core/Module'
import {ModuleModel} from './core/Module'
import {ServerTime} from './core/ServerTime'
import {ServerTimeModel} from './core/ServerTime'
import {Timezone} from './core/Timezone'
import {TimezoneModel} from './core/Timezone'
import {CurrencyGroup} from './core/CurrencyGroup'
import {CurrencyGroupModel} from './core/CurrencyGroup'
import {Currency} from './core/Currency'
import {CurrencyModel} from './core/Currency'
import {SymbolGroup} from './core/SymbolGroup'
import {SymbolGroupModel} from './core/SymbolGroup'
import {CommissionValue} from './core/CommissionValue'
import {CommissionValueModel} from './core/CommissionValue'
import {CommissionProfile} from './core/CommissionProfile'
import {CommissionProfileModel} from './core/CommissionProfile'
import {MarkupValue} from './core/MarkupValue'
import {MarkupValueModel} from './core/MarkupValue'
import {MarkupProfile} from './core/MarkupProfile'
import {MarkupProfileModel} from './core/MarkupProfile'
import {SymbolGroupSettings} from './core/SymbolGroupSettings'
import {SymbolGroupSettingsModel} from './core/SymbolGroupSettings'
import {SwapSymbolSettings} from './core/SwapSymbolSettings'
import {SwapSymbolSettingsModel} from './core/SwapSymbolSettings'
import {Symbol} from './core/Symbol'
import {SymbolModel} from './core/Symbol'
import {ChartBar} from './core/ChartBar'
import {ChartBarModel} from './core/ChartBar'
import {ChartBarUpdate} from './core/ChartBarUpdate'
import {ChartBarUpdateModel} from './core/ChartBarUpdate'
import {Quote} from './core/Quote'
import {QuoteModel} from './core/Quote'
import {Tick} from './core/Tick'
import {TickModel} from './core/Tick'
import {TickClient} from './core/TickClient'
import {TickClientModel} from './core/TickClient'
import {TickQuotes} from './core/TickQuotes'
import {TickQuotesModel} from './core/TickQuotes'
import {TickUpdate} from './core/TickUpdate'
import {TickUpdateModel} from './core/TickUpdate'
import {TickManagerUpdate} from './core/TickManagerUpdate'
import {TickManagerUpdateModel} from './core/TickManagerUpdate'
import {TickClientUpdate} from './core/TickClientUpdate'
import {TickClientUpdateModel} from './core/TickClientUpdate'
import {Level2} from './core/Level2'
import {Level2Model} from './core/Level2'
import {Level2Quotes} from './core/Level2Quotes'
import {Level2QuotesModel} from './core/Level2Quotes'
import {Level2Update} from './core/Level2Update'
import {Level2UpdateModel} from './core/Level2Update'
import {Level2ManagerUpdate} from './core/Level2ManagerUpdate'
import {Level2ManagerUpdateModel} from './core/Level2ManagerUpdate'
import {Level2ClientUpdate} from './core/Level2ClientUpdate'
import {Level2ClientUpdateModel} from './core/Level2ClientUpdate'
import {ManagerRights} from './core/ManagerRights'
import {ManagerRightsModel} from './core/ManagerRights'
import {ManagerGroup} from './core/ManagerGroup'
import {ManagerGroupModel} from './core/ManagerGroup'
import {Manager} from './core/Manager'
import {ManagerModel} from './core/Manager'
import {Platform} from './core/Platform'
import {PlatformModel} from './core/Platform'
import {AccountGroup} from './core/AccountGroup'
import {AccountGroupModel} from './core/AccountGroup'
import {SocialTradingMaster} from './core/SocialTradingMaster'
import {SocialTradingMasterModel} from './core/SocialTradingMaster'
import {SocialTradingInvestor} from './core/SocialTradingInvestor'
import {SocialTradingInvestorModel} from './core/SocialTradingInvestor'
import {Deprecated_SocialTradingMasterUpdate} from './core/Deprecated_SocialTradingMasterUpdate'
import {Deprecated_SocialTradingMasterUpdateModel} from './core/Deprecated_SocialTradingMasterUpdate'
import {Deprecated_SocialTradingInvestorUpdate} from './core/Deprecated_SocialTradingInvestorUpdate'
import {Deprecated_SocialTradingInvestorUpdateModel} from './core/Deprecated_SocialTradingInvestorUpdate'
import {Account} from './core/Account'
import {AccountModel} from './core/Account'
import {Deprecated_AccountUpdate} from './core/Deprecated_AccountUpdate'
import {Deprecated_AccountUpdateModel} from './core/Deprecated_AccountUpdate'
import {AccountMargin} from './core/AccountMargin'
import {AccountMarginModel} from './core/AccountMargin'
import {Deprecated_AccountMarginUpdate} from './core/Deprecated_AccountMarginUpdate'
import {Deprecated_AccountMarginUpdateModel} from './core/Deprecated_AccountMarginUpdate'
import {Asset} from './core/Asset'
import {AssetModel} from './core/Asset'
import {Deprecated_AssetUpdate} from './core/Deprecated_AssetUpdate'
import {Deprecated_AssetUpdateModel} from './core/Deprecated_AssetUpdate'
import {AccountAssets} from './core/AccountAssets'
import {AccountAssetsModel} from './core/AccountAssets'
import {SocialTradingOrderInvestor} from './core/SocialTradingOrderInvestor'
import {SocialTradingOrderInvestorModel} from './core/SocialTradingOrderInvestor'
import {SocialTradingOrderMaster} from './core/SocialTradingOrderMaster'
import {SocialTradingOrderMasterModel} from './core/SocialTradingOrderMaster'
import {Order} from './core/Order'
import {OrderModel} from './core/Order'
import {Deprecated_SocialTradingOrderInvestorUpdate} from './core/Deprecated_SocialTradingOrderInvestorUpdate'
import {Deprecated_SocialTradingOrderInvestorUpdateModel} from './core/Deprecated_SocialTradingOrderInvestorUpdate'
import {Deprecated_SocialTradingOrderMasterUpdate} from './core/Deprecated_SocialTradingOrderMasterUpdate'
import {Deprecated_SocialTradingOrderMasterUpdateModel} from './core/Deprecated_SocialTradingOrderMasterUpdate'
import {Deprecated_OrderUpdate} from './core/Deprecated_OrderUpdate'
import {Deprecated_OrderUpdateModel} from './core/Deprecated_OrderUpdate'
import {Position} from './core/Position'
import {PositionModel} from './core/Position'
import {Deprecated_PositionUpdate} from './core/Deprecated_PositionUpdate'
import {Deprecated_PositionUpdateModel} from './core/Deprecated_PositionUpdate'
import {AccountPositions} from './core/AccountPositions'
import {AccountPositionsModel} from './core/AccountPositions'
import {TradeHistoryRecord} from './core/TradeHistoryRecord'
import {TradeHistoryRecordModel} from './core/TradeHistoryRecord'
import {Deprecated001} from './core/Deprecated001'
import {Deprecated001Model} from './core/Deprecated001'
import {TradingCalendarRegularRule} from './core/TradingCalendarRegularRule'
import {TradingCalendarRegularRuleModel} from './core/TradingCalendarRegularRule'
import {TradingCalendarOffTimeRule} from './core/TradingCalendarOffTimeRule'
import {TradingCalendarOffTimeRuleModel} from './core/TradingCalendarOffTimeRule'
import {WorkingTimePeriod} from './core/WorkingTimePeriod'
import {WorkingTimePeriodModel} from './core/WorkingTimePeriod'
import {TradingCalendarRule} from './core/TradingCalendarRule'
import {TradingCalendarRuleModel} from './core/TradingCalendarRule'
import {TradingRouteCondition} from './core/TradingRouteCondition'
import {TradingRouteConditionModel} from './core/TradingRouteCondition'
import {TradingRouteActionDelay} from './core/TradingRouteActionDelay'
import {TradingRouteActionDelayModel} from './core/TradingRouteActionDelay'
import {TradingRouteActionForwardToGateway} from './core/TradingRouteActionForwardToGateway'
import {TradingRouteActionForwardToGatewayModel} from './core/TradingRouteActionForwardToGateway'
import {TradingRouteRule} from './core/TradingRouteRule'
import {TradingRouteRuleModel} from './core/TradingRouteRule'
import {SymbolMap} from './core/SymbolMap'
import {SymbolMapModel} from './core/SymbolMap'
import {GatewayModule} from './core/GatewayModule'
import {GatewayModuleModel} from './core/GatewayModule'
import {Gateway} from './core/Gateway'
import {GatewayModel} from './core/Gateway'
import {DataFeedModule} from './core/DataFeedModule'
import {DataFeedModuleModel} from './core/DataFeedModule'
import {DataFeedSubscription} from './core/DataFeedSubscription'
import {DataFeedSubscriptionModel} from './core/DataFeedSubscription'
import {DataFeed} from './core/DataFeed'
import {DataFeedModel} from './core/DataFeed'
import {CounterInfo} from './core/CounterInfo'
import {CounterInfoModel} from './core/CounterInfo'
import {SymbolCounterInfo} from './core/SymbolCounterInfo'
import {SymbolCounterInfoModel} from './core/SymbolCounterInfo'
import {FeedModuleInfo} from './core/FeedModuleInfo'
import {FeedModuleInfoModel} from './core/FeedModuleInfo'
import {TradeModuleInfo} from './core/TradeModuleInfo'
import {TradeModuleInfoModel} from './core/TradeModuleInfo'
import {ExportModuleSyncInfo} from './core/ExportModuleSyncInfo'
import {ExportModuleSyncInfoModel} from './core/ExportModuleSyncInfo'
import {ExportModuleInfo} from './core/ExportModuleInfo'
import {ExportModuleInfoModel} from './core/ExportModuleInfo'
import {BackupModuleSyncInfo} from './core/BackupModuleSyncInfo'
import {BackupModuleSyncInfoModel} from './core/BackupModuleSyncInfo'
import {BackupModuleInfo} from './core/BackupModuleInfo'
import {BackupModuleInfoModel} from './core/BackupModuleInfo'
import {ModuleConnectionInfo} from './core/ModuleConnectionInfo'
import {ModuleConnectionInfoModel} from './core/ModuleConnectionInfo'
import {ModuleSystemInfo} from './core/ModuleSystemInfo'
import {ModuleSystemInfoModel} from './core/ModuleSystemInfo'
import {MailServer} from './core/MailServer'
import {MailServerModel} from './core/MailServer'
import {OrderState} from './core/OrderState'
import {OrderStateModel} from './core/OrderState'
import {AccountState} from './core/AccountState'
import {AccountStateModel} from './core/AccountState'
import {LeverageProfileValue} from './core/LeverageProfileValue'
import {LeverageProfileValueModel} from './core/LeverageProfileValue'
import {LeverageProfile} from './core/LeverageProfile'
import {LeverageProfileModel} from './core/LeverageProfile'
import {MarginRateProfile} from './core/MarginRateProfile'
import {MarginRateProfileModel} from './core/MarginRateProfile'
import {NewsProfile} from './core/NewsProfile'
import {NewsProfileModel} from './core/NewsProfile'
import {EmailTemplate} from './core/EmailTemplate'
import {EmailTemplateModel} from './core/EmailTemplate'
import {Version} from './core/Version'
import {VersionModel} from './core/Version'
import {JournalRecord} from './core/JournalRecord'
import {JournalRecordModel} from './core/JournalRecord'
import {JournalBusinessRecord} from './core/JournalBusinessRecord'
import {JournalBusinessRecordModel} from './core/JournalBusinessRecord'
import {TickPricesOptional} from './core/TickPricesOptional'
import {TickPricesOptionalModel} from './core/TickPricesOptional'
import {FilterInt8} from './core/FilterInt8'
import {FilterInt8Model} from './core/FilterInt8'
import {FilterUint8} from './core/FilterUint8'
import {FilterUint8Model} from './core/FilterUint8'
import {FilterInt16} from './core/FilterInt16'
import {FilterInt16Model} from './core/FilterInt16'
import {FilterUint16} from './core/FilterUint16'
import {FilterUint16Model} from './core/FilterUint16'
import {FilterInt32} from './core/FilterInt32'
import {FilterInt32Model} from './core/FilterInt32'
import {FilterUint32} from './core/FilterUint32'
import {FilterUint32Model} from './core/FilterUint32'
import {FilterInt64} from './core/FilterInt64'
import {FilterInt64Model} from './core/FilterInt64'
import {FilterUint64} from './core/FilterUint64'
import {FilterUint64Model} from './core/FilterUint64'
import {FilterDouble} from './core/FilterDouble'
import {FilterDoubleModel} from './core/FilterDouble'
import {FilterTime} from './core/FilterTime'
import {FilterTimeModel} from './core/FilterTime'
import {FilterString} from './core/FilterString'
import {FilterStringModel} from './core/FilterString'
import {FilterBool} from './core/FilterBool'
import {FilterBoolModel} from './core/FilterBool'
import {FilterEnum} from './core/FilterEnum'
import {FilterEnumModel} from './core/FilterEnum'
import {SocialTradingMasterAccountFiltration} from './core/SocialTradingMasterAccountFiltration'
import {SocialTradingMasterAccountFiltrationModel} from './core/SocialTradingMasterAccountFiltration'
import {SocialTradingInvestorAccountFiltration} from './core/SocialTradingInvestorAccountFiltration'
import {SocialTradingInvestorAccountFiltrationModel} from './core/SocialTradingInvestorAccountFiltration'
import {AccountFiltration} from './core/AccountFiltration'
import {AccountFiltrationModel} from './core/AccountFiltration'
import {AccountMarginFiltration} from './core/AccountMarginFiltration'
import {AccountMarginFiltrationModel} from './core/AccountMarginFiltration'
import {AccountGroupFiltration} from './core/AccountGroupFiltration'
import {AccountGroupFiltrationModel} from './core/AccountGroupFiltration'
import {PlatformFiltration} from './core/PlatformFiltration'
import {PlatformFiltrationModel} from './core/PlatformFiltration'
import {TimezoneFiltration} from './core/TimezoneFiltration'
import {TimezoneFiltrationModel} from './core/TimezoneFiltration'
import {CurrencyFiltration} from './core/CurrencyFiltration'
import {CurrencyFiltrationModel} from './core/CurrencyFiltration'
import {CurrencyGroupFiltration} from './core/CurrencyGroupFiltration'
import {CurrencyGroupFiltrationModel} from './core/CurrencyGroupFiltration'
import {SymbolFiltration} from './core/SymbolFiltration'
import {SymbolFiltrationModel} from './core/SymbolFiltration'
import {SymbolGroupFiltration} from './core/SymbolGroupFiltration'
import {SymbolGroupFiltrationModel} from './core/SymbolGroupFiltration'
import {ManagerFiltration} from './core/ManagerFiltration'
import {ManagerFiltrationModel} from './core/ManagerFiltration'
import {ManagerGroupFiltration} from './core/ManagerGroupFiltration'
import {ManagerGroupFiltrationModel} from './core/ManagerGroupFiltration'
import {SocialTradingOrderInvestorFiltration} from './core/SocialTradingOrderInvestorFiltration'
import {SocialTradingOrderInvestorFiltrationModel} from './core/SocialTradingOrderInvestorFiltration'
import {SocialTradingOrderMasterFiltration} from './core/SocialTradingOrderMasterFiltration'
import {SocialTradingOrderMasterFiltrationModel} from './core/SocialTradingOrderMasterFiltration'
import {OrderFiltration} from './core/OrderFiltration'
import {OrderFiltrationModel} from './core/OrderFiltration'
import {OrderStateFiltration} from './core/OrderStateFiltration'
import {OrderStateFiltrationModel} from './core/OrderStateFiltration'
import {FeaturesToggleFiltration} from './core/FeaturesToggleFiltration'
import {FeaturesToggleFiltrationModel} from './core/FeaturesToggleFiltration'
import {Sorting} from './core/Sorting'
import {SortingModel} from './core/Sorting'
import {SortingEnum} from './core/SortingEnum'
import {SortingEnumModel} from './core/SortingEnum'
import {SocialTradingMasterAccountSorting} from './core/SocialTradingMasterAccountSorting'
import {SocialTradingMasterAccountSortingModel} from './core/SocialTradingMasterAccountSorting'
import {SocialTradingInvestorAccountSorting} from './core/SocialTradingInvestorAccountSorting'
import {SocialTradingInvestorAccountSortingModel} from './core/SocialTradingInvestorAccountSorting'
import {AccountSorting} from './core/AccountSorting'
import {AccountSortingModel} from './core/AccountSorting'
import {AccountMarginSorting} from './core/AccountMarginSorting'
import {AccountMarginSortingModel} from './core/AccountMarginSorting'
import {AccountGroupSorting} from './core/AccountGroupSorting'
import {AccountGroupSortingModel} from './core/AccountGroupSorting'
import {PlatformSorting} from './core/PlatformSorting'
import {PlatformSortingModel} from './core/PlatformSorting'
import {TimezoneSorting} from './core/TimezoneSorting'
import {TimezoneSortingModel} from './core/TimezoneSorting'
import {CurrencySorting} from './core/CurrencySorting'
import {CurrencySortingModel} from './core/CurrencySorting'
import {CurrencyGroupSorting} from './core/CurrencyGroupSorting'
import {CurrencyGroupSortingModel} from './core/CurrencyGroupSorting'
import {SymbolSorting} from './core/SymbolSorting'
import {SymbolSortingModel} from './core/SymbolSorting'
import {SymbolGroupSorting} from './core/SymbolGroupSorting'
import {SymbolGroupSortingModel} from './core/SymbolGroupSorting'
import {ManagerSorting} from './core/ManagerSorting'
import {ManagerSortingModel} from './core/ManagerSorting'
import {ManagerGroupSorting} from './core/ManagerGroupSorting'
import {ManagerGroupSortingModel} from './core/ManagerGroupSorting'
import {SocialTradingOrderInvestorSorting} from './core/SocialTradingOrderInvestorSorting'
import {SocialTradingOrderInvestorSortingModel} from './core/SocialTradingOrderInvestorSorting'
import {SocialTradingOrderMasterSorting} from './core/SocialTradingOrderMasterSorting'
import {SocialTradingOrderMasterSortingModel} from './core/SocialTradingOrderMasterSorting'
import {OrderSorting} from './core/OrderSorting'
import {OrderSortingModel} from './core/OrderSorting'
import {OrderStateSorting} from './core/OrderStateSorting'
import {OrderStateSortingModel} from './core/OrderStateSorting'
import {FeaturesToggleSorting} from './core/FeaturesToggleSorting'
import {FeaturesToggleSortingModel} from './core/FeaturesToggleSorting'
import {AccountsFiltration} from './core/AccountsFiltration'
import {AccountsFiltrationModel} from './core/AccountsFiltration'
import {AccountsSorting} from './core/AccountsSorting'
import {AccountsSortingModel} from './core/AccountsSorting'
import {OrdersFiltration} from './core/OrdersFiltration'
import {OrdersFiltrationModel} from './core/OrdersFiltration'
import {OrdersSorting} from './core/OrdersSorting'
import {OrdersSortingModel} from './core/OrdersSorting'
import {OrdersDisjunctionFiltration} from './core/OrdersDisjunctionFiltration'
import {OrdersDisjunctionFiltrationModel} from './core/OrdersDisjunctionFiltration'
import {AccountWithAccountMargin} from './core/AccountWithAccountMargin'
import {AccountWithAccountMarginModel} from './core/AccountWithAccountMargin'
import {OrderWithAccount} from './core/OrderWithAccount'
import {OrderWithAccountModel} from './core/OrderWithAccount'
import {SwapImportAccountGroupSettings} from './core/SwapImportAccountGroupSettings'
import {SwapImportAccountGroupSettingsModel} from './core/SwapImportAccountGroupSettings'
import {SwapImportSymbolSettings} from './core/SwapImportSymbolSettings'
import {SwapImportSymbolSettingsModel} from './core/SwapImportSymbolSettings'
import {SwapImportSettings} from './core/SwapImportSettings'
import {SwapImportSettingsModel} from './core/SwapImportSettings'
import {NewsProfileImportSettings} from './core/NewsProfileImportSettings'
import {NewsProfileImportSettingsModel} from './core/NewsProfileImportSettings'
import {OrdersClosedSummary} from './core/OrdersClosedSummary'
import {OrdersClosedSummaryModel} from './core/OrdersClosedSummary'
import {OrdersClosedSummaries} from './core/OrdersClosedSummaries'
import {OrdersClosedSummariesModel} from './core/OrdersClosedSummaries'
import {ClientLoginInfoRecord} from './core/ClientLoginInfoRecord'
import {ClientLoginInfoRecordModel} from './core/ClientLoginInfoRecord'
import {Date} from './core/Date'
import {DateModel} from './core/Date'
import {IPv4Range} from './core/IPv4Range'
import {IPv4RangeModel} from './core/IPv4Range'
import {FirewallRule} from './core/FirewallRule'
import {FirewallRuleModel} from './core/FirewallRule'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding core sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize core sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._MessageModel = new MessageModel(this.buffer)
    this._RequestModel = new RequestModel(this.buffer)
    this._ResponseModel = new ResponseModel(this.buffer)
    this._RejectModel = new RejectModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Sender models accessors

  /**
   * Get Message model
   * @this {!Sender}
   * @returns {!MessageModel} Message model
   */
  get MessageModel () {
    return this._MessageModel
  }

  /**
   * Get Request model
   * @this {!Sender}
   * @returns {!RequestModel} Request model
   */
  get RequestModel () {
    return this._RequestModel
  }

  /**
   * Get Response model
   * @this {!Sender}
   * @returns {!ResponseModel} Response model
   */
  get ResponseModel () {
    return this._ResponseModel
  }

  /**
   * Get Reject model
   * @this {!Sender}
   * @returns {!RejectModel} Reject model
   */
  get RejectModel () {
    return this._RejectModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof Message) && (value.fbeType === this.MessageModel.fbeType)) {
      return this.send_Message(value)
    }
    if ((value instanceof Request) && (value.fbeType === this.RequestModel.fbeType)) {
      return this.send_Request(value)
    }
    if ((value instanceof Response) && (value.fbeType === this.ResponseModel.fbeType)) {
      return this.send_Response(value)
    }
    if ((value instanceof Reject) && (value.fbeType === this.RejectModel.fbeType)) {
      return this.send_Reject(value)
    }
    return 0
  }

  /**
   * Send Message value
   * @this {!Sender}
   * @param {!Message} value Message value to send
   * @returns {!number} Sent bytes
   */
  send_Message (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MessageModel.serialize(value)
    console.assert((serialized > 0), 'core.Message serialization failed!')
    console.assert(this.MessageModel.verify(), 'core.Message validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Request value
   * @this {!Sender}
   * @param {!Request} value Request value to send
   * @returns {!number} Sent bytes
   */
  send_Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestModel.serialize(value)
    console.assert((serialized > 0), 'core.Request serialization failed!')
    console.assert(this.RequestModel.verify(), 'core.Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Response value
   * @this {!Sender}
   * @param {!Response} value Response value to send
   * @returns {!number} Sent bytes
   */
  send_Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ResponseModel.serialize(value)
    console.assert((serialized > 0), 'core.Response serialization failed!')
    console.assert(this.ResponseModel.verify(), 'core.Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Reject value
   * @this {!Sender}
   * @param {!Reject} value Reject value to send
   * @returns {!number} Sent bytes
   */
  send_Reject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RejectModel.serialize(value)
    console.assert((serialized > 0), 'core.Reject serialization failed!')
    console.assert(this.RejectModel.verify(), 'core.Reject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'core.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding core receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize core receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._MessageValue = new Message()
    this._MessageModel = new MessageModel()
    this._RequestValue = new Request()
    this._RequestModel = new RequestModel()
    this._ResponseValue = new Response()
    this._ResponseModel = new ResponseModel()
    this._RejectValue = new Reject()
    this._RejectModel = new RejectModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * Message receive handler
   * @this {!Receiver}
   * @param {!Message} value Message received value
   */
  onReceive_Message (value) {}  // eslint-disable-line

  /**
   * Request receive handler
   * @this {!Receiver}
   * @param {!Request} value Request received value
   */
  onReceive_Request (value) {}  // eslint-disable-line

  /**
   * Response receive handler
   * @this {!Receiver}
   * @param {!Response} value Response received value
   */
  onReceive_Response (value) {}  // eslint-disable-line

  /**
   * Reject receive handler
   * @this {!Receiver}
   * @param {!Reject} value Reject received value
   */
  onReceive_Reject (value) {}  // eslint-disable-line

  /**
   * core receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case MessageModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MessageModel.attachBuffer(buffer, offset)
        console.assert(this._MessageModel.verify(), 'core.Message validation failed!')
        let deserialized = this._MessageModel.deserialize(this._MessageValue)
        console.assert((deserialized.size > 0), 'core.Message deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MessageValue)
        this.onReceive_Message(this._MessageValue)
        return true
      }
      case RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestModel.verify(), 'core.Request validation failed!')
        let deserialized = this._RequestModel.deserialize(this._RequestValue)
        console.assert((deserialized.size > 0), 'core.Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestValue)
        this.onReceive_Request(this._RequestValue)
        return true
      }
      case ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ResponseModel.verify(), 'core.Response validation failed!')
        let deserialized = this._ResponseModel.deserialize(this._ResponseValue)
        console.assert((deserialized.size > 0), 'core.Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ResponseValue)
        this.onReceive_Response(this._ResponseValue)
        return true
      }
      case RejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RejectModel.attachBuffer(buffer, offset)
        console.assert(this._RejectModel.verify(), 'core.Reject validation failed!')
        let deserialized = this._RejectModel.deserialize(this._RejectValue)
        console.assert((deserialized.size > 0), 'core.Reject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RejectValue)
        this.onReceive_Reject(this._RejectValue)
        return true
      }
      default: break
    }
    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding core proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize core proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._MessageModel = new MessageModel()
    this._RequestModel = new RequestModel()
    this._ResponseModel = new ResponseModel()
    this._RejectModel = new RejectModel()
  }

  // Proxy handlers

  /**
   * Message proxy handler
   * @this {!Proxy}
   * @param {!Message} model Message model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Message (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Request proxy handler
   * @this {!Proxy}
   * @param {!Request} model Request model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Request (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Response proxy handler
   * @this {!Proxy}
   * @param {!Response} model Response model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Response (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * Reject proxy handler
   * @this {!Proxy}
   * @param {!Reject} model Reject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_Reject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * core receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case MessageModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._MessageModel.attachBuffer(buffer, offset)
        console.assert(this._MessageModel.verify(), 'core.Message validation failed!')

        let fbeBegin = this._MessageModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Message(this._MessageModel, type, buffer, offset, size)
        this._MessageModel.model.getEnd(fbeBegin)
        return true
      }
      case RequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RequestModel.attachBuffer(buffer, offset)
        console.assert(this._RequestModel.verify(), 'core.Request validation failed!')

        let fbeBegin = this._RequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Request(this._RequestModel, type, buffer, offset, size)
        this._RequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ResponseModel.verify(), 'core.Response validation failed!')

        let fbeBegin = this._ResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Response(this._ResponseModel, type, buffer, offset, size)
        this._ResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case RejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._RejectModel.attachBuffer(buffer, offset)
        console.assert(this._RejectModel.verify(), 'core.Reject validation failed!')

        let fbeBegin = this._RejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_Reject(this._RejectModel, type, buffer, offset, size)
        this._RejectModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }
    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding core client
 */
class Client extends fbe.Client {
  /**
   * Initialize core client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._MessageSenderModel = new MessageModel(this.sendBuffer)
    this._MessageReceiverValue = new Message()
    this._MessageReceiverModel = new MessageModel()
    this._RequestSenderModel = new RequestModel(this.sendBuffer)
    this._RequestReceiverValue = new Request()
    this._RequestReceiverModel = new RequestModel()
    this._ResponseSenderModel = new ResponseModel(this.sendBuffer)
    this._ResponseReceiverValue = new Response()
    this._ResponseReceiverModel = new ResponseModel()
    this._RejectSenderModel = new RejectModel(this.sendBuffer)
    this._RejectReceiverValue = new Reject()
    this._RejectReceiverModel = new RejectModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Sender models accessors

  /**
   * Get Message model
   * @this {!Client}
   * @returns {!MessageModel} Message sender model
   */
  get MessageSenderModel () {
    return this._MessageSenderModel
  }

  /**
   * Get Request model
   * @this {!Client}
   * @returns {!RequestModel} Request sender model
   */
  get RequestSenderModel () {
    return this._RequestSenderModel
  }

  /**
   * Get Response model
   * @this {!Client}
   * @returns {!ResponseModel} Response sender model
   */
  get ResponseSenderModel () {
    return this._ResponseSenderModel
  }

  /**
   * Get Reject model
   * @this {!Client}
   * @returns {!RejectModel} Reject sender model
   */
  get RejectSenderModel () {
    return this._RejectSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof Message) && (value.fbeType === this.MessageSenderModel.fbeType)) {
      return this.send_Message(value)
    }
    if ((value instanceof Request) && (value.fbeType === this.RequestSenderModel.fbeType)) {
      return this.send_Request(value)
    }
    if ((value instanceof Response) && (value.fbeType === this.ResponseSenderModel.fbeType)) {
      return this.send_Response(value)
    }
    if ((value instanceof Reject) && (value.fbeType === this.RejectSenderModel.fbeType)) {
      return this.send_Reject(value)
    }
    return 0
  }

  /**
   * Send Message value
   * @this {!Client}
   * @param {!Message} value Message value to send
   * @returns {!number} Sent bytes
   */
  send_Message (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.MessageSenderModel.serialize(value)
    console.assert((serialized > 0), 'core.Message serialization failed!')
    console.assert(this.MessageSenderModel.verify(), 'core.Message validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Request value
   * @this {!Client}
   * @param {!Request} value Request value to send
   * @returns {!number} Sent bytes
   */
  send_Request (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'core.Request serialization failed!')
    console.assert(this.RequestSenderModel.verify(), 'core.Request validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Response value
   * @this {!Client}
   * @param {!Response} value Response value to send
   * @returns {!number} Sent bytes
   */
  send_Response (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'core.Response serialization failed!')
    console.assert(this.ResponseSenderModel.verify(), 'core.Response validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send Reject value
   * @this {!Client}
   * @param {!Reject} value Reject value to send
   * @returns {!number} Sent bytes
   */
  send_Reject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.RejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'core.Reject serialization failed!')
    console.assert(this.RejectSenderModel.verify(), 'core.Reject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'core.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * Message receive handler
   * @this {!Client}
   * @param {!Message} value Message received value
   */
  onReceive_Message (value) {}  // eslint-disable-line

  /**
   * Request receive handler
   * @this {!Client}
   * @param {!Request} value Request received value
   */
  onReceive_Request (value) {}  // eslint-disable-line

  /**
   * Response receive handler
   * @this {!Client}
   * @param {!Response} value Response received value
   */
  onReceive_Response (value) {}  // eslint-disable-line

  /**
   * Reject receive handler
   * @this {!Client}
   * @param {!Reject} value Reject received value
   */
  onReceive_Reject (value) {}  // eslint-disable-line

  /**
   * core receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case MessageModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._MessageReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._MessageReceiverModel.verify(), 'core.Message validation failed!')
        let deserialized = this._MessageReceiverModel.deserialize(this._MessageReceiverValue)
        console.assert((deserialized.size > 0), 'core.Message deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._MessageReceiverValue)
        this.onReceive_Message(this._MessageReceiverValue)
        return true
      }
      case RequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RequestReceiverModel.verify(), 'core.Request validation failed!')
        let deserialized = this._RequestReceiverModel.deserialize(this._RequestReceiverValue)
        console.assert((deserialized.size > 0), 'core.Request deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RequestReceiverValue)
        this.onReceive_Request(this._RequestReceiverValue)
        return true
      }
      case ResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ResponseReceiverModel.verify(), 'core.Response validation failed!')
        let deserialized = this._ResponseReceiverModel.deserialize(this._ResponseReceiverValue)
        console.assert((deserialized.size > 0), 'core.Response deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ResponseReceiverValue)
        this.onReceive_Response(this._ResponseReceiverValue)
        return true
      }
      case RejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._RejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._RejectReceiverModel.verify(), 'core.Reject validation failed!')
        let deserialized = this._RejectReceiverModel.deserialize(this._RejectReceiverValue)
        console.assert((deserialized.size > 0), 'core.Reject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._RejectReceiverValue)
        this.onReceive_Reject(this._RejectReceiverValue)
        return true
      }
      default: break
    }
    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }
    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }
    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
