export type DataFeedSubscription = {
    SymbolPattern: string | null;
    MarketDepth: number | null;
    SortOrder: number | null;
};

export type DataFeedParameters = {
    CheckLatency?: boolean | null;
    BeginString?: string | null;
    ConnectionType?: string | null; // not uses
    DataDictionary?: string | null;
    FilterLogExcludeMsgTypes?: string | null;
    EndDay?: string | null; // daysOfWeek.shortLowered
    FileStorePath?: string | null;
    EndTime?: string | null; // time '00.00.00'
    FileLogPath?: string | null;
    HeartBtInt?: number | null; // int >0
    ResetOnLogon?: boolean | null;
    SenderCompID?: string | null;
    SessionQualifier?: string | null;
    SocketConnectPort?: number | null;
    SocketConnectHost?: string | null;
    StartDay?: string | null; // daysOfWeek.shortLowered
    StartTime?: string | null; // time '00.00.00'
    TargetCompID?: string | null;
    UseDataDictionary?: boolean | null;
    UseSSL?: boolean | null;
    Username?: string | null;
    Password?: string | null;
    SendF1000?: boolean | null;
};

export type DataFeed = {
    id: string | null;
    ModuleName: string | null;
    Name: string;
    Host: string | null;
    Port: number | null;
    MarketDepth: number | null;
    IsEnabled: boolean;
    DataFeedStatus: number | null;
    Subscriptions: DataFeedSubscription[];
    SymbolMapId: string | null;
    Parameters: DataFeedParameters | null;
    IsRemote: boolean;
};

const YNtoBool = (value?: string | null): boolean => value?.toLowerCase() === 'y' || false;
const boolToYN = (value?: boolean | null): string => (value ? 'Y' : 'N');

export const parameretsMapToObject = (record: Map<string, string>): DataFeedParameters => {
    const CheckLatency = record.get('CheckLatency') ?? null;
    const BeginString = record.get('BeginString') ?? null;
    const ConnectionType = record.get('ConnectionType') ?? null;
    const DataDictionary = record.get('DataDictionary') ?? null;
    const FilterLogExcludeMsgTypes = record.get('FilterLogExcludeMsgTypes') ?? null;
    const EndDay = record.get('EndDay') ?? null;
    const FileStorePath = record.get('FileStorePath') ?? null;
    const EndTime = record.get('EndTime') ?? null;
    const FileLogPath = record.get('FileLogPath') ?? null;
    const HeartBtInt = record.get('HeartBtInt') ?? null;
    const ResetOnLogon = record.get('ResetOnLogon') ?? null;
    const SenderCompID = record.get('SenderCompID') ?? null;
    const SessionQualifier = record.get('SessionQualifier') ?? null;
    const SocketConnectPort = record.get('SocketConnectPort') ?? null;
    const SocketConnectHost = record.get('SocketConnectHost') ?? null;
    const StartDay = record.get('StartDay') ?? null;
    const StartTime = record.get('StartTime') ?? null;
    const TargetCompID = record.get('TargetCompID') ?? null;
    const UseDataDictionary = record.get('UseDataDictionary') ?? null;
    const UseSSL = record.get('UseSSL') ?? null;
    const Username = record.get('Username') ?? null;
    const Password = record.get('Password') ?? null;
    const SendF1000 = record.get('SendF1000') ?? null;

    return {
        CheckLatency: YNtoBool(CheckLatency),
        BeginString,
        ConnectionType,
        DataDictionary,
        FilterLogExcludeMsgTypes,
        EndDay,
        FileStorePath,
        EndTime,
        FileLogPath,
        HeartBtInt: HeartBtInt === null ? null : +HeartBtInt,
        ResetOnLogon: YNtoBool(ResetOnLogon),
        SenderCompID,
        SessionQualifier,
        SocketConnectPort: SocketConnectPort === null ? null : +SocketConnectPort,
        SocketConnectHost,
        StartDay,
        StartTime,
        TargetCompID,
        UseDataDictionary: YNtoBool(UseDataDictionary),
        UseSSL: YNtoBool(UseSSL),
        Username,
        Password,
        SendF1000: YNtoBool(SendF1000),
    };
};

export const objectParametersToMap = (record: DataFeedParameters): Map<string, string> =>
    new Map([
        ['CheckLatency', boolToYN(record.CheckLatency) ?? ''],
        ['BeginString', record.BeginString ?? ''],
        ['ConnectionType', record.ConnectionType ?? ''],
        ['DataDictionary', record.DataDictionary ?? ''],
        ['FilterLogExcludeMsgTypes', record.FilterLogExcludeMsgTypes ?? ''],
        ['EndDay', record.EndDay ?? ''],
        ['FileStorePath', record.FileStorePath ?? ''],
        ['EndTime', record.EndTime ?? ''],
        ['FileLogPath', record.FileLogPath ?? ''],
        ['HeartBtInt', typeof record.HeartBtInt === 'number' ? record.HeartBtInt.toString() : ''],
        ['ResetOnLogon', boolToYN(record.ResetOnLogon) ?? ''],
        ['SenderCompID', record.SenderCompID ?? ''],
        ['SessionQualifier', record.SessionQualifier ?? ''],
        ['SocketConnectPort', typeof record.SocketConnectPort === 'number' ? record.SocketConnectPort.toString() : ''],
        ['SocketConnectHost', record.SocketConnectHost ?? ''],
        ['StartDay', record.StartDay ?? ''],
        ['StartTime', record.StartTime ?? ''],
        ['TargetCompID', record.TargetCompID ?? ''],
        ['UseDataDictionary', boolToYN(record.UseDataDictionary) ?? ''],
        ['UseSSL', boolToYN(record.UseSSL) ?? ''],
        ['Username', record.Username ?? ''],
        ['Password', record.Password ?? ''],
        ['SendF1000', boolToYN(record.SendF1000) ?? ''],
    ]);
