
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {ChartInfoShort} from './ChartInfoShort';
import {FieldModelChartInfoShort} from './ChartInfoShort';
import {ChartInfoFull} from './ChartInfoFull';
import {FieldModelChartInfoFull} from './ChartInfoFull';

/**
 * ChartInfoListResponse struct
 */
class ChartInfoListResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!UUID=} dashboardId
   * @param {!boolean=} getFullInfo
   * @param {!Array=} chartInfoShort
   * @param {!Array=} chartInfoFull
   * @constructor
   */
  constructor (parent = new Response(), dashboardId = new UUID(), getFullInfo = false, chartInfoShort = [], chartInfoFull = []) {
    super()
    super.copy(parent)
    this.DashboardId = dashboardId
    this.GetFullInfo = getFullInfo
    this.ChartInfoShort = chartInfoShort
    this.ChartInfoFull = chartInfoFull
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartInfoListResponse}
   * @param {!ChartInfoListResponse} other Other struct
   * @returns {!ChartInfoListResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.DashboardId != null) {
      this.DashboardId = new UUID(other.DashboardId)
    } else {
      this.DashboardId = null
    }
    if (other.GetFullInfo != null) {
      this.GetFullInfo = other.GetFullInfo
    } else {
      this.GetFullInfo = null
    }
    if (other.ChartInfoShort != null) {
      this.ChartInfoShort = []
      for (let item of other.ChartInfoShort) {
        if (item != null) {
          let tempItem
          tempItem = ChartInfoShort.fromObject(item)
          this.ChartInfoShort.push(tempItem)
        } else {
          this.ChartInfoShort.push(null)
        }
      }
    } else {
      this.ChartInfoShort = null
    }
    if (other.ChartInfoFull != null) {
      this.ChartInfoFull = []
      for (let item of other.ChartInfoFull) {
        if (item != null) {
          let tempItem
          tempItem = ChartInfoFull.fromObject(item)
          this.ChartInfoFull.push(tempItem)
        } else {
          this.ChartInfoFull.push(null)
        }
      }
    } else {
      this.ChartInfoFull = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartInfoListResponse}
   * @returns {!ChartInfoListResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartInfoListResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartInfoListResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartInfoListResponse}
   * @param {!ChartInfoListResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartInfoListResponse)) {
      throw new TypeError('Instance of ChartInfoListResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartInfoListResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      DashboardId: ((this.DashboardId != null) ? this.DashboardId.toString() : null),
      GetFullInfo: ((this.GetFullInfo != null) ? this.GetFullInfo : null),
      ChartInfoShort: ((this.ChartInfoShort != null) ? Array.from(this.ChartInfoShort, item => ((item != null) ? item : null)) : null),
      ChartInfoFull: ((this.ChartInfoFull != null) ? Array.from(this.ChartInfoFull, item => ((item != null) ? item : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartInfoListResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartInfoListResponse} other Object value
   * @returns {!ChartInfoListResponse} Created struct
   */
  static fromObject (other) {
    return new ChartInfoListResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartInfoListResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartInfoListResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartInfoListResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5196
  }
}

export { ChartInfoListResponse };

/**
 * Fast Binary Encoding ChartInfoListResponse field model
 */
class FieldModelChartInfoListResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.DashboardId = new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.GetFullInfo = new fbe.FieldModelBool(buffer, this.DashboardId.fbeOffset + this.DashboardId.fbeSize)
    this.ChartInfoShort = new fbe.FieldModelVector(new FieldModelChartInfoShort(buffer, this.GetFullInfo.fbeOffset + this.GetFullInfo.fbeSize), buffer, this.GetFullInfo.fbeOffset + this.GetFullInfo.fbeSize)
    this.ChartInfoFull = new fbe.FieldModelVector(new FieldModelChartInfoFull(buffer, this.ChartInfoShort.fbeOffset + this.ChartInfoShort.fbeSize), buffer, this.ChartInfoShort.fbeOffset + this.ChartInfoShort.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.DashboardId.fbeSize + this.GetFullInfo.fbeSize + this.ChartInfoShort.fbeSize + this.ChartInfoFull.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.DashboardId.fbeExtra + this.GetFullInfo.fbeExtra + this.ChartInfoShort.fbeExtra + this.ChartInfoFull.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartInfoListResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5196
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartInfoListResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartInfoListResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.DashboardId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DashboardId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DashboardId.fbeSize

    if ((fbeCurrentSize + this.GetFullInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GetFullInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GetFullInfo.fbeSize

    if ((fbeCurrentSize + this.ChartInfoShort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartInfoShort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartInfoShort.fbeSize

    if ((fbeCurrentSize + this.ChartInfoFull.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartInfoFull.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartInfoFull.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartInfoListResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartInfoListResponse}
   * @param {!ChartInfoListResponse} fbeValue Default value, defaults is new ChartInfoListResponse()
   * @returns {!ChartInfoListResponse} ChartInfoListResponse value
   */
  get (fbeValue = new ChartInfoListResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartInfoListResponse}
   * @param {!ChartInfoListResponse} fbeValue ChartInfoListResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.DashboardId.fbeSize) <= fbeStructSize) {
      fbeValue.DashboardId = this.DashboardId.get()
    } else {
      fbeValue.DashboardId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DashboardId.fbeSize

    if ((fbeCurrentSize + this.GetFullInfo.fbeSize) <= fbeStructSize) {
      fbeValue.GetFullInfo = this.GetFullInfo.get()
    } else {
      fbeValue.GetFullInfo = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GetFullInfo.fbeSize

    if ((fbeCurrentSize + this.ChartInfoShort.fbeSize) <= fbeStructSize) {
      this.ChartInfoShort.get(fbeValue.ChartInfoShort)
    } else {
      fbeValue.ChartInfoShort.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartInfoShort.fbeSize

    if ((fbeCurrentSize + this.ChartInfoFull.fbeSize) <= fbeStructSize) {
      this.ChartInfoFull.get(fbeValue.ChartInfoFull)
    } else {
      fbeValue.ChartInfoFull.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartInfoFull.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartInfoListResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartInfoListResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartInfoListResponse}
   * @param {!ChartInfoListResponse} fbeValue ChartInfoListResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartInfoListResponse}
   * @param {ChartInfoListResponse} fbeValue ChartInfoListResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.DashboardId.set(fbeValue.DashboardId)
    this.GetFullInfo.set(fbeValue.GetFullInfo)
    this.ChartInfoShort.set(fbeValue.ChartInfoShort)
    this.ChartInfoFull.set(fbeValue.ChartInfoFull)
  }
}

export { FieldModelChartInfoListResponse };

/**
 * Fast Binary Encoding ChartInfoListResponse model
 */
class ChartInfoListResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartInfoListResponse(this.buffer, 4)
  }

  /**
   * Get the ChartInfoListResponse model
   * @this {!ChartInfoListResponseModel}
   * @returns {!FieldModelChartInfoListResponse} model ChartInfoListResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartInfoListResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartInfoListResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartInfoListResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartInfoListResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartInfoListResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartInfoListResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartInfoListResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartInfoListResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartInfoListResponseModel}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartInfoListResponseModel}
   * @param {!ChartInfoListResponse} value ChartInfoListResponse value, defaults is new ChartInfoListResponse()
   * @return {!object} Deserialized ChartInfoListResponse value and its size
   */
  deserialize (value = new ChartInfoListResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartInfoListResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartInfoListResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartInfoListResponseModel}
   * @param {!number} prev Previous ChartInfoListResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartInfoListResponseModel };
