
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradingRouteConditionType} from './TradingRouteConditionType';
import {FieldModelTradingRouteConditionType} from './TradingRouteConditionType';
import {BinaryOperatorType} from './BinaryOperatorType';
import {FieldModelBinaryOperatorType} from './BinaryOperatorType';
import {OrderSide} from './OrderSide';
import {FieldModelOrderSide} from './OrderSide';

/**
 * TradingRouteCondition struct
 */
class TradingRouteCondition {
  /**
   * Initialize struct
   * @param {!TradingRouteConditionType=} conditionType
   * @param {!BinaryOperatorType=} operatorType
   * @param {!Set=} orderSide
   * @param {number=} orderVolume
   * @param {!Set=} symbolId
   * @param {!Set=} symbolGroupId
   * @param {!Set=} accountGroupId
   * @param {!Set=} accountLogin
   * @constructor
   */
  constructor (conditionType = new TradingRouteConditionType(), operatorType = new BinaryOperatorType(), orderSide = new Set(), orderVolume = null, symbolId = new Set(), symbolGroupId = new Set(), accountGroupId = new Set(), accountLogin = new Set()) {
    this.ConditionType = conditionType
    this.OperatorType = operatorType
    this.OrderSide = orderSide
    this.OrderVolume = orderVolume
    this.SymbolId = symbolId
    this.SymbolGroupId = symbolGroupId
    this.AccountGroupId = accountGroupId
    this.AccountLogin = accountLogin
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingRouteCondition}
   * @param {!TradingRouteCondition} other Other struct
   * @returns {!TradingRouteCondition} This struct
   */
  copy (other) {
    if (other.ConditionType != null) {
      this.ConditionType = TradingRouteConditionType.fromObject(other.ConditionType)
    } else {
      this.ConditionType = null
    }
    if (other.OperatorType != null) {
      this.OperatorType = BinaryOperatorType.fromObject(other.OperatorType)
    } else {
      this.OperatorType = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = new Set()
      for (let item of other.OrderSide) {
        if (item != null) {
          let tempItem
          tempItem = OrderSide.fromObject(item)
          this.OrderSide.add(tempItem)
        } else {
          this.OrderSide.add(null)
        }
      }
    } else {
      this.OrderSide = null
    }
    if (other.OrderVolume != null) {
      this.OrderVolume = other.OrderVolume
    } else {
      this.OrderVolume = null
    }
    if (other.SymbolId != null) {
      this.SymbolId = new Set()
      for (let item of other.SymbolId) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.SymbolId.add(tempItem)
        } else {
          this.SymbolId.add(null)
        }
      }
    } else {
      this.SymbolId = null
    }
    if (other.SymbolGroupId != null) {
      this.SymbolGroupId = new Set()
      for (let item of other.SymbolGroupId) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.SymbolGroupId.add(tempItem)
        } else {
          this.SymbolGroupId.add(null)
        }
      }
    } else {
      this.SymbolGroupId = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new Set()
      for (let item of other.AccountGroupId) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.AccountGroupId.add(tempItem)
        } else {
          this.AccountGroupId.add(null)
        }
      }
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = new Set()
      for (let item of other.AccountLogin) {
        if (item != null) {
          let tempItem
          tempItem = UInt64.fromNumber(item)
          this.AccountLogin.add(tempItem)
        } else {
          this.AccountLogin.add(null)
        }
      }
    } else {
      this.AccountLogin = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingRouteCondition}
   * @returns {!TradingRouteCondition} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingRouteConditionModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingRouteConditionModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingRouteCondition}
   * @param {!TradingRouteCondition} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingRouteCondition)) {
      throw new TypeError('Instance of TradingRouteCondition is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingRouteCondition}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ConditionType: ((this.ConditionType != null) ? this.ConditionType : null),
      OperatorType: ((this.OperatorType != null) ? this.OperatorType : null),
      OrderSide: ((this.OrderSide != null) ? Array.from(this.OrderSide, item => ((item != null) ? item : null)) : null),
      OrderVolume: ((this.OrderVolume != null) ? this.OrderVolume : null),
      SymbolId: ((this.SymbolId != null) ? Array.from(this.SymbolId, item => ((item != null) ? item.toString() : null)) : null),
      SymbolGroupId: ((this.SymbolGroupId != null) ? Array.from(this.SymbolGroupId, item => ((item != null) ? item.toString() : null)) : null),
      AccountGroupId: ((this.AccountGroupId != null) ? Array.from(this.AccountGroupId, item => ((item != null) ? item.toString() : null)) : null),
      AccountLogin: ((this.AccountLogin != null) ? Array.from(this.AccountLogin, item => ((item != null) ? item.toNumber() : null)) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingRouteCondition.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingRouteCondition} other Object value
   * @returns {!TradingRouteCondition} Created struct
   */
  static fromObject (other) {
    return new TradingRouteCondition().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingRouteCondition}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingRouteCondition.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingRouteCondition}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 64
  }
}

export { TradingRouteCondition };

/**
 * Fast Binary Encoding TradingRouteCondition field model
 */
class FieldModelTradingRouteCondition extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ConditionType = new FieldModelTradingRouteConditionType(buffer, 4 + 4)
    this.OperatorType = new FieldModelBinaryOperatorType(buffer, this.ConditionType.fbeOffset + this.ConditionType.fbeSize)
    this.OrderSide = new fbe.FieldModelSet(new FieldModelOrderSide(buffer, this.OperatorType.fbeOffset + this.OperatorType.fbeSize), buffer, this.OperatorType.fbeOffset + this.OperatorType.fbeSize)
    this.OrderVolume = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize), buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.SymbolId = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.OrderVolume.fbeOffset + this.OrderVolume.fbeSize), buffer, this.OrderVolume.fbeOffset + this.OrderVolume.fbeSize)
    this.SymbolGroupId = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.AccountGroupId = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.SymbolGroupId.fbeOffset + this.SymbolGroupId.fbeSize), buffer, this.SymbolGroupId.fbeOffset + this.SymbolGroupId.fbeSize)
    this.AccountLogin = new fbe.FieldModelSet(new fbe.FieldModelUInt64(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ConditionType.fbeSize + this.OperatorType.fbeSize + this.OrderSide.fbeSize + this.OrderVolume.fbeSize + this.SymbolId.fbeSize + this.SymbolGroupId.fbeSize + this.AccountGroupId.fbeSize + this.AccountLogin.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ConditionType.fbeExtra + this.OperatorType.fbeExtra + this.OrderSide.fbeExtra + this.OrderVolume.fbeExtra + this.SymbolId.fbeExtra + this.SymbolGroupId.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountLogin.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingRouteCondition.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 64
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingRouteCondition}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingRouteCondition}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConditionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConditionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConditionType.fbeSize

    if ((fbeCurrentSize + this.OperatorType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OperatorType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OperatorType.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderVolume.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingRouteCondition}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingRouteCondition}
   * @param {!TradingRouteCondition} fbeValue Default value, defaults is new TradingRouteCondition()
   * @returns {!TradingRouteCondition} TradingRouteCondition value
   */
  get (fbeValue = new TradingRouteCondition()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingRouteCondition}
   * @param {!TradingRouteCondition} fbeValue TradingRouteCondition value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConditionType.fbeSize) <= fbeStructSize) {
      fbeValue.ConditionType = this.ConditionType.get()
    } else {
      fbeValue.ConditionType = new TradingRouteConditionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConditionType.fbeSize

    if ((fbeCurrentSize + this.OperatorType.fbeSize) <= fbeStructSize) {
      fbeValue.OperatorType = this.OperatorType.get()
    } else {
      fbeValue.OperatorType = new BinaryOperatorType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OperatorType.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      this.OrderSide.get(fbeValue.OrderSide)
    } else {
      fbeValue.OrderSide.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderVolume.fbeSize) <= fbeStructSize) {
      fbeValue.OrderVolume = this.OrderVolume.get()
    } else {
      fbeValue.OrderVolume = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderVolume.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      this.SymbolId.get(fbeValue.SymbolId)
    } else {
      fbeValue.SymbolId.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupId.fbeSize) <= fbeStructSize) {
      this.SymbolGroupId.get(fbeValue.SymbolGroupId)
    } else {
      fbeValue.SymbolGroupId.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      this.AccountGroupId.get(fbeValue.AccountGroupId)
    } else {
      fbeValue.AccountGroupId.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      this.AccountLogin.get(fbeValue.AccountLogin)
    } else {
      fbeValue.AccountLogin.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingRouteCondition}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingRouteCondition}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingRouteCondition}
   * @param {!TradingRouteCondition} fbeValue TradingRouteCondition value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingRouteCondition}
   * @param {TradingRouteCondition} fbeValue TradingRouteCondition value
   */
  setFields (fbeValue) {
    this.ConditionType.set(fbeValue.ConditionType)
    this.OperatorType.set(fbeValue.OperatorType)
    this.OrderSide.set(fbeValue.OrderSide)
    this.OrderVolume.set(fbeValue.OrderVolume)
    this.SymbolId.set(fbeValue.SymbolId)
    this.SymbolGroupId.set(fbeValue.SymbolGroupId)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountLogin.set(fbeValue.AccountLogin)
  }
}

export { FieldModelTradingRouteCondition };

/**
 * Fast Binary Encoding TradingRouteCondition model
 */
class TradingRouteConditionModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingRouteCondition(this.buffer, 4)
  }

  /**
   * Get the TradingRouteCondition model
   * @this {!TradingRouteConditionModel}
   * @returns {!FieldModelTradingRouteCondition} model TradingRouteCondition model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingRouteConditionModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingRouteConditionModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingRouteConditionModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingRouteConditionModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingRouteCondition.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingRouteConditionModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingRouteConditionModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingRouteConditionModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingRouteConditionModel}
   * @param {!TradingRouteCondition} value TradingRouteCondition value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingRouteConditionModel}
   * @param {!TradingRouteCondition} value TradingRouteCondition value, defaults is new TradingRouteCondition()
   * @return {!object} Deserialized TradingRouteCondition value and its size
   */
  deserialize (value = new TradingRouteCondition()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingRouteCondition(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingRouteCondition(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingRouteConditionModel}
   * @param {!number} prev Previous TradingRouteCondition model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingRouteConditionModel };
