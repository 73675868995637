import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { ChartBarPeriodType } from '@api/fbe/core/ChartBarPeriodType';
import { ChartBarInfo, ChartBarsRequestParams } from '@models/ChartBars';
import { makeAutoObservable } from 'mobx';
import { ChartBarHistoryRangeRequest } from '@api/fbe/client/ChartBarHistoryRangeRequest';
import { Message, MessageType } from '@components/Message/Message';

class ChartBarsStore {
    errorTracker = new ErrorsTracker({ title: 'ChartBars' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ChartBarInfo[] | null = null;

    requestParams: ChartBarsRequestParams | null = null;

    isLoading: boolean = false;

    setData(value: ChartBarInfo[]) {
        this.data = value;
    }

    setRequestParams(value: ChartBarsRequestParams | null) {
        this.requestParams = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new ChartBarHistoryRangeRequest();
        if (this.requestParams) {
            request.PeriodType = new ChartBarPeriodType(this.requestParams.Period);
            request.Symbol = this.requestParams.Symbol;
            if (this.requestParams.TimeFrom) {
                request.From = this.requestParams.TimeFrom;
            }
            if (this.requestParams.TimeTo) {
                request.To = this.requestParams.TimeTo;
            }
        }
        try {
            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.setData(
                result.ChartBars.map((record, index) => ({
                    key: index,
                    Symbol: record.Symbol,
                    PeriodType: record.PeriodType.valueOf(),
                    OpenTime: record.OpenTime,
                    OpenPrice: record.OpenPrice,
                    HighPrice: record.HighPrice,
                    LowPrice: record.LowPrice,
                    ClosePrice: record.ClosePrice,
                    Volume: +record.Volume.toNumber(),
                })),
            );
        } catch (error) {
            this.setData([]);
            Message(MessageType.error, 'Can\'t execute data');
        }
        this.setIsLoading(false);
    }

    reset() {
        this.data = null;
        this.requestParams = null;
        this.isLoading = false;
    }
}

export const chartsBarsStore = new ChartBarsStore();
