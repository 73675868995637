import { makeAutoObservable } from 'mobx';
import { keyMap, t } from '@localization';
import { UUID } from '@api/fbe/uuid';
import { Timezone } from '@models';
import { SocketClient } from '@api/SocketClient';
import { ErrorsTracker } from '@api/errorsTracker';
import { TimezonesGetAllRequest } from '@api/fbe/manager/TimezonesGetAllRequest';
import { TimezoneCreateRequest } from '@api/fbe/manager/TimezoneCreateRequest';
import { TimezoneDeleteRequest } from '@api/fbe/manager/TimezoneDeleteRequest';
import { TimezoneModifyRequest } from '@api/fbe/manager/TimezoneModifyRequest';

export class TimezonesStore {
    errorTracker = new ErrorsTracker({ title: 'Timezones' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Timezone[] = [];

    isLoading: boolean = false;

    setData(value: Timezone[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            this.update({
                ...targetItem,
                SortOrder: newIndex,
            });
        } else {
            throw new Error(t(keyMap.messages.timezones.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new TimezonesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Timezones.map(
                (record): Timezone => ({
                    id: record.id.toString(),
                    SortOrder: record.SortOrder,
                    Name: record.Name,
                    Comment: record.Comment,
                    Description: record.Description,
                    Offset: record.Offset,
                    Daylight: record.Daylight,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: Timezone) {
        const request = new TimezoneCreateRequest();
        request.Name = record.Name;
        request.SortOrder = record.SortOrder;
        request.Comment = record.Comment;
        request.Description = record.Description;
        if (record.Offset !== null) request.Offset = record.Offset;
        if (record.Daylight !== null) request.Daylight = record.Daylight;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new TimezoneDeleteRequest();
        request.TimezoneId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: Timezone) {
        const request = new TimezoneModifyRequest();
        request.TimezoneId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        request.NewOffset = record.Offset;
        request.NewDaylight = record.Daylight;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const timezonesStore = new TimezonesStore();
export { timezonesStore };
