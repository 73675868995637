import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { History } from 'history';
import { jsonHash } from '@utils/json-hash';

export const createApplicationInsights = (params: {
    history: History;
    reactPlugin: ReactPlugin;
    instrumentationKey: string;
}) => {
    const { history, reactPlugin, instrumentationKey } = params;

    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: {
                    history,
                },
            },
        },
    });
    appInsights.loadAppInsights();

    const telemetryInitializer = (context: ITelemetryItem) => {
        context.data = context.data || {};

        context.data.ScreenSize = `${window.screen.width}X${window.screen.height}`;

        if (context.baseType === 'ExceptionData') {
            // pick some unique data related to exception description
            // data in `context` may be different on different stages/AI versions

            const uniqueIssueId = {
                url: context.baseData?.url || context.ext?.trace?.name,
                messages: context.baseData?.exceptions?.map((x) => x.message),
                callStack: context.baseData?.exceptions?.map((x) => x.stack),
                errorObj: String(context.baseData?.error),
                message: context.baseData?.message,
            };

            context.data.uniqueIssueIdHash = jsonHash(uniqueIssueId, 'sha1').slice(-10);
        }
    };

    appInsights.addTelemetryInitializer(telemetryInitializer);

    return appInsights;
};

export type ApplicationInsightsRef = {
    /** !! it may be post-initialized !! */
    appInsights: ApplicationInsights;
};

const _applicationInsights: ApplicationInsightsRef = {
    appInsights: undefined!,
};

/** get global application insights instance */
export const getApplicationInsightsRef = (): ApplicationInsightsRef => _applicationInsights;
export const _setApplicationInsights = (appInsights: ApplicationInsights) =>
    (_applicationInsights.appInsights = appInsights);
