
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ExportDatabaseParams} from './ExportDatabaseParams';
import {FieldModelExportDatabaseParams} from './ExportDatabaseParams';

/**
 * ExportServerSettings struct
 */
class ExportServerSettings {
  /**
   * Initialize struct
   * @param {!ExportDatabaseParams=} databaseParams
   * @param {!boolean=} syncPrices
   * @param {!number=} syncPricesInterval
   * @param {!boolean=} syncTrades
   * @param {!number=} syncTradesInterval
   * @param {!number=} syncMarginInterval
   * @param {!boolean=} syncDaily
   * @param {!number=} syncDailyInterval
   * @param {UUID=} platformId
   * @param {!number=} exportPort
   * @param {!boolean=} useSsl
   * @param {!boolean=} syncUsers
   * @param {!number=} syncUsersInterval
   * @constructor
   */
  constructor (databaseParams = new ExportDatabaseParams(), syncPrices = true, syncPricesInterval = new UInt64(1000000000, 0), syncTrades = true, syncTradesInterval = new UInt64(1000000000, 0), syncMarginInterval = new UInt64(4165425152, 13), syncDaily = true, syncDailyInterval = new UInt64(1000000000, 0), platformId = null, exportPort = 6170, useSsl = false, syncUsers = true, syncUsersInterval = new UInt64(1000000000, 0)) {
    this.DatabaseParams = databaseParams
    this.SyncPrices = syncPrices
    this.SyncPricesInterval = syncPricesInterval
    this.SyncTrades = syncTrades
    this.SyncTradesInterval = syncTradesInterval
    this.SyncMarginInterval = syncMarginInterval
    this.SyncDaily = syncDaily
    this.SyncDailyInterval = syncDailyInterval
    this.PlatformId = platformId
    this.ExportPort = exportPort
    this.UseSsl = useSsl
    this.SyncUsers = syncUsers
    this.SyncUsersInterval = syncUsersInterval
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ExportServerSettings}
   * @param {!ExportServerSettings} other Other struct
   * @returns {!ExportServerSettings} This struct
   */
  copy (other) {
    if (other.DatabaseParams != null) {
      this.DatabaseParams = ExportDatabaseParams.fromObject(other.DatabaseParams)
    } else {
      this.DatabaseParams = null
    }
    if (other.SyncPrices != null) {
      this.SyncPrices = other.SyncPrices
    } else {
      this.SyncPrices = null
    }
    if (other.SyncPricesInterval != null) {
      this.SyncPricesInterval = UInt64.fromNumber(other.SyncPricesInterval)
    } else {
      this.SyncPricesInterval = null
    }
    if (other.SyncTrades != null) {
      this.SyncTrades = other.SyncTrades
    } else {
      this.SyncTrades = null
    }
    if (other.SyncTradesInterval != null) {
      this.SyncTradesInterval = UInt64.fromNumber(other.SyncTradesInterval)
    } else {
      this.SyncTradesInterval = null
    }
    if (other.SyncMarginInterval != null) {
      this.SyncMarginInterval = UInt64.fromNumber(other.SyncMarginInterval)
    } else {
      this.SyncMarginInterval = null
    }
    if (other.SyncDaily != null) {
      this.SyncDaily = other.SyncDaily
    } else {
      this.SyncDaily = null
    }
    if (other.SyncDailyInterval != null) {
      this.SyncDailyInterval = UInt64.fromNumber(other.SyncDailyInterval)
    } else {
      this.SyncDailyInterval = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.ExportPort != null) {
      this.ExportPort = other.ExportPort
    } else {
      this.ExportPort = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    if (other.SyncUsers != null) {
      this.SyncUsers = other.SyncUsers
    } else {
      this.SyncUsers = null
    }
    if (other.SyncUsersInterval != null) {
      this.SyncUsersInterval = UInt64.fromNumber(other.SyncUsersInterval)
    } else {
      this.SyncUsersInterval = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ExportServerSettings}
   * @returns {!ExportServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ExportServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ExportServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ExportServerSettings}
   * @param {!ExportServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ExportServerSettings)) {
      throw new TypeError('Instance of ExportServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ExportServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      DatabaseParams: ((this.DatabaseParams != null) ? this.DatabaseParams : null),
      SyncPrices: ((this.SyncPrices != null) ? this.SyncPrices : null),
      SyncPricesInterval: ((this.SyncPricesInterval != null) ? this.SyncPricesInterval.toNumber() : null),
      SyncTrades: ((this.SyncTrades != null) ? this.SyncTrades : null),
      SyncTradesInterval: ((this.SyncTradesInterval != null) ? this.SyncTradesInterval.toNumber() : null),
      SyncMarginInterval: ((this.SyncMarginInterval != null) ? this.SyncMarginInterval.toNumber() : null),
      SyncDaily: ((this.SyncDaily != null) ? this.SyncDaily : null),
      SyncDailyInterval: ((this.SyncDailyInterval != null) ? this.SyncDailyInterval.toNumber() : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      ExportPort: ((this.ExportPort != null) ? this.ExportPort : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null),
      SyncUsers: ((this.SyncUsers != null) ? this.SyncUsers : null),
      SyncUsersInterval: ((this.SyncUsersInterval != null) ? this.SyncUsersInterval.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ExportServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ExportServerSettings} other Object value
   * @returns {!ExportServerSettings} Created struct
   */
  static fromObject (other) {
    return new ExportServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ExportServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ExportServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ExportServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 85
  }
}

export { ExportServerSettings };

/**
 * Fast Binary Encoding ExportServerSettings field model
 */
class FieldModelExportServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.DatabaseParams = new FieldModelExportDatabaseParams(buffer, 4 + 4)
    this.SyncPrices = new fbe.FieldModelBool(buffer, this.DatabaseParams.fbeOffset + this.DatabaseParams.fbeSize)
    this.SyncPricesInterval = new fbe.FieldModelUInt64(buffer, this.SyncPrices.fbeOffset + this.SyncPrices.fbeSize)
    this.SyncTrades = new fbe.FieldModelBool(buffer, this.SyncPricesInterval.fbeOffset + this.SyncPricesInterval.fbeSize)
    this.SyncTradesInterval = new fbe.FieldModelUInt64(buffer, this.SyncTrades.fbeOffset + this.SyncTrades.fbeSize)
    this.SyncMarginInterval = new fbe.FieldModelUInt64(buffer, this.SyncTradesInterval.fbeOffset + this.SyncTradesInterval.fbeSize)
    this.SyncDaily = new fbe.FieldModelBool(buffer, this.SyncMarginInterval.fbeOffset + this.SyncMarginInterval.fbeSize)
    this.SyncDailyInterval = new fbe.FieldModelUInt64(buffer, this.SyncDaily.fbeOffset + this.SyncDaily.fbeSize)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SyncDailyInterval.fbeOffset + this.SyncDailyInterval.fbeSize), buffer, this.SyncDailyInterval.fbeOffset + this.SyncDailyInterval.fbeSize)
    this.ExportPort = new fbe.FieldModelUInt16(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.ExportPort.fbeOffset + this.ExportPort.fbeSize)
    this.SyncUsers = new fbe.FieldModelBool(buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize)
    this.SyncUsersInterval = new fbe.FieldModelUInt64(buffer, this.SyncUsers.fbeOffset + this.SyncUsers.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.DatabaseParams.fbeSize + this.SyncPrices.fbeSize + this.SyncPricesInterval.fbeSize + this.SyncTrades.fbeSize + this.SyncTradesInterval.fbeSize + this.SyncMarginInterval.fbeSize + this.SyncDaily.fbeSize + this.SyncDailyInterval.fbeSize + this.PlatformId.fbeSize + this.ExportPort.fbeSize + this.UseSsl.fbeSize + this.SyncUsers.fbeSize + this.SyncUsersInterval.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.DatabaseParams.fbeExtra + this.SyncPrices.fbeExtra + this.SyncPricesInterval.fbeExtra + this.SyncTrades.fbeExtra + this.SyncTradesInterval.fbeExtra + this.SyncMarginInterval.fbeExtra + this.SyncDaily.fbeExtra + this.SyncDailyInterval.fbeExtra + this.PlatformId.fbeExtra + this.ExportPort.fbeExtra + this.UseSsl.fbeExtra + this.SyncUsers.fbeExtra + this.SyncUsersInterval.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelExportServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 85
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelExportServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelExportServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.DatabaseParams.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DatabaseParams.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseParams.fbeSize

    if ((fbeCurrentSize + this.SyncPrices.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncPrices.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncPrices.fbeSize

    if ((fbeCurrentSize + this.SyncPricesInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncPricesInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncPricesInterval.fbeSize

    if ((fbeCurrentSize + this.SyncTrades.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncTrades.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncTrades.fbeSize

    if ((fbeCurrentSize + this.SyncTradesInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncTradesInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncTradesInterval.fbeSize

    if ((fbeCurrentSize + this.SyncMarginInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncMarginInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncMarginInterval.fbeSize

    if ((fbeCurrentSize + this.SyncDaily.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncDaily.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncDaily.fbeSize

    if ((fbeCurrentSize + this.SyncDailyInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncDailyInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncDailyInterval.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.ExportPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExportPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.SyncUsers.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncUsers.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncUsers.fbeSize

    if ((fbeCurrentSize + this.SyncUsersInterval.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SyncUsersInterval.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncUsersInterval.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelExportServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelExportServerSettings}
   * @param {!ExportServerSettings} fbeValue Default value, defaults is new ExportServerSettings()
   * @returns {!ExportServerSettings} ExportServerSettings value
   */
  get (fbeValue = new ExportServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelExportServerSettings}
   * @param {!ExportServerSettings} fbeValue ExportServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.DatabaseParams.fbeSize) <= fbeStructSize) {
      fbeValue.DatabaseParams = this.DatabaseParams.get()
    } else {
      fbeValue.DatabaseParams = new ExportDatabaseParams()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DatabaseParams.fbeSize

    if ((fbeCurrentSize + this.SyncPrices.fbeSize) <= fbeStructSize) {
      fbeValue.SyncPrices = this.SyncPrices.get(true)
    } else {
      fbeValue.SyncPrices = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncPrices.fbeSize

    if ((fbeCurrentSize + this.SyncPricesInterval.fbeSize) <= fbeStructSize) {
      fbeValue.SyncPricesInterval = this.SyncPricesInterval.get(new UInt64(1000000000, 0))
    } else {
      fbeValue.SyncPricesInterval = new UInt64(1000000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncPricesInterval.fbeSize

    if ((fbeCurrentSize + this.SyncTrades.fbeSize) <= fbeStructSize) {
      fbeValue.SyncTrades = this.SyncTrades.get(true)
    } else {
      fbeValue.SyncTrades = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncTrades.fbeSize

    if ((fbeCurrentSize + this.SyncTradesInterval.fbeSize) <= fbeStructSize) {
      fbeValue.SyncTradesInterval = this.SyncTradesInterval.get(new UInt64(1000000000, 0))
    } else {
      fbeValue.SyncTradesInterval = new UInt64(1000000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncTradesInterval.fbeSize

    if ((fbeCurrentSize + this.SyncMarginInterval.fbeSize) <= fbeStructSize) {
      fbeValue.SyncMarginInterval = this.SyncMarginInterval.get(new UInt64(4165425152, 13))
    } else {
      fbeValue.SyncMarginInterval = new UInt64(4165425152, 13)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncMarginInterval.fbeSize

    if ((fbeCurrentSize + this.SyncDaily.fbeSize) <= fbeStructSize) {
      fbeValue.SyncDaily = this.SyncDaily.get(true)
    } else {
      fbeValue.SyncDaily = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncDaily.fbeSize

    if ((fbeCurrentSize + this.SyncDailyInterval.fbeSize) <= fbeStructSize) {
      fbeValue.SyncDailyInterval = this.SyncDailyInterval.get(new UInt64(1000000000, 0))
    } else {
      fbeValue.SyncDailyInterval = new UInt64(1000000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncDailyInterval.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.ExportPort.fbeSize) <= fbeStructSize) {
      fbeValue.ExportPort = this.ExportPort.get(6170)
    } else {
      fbeValue.ExportPort = 6170
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportPort.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get(false)
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.SyncUsers.fbeSize) <= fbeStructSize) {
      fbeValue.SyncUsers = this.SyncUsers.get(true)
    } else {
      fbeValue.SyncUsers = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncUsers.fbeSize

    if ((fbeCurrentSize + this.SyncUsersInterval.fbeSize) <= fbeStructSize) {
      fbeValue.SyncUsersInterval = this.SyncUsersInterval.get(new UInt64(1000000000, 0))
    } else {
      fbeValue.SyncUsersInterval = new UInt64(1000000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SyncUsersInterval.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelExportServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelExportServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelExportServerSettings}
   * @param {!ExportServerSettings} fbeValue ExportServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelExportServerSettings}
   * @param {ExportServerSettings} fbeValue ExportServerSettings value
   */
  setFields (fbeValue) {
    this.DatabaseParams.set(fbeValue.DatabaseParams)
    this.SyncPrices.set(fbeValue.SyncPrices)
    this.SyncPricesInterval.set(fbeValue.SyncPricesInterval)
    this.SyncTrades.set(fbeValue.SyncTrades)
    this.SyncTradesInterval.set(fbeValue.SyncTradesInterval)
    this.SyncMarginInterval.set(fbeValue.SyncMarginInterval)
    this.SyncDaily.set(fbeValue.SyncDaily)
    this.SyncDailyInterval.set(fbeValue.SyncDailyInterval)
    this.PlatformId.set(fbeValue.PlatformId)
    this.ExportPort.set(fbeValue.ExportPort)
    this.UseSsl.set(fbeValue.UseSsl)
    this.SyncUsers.set(fbeValue.SyncUsers)
    this.SyncUsersInterval.set(fbeValue.SyncUsersInterval)
  }
}

export { FieldModelExportServerSettings };

/**
 * Fast Binary Encoding ExportServerSettings model
 */
class ExportServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelExportServerSettings(this.buffer, 4)
  }

  /**
   * Get the ExportServerSettings model
   * @this {!ExportServerSettingsModel}
   * @returns {!FieldModelExportServerSettings} model ExportServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ExportServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ExportServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ExportServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ExportServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelExportServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ExportServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ExportServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ExportServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ExportServerSettingsModel}
   * @param {!ExportServerSettings} value ExportServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ExportServerSettingsModel}
   * @param {!ExportServerSettings} value ExportServerSettings value, defaults is new ExportServerSettings()
   * @return {!object} Deserialized ExportServerSettings value and its size
   */
  deserialize (value = new ExportServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ExportServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ExportServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ExportServerSettingsModel}
   * @param {!number} prev Previous ExportServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ExportServerSettingsModel };
