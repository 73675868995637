import { Dispatch, useEffect, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { SymbolsGroup } from '@models';
import { CheckboxValuesT } from '@components/TableExt/RowFilters/types';
import { IFormContext, OperationType } from '../types/commonTypes';
import { getTextColorByBGC } from 'utils';

export const defineSymbolGroupCellColors = (symbolGroup?: SymbolsGroup) => ({
    backgroundColor: symbolGroup ? symbolGroup.Color : undefined,
    color: symbolGroup ? getTextColorByBGC(symbolGroup.Color) : undefined,
});

export const numberInputFormatter = (
    value: number | string | undefined,
    info: {
        userTyping: boolean;
        input: string;
    },
): string => {
    if (value === undefined || value === '') return '';
    if ((value === '0' || value === 0) && info.input === '-0') {
        return '-0';
    }
    if (info.input === '-0') {
        return '0';
    }
    if (info.input === '-0.' || info.input === '-0,') {
        return '0';
    }
    return `${value}`.replace(',', '.');
};

export const getBoolFilterData: CheckboxValuesT = [
    { title: 'Enabled', compareValue: true },
    { title: 'Disabled', compareValue: false },
];

export const getBoolFilterDataNullable: CheckboxValuesT = [
    ...getBoolFilterData,
    {
        title: 'Not Set',
        compareValue: '',
    },
];

export const getBoolFilterDataYesNo: CheckboxValuesT = [
    { title: 'Yes', compareValue: true },
    { title: 'No', compareValue: false },
];

export const getYesNoFromBool = (value: boolean | null | undefined): string => {
    if (typeof value !== 'boolean') return '';
    return value ? 'Yes' : 'No';
};

export function openEditForm<EntityT>(
    row: EntityT,
    context: IFormContext<EntityT>,
    setCurrentRow: Dispatch<number | string | null>,
    rowKey?: string,
) {
    const key = rowKey || 'key';
    if (row[key] !== undefined) {
        if (context?.formData?.currentRow && context?.formData?.currentRow[key] === row[key]) {
            context?.setFormData(undefined);
            setCurrentRow(null);
        } else {
            context!.setFormData({
                type: OperationType.update,
                currentRow: row,
            });
            setCurrentRow(row[key]);
        }
    } else {
        context!.setFormData({
            type: OperationType.update,
            currentRow: row,
        });
    }
}

export function openCreateForm<EntityT>(context: IFormContext<EntityT>, setCurrentRow: Dispatch<number | null>) {
    setCurrentRow(null);
    context.setFormData({
        type: OperationType.create,
    });
}

export const useReaction = <T, U = T>(
    what: () => T,
    opts: {
        transform?: (from: T) => U;
    } = {},
): [U, () => void] => {
    const [value, setValue] = useState<T>(() => what());
    const disposeRef = useRef(() => {});

    useEffect(() => {
        const dispose = (disposeRef.current = reaction(what, (newValue) => {
            setValue(opts.transform ? opts.transform(newValue) : (newValue as any));
        }));

        return dispose;
    }, []);

    return [value as any, disposeRef.current];
};

type updateFormDataEffectType = {
    updateFormData: IFormContext<any>;
    dataSource: object[];
    rowKey: string;
    rowKeyValue: number | string | null | undefined;
    currentTab?: string;
};

export const updateFormDataEffect = (config: updateFormDataEffectType) => {
    const { updateFormData, dataSource, rowKey = 'id', rowKeyValue, currentTab } = config;
    if (updateFormData.formData?.type === OperationType.update && currentTab === updateFormData.currentTab) {
        const updatedRow = dataSource.find((item) => item[rowKey] === rowKeyValue);
        if (updatedRow) {
            updateFormData.setFormData({
                ...updateFormData.formData,
                currentRow: updatedRow,
            });
        } else {
            updateFormData.setFormData(undefined);
        }
    }
};

export const loginsFilter = (logins: string[] | number[]): string[] =>
    logins
        .map((item) => (item ? `${item}`.replace(/\D/g, '') : ''))
        .filter((item) => !!item && +item < Number.MAX_SAFE_INTEGER);
