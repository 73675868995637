
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigResponse} from './ConfigResponse';
import {FieldModelConfigResponse} from './ConfigResponse';
import {AccountGroup} from '../core/AccountGroup';
import {FieldModelAccountGroup} from '../core/AccountGroup';

/**
 * AccountGroupGetResponse struct
 */
class AccountGroupGetResponse extends ConfigResponse {
  /**
   * Initialize struct
   * @param {!ConfigResponse=} parent
   * @param {!AccountGroup=} accountGroup
   * @constructor
   */
  constructor (parent = new ConfigResponse(), accountGroup = new AccountGroup()) {
    super()
    super.copy(parent)
    this.AccountGroup = accountGroup
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountGroupGetResponse}
   * @param {!AccountGroupGetResponse} other Other struct
   * @returns {!AccountGroupGetResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.AccountGroup != null) {
      this.AccountGroup = AccountGroup.fromObject(other.AccountGroup)
    } else {
      this.AccountGroup = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountGroupGetResponse}
   * @returns {!AccountGroupGetResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountGroupGetResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountGroupGetResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountGroupGetResponse}
   * @param {!AccountGroupGetResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountGroupGetResponse)) {
      throw new TypeError('Instance of AccountGroupGetResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountGroupGetResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      AccountGroup: ((this.AccountGroup != null) ? this.AccountGroup : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountGroupGetResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountGroupGetResponse} other Object value
   * @returns {!AccountGroupGetResponse} Created struct
   */
  static fromObject (other) {
    return new AccountGroupGetResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountGroupGetResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountGroupGetResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountGroupGetResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6178
  }
}

export { AccountGroupGetResponse };

/**
 * Fast Binary Encoding AccountGroupGetResponse field model
 */
class FieldModelAccountGroupGetResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigResponse(buffer, 4 + 4)
    this.AccountGroup = new FieldModelAccountGroup(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
  }

  /**
   * Get the ConfigResponse field model
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!FieldModelConfigResponse} ConfigResponse field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.AccountGroup.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.AccountGroup.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountGroupGetResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6178
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroup.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroup.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroup.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!AccountGroupGetResponse} fbeValue Default value, defaults is new AccountGroupGetResponse()
   * @returns {!AccountGroupGetResponse} AccountGroupGetResponse value
   */
  get (fbeValue = new AccountGroupGetResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!AccountGroupGetResponse} fbeValue AccountGroupGetResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.AccountGroup.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroup = this.AccountGroup.get()
    } else {
      fbeValue.AccountGroup = new AccountGroup()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroup.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountGroupGetResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {!AccountGroupGetResponse} fbeValue AccountGroupGetResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountGroupGetResponse}
   * @param {AccountGroupGetResponse} fbeValue AccountGroupGetResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.AccountGroup.set(fbeValue.AccountGroup)
  }
}

export { FieldModelAccountGroupGetResponse };

/**
 * Fast Binary Encoding AccountGroupGetResponse model
 */
class AccountGroupGetResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountGroupGetResponse(this.buffer, 4)
  }

  /**
   * Get the AccountGroupGetResponse model
   * @this {!AccountGroupGetResponseModel}
   * @returns {!FieldModelAccountGroupGetResponse} model AccountGroupGetResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountGroupGetResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountGroupGetResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountGroupGetResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountGroupGetResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountGroupGetResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountGroupGetResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountGroupGetResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountGroupGetResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountGroupGetResponseModel}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountGroupGetResponseModel}
   * @param {!AccountGroupGetResponse} value AccountGroupGetResponse value, defaults is new AccountGroupGetResponse()
   * @return {!object} Deserialized AccountGroupGetResponse value and its size
   */
  deserialize (value = new AccountGroupGetResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountGroupGetResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountGroupGetResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountGroupGetResponseModel}
   * @param {!number} prev Previous AccountGroupGetResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountGroupGetResponseModel };
