export const kebabilize = (str: string): string => {
    if (!str) return str;
    const matches = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
    if (matches) {
        return matches.join('-').toLowerCase();
    }
    return str;
};

export const camelify = (str: string): string => {
    if (!str) return str;
    const letters = str.split('');
    const camelledLetters: string[] = [];
    let index = 0;
    while (index < letters.length) {
        if (letters[index] === '-' && letters[index + 1]) {
            camelledLetters.push(letters[index + 1].toUpperCase());
            index += 2;
        } else {
            camelledLetters.push(letters[index]);
            index += 1;
        }
    }
    return camelledLetters.join('');
};

export const getMessageFromResponseError = (e) => e.Reason || e.reason || e.message || '';