
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradeEventType} from './TradeEventType';
import {FieldModelTradeEventType} from './TradeEventType';
import {ReasonCode} from './ReasonCode';
import {FieldModelReasonCode} from './ReasonCode';
import {OrderEvent} from './OrderEvent';
import {FieldModelOrderEvent} from './OrderEvent';
import {OrderStable} from './OrderStable';
import {FieldModelOrderStable} from './OrderStable';
import {AccountMarginStable} from './AccountMarginStable';
import {FieldModelAccountMarginStable} from './AccountMarginStable';
import {OrderSnapshot} from './OrderSnapshot';
import {FieldModelOrderSnapshot} from './OrderSnapshot';
import {AccountMarginSnapshot} from './AccountMarginSnapshot';
import {FieldModelAccountMarginSnapshot} from './AccountMarginSnapshot';
import {TickSnapshot} from './TickSnapshot';
import {FieldModelTickSnapshot} from './TickSnapshot';
import {TransactionDuration} from './TransactionDuration';
import {FieldModelTransactionDuration} from './TransactionDuration';

/**
 * TradeEvent struct
 */
class TradeEvent {
  /**
   * Initialize struct
   * @param {!number=} recordId
   * @param {!number=} transactionId
   * @param {!TradeEventType=} tradeEventType
   * @param {!ReasonCode=} reasonCode
   * @param {!Date=} transactionTimestamp
   * @param {number=} accountLogin
   * @param {number=} orderId
   * @param {number=} managerLogin
   * @param {string=} comment
   * @param {OrderEvent=} orderEvent
   * @param {OrderStable=} orderStable
   * @param {AccountMarginStable=} accountMarginStable
   * @param {OrderSnapshot=} orderSnapshot
   * @param {AccountMarginSnapshot=} accountMarginSnapshot
   * @param {UUID=} platformId
   * @param {TickSnapshot=} tickSnapshot
   * @param {TransactionDuration=} transactionDuration
   * @param {Date=} platformTransactionLocalTimestamp
   * @constructor
   */
  constructor (recordId = new UInt64(0, 0), transactionId = new UInt64(0, 0), tradeEventType = new TradeEventType(), reasonCode = new ReasonCode(), transactionTimestamp = new Date(0), accountLogin = null, orderId = null, managerLogin = null, comment = null, orderEvent = null, orderStable = null, accountMarginStable = null, orderSnapshot = null, accountMarginSnapshot = null, platformId = null, tickSnapshot = null, transactionDuration = null, platformTransactionLocalTimestamp = null) {
    this.RecordId = recordId
    this.TransactionId = transactionId
    this.TradeEventType = tradeEventType
    this.ReasonCode = reasonCode
    this.TransactionTimestamp = transactionTimestamp
    this.AccountLogin = accountLogin
    this.OrderId = orderId
    this.ManagerLogin = managerLogin
    this.Comment = comment
    this.OrderEvent = orderEvent
    this.OrderStable = orderStable
    this.AccountMarginStable = accountMarginStable
    this.OrderSnapshot = orderSnapshot
    this.AccountMarginSnapshot = accountMarginSnapshot
    this.PlatformId = platformId
    this.TickSnapshot = tickSnapshot
    this.TransactionDuration = transactionDuration
    this.PlatformTransactionLocalTimestamp = platformTransactionLocalTimestamp
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradeEvent}
   * @param {!TradeEvent} other Other struct
   * @returns {!TradeEvent} This struct
   */
  copy (other) {
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    if (other.TransactionId != null) {
      this.TransactionId = UInt64.fromNumber(other.TransactionId)
    } else {
      this.TransactionId = null
    }
    if (other.TradeEventType != null) {
      this.TradeEventType = TradeEventType.fromObject(other.TradeEventType)
    } else {
      this.TradeEventType = null
    }
    if (other.ReasonCode != null) {
      this.ReasonCode = ReasonCode.fromObject(other.ReasonCode)
    } else {
      this.ReasonCode = null
    }
    if (other.TransactionTimestamp != null) {
      if (other.TransactionTimestamp instanceof Date) {
        this.TransactionTimestamp = new Date(other.TransactionTimestamp.getTime())
      } else {
        this.TransactionTimestamp = new Date(Math.round(other.TransactionTimestamp / 1000000))
      }
    } else {
      this.TransactionTimestamp = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = UInt64.fromNumber(other.AccountLogin)
    } else {
      this.AccountLogin = null
    }
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.ManagerLogin != null) {
      this.ManagerLogin = UInt64.fromNumber(other.ManagerLogin)
    } else {
      this.ManagerLogin = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.OrderEvent != null) {
      this.OrderEvent = OrderEvent.fromObject(other.OrderEvent)
    } else {
      this.OrderEvent = null
    }
    if (other.OrderStable != null) {
      this.OrderStable = OrderStable.fromObject(other.OrderStable)
    } else {
      this.OrderStable = null
    }
    if (other.AccountMarginStable != null) {
      this.AccountMarginStable = AccountMarginStable.fromObject(other.AccountMarginStable)
    } else {
      this.AccountMarginStable = null
    }
    if (other.OrderSnapshot != null) {
      this.OrderSnapshot = OrderSnapshot.fromObject(other.OrderSnapshot)
    } else {
      this.OrderSnapshot = null
    }
    if (other.AccountMarginSnapshot != null) {
      this.AccountMarginSnapshot = AccountMarginSnapshot.fromObject(other.AccountMarginSnapshot)
    } else {
      this.AccountMarginSnapshot = null
    }
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.TickSnapshot != null) {
      this.TickSnapshot = TickSnapshot.fromObject(other.TickSnapshot)
    } else {
      this.TickSnapshot = null
    }
    if (other.TransactionDuration != null) {
      this.TransactionDuration = TransactionDuration.fromObject(other.TransactionDuration)
    } else {
      this.TransactionDuration = null
    }
    if (other.PlatformTransactionLocalTimestamp != null) {
      if (other.PlatformTransactionLocalTimestamp instanceof Date) {
        this.PlatformTransactionLocalTimestamp = new Date(other.PlatformTransactionLocalTimestamp.getTime())
      } else {
        this.PlatformTransactionLocalTimestamp = new Date(Math.round(other.PlatformTransactionLocalTimestamp / 1000000))
      }
    } else {
      this.PlatformTransactionLocalTimestamp = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradeEvent}
   * @returns {!TradeEvent} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradeEventModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradeEventModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradeEvent}
   * @param {!TradeEvent} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeEvent)) {
      throw new TypeError('Instance of TradeEvent is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradeEvent}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null),
      TransactionId: ((this.TransactionId != null) ? this.TransactionId.toNumber() : null),
      TradeEventType: ((this.TradeEventType != null) ? this.TradeEventType : null),
      ReasonCode: ((this.ReasonCode != null) ? this.ReasonCode : null),
      TransactionTimestamp: ((this.TransactionTimestamp != null) ? (this.TransactionTimestamp.getTime() * 1000000) : null),
      AccountLogin: ((this.AccountLogin != null) ? this.AccountLogin.toNumber() : null),
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      ManagerLogin: ((this.ManagerLogin != null) ? this.ManagerLogin.toNumber() : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      OrderEvent: ((this.OrderEvent != null) ? this.OrderEvent : null),
      OrderStable: ((this.OrderStable != null) ? this.OrderStable : null),
      AccountMarginStable: ((this.AccountMarginStable != null) ? this.AccountMarginStable : null),
      OrderSnapshot: ((this.OrderSnapshot != null) ? this.OrderSnapshot : null),
      AccountMarginSnapshot: ((this.AccountMarginSnapshot != null) ? this.AccountMarginSnapshot : null),
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      TickSnapshot: ((this.TickSnapshot != null) ? this.TickSnapshot : null),
      TransactionDuration: ((this.TransactionDuration != null) ? this.TransactionDuration : null),
      PlatformTransactionLocalTimestamp: ((this.PlatformTransactionLocalTimestamp != null) ? (this.PlatformTransactionLocalTimestamp.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradeEvent.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradeEvent} other Object value
   * @returns {!TradeEvent} Created struct
   */
  static fromObject (other) {
    return new TradeEvent().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradeEvent}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradeEvent.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradeEvent}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6520
  }
}

export { TradeEvent };

/**
 * Fast Binary Encoding TradeEvent field model
 */
class FieldModelTradeEvent extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.RecordId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.TransactionId = new fbe.FieldModelUInt64(buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize)
    this.TradeEventType = new FieldModelTradeEventType(buffer, this.TransactionId.fbeOffset + this.TransactionId.fbeSize)
    this.ReasonCode = new FieldModelReasonCode(buffer, this.TradeEventType.fbeOffset + this.TradeEventType.fbeSize)
    this.TransactionTimestamp = new fbe.FieldModelTimestamp(buffer, this.ReasonCode.fbeOffset + this.ReasonCode.fbeSize)
    this.AccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.TransactionTimestamp.fbeOffset + this.TransactionTimestamp.fbeSize), buffer, this.TransactionTimestamp.fbeOffset + this.TransactionTimestamp.fbeSize)
    this.OrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize), buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize)
    this.ManagerLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.Comment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ManagerLogin.fbeOffset + this.ManagerLogin.fbeSize), buffer, this.ManagerLogin.fbeOffset + this.ManagerLogin.fbeSize)
    this.OrderEvent = new fbe.FieldModelOptional(new FieldModelOrderEvent(buffer, this.Comment.fbeOffset + this.Comment.fbeSize), buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.OrderStable = new fbe.FieldModelOptional(new FieldModelOrderStable(buffer, this.OrderEvent.fbeOffset + this.OrderEvent.fbeSize), buffer, this.OrderEvent.fbeOffset + this.OrderEvent.fbeSize)
    this.AccountMarginStable = new fbe.FieldModelOptional(new FieldModelAccountMarginStable(buffer, this.OrderStable.fbeOffset + this.OrderStable.fbeSize), buffer, this.OrderStable.fbeOffset + this.OrderStable.fbeSize)
    this.OrderSnapshot = new fbe.FieldModelOptional(new FieldModelOrderSnapshot(buffer, this.AccountMarginStable.fbeOffset + this.AccountMarginStable.fbeSize), buffer, this.AccountMarginStable.fbeOffset + this.AccountMarginStable.fbeSize)
    this.AccountMarginSnapshot = new fbe.FieldModelOptional(new FieldModelAccountMarginSnapshot(buffer, this.OrderSnapshot.fbeOffset + this.OrderSnapshot.fbeSize), buffer, this.OrderSnapshot.fbeOffset + this.OrderSnapshot.fbeSize)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.AccountMarginSnapshot.fbeOffset + this.AccountMarginSnapshot.fbeSize), buffer, this.AccountMarginSnapshot.fbeOffset + this.AccountMarginSnapshot.fbeSize)
    this.TickSnapshot = new fbe.FieldModelOptional(new FieldModelTickSnapshot(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize), buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
    this.TransactionDuration = new fbe.FieldModelOptional(new FieldModelTransactionDuration(buffer, this.TickSnapshot.fbeOffset + this.TickSnapshot.fbeSize), buffer, this.TickSnapshot.fbeOffset + this.TickSnapshot.fbeSize)
    this.PlatformTransactionLocalTimestamp = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.TransactionDuration.fbeOffset + this.TransactionDuration.fbeSize), buffer, this.TransactionDuration.fbeOffset + this.TransactionDuration.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.RecordId.fbeSize + this.TransactionId.fbeSize + this.TradeEventType.fbeSize + this.ReasonCode.fbeSize + this.TransactionTimestamp.fbeSize + this.AccountLogin.fbeSize + this.OrderId.fbeSize + this.ManagerLogin.fbeSize + this.Comment.fbeSize + this.OrderEvent.fbeSize + this.OrderStable.fbeSize + this.AccountMarginStable.fbeSize + this.OrderSnapshot.fbeSize + this.AccountMarginSnapshot.fbeSize + this.PlatformId.fbeSize + this.TickSnapshot.fbeSize + this.TransactionDuration.fbeSize + this.PlatformTransactionLocalTimestamp.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.RecordId.fbeExtra + this.TransactionId.fbeExtra + this.TradeEventType.fbeExtra + this.ReasonCode.fbeExtra + this.TransactionTimestamp.fbeExtra + this.AccountLogin.fbeExtra + this.OrderId.fbeExtra + this.ManagerLogin.fbeExtra + this.Comment.fbeExtra + this.OrderEvent.fbeExtra + this.OrderStable.fbeExtra + this.AccountMarginStable.fbeExtra + this.OrderSnapshot.fbeExtra + this.AccountMarginSnapshot.fbeExtra + this.PlatformId.fbeExtra + this.TickSnapshot.fbeExtra + this.TransactionDuration.fbeExtra + this.PlatformTransactionLocalTimestamp.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradeEvent.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6520
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradeEvent}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradeEvent}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.TransactionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionId.fbeSize

    if ((fbeCurrentSize + this.TradeEventType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeEventType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEventType.fbeSize

    if ((fbeCurrentSize + this.ReasonCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReasonCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReasonCode.fbeSize

    if ((fbeCurrentSize + this.TransactionTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTimestamp.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ManagerLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLogin.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.OrderEvent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderEvent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderEvent.fbeSize

    if ((fbeCurrentSize + this.OrderStable.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStable.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStable.fbeSize

    if ((fbeCurrentSize + this.AccountMarginStable.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginStable.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginStable.fbeSize

    if ((fbeCurrentSize + this.OrderSnapshot.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSnapshot.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSnapshot.fbeSize

    if ((fbeCurrentSize + this.AccountMarginSnapshot.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginSnapshot.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginSnapshot.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.TickSnapshot.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TickSnapshot.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TickSnapshot.fbeSize

    if ((fbeCurrentSize + this.TransactionDuration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionDuration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionDuration.fbeSize

    if ((fbeCurrentSize + this.PlatformTransactionLocalTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformTransactionLocalTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformTransactionLocalTimestamp.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradeEvent}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradeEvent}
   * @param {!TradeEvent} fbeValue Default value, defaults is new TradeEvent()
   * @returns {!TradeEvent} TradeEvent value
   */
  get (fbeValue = new TradeEvent()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradeEvent}
   * @param {!TradeEvent} fbeValue TradeEvent value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.TransactionId.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionId = this.TransactionId.get()
    } else {
      fbeValue.TransactionId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionId.fbeSize

    if ((fbeCurrentSize + this.TradeEventType.fbeSize) <= fbeStructSize) {
      fbeValue.TradeEventType = this.TradeEventType.get()
    } else {
      fbeValue.TradeEventType = new TradeEventType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEventType.fbeSize

    if ((fbeCurrentSize + this.ReasonCode.fbeSize) <= fbeStructSize) {
      fbeValue.ReasonCode = this.ReasonCode.get()
    } else {
      fbeValue.ReasonCode = new ReasonCode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReasonCode.fbeSize

    if ((fbeCurrentSize + this.TransactionTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionTimestamp = this.TransactionTimestamp.get()
    } else {
      fbeValue.TransactionTimestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTimestamp.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLogin = this.AccountLogin.get()
    } else {
      fbeValue.AccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ManagerLogin.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerLogin = this.ManagerLogin.get()
    } else {
      fbeValue.ManagerLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLogin.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.OrderEvent.fbeSize) <= fbeStructSize) {
      fbeValue.OrderEvent = this.OrderEvent.get()
    } else {
      fbeValue.OrderEvent = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderEvent.fbeSize

    if ((fbeCurrentSize + this.OrderStable.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStable = this.OrderStable.get()
    } else {
      fbeValue.OrderStable = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStable.fbeSize

    if ((fbeCurrentSize + this.AccountMarginStable.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginStable = this.AccountMarginStable.get()
    } else {
      fbeValue.AccountMarginStable = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginStable.fbeSize

    if ((fbeCurrentSize + this.OrderSnapshot.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSnapshot = this.OrderSnapshot.get()
    } else {
      fbeValue.OrderSnapshot = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSnapshot.fbeSize

    if ((fbeCurrentSize + this.AccountMarginSnapshot.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginSnapshot = this.AccountMarginSnapshot.get()
    } else {
      fbeValue.AccountMarginSnapshot = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginSnapshot.fbeSize

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.TickSnapshot.fbeSize) <= fbeStructSize) {
      fbeValue.TickSnapshot = this.TickSnapshot.get()
    } else {
      fbeValue.TickSnapshot = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TickSnapshot.fbeSize

    if ((fbeCurrentSize + this.TransactionDuration.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionDuration = this.TransactionDuration.get()
    } else {
      fbeValue.TransactionDuration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionDuration.fbeSize

    if ((fbeCurrentSize + this.PlatformTransactionLocalTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformTransactionLocalTimestamp = this.PlatformTransactionLocalTimestamp.get()
    } else {
      fbeValue.PlatformTransactionLocalTimestamp = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformTransactionLocalTimestamp.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradeEvent}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradeEvent}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradeEvent}
   * @param {!TradeEvent} fbeValue TradeEvent value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradeEvent}
   * @param {TradeEvent} fbeValue TradeEvent value
   */
  setFields (fbeValue) {
    this.RecordId.set(fbeValue.RecordId)
    this.TransactionId.set(fbeValue.TransactionId)
    this.TradeEventType.set(fbeValue.TradeEventType)
    this.ReasonCode.set(fbeValue.ReasonCode)
    this.TransactionTimestamp.set(fbeValue.TransactionTimestamp)
    this.AccountLogin.set(fbeValue.AccountLogin)
    this.OrderId.set(fbeValue.OrderId)
    this.ManagerLogin.set(fbeValue.ManagerLogin)
    this.Comment.set(fbeValue.Comment)
    this.OrderEvent.set(fbeValue.OrderEvent)
    this.OrderStable.set(fbeValue.OrderStable)
    this.AccountMarginStable.set(fbeValue.AccountMarginStable)
    this.OrderSnapshot.set(fbeValue.OrderSnapshot)
    this.AccountMarginSnapshot.set(fbeValue.AccountMarginSnapshot)
    this.PlatformId.set(fbeValue.PlatformId)
    this.TickSnapshot.set(fbeValue.TickSnapshot)
    this.TransactionDuration.set(fbeValue.TransactionDuration)
    this.PlatformTransactionLocalTimestamp.set(fbeValue.PlatformTransactionLocalTimestamp)
  }
}

export { FieldModelTradeEvent };

/**
 * Fast Binary Encoding TradeEvent model
 */
class TradeEventModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradeEvent(this.buffer, 4)
  }

  /**
   * Get the TradeEvent model
   * @this {!TradeEventModel}
   * @returns {!FieldModelTradeEvent} model TradeEvent model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradeEventModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradeEventModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradeEventModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradeEventModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradeEvent.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradeEventModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradeEventModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradeEventModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradeEventModel}
   * @param {!TradeEvent} value TradeEvent value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradeEventModel}
   * @param {!TradeEvent} value TradeEvent value, defaults is new TradeEvent()
   * @return {!object} Deserialized TradeEvent value and its size
   */
  deserialize (value = new TradeEvent()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradeEvent(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradeEvent(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradeEventModel}
   * @param {!number} prev Previous TradeEvent model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradeEventModel };
