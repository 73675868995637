
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ChartBarPeriodType} from './ChartBarPeriodType';
import {FieldModelChartBarPeriodType} from './ChartBarPeriodType';

/**
 * ChartBarUpdate struct
 */
class ChartBarUpdate {
  /**
   * Initialize struct
   * @param {!UUID=} symbolId
   * @param {!ChartBarPeriodType=} periodType
   * @param {!Date=} openTime
   * @param {number=} highPrice
   * @param {number=} lowPrice
   * @param {!number=} closePrice
   * @param {!number=} volume
   * @param {!number=} uniqueVersion
   * @constructor
   */
  constructor (symbolId = new UUID(), periodType = new ChartBarPeriodType(), openTime = new Date(0), highPrice = null, lowPrice = null, closePrice = 0.0, volume = new UInt64(0, 0), uniqueVersion = new UInt64(0, 0)) {
    this.SymbolId = symbolId
    this.PeriodType = periodType
    this.OpenTime = openTime
    this.HighPrice = highPrice
    this.LowPrice = lowPrice
    this.ClosePrice = closePrice
    this.Volume = volume
    this.UniqueVersion = uniqueVersion
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ChartBarUpdate}
   * @param {!ChartBarUpdate} other Other struct
   * @returns {!ChartBarUpdate} This struct
   */
  copy (other) {
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.PeriodType != null) {
      this.PeriodType = ChartBarPeriodType.fromObject(other.PeriodType)
    } else {
      this.PeriodType = null
    }
    if (other.OpenTime != null) {
      if (other.OpenTime instanceof Date) {
        this.OpenTime = new Date(other.OpenTime.getTime())
      } else {
        this.OpenTime = new Date(Math.round(other.OpenTime / 1000000))
      }
    } else {
      this.OpenTime = null
    }
    if (other.HighPrice != null) {
      this.HighPrice = other.HighPrice
    } else {
      this.HighPrice = null
    }
    if (other.LowPrice != null) {
      this.LowPrice = other.LowPrice
    } else {
      this.LowPrice = null
    }
    if (other.ClosePrice != null) {
      this.ClosePrice = other.ClosePrice
    } else {
      this.ClosePrice = null
    }
    if (other.Volume != null) {
      this.Volume = UInt64.fromNumber(other.Volume)
    } else {
      this.Volume = null
    }
    if (other.UniqueVersion != null) {
      this.UniqueVersion = UInt64.fromNumber(other.UniqueVersion)
    } else {
      this.UniqueVersion = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ChartBarUpdate}
   * @returns {!ChartBarUpdate} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ChartBarUpdateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ChartBarUpdateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ChartBarUpdate}
   * @param {!ChartBarUpdate} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarUpdate)) {
      throw new TypeError('Instance of ChartBarUpdate is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ChartBarUpdate}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      PeriodType: ((this.PeriodType != null) ? this.PeriodType : null),
      OpenTime: ((this.OpenTime != null) ? (this.OpenTime.getTime() * 1000000) : null),
      HighPrice: ((this.HighPrice != null) ? this.HighPrice : null),
      LowPrice: ((this.LowPrice != null) ? this.LowPrice : null),
      ClosePrice: ((this.ClosePrice != null) ? this.ClosePrice : null),
      Volume: ((this.Volume != null) ? this.Volume.toNumber() : null),
      UniqueVersion: ((this.UniqueVersion != null) ? this.UniqueVersion.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ChartBarUpdate.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ChartBarUpdate} other Object value
   * @returns {!ChartBarUpdate} Created struct
   */
  static fromObject (other) {
    return new ChartBarUpdate().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ChartBarUpdate}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ChartBarUpdate.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ChartBarUpdate}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 28
  }
}

export { ChartBarUpdate };

/**
 * Fast Binary Encoding ChartBarUpdate field model
 */
class FieldModelChartBarUpdate extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SymbolId = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.PeriodType = new FieldModelChartBarPeriodType(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.OpenTime = new fbe.FieldModelTimestamp(buffer, this.PeriodType.fbeOffset + this.PeriodType.fbeSize)
    this.HighPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenTime.fbeOffset + this.OpenTime.fbeSize), buffer, this.OpenTime.fbeOffset + this.OpenTime.fbeSize)
    this.LowPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.HighPrice.fbeOffset + this.HighPrice.fbeSize), buffer, this.HighPrice.fbeOffset + this.HighPrice.fbeSize)
    this.ClosePrice = new fbe.FieldModelDouble(buffer, this.LowPrice.fbeOffset + this.LowPrice.fbeSize)
    this.Volume = new fbe.FieldModelUInt64(buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize)
    this.UniqueVersion = new fbe.FieldModelUInt64(buffer, this.Volume.fbeOffset + this.Volume.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SymbolId.fbeSize + this.PeriodType.fbeSize + this.OpenTime.fbeSize + this.HighPrice.fbeSize + this.LowPrice.fbeSize + this.ClosePrice.fbeSize + this.Volume.fbeSize + this.UniqueVersion.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SymbolId.fbeExtra + this.PeriodType.fbeExtra + this.OpenTime.fbeExtra + this.HighPrice.fbeExtra + this.LowPrice.fbeExtra + this.ClosePrice.fbeExtra + this.Volume.fbeExtra + this.UniqueVersion.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelChartBarUpdate.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 28
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelChartBarUpdate}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelChartBarUpdate}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PeriodType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize

    if ((fbeCurrentSize + this.OpenTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenTime.fbeSize

    if ((fbeCurrentSize + this.HighPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HighPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HighPrice.fbeSize

    if ((fbeCurrentSize + this.LowPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LowPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LowPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.Volume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Volume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Volume.fbeSize

    if ((fbeCurrentSize + this.UniqueVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UniqueVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueVersion.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelChartBarUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelChartBarUpdate}
   * @param {!ChartBarUpdate} fbeValue Default value, defaults is new ChartBarUpdate()
   * @returns {!ChartBarUpdate} ChartBarUpdate value
   */
  get (fbeValue = new ChartBarUpdate()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelChartBarUpdate}
   * @param {!ChartBarUpdate} fbeValue ChartBarUpdate value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.PeriodType.fbeSize) <= fbeStructSize) {
      fbeValue.PeriodType = this.PeriodType.get()
    } else {
      fbeValue.PeriodType = new ChartBarPeriodType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PeriodType.fbeSize

    if ((fbeCurrentSize + this.OpenTime.fbeSize) <= fbeStructSize) {
      fbeValue.OpenTime = this.OpenTime.get()
    } else {
      fbeValue.OpenTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenTime.fbeSize

    if ((fbeCurrentSize + this.HighPrice.fbeSize) <= fbeStructSize) {
      fbeValue.HighPrice = this.HighPrice.get()
    } else {
      fbeValue.HighPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HighPrice.fbeSize

    if ((fbeCurrentSize + this.LowPrice.fbeSize) <= fbeStructSize) {
      fbeValue.LowPrice = this.LowPrice.get()
    } else {
      fbeValue.LowPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LowPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.ClosePrice = this.ClosePrice.get()
    } else {
      fbeValue.ClosePrice = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.Volume.fbeSize) <= fbeStructSize) {
      fbeValue.Volume = this.Volume.get()
    } else {
      fbeValue.Volume = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Volume.fbeSize

    if ((fbeCurrentSize + this.UniqueVersion.fbeSize) <= fbeStructSize) {
      fbeValue.UniqueVersion = this.UniqueVersion.get()
    } else {
      fbeValue.UniqueVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueVersion.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelChartBarUpdate}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelChartBarUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelChartBarUpdate}
   * @param {!ChartBarUpdate} fbeValue ChartBarUpdate value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelChartBarUpdate}
   * @param {ChartBarUpdate} fbeValue ChartBarUpdate value
   */
  setFields (fbeValue) {
    this.SymbolId.set(fbeValue.SymbolId)
    this.PeriodType.set(fbeValue.PeriodType)
    this.OpenTime.set(fbeValue.OpenTime)
    this.HighPrice.set(fbeValue.HighPrice)
    this.LowPrice.set(fbeValue.LowPrice)
    this.ClosePrice.set(fbeValue.ClosePrice)
    this.Volume.set(fbeValue.Volume)
    this.UniqueVersion.set(fbeValue.UniqueVersion)
  }
}

export { FieldModelChartBarUpdate };

/**
 * Fast Binary Encoding ChartBarUpdate model
 */
class ChartBarUpdateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelChartBarUpdate(this.buffer, 4)
  }

  /**
   * Get the ChartBarUpdate model
   * @this {!ChartBarUpdateModel}
   * @returns {!FieldModelChartBarUpdate} model ChartBarUpdate model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ChartBarUpdateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ChartBarUpdateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ChartBarUpdateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ChartBarUpdateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelChartBarUpdate.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ChartBarUpdateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ChartBarUpdateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ChartBarUpdateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ChartBarUpdateModel}
   * @param {!ChartBarUpdate} value ChartBarUpdate value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ChartBarUpdateModel}
   * @param {!ChartBarUpdate} value ChartBarUpdate value, defaults is new ChartBarUpdate()
   * @return {!object} Deserialized ChartBarUpdate value and its size
   */
  deserialize (value = new ChartBarUpdate()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ChartBarUpdate(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ChartBarUpdate(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ChartBarUpdateModel}
   * @param {!number} prev Previous ChartBarUpdate model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ChartBarUpdateModel };
