
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * LoginType enum
 */
class LoginType {
  /**
   * Initialize enum with a given value
   * @param {LoginType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof LoginType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!LoginType}
   * @param {!LoginType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LoginType)) {
      throw new TypeError('Instance of LoginType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!LoginType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!LoginType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === LoginType.UNKNOWN.value) {
      return 'UNKNOWN'
    }
    if (this.value === LoginType.MOBILE_APP.value) {
      return 'MOBILE_APP'
    }
    if (this.value === LoginType.WEB_TRADER.value) {
      return 'WEB_TRADER'
    }
    if (this.value === LoginType.HTTPS_API.value) {
      return 'HTTPS_API'
    }
    if (this.value === LoginType.ADMIN_PANEL.value) {
      return 'ADMIN_PANEL'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!LoginType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!LoginType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!LoginType} Created enum
   */
  static fromObject (other) {
    return new LoginType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
LoginType.UNKNOWN = new LoginType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
LoginType.MOBILE_APP = new LoginType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
LoginType.WEB_TRADER = new LoginType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
LoginType.HTTPS_API = new LoginType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
LoginType.ADMIN_PANEL = new LoginType(0 + 4)

export { LoginType };

/**
 * Fast Binary Encoding LoginType field model
 */
class FieldModelLoginType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelLoginType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelLoginType}
   * @param {LoginType=} defaults Default value, defaults is new LoginType()
   * @returns {!LoginType} Result value
   */
  get (defaults = new LoginType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new LoginType(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelLoginType}
   * @param {!LoginType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelLoginType };
