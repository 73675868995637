
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {WorkingTimePeriod} from './WorkingTimePeriod';
import {FieldModelWorkingTimePeriod} from './WorkingTimePeriod';

/**
 * TradingCalendarRule struct
 */
class TradingCalendarRule {
  /**
   * Initialize struct
   * @param {!Date=} day
   * @param {!Array=} marketWorkingTime
   * @param {!Array=} tradingWorkingTime
   * @param {!UUID=} timezoneId
   * @constructor
   */
  constructor (day = new Date(0), marketWorkingTime = [], tradingWorkingTime = [], timezoneId = new UUID()) {
    this.Day = day
    this.MarketWorkingTime = marketWorkingTime
    this.TradingWorkingTime = tradingWorkingTime
    this.TimezoneId = timezoneId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingCalendarRule}
   * @param {!TradingCalendarRule} other Other struct
   * @returns {!TradingCalendarRule} This struct
   */
  copy (other) {
    if (other.Day != null) {
      if (other.Day instanceof Date) {
        this.Day = new Date(other.Day.getTime())
      } else {
        this.Day = new Date(Math.round(other.Day / 1000000))
      }
    } else {
      this.Day = null
    }
    if (other.MarketWorkingTime != null) {
      this.MarketWorkingTime = []
      for (let item of other.MarketWorkingTime) {
        if (item != null) {
          let tempItem
          tempItem = WorkingTimePeriod.fromObject(item)
          this.MarketWorkingTime.push(tempItem)
        } else {
          this.MarketWorkingTime.push(null)
        }
      }
    } else {
      this.MarketWorkingTime = null
    }
    if (other.TradingWorkingTime != null) {
      this.TradingWorkingTime = []
      for (let item of other.TradingWorkingTime) {
        if (item != null) {
          let tempItem
          tempItem = WorkingTimePeriod.fromObject(item)
          this.TradingWorkingTime.push(tempItem)
        } else {
          this.TradingWorkingTime.push(null)
        }
      }
    } else {
      this.TradingWorkingTime = null
    }
    if (other.TimezoneId != null) {
      this.TimezoneId = new UUID(other.TimezoneId)
    } else {
      this.TimezoneId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingCalendarRule}
   * @returns {!TradingCalendarRule} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingCalendarRuleModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingCalendarRuleModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingCalendarRule}
   * @param {!TradingCalendarRule} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCalendarRule)) {
      throw new TypeError('Instance of TradingCalendarRule is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingCalendarRule}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Day: ((this.Day != null) ? (this.Day.getTime() * 1000000) : null),
      MarketWorkingTime: ((this.MarketWorkingTime != null) ? Array.from(this.MarketWorkingTime, item => ((item != null) ? item : null)) : null),
      TradingWorkingTime: ((this.TradingWorkingTime != null) ? Array.from(this.TradingWorkingTime, item => ((item != null) ? item : null)) : null),
      TimezoneId: ((this.TimezoneId != null) ? this.TimezoneId.toString() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingCalendarRule.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingCalendarRule} other Object value
   * @returns {!TradingCalendarRule} Created struct
   */
  static fromObject (other) {
    return new TradingCalendarRule().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingCalendarRule}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingCalendarRule.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingCalendarRule}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 62
  }
}

export { TradingCalendarRule };

/**
 * Fast Binary Encoding TradingCalendarRule field model
 */
class FieldModelTradingCalendarRule extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Day = new fbe.FieldModelTimestamp(buffer, 4 + 4)
    this.MarketWorkingTime = new fbe.FieldModelVector(new FieldModelWorkingTimePeriod(buffer, this.Day.fbeOffset + this.Day.fbeSize), buffer, this.Day.fbeOffset + this.Day.fbeSize)
    this.TradingWorkingTime = new fbe.FieldModelVector(new FieldModelWorkingTimePeriod(buffer, this.MarketWorkingTime.fbeOffset + this.MarketWorkingTime.fbeSize), buffer, this.MarketWorkingTime.fbeOffset + this.MarketWorkingTime.fbeSize)
    this.TimezoneId = new fbe.FieldModelUUID(buffer, this.TradingWorkingTime.fbeOffset + this.TradingWorkingTime.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Day.fbeSize + this.MarketWorkingTime.fbeSize + this.TradingWorkingTime.fbeSize + this.TimezoneId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Day.fbeExtra + this.MarketWorkingTime.fbeExtra + this.TradingWorkingTime.fbeExtra + this.TimezoneId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingCalendarRule.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 62
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingCalendarRule}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingCalendarRule}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Day.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Day.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.MarketWorkingTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarketWorkingTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketWorkingTime.fbeSize

    if ((fbeCurrentSize + this.TradingWorkingTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingWorkingTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingWorkingTime.fbeSize

    if ((fbeCurrentSize + this.TimezoneId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimezoneId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingCalendarRule}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingCalendarRule}
   * @param {!TradingCalendarRule} fbeValue Default value, defaults is new TradingCalendarRule()
   * @returns {!TradingCalendarRule} TradingCalendarRule value
   */
  get (fbeValue = new TradingCalendarRule()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingCalendarRule}
   * @param {!TradingCalendarRule} fbeValue TradingCalendarRule value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Day.fbeSize) <= fbeStructSize) {
      fbeValue.Day = this.Day.get()
    } else {
      fbeValue.Day = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Day.fbeSize

    if ((fbeCurrentSize + this.MarketWorkingTime.fbeSize) <= fbeStructSize) {
      this.MarketWorkingTime.get(fbeValue.MarketWorkingTime)
    } else {
      fbeValue.MarketWorkingTime.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketWorkingTime.fbeSize

    if ((fbeCurrentSize + this.TradingWorkingTime.fbeSize) <= fbeStructSize) {
      this.TradingWorkingTime.get(fbeValue.TradingWorkingTime)
    } else {
      fbeValue.TradingWorkingTime.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingWorkingTime.fbeSize

    if ((fbeCurrentSize + this.TimezoneId.fbeSize) <= fbeStructSize) {
      fbeValue.TimezoneId = this.TimezoneId.get()
    } else {
      fbeValue.TimezoneId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRule}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingCalendarRule}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingCalendarRule}
   * @param {!TradingCalendarRule} fbeValue TradingCalendarRule value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingCalendarRule}
   * @param {TradingCalendarRule} fbeValue TradingCalendarRule value
   */
  setFields (fbeValue) {
    this.Day.set(fbeValue.Day)
    this.MarketWorkingTime.set(fbeValue.MarketWorkingTime)
    this.TradingWorkingTime.set(fbeValue.TradingWorkingTime)
    this.TimezoneId.set(fbeValue.TimezoneId)
  }
}

export { FieldModelTradingCalendarRule };

/**
 * Fast Binary Encoding TradingCalendarRule model
 */
class TradingCalendarRuleModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingCalendarRule(this.buffer, 4)
  }

  /**
   * Get the TradingCalendarRule model
   * @this {!TradingCalendarRuleModel}
   * @returns {!FieldModelTradingCalendarRule} model TradingCalendarRule model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingCalendarRuleModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingCalendarRuleModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingCalendarRuleModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingCalendarRuleModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingCalendarRule.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingCalendarRuleModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingCalendarRuleModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingCalendarRuleModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingCalendarRuleModel}
   * @param {!TradingCalendarRule} value TradingCalendarRule value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingCalendarRuleModel}
   * @param {!TradingCalendarRule} value TradingCalendarRule value, defaults is new TradingCalendarRule()
   * @return {!object} Deserialized TradingCalendarRule value and its size
   */
  deserialize (value = new TradingCalendarRule()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingCalendarRule(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingCalendarRule(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingCalendarRuleModel}
   * @param {!number} prev Previous TradingCalendarRule model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingCalendarRuleModel };
