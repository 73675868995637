import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { MarginRateProfile as MarginRateProfileAPI } from '@api/fbe/core/MarginRateProfile';
import { MarginRateProfilePositionMode } from '@api/fbe/core/MarginRateProfilePositionMode';
import { MarginRateProfileStatus } from '@api/fbe/core/MarginRateProfileStatus';
import { MarginRateProfile, MarginRateProfilePercentage } from '@models/profiles/MarginRateProfile';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { UInt64 } from '@api/fbe/int64';
import { MarginRateProfilesGetAllRequest } from '@api/fbe/manager/MarginRateProfilesGetAllRequest';
import { MarginRateProfileCreateRequest } from '@api/fbe/manager/MarginRateProfileCreateRequest';
import { MarginRateProfileModifyRequest } from '@api/fbe/manager/MarginRateProfileModifyRequest';
import { MarginRateProfileDeleteRequest } from '@api/fbe/manager/MarginRateProfileDeleteRequest';
import { keyMap, t } from '@localization';

const convertMarginRateProfilePercentage = (records: MarginRateProfilePercentage[]): Map<UUID, number> => {
    const map = new Map();
    records.forEach((item) => {
        if (item.SymbolId) {
            map.set(UUID.fromString(item.SymbolId), item.Value);
        }
    });
    return map;
};

class MarginRateProfileStore {
    errorTracker = new ErrorsTracker({ title: 'Margin Rate Profiles' });

    constructor() {
        makeAutoObservable(this);
    }

    data: MarginRateProfile[] = [];

    isLoading = false;

    setData(value: MarginRateProfile[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new MarginRateProfilesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.MarginRateProfiles.map(
                (result: MarginRateProfileAPI): MarginRateProfile => ({
                    id: result.id.toString(),
                    Name: result.Name,
                    Comment: result.Comment,
                    Description: result.Description,
                    SortOrder: result.SortOrder,
                    AccountGroupId: result.AccountGroupId ? Array.from(result.AccountGroupId).map(item => item.toString()) : [],
                    // ManualActivationDuration: +result.ManualActivationDuration.toNumber(),
                    ActivationTime: result.ActivationTime,
                    Status: result.Status.valueOf(),
                    PositionMode: result.PositionMode.valueOf(),
                    Percentage: Array.from(result.Percentage, ([key, value]) => ({
                        SymbolId: key.toString(),
                        Value: value,
                    })),
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: MarginRateProfile) {
        const request = new MarginRateProfileCreateRequest();
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        request.SortOrder = record.SortOrder;
        request.AccountGroupId = new Set(record.AccountGroupId.map(item => UUID.fromString(item)));
        // if (record.ManualActivationDuration !== null)
        //     request.ManualActivationDuration = UInt64.fromNumber(record.ManualActivationDuration);
        if (record.Status !== null) request.Status = new MarginRateProfileStatus(record.Status);
        if (record.PositionMode !== null) request.PositionMode = new MarginRateProfilePositionMode(record.PositionMode);
        // request.Percentage = convertMarginRateProfilePercentage(record.Percentage);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: MarginRateProfile, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new MarginRateProfileModifyRequest();
        request.MarginRateProfileId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        if (dirtyFields?.Name) request.NewName = record.Name;
        if (dirtyFields?.Comment) request.NewComment = record.Comment;
        if (dirtyFields?.Description) request.NewDescription = record.Description;
        if (dirtyFields?.AccountGroupId) request.NewAccountGroupId = new Set(record.AccountGroupId.map(item => UUID.fromString(item)));
        // if (dirtyFields?.ManualActivationDuration)
        //     request.NewManualActivationDuration =
        //         record.ManualActivationDuration === null ? null : UInt64.fromNumber(record.ManualActivationDuration);
        if (dirtyFields?.Status)
            request.NewStatus = record.Status === null ? null : new MarginRateProfileStatus(record.Status);
        if (dirtyFields?.PositionMode)
            request.NewPositionMode =
                record.PositionMode === null ? null : new MarginRateProfilePositionMode(record.PositionMode);
        if (dirtyFields?.Percentage) request.NewPercentage = convertMarginRateProfilePercentage(record.Percentage);
        if (!record.Percentage.length) request.PercentageReset = true;

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new MarginRateProfileDeleteRequest();
        request.MarginRateProfileId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new MarginRateProfileModifyRequest();
            request.MarginRateProfileId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error(t(keyMap.messages.platforms.reorderError));
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const marginRateProfileStore = new MarginRateProfileStore();
