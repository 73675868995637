
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as entity from '../entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * DashboardUpdateResult enum
 */
class DashboardUpdateResult {
  /**
   * Initialize enum with a given value
   * @param {DashboardUpdateResult|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof DashboardUpdateResult) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!DashboardUpdateResult}
   * @param {!DashboardUpdateResult} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DashboardUpdateResult)) {
      throw new TypeError('Instance of DashboardUpdateResult is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!DashboardUpdateResult}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!DashboardUpdateResult}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === DashboardUpdateResult.SUCCESS.value) {
      return 'SUCCESS'
    }
    if (this.value === DashboardUpdateResult.NAME_CONFLICT.value) {
      return 'NAME_CONFLICT'
    }
    if (this.value === DashboardUpdateResult.VERSION_CONFLICT.value) {
      return 'VERSION_CONFLICT'
    }
    if (this.value === DashboardUpdateResult.NOT_FOUND.value) {
      return 'NOT_FOUND'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!DashboardUpdateResult}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!DashboardUpdateResult}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!DashboardUpdateResult} Created enum
   */
  static fromObject (other) {
    return new DashboardUpdateResult(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
DashboardUpdateResult.SUCCESS = new DashboardUpdateResult(0 + 0)
// noinspection PointlessArithmeticExpressionJS
DashboardUpdateResult.NAME_CONFLICT = new DashboardUpdateResult(0 + 1)
// noinspection PointlessArithmeticExpressionJS
DashboardUpdateResult.VERSION_CONFLICT = new DashboardUpdateResult(0 + 2)
// noinspection PointlessArithmeticExpressionJS
DashboardUpdateResult.NOT_FOUND = new DashboardUpdateResult(0 + 3)

export { DashboardUpdateResult };

/**
 * Fast Binary Encoding DashboardUpdateResult field model
 */
class FieldModelDashboardUpdateResult extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelDashboardUpdateResult}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelDashboardUpdateResult}
   * @param {DashboardUpdateResult=} defaults Default value, defaults is new DashboardUpdateResult()
   * @returns {!DashboardUpdateResult} Result value
   */
  get (defaults = new DashboardUpdateResult()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new DashboardUpdateResult(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelDashboardUpdateResult}
   * @param {!DashboardUpdateResult} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelDashboardUpdateResult };
