/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, FC } from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useRangePickerAutoaccept } from './useRangePickerAutoaccept';

const { RangePicker } = DatePicker;

export const RangePickerAutoaccept: FC<RangePickerProps> = ({ value, onChange, format, ...restProps }) => {
    const mainElRef = useRef<HTMLDivElement | null>(null);

    useRangePickerAutoaccept(mainElRef, value, format as string, onChange);

    return (
        <div className="pickers-wrapper" ref={mainElRef}>
            <RangePicker value={value} onChange={onChange} format={format} {...restProps} />
        </div>
    );
};
