
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {DataFeedSubscription} from './DataFeedSubscription';
import {FieldModelDataFeedSubscription} from './DataFeedSubscription';

/**
 * DataFeedUpdateSubscriptionsRequest struct
 */
class DataFeedUpdateSubscriptionsRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!Map=} subscriptionsToAdd
   * @param {!Set=} subscriptionsToDelete
   * @constructor
   */
  constructor (parent = new Request(), subscriptionsToAdd = new Map(), subscriptionsToDelete = new Set()) {
    super()
    super.copy(parent)
    this.SubscriptionsToAdd = subscriptionsToAdd
    this.SubscriptionsToDelete = subscriptionsToDelete
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @param {!DataFeedUpdateSubscriptionsRequest} other Other struct
   * @returns {!DataFeedUpdateSubscriptionsRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SubscriptionsToAdd != null) {
      this.SubscriptionsToAdd = new Map()
      Object.keys(other.SubscriptionsToAdd).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = new UUID(key)
          if (other.SubscriptionsToAdd[key] != null) {
            let tempValue
            tempValue = DataFeedSubscription.fromObject(other.SubscriptionsToAdd[key])
            this.SubscriptionsToAdd.set(tempKey, tempValue)
          } else {
            this.SubscriptionsToAdd.set(tempKey, null)
          }
        }
      })
    } else {
      this.SubscriptionsToAdd = null
    }
    if (other.SubscriptionsToDelete != null) {
      this.SubscriptionsToDelete = new Set()
      for (let item of other.SubscriptionsToDelete) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.SubscriptionsToDelete.add(tempItem)
        } else {
          this.SubscriptionsToDelete.add(null)
        }
      }
    } else {
      this.SubscriptionsToDelete = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @returns {!DataFeedUpdateSubscriptionsRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DataFeedUpdateSubscriptionsRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DataFeedUpdateSubscriptionsRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @param {!DataFeedUpdateSubscriptionsRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DataFeedUpdateSubscriptionsRequest)) {
      throw new TypeError('Instance of DataFeedUpdateSubscriptionsRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SubscriptionsToAdd: ((this.SubscriptionsToAdd != null) ? fbe.MapToObject(new Map(Array.from(this.SubscriptionsToAdd, item => [((item[0] != null) ? item[0].toString() : null), ((item[1] != null) ? item[1] : null)]))) : null),
      SubscriptionsToDelete: ((this.SubscriptionsToDelete != null) ? Array.from(this.SubscriptionsToDelete, item => ((item != null) ? item.toString() : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DataFeedUpdateSubscriptionsRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DataFeedUpdateSubscriptionsRequest} other Object value
   * @returns {!DataFeedUpdateSubscriptionsRequest} Created struct
   */
  static fromObject (other) {
    return new DataFeedUpdateSubscriptionsRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DataFeedUpdateSubscriptionsRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DataFeedUpdateSubscriptionsRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 2060
  }
}

export { DataFeedUpdateSubscriptionsRequest };

/**
 * Fast Binary Encoding DataFeedUpdateSubscriptionsRequest field model
 */
class FieldModelDataFeedUpdateSubscriptionsRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.SubscriptionsToAdd = new fbe.FieldModelMap(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), new FieldModelDataFeedSubscription(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.SubscriptionsToDelete = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.SubscriptionsToAdd.fbeOffset + this.SubscriptionsToAdd.fbeSize), buffer, this.SubscriptionsToAdd.fbeOffset + this.SubscriptionsToAdd.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SubscriptionsToAdd.fbeSize + this.SubscriptionsToDelete.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SubscriptionsToAdd.fbeExtra + this.SubscriptionsToDelete.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDataFeedUpdateSubscriptionsRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 2060
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SubscriptionsToAdd.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SubscriptionsToAdd.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SubscriptionsToAdd.fbeSize

    if ((fbeCurrentSize + this.SubscriptionsToDelete.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SubscriptionsToDelete.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SubscriptionsToDelete.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!DataFeedUpdateSubscriptionsRequest} fbeValue Default value, defaults is new DataFeedUpdateSubscriptionsRequest()
   * @returns {!DataFeedUpdateSubscriptionsRequest} DataFeedUpdateSubscriptionsRequest value
   */
  get (fbeValue = new DataFeedUpdateSubscriptionsRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!DataFeedUpdateSubscriptionsRequest} fbeValue DataFeedUpdateSubscriptionsRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SubscriptionsToAdd.fbeSize) <= fbeStructSize) {
      this.SubscriptionsToAdd.get(fbeValue.SubscriptionsToAdd)
    } else {
      fbeValue.SubscriptionsToAdd.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SubscriptionsToAdd.fbeSize

    if ((fbeCurrentSize + this.SubscriptionsToDelete.fbeSize) <= fbeStructSize) {
      this.SubscriptionsToDelete.get(fbeValue.SubscriptionsToDelete)
    } else {
      fbeValue.SubscriptionsToDelete.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SubscriptionsToDelete.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {!DataFeedUpdateSubscriptionsRequest} fbeValue DataFeedUpdateSubscriptionsRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDataFeedUpdateSubscriptionsRequest}
   * @param {DataFeedUpdateSubscriptionsRequest} fbeValue DataFeedUpdateSubscriptionsRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SubscriptionsToAdd.set(fbeValue.SubscriptionsToAdd)
    this.SubscriptionsToDelete.set(fbeValue.SubscriptionsToDelete)
  }
}

export { FieldModelDataFeedUpdateSubscriptionsRequest };

/**
 * Fast Binary Encoding DataFeedUpdateSubscriptionsRequest model
 */
class DataFeedUpdateSubscriptionsRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDataFeedUpdateSubscriptionsRequest(this.buffer, 4)
  }

  /**
   * Get the DataFeedUpdateSubscriptionsRequest model
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!FieldModelDataFeedUpdateSubscriptionsRequest} model DataFeedUpdateSubscriptionsRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DataFeedUpdateSubscriptionsRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDataFeedUpdateSubscriptionsRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @param {!DataFeedUpdateSubscriptionsRequest} value DataFeedUpdateSubscriptionsRequest value, defaults is new DataFeedUpdateSubscriptionsRequest()
   * @return {!object} Deserialized DataFeedUpdateSubscriptionsRequest value and its size
   */
  deserialize (value = new DataFeedUpdateSubscriptionsRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DataFeedUpdateSubscriptionsRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DataFeedUpdateSubscriptionsRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DataFeedUpdateSubscriptionsRequestModel}
   * @param {!number} prev Previous DataFeedUpdateSubscriptionsRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DataFeedUpdateSubscriptionsRequestModel };
