
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * AccountState struct
 */
class AccountState {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} balance
   * @param {!number=} credit
   * @param {!number=} equity
   * @param {!number=} margin
   * @param {!number=} leverage
   * @param {!number=} openProfit
   * @constructor
   */
  constructor (login = new UInt64(0, 0), balance = 0.0, credit = 0.0, equity = 0.0, margin = 0.0, leverage = 0, openProfit = 0.0) {
    this.Login = login
    this.Balance = balance
    this.Credit = credit
    this.Equity = equity
    this.Margin = margin
    this.Leverage = leverage
    this.OpenProfit = openProfit
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountState}
   * @param {!AccountState} other Other struct
   * @returns {!AccountState} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Equity != null) {
      this.Equity = other.Equity
    } else {
      this.Equity = null
    }
    if (other.Margin != null) {
      this.Margin = other.Margin
    } else {
      this.Margin = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    if (other.OpenProfit != null) {
      this.OpenProfit = other.OpenProfit
    } else {
      this.OpenProfit = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountState}
   * @returns {!AccountState} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountStateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountStateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountState}
   * @param {!AccountState} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountState)) {
      throw new TypeError('Instance of AccountState is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountState}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Equity: ((this.Equity != null) ? this.Equity : null),
      Margin: ((this.Margin != null) ? this.Margin : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      OpenProfit: ((this.OpenProfit != null) ? this.OpenProfit : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountState.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountState} other Object value
   * @returns {!AccountState} Created struct
   */
  static fromObject (other) {
    return new AccountState().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountState}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountState.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountState}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 82
  }
}

export { AccountState };

/**
 * Fast Binary Encoding AccountState field model
 */
class FieldModelAccountState extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Balance = new fbe.FieldModelDouble(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.Credit = new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Equity = new fbe.FieldModelDouble(buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.Margin = new fbe.FieldModelDouble(buffer, this.Equity.fbeOffset + this.Equity.fbeSize)
    this.Leverage = new fbe.FieldModelUInt32(buffer, this.Margin.fbeOffset + this.Margin.fbeSize)
    this.OpenProfit = new fbe.FieldModelDouble(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountState}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountState}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Equity.fbeSize + this.Margin.fbeSize + this.Leverage.fbeSize + this.OpenProfit.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountState}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Equity.fbeExtra + this.Margin.fbeExtra + this.Leverage.fbeExtra + this.OpenProfit.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountState}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountState.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountState}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 82
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountState}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountState}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Equity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Margin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountState}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountState}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountState}
   * @param {!AccountState} fbeValue Default value, defaults is new AccountState()
   * @returns {!AccountState} AccountState value
   */
  get (fbeValue = new AccountState()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountState}
   * @param {!AccountState} fbeValue AccountState value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) <= fbeStructSize) {
      fbeValue.Equity = this.Equity.get()
    } else {
      fbeValue.Equity = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) <= fbeStructSize) {
      fbeValue.Margin = this.Margin.get()
    } else {
      fbeValue.Margin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.OpenProfit = this.OpenProfit.get()
    } else {
      fbeValue.OpenProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountState}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountState}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountState}
   * @param {!AccountState} fbeValue AccountState value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountState}
   * @param {AccountState} fbeValue AccountState value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Equity.set(fbeValue.Equity)
    this.Margin.set(fbeValue.Margin)
    this.Leverage.set(fbeValue.Leverage)
    this.OpenProfit.set(fbeValue.OpenProfit)
  }
}

export { FieldModelAccountState };

/**
 * Fast Binary Encoding AccountState model
 */
class AccountStateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountState(this.buffer, 4)
  }

  /**
   * Get the AccountState model
   * @this {!AccountStateModel}
   * @returns {!FieldModelAccountState} model AccountState model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountStateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountStateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountStateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountStateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountState.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountStateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountStateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountStateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountStateModel}
   * @param {!AccountState} value AccountState value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountStateModel}
   * @param {!AccountState} value AccountState value, defaults is new AccountState()
   * @return {!object} Deserialized AccountState value and its size
   */
  deserialize (value = new AccountState()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountState(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountState(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountStateModel}
   * @param {!number} prev Previous AccountState model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountStateModel };
