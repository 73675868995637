
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * CurrencyInfo struct
 */
class CurrencyInfo {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!string=} description
   * @param {!number=} precision
   * @constructor
   */
  constructor (name = '', description = '', precision = 0) {
    this.Name = name
    this.Description = description
    this.Precision = precision
  }

  /**
   * Copy struct (shallow copy)
   * @this {!CurrencyInfo}
   * @param {!CurrencyInfo} other Other struct
   * @returns {!CurrencyInfo} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Precision != null) {
      this.Precision = other.Precision
    } else {
      this.Precision = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!CurrencyInfo}
   * @returns {!CurrencyInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new CurrencyInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new CurrencyInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!CurrencyInfo}
   * @param {!CurrencyInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof CurrencyInfo)) {
      throw new TypeError('Instance of CurrencyInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!CurrencyInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      Description: ((this.Description != null) ? this.Description : null),
      Precision: ((this.Precision != null) ? this.Precision : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return CurrencyInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!CurrencyInfo} other Object value
   * @returns {!CurrencyInfo} Created struct
   */
  static fromObject (other) {
    return new CurrencyInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!CurrencyInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return CurrencyInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!CurrencyInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5019
  }
}

export { CurrencyInfo };

/**
 * Fast Binary Encoding CurrencyInfo field model
 */
class FieldModelCurrencyInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.Description = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Precision = new fbe.FieldModelUInt8(buffer, this.Description.fbeOffset + this.Description.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.Description.fbeSize + this.Precision.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.Description.fbeExtra + this.Precision.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelCurrencyInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5019
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelCurrencyInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelCurrencyInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Precision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelCurrencyInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelCurrencyInfo}
   * @param {!CurrencyInfo} fbeValue Default value, defaults is new CurrencyInfo()
   * @returns {!CurrencyInfo} CurrencyInfo value
   */
  get (fbeValue = new CurrencyInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelCurrencyInfo}
   * @param {!CurrencyInfo} fbeValue CurrencyInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Precision.fbeSize) <= fbeStructSize) {
      fbeValue.Precision = this.Precision.get()
    } else {
      fbeValue.Precision = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Precision.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelCurrencyInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelCurrencyInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelCurrencyInfo}
   * @param {!CurrencyInfo} fbeValue CurrencyInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelCurrencyInfo}
   * @param {CurrencyInfo} fbeValue CurrencyInfo value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.Description.set(fbeValue.Description)
    this.Precision.set(fbeValue.Precision)
  }
}

export { FieldModelCurrencyInfo };

/**
 * Fast Binary Encoding CurrencyInfo model
 */
class CurrencyInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelCurrencyInfo(this.buffer, 4)
  }

  /**
   * Get the CurrencyInfo model
   * @this {!CurrencyInfoModel}
   * @returns {!FieldModelCurrencyInfo} model CurrencyInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!CurrencyInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!CurrencyInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return CurrencyInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!CurrencyInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelCurrencyInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!CurrencyInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!CurrencyInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!CurrencyInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!CurrencyInfoModel}
   * @param {!CurrencyInfo} value CurrencyInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!CurrencyInfoModel}
   * @param {!CurrencyInfo} value CurrencyInfo value, defaults is new CurrencyInfo()
   * @return {!object} Deserialized CurrencyInfo value and its size
   */
  deserialize (value = new CurrencyInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new CurrencyInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new CurrencyInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!CurrencyInfoModel}
   * @param {!number} prev Previous CurrencyInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { CurrencyInfoModel };
