import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UInt64 } from '@api/fbe/int64';
import { StatementsReportModel, StatementsReportRequestParams } from '@models/reports/Statement';
import { ClientReportsListGetRequest } from '@api/fbe/manager/ClientReportsListGetRequest';
import { ClientReportType } from '@api/fbe/core/ClientReportType';
import { nanosecondsToDate } from '../../utils/datetime';
import { ClientReportGetRequest } from '@api/fbe/manager/ClientReportGetRequest';
import { ClientReportsSendRequest } from '@api/fbe/manager/ClientReportsSendRequest';
import { ClientReportSendType } from '@api/fbe/core/ClientReportSendType';
import { Message, MessageType } from '@components/Message/Message';
import { keyMap, t } from '@localization';

const DEFAULT_LIMIT = 100;

class StatementReportsStore {
    errorTracker = new ErrorsTracker({ title: 'Statement Reports' });

    constructor() {
        makeAutoObservable(this);
    }

    data: StatementsReportModel[] = [];

    reportData: string | null = null;

    isLoading = false;

    isLoadingReport = false;

    requestParams: StatementsReportRequestParams | null = null;

    setData(value: StatementsReportModel[]) {
        this.data = value;
    }

    setReportData(value: string) {
        this.reportData = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setIsLoadingReport(value: boolean) {
        this.isLoadingReport = value;
    }

    setRequestParams = (params: StatementsReportRequestParams | null): void => {
        this.requestParams = params;
    };

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new ClientReportsListGetRequest();
        if (this.requestParams) {
            if (this.requestParams.Login !== null) {
                request.Login = UInt64.fromNumber(this.requestParams.Login);
            }
            if (this.requestParams.RequestFrom)
                request.TimeFrom = this.requestParams.RequestFrom;
            if (this.requestParams.RequestTo) request.TimeTo = this.requestParams.RequestTo;
            request.Limit = UInt64.fromNumber(DEFAULT_LIMIT);
            if (this.requestParams.ClientReportType !== null) {
                request.ClientReportType = new ClientReportType(this.requestParams.ClientReportType);
            }
            try {
                const result = await SocketClient.instance.request(request, this.errorTracker);
                this.setData(
                    result.ClientReports.map((record, index) => ({
                        key: index,
                        FilePath: record.FilePath,
                        IsSent: record.IsSent,
                        ReportTimestamp: nanosecondsToDate(record.ReportTimestamp.toNumber()),
                        RolloverTimestamp: nanosecondsToDate(record.RolloverTimestamp.toNumber()),
                    })),
                );
            } catch (error) {
                Message(MessageType.error, 'Account not found! Please check account login or contact your administrator to receive permissions');
            }
            this.setIsLoading(false);
        }
    }

    @ErrorsTracker.wrapApi()
    async getReport(login: number, reportFilePath: string) {
        this.setIsLoadingReport(true);
        const request = new ClientReportGetRequest();
        request.Login = UInt64.fromNumber(login);
        request.ReportFilePath = reportFilePath;
        try {
            const result = await SocketClient.instance.request(request, this.errorTracker);
            this.setReportData(result.Report);
        } catch (error) {
            Message(MessageType.error, t(keyMap.form.statements.messages.invalidRequestParams));
            this.sendReport(null);
        }
        this.setIsLoadingReport(false);
    }

    @ErrorsTracker.wrapApi()
    async sendReport(rolloverTimestamp: Date | null) {
        if (
            rolloverTimestamp &&
            this.requestParams &&
            typeof this.requestParams.ClientReportType === 'number' &&
            typeof this.requestParams.Login === 'number' &&
            typeof this.requestParams.ClientReportType
        ) {
            const request = new ClientReportsSendRequest();
            request.Login = UInt64.fromNumber(this.requestParams.Login);
            request.Force = true;
            request.RolloverTimestamp = rolloverTimestamp;
            request.ClientReportType = new ClientReportType(this.requestParams.ClientReportType);
            request.ClientReportSendType = ClientReportSendType.SINGLE;
            try {
                await SocketClient.instance.request(request, this.errorTracker);
                Message(MessageType.success, t(keyMap.form.statements.messages.reportSent));
            } catch (error) {
                Message(MessageType.error, t(keyMap.form.statements.messages.invalidRequestParams));
            }
        } else {
            Message(MessageType.error, t(keyMap.form.statements.messages.invalidRequestParams));
        }
    }

    reset() {
        this.data = [];
        this.reportData = null;
        this.isLoading = false;
        this.isLoadingReport = false;
        this.requestParams = null;
    }
}

export const statementReportsStore = new StatementReportsStore();
