
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ProtocolType} from '../core/ProtocolType';
import {FieldModelProtocolType} from '../core/ProtocolType';
import {TransportType} from '../core/TransportType';
import {FieldModelTransportType} from '../core/TransportType';

/**
 * SessionInfo struct
 */
class SessionInfo {
  /**
   * Initialize struct
   * @param {!UUID=} sessionId
   * @param {!number=} login
   * @param {!ProtocolType=} protocolType
   * @param {!TransportType=} transportType
   * @param {!string=} clientAddress
   * @param {!number=} clientPort
   * @param {!number=} protocolVersionMajor
   * @param {!number=} protocolVersionMinor
   * @param {!Date=} created
   * @constructor
   */
  constructor (sessionId = new UUID(), login = new UInt64(0, 0), protocolType = new ProtocolType(), transportType = new TransportType(), clientAddress = '', clientPort = 0, protocolVersionMajor = 0, protocolVersionMinor = 0, created = new Date(0)) {
    this.SessionId = sessionId
    this.Login = login
    this.ProtocolType = protocolType
    this.TransportType = transportType
    this.ClientAddress = clientAddress
    this.ClientPort = clientPort
    this.ProtocolVersionMajor = protocolVersionMajor
    this.ProtocolVersionMinor = protocolVersionMinor
    this.Created = created
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SessionInfo}
   * @param {!SessionInfo} other Other struct
   * @returns {!SessionInfo} This struct
   */
  copy (other) {
    if (other.SessionId != null) {
      this.SessionId = new UUID(other.SessionId)
    } else {
      this.SessionId = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ProtocolType != null) {
      this.ProtocolType = ProtocolType.fromObject(other.ProtocolType)
    } else {
      this.ProtocolType = null
    }
    if (other.TransportType != null) {
      this.TransportType = TransportType.fromObject(other.TransportType)
    } else {
      this.TransportType = null
    }
    if (other.ClientAddress != null) {
      this.ClientAddress = other.ClientAddress
    } else {
      this.ClientAddress = null
    }
    if (other.ClientPort != null) {
      this.ClientPort = other.ClientPort
    } else {
      this.ClientPort = null
    }
    if (other.ProtocolVersionMajor != null) {
      this.ProtocolVersionMajor = other.ProtocolVersionMajor
    } else {
      this.ProtocolVersionMajor = null
    }
    if (other.ProtocolVersionMinor != null) {
      this.ProtocolVersionMinor = other.ProtocolVersionMinor
    } else {
      this.ProtocolVersionMinor = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SessionInfo}
   * @returns {!SessionInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SessionInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SessionInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SessionInfo}
   * @param {!SessionInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SessionInfo)) {
      throw new TypeError('Instance of SessionInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SessionInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SessionId: ((this.SessionId != null) ? this.SessionId.toString() : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ProtocolType: ((this.ProtocolType != null) ? this.ProtocolType : null),
      TransportType: ((this.TransportType != null) ? this.TransportType : null),
      ClientAddress: ((this.ClientAddress != null) ? this.ClientAddress : null),
      ClientPort: ((this.ClientPort != null) ? this.ClientPort : null),
      ProtocolVersionMajor: ((this.ProtocolVersionMajor != null) ? this.ProtocolVersionMajor : null),
      ProtocolVersionMinor: ((this.ProtocolVersionMinor != null) ? this.ProtocolVersionMinor : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SessionInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SessionInfo} other Object value
   * @returns {!SessionInfo} Created struct
   */
  static fromObject (other) {
    return new SessionInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SessionInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SessionInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SessionInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5009
  }
}

export { SessionInfo };

/**
 * Fast Binary Encoding SessionInfo field model
 */
class FieldModelSessionInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SessionId = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this.SessionId.fbeOffset + this.SessionId.fbeSize)
    this.ProtocolType = new FieldModelProtocolType(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.TransportType = new FieldModelTransportType(buffer, this.ProtocolType.fbeOffset + this.ProtocolType.fbeSize)
    this.ClientAddress = new fbe.FieldModelString(buffer, this.TransportType.fbeOffset + this.TransportType.fbeSize)
    this.ClientPort = new fbe.FieldModelUInt16(buffer, this.ClientAddress.fbeOffset + this.ClientAddress.fbeSize)
    this.ProtocolVersionMajor = new fbe.FieldModelUInt16(buffer, this.ClientPort.fbeOffset + this.ClientPort.fbeSize)
    this.ProtocolVersionMinor = new fbe.FieldModelUInt16(buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.ProtocolVersionMinor.fbeOffset + this.ProtocolVersionMinor.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SessionId.fbeSize + this.Login.fbeSize + this.ProtocolType.fbeSize + this.TransportType.fbeSize + this.ClientAddress.fbeSize + this.ClientPort.fbeSize + this.ProtocolVersionMajor.fbeSize + this.ProtocolVersionMinor.fbeSize + this.Created.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SessionId.fbeExtra + this.Login.fbeExtra + this.ProtocolType.fbeExtra + this.TransportType.fbeExtra + this.ClientAddress.fbeExtra + this.ClientPort.fbeExtra + this.ProtocolVersionMajor.fbeExtra + this.ProtocolVersionMinor.fbeExtra + this.Created.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSessionInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5009
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSessionInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSessionInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SessionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SessionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ProtocolType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolType.fbeSize

    if ((fbeCurrentSize + this.TransportType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransportType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransportType.fbeSize

    if ((fbeCurrentSize + this.ClientAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientAddress.fbeSize

    if ((fbeCurrentSize + this.ClientPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientPort.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSessionInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSessionInfo}
   * @param {!SessionInfo} fbeValue Default value, defaults is new SessionInfo()
   * @returns {!SessionInfo} SessionInfo value
   */
  get (fbeValue = new SessionInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSessionInfo}
   * @param {!SessionInfo} fbeValue SessionInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SessionId.fbeSize) <= fbeStructSize) {
      fbeValue.SessionId = this.SessionId.get()
    } else {
      fbeValue.SessionId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ProtocolType.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolType = this.ProtocolType.get()
    } else {
      fbeValue.ProtocolType = new ProtocolType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolType.fbeSize

    if ((fbeCurrentSize + this.TransportType.fbeSize) <= fbeStructSize) {
      fbeValue.TransportType = this.TransportType.get()
    } else {
      fbeValue.TransportType = new TransportType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransportType.fbeSize

    if ((fbeCurrentSize + this.ClientAddress.fbeSize) <= fbeStructSize) {
      fbeValue.ClientAddress = this.ClientAddress.get()
    } else {
      fbeValue.ClientAddress = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientAddress.fbeSize

    if ((fbeCurrentSize + this.ClientPort.fbeSize) <= fbeStructSize) {
      fbeValue.ClientPort = this.ClientPort.get()
    } else {
      fbeValue.ClientPort = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientPort.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMajor = this.ProtocolVersionMajor.get()
    } else {
      fbeValue.ProtocolVersionMajor = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMinor = this.ProtocolVersionMinor.get()
    } else {
      fbeValue.ProtocolVersionMinor = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSessionInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSessionInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSessionInfo}
   * @param {!SessionInfo} fbeValue SessionInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSessionInfo}
   * @param {SessionInfo} fbeValue SessionInfo value
   */
  setFields (fbeValue) {
    this.SessionId.set(fbeValue.SessionId)
    this.Login.set(fbeValue.Login)
    this.ProtocolType.set(fbeValue.ProtocolType)
    this.TransportType.set(fbeValue.TransportType)
    this.ClientAddress.set(fbeValue.ClientAddress)
    this.ClientPort.set(fbeValue.ClientPort)
    this.ProtocolVersionMajor.set(fbeValue.ProtocolVersionMajor)
    this.ProtocolVersionMinor.set(fbeValue.ProtocolVersionMinor)
    this.Created.set(fbeValue.Created)
  }
}

export { FieldModelSessionInfo };

/**
 * Fast Binary Encoding SessionInfo model
 */
class SessionInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSessionInfo(this.buffer, 4)
  }

  /**
   * Get the SessionInfo model
   * @this {!SessionInfoModel}
   * @returns {!FieldModelSessionInfo} model SessionInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SessionInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SessionInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SessionInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SessionInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSessionInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SessionInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SessionInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SessionInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SessionInfoModel}
   * @param {!SessionInfo} value SessionInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SessionInfoModel}
   * @param {!SessionInfo} value SessionInfo value, defaults is new SessionInfo()
   * @return {!object} Deserialized SessionInfo value and its size
   */
  deserialize (value = new SessionInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SessionInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SessionInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SessionInfoModel}
   * @param {!number} prev Previous SessionInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SessionInfoModel };
