import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { MarkupProfile } from '@models/MarkupProfile';
import { UUID } from '@api/fbe/uuid';
import { UInt64 } from '@api/fbe/int64';
import { MarkupValue } from '@api/fbe/core/MarkupValue';
import { MarkupProfilesGetAllRequest } from '@api/fbe/manager/MarkupProfilesGetAllRequest';
import { MarkupProfileCreateRequest } from '@api/fbe/manager/MarkupProfileCreateRequest';
import { MarkupProfileModifyRequest } from '@api/fbe/manager/MarkupProfileModifyRequest';
import { MarkupProfileDeleteRequest } from '@api/fbe/manager/MarkupProfileDeleteRequest';

class MarkupProfileStore {
    errorTracker = new ErrorsTracker({ title: 'Markups' });

    constructor() {
        makeAutoObservable(this);
    }

    data: MarkupProfile[] = [];

    isLoading = false;

    setData(value: MarkupProfile[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new MarkupProfilesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.MarkupProfiles.map(
                (result): MarkupProfile => ({
                    id: result.id.toString(),
                    Name: result.Name,
                    Comment: result.Comment,
                    Description: result.Description,
                    Values: result.Values.map((item) => ({
                        SymbolId: item.SymbolId.toString(),
                        Bid: item.Bid.toInt32(),
                        Ask: item.Ask.toInt32(),
                    })),
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: MarkupProfile) {
        const request = new MarkupProfileCreateRequest();
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        request.Values = record.Values.map(
            (item) =>
                new MarkupValue(
                    item.SymbolId ? UUID.fromString(item.SymbolId) : undefined,
                    typeof item.Bid === 'number' ? UInt64.fromNumber(item.Bid) : undefined,
                    typeof item.Ask === 'number' ? UInt64.fromNumber(item.Ask) : undefined,
                ),
        );

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: MarkupProfile) {
        const request = new MarkupProfileModifyRequest();
        request.MarkupProfileId = UUID.fromString(record.id);
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        request.NewValues = record.Values.map(
            (item) =>
                new MarkupValue(
                    item.SymbolId ? UUID.fromString(item.SymbolId) : undefined,
                    typeof item.Bid === 'number' ? UInt64.fromNumber(item.Bid) : undefined,
                    typeof item.Ask === 'number' ? UInt64.fromNumber(item.Ask) : undefined,
                ),
        );
        request.ValuesReset = !request.NewValues.length;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new MarkupProfileDeleteRequest();
        request.MarkupProfileId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const markupProfileStore = new MarkupProfileStore();
