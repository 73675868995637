
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * SymbolGroupSettings struct
 */
class SymbolGroupSettings {
  /**
   * Initialize struct
   * @param {!boolean=} isEnabled
   * @param {!boolean=} isTradeAllowed
   * @param {number=} minTradeAmount
   * @param {number=} maxTradeAmount
   * @param {number=} tradeAmountStep
   * @param {!boolean=} useDefaultSymbolTradeSettings
   * @param {UUID=} commissionProfileId
   * @param {UUID=} markupProfileId
   * @constructor
   */
  constructor (isEnabled = false, isTradeAllowed = false, minTradeAmount = null, maxTradeAmount = null, tradeAmountStep = null, useDefaultSymbolTradeSettings = true, commissionProfileId = null, markupProfileId = null) {
    this.IsEnabled = isEnabled
    this.IsTradeAllowed = isTradeAllowed
    this.MinTradeAmount = minTradeAmount
    this.MaxTradeAmount = maxTradeAmount
    this.TradeAmountStep = tradeAmountStep
    this.UseDefaultSymbolTradeSettings = useDefaultSymbolTradeSettings
    this.CommissionProfileId = commissionProfileId
    this.MarkupProfileId = markupProfileId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SymbolGroupSettings}
   * @param {!SymbolGroupSettings} other Other struct
   * @returns {!SymbolGroupSettings} This struct
   */
  copy (other) {
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsTradeAllowed != null) {
      this.IsTradeAllowed = other.IsTradeAllowed
    } else {
      this.IsTradeAllowed = null
    }
    if (other.MinTradeAmount != null) {
      this.MinTradeAmount = other.MinTradeAmount
    } else {
      this.MinTradeAmount = null
    }
    if (other.MaxTradeAmount != null) {
      this.MaxTradeAmount = other.MaxTradeAmount
    } else {
      this.MaxTradeAmount = null
    }
    if (other.TradeAmountStep != null) {
      this.TradeAmountStep = other.TradeAmountStep
    } else {
      this.TradeAmountStep = null
    }
    if (other.UseDefaultSymbolTradeSettings != null) {
      this.UseDefaultSymbolTradeSettings = other.UseDefaultSymbolTradeSettings
    } else {
      this.UseDefaultSymbolTradeSettings = null
    }
    if (other.CommissionProfileId != null) {
      this.CommissionProfileId = new UUID(other.CommissionProfileId)
    } else {
      this.CommissionProfileId = null
    }
    if (other.MarkupProfileId != null) {
      this.MarkupProfileId = new UUID(other.MarkupProfileId)
    } else {
      this.MarkupProfileId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SymbolGroupSettings}
   * @returns {!SymbolGroupSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SymbolGroupSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SymbolGroupSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SymbolGroupSettings}
   * @param {!SymbolGroupSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SymbolGroupSettings)) {
      throw new TypeError('Instance of SymbolGroupSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SymbolGroupSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsTradeAllowed: ((this.IsTradeAllowed != null) ? this.IsTradeAllowed : null),
      MinTradeAmount: ((this.MinTradeAmount != null) ? this.MinTradeAmount : null),
      MaxTradeAmount: ((this.MaxTradeAmount != null) ? this.MaxTradeAmount : null),
      TradeAmountStep: ((this.TradeAmountStep != null) ? this.TradeAmountStep : null),
      UseDefaultSymbolTradeSettings: ((this.UseDefaultSymbolTradeSettings != null) ? this.UseDefaultSymbolTradeSettings : null),
      CommissionProfileId: ((this.CommissionProfileId != null) ? this.CommissionProfileId.toString() : null),
      MarkupProfileId: ((this.MarkupProfileId != null) ? this.MarkupProfileId.toString() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SymbolGroupSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SymbolGroupSettings} other Object value
   * @returns {!SymbolGroupSettings} Created struct
   */
  static fromObject (other) {
    return new SymbolGroupSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SymbolGroupSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SymbolGroupSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SymbolGroupSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 24
  }
}

export { SymbolGroupSettings };

/**
 * Fast Binary Encoding SymbolGroupSettings field model
 */
class FieldModelSymbolGroupSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.IsEnabled = new fbe.FieldModelBool(buffer, 4 + 4)
    this.IsTradeAllowed = new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.MinTradeAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.IsTradeAllowed.fbeOffset + this.IsTradeAllowed.fbeSize), buffer, this.IsTradeAllowed.fbeOffset + this.IsTradeAllowed.fbeSize)
    this.MaxTradeAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MinTradeAmount.fbeOffset + this.MinTradeAmount.fbeSize), buffer, this.MinTradeAmount.fbeOffset + this.MinTradeAmount.fbeSize)
    this.TradeAmountStep = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MaxTradeAmount.fbeOffset + this.MaxTradeAmount.fbeSize), buffer, this.MaxTradeAmount.fbeOffset + this.MaxTradeAmount.fbeSize)
    this.UseDefaultSymbolTradeSettings = new fbe.FieldModelBool(buffer, this.TradeAmountStep.fbeOffset + this.TradeAmountStep.fbeSize)
    this.CommissionProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.UseDefaultSymbolTradeSettings.fbeOffset + this.UseDefaultSymbolTradeSettings.fbeSize), buffer, this.UseDefaultSymbolTradeSettings.fbeOffset + this.UseDefaultSymbolTradeSettings.fbeSize)
    this.MarkupProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.CommissionProfileId.fbeOffset + this.CommissionProfileId.fbeSize), buffer, this.CommissionProfileId.fbeOffset + this.CommissionProfileId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.IsEnabled.fbeSize + this.IsTradeAllowed.fbeSize + this.MinTradeAmount.fbeSize + this.MaxTradeAmount.fbeSize + this.TradeAmountStep.fbeSize + this.UseDefaultSymbolTradeSettings.fbeSize + this.CommissionProfileId.fbeSize + this.MarkupProfileId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.IsEnabled.fbeExtra + this.IsTradeAllowed.fbeExtra + this.MinTradeAmount.fbeExtra + this.MaxTradeAmount.fbeExtra + this.TradeAmountStep.fbeExtra + this.UseDefaultSymbolTradeSettings.fbeExtra + this.CommissionProfileId.fbeExtra + this.MarkupProfileId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSymbolGroupSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 24
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeAllowed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MinTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeAmountStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.UseDefaultSymbolTradeSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseDefaultSymbolTradeSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseDefaultSymbolTradeSettings.fbeSize

    if ((fbeCurrentSize + this.CommissionProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileId.fbeSize

    if ((fbeCurrentSize + this.MarkupProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarkupProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarkupProfileId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!SymbolGroupSettings} fbeValue Default value, defaults is new SymbolGroupSettings()
   * @returns {!SymbolGroupSettings} SymbolGroupSettings value
   */
  get (fbeValue = new SymbolGroupSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!SymbolGroupSettings} fbeValue SymbolGroupSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get(false)
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeAllowed.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeAllowed = this.IsTradeAllowed.get(false)
    } else {
      fbeValue.IsTradeAllowed = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.MinTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MinTradeAmount = this.MinTradeAmount.get()
    } else {
      fbeValue.MinTradeAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.MaxTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.MaxTradeAmount = this.MaxTradeAmount.get()
    } else {
      fbeValue.MaxTradeAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.TradeAmountStep.fbeSize) <= fbeStructSize) {
      fbeValue.TradeAmountStep = this.TradeAmountStep.get()
    } else {
      fbeValue.TradeAmountStep = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.UseDefaultSymbolTradeSettings.fbeSize) <= fbeStructSize) {
      fbeValue.UseDefaultSymbolTradeSettings = this.UseDefaultSymbolTradeSettings.get(true)
    } else {
      fbeValue.UseDefaultSymbolTradeSettings = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseDefaultSymbolTradeSettings.fbeSize

    if ((fbeCurrentSize + this.CommissionProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionProfileId = this.CommissionProfileId.get()
    } else {
      fbeValue.CommissionProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionProfileId.fbeSize

    if ((fbeCurrentSize + this.MarkupProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.MarkupProfileId = this.MarkupProfileId.get()
    } else {
      fbeValue.MarkupProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarkupProfileId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSymbolGroupSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSymbolGroupSettings}
   * @param {!SymbolGroupSettings} fbeValue SymbolGroupSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSymbolGroupSettings}
   * @param {SymbolGroupSettings} fbeValue SymbolGroupSettings value
   */
  setFields (fbeValue) {
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsTradeAllowed.set(fbeValue.IsTradeAllowed)
    this.MinTradeAmount.set(fbeValue.MinTradeAmount)
    this.MaxTradeAmount.set(fbeValue.MaxTradeAmount)
    this.TradeAmountStep.set(fbeValue.TradeAmountStep)
    this.UseDefaultSymbolTradeSettings.set(fbeValue.UseDefaultSymbolTradeSettings)
    this.CommissionProfileId.set(fbeValue.CommissionProfileId)
    this.MarkupProfileId.set(fbeValue.MarkupProfileId)
  }
}

export { FieldModelSymbolGroupSettings };

/**
 * Fast Binary Encoding SymbolGroupSettings model
 */
class SymbolGroupSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSymbolGroupSettings(this.buffer, 4)
  }

  /**
   * Get the SymbolGroupSettings model
   * @this {!SymbolGroupSettingsModel}
   * @returns {!FieldModelSymbolGroupSettings} model SymbolGroupSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SymbolGroupSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SymbolGroupSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SymbolGroupSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SymbolGroupSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSymbolGroupSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SymbolGroupSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SymbolGroupSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SymbolGroupSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SymbolGroupSettingsModel}
   * @param {!SymbolGroupSettings} value SymbolGroupSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SymbolGroupSettingsModel}
   * @param {!SymbolGroupSettings} value SymbolGroupSettings value, defaults is new SymbolGroupSettings()
   * @return {!object} Deserialized SymbolGroupSettings value and its size
   */
  deserialize (value = new SymbolGroupSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SymbolGroupSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SymbolGroupSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SymbolGroupSettingsModel}
   * @param {!number} prev Previous SymbolGroupSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SymbolGroupSettingsModel };
