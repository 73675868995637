
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {GatewayStatus} from './GatewayStatus';
import {FieldModelGatewayStatus} from './GatewayStatus';

/**
 * Gateway struct
 */
class Gateway {
  /**
   * Initialize struct
   * @param {!UUID=} id
   * @param {!string=} moduleName
   * @param {!string=} name
   * @param {string=} host
   * @param {number=} port
   * @param {!boolean=} isEnabled
   * @param {!GatewayStatus=} gatewayStatus
   * @param {UUID=} symbolMapId
   * @param {!Map=} parameters
   * @param {!boolean=} isRemote
   * @constructor
   */
  constructor (id = UUID.sequential(), moduleName = '', name = '', host = null, port = null, isEnabled = false, gatewayStatus = new GatewayStatus(), symbolMapId = null, parameters = new Map(), isRemote = false) {
    this.id = id
    this.ModuleName = moduleName
    this.Name = name
    this.Host = host
    this.Port = port
    this.IsEnabled = isEnabled
    this.GatewayStatus = gatewayStatus
    this.SymbolMapId = symbolMapId
    this.Parameters = parameters
    this.IsRemote = isRemote
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Gateway}
   * @param {!Gateway} other Other struct
   * @returns {!Gateway} This struct
   */
  copy (other) {
    if (other.id != null) {
      this.id = new UUID(other.id)
    } else {
      this.id = null
    }
    if (other.ModuleName != null) {
      this.ModuleName = other.ModuleName
    } else {
      this.ModuleName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Host != null) {
      this.Host = other.Host
    } else {
      this.Host = null
    }
    if (other.Port != null) {
      this.Port = other.Port
    } else {
      this.Port = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.GatewayStatus != null) {
      this.GatewayStatus = GatewayStatus.fromObject(other.GatewayStatus)
    } else {
      this.GatewayStatus = null
    }
    if (other.SymbolMapId != null) {
      this.SymbolMapId = new UUID(other.SymbolMapId)
    } else {
      this.SymbolMapId = null
    }
    if (other.Parameters != null) {
      this.Parameters = new Map()
      Object.keys(other.Parameters).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.Parameters[key] != null) {
            let tempValue
            tempValue = other.Parameters[key]
            this.Parameters.set(tempKey, tempValue)
          } else {
            this.Parameters.set(tempKey, null)
          }
        }
      })
    } else {
      this.Parameters = null
    }
    if (other.IsRemote != null) {
      this.IsRemote = other.IsRemote
    } else {
      this.IsRemote = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Gateway}
   * @returns {!Gateway} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new GatewayModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new GatewayModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Gateway}
   * @param {!Gateway} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Gateway)) {
      throw new TypeError('Instance of Gateway is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.id != null) {
      if ((other.id == null) || !this.id.eq(other.id)) {
        return false
      }
    } else if (other.id != null) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Gateway}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      id: ((this.id != null) ? this.id.toString() : null),
      ModuleName: ((this.ModuleName != null) ? this.ModuleName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Host: ((this.Host != null) ? this.Host : null),
      Port: ((this.Port != null) ? this.Port : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      GatewayStatus: ((this.GatewayStatus != null) ? this.GatewayStatus : null),
      SymbolMapId: ((this.SymbolMapId != null) ? this.SymbolMapId.toString() : null),
      Parameters: ((this.Parameters != null) ? fbe.MapToObject(new Map(Array.from(this.Parameters, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null),
      IsRemote: ((this.IsRemote != null) ? this.IsRemote : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Gateway.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Gateway} other Object value
   * @returns {!Gateway} Created struct
   */
  static fromObject (other) {
    return new Gateway().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Gateway}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Gateway.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Gateway}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 70
  }
}

export { Gateway };

/**
 * Fast Binary Encoding Gateway field model
 */
class FieldModelGateway extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.id = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.ModuleName = new fbe.FieldModelString(buffer, this.id.fbeOffset + this.id.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.ModuleName.fbeOffset + this.ModuleName.fbeSize)
    this.Host = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize), buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Port = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.Host.fbeOffset + this.Host.fbeSize), buffer, this.Host.fbeOffset + this.Host.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.Port.fbeOffset + this.Port.fbeSize)
    this.GatewayStatus = new FieldModelGatewayStatus(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.SymbolMapId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.GatewayStatus.fbeOffset + this.GatewayStatus.fbeSize), buffer, this.GatewayStatus.fbeOffset + this.GatewayStatus.fbeSize)
    this.Parameters = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this.SymbolMapId.fbeOffset + this.SymbolMapId.fbeSize), new fbe.FieldModelString(buffer, this.SymbolMapId.fbeOffset + this.SymbolMapId.fbeSize), buffer, this.SymbolMapId.fbeOffset + this.SymbolMapId.fbeSize)
    this.IsRemote = new fbe.FieldModelBool(buffer, this.Parameters.fbeOffset + this.Parameters.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelGateway}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelGateway}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.id.fbeSize + this.ModuleName.fbeSize + this.Name.fbeSize + this.Host.fbeSize + this.Port.fbeSize + this.IsEnabled.fbeSize + this.GatewayStatus.fbeSize + this.SymbolMapId.fbeSize + this.Parameters.fbeSize + this.IsRemote.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelGateway}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.id.fbeExtra + this.ModuleName.fbeExtra + this.Name.fbeExtra + this.Host.fbeExtra + this.Port.fbeExtra + this.IsEnabled.fbeExtra + this.GatewayStatus.fbeExtra + this.SymbolMapId.fbeExtra + this.Parameters.fbeExtra + this.IsRemote.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelGateway}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelGateway.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelGateway}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 70
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelGateway}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelGateway}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.id.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.ModuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Host.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Host.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Host.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Port.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.GatewayStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewayStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayStatus.fbeSize

    if ((fbeCurrentSize + this.SymbolMapId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolMapId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolMapId.fbeSize

    if ((fbeCurrentSize + this.Parameters.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Parameters.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Parameters.fbeSize

    if ((fbeCurrentSize + this.IsRemote.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsRemote.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsRemote.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelGateway}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelGateway}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelGateway}
   * @param {!Gateway} fbeValue Default value, defaults is new Gateway()
   * @returns {!Gateway} Gateway value
   */
  get (fbeValue = new Gateway()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelGateway}
   * @param {!Gateway} fbeValue Gateway value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) <= fbeStructSize) {
      fbeValue.id = this.id.get(UUID.sequential())
    } else {
      fbeValue.id = UUID.sequential()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.ModuleName.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleName = this.ModuleName.get()
    } else {
      fbeValue.ModuleName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Host.fbeSize) <= fbeStructSize) {
      fbeValue.Host = this.Host.get()
    } else {
      fbeValue.Host = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Host.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) <= fbeStructSize) {
      fbeValue.Port = this.Port.get()
    } else {
      fbeValue.Port = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.GatewayStatus.fbeSize) <= fbeStructSize) {
      fbeValue.GatewayStatus = this.GatewayStatus.get()
    } else {
      fbeValue.GatewayStatus = new GatewayStatus()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewayStatus.fbeSize

    if ((fbeCurrentSize + this.SymbolMapId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolMapId = this.SymbolMapId.get()
    } else {
      fbeValue.SymbolMapId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolMapId.fbeSize

    if ((fbeCurrentSize + this.Parameters.fbeSize) <= fbeStructSize) {
      this.Parameters.get(fbeValue.Parameters)
    } else {
      fbeValue.Parameters.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Parameters.fbeSize

    if ((fbeCurrentSize + this.IsRemote.fbeSize) <= fbeStructSize) {
      fbeValue.IsRemote = this.IsRemote.get()
    } else {
      fbeValue.IsRemote = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsRemote.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelGateway}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelGateway}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelGateway}
   * @param {!Gateway} fbeValue Gateway value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelGateway}
   * @param {Gateway} fbeValue Gateway value
   */
  setFields (fbeValue) {
    this.id.set(fbeValue.id)
    this.ModuleName.set(fbeValue.ModuleName)
    this.Name.set(fbeValue.Name)
    this.Host.set(fbeValue.Host)
    this.Port.set(fbeValue.Port)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.GatewayStatus.set(fbeValue.GatewayStatus)
    this.SymbolMapId.set(fbeValue.SymbolMapId)
    this.Parameters.set(fbeValue.Parameters)
    this.IsRemote.set(fbeValue.IsRemote)
  }
}

export { FieldModelGateway };

/**
 * Fast Binary Encoding Gateway model
 */
class GatewayModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelGateway(this.buffer, 4)
  }

  /**
   * Get the Gateway model
   * @this {!GatewayModel}
   * @returns {!FieldModelGateway} model Gateway model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!GatewayModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!GatewayModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return GatewayModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!GatewayModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelGateway.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!GatewayModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!GatewayModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!GatewayModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!GatewayModel}
   * @param {!Gateway} value Gateway value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!GatewayModel}
   * @param {!Gateway} value Gateway value, defaults is new Gateway()
   * @return {!object} Deserialized Gateway value and its size
   */
  deserialize (value = new Gateway()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Gateway(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Gateway(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!GatewayModel}
   * @param {!number} prev Previous Gateway model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { GatewayModel };
