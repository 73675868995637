import { MailServer } from '@models/MailServer';
import { MailServerCreateRequest } from '@api/fbe/manager/MailServerCreateRequest';
import { MailServerModifyRequest } from '@api/fbe/manager/MailServerModifyRequest';
import { EventType } from '@api/fbe/core/EventType';
import { MailServer as MailServerApi } from '@api/fbe/core/MailServer';
import { SSLMode } from '@api/fbe/core/SSLMode';
import { UUID } from '@api/fbe/uuid';

export const adaptFromApi = (mailServer: MailServerApi): MailServer => ({
    ...mailServer,
    id: mailServer.id.toString(),
    SSLMode: mailServer.SSLMode.valueOf(),
    EventTypes: Array.from(mailServer.EventTypes).map((type) => type.valueOf()),
});

export const adaptToApiCreate = (request: MailServerCreateRequest, mailServer: MailServer): MailServerCreateRequest => {
    request.Name = mailServer.Name;
    request.Comment = mailServer.Comment;
    request.Description = mailServer.Description;
    request.SMTPServer = mailServer.SMTPServer;
    request.SMTPServerPort = mailServer.SMTPServerPort!;
    request.IsEnabled = mailServer.IsEnabled;
    request.SMTPLogin = mailServer.SMTPLogin;
    request.SMTPPassword = mailServer.SMTPPassword;
    request.SenderEmail = mailServer.SenderEmail;
    request.SenderName = mailServer.SenderName;
    request.SSLMode = new SSLMode(mailServer.SSLMode || 0);
    request.EventTypes = new Set(mailServer.EventTypes ? mailServer.EventTypes.map((type) => new EventType(type)) : []);
    return request;
};

export const adaptToApiModify = (request: MailServerModifyRequest, mailServer: MailServer): MailServerModifyRequest => {
    request.MailServerId = UUID.fromString(mailServer.id!);
    request.NewName = mailServer.Name;
    request.NewComment = mailServer.Comment;
    request.NewDescription = mailServer.Description;
    request.NewSMTPServer = mailServer.SMTPServer;
    request.NewSMTPServerPort = mailServer.SMTPServerPort;
    request.NewIsEnabled = mailServer.IsEnabled;
    request.NewSMTPLogin = mailServer.SMTPLogin;
    request.NewSMTPPassword = mailServer.SMTPPassword;
    request.NewSenderEmail = mailServer.SenderEmail;
    request.NewSenderName = mailServer.SenderName;
    request.NewSSLMode = new SSLMode(mailServer.SSLMode || 0);
    if (mailServer.EventTypes) {
        request.NewEventTypes = new Set(mailServer.EventTypes.map((type) => new EventType(type)));
        request.EventTypesReset = true;
    }
    return request;
};
