
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * AccountMarginSnapshot struct
 */
class AccountMarginSnapshot {
  /**
   * Initialize struct
   * @param {!number=} balance
   * @param {!number=} credit
   * @param {!number=} equity
   * @param {!number=} margin
   * @param {!number=} marginLevel
   * @param {!number=} profit
   * @param {!number=} freeMargin
   * @param {!number=} prevDayBalance
   * @param {!number=} dayCloseProfit
   * @param {!number=} dayDeposit
   * @param {!number=} openProfit
   * @param {!number=} totalCloseProfit
   * @param {!number=} totalDeposit
   * @param {!number=} totalWithdrawal
   * @constructor
   */
  constructor (balance = 0.0, credit = 0.0, equity = 0.0, margin = 0.0, marginLevel = 0.0, profit = 0.0, freeMargin = 0.0, prevDayBalance = 0.0, dayCloseProfit = 0.0, dayDeposit = 0.0, openProfit = 0.0, totalCloseProfit = 0.0, totalDeposit = 0.0, totalWithdrawal = 0.0) {
    this.Balance = balance
    this.Credit = credit
    this.Equity = equity
    this.Margin = margin
    this.MarginLevel = marginLevel
    this.Profit = profit
    this.FreeMargin = freeMargin
    this.PrevDayBalance = prevDayBalance
    this.DayCloseProfit = dayCloseProfit
    this.DayDeposit = dayDeposit
    this.OpenProfit = openProfit
    this.TotalCloseProfit = totalCloseProfit
    this.TotalDeposit = totalDeposit
    this.TotalWithdrawal = totalWithdrawal
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountMarginSnapshot}
   * @param {!AccountMarginSnapshot} other Other struct
   * @returns {!AccountMarginSnapshot} This struct
   */
  copy (other) {
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Equity != null) {
      this.Equity = other.Equity
    } else {
      this.Equity = null
    }
    if (other.Margin != null) {
      this.Margin = other.Margin
    } else {
      this.Margin = null
    }
    if (other.MarginLevel != null) {
      this.MarginLevel = other.MarginLevel
    } else {
      this.MarginLevel = null
    }
    if (other.Profit != null) {
      this.Profit = other.Profit
    } else {
      this.Profit = null
    }
    if (other.FreeMargin != null) {
      this.FreeMargin = other.FreeMargin
    } else {
      this.FreeMargin = null
    }
    if (other.PrevDayBalance != null) {
      this.PrevDayBalance = other.PrevDayBalance
    } else {
      this.PrevDayBalance = null
    }
    if (other.DayCloseProfit != null) {
      this.DayCloseProfit = other.DayCloseProfit
    } else {
      this.DayCloseProfit = null
    }
    if (other.DayDeposit != null) {
      this.DayDeposit = other.DayDeposit
    } else {
      this.DayDeposit = null
    }
    if (other.OpenProfit != null) {
      this.OpenProfit = other.OpenProfit
    } else {
      this.OpenProfit = null
    }
    if (other.TotalCloseProfit != null) {
      this.TotalCloseProfit = other.TotalCloseProfit
    } else {
      this.TotalCloseProfit = null
    }
    if (other.TotalDeposit != null) {
      this.TotalDeposit = other.TotalDeposit
    } else {
      this.TotalDeposit = null
    }
    if (other.TotalWithdrawal != null) {
      this.TotalWithdrawal = other.TotalWithdrawal
    } else {
      this.TotalWithdrawal = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountMarginSnapshot}
   * @returns {!AccountMarginSnapshot} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountMarginSnapshotModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountMarginSnapshotModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountMarginSnapshot}
   * @param {!AccountMarginSnapshot} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountMarginSnapshot)) {
      throw new TypeError('Instance of AccountMarginSnapshot is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountMarginSnapshot}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Equity: ((this.Equity != null) ? this.Equity : null),
      Margin: ((this.Margin != null) ? this.Margin : null),
      MarginLevel: ((this.MarginLevel != null) ? this.MarginLevel : null),
      Profit: ((this.Profit != null) ? this.Profit : null),
      FreeMargin: ((this.FreeMargin != null) ? this.FreeMargin : null),
      PrevDayBalance: ((this.PrevDayBalance != null) ? this.PrevDayBalance : null),
      DayCloseProfit: ((this.DayCloseProfit != null) ? this.DayCloseProfit : null),
      DayDeposit: ((this.DayDeposit != null) ? this.DayDeposit : null),
      OpenProfit: ((this.OpenProfit != null) ? this.OpenProfit : null),
      TotalCloseProfit: ((this.TotalCloseProfit != null) ? this.TotalCloseProfit : null),
      TotalDeposit: ((this.TotalDeposit != null) ? this.TotalDeposit : null),
      TotalWithdrawal: ((this.TotalWithdrawal != null) ? this.TotalWithdrawal : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountMarginSnapshot.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountMarginSnapshot} other Object value
   * @returns {!AccountMarginSnapshot} Created struct
   */
  static fromObject (other) {
    return new AccountMarginSnapshot().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountMarginSnapshot}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountMarginSnapshot.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountMarginSnapshot}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 13004
  }
}

export { AccountMarginSnapshot };

/**
 * Fast Binary Encoding AccountMarginSnapshot field model
 */
class FieldModelAccountMarginSnapshot extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Balance = new fbe.FieldModelDouble(buffer, 4 + 4)
    this.Credit = new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Equity = new fbe.FieldModelDouble(buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
    this.Margin = new fbe.FieldModelDouble(buffer, this.Equity.fbeOffset + this.Equity.fbeSize)
    this.MarginLevel = new fbe.FieldModelDouble(buffer, this.Margin.fbeOffset + this.Margin.fbeSize)
    this.Profit = new fbe.FieldModelDouble(buffer, this.MarginLevel.fbeOffset + this.MarginLevel.fbeSize)
    this.FreeMargin = new fbe.FieldModelDouble(buffer, this.Profit.fbeOffset + this.Profit.fbeSize)
    this.PrevDayBalance = new fbe.FieldModelDouble(buffer, this.FreeMargin.fbeOffset + this.FreeMargin.fbeSize)
    this.DayCloseProfit = new fbe.FieldModelDouble(buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize)
    this.DayDeposit = new fbe.FieldModelDouble(buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize)
    this.OpenProfit = new fbe.FieldModelDouble(buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize)
    this.TotalCloseProfit = new fbe.FieldModelDouble(buffer, this.OpenProfit.fbeOffset + this.OpenProfit.fbeSize)
    this.TotalDeposit = new fbe.FieldModelDouble(buffer, this.TotalCloseProfit.fbeOffset + this.TotalCloseProfit.fbeSize)
    this.TotalWithdrawal = new fbe.FieldModelDouble(buffer, this.TotalDeposit.fbeOffset + this.TotalDeposit.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Balance.fbeSize + this.Credit.fbeSize + this.Equity.fbeSize + this.Margin.fbeSize + this.MarginLevel.fbeSize + this.Profit.fbeSize + this.FreeMargin.fbeSize + this.PrevDayBalance.fbeSize + this.DayCloseProfit.fbeSize + this.DayDeposit.fbeSize + this.OpenProfit.fbeSize + this.TotalCloseProfit.fbeSize + this.TotalDeposit.fbeSize + this.TotalWithdrawal.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Equity.fbeExtra + this.Margin.fbeExtra + this.MarginLevel.fbeExtra + this.Profit.fbeExtra + this.FreeMargin.fbeExtra + this.PrevDayBalance.fbeExtra + this.DayCloseProfit.fbeExtra + this.DayDeposit.fbeExtra + this.OpenProfit.fbeExtra + this.TotalCloseProfit.fbeExtra + this.TotalDeposit.fbeExtra + this.TotalWithdrawal.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountMarginSnapshot.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 13004
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Equity.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Margin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.FreeMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FreeMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FreeMargin.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalWithdrawal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!AccountMarginSnapshot} fbeValue Default value, defaults is new AccountMarginSnapshot()
   * @returns {!AccountMarginSnapshot} AccountMarginSnapshot value
   */
  get (fbeValue = new AccountMarginSnapshot()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!AccountMarginSnapshot} fbeValue AccountMarginSnapshot value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Equity.fbeSize) <= fbeStructSize) {
      fbeValue.Equity = this.Equity.get()
    } else {
      fbeValue.Equity = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Equity.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) <= fbeStructSize) {
      fbeValue.Margin = this.Margin.get()
    } else {
      fbeValue.Margin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.MarginLevel.fbeSize) <= fbeStructSize) {
      fbeValue.MarginLevel = this.MarginLevel.get()
    } else {
      fbeValue.MarginLevel = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginLevel.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) <= fbeStructSize) {
      fbeValue.Profit = this.Profit.get()
    } else {
      fbeValue.Profit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.FreeMargin.fbeSize) <= fbeStructSize) {
      fbeValue.FreeMargin = this.FreeMargin.get()
    } else {
      fbeValue.FreeMargin = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FreeMargin.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayBalance = this.PrevDayBalance.get()
    } else {
      fbeValue.PrevDayBalance = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayCloseProfit = this.DayCloseProfit.get()
    } else {
      fbeValue.DayCloseProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.DayDeposit = this.DayDeposit.get()
    } else {
      fbeValue.DayDeposit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.OpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.OpenProfit = this.OpenProfit.get()
    } else {
      fbeValue.OpenProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenProfit.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalCloseProfit = this.TotalCloseProfit.get()
    } else {
      fbeValue.TotalCloseProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfit.fbeSize

    if ((fbeCurrentSize + this.TotalDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalDeposit = this.TotalDeposit.get()
    } else {
      fbeValue.TotalDeposit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDeposit.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalWithdrawal = this.TotalWithdrawal.get()
    } else {
      fbeValue.TotalWithdrawal = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawal.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountMarginSnapshot}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {!AccountMarginSnapshot} fbeValue AccountMarginSnapshot value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountMarginSnapshot}
   * @param {AccountMarginSnapshot} fbeValue AccountMarginSnapshot value
   */
  setFields (fbeValue) {
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Equity.set(fbeValue.Equity)
    this.Margin.set(fbeValue.Margin)
    this.MarginLevel.set(fbeValue.MarginLevel)
    this.Profit.set(fbeValue.Profit)
    this.FreeMargin.set(fbeValue.FreeMargin)
    this.PrevDayBalance.set(fbeValue.PrevDayBalance)
    this.DayCloseProfit.set(fbeValue.DayCloseProfit)
    this.DayDeposit.set(fbeValue.DayDeposit)
    this.OpenProfit.set(fbeValue.OpenProfit)
    this.TotalCloseProfit.set(fbeValue.TotalCloseProfit)
    this.TotalDeposit.set(fbeValue.TotalDeposit)
    this.TotalWithdrawal.set(fbeValue.TotalWithdrawal)
  }
}

export { FieldModelAccountMarginSnapshot };

/**
 * Fast Binary Encoding AccountMarginSnapshot model
 */
class AccountMarginSnapshotModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountMarginSnapshot(this.buffer, 4)
  }

  /**
   * Get the AccountMarginSnapshot model
   * @this {!AccountMarginSnapshotModel}
   * @returns {!FieldModelAccountMarginSnapshot} model AccountMarginSnapshot model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountMarginSnapshotModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountMarginSnapshotModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountMarginSnapshotModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountMarginSnapshotModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountMarginSnapshot.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountMarginSnapshotModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountMarginSnapshotModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountMarginSnapshotModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountMarginSnapshotModel}
   * @param {!AccountMarginSnapshot} value AccountMarginSnapshot value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountMarginSnapshotModel}
   * @param {!AccountMarginSnapshot} value AccountMarginSnapshot value, defaults is new AccountMarginSnapshot()
   * @return {!object} Deserialized AccountMarginSnapshot value and its size
   */
  deserialize (value = new AccountMarginSnapshot()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountMarginSnapshot(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountMarginSnapshot(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountMarginSnapshotModel}
   * @param {!number} prev Previous AccountMarginSnapshot model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountMarginSnapshotModel };
