
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {LoginType} from '../core/LoginType';
import {FieldModelLoginType} from '../core/LoginType';

/**
 * ClientLoginInfoRecord struct
 */
class ClientLoginInfoRecord {
  /**
   * Initialize struct
   * @param {!number=} recordId
   * @param {!number=} login
   * @param {!Date=} loginTimestamp
   * @param {!LoginType=} loginType
   * @param {!string=} device
   * @param {!string=} deviceModel
   * @param {!string=} browser
   * @param {!string=} oS
   * @param {!string=} oSVersionMajor
   * @param {!string=} oSVersionMinor
   * @param {!string=} oSVersionBuild
   * @param {!string=} oSVersionPatch
   * @param {!string=} accountType
   * @param {!string=} externalAccountId
   * @param {!string=} clientVersionMajor
   * @param {!string=} clientVersionMinor
   * @param {!string=} clientVersionBuild
   * @param {!string=} clientVersionPatch
   * @param {!string=} clientBuildId
   * @param {!string=} uniqueId
   * @param {!UUID=} sessionId
   * @param {!string=} externalClientId
   * @param {!string=} iPAddress
   * @param {number=} protocolVersionMajor
   * @param {number=} protocolVersionMinor
   * @param {number=} port
   * @param {!UUID=} accountGroupId
   * @param {!string=} accountGroupName
   * @constructor
   */
  constructor (recordId = new UInt64(0, 0), login = new UInt64(0, 0), loginTimestamp = new Date(0), loginType = new LoginType(), device = '', deviceModel = '', browser = '', oS = '', oSVersionMajor = '', oSVersionMinor = '', oSVersionBuild = '', oSVersionPatch = '', accountType = '', externalAccountId = '', clientVersionMajor = '', clientVersionMinor = '', clientVersionBuild = '', clientVersionPatch = '', clientBuildId = '', uniqueId = '', sessionId = new UUID(), externalClientId = '', iPAddress = '', protocolVersionMajor = null, protocolVersionMinor = null, port = null, accountGroupId = new UUID(), accountGroupName = '') {
    this.RecordId = recordId
    this.Login = login
    this.LoginTimestamp = loginTimestamp
    this.LoginType = loginType
    this.Device = device
    this.DeviceModel = deviceModel
    this.Browser = browser
    this.OS = oS
    this.OSVersionMajor = oSVersionMajor
    this.OSVersionMinor = oSVersionMinor
    this.OSVersionBuild = oSVersionBuild
    this.OSVersionPatch = oSVersionPatch
    this.AccountType = accountType
    this.ExternalAccountId = externalAccountId
    this.ClientVersionMajor = clientVersionMajor
    this.ClientVersionMinor = clientVersionMinor
    this.ClientVersionBuild = clientVersionBuild
    this.ClientVersionPatch = clientVersionPatch
    this.ClientBuildId = clientBuildId
    this.UniqueId = uniqueId
    this.SessionId = sessionId
    this.ExternalClientId = externalClientId
    this.IPAddress = iPAddress
    this.ProtocolVersionMajor = protocolVersionMajor
    this.ProtocolVersionMinor = protocolVersionMinor
    this.Port = port
    this.AccountGroupId = accountGroupId
    this.AccountGroupName = accountGroupName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ClientLoginInfoRecord}
   * @param {!ClientLoginInfoRecord} other Other struct
   * @returns {!ClientLoginInfoRecord} This struct
   */
  copy (other) {
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.LoginTimestamp != null) {
      if (other.LoginTimestamp instanceof Date) {
        this.LoginTimestamp = new Date(other.LoginTimestamp.getTime())
      } else {
        this.LoginTimestamp = new Date(Math.round(other.LoginTimestamp / 1000000))
      }
    } else {
      this.LoginTimestamp = null
    }
    if (other.LoginType != null) {
      this.LoginType = LoginType.fromObject(other.LoginType)
    } else {
      this.LoginType = null
    }
    if (other.Device != null) {
      this.Device = other.Device
    } else {
      this.Device = null
    }
    if (other.DeviceModel != null) {
      this.DeviceModel = other.DeviceModel
    } else {
      this.DeviceModel = null
    }
    if (other.Browser != null) {
      this.Browser = other.Browser
    } else {
      this.Browser = null
    }
    if (other.OS != null) {
      this.OS = other.OS
    } else {
      this.OS = null
    }
    if (other.OSVersionMajor != null) {
      this.OSVersionMajor = other.OSVersionMajor
    } else {
      this.OSVersionMajor = null
    }
    if (other.OSVersionMinor != null) {
      this.OSVersionMinor = other.OSVersionMinor
    } else {
      this.OSVersionMinor = null
    }
    if (other.OSVersionBuild != null) {
      this.OSVersionBuild = other.OSVersionBuild
    } else {
      this.OSVersionBuild = null
    }
    if (other.OSVersionPatch != null) {
      this.OSVersionPatch = other.OSVersionPatch
    } else {
      this.OSVersionPatch = null
    }
    if (other.AccountType != null) {
      this.AccountType = other.AccountType
    } else {
      this.AccountType = null
    }
    if (other.ExternalAccountId != null) {
      this.ExternalAccountId = other.ExternalAccountId
    } else {
      this.ExternalAccountId = null
    }
    if (other.ClientVersionMajor != null) {
      this.ClientVersionMajor = other.ClientVersionMajor
    } else {
      this.ClientVersionMajor = null
    }
    if (other.ClientVersionMinor != null) {
      this.ClientVersionMinor = other.ClientVersionMinor
    } else {
      this.ClientVersionMinor = null
    }
    if (other.ClientVersionBuild != null) {
      this.ClientVersionBuild = other.ClientVersionBuild
    } else {
      this.ClientVersionBuild = null
    }
    if (other.ClientVersionPatch != null) {
      this.ClientVersionPatch = other.ClientVersionPatch
    } else {
      this.ClientVersionPatch = null
    }
    if (other.ClientBuildId != null) {
      this.ClientBuildId = other.ClientBuildId
    } else {
      this.ClientBuildId = null
    }
    if (other.UniqueId != null) {
      this.UniqueId = other.UniqueId
    } else {
      this.UniqueId = null
    }
    if (other.SessionId != null) {
      this.SessionId = new UUID(other.SessionId)
    } else {
      this.SessionId = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.IPAddress != null) {
      this.IPAddress = other.IPAddress
    } else {
      this.IPAddress = null
    }
    if (other.ProtocolVersionMajor != null) {
      this.ProtocolVersionMajor = other.ProtocolVersionMajor
    } else {
      this.ProtocolVersionMajor = null
    }
    if (other.ProtocolVersionMinor != null) {
      this.ProtocolVersionMinor = other.ProtocolVersionMinor
    } else {
      this.ProtocolVersionMinor = null
    }
    if (other.Port != null) {
      this.Port = other.Port
    } else {
      this.Port = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.AccountGroupName != null) {
      this.AccountGroupName = other.AccountGroupName
    } else {
      this.AccountGroupName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ClientLoginInfoRecord}
   * @returns {!ClientLoginInfoRecord} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ClientLoginInfoRecordModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ClientLoginInfoRecordModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ClientLoginInfoRecord}
   * @param {!ClientLoginInfoRecord} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ClientLoginInfoRecord)) {
      throw new TypeError('Instance of ClientLoginInfoRecord is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ClientLoginInfoRecord}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      LoginTimestamp: ((this.LoginTimestamp != null) ? (this.LoginTimestamp.getTime() * 1000000) : null),
      LoginType: ((this.LoginType != null) ? this.LoginType : null),
      Device: ((this.Device != null) ? this.Device : null),
      DeviceModel: ((this.DeviceModel != null) ? this.DeviceModel : null),
      Browser: ((this.Browser != null) ? this.Browser : null),
      OS: ((this.OS != null) ? this.OS : null),
      OSVersionMajor: ((this.OSVersionMajor != null) ? this.OSVersionMajor : null),
      OSVersionMinor: ((this.OSVersionMinor != null) ? this.OSVersionMinor : null),
      OSVersionBuild: ((this.OSVersionBuild != null) ? this.OSVersionBuild : null),
      OSVersionPatch: ((this.OSVersionPatch != null) ? this.OSVersionPatch : null),
      AccountType: ((this.AccountType != null) ? this.AccountType : null),
      ExternalAccountId: ((this.ExternalAccountId != null) ? this.ExternalAccountId : null),
      ClientVersionMajor: ((this.ClientVersionMajor != null) ? this.ClientVersionMajor : null),
      ClientVersionMinor: ((this.ClientVersionMinor != null) ? this.ClientVersionMinor : null),
      ClientVersionBuild: ((this.ClientVersionBuild != null) ? this.ClientVersionBuild : null),
      ClientVersionPatch: ((this.ClientVersionPatch != null) ? this.ClientVersionPatch : null),
      ClientBuildId: ((this.ClientBuildId != null) ? this.ClientBuildId : null),
      UniqueId: ((this.UniqueId != null) ? this.UniqueId : null),
      SessionId: ((this.SessionId != null) ? this.SessionId.toString() : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      IPAddress: ((this.IPAddress != null) ? this.IPAddress : null),
      ProtocolVersionMajor: ((this.ProtocolVersionMajor != null) ? this.ProtocolVersionMajor : null),
      ProtocolVersionMinor: ((this.ProtocolVersionMinor != null) ? this.ProtocolVersionMinor : null),
      Port: ((this.Port != null) ? this.Port : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      AccountGroupName: ((this.AccountGroupName != null) ? this.AccountGroupName : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ClientLoginInfoRecord.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ClientLoginInfoRecord} other Object value
   * @returns {!ClientLoginInfoRecord} Created struct
   */
  static fromObject (other) {
    return new ClientLoginInfoRecord().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ClientLoginInfoRecord}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ClientLoginInfoRecord.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ClientLoginInfoRecord}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 192
  }
}

export { ClientLoginInfoRecord };

/**
 * Fast Binary Encoding ClientLoginInfoRecord field model
 */
class FieldModelClientLoginInfoRecord extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.RecordId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize)
    this.LoginTimestamp = new fbe.FieldModelTimestamp(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.LoginType = new FieldModelLoginType(buffer, this.LoginTimestamp.fbeOffset + this.LoginTimestamp.fbeSize)
    this.Device = new fbe.FieldModelString(buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize)
    this.DeviceModel = new fbe.FieldModelString(buffer, this.Device.fbeOffset + this.Device.fbeSize)
    this.Browser = new fbe.FieldModelString(buffer, this.DeviceModel.fbeOffset + this.DeviceModel.fbeSize)
    this.OS = new fbe.FieldModelString(buffer, this.Browser.fbeOffset + this.Browser.fbeSize)
    this.OSVersionMajor = new fbe.FieldModelString(buffer, this.OS.fbeOffset + this.OS.fbeSize)
    this.OSVersionMinor = new fbe.FieldModelString(buffer, this.OSVersionMajor.fbeOffset + this.OSVersionMajor.fbeSize)
    this.OSVersionBuild = new fbe.FieldModelString(buffer, this.OSVersionMinor.fbeOffset + this.OSVersionMinor.fbeSize)
    this.OSVersionPatch = new fbe.FieldModelString(buffer, this.OSVersionBuild.fbeOffset + this.OSVersionBuild.fbeSize)
    this.AccountType = new fbe.FieldModelString(buffer, this.OSVersionPatch.fbeOffset + this.OSVersionPatch.fbeSize)
    this.ExternalAccountId = new fbe.FieldModelString(buffer, this.AccountType.fbeOffset + this.AccountType.fbeSize)
    this.ClientVersionMajor = new fbe.FieldModelString(buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize)
    this.ClientVersionMinor = new fbe.FieldModelString(buffer, this.ClientVersionMajor.fbeOffset + this.ClientVersionMajor.fbeSize)
    this.ClientVersionBuild = new fbe.FieldModelString(buffer, this.ClientVersionMinor.fbeOffset + this.ClientVersionMinor.fbeSize)
    this.ClientVersionPatch = new fbe.FieldModelString(buffer, this.ClientVersionBuild.fbeOffset + this.ClientVersionBuild.fbeSize)
    this.ClientBuildId = new fbe.FieldModelString(buffer, this.ClientVersionPatch.fbeOffset + this.ClientVersionPatch.fbeSize)
    this.UniqueId = new fbe.FieldModelString(buffer, this.ClientBuildId.fbeOffset + this.ClientBuildId.fbeSize)
    this.SessionId = new fbe.FieldModelUUID(buffer, this.UniqueId.fbeOffset + this.UniqueId.fbeSize)
    this.ExternalClientId = new fbe.FieldModelString(buffer, this.SessionId.fbeOffset + this.SessionId.fbeSize)
    this.IPAddress = new fbe.FieldModelString(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.ProtocolVersionMajor = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.IPAddress.fbeOffset + this.IPAddress.fbeSize), buffer, this.IPAddress.fbeOffset + this.IPAddress.fbeSize)
    this.ProtocolVersionMinor = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize), buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize)
    this.Port = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.ProtocolVersionMinor.fbeOffset + this.ProtocolVersionMinor.fbeSize), buffer, this.ProtocolVersionMinor.fbeOffset + this.ProtocolVersionMinor.fbeSize)
    this.AccountGroupId = new fbe.FieldModelUUID(buffer, this.Port.fbeOffset + this.Port.fbeSize)
    this.AccountGroupName = new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.RecordId.fbeSize + this.Login.fbeSize + this.LoginTimestamp.fbeSize + this.LoginType.fbeSize + this.Device.fbeSize + this.DeviceModel.fbeSize + this.Browser.fbeSize + this.OS.fbeSize + this.OSVersionMajor.fbeSize + this.OSVersionMinor.fbeSize + this.OSVersionBuild.fbeSize + this.OSVersionPatch.fbeSize + this.AccountType.fbeSize + this.ExternalAccountId.fbeSize + this.ClientVersionMajor.fbeSize + this.ClientVersionMinor.fbeSize + this.ClientVersionBuild.fbeSize + this.ClientVersionPatch.fbeSize + this.ClientBuildId.fbeSize + this.UniqueId.fbeSize + this.SessionId.fbeSize + this.ExternalClientId.fbeSize + this.IPAddress.fbeSize + this.ProtocolVersionMajor.fbeSize + this.ProtocolVersionMinor.fbeSize + this.Port.fbeSize + this.AccountGroupId.fbeSize + this.AccountGroupName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.RecordId.fbeExtra + this.Login.fbeExtra + this.LoginTimestamp.fbeExtra + this.LoginType.fbeExtra + this.Device.fbeExtra + this.DeviceModel.fbeExtra + this.Browser.fbeExtra + this.OS.fbeExtra + this.OSVersionMajor.fbeExtra + this.OSVersionMinor.fbeExtra + this.OSVersionBuild.fbeExtra + this.OSVersionPatch.fbeExtra + this.AccountType.fbeExtra + this.ExternalAccountId.fbeExtra + this.ClientVersionMajor.fbeExtra + this.ClientVersionMinor.fbeExtra + this.ClientVersionBuild.fbeExtra + this.ClientVersionPatch.fbeExtra + this.ClientBuildId.fbeExtra + this.UniqueId.fbeExtra + this.SessionId.fbeExtra + this.ExternalClientId.fbeExtra + this.IPAddress.fbeExtra + this.ProtocolVersionMajor.fbeExtra + this.ProtocolVersionMinor.fbeExtra + this.Port.fbeExtra + this.AccountGroupId.fbeExtra + this.AccountGroupName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelClientLoginInfoRecord.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 192
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.LoginTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginTimestamp.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Device.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.DeviceModel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DeviceModel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DeviceModel.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Browser.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OS.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMajor.fbeSize

    if ((fbeCurrentSize + this.OSVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMinor.fbeSize

    if ((fbeCurrentSize + this.OSVersionBuild.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionBuild.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionBuild.fbeSize

    if ((fbeCurrentSize + this.OSVersionPatch.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OSVersionPatch.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionPatch.fbeSize

    if ((fbeCurrentSize + this.AccountType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountType.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMinor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionBuild.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionBuild.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionBuild.fbeSize

    if ((fbeCurrentSize + this.ClientVersionPatch.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientVersionPatch.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientBuildId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientBuildId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientBuildId.fbeSize

    if ((fbeCurrentSize + this.UniqueId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UniqueId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueId.fbeSize

    if ((fbeCurrentSize + this.SessionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SessionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IPAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Port.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!ClientLoginInfoRecord} fbeValue Default value, defaults is new ClientLoginInfoRecord()
   * @returns {!ClientLoginInfoRecord} ClientLoginInfoRecord value
   */
  get (fbeValue = new ClientLoginInfoRecord()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!ClientLoginInfoRecord} fbeValue ClientLoginInfoRecord value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.LoginTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.LoginTimestamp = this.LoginTimestamp.get()
    } else {
      fbeValue.LoginTimestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginTimestamp.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) <= fbeStructSize) {
      fbeValue.LoginType = this.LoginType.get()
    } else {
      fbeValue.LoginType = new LoginType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.Device.fbeSize) <= fbeStructSize) {
      fbeValue.Device = this.Device.get()
    } else {
      fbeValue.Device = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Device.fbeSize

    if ((fbeCurrentSize + this.DeviceModel.fbeSize) <= fbeStructSize) {
      fbeValue.DeviceModel = this.DeviceModel.get()
    } else {
      fbeValue.DeviceModel = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DeviceModel.fbeSize

    if ((fbeCurrentSize + this.Browser.fbeSize) <= fbeStructSize) {
      fbeValue.Browser = this.Browser.get()
    } else {
      fbeValue.Browser = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Browser.fbeSize

    if ((fbeCurrentSize + this.OS.fbeSize) <= fbeStructSize) {
      fbeValue.OS = this.OS.get()
    } else {
      fbeValue.OS = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OS.fbeSize

    if ((fbeCurrentSize + this.OSVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionMajor = this.OSVersionMajor.get()
    } else {
      fbeValue.OSVersionMajor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMajor.fbeSize

    if ((fbeCurrentSize + this.OSVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionMinor = this.OSVersionMinor.get()
    } else {
      fbeValue.OSVersionMinor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionMinor.fbeSize

    if ((fbeCurrentSize + this.OSVersionBuild.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionBuild = this.OSVersionBuild.get()
    } else {
      fbeValue.OSVersionBuild = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionBuild.fbeSize

    if ((fbeCurrentSize + this.OSVersionPatch.fbeSize) <= fbeStructSize) {
      fbeValue.OSVersionPatch = this.OSVersionPatch.get()
    } else {
      fbeValue.OSVersionPatch = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OSVersionPatch.fbeSize

    if ((fbeCurrentSize + this.AccountType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountType = this.AccountType.get()
    } else {
      fbeValue.AccountType = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountType.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAccountId = this.ExternalAccountId.get()
    } else {
      fbeValue.ExternalAccountId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionMajor = this.ClientVersionMajor.get()
    } else {
      fbeValue.ClientVersionMajor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionMinor = this.ClientVersionMinor.get()
    } else {
      fbeValue.ClientVersionMinor = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionMinor.fbeSize

    if ((fbeCurrentSize + this.ClientVersionBuild.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionBuild = this.ClientVersionBuild.get()
    } else {
      fbeValue.ClientVersionBuild = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionBuild.fbeSize

    if ((fbeCurrentSize + this.ClientVersionPatch.fbeSize) <= fbeStructSize) {
      fbeValue.ClientVersionPatch = this.ClientVersionPatch.get()
    } else {
      fbeValue.ClientVersionPatch = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientVersionPatch.fbeSize

    if ((fbeCurrentSize + this.ClientBuildId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientBuildId = this.ClientBuildId.get()
    } else {
      fbeValue.ClientBuildId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientBuildId.fbeSize

    if ((fbeCurrentSize + this.UniqueId.fbeSize) <= fbeStructSize) {
      fbeValue.UniqueId = this.UniqueId.get()
    } else {
      fbeValue.UniqueId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UniqueId.fbeSize

    if ((fbeCurrentSize + this.SessionId.fbeSize) <= fbeStructSize) {
      fbeValue.SessionId = this.SessionId.get()
    } else {
      fbeValue.SessionId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) <= fbeStructSize) {
      fbeValue.IPAddress = this.IPAddress.get()
    } else {
      fbeValue.IPAddress = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMajor = this.ProtocolVersionMajor.get()
    } else {
      fbeValue.ProtocolVersionMajor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMinor = this.ProtocolVersionMinor.get()
    } else {
      fbeValue.ProtocolVersionMinor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Port.fbeSize) <= fbeStructSize) {
      fbeValue.Port = this.Port.get()
    } else {
      fbeValue.Port = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Port.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.AccountGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupName = this.AccountGroupName.get()
    } else {
      fbeValue.AccountGroupName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelClientLoginInfoRecord}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {!ClientLoginInfoRecord} fbeValue ClientLoginInfoRecord value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelClientLoginInfoRecord}
   * @param {ClientLoginInfoRecord} fbeValue ClientLoginInfoRecord value
   */
  setFields (fbeValue) {
    this.RecordId.set(fbeValue.RecordId)
    this.Login.set(fbeValue.Login)
    this.LoginTimestamp.set(fbeValue.LoginTimestamp)
    this.LoginType.set(fbeValue.LoginType)
    this.Device.set(fbeValue.Device)
    this.DeviceModel.set(fbeValue.DeviceModel)
    this.Browser.set(fbeValue.Browser)
    this.OS.set(fbeValue.OS)
    this.OSVersionMajor.set(fbeValue.OSVersionMajor)
    this.OSVersionMinor.set(fbeValue.OSVersionMinor)
    this.OSVersionBuild.set(fbeValue.OSVersionBuild)
    this.OSVersionPatch.set(fbeValue.OSVersionPatch)
    this.AccountType.set(fbeValue.AccountType)
    this.ExternalAccountId.set(fbeValue.ExternalAccountId)
    this.ClientVersionMajor.set(fbeValue.ClientVersionMajor)
    this.ClientVersionMinor.set(fbeValue.ClientVersionMinor)
    this.ClientVersionBuild.set(fbeValue.ClientVersionBuild)
    this.ClientVersionPatch.set(fbeValue.ClientVersionPatch)
    this.ClientBuildId.set(fbeValue.ClientBuildId)
    this.UniqueId.set(fbeValue.UniqueId)
    this.SessionId.set(fbeValue.SessionId)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.IPAddress.set(fbeValue.IPAddress)
    this.ProtocolVersionMajor.set(fbeValue.ProtocolVersionMajor)
    this.ProtocolVersionMinor.set(fbeValue.ProtocolVersionMinor)
    this.Port.set(fbeValue.Port)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.AccountGroupName.set(fbeValue.AccountGroupName)
  }
}

export { FieldModelClientLoginInfoRecord };

/**
 * Fast Binary Encoding ClientLoginInfoRecord model
 */
class ClientLoginInfoRecordModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelClientLoginInfoRecord(this.buffer, 4)
  }

  /**
   * Get the ClientLoginInfoRecord model
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!FieldModelClientLoginInfoRecord} model ClientLoginInfoRecord model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ClientLoginInfoRecordModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelClientLoginInfoRecord.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ClientLoginInfoRecordModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ClientLoginInfoRecordModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ClientLoginInfoRecordModel}
   * @param {!ClientLoginInfoRecord} value ClientLoginInfoRecord value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ClientLoginInfoRecordModel}
   * @param {!ClientLoginInfoRecord} value ClientLoginInfoRecord value, defaults is new ClientLoginInfoRecord()
   * @return {!object} Deserialized ClientLoginInfoRecord value and its size
   */
  deserialize (value = new ClientLoginInfoRecord()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ClientLoginInfoRecord(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ClientLoginInfoRecord(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ClientLoginInfoRecordModel}
   * @param {!number} prev Previous ClientLoginInfoRecord model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ClientLoginInfoRecordModel };
