import React, { FC } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { authStore } from '@store/auth';
import { LoginReject } from '@api/fbe/client/LoginReject';
import LoginForm from '@pages/Login/LoginForm';
import { LS_WS_CONNECTION_KEY } from '@constants/index';
import { LoginValues, isValidWsUrl } from '@models/Auth';
import StorageHelper from '@utils/StorageHelper';

export const Login: FC = observer(() => {
    const history = useHistory();
    const location = useLocation();

    const { from } = location.state || ({ from: { pathname: '/accounts' } } as any);

    const sendAuth = (fields: LoginValues, onFail: (reason: LoginReject) => void): void => {
        authStore.authHandler({
            ...fields,
            onSuccess() {
                history.replace(from);
                if (isValidWsUrl(fields.wsUrl)) {
                    StorageHelper.saveToLocal(LS_WS_CONNECTION_KEY, fields.wsUrl);
                }
            },
            onFail(reason) {
                onFail(reason);
            },
        });
    };

    if (authStore.auth) {
        return <Redirect to={from} />;
    }
    return <LoginForm sendAuth={sendAuth} />;
});
