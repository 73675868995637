import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { CurrencyGroup } from '@models';
import { ErrorsTracker } from '@api/errorsTracker';
import { adaptApi } from '@utils/models';
import { keyMap, t } from '@localization';
import { UUID } from '@api/fbe/uuid';
import { CurrencyGroupsGetAllRequest } from '@api/fbe/manager/CurrencyGroupsGetAllRequest';
import { CurrencyGroupCreateRequest } from '@api/fbe/manager/CurrencyGroupCreateRequest';
import { CurrencyGroupDeleteRequest } from '@api/fbe/manager/CurrencyGroupDeleteRequest';
import { CurrencyGroupModifyRequest } from '@api/fbe/manager/CurrencyGroupModifyRequest';

export class CurrenciesTypesStore {
    errorTracker = new ErrorsTracker({ title: 'Currencies Types' });

    constructor() {
        makeAutoObservable(this);
    }

    data: CurrencyGroup[] = [];

    isLoading = false;

    setData(value: CurrencyGroup[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            this.update({
                ...targetItem,
                SortOrder: newIndex,
            });
        } else {
            throw new Error(t(keyMap.messages.currencyGroups.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new CurrencyGroupsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.CurrencyGroups.map((group, index) => ({
                ...adaptApi(group),
                Currencies: [],
                key: index,
            })),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: CurrencyGroup): Promise<CurrencyGroup> {
        const request = new CurrencyGroupCreateRequest();
        request.SortOrder = record.SortOrder;
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.Description = record.Description;
        const newGroup = await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
        return {
            ...adaptApi(newGroup.CurrencyGroup),
            Currencies: record.Currencies,
        };
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new CurrencyGroupDeleteRequest();
        request.CurrencyGroupId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: CurrencyGroup) {
        const request = new CurrencyGroupModifyRequest();
        request.CurrencyGroupId = UUID.fromString(record.id);
        request.NewSortOrder = record.SortOrder;
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewDescription = record.Description;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const currenciesTypesStore = new CurrenciesTypesStore();
