
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * FeatureToggleForce enum
 */
class FeatureToggleForce {
  /**
   * Initialize enum with a given value
   * @param {FeatureToggleForce|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof FeatureToggleForce) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!FeatureToggleForce}
   * @param {!FeatureToggleForce} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof FeatureToggleForce)) {
      throw new TypeError('Instance of FeatureToggleForce is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!FeatureToggleForce}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!FeatureToggleForce}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === FeatureToggleForce.NONE.value) {
      return 'NONE'
    }
    if (this.value === FeatureToggleForce.FORCE_ENABLE.value) {
      return 'FORCE_ENABLE'
    }
    if (this.value === FeatureToggleForce.FORCE_DISABLE.value) {
      return 'FORCE_DISABLE'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!FeatureToggleForce}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!FeatureToggleForce}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!FeatureToggleForce} Created enum
   */
  static fromObject (other) {
    return new FeatureToggleForce(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
FeatureToggleForce.NONE = new FeatureToggleForce(0 + 0)
// noinspection PointlessArithmeticExpressionJS
FeatureToggleForce.FORCE_ENABLE = new FeatureToggleForce(0 + 1)
// noinspection PointlessArithmeticExpressionJS
FeatureToggleForce.FORCE_DISABLE = new FeatureToggleForce(0 + 2)

export { FeatureToggleForce };

/**
 * Fast Binary Encoding FeatureToggleForce field model
 */
class FieldModelFeatureToggleForce extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelFeatureToggleForce}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelFeatureToggleForce}
   * @param {FeatureToggleForce=} defaults Default value, defaults is new FeatureToggleForce()
   * @returns {!FeatureToggleForce} Result value
   */
  get (defaults = new FeatureToggleForce()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new FeatureToggleForce(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelFeatureToggleForce}
   * @param {!FeatureToggleForce} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelFeatureToggleForce };
