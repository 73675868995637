
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {TradeTransactionType} from '../core/TradeTransactionType';
import {FieldModelTradeTransactionType} from '../core/TradeTransactionType';
import {TradeTransactionReason} from '../core/TradeTransactionReason';
import {FieldModelTradeTransactionReason} from '../core/TradeTransactionReason';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';
import {OrderTimeInForce} from '../core/OrderTimeInForce';
import {FieldModelOrderTimeInForce} from '../core/OrderTimeInForce';
import {OrderStatus} from '../core/OrderStatus';
import {FieldModelOrderStatus} from '../core/OrderStatus';
import {SocialTradingOrderType} from '../core/SocialTradingOrderType';
import {FieldModelSocialTradingOrderType} from '../core/SocialTradingOrderType';

/**
 * TradeHistoryInfo struct
 */
class TradeHistoryInfo {
  /**
   * Initialize struct
   * @param {!number=} recordId
   * @param {number=} accountLogin
   * @param {number=} managerLogin
   * @param {!TradeTransactionType=} transactionType
   * @param {!TradeTransactionReason=} transactionReason
   * @param {!number=} transactionStep
   * @param {!Date=} transactionTimestamp
   * @param {string=} accountComment
   * @param {string=} balanceCurrencyName
   * @param {number=} balanceMovement
   * @param {number=} balanceResult
   * @param {string=} srcAssetCurrencyName
   * @param {number=} srcAssetMovement
   * @param {number=} srcAssetResult
   * @param {string=} dstAssetCurrencyName
   * @param {number=} dstAssetMovement
   * @param {number=} dstAssetResult
   * @param {number=} orderId
   * @param {number=} parentOrderId
   * @param {string=} clientOrderId
   * @param {number=} actualVersion
   * @param {string=} symbolName
   * @param {OrderSide=} orderSide
   * @param {OrderType=} orderType
   * @param {OrderType=} orderInitialType
   * @param {OrderTimeInForce=} orderTimeInForce
   * @param {OrderStatus=} orderStatus
   * @param {number=} price
   * @param {number=} stopPrice
   * @param {number=} takeProfit
   * @param {number=} stopLoss
   * @param {number=} amount
   * @param {number=} executedAmount
   * @param {number=} remainingAmount
   * @param {Date=} expiration
   * @param {number=} magic
   * @param {Date=} created
   * @param {Date=} modified
   * @param {Date=} archived
   * @param {Date=} deleted
   * @param {number=} openPrice
   * @param {number=} closePrice
   * @param {number=} closeAmount
   * @param {number=} profit
   * @param {number=} profitConversionRate
   * @param {number=} commission
   * @param {number=} swap
   * @param {Date=} opened
   * @param {string=} managerComment
   * @param {Date=} closed
   * @param {number=} lastClosePrice
   * @param {number=} lastCloseAmount
   * @param {SocialTradingOrderType=} socialTradingOrderType
   * @param {number=} socialTradingMasterLogin
   * @constructor
   */
  constructor (recordId = new UInt64(0, 0), accountLogin = null, managerLogin = null, transactionType = new TradeTransactionType(), transactionReason = new TradeTransactionReason(), transactionStep = 0, transactionTimestamp = new Date(0), accountComment = null, balanceCurrencyName = null, balanceMovement = null, balanceResult = null, srcAssetCurrencyName = null, srcAssetMovement = null, srcAssetResult = null, dstAssetCurrencyName = null, dstAssetMovement = null, dstAssetResult = null, orderId = null, parentOrderId = null, clientOrderId = null, actualVersion = null, symbolName = null, orderSide = null, orderType = null, orderInitialType = null, orderTimeInForce = null, orderStatus = null, price = null, stopPrice = null, takeProfit = null, stopLoss = null, amount = null, executedAmount = null, remainingAmount = null, expiration = null, magic = null, created = null, modified = null, archived = null, deleted = null, openPrice = null, closePrice = null, closeAmount = null, profit = null, profitConversionRate = null, commission = null, swap = null, opened = null, managerComment = null, closed = null, lastClosePrice = null, lastCloseAmount = null, socialTradingOrderType = null, socialTradingMasterLogin = null) {
    this.RecordId = recordId
    this.AccountLogin = accountLogin
    this.ManagerLogin = managerLogin
    this.TransactionType = transactionType
    this.TransactionReason = transactionReason
    this.TransactionStep = transactionStep
    this.TransactionTimestamp = transactionTimestamp
    this.AccountComment = accountComment
    this.BalanceCurrencyName = balanceCurrencyName
    this.BalanceMovement = balanceMovement
    this.BalanceResult = balanceResult
    this.SrcAssetCurrencyName = srcAssetCurrencyName
    this.SrcAssetMovement = srcAssetMovement
    this.SrcAssetResult = srcAssetResult
    this.DstAssetCurrencyName = dstAssetCurrencyName
    this.DstAssetMovement = dstAssetMovement
    this.DstAssetResult = dstAssetResult
    this.OrderId = orderId
    this.ParentOrderId = parentOrderId
    this.ClientOrderId = clientOrderId
    this.ActualVersion = actualVersion
    this.SymbolName = symbolName
    this.OrderSide = orderSide
    this.OrderType = orderType
    this.OrderInitialType = orderInitialType
    this.OrderTimeInForce = orderTimeInForce
    this.OrderStatus = orderStatus
    this.Price = price
    this.StopPrice = stopPrice
    this.TakeProfit = takeProfit
    this.StopLoss = stopLoss
    this.Amount = amount
    this.ExecutedAmount = executedAmount
    this.RemainingAmount = remainingAmount
    this.Expiration = expiration
    this.Magic = magic
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.OpenPrice = openPrice
    this.ClosePrice = closePrice
    this.CloseAmount = closeAmount
    this.Profit = profit
    this.ProfitConversionRate = profitConversionRate
    this.Commission = commission
    this.Swap = swap
    this.Opened = opened
    this.ManagerComment = managerComment
    this.Closed = closed
    this.LastClosePrice = lastClosePrice
    this.LastCloseAmount = lastCloseAmount
    this.SocialTradingOrderType = socialTradingOrderType
    this.SocialTradingMasterLogin = socialTradingMasterLogin
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradeHistoryInfo}
   * @param {!TradeHistoryInfo} other Other struct
   * @returns {!TradeHistoryInfo} This struct
   */
  copy (other) {
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = UInt64.fromNumber(other.AccountLogin)
    } else {
      this.AccountLogin = null
    }
    if (other.ManagerLogin != null) {
      this.ManagerLogin = UInt64.fromNumber(other.ManagerLogin)
    } else {
      this.ManagerLogin = null
    }
    if (other.TransactionType != null) {
      this.TransactionType = TradeTransactionType.fromObject(other.TransactionType)
    } else {
      this.TransactionType = null
    }
    if (other.TransactionReason != null) {
      this.TransactionReason = TradeTransactionReason.fromObject(other.TransactionReason)
    } else {
      this.TransactionReason = null
    }
    if (other.TransactionStep != null) {
      this.TransactionStep = other.TransactionStep
    } else {
      this.TransactionStep = null
    }
    if (other.TransactionTimestamp != null) {
      if (other.TransactionTimestamp instanceof Date) {
        this.TransactionTimestamp = new Date(other.TransactionTimestamp.getTime())
      } else {
        this.TransactionTimestamp = new Date(Math.round(other.TransactionTimestamp / 1000000))
      }
    } else {
      this.TransactionTimestamp = null
    }
    if (other.AccountComment != null) {
      this.AccountComment = other.AccountComment
    } else {
      this.AccountComment = null
    }
    if (other.BalanceCurrencyName != null) {
      this.BalanceCurrencyName = other.BalanceCurrencyName
    } else {
      this.BalanceCurrencyName = null
    }
    if (other.BalanceMovement != null) {
      this.BalanceMovement = other.BalanceMovement
    } else {
      this.BalanceMovement = null
    }
    if (other.BalanceResult != null) {
      this.BalanceResult = other.BalanceResult
    } else {
      this.BalanceResult = null
    }
    if (other.SrcAssetCurrencyName != null) {
      this.SrcAssetCurrencyName = other.SrcAssetCurrencyName
    } else {
      this.SrcAssetCurrencyName = null
    }
    if (other.SrcAssetMovement != null) {
      this.SrcAssetMovement = other.SrcAssetMovement
    } else {
      this.SrcAssetMovement = null
    }
    if (other.SrcAssetResult != null) {
      this.SrcAssetResult = other.SrcAssetResult
    } else {
      this.SrcAssetResult = null
    }
    if (other.DstAssetCurrencyName != null) {
      this.DstAssetCurrencyName = other.DstAssetCurrencyName
    } else {
      this.DstAssetCurrencyName = null
    }
    if (other.DstAssetMovement != null) {
      this.DstAssetMovement = other.DstAssetMovement
    } else {
      this.DstAssetMovement = null
    }
    if (other.DstAssetResult != null) {
      this.DstAssetResult = other.DstAssetResult
    } else {
      this.DstAssetResult = null
    }
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.ParentOrderId != null) {
      this.ParentOrderId = UInt64.fromNumber(other.ParentOrderId)
    } else {
      this.ParentOrderId = null
    }
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    if (other.OrderSide != null) {
      this.OrderSide = OrderSide.fromObject(other.OrderSide)
    } else {
      this.OrderSide = null
    }
    if (other.OrderType != null) {
      this.OrderType = OrderType.fromObject(other.OrderType)
    } else {
      this.OrderType = null
    }
    if (other.OrderInitialType != null) {
      this.OrderInitialType = OrderType.fromObject(other.OrderInitialType)
    } else {
      this.OrderInitialType = null
    }
    if (other.OrderTimeInForce != null) {
      this.OrderTimeInForce = OrderTimeInForce.fromObject(other.OrderTimeInForce)
    } else {
      this.OrderTimeInForce = null
    }
    if (other.OrderStatus != null) {
      this.OrderStatus = OrderStatus.fromObject(other.OrderStatus)
    } else {
      this.OrderStatus = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    if (other.StopPrice != null) {
      this.StopPrice = other.StopPrice
    } else {
      this.StopPrice = null
    }
    if (other.TakeProfit != null) {
      this.TakeProfit = other.TakeProfit
    } else {
      this.TakeProfit = null
    }
    if (other.StopLoss != null) {
      this.StopLoss = other.StopLoss
    } else {
      this.StopLoss = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.ExecutedAmount != null) {
      this.ExecutedAmount = other.ExecutedAmount
    } else {
      this.ExecutedAmount = null
    }
    if (other.RemainingAmount != null) {
      this.RemainingAmount = other.RemainingAmount
    } else {
      this.RemainingAmount = null
    }
    if (other.Expiration != null) {
      if (other.Expiration instanceof Date) {
        this.Expiration = new Date(other.Expiration.getTime())
      } else {
        this.Expiration = new Date(Math.round(other.Expiration / 1000000))
      }
    } else {
      this.Expiration = null
    }
    if (other.Magic != null) {
      this.Magic = other.Magic
    } else {
      this.Magic = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    if (other.OpenPrice != null) {
      this.OpenPrice = other.OpenPrice
    } else {
      this.OpenPrice = null
    }
    if (other.ClosePrice != null) {
      this.ClosePrice = other.ClosePrice
    } else {
      this.ClosePrice = null
    }
    if (other.CloseAmount != null) {
      this.CloseAmount = other.CloseAmount
    } else {
      this.CloseAmount = null
    }
    if (other.Profit != null) {
      this.Profit = other.Profit
    } else {
      this.Profit = null
    }
    if (other.ProfitConversionRate != null) {
      this.ProfitConversionRate = other.ProfitConversionRate
    } else {
      this.ProfitConversionRate = null
    }
    if (other.Commission != null) {
      this.Commission = other.Commission
    } else {
      this.Commission = null
    }
    if (other.Swap != null) {
      this.Swap = other.Swap
    } else {
      this.Swap = null
    }
    if (other.Opened != null) {
      if (other.Opened instanceof Date) {
        this.Opened = new Date(other.Opened.getTime())
      } else {
        this.Opened = new Date(Math.round(other.Opened / 1000000))
      }
    } else {
      this.Opened = null
    }
    if (other.ManagerComment != null) {
      this.ManagerComment = other.ManagerComment
    } else {
      this.ManagerComment = null
    }
    if (other.Closed != null) {
      if (other.Closed instanceof Date) {
        this.Closed = new Date(other.Closed.getTime())
      } else {
        this.Closed = new Date(Math.round(other.Closed / 1000000))
      }
    } else {
      this.Closed = null
    }
    if (other.LastClosePrice != null) {
      this.LastClosePrice = other.LastClosePrice
    } else {
      this.LastClosePrice = null
    }
    if (other.LastCloseAmount != null) {
      this.LastCloseAmount = other.LastCloseAmount
    } else {
      this.LastCloseAmount = null
    }
    if (other.SocialTradingOrderType != null) {
      this.SocialTradingOrderType = SocialTradingOrderType.fromObject(other.SocialTradingOrderType)
    } else {
      this.SocialTradingOrderType = null
    }
    if (other.SocialTradingMasterLogin != null) {
      this.SocialTradingMasterLogin = UInt64.fromNumber(other.SocialTradingMasterLogin)
    } else {
      this.SocialTradingMasterLogin = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradeHistoryInfo}
   * @returns {!TradeHistoryInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradeHistoryInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradeHistoryInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradeHistoryInfo}
   * @param {!TradeHistoryInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeHistoryInfo)) {
      throw new TypeError('Instance of TradeHistoryInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradeHistoryInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null),
      AccountLogin: ((this.AccountLogin != null) ? this.AccountLogin.toNumber() : null),
      ManagerLogin: ((this.ManagerLogin != null) ? this.ManagerLogin.toNumber() : null),
      TransactionType: ((this.TransactionType != null) ? this.TransactionType : null),
      TransactionReason: ((this.TransactionReason != null) ? this.TransactionReason : null),
      TransactionStep: ((this.TransactionStep != null) ? this.TransactionStep : null),
      TransactionTimestamp: ((this.TransactionTimestamp != null) ? (this.TransactionTimestamp.getTime() * 1000000) : null),
      AccountComment: ((this.AccountComment != null) ? this.AccountComment : null),
      BalanceCurrencyName: ((this.BalanceCurrencyName != null) ? this.BalanceCurrencyName : null),
      BalanceMovement: ((this.BalanceMovement != null) ? this.BalanceMovement : null),
      BalanceResult: ((this.BalanceResult != null) ? this.BalanceResult : null),
      SrcAssetCurrencyName: ((this.SrcAssetCurrencyName != null) ? this.SrcAssetCurrencyName : null),
      SrcAssetMovement: ((this.SrcAssetMovement != null) ? this.SrcAssetMovement : null),
      SrcAssetResult: ((this.SrcAssetResult != null) ? this.SrcAssetResult : null),
      DstAssetCurrencyName: ((this.DstAssetCurrencyName != null) ? this.DstAssetCurrencyName : null),
      DstAssetMovement: ((this.DstAssetMovement != null) ? this.DstAssetMovement : null),
      DstAssetResult: ((this.DstAssetResult != null) ? this.DstAssetResult : null),
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      ParentOrderId: ((this.ParentOrderId != null) ? this.ParentOrderId.toNumber() : null),
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null),
      OrderSide: ((this.OrderSide != null) ? this.OrderSide : null),
      OrderType: ((this.OrderType != null) ? this.OrderType : null),
      OrderInitialType: ((this.OrderInitialType != null) ? this.OrderInitialType : null),
      OrderTimeInForce: ((this.OrderTimeInForce != null) ? this.OrderTimeInForce : null),
      OrderStatus: ((this.OrderStatus != null) ? this.OrderStatus : null),
      Price: ((this.Price != null) ? this.Price : null),
      StopPrice: ((this.StopPrice != null) ? this.StopPrice : null),
      TakeProfit: ((this.TakeProfit != null) ? this.TakeProfit : null),
      StopLoss: ((this.StopLoss != null) ? this.StopLoss : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      ExecutedAmount: ((this.ExecutedAmount != null) ? this.ExecutedAmount : null),
      RemainingAmount: ((this.RemainingAmount != null) ? this.RemainingAmount : null),
      Expiration: ((this.Expiration != null) ? (this.Expiration.getTime() * 1000000) : null),
      Magic: ((this.Magic != null) ? this.Magic : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null),
      OpenPrice: ((this.OpenPrice != null) ? this.OpenPrice : null),
      ClosePrice: ((this.ClosePrice != null) ? this.ClosePrice : null),
      CloseAmount: ((this.CloseAmount != null) ? this.CloseAmount : null),
      Profit: ((this.Profit != null) ? this.Profit : null),
      ProfitConversionRate: ((this.ProfitConversionRate != null) ? this.ProfitConversionRate : null),
      Commission: ((this.Commission != null) ? this.Commission : null),
      Swap: ((this.Swap != null) ? this.Swap : null),
      Opened: ((this.Opened != null) ? (this.Opened.getTime() * 1000000) : null),
      ManagerComment: ((this.ManagerComment != null) ? this.ManagerComment : null),
      Closed: ((this.Closed != null) ? (this.Closed.getTime() * 1000000) : null),
      LastClosePrice: ((this.LastClosePrice != null) ? this.LastClosePrice : null),
      LastCloseAmount: ((this.LastCloseAmount != null) ? this.LastCloseAmount : null),
      SocialTradingOrderType: ((this.SocialTradingOrderType != null) ? this.SocialTradingOrderType : null),
      SocialTradingMasterLogin: ((this.SocialTradingMasterLogin != null) ? this.SocialTradingMasterLogin.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradeHistoryInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradeHistoryInfo} other Object value
   * @returns {!TradeHistoryInfo} Created struct
   */
  static fromObject (other) {
    return new TradeHistoryInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradeHistoryInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradeHistoryInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradeHistoryInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5117
  }
}

export { TradeHistoryInfo };

/**
 * Fast Binary Encoding TradeHistoryInfo field model
 */
class FieldModelTradeHistoryInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.RecordId = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.AccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize), buffer, this.RecordId.fbeOffset + this.RecordId.fbeSize)
    this.ManagerLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize), buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize)
    this.TransactionType = new FieldModelTradeTransactionType(buffer, this.ManagerLogin.fbeOffset + this.ManagerLogin.fbeSize)
    this.TransactionReason = new FieldModelTradeTransactionReason(buffer, this.TransactionType.fbeOffset + this.TransactionType.fbeSize)
    this.TransactionStep = new fbe.FieldModelUInt16(buffer, this.TransactionReason.fbeOffset + this.TransactionReason.fbeSize)
    this.TransactionTimestamp = new fbe.FieldModelTimestamp(buffer, this.TransactionStep.fbeOffset + this.TransactionStep.fbeSize)
    this.AccountComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.TransactionTimestamp.fbeOffset + this.TransactionTimestamp.fbeSize), buffer, this.TransactionTimestamp.fbeOffset + this.TransactionTimestamp.fbeSize)
    this.BalanceCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountComment.fbeOffset + this.AccountComment.fbeSize), buffer, this.AccountComment.fbeOffset + this.AccountComment.fbeSize)
    this.BalanceMovement = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceCurrencyName.fbeOffset + this.BalanceCurrencyName.fbeSize), buffer, this.BalanceCurrencyName.fbeOffset + this.BalanceCurrencyName.fbeSize)
    this.BalanceResult = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceMovement.fbeOffset + this.BalanceMovement.fbeSize), buffer, this.BalanceMovement.fbeOffset + this.BalanceMovement.fbeSize)
    this.SrcAssetCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.BalanceResult.fbeOffset + this.BalanceResult.fbeSize), buffer, this.BalanceResult.fbeOffset + this.BalanceResult.fbeSize)
    this.SrcAssetMovement = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SrcAssetCurrencyName.fbeOffset + this.SrcAssetCurrencyName.fbeSize), buffer, this.SrcAssetCurrencyName.fbeOffset + this.SrcAssetCurrencyName.fbeSize)
    this.SrcAssetResult = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SrcAssetMovement.fbeOffset + this.SrcAssetMovement.fbeSize), buffer, this.SrcAssetMovement.fbeOffset + this.SrcAssetMovement.fbeSize)
    this.DstAssetCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SrcAssetResult.fbeOffset + this.SrcAssetResult.fbeSize), buffer, this.SrcAssetResult.fbeOffset + this.SrcAssetResult.fbeSize)
    this.DstAssetMovement = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.DstAssetCurrencyName.fbeOffset + this.DstAssetCurrencyName.fbeSize), buffer, this.DstAssetCurrencyName.fbeOffset + this.DstAssetCurrencyName.fbeSize)
    this.DstAssetResult = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.DstAssetMovement.fbeOffset + this.DstAssetMovement.fbeSize), buffer, this.DstAssetMovement.fbeOffset + this.DstAssetMovement.fbeSize)
    this.OrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.DstAssetResult.fbeOffset + this.DstAssetResult.fbeSize), buffer, this.DstAssetResult.fbeOffset + this.DstAssetResult.fbeSize)
    this.ParentOrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ParentOrderId.fbeOffset + this.ParentOrderId.fbeSize), buffer, this.ParentOrderId.fbeOffset + this.ParentOrderId.fbeSize)
    this.ActualVersion = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize), buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.SymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize), buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.OrderSide = new fbe.FieldModelOptional(new FieldModelOrderSide(buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize), buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize)
    this.OrderType = new fbe.FieldModelOptional(new FieldModelOrderType(buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize), buffer, this.OrderSide.fbeOffset + this.OrderSide.fbeSize)
    this.OrderInitialType = new fbe.FieldModelOptional(new FieldModelOrderType(buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize), buffer, this.OrderType.fbeOffset + this.OrderType.fbeSize)
    this.OrderTimeInForce = new fbe.FieldModelOptional(new FieldModelOrderTimeInForce(buffer, this.OrderInitialType.fbeOffset + this.OrderInitialType.fbeSize), buffer, this.OrderInitialType.fbeOffset + this.OrderInitialType.fbeSize)
    this.OrderStatus = new fbe.FieldModelOptional(new FieldModelOrderStatus(buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize), buffer, this.OrderTimeInForce.fbeOffset + this.OrderTimeInForce.fbeSize)
    this.Price = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize), buffer, this.OrderStatus.fbeOffset + this.OrderStatus.fbeSize)
    this.StopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Price.fbeOffset + this.Price.fbeSize), buffer, this.Price.fbeOffset + this.Price.fbeSize)
    this.TakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize), buffer, this.StopPrice.fbeOffset + this.StopPrice.fbeSize)
    this.StopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize), buffer, this.TakeProfit.fbeOffset + this.TakeProfit.fbeSize)
    this.Amount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.StopLoss.fbeOffset + this.StopLoss.fbeSize), buffer, this.StopLoss.fbeOffset + this.StopLoss.fbeSize)
    this.ExecutedAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Amount.fbeOffset + this.Amount.fbeSize), buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.RemainingAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ExecutedAmount.fbeOffset + this.ExecutedAmount.fbeSize), buffer, this.ExecutedAmount.fbeOffset + this.ExecutedAmount.fbeSize)
    this.Expiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.RemainingAmount.fbeOffset + this.RemainingAmount.fbeSize), buffer, this.RemainingAmount.fbeOffset + this.RemainingAmount.fbeSize)
    this.Magic = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.Expiration.fbeOffset + this.Expiration.fbeSize), buffer, this.Expiration.fbeOffset + this.Expiration.fbeSize)
    this.Created = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Magic.fbeOffset + this.Magic.fbeSize), buffer, this.Magic.fbeOffset + this.Magic.fbeSize)
    this.Modified = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize), buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize), buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize), buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.OpenPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize), buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.ClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.OpenPrice.fbeOffset + this.OpenPrice.fbeSize), buffer, this.OpenPrice.fbeOffset + this.OpenPrice.fbeSize)
    this.CloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize), buffer, this.ClosePrice.fbeOffset + this.ClosePrice.fbeSize)
    this.Profit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize), buffer, this.CloseAmount.fbeOffset + this.CloseAmount.fbeSize)
    this.ProfitConversionRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Profit.fbeOffset + this.Profit.fbeSize), buffer, this.Profit.fbeOffset + this.Profit.fbeSize)
    this.Commission = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.ProfitConversionRate.fbeOffset + this.ProfitConversionRate.fbeSize), buffer, this.ProfitConversionRate.fbeOffset + this.ProfitConversionRate.fbeSize)
    this.Swap = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Commission.fbeOffset + this.Commission.fbeSize), buffer, this.Commission.fbeOffset + this.Commission.fbeSize)
    this.Opened = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Swap.fbeOffset + this.Swap.fbeSize), buffer, this.Swap.fbeOffset + this.Swap.fbeSize)
    this.ManagerComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Opened.fbeOffset + this.Opened.fbeSize), buffer, this.Opened.fbeOffset + this.Opened.fbeSize)
    this.Closed = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize), buffer, this.ManagerComment.fbeOffset + this.ManagerComment.fbeSize)
    this.LastClosePrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Closed.fbeOffset + this.Closed.fbeSize), buffer, this.Closed.fbeOffset + this.Closed.fbeSize)
    this.LastCloseAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.LastClosePrice.fbeOffset + this.LastClosePrice.fbeSize), buffer, this.LastClosePrice.fbeOffset + this.LastClosePrice.fbeSize)
    this.SocialTradingOrderType = new fbe.FieldModelOptional(new FieldModelSocialTradingOrderType(buffer, this.LastCloseAmount.fbeOffset + this.LastCloseAmount.fbeSize), buffer, this.LastCloseAmount.fbeOffset + this.LastCloseAmount.fbeSize)
    this.SocialTradingMasterLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.SocialTradingOrderType.fbeOffset + this.SocialTradingOrderType.fbeSize), buffer, this.SocialTradingOrderType.fbeOffset + this.SocialTradingOrderType.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.RecordId.fbeSize + this.AccountLogin.fbeSize + this.ManagerLogin.fbeSize + this.TransactionType.fbeSize + this.TransactionReason.fbeSize + this.TransactionStep.fbeSize + this.TransactionTimestamp.fbeSize + this.AccountComment.fbeSize + this.BalanceCurrencyName.fbeSize + this.BalanceMovement.fbeSize + this.BalanceResult.fbeSize + this.SrcAssetCurrencyName.fbeSize + this.SrcAssetMovement.fbeSize + this.SrcAssetResult.fbeSize + this.DstAssetCurrencyName.fbeSize + this.DstAssetMovement.fbeSize + this.DstAssetResult.fbeSize + this.OrderId.fbeSize + this.ParentOrderId.fbeSize + this.ClientOrderId.fbeSize + this.ActualVersion.fbeSize + this.SymbolName.fbeSize + this.OrderSide.fbeSize + this.OrderType.fbeSize + this.OrderInitialType.fbeSize + this.OrderTimeInForce.fbeSize + this.OrderStatus.fbeSize + this.Price.fbeSize + this.StopPrice.fbeSize + this.TakeProfit.fbeSize + this.StopLoss.fbeSize + this.Amount.fbeSize + this.ExecutedAmount.fbeSize + this.RemainingAmount.fbeSize + this.Expiration.fbeSize + this.Magic.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.OpenPrice.fbeSize + this.ClosePrice.fbeSize + this.CloseAmount.fbeSize + this.Profit.fbeSize + this.ProfitConversionRate.fbeSize + this.Commission.fbeSize + this.Swap.fbeSize + this.Opened.fbeSize + this.ManagerComment.fbeSize + this.Closed.fbeSize + this.LastClosePrice.fbeSize + this.LastCloseAmount.fbeSize + this.SocialTradingOrderType.fbeSize + this.SocialTradingMasterLogin.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.RecordId.fbeExtra + this.AccountLogin.fbeExtra + this.ManagerLogin.fbeExtra + this.TransactionType.fbeExtra + this.TransactionReason.fbeExtra + this.TransactionStep.fbeExtra + this.TransactionTimestamp.fbeExtra + this.AccountComment.fbeExtra + this.BalanceCurrencyName.fbeExtra + this.BalanceMovement.fbeExtra + this.BalanceResult.fbeExtra + this.SrcAssetCurrencyName.fbeExtra + this.SrcAssetMovement.fbeExtra + this.SrcAssetResult.fbeExtra + this.DstAssetCurrencyName.fbeExtra + this.DstAssetMovement.fbeExtra + this.DstAssetResult.fbeExtra + this.OrderId.fbeExtra + this.ParentOrderId.fbeExtra + this.ClientOrderId.fbeExtra + this.ActualVersion.fbeExtra + this.SymbolName.fbeExtra + this.OrderSide.fbeExtra + this.OrderType.fbeExtra + this.OrderInitialType.fbeExtra + this.OrderTimeInForce.fbeExtra + this.OrderStatus.fbeExtra + this.Price.fbeExtra + this.StopPrice.fbeExtra + this.TakeProfit.fbeExtra + this.StopLoss.fbeExtra + this.Amount.fbeExtra + this.ExecutedAmount.fbeExtra + this.RemainingAmount.fbeExtra + this.Expiration.fbeExtra + this.Magic.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.OpenPrice.fbeExtra + this.ClosePrice.fbeExtra + this.CloseAmount.fbeExtra + this.Profit.fbeExtra + this.ProfitConversionRate.fbeExtra + this.Commission.fbeExtra + this.Swap.fbeExtra + this.Opened.fbeExtra + this.ManagerComment.fbeExtra + this.Closed.fbeExtra + this.LastClosePrice.fbeExtra + this.LastCloseAmount.fbeExtra + this.SocialTradingOrderType.fbeExtra + this.SocialTradingMasterLogin.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradeHistoryInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5117
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.ManagerLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLogin.fbeSize

    if ((fbeCurrentSize + this.TransactionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionType.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionReason.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.TransactionStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionStep.fbeSize

    if ((fbeCurrentSize + this.TransactionTimestamp.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionTimestamp.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTimestamp.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.BalanceMovement.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceMovement.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceMovement.fbeSize

    if ((fbeCurrentSize + this.BalanceResult.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceResult.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceResult.fbeSize

    if ((fbeCurrentSize + this.SrcAssetCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcAssetCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetCurrencyName.fbeSize

    if ((fbeCurrentSize + this.SrcAssetMovement.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcAssetMovement.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetMovement.fbeSize

    if ((fbeCurrentSize + this.SrcAssetResult.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcAssetResult.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetResult.fbeSize

    if ((fbeCurrentSize + this.DstAssetCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstAssetCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetCurrencyName.fbeSize

    if ((fbeCurrentSize + this.DstAssetMovement.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstAssetMovement.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetMovement.fbeSize

    if ((fbeCurrentSize + this.DstAssetResult.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstAssetResult.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetResult.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ParentOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ParentOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParentOrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderInitialType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderInitialType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderInitialType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderTimeInForce.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderStatus.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.ExecutedAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExecutedAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.RemainingAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RemainingAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingAmount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Expiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Magic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OpenPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Profit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.ProfitConversionRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitConversionRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitConversionRate.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Commission.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Swap.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.Opened.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Opened.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Opened.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Closed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Closed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Closed.fbeSize

    if ((fbeCurrentSize + this.LastClosePrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastClosePrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastClosePrice.fbeSize

    if ((fbeCurrentSize + this.LastCloseAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastCloseAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastCloseAmount.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingOrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMasterLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterLogin.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!TradeHistoryInfo} fbeValue Default value, defaults is new TradeHistoryInfo()
   * @returns {!TradeHistoryInfo} TradeHistoryInfo value
   */
  get (fbeValue = new TradeHistoryInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!TradeHistoryInfo} fbeValue TradeHistoryInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLogin = this.AccountLogin.get()
    } else {
      fbeValue.AccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.ManagerLogin.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerLogin = this.ManagerLogin.get()
    } else {
      fbeValue.ManagerLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLogin.fbeSize

    if ((fbeCurrentSize + this.TransactionType.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionType = this.TransactionType.get()
    } else {
      fbeValue.TransactionType = new TradeTransactionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionType.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionReason = this.TransactionReason.get()
    } else {
      fbeValue.TransactionReason = new TradeTransactionReason()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.TransactionStep.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionStep = this.TransactionStep.get()
    } else {
      fbeValue.TransactionStep = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionStep.fbeSize

    if ((fbeCurrentSize + this.TransactionTimestamp.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionTimestamp = this.TransactionTimestamp.get()
    } else {
      fbeValue.TransactionTimestamp = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionTimestamp.fbeSize

    if ((fbeCurrentSize + this.AccountComment.fbeSize) <= fbeStructSize) {
      fbeValue.AccountComment = this.AccountComment.get()
    } else {
      fbeValue.AccountComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountComment.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrencyName = this.BalanceCurrencyName.get()
    } else {
      fbeValue.BalanceCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyName.fbeSize

    if ((fbeCurrentSize + this.BalanceMovement.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceMovement = this.BalanceMovement.get()
    } else {
      fbeValue.BalanceMovement = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceMovement.fbeSize

    if ((fbeCurrentSize + this.BalanceResult.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceResult = this.BalanceResult.get()
    } else {
      fbeValue.BalanceResult = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceResult.fbeSize

    if ((fbeCurrentSize + this.SrcAssetCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.SrcAssetCurrencyName = this.SrcAssetCurrencyName.get()
    } else {
      fbeValue.SrcAssetCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetCurrencyName.fbeSize

    if ((fbeCurrentSize + this.SrcAssetMovement.fbeSize) <= fbeStructSize) {
      fbeValue.SrcAssetMovement = this.SrcAssetMovement.get()
    } else {
      fbeValue.SrcAssetMovement = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetMovement.fbeSize

    if ((fbeCurrentSize + this.SrcAssetResult.fbeSize) <= fbeStructSize) {
      fbeValue.SrcAssetResult = this.SrcAssetResult.get()
    } else {
      fbeValue.SrcAssetResult = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAssetResult.fbeSize

    if ((fbeCurrentSize + this.DstAssetCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.DstAssetCurrencyName = this.DstAssetCurrencyName.get()
    } else {
      fbeValue.DstAssetCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetCurrencyName.fbeSize

    if ((fbeCurrentSize + this.DstAssetMovement.fbeSize) <= fbeStructSize) {
      fbeValue.DstAssetMovement = this.DstAssetMovement.get()
    } else {
      fbeValue.DstAssetMovement = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetMovement.fbeSize

    if ((fbeCurrentSize + this.DstAssetResult.fbeSize) <= fbeStructSize) {
      fbeValue.DstAssetResult = this.DstAssetResult.get()
    } else {
      fbeValue.DstAssetResult = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAssetResult.fbeSize

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ParentOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ParentOrderId = this.ParentOrderId.get()
    } else {
      fbeValue.ParentOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ParentOrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.OrderSide.fbeSize) <= fbeStructSize) {
      fbeValue.OrderSide = this.OrderSide.get()
    } else {
      fbeValue.OrderSide = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderSide.fbeSize

    if ((fbeCurrentSize + this.OrderType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderType = this.OrderType.get()
    } else {
      fbeValue.OrderType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderType.fbeSize

    if ((fbeCurrentSize + this.OrderInitialType.fbeSize) <= fbeStructSize) {
      fbeValue.OrderInitialType = this.OrderInitialType.get()
    } else {
      fbeValue.OrderInitialType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderInitialType.fbeSize

    if ((fbeCurrentSize + this.OrderTimeInForce.fbeSize) <= fbeStructSize) {
      fbeValue.OrderTimeInForce = this.OrderTimeInForce.get()
    } else {
      fbeValue.OrderTimeInForce = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderTimeInForce.fbeSize

    if ((fbeCurrentSize + this.OrderStatus.fbeSize) <= fbeStructSize) {
      fbeValue.OrderStatus = this.OrderStatus.get()
    } else {
      fbeValue.OrderStatus = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderStatus.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    if ((fbeCurrentSize + this.StopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.StopPrice = this.StopPrice.get()
    } else {
      fbeValue.StopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopPrice.fbeSize

    if ((fbeCurrentSize + this.TakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TakeProfit = this.TakeProfit.get()
    } else {
      fbeValue.TakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TakeProfit.fbeSize

    if ((fbeCurrentSize + this.StopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.StopLoss = this.StopLoss.get()
    } else {
      fbeValue.StopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StopLoss.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.ExecutedAmount.fbeSize) <= fbeStructSize) {
      fbeValue.ExecutedAmount = this.ExecutedAmount.get()
    } else {
      fbeValue.ExecutedAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutedAmount.fbeSize

    if ((fbeCurrentSize + this.RemainingAmount.fbeSize) <= fbeStructSize) {
      fbeValue.RemainingAmount = this.RemainingAmount.get()
    } else {
      fbeValue.RemainingAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RemainingAmount.fbeSize

    if ((fbeCurrentSize + this.Expiration.fbeSize) <= fbeStructSize) {
      fbeValue.Expiration = this.Expiration.get()
    } else {
      fbeValue.Expiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Expiration.fbeSize

    if ((fbeCurrentSize + this.Magic.fbeSize) <= fbeStructSize) {
      fbeValue.Magic = this.Magic.get()
    } else {
      fbeValue.Magic = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Magic.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.OpenPrice.fbeSize) <= fbeStructSize) {
      fbeValue.OpenPrice = this.OpenPrice.get()
    } else {
      fbeValue.OpenPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OpenPrice.fbeSize

    if ((fbeCurrentSize + this.ClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.ClosePrice = this.ClosePrice.get()
    } else {
      fbeValue.ClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClosePrice.fbeSize

    if ((fbeCurrentSize + this.CloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.CloseAmount = this.CloseAmount.get()
    } else {
      fbeValue.CloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CloseAmount.fbeSize

    if ((fbeCurrentSize + this.Profit.fbeSize) <= fbeStructSize) {
      fbeValue.Profit = this.Profit.get()
    } else {
      fbeValue.Profit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Profit.fbeSize

    if ((fbeCurrentSize + this.ProfitConversionRate.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitConversionRate = this.ProfitConversionRate.get()
    } else {
      fbeValue.ProfitConversionRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitConversionRate.fbeSize

    if ((fbeCurrentSize + this.Commission.fbeSize) <= fbeStructSize) {
      fbeValue.Commission = this.Commission.get()
    } else {
      fbeValue.Commission = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Commission.fbeSize

    if ((fbeCurrentSize + this.Swap.fbeSize) <= fbeStructSize) {
      fbeValue.Swap = this.Swap.get()
    } else {
      fbeValue.Swap = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Swap.fbeSize

    if ((fbeCurrentSize + this.Opened.fbeSize) <= fbeStructSize) {
      fbeValue.Opened = this.Opened.get()
    } else {
      fbeValue.Opened = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Opened.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerComment = this.ManagerComment.get()
    } else {
      fbeValue.ManagerComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    if ((fbeCurrentSize + this.Closed.fbeSize) <= fbeStructSize) {
      fbeValue.Closed = this.Closed.get()
    } else {
      fbeValue.Closed = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Closed.fbeSize

    if ((fbeCurrentSize + this.LastClosePrice.fbeSize) <= fbeStructSize) {
      fbeValue.LastClosePrice = this.LastClosePrice.get()
    } else {
      fbeValue.LastClosePrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastClosePrice.fbeSize

    if ((fbeCurrentSize + this.LastCloseAmount.fbeSize) <= fbeStructSize) {
      fbeValue.LastCloseAmount = this.LastCloseAmount.get()
    } else {
      fbeValue.LastCloseAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastCloseAmount.fbeSize

    if ((fbeCurrentSize + this.SocialTradingOrderType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingOrderType = this.SocialTradingOrderType.get()
    } else {
      fbeValue.SocialTradingOrderType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingOrderType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterLogin.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMasterLogin = this.SocialTradingMasterLogin.get()
    } else {
      fbeValue.SocialTradingMasterLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterLogin.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradeHistoryInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradeHistoryInfo}
   * @param {!TradeHistoryInfo} fbeValue TradeHistoryInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradeHistoryInfo}
   * @param {TradeHistoryInfo} fbeValue TradeHistoryInfo value
   */
  setFields (fbeValue) {
    this.RecordId.set(fbeValue.RecordId)
    this.AccountLogin.set(fbeValue.AccountLogin)
    this.ManagerLogin.set(fbeValue.ManagerLogin)
    this.TransactionType.set(fbeValue.TransactionType)
    this.TransactionReason.set(fbeValue.TransactionReason)
    this.TransactionStep.set(fbeValue.TransactionStep)
    this.TransactionTimestamp.set(fbeValue.TransactionTimestamp)
    this.AccountComment.set(fbeValue.AccountComment)
    this.BalanceCurrencyName.set(fbeValue.BalanceCurrencyName)
    this.BalanceMovement.set(fbeValue.BalanceMovement)
    this.BalanceResult.set(fbeValue.BalanceResult)
    this.SrcAssetCurrencyName.set(fbeValue.SrcAssetCurrencyName)
    this.SrcAssetMovement.set(fbeValue.SrcAssetMovement)
    this.SrcAssetResult.set(fbeValue.SrcAssetResult)
    this.DstAssetCurrencyName.set(fbeValue.DstAssetCurrencyName)
    this.DstAssetMovement.set(fbeValue.DstAssetMovement)
    this.DstAssetResult.set(fbeValue.DstAssetResult)
    this.OrderId.set(fbeValue.OrderId)
    this.ParentOrderId.set(fbeValue.ParentOrderId)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.SymbolName.set(fbeValue.SymbolName)
    this.OrderSide.set(fbeValue.OrderSide)
    this.OrderType.set(fbeValue.OrderType)
    this.OrderInitialType.set(fbeValue.OrderInitialType)
    this.OrderTimeInForce.set(fbeValue.OrderTimeInForce)
    this.OrderStatus.set(fbeValue.OrderStatus)
    this.Price.set(fbeValue.Price)
    this.StopPrice.set(fbeValue.StopPrice)
    this.TakeProfit.set(fbeValue.TakeProfit)
    this.StopLoss.set(fbeValue.StopLoss)
    this.Amount.set(fbeValue.Amount)
    this.ExecutedAmount.set(fbeValue.ExecutedAmount)
    this.RemainingAmount.set(fbeValue.RemainingAmount)
    this.Expiration.set(fbeValue.Expiration)
    this.Magic.set(fbeValue.Magic)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.OpenPrice.set(fbeValue.OpenPrice)
    this.ClosePrice.set(fbeValue.ClosePrice)
    this.CloseAmount.set(fbeValue.CloseAmount)
    this.Profit.set(fbeValue.Profit)
    this.ProfitConversionRate.set(fbeValue.ProfitConversionRate)
    this.Commission.set(fbeValue.Commission)
    this.Swap.set(fbeValue.Swap)
    this.Opened.set(fbeValue.Opened)
    this.ManagerComment.set(fbeValue.ManagerComment)
    this.Closed.set(fbeValue.Closed)
    this.LastClosePrice.set(fbeValue.LastClosePrice)
    this.LastCloseAmount.set(fbeValue.LastCloseAmount)
    this.SocialTradingOrderType.set(fbeValue.SocialTradingOrderType)
    this.SocialTradingMasterLogin.set(fbeValue.SocialTradingMasterLogin)
  }
}

export { FieldModelTradeHistoryInfo };

/**
 * Fast Binary Encoding TradeHistoryInfo model
 */
class TradeHistoryInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradeHistoryInfo(this.buffer, 4)
  }

  /**
   * Get the TradeHistoryInfo model
   * @this {!TradeHistoryInfoModel}
   * @returns {!FieldModelTradeHistoryInfo} model TradeHistoryInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradeHistoryInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradeHistoryInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradeHistoryInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradeHistoryInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradeHistoryInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradeHistoryInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradeHistoryInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradeHistoryInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradeHistoryInfoModel}
   * @param {!TradeHistoryInfo} value TradeHistoryInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradeHistoryInfoModel}
   * @param {!TradeHistoryInfo} value TradeHistoryInfo value, defaults is new TradeHistoryInfo()
   * @return {!object} Deserialized TradeHistoryInfo value and its size
   */
  deserialize (value = new TradeHistoryInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradeHistoryInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradeHistoryInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradeHistoryInfoModel}
   * @param {!number} prev Previous TradeHistoryInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradeHistoryInfoModel };
