import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { ParsedManagerGroup } from '@models';
import { SocketClient } from '@api/SocketClient';
import { adaptFromApi, adaptToApiCreate, adaptToApiModify } from './utils';
import { UUID } from '@api/fbe/uuid';
import { ManagerGroupsGetAllRequest } from '@api/fbe/manager/ManagerGroupsGetAllRequest';
import { ManagerGroupCreateRequest } from '@api/fbe/manager/ManagerGroupCreateRequest';
import { ManagerGroupDeleteRequest } from '@api/fbe/manager/ManagerGroupDeleteRequest';
import { ManagerGroupModifyRequest } from '@api/fbe/manager/ManagerGroupModifyRequest';

export class ManagerGroupsStore {
    errorTracker = new ErrorsTracker({ title: 'ManagerGroups' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ParsedManagerGroup[] = [];

    isLoading: boolean = false;

    setData(value: ParsedManagerGroup[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new ManagerGroupsGetAllRequest();

        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.ManagerGroups.map((group) => adaptFromApi(group)));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(group: ParsedManagerGroup) {
        const request = adaptToApiCreate(new ManagerGroupCreateRequest(), group);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(parsedId: string) {
        const request = new ManagerGroupDeleteRequest();
        request.ManagerGroupId = UUID.fromString(parsedId);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(newManagerGroup: ParsedManagerGroup) {
        const request = adaptToApiModify(new ManagerGroupModifyRequest(), newManagerGroup);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const managerGroupsStore = new ManagerGroupsStore();
export { managerGroupsStore };
