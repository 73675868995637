// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: FBE
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as big from './big'
import * as int64 from './int64'
import * as ieee754 from './ieee754'
import * as utf8 from './utf8'
import * as uuid from './uuid'

const Big = big.Big
const Int64 = int64.Int64
const UInt64 = int64.UInt64
const UUID = uuid.UUID
const ieee754read = ieee754.ieee754read
const ieee754write = ieee754.ieee754write
const utf8count = utf8.utf8count
const utf8encode = utf8.utf8encode
const utf8decode = utf8.utf8decode

/**
 * Fast Binary Encoding deferred promise
 */
class DeferredPromise {
  /**
   * Initialize buffer
   * @constructor
   */
  constructor () {
    this._promise = new Promise((resolve, reject) => {
      // Assign the resolve and reject functions to `this` making them usable on the class instance
      this.resolve = resolve
      this.reject = reject
    })
    // Bind `then` and `catch` to implement the same interface as Promise
    this.then = this._promise.then.bind(this._promise)
    this.catch = this._promise.catch.bind(this._promise)
    this[Symbol.toStringTag] = 'Promise'
  }
}

export { DeferredPromise };

/**
 * Fast Binary Encoding base buffer
 */
class BaseBuffer {
  /**
   * Initialize buffer
   * @constructor
   */
  constructor () {
    this._buffer = null
    this._size = 0
    this._offset = 0
  }

  /**
   * Is the buffer empty?
   * @this {!BaseBuffer}
   * @returns {boolean} Buffer empty flag
   */
  get isEmpty () {
    return (this._buffer == null) || (this._size === 0)
  }

  /**
   * Get the buffer
   * @this {!BaseBuffer}
   * @returns {Uint8Array} Buffer
   */
  get buffer () {
    return this._buffer
  }

  /**
   * Get the buffer capacity
   * @this {!BaseBuffer}
   * @returns {number} Buffer capacity
   */
  get capacity () {
    return this._buffer.length
  }

  /**
   * Get the buffer length
   * @this {!BaseBuffer}
   * @returns {number} Buffer length
   */
  get length () {
    return this._size
  }

  /**
   * Get the buffer size
   * @this {!BaseBuffer}
   * @returns {number} Buffer size
   */
  get size () {
    return this._size
  }

  /**
   * Get the buffer offset
   * @this {!BaseBuffer}
   * @returns {number} Buffer offset
   */
  get offset () {
    return this._offset
  }

  /**
   * Shift the current buffer offset
   * @this {!BaseBuffer}
   * @param {!number} offset Offset
   */
  shift (offset) {
    this._offset += offset
  }

  /**
   * Unshift the current buffer offset
   * @this {!BaseBuffer}
   * @param {!number} offset Offset
   */
  unshift (offset) {
    this._offset -= offset
  }

  /**
   * Check the buffer offset bounds
   * @this {!BaseBuffer}
   * @param {!number} offset Offset
   * @param {!number} size Size
   */
  checkOffset (offset, size) {
    if (((offset % 1) !== 0) || (offset < 0)) {
      throw new RangeError('Invalid offset!')
    }
    if ((offset + size) > this.length) {
      throw new RangeError('Out of bounds!')
    }
  }

  /**
   * Check the value range and its buffer offset bounds
   * @this {!BaseBuffer}
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @param {!number} value Value
   * @param {!number} min Min value
   * @param {!number} max Max value
   */
  checkValue (offset, size, value, min, max) {
    this.checkOffset(offset, size)
    if ((value < min) || (value > max)) {
      throw new RangeError('Value is out of bounds!')
    }
  }
}

export { BaseBuffer };

/**
 * Fast Binary Encoding write buffer based on the dynamic byte array
 */
class WriteBuffer extends BaseBuffer {
  /**
   * Initialize write buffer with the given capacity
   * @param {number=} capacity Write buffer capacity, defaults is 0
   * @constructor
   */
  constructor (capacity = 0) {
    super()
    this._buffer = new Uint8Array(capacity)
    this._size = 0
    this._offset = 0
  }

  /**
   * Attach an empty memory buffer
   * @this {!WriteBuffer}
   */
  attachNew () {
    this._buffer = new Uint8Array(0)
    this._size = 0
    this._offset = 0
  }

  /**
   * Attach an empty memory buffer with a given capacity
   * @this {!WriteBuffer}
   * @param {number=} capacity Write buffer capacity, defaults is 0
   */
  attachCapacity (capacity = 0) {
    this._buffer = new Uint8Array(capacity)
    this._size = 0
    this._offset = 0
  }

  /**
   * Attach a given memory buffer
   * @this {!WriteBuffer}
   * @param {!Uint8Array|!ReadBuffer|!WriteBuffer} buffer Buffer to attach
   * @param {number=} offset Buffer offset, defaults is 0
   * @param {number=} size Buffer size, defaults is buffer.length
   */
  attachBuffer (buffer, offset = 0, size = undefined) {
    if (size == null) {
      size = buffer.length
    }

    if (size <= 0) {
      throw new Error('Invalid size!')
    }
    if (offset > size) {
      throw new Error('Invalid offset!')
    }
    if ((buffer instanceof ReadBuffer) || (buffer instanceof WriteBuffer)) {
      this._buffer = buffer.buffer
    } else {
      this._buffer = buffer
    }
    this._size = size
    this._offset = offset
  }

  /**
   * Allocate memory in the current write buffer and return offset to the allocated memory block
   * @this {!WriteBuffer}
   * @param {!number} size Allocation size
   * @returns {!number} Allocated memory offset
   */
  allocate (size) {
    if (size < 0) {
      throw new Error('Invalid allocation size!')
    }

    let offset = this._size

    // Calculate a new buffer size
    let total = this._size + size

    if (total <= this._buffer.length) {
      this._size = total
      return offset
    }

    let data = new Uint8Array(Math.max(total, 2 * this._buffer.length))
    data.set(this._buffer)
    this._buffer = data
    this._size = total
    return offset
  }

  /**
   * Remove some memory of the given size from the current write buffer
   * @this {!WriteBuffer}
   * @param {!number} offset Removed memory offset
   * @param {!number} size Removed memory size
   */
  remove (offset, size) {
    if ((offset + size) > this._buffer.length) {
      throw new Error('Invalid offset & size!')
    }

    for (let i = 0; i < size; i++) {
      this._buffer[offset + i] = this._buffer[offset + size + i]
    }
    this._size -= size
    if (this._offset >= (offset + size)) {
      this._offset -= size
    } else if (this._offset >= offset) {
      this._offset -= this._offset - offset
      if (this._offset > this._size) {
        this._offset = this._size
      }
    }
  }

  /**
   * Reserve memory of the given capacity in the current write buffer
   * @this {!WriteBuffer}
   * @param {number} capacity Write buffer capacity
   */
  reserve (capacity) {
    if (capacity < 0) {
      throw new Error('Invalid reserve capacity!')
    }

    if (capacity > this._buffer.length) {
      let data = new Uint8Array(Math.max(capacity, 2 * this._buffer.length))
      data.set(this._buffer)
      this._buffer = data
    }
  }

  /**
   * Resize the current write buffer
   * @this {!WriteBuffer}
   * @param {number} size Write buffer size
   */
  resize (size) {
    this.reserve(size)
    this._size = size
    if (this._offset > this._size) {
      this._offset = this._size
    }
  }

  /**
   * Reset the current write buffer and its offset
   * @this {!WriteBuffer}
   */
  reset () {
    this._size = 0
    this._offset = 0
  }
}

export { WriteBuffer };

/**
 * Fast Binary Encoding read buffer based on the constant byte buffer
 */
class ReadBuffer extends BaseBuffer {
  /**
   * Attach a given memory buffer
   * @this {!ReadBuffer}
   * @param {!Uint8Array|!ReadBuffer|!WriteBuffer} buffer Buffer to attach
   * @param {number=} offset Buffer offset, defaults is 0
   * @param {number=} size Buffer size, defaults is buffer.length
   */
  attachBuffer (buffer, offset = 0, size = undefined) {
    if (size == null) {
      size = buffer.length
    }

    if (size <= 0) {
      throw new Error('Invalid size!')
    }
    if (offset > size) {
      throw new Error('Invalid offset!')
    }
    if ((buffer instanceof ReadBuffer) || (buffer instanceof WriteBuffer)) {
      this._buffer = buffer.buffer
    } else {
      this._buffer = buffer
    }
    this._size = size
    this._offset = offset
  }

  /**
   * Reset the current read buffer and its offset
   * @this {!ReadBuffer}
   */
  reset () {
    this._buffer = null
    this._size = 0
    this._offset = 0
  }
}

export { ReadBuffer };

/**
 * Fast Binary Encoding base model
 */
class Model {
  /**
   * Initialize model with the given buffer
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new WriteBuffer()) {
    this._buffer = buffer
  }

  /**
   * Get the buffer
   * @this {!Buffer}
   * @returns {Uint8Array} Buffer
   */
  get buffer () {
    return this._buffer
  }

  /**
   * Attach an empty memory buffer
   * @this {!Model}
   */
  attachNew () {
    this._buffer.attachNew()
  }

  /**
   * Attach an empty memory buffer with a given capacity
   * @this {!Model}
   * @param {number=} capacity Write buffer capacity, defaults is 0
   */
  attachCapacity (capacity = 0) {
    this._buffer.attachCapacity(capacity)
  }

  /**
   * Attach a given memory buffer
   * @this {!Model}
   * @param {!Uint8Array|!ReadBuffer|!WriteBuffer} buffer Buffer to attach
   * @param {number=} offset Buffer offset, defaults is 0
   * @param {number=} size Buffer size, defaults is undefined
   */
  attachBuffer (buffer, offset = 0, size = undefined) {
    this._buffer.attachBuffer(buffer, offset, size)
  }

  /**
   * Allocate memory in the current write buffer and return offset to the allocated memory block
   * @this {!Model}
   * @param {!number} size Allocation size
   * @returns {!number} Allocated memory offset
   */
  allocate (size) {
    return this._buffer.allocate(size)
  }

  /**
   * Remove some memory of the given size from the current write buffer
   * @this {!Model}
   * @param {!number} offset Removed memory offset
   * @param {!number} size Removed memory size
   */
  remove (offset, size) {
    this._buffer.remove(offset, size)
  }

  /**
   * Reserve memory of the given capacity in the current write buffer
   * @this {!Model}
   * @param {number} capacity Write buffer capacity, defaults is 0
   */
  reserve (capacity) {
    this._buffer.reserve(capacity)
  }

  /**
   * Resize the current write buffer
   * @this {!Model}
   * @param {number} size Write buffer size
   */
  resize (size) {
    this._buffer.resize(size)
  }

  /**
   * Reset the current write buffer and its offset
   * @this {!Model}
   */
  reset () {
    this._buffer.reset()
  }

  /**
   * Shift the current write buffer offset
   * @this {!Model}
   * @param {!number} offset Offset
   */
  shift (offset) {
    this._buffer.shift(offset)
  }

  /**
   * Unshift the current write buffer offset
   * @this {!Model}
   * @param {!number} offset Offset
   */
  unshift (offset) {
    this._buffer.unshift(offset)
  }

  // Buffer I/O methods

  /**
   * Read UInt32 value from the model buffer
   * @this {!Model}
   * @param {!number} offset Offset
   * @returns {!number} UInt32 value
   */
  readUInt32 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 4)
    return (
      (this._buffer.buffer[offset + 0] << 0) |
      (this._buffer.buffer[offset + 1] << 8) |
      (this._buffer.buffer[offset + 2] << 16)) +
      (this._buffer.buffer[offset + 3] * 0x1000000)
  }

  /**
   * Write UInt32 value into the model buffer
   * @this {!Model}
   * @param {!number} offset Offset
   * @param {!number} value Value
   */
  writeUInt32 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 4, value, 0, 0xFFFFFFFF)
    this._buffer.buffer[offset + 3] = (value >>> 24)
    this._buffer.buffer[offset + 2] = (value >>> 16)
    this._buffer.buffer[offset + 1] = (value >>> 8)
    this._buffer.buffer[offset + 0] = (value & 0xFF)
  }
}

export { Model };

/**
 * Fast Binary Encoding base field model
 */
class FieldModelBase {
  /**
   * Initialize field model with the given buffer
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    this._buffer = buffer
    this._offset = offset
  }

  /**
   * Get the field offset
   * @this {!FieldModelBase}
   * @returns {!number} Field offset
   */
  get fbeOffset () {
    return this._offset
  }

  /**
   * Set the field offset
   * @this {!FieldModelBase}
   * @param {!number} offset Field offset
   */
  set fbeOffset (offset) {
    this._offset = offset
  }

  /**
   * Get the field size
   * @this {!FieldModelBase}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 0
  }

  /**
   * Get the field extra size
   * @this {!FieldModelBase}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    return 0
  }

  /**
   * Shift the current field offset
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   */
  fbeShift (offset) {
    this._offset += offset
  }

  /**
   * Unshift the current field offset
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   */
  fbeUnshift (offset) {
    this._offset -= offset
  }

  // Buffer I/O methods

  /**
   * Read boolean value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!boolean} Boolean value
   */
  readBool (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 1)
    return this._buffer.buffer[offset] !== 0
  }

  /**
   * Read byte value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Byte value
   */
  readByte (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 1)
    return this._buffer.buffer[offset]
  }

  /**
   * Read char value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!string} Char value
   */
  readChar (offset) {
    let code = this.readUInt8(offset)
    return String.fromCharCode(code)
  }

  /**
   * Read wide char value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!string} Wide char value
   */
  readWChar (offset) {
    let code = this.readUInt32(offset)
    return String.fromCharCode(code)
  }

  /**
   * Read Int8 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Int8 value
   */
  readInt8 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 1)
    if ((this._buffer.buffer[offset] & 0x80) === 0) {
      return (this._buffer.buffer[offset])
    }
    return ((0xFF - this._buffer.buffer[offset] + 1) * -1)
  }

  /**
   * Read UInt8 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} UInt8 value
   */
  readUInt8 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 1)
    return this._buffer.buffer[offset]
  }

  /**
   * Read Int16 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Int16 value
   */
  readInt16 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 2)
    let val = this._buffer.buffer[offset] | (this._buffer.buffer[offset + 1] << 8)
    return (val & 0x8000) ? val | 0xFFFF0000 : val
  }

  /**
   * Read UInt16 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} UInt16 value
   */
  readUInt16 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 2)
    return this._buffer.buffer[offset] | (this._buffer.buffer[offset + 1] << 8)
  }

  /**
   * Read Int32 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Int32 value
   */
  readInt32 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 4)
    return (
      (this._buffer.buffer[offset + 0] << 0) |
      (this._buffer.buffer[offset + 1] << 8) |
      (this._buffer.buffer[offset + 2] << 16) |
      (this._buffer.buffer[offset + 3] << 24))
  }

  /**
   * Read UInt32 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} UInt32 value
   */
  readUInt32 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 4)
    return (
      (this._buffer.buffer[offset + 0] << 0) |
      (this._buffer.buffer[offset + 1] << 8) |
      (this._buffer.buffer[offset + 2] << 16)) +
      (this._buffer.buffer[offset + 3] * 0x1000000)
  }

  /**
   * Read Int64 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!Int64} Int64 value
   */
  readInt64 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 8)
    let low = (
      (this._buffer.buffer[offset + 0] << 0) |
      (this._buffer.buffer[offset + 1] << 8) |
      (this._buffer.buffer[offset + 2] << 16) |
      (this._buffer.buffer[offset + 3] << 24))
    let high = (
      (this._buffer.buffer[offset + 4] << 0) |
      (this._buffer.buffer[offset + 5] << 8) |
      (this._buffer.buffer[offset + 6] << 16) |
      (this._buffer.buffer[offset + 7] << 24))
    return new Int64(low, high)
  }

  /**
   * Read UInt64 value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!UInt64} UInt64 value
   */
  readUInt64 (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 8)
    let low = (
      (this._buffer.buffer[offset + 0] << 0) |
      (this._buffer.buffer[offset + 1] << 8) |
      (this._buffer.buffer[offset + 2] << 16) |
      (this._buffer.buffer[offset + 3] << 24))
    let high = (
      (this._buffer.buffer[offset + 4] << 0) |
      (this._buffer.buffer[offset + 5] << 8) |
      (this._buffer.buffer[offset + 6] << 16) +
      (this._buffer.buffer[offset + 7] << 24))
    return new UInt64(low, high)
  }

  /**
   * Read float value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Float value
   */
  readFloat (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 4)
    return ieee754read(this._buffer.buffer, offset, true, 23, 4)
  }

  /**
   * Read double value from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @returns {!number} Double value
   */
  readDouble (offset) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 4)
    return ieee754read(this._buffer.buffer, offset, true, 52, 8)
  }

  /**
   * Read bytes from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @returns {!Uint8Array} Bytes buffer
   */
  readBytes (offset, size) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, size)
    return this._buffer.buffer.slice(offset, offset + size)
  }

  /**
   * Read string from the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @returns {!string} String value
   */
  readString (offset, size) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, size)
    return utf8decode(this._buffer.buffer, offset, size)
  }

  /**
   * Write boolean value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!boolean} value Boolean value
   */
  writeBool (offset, value) {
    let byte = value ? 1 : 0
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 1, byte, 0, 0xFF)
    this._buffer.buffer[offset] = (byte & 0xFF)
  }

  /**
   * Write byte value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Byte value
   */
  writeByte (offset, value) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 1, value, 0, 0xFF)
    this._buffer.buffer[offset] = (value & 0xFF)
  }

  /**
   * Write char value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!string} value Char value
   */
  writeChar (offset, value) {
    let code = value.charCodeAt(0)
    this.writeUInt8(offset, code)
  }

  /**
   * Write wide char value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!string} value Wide char value
   */
  writeWChar (offset, value) {
    let code = value.charCodeAt(0)
    this.writeUInt32(offset, code)
  }

  /**
   * Write Int8 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Int8 value
   */
  writeInt8 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 1, value, -0x80, 0x7F)
    if (value < 0) {
      value = 0xFF + value + 1
    }
    this._buffer.buffer[offset] = (value & 0xFF)
  }

  /**
   * Write UInt8 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value UInt8 value
   */
  writeUInt8 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 1, value, 0, 0xFF)
    this._buffer.buffer[offset] = (value & 0xFF)
  }

  /**
   * Write Int16 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Int16 value
   */
  writeInt16 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 2, value, -0x8000, 0x7FFF)
    this._buffer.buffer[offset + 0] = (value & 0xFF)
    this._buffer.buffer[offset + 1] = (value >>> 8)
  }

  /**
   * Write UInt16 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value UInt16 value
   */
  writeUInt16 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 2, value, 0, 0xFFFF)
    this._buffer.buffer[offset + 0] = (value & 0xFF)
    this._buffer.buffer[offset + 1] = (value >>> 8)
  }

  /**
   * Write Int32 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Int32 value
   */
  writeInt32 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 4, value, -0x80000000, 0x7FFFFFFF)
    this._buffer.buffer[offset + 0] = (value & 0xFF)
    this._buffer.buffer[offset + 1] = (value >>> 8)
    this._buffer.buffer[offset + 2] = (value >>> 16)
    this._buffer.buffer[offset + 3] = (value >>> 24)
  }

  /**
   * Write UInt32 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value UInt32 value
   */
  writeUInt32 (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 4, value, 0, 0xFFFFFFFF)
    this._buffer.buffer[offset + 0] = (value & 0xFF)
    this._buffer.buffer[offset + 1] = (value >>> 8)
    this._buffer.buffer[offset + 2] = (value >>> 16)
    this._buffer.buffer[offset + 3] = (value >>> 24)
  }

  /**
   * Write Int64 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!Int64} value Int64 value
   */
  writeInt64 (offset, value) {
    value = Int64.fromValue(value)
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 8)
    this._buffer.buffer[offset + 0] = (value.low & 0xFF)
    this._buffer.buffer[offset + 1] = (value.low >>> 8)
    this._buffer.buffer[offset + 2] = (value.low >>> 16)
    this._buffer.buffer[offset + 3] = (value.low >>> 24)
    this._buffer.buffer[offset + 4] = (value.high & 0xFF)
    this._buffer.buffer[offset + 5] = (value.high >>> 8)
    this._buffer.buffer[offset + 6] = (value.high >>> 16)
    this._buffer.buffer[offset + 7] = (value.high >>> 24)
  }

  /**
   * Write UInt64 value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!UInt64} value UInt64 value
   */
  writeUInt64 (offset, value) {
    value = UInt64.fromValue(value)
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, 8)
    this._buffer.buffer[offset + 0] = (value.low & 0xFF)
    this._buffer.buffer[offset + 1] = (value.low >>> 8)
    this._buffer.buffer[offset + 2] = (value.low >>> 16)
    this._buffer.buffer[offset + 3] = (value.low >>> 24)
    this._buffer.buffer[offset + 4] = (value.high & 0xFF)
    this._buffer.buffer[offset + 5] = (value.high >>> 8)
    this._buffer.buffer[offset + 6] = (value.high >>> 16)
    this._buffer.buffer[offset + 7] = (value.high >>> 24)
  }

  /**
   * Write float value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Float value
   */
  writeFloat (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 4, value, -3.4028234663852886e+38, 3.4028234663852886e+38)
    ieee754write(this._buffer.buffer, offset, value, true, 23, 4)
  }

  /**
   * Write double value into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Double value
   */
  writeDouble (offset, value) {
    value = +value
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkValue(offset, 8, value, -1.7976931348623157E+308, 1.7976931348623157E+308)
    ieee754write(this._buffer.buffer, offset, value, true, 52, 8)
  }

  /**
   * Write bytes into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!Uint8Array} value Bytes buffer value
   * @param {number=} valueOffset Bytes buffer offset, defaults is 0
   * @param {number=} valueSize Bytes buffer size, defaults is value.length
   */
  writeBytes (offset, value, valueOffset = 0, valueSize = undefined) {
    if (valueSize == null) {
      valueSize = value.length
    }

    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, valueSize)
    for (let i = 0; i < valueSize; i++) {
      this._buffer.buffer[offset + i] = value[valueOffset + i]
    }
  }

  /**
   * Write byte value of the given count into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!number} value Byte value
   * @param {!number} valueCount Count
   */
  writeCount (offset, value, valueCount) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, valueCount)
    for (let i = 0; i < valueCount; i++) {
      this._buffer.buffer[offset + i] = (value & 0xFF)
    }
  }

  /**
   * Write string into the field model buffer
   * @this {!FieldModelBase}
   * @param {!number} offset Offset
   * @param {!string} value String value
   * @param {number=} size String size
   */
  writeString (offset, value, size) {
    offset = offset >>> 0
    offset += this._buffer.offset
    this._buffer.checkOffset(offset, size)
    utf8encode(this._buffer.buffer, offset, value)
  }
}

export { FieldModelBase };

/**
 * Fast Binary Encoding field model
 */
class FieldModel extends FieldModelBase {
  /**
   * Check if the value is valid
   * @this {!FieldModel}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    return true
  }
}

export { FieldModel };

/**
 * Fast Binary Encoding bool field model
 */
class FieldModelBool extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelBool}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelBool}
   * @param {boolean=} defaults Default value, defaults is false
   * @returns {!boolean} Result value
   */
  get (defaults = false) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readBool(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelBool}
   * @param {!boolean} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeBool(this.fbeOffset, value)
  }
}

export { FieldModelBool };

/**
 * Fast Binary Encoding byte field model
 */
class FieldModelByte extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelByte}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelByte}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readByte(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelByte}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value)
  }
}

export { FieldModelByte };

/**
 * Fast Binary Encoding char field model
 */
class FieldModelChar extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelChar}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelChar}
   * @param {string=} defaults Default value, defaults is '\0'
   * @returns {!string} Result value
   */
  get (defaults = '\0') {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readChar(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelChar}
   * @param {!string} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeChar(this.fbeOffset, value)
  }
}

export { FieldModelChar };

/**
 * Fast Binary Encoding wchar field model
 */
class FieldModelWChar extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelWChar}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelWChar}
   * @param {string=} defaults Default value, defaults is '\0'
   * @returns {!string} Result value
   */
  get (defaults = '\0') {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readWChar(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelWChar}
   * @param {!string} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeWChar(this.fbeOffset, value)
  }
}

export { FieldModelWChar };

/**
 * Fast Binary Encoding int8 field model
 */
class FieldModelInt8 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelInt8}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelInt8}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readInt8(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelInt8}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt8(this.fbeOffset, value)
  }
}

export { FieldModelInt8 };

/**
 * Fast Binary Encoding uint8 field model
 */
class FieldModelUInt8 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelUInt8}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelUInt8}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readUInt8(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelUInt8}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeUInt8(this.fbeOffset, value)
  }
}

export { FieldModelUInt8 };

/**
 * Fast Binary Encoding int16 field model
 */
class FieldModelInt16 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelInt16}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 2
  }

  /**
   * Get the value
   * @this {!FieldModelInt16}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readInt16(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelInt16}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt16(this.fbeOffset, value)
  }
}

export { FieldModelInt16 };

/**
 * Fast Binary Encoding uint16 field model
 */
class FieldModelUInt16 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelUInt16}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 2
  }

  /**
   * Get the value
   * @this {!FieldModelUInt16}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readUInt16(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelUInt16}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeUInt16(this.fbeOffset, value)
  }
}

export { FieldModelUInt16 };

/**
 * Fast Binary Encoding int32 field model
 */
class FieldModelInt32 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelInt32}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelInt32}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readInt32(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelInt32}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value)
  }
}

export { FieldModelInt32 };

/**
 * Fast Binary Encoding uint32 field model
 */
class FieldModelUInt32 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelUInt32}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelUInt32}
   * @param {number=} defaults Default value, defaults is 0
   * @returns {!number} Result value
   */
  get (defaults = 0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readUInt32(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelUInt32}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeUInt32(this.fbeOffset, value)
  }
}

export { FieldModelUInt32 };

/**
 * Fast Binary Encoding int64 field model
 */
class FieldModelInt64 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelInt64}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 8
  }

  /**
   * Get the value
   * @this {!FieldModelInt64}
   * @param {Int64=} defaults Default value, defaults is new Int64(0, 0)
   * @returns {!Int64} Result value
   */
  get (defaults = new Int64(0, 0)) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readInt64(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelInt64}
   * @param {!Int64} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt64(this.fbeOffset, value)
  }
}

export { FieldModelInt64 };

/**
 * Fast Binary Encoding uint64 field model
 */
class FieldModelUInt64 extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelUInt64}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 8
  }

  /**
   * Get the value
   * @this {!FieldModelUInt64}
   * @param {UInt64=} defaults Default value, defaults is new UInt64(0, 0)
   * @returns {!UInt64} Result value
   */
  get (defaults = new UInt64(0, 0)) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readUInt64(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelUInt64}
   * @param {!UInt64} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeUInt64(this.fbeOffset, value)
  }
}

export { FieldModelUInt64 };

/**
 * Fast Binary Encoding float field model
 */
class FieldModelFloat extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelFloat}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelFloat}
   * @param {number=} defaults Default value, defaults is 0.0
   * @returns {!number} Result value
   */
  get (defaults = 0.0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readFloat(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelFloat}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeFloat(this.fbeOffset, value)
  }
}

export { FieldModelFloat };

/**
 * Fast Binary Encoding double field model
 */
class FieldModelDouble extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelDouble}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 8
  }

  /**
   * Get the value
   * @this {!FieldModelDouble}
   * @param {number=} defaults Default value, defaults is 0.0
   * @returns {!number} Result value
   */
  get (defaults = 0.0) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return this.readDouble(this.fbeOffset)
  }

  /**
   * Set the value
   * @this {!FieldModelDouble}
   * @param {!number} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeDouble(this.fbeOffset, value)
  }
}

export { FieldModelDouble };

/**
 * Fast Binary Encoding decimal field model
 */
class FieldModelDecimal extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelDecimal}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 16
  }

  /**
   * Get the decimal value
   * @this {!FieldModelDecimal}
   * @param {Big=} defaults Default value, defaults is new Big(0)
   * @returns {!Big} Result value
   */
  get (defaults = new Big(0)) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    // Read decimal parts
    let low = this.readUInt32(this.fbeOffset)
    let mid = this.readUInt32(this.fbeOffset + 4)
    let high = this.readUInt32(this.fbeOffset + 8)
    let flags = this.readUInt32(this.fbeOffset + 12)

    // Calculate decimal value
    let negative = (flags & 0x80000000) !== 0
    let scale = (flags & 0x7FFFFFFF) >> 16
    let result = new Big(0)
    result = result.add(new Big(high).mul('18446744073709551616'))
    result = result.add(new Big(mid).mul('4294967296'))
    result = result.add(new Big(low))
    result = result.div(Math.pow(10, scale))
    if (negative) {
      result.s = -1
    }

    return result
  }

  /**
   * Set the decimal value
   * @this {!FieldModelDecimal}
   * @param {!Big} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    // Extract decimal parts
    let negative = value.s < 0
    let scale = Math.max(0, value.c.length - 1 - value.e)
    let number = value.mul(Math.pow(10, scale)).abs()

    // Check for decimal scale overflow
    if ((scale < 0) || (scale > 28)) {
      // Value scale exceeds .NET Decimal limit of [0, 28]
      this.writeCount(this.fbeOffset, 0, this.fbeSize)
      return
    }

    // Write unscaled value to bytes 0-11
    let index = 0
    while (number > 0) {
      // Check for decimal number overflow
      if (index > 11) {
        // Value too big for .NET Decimal (bit length is limited to [0, 96])
        this.writeCount(this.fbeOffset, 0, this.fbeSize)
        return
      }
      let byte = parseInt(number.mod(256))
      this.writeByte(this.fbeOffset + index, byte)
      number = number.div(256).round(0, 0)
      index++
    }

    // Fill remaining bytes with zeros
    while (index < 12) {
      this.writeByte(this.fbeOffset + index, 0)
      index++
    }

    // Write scale at byte 14
    this.writeByte(this.fbeOffset + 14, scale)

    // Write signum at byte 15
    this.writeByte(this.fbeOffset + 15, (negative ? 0x80 : 0))
  }
}

export { FieldModelDecimal };

/**
 * Fast Binary Encoding timestamp field model
 */
class FieldModelTimestamp extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTimestamp}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 8
  }

  /**
   * Get the timestamp value
   * @this {!FieldModelTimestamp}
   * @param {Date=} defaults Default value, defaults is new Date(0)
   * @returns {!Date} Result value
   */
  get (defaults = new Date(0)) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    let nanoseconds = this.readUInt64(this.fbeOffset)
    return new Date(Math.round(nanoseconds / 1000000))
  }

  /**
   * Set the timestamp value
   * @this {!FieldModelTimestamp}
   * @param {!Date} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let nanoseconds = UInt64.fromNumber(value.getTime()).mul(1000000)
    this.writeUInt64(this.fbeOffset, nanoseconds)
  }
}

export { FieldModelTimestamp };

/**
 * Fast Binary Encoding UUID field model
 */
class FieldModelUUID extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelUUID}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 16
  }

  /**
   * Get the UUID value
   * @this {!FieldModelUUID}
   * @param {UUID=} defaults Default value, defaults is UUID.nil()
   * @returns {!UUID} Result value
   */
  get (defaults = UUID.nil()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new UUID(this.readBytes(this.fbeOffset, 16))
  }

  /**
   * Set the UUID value
   * @this {!FieldModelUUID}
   * @param {!UUID} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeBytes(this.fbeOffset, value.data)
  }
}

export { FieldModelUUID };

/**
 * Fast Binary Encoding bytes field model
 */
class FieldModelBytes extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelBytes}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelBytes}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeBytesOffset = this.readUInt32(this.fbeOffset)
    if ((fbeBytesOffset === 0) || ((this._buffer.offset + fbeBytesOffset + 4) > this._buffer.size)) {
      return 0
    }

    let fbeBytesSize = this.readUInt32(fbeBytesOffset)
    return 4 + fbeBytesSize
  }

  /**
   * Check if the bytes value is valid
   * @this {!FieldModelBytes}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeBytesOffset = this.readUInt32(this.fbeOffset)
    if (fbeBytesOffset === 0) {
      return true
    }

    if ((this._buffer.offset + fbeBytesOffset + 4) > this._buffer.size) {
      return false
    }

    let fbeBytesSize = this.readUInt32(fbeBytesOffset)
    return (this._buffer.offset + fbeBytesOffset + 4 + fbeBytesSize) <= this._buffer.size
  }

  /**
   * Get the bytes value
   * @this {!FieldModelBytes}
   * @param {Uint8Array=} defaults Default value, defaults is Uint8Array(0)
   * @returns {!Uint8Array} Result value
   */
  get (defaults = new Uint8Array(0)) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    let fbeBytesOffset = this.readUInt32(this.fbeOffset)
    if (fbeBytesOffset === 0) {
      return defaults
    }

    console.assert(((this._buffer.offset + fbeBytesOffset + 4) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + fbeBytesOffset + 4) > this._buffer.size) {
      return defaults
    }

    let fbeBytesSize = this.readUInt32(fbeBytesOffset)
    console.assert(((this._buffer.offset + fbeBytesOffset + 4 + fbeBytesSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + fbeBytesOffset + 4 + fbeBytesSize) > this._buffer.size) {
      return defaults
    }

    return this.readBytes(fbeBytesOffset + 4, fbeBytesSize)
  }

  /**
   * Set the bytes value
   * @this {!FieldModelBytes}
   * @param {!Uint8Array} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let fbeBytesSize = value.length
    let fbeBytesOffset = this._buffer.allocate(4 + fbeBytesSize) - this._buffer.offset
    console.assert(((fbeBytesOffset > 0) && ((this._buffer.offset + fbeBytesOffset + 4 + fbeBytesSize) <= this._buffer.size)), 'Model is broken!')
    if (((fbeBytesOffset <= 0) || ((this._buffer.offset + fbeBytesOffset + 4 + fbeBytesSize) > this._buffer.size))) {
      return
    }

    this.writeUInt32(this.fbeOffset, fbeBytesOffset)
    this.writeUInt32(fbeBytesOffset, fbeBytesSize)
    this.writeBytes(fbeBytesOffset + 4, value)
  }
}

export { FieldModelBytes };

/**
 * Fast Binary Encoding string field model
 */
class FieldModelString extends FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelString}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelString}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStringOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStringOffset === 0) || ((this._buffer.offset + fbeStringOffset + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStringSize = this.readUInt32(fbeStringOffset)
    return 4 + fbeStringSize
  }

  /**
   * Check if the string value is valid
   * @this {!FieldModelString}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStringOffset = this.readUInt32(this.fbeOffset)
    if (fbeStringOffset === 0) {
      return true
    }

    if ((this._buffer.offset + fbeStringOffset + 4) > this._buffer.size) {
      return false
    }

    let fbeStringSize = this.readUInt32(fbeStringOffset)
    return (this._buffer.offset + fbeStringOffset + 4 + fbeStringSize) <= this._buffer.size
  }

  /**
   * Get the string value
   * @this {!FieldModelString}
   * @param {string=} defaults Default value, defaults is ''
   * @returns {!string} Result value
   */
  get (defaults = '') {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    let fbeStringOffset = this.readUInt32(this.fbeOffset)
    if (fbeStringOffset === 0) {
      return defaults
    }

    console.assert(((this._buffer.offset + fbeStringOffset + 4) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + fbeStringOffset + 4) > this._buffer.size) {
      return defaults
    }

    let fbeStringSize = this.readUInt32(fbeStringOffset)
    console.assert(((this._buffer.offset + fbeStringOffset + 4 + fbeStringSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + fbeStringOffset + 4 + fbeStringSize) > this._buffer.size) {
      return defaults
    }

    return this.readString(fbeStringOffset + 4, fbeStringSize)
  }

  /**
   * Set the string value
   * @this {!FieldModelString}
   * @param {!string} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let fbeStringSize = utf8count(value)
    let fbeStringOffset = this._buffer.allocate(4 + fbeStringSize) - this._buffer.offset
    console.assert(((fbeStringOffset > 0) && ((this._buffer.offset + fbeStringOffset + 4 + fbeStringSize) <= this._buffer.size)), 'Model is broken!')
    if ((fbeStringOffset <= 0) || ((this._buffer.offset + fbeStringOffset + 4 + fbeStringSize) > this._buffer.size)) {
      return
    }

    this.writeUInt32(this.fbeOffset, fbeStringOffset)
    this.writeUInt32(fbeStringOffset, fbeStringSize)
    this.writeString(fbeStringOffset + 4, value, fbeStringSize)
  }
}

export { FieldModelString };

/**
 * Fast Binary Encoding optional field model
 */
class FieldModelOptional extends FieldModel {
  /**
   * Initialize optional field model with the given value field model and buffer
   * @param {!FieldModel} model Value field model
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (model, buffer, offset) {
    super(buffer, offset)
    this._model = model
    this._model.fbeOffset = 0
  }

  /**
   * Get the field size
   * @this {!FieldModelOptional}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1 + 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOptional}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if (!this.hasValue) {
      return 0
    }

    let fbeOptionalOffset = this.readUInt32(this.fbeOffset + 1)
    if ((fbeOptionalOffset === 0) || ((this._buffer.offset + fbeOptionalOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeOptionalOffset)
    let fbeResult = this.value.fbeSize + this.value.fbeExtra
    this._buffer.unshift(fbeOptionalOffset)
    return fbeResult
  }

  /**
   * Checks if the object contains a value
   * @this {!FieldModelOptional}
   * @returns {!boolean} Optional has value flag
   */
  get hasValue () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return false
    }

    let fbeHasValue = this.readUInt8(this.fbeOffset)
    return fbeHasValue !== 0
  }

  /**
   * Get the base field model value
   * @this {!FieldModelOptional}
   * @returns {!FieldModel} Base field model value
   */
  get value () {
    return this._model
  }

  /**
   * Check if the optional value is valid
   * @this {!FieldModelOptional}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeHasValue = this.readUInt8(this.fbeOffset)
    if (fbeHasValue === 0) {
      return true
    }

    let fbeOptionalOffset = this.readUInt32(this.fbeOffset + 1)
    if (fbeOptionalOffset === 0) {
      return false
    }

    this._buffer.shift(fbeOptionalOffset)
    let fbeResult = this.value.verify()
    this._buffer.unshift(fbeOptionalOffset)
    return fbeResult
  }

  /**
   * Get the optional value (being phase)
   * @this {!FieldModelOptional}
   * @returns {!number} Optional begin offset
   */
  getBegin () {
    if (!this.hasValue) {
      return 0
    }

    let fbeOptionalOffset = this.readUInt32(this.fbeOffset + 1)
    console.assert((fbeOptionalOffset > 0), 'Model is broken!')
    if (fbeOptionalOffset <= 0) {
      return 0
    }

    this._buffer.shift(fbeOptionalOffset)
    return fbeOptionalOffset
  }

  /**
   * Get the optional value (end phase)
   * @this {!FieldModelOptional}
   * @param {!number} begin Optional begin offset
   */
  getEnd (begin) {
    this._buffer.unshift(begin)
  }

  /**
   * Get the optional value
   * @this {!FieldModelOptional}
   * @param {object=} defaults Default value, defaults is null
   * @returns {object} Result value
   */
  get (defaults = null) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return defaults
    }
    let optional = this.value.get()
    this.getEnd(fbeBegin)
    return optional
  }

  /**
   * Set the optional value (begin phase)
   * @this {!FieldModelOptional}
   * @param {boolean} hasValue Optional has value flag
   * @returns {!number} Optional begin offset
   */
  setBegin (hasValue) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    this.writeUInt8(this.fbeOffset, (hasValue ? 1 : 0))
    if (!hasValue) {
      this.writeUInt32(this.fbeOffset + 1, 0)
      return 0
    }

    let fbeOptionalSize = this.value.fbeSize
    let fbeOptionalOffset = this._buffer.allocate(fbeOptionalSize) - this._buffer.offset
    console.assert(((fbeOptionalOffset > 0) && ((this._buffer.offset + fbeOptionalOffset + fbeOptionalSize) <= this._buffer.size)), 'Model is broken!')
    if ((fbeOptionalOffset <= 0) || ((this._buffer.offset + fbeOptionalOffset + fbeOptionalSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset + 1, fbeOptionalOffset)

    this._buffer.shift(fbeOptionalOffset)
    return fbeOptionalOffset
  }

  /**
   * Set the optional value (end phase)
   * @this {!FieldModelOptional}
   * @param {!number} begin Optional begin offset
   */
  setEnd (begin) {
    this._buffer.unshift(begin)
  }

  /**
   * Set the optional value
   * @this {!FieldModelOptional}
   * @param {object} optional Optional value
   */
  set (optional) {
    let fbeBegin = this.setBegin(optional != null)
    if (fbeBegin === 0) {
      return
    }
    this.value.set(optional)
    this.setEnd(fbeBegin)
  }
}

export { FieldModelOptional };

/**
 * Fast Binary Encoding array field model
 */
class FieldModelArray extends FieldModel {
  /**
   * Initialize array field model with the given value field model, buffer and array size
   * @param {!FieldModel} model Value field model
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @constructor
   */
  constructor (model, buffer, offset, size) {
    super(buffer, offset)
    this._model = model
    this._size = size
  }

  /**
   * Get the field size
   * @this {!FieldModelArray}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return this._size * this._model.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelArray}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    return 0
  }

  /**
   * Get the array offset
   * @this {!FieldModelArray}
   * @returns {!number} Array offset
   */
  get offset () {
    return 0
  }

  /**
   * Get the array size
   * @this {!FieldModelArray}
   * @returns {!number} Array size
   */
  get size () {
    return this._size
  }

  /**
   * Array index operator
   * @this {!FieldModelArray}
   * @param {!number} index Array index
   * @returns {!FieldModel} Base field model value
   */
  getItem (index) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if (index >= this._size) {
      throw new Error('Index is out of bounds!')
    }

    this._model.fbeOffset = this.fbeOffset
    this._model.fbeShift(index * this._model.fbeSize)
    return this._model
  }

  /**
   * Check if the array is valid
   * @this {!FieldModelArray}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return false
    }

    this._model.fbeOffset = this.fbeOffset
    for (let i = 0; i < this._size; i++) {
      if (!this._model.verify()) {
        return false
      }
      this._model.fbeShift(this._model.fbeSize)
    }

    return true
  }

  /**
   * Get the array
   * @this {!FieldModelArray}
   * @param {Array=} values Array values, defaults is []
   * @returns {!Array} Result array
   */
  get (values = []) {
    values.length = 0

    let fbeModel = this.getItem(0)
    for (let i = 0; i < this._size; i++) {
      let value = fbeModel.get()
      values.push(value)
      fbeModel.fbeShift(fbeModel.fbeSize)
    }

    return values
  }

  /**
   * Set the array
   * @this {!FieldModelArray}
   * @param {!Array} values Array values
   */
  set (values) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let fbeModel = this.getItem(0)
    for (let i = 0; i < Math.min(values.length, this._size); i++) {
      fbeModel.set(values[i])
      fbeModel.fbeShift(fbeModel.fbeSize)
    }
  }
}

export { FieldModelArray };

/**
 * Fast Binary Encoding vector field model
 */
class FieldModelVector extends FieldModel {
  /**
   * Initialize vector field model with the given value field model and buffer
   * @param {!FieldModel} model Value field model
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (model, buffer, offset) {
    super(buffer, offset)
    this._model = model
  }

  /**
   * Get the field size
   * @this {!FieldModelVector}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelVector}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeVectorOffset = this.readUInt32(this.fbeOffset)
    if ((fbeVectorOffset === 0) || ((this._buffer.offset + fbeVectorOffset + 4) > this._buffer.size)) {
      return 0
    }

    let fbeVectorSize = this.readUInt32(fbeVectorOffset)

    let fbeResult = 4
    this._model.fbeOffset = fbeVectorOffset + 4
    for (let i = 0; i < fbeVectorSize; i++) {
      fbeResult += this._model.fbeSize + this._model.fbeExtra
      this._model.fbeShift(this._model.fbeSize)
    }
    return fbeResult
  }

  /**
   * Get the vector offset
   * @this {!FieldModelVector}
   * @returns {!number} Vector offset
   */
  get offset () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeVectorOffset = this.readUInt32(this.fbeOffset)
    return fbeVectorOffset
  }

  /**
   * Get the vector size
   * @this {!FieldModelVector}
   * @returns {!number} Vector size
   */
  get size () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeVectorOffset = this.readUInt32(this.fbeOffset)
    if ((fbeVectorOffset === 0) || ((this._buffer.offset + fbeVectorOffset + 4) > this._buffer.size)) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeVectorSize = this.readUInt32(fbeVectorOffset)
    return fbeVectorSize
  }

  /**
   * Vector index operator
   * @this {!FieldModelVector}
   * @param {!number} index Vector index
   * @returns {!FieldModel} Base field model value
   */
  getItem (index) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')

    let fbeVectorOffset = this.readUInt32(this.fbeOffset)
    console.assert(((fbeVectorOffset > 0) && ((this._buffer.offset + fbeVectorOffset + 4) <= this._buffer.size)), 'Model is broken!')

    let fbeVectorSize = this.readUInt32(fbeVectorOffset)
    if (index >= fbeVectorSize) {
      throw new Error('Index is out of bounds!')
    }

    this._model.fbeOffset = fbeVectorOffset + 4
    this._model.fbeShift(index * this._model.fbeSize)
    return this._model
  }

  /**
   * Resize the vector and get its first model
   * @this {!FieldModelVector}
   * @param {!number} size Size
   * @returns {!FieldModel} Base field model value
   */
  resize (size) {
    let fbeVectorSize = size * this._model.fbeSize
    let fbeVectorOffset = this._buffer.allocate(4 + fbeVectorSize) - this._buffer.offset
    console.assert(((fbeVectorOffset > 0) && ((this._buffer.offset + fbeVectorOffset + 4) <= this._buffer.size)), 'Model is broken!')

    this.writeUInt32(this.fbeOffset, fbeVectorOffset)
    this.writeUInt32(fbeVectorOffset, size)
    this.writeCount(fbeVectorOffset + 4, 0, fbeVectorSize)

    this._model.fbeOffset = fbeVectorOffset + 4
    return this._model
  }

  /**
   * Check if the vector is valid
   * @this {!FieldModelVector}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeVectorOffset = this.readUInt32(this.fbeOffset)
    if (fbeVectorOffset === 0) {
      return true
    }

    if ((this._buffer.offset + fbeVectorOffset + 4) > this._buffer.size) {
      return false
    }

    let fbeVectorSize = this.readUInt32(fbeVectorOffset)

    this._model.fbeOffset = fbeVectorOffset + 4
    for (let i = 0; i < fbeVectorSize; i++) {
      if (!this._model.verify()) {
        return false
      }
      this._model.fbeShift(this._model.fbeSize)
    }

    return true
  }

  /**
   * Get the vector
   * @this {!FieldModelVector}
   * @param {Array=} values Vector values, defaults is []
   * @returns {!Array} Result vector values
   */
  get (values = []) {
    values.length = 0

    let fbeVectorSize = this.size
    if (fbeVectorSize === 0) {
      return values
    }

    let fbeModel = this.getItem(0)
    for (let i = 0; i < fbeVectorSize; i++) {
      let value = fbeModel.get()
      values.push(value)
      fbeModel.fbeShift(fbeModel.fbeSize)
    }

    return values
  }

  /**
   * Set the vector
   * @this {!FieldModelVector}
   * @param {!Array} values Vector values
   */
  set (values) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let fbeModel = this.resize(values.length)
    for (let value of values) {
      fbeModel.set(value)
      fbeModel.fbeShift(fbeModel.fbeSize)
    }
  }
}

export { FieldModelVector };

/**
 * Fast Binary Encoding set field model
 */
class FieldModelSet extends FieldModel {
  /**
   * Initialize set field model with the given value field model and buffer
   * @param {!FieldModel} model Value field model
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (model, buffer, offset) {
    super(buffer, offset)
    this._model = model
  }

  /**
   * Get the field size
   * @this {!FieldModelSet}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSet}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeSetOffset = this.readUInt32(this.fbeOffset)
    if ((fbeSetOffset === 0) || ((this._buffer.offset + fbeSetOffset + 4) > this._buffer.size)) {
      return 0
    }

    let fbeSetSize = this.readUInt32(fbeSetOffset)

    let fbeResult = 4
    this._model.fbeOffset = fbeSetOffset + 4
    for (let i = 0; i < fbeSetSize; i++) {
      fbeResult += this._model.fbeSize + this._model.fbeExtra
      this._model.fbeShift(this._model.fbeSize)
    }
    return fbeResult
  }

  /**
   * Get the set offset
   * @this {!FieldModelSet}
   * @returns {!number} Set offset
   */
  get offset () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeSetOffset = this.readUInt32(this.fbeOffset)
    return fbeSetOffset
  }

  /**
   * Get the set size
   * @this {!FieldModelSet}
   * @returns {!number} Set size
   */
  get size () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeSetOffset = this.readUInt32(this.fbeOffset)
    if ((fbeSetOffset === 0) || ((this._buffer.offset + fbeSetOffset + 4) > this._buffer.size)) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeSetSize = this.readUInt32(fbeSetOffset)
    return fbeSetSize
  }

  /**
   * Set index operator
   * @this {!FieldModelSet}
   * @param {!number} index Set index
   * @returns {!FieldModel} Base field model value
   */
  getItem (index) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')

    let fbeSetOffset = this.readUInt32(this.fbeOffset)
    console.assert(((fbeSetOffset > 0) && ((this._buffer.offset + fbeSetOffset + 4) <= this._buffer.size)), 'Model is broken!')

    let fbeSetSize = this.readUInt32(fbeSetOffset)
    if (index >= fbeSetSize) {
      throw new Error('Index is out of bounds!')
    }

    this._model.fbeOffset = fbeSetOffset + 4
    this._model.fbeShift(index * this._model.fbeSize)
    return this._model
  }

  /**
   * Resize the set and get its first model
   * @this {!FieldModelSet}
   * @param {!number} size Size
   * @returns {!FieldModel} Base field model value
   */
  resize (size) {
    let fbeSetSize = size * this._model.fbeSize
    let fbeSetOffset = this._buffer.allocate(4 + fbeSetSize) - this._buffer.offset
    console.assert(((fbeSetOffset > 0) && ((this._buffer.offset + fbeSetOffset + 4) <= this._buffer.size)), 'Model is broken!')

    this.writeUInt32(this.fbeOffset, fbeSetOffset)
    this.writeUInt32(fbeSetOffset, size)
    this.writeCount(fbeSetOffset + 4, 0, fbeSetSize)

    this._model.fbeOffset = fbeSetOffset + 4
    return this._model
  }

  /**
   * Check if the set value is valid
   * @this {!FieldModelSet}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeSetOffset = this.readUInt32(this.fbeOffset)
    if (fbeSetOffset === 0) {
      return true
    }

    if ((this._buffer.offset + fbeSetOffset + 4) > this._buffer.size) {
      return false
    }

    let fbeSetSize = this.readUInt32(fbeSetOffset)

    this._model.fbeOffset = fbeSetOffset + 4
    for (let i = 0; i < fbeSetSize; i++) {
      if (!this._model.verify()) {
        return false
      }
      this._model.fbeShift(this._model.fbeSize)
    }

    return true
  }

  /**
   * Get the set value
   * @this {!FieldModelSet}
   * @param {Set=} values Set values, defaults is new Set()
   * @returns {!Set} Result set values
   */
  get (values = new Set()) {
    values.clear()

    let fbeSetSize = this.size
    if (fbeSetSize === 0) {
      return values
    }

    let fbeModel = this.getItem(0)
    for (let i = 0; i < fbeSetSize; i++) {
      let value = fbeModel.get()
      values.add(value)
      fbeModel.fbeShift(fbeModel.fbeSize)
    }

    return values
  }

  /**
   * Set the set value
   * @this {!FieldModelSet}
   * @param {!Set} values Set values
   */
  set (values) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let fbeModel = this.resize(values.size)
    for (let value of values) {
      fbeModel.set(value)
      fbeModel.fbeShift(fbeModel.fbeSize)
    }
  }
}

export { FieldModelSet };

/**
 * Fast Binary Encoding map field model
 */
class FieldModelMap extends FieldModel {
  /**
   * Initialize map field model with the given key/value field models and buffer
   * @param {!FieldModel} modelKey Key field model
   * @param {!FieldModel} modelValue Value field model
   * @param {!ReadBuffer|!WriteBuffer} buffer Read/Write buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (modelKey, modelValue, buffer, offset) {
    super(buffer, offset)
    this._modelKey = modelKey
    this._modelValue = modelValue
  }

  /**
   * Get the field size
   * @this {!FieldModelMap}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMap}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeMapOffset = this.readUInt32(this.fbeOffset)
    if ((fbeMapOffset === 0) || ((this._buffer.offset + fbeMapOffset + 4) > this._buffer.size)) {
      return 0
    }

    let fbeMapSize = this.readUInt32(fbeMapOffset)

    let fbeResult = 4
    this._modelKey.fbeOffset = fbeMapOffset + 4
    this._modelValue.fbeOffset = fbeMapOffset + 4 + this._modelKey.fbeSize
    for (let i = 0; i < fbeMapSize; i++) {
      fbeResult += this._modelKey.fbeSize + this._modelKey.fbeExtra
      this._modelKey.fbeShift(this._modelKey.fbeSize + this._modelValue.fbeSize)
      fbeResult += this._modelValue.fbeSize + this._modelValue.fbeExtra
      this._modelValue.fbeShift(this._modelKey.fbeSize + this._modelValue.fbeSize)
    }
    return fbeResult
  }

  /**
   * Get the map offset
   * @this {!FieldModelMap}
   * @returns {!number} Map offset
   */
  get offset () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeMapOffset = this.readUInt32(this.fbeOffset)
    return fbeMapOffset
  }

  /**
   * Get the map size
   * @this {!FieldModelMap}
   * @returns {!number} Map size
   */
  get size () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeMapOffset = this.readUInt32(this.fbeOffset)
    if ((fbeMapOffset === 0) || ((this._buffer.offset + fbeMapOffset + 4) > this._buffer.size)) {
      return 0
    }

    // noinspection UnnecessaryLocalVariableJS
    let fbeMapSize = this.readUInt32(fbeMapOffset)
    return fbeMapSize
  }

  /**
   * Map index operator
   * @this {!FieldModelMap}
   * @param {!number} index Map index
   * @returns {[!FieldModel]} Base field model value
   */
  getItem (index) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')

    let fbeMapOffset = this.readUInt32(this.fbeOffset)
    console.assert(((fbeMapOffset > 0) && ((this._buffer.offset + fbeMapOffset + 4) <= this._buffer.size)), 'Model is broken!')

    let fbeMapSize = this.readUInt32(fbeMapOffset)
    if (index >= fbeMapSize) {
      throw new Error('Index is out of bounds!')
    }

    this._modelKey.fbeOffset = fbeMapOffset + 4
    this._modelValue.fbeOffset = fbeMapOffset + 4 + this._modelKey.fbeSize
    this._modelKey.fbeShift(index * (this._modelKey.fbeSize + this._modelValue.fbeSize))
    this._modelValue.fbeShift(index * (this._modelKey.fbeSize + this._modelValue.fbeSize))
    return [this._modelKey, this._modelValue]
  }

  /**
   * Resize the map and get its first model
   * @this {!FieldModelMap}
   * @param {!number} size Size
   * @returns {[!FieldModel]} Base field model value
   */
  resize (size) {
    let fbeMapSize = size * (this._modelKey.fbeSize + this._modelValue.fbeSize)
    let fbeMapOffset = this._buffer.allocate(4 + fbeMapSize) - this._buffer.offset
    console.assert(((fbeMapOffset > 0) && ((this._buffer.offset + fbeMapOffset + 4) <= this._buffer.size)), 'Model is broken!')

    this.writeUInt32(this.fbeOffset, fbeMapOffset)
    this.writeUInt32(fbeMapOffset, size)
    this.writeCount(fbeMapOffset + 4, 0, fbeMapSize)

    this._modelKey.fbeOffset = fbeMapOffset + 4
    this._modelValue.fbeOffset = fbeMapOffset + 4 + this._modelKey.fbeSize
    return [this._modelKey, this._modelValue]
  }

  /**
   * Check if the map is valid
   * @this {!FieldModelMap}
   * @returns {!boolean} Field model valid state
   */
  verify () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeMapOffset = this.readUInt32(this.fbeOffset)
    if (fbeMapOffset === 0) {
      return true
    }

    if ((this._buffer.offset + fbeMapOffset + 4) > this._buffer.size) {
      return false
    }

    let fbeMapSize = this.readUInt32(fbeMapOffset)

    this._modelKey.fbeOffset = fbeMapOffset + 4
    this._modelValue.fbeOffset = fbeMapOffset + 4 + this._modelKey.fbeSize
    for (let i = 0; i < fbeMapSize; i++) {
      if (!this._modelKey.verify()) {
        return false
      }
      this._modelKey.fbeShift(this._modelKey.fbeSize + this._modelValue.fbeSize)
      if (!this._modelValue.verify()) {
        return false
      }
      this._modelValue.fbeShift(this._modelKey.fbeSize + this._modelValue.fbeSize)
    }

    return true
  }

  /**
   * Get the map
   * @this {!FieldModelMap}
   * @param {Map=} values Map values, defaults is new Map()
   * @returns {!Map} Result map values
   */
  get (values = new Map()) {
    values.clear()

    let fbeMapSize = this.size
    if (fbeMapSize === 0) {
      return values
    }

    let [fbeModelKey, fbeModelValue] = this.getItem(0)
    for (let i = 0; i < fbeMapSize; i++) {
      let key = fbeModelKey.get()
      let value = fbeModelValue.get()
      values.set(key, value)
      fbeModelKey.fbeShift(fbeModelKey.fbeSize + fbeModelValue.fbeSize)
      fbeModelValue.fbeShift(fbeModelKey.fbeSize + fbeModelValue.fbeSize)
    }

    return values
  }

  /**
   * Set the map
   * @this {!FieldModelMap}
   * @param {!Map} values Map values
   */
  set (values) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    let [fbeModelKey, fbeModelValue] = this.resize(values.size)
    for (let [key, value] of values) {
      fbeModelKey.set(key)
      fbeModelKey.fbeShift(fbeModelKey.fbeSize + fbeModelValue.fbeSize)
      fbeModelValue.set(value)
      fbeModelValue.fbeShift(fbeModelKey.fbeSize + fbeModelValue.fbeSize)
    }
  }
}

export { FieldModelMap };

/**
 * Fast Binary Encoding base sender
 */
class Sender {
  /**
   * Initialize sender with the given buffer and logging flag
   * @param {!WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @param {boolean=} final Final protocol flag, defaults is false
   * @constructor
   */
  constructor (buffer = new WriteBuffer(), final = false) {
    this._buffer = buffer
    this._logging = false
    this._final = final
  }

  /**
   * Get the bytes buffer
   * @this {!Sender}
   * @returns {!WriteBuffer} Bytes buffer
   */
  get buffer () {
    return this._buffer
  }

  /**
   * Get the final protocol flag
   * @this {!Sender}
   * @returns {!boolean} Final protocol flag
   */
  get final () {
    return this._final
  }

  /**
   * Get the logging flag
   * @this {!Sender}
   * @returns {!boolean} Logging flag
   */
  get logging () {
    return this._logging
  }

  /**
   * Set the logging flag
   * @this {!Sender}
   * @param {!boolean} logging Logging flag
   */
  set logging (logging) {
    this._logging = logging
  }

  /**
   * Reset the sender buffer
   * @this {!Sender}
   */
  reset () {
    this._buffer.reset()
  }

  /**
   * Send serialized buffer.
   * Direct call of the method requires knowledge about internals of FBE models serialization.
   * Use it with care!
   * @this {!Sender}
   * @param {!number} serialized Serialized bytes
   * @returns {!number} Sent bytes
   */
  sendSerialized (serialized) {
    console.assert((serialized > 0), 'Invalid size of the serialized buffer!')
    if (serialized <= 0) {
      return 0
    }

    // Shift the send buffer
    this._buffer.shift(serialized)

    // Send the value
    let sent = this.onSend(this._buffer.buffer, 0, this._buffer.size)
    this._buffer.remove(0, sent)
    return sent
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'Abstract method call!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Send log message handler
   * @this {!Sender}
   * @param {!string} message Log message
   */
  onSendLog (message) {}

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
  }
}

export { Sender };

/**
 * Fast Binary Encoding base receiver
 */
class Receiver {
  /**
   * Initialize receiver with the given buffer and logging flag
   * @param {!WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @param {boolean=} final Final protocol flag, defaults is false
   * @constructor
   */
  constructor (buffer = new WriteBuffer(), final = false) {
    this._buffer = buffer
    this._logging = false
    this._final = final
  }

  /**
   * Get the bytes buffer
   * @this {!Receiver}
   * @returns {!WriteBuffer} Bytes buffer
   */
  get buffer () {
    return this._buffer
  }

  /**
   * Get the final protocol flag
   * @this {!Sender}
   * @returns {!boolean} Final protocol flag
   */
  get final () {
    return this._final
  }

  /**
   * Get the logging flag
   * @this {!Receiver}
   * @returns {!boolean} Logging flag
   */
  get logging () {
    return this._logging
  }

  /**
   * Set the logging flag
   * @this {!Receiver}
   * @param {!boolean} logging Logging flag
   */
  set logging (logging) {
    this._logging = logging
  }

  /**
   * Reset the receiver buffer
   * @this {!Receiver}
   */
  reset () {
    this._buffer.reset()
  }

  /**
   * Receive data
   * @this {!Receiver}
   * @param {!Uint8Array|!ReadBuffer|!WriteBuffer} buffer Buffer
   * @param {number=} offset Buffer offset, defaulfs is 0
   * @param {number=} size Buffer size, defaulfs is undefined
   */
  receive (buffer, offset = 0, size = undefined) {
    if (size == null) {
      size = buffer.length
    }

    console.assert(((offset + size) <= buffer.length), 'Invalid offset & size!')
    if ((offset + size) > buffer.length) {
      throw new Error('Invalid offset & size!')
    }

    if (size === 0) {
      return
    }

    if ((buffer instanceof ReadBuffer) || (buffer instanceof WriteBuffer)) {
      buffer = buffer.buffer
    }

    // Storage buffer
    let offset0 = this._buffer.offset
    let offset1 = this._buffer.size
    let size1 = this._buffer.size

    // Receive buffer
    let offset2 = 0
    let size2 = size

    // While receive buffer is available to handle...
    while (offset2 < size2) {
      let messageBuffer = null
      let messageOffset = 0
      let messageSize = 0

      // Try to receive message size
      let messageSizeCopied = false
      let messageSizeFound = false
      while (!messageSizeFound) {
        // Look into the storage buffer
        if (offset0 < size1) {
          let count = Math.min(size1 - offset0, 4)
          if (count === 4) {
            messageSizeCopied = true
            messageSizeFound = true
            messageSize = Receiver.readUInt32(this._buffer.buffer, this._buffer.offset + offset0)
            offset0 += 4
            break
          } else {
            // Fill remaining data from the receive buffer
            if (offset2 < size2) {
              count = Math.min(size2 - offset2, 4 - count)

              // Allocate and refresh the storage buffer
              this._buffer.allocate(count)
              size1 += count

              for (let i = 0; i < count; i++) {
                this._buffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
              }
              offset1 += count
              offset2 += count
              continue
            } else {
              break
            }
          }
        }

        // Look into the receive buffer
        if (offset2 < size2) {
          let count = Math.min(size2 - offset2, 4)
          if (count === 4) {
            messageSizeFound = true
            messageSize = Receiver.readUInt32(buffer, offset + offset2)
            offset2 += 4
            break
          } else {
            // Allocate and refresh the storage buffer
            this._buffer.allocate(count)
            size1 += count

            for (let i = 0; i < count; i++) {
              this._buffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
            }
            offset1 += count
            offset2 += count
            // noinspection UnnecessaryContinueJS
            continue
          }
        } else {
          break
        }
      }

      if (!messageSizeFound) {
        return
      }

      // Check the message full size
      let minSize = this._final ? (4 + 4) : (4 + 4 + 4 + 4)
      console.assert((messageSize >= minSize), 'Invalid receive data!')
      if (messageSize < minSize) {
        return
      }

      // Try to receive message body
      let messageFound = false
      while (!messageFound) {
        // Look into the storage buffer
        if (offset0 < size1) {
          let count = Math.min(size1 - offset0, messageSize - 4)
          if (count === (messageSize - 4)) {
            messageFound = true
            messageBuffer = this._buffer.buffer
            messageOffset = offset0 - 4
            offset0 += messageSize - 4
            break
          } else {
            // Fill remaining data from the receive buffer
            if (offset2 < size2) {
              // Copy message size into the storage buffer
              if (!messageSizeCopied) {
                // Allocate and refresh the storage buffer
                this._buffer.allocate(4)
                size1 += 4

                Receiver.writeUInt32(this._buffer.buffer, this._buffer.offset + offset0, messageSize)
                offset0 += 4
                offset1 += 4

                messageSizeCopied = true
              }

              count = Math.min(size2 - offset2, messageSize - 4 - count)

              // Allocate and refresh the storage buffer
              this._buffer.allocate(count)
              size1 += count

              for (let i = 0; i < count; i++) {
                this._buffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
              }
              offset1 += count
              offset2 += count
              continue
            } else {
              break
            }
          }
        }

        // Look into the receive buffer
        if (offset2 < size2) {
          let count = Math.min(size2 - offset2, messageSize - 4)
          if (!messageSizeCopied && (count === (messageSize - 4))) {
            messageFound = true
            messageBuffer = buffer
            messageOffset = offset + offset2 - 4
            offset2 += messageSize - 4
            break
          } else {
            // Copy message size into the storage buffer
            if (!messageSizeCopied) {
              // Allocate and refresh the storage buffer
              this._buffer.allocate(4)
              size1 += 4

              Receiver.writeUInt32(this._buffer.buffer, this._buffer.offset + offset0, messageSize)
              offset0 += 4
              offset1 += 4

              messageSizeCopied = true
            }

            // Allocate and refresh the storage buffer
            this._buffer.allocate(count)
            size1 += count

            for (let i = 0; i < count; i++) {
              this._buffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
            }
            offset1 += count
            offset2 += count
            // noinspection UnnecessaryContinueJS
            continue
          }
        } else {
          break
        }
      }

      if (!messageFound) {
        // Copy message size into the storage buffer
        if (!messageSizeCopied) {
          // Allocate and refresh the storage buffer
          this._buffer.allocate(4)
          size1 += 4

          Receiver.writeUInt32(this._buffer.buffer, this._buffer.offset + offset0, messageSize)
          offset0 += 4
          offset1 += 4

          messageSizeCopied = true
        }
        return
      }

      // noinspection JSUnusedLocalSymbols
      let fbeStructSize // eslint-disable-line
      let fbeStructType

      // Read the message parameters
      if (this._final) {
        fbeStructSize = Receiver.readUInt32(messageBuffer, messageOffset)
        fbeStructType = Receiver.readUInt32(messageBuffer, messageOffset + 4)
      } else {
        let fbeStructOffset = Receiver.readUInt32(messageBuffer, messageOffset + 4)
        // noinspection JSUnusedLocalSymbols
        fbeStructSize = Receiver.readUInt32(messageBuffer, messageOffset + fbeStructOffset) // eslint-disable-line
        fbeStructType = Receiver.readUInt32(messageBuffer, messageOffset + fbeStructOffset + 4)
      }

      // Handle the message
      this.onReceive(fbeStructType, messageBuffer, messageOffset, messageSize)

      // Reset the storage buffer
      this._buffer.reset()

      // Refresh the storage buffer
      offset0 = this._buffer.offset
      offset1 = this._buffer.size
      size1 = this._buffer.size
    }
  }

  /**
   * Receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    console.assert(true, 'Abstract method call!')
    debugger // eslint-disable-line
    return false
  }

  /**
   * Receive log message handler
   * @this {!Receiver}
   * @param {!string} message Log message
   */
  onReceiveLog (message) {}

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
  }

  // Buffer I/O methods

  /**
   * Check the buffer offset bounds
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} size Size
   */
  static checkOffset (buffer, offset, size) {
    if (((offset % 1) !== 0) || (offset < 0)) {
      throw new RangeError('Invalid offset!')
    }
    if ((offset + size) > buffer.length) {
      throw new RangeError('Out of bounds!')
    }
  }

  /**
   * Check the value range and its buffer offset bounds
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @param {!number} value Value
   * @param {!number} min Min value
   * @param {!number} max Max value
   */
  static checkValue (buffer, offset, size, value, min, max) {
    this.checkOffset(buffer, offset, size)
    if ((value < min) || (value > max)) {
      throw new RangeError('Value is out of bounds!')
    }
  }

  /**
   * Read UInt32 value from the model buffer
   * @this {!Model}
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @returns {!number} UInt32 value
   */
  static readUInt32 (buffer, offset) {
    offset = offset >>> 0
    Receiver.checkOffset(buffer, offset, 4)
    return (
      (buffer[offset + 0] << 0) |
      (buffer[offset + 1] << 8) |
      (buffer[offset + 2] << 16)) +
      (buffer[offset + 3] * 0x1000000)
  }

  /**
   * Write UInt32 value into the model buffer
   * @this {!Model}
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} value Value
   */
  static writeUInt32 (buffer, offset, value) {
    value = +value
    Receiver.checkValue(buffer, offset, 4, value, 0, 0xFFFFFFFF)
    buffer[offset + 3] = (value >>> 24)
    buffer[offset + 2] = (value >>> 16)
    buffer[offset + 1] = (value >>> 8)
    buffer[offset + 0] = (value & 0xFF)
  }
}

export { Receiver };

/**
 * Fast Binary Encoding base client
 */
class Client {
  /**
   * Initialize client with the given buffers and logging flag
   * @param {!WriteBuffer} sendBuffer Send buffer, defaults is new WriteBuffer()
   * @param {!WriteBuffer} receiveBuffer Receive buffer, defaults is new WriteBuffer()
   * @param {boolean=} final Final protocol flag, defaults is false
   * @constructor
   */
  constructor (sendBuffer = new WriteBuffer(), receiveBuffer = new WriteBuffer(), final = false) {
    this._sendBuffer = sendBuffer
    this._receiveBuffer = receiveBuffer
    this._logging = false
    this._final = final
  }

  /**
   * Get the send bytes buffer
   * @this {!Sender}
   * @returns {!WriteBuffer} Send bytes buffer
   */
  get sendBuffer () {
    return this._sendBuffer
  }

  /**
   * Get the receive bytes buffer
   * @this {!Sender}
   * @returns {!WriteBuffer} Receive bytes buffer
   */
  get receiveBuffer () {
    return this._receiveBuffer
  }

  /**
   * Get the final protocol flag
   * @this {!Sender}
   * @returns {!boolean} Final protocol flag
   */
  get final () {
    return this._final
  }

  /**
   * Get the logging flag
   * @this {!Sender}
   * @returns {!boolean} Logging flag
   */
  get logging () {
    return this._logging
  }

  /**
   * Set the logging flag
   * @this {!Sender}
   * @param {!boolean} logging Logging flag
   */
  set logging (logging) {
    this._logging = logging
  }

  /**
   * Reset the sender and receive buffers
   * @this {!Sender}
   */
  reset () {
    this._sendBuffer.reset()
    this._receiveBuffer.reset()
  }

  /**
   * Send serialized buffer.
   * Direct call of the method requires knowledge about internals of FBE models serialization.
   * Use it with care!
   * @this {!Sender}
   * @param {!number} serialized Serialized bytes
   * @returns {!number} Sent bytes
   */
  sendSerialized (serialized) {
    console.assert((serialized > 0), 'Invalid size of the serialized buffer!')
    if (serialized <= 0) {
      return 0
    }

    // Shift the send buffer
    this._sendBuffer.shift(serialized)

    // Send the value
    let sent = this.onSend(this._sendBuffer.buffer, 0, this._sendBuffer.size)
    this._sendBuffer.remove(0, sent)
    return sent
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'Abstract method call!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Send log message handler
   * @this {!Sender}
   * @param {!string} message Log message
   */
  onSendLog (message) {}

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
  }

  /**
   * Receive data
   * @this {!Receiver}
   * @param {!Uint8Array|!ReadBuffer|!WriteBuffer} buffer Buffer
   * @param {number=} offset Buffer offset, defaulfs is 0
   * @param {number=} size Buffer size, defaulfs is undefined
   */
  receive (buffer, offset = 0, size = undefined) {
    if (size == null) {
      size = buffer.length
    }

    console.assert(((offset + size) <= buffer.length), 'Invalid offset & size!')
    if ((offset + size) > buffer.length) {
      throw new Error('Invalid offset & size!')
    }

    if (size === 0) {
      return
    }

    if ((buffer instanceof ReadBuffer) || (buffer instanceof WriteBuffer)) {
      buffer = buffer.buffer
    }

    // Storage buffer
    let offset0 = this._receiveBuffer.offset
    let offset1 = this._receiveBuffer.size
    let size1 = this._receiveBuffer.size

    // Receive buffer
    let offset2 = 0
    let size2 = size

    // While receive buffer is available to handle...
    while (offset2 < size2) {
      let messageBuffer = null
      let messageOffset = 0
      let messageSize = 0

      // Try to receive message size
      let messageSizeCopied = false
      let messageSizeFound = false
      while (!messageSizeFound) {
        // Look into the storage buffer
        if (offset0 < size1) {
          let count = Math.min(size1 - offset0, 4)
          if (count === 4) {
            messageSizeCopied = true
            messageSizeFound = true
            messageSize = Receiver.readUInt32(this._receiveBuffer.buffer, this._receiveBuffer.offset + offset0)
            offset0 += 4
            break
          } else {
            // Fill remaining data from the receive buffer
            if (offset2 < size2) {
              count = Math.min(size2 - offset2, 4 - count)

              // Allocate and refresh the storage buffer
              this._receiveBuffer.allocate(count)
              size1 += count

              for (let i = 0; i < count; i++) {
                this._receiveBuffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
              }
              offset1 += count
              offset2 += count
              continue
            } else {
              break
            }
          }
        }

        // Look into the receive buffer
        if (offset2 < size2) {
          let count = Math.min(size2 - offset2, 4)
          if (count === 4) {
            messageSizeFound = true
            messageSize = Receiver.readUInt32(buffer, offset + offset2)
            offset2 += 4
            break
          } else {
            // Allocate and refresh the storage buffer
            this._receiveBuffer.allocate(count)
            size1 += count

            for (let i = 0; i < count; i++) {
              this._receiveBuffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
            }
            offset1 += count
            offset2 += count
            // noinspection UnnecessaryContinueJS
            continue
          }
        } else {
          break
        }
      }

      if (!messageSizeFound) {
        return
      }

      // Check the message full size
      let minSize = this._final ? (4 + 4) : (4 + 4 + 4 + 4)
      console.assert((messageSize >= minSize), 'Invalid receive data!')
      if (messageSize < minSize) {
        return
      }

      // Try to receive message body
      let messageFound = false
      while (!messageFound) {
        // Look into the storage buffer
        if (offset0 < size1) {
          let count = Math.min(size1 - offset0, messageSize - 4)
          if (count === (messageSize - 4)) {
            messageFound = true
            messageBuffer = this._receiveBuffer.buffer
            messageOffset = offset0 - 4
            offset0 += messageSize - 4
            break
          } else {
            // Fill remaining data from the receive buffer
            if (offset2 < size2) {
              // Copy message size into the storage buffer
              if (!messageSizeCopied) {
                // Allocate and refresh the storage buffer
                this._receiveBuffer.allocate(4)
                size1 += 4

                Receiver.writeUInt32(this._receiveBuffer.buffer, this._receiveBuffer.offset + offset0, messageSize)
                offset0 += 4
                offset1 += 4

                messageSizeCopied = true
              }

              count = Math.min(size2 - offset2, messageSize - 4 - count)

              // Allocate and refresh the storage buffer
              this._receiveBuffer.allocate(count)
              size1 += count

              for (let i = 0; i < count; i++) {
                this._receiveBuffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
              }
              offset1 += count
              offset2 += count
              continue
            } else {
              break
            }
          }
        }

        // Look into the receive buffer
        if (offset2 < size2) {
          let count = Math.min(size2 - offset2, messageSize - 4)
          if (!messageSizeCopied && (count === (messageSize - 4))) {
            messageFound = true
            messageBuffer = buffer
            messageOffset = offset + offset2 - 4
            offset2 += messageSize - 4
            break
          } else {
            // Copy message size into the storage buffer
            if (!messageSizeCopied) {
              // Allocate and refresh the storage buffer
              this._receiveBuffer.allocate(4)
              size1 += 4

              Receiver.writeUInt32(this._receiveBuffer.buffer, this._receiveBuffer.offset + offset0, messageSize)
              offset0 += 4
              offset1 += 4

              messageSizeCopied = true
            }

            // Allocate and refresh the storage buffer
            this._receiveBuffer.allocate(count)
            size1 += count

            for (let i = 0; i < count; i++) {
              this._receiveBuffer.buffer[offset1 + i] = buffer[offset + offset2 + i]
            }
            offset1 += count
            offset2 += count
            // noinspection UnnecessaryContinueJS
            continue
          }
        } else {
          break
        }
      }

      if (!messageFound) {
        // Copy message size into the storage buffer
        if (!messageSizeCopied) {
          // Allocate and refresh the storage buffer
          this._receiveBuffer.allocate(4)
          size1 += 4

          Receiver.writeUInt32(this._receiveBuffer.buffer, this._receiveBuffer.offset + offset0, messageSize)
          offset0 += 4
          offset1 += 4

          messageSizeCopied = true
        }
        return
      }

      // noinspection JSUnusedLocalSymbols
      let fbeStructSize // eslint-disable-line
      let fbeStructType

      // Read the message parameters
      if (this._final) {
        fbeStructSize = Receiver.readUInt32(messageBuffer, messageOffset)
        fbeStructType = Receiver.readUInt32(messageBuffer, messageOffset + 4)
      } else {
        let fbeStructOffset = Receiver.readUInt32(messageBuffer, messageOffset + 4)
        // noinspection JSUnusedLocalSymbols
        fbeStructSize = Receiver.readUInt32(messageBuffer, messageOffset + fbeStructOffset) // eslint-disable-line
        fbeStructType = Receiver.readUInt32(messageBuffer, messageOffset + fbeStructOffset + 4)
      }

      // Handle the message
      this.onReceive(fbeStructType, messageBuffer, messageOffset, messageSize)

      // Reset the storage buffer
      this._receiveBuffer.reset()

      // Refresh the storage buffer
      offset0 = this._receiveBuffer.offset
      offset1 = this._receiveBuffer.size
      size1 = this._receiveBuffer.size
    }
  }

  /**
   * Receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    console.assert(true, 'Abstract method call!')
    debugger // eslint-disable-line
    return false
  }

  /**
   * Receive log message handler
   * @this {!Receiver}
   * @param {!string} message Log message
   */
  onReceiveLog (message) {}

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
  }

  // Buffer I/O methods

  /**
   * Check the buffer offset bounds
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} size Size
   */
  static checkOffset (buffer, offset, size) {
    if (((offset % 1) !== 0) || (offset < 0)) {
      throw new RangeError('Invalid offset!')
    }
    if ((offset + size) > buffer.length) {
      throw new RangeError('Out of bounds!')
    }
  }

  /**
   * Check the value range and its buffer offset bounds
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} size Size
   * @param {!number} value Value
   * @param {!number} min Min value
   * @param {!number} max Max value
   */
  static checkValue (buffer, offset, size, value, min, max) {
    this.checkOffset(buffer, offset, size)
    if ((value < min) || (value > max)) {
      throw new RangeError('Value is out of bounds!')
    }
  }

  /**
   * Read UInt32 value from the model buffer
   * @this {!Model}
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @returns {!number} UInt32 value
   */
  static readUInt32 (buffer, offset) {
    offset = offset >>> 0
    Receiver.checkOffset(buffer, offset, 4)
    return (
      (buffer[offset + 0] << 0) |
      (buffer[offset + 1] << 8) |
      (buffer[offset + 2] << 16)) +
      (buffer[offset + 3] * 0x1000000)
  }

  /**
   * Write UInt32 value into the model buffer
   * @this {!Model}
   * @param {!Uint8Array} buffer Buffer
   * @param {!number} offset Offset
   * @param {!number} value Value
   */
  static writeUInt32 (buffer, offset, value) {
    value = +value
    Receiver.checkValue(buffer, offset, 4, value, 0, 0xFFFFFFFF)
    buffer[offset + 3] = (value >>> 24)
    buffer[offset + 2] = (value >>> 16)
    buffer[offset + 1] = (value >>> 8)
    buffer[offset + 0] = (value & 0xFF)
  }
}

export { Client };

/**
 * Converts Map instance to object datatype
 * @param {!Map} map Map to convert
 * @returns {!object} Object
 */
let MapToObject = function (map) {
  let obj = {}
  map.forEach((value, key) => { obj[key] = value })
  return obj
}

export { MapToObject };

/**
 * Convert object instance to Map datatype
 * @param {!object} obj Object to convert
 * @returns {!Map} Map
 */
let ObjectToMap = function (obj) {
  let map = new Map()
  Object.keys(obj).forEach(key => { map.set(key, obj[key]) })
  return map
}

export { ObjectToMap };
