import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { DataFeed, objectParametersToMap, parameretsMapToObject } from '@models/DataFeeds';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { DataFeedModule } from '@api/fbe/core/DataFeedModule';
import { DataFeedSubscription } from '@api/fbe/core/DataFeedSubscription';
import { DataFeedModulesGetRequest } from '@api/fbe/feed/DataFeedModulesGetRequest';
import { DataFeedsGetRequest } from '@api/fbe/manager/DataFeedsGetRequest';
import { DataFeedCreateRequest } from '@api/fbe/manager/DataFeedCreateRequest';
import { DataFeedDeleteRequest } from '@api/fbe/manager/DataFeedDeleteRequest';
import { DataFeedModifyRequest } from '@api/fbe/manager/DataFeedModifyRequest';

export class DataFeedsStore {
    errorTracker = new ErrorsTracker({ title: 'DataFeedsStore' });

    constructor() {
        makeAutoObservable(this);
    }

    data: DataFeed[] = [];

    modules: DataFeedModule[] = [];

    isLoading: boolean = false;

    setData(value: DataFeed[]) {
        this.data = value;
    }

    setModules(value: DataFeedModule[]) {
        this.modules = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async getModules() {
        const request = new DataFeedModulesGetRequest();
        const modules = await SocketClient.instance.request(request, this.errorTracker);
        this.setModules(modules.DataFeedModule);
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new DataFeedsGetRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.DataFeeds.map(
                (record): DataFeed => ({
                    id: record.id.toString(),
                    ModuleName: record.ModuleName,
                    Name: record.Name,
                    Host: record.Host ?? null,
                    Port: record.Port ?? null,
                    IsEnabled: record.IsEnabled,
                    DataFeedStatus: +record.DataFeedStatus.valueOf(),
                    MarketDepth: record.Subscriptions.length ? record.Subscriptions[0].MarketDepth : null,
                    Subscriptions: record.Subscriptions.map((subscription) => ({
                        SymbolPattern: subscription.SymbolPattern,
                        MarketDepth: subscription.MarketDepth,
                        SortOrder: subscription.SortOrder,
                    })),
                    SymbolMapId: record.SymbolMapId?.toString() || null,
                    Parameters: parameretsMapToObject(record.Parameters),
                    IsRemote: record.IsRemote,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(record: DataFeed) {
        const request = new DataFeedCreateRequest();
        if (record.ModuleName) request.ModuleName = record.ModuleName;
        if (record.Name) request.Name = record.Name;
        request.Host = record.Host;
        request.Port = record.Port;
        request.IsEnabled = record.IsEnabled;
        request.IsRemote = record.IsRemote;
        if (record.SymbolMapId) request.SymbolMapId = UUID.fromString(record.SymbolMapId);
        request.Subscriptions = record.Subscriptions.map(
            (item) =>
                new DataFeedSubscription(
                    item.SymbolPattern ?? undefined,
                    item.MarketDepth ?? undefined,
                    item.SortOrder ?? undefined,
                ),
        );
        request.Parameters = objectParametersToMap(record.Parameters || {});
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new DataFeedDeleteRequest();
        request.DataFeedId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: DataFeed, dirtyFields: FieldNamesMarkedBoolean<FieldValues>) {
        if (record.id) {
            const request = new DataFeedModifyRequest();
            request.DataFeedId = UUID.fromString(record.id);
            if (dirtyFields.ModuleName) request.NewModuleName = record.ModuleName;
            if (dirtyFields.Name) request.NewName = record.Name;
            if (dirtyFields.Host) request.NewHost = record.Host;
            if (record.Host === null) request.NewHostReset = true;
            if (dirtyFields.Port) request.NewPort = record.Port;
            if (record.Port === null) request.NewPortReset = true;
            if (dirtyFields.IsEnabled) request.NewIsEnabled = record.IsEnabled;
            if (dirtyFields.IsRemote) request.NewIsRemote = record.IsRemote;
            if (dirtyFields.SymbolMapId && record.SymbolMapId)
                request.NewSymbolMapId = UUID.fromString(record.SymbolMapId);
            if (!record.SymbolMapId) request.NewSymbolMapIdReset = true;
            if (dirtyFields.Subscriptions) {
                request.NewSubscriptions = record.Subscriptions.map(
                    (item) =>
                        new DataFeedSubscription(
                            item.SymbolPattern ?? undefined,
                            item.MarketDepth ?? undefined,
                            item.SortOrder ?? undefined,
                        ),
                );
                if (!request.NewSubscriptions.length) request.SubscriptionsReset = true;
            }
            request.ParametersToModify = objectParametersToMap(record.Parameters || {});
            await SocketClient.instance.request(request, this.errorTracker);
            await this.get();
        }
    }

    reset() {
        this.data = [];
        this.isLoading = false;
        this.modules = [];
    }
}

export const dataFeedsStore = new DataFeedsStore();
