import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { MailServer } from '@models/MailServer';
import { SocketClient } from '@api/SocketClient';
import { adaptFromApi, adaptToApiCreate, adaptToApiModify } from './utils';
import { UUID } from '@api/fbe/uuid';
import { MailServersGetAllRequest } from '@api/fbe/manager/MailServersGetAllRequest';
import { MailServerCreateRequest } from '@api/fbe/manager/MailServerCreateRequest';
import { MailServerModifyRequest } from '@api/fbe/manager/MailServerModifyRequest';
import { MailServerDeleteRequest } from '@api/fbe/manager/MailServerDeleteRequest';

export class MailStore {
    errorTracker = new ErrorsTracker({ title: 'Mails' });

    constructor() {
        makeAutoObservable(this);
    }

    data: MailServer[] = [];

    isLoading: boolean = false;

    setData(value: MailServer[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new MailServersGetAllRequest();

        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.MailServers.map((mailServer) => adaptFromApi(mailServer)));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(mailServer: MailServer) {
        const request = adaptToApiCreate(new MailServerCreateRequest(), mailServer);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new MailServerDeleteRequest();
        request.MailServerId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(mailServer: MailServer) {
        const request = adaptToApiModify(new MailServerModifyRequest(), mailServer);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const mailStore = new MailStore();
export { mailStore };
