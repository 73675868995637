import Basil from 'basil.js';

const storage = new Basil({
    namespace: 'Storage',
    storages: ['cookie', 'local'],
    storage: 'cookie',
    expireDays: 1,
});

const saveToCookie = (key, value) => storage.set(key, value, { storages: ['cookie'] });
const saveToCookies = (key, array) => {
    const obj = {};

    array.forEach((item) => {
        obj[item.key] = item.value;
    });

    storage.set(key, obj, { storages: ['cookie'] });
};
const getFromCookie = (key) => storage.get(key, { storages: ['cookie'] });
const removeFromCookie = (key) => storage.remove(key, { storages: ['cookie'] });

const saveToLocal = (key, value) => storage.set(key, value, { storages: ['local'] });
const getFromLocal = (key) => storage.get(key, { storages: ['local'] });
const removeFromLocal = (key) => storage.remove(key, { storages: ['local'] });

export default {
    saveToCookie,
    saveToCookies,
    getFromCookie,
    removeFromCookie,
    saveToLocal,
    getFromLocal,
    removeFromLocal,
};
