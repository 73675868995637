
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {OrdersClosedSummary} from './OrdersClosedSummary';
import {FieldModelOrdersClosedSummary} from './OrdersClosedSummary';

/**
 * OrdersClosedSummaries struct
 */
class OrdersClosedSummaries {
  /**
   * Initialize struct
   * @param {!Array=} summariesByCurrency
   * @param {!OrdersClosedSummary=} total
   * @constructor
   */
  constructor (summariesByCurrency = [], total = new OrdersClosedSummary()) {
    this.SummariesByCurrency = summariesByCurrency
    this.Total = total
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrdersClosedSummaries}
   * @param {!OrdersClosedSummaries} other Other struct
   * @returns {!OrdersClosedSummaries} This struct
   */
  copy (other) {
    if (other.SummariesByCurrency != null) {
      this.SummariesByCurrency = []
      for (let item of other.SummariesByCurrency) {
        if (item != null) {
          let tempItem
          tempItem = OrdersClosedSummary.fromObject(item)
          this.SummariesByCurrency.push(tempItem)
        } else {
          this.SummariesByCurrency.push(null)
        }
      }
    } else {
      this.SummariesByCurrency = null
    }
    if (other.Total != null) {
      this.Total = OrdersClosedSummary.fromObject(other.Total)
    } else {
      this.Total = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrdersClosedSummaries}
   * @returns {!OrdersClosedSummaries} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrdersClosedSummariesModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrdersClosedSummariesModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrdersClosedSummaries}
   * @param {!OrdersClosedSummaries} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrdersClosedSummaries)) {
      throw new TypeError('Instance of OrdersClosedSummaries is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrdersClosedSummaries}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SummariesByCurrency: ((this.SummariesByCurrency != null) ? Array.from(this.SummariesByCurrency, item => ((item != null) ? item : null)) : null),
      Total: ((this.Total != null) ? this.Total : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrdersClosedSummaries.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrdersClosedSummaries} other Object value
   * @returns {!OrdersClosedSummaries} Created struct
   */
  static fromObject (other) {
    return new OrdersClosedSummaries().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrdersClosedSummaries}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrdersClosedSummaries.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrdersClosedSummaries}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 190
  }
}

export { OrdersClosedSummaries };

/**
 * Fast Binary Encoding OrdersClosedSummaries field model
 */
class FieldModelOrdersClosedSummaries extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SummariesByCurrency = new fbe.FieldModelVector(new FieldModelOrdersClosedSummary(buffer, 4 + 4), buffer, 4 + 4)
    this.Total = new FieldModelOrdersClosedSummary(buffer, this.SummariesByCurrency.fbeOffset + this.SummariesByCurrency.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SummariesByCurrency.fbeSize + this.Total.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SummariesByCurrency.fbeExtra + this.Total.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrdersClosedSummaries.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 190
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SummariesByCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SummariesByCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SummariesByCurrency.fbeSize

    if ((fbeCurrentSize + this.Total.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Total.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Total.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!OrdersClosedSummaries} fbeValue Default value, defaults is new OrdersClosedSummaries()
   * @returns {!OrdersClosedSummaries} OrdersClosedSummaries value
   */
  get (fbeValue = new OrdersClosedSummaries()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!OrdersClosedSummaries} fbeValue OrdersClosedSummaries value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SummariesByCurrency.fbeSize) <= fbeStructSize) {
      this.SummariesByCurrency.get(fbeValue.SummariesByCurrency)
    } else {
      fbeValue.SummariesByCurrency.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SummariesByCurrency.fbeSize

    if ((fbeCurrentSize + this.Total.fbeSize) <= fbeStructSize) {
      fbeValue.Total = this.Total.get()
    } else {
      fbeValue.Total = new OrdersClosedSummary()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Total.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrdersClosedSummaries}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {!OrdersClosedSummaries} fbeValue OrdersClosedSummaries value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrdersClosedSummaries}
   * @param {OrdersClosedSummaries} fbeValue OrdersClosedSummaries value
   */
  setFields (fbeValue) {
    this.SummariesByCurrency.set(fbeValue.SummariesByCurrency)
    this.Total.set(fbeValue.Total)
  }
}

export { FieldModelOrdersClosedSummaries };

/**
 * Fast Binary Encoding OrdersClosedSummaries model
 */
class OrdersClosedSummariesModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrdersClosedSummaries(this.buffer, 4)
  }

  /**
   * Get the OrdersClosedSummaries model
   * @this {!OrdersClosedSummariesModel}
   * @returns {!FieldModelOrdersClosedSummaries} model OrdersClosedSummaries model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrdersClosedSummariesModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrdersClosedSummariesModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrdersClosedSummariesModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrdersClosedSummariesModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrdersClosedSummaries.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrdersClosedSummariesModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrdersClosedSummariesModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrdersClosedSummariesModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrdersClosedSummariesModel}
   * @param {!OrdersClosedSummaries} value OrdersClosedSummaries value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrdersClosedSummariesModel}
   * @param {!OrdersClosedSummaries} value OrdersClosedSummaries value, defaults is new OrdersClosedSummaries()
   * @return {!object} Deserialized OrdersClosedSummaries value and its size
   */
  deserialize (value = new OrdersClosedSummaries()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrdersClosedSummaries(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrdersClosedSummaries(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrdersClosedSummariesModel}
   * @param {!number} prev Previous OrdersClosedSummaries model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrdersClosedSummariesModel };
