import { TradeHistoryRecord as TradeHistoryRecordApi } from '@api/fbe/core/TradeHistoryRecord';
import { TradeTransactionType } from '@api/fbe/core/TradeTransactionType';

export type BalanceTransactionsRequestParams = {
    Login: string | null;
    RequestFrom: Date | null;
    RequestTo: Date | null;
};

export type BalanceTransactionsRequestParamsWithTransactionType = BalanceTransactionsRequestParams & {
    TransactionTypes: Set<TradeTransactionType>;
};

export type BalanceTransaction = {
    key?: number;
    RecordId: number | null;
    AccountLogin: number | null;
    TransactionTimestamp: Date | null;
    ManagerComment: string | null;
    BalanceCurrencyName: string | null;
    BalanceMovement: number | null;
    SrcAssetMovement: number | null;
    DstAssetMovement: number | null;
    OrderId: number | null;
    Profit: number | null;
    AccountName?: string;
};

export const getCustomProfit = (record: TradeHistoryRecordApi): number | null => {
    let customProfit = record.Profit ?? null;
    if (
        (record.TransactionType.eq(TradeTransactionType.DEPOSIT) ||
            record.TransactionType.eq(TradeTransactionType.CREDIT)) &&
        typeof record.BalanceMovement === 'number'
    ) {
        customProfit = record.BalanceMovement ?? null;
    }
    if (record.TransactionType.eq(TradeTransactionType.TRANSFER) && typeof record.BalanceMovement === 'number') {
        customProfit = record.SrcAssetMovement ?? record.DstAssetMovement ?? null;
    }
    return customProfit;
};

export const convertTradeRecordToBalanceTransactionRecord = (record: TradeHistoryRecordApi): BalanceTransaction => ({
    RecordId: +record.RecordId.toNumber(),
    AccountLogin: record.AccountLogin ? +record.AccountLogin.toNumber() : null,
    TransactionTimestamp: record.TransactionTimestamp,
    ManagerComment: record.ManagerComment ?? '',
    BalanceCurrencyName: record.BalanceCurrencyName ?? '',
    BalanceMovement: record.BalanceMovement ?? null,
    SrcAssetMovement: record.SrcAssetMovement ?? null,
    DstAssetMovement: record.DstAssetMovement ?? null,
    OrderId: record.OrderId ? +record.OrderId.toNumber() : null,
    Profit: getCustomProfit(record),
});
