
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {UpdateType} from './UpdateType';
import {FieldModelUpdateType} from './UpdateType';
import {QuoteType} from './QuoteType';
import {FieldModelQuoteType} from './QuoteType';
import {Quote} from './Quote';
import {FieldModelQuote} from './Quote';

/**
 * Level2ManagerUpdate struct
 */
class Level2ManagerUpdate {
  /**
   * Initialize struct
   * @param {!string=} symbolName
   * @param {!UpdateType=} updateType
   * @param {!QuoteType=} quoteType
   * @param {Quote=} quote
   * @param {!number=} level
   * @param {!number=} price
   * @constructor
   */
  constructor (symbolName = '', updateType = new UpdateType(), quoteType = new QuoteType(), quote = null, level = 0, price = 0.0) {
    this.SymbolName = symbolName
    this.UpdateType = updateType
    this.QuoteType = quoteType
    this.Quote = quote
    this.Level = level
    this.Price = price
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Level2ManagerUpdate}
   * @param {!Level2ManagerUpdate} other Other struct
   * @returns {!Level2ManagerUpdate} This struct
   */
  copy (other) {
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    if (other.UpdateType != null) {
      this.UpdateType = UpdateType.fromObject(other.UpdateType)
    } else {
      this.UpdateType = null
    }
    if (other.QuoteType != null) {
      this.QuoteType = QuoteType.fromObject(other.QuoteType)
    } else {
      this.QuoteType = null
    }
    if (other.Quote != null) {
      this.Quote = Quote.fromObject(other.Quote)
    } else {
      this.Quote = null
    }
    if (other.Level != null) {
      this.Level = other.Level
    } else {
      this.Level = null
    }
    if (other.Price != null) {
      this.Price = other.Price
    } else {
      this.Price = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Level2ManagerUpdate}
   * @returns {!Level2ManagerUpdate} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Level2ManagerUpdateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Level2ManagerUpdateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Level2ManagerUpdate}
   * @param {!Level2ManagerUpdate} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Level2ManagerUpdate)) {
      throw new TypeError('Instance of Level2ManagerUpdate is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Level2ManagerUpdate}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null),
      UpdateType: ((this.UpdateType != null) ? this.UpdateType : null),
      QuoteType: ((this.QuoteType != null) ? this.QuoteType : null),
      Quote: ((this.Quote != null) ? this.Quote : null),
      Level: ((this.Level != null) ? this.Level : null),
      Price: ((this.Price != null) ? this.Price : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Level2ManagerUpdate.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Level2ManagerUpdate} other Object value
   * @returns {!Level2ManagerUpdate} Created struct
   */
  static fromObject (other) {
    return new Level2ManagerUpdate().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Level2ManagerUpdate}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Level2ManagerUpdate.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Level2ManagerUpdate}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 38
  }
}

export { Level2ManagerUpdate };

/**
 * Fast Binary Encoding Level2ManagerUpdate field model
 */
class FieldModelLevel2ManagerUpdate extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.SymbolName = new fbe.FieldModelString(buffer, 4 + 4)
    this.UpdateType = new FieldModelUpdateType(buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize)
    this.QuoteType = new FieldModelQuoteType(buffer, this.UpdateType.fbeOffset + this.UpdateType.fbeSize)
    this.Quote = new fbe.FieldModelOptional(new FieldModelQuote(buffer, this.QuoteType.fbeOffset + this.QuoteType.fbeSize), buffer, this.QuoteType.fbeOffset + this.QuoteType.fbeSize)
    this.Level = new fbe.FieldModelUInt16(buffer, this.Quote.fbeOffset + this.Quote.fbeSize)
    this.Price = new fbe.FieldModelDouble(buffer, this.Level.fbeOffset + this.Level.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.SymbolName.fbeSize + this.UpdateType.fbeSize + this.QuoteType.fbeSize + this.Quote.fbeSize + this.Level.fbeSize + this.Price.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.SymbolName.fbeExtra + this.UpdateType.fbeExtra + this.QuoteType.fbeExtra + this.Quote.fbeExtra + this.Level.fbeExtra + this.Price.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLevel2ManagerUpdate.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 38
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.UpdateType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UpdateType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateType.fbeSize

    if ((fbeCurrentSize + this.QuoteType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.QuoteType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuoteType.fbeSize

    if ((fbeCurrentSize + this.Quote.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Quote.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Quote.fbeSize

    if ((fbeCurrentSize + this.Level.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Level.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Level.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Price.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!Level2ManagerUpdate} fbeValue Default value, defaults is new Level2ManagerUpdate()
   * @returns {!Level2ManagerUpdate} Level2ManagerUpdate value
   */
  get (fbeValue = new Level2ManagerUpdate()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!Level2ManagerUpdate} fbeValue Level2ManagerUpdate value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.UpdateType.fbeSize) <= fbeStructSize) {
      fbeValue.UpdateType = this.UpdateType.get()
    } else {
      fbeValue.UpdateType = new UpdateType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UpdateType.fbeSize

    if ((fbeCurrentSize + this.QuoteType.fbeSize) <= fbeStructSize) {
      fbeValue.QuoteType = this.QuoteType.get()
    } else {
      fbeValue.QuoteType = new QuoteType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuoteType.fbeSize

    if ((fbeCurrentSize + this.Quote.fbeSize) <= fbeStructSize) {
      fbeValue.Quote = this.Quote.get()
    } else {
      fbeValue.Quote = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Quote.fbeSize

    if ((fbeCurrentSize + this.Level.fbeSize) <= fbeStructSize) {
      fbeValue.Level = this.Level.get()
    } else {
      fbeValue.Level = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Level.fbeSize

    if ((fbeCurrentSize + this.Price.fbeSize) <= fbeStructSize) {
      fbeValue.Price = this.Price.get()
    } else {
      fbeValue.Price = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Price.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLevel2ManagerUpdate}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {!Level2ManagerUpdate} fbeValue Level2ManagerUpdate value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLevel2ManagerUpdate}
   * @param {Level2ManagerUpdate} fbeValue Level2ManagerUpdate value
   */
  setFields (fbeValue) {
    this.SymbolName.set(fbeValue.SymbolName)
    this.UpdateType.set(fbeValue.UpdateType)
    this.QuoteType.set(fbeValue.QuoteType)
    this.Quote.set(fbeValue.Quote)
    this.Level.set(fbeValue.Level)
    this.Price.set(fbeValue.Price)
  }
}

export { FieldModelLevel2ManagerUpdate };

/**
 * Fast Binary Encoding Level2ManagerUpdate model
 */
class Level2ManagerUpdateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLevel2ManagerUpdate(this.buffer, 4)
  }

  /**
   * Get the Level2ManagerUpdate model
   * @this {!Level2ManagerUpdateModel}
   * @returns {!FieldModelLevel2ManagerUpdate} model Level2ManagerUpdate model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Level2ManagerUpdateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Level2ManagerUpdateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Level2ManagerUpdateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Level2ManagerUpdateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLevel2ManagerUpdate.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Level2ManagerUpdateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Level2ManagerUpdateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Level2ManagerUpdateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Level2ManagerUpdateModel}
   * @param {!Level2ManagerUpdate} value Level2ManagerUpdate value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Level2ManagerUpdateModel}
   * @param {!Level2ManagerUpdate} value Level2ManagerUpdate value, defaults is new Level2ManagerUpdate()
   * @return {!object} Deserialized Level2ManagerUpdate value and its size
   */
  deserialize (value = new Level2ManagerUpdate()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Level2ManagerUpdate(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Level2ManagerUpdate(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Level2ManagerUpdateModel}
   * @param {!number} prev Previous Level2ManagerUpdate model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Level2ManagerUpdateModel };
