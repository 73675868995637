import React, { useContext } from 'react';
import { get } from 'lodash';
import { FilterFilled } from '@ant-design/icons';
import {
    CustomColumnType,
    FilterOptionsT,
    FilterValuesContextT,
    FilterValuesT,
    IRangeInputD,
    IRangeInputN,
} from '../types';
import { IComparePare, RowFilterRangeNum } from './RowFilterRangeNum';
import { RowFilterCheckbox } from './RowFilterCheckbox';
import { RowFilterRangeDate } from './RowFilterRangeDate';
import { RowFilterSearch } from './RowFilterSearch';
import { FilterValuesContext } from '../TableExt';
import { keyMap, t } from '@localization';
import { Tooltip } from 'antd';
import { getDateSeconds, timeToNanoseconds } from '@utils/datetime';

export const getFlatDataIndex = (dataIndex: string | string[]): string =>
    typeof dataIndex === 'string' ? dataIndex : dataIndex.join('.');

export function filterSearch<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: string,
    caseSensitive?: boolean,
    searchDataFormatter?,
) {
    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined) {
            return false;
        }
        let colValue = (searchDataFormatter ? searchDataFormatter(elValue, el) : elValue)?.toString();
        colValue = caseSensitive ? colValue : colValue?.toLowerCase();
        const filterValue = caseSensitive ? value : value?.toLowerCase();
        return colValue?.toString().includes(filterValue);
    });
}

function filterRangeDate<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        return (
            getDateSeconds(colValue) >= getDateSeconds(value.from) &&
            getDateSeconds(colValue) <= getDateSeconds(value.to)
        );
    });
}

function filterRangeTime<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        const fromTimeNS = value.from ? timeToNanoseconds(value.from) ?? 0 : 0;
        const toTimeNS = value.to ? timeToNanoseconds(value.to) ?? 0 : 0;
        return colValue >= fromTimeNS && colValue <= toTimeNS;
    });
}

function filterRangeNumber<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputN,
    searchDataFormatter?,
) {
    const comparePair: IComparePare = {
        from: value.from === null || Number.isNaN(value.from) ? Number.MIN_SAFE_INTEGER : value.from,
        to: value.to === null || Number.isNaN(value.to) ? Number.MAX_SAFE_INTEGER : value.to,
    };
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        if ((colValue === '' || colValue === undefined || colValue === null) && (comparePair.from !== null || comparePair.to !== null)) {
            return false;
        }
        return (
            (comparePair.from !== null ? colValue >= comparePair.from : true) &&
            (comparePair.to !== null ? colValue <= comparePair.to : true)
        );
    });
}

function filterCheckbox<ContextDataT>(data: ContextDataT[], dataIndex: string, value: string[], searchDataFormatter?) {
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        if (Array.isArray(colValue)) {
            return value.filter((v) => colValue.includes(v)).length > 0;
        }
        return value.includes(colValue);
    });
}

export function filterAll<ContextDataT>(
    data: ContextDataT[],
    filterValues: FilterValuesT,
    columns: CustomColumnType<ContextDataT>[],
) {
    let local = data;
    const localColumns = {};
    columns.forEach((col) => {
        localColumns[getFlatDataIndex(col.dataIndex)] = col;
    });
    Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]) {
            const colData = localColumns[key];
            if (colData) {
                switch (colData.filterOptions?.type) {
                    case 'Search':
                        local = filterSearch(
                            local,
                            key,
                            filterValues[key] as string,
                            colData.filterOptions.caseSensitive,
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'Checkbox':
                        local = filterCheckbox(
                            local,
                            key,
                            filterValues[key] as string[],
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'RangeDate':
                        local = filterRangeDate(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeTime':
                        local = filterRangeTime(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeNum':
                        local = filterRangeNumber(
                            local,
                            key,
                            filterValues[key] as IRangeInputN,
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    default:
                        break;
                }
            }
        }
    });
    return local;
}

export function Filter(props: { options: FilterOptionsT; dataIndex: string, confirm: () => void }) {
    switch (props.options.type) {
        case 'Checkbox':
            return (
                <RowFilterCheckbox
                    values={props.options.checkboxValues ? props.options.checkboxValues() : []}
                    dataIndex={props.dataIndex}
                    allOption={props.options.allOption}
                    confirmCallback={props.confirm}
                />
            );
        case 'RangeDate':
            return <RowFilterRangeDate dataIndex={props.dataIndex} withTimePicker={props.options.withTimePicker} confirmCallback={props.confirm} />;
        case 'RangeTime':
            return <RowFilterRangeDate dataIndex={props.dataIndex} confirmCallback={props.confirm} timeOnly />;
        case 'RangeNum':
            return <RowFilterRangeNum dataIndex={props.dataIndex} confirmCallback={props.confirm} />;
        case 'Search':
            return <RowFilterSearch dataIndex={props.dataIndex} caseSensitive={props.options.caseSensitive} confirmCallback={props.confirm} />;
        default:
            return null;
    }
}

export function FilterIcon(props: { dataIndex: string }) {
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);

    return (
        <Tooltip title={t(keyMap.common.clickToFilter)}>
            <FilterFilled style={filterValues.values[props.dataIndex] ? { color: '#1890ff' } : {}} />
        </Tooltip>
    );
}

export const baseSorter = (
    a: Date | number | string | null | boolean | undefined,
    b: Date | number | string | null | boolean | undefined,
): number => {
    if (typeof a === 'boolean' && typeof b === 'boolean') {
        return a > b ? 1 : -1;
    }
    if (typeof a === 'number' && typeof b === 'number') {
        return a > b ? 1 : -1;
    }
    if (a instanceof Date || b instanceof Date) {
        const aString = a instanceof Date ? a.getTime().toString() : '';
        const bString = b instanceof Date ? b.getTime().toString() : '';
        return aString > bString ? 1 : -1;
    }
    const aString = (a ?? '').toString();
    const bString = (b ?? '').toString();
    return aString.localeCompare(bString);
};

export const longTextPrerender = (val: string | null | undefined) =>
    val && val.length > 30 ? `${val.slice(0, 30)}...` : val;
