
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as entity from '../entity'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * DrawingStorageDashboardData struct
 */
class DrawingStorageDashboardData {
  /**
   * Initialize struct
   * @param {!UUID=} dashboardId
   * @param {!number=} version
   * @param {number=} userId
   * @param {string=} name
   * @param {string=} settings
   * @param {number=} deletedAt
   * @constructor
   */
  constructor (dashboardId = new UUID(), version = new UInt64(0, 0), userId = null, name = null, settings = null, deletedAt = null) {
    this.dashboardId = dashboardId
    this.version = version
    this.userId = userId
    this.name = name
    this.settings = settings
    this.deletedAt = deletedAt
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DrawingStorageDashboardData}
   * @param {!DrawingStorageDashboardData} other Other struct
   * @returns {!DrawingStorageDashboardData} This struct
   */
  copy (other) {
    if (other.dashboardId != null) {
      this.dashboardId = new UUID(other.dashboardId)
    } else {
      this.dashboardId = null
    }
    if (other.version != null) {
      this.version = UInt64.fromNumber(other.version)
    } else {
      this.version = null
    }
    if (other.userId != null) {
      this.userId = UInt64.fromNumber(other.userId)
    } else {
      this.userId = null
    }
    if (other.name != null) {
      this.name = other.name
    } else {
      this.name = null
    }
    if (other.settings != null) {
      this.settings = other.settings
    } else {
      this.settings = null
    }
    if (other.deletedAt != null) {
      this.deletedAt = UInt64.fromNumber(other.deletedAt)
    } else {
      this.deletedAt = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DrawingStorageDashboardData}
   * @returns {!DrawingStorageDashboardData} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DrawingStorageDashboardDataModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DrawingStorageDashboardDataModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DrawingStorageDashboardData}
   * @param {!DrawingStorageDashboardData} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DrawingStorageDashboardData)) {
      throw new TypeError('Instance of DrawingStorageDashboardData is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DrawingStorageDashboardData}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      dashboardId: ((this.dashboardId != null) ? this.dashboardId.toString() : null),
      version: ((this.version != null) ? this.version.toNumber() : null),
      userId: ((this.userId != null) ? this.userId.toNumber() : null),
      name: ((this.name != null) ? this.name : null),
      settings: ((this.settings != null) ? this.settings : null),
      deletedAt: ((this.deletedAt != null) ? this.deletedAt.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DrawingStorageDashboardData.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DrawingStorageDashboardData} other Object value
   * @returns {!DrawingStorageDashboardData} Created struct
   */
  static fromObject (other) {
    return new DrawingStorageDashboardData().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DrawingStorageDashboardData}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DrawingStorageDashboardData.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DrawingStorageDashboardData}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 10018
  }
}

export { DrawingStorageDashboardData };

/**
 * Fast Binary Encoding DrawingStorageDashboardData field model
 */
class FieldModelDrawingStorageDashboardData extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.dashboardId = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.version = new fbe.FieldModelUInt64(buffer, this.dashboardId.fbeOffset + this.dashboardId.fbeSize)
    this.userId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.version.fbeOffset + this.version.fbeSize), buffer, this.version.fbeOffset + this.version.fbeSize)
    this.name = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.userId.fbeOffset + this.userId.fbeSize), buffer, this.userId.fbeOffset + this.userId.fbeSize)
    this.settings = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.name.fbeOffset + this.name.fbeSize), buffer, this.name.fbeOffset + this.name.fbeSize)
    this.deletedAt = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.settings.fbeOffset + this.settings.fbeSize), buffer, this.settings.fbeOffset + this.settings.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.dashboardId.fbeSize + this.version.fbeSize + this.userId.fbeSize + this.name.fbeSize + this.settings.fbeSize + this.deletedAt.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.dashboardId.fbeExtra + this.version.fbeExtra + this.userId.fbeExtra + this.name.fbeExtra + this.settings.fbeExtra + this.deletedAt.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDrawingStorageDashboardData.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 10018
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.dashboardId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.dashboardId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.dashboardId.fbeSize

    if ((fbeCurrentSize + this.version.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.version.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.version.fbeSize

    if ((fbeCurrentSize + this.userId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.userId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.userId.fbeSize

    if ((fbeCurrentSize + this.name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.name.fbeSize

    if ((fbeCurrentSize + this.settings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.settings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.settings.fbeSize

    if ((fbeCurrentSize + this.deletedAt.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.deletedAt.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.deletedAt.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!DrawingStorageDashboardData} fbeValue Default value, defaults is new DrawingStorageDashboardData()
   * @returns {!DrawingStorageDashboardData} DrawingStorageDashboardData value
   */
  get (fbeValue = new DrawingStorageDashboardData()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!DrawingStorageDashboardData} fbeValue DrawingStorageDashboardData value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.dashboardId.fbeSize) <= fbeStructSize) {
      fbeValue.dashboardId = this.dashboardId.get()
    } else {
      fbeValue.dashboardId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.dashboardId.fbeSize

    if ((fbeCurrentSize + this.version.fbeSize) <= fbeStructSize) {
      fbeValue.version = this.version.get()
    } else {
      fbeValue.version = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.version.fbeSize

    if ((fbeCurrentSize + this.userId.fbeSize) <= fbeStructSize) {
      fbeValue.userId = this.userId.get()
    } else {
      fbeValue.userId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.userId.fbeSize

    if ((fbeCurrentSize + this.name.fbeSize) <= fbeStructSize) {
      fbeValue.name = this.name.get()
    } else {
      fbeValue.name = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.name.fbeSize

    if ((fbeCurrentSize + this.settings.fbeSize) <= fbeStructSize) {
      fbeValue.settings = this.settings.get()
    } else {
      fbeValue.settings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.settings.fbeSize

    if ((fbeCurrentSize + this.deletedAt.fbeSize) <= fbeStructSize) {
      fbeValue.deletedAt = this.deletedAt.get()
    } else {
      fbeValue.deletedAt = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.deletedAt.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDrawingStorageDashboardData}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {!DrawingStorageDashboardData} fbeValue DrawingStorageDashboardData value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDrawingStorageDashboardData}
   * @param {DrawingStorageDashboardData} fbeValue DrawingStorageDashboardData value
   */
  setFields (fbeValue) {
    this.dashboardId.set(fbeValue.dashboardId)
    this.version.set(fbeValue.version)
    this.userId.set(fbeValue.userId)
    this.name.set(fbeValue.name)
    this.settings.set(fbeValue.settings)
    this.deletedAt.set(fbeValue.deletedAt)
  }
}

export { FieldModelDrawingStorageDashboardData };

/**
 * Fast Binary Encoding DrawingStorageDashboardData model
 */
class DrawingStorageDashboardDataModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDrawingStorageDashboardData(this.buffer, 4)
  }

  /**
   * Get the DrawingStorageDashboardData model
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!FieldModelDrawingStorageDashboardData} model DrawingStorageDashboardData model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DrawingStorageDashboardDataModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDrawingStorageDashboardData.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DrawingStorageDashboardDataModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DrawingStorageDashboardDataModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DrawingStorageDashboardDataModel}
   * @param {!DrawingStorageDashboardData} value DrawingStorageDashboardData value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DrawingStorageDashboardDataModel}
   * @param {!DrawingStorageDashboardData} value DrawingStorageDashboardData value, defaults is new DrawingStorageDashboardData()
   * @return {!object} Deserialized DrawingStorageDashboardData value and its size
   */
  deserialize (value = new DrawingStorageDashboardData()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DrawingStorageDashboardData(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DrawingStorageDashboardData(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DrawingStorageDashboardDataModel}
   * @param {!number} prev Previous DrawingStorageDashboardData model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DrawingStorageDashboardDataModel };
