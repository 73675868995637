import React, { FC } from 'react';
import { Menu as AntdMenu, MenuProps as AntdMenuProps } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { keyFromMenuItem, matchMenuKeys } from './utils';
import styles from './Menu.module.scss';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { History } from 'history';
import { RegularIcon } from '@components/Icons/RegularIcon';

export type MenuItemConfig<Extend = {}> = {
    title: any;
    key?: string;
    path?: string;
    icon?: string | JSX.Element;
    exact?: boolean;
    children?: MenuItemConfig<Extend>[];
};

export type MenuItemProps<Extend = {}> = {
    item: MenuItemConfig<Extend>;
    menuItemClassName?: string;
    submenuItemClassName?: string;
    isMenuCollapsed?: boolean;
    selectedKeys?: string[];
    history?: History<unknown>;
};

const renderMenuItem = ({
    item,
    menuItemClassName,
    submenuItemClassName,
    isMenuCollapsed,
    history,
}: MenuItemProps): ItemType => {
    let icon: undefined | JSX.Element;

    if (!item.icon) {
        icon = <i className={styles.emptyIcon} />;
    } else if (typeof item.icon === 'string') {
        icon = <RegularIcon src={item.icon} />;
    } else {
        icon = item.icon;
    }

    if (item.children && item.children.length !== 0) {
        return {
            icon,
            label: !isMenuCollapsed && item.title,
            key: keyFromMenuItem(item),
            children: item.children.map((subItem) =>
                // important for antd menus - no custom components
                renderMenuItem({
                    item: subItem,
                    menuItemClassName,
                    submenuItemClassName,
                    isMenuCollapsed,
                    history,
                }),
            ) as ItemType[],
        };
    }

    return {
        icon,
        label: item.title,
        key: keyFromMenuItem(item),
        className: `${menuItemClassName || ''}`,
        onClick: () => history?.push(item.path as string),
    };
};

export type MenuProps = {
    items: MenuItemConfig[];
    className?: string;
    menuItemClassName?: string;
    submenuItemClassName?: string;
    isMenuCollapsed?: boolean;
    /** its fixed after first render */
    noRouteMatch?: boolean;
} & AntdMenuProps;

export const Menu: FC<MenuProps> = ({
    items,
    className,
    selectedKeys,
    menuItemClassName,
    submenuItemClassName,
    isMenuCollapsed,
    noRouteMatch,
    ...restProps
}) => {
    const history = useHistory();
    if (!noRouteMatch) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const location = useLocation();

        if (!selectedKeys) {
            const matched = matchMenuKeys(location.pathname, items);
            selectedKeys = matched.map((x) => keyFromMenuItem(x));
        }
    }

    return (
        <AntdMenu
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
            selectedKeys={selectedKeys}
            inlineIndent={0}
            mode='inline'
            className={`${className || ''}`}
            style={{
                width: isMenuCollapsed ? 40 : 'unset',
            }}
            items={
                items.map((item) =>
                    // important for antd menus - no custom components
                    renderMenuItem({
                        item,
                        menuItemClassName,
                        submenuItemClassName,
                        isMenuCollapsed,
                        selectedKeys,
                        history,
                    }),
                ) as ItemType[]
            }
        />
    );
};
