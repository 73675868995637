// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: execution.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {GatewayExecutionType} from './execution/GatewayExecutionType'
import {ExecutionVersionRequest} from './execution/ExecutionVersionRequest'
import {ExecutionVersionRequestModel} from './execution/ExecutionVersionRequest'
import {ExecutionVersionResponse} from './execution/ExecutionVersionResponse'
import {ExecutionVersionResponseModel} from './execution/ExecutionVersionResponse'
import {ExecutionUpdateNotify} from './execution/ExecutionUpdateNotify'
import {ExecutionUpdateNotifyModel} from './execution/ExecutionUpdateNotify'
import {GatewayModulesGetRequest} from './execution/GatewayModulesGetRequest'
import {GatewayModulesGetRequestModel} from './execution/GatewayModulesGetRequest'
import {GatewayModulesGetResponse} from './execution/GatewayModulesGetResponse'
import {GatewayModulesGetResponseModel} from './execution/GatewayModulesGetResponse'
import {GatewayModuleUpdateNotify} from './execution/GatewayModuleUpdateNotify'
import {GatewayModuleUpdateNotifyModel} from './execution/GatewayModuleUpdateNotify'
import {GatewayStartRequest} from './execution/GatewayStartRequest'
import {GatewayStartRequestModel} from './execution/GatewayStartRequest'
import {GatewayStopRequest} from './execution/GatewayStopRequest'
import {GatewayStopRequestModel} from './execution/GatewayStopRequest'
import {GatewayShutdownRequest} from './execution/GatewayShutdownRequest'
import {GatewayShutdownRequestModel} from './execution/GatewayShutdownRequest'
import {GatewayStatusNotify} from './execution/GatewayStatusNotify'
import {GatewayStatusNotifyModel} from './execution/GatewayStatusNotify'
import {GatewayOrderRequest} from './execution/GatewayOrderRequest'
import {GatewayOrderRequestModel} from './execution/GatewayOrderRequest'
import {GatewayOrderExecutionResponse} from './execution/GatewayOrderExecutionResponse'
import {GatewayOrderExecutionResponseModel} from './execution/GatewayOrderExecutionResponse'
import {SymbolUpdateNotify} from './execution/SymbolUpdateNotify'
import {SymbolUpdateNotifyModel} from './execution/SymbolUpdateNotify'
import {SymbolsUpdateNotify} from './execution/SymbolsUpdateNotify'
import {SymbolsUpdateNotifyModel} from './execution/SymbolsUpdateNotify'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding execution sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize execution sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._ExecutionVersionRequestModel = new ExecutionVersionRequestModel(this.buffer)
    this._ExecutionVersionResponseModel = new ExecutionVersionResponseModel(this.buffer)
    this._ExecutionUpdateNotifyModel = new ExecutionUpdateNotifyModel(this.buffer)
    this._GatewayModulesGetRequestModel = new GatewayModulesGetRequestModel(this.buffer)
    this._GatewayModulesGetResponseModel = new GatewayModulesGetResponseModel(this.buffer)
    this._GatewayModuleUpdateNotifyModel = new GatewayModuleUpdateNotifyModel(this.buffer)
    this._GatewayStartRequestModel = new GatewayStartRequestModel(this.buffer)
    this._GatewayStopRequestModel = new GatewayStopRequestModel(this.buffer)
    this._GatewayShutdownRequestModel = new GatewayShutdownRequestModel(this.buffer)
    this._GatewayStatusNotifyModel = new GatewayStatusNotifyModel(this.buffer)
    this._GatewayOrderRequestModel = new GatewayOrderRequestModel(this.buffer)
    this._GatewayOrderExecutionResponseModel = new GatewayOrderExecutionResponseModel(this.buffer)
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel(this.buffer)
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get ExecutionVersionRequest model
   * @this {!Sender}
   * @returns {!ExecutionVersionRequestModel} ExecutionVersionRequest model
   */
  get ExecutionVersionRequestModel () {
    return this._ExecutionVersionRequestModel
  }

  /**
   * Get ExecutionVersionResponse model
   * @this {!Sender}
   * @returns {!ExecutionVersionResponseModel} ExecutionVersionResponse model
   */
  get ExecutionVersionResponseModel () {
    return this._ExecutionVersionResponseModel
  }

  /**
   * Get ExecutionUpdateNotify model
   * @this {!Sender}
   * @returns {!ExecutionUpdateNotifyModel} ExecutionUpdateNotify model
   */
  get ExecutionUpdateNotifyModel () {
    return this._ExecutionUpdateNotifyModel
  }

  /**
   * Get GatewayModulesGetRequest model
   * @this {!Sender}
   * @returns {!GatewayModulesGetRequestModel} GatewayModulesGetRequest model
   */
  get GatewayModulesGetRequestModel () {
    return this._GatewayModulesGetRequestModel
  }

  /**
   * Get GatewayModulesGetResponse model
   * @this {!Sender}
   * @returns {!GatewayModulesGetResponseModel} GatewayModulesGetResponse model
   */
  get GatewayModulesGetResponseModel () {
    return this._GatewayModulesGetResponseModel
  }

  /**
   * Get GatewayModuleUpdateNotify model
   * @this {!Sender}
   * @returns {!GatewayModuleUpdateNotifyModel} GatewayModuleUpdateNotify model
   */
  get GatewayModuleUpdateNotifyModel () {
    return this._GatewayModuleUpdateNotifyModel
  }

  /**
   * Get GatewayStartRequest model
   * @this {!Sender}
   * @returns {!GatewayStartRequestModel} GatewayStartRequest model
   */
  get GatewayStartRequestModel () {
    return this._GatewayStartRequestModel
  }

  /**
   * Get GatewayStopRequest model
   * @this {!Sender}
   * @returns {!GatewayStopRequestModel} GatewayStopRequest model
   */
  get GatewayStopRequestModel () {
    return this._GatewayStopRequestModel
  }

  /**
   * Get GatewayShutdownRequest model
   * @this {!Sender}
   * @returns {!GatewayShutdownRequestModel} GatewayShutdownRequest model
   */
  get GatewayShutdownRequestModel () {
    return this._GatewayShutdownRequestModel
  }

  /**
   * Get GatewayStatusNotify model
   * @this {!Sender}
   * @returns {!GatewayStatusNotifyModel} GatewayStatusNotify model
   */
  get GatewayStatusNotifyModel () {
    return this._GatewayStatusNotifyModel
  }

  /**
   * Get GatewayOrderRequest model
   * @this {!Sender}
   * @returns {!GatewayOrderRequestModel} GatewayOrderRequest model
   */
  get GatewayOrderRequestModel () {
    return this._GatewayOrderRequestModel
  }

  /**
   * Get GatewayOrderExecutionResponse model
   * @this {!Sender}
   * @returns {!GatewayOrderExecutionResponseModel} GatewayOrderExecutionResponse model
   */
  get GatewayOrderExecutionResponseModel () {
    return this._GatewayOrderExecutionResponseModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify model
   */
  get SymbolUpdateNotifyModel () {
    return this._SymbolUpdateNotifyModel
  }

  /**
   * Get SymbolsUpdateNotify model
   * @this {!Sender}
   * @returns {!SymbolsUpdateNotifyModel} SymbolsUpdateNotify model
   */
  get SymbolsUpdateNotifyModel () {
    return this._SymbolsUpdateNotifyModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ExecutionVersionRequest) && (value.fbeType === this.ExecutionVersionRequestModel.fbeType)) {
      return this.send_ExecutionVersionRequest(value)
    }
    if ((value instanceof ExecutionVersionResponse) && (value.fbeType === this.ExecutionVersionResponseModel.fbeType)) {
      return this.send_ExecutionVersionResponse(value)
    }
    if ((value instanceof ExecutionUpdateNotify) && (value.fbeType === this.ExecutionUpdateNotifyModel.fbeType)) {
      return this.send_ExecutionUpdateNotify(value)
    }
    if ((value instanceof GatewayModulesGetRequest) && (value.fbeType === this.GatewayModulesGetRequestModel.fbeType)) {
      return this.send_GatewayModulesGetRequest(value)
    }
    if ((value instanceof GatewayModulesGetResponse) && (value.fbeType === this.GatewayModulesGetResponseModel.fbeType)) {
      return this.send_GatewayModulesGetResponse(value)
    }
    if ((value instanceof GatewayModuleUpdateNotify) && (value.fbeType === this.GatewayModuleUpdateNotifyModel.fbeType)) {
      return this.send_GatewayModuleUpdateNotify(value)
    }
    if ((value instanceof GatewayStartRequest) && (value.fbeType === this.GatewayStartRequestModel.fbeType)) {
      return this.send_GatewayStartRequest(value)
    }
    if ((value instanceof GatewayStopRequest) && (value.fbeType === this.GatewayStopRequestModel.fbeType)) {
      return this.send_GatewayStopRequest(value)
    }
    if ((value instanceof GatewayShutdownRequest) && (value.fbeType === this.GatewayShutdownRequestModel.fbeType)) {
      return this.send_GatewayShutdownRequest(value)
    }
    if ((value instanceof GatewayStatusNotify) && (value.fbeType === this.GatewayStatusNotifyModel.fbeType)) {
      return this.send_GatewayStatusNotify(value)
    }
    if ((value instanceof GatewayOrderRequest) && (value.fbeType === this.GatewayOrderRequestModel.fbeType)) {
      return this.send_GatewayOrderRequest(value)
    }
    if ((value instanceof GatewayOrderExecutionResponse) && (value.fbeType === this.GatewayOrderExecutionResponseModel.fbeType)) {
      return this.send_GatewayOrderExecutionResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifyModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof SymbolsUpdateNotify) && (value.fbeType === this.SymbolsUpdateNotifyModel.fbeType)) {
      return this.send_SymbolsUpdateNotify(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ExecutionVersionRequest value
   * @this {!Sender}
   * @param {!ExecutionVersionRequest} value ExecutionVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionVersionRequest serialization failed!')
    console.assert(this.ExecutionVersionRequestModel.verify(), 'execution.ExecutionVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ExecutionVersionResponse value
   * @this {!Sender}
   * @param {!ExecutionVersionResponse} value ExecutionVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionVersionResponse serialization failed!')
    console.assert(this.ExecutionVersionResponseModel.verify(), 'execution.ExecutionVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ExecutionUpdateNotify value
   * @this {!Sender}
   * @param {!ExecutionUpdateNotify} value ExecutionUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionUpdateNotify serialization failed!')
    console.assert(this.ExecutionUpdateNotifyModel.verify(), 'execution.ExecutionUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModulesGetRequest value
   * @this {!Sender}
   * @param {!GatewayModulesGetRequest} value GatewayModulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModulesGetRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModulesGetRequest serialization failed!')
    console.assert(this.GatewayModulesGetRequestModel.verify(), 'execution.GatewayModulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModulesGetResponse value
   * @this {!Sender}
   * @param {!GatewayModulesGetResponse} value GatewayModulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModulesGetResponseModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModulesGetResponse serialization failed!')
    console.assert(this.GatewayModulesGetResponseModel.verify(), 'execution.GatewayModulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModuleUpdateNotify value
   * @this {!Sender}
   * @param {!GatewayModuleUpdateNotify} value GatewayModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModuleUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModuleUpdateNotify serialization failed!')
    console.assert(this.GatewayModuleUpdateNotifyModel.verify(), 'execution.GatewayModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStartRequest value
   * @this {!Sender}
   * @param {!GatewayStartRequest} value GatewayStartRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStartRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStartRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStartRequest serialization failed!')
    console.assert(this.GatewayStartRequestModel.verify(), 'execution.GatewayStartRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStopRequest value
   * @this {!Sender}
   * @param {!GatewayStopRequest} value GatewayStopRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStopRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStopRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStopRequest serialization failed!')
    console.assert(this.GatewayStopRequestModel.verify(), 'execution.GatewayStopRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayShutdownRequest value
   * @this {!Sender}
   * @param {!GatewayShutdownRequest} value GatewayShutdownRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayShutdownRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayShutdownRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayShutdownRequest serialization failed!')
    console.assert(this.GatewayShutdownRequestModel.verify(), 'execution.GatewayShutdownRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStatusNotify value
   * @this {!Sender}
   * @param {!GatewayStatusNotify} value GatewayStatusNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStatusNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStatusNotifyModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStatusNotify serialization failed!')
    console.assert(this.GatewayStatusNotifyModel.verify(), 'execution.GatewayStatusNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayOrderRequest value
   * @this {!Sender}
   * @param {!GatewayOrderRequest} value GatewayOrderRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayOrderRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayOrderRequestModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayOrderRequest serialization failed!')
    console.assert(this.GatewayOrderRequestModel.verify(), 'execution.GatewayOrderRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayOrderExecutionResponse value
   * @this {!Sender}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayOrderExecutionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayOrderExecutionResponseModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayOrderExecutionResponse serialization failed!')
    console.assert(this.GatewayOrderExecutionResponseModel.verify(), 'execution.GatewayOrderExecutionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'execution.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifyModel.verify(), 'execution.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsUpdateNotify value
   * @this {!Sender}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'execution.SymbolsUpdateNotify serialization failed!')
    console.assert(this.SymbolsUpdateNotifyModel.verify(), 'execution.SymbolsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'execution.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding execution receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize execution receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._ExecutionVersionRequestValue = new ExecutionVersionRequest()
    this._ExecutionVersionRequestModel = new ExecutionVersionRequestModel()
    this._ExecutionVersionResponseValue = new ExecutionVersionResponse()
    this._ExecutionVersionResponseModel = new ExecutionVersionResponseModel()
    this._ExecutionUpdateNotifyValue = new ExecutionUpdateNotify()
    this._ExecutionUpdateNotifyModel = new ExecutionUpdateNotifyModel()
    this._GatewayModulesGetRequestValue = new GatewayModulesGetRequest()
    this._GatewayModulesGetRequestModel = new GatewayModulesGetRequestModel()
    this._GatewayModulesGetResponseValue = new GatewayModulesGetResponse()
    this._GatewayModulesGetResponseModel = new GatewayModulesGetResponseModel()
    this._GatewayModuleUpdateNotifyValue = new GatewayModuleUpdateNotify()
    this._GatewayModuleUpdateNotifyModel = new GatewayModuleUpdateNotifyModel()
    this._GatewayStartRequestValue = new GatewayStartRequest()
    this._GatewayStartRequestModel = new GatewayStartRequestModel()
    this._GatewayStopRequestValue = new GatewayStopRequest()
    this._GatewayStopRequestModel = new GatewayStopRequestModel()
    this._GatewayShutdownRequestValue = new GatewayShutdownRequest()
    this._GatewayShutdownRequestModel = new GatewayShutdownRequestModel()
    this._GatewayStatusNotifyValue = new GatewayStatusNotify()
    this._GatewayStatusNotifyModel = new GatewayStatusNotifyModel()
    this._GatewayOrderRequestValue = new GatewayOrderRequest()
    this._GatewayOrderRequestModel = new GatewayOrderRequestModel()
    this._GatewayOrderExecutionResponseValue = new GatewayOrderExecutionResponse()
    this._GatewayOrderExecutionResponseModel = new GatewayOrderExecutionResponseModel()
    this._SymbolUpdateNotifyValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._SymbolsUpdateNotifyValue = new SymbolsUpdateNotify()
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ExecutionVersionRequest receive handler
   * @this {!Receiver}
   * @param {!ExecutionVersionRequest} value ExecutionVersionRequest received value
   */
  onReceive_ExecutionVersionRequest (value) {}  // eslint-disable-line

  /**
   * ExecutionVersionResponse receive handler
   * @this {!Receiver}
   * @param {!ExecutionVersionResponse} value ExecutionVersionResponse received value
   */
  onReceive_ExecutionVersionResponse (value) {}  // eslint-disable-line

  /**
   * ExecutionUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ExecutionUpdateNotify} value ExecutionUpdateNotify received value
   */
  onReceive_ExecutionUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewayModulesGetRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayModulesGetRequest} value GatewayModulesGetRequest received value
   */
  onReceive_GatewayModulesGetRequest (value) {}  // eslint-disable-line

  /**
   * GatewayModulesGetResponse receive handler
   * @this {!Receiver}
   * @param {!GatewayModulesGetResponse} value GatewayModulesGetResponse received value
   */
  onReceive_GatewayModulesGetResponse (value) {}  // eslint-disable-line

  /**
   * GatewayModuleUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!GatewayModuleUpdateNotify} value GatewayModuleUpdateNotify received value
   */
  onReceive_GatewayModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewayStartRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayStartRequest} value GatewayStartRequest received value
   */
  onReceive_GatewayStartRequest (value) {}  // eslint-disable-line

  /**
   * GatewayStopRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayStopRequest} value GatewayStopRequest received value
   */
  onReceive_GatewayStopRequest (value) {}  // eslint-disable-line

  /**
   * GatewayShutdownRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayShutdownRequest} value GatewayShutdownRequest received value
   */
  onReceive_GatewayShutdownRequest (value) {}  // eslint-disable-line

  /**
   * GatewayStatusNotify receive handler
   * @this {!Receiver}
   * @param {!GatewayStatusNotify} value GatewayStatusNotify received value
   */
  onReceive_GatewayStatusNotify (value) {}  // eslint-disable-line

  /**
   * GatewayOrderRequest receive handler
   * @this {!Receiver}
   * @param {!GatewayOrderRequest} value GatewayOrderRequest received value
   */
  onReceive_GatewayOrderRequest (value) {}  // eslint-disable-line

  /**
   * GatewayOrderExecutionResponse receive handler
   * @this {!Receiver}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse received value
   */
  onReceive_GatewayOrderExecutionResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify received value
   */
  onReceive_SymbolsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * execution receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ExecutionVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionRequestModel.verify(), 'execution.ExecutionVersionRequest validation failed!')
        let deserialized = this._ExecutionVersionRequestModel.deserialize(this._ExecutionVersionRequestValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionVersionRequestValue)
        this.onReceive_ExecutionVersionRequest(this._ExecutionVersionRequestValue)
        return true
      }
      case ExecutionVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionResponseModel.verify(), 'execution.ExecutionVersionResponse validation failed!')
        let deserialized = this._ExecutionVersionResponseModel.deserialize(this._ExecutionVersionResponseValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionVersionResponseValue)
        this.onReceive_ExecutionVersionResponse(this._ExecutionVersionResponseValue)
        return true
      }
      case ExecutionUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionUpdateNotifyModel.verify(), 'execution.ExecutionUpdateNotify validation failed!')
        let deserialized = this._ExecutionUpdateNotifyModel.deserialize(this._ExecutionUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionUpdateNotifyValue)
        this.onReceive_ExecutionUpdateNotify(this._ExecutionUpdateNotifyValue)
        return true
      }
      case GatewayModulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetRequestModel.verify(), 'execution.GatewayModulesGetRequest validation failed!')
        let deserialized = this._GatewayModulesGetRequestModel.deserialize(this._GatewayModulesGetRequestValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModulesGetRequestValue)
        this.onReceive_GatewayModulesGetRequest(this._GatewayModulesGetRequestValue)
        return true
      }
      case GatewayModulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetResponseModel.verify(), 'execution.GatewayModulesGetResponse validation failed!')
        let deserialized = this._GatewayModulesGetResponseModel.deserialize(this._GatewayModulesGetResponseValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModulesGetResponseValue)
        this.onReceive_GatewayModulesGetResponse(this._GatewayModulesGetResponseValue)
        return true
      }
      case GatewayModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModuleUpdateNotifyModel.verify(), 'execution.GatewayModuleUpdateNotify validation failed!')
        let deserialized = this._GatewayModuleUpdateNotifyModel.deserialize(this._GatewayModuleUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModuleUpdateNotifyValue)
        this.onReceive_GatewayModuleUpdateNotify(this._GatewayModuleUpdateNotifyValue)
        return true
      }
      case GatewayStartRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStartRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStartRequestModel.verify(), 'execution.GatewayStartRequest validation failed!')
        let deserialized = this._GatewayStartRequestModel.deserialize(this._GatewayStartRequestValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStartRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStartRequestValue)
        this.onReceive_GatewayStartRequest(this._GatewayStartRequestValue)
        return true
      }
      case GatewayStopRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStopRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStopRequestModel.verify(), 'execution.GatewayStopRequest validation failed!')
        let deserialized = this._GatewayStopRequestModel.deserialize(this._GatewayStopRequestValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStopRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStopRequestValue)
        this.onReceive_GatewayStopRequest(this._GatewayStopRequestValue)
        return true
      }
      case GatewayShutdownRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayShutdownRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayShutdownRequestModel.verify(), 'execution.GatewayShutdownRequest validation failed!')
        let deserialized = this._GatewayShutdownRequestModel.deserialize(this._GatewayShutdownRequestValue)
        console.assert((deserialized.size > 0), 'execution.GatewayShutdownRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayShutdownRequestValue)
        this.onReceive_GatewayShutdownRequest(this._GatewayShutdownRequestValue)
        return true
      }
      case GatewayStatusNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStatusNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStatusNotifyModel.verify(), 'execution.GatewayStatusNotify validation failed!')
        let deserialized = this._GatewayStatusNotifyModel.deserialize(this._GatewayStatusNotifyValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStatusNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStatusNotifyValue)
        this.onReceive_GatewayStatusNotify(this._GatewayStatusNotifyValue)
        return true
      }
      case GatewayOrderRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayOrderRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderRequestModel.verify(), 'execution.GatewayOrderRequest validation failed!')
        let deserialized = this._GatewayOrderRequestModel.deserialize(this._GatewayOrderRequestValue)
        console.assert((deserialized.size > 0), 'execution.GatewayOrderRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayOrderRequestValue)
        this.onReceive_GatewayOrderRequest(this._GatewayOrderRequestValue)
        return true
      }
      case GatewayOrderExecutionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayOrderExecutionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderExecutionResponseModel.verify(), 'execution.GatewayOrderExecutionResponse validation failed!')
        let deserialized = this._GatewayOrderExecutionResponseModel.deserialize(this._GatewayOrderExecutionResponseValue)
        console.assert((deserialized.size > 0), 'execution.GatewayOrderExecutionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayOrderExecutionResponseValue)
        this.onReceive_GatewayOrderExecutionResponse(this._GatewayOrderExecutionResponseValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'execution.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyModel.deserialize(this._SymbolUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'execution.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyValue)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyModel.verify(), 'execution.SymbolsUpdateNotify validation failed!')
        let deserialized = this._SymbolsUpdateNotifyModel.deserialize(this._SymbolsUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'execution.SymbolsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsUpdateNotifyValue)
        this.onReceive_SymbolsUpdateNotify(this._SymbolsUpdateNotifyValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding execution proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize execution proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._ExecutionVersionRequestModel = new ExecutionVersionRequestModel()
    this._ExecutionVersionResponseModel = new ExecutionVersionResponseModel()
    this._ExecutionUpdateNotifyModel = new ExecutionUpdateNotifyModel()
    this._GatewayModulesGetRequestModel = new GatewayModulesGetRequestModel()
    this._GatewayModulesGetResponseModel = new GatewayModulesGetResponseModel()
    this._GatewayModuleUpdateNotifyModel = new GatewayModuleUpdateNotifyModel()
    this._GatewayStartRequestModel = new GatewayStartRequestModel()
    this._GatewayStopRequestModel = new GatewayStopRequestModel()
    this._GatewayShutdownRequestModel = new GatewayShutdownRequestModel()
    this._GatewayStatusNotifyModel = new GatewayStatusNotifyModel()
    this._GatewayOrderRequestModel = new GatewayOrderRequestModel()
    this._GatewayOrderExecutionResponseModel = new GatewayOrderExecutionResponseModel()
    this._SymbolUpdateNotifyModel = new SymbolUpdateNotifyModel()
    this._SymbolsUpdateNotifyModel = new SymbolsUpdateNotifyModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * ExecutionVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!ExecutionVersionRequest} model ExecutionVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ExecutionVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ExecutionVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!ExecutionVersionResponse} model ExecutionVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ExecutionVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ExecutionUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ExecutionUpdateNotify} model ExecutionUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ExecutionUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayModulesGetRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayModulesGetRequest} model GatewayModulesGetRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayModulesGetRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayModulesGetResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewayModulesGetResponse} model GatewayModulesGetResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayModulesGetResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayModuleUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!GatewayModuleUpdateNotify} model GatewayModuleUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayModuleUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayStartRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayStartRequest} model GatewayStartRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayStartRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayStopRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayStopRequest} model GatewayStopRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayStopRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayShutdownRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayShutdownRequest} model GatewayShutdownRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayShutdownRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayStatusNotify proxy handler
   * @this {!Proxy}
   * @param {!GatewayStatusNotify} model GatewayStatusNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayStatusNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayOrderRequest proxy handler
   * @this {!Proxy}
   * @param {!GatewayOrderRequest} model GatewayOrderRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayOrderRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * GatewayOrderExecutionResponse proxy handler
   * @this {!Proxy}
   * @param {!GatewayOrderExecutionResponse} model GatewayOrderExecutionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_GatewayOrderExecutionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolUpdateNotify} model SymbolUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!SymbolsUpdateNotify} model SymbolsUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_SymbolsUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * execution receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ExecutionVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ExecutionVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionRequestModel.verify(), 'execution.ExecutionVersionRequest validation failed!')

        let fbeBegin = this._ExecutionVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ExecutionVersionRequest(this._ExecutionVersionRequestModel, type, buffer, offset, size)
        this._ExecutionVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ExecutionVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ExecutionVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionResponseModel.verify(), 'execution.ExecutionVersionResponse validation failed!')

        let fbeBegin = this._ExecutionVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ExecutionVersionResponse(this._ExecutionVersionResponseModel, type, buffer, offset, size)
        this._ExecutionVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ExecutionUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ExecutionUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionUpdateNotifyModel.verify(), 'execution.ExecutionUpdateNotify validation failed!')

        let fbeBegin = this._ExecutionUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ExecutionUpdateNotify(this._ExecutionUpdateNotifyModel, type, buffer, offset, size)
        this._ExecutionUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayModulesGetRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayModulesGetRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetRequestModel.verify(), 'execution.GatewayModulesGetRequest validation failed!')

        let fbeBegin = this._GatewayModulesGetRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayModulesGetRequest(this._GatewayModulesGetRequestModel, type, buffer, offset, size)
        this._GatewayModulesGetRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayModulesGetResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayModulesGetResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetResponseModel.verify(), 'execution.GatewayModulesGetResponse validation failed!')

        let fbeBegin = this._GatewayModulesGetResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayModulesGetResponse(this._GatewayModulesGetResponseModel, type, buffer, offset, size)
        this._GatewayModulesGetResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayModuleUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayModuleUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModuleUpdateNotifyModel.verify(), 'execution.GatewayModuleUpdateNotify validation failed!')

        let fbeBegin = this._GatewayModuleUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayModuleUpdateNotify(this._GatewayModuleUpdateNotifyModel, type, buffer, offset, size)
        this._GatewayModuleUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayStartRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayStartRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStartRequestModel.verify(), 'execution.GatewayStartRequest validation failed!')

        let fbeBegin = this._GatewayStartRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayStartRequest(this._GatewayStartRequestModel, type, buffer, offset, size)
        this._GatewayStartRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayStopRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayStopRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStopRequestModel.verify(), 'execution.GatewayStopRequest validation failed!')

        let fbeBegin = this._GatewayStopRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayStopRequest(this._GatewayStopRequestModel, type, buffer, offset, size)
        this._GatewayStopRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayShutdownRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayShutdownRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayShutdownRequestModel.verify(), 'execution.GatewayShutdownRequest validation failed!')

        let fbeBegin = this._GatewayShutdownRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayShutdownRequest(this._GatewayShutdownRequestModel, type, buffer, offset, size)
        this._GatewayShutdownRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayStatusNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayStatusNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStatusNotifyModel.verify(), 'execution.GatewayStatusNotify validation failed!')

        let fbeBegin = this._GatewayStatusNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayStatusNotify(this._GatewayStatusNotifyModel, type, buffer, offset, size)
        this._GatewayStatusNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayOrderRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayOrderRequestModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderRequestModel.verify(), 'execution.GatewayOrderRequest validation failed!')

        let fbeBegin = this._GatewayOrderRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayOrderRequest(this._GatewayOrderRequestModel, type, buffer, offset, size)
        this._GatewayOrderRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case GatewayOrderExecutionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._GatewayOrderExecutionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderExecutionResponseModel.verify(), 'execution.GatewayOrderExecutionResponse validation failed!')

        let fbeBegin = this._GatewayOrderExecutionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_GatewayOrderExecutionResponse(this._GatewayOrderExecutionResponseModel, type, buffer, offset, size)
        this._GatewayOrderExecutionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyModel.verify(), 'execution.SymbolUpdateNotify validation failed!')

        let fbeBegin = this._SymbolUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolUpdateNotify(this._SymbolUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._SymbolsUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyModel.verify(), 'execution.SymbolsUpdateNotify validation failed!')

        let fbeBegin = this._SymbolsUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_SymbolsUpdateNotify(this._SymbolsUpdateNotifyModel, type, buffer, offset, size)
        this._SymbolsUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding execution client
 */
class Client extends fbe.Client {
  /**
   * Initialize execution client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._ExecutionVersionRequestSenderModel = new ExecutionVersionRequestModel(this.sendBuffer)
    this._ExecutionVersionRequestReceiverValue = new ExecutionVersionRequest()
    this._ExecutionVersionRequestReceiverModel = new ExecutionVersionRequestModel()
    this._ExecutionVersionResponseSenderModel = new ExecutionVersionResponseModel(this.sendBuffer)
    this._ExecutionVersionResponseReceiverValue = new ExecutionVersionResponse()
    this._ExecutionVersionResponseReceiverModel = new ExecutionVersionResponseModel()
    this._ExecutionUpdateNotifySenderModel = new ExecutionUpdateNotifyModel(this.sendBuffer)
    this._ExecutionUpdateNotifyReceiverValue = new ExecutionUpdateNotify()
    this._ExecutionUpdateNotifyReceiverModel = new ExecutionUpdateNotifyModel()
    this._GatewayModulesGetRequestSenderModel = new GatewayModulesGetRequestModel(this.sendBuffer)
    this._GatewayModulesGetRequestReceiverValue = new GatewayModulesGetRequest()
    this._GatewayModulesGetRequestReceiverModel = new GatewayModulesGetRequestModel()
    this._GatewayModulesGetResponseSenderModel = new GatewayModulesGetResponseModel(this.sendBuffer)
    this._GatewayModulesGetResponseReceiverValue = new GatewayModulesGetResponse()
    this._GatewayModulesGetResponseReceiverModel = new GatewayModulesGetResponseModel()
    this._GatewayModuleUpdateNotifySenderModel = new GatewayModuleUpdateNotifyModel(this.sendBuffer)
    this._GatewayModuleUpdateNotifyReceiverValue = new GatewayModuleUpdateNotify()
    this._GatewayModuleUpdateNotifyReceiverModel = new GatewayModuleUpdateNotifyModel()
    this._GatewayStartRequestSenderModel = new GatewayStartRequestModel(this.sendBuffer)
    this._GatewayStartRequestReceiverValue = new GatewayStartRequest()
    this._GatewayStartRequestReceiverModel = new GatewayStartRequestModel()
    this._GatewayStopRequestSenderModel = new GatewayStopRequestModel(this.sendBuffer)
    this._GatewayStopRequestReceiverValue = new GatewayStopRequest()
    this._GatewayStopRequestReceiverModel = new GatewayStopRequestModel()
    this._GatewayShutdownRequestSenderModel = new GatewayShutdownRequestModel(this.sendBuffer)
    this._GatewayShutdownRequestReceiverValue = new GatewayShutdownRequest()
    this._GatewayShutdownRequestReceiverModel = new GatewayShutdownRequestModel()
    this._GatewayStatusNotifySenderModel = new GatewayStatusNotifyModel(this.sendBuffer)
    this._GatewayStatusNotifyReceiverValue = new GatewayStatusNotify()
    this._GatewayStatusNotifyReceiverModel = new GatewayStatusNotifyModel()
    this._GatewayOrderRequestSenderModel = new GatewayOrderRequestModel(this.sendBuffer)
    this._GatewayOrderRequestReceiverValue = new GatewayOrderRequest()
    this._GatewayOrderRequestReceiverModel = new GatewayOrderRequestModel()
    this._GatewayOrderExecutionResponseSenderModel = new GatewayOrderExecutionResponseModel(this.sendBuffer)
    this._GatewayOrderExecutionResponseReceiverValue = new GatewayOrderExecutionResponse()
    this._GatewayOrderExecutionResponseReceiverModel = new GatewayOrderExecutionResponseModel()
    this._SymbolUpdateNotifySenderModel = new SymbolUpdateNotifyModel(this.sendBuffer)
    this._SymbolUpdateNotifyReceiverValue = new SymbolUpdateNotify()
    this._SymbolUpdateNotifyReceiverModel = new SymbolUpdateNotifyModel()
    this._SymbolsUpdateNotifySenderModel = new SymbolsUpdateNotifyModel(this.sendBuffer)
    this._SymbolsUpdateNotifyReceiverValue = new SymbolsUpdateNotify()
    this._SymbolsUpdateNotifyReceiverModel = new SymbolsUpdateNotifyModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get ExecutionVersionRequest model
   * @this {!Client}
   * @returns {!ExecutionVersionRequestModel} ExecutionVersionRequest sender model
   */
  get ExecutionVersionRequestSenderModel () {
    return this._ExecutionVersionRequestSenderModel
  }

  /**
   * Get ExecutionVersionResponse model
   * @this {!Client}
   * @returns {!ExecutionVersionResponseModel} ExecutionVersionResponse sender model
   */
  get ExecutionVersionResponseSenderModel () {
    return this._ExecutionVersionResponseSenderModel
  }

  /**
   * Get ExecutionUpdateNotify model
   * @this {!Client}
   * @returns {!ExecutionUpdateNotifyModel} ExecutionUpdateNotify sender model
   */
  get ExecutionUpdateNotifySenderModel () {
    return this._ExecutionUpdateNotifySenderModel
  }

  /**
   * Get GatewayModulesGetRequest model
   * @this {!Client}
   * @returns {!GatewayModulesGetRequestModel} GatewayModulesGetRequest sender model
   */
  get GatewayModulesGetRequestSenderModel () {
    return this._GatewayModulesGetRequestSenderModel
  }

  /**
   * Get GatewayModulesGetResponse model
   * @this {!Client}
   * @returns {!GatewayModulesGetResponseModel} GatewayModulesGetResponse sender model
   */
  get GatewayModulesGetResponseSenderModel () {
    return this._GatewayModulesGetResponseSenderModel
  }

  /**
   * Get GatewayModuleUpdateNotify model
   * @this {!Client}
   * @returns {!GatewayModuleUpdateNotifyModel} GatewayModuleUpdateNotify sender model
   */
  get GatewayModuleUpdateNotifySenderModel () {
    return this._GatewayModuleUpdateNotifySenderModel
  }

  /**
   * Get GatewayStartRequest model
   * @this {!Client}
   * @returns {!GatewayStartRequestModel} GatewayStartRequest sender model
   */
  get GatewayStartRequestSenderModel () {
    return this._GatewayStartRequestSenderModel
  }

  /**
   * Get GatewayStopRequest model
   * @this {!Client}
   * @returns {!GatewayStopRequestModel} GatewayStopRequest sender model
   */
  get GatewayStopRequestSenderModel () {
    return this._GatewayStopRequestSenderModel
  }

  /**
   * Get GatewayShutdownRequest model
   * @this {!Client}
   * @returns {!GatewayShutdownRequestModel} GatewayShutdownRequest sender model
   */
  get GatewayShutdownRequestSenderModel () {
    return this._GatewayShutdownRequestSenderModel
  }

  /**
   * Get GatewayStatusNotify model
   * @this {!Client}
   * @returns {!GatewayStatusNotifyModel} GatewayStatusNotify sender model
   */
  get GatewayStatusNotifySenderModel () {
    return this._GatewayStatusNotifySenderModel
  }

  /**
   * Get GatewayOrderRequest model
   * @this {!Client}
   * @returns {!GatewayOrderRequestModel} GatewayOrderRequest sender model
   */
  get GatewayOrderRequestSenderModel () {
    return this._GatewayOrderRequestSenderModel
  }

  /**
   * Get GatewayOrderExecutionResponse model
   * @this {!Client}
   * @returns {!GatewayOrderExecutionResponseModel} GatewayOrderExecutionResponse sender model
   */
  get GatewayOrderExecutionResponseSenderModel () {
    return this._GatewayOrderExecutionResponseSenderModel
  }

  /**
   * Get SymbolUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolUpdateNotifyModel} SymbolUpdateNotify sender model
   */
  get SymbolUpdateNotifySenderModel () {
    return this._SymbolUpdateNotifySenderModel
  }

  /**
   * Get SymbolsUpdateNotify model
   * @this {!Client}
   * @returns {!SymbolsUpdateNotifyModel} SymbolsUpdateNotify sender model
   */
  get SymbolsUpdateNotifySenderModel () {
    return this._SymbolsUpdateNotifySenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ExecutionVersionRequest) && (value.fbeType === this.ExecutionVersionRequestSenderModel.fbeType)) {
      return this.send_ExecutionVersionRequest(value)
    }
    if ((value instanceof ExecutionVersionResponse) && (value.fbeType === this.ExecutionVersionResponseSenderModel.fbeType)) {
      return this.send_ExecutionVersionResponse(value)
    }
    if ((value instanceof ExecutionUpdateNotify) && (value.fbeType === this.ExecutionUpdateNotifySenderModel.fbeType)) {
      return this.send_ExecutionUpdateNotify(value)
    }
    if ((value instanceof GatewayModulesGetRequest) && (value.fbeType === this.GatewayModulesGetRequestSenderModel.fbeType)) {
      return this.send_GatewayModulesGetRequest(value)
    }
    if ((value instanceof GatewayModulesGetResponse) && (value.fbeType === this.GatewayModulesGetResponseSenderModel.fbeType)) {
      return this.send_GatewayModulesGetResponse(value)
    }
    if ((value instanceof GatewayModuleUpdateNotify) && (value.fbeType === this.GatewayModuleUpdateNotifySenderModel.fbeType)) {
      return this.send_GatewayModuleUpdateNotify(value)
    }
    if ((value instanceof GatewayStartRequest) && (value.fbeType === this.GatewayStartRequestSenderModel.fbeType)) {
      return this.send_GatewayStartRequest(value)
    }
    if ((value instanceof GatewayStopRequest) && (value.fbeType === this.GatewayStopRequestSenderModel.fbeType)) {
      return this.send_GatewayStopRequest(value)
    }
    if ((value instanceof GatewayShutdownRequest) && (value.fbeType === this.GatewayShutdownRequestSenderModel.fbeType)) {
      return this.send_GatewayShutdownRequest(value)
    }
    if ((value instanceof GatewayStatusNotify) && (value.fbeType === this.GatewayStatusNotifySenderModel.fbeType)) {
      return this.send_GatewayStatusNotify(value)
    }
    if ((value instanceof GatewayOrderRequest) && (value.fbeType === this.GatewayOrderRequestSenderModel.fbeType)) {
      return this.send_GatewayOrderRequest(value)
    }
    if ((value instanceof GatewayOrderExecutionResponse) && (value.fbeType === this.GatewayOrderExecutionResponseSenderModel.fbeType)) {
      return this.send_GatewayOrderExecutionResponse(value)
    }
    if ((value instanceof SymbolUpdateNotify) && (value.fbeType === this.SymbolUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolUpdateNotify(value)
    }
    if ((value instanceof SymbolsUpdateNotify) && (value.fbeType === this.SymbolsUpdateNotifySenderModel.fbeType)) {
      return this.send_SymbolsUpdateNotify(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ExecutionVersionRequest value
   * @this {!Client}
   * @param {!ExecutionVersionRequest} value ExecutionVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionVersionRequest serialization failed!')
    console.assert(this.ExecutionVersionRequestSenderModel.verify(), 'execution.ExecutionVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ExecutionVersionResponse value
   * @this {!Client}
   * @param {!ExecutionVersionResponse} value ExecutionVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionVersionResponse serialization failed!')
    console.assert(this.ExecutionVersionResponseSenderModel.verify(), 'execution.ExecutionVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ExecutionUpdateNotify value
   * @this {!Client}
   * @param {!ExecutionUpdateNotify} value ExecutionUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ExecutionUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ExecutionUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.ExecutionUpdateNotify serialization failed!')
    console.assert(this.ExecutionUpdateNotifySenderModel.verify(), 'execution.ExecutionUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModulesGetRequest value
   * @this {!Client}
   * @param {!GatewayModulesGetRequest} value GatewayModulesGetRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModulesGetRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModulesGetRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModulesGetRequest serialization failed!')
    console.assert(this.GatewayModulesGetRequestSenderModel.verify(), 'execution.GatewayModulesGetRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModulesGetResponse value
   * @this {!Client}
   * @param {!GatewayModulesGetResponse} value GatewayModulesGetResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModulesGetResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModulesGetResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModulesGetResponse serialization failed!')
    console.assert(this.GatewayModulesGetResponseSenderModel.verify(), 'execution.GatewayModulesGetResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayModuleUpdateNotify value
   * @this {!Client}
   * @param {!GatewayModuleUpdateNotify} value GatewayModuleUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayModuleUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayModuleUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayModuleUpdateNotify serialization failed!')
    console.assert(this.GatewayModuleUpdateNotifySenderModel.verify(), 'execution.GatewayModuleUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStartRequest value
   * @this {!Client}
   * @param {!GatewayStartRequest} value GatewayStartRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStartRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStartRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStartRequest serialization failed!')
    console.assert(this.GatewayStartRequestSenderModel.verify(), 'execution.GatewayStartRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStopRequest value
   * @this {!Client}
   * @param {!GatewayStopRequest} value GatewayStopRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStopRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStopRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStopRequest serialization failed!')
    console.assert(this.GatewayStopRequestSenderModel.verify(), 'execution.GatewayStopRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayShutdownRequest value
   * @this {!Client}
   * @param {!GatewayShutdownRequest} value GatewayShutdownRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayShutdownRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayShutdownRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayShutdownRequest serialization failed!')
    console.assert(this.GatewayShutdownRequestSenderModel.verify(), 'execution.GatewayShutdownRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayStatusNotify value
   * @this {!Client}
   * @param {!GatewayStatusNotify} value GatewayStatusNotify value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayStatusNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayStatusNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayStatusNotify serialization failed!')
    console.assert(this.GatewayStatusNotifySenderModel.verify(), 'execution.GatewayStatusNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayOrderRequest value
   * @this {!Client}
   * @param {!GatewayOrderRequest} value GatewayOrderRequest value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayOrderRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayOrderRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayOrderRequest serialization failed!')
    console.assert(this.GatewayOrderRequestSenderModel.verify(), 'execution.GatewayOrderRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send GatewayOrderExecutionResponse value
   * @this {!Client}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_GatewayOrderExecutionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.GatewayOrderExecutionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.GatewayOrderExecutionResponse serialization failed!')
    console.assert(this.GatewayOrderExecutionResponseSenderModel.verify(), 'execution.GatewayOrderExecutionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolUpdateNotify value
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.SymbolUpdateNotify serialization failed!')
    console.assert(this.SymbolUpdateNotifySenderModel.verify(), 'execution.SymbolUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send SymbolsUpdateNotify value
   * @this {!Client}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_SymbolsUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.SymbolsUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'execution.SymbolsUpdateNotify serialization failed!')
    console.assert(this.SymbolsUpdateNotifySenderModel.verify(), 'execution.SymbolsUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'execution.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ExecutionVersionRequest receive handler
   * @this {!Client}
   * @param {!ExecutionVersionRequest} value ExecutionVersionRequest received value
   */
  onReceive_ExecutionVersionRequest (value) {}  // eslint-disable-line

  /**
   * ExecutionVersionResponse receive handler
   * @this {!Client}
   * @param {!ExecutionVersionResponse} value ExecutionVersionResponse received value
   */
  onReceive_ExecutionVersionResponse (value) {}  // eslint-disable-line

  /**
   * ExecutionUpdateNotify receive handler
   * @this {!Client}
   * @param {!ExecutionUpdateNotify} value ExecutionUpdateNotify received value
   */
  onReceive_ExecutionUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewayModulesGetRequest receive handler
   * @this {!Client}
   * @param {!GatewayModulesGetRequest} value GatewayModulesGetRequest received value
   */
  onReceive_GatewayModulesGetRequest (value) {}  // eslint-disable-line

  /**
   * GatewayModulesGetResponse receive handler
   * @this {!Client}
   * @param {!GatewayModulesGetResponse} value GatewayModulesGetResponse received value
   */
  onReceive_GatewayModulesGetResponse (value) {}  // eslint-disable-line

  /**
   * GatewayModuleUpdateNotify receive handler
   * @this {!Client}
   * @param {!GatewayModuleUpdateNotify} value GatewayModuleUpdateNotify received value
   */
  onReceive_GatewayModuleUpdateNotify (value) {}  // eslint-disable-line

  /**
   * GatewayStartRequest receive handler
   * @this {!Client}
   * @param {!GatewayStartRequest} value GatewayStartRequest received value
   */
  onReceive_GatewayStartRequest (value) {}  // eslint-disable-line

  /**
   * GatewayStopRequest receive handler
   * @this {!Client}
   * @param {!GatewayStopRequest} value GatewayStopRequest received value
   */
  onReceive_GatewayStopRequest (value) {}  // eslint-disable-line

  /**
   * GatewayShutdownRequest receive handler
   * @this {!Client}
   * @param {!GatewayShutdownRequest} value GatewayShutdownRequest received value
   */
  onReceive_GatewayShutdownRequest (value) {}  // eslint-disable-line

  /**
   * GatewayStatusNotify receive handler
   * @this {!Client}
   * @param {!GatewayStatusNotify} value GatewayStatusNotify received value
   */
  onReceive_GatewayStatusNotify (value) {}  // eslint-disable-line

  /**
   * GatewayOrderRequest receive handler
   * @this {!Client}
   * @param {!GatewayOrderRequest} value GatewayOrderRequest received value
   */
  onReceive_GatewayOrderRequest (value) {}  // eslint-disable-line

  /**
   * GatewayOrderExecutionResponse receive handler
   * @this {!Client}
   * @param {!GatewayOrderExecutionResponse} value GatewayOrderExecutionResponse received value
   */
  onReceive_GatewayOrderExecutionResponse (value) {}  // eslint-disable-line

  /**
   * SymbolUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolUpdateNotify} value SymbolUpdateNotify received value
   */
  onReceive_SymbolUpdateNotify (value) {}  // eslint-disable-line

  /**
   * SymbolsUpdateNotify receive handler
   * @this {!Client}
   * @param {!SymbolsUpdateNotify} value SymbolsUpdateNotify received value
   */
  onReceive_SymbolsUpdateNotify (value) {}  // eslint-disable-line

  /**
   * execution receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ExecutionVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionRequestReceiverModel.verify(), 'execution.ExecutionVersionRequest validation failed!')
        let deserialized = this._ExecutionVersionRequestReceiverModel.deserialize(this._ExecutionVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionVersionRequestReceiverValue)
        this.onReceive_ExecutionVersionRequest(this._ExecutionVersionRequestReceiverValue)
        return true
      }
      case ExecutionVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionVersionResponseReceiverModel.verify(), 'execution.ExecutionVersionResponse validation failed!')
        let deserialized = this._ExecutionVersionResponseReceiverModel.deserialize(this._ExecutionVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionVersionResponseReceiverValue)
        this.onReceive_ExecutionVersionResponse(this._ExecutionVersionResponseReceiverValue)
        return true
      }
      case ExecutionUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ExecutionUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ExecutionUpdateNotifyReceiverModel.verify(), 'execution.ExecutionUpdateNotify validation failed!')
        let deserialized = this._ExecutionUpdateNotifyReceiverModel.deserialize(this._ExecutionUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'execution.ExecutionUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ExecutionUpdateNotifyReceiverValue)
        this.onReceive_ExecutionUpdateNotify(this._ExecutionUpdateNotifyReceiverValue)
        return true
      }
      case GatewayModulesGetRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModulesGetRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetRequestReceiverModel.verify(), 'execution.GatewayModulesGetRequest validation failed!')
        let deserialized = this._GatewayModulesGetRequestReceiverModel.deserialize(this._GatewayModulesGetRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModulesGetRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModulesGetRequestReceiverValue)
        this.onReceive_GatewayModulesGetRequest(this._GatewayModulesGetRequestReceiverValue)
        return true
      }
      case GatewayModulesGetResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModulesGetResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModulesGetResponseReceiverModel.verify(), 'execution.GatewayModulesGetResponse validation failed!')
        let deserialized = this._GatewayModulesGetResponseReceiverModel.deserialize(this._GatewayModulesGetResponseReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModulesGetResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModulesGetResponseReceiverValue)
        this.onReceive_GatewayModulesGetResponse(this._GatewayModulesGetResponseReceiverValue)
        return true
      }
      case GatewayModuleUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayModuleUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayModuleUpdateNotifyReceiverModel.verify(), 'execution.GatewayModuleUpdateNotify validation failed!')
        let deserialized = this._GatewayModuleUpdateNotifyReceiverModel.deserialize(this._GatewayModuleUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayModuleUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayModuleUpdateNotifyReceiverValue)
        this.onReceive_GatewayModuleUpdateNotify(this._GatewayModuleUpdateNotifyReceiverValue)
        return true
      }
      case GatewayStartRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStartRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStartRequestReceiverModel.verify(), 'execution.GatewayStartRequest validation failed!')
        let deserialized = this._GatewayStartRequestReceiverModel.deserialize(this._GatewayStartRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStartRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStartRequestReceiverValue)
        this.onReceive_GatewayStartRequest(this._GatewayStartRequestReceiverValue)
        return true
      }
      case GatewayStopRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStopRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStopRequestReceiverModel.verify(), 'execution.GatewayStopRequest validation failed!')
        let deserialized = this._GatewayStopRequestReceiverModel.deserialize(this._GatewayStopRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStopRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStopRequestReceiverValue)
        this.onReceive_GatewayStopRequest(this._GatewayStopRequestReceiverValue)
        return true
      }
      case GatewayShutdownRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayShutdownRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayShutdownRequestReceiverModel.verify(), 'execution.GatewayShutdownRequest validation failed!')
        let deserialized = this._GatewayShutdownRequestReceiverModel.deserialize(this._GatewayShutdownRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayShutdownRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayShutdownRequestReceiverValue)
        this.onReceive_GatewayShutdownRequest(this._GatewayShutdownRequestReceiverValue)
        return true
      }
      case GatewayStatusNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayStatusNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayStatusNotifyReceiverModel.verify(), 'execution.GatewayStatusNotify validation failed!')
        let deserialized = this._GatewayStatusNotifyReceiverModel.deserialize(this._GatewayStatusNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayStatusNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayStatusNotifyReceiverValue)
        this.onReceive_GatewayStatusNotify(this._GatewayStatusNotifyReceiverValue)
        return true
      }
      case GatewayOrderRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayOrderRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderRequestReceiverModel.verify(), 'execution.GatewayOrderRequest validation failed!')
        let deserialized = this._GatewayOrderRequestReceiverModel.deserialize(this._GatewayOrderRequestReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayOrderRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayOrderRequestReceiverValue)
        this.onReceive_GatewayOrderRequest(this._GatewayOrderRequestReceiverValue)
        return true
      }
      case GatewayOrderExecutionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._GatewayOrderExecutionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._GatewayOrderExecutionResponseReceiverModel.verify(), 'execution.GatewayOrderExecutionResponse validation failed!')
        let deserialized = this._GatewayOrderExecutionResponseReceiverModel.deserialize(this._GatewayOrderExecutionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'execution.GatewayOrderExecutionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._GatewayOrderExecutionResponseReceiverValue)
        this.onReceive_GatewayOrderExecutionResponse(this._GatewayOrderExecutionResponseReceiverValue)
        return true
      }
      case SymbolUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolUpdateNotifyReceiverModel.verify(), 'execution.SymbolUpdateNotify validation failed!')
        let deserialized = this._SymbolUpdateNotifyReceiverModel.deserialize(this._SymbolUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'execution.SymbolUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolUpdateNotifyReceiverValue)
        this.onReceive_SymbolUpdateNotify(this._SymbolUpdateNotifyReceiverValue)
        return true
      }
      case SymbolsUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._SymbolsUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._SymbolsUpdateNotifyReceiverModel.verify(), 'execution.SymbolsUpdateNotify validation failed!')
        let deserialized = this._SymbolsUpdateNotifyReceiverModel.deserialize(this._SymbolsUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'execution.SymbolsUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._SymbolsUpdateNotifyReceiverValue)
        this.onReceive_SymbolsUpdateNotify(this._SymbolsUpdateNotifyReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
