import React, { useContext, useState } from 'react';
import { Button, Checkbox, Col, Row } from 'antd';
import styles from './RowFilters.module.scss';
import { IFilterPropsCheckbox } from './types';
import { FilterValuesContext } from '../TableExt';
import { typedMemo } from '../../../utils';

export const RowFilterCheckbox = typedMemo((props: IFilterPropsCheckbox) => {
    const { values, allOption } = props;
    const filterValues = useContext(FilterValuesContext);
    const [selected, setSelected] = useState<string[]>(
        filterValues.values[props.dataIndex] ? (filterValues.values[props.dataIndex] as string[]) : [],
    );

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setSelected([]);
        props.confirmCallback();
    };

    const onCheckboxChange = (val) => {
        setSelected(val as string[]);
    };

    const onAllOptionChange = ({ target: { checked }}) => {
        if (checked) {
            const compareValues = values.map(item => item.compareValue) as string[];
            setSelected(compareValues)
        }
        else {
            onReset();
        }
    };

    const filter = () => {
        filterValues.setValues({
            ...filterValues.values,
            [props.dataIndex]: selected.length === 0 ? null : selected,
        });
        props.confirmCallback();
    };

    const isAllSelected = selected.length === values.length;

    return props.values.length ? (
        <div className={styles.wrapperCheckbox}>
            <div className={styles.checkboxBlock}>
            {allOption && <Checkbox checked={isAllSelected} onChange={onAllOptionChange}>All</Checkbox>}
            <Checkbox.Group value={selected}  onChange={onCheckboxChange}>
                <Col>
                    {values.map((el) => (
                        <Row key={el.title}>
                            <Checkbox value={el.compareValue}>{el.title}</Checkbox>
                        </Row>
                    ))}
                </Col>
            </Checkbox.Group>
            </div>
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={filter} size="small">
                    OK
                </Button>
            </div>
        </div>
    ) : null;
});
