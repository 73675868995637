import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { ErrorsTracker } from '@api/errorsTracker';
import { UUID } from '@api/fbe/uuid';
import { UInt64 } from '@api/fbe/int64';
import { convertDays } from '@utils/datetime';
import { RegularRule } from '@models/calendars/RegularCalendar';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { DaysOfWeek } from '@api/fbe/core/DaysOfWeek';
import { TradingCalendarRegularRulesGetAllRequest } from '@api/fbe/manager/TradingCalendarRegularRulesGetAllRequest';
import { TradingCalendarRegularRuleDeleteRequest } from '@api/fbe/manager/TradingCalendarRegularRuleDeleteRequest';
import { TradingCalendarRegularRuleCreateRequest } from '@api/fbe/manager/TradingCalendarRegularRuleCreateRequest';
import { TradingCalendarRegularRuleModifyRequest } from '@api/fbe/manager/TradingCalendarRegularRuleModifyRequest';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export class RegularCalendarStore {
    errorTracker = new ErrorsTracker({ title: 'Regular Trading Calendar' });

    constructor() {
        makeAutoObservable(this);
    }

    data: RegularRule[] = [];

    isLoading = false;

    setData(value: RegularRule[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new TradingCalendarRegularRulesGetAllRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setIsLoading(false);
        this.setData(
            data.TradingCalendarRegularRules.map(
                (record): RegularRule => ({
                    id: record.id.toString(),
                    Name: record.Name,
                    Days: record.Days.toString()
                        .split('|')
                        .map((day) => day.slice(0, 3).toUpperCase())
                        .filter((value) => value !== ''),
                    SymbolNames: record.IsDefault ? [] : record.SymbolNames,
                    SymbolGroupNames: record.IsDefault ? [] : record.SymbolGroupNames,
                    IsDefault: record.IsDefault,
                    MarketOpen: +record.MarketOpen.toNumber(),
                    MarketClose: +record.MarketClose.toNumber(),
                    TradeOpen: +record.TradeOpen.toNumber(),
                    TradeClose: +record.TradeClose.toNumber(),
                    Comment: record.Comment,
                    OffTimeRulesIds: record.OffTimeRulesIds.map((rule) => rule.toString()),
                    TimezoneId: record.TimezoneId.toString(),
                }),
            ).sort((a, b) => baseSorter(b.IsDefault, a.IsDefault)),
        );
    }

    @ErrorsTracker.wrapApi()
    async delete(ruleId: string) {
        const request = new TradingCalendarRegularRuleDeleteRequest();
        request.RuleId = UUID.fromString(ruleId);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async create(record: RegularRule) {
        const request = new TradingCalendarRegularRuleCreateRequest();
        request.Name = record.Name;
        request.Days = record.Days ? convertDays(record.Days) : new DaysOfWeek(0);
        request.SymbolNames = record.IsDefault ? [] : record.SymbolNames;
        request.SymbolGroupNames = record.IsDefault ? [] : record.SymbolGroupNames;
        request.IsDefault = record.IsDefault;
        request.Comment = record.Comment;
        if (record.MarketOpen !== null) request.MarketOpen = UInt64.fromNumber(record.MarketOpen);
        if (record.MarketClose !== null) request.MarketClose = UInt64.fromNumber(record.MarketClose);
        if (record.TradeOpen !== null) request.TradeOpen = UInt64.fromNumber(record.TradeOpen);
        if (record.TradeClose !== null) request.TradeClose = UInt64.fromNumber(record.TradeClose);

        request.OffTimeRulesIds = record.OffTimeRulesIds ? record.OffTimeRulesIds.map((id) => UUID.fromString(id)) : [];
        if (record.TimezoneId) request.TimezoneId = UUID.fromString(record.TimezoneId);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(
        record: RegularRule,
        defaultValues: RegularRule | any,
        dirtyFields?: FieldNamesMarkedBoolean<FieldValues>,
    ) {
        const request = new TradingCalendarRegularRuleModifyRequest();
        if (defaultValues.id === null || record.id === null) throw new Error('Not found Rule');
        request.RuleId = UUID.fromString(defaultValues.id);

        request.NewName = record.Name;
        request.NewDays = record.Days ? convertDays(record.Days) : new DaysOfWeek(0);
        request.NewIsDefault = record.IsDefault;

        if (dirtyFields?.SymbolNames) request.NewSymbolNames = record.SymbolNames;
        if (dirtyFields?.SymbolNames && record.SymbolNames.length === 0) request.SymbolNamesReset = true;

        if (dirtyFields?.SymbolGroupNames) request.NewSymbolGroupNames = record.SymbolGroupNames;
        if (dirtyFields?.SymbolGroupNames && record.SymbolGroupNames.length === 0) request.SymbolGroupNamesReset = true;

        if (record.IsDefault) {
            request.SymbolNamesReset = true;
            request.SymbolGroupNamesReset = true;
            request.NewSymbolNames = [];
            request.NewSymbolGroupNames = [];
        }

        request.NewMarketOpen = record.MarketOpen !== null ? UInt64.fromNumber(record.MarketOpen) : null;
        request.NewMarketClose = record.MarketClose !== null ? UInt64.fromNumber(record.MarketClose) : null;
        request.NewTradeOpen = record.TradeOpen !== null ? UInt64.fromNumber(record.TradeOpen) : null;
        request.NewTradeClose = record.TradeClose !== null ? UInt64.fromNumber(record.TradeClose) : null;
        request.NewComment = record.Comment;
        if (dirtyFields?.OffTimeRulesIds) {
            request.IsOffTimeRulesChanged = true;
            request.NewOffTimeRulesIds = record.OffTimeRulesIds.map((id) => UUID.fromString(id));
        }
        if (record.TimezoneId) request.NewTimezoneId = UUID.fromString(record.TimezoneId);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const regularCalendarStore = new RegularCalendarStore();
export { regularCalendarStore };
