import styles from './PagePlaceholder.module.scss';
import React from 'react';
import { Spin } from 'antd';
import { keyMap, t } from '@localization';

export const PagePlaceholder = () => (
    <div className={styles.pageWrapper}>
        <div className={styles.contentHeader} />
        <div className={styles.contentWrapper}>
            <Spin tip={t(keyMap.pages.loading)} />
        </div>
    </div>
);
