import React from 'react';
import { Table } from 'antd';
import { CustomColumnType, SummaryItem } from '../types';
import styles from './SummaryRow.module.scss';

export function getSummaryRow<ContextDataT>(
    data: SummaryItem[] | undefined,
    columns: CustomColumnType<ContextDataT>[],
) {
    if (!data || !data.length) return null;
    const dataToRender = columns.map((col) => data.find((item) => item.key === col.dataIndex)).filter((item) => !!item) as SummaryItem[];

    return (
        <>
            <Table.Summary.Row className={styles.row}>
                {dataToRender.map((item, index) => (
                    <Table.Summary.Cell key={item.key} index={index} align={item.align ?? 'left'}>
                        {item.renderValue ?? item.value}
                    </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
        </>
    );
}
