import { UUID } from '@api/fbe/uuid';
import { ManagerGroup } from '@api/fbe/core/ManagerGroup';
import { ManagerRight } from '@api/fbe/core/ManagerRight';
import { RightsMode } from '@api/fbe/core/RightsMode';
import { ParsedManagerGroup } from '@models';
import { ManagerGroupCreateRequest } from '@api/fbe/manager/ManagerGroupCreateRequest';
import { ManagerGroupModifyRequest } from '@api/fbe/manager/ManagerGroupModifyRequest';
import { adaptManagerRightsFromApi } from '../managers/utils';

export const adaptFromApi = (group: ManagerGroup): ParsedManagerGroup => ({
    id: group.id.toString(),
    SortOrder: group.SortOrder,
    Name: group.Name,
    Comment: group.Comment,
    Description: group.Description,
    IsEnabled: group.IsEnabled,
    IsOTPEnabled: group.IsOTPEnabled,
    ManagerRights: adaptManagerRightsFromApi(group.ManagerRights),
    PlatformId: group.PlatformId.toString(),
});

export const adaptToApiCreate = (
    request: ManagerGroupCreateRequest,
    parsedGroup: ParsedManagerGroup,
): ManagerGroupCreateRequest => {
    request.SortOrder = parsedGroup.SortOrder;
    request.Name = parsedGroup.Name;
    request.Comment = parsedGroup.Comment;
    request.Description = parsedGroup.Description;
    request.IsEnabled = parsedGroup.IsEnabled;
    request.IsOTPEnabled = parsedGroup.IsOTPEnabled;
    request.AccessibleAccountGroups = parsedGroup.ManagerRights ? parsedGroup.ManagerRights.AccountGroups : [];
    request.AccessibleSymbolGroups = parsedGroup.ManagerRights ? parsedGroup.ManagerRights.SymbolGroups : [];
    request.AccountsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Accounts)
        : parsedGroup.ManagerRights;
    request.SymbolsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Symbols)
        : parsedGroup.ManagerRights;
    request.SystemRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.System)
        : parsedGroup.ManagerRights;
    request.ModulesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Modules)
        : parsedGroup.ManagerRights;
    request.PlatformsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Platforms)
        : parsedGroup.ManagerRights;
    request.ManagersRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Managers)
        : parsedGroup.ManagerRights;
    request.CurrenciesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Currencies)
        : parsedGroup.ManagerRights;
    request.CalendarsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Calendars)
        : parsedGroup.ManagerRights;
    request.ProfilesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Profiles)
        : parsedGroup.ManagerRights;
    request.DataFeedsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.DataFeeds)
        : parsedGroup.ManagerRights;
    request.GatewaysRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Gateways)
        : parsedGroup.ManagerRights;
    request.TradingRoutesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.TradingRoutes)
        : parsedGroup.ManagerRights;
    request.QuotesHistoryRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.QuotesHistory)
        : parsedGroup.ManagerRights;
    request.ReportsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Reports)
        : parsedGroup.ManagerRights;
    request.MonitoringRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Monitoring)
        : parsedGroup.ManagerRights;
    request.SecuritySettingsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.SecuritySettings)
        : parsedGroup.ManagerRights;
    request.FirewallRulesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.FirewallRules)
        : parsedGroup.ManagerRights;
    request.Rights = new Set(parsedGroup.ManagerRights?.Rights.map((right) => new ManagerRight(right)) || []);
    request.PlatformId = UUID.fromString(parsedGroup.PlatformId);

    return request;
};

export const adaptToApiModify = (
    request: ManagerGroupModifyRequest,
    parsedGroup: ParsedManagerGroup,
): ManagerGroupModifyRequest => {
    request.ManagerGroupId = UUID.fromString(parsedGroup.id);
    request.NewSortOrder = parsedGroup.SortOrder;
    request.NewName = parsedGroup.Name;
    request.NewComment = parsedGroup.Comment;
    request.NewDescription = parsedGroup.Description;
    request.NewIsEnabled = parsedGroup.IsEnabled;
    request.NewIsOTPEnabled = parsedGroup.IsOTPEnabled;
    request.NewAccessibleAccountGroups = parsedGroup.ManagerRights ? parsedGroup.ManagerRights.AccountGroups : [];
    request.NewAccessibleSymbolGroups = parsedGroup.ManagerRights ? parsedGroup.ManagerRights.SymbolGroups : [];
    request.NewAccountsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Accounts)
        : parsedGroup.ManagerRights;
    request.NewSymbolsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Symbols)
        : parsedGroup.ManagerRights;
    request.NewSystemRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.System)
        : parsedGroup.ManagerRights;
    request.NewModulesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Modules)
        : parsedGroup.ManagerRights;
    request.NewPlatformsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Platforms)
        : parsedGroup.ManagerRights;
    request.NewManagersRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Managers)
        : parsedGroup.ManagerRights;
    request.NewCurrenciesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Currencies)
        : parsedGroup.ManagerRights;
    request.NewCalendarsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Calendars)
        : parsedGroup.ManagerRights;
    request.NewProfilesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Profiles)
        : parsedGroup.ManagerRights;
    request.NewDataFeedsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.DataFeeds)
        : parsedGroup.ManagerRights;
    request.NewGatewaysRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Gateways)
        : parsedGroup.ManagerRights;
    request.NewTradingRoutesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.TradingRoutes)
        : parsedGroup.ManagerRights;
    request.NewQuotesHistoryRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.QuotesHistory)
        : parsedGroup.ManagerRights;
    request.NewReportsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Reports)
        : parsedGroup.ManagerRights;
    request.NewMonitoringRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.Monitoring)
        : parsedGroup.ManagerRights;
    request.NewSecuritySettingsRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.SecuritySettings)
        : parsedGroup.ManagerRights;
    request.NewFirewallRulesRights = parsedGroup.ManagerRights
        ? new RightsMode(parsedGroup.ManagerRights.FirewallRules)
        : parsedGroup.ManagerRights;
    request.NewRights = new Set(parsedGroup.ManagerRights?.Rights.map((right) => new ManagerRight(right)) || []);
    request.NewPlatformId = UUID.fromString(parsedGroup.PlatformId);

    return request;
};
