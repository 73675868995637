import { SecuritySettings } from '@api/fbe/core/SecuritySettings';

export type SecurityProfileModel = {
    id: string;
    Name: string;
    SortOrder: number;
    Description: string;
    IsFirewallEnabled: boolean;
    IsAntifloodEnabled: boolean;
    ConnectionRateThreshold: number;
    FailedLoginRateThreshold: number;
};

export const convertSecuritySettingToModel = (record: SecuritySettings): SecurityProfileModel => ({
    id: record.id.toString(),
    Name: record.Name,
    SortOrder: record.SortOrder,
    Description: record.Description,
    IsFirewallEnabled: record.IsFirewallEnabled,
    IsAntifloodEnabled: record.IsAntifloodEnabled,
    ConnectionRateThreshold: record.ConnectionRateThreshold,
    FailedLoginRateThreshold: record.FailedLoginRateThreshold,
});
