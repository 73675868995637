
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {MessageType} from './MessageType';
import {FieldModelMessageType} from './MessageType';
import {TraceFlags} from './TraceFlags';
import {FieldModelTraceFlags} from './TraceFlags';

/**
 * Message struct
 */
class Message {
  /**
   * Initialize struct
   * @param {!UUID=} id
   * @param {!MessageType=} type
   * @param {!number=} clientLogin
   * @param {!Array=} traceId
   * @param {!Array=} spanId
   * @param {!TraceFlags=} traceFlags
   * @param {!Array=} reserved
   * @param {!UUID=} sessionId
   * @constructor
   */
  constructor (id = UUID.sequential(), type = new MessageType(), clientLogin = new UInt64(0, 0), traceId = [], spanId = [], traceFlags = new TraceFlags(), reserved = [], sessionId = new UUID()) {
    this.id = id
    this.Type = type
    this.ClientLogin = clientLogin
    this.TraceId = traceId
    this.SpanId = spanId
    this.TraceFlags = traceFlags
    this.Reserved = reserved
    this.SessionId = sessionId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Message}
   * @param {!Message} other Other struct
   * @returns {!Message} This struct
   */
  copy (other) {
    if (other.id != null) {
      this.id = new UUID(other.id)
    } else {
      this.id = null
    }
    if (other.Type != null) {
      this.Type = MessageType.fromObject(other.Type)
    } else {
      this.Type = null
    }
    if (other.ClientLogin != null) {
      this.ClientLogin = UInt64.fromNumber(other.ClientLogin)
    } else {
      this.ClientLogin = null
    }
    if (other.TraceId != null) {
      this.TraceId = []
      for (let item of other.TraceId) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.TraceId.push(tempItem)
        } else {
          this.TraceId.push(null)
        }
      }
    } else {
      this.TraceId = null
    }
    if (other.SpanId != null) {
      this.SpanId = []
      for (let item of other.SpanId) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.SpanId.push(tempItem)
        } else {
          this.SpanId.push(null)
        }
      }
    } else {
      this.SpanId = null
    }
    if (other.TraceFlags != null) {
      this.TraceFlags = TraceFlags.fromObject(other.TraceFlags)
    } else {
      this.TraceFlags = null
    }
    if (other.Reserved != null) {
      this.Reserved = []
      for (let item of other.Reserved) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.Reserved.push(tempItem)
        } else {
          this.Reserved.push(null)
        }
      }
    } else {
      this.Reserved = null
    }
    if (other.SessionId != null) {
      this.SessionId = new UUID(other.SessionId)
    } else {
      this.SessionId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Message}
   * @returns {!Message} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new MessageModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new MessageModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Message}
   * @param {!Message} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Message)) {
      throw new TypeError('Instance of Message is required!')
    }
    // noinspection RedundantIfStatementJS
    if (this.id != null) {
      if ((other.id == null) || !this.id.eq(other.id)) {
        return false
      }
    } else if (other.id != null) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Message}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      id: ((this.id != null) ? this.id.toString() : null),
      Type: ((this.Type != null) ? this.Type : null),
      ClientLogin: ((this.ClientLogin != null) ? this.ClientLogin.toNumber() : null),
      TraceId: ((this.TraceId != null) ? Array.from(this.TraceId, item => ((item != null) ? item : null)) : null),
      SpanId: ((this.SpanId != null) ? Array.from(this.SpanId, item => ((item != null) ? item : null)) : null),
      TraceFlags: ((this.TraceFlags != null) ? this.TraceFlags : null),
      Reserved: ((this.Reserved != null) ? Array.from(this.Reserved, item => ((item != null) ? item : null)) : null),
      SessionId: ((this.SessionId != null) ? this.SessionId.toString() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Message.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Message} other Object value
   * @returns {!Message} Created struct
   */
  static fromObject (other) {
    return new Message().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Message}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Message.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Message}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 1
  }
}

export { Message };

/**
 * Fast Binary Encoding Message field model
 */
class FieldModelMessage extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.id = new fbe.FieldModelUUID(buffer, 4 + 4)
    this.Type = new FieldModelMessageType(buffer, this.id.fbeOffset + this.id.fbeSize)
    this.ClientLogin = new fbe.FieldModelUInt64(buffer, this.Type.fbeOffset + this.Type.fbeSize)
    this.TraceId = new fbe.FieldModelArray(new fbe.FieldModelByte(buffer, this.ClientLogin.fbeOffset + this.ClientLogin.fbeSize), buffer, this.ClientLogin.fbeOffset + this.ClientLogin.fbeSize, 16)
    this.SpanId = new fbe.FieldModelArray(new fbe.FieldModelByte(buffer, this.TraceId.fbeOffset + this.TraceId.fbeSize), buffer, this.TraceId.fbeOffset + this.TraceId.fbeSize, 8)
    this.TraceFlags = new FieldModelTraceFlags(buffer, this.SpanId.fbeOffset + this.SpanId.fbeSize)
    this.Reserved = new fbe.FieldModelArray(new fbe.FieldModelByte(buffer, this.TraceFlags.fbeOffset + this.TraceFlags.fbeSize), buffer, this.TraceFlags.fbeOffset + this.TraceFlags.fbeSize, 7)
    this.SessionId = new fbe.FieldModelUUID(buffer, this.Reserved.fbeOffset + this.Reserved.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelMessage}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMessage}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.id.fbeSize + this.Type.fbeSize + this.ClientLogin.fbeSize + this.TraceId.fbeSize + this.SpanId.fbeSize + this.TraceFlags.fbeSize + this.Reserved.fbeSize + this.SessionId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMessage}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.id.fbeExtra + this.Type.fbeExtra + this.ClientLogin.fbeExtra + this.TraceId.fbeExtra + this.SpanId.fbeExtra + this.TraceFlags.fbeExtra + this.Reserved.fbeExtra + this.SessionId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMessage}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMessage.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMessage}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 1
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMessage}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMessage}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.id.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.Type.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Type.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Type.fbeSize

    if ((fbeCurrentSize + this.ClientLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientLogin.fbeSize

    if ((fbeCurrentSize + this.TraceId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TraceId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TraceId.fbeSize

    if ((fbeCurrentSize + this.SpanId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SpanId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SpanId.fbeSize

    if ((fbeCurrentSize + this.TraceFlags.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TraceFlags.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TraceFlags.fbeSize

    if ((fbeCurrentSize + this.Reserved.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Reserved.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reserved.fbeSize

    if ((fbeCurrentSize + this.SessionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SessionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMessage}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMessage}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMessage}
   * @param {!Message} fbeValue Default value, defaults is new Message()
   * @returns {!Message} Message value
   */
  get (fbeValue = new Message()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMessage}
   * @param {!Message} fbeValue Message value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.id.fbeSize) <= fbeStructSize) {
      fbeValue.id = this.id.get(UUID.sequential())
    } else {
      fbeValue.id = UUID.sequential()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.id.fbeSize

    if ((fbeCurrentSize + this.Type.fbeSize) <= fbeStructSize) {
      fbeValue.Type = this.Type.get()
    } else {
      fbeValue.Type = new MessageType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Type.fbeSize

    if ((fbeCurrentSize + this.ClientLogin.fbeSize) <= fbeStructSize) {
      fbeValue.ClientLogin = this.ClientLogin.get()
    } else {
      fbeValue.ClientLogin = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientLogin.fbeSize

    if ((fbeCurrentSize + this.TraceId.fbeSize) <= fbeStructSize) {
      this.TraceId.get(fbeValue.TraceId)
    } else {
      fbeValue.TraceId.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TraceId.fbeSize

    if ((fbeCurrentSize + this.SpanId.fbeSize) <= fbeStructSize) {
      this.SpanId.get(fbeValue.SpanId)
    } else {
      fbeValue.SpanId.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SpanId.fbeSize

    if ((fbeCurrentSize + this.TraceFlags.fbeSize) <= fbeStructSize) {
      fbeValue.TraceFlags = this.TraceFlags.get()
    } else {
      fbeValue.TraceFlags = new TraceFlags()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TraceFlags.fbeSize

    if ((fbeCurrentSize + this.Reserved.fbeSize) <= fbeStructSize) {
      this.Reserved.get(fbeValue.Reserved)
    } else {
      fbeValue.Reserved.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Reserved.fbeSize

    if ((fbeCurrentSize + this.SessionId.fbeSize) <= fbeStructSize) {
      fbeValue.SessionId = this.SessionId.get()
    } else {
      fbeValue.SessionId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SessionId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMessage}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMessage}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMessage}
   * @param {!Message} fbeValue Message value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMessage}
   * @param {Message} fbeValue Message value
   */
  setFields (fbeValue) {
    this.id.set(fbeValue.id)
    this.Type.set(fbeValue.Type)
    this.ClientLogin.set(fbeValue.ClientLogin)
    this.TraceId.set(fbeValue.TraceId)
    this.SpanId.set(fbeValue.SpanId)
    this.TraceFlags.set(fbeValue.TraceFlags)
    this.Reserved.set(fbeValue.Reserved)
    this.SessionId.set(fbeValue.SessionId)
  }
}

export { FieldModelMessage };

/**
 * Fast Binary Encoding Message model
 */
class MessageModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMessage(this.buffer, 4)
  }

  /**
   * Get the Message model
   * @this {!MessageModel}
   * @returns {!FieldModelMessage} model Message model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!MessageModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!MessageModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return MessageModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!MessageModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMessage.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!MessageModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!MessageModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!MessageModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!MessageModel}
   * @param {!Message} value Message value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!MessageModel}
   * @param {!Message} value Message value, defaults is new Message()
   * @return {!object} Deserialized Message value and its size
   */
  deserialize (value = new Message()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Message(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Message(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!MessageModel}
   * @param {!number} prev Previous Message model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { MessageModel };
