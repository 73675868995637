import { makeAutoObservable } from 'mobx';
import { UInt64 } from '@api/fbe/int64';
import { DaysOfWeek } from '@api/fbe/core/DaysOfWeek';
import { OffTimeType } from '@api/fbe/core/OffTimeType';
import {
    convertDays,
    getDateUTCStringFromLocalDate,
    getOffset,
} from '@utils/datetime';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { OffTimeRule } from '@models/calendars/OffTimeCalendar';
import { TradingCalendarOffTimeRulesGetAllRequest } from '@api/fbe/manager/TradingCalendarOffTimeRulesGetAllRequest';
import { TradingCalendarOffTimeRuleCreateRequest } from '@api/fbe/manager/TradingCalendarOffTimeRuleCreateRequest';
import { TradingCalendarOffTimeRuleModifyRequest } from '@api/fbe/manager/TradingCalendarOffTimeRuleModifyRequest';
import { TradingCalendarOffTimeRuleDeleteRequest } from '@api/fbe/manager/TradingCalendarOffTimeRuleDeleteRequest';
import moment from 'moment';

class OffTimeCalendarStore {
    errorTracker = new ErrorsTracker({ title: 'Off-time Calendar' });

    constructor() {
        makeAutoObservable(this);
    }

    data: OffTimeRule[] = [];

    isLoading = false;

    setData(value: OffTimeRule[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        this.setIsLoading(true);
        const request = new TradingCalendarOffTimeRulesGetAllRequest();
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setIsLoading(false);
        this.setData(
            data.TradingCalendarOffTimeRules.map(
                (result): OffTimeRule => ({
                    id: result.id.toString(),
                    Name: result.Name,
                    Comment: result.Comment,
                    IsMarketEnabled: result.IsMarketEnabled,
                    IsTradingEnabled: result.IsTradingEnabled,
                    OffTimeType: result.OffTimeType.valueOf(),
                    Day: result.Day || null,
                    StartTime: +result.StartTime.toNumber(),
                    EndTime: +result.EndTime.toNumber(),
                    WeekDays: result.WeekDays?.toString()
                        .split('|')
                        .map((day) => day.slice(0, 3).toUpperCase())
                        .filter((value) => value !== ''),
                    MonthDay: typeof result.MonthDay === 'number' ? result.MonthDay : undefined,
                    YearMonth: typeof result.YearMonth === 'number' ? result.YearMonth : undefined,
                }),
            ),
        );
    }

    @ErrorsTracker.wrapApi()
    async create(record: OffTimeRule) {
        const request = new TradingCalendarOffTimeRuleCreateRequest();
        request.Name = record.Name;
        request.Comment = record.Comment;
        request.IsMarketEnabled = false;
        request.IsTradingEnabled = false;
        request.OffTimeType = new OffTimeType(record.OffTimeType);
        if (record.Day) {
            const timeString = getDateUTCStringFromLocalDate(record.Day);
            const newDayTimestamp = new Date(timeString).getTime();
            request.Day = new Date(newDayTimestamp);
        }
        if (record.StartTime !== null) request.StartTime = UInt64.fromNumber(record.StartTime);
        if (record.EndTime !== null) request.EndTime = UInt64.fromNumber(record.EndTime);
        request.WeekDays = record.WeekDays ? convertDays(record.WeekDays) : new DaysOfWeek(0);
        request.MonthDay = record.MonthDay;
        request.YearMonth = record.YearMonth;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(record: OffTimeRule) {
        const request = new TradingCalendarOffTimeRuleModifyRequest();
        request.RuleId = UUID.fromString(record.id);
        request.NewName = record.Name;
        request.NewComment = record.Comment;
        request.NewIsMarketEnabled = false;
        request.NewIsTradingEnabled = false;
        request.NewOffTimeType = new OffTimeType(record.OffTimeType);
        if (record.Day) {
            const timeString = getDateUTCStringFromLocalDate(record.Day);
            const newDayTimestamp = new Date(timeString).getTime();
            request.NewDay = new Date(newDayTimestamp);
        }
        if (record.StartTime !== null) request.NewStartTime = UInt64.fromNumber(record.StartTime);
        if (record.EndTime !== null) request.NewEndTime = UInt64.fromNumber(record.EndTime);
        request.NewWeekDays = record.WeekDays ? convertDays(record.WeekDays) : new DaysOfWeek(0);
        request.NewMonthDay = record.MonthDay;
        request.NewYearMonth = record.YearMonth;
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new TradingCalendarOffTimeRuleDeleteRequest();
        request.RuleId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const offTimeCalendarStore = new OffTimeCalendarStore();
