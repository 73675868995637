import React, { memo } from 'react';
import { RouteComponentProps } from 'react-router';
import { IRouteTree, RouteComponent } from './index';

export const RouteTreeContext = React.createContext({} as any);

export const ProvideRouteProps = ({ children, ...props }: any) => (
    <RouteTreeContext.Provider value={props}>{children}</RouteTreeContext.Provider>
);

type ComponentRouteProps<P = any> = Partial<RouteComponentProps> & {
    children?: React.ReactNode;
    component: RouteComponent<P>;
    route: IRouteTree;
};

export const ConsumeRouteProps = memo((props: ComponentRouteProps) => {
    const { children, component: Component, route } = props;

    return (
        <RouteTreeContext.Consumer>
            {({ children: _, ...additionalProps }) => {
                const componentProps = {
                    ...props,
                    ...additionalProps,
                };

                return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Component {...(route.props ? route.props(componentProps) : componentProps)}>{children}</Component>
                );
            }}
        </RouteTreeContext.Consumer>
    );
});
