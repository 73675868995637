import { ReportsSubscriptionType } from '@api/fbe/core/ReportsSubscriptionType';
import { AccountMargin } from './AccountMargin';
import { Account as AccountApi } from '@api/fbe/core/Account';
import { toDateNotZero } from '@models';
import { FeaturesToggleForceModel, getFeatureToggleForceModel } from '@models/FeaturesToggle';

export enum AccountOperationTypes {
    Balance = 'Balance',
    Credit = 'Credit',
}

export type SocialTradingMasterType = {
    AllocationMethod: number | null;
    AccountMode: number | null;
    CommissionLogin: number | null;
    FeePercent: number | null;
}

export type SocialTradingInvestorType = {
    AllocationMethod: number | null;
    AccountMode: number | null;
    MasterAccountLogin: number | null;
    Lot: number | null;
    MarginLevel: number | null;
    PercentageLossLevel: number | null;
}

export type SocialTradingType = {
    SocialTradingAccountType: number | null;
    SocialTradingMaster: SocialTradingMasterType | null;
    SocialTradingInvestor: SocialTradingInvestorType | null;
}

export type Account = {
    Login: number | null;
    ActualVersion: number | null;
    AccountGroupId: string | null;
    Name: string | null;
    Country: string | null;
    City: string | null;
    State: string | null;
    ZipCode: string | null;
    Address: string | null;
    Phone: string | null;
    Email: string | null;
    Identifier: string | null;
    Status: string | null;
    Comment: string | null;
    Description: string | null;
    IsEnabled: boolean | null;
    IsTradeEnabled: boolean | null;
    AuthenticationType: number | null;
    PasswordDigest: string | null;
    PhonePasswordDigest: string | null;
    AccountingType: number | null;
    AgentAccount: number | null;
    MaxPendingOrders: number | null;
    Created: Date | null;
    Modified: Date | null;
    Archived: Date | null;
    Deleted: Date | null;
    LastLogin: Date | null;
    Group: string | null;
    Platform: string | null;
    ReportsSubscriptionType: number | null;
    MaxOrders: number | null;
    ExternalClientId: string | null;
    ExternalAccountId: string | null;
    LocalLanguageName: string | null;

    AccountMargin: AccountMargin | null;
    ReportsSubscriptionDaily: boolean | null;
    ReportsSubscriptionMonthly: boolean | null;

    FeaturesToggleForce: FeaturesToggleForceModel;
    NewPassword: string | null;
} & SocialTradingType;

export type DepositWithdrawModel = {
    Login: number | null;
    Name: string | null;
    CurrencyId: string | null;
    Balance: number | null;
    OperationType: AccountOperationTypes;
    Value: number | null;
    Comment: string | null;
};

export const getReportsSubscriptionType = (
    ReportsSubscriptionDaily: boolean,
    ReportsSubscriptionMonthly: boolean,
): ReportsSubscriptionType => {
    if (ReportsSubscriptionDaily && ReportsSubscriptionMonthly) return ReportsSubscriptionType.ALL;
    if (ReportsSubscriptionDaily) return ReportsSubscriptionType.DAILY_CONFIRMATIONS_ONLY;
    if (ReportsSubscriptionMonthly) return ReportsSubscriptionType.MONTHLY_STATEMENTS_ONLY;
    return ReportsSubscriptionType.NONE;
};

export const convertAccountRecordToAccountModel = (record: AccountApi, margin: AccountMargin | null): Account => ({
    Login: record.Login.toInt32(),
    ActualVersion: record.ActualVersion.toInt32(),
    AccountGroupId: record.AccountGroupId.toString(),
    Name: record.Name,
    Country: record.Country,
    City: record.City,
    State: record.State,
    ZipCode: record.ZipCode,
    Address: record.Address,
    Phone: record.Phone,
    Email: record.Email,
    Identifier: record.Identifier,
    Status: record.Status,
    Comment: record.Comment,
    Description: record.Description,
    IsEnabled: record.IsEnabled ?? null,
    IsTradeEnabled: record.IsTradeEnabled ?? null,
    AuthenticationType: record.AuthenticationType.valueOf(),
    PasswordDigest: record.PasswordDigest,
    PhonePasswordDigest: record.PhonePasswordDigest,
    AccountingType: record.AccountingType.valueOf(),
    AgentAccount: record.AgentAccount?.toInt32() ?? null,
    MaxPendingOrders: record.MaxPendingOrders.toInt32(),
    Created: toDateNotZero(record.Created),
    Modified: toDateNotZero(record.Modified),
    Archived: toDateNotZero(record.Archived),
    Deleted: toDateNotZero(record.Deleted),
    LastLogin: toDateNotZero(record.LastLogin),
    Group: record.Group,
    Platform: record.Platform,
    ReportsSubscriptionType: record.ReportsSubscriptionType.valueOf(),
    MaxOrders: record.MaxOrders.toInt32(),
    ExternalAccountId: record.ExternalAccountId ?? null,
    ExternalClientId: record.ExternalClientId ?? null,
    LocalLanguageName: record.LocalLanguageName,
    NewPassword: null,

    AccountMargin: margin,
    ReportsSubscriptionDaily:
        ReportsSubscriptionType.ALL.eq(record.ReportsSubscriptionType) ||
        ReportsSubscriptionType.DAILY_CONFIRMATIONS_ONLY.eq(record.ReportsSubscriptionType),
    ReportsSubscriptionMonthly:
        ReportsSubscriptionType.ALL.eq(record.ReportsSubscriptionType) ||
        ReportsSubscriptionType.MONTHLY_STATEMENTS_ONLY.eq(record.ReportsSubscriptionType),
    FeaturesToggleForce: {
        MultipleDashboards: getFeatureToggleForceModel(record.FeaturesToggleForce.MultipleDashboards),
    },
    SocialTradingAccountType: record.SocialTradingAccountType.valueOf(),
    SocialTradingMaster: record.SocialTradingMaster
        ? {
              AllocationMethod: record.SocialTradingMaster.AllocationMethod.valueOf(),
              AccountMode: record.SocialTradingMaster.AccountMode.valueOf(),
              CommissionLogin: record.SocialTradingMaster.CommissionLogin.toInt32() ?? null,
              FeePercent: record.SocialTradingMaster.FeePercent,
          }
        : null,
    SocialTradingInvestor: record.SocialTradingInvestor ? {
        AllocationMethod:record.SocialTradingInvestor.AllocationMethod.valueOf(),
        AccountMode: record.SocialTradingInvestor.AccountMode.valueOf(),
        MasterAccountLogin: record.SocialTradingInvestor.MasterAccountLogin?.toInt32() ?? null,
        Lot: record.SocialTradingInvestor.Lot,
        MarginLevel: record.SocialTradingInvestor.MarginLevel,
        PercentageLossLevel: record.SocialTradingInvestor.PercentageLossLevel,
    } : null,
});
