
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderUpdateWithTypeResponse} from './OrderUpdateWithTypeResponse';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';
import {OrderSide} from '../core/OrderSide';
import {FieldModelOrderSide} from '../core/OrderSide';

/**
 * OrderUpdateWithTypeRequest struct
 */
class OrderUpdateWithTypeRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {number=} orderId
   * @param {string=} clientOrderId
   * @param {string=} newClientOrderId
   * @param {number=} newPrice
   * @param {!boolean=} newPriceReset
   * @param {number=} newStopPrice
   * @param {!boolean=} newStopPriceReset
   * @param {number=} newTakeProfit
   * @param {!boolean=} newTakeProfitReset
   * @param {number=} newStopLoss
   * @param {!boolean=} newStopLossReset
   * @param {number=} newAmount
   * @param {Date=} newExpiration
   * @param {!boolean=} newExpirationReset
   * @param {string=} newComment
   * @param {number=} newMagic
   * @param {!boolean=} newMagicReset
   * @param {OrderType=} newType
   * @param {OrderSide=} newSide
   * @constructor
   */
  constructor (parent = new Request(), orderId = null, clientOrderId = null, newClientOrderId = null, newPrice = null, newPriceReset = false, newStopPrice = null, newStopPriceReset = false, newTakeProfit = null, newTakeProfitReset = false, newStopLoss = null, newStopLossReset = false, newAmount = null, newExpiration = null, newExpirationReset = false, newComment = null, newMagic = null, newMagicReset = false, newType = null, newSide = null) {
    super()
    super.copy(parent)
    this.OrderId = orderId
    this.ClientOrderId = clientOrderId
    this.NewClientOrderId = newClientOrderId
    this.NewPrice = newPrice
    this.NewPriceReset = newPriceReset
    this.NewStopPrice = newStopPrice
    this.NewStopPriceReset = newStopPriceReset
    this.NewTakeProfit = newTakeProfit
    this.NewTakeProfitReset = newTakeProfitReset
    this.NewStopLoss = newStopLoss
    this.NewStopLossReset = newStopLossReset
    this.NewAmount = newAmount
    this.NewExpiration = newExpiration
    this.NewExpirationReset = newExpirationReset
    this.NewComment = newComment
    this.NewMagic = newMagic
    this.NewMagicReset = newMagicReset
    this.NewType = newType
    this.NewSide = newSide
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderUpdateWithTypeRequest}
   * @param {!OrderUpdateWithTypeRequest} other Other struct
   * @returns {!OrderUpdateWithTypeRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.OrderId != null) {
      this.OrderId = UInt64.fromNumber(other.OrderId)
    } else {
      this.OrderId = null
    }
    if (other.ClientOrderId != null) {
      this.ClientOrderId = other.ClientOrderId
    } else {
      this.ClientOrderId = null
    }
    if (other.NewClientOrderId != null) {
      this.NewClientOrderId = other.NewClientOrderId
    } else {
      this.NewClientOrderId = null
    }
    if (other.NewPrice != null) {
      this.NewPrice = other.NewPrice
    } else {
      this.NewPrice = null
    }
    if (other.NewPriceReset != null) {
      this.NewPriceReset = other.NewPriceReset
    } else {
      this.NewPriceReset = null
    }
    if (other.NewStopPrice != null) {
      this.NewStopPrice = other.NewStopPrice
    } else {
      this.NewStopPrice = null
    }
    if (other.NewStopPriceReset != null) {
      this.NewStopPriceReset = other.NewStopPriceReset
    } else {
      this.NewStopPriceReset = null
    }
    if (other.NewTakeProfit != null) {
      this.NewTakeProfit = other.NewTakeProfit
    } else {
      this.NewTakeProfit = null
    }
    if (other.NewTakeProfitReset != null) {
      this.NewTakeProfitReset = other.NewTakeProfitReset
    } else {
      this.NewTakeProfitReset = null
    }
    if (other.NewStopLoss != null) {
      this.NewStopLoss = other.NewStopLoss
    } else {
      this.NewStopLoss = null
    }
    if (other.NewStopLossReset != null) {
      this.NewStopLossReset = other.NewStopLossReset
    } else {
      this.NewStopLossReset = null
    }
    if (other.NewAmount != null) {
      this.NewAmount = other.NewAmount
    } else {
      this.NewAmount = null
    }
    if (other.NewExpiration != null) {
      if (other.NewExpiration instanceof Date) {
        this.NewExpiration = new Date(other.NewExpiration.getTime())
      } else {
        this.NewExpiration = new Date(Math.round(other.NewExpiration / 1000000))
      }
    } else {
      this.NewExpiration = null
    }
    if (other.NewExpirationReset != null) {
      this.NewExpirationReset = other.NewExpirationReset
    } else {
      this.NewExpirationReset = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewMagic != null) {
      this.NewMagic = other.NewMagic
    } else {
      this.NewMagic = null
    }
    if (other.NewMagicReset != null) {
      this.NewMagicReset = other.NewMagicReset
    } else {
      this.NewMagicReset = null
    }
    if (other.NewType != null) {
      this.NewType = OrderType.fromObject(other.NewType)
    } else {
      this.NewType = null
    }
    if (other.NewSide != null) {
      this.NewSide = OrderSide.fromObject(other.NewSide)
    } else {
      this.NewSide = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderUpdateWithTypeRequest}
   * @returns {!OrderUpdateWithTypeRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderUpdateWithTypeRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderUpdateWithTypeRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderUpdateWithTypeRequest}
   * @param {!OrderUpdateWithTypeRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderUpdateWithTypeRequest)) {
      throw new TypeError('Instance of OrderUpdateWithTypeRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderUpdateWithTypeRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      OrderId: ((this.OrderId != null) ? this.OrderId.toNumber() : null),
      ClientOrderId: ((this.ClientOrderId != null) ? this.ClientOrderId : null),
      NewClientOrderId: ((this.NewClientOrderId != null) ? this.NewClientOrderId : null),
      NewPrice: ((this.NewPrice != null) ? this.NewPrice : null),
      NewPriceReset: ((this.NewPriceReset != null) ? this.NewPriceReset : null),
      NewStopPrice: ((this.NewStopPrice != null) ? this.NewStopPrice : null),
      NewStopPriceReset: ((this.NewStopPriceReset != null) ? this.NewStopPriceReset : null),
      NewTakeProfit: ((this.NewTakeProfit != null) ? this.NewTakeProfit : null),
      NewTakeProfitReset: ((this.NewTakeProfitReset != null) ? this.NewTakeProfitReset : null),
      NewStopLoss: ((this.NewStopLoss != null) ? this.NewStopLoss : null),
      NewStopLossReset: ((this.NewStopLossReset != null) ? this.NewStopLossReset : null),
      NewAmount: ((this.NewAmount != null) ? this.NewAmount : null),
      NewExpiration: ((this.NewExpiration != null) ? (this.NewExpiration.getTime() * 1000000) : null),
      NewExpirationReset: ((this.NewExpirationReset != null) ? this.NewExpirationReset : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewMagic: ((this.NewMagic != null) ? this.NewMagic : null),
      NewMagicReset: ((this.NewMagicReset != null) ? this.NewMagicReset : null),
      NewType: ((this.NewType != null) ? this.NewType : null),
      NewSide: ((this.NewSide != null) ? this.NewSide : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderUpdateWithTypeRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderUpdateWithTypeRequest} other Object value
   * @returns {!OrderUpdateWithTypeRequest} Created struct
   */
  static fromObject (other) {
    return new OrderUpdateWithTypeRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderUpdateWithTypeRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderUpdateWithTypeRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderUpdateWithTypeRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5217
  }
}

export { OrderUpdateWithTypeRequest };

/**
 * Fast Binary Encoding OrderUpdateWithTypeRequest field model
 */
class FieldModelOrderUpdateWithTypeRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.OrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.ClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize), buffer, this.OrderId.fbeOffset + this.OrderId.fbeSize)
    this.NewClientOrderId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize), buffer, this.ClientOrderId.fbeOffset + this.ClientOrderId.fbeSize)
    this.NewPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewClientOrderId.fbeOffset + this.NewClientOrderId.fbeSize), buffer, this.NewClientOrderId.fbeOffset + this.NewClientOrderId.fbeSize)
    this.NewPriceReset = new fbe.FieldModelBool(buffer, this.NewPrice.fbeOffset + this.NewPrice.fbeSize)
    this.NewStopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewPriceReset.fbeOffset + this.NewPriceReset.fbeSize), buffer, this.NewPriceReset.fbeOffset + this.NewPriceReset.fbeSize)
    this.NewStopPriceReset = new fbe.FieldModelBool(buffer, this.NewStopPrice.fbeOffset + this.NewStopPrice.fbeSize)
    this.NewTakeProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewStopPriceReset.fbeOffset + this.NewStopPriceReset.fbeSize), buffer, this.NewStopPriceReset.fbeOffset + this.NewStopPriceReset.fbeSize)
    this.NewTakeProfitReset = new fbe.FieldModelBool(buffer, this.NewTakeProfit.fbeOffset + this.NewTakeProfit.fbeSize)
    this.NewStopLoss = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewTakeProfitReset.fbeOffset + this.NewTakeProfitReset.fbeSize), buffer, this.NewTakeProfitReset.fbeOffset + this.NewTakeProfitReset.fbeSize)
    this.NewStopLossReset = new fbe.FieldModelBool(buffer, this.NewStopLoss.fbeOffset + this.NewStopLoss.fbeSize)
    this.NewAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewStopLossReset.fbeOffset + this.NewStopLossReset.fbeSize), buffer, this.NewStopLossReset.fbeOffset + this.NewStopLossReset.fbeSize)
    this.NewExpiration = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize), buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize)
    this.NewExpirationReset = new fbe.FieldModelBool(buffer, this.NewExpiration.fbeOffset + this.NewExpiration.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExpirationReset.fbeOffset + this.NewExpirationReset.fbeSize), buffer, this.NewExpirationReset.fbeOffset + this.NewExpirationReset.fbeSize)
    this.NewMagic = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewMagicReset = new fbe.FieldModelBool(buffer, this.NewMagic.fbeOffset + this.NewMagic.fbeSize)
    this.NewType = new fbe.FieldModelOptional(new FieldModelOrderType(buffer, this.NewMagicReset.fbeOffset + this.NewMagicReset.fbeSize), buffer, this.NewMagicReset.fbeOffset + this.NewMagicReset.fbeSize)
    this.NewSide = new fbe.FieldModelOptional(new FieldModelOrderSide(buffer, this.NewType.fbeOffset + this.NewType.fbeSize), buffer, this.NewType.fbeOffset + this.NewType.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.OrderId.fbeSize + this.ClientOrderId.fbeSize + this.NewClientOrderId.fbeSize + this.NewPrice.fbeSize + this.NewPriceReset.fbeSize + this.NewStopPrice.fbeSize + this.NewStopPriceReset.fbeSize + this.NewTakeProfit.fbeSize + this.NewTakeProfitReset.fbeSize + this.NewStopLoss.fbeSize + this.NewStopLossReset.fbeSize + this.NewAmount.fbeSize + this.NewExpiration.fbeSize + this.NewExpirationReset.fbeSize + this.NewComment.fbeSize + this.NewMagic.fbeSize + this.NewMagicReset.fbeSize + this.NewType.fbeSize + this.NewSide.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.OrderId.fbeExtra + this.ClientOrderId.fbeExtra + this.NewClientOrderId.fbeExtra + this.NewPrice.fbeExtra + this.NewPriceReset.fbeExtra + this.NewStopPrice.fbeExtra + this.NewStopPriceReset.fbeExtra + this.NewTakeProfit.fbeExtra + this.NewTakeProfitReset.fbeExtra + this.NewStopLoss.fbeExtra + this.NewStopLossReset.fbeExtra + this.NewAmount.fbeExtra + this.NewExpiration.fbeExtra + this.NewExpirationReset.fbeExtra + this.NewComment.fbeExtra + this.NewMagic.fbeExtra + this.NewMagicReset.fbeExtra + this.NewType.fbeExtra + this.NewSide.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderUpdateWithTypeRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5217
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewClientOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewClientOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewPriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPriceReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopPriceReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTakeProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfit.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfitReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTakeProfitReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfitReset.fbeSize

    if ((fbeCurrentSize + this.NewStopLoss.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopLoss.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLoss.fbeSize

    if ((fbeCurrentSize + this.NewStopLossReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopLossReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLossReset.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpiration.fbeSize

    if ((fbeCurrentSize + this.NewExpirationReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExpirationReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewMagic.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMagic.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagic.fbeSize

    if ((fbeCurrentSize + this.NewMagicReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMagicReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagicReset.fbeSize

    if ((fbeCurrentSize + this.NewType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewType.fbeSize

    if ((fbeCurrentSize + this.NewSide.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSide.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSide.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!OrderUpdateWithTypeRequest} fbeValue Default value, defaults is new OrderUpdateWithTypeRequest()
   * @returns {!OrderUpdateWithTypeRequest} OrderUpdateWithTypeRequest value
   */
  get (fbeValue = new OrderUpdateWithTypeRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!OrderUpdateWithTypeRequest} fbeValue OrderUpdateWithTypeRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.OrderId.fbeSize) <= fbeStructSize) {
      fbeValue.OrderId = this.OrderId.get()
    } else {
      fbeValue.OrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderId.fbeSize

    if ((fbeCurrentSize + this.ClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.ClientOrderId = this.ClientOrderId.get()
    } else {
      fbeValue.ClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewClientOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.NewClientOrderId = this.NewClientOrderId.get()
    } else {
      fbeValue.NewClientOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewClientOrderId.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewPrice = this.NewPrice.get()
    } else {
      fbeValue.NewPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewPriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewPriceReset = this.NewPriceReset.get()
    } else {
      fbeValue.NewPriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopPrice = this.NewStopPrice.get()
    } else {
      fbeValue.NewStopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPriceReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopPriceReset = this.NewStopPriceReset.get()
    } else {
      fbeValue.NewStopPriceReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPriceReset.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfit.fbeSize) <= fbeStructSize) {
      fbeValue.NewTakeProfit = this.NewTakeProfit.get()
    } else {
      fbeValue.NewTakeProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfit.fbeSize

    if ((fbeCurrentSize + this.NewTakeProfitReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewTakeProfitReset = this.NewTakeProfitReset.get()
    } else {
      fbeValue.NewTakeProfitReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTakeProfitReset.fbeSize

    if ((fbeCurrentSize + this.NewStopLoss.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopLoss = this.NewStopLoss.get()
    } else {
      fbeValue.NewStopLoss = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLoss.fbeSize

    if ((fbeCurrentSize + this.NewStopLossReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopLossReset = this.NewStopLossReset.get()
    } else {
      fbeValue.NewStopLossReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopLossReset.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewAmount = this.NewAmount.get()
    } else {
      fbeValue.NewAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.NewExpiration = this.NewExpiration.get()
    } else {
      fbeValue.NewExpiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpiration.fbeSize

    if ((fbeCurrentSize + this.NewExpirationReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewExpirationReset = this.NewExpirationReset.get()
    } else {
      fbeValue.NewExpirationReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewMagic.fbeSize) <= fbeStructSize) {
      fbeValue.NewMagic = this.NewMagic.get()
    } else {
      fbeValue.NewMagic = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagic.fbeSize

    if ((fbeCurrentSize + this.NewMagicReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMagicReset = this.NewMagicReset.get()
    } else {
      fbeValue.NewMagicReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMagicReset.fbeSize

    if ((fbeCurrentSize + this.NewType.fbeSize) <= fbeStructSize) {
      fbeValue.NewType = this.NewType.get()
    } else {
      fbeValue.NewType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewType.fbeSize

    if ((fbeCurrentSize + this.NewSide.fbeSize) <= fbeStructSize) {
      fbeValue.NewSide = this.NewSide.get()
    } else {
      fbeValue.NewSide = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSide.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {!OrderUpdateWithTypeRequest} fbeValue OrderUpdateWithTypeRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderUpdateWithTypeRequest}
   * @param {OrderUpdateWithTypeRequest} fbeValue OrderUpdateWithTypeRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.OrderId.set(fbeValue.OrderId)
    this.ClientOrderId.set(fbeValue.ClientOrderId)
    this.NewClientOrderId.set(fbeValue.NewClientOrderId)
    this.NewPrice.set(fbeValue.NewPrice)
    this.NewPriceReset.set(fbeValue.NewPriceReset)
    this.NewStopPrice.set(fbeValue.NewStopPrice)
    this.NewStopPriceReset.set(fbeValue.NewStopPriceReset)
    this.NewTakeProfit.set(fbeValue.NewTakeProfit)
    this.NewTakeProfitReset.set(fbeValue.NewTakeProfitReset)
    this.NewStopLoss.set(fbeValue.NewStopLoss)
    this.NewStopLossReset.set(fbeValue.NewStopLossReset)
    this.NewAmount.set(fbeValue.NewAmount)
    this.NewExpiration.set(fbeValue.NewExpiration)
    this.NewExpirationReset.set(fbeValue.NewExpirationReset)
    this.NewComment.set(fbeValue.NewComment)
    this.NewMagic.set(fbeValue.NewMagic)
    this.NewMagicReset.set(fbeValue.NewMagicReset)
    this.NewType.set(fbeValue.NewType)
    this.NewSide.set(fbeValue.NewSide)
  }
}

export { FieldModelOrderUpdateWithTypeRequest };

/**
 * Fast Binary Encoding OrderUpdateWithTypeRequest model
 */
class OrderUpdateWithTypeRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderUpdateWithTypeRequest(this.buffer, 4)
  }

  /**
   * Get the OrderUpdateWithTypeRequest model
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!FieldModelOrderUpdateWithTypeRequest} model OrderUpdateWithTypeRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderUpdateWithTypeRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderUpdateWithTypeRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderUpdateWithTypeRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderUpdateWithTypeRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderUpdateWithTypeRequestModel}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderUpdateWithTypeRequestModel}
   * @param {!OrderUpdateWithTypeRequest} value OrderUpdateWithTypeRequest value, defaults is new OrderUpdateWithTypeRequest()
   * @return {!object} Deserialized OrderUpdateWithTypeRequest value and its size
   */
  deserialize (value = new OrderUpdateWithTypeRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderUpdateWithTypeRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderUpdateWithTypeRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderUpdateWithTypeRequestModel}
   * @param {!number} prev Previous OrderUpdateWithTypeRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderUpdateWithTypeRequestModel };
OrderUpdateWithTypeRequest.__has_response = true;
OrderUpdateWithTypeRequest.__response_class = OrderUpdateWithTypeResponse;
