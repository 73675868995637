/* eslint-disable no-useless-escape */
import { keyMap, t } from '@localization';
import * as yup from 'yup';

const shouldSkipTesting = (val: any | null | undefined) => val === 'undefined' || val === null;

export const checkStringByRegExp = (regExp: RegExp, message: string) =>
    yup.string().test('checkStringByRegExp', message, (value) => (value ? !!value.match(regExp) : true));

export const spacesStartNotAllowed = () =>
    yup.string().test({
        name: 'spacesStartNotAllowed',
        message: t(keyMap.validations.spacesStartNotAllowed),
        test: (value: string | undefined | null) => {
            if (shouldSkipTesting(value)) return true;
            return typeof value === 'string' ? value[0] !== ' ' : true;
        },
    });

export const spacesEndNotAllowed = () =>
    yup.string().test({
        name: 'spacesEndNotAllowed',
        message: t(keyMap.validations.spacesEndNotAllowed),
        test: (value: string | undefined | null) => {
            if (shouldSkipTesting(value)) return true;
            return typeof value === 'string' && !!value.length ? value[value.length - 1] !== ' ' : true;
        },
    });
