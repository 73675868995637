import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { convertFirewallRuleMToModel, convertStringIPtoNumArray, FirewallRuleModel } from '@models/security/Firewall';
import { FirewallRuleModifyRequest } from '@api/fbe/manager/FirewallRuleModifyRequest';
import { FirewallRulesGetAllRequest } from '@api/fbe/manager/FirewallRulesGetAllRequest';
import { FirewallRuleCreateRequest } from '@api/fbe/manager/FirewallRuleCreateRequest';
import { FirewallActionType } from '@api/fbe/core/FirewallActionType';
import { IPv4Range } from '@api/fbe/core/IPv4Range';
import { FirewallRuleDeleteRequest } from '@api/fbe/manager/FirewallRuleDeleteRequest';

export class FirewallStore {
    errorTracker = new ErrorsTracker({ title: 'Firewall' });

    constructor() {
        makeAutoObservable(this);
    }

    data: FirewallRuleModel[] = [];

    isLoading = false;

    setData(value: FirewallRuleModel[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new FirewallRuleModifyRequest();
            request.FirewallRuleId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error("Can't find Firewall Rule to reorder");
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new FirewallRulesGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(data.FirewallRules.map(convertFirewallRuleMToModel));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newRecord: FirewallRuleModel) {
        const request = new FirewallRuleCreateRequest();

        request.SortOrder = newRecord.SortOrder;
        request.Name = newRecord.Name;
        request.Description = newRecord.Description;
        request.FirewallActionType = new FirewallActionType(newRecord.ActionType);
        request.IPv4Range = new IPv4Range(
            convertStringIPtoNumArray(newRecord.IPv4Range.FromInclusive),
            convertStringIPtoNumArray(newRecord.IPv4Range.ToInclusive),
        );

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(updatedRecord: FirewallRuleModel, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new FirewallRuleModifyRequest();

        request.FirewallRuleId = UUID.fromString(updatedRecord.id);
        request.NewSortOrder = updatedRecord.SortOrder;
        request.NewName = updatedRecord.Name;
        request.NewDescription = updatedRecord.Description;
        request.NewFirewallActionType = new FirewallActionType(updatedRecord.ActionType);
        request.NewIPv4Range = new IPv4Range(
            convertStringIPtoNumArray(updatedRecord.IPv4Range.FromInclusive),
            convertStringIPtoNumArray(updatedRecord.IPv4Range.ToInclusive),
        );

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: UUID) {
        const request = new FirewallRuleDeleteRequest();
        request.FirewallRuleId = id;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const firewallStore = new FirewallStore();
export { firewallStore };
