
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {OrderMarginSubscribeResponse} from './OrderMarginSubscribeResponse';
import {OrderType} from '../core/OrderType';
import {FieldModelOrderType} from '../core/OrderType';

/**
 * OrderMarginUpdateSubscriptionRequest struct
 */
class OrderMarginUpdateSubscriptionRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {string=} newSymbol
   * @param {OrderType=} newOrderType
   * @param {number=} newAmount
   * @param {number=} newPrice
   * @param {number=} newStopPrice
   * @constructor
   */
  constructor (parent = new Request(), newSymbol = null, newOrderType = null, newAmount = null, newPrice = null, newStopPrice = null) {
    super()
    super.copy(parent)
    this.NewSymbol = newSymbol
    this.NewOrderType = newOrderType
    this.NewAmount = newAmount
    this.NewPrice = newPrice
    this.NewStopPrice = newStopPrice
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @param {!OrderMarginUpdateSubscriptionRequest} other Other struct
   * @returns {!OrderMarginUpdateSubscriptionRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.NewSymbol != null) {
      this.NewSymbol = other.NewSymbol
    } else {
      this.NewSymbol = null
    }
    if (other.NewOrderType != null) {
      this.NewOrderType = OrderType.fromObject(other.NewOrderType)
    } else {
      this.NewOrderType = null
    }
    if (other.NewAmount != null) {
      this.NewAmount = other.NewAmount
    } else {
      this.NewAmount = null
    }
    if (other.NewPrice != null) {
      this.NewPrice = other.NewPrice
    } else {
      this.NewPrice = null
    }
    if (other.NewStopPrice != null) {
      this.NewStopPrice = other.NewStopPrice
    } else {
      this.NewStopPrice = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @returns {!OrderMarginUpdateSubscriptionRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderMarginUpdateSubscriptionRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderMarginUpdateSubscriptionRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @param {!OrderMarginUpdateSubscriptionRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderMarginUpdateSubscriptionRequest)) {
      throw new TypeError('Instance of OrderMarginUpdateSubscriptionRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      NewSymbol: ((this.NewSymbol != null) ? this.NewSymbol : null),
      NewOrderType: ((this.NewOrderType != null) ? this.NewOrderType : null),
      NewAmount: ((this.NewAmount != null) ? this.NewAmount : null),
      NewPrice: ((this.NewPrice != null) ? this.NewPrice : null),
      NewStopPrice: ((this.NewStopPrice != null) ? this.NewStopPrice : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderMarginUpdateSubscriptionRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderMarginUpdateSubscriptionRequest} other Object value
   * @returns {!OrderMarginUpdateSubscriptionRequest} Created struct
   */
  static fromObject (other) {
    return new OrderMarginUpdateSubscriptionRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderMarginUpdateSubscriptionRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderMarginUpdateSubscriptionRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5141
  }
}

export { OrderMarginUpdateSubscriptionRequest };

/**
 * Fast Binary Encoding OrderMarginUpdateSubscriptionRequest field model
 */
class FieldModelOrderMarginUpdateSubscriptionRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.NewSymbol = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewOrderType = new fbe.FieldModelOptional(new FieldModelOrderType(buffer, this.NewSymbol.fbeOffset + this.NewSymbol.fbeSize), buffer, this.NewSymbol.fbeOffset + this.NewSymbol.fbeSize)
    this.NewAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewOrderType.fbeOffset + this.NewOrderType.fbeSize), buffer, this.NewOrderType.fbeOffset + this.NewOrderType.fbeSize)
    this.NewPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize), buffer, this.NewAmount.fbeOffset + this.NewAmount.fbeSize)
    this.NewStopPrice = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewPrice.fbeOffset + this.NewPrice.fbeSize), buffer, this.NewPrice.fbeOffset + this.NewPrice.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.NewSymbol.fbeSize + this.NewOrderType.fbeSize + this.NewAmount.fbeSize + this.NewPrice.fbeSize + this.NewStopPrice.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.NewSymbol.fbeExtra + this.NewOrderType.fbeExtra + this.NewAmount.fbeExtra + this.NewPrice.fbeExtra + this.NewStopPrice.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderMarginUpdateSubscriptionRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5141
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.NewSymbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbol.fbeSize

    if ((fbeCurrentSize + this.NewOrderType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewOrderType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderType.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopPrice.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!OrderMarginUpdateSubscriptionRequest} fbeValue Default value, defaults is new OrderMarginUpdateSubscriptionRequest()
   * @returns {!OrderMarginUpdateSubscriptionRequest} OrderMarginUpdateSubscriptionRequest value
   */
  get (fbeValue = new OrderMarginUpdateSubscriptionRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!OrderMarginUpdateSubscriptionRequest} fbeValue OrderMarginUpdateSubscriptionRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.NewSymbol.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbol = this.NewSymbol.get()
    } else {
      fbeValue.NewSymbol = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbol.fbeSize

    if ((fbeCurrentSize + this.NewOrderType.fbeSize) <= fbeStructSize) {
      fbeValue.NewOrderType = this.NewOrderType.get()
    } else {
      fbeValue.NewOrderType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewOrderType.fbeSize

    if ((fbeCurrentSize + this.NewAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewAmount = this.NewAmount.get()
    } else {
      fbeValue.NewAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAmount.fbeSize

    if ((fbeCurrentSize + this.NewPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewPrice = this.NewPrice.get()
    } else {
      fbeValue.NewPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrice.fbeSize

    if ((fbeCurrentSize + this.NewStopPrice.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopPrice = this.NewStopPrice.get()
    } else {
      fbeValue.NewStopPrice = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopPrice.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {!OrderMarginUpdateSubscriptionRequest} fbeValue OrderMarginUpdateSubscriptionRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderMarginUpdateSubscriptionRequest}
   * @param {OrderMarginUpdateSubscriptionRequest} fbeValue OrderMarginUpdateSubscriptionRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.NewSymbol.set(fbeValue.NewSymbol)
    this.NewOrderType.set(fbeValue.NewOrderType)
    this.NewAmount.set(fbeValue.NewAmount)
    this.NewPrice.set(fbeValue.NewPrice)
    this.NewStopPrice.set(fbeValue.NewStopPrice)
  }
}

export { FieldModelOrderMarginUpdateSubscriptionRequest };

/**
 * Fast Binary Encoding OrderMarginUpdateSubscriptionRequest model
 */
class OrderMarginUpdateSubscriptionRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderMarginUpdateSubscriptionRequest(this.buffer, 4)
  }

  /**
   * Get the OrderMarginUpdateSubscriptionRequest model
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!FieldModelOrderMarginUpdateSubscriptionRequest} model OrderMarginUpdateSubscriptionRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderMarginUpdateSubscriptionRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderMarginUpdateSubscriptionRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @param {!OrderMarginUpdateSubscriptionRequest} value OrderMarginUpdateSubscriptionRequest value, defaults is new OrderMarginUpdateSubscriptionRequest()
   * @return {!object} Deserialized OrderMarginUpdateSubscriptionRequest value and its size
   */
  deserialize (value = new OrderMarginUpdateSubscriptionRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderMarginUpdateSubscriptionRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderMarginUpdateSubscriptionRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderMarginUpdateSubscriptionRequestModel}
   * @param {!number} prev Previous OrderMarginUpdateSubscriptionRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderMarginUpdateSubscriptionRequestModel };
OrderMarginUpdateSubscriptionRequest.__has_response = true;
OrderMarginUpdateSubscriptionRequest.__response_class = OrderMarginSubscribeResponse;
