import React, { PureComponent } from 'react';
import { withAITracking, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { createApplicationInsights, getApplicationInsightsRef, _setApplicationInsights } from './service';

type TelemetryProviderProps = {
    reactPlugin: ReactPlugin;
    history: History;
    instrumentationKey: string;
    after?: () => void;
};

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends PureComponent<TelemetryProviderProps> {
    state = {
        initialized: false,
    };

    componentDidMount() {
        const { history, instrumentationKey } = this.props;
        const { initialized } = this.state;

        if (!initialized && !!instrumentationKey && !!history) {
            const aiRef = getApplicationInsightsRef();
            if (!aiRef.appInsights) {
                const ai = createApplicationInsights({
                    history,
                    instrumentationKey,
                    reactPlugin: this.props.reactPlugin,
                });
                _setApplicationInsights(ai);
            }
            this.setState({ initialized: true });
        }

        this.props.after && this.props.after();
    }

    render() {
        return <>{this.props.children}</>;
    }
}

type TelemetryProviderMasterProps = {
    instrumentationKey: string;
};

class TelemetryProviderMaster extends PureComponent<TelemetryProviderMasterProps> {
    reactPlugin = new ReactPlugin();

    TelemetryProviderWrapped = withRouter(withAITracking(this.reactPlugin, TelemetryProvider) as any) as any;

    render() {
        return (
            <this.TelemetryProviderWrapped {...this.props} reactPlugin={this.reactPlugin}>
                {this.props.children}
            </this.TelemetryProviderWrapped>
        );
    }
}

export const ApplicationInsightsProvider = TelemetryProviderMaster;
