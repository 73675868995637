import type { FieldData as _OriginalFieldData } from 'rc-field-form/lib/interface';

export * from './Currencies';
export * from './Managers';
export * from './Platforms';
export * from './Symbols';
export * from './Timezones';

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export type Value = number;

export type FieldData = Omit<_OriginalFieldData, 'name'> & { name: string | number };

export type StoreFieldData<ValueType = never> = Omit<_OriginalFieldData, 'name' | 'value'> & {
    value: ValueType;
    error?: string;
};

export const toDateNotZero = (value: Date): Date | null => value.getTime() > 0 ? value : null;
