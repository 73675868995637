
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {MailServerModifyResponse} from './MailServerModifyResponse';
import {SSLMode} from '../core/SSLMode';
import {FieldModelSSLMode} from '../core/SSLMode';
import {EventType} from '../core/EventType';
import {FieldModelEventType} from '../core/EventType';

/**
 * MailServerModifyRequest struct
 */
class MailServerModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} mailServerId
   * @param {string=} mailServerName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {string=} newSMTPServer
   * @param {string=} newSMTPLogin
   * @param {string=} newSMTPPassword
   * @param {string=} newSenderEmail
   * @param {string=} newSenderName
   * @param {SSLMode=} newSSLMode
   * @param {!Set=} newEventTypes
   * @param {!boolean=} eventTypesReset
   * @param {boolean=} newIsEnabled
   * @param {number=} newSMTPServerPort
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), mailServerId = null, mailServerName = null, newName = null, newComment = null, newDescription = null, newSMTPServer = null, newSMTPLogin = null, newSMTPPassword = null, newSenderEmail = null, newSenderName = null, newSSLMode = null, newEventTypes = new Set(), eventTypesReset = false, newIsEnabled = null, newSMTPServerPort = null) {
    super()
    super.copy(parent)
    this.MailServerId = mailServerId
    this.MailServerName = mailServerName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewSMTPServer = newSMTPServer
    this.NewSMTPLogin = newSMTPLogin
    this.NewSMTPPassword = newSMTPPassword
    this.NewSenderEmail = newSenderEmail
    this.NewSenderName = newSenderName
    this.NewSSLMode = newSSLMode
    this.NewEventTypes = newEventTypes
    this.EventTypesReset = eventTypesReset
    this.NewIsEnabled = newIsEnabled
    this.NewSMTPServerPort = newSMTPServerPort
  }

  /**
   * Copy struct (shallow copy)
   * @this {!MailServerModifyRequest}
   * @param {!MailServerModifyRequest} other Other struct
   * @returns {!MailServerModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.MailServerId != null) {
      this.MailServerId = new UUID(other.MailServerId)
    } else {
      this.MailServerId = null
    }
    if (other.MailServerName != null) {
      this.MailServerName = other.MailServerName
    } else {
      this.MailServerName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewSMTPServer != null) {
      this.NewSMTPServer = other.NewSMTPServer
    } else {
      this.NewSMTPServer = null
    }
    if (other.NewSMTPLogin != null) {
      this.NewSMTPLogin = other.NewSMTPLogin
    } else {
      this.NewSMTPLogin = null
    }
    if (other.NewSMTPPassword != null) {
      this.NewSMTPPassword = other.NewSMTPPassword
    } else {
      this.NewSMTPPassword = null
    }
    if (other.NewSenderEmail != null) {
      this.NewSenderEmail = other.NewSenderEmail
    } else {
      this.NewSenderEmail = null
    }
    if (other.NewSenderName != null) {
      this.NewSenderName = other.NewSenderName
    } else {
      this.NewSenderName = null
    }
    if (other.NewSSLMode != null) {
      this.NewSSLMode = SSLMode.fromObject(other.NewSSLMode)
    } else {
      this.NewSSLMode = null
    }
    if (other.NewEventTypes != null) {
      this.NewEventTypes = new Set()
      for (let item of other.NewEventTypes) {
        if (item != null) {
          let tempItem
          tempItem = EventType.fromObject(item)
          this.NewEventTypes.add(tempItem)
        } else {
          this.NewEventTypes.add(null)
        }
      }
    } else {
      this.NewEventTypes = null
    }
    if (other.EventTypesReset != null) {
      this.EventTypesReset = other.EventTypesReset
    } else {
      this.EventTypesReset = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewSMTPServerPort != null) {
      this.NewSMTPServerPort = other.NewSMTPServerPort
    } else {
      this.NewSMTPServerPort = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!MailServerModifyRequest}
   * @returns {!MailServerModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new MailServerModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new MailServerModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!MailServerModifyRequest}
   * @param {!MailServerModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof MailServerModifyRequest)) {
      throw new TypeError('Instance of MailServerModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!MailServerModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      MailServerId: ((this.MailServerId != null) ? this.MailServerId.toString() : null),
      MailServerName: ((this.MailServerName != null) ? this.MailServerName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewSMTPServer: ((this.NewSMTPServer != null) ? this.NewSMTPServer : null),
      NewSMTPLogin: ((this.NewSMTPLogin != null) ? this.NewSMTPLogin : null),
      NewSMTPPassword: ((this.NewSMTPPassword != null) ? this.NewSMTPPassword : null),
      NewSenderEmail: ((this.NewSenderEmail != null) ? this.NewSenderEmail : null),
      NewSenderName: ((this.NewSenderName != null) ? this.NewSenderName : null),
      NewSSLMode: ((this.NewSSLMode != null) ? this.NewSSLMode : null),
      NewEventTypes: ((this.NewEventTypes != null) ? Array.from(this.NewEventTypes, item => ((item != null) ? item : null)) : null),
      EventTypesReset: ((this.EventTypesReset != null) ? this.EventTypesReset : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewSMTPServerPort: ((this.NewSMTPServerPort != null) ? this.NewSMTPServerPort : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return MailServerModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!MailServerModifyRequest} other Object value
   * @returns {!MailServerModifyRequest} Created struct
   */
  static fromObject (other) {
    return new MailServerModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!MailServerModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return MailServerModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!MailServerModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6362
  }
}

export { MailServerModifyRequest };

/**
 * Fast Binary Encoding MailServerModifyRequest field model
 */
class FieldModelMailServerModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.MailServerId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.MailServerName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MailServerId.fbeOffset + this.MailServerId.fbeSize), buffer, this.MailServerId.fbeOffset + this.MailServerId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MailServerName.fbeOffset + this.MailServerName.fbeSize), buffer, this.MailServerName.fbeOffset + this.MailServerName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewSMTPServer = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewSMTPLogin = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSMTPServer.fbeOffset + this.NewSMTPServer.fbeSize), buffer, this.NewSMTPServer.fbeOffset + this.NewSMTPServer.fbeSize)
    this.NewSMTPPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSMTPLogin.fbeOffset + this.NewSMTPLogin.fbeSize), buffer, this.NewSMTPLogin.fbeOffset + this.NewSMTPLogin.fbeSize)
    this.NewSenderEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSMTPPassword.fbeOffset + this.NewSMTPPassword.fbeSize), buffer, this.NewSMTPPassword.fbeOffset + this.NewSMTPPassword.fbeSize)
    this.NewSenderName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSenderEmail.fbeOffset + this.NewSenderEmail.fbeSize), buffer, this.NewSenderEmail.fbeOffset + this.NewSenderEmail.fbeSize)
    this.NewSSLMode = new fbe.FieldModelOptional(new FieldModelSSLMode(buffer, this.NewSenderName.fbeOffset + this.NewSenderName.fbeSize), buffer, this.NewSenderName.fbeOffset + this.NewSenderName.fbeSize)
    this.NewEventTypes = new fbe.FieldModelSet(new FieldModelEventType(buffer, this.NewSSLMode.fbeOffset + this.NewSSLMode.fbeSize), buffer, this.NewSSLMode.fbeOffset + this.NewSSLMode.fbeSize)
    this.EventTypesReset = new fbe.FieldModelBool(buffer, this.NewEventTypes.fbeOffset + this.NewEventTypes.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.EventTypesReset.fbeOffset + this.EventTypesReset.fbeSize), buffer, this.EventTypesReset.fbeOffset + this.EventTypesReset.fbeSize)
    this.NewSMTPServerPort = new fbe.FieldModelOptional(new fbe.FieldModelUInt16(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.MailServerId.fbeSize + this.MailServerName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewSMTPServer.fbeSize + this.NewSMTPLogin.fbeSize + this.NewSMTPPassword.fbeSize + this.NewSenderEmail.fbeSize + this.NewSenderName.fbeSize + this.NewSSLMode.fbeSize + this.NewEventTypes.fbeSize + this.EventTypesReset.fbeSize + this.NewIsEnabled.fbeSize + this.NewSMTPServerPort.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.MailServerId.fbeExtra + this.MailServerName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewSMTPServer.fbeExtra + this.NewSMTPLogin.fbeExtra + this.NewSMTPPassword.fbeExtra + this.NewSenderEmail.fbeExtra + this.NewSenderName.fbeExtra + this.NewSSLMode.fbeExtra + this.NewEventTypes.fbeExtra + this.EventTypesReset.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewSMTPServerPort.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelMailServerModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6362
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.MailServerId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MailServerId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MailServerId.fbeSize

    if ((fbeCurrentSize + this.MailServerName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MailServerName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MailServerName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewSMTPServer.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSMTPServer.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPServer.fbeSize

    if ((fbeCurrentSize + this.NewSMTPLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSMTPLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPLogin.fbeSize

    if ((fbeCurrentSize + this.NewSMTPPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSMTPPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPPassword.fbeSize

    if ((fbeCurrentSize + this.NewSenderEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSenderEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSenderEmail.fbeSize

    if ((fbeCurrentSize + this.NewSenderName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSenderName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSenderName.fbeSize

    if ((fbeCurrentSize + this.NewSSLMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSSLMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSSLMode.fbeSize

    if ((fbeCurrentSize + this.NewEventTypes.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewEventTypes.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEventTypes.fbeSize

    if ((fbeCurrentSize + this.EventTypesReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.EventTypesReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventTypesReset.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewSMTPServerPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSMTPServerPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPServerPort.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!MailServerModifyRequest} fbeValue Default value, defaults is new MailServerModifyRequest()
   * @returns {!MailServerModifyRequest} MailServerModifyRequest value
   */
  get (fbeValue = new MailServerModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!MailServerModifyRequest} fbeValue MailServerModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.MailServerId.fbeSize) <= fbeStructSize) {
      fbeValue.MailServerId = this.MailServerId.get()
    } else {
      fbeValue.MailServerId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MailServerId.fbeSize

    if ((fbeCurrentSize + this.MailServerName.fbeSize) <= fbeStructSize) {
      fbeValue.MailServerName = this.MailServerName.get()
    } else {
      fbeValue.MailServerName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MailServerName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewSMTPServer.fbeSize) <= fbeStructSize) {
      fbeValue.NewSMTPServer = this.NewSMTPServer.get()
    } else {
      fbeValue.NewSMTPServer = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPServer.fbeSize

    if ((fbeCurrentSize + this.NewSMTPLogin.fbeSize) <= fbeStructSize) {
      fbeValue.NewSMTPLogin = this.NewSMTPLogin.get()
    } else {
      fbeValue.NewSMTPLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPLogin.fbeSize

    if ((fbeCurrentSize + this.NewSMTPPassword.fbeSize) <= fbeStructSize) {
      fbeValue.NewSMTPPassword = this.NewSMTPPassword.get()
    } else {
      fbeValue.NewSMTPPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPPassword.fbeSize

    if ((fbeCurrentSize + this.NewSenderEmail.fbeSize) <= fbeStructSize) {
      fbeValue.NewSenderEmail = this.NewSenderEmail.get()
    } else {
      fbeValue.NewSenderEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSenderEmail.fbeSize

    if ((fbeCurrentSize + this.NewSenderName.fbeSize) <= fbeStructSize) {
      fbeValue.NewSenderName = this.NewSenderName.get()
    } else {
      fbeValue.NewSenderName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSenderName.fbeSize

    if ((fbeCurrentSize + this.NewSSLMode.fbeSize) <= fbeStructSize) {
      fbeValue.NewSSLMode = this.NewSSLMode.get()
    } else {
      fbeValue.NewSSLMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSSLMode.fbeSize

    if ((fbeCurrentSize + this.NewEventTypes.fbeSize) <= fbeStructSize) {
      this.NewEventTypes.get(fbeValue.NewEventTypes)
    } else {
      fbeValue.NewEventTypes.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEventTypes.fbeSize

    if ((fbeCurrentSize + this.EventTypesReset.fbeSize) <= fbeStructSize) {
      fbeValue.EventTypesReset = this.EventTypesReset.get()
    } else {
      fbeValue.EventTypesReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EventTypesReset.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewSMTPServerPort.fbeSize) <= fbeStructSize) {
      fbeValue.NewSMTPServerPort = this.NewSMTPServerPort.get()
    } else {
      fbeValue.NewSMTPServerPort = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSMTPServerPort.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelMailServerModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelMailServerModifyRequest}
   * @param {!MailServerModifyRequest} fbeValue MailServerModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelMailServerModifyRequest}
   * @param {MailServerModifyRequest} fbeValue MailServerModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.MailServerId.set(fbeValue.MailServerId)
    this.MailServerName.set(fbeValue.MailServerName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewSMTPServer.set(fbeValue.NewSMTPServer)
    this.NewSMTPLogin.set(fbeValue.NewSMTPLogin)
    this.NewSMTPPassword.set(fbeValue.NewSMTPPassword)
    this.NewSenderEmail.set(fbeValue.NewSenderEmail)
    this.NewSenderName.set(fbeValue.NewSenderName)
    this.NewSSLMode.set(fbeValue.NewSSLMode)
    this.NewEventTypes.set(fbeValue.NewEventTypes)
    this.EventTypesReset.set(fbeValue.EventTypesReset)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewSMTPServerPort.set(fbeValue.NewSMTPServerPort)
  }
}

export { FieldModelMailServerModifyRequest };

/**
 * Fast Binary Encoding MailServerModifyRequest model
 */
class MailServerModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelMailServerModifyRequest(this.buffer, 4)
  }

  /**
   * Get the MailServerModifyRequest model
   * @this {!MailServerModifyRequestModel}
   * @returns {!FieldModelMailServerModifyRequest} model MailServerModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!MailServerModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!MailServerModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return MailServerModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!MailServerModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelMailServerModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!MailServerModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!MailServerModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!MailServerModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!MailServerModifyRequestModel}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!MailServerModifyRequestModel}
   * @param {!MailServerModifyRequest} value MailServerModifyRequest value, defaults is new MailServerModifyRequest()
   * @return {!object} Deserialized MailServerModifyRequest value and its size
   */
  deserialize (value = new MailServerModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new MailServerModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new MailServerModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!MailServerModifyRequestModel}
   * @param {!number} prev Previous MailServerModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { MailServerModifyRequestModel };
MailServerModifyRequest.__has_response = true;
MailServerModifyRequest.__response_class = MailServerModifyResponse;
