// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: exposed.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {BalanceCurrencyListRequest} from './exposed/BalanceCurrencyListRequest'
import {BalanceCurrencyListRequestModel} from './exposed/BalanceCurrencyListRequest'
import {BalanceCurrencyListResponse} from './exposed/BalanceCurrencyListResponse'
import {BalanceCurrencyListResponseModel} from './exposed/BalanceCurrencyListResponse'
import {LeverageListRequest} from './exposed/LeverageListRequest'
import {LeverageListRequestModel} from './exposed/LeverageListRequest'
import {LeverageListResponse} from './exposed/LeverageListResponse'
import {LeverageListResponseModel} from './exposed/LeverageListResponse'
import {DemoAccountCreateRequest} from './exposed/DemoAccountCreateRequest'
import {DemoAccountCreateRequestModel} from './exposed/DemoAccountCreateRequest'
import {DemoAccountCreateResponse} from './exposed/DemoAccountCreateResponse'
import {DemoAccountCreateResponseModel} from './exposed/DemoAccountCreateResponse'
import {ServerVersionRequest} from './exposed/ServerVersionRequest'
import {ServerVersionRequestModel} from './exposed/ServerVersionRequest'
import {ServerVersionResponse} from './exposed/ServerVersionResponse'
import {ServerVersionResponseModel} from './exposed/ServerVersionResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding exposed sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize exposed sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._BalanceCurrencyListRequestModel = new BalanceCurrencyListRequestModel(this.buffer)
    this._BalanceCurrencyListResponseModel = new BalanceCurrencyListResponseModel(this.buffer)
    this._LeverageListRequestModel = new LeverageListRequestModel(this.buffer)
    this._LeverageListResponseModel = new LeverageListResponseModel(this.buffer)
    this._DemoAccountCreateRequestModel = new DemoAccountCreateRequestModel(this.buffer)
    this._DemoAccountCreateResponseModel = new DemoAccountCreateResponseModel(this.buffer)
    this._ServerVersionRequestModel = new ServerVersionRequestModel(this.buffer)
    this._ServerVersionResponseModel = new ServerVersionResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get BalanceCurrencyListRequest model
   * @this {!Sender}
   * @returns {!BalanceCurrencyListRequestModel} BalanceCurrencyListRequest model
   */
  get BalanceCurrencyListRequestModel () {
    return this._BalanceCurrencyListRequestModel
  }

  /**
   * Get BalanceCurrencyListResponse model
   * @this {!Sender}
   * @returns {!BalanceCurrencyListResponseModel} BalanceCurrencyListResponse model
   */
  get BalanceCurrencyListResponseModel () {
    return this._BalanceCurrencyListResponseModel
  }

  /**
   * Get LeverageListRequest model
   * @this {!Sender}
   * @returns {!LeverageListRequestModel} LeverageListRequest model
   */
  get LeverageListRequestModel () {
    return this._LeverageListRequestModel
  }

  /**
   * Get LeverageListResponse model
   * @this {!Sender}
   * @returns {!LeverageListResponseModel} LeverageListResponse model
   */
  get LeverageListResponseModel () {
    return this._LeverageListResponseModel
  }

  /**
   * Get DemoAccountCreateRequest model
   * @this {!Sender}
   * @returns {!DemoAccountCreateRequestModel} DemoAccountCreateRequest model
   */
  get DemoAccountCreateRequestModel () {
    return this._DemoAccountCreateRequestModel
  }

  /**
   * Get DemoAccountCreateResponse model
   * @this {!Sender}
   * @returns {!DemoAccountCreateResponseModel} DemoAccountCreateResponse model
   */
  get DemoAccountCreateResponseModel () {
    return this._DemoAccountCreateResponseModel
  }

  /**
   * Get ServerVersionRequest model
   * @this {!Sender}
   * @returns {!ServerVersionRequestModel} ServerVersionRequest model
   */
  get ServerVersionRequestModel () {
    return this._ServerVersionRequestModel
  }

  /**
   * Get ServerVersionResponse model
   * @this {!Sender}
   * @returns {!ServerVersionResponseModel} ServerVersionResponse model
   */
  get ServerVersionResponseModel () {
    return this._ServerVersionResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof BalanceCurrencyListRequest) && (value.fbeType === this.BalanceCurrencyListRequestModel.fbeType)) {
      return this.send_BalanceCurrencyListRequest(value)
    }
    if ((value instanceof BalanceCurrencyListResponse) && (value.fbeType === this.BalanceCurrencyListResponseModel.fbeType)) {
      return this.send_BalanceCurrencyListResponse(value)
    }
    if ((value instanceof LeverageListRequest) && (value.fbeType === this.LeverageListRequestModel.fbeType)) {
      return this.send_LeverageListRequest(value)
    }
    if ((value instanceof LeverageListResponse) && (value.fbeType === this.LeverageListResponseModel.fbeType)) {
      return this.send_LeverageListResponse(value)
    }
    if ((value instanceof DemoAccountCreateRequest) && (value.fbeType === this.DemoAccountCreateRequestModel.fbeType)) {
      return this.send_DemoAccountCreateRequest(value)
    }
    if ((value instanceof DemoAccountCreateResponse) && (value.fbeType === this.DemoAccountCreateResponseModel.fbeType)) {
      return this.send_DemoAccountCreateResponse(value)
    }
    if ((value instanceof ServerVersionRequest) && (value.fbeType === this.ServerVersionRequestModel.fbeType)) {
      return this.send_ServerVersionRequest(value)
    }
    if ((value instanceof ServerVersionResponse) && (value.fbeType === this.ServerVersionResponseModel.fbeType)) {
      return this.send_ServerVersionResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send BalanceCurrencyListRequest value
   * @this {!Sender}
   * @param {!BalanceCurrencyListRequest} value BalanceCurrencyListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BalanceCurrencyListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BalanceCurrencyListRequestModel.serialize(value)
    console.assert((serialized > 0), 'exposed.BalanceCurrencyListRequest serialization failed!')
    console.assert(this.BalanceCurrencyListRequestModel.verify(), 'exposed.BalanceCurrencyListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BalanceCurrencyListResponse value
   * @this {!Sender}
   * @param {!BalanceCurrencyListResponse} value BalanceCurrencyListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BalanceCurrencyListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BalanceCurrencyListResponseModel.serialize(value)
    console.assert((serialized > 0), 'exposed.BalanceCurrencyListResponse serialization failed!')
    console.assert(this.BalanceCurrencyListResponseModel.verify(), 'exposed.BalanceCurrencyListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageListRequest value
   * @this {!Sender}
   * @param {!LeverageListRequest} value LeverageListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageListRequestModel.serialize(value)
    console.assert((serialized > 0), 'exposed.LeverageListRequest serialization failed!')
    console.assert(this.LeverageListRequestModel.verify(), 'exposed.LeverageListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageListResponse value
   * @this {!Sender}
   * @param {!LeverageListResponse} value LeverageListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageListResponseModel.serialize(value)
    console.assert((serialized > 0), 'exposed.LeverageListResponse serialization failed!')
    console.assert(this.LeverageListResponseModel.verify(), 'exposed.LeverageListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountCreateRequest value
   * @this {!Sender}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountCreateRequestModel.serialize(value)
    console.assert((serialized > 0), 'exposed.DemoAccountCreateRequest serialization failed!')
    console.assert(this.DemoAccountCreateRequestModel.verify(), 'exposed.DemoAccountCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountCreateResponse value
   * @this {!Sender}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountCreateResponseModel.serialize(value)
    console.assert((serialized > 0), 'exposed.DemoAccountCreateResponse serialization failed!')
    console.assert(this.DemoAccountCreateResponseModel.verify(), 'exposed.DemoAccountCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerVersionRequest value
   * @this {!Sender}
   * @param {!ServerVersionRequest} value ServerVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServerVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'exposed.ServerVersionRequest serialization failed!')
    console.assert(this.ServerVersionRequestModel.verify(), 'exposed.ServerVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerVersionResponse value
   * @this {!Sender}
   * @param {!ServerVersionResponse} value ServerVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServerVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'exposed.ServerVersionResponse serialization failed!')
    console.assert(this.ServerVersionResponseModel.verify(), 'exposed.ServerVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'exposed.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding exposed receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize exposed receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._BalanceCurrencyListRequestValue = new BalanceCurrencyListRequest()
    this._BalanceCurrencyListRequestModel = new BalanceCurrencyListRequestModel()
    this._BalanceCurrencyListResponseValue = new BalanceCurrencyListResponse()
    this._BalanceCurrencyListResponseModel = new BalanceCurrencyListResponseModel()
    this._LeverageListRequestValue = new LeverageListRequest()
    this._LeverageListRequestModel = new LeverageListRequestModel()
    this._LeverageListResponseValue = new LeverageListResponse()
    this._LeverageListResponseModel = new LeverageListResponseModel()
    this._DemoAccountCreateRequestValue = new DemoAccountCreateRequest()
    this._DemoAccountCreateRequestModel = new DemoAccountCreateRequestModel()
    this._DemoAccountCreateResponseValue = new DemoAccountCreateResponse()
    this._DemoAccountCreateResponseModel = new DemoAccountCreateResponseModel()
    this._ServerVersionRequestValue = new ServerVersionRequest()
    this._ServerVersionRequestModel = new ServerVersionRequestModel()
    this._ServerVersionResponseValue = new ServerVersionResponse()
    this._ServerVersionResponseModel = new ServerVersionResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * BalanceCurrencyListRequest receive handler
   * @this {!Receiver}
   * @param {!BalanceCurrencyListRequest} value BalanceCurrencyListRequest received value
   */
  onReceive_BalanceCurrencyListRequest (value) {}  // eslint-disable-line

  /**
   * BalanceCurrencyListResponse receive handler
   * @this {!Receiver}
   * @param {!BalanceCurrencyListResponse} value BalanceCurrencyListResponse received value
   */
  onReceive_BalanceCurrencyListResponse (value) {}  // eslint-disable-line

  /**
   * LeverageListRequest receive handler
   * @this {!Receiver}
   * @param {!LeverageListRequest} value LeverageListRequest received value
   */
  onReceive_LeverageListRequest (value) {}  // eslint-disable-line

  /**
   * LeverageListResponse receive handler
   * @this {!Receiver}
   * @param {!LeverageListResponse} value LeverageListResponse received value
   */
  onReceive_LeverageListResponse (value) {}  // eslint-disable-line

  /**
   * DemoAccountCreateRequest receive handler
   * @this {!Receiver}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest received value
   */
  onReceive_DemoAccountCreateRequest (value) {}  // eslint-disable-line

  /**
   * DemoAccountCreateResponse receive handler
   * @this {!Receiver}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse received value
   */
  onReceive_DemoAccountCreateResponse (value) {}  // eslint-disable-line

  /**
   * ServerVersionRequest receive handler
   * @this {!Receiver}
   * @param {!ServerVersionRequest} value ServerVersionRequest received value
   */
  onReceive_ServerVersionRequest (value) {}  // eslint-disable-line

  /**
   * ServerVersionResponse receive handler
   * @this {!Receiver}
   * @param {!ServerVersionResponse} value ServerVersionResponse received value
   */
  onReceive_ServerVersionResponse (value) {}  // eslint-disable-line

  /**
   * exposed receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BalanceCurrencyListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BalanceCurrencyListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListRequestModel.verify(), 'exposed.BalanceCurrencyListRequest validation failed!')
        let deserialized = this._BalanceCurrencyListRequestModel.deserialize(this._BalanceCurrencyListRequestValue)
        console.assert((deserialized.size > 0), 'exposed.BalanceCurrencyListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BalanceCurrencyListRequestValue)
        this.onReceive_BalanceCurrencyListRequest(this._BalanceCurrencyListRequestValue)
        return true
      }
      case BalanceCurrencyListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BalanceCurrencyListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListResponseModel.verify(), 'exposed.BalanceCurrencyListResponse validation failed!')
        let deserialized = this._BalanceCurrencyListResponseModel.deserialize(this._BalanceCurrencyListResponseValue)
        console.assert((deserialized.size > 0), 'exposed.BalanceCurrencyListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BalanceCurrencyListResponseValue)
        this.onReceive_BalanceCurrencyListResponse(this._BalanceCurrencyListResponseValue)
        return true
      }
      case LeverageListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListRequestModel.verify(), 'exposed.LeverageListRequest validation failed!')
        let deserialized = this._LeverageListRequestModel.deserialize(this._LeverageListRequestValue)
        console.assert((deserialized.size > 0), 'exposed.LeverageListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageListRequestValue)
        this.onReceive_LeverageListRequest(this._LeverageListRequestValue)
        return true
      }
      case LeverageListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListResponseModel.verify(), 'exposed.LeverageListResponse validation failed!')
        let deserialized = this._LeverageListResponseModel.deserialize(this._LeverageListResponseValue)
        console.assert((deserialized.size > 0), 'exposed.LeverageListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageListResponseValue)
        this.onReceive_LeverageListResponse(this._LeverageListResponseValue)
        return true
      }
      case DemoAccountCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateRequestModel.verify(), 'exposed.DemoAccountCreateRequest validation failed!')
        let deserialized = this._DemoAccountCreateRequestModel.deserialize(this._DemoAccountCreateRequestValue)
        console.assert((deserialized.size > 0), 'exposed.DemoAccountCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountCreateRequestValue)
        this.onReceive_DemoAccountCreateRequest(this._DemoAccountCreateRequestValue)
        return true
      }
      case DemoAccountCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateResponseModel.verify(), 'exposed.DemoAccountCreateResponse validation failed!')
        let deserialized = this._DemoAccountCreateResponseModel.deserialize(this._DemoAccountCreateResponseValue)
        console.assert((deserialized.size > 0), 'exposed.DemoAccountCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountCreateResponseValue)
        this.onReceive_DemoAccountCreateResponse(this._DemoAccountCreateResponseValue)
        return true
      }
      case ServerVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionRequestModel.verify(), 'exposed.ServerVersionRequest validation failed!')
        let deserialized = this._ServerVersionRequestModel.deserialize(this._ServerVersionRequestValue)
        console.assert((deserialized.size > 0), 'exposed.ServerVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerVersionRequestValue)
        this.onReceive_ServerVersionRequest(this._ServerVersionRequestValue)
        return true
      }
      case ServerVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionResponseModel.verify(), 'exposed.ServerVersionResponse validation failed!')
        let deserialized = this._ServerVersionResponseModel.deserialize(this._ServerVersionResponseValue)
        console.assert((deserialized.size > 0), 'exposed.ServerVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerVersionResponseValue)
        this.onReceive_ServerVersionResponse(this._ServerVersionResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding exposed proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize exposed proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._BalanceCurrencyListRequestModel = new BalanceCurrencyListRequestModel()
    this._BalanceCurrencyListResponseModel = new BalanceCurrencyListResponseModel()
    this._LeverageListRequestModel = new LeverageListRequestModel()
    this._LeverageListResponseModel = new LeverageListResponseModel()
    this._DemoAccountCreateRequestModel = new DemoAccountCreateRequestModel()
    this._DemoAccountCreateResponseModel = new DemoAccountCreateResponseModel()
    this._ServerVersionRequestModel = new ServerVersionRequestModel()
    this._ServerVersionResponseModel = new ServerVersionResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * BalanceCurrencyListRequest proxy handler
   * @this {!Proxy}
   * @param {!BalanceCurrencyListRequest} model BalanceCurrencyListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BalanceCurrencyListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * BalanceCurrencyListResponse proxy handler
   * @this {!Proxy}
   * @param {!BalanceCurrencyListResponse} model BalanceCurrencyListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_BalanceCurrencyListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageListRequest proxy handler
   * @this {!Proxy}
   * @param {!LeverageListRequest} model LeverageListRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageListRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * LeverageListResponse proxy handler
   * @this {!Proxy}
   * @param {!LeverageListResponse} model LeverageListResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_LeverageListResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DemoAccountCreateRequest proxy handler
   * @this {!Proxy}
   * @param {!DemoAccountCreateRequest} model DemoAccountCreateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DemoAccountCreateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DemoAccountCreateResponse proxy handler
   * @this {!Proxy}
   * @param {!DemoAccountCreateResponse} model DemoAccountCreateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DemoAccountCreateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServerVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!ServerVersionRequest} model ServerVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServerVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ServerVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!ServerVersionResponse} model ServerVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ServerVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * exposed receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BalanceCurrencyListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BalanceCurrencyListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListRequestModel.verify(), 'exposed.BalanceCurrencyListRequest validation failed!')

        let fbeBegin = this._BalanceCurrencyListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BalanceCurrencyListRequest(this._BalanceCurrencyListRequestModel, type, buffer, offset, size)
        this._BalanceCurrencyListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case BalanceCurrencyListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._BalanceCurrencyListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListResponseModel.verify(), 'exposed.BalanceCurrencyListResponse validation failed!')

        let fbeBegin = this._BalanceCurrencyListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_BalanceCurrencyListResponse(this._BalanceCurrencyListResponseModel, type, buffer, offset, size)
        this._BalanceCurrencyListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageListRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageListRequestModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListRequestModel.verify(), 'exposed.LeverageListRequest validation failed!')

        let fbeBegin = this._LeverageListRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageListRequest(this._LeverageListRequestModel, type, buffer, offset, size)
        this._LeverageListRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case LeverageListResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._LeverageListResponseModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListResponseModel.verify(), 'exposed.LeverageListResponse validation failed!')

        let fbeBegin = this._LeverageListResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_LeverageListResponse(this._LeverageListResponseModel, type, buffer, offset, size)
        this._LeverageListResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DemoAccountCreateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DemoAccountCreateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateRequestModel.verify(), 'exposed.DemoAccountCreateRequest validation failed!')

        let fbeBegin = this._DemoAccountCreateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DemoAccountCreateRequest(this._DemoAccountCreateRequestModel, type, buffer, offset, size)
        this._DemoAccountCreateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DemoAccountCreateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DemoAccountCreateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateResponseModel.verify(), 'exposed.DemoAccountCreateResponse validation failed!')

        let fbeBegin = this._DemoAccountCreateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DemoAccountCreateResponse(this._DemoAccountCreateResponseModel, type, buffer, offset, size)
        this._DemoAccountCreateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ServerVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServerVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionRequestModel.verify(), 'exposed.ServerVersionRequest validation failed!')

        let fbeBegin = this._ServerVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServerVersionRequest(this._ServerVersionRequestModel, type, buffer, offset, size)
        this._ServerVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ServerVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ServerVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionResponseModel.verify(), 'exposed.ServerVersionResponse validation failed!')

        let fbeBegin = this._ServerVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ServerVersionResponse(this._ServerVersionResponseModel, type, buffer, offset, size)
        this._ServerVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding exposed client
 */
class Client extends fbe.Client {
  /**
   * Initialize exposed client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._BalanceCurrencyListRequestSenderModel = new BalanceCurrencyListRequestModel(this.sendBuffer)
    this._BalanceCurrencyListRequestReceiverValue = new BalanceCurrencyListRequest()
    this._BalanceCurrencyListRequestReceiverModel = new BalanceCurrencyListRequestModel()
    this._BalanceCurrencyListResponseSenderModel = new BalanceCurrencyListResponseModel(this.sendBuffer)
    this._BalanceCurrencyListResponseReceiverValue = new BalanceCurrencyListResponse()
    this._BalanceCurrencyListResponseReceiverModel = new BalanceCurrencyListResponseModel()
    this._LeverageListRequestSenderModel = new LeverageListRequestModel(this.sendBuffer)
    this._LeverageListRequestReceiverValue = new LeverageListRequest()
    this._LeverageListRequestReceiverModel = new LeverageListRequestModel()
    this._LeverageListResponseSenderModel = new LeverageListResponseModel(this.sendBuffer)
    this._LeverageListResponseReceiverValue = new LeverageListResponse()
    this._LeverageListResponseReceiverModel = new LeverageListResponseModel()
    this._DemoAccountCreateRequestSenderModel = new DemoAccountCreateRequestModel(this.sendBuffer)
    this._DemoAccountCreateRequestReceiverValue = new DemoAccountCreateRequest()
    this._DemoAccountCreateRequestReceiverModel = new DemoAccountCreateRequestModel()
    this._DemoAccountCreateResponseSenderModel = new DemoAccountCreateResponseModel(this.sendBuffer)
    this._DemoAccountCreateResponseReceiverValue = new DemoAccountCreateResponse()
    this._DemoAccountCreateResponseReceiverModel = new DemoAccountCreateResponseModel()
    this._ServerVersionRequestSenderModel = new ServerVersionRequestModel(this.sendBuffer)
    this._ServerVersionRequestReceiverValue = new ServerVersionRequest()
    this._ServerVersionRequestReceiverModel = new ServerVersionRequestModel()
    this._ServerVersionResponseSenderModel = new ServerVersionResponseModel(this.sendBuffer)
    this._ServerVersionResponseReceiverValue = new ServerVersionResponse()
    this._ServerVersionResponseReceiverModel = new ServerVersionResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get BalanceCurrencyListRequest model
   * @this {!Client}
   * @returns {!BalanceCurrencyListRequestModel} BalanceCurrencyListRequest sender model
   */
  get BalanceCurrencyListRequestSenderModel () {
    return this._BalanceCurrencyListRequestSenderModel
  }

  /**
   * Get BalanceCurrencyListResponse model
   * @this {!Client}
   * @returns {!BalanceCurrencyListResponseModel} BalanceCurrencyListResponse sender model
   */
  get BalanceCurrencyListResponseSenderModel () {
    return this._BalanceCurrencyListResponseSenderModel
  }

  /**
   * Get LeverageListRequest model
   * @this {!Client}
   * @returns {!LeverageListRequestModel} LeverageListRequest sender model
   */
  get LeverageListRequestSenderModel () {
    return this._LeverageListRequestSenderModel
  }

  /**
   * Get LeverageListResponse model
   * @this {!Client}
   * @returns {!LeverageListResponseModel} LeverageListResponse sender model
   */
  get LeverageListResponseSenderModel () {
    return this._LeverageListResponseSenderModel
  }

  /**
   * Get DemoAccountCreateRequest model
   * @this {!Client}
   * @returns {!DemoAccountCreateRequestModel} DemoAccountCreateRequest sender model
   */
  get DemoAccountCreateRequestSenderModel () {
    return this._DemoAccountCreateRequestSenderModel
  }

  /**
   * Get DemoAccountCreateResponse model
   * @this {!Client}
   * @returns {!DemoAccountCreateResponseModel} DemoAccountCreateResponse sender model
   */
  get DemoAccountCreateResponseSenderModel () {
    return this._DemoAccountCreateResponseSenderModel
  }

  /**
   * Get ServerVersionRequest model
   * @this {!Client}
   * @returns {!ServerVersionRequestModel} ServerVersionRequest sender model
   */
  get ServerVersionRequestSenderModel () {
    return this._ServerVersionRequestSenderModel
  }

  /**
   * Get ServerVersionResponse model
   * @this {!Client}
   * @returns {!ServerVersionResponseModel} ServerVersionResponse sender model
   */
  get ServerVersionResponseSenderModel () {
    return this._ServerVersionResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof BalanceCurrencyListRequest) && (value.fbeType === this.BalanceCurrencyListRequestSenderModel.fbeType)) {
      return this.send_BalanceCurrencyListRequest(value)
    }
    if ((value instanceof BalanceCurrencyListResponse) && (value.fbeType === this.BalanceCurrencyListResponseSenderModel.fbeType)) {
      return this.send_BalanceCurrencyListResponse(value)
    }
    if ((value instanceof LeverageListRequest) && (value.fbeType === this.LeverageListRequestSenderModel.fbeType)) {
      return this.send_LeverageListRequest(value)
    }
    if ((value instanceof LeverageListResponse) && (value.fbeType === this.LeverageListResponseSenderModel.fbeType)) {
      return this.send_LeverageListResponse(value)
    }
    if ((value instanceof DemoAccountCreateRequest) && (value.fbeType === this.DemoAccountCreateRequestSenderModel.fbeType)) {
      return this.send_DemoAccountCreateRequest(value)
    }
    if ((value instanceof DemoAccountCreateResponse) && (value.fbeType === this.DemoAccountCreateResponseSenderModel.fbeType)) {
      return this.send_DemoAccountCreateResponse(value)
    }
    if ((value instanceof ServerVersionRequest) && (value.fbeType === this.ServerVersionRequestSenderModel.fbeType)) {
      return this.send_ServerVersionRequest(value)
    }
    if ((value instanceof ServerVersionResponse) && (value.fbeType === this.ServerVersionResponseSenderModel.fbeType)) {
      return this.send_ServerVersionResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send BalanceCurrencyListRequest value
   * @this {!Client}
   * @param {!BalanceCurrencyListRequest} value BalanceCurrencyListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_BalanceCurrencyListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BalanceCurrencyListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.BalanceCurrencyListRequest serialization failed!')
    console.assert(this.BalanceCurrencyListRequestSenderModel.verify(), 'exposed.BalanceCurrencyListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send BalanceCurrencyListResponse value
   * @this {!Client}
   * @param {!BalanceCurrencyListResponse} value BalanceCurrencyListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_BalanceCurrencyListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.BalanceCurrencyListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.BalanceCurrencyListResponse serialization failed!')
    console.assert(this.BalanceCurrencyListResponseSenderModel.verify(), 'exposed.BalanceCurrencyListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageListRequest value
   * @this {!Client}
   * @param {!LeverageListRequest} value LeverageListRequest value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageListRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageListRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.LeverageListRequest serialization failed!')
    console.assert(this.LeverageListRequestSenderModel.verify(), 'exposed.LeverageListRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send LeverageListResponse value
   * @this {!Client}
   * @param {!LeverageListResponse} value LeverageListResponse value to send
   * @returns {!number} Sent bytes
   */
  send_LeverageListResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.LeverageListResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.LeverageListResponse serialization failed!')
    console.assert(this.LeverageListResponseSenderModel.verify(), 'exposed.LeverageListResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountCreateRequest value
   * @this {!Client}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountCreateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountCreateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.DemoAccountCreateRequest serialization failed!')
    console.assert(this.DemoAccountCreateRequestSenderModel.verify(), 'exposed.DemoAccountCreateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DemoAccountCreateResponse value
   * @this {!Client}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DemoAccountCreateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DemoAccountCreateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.DemoAccountCreateResponse serialization failed!')
    console.assert(this.DemoAccountCreateResponseSenderModel.verify(), 'exposed.DemoAccountCreateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerVersionRequest value
   * @this {!Client}
   * @param {!ServerVersionRequest} value ServerVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ServerVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.ServerVersionRequest serialization failed!')
    console.assert(this.ServerVersionRequestSenderModel.verify(), 'exposed.ServerVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ServerVersionResponse value
   * @this {!Client}
   * @param {!ServerVersionResponse} value ServerVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ServerVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ServerVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'exposed.ServerVersionResponse serialization failed!')
    console.assert(this.ServerVersionResponseSenderModel.verify(), 'exposed.ServerVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'exposed.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * BalanceCurrencyListRequest receive handler
   * @this {!Client}
   * @param {!BalanceCurrencyListRequest} value BalanceCurrencyListRequest received value
   */
  onReceive_BalanceCurrencyListRequest (value) {}  // eslint-disable-line

  /**
   * BalanceCurrencyListResponse receive handler
   * @this {!Client}
   * @param {!BalanceCurrencyListResponse} value BalanceCurrencyListResponse received value
   */
  onReceive_BalanceCurrencyListResponse (value) {}  // eslint-disable-line

  /**
   * LeverageListRequest receive handler
   * @this {!Client}
   * @param {!LeverageListRequest} value LeverageListRequest received value
   */
  onReceive_LeverageListRequest (value) {}  // eslint-disable-line

  /**
   * LeverageListResponse receive handler
   * @this {!Client}
   * @param {!LeverageListResponse} value LeverageListResponse received value
   */
  onReceive_LeverageListResponse (value) {}  // eslint-disable-line

  /**
   * DemoAccountCreateRequest receive handler
   * @this {!Client}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest received value
   */
  onReceive_DemoAccountCreateRequest (value) {}  // eslint-disable-line

  /**
   * DemoAccountCreateResponse receive handler
   * @this {!Client}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse received value
   */
  onReceive_DemoAccountCreateResponse (value) {}  // eslint-disable-line

  /**
   * ServerVersionRequest receive handler
   * @this {!Client}
   * @param {!ServerVersionRequest} value ServerVersionRequest received value
   */
  onReceive_ServerVersionRequest (value) {}  // eslint-disable-line

  /**
   * ServerVersionResponse receive handler
   * @this {!Client}
   * @param {!ServerVersionResponse} value ServerVersionResponse received value
   */
  onReceive_ServerVersionResponse (value) {}  // eslint-disable-line

  /**
   * exposed receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case BalanceCurrencyListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BalanceCurrencyListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListRequestReceiverModel.verify(), 'exposed.BalanceCurrencyListRequest validation failed!')
        let deserialized = this._BalanceCurrencyListRequestReceiverModel.deserialize(this._BalanceCurrencyListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.BalanceCurrencyListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BalanceCurrencyListRequestReceiverValue)
        this.onReceive_BalanceCurrencyListRequest(this._BalanceCurrencyListRequestReceiverValue)
        return true
      }
      case BalanceCurrencyListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._BalanceCurrencyListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._BalanceCurrencyListResponseReceiverModel.verify(), 'exposed.BalanceCurrencyListResponse validation failed!')
        let deserialized = this._BalanceCurrencyListResponseReceiverModel.deserialize(this._BalanceCurrencyListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.BalanceCurrencyListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._BalanceCurrencyListResponseReceiverValue)
        this.onReceive_BalanceCurrencyListResponse(this._BalanceCurrencyListResponseReceiverValue)
        return true
      }
      case LeverageListRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageListRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListRequestReceiverModel.verify(), 'exposed.LeverageListRequest validation failed!')
        let deserialized = this._LeverageListRequestReceiverModel.deserialize(this._LeverageListRequestReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.LeverageListRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageListRequestReceiverValue)
        this.onReceive_LeverageListRequest(this._LeverageListRequestReceiverValue)
        return true
      }
      case LeverageListResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._LeverageListResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._LeverageListResponseReceiverModel.verify(), 'exposed.LeverageListResponse validation failed!')
        let deserialized = this._LeverageListResponseReceiverModel.deserialize(this._LeverageListResponseReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.LeverageListResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._LeverageListResponseReceiverValue)
        this.onReceive_LeverageListResponse(this._LeverageListResponseReceiverValue)
        return true
      }
      case DemoAccountCreateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountCreateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateRequestReceiverModel.verify(), 'exposed.DemoAccountCreateRequest validation failed!')
        let deserialized = this._DemoAccountCreateRequestReceiverModel.deserialize(this._DemoAccountCreateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.DemoAccountCreateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountCreateRequestReceiverValue)
        this.onReceive_DemoAccountCreateRequest(this._DemoAccountCreateRequestReceiverValue)
        return true
      }
      case DemoAccountCreateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DemoAccountCreateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DemoAccountCreateResponseReceiverModel.verify(), 'exposed.DemoAccountCreateResponse validation failed!')
        let deserialized = this._DemoAccountCreateResponseReceiverModel.deserialize(this._DemoAccountCreateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.DemoAccountCreateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DemoAccountCreateResponseReceiverValue)
        this.onReceive_DemoAccountCreateResponse(this._DemoAccountCreateResponseReceiverValue)
        return true
      }
      case ServerVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionRequestReceiverModel.verify(), 'exposed.ServerVersionRequest validation failed!')
        let deserialized = this._ServerVersionRequestReceiverModel.deserialize(this._ServerVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.ServerVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerVersionRequestReceiverValue)
        this.onReceive_ServerVersionRequest(this._ServerVersionRequestReceiverValue)
        return true
      }
      case ServerVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ServerVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ServerVersionResponseReceiverModel.verify(), 'exposed.ServerVersionResponse validation failed!')
        let deserialized = this._ServerVersionResponseReceiverModel.deserialize(this._ServerVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'exposed.ServerVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ServerVersionResponseReceiverValue)
        this.onReceive_ServerVersionResponse(this._ServerVersionResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
