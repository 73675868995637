// special event emitter

import { Message, MessageType } from '@components/Message/Message';
import { LS_WS_CONNECTION_KEY } from '@constants';
import { keyMap, t } from '@localization';
import { authStore } from '@store/auth';
import StorageHelper from '@utils/StorageHelper';
import { Reject } from './fbe/core/Reject';

export class ResponseListener {
    _responseListeners: {
        [fbeType: number]: ((responseObj: unknown) => void)[];
    } = {};

    _handleResponse = (responseObj: any) => {
        if (responseObj instanceof Reject && responseObj?.Code.valueOf() === 2001) {
            const message = responseObj?.Reason ?? 'Session is not authenticated!';
            Message(MessageType.error, message);
            authStore.setAuth(false);
            authStore.logout({ message: message, code: responseObj?.Code.valueOf() });
        }
        const fbeType = responseObj.fbeType;
        if (fbeType in this._responseListeners) {
            this._responseListeners[fbeType].forEach((f) => f(responseObj));
        }
        if (fbeType === 1000) {
            Message(MessageType.error, t(keyMap.form.auth.errorConnectToSource));
            authStore.logout({ message: t(keyMap.form.auth.unavailableSource), code: fbeType });
        }
        if ([5005].includes(fbeType)) {
            authStore.logout({ message: responseObj.Reason, code: fbeType });
        } else {
            const cookieToken = StorageHelper.getFromCookie('SessionData');
            if (cookieToken && !authStore.isLoading && ([2001, 2003].includes(fbeType))) {
                authStore.setAuth(true);
                authStore.authHandler({
                    wsUrl: StorageHelper.getFromLocal(LS_WS_CONNECTION_KEY),
                    token: cookieToken.AuthToken,
                    login: cookieToken.Login,
                    onSuccess: () => {
                        Message(MessageType.info, t(keyMap.messages.connectionRestored));
                    },
                });
            }
        }
    };

    _addListener = (fbeType: number, cb: (responseObj: any) => void) => {
        if (!this._responseListeners[fbeType]) {
            this._responseListeners[fbeType] = [cb];
        } else {
            this._responseListeners[fbeType].push(cb);
        }
    };

    _removeListener = (fbeType: number, cb: (responseObj: any) => void) => {
        if (this._responseListeners[fbeType]) {
            const i = this._responseListeners[fbeType].findIndex((x) => x === cb);
            if (i !== -1) this._responseListeners[fbeType].splice(i, 1);
        }
    };

    addListener = <T extends { fbeType: number }>(
        responseObj: new (...args: any) => T,
        cb: (responseObj: T) => void,
    ) => {
        const fbeType = (responseObj as any).fbeType;
        return this._addListener(fbeType, cb);
    };

    removeListener = <T extends { fbeType: number }>(
        responseObj: new (...args: any) => T,
        cb: (responseObj: T) => void,
    ) => {
        const fbeType = (responseObj as any).fbeType;
        return this._removeListener(fbeType, cb);
    };

    removeAllListeners = () => {
        this._responseListeners = {};
    };

    once = <T extends { fbeType: number }>(
        responseObj: new (...args: any) => T,
        cb: (responseObj: T) => void,
    ): void => {
        const fbeType = (responseObj as any).fbeType;

        const handler = (responseObj: any) => {
            this._removeListener(fbeType, handler);
            cb(responseObj);
        };
        this._addListener(fbeType, handler);
    };
}
