import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UInt64 } from '@api/fbe/int64';
import { ChartBarsImportRequestParams, ImportBarsInfo, ImportBarsResponseDetails, convertChartBarInfoToModel } from '@models/ChartBars';
import { ChartBarsImportRequest } from '@api/fbe/manager/ChartBarsImportRequest';
import { UUID } from '@api/fbe/uuid';
import { ChartBarsImportServerType } from '@api/fbe/core/ChartBarsImportServerType';
import { ChartBarsImportInfo } from '@api/fbe/history/ChartBarsImportInfo';
import { ChartBarsImportNotify } from '@api/fbe/manager/ChartBarsImportNotify';

class ChartBarsImportStore {
    errorTracker = new ErrorsTracker({ title: 'Chart Bars Import Store' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ImportBarsInfo[] | null = null;

    importResponseDetails: ImportBarsResponseDetails | null = null;

    isLoading = false;

    isFinished = false;

    requestParams: ChartBarsImportRequestParams | null = null;

    setData(value: ImportBarsInfo[] | null) {
        this.data = value;
    }

    setImportResponseDetails(value: ImportBarsResponseDetails | null) {
        this.importResponseDetails = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setIsFinished(value: boolean) {
        this.isFinished = value;
    }

    setRequestParams = (params: ChartBarsImportRequestParams | null): void => {
        this.requestParams = params;
    };

    private importNotifyCallback = (data: ChartBarsImportNotify) => {
        this.setData([convertChartBarInfoToModel(data.ImportBarsInfo), ...(this.data ?? [])]);
    };

    subscribeImportEvents() {
        SocketClient.instance.responseListener.addListener(ChartBarsImportNotify, this.importNotifyCallback);
        const request = new ChartBarsImportNotify();
        SocketClient.instance.sendRequest(request);
    }

    unsubscribeImportEvents() {
        SocketClient.instance.responseListener.removeListener(ChartBarsImportNotify, this.importNotifyCallback);
    }

    @ErrorsTracker.wrapApi()
    async import(): Promise<ImportBarsInfo[]> {
        this.setIsLoading(true);
        const request = new ChartBarsImportRequest();
        if (this.requestParams) {
            this.setImportResponseDetails(null);
            if (this.requestParams.TimeFrom) request.TimeFrom = this.requestParams.TimeFrom;
            if (this.requestParams.TimeTo) request.TimeTo = this.requestParams.TimeTo;
            request.Limit = this.requestParams.Limit ? UInt64.fromNumber(this.requestParams.Limit) : null;
            request.SymbolPatterns = new Set(this.requestParams.SymbolPatterns);
            if (this.requestParams.SymbolMapId) request.SymbolMapId = UUID.fromString(this.requestParams.SymbolMapId);
            request.ServerType = new ChartBarsImportServerType(this.requestParams.ServerType);
            request.Address = this.requestParams.Address;
            if (this.requestParams.ManagerLogin !== null)
                request.ManagerLogin = UInt64.fromNumber(this.requestParams.ManagerLogin);
            request.ManagerPassword = this.requestParams.ManagerPassword;
            const result = await SocketClient.instance.request(request, this.errorTracker);
            const importBarsInfo = result.ImportBarsInfo.map((record: ChartBarsImportInfo, index) => ({
                key: index,
                ...convertChartBarInfoToModel(record),
            }));
            this.setData(importBarsInfo);
            this.setImportResponseDetails({
                ImportSymbolsSucceed: result.ImportSymbolsSucceed,
                ImportSymbolsFailed: result.ImportSymbolsFailed,
            });
            return importBarsInfo;
        }
        return Promise.reject();

    }

    reset() {
        this.data = null;
        this.isLoading = false;
        this.isFinished = false;
        this.requestParams = null;
        this.importResponseDetails = null;
    }
}

export const chartBarsImportStore = new ChartBarsImportStore();
