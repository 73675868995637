import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { Platform } from '@models';
import { keyMap, t } from '@localization';
import { UUID } from '@api/fbe/uuid';
import { UInt64 } from '@api/fbe/int64';
import { PlatformModifyRequest } from '@api/fbe/manager/PlatformModifyRequest';
import { PlatformsGetAllRequest } from '@api/fbe/manager/PlatformsGetAllRequest';
import { PlatformCreateRequest } from '@api/fbe/manager/PlatformCreateRequest';
import { PlatformDeleteRequest } from '@api/fbe/manager/PlatformDeleteRequest';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { FeaturesToggle } from '@api/fbe/core/FeaturesToggle';

export class PlatformsStore {
    errorTracker = new ErrorsTracker({ title: 'Platforms' });

    constructor() {
        makeAutoObservable(this);
    }

    data: Platform[] = [];

    isLoading = false;

    setData(value: Platform[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new PlatformModifyRequest();
            request.PlatformId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        } else {
            throw new Error(t(keyMap.messages.platforms.reorderError));
        }
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new PlatformsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Platforms.map(
                (record): Platform => ({
                    id: record.id.toString(),
                    SortOrder: record.SortOrder,
                    Name: record.Name,
                    Comment: record.Comment,
                    Description: record.Description,
                    CompanyName: record.CompanyName,
                    TimezoneId: record.TimezoneId.toString(),
                    RolloverTime:
                        record.RolloverTime !== undefined && record.RolloverTime !== null
                            ? +record.RolloverTime.toNumber()
                            : null,
                    // SnapshotTime:
                    //     record.SnapshotTime !== undefined && record.SnapshotTime !== null
                    //         ? +record.SnapshotTime.toNumber()
                    //         : null,
                    CompanyFullName: record.CompanyFullName,
                    CompanyEmail: record.CompanyEmail,
                    CompanyPhone: record.CompanyPhone,
                    CompanyAddress: record.CompanyAddress,
                    CompanyWebsite: record.CompanyWebsite,
                    CompanyDescription: record.CompanyDescription,
                    ManagerLoginRangeOffset: +record.ManagerLoginRangeOffset.toNumber(),
                    ManagerLoginRangeSize: +record.ManagerLoginRangeSize.toNumber(),
                    AccountLoginRangeOffset: +record.AccountLoginRangeOffset.toNumber(),
                    AccountLoginRangeSize: +record.AccountLoginRangeSize.toNumber(),
                    FeaturesToggle: {
                        MultipleDashboards: record.FeaturesToggle.MultipleDashboards,
                        SocialTradingAccountsManipulation: record.FeaturesToggle.SocialTradingAccountsManipulation,
                    },
                    // TODO should be checked after the BE logic will be appeared
                    // @ts-ignore
                    EventType: record.EventType,
                    DailyReportTemplateId: record.DailyReportTemplateId
                        ? record.DailyReportTemplateId.toString()
                        : null,
                    MonthlyReportTemplateId: record.MonthlyReportTemplateId
                        ? record.MonthlyReportTemplateId.toString()
                        : null,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newPlatform: Platform) {
        const request = new PlatformCreateRequest();
        request.SortOrder = newPlatform.SortOrder;
        request.Name = newPlatform.Name;
        request.TimezoneId = UUID.fromString(newPlatform.TimezoneId);
        request.RolloverTime =
            newPlatform && typeof newPlatform.RolloverTime === 'number'
                ? UInt64.fromValue(newPlatform.RolloverTime)
                : null;
        // request.SnapshotTime = null;
        // request.SnapshotTime = // Snapshot time field skipped for now
        //     newPlatform && typeof newPlatform.SnapshotTime === 'number'
        //         ? UInt64.fromValue(newPlatform.SnapshotTime)
        //         : null;
        request.Comment = newPlatform.Comment;
        request.Description = newPlatform.Description;

        request.CompanyName = newPlatform.CompanyName;
        request.CompanyFullName = newPlatform.CompanyFullName;
        request.CompanyEmail = newPlatform.CompanyEmail;
        request.CompanyPhone = newPlatform.CompanyPhone;
        request.CompanyAddress = newPlatform.CompanyAddress;
        request.CompanyWebsite = newPlatform.CompanyWebsite;

        request.CompanyDescription = newPlatform.CompanyDescription;
        if (newPlatform.ManagerLoginRangeOffset !== null)
            request.ManagerLoginRangeOffset = UInt64.fromValue(newPlatform.ManagerLoginRangeOffset);
        if (newPlatform.ManagerLoginRangeSize !== null)
            request.ManagerLoginRangeSize = UInt64.fromValue(newPlatform.ManagerLoginRangeSize);
        if (newPlatform.AccountLoginRangeOffset !== null)
            request.AccountLoginRangeOffset = UInt64.fromValue(newPlatform.AccountLoginRangeOffset);
        if (newPlatform.AccountLoginRangeSize !== null)
            request.AccountLoginRangeSize = UInt64.fromValue(newPlatform.AccountLoginRangeSize);

        request.FeaturesToggle = new FeaturesToggle();
        request.FeaturesToggle.MultipleDashboards = newPlatform.FeaturesToggle.MultipleDashboards;
        request.FeaturesToggle.SocialTradingAccountsManipulation =
            newPlatform.FeaturesToggle.SocialTradingAccountsManipulation;
        request.DailyReportTemplateId =
            newPlatform.DailyReportTemplateId != null ? UUID.fromString(newPlatform.DailyReportTemplateId) : null;
        request.MonthlyReportTemplateId =
            newPlatform.MonthlyReportTemplateId != null ? UUID.fromString(newPlatform.MonthlyReportTemplateId) : null;
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(updatedPlatform: Platform, dirtyFields?: FieldNamesMarkedBoolean<FieldValues>) {
        const request = new PlatformModifyRequest();
        request.PlatformId = UUID.fromString(updatedPlatform.id);
        request.NewSortOrder = updatedPlatform.SortOrder;
        request.NewName = updatedPlatform.Name;
        request.NewTimezoneId = UUID.fromString(updatedPlatform.TimezoneId);
        if (dirtyFields?.RolloverTime) {
            request.NewRolloverTime =
                updatedPlatform && typeof updatedPlatform.RolloverTime === 'number'
                    ? UInt64.fromValue(updatedPlatform.RolloverTime)
                    : null;
        }

        // request.NewSnapshotTime =  // Snapshot time field skipped for now
        //     updatedPlatform && typeof updatedPlatform.SnapshotTime === 'number'
        //         ? UInt64.fromValue(updatedPlatform.SnapshotTime)
        //         : null;
        request.NewComment = updatedPlatform.Comment;
        request.NewDescription = updatedPlatform.Description;

        request.NewCompanyName = updatedPlatform.CompanyName;
        request.NewCompanyFullName = updatedPlatform.CompanyFullName;
        request.NewCompanyEmail = updatedPlatform.CompanyEmail;
        request.NewCompanyPhone = updatedPlatform.CompanyPhone;
        request.NewCompanyAddress = updatedPlatform.CompanyAddress;
        request.NewCompanyWebsite = updatedPlatform.CompanyWebsite;

        request.NewCompanyDescription = updatedPlatform.CompanyDescription;
        if (updatedPlatform.ManagerLoginRangeOffset !== null)
            request.NewManagerLoginRangeOffset = UInt64.fromValue(updatedPlatform.ManagerLoginRangeOffset);
        if (updatedPlatform.ManagerLoginRangeSize !== null)
            request.NewManagerLoginRangeSize = UInt64.fromValue(updatedPlatform.ManagerLoginRangeSize);
        if (updatedPlatform.AccountLoginRangeOffset !== null)
            request.NewAccountLoginRangeOffset = UInt64.fromValue(updatedPlatform.AccountLoginRangeOffset);
        if (updatedPlatform.AccountLoginRangeSize !== null)
            request.NewAccountLoginRangeSize = UInt64.fromValue(updatedPlatform.AccountLoginRangeSize);

        request.NewFeaturesToggle = new FeaturesToggle();
        request.NewFeaturesToggle.MultipleDashboards = updatedPlatform.FeaturesToggle.MultipleDashboards;
        request.NewFeaturesToggle.SocialTradingAccountsManipulation =
            updatedPlatform.FeaturesToggle.SocialTradingAccountsManipulation;
        if (updatedPlatform.DailyReportTemplateId !== null) {
            request.NewDailyReportTemplateId = UUID.fromString(updatedPlatform.DailyReportTemplateId);
        } else {
            request.NewDailyReportTemplateId = null;
            request.NewDailyReportTemplateIdReset = true;
        }

        if (updatedPlatform.MonthlyReportTemplateId !== null) {
            request.NewMonthlyReportTemplateId = UUID.fromString(updatedPlatform.MonthlyReportTemplateId);
        } else {
            request.NewMonthlyReportTemplateId = null;
            request.NewMonthlyReportTemplateIdReset = true;
        }

        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string) {
        const request = new PlatformDeleteRequest();
        request.PlatformId = UUID.fromString(id);
        await SocketClient.instance.request(request, this.errorTracker);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const platformsStore = new PlatformsStore();
export { platformsStore };
