import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import StorageHelper from '@utils/StorageHelper';
import { authStore } from '@store/auth';
import { IRouteTree, RouterTree } from '@components/Router';
import { AdditionalRouteParams, getRoutes } from '../routes/routes';
import { useReaction } from '@pages/utils/commonUtils';
import { AccessContext, AdminPanelAccessMap, getAccessMap } from '@utils/accessMap';
import { LS_WS_CONNECTION_KEY } from '@constants';
import { isValidWsUrl } from '../models/Auth';
import { SocketClient } from '@api/SocketClient';

function App() {
    const [rights] = useReaction(() => authStore.rights);
    const accessMap = useMemo<AdminPanelAccessMap | null>(() => (rights ? getAccessMap(rights) : null), [rights]);
    const filteredRoutes = useMemo<IRouteTree<AdditionalRouteParams>>(() => getRoutes(accessMap), [accessMap]);

    const checkSession = () => {
        const cookieToken = StorageHelper.getFromCookie('SessionData');
        const wsUrl = StorageHelper.getFromLocal(LS_WS_CONNECTION_KEY);

        if (SocketClient.instance.websocket && cookieToken && isValidWsUrl(wsUrl)) {
            authStore.setAuth(true);
            authStore.authHandler({
                wsUrl,
                token: cookieToken.AuthToken,
                login: cookieToken.Login,
            });
        } else {
            authStore.logout();
        }
    };

    useEffect(() => {
        checkSession();
        authStore.subscribeSessionEvents();

        return () => {
            authStore.unsubscribeSessionEvents();
        };
    }, []);

    return (
        <AccessContext.Provider value={accessMap}>
            <RouterTree route={filteredRoutes} />
        </AccessContext.Provider>
    );
}

export default observer(App);
