
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * AccountMarginUpdate struct
 */
class AccountMarginUpdate {
  /**
   * Initialize struct
   * @param {!number=} key
   * @param {number=} actualVersion
   * @param {UUID=} balanceCurrencyId
   * @param {number=} balanceChange
   * @param {number=} balanceTotal
   * @param {number=} creditChange
   * @param {number=} creditTotal
   * @param {number=} leverage
   * @param {number=} prevDayOpenProfit
   * @param {number=} prevDayBalance
   * @param {number=} prevMonthBalance
   * @param {number=} dayCloseProfit
   * @param {number=} dayDeposit
   * @param {number=} maxLeverage
   * @param {!boolean=} maxLeverageReset
   * @param {number=} totalCloseProfitChange
   * @param {number=} totalCloseProfitTotal
   * @param {number=} totalDepositChange
   * @param {number=} totalDepositTotal
   * @param {number=} totalWithdrawalChange
   * @param {number=} totalWithdrawalTotal
   * @param {number=} marginHistoryRecordOrderId
   * @param {string=} marginHistoryManagerComment
   * @constructor
   */
  constructor (key = new UInt64(0, 0), actualVersion = null, balanceCurrencyId = null, balanceChange = null, balanceTotal = null, creditChange = null, creditTotal = null, leverage = null, prevDayOpenProfit = null, prevDayBalance = null, prevMonthBalance = null, dayCloseProfit = null, dayDeposit = null, maxLeverage = null, maxLeverageReset = false, totalCloseProfitChange = null, totalCloseProfitTotal = null, totalDepositChange = null, totalDepositTotal = null, totalWithdrawalChange = null, totalWithdrawalTotal = null, marginHistoryRecordOrderId = null, marginHistoryManagerComment = null) {
    this.Key = key
    this.ActualVersion = actualVersion
    this.BalanceCurrencyId = balanceCurrencyId
    this.BalanceChange = balanceChange
    this.BalanceTotal = balanceTotal
    this.CreditChange = creditChange
    this.CreditTotal = creditTotal
    this.Leverage = leverage
    this.PrevDayOpenProfit = prevDayOpenProfit
    this.PrevDayBalance = prevDayBalance
    this.PrevMonthBalance = prevMonthBalance
    this.DayCloseProfit = dayCloseProfit
    this.DayDeposit = dayDeposit
    this.MaxLeverage = maxLeverage
    this.MaxLeverageReset = maxLeverageReset
    this.TotalCloseProfitChange = totalCloseProfitChange
    this.TotalCloseProfitTotal = totalCloseProfitTotal
    this.TotalDepositChange = totalDepositChange
    this.TotalDepositTotal = totalDepositTotal
    this.TotalWithdrawalChange = totalWithdrawalChange
    this.TotalWithdrawalTotal = totalWithdrawalTotal
    this.MarginHistoryRecordOrderId = marginHistoryRecordOrderId
    this.MarginHistoryManagerComment = marginHistoryManagerComment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountMarginUpdate}
   * @param {!AccountMarginUpdate} other Other struct
   * @returns {!AccountMarginUpdate} This struct
   */
  copy (other) {
    if (other.Key != null) {
      this.Key = UInt64.fromNumber(other.Key)
    } else {
      this.Key = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.BalanceCurrencyId != null) {
      this.BalanceCurrencyId = new UUID(other.BalanceCurrencyId)
    } else {
      this.BalanceCurrencyId = null
    }
    if (other.BalanceChange != null) {
      this.BalanceChange = other.BalanceChange
    } else {
      this.BalanceChange = null
    }
    if (other.BalanceTotal != null) {
      this.BalanceTotal = other.BalanceTotal
    } else {
      this.BalanceTotal = null
    }
    if (other.CreditChange != null) {
      this.CreditChange = other.CreditChange
    } else {
      this.CreditChange = null
    }
    if (other.CreditTotal != null) {
      this.CreditTotal = other.CreditTotal
    } else {
      this.CreditTotal = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    if (other.PrevDayOpenProfit != null) {
      this.PrevDayOpenProfit = other.PrevDayOpenProfit
    } else {
      this.PrevDayOpenProfit = null
    }
    if (other.PrevDayBalance != null) {
      this.PrevDayBalance = other.PrevDayBalance
    } else {
      this.PrevDayBalance = null
    }
    if (other.PrevMonthBalance != null) {
      this.PrevMonthBalance = other.PrevMonthBalance
    } else {
      this.PrevMonthBalance = null
    }
    if (other.DayCloseProfit != null) {
      this.DayCloseProfit = other.DayCloseProfit
    } else {
      this.DayCloseProfit = null
    }
    if (other.DayDeposit != null) {
      this.DayDeposit = other.DayDeposit
    } else {
      this.DayDeposit = null
    }
    if (other.MaxLeverage != null) {
      this.MaxLeverage = other.MaxLeverage
    } else {
      this.MaxLeverage = null
    }
    if (other.MaxLeverageReset != null) {
      this.MaxLeverageReset = other.MaxLeverageReset
    } else {
      this.MaxLeverageReset = null
    }
    if (other.TotalCloseProfitChange != null) {
      this.TotalCloseProfitChange = other.TotalCloseProfitChange
    } else {
      this.TotalCloseProfitChange = null
    }
    if (other.TotalCloseProfitTotal != null) {
      this.TotalCloseProfitTotal = other.TotalCloseProfitTotal
    } else {
      this.TotalCloseProfitTotal = null
    }
    if (other.TotalDepositChange != null) {
      this.TotalDepositChange = other.TotalDepositChange
    } else {
      this.TotalDepositChange = null
    }
    if (other.TotalDepositTotal != null) {
      this.TotalDepositTotal = other.TotalDepositTotal
    } else {
      this.TotalDepositTotal = null
    }
    if (other.TotalWithdrawalChange != null) {
      this.TotalWithdrawalChange = other.TotalWithdrawalChange
    } else {
      this.TotalWithdrawalChange = null
    }
    if (other.TotalWithdrawalTotal != null) {
      this.TotalWithdrawalTotal = other.TotalWithdrawalTotal
    } else {
      this.TotalWithdrawalTotal = null
    }
    if (other.MarginHistoryRecordOrderId != null) {
      this.MarginHistoryRecordOrderId = UInt64.fromNumber(other.MarginHistoryRecordOrderId)
    } else {
      this.MarginHistoryRecordOrderId = null
    }
    if (other.MarginHistoryManagerComment != null) {
      this.MarginHistoryManagerComment = other.MarginHistoryManagerComment
    } else {
      this.MarginHistoryManagerComment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountMarginUpdate}
   * @returns {!AccountMarginUpdate} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountMarginUpdateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountMarginUpdateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountMarginUpdate}
   * @param {!AccountMarginUpdate} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountMarginUpdate)) {
      throw new TypeError('Instance of AccountMarginUpdate is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountMarginUpdate}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Key: ((this.Key != null) ? this.Key.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      BalanceCurrencyId: ((this.BalanceCurrencyId != null) ? this.BalanceCurrencyId.toString() : null),
      BalanceChange: ((this.BalanceChange != null) ? this.BalanceChange : null),
      BalanceTotal: ((this.BalanceTotal != null) ? this.BalanceTotal : null),
      CreditChange: ((this.CreditChange != null) ? this.CreditChange : null),
      CreditTotal: ((this.CreditTotal != null) ? this.CreditTotal : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null),
      PrevDayOpenProfit: ((this.PrevDayOpenProfit != null) ? this.PrevDayOpenProfit : null),
      PrevDayBalance: ((this.PrevDayBalance != null) ? this.PrevDayBalance : null),
      PrevMonthBalance: ((this.PrevMonthBalance != null) ? this.PrevMonthBalance : null),
      DayCloseProfit: ((this.DayCloseProfit != null) ? this.DayCloseProfit : null),
      DayDeposit: ((this.DayDeposit != null) ? this.DayDeposit : null),
      MaxLeverage: ((this.MaxLeverage != null) ? this.MaxLeverage : null),
      MaxLeverageReset: ((this.MaxLeverageReset != null) ? this.MaxLeverageReset : null),
      TotalCloseProfitChange: ((this.TotalCloseProfitChange != null) ? this.TotalCloseProfitChange : null),
      TotalCloseProfitTotal: ((this.TotalCloseProfitTotal != null) ? this.TotalCloseProfitTotal : null),
      TotalDepositChange: ((this.TotalDepositChange != null) ? this.TotalDepositChange : null),
      TotalDepositTotal: ((this.TotalDepositTotal != null) ? this.TotalDepositTotal : null),
      TotalWithdrawalChange: ((this.TotalWithdrawalChange != null) ? this.TotalWithdrawalChange : null),
      TotalWithdrawalTotal: ((this.TotalWithdrawalTotal != null) ? this.TotalWithdrawalTotal : null),
      MarginHistoryRecordOrderId: ((this.MarginHistoryRecordOrderId != null) ? this.MarginHistoryRecordOrderId.toNumber() : null),
      MarginHistoryManagerComment: ((this.MarginHistoryManagerComment != null) ? this.MarginHistoryManagerComment : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountMarginUpdate.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountMarginUpdate} other Object value
   * @returns {!AccountMarginUpdate} Created struct
   */
  static fromObject (other) {
    return new AccountMarginUpdate().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountMarginUpdate}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountMarginUpdate.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountMarginUpdate}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4166
  }
}

export { AccountMarginUpdate };

/**
 * Fast Binary Encoding AccountMarginUpdate field model
 */
class FieldModelAccountMarginUpdate extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Key = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Key.fbeOffset + this.Key.fbeSize), buffer, this.Key.fbeOffset + this.Key.fbeSize)
    this.BalanceCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize), buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.BalanceChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceCurrencyId.fbeOffset + this.BalanceCurrencyId.fbeSize), buffer, this.BalanceCurrencyId.fbeOffset + this.BalanceCurrencyId.fbeSize)
    this.BalanceTotal = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceChange.fbeOffset + this.BalanceChange.fbeSize), buffer, this.BalanceChange.fbeOffset + this.BalanceChange.fbeSize)
    this.CreditChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceTotal.fbeOffset + this.BalanceTotal.fbeSize), buffer, this.BalanceTotal.fbeOffset + this.BalanceTotal.fbeSize)
    this.CreditTotal = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.CreditChange.fbeOffset + this.CreditChange.fbeSize), buffer, this.CreditChange.fbeOffset + this.CreditChange.fbeSize)
    this.Leverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.CreditTotal.fbeOffset + this.CreditTotal.fbeSize), buffer, this.CreditTotal.fbeOffset + this.CreditTotal.fbeSize)
    this.PrevDayOpenProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize), buffer, this.Leverage.fbeOffset + this.Leverage.fbeSize)
    this.PrevDayBalance = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.PrevDayOpenProfit.fbeOffset + this.PrevDayOpenProfit.fbeSize), buffer, this.PrevDayOpenProfit.fbeOffset + this.PrevDayOpenProfit.fbeSize)
    this.PrevMonthBalance = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize), buffer, this.PrevDayBalance.fbeOffset + this.PrevDayBalance.fbeSize)
    this.DayCloseProfit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.PrevMonthBalance.fbeOffset + this.PrevMonthBalance.fbeSize), buffer, this.PrevMonthBalance.fbeOffset + this.PrevMonthBalance.fbeSize)
    this.DayDeposit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize), buffer, this.DayCloseProfit.fbeOffset + this.DayCloseProfit.fbeSize)
    this.MaxLeverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize), buffer, this.DayDeposit.fbeOffset + this.DayDeposit.fbeSize)
    this.MaxLeverageReset = new fbe.FieldModelBool(buffer, this.MaxLeverage.fbeOffset + this.MaxLeverage.fbeSize)
    this.TotalCloseProfitChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.MaxLeverageReset.fbeOffset + this.MaxLeverageReset.fbeSize), buffer, this.MaxLeverageReset.fbeOffset + this.MaxLeverageReset.fbeSize)
    this.TotalCloseProfitTotal = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalCloseProfitChange.fbeOffset + this.TotalCloseProfitChange.fbeSize), buffer, this.TotalCloseProfitChange.fbeOffset + this.TotalCloseProfitChange.fbeSize)
    this.TotalDepositChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalCloseProfitTotal.fbeOffset + this.TotalCloseProfitTotal.fbeSize), buffer, this.TotalCloseProfitTotal.fbeOffset + this.TotalCloseProfitTotal.fbeSize)
    this.TotalDepositTotal = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalDepositChange.fbeOffset + this.TotalDepositChange.fbeSize), buffer, this.TotalDepositChange.fbeOffset + this.TotalDepositChange.fbeSize)
    this.TotalWithdrawalChange = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalDepositTotal.fbeOffset + this.TotalDepositTotal.fbeSize), buffer, this.TotalDepositTotal.fbeOffset + this.TotalDepositTotal.fbeSize)
    this.TotalWithdrawalTotal = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.TotalWithdrawalChange.fbeOffset + this.TotalWithdrawalChange.fbeSize), buffer, this.TotalWithdrawalChange.fbeOffset + this.TotalWithdrawalChange.fbeSize)
    this.MarginHistoryRecordOrderId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.TotalWithdrawalTotal.fbeOffset + this.TotalWithdrawalTotal.fbeSize), buffer, this.TotalWithdrawalTotal.fbeOffset + this.TotalWithdrawalTotal.fbeSize)
    this.MarginHistoryManagerComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MarginHistoryRecordOrderId.fbeOffset + this.MarginHistoryRecordOrderId.fbeSize), buffer, this.MarginHistoryRecordOrderId.fbeOffset + this.MarginHistoryRecordOrderId.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Key.fbeSize + this.ActualVersion.fbeSize + this.BalanceCurrencyId.fbeSize + this.BalanceChange.fbeSize + this.BalanceTotal.fbeSize + this.CreditChange.fbeSize + this.CreditTotal.fbeSize + this.Leverage.fbeSize + this.PrevDayOpenProfit.fbeSize + this.PrevDayBalance.fbeSize + this.PrevMonthBalance.fbeSize + this.DayCloseProfit.fbeSize + this.DayDeposit.fbeSize + this.MaxLeverage.fbeSize + this.MaxLeverageReset.fbeSize + this.TotalCloseProfitChange.fbeSize + this.TotalCloseProfitTotal.fbeSize + this.TotalDepositChange.fbeSize + this.TotalDepositTotal.fbeSize + this.TotalWithdrawalChange.fbeSize + this.TotalWithdrawalTotal.fbeSize + this.MarginHistoryRecordOrderId.fbeSize + this.MarginHistoryManagerComment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Key.fbeExtra + this.ActualVersion.fbeExtra + this.BalanceCurrencyId.fbeExtra + this.BalanceChange.fbeExtra + this.BalanceTotal.fbeExtra + this.CreditChange.fbeExtra + this.CreditTotal.fbeExtra + this.Leverage.fbeExtra + this.PrevDayOpenProfit.fbeExtra + this.PrevDayBalance.fbeExtra + this.PrevMonthBalance.fbeExtra + this.DayCloseProfit.fbeExtra + this.DayDeposit.fbeExtra + this.MaxLeverage.fbeExtra + this.MaxLeverageReset.fbeExtra + this.TotalCloseProfitChange.fbeExtra + this.TotalCloseProfitTotal.fbeExtra + this.TotalDepositChange.fbeExtra + this.TotalDepositTotal.fbeExtra + this.TotalWithdrawalChange.fbeExtra + this.TotalWithdrawalTotal.fbeExtra + this.MarginHistoryRecordOrderId.fbeExtra + this.MarginHistoryManagerComment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountMarginUpdate.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4166
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Key.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Key.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.BalanceChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceChange.fbeSize

    if ((fbeCurrentSize + this.BalanceTotal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceTotal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceTotal.fbeSize

    if ((fbeCurrentSize + this.CreditChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CreditChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CreditChange.fbeSize

    if ((fbeCurrentSize + this.CreditTotal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CreditTotal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CreditTotal.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayOpenProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevDayBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PrevMonthBalance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayCloseProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DayDeposit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.MaxLeverageReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxLeverageReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfitChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalCloseProfitChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfitChange.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfitTotal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalCloseProfitTotal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfitTotal.fbeSize

    if ((fbeCurrentSize + this.TotalDepositChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalDepositChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDepositChange.fbeSize

    if ((fbeCurrentSize + this.TotalDepositTotal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalDepositTotal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDepositTotal.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawalChange.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalWithdrawalChange.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawalChange.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawalTotal.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalWithdrawalTotal.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawalTotal.fbeSize

    if ((fbeCurrentSize + this.MarginHistoryRecordOrderId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginHistoryRecordOrderId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginHistoryRecordOrderId.fbeSize

    if ((fbeCurrentSize + this.MarginHistoryManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginHistoryManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginHistoryManagerComment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!AccountMarginUpdate} fbeValue Default value, defaults is new AccountMarginUpdate()
   * @returns {!AccountMarginUpdate} AccountMarginUpdate value
   */
  get (fbeValue = new AccountMarginUpdate()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!AccountMarginUpdate} fbeValue AccountMarginUpdate value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Key.fbeSize) <= fbeStructSize) {
      fbeValue.Key = this.Key.get()
    } else {
      fbeValue.Key = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrencyId = this.BalanceCurrencyId.get()
    } else {
      fbeValue.BalanceCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrencyId.fbeSize

    if ((fbeCurrentSize + this.BalanceChange.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceChange = this.BalanceChange.get()
    } else {
      fbeValue.BalanceChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceChange.fbeSize

    if ((fbeCurrentSize + this.BalanceTotal.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceTotal = this.BalanceTotal.get()
    } else {
      fbeValue.BalanceTotal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceTotal.fbeSize

    if ((fbeCurrentSize + this.CreditChange.fbeSize) <= fbeStructSize) {
      fbeValue.CreditChange = this.CreditChange.get()
    } else {
      fbeValue.CreditChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CreditChange.fbeSize

    if ((fbeCurrentSize + this.CreditTotal.fbeSize) <= fbeStructSize) {
      fbeValue.CreditTotal = this.CreditTotal.get()
    } else {
      fbeValue.CreditTotal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CreditTotal.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    if ((fbeCurrentSize + this.PrevDayOpenProfit.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayOpenProfit = this.PrevDayOpenProfit.get()
    } else {
      fbeValue.PrevDayOpenProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayOpenProfit.fbeSize

    if ((fbeCurrentSize + this.PrevDayBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevDayBalance = this.PrevDayBalance.get()
    } else {
      fbeValue.PrevDayBalance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevDayBalance.fbeSize

    if ((fbeCurrentSize + this.PrevMonthBalance.fbeSize) <= fbeStructSize) {
      fbeValue.PrevMonthBalance = this.PrevMonthBalance.get()
    } else {
      fbeValue.PrevMonthBalance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PrevMonthBalance.fbeSize

    if ((fbeCurrentSize + this.DayCloseProfit.fbeSize) <= fbeStructSize) {
      fbeValue.DayCloseProfit = this.DayCloseProfit.get()
    } else {
      fbeValue.DayCloseProfit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayCloseProfit.fbeSize

    if ((fbeCurrentSize + this.DayDeposit.fbeSize) <= fbeStructSize) {
      fbeValue.DayDeposit = this.DayDeposit.get()
    } else {
      fbeValue.DayDeposit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DayDeposit.fbeSize

    if ((fbeCurrentSize + this.MaxLeverage.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverage = this.MaxLeverage.get()
    } else {
      fbeValue.MaxLeverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverage.fbeSize

    if ((fbeCurrentSize + this.MaxLeverageReset.fbeSize) <= fbeStructSize) {
      fbeValue.MaxLeverageReset = this.MaxLeverageReset.get()
    } else {
      fbeValue.MaxLeverageReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxLeverageReset.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfitChange.fbeSize) <= fbeStructSize) {
      fbeValue.TotalCloseProfitChange = this.TotalCloseProfitChange.get()
    } else {
      fbeValue.TotalCloseProfitChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfitChange.fbeSize

    if ((fbeCurrentSize + this.TotalCloseProfitTotal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalCloseProfitTotal = this.TotalCloseProfitTotal.get()
    } else {
      fbeValue.TotalCloseProfitTotal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalCloseProfitTotal.fbeSize

    if ((fbeCurrentSize + this.TotalDepositChange.fbeSize) <= fbeStructSize) {
      fbeValue.TotalDepositChange = this.TotalDepositChange.get()
    } else {
      fbeValue.TotalDepositChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDepositChange.fbeSize

    if ((fbeCurrentSize + this.TotalDepositTotal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalDepositTotal = this.TotalDepositTotal.get()
    } else {
      fbeValue.TotalDepositTotal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalDepositTotal.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawalChange.fbeSize) <= fbeStructSize) {
      fbeValue.TotalWithdrawalChange = this.TotalWithdrawalChange.get()
    } else {
      fbeValue.TotalWithdrawalChange = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawalChange.fbeSize

    if ((fbeCurrentSize + this.TotalWithdrawalTotal.fbeSize) <= fbeStructSize) {
      fbeValue.TotalWithdrawalTotal = this.TotalWithdrawalTotal.get()
    } else {
      fbeValue.TotalWithdrawalTotal = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalWithdrawalTotal.fbeSize

    if ((fbeCurrentSize + this.MarginHistoryRecordOrderId.fbeSize) <= fbeStructSize) {
      fbeValue.MarginHistoryRecordOrderId = this.MarginHistoryRecordOrderId.get()
    } else {
      fbeValue.MarginHistoryRecordOrderId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginHistoryRecordOrderId.fbeSize

    if ((fbeCurrentSize + this.MarginHistoryManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.MarginHistoryManagerComment = this.MarginHistoryManagerComment.get()
    } else {
      fbeValue.MarginHistoryManagerComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginHistoryManagerComment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountMarginUpdate}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountMarginUpdate}
   * @param {!AccountMarginUpdate} fbeValue AccountMarginUpdate value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountMarginUpdate}
   * @param {AccountMarginUpdate} fbeValue AccountMarginUpdate value
   */
  setFields (fbeValue) {
    this.Key.set(fbeValue.Key)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.BalanceCurrencyId.set(fbeValue.BalanceCurrencyId)
    this.BalanceChange.set(fbeValue.BalanceChange)
    this.BalanceTotal.set(fbeValue.BalanceTotal)
    this.CreditChange.set(fbeValue.CreditChange)
    this.CreditTotal.set(fbeValue.CreditTotal)
    this.Leverage.set(fbeValue.Leverage)
    this.PrevDayOpenProfit.set(fbeValue.PrevDayOpenProfit)
    this.PrevDayBalance.set(fbeValue.PrevDayBalance)
    this.PrevMonthBalance.set(fbeValue.PrevMonthBalance)
    this.DayCloseProfit.set(fbeValue.DayCloseProfit)
    this.DayDeposit.set(fbeValue.DayDeposit)
    this.MaxLeverage.set(fbeValue.MaxLeverage)
    this.MaxLeverageReset.set(fbeValue.MaxLeverageReset)
    this.TotalCloseProfitChange.set(fbeValue.TotalCloseProfitChange)
    this.TotalCloseProfitTotal.set(fbeValue.TotalCloseProfitTotal)
    this.TotalDepositChange.set(fbeValue.TotalDepositChange)
    this.TotalDepositTotal.set(fbeValue.TotalDepositTotal)
    this.TotalWithdrawalChange.set(fbeValue.TotalWithdrawalChange)
    this.TotalWithdrawalTotal.set(fbeValue.TotalWithdrawalTotal)
    this.MarginHistoryRecordOrderId.set(fbeValue.MarginHistoryRecordOrderId)
    this.MarginHistoryManagerComment.set(fbeValue.MarginHistoryManagerComment)
  }
}

export { FieldModelAccountMarginUpdate };

/**
 * Fast Binary Encoding AccountMarginUpdate model
 */
class AccountMarginUpdateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountMarginUpdate(this.buffer, 4)
  }

  /**
   * Get the AccountMarginUpdate model
   * @this {!AccountMarginUpdateModel}
   * @returns {!FieldModelAccountMarginUpdate} model AccountMarginUpdate model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountMarginUpdateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountMarginUpdateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountMarginUpdateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountMarginUpdateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountMarginUpdate.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountMarginUpdateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountMarginUpdateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountMarginUpdateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountMarginUpdateModel}
   * @param {!AccountMarginUpdate} value AccountMarginUpdate value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountMarginUpdateModel}
   * @param {!AccountMarginUpdate} value AccountMarginUpdate value, defaults is new AccountMarginUpdate()
   * @return {!object} Deserialized AccountMarginUpdate value and its size
   */
  deserialize (value = new AccountMarginUpdate()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountMarginUpdate(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountMarginUpdate(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountMarginUpdateModel}
   * @param {!number} prev Previous AccountMarginUpdate model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountMarginUpdateModel };
