import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const DraggableTableSrollWrapperContext = React.createContext<HTMLDivElement | null>(null!);

export const DraggableTableWrapper = ({ children }) => {
    const scrollContanerRef = useRef<HTMLDivElement | null>(null);
    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        setScrollContainer(scrollContanerRef.current);
    }, []);
    return (
        <DraggableTableSrollWrapperContext.Provider value={scrollContainer}>
            <div className={styles.tableWrapperDraggable} ref={scrollContanerRef}>
                {children}
            </div>
        </DraggableTableSrollWrapperContext.Provider>
    );
};

export const DragHandle = SortableHandle(() => <MenuOutlined className={styles.menuIcon} />);

const SortableCont = SortableContainer((props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <tbody {...props} />
));

export function useSortableTableRows<RowT>(
    params: {
        onSortEnd: (params: { oldIndex: number; newIndex: number }) => void;

        findItemIndex: (dataRowKey: string | number) => number;

        /** additional class */
        sortableItemClass?: (item: RowT) => string;

        sortableContainer?: Partial<SortableContainerProps>;
    },
) {
    const { onSortEnd, sortableItemClass, findItemIndex, sortableContainer } = params;
    const scrollContaner = useContext(DraggableTableSrollWrapperContext);
    // Idea:
    // Before user start dragging row, we capture layout's style from it
    // & assign to dragginghelper element
    const tableWidth = useRef({
        width: undefined as number | undefined,
        columns: [] as Pick<CSSStyleDeclaration, 'padding' | 'width'>[],
    });

    const handleSortEnd = useCallback(
        (args: { oldIndex: number; newIndex: number }) => {
            onSortEnd(args);
        },
        [onSortEnd],
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleUpdateBeforeSortStart = useCallback<Required<SortableContainerProps>['updateBeforeSortStart']>(
        (params_, _evt) => {
            const { width: rowWidth } = params_.node.getBoundingClientRect();
            tableWidth.current.width = rowWidth;

            params_.node.querySelectorAll('td').forEach((td, i) => {
                const tdStyles = window.getComputedStyle(td);
                tableWidth.current.columns[i] = {
                    padding: tdStyles.padding,
                    width: tdStyles.width,
                };
            });
        },
        [],
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSortStart = useCallback<Required<SortableContainerProps>['onSortStart']>((params_, _evt) => {
        params_.helper.style.width = `${tableWidth.current.width}px`;
        params_.helper.querySelectorAll('td').forEach((td, i) => {
            td.style.padding = tableWidth.current.columns[i].padding!;
            td.style.width = tableWidth.current.columns[i].width!;
        });
    }, []);

    const TableWrapper = useCallback(
        (props) => {
            const additionalProps = scrollContaner ? { getContainer: () => scrollContaner } : {};
            return (
                <SortableCont
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    useDragHandle
                    helperClass={styles.rowDragging}
                    axis="y"
                    lockAxis="y"
                    lockToContainerEdges
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...sortableContainer}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...additionalProps}
                    updateBeforeSortStart={handleUpdateBeforeSortStart}
                    onSortStart={handleSortStart}
                    onSortEnd={handleSortEnd}
                />
            );
        },
        [handleUpdateBeforeSortStart, handleSortStart, handleSortEnd, sortableContainer],
    );

    const SortableItem = useMemo(
        () =>
            SortableElement((props: any) => (
                <tr
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    className={`${props.className || ''} ${
                        sortableItemClass && props.children[0]?.props.record
                            ? sortableItemClass(props.children[0]?.props.record)
                            : ''
                    }`}
                />
            )),
        [sortableItemClass],
    );

    const TableRow = useMemo(
        () =>
            ({ className, style, ...restProps }) => {
                const index = findItemIndex(restProps['data-row-key']);
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <SortableItem index={index} {...restProps} />;
            },
        [findItemIndex, SortableItem],
    );

    return {
        TableWrapper,
        TableRow,
    };
}
