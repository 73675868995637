
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as history from '../history'
import * as client from '../client'
import * as feed from '../feed'
import * as storage from '../storage'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'
import * as trade from '../trade'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {SecuritySettingsCreateResponse} from './SecuritySettingsCreateResponse';

/**
 * SecuritySettingsCreateRequest struct
 */
class SecuritySettingsCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {!string=} name
   * @param {!string=} description
   * @param {!boolean=} isFirewallEnabled
   * @param {!boolean=} isAntifloodEnabled
   * @param {!number=} connectionRateThreshold
   * @param {!number=} failedLoginRateThreshold
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, name = '', description = '', isFirewallEnabled = true, isAntifloodEnabled = true, connectionRateThreshold = 100, failedLoginRateThreshold = 10) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.Name = name
    this.Description = description
    this.IsFirewallEnabled = isFirewallEnabled
    this.IsAntifloodEnabled = isAntifloodEnabled
    this.ConnectionRateThreshold = connectionRateThreshold
    this.FailedLoginRateThreshold = failedLoginRateThreshold
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SecuritySettingsCreateRequest}
   * @param {!SecuritySettingsCreateRequest} other Other struct
   * @returns {!SecuritySettingsCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsFirewallEnabled != null) {
      this.IsFirewallEnabled = other.IsFirewallEnabled
    } else {
      this.IsFirewallEnabled = null
    }
    if (other.IsAntifloodEnabled != null) {
      this.IsAntifloodEnabled = other.IsAntifloodEnabled
    } else {
      this.IsAntifloodEnabled = null
    }
    if (other.ConnectionRateThreshold != null) {
      this.ConnectionRateThreshold = other.ConnectionRateThreshold
    } else {
      this.ConnectionRateThreshold = null
    }
    if (other.FailedLoginRateThreshold != null) {
      this.FailedLoginRateThreshold = other.FailedLoginRateThreshold
    } else {
      this.FailedLoginRateThreshold = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SecuritySettingsCreateRequest}
   * @returns {!SecuritySettingsCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SecuritySettingsCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SecuritySettingsCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SecuritySettingsCreateRequest}
   * @param {!SecuritySettingsCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SecuritySettingsCreateRequest)) {
      throw new TypeError('Instance of SecuritySettingsCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SecuritySettingsCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      Name: ((this.Name != null) ? this.Name : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsFirewallEnabled: ((this.IsFirewallEnabled != null) ? this.IsFirewallEnabled : null),
      IsAntifloodEnabled: ((this.IsAntifloodEnabled != null) ? this.IsAntifloodEnabled : null),
      ConnectionRateThreshold: ((this.ConnectionRateThreshold != null) ? this.ConnectionRateThreshold : null),
      FailedLoginRateThreshold: ((this.FailedLoginRateThreshold != null) ? this.FailedLoginRateThreshold : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SecuritySettingsCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SecuritySettingsCreateRequest} other Object value
   * @returns {!SecuritySettingsCreateRequest} Created struct
   */
  static fromObject (other) {
    return new SecuritySettingsCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SecuritySettingsCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SecuritySettingsCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SecuritySettingsCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6536
  }
}

export { SecuritySettingsCreateRequest };

/**
 * Fast Binary Encoding SecuritySettingsCreateRequest field model
 */
class FieldModelSecuritySettingsCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Name = new fbe.FieldModelString(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.IsFirewallEnabled = new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsAntifloodEnabled = new fbe.FieldModelBool(buffer, this.IsFirewallEnabled.fbeOffset + this.IsFirewallEnabled.fbeSize)
    this.ConnectionRateThreshold = new fbe.FieldModelUInt32(buffer, this.IsAntifloodEnabled.fbeOffset + this.IsAntifloodEnabled.fbeSize)
    this.FailedLoginRateThreshold = new fbe.FieldModelUInt32(buffer, this.ConnectionRateThreshold.fbeOffset + this.ConnectionRateThreshold.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.Name.fbeSize + this.Description.fbeSize + this.IsFirewallEnabled.fbeSize + this.IsAntifloodEnabled.fbeSize + this.ConnectionRateThreshold.fbeSize + this.FailedLoginRateThreshold.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.Name.fbeExtra + this.Description.fbeExtra + this.IsFirewallEnabled.fbeExtra + this.IsAntifloodEnabled.fbeExtra + this.ConnectionRateThreshold.fbeExtra + this.FailedLoginRateThreshold.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSecuritySettingsCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6536
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsFirewallEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsFirewallEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFirewallEnabled.fbeSize

    if ((fbeCurrentSize + this.IsAntifloodEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsAntifloodEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsAntifloodEnabled.fbeSize

    if ((fbeCurrentSize + this.ConnectionRateThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConnectionRateThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConnectionRateThreshold.fbeSize

    if ((fbeCurrentSize + this.FailedLoginRateThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FailedLoginRateThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FailedLoginRateThreshold.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!SecuritySettingsCreateRequest} fbeValue Default value, defaults is new SecuritySettingsCreateRequest()
   * @returns {!SecuritySettingsCreateRequest} SecuritySettingsCreateRequest value
   */
  get (fbeValue = new SecuritySettingsCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!SecuritySettingsCreateRequest} fbeValue SecuritySettingsCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsFirewallEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsFirewallEnabled = this.IsFirewallEnabled.get(true)
    } else {
      fbeValue.IsFirewallEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsFirewallEnabled.fbeSize

    if ((fbeCurrentSize + this.IsAntifloodEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsAntifloodEnabled = this.IsAntifloodEnabled.get(true)
    } else {
      fbeValue.IsAntifloodEnabled = true
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsAntifloodEnabled.fbeSize

    if ((fbeCurrentSize + this.ConnectionRateThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.ConnectionRateThreshold = this.ConnectionRateThreshold.get(100)
    } else {
      fbeValue.ConnectionRateThreshold = 100
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConnectionRateThreshold.fbeSize

    if ((fbeCurrentSize + this.FailedLoginRateThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.FailedLoginRateThreshold = this.FailedLoginRateThreshold.get(10)
    } else {
      fbeValue.FailedLoginRateThreshold = 10
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FailedLoginRateThreshold.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {!SecuritySettingsCreateRequest} fbeValue SecuritySettingsCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSecuritySettingsCreateRequest}
   * @param {SecuritySettingsCreateRequest} fbeValue SecuritySettingsCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.Name.set(fbeValue.Name)
    this.Description.set(fbeValue.Description)
    this.IsFirewallEnabled.set(fbeValue.IsFirewallEnabled)
    this.IsAntifloodEnabled.set(fbeValue.IsAntifloodEnabled)
    this.ConnectionRateThreshold.set(fbeValue.ConnectionRateThreshold)
    this.FailedLoginRateThreshold.set(fbeValue.FailedLoginRateThreshold)
  }
}

export { FieldModelSecuritySettingsCreateRequest };

/**
 * Fast Binary Encoding SecuritySettingsCreateRequest model
 */
class SecuritySettingsCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSecuritySettingsCreateRequest(this.buffer, 4)
  }

  /**
   * Get the SecuritySettingsCreateRequest model
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!FieldModelSecuritySettingsCreateRequest} model SecuritySettingsCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SecuritySettingsCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSecuritySettingsCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SecuritySettingsCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SecuritySettingsCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SecuritySettingsCreateRequestModel}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SecuritySettingsCreateRequestModel}
   * @param {!SecuritySettingsCreateRequest} value SecuritySettingsCreateRequest value, defaults is new SecuritySettingsCreateRequest()
   * @return {!object} Deserialized SecuritySettingsCreateRequest value and its size
   */
  deserialize (value = new SecuritySettingsCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SecuritySettingsCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SecuritySettingsCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SecuritySettingsCreateRequestModel}
   * @param {!number} prev Previous SecuritySettingsCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SecuritySettingsCreateRequestModel };
SecuritySettingsCreateRequest.__has_response = true;
SecuritySettingsCreateRequest.__response_class = SecuritySettingsCreateResponse;
