
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TradingRouteRequestType enum
 */
class TradingRouteRequestType {
  /**
   * Initialize enum with a given value
   * @param {TradingRouteRequestType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof TradingRouteRequestType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!TradingRouteRequestType}
   * @param {!TradingRouteRequestType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingRouteRequestType)) {
      throw new TypeError('Instance of TradingRouteRequestType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!TradingRouteRequestType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!TradingRouteRequestType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === TradingRouteRequestType.OPENING_MARKET_ORDER.value) {
      return 'OPENING_MARKET_ORDER'
    }
    if (this.value === TradingRouteRequestType.CLOSING_MARKET_ORDER.value) {
      return 'CLOSING_MARKET_ORDER'
    }
    if (this.value === TradingRouteRequestType.PLACING_PENDING_ORDER.value) {
      return 'PLACING_PENDING_ORDER'
    }
    if (this.value === TradingRouteRequestType.ACTIVATING_PENDING_ORDER.value) {
      return 'ACTIVATING_PENDING_ORDER'
    }
    if (this.value === TradingRouteRequestType.MODIFYING_PENDING_ORDER.value) {
      return 'MODIFYING_PENDING_ORDER'
    }
    if (this.value === TradingRouteRequestType.CANCELLING_PENDING_ORDER.value) {
      return 'CANCELLING_PENDING_ORDER'
    }
    if (this.value === TradingRouteRequestType.EXPIRING_PENDING_ORDER.value) {
      return 'EXPIRING_PENDING_ORDER'
    }
    if (this.value === TradingRouteRequestType.ACTIVATING_SL.value) {
      return 'ACTIVATING_SL'
    }
    if (this.value === TradingRouteRequestType.ACTIVATING_TP.value) {
      return 'ACTIVATING_TP'
    }
    if (this.value === TradingRouteRequestType.ACTIVATING_STOP_OUT.value) {
      return 'ACTIVATING_STOP_OUT'
    }
    if (this.value === TradingRouteRequestType.ALL.value) {
      return 'ALL'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!TradingRouteRequestType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!TradingRouteRequestType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!TradingRouteRequestType} Created enum
   */
  static fromObject (other) {
    return new TradingRouteRequestType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.OPENING_MARKET_ORDER = new TradingRouteRequestType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.CLOSING_MARKET_ORDER = new TradingRouteRequestType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.PLACING_PENDING_ORDER = new TradingRouteRequestType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.ACTIVATING_PENDING_ORDER = new TradingRouteRequestType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.MODIFYING_PENDING_ORDER = new TradingRouteRequestType(0 + 4)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.CANCELLING_PENDING_ORDER = new TradingRouteRequestType(0 + 5)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.EXPIRING_PENDING_ORDER = new TradingRouteRequestType(0 + 6)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.ACTIVATING_SL = new TradingRouteRequestType(0 + 7)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.ACTIVATING_TP = new TradingRouteRequestType(0 + 8)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.ACTIVATING_STOP_OUT = new TradingRouteRequestType(0 + 9)
// noinspection PointlessArithmeticExpressionJS
TradingRouteRequestType.ALL = new TradingRouteRequestType(10000 + 0)

export { TradingRouteRequestType };

/**
 * Fast Binary Encoding TradingRouteRequestType field model
 */
class FieldModelTradingRouteRequestType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelTradingRouteRequestType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelTradingRouteRequestType}
   * @param {TradingRouteRequestType=} defaults Default value, defaults is new TradingRouteRequestType()
   * @returns {!TradingRouteRequestType} Result value
   */
  get (defaults = new TradingRouteRequestType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new TradingRouteRequestType(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelTradingRouteRequestType}
   * @param {!TradingRouteRequestType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelTradingRouteRequestType };
