import { FirewallRule } from '@api/fbe/core/FirewallRule';
import { Message, MessageType } from '@components/Message/Message';

export type FirewallRuleModel = {
    id: string;
    Name: string;
    Description: string;
    SortOrder: number;
    ActionType: number;
    IPv4Range: IPv4RangeModel;
};

export type IPv4RangeModel = {
    FromInclusive: string;
    ToInclusive: string;
};

export const convertFirewallRuleMToModel = (record: FirewallRule): FirewallRuleModel => ({
    id: record.id.toString(),
    Name: record.Name,
    Description: record.Description,
    SortOrder: record.SortOrder,
    ActionType: record.ActionType.valueOf(),
    IPv4Range: {
        FromInclusive: record.IPv4Range.FromInclusive.join('.'),
        ToInclusive: record.IPv4Range.ToInclusive.join('.'),
    },
});

export const convertStringIPtoNumArray = (ip: string): number[] => {
    const ipArray = ip.split('.');
    ipArray.forEach((item) => {
        const numValue = +item;
        if (!Number.isFinite(numValue) || numValue < 0 || numValue > 255 || !Number.isInteger(numValue)) {
            throw new Error(`Invalid IP address ${ip}`);
        }
    });
    return ipArray.map((item) => +item);
};
