import { DEFAULT_MAX_PRECISION } from '@constants/presets';
import { Symbol as SymbolApi } from '@api/fbe/core/Symbol';
import { adaptApi } from '@utils/models';

export type ConvertedSymbol = {
    id: string;
    SortOrder: number;
    SymbolGroupId: string;
    Name: string;
    Comment: string;
    Description: string;
    Alias: string;
    Source: string;
    Precision: number;
    MarginCurrencyId?: string | null;
    MarginCurrencyPrecision: number | null;
    MarginCalculationType: number;
    ProfitCurrencyId?: string | null;
    ProfitCurrencyPrecision: number | null;
    ProfitCalculationType: number;
    Color?: number | null;
    IsTradeAllowed: boolean | null;
    ContractSize: number;
    MinTradeAmount: number;
    MaxTradeAmount: number;
    TradeAmountStep: number;
    DefaultExpiration?: number | null;

    IsSwapEnabled: boolean;
    SwapType: number | null;
    SwapLong: number | null;
    SwapShort: number | null;
    SwapTripleDay: number | null;
    SwapDaysInYear: number | null;

    MarketOrderMarginRate: number;
    LimitOrderMarginRate: number;
    StopOrderMarginRate: number;
    TradingMode?: number | null;
    Index: number;
    StopsLevel?: number | null;
    IsFavorite: boolean;
    SortOrderFavorite?: number | null;
    Postfix?: string | null;
    InitialMargin: number;
    HedgedMargin: number;
};

export type SymbolsGroup = {
    id: string;
    SortOrder: number | null;
    Name: string;
    Comment: string;
    Description: string;
    Color: string;
    IsTradeAllowed: boolean;
    DefaultExpiration: number | null;
    MarginRateCalculationType: number | null;
    DefaultTradingMode: number | null;
    Symbols: string[];
    ClientName: string | null;
};

export const getSymbolPrecisionById = (items: ConvertedSymbol[], symbolId?: string): number => {
    if (!items.length || !symbolId) return DEFAULT_MAX_PRECISION;
    const targetCurrency = items.find((item) => item.id === symbolId);
    return targetCurrency ? targetCurrency.Precision ?? DEFAULT_MAX_PRECISION : DEFAULT_MAX_PRECISION;
};

export const getSymbolPrecisionByName = (items: ConvertedSymbol[] | null, symbolName?: string): number => {
    if (!items || !items.length || !symbolName) return DEFAULT_MAX_PRECISION;
    const targetCurrency = items.find((item) => item.Name === symbolName);
    return targetCurrency ? targetCurrency.Precision ?? DEFAULT_MAX_PRECISION : DEFAULT_MAX_PRECISION;
};

export const convertSymbolToSymbolModel = (symbol: SymbolApi): ConvertedSymbol => ({
    ...adaptApi(symbol),
    MarginCalculationType: symbol.MarginCalculationType.valueOf(),
    ProfitCalculationType: symbol.ProfitCalculationType.valueOf(),

    IsSwapEnabled: symbol.IsSwapEnabled,
    SwapType: symbol.SwapSettings ? symbol.SwapSettings.Type.valueOf() : null,
    SwapLong: symbol.SwapSettings ? symbol.SwapSettings.Long : null,
    SwapShort: symbol.SwapSettings ? symbol.SwapSettings.Short : null,
    SwapTripleDay: symbol.SwapSettings ? symbol.SwapSettings.TripleDay.valueOf() : null,
    SwapDaysInYear: symbol.SwapSettings ? +symbol.SwapSettings.DaysInYear.toNumber() : null,
    TradingMode: symbol.TradingMode?.valueOf(),
    StopsLevel: Number(symbol.StopsLevel?.toString()),
    ProfitCurrencyPrecision: symbol.ProfitCurrencyPrecision ?? null,
    MarginCurrencyPrecision: symbol.MarginCurrencyPrecision ?? null,
    IsTradeAllowed: symbol.IsTradeAllowed ?? null,
});
