import { makeAutoObservable } from 'mobx';
import { ErrorsTracker } from '@api/errorsTracker';
import { SocketClient } from '@api/SocketClient';
import { NewSymbolMap, SymbolMap, SymbolMapsFormData } from '@models/SymbolMaps';
import { adaptApi } from '@utils/models';
import { UUID } from '@api/fbe/uuid';
import { SymbolMapsGetRequest } from '@api/fbe/manager/SymbolMapsGetRequest';
import { SymbolMapCreateRequest } from '@api/fbe/manager/SymbolMapCreateRequest';
import { SymbolMapModifyRequest } from '@api/fbe/manager/SymbolMapModifyRequest';
import { SymbolMapDeleteRequest } from '@api/fbe/manager/SymbolMapDeleteRequest';

export class SymbolMaps {
    errorTracker = new ErrorsTracker({ title: 'SymbolMaps' });

    constructor() {
        makeAutoObservable(this);
    }

    data: SymbolMap[] = [];

    isLoading = false;

    setData(value: SymbolMap[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new SymbolMapsGetRequest();

        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request);
        this.setData(data.SymbolMaps.map((sm) => adaptApi(sm)));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(newMap: NewSymbolMap) {
        const request = new SymbolMapCreateRequest();
        request.Description = newMap.Description;
        request.Symbols = newMap.Symbols;
        request.Name = newMap.Name;

        await SocketClient.instance.request(request);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(newMap: SymbolMapsFormData) {
        const request = new SymbolMapModifyRequest();
        request.SymbolMapId = UUID.fromString(newMap.id);
        request.NewName = newMap.Name;
        request.SymbolsToModify = newMap.SymbolsToModify || new Map();
        request.SymbolsToDelete = newMap.SymbolsToDelete || new Set();
        request.NewDescription = newMap.Description || null;

        await SocketClient.instance.request(request);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: UUID) {
        const request = new SymbolMapDeleteRequest();
        request.SymbolMapId = id;

        await SocketClient.instance.request(request);
        await this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const symbolMapsStore = new SymbolMaps();
