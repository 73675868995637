import React, { useCallback, useContext, useState } from 'react';
import { Button, Input, InputNumber } from 'antd';
import { FilterPropsRangeNum } from './types';
import styles from './RowFilters.module.scss';
import { FilterValuesContext } from '../TableExt';
import { IRangeInputN } from '../types';
import { typedMemo } from '../../../utils';
import { numberInputFormatter } from '@App/pages/utils/commonUtils';

export interface IComparePare {
    from: number | null;
    to: number | null;
}

export const RowFilterRangeNum = typedMemo((props: FilterPropsRangeNum) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IRangeInputN>(
        filterValues.values[props.dataIndex]
            ? (filterValues.values[props.dataIndex] as IRangeInputN)
            : { from: null, to: null },
    );

    const onFilter = useCallback(() => {
        if (value.from === null && value.to === null) {
            filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        } else {
            filterValues.setValues({
                ...filterValues.values,
                [props.dataIndex]: {
                    from: value.from,
                    to: value.to,
                },
            });
        }
        props.confirmCallback();
    }, [filterValues, props.dataIndex, value.from, value.to]);

    const onChangeHandler = (val: number | null, type: 'from' | 'to') => {
        setValue({ ...value, [type]: val });
    };

    const onFromInput = (val: number | null) => {
        onChangeHandler(val, 'from');
    };

    const onToInput = (val: number | null) => {
        onChangeHandler(val, 'to');
    };

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: null, to: null });
        props.confirmCallback();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles['site-input-group-wrapper']}>
                <Input.Group compact>
                    <InputNumber
                        decimalSeparator=','
                        onChange={onFromInput}
                        formatter={numberInputFormatter}
                        value={value.from ?? undefined}
                        className={styles['site-input-left']}
                        placeholder='From'
                        onPressEnter={onFilter}
                        controls={false}
                    />
                    <Input className={styles['site-input-split']} placeholder='→' disabled />
                    <InputNumber
                        decimalSeparator=','
                        onChange={onToInput}
                        formatter={numberInputFormatter}
                        value={value.to ?? undefined}
                        className={styles['site-input-right']}
                        placeholder='To'
                        onPressEnter={onFilter}
                        controls={false}
                    />
                </Input.Group>
            </div>
            <div className={styles.btnBlock}>
                <Button type='link' className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type='primary' onClick={onFilter} size='small' className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
