
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AccountSorting} from '../core/AccountSorting';
import {FieldModelAccountSorting} from '../core/AccountSorting';
import {AccountMarginSorting} from '../core/AccountMarginSorting';
import {FieldModelAccountMarginSorting} from '../core/AccountMarginSorting';
import {AccountGroupSorting} from '../core/AccountGroupSorting';
import {FieldModelAccountGroupSorting} from '../core/AccountGroupSorting';
import {PlatformSorting} from '../core/PlatformSorting';
import {FieldModelPlatformSorting} from '../core/PlatformSorting';
import {CurrencySorting} from '../core/CurrencySorting';
import {FieldModelCurrencySorting} from '../core/CurrencySorting';
import {FeaturesToggleSorting} from '../core/FeaturesToggleSorting';
import {FieldModelFeaturesToggleSorting} from '../core/FeaturesToggleSorting';

/**
 * AccountsSorting struct
 */
class AccountsSorting {
  /**
   * Initialize struct
   * @param {AccountSorting=} accountSorting
   * @param {AccountMarginSorting=} accountMarginSorting
   * @param {AccountGroupSorting=} accountGroupSorting
   * @param {PlatformSorting=} platformSorting
   * @param {CurrencySorting=} marginCurrencySorting
   * @param {FeaturesToggleSorting=} featuresToggleSorting
   * @constructor
   */
  constructor (accountSorting = null, accountMarginSorting = null, accountGroupSorting = null, platformSorting = null, marginCurrencySorting = null, featuresToggleSorting = null) {
    this.AccountSorting = accountSorting
    this.AccountMarginSorting = accountMarginSorting
    this.AccountGroupSorting = accountGroupSorting
    this.PlatformSorting = platformSorting
    this.MarginCurrencySorting = marginCurrencySorting
    this.FeaturesToggleSorting = featuresToggleSorting
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountsSorting}
   * @param {!AccountsSorting} other Other struct
   * @returns {!AccountsSorting} This struct
   */
  copy (other) {
    if (other.AccountSorting != null) {
      this.AccountSorting = AccountSorting.fromObject(other.AccountSorting)
    } else {
      this.AccountSorting = null
    }
    if (other.AccountMarginSorting != null) {
      this.AccountMarginSorting = AccountMarginSorting.fromObject(other.AccountMarginSorting)
    } else {
      this.AccountMarginSorting = null
    }
    if (other.AccountGroupSorting != null) {
      this.AccountGroupSorting = AccountGroupSorting.fromObject(other.AccountGroupSorting)
    } else {
      this.AccountGroupSorting = null
    }
    if (other.PlatformSorting != null) {
      this.PlatformSorting = PlatformSorting.fromObject(other.PlatformSorting)
    } else {
      this.PlatformSorting = null
    }
    if (other.MarginCurrencySorting != null) {
      this.MarginCurrencySorting = CurrencySorting.fromObject(other.MarginCurrencySorting)
    } else {
      this.MarginCurrencySorting = null
    }
    if (other.FeaturesToggleSorting != null) {
      this.FeaturesToggleSorting = FeaturesToggleSorting.fromObject(other.FeaturesToggleSorting)
    } else {
      this.FeaturesToggleSorting = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountsSorting}
   * @returns {!AccountsSorting} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountsSortingModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountsSortingModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountsSorting}
   * @param {!AccountsSorting} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountsSorting)) {
      throw new TypeError('Instance of AccountsSorting is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountsSorting}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      AccountSorting: ((this.AccountSorting != null) ? this.AccountSorting : null),
      AccountMarginSorting: ((this.AccountMarginSorting != null) ? this.AccountMarginSorting : null),
      AccountGroupSorting: ((this.AccountGroupSorting != null) ? this.AccountGroupSorting : null),
      PlatformSorting: ((this.PlatformSorting != null) ? this.PlatformSorting : null),
      MarginCurrencySorting: ((this.MarginCurrencySorting != null) ? this.MarginCurrencySorting : null),
      FeaturesToggleSorting: ((this.FeaturesToggleSorting != null) ? this.FeaturesToggleSorting : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountsSorting.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountsSorting} other Object value
   * @returns {!AccountsSorting} Created struct
   */
  static fromObject (other) {
    return new AccountsSorting().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountsSorting}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountsSorting.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountsSorting}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 171
  }
}

export { AccountsSorting };

/**
 * Fast Binary Encoding AccountsSorting field model
 */
class FieldModelAccountsSorting extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.AccountSorting = new fbe.FieldModelOptional(new FieldModelAccountSorting(buffer, 4 + 4), buffer, 4 + 4)
    this.AccountMarginSorting = new fbe.FieldModelOptional(new FieldModelAccountMarginSorting(buffer, this.AccountSorting.fbeOffset + this.AccountSorting.fbeSize), buffer, this.AccountSorting.fbeOffset + this.AccountSorting.fbeSize)
    this.AccountGroupSorting = new fbe.FieldModelOptional(new FieldModelAccountGroupSorting(buffer, this.AccountMarginSorting.fbeOffset + this.AccountMarginSorting.fbeSize), buffer, this.AccountMarginSorting.fbeOffset + this.AccountMarginSorting.fbeSize)
    this.PlatformSorting = new fbe.FieldModelOptional(new FieldModelPlatformSorting(buffer, this.AccountGroupSorting.fbeOffset + this.AccountGroupSorting.fbeSize), buffer, this.AccountGroupSorting.fbeOffset + this.AccountGroupSorting.fbeSize)
    this.MarginCurrencySorting = new fbe.FieldModelOptional(new FieldModelCurrencySorting(buffer, this.PlatformSorting.fbeOffset + this.PlatformSorting.fbeSize), buffer, this.PlatformSorting.fbeOffset + this.PlatformSorting.fbeSize)
    this.FeaturesToggleSorting = new fbe.FieldModelOptional(new FieldModelFeaturesToggleSorting(buffer, this.MarginCurrencySorting.fbeOffset + this.MarginCurrencySorting.fbeSize), buffer, this.MarginCurrencySorting.fbeOffset + this.MarginCurrencySorting.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.AccountSorting.fbeSize + this.AccountMarginSorting.fbeSize + this.AccountGroupSorting.fbeSize + this.PlatformSorting.fbeSize + this.MarginCurrencySorting.fbeSize + this.FeaturesToggleSorting.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.AccountSorting.fbeExtra + this.AccountMarginSorting.fbeExtra + this.AccountGroupSorting.fbeExtra + this.PlatformSorting.fbeExtra + this.MarginCurrencySorting.fbeExtra + this.FeaturesToggleSorting.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountsSorting.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 171
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountsSorting}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountsSorting}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountSorting.fbeSize

    if ((fbeCurrentSize + this.AccountMarginSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginSorting.fbeSize

    if ((fbeCurrentSize + this.AccountGroupSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupSorting.fbeSize

    if ((fbeCurrentSize + this.PlatformSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformSorting.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencySorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginCurrencySorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencySorting.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleSorting.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggleSorting.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleSorting.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountsSorting}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountsSorting}
   * @param {!AccountsSorting} fbeValue Default value, defaults is new AccountsSorting()
   * @returns {!AccountsSorting} AccountsSorting value
   */
  get (fbeValue = new AccountsSorting()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountsSorting}
   * @param {!AccountsSorting} fbeValue AccountsSorting value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.AccountSorting.fbeSize) <= fbeStructSize) {
      fbeValue.AccountSorting = this.AccountSorting.get()
    } else {
      fbeValue.AccountSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountSorting.fbeSize

    if ((fbeCurrentSize + this.AccountMarginSorting.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginSorting = this.AccountMarginSorting.get()
    } else {
      fbeValue.AccountMarginSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginSorting.fbeSize

    if ((fbeCurrentSize + this.AccountGroupSorting.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupSorting = this.AccountGroupSorting.get()
    } else {
      fbeValue.AccountGroupSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupSorting.fbeSize

    if ((fbeCurrentSize + this.PlatformSorting.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformSorting = this.PlatformSorting.get()
    } else {
      fbeValue.PlatformSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformSorting.fbeSize

    if ((fbeCurrentSize + this.MarginCurrencySorting.fbeSize) <= fbeStructSize) {
      fbeValue.MarginCurrencySorting = this.MarginCurrencySorting.get()
    } else {
      fbeValue.MarginCurrencySorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginCurrencySorting.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggleSorting.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggleSorting = this.FeaturesToggleSorting.get()
    } else {
      fbeValue.FeaturesToggleSorting = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggleSorting.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountsSorting}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountsSorting}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountsSorting}
   * @param {!AccountsSorting} fbeValue AccountsSorting value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountsSorting}
   * @param {AccountsSorting} fbeValue AccountsSorting value
   */
  setFields (fbeValue) {
    this.AccountSorting.set(fbeValue.AccountSorting)
    this.AccountMarginSorting.set(fbeValue.AccountMarginSorting)
    this.AccountGroupSorting.set(fbeValue.AccountGroupSorting)
    this.PlatformSorting.set(fbeValue.PlatformSorting)
    this.MarginCurrencySorting.set(fbeValue.MarginCurrencySorting)
    this.FeaturesToggleSorting.set(fbeValue.FeaturesToggleSorting)
  }
}

export { FieldModelAccountsSorting };

/**
 * Fast Binary Encoding AccountsSorting model
 */
class AccountsSortingModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountsSorting(this.buffer, 4)
  }

  /**
   * Get the AccountsSorting model
   * @this {!AccountsSortingModel}
   * @returns {!FieldModelAccountsSorting} model AccountsSorting model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountsSortingModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountsSortingModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountsSortingModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountsSortingModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountsSorting.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountsSortingModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountsSortingModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountsSortingModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountsSortingModel}
   * @param {!AccountsSorting} value AccountsSorting value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountsSortingModel}
   * @param {!AccountsSorting} value AccountsSorting value, defaults is new AccountsSorting()
   * @return {!object} Deserialized AccountsSorting value and its size
   */
  deserialize (value = new AccountsSorting()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountsSorting(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountsSorting(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountsSortingModel}
   * @param {!number} prev Previous AccountsSorting model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountsSortingModel };
