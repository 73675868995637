import { useRef, useEffect, MutableRefObject } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';

type RangeValue =
    | Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0]
    | undefined;
export const useRangePickerAutoaccept = (
    elementRef: MutableRefObject<HTMLDivElement | null>,
    value: RangeValue,
    format: string,
    onChange?: (value: [Moment, Moment], format: [string, string]) => void,
) => {
    const valuesRef = useRef(value);
    valuesRef.current = value;
    const onChangeRef = useRef(onChange);

    useEffect(() => {
        let timeout;
        const isPlaceholderValue = (el: HTMLElement) => el.classList.contains('ant-picker-input-placeholder');
        const [startInput, endInput] = elementRef.current!.querySelectorAll(
            '.ant-picker-input input',
        ) as NodeListOf<HTMLInputElement>;

        const saveData = () => {
            const startValue = moment(startInput!.value, format as string);
            const endValue = moment(endInput!.value, format as string);
            const hasChanged = !startValue.isSame(valuesRef.current![0]) || !endValue.isSame(valuesRef.current![1]);
            const isValid = startValue.isValid() && endValue.isValid();
            if (hasChanged && isValid && onChangeRef.current) {
                onChangeRef.current([startValue, endValue], [format, format] as [string, string]);
            }
        };

        const onRangePickerChange = (mutationList) => {
            clearTimeout(timeout);

            mutationList.forEach((mutation) => {
                switch (mutation.type) {
                    case 'attributes':
                        if (
                            mutation.attributeName === 'class' &&
                            mutation.target.classList.contains('ant-picker-input')
                        ) {
                            if (!isPlaceholderValue(mutation.target)) {
                                timeout = setTimeout(() => saveData(), 200);
                            }
                        } else if (
                            mutation.target.tagName === 'INPUT' &&
                            !isPlaceholderValue(mutation.target.parentNode)
                        ) {
                            timeout = setTimeout(() => saveData(), 200);
                        }
                        break;
                    default:
                        break;
                }
            });
        };

        const observerOptions = {
            attributes: true,
            subtree: true,
        };
        const observer = new MutationObserver(onRangePickerChange);
        observer.observe(elementRef.current!, observerOptions);

        return () => {
            observer.disconnect();
        };
    }, [format]);
};
