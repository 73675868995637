
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * LogLevelType enum
 */
class LogLevelType {
  /**
   * Initialize enum with a given value
   * @param {LogLevelType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof LogLevelType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!LogLevelType}
   * @param {!LogLevelType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LogLevelType)) {
      throw new TypeError('Instance of LogLevelType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!LogLevelType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!LogLevelType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === LogLevelType.NONE.value) {
      return 'NONE'
    }
    if (this.value === LogLevelType.FATAL.value) {
      return 'FATAL'
    }
    if (this.value === LogLevelType.ERROR.value) {
      return 'ERROR'
    }
    if (this.value === LogLevelType.WARN.value) {
      return 'WARN'
    }
    if (this.value === LogLevelType.INFO.value) {
      return 'INFO'
    }
    if (this.value === LogLevelType.DEBUG.value) {
      return 'DEBUG'
    }
    if (this.value === LogLevelType.TRACE.value) {
      return 'TRACE'
    }
    if (this.value === LogLevelType.ALL.value) {
      return 'ALL'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!LogLevelType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!LogLevelType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!LogLevelType} Created enum
   */
  static fromObject (other) {
    return new LogLevelType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
LogLevelType.NONE = new LogLevelType(0x00 + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.FATAL = new LogLevelType(0x1F + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.ERROR = new LogLevelType(0x3F + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.WARN = new LogLevelType(0x7F + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.INFO = new LogLevelType(0x9F + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.DEBUG = new LogLevelType(0xBF + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.TRACE = new LogLevelType(0xDF + 0)
// noinspection PointlessArithmeticExpressionJS
LogLevelType.ALL = new LogLevelType(0xFF + 0)

export { LogLevelType };

/**
 * Fast Binary Encoding LogLevelType field model
 */
class FieldModelLogLevelType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelLogLevelType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the value
   * @this {!FieldModelLogLevelType}
   * @param {LogLevelType=} defaults Default value, defaults is new LogLevelType()
   * @returns {!LogLevelType} Result value
   */
  get (defaults = new LogLevelType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new LogLevelType(this.readInt32(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelLogLevelType}
   * @param {!LogLevelType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeInt32(this.fbeOffset, value.value)
  }
}

export { FieldModelLogLevelType };
