import { ErrorsTracker } from '@api/errorsTracker';
import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { ParsedCommissionProfile } from '@models/Commission';
import { UUID } from '@api/fbe/uuid';
import { fromApiFormat } from './utils';
import { CommissionCalculationType } from '@api/fbe/core/CommissionCalculationType';
import { CommissionChargingType } from '@api/fbe/core/CommissionChargingType';
import { CommissionMode } from '@api/fbe/core/CommissionMode';
import { CommissionValue } from '@api/fbe/core/CommissionValue';
import { CommissionProfilesGetAllRequest } from '@api/fbe/manager/CommissionProfilesGetAllRequest';
import { CommissionProfileCreateRequest } from '@api/fbe/manager/CommissionProfileCreateRequest';
import { CommissionProfileModifyRequest } from '@api/fbe/manager/CommissionProfileModifyRequest';
import { CommissionProfileDeleteRequest } from '@api/fbe/manager/CommissionProfileDeleteRequest';
import { CommissionType } from '@api/fbe/core/CommissionType';

class CommissionStore {
    errorTracker = new ErrorsTracker({ title: 'Commissions' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ParsedCommissionProfile[] = [];

    isLoading = false;

    setData(value: ParsedCommissionProfile[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new CommissionProfilesGetAllRequest();

        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request);
        this.setData(data.CommissionProfiles.map((profile) => fromApiFormat(profile)));
        this.setIsLoading(false);
    }

    @ErrorsTracker.wrapApi()
    async create(commission: ParsedCommissionProfile) {
        const request = new CommissionProfileCreateRequest();
        request.Name = commission.Name;
        request.Description = commission.Description;
        request.Comment = commission.Comment;
        request.Mode = new CommissionMode(commission.Mode!);
        request.CommissionType = new CommissionType(commission.CommissionType!);
        request.CalculationType = new CommissionCalculationType(0);
        request.ChargingType = new CommissionChargingType(commission.ChargingType!);
        request.Values = commission.Values.map((value) => CommissionValue.fromObject(value));

        await SocketClient.instance.request(request);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(commission: ParsedCommissionProfile) {
        const request = new CommissionProfileModifyRequest();
        request.CommissionProfileId = UUID.fromString(commission.id!);
        request.NewName = commission.Name;
        request.NewDescription = commission.Description;
        request.NewComment = commission.Comment;
        request.NewMode = new CommissionMode(commission.Mode!);
        request.NewCommissionType = new CommissionType(commission.CommissionType!);
        request.NewCalculationType = new CommissionCalculationType(0);
        request.NewChargingType = new CommissionChargingType(commission.ChargingType!);
        request.NewValues = commission.Values.map((value) => CommissionValue.fromObject(value));

        await SocketClient.instance.request(request);
        this.get();
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string | UUID) {
        const request = new CommissionProfileDeleteRequest(undefined, new UUID(id));

        await SocketClient.instance.request(request);
        this.get();
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

export const commissionStore = new CommissionStore();
