// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: config.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateType} from './config/ConfigUpdateType'
import {ConfigChangeType} from './config/ConfigChangeType'
import {ConfigRequest} from './config/ConfigRequest'
import {ConfigRequestModel} from './config/ConfigRequest'
import {ConfigResponse} from './config/ConfigResponse'
import {ConfigResponseModel} from './config/ConfigResponse'
import {ConfigReject} from './config/ConfigReject'
import {ConfigRejectModel} from './config/ConfigReject'
import {ConfigVersionRequest} from './config/ConfigVersionRequest'
import {ConfigVersionRequestModel} from './config/ConfigVersionRequest'
import {ConfigVersionResponse} from './config/ConfigVersionResponse'
import {ConfigVersionResponseModel} from './config/ConfigVersionResponse'
import {ConfigValue} from './config/ConfigValue'
import {ConfigValueModel} from './config/ConfigValue'
import {ConfigSnapshotRequest} from './config/ConfigSnapshotRequest'
import {ConfigSnapshotRequestModel} from './config/ConfigSnapshotRequest'
import {ConfigSnapshotResponse} from './config/ConfigSnapshotResponse'
import {ConfigSnapshotResponseModel} from './config/ConfigSnapshotResponse'
import {ConfigUpdate} from './config/ConfigUpdate'
import {ConfigUpdateModel} from './config/ConfigUpdate'
import {ConfigChange} from './config/ConfigChange'
import {ConfigChangeModel} from './config/ConfigChange'
import {ConfigUpdateRequest} from './config/ConfigUpdateRequest'
import {ConfigUpdateRequestModel} from './config/ConfigUpdateRequest'
import {ConfigUpdateResponse} from './config/ConfigUpdateResponse'
import {ConfigUpdateResponseModel} from './config/ConfigUpdateResponse'
import {ConfigUpdateReject} from './config/ConfigUpdateReject'
import {ConfigUpdateRejectModel} from './config/ConfigUpdateReject'
import {ConfigUpdateNotify} from './config/ConfigUpdateNotify'
import {ConfigUpdateNotifyModel} from './config/ConfigUpdateNotify'
import {ConfigRefreshRequest} from './config/ConfigRefreshRequest'
import {ConfigRefreshRequestModel} from './config/ConfigRefreshRequest'
import {ConfigRefreshResponse} from './config/ConfigRefreshResponse'
import {ConfigRefreshResponseModel} from './config/ConfigRefreshResponse'
import {ConfigHistory} from './config/ConfigHistory'
import {ConfigHistoryModel} from './config/ConfigHistory'
import {ConfigHistoryRequest} from './config/ConfigHistoryRequest'
import {ConfigHistoryRequestModel} from './config/ConfigHistoryRequest'
import {ConfigHistoryResponse} from './config/ConfigHistoryResponse'
import {ConfigHistoryResponseModel} from './config/ConfigHistoryResponse'
import {ConfigRollbackRequest} from './config/ConfigRollbackRequest'
import {ConfigRollbackRequestModel} from './config/ConfigRollbackRequest'
import {ConfigRollbackResponse} from './config/ConfigRollbackResponse'
import {ConfigRollbackResponseModel} from './config/ConfigRollbackResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding config sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize config sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._ConfigRequestModel = new ConfigRequestModel(this.buffer)
    this._ConfigResponseModel = new ConfigResponseModel(this.buffer)
    this._ConfigRejectModel = new ConfigRejectModel(this.buffer)
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel(this.buffer)
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel(this.buffer)
    this._ConfigSnapshotRequestModel = new ConfigSnapshotRequestModel(this.buffer)
    this._ConfigSnapshotResponseModel = new ConfigSnapshotResponseModel(this.buffer)
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel(this.buffer)
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel(this.buffer)
    this._ConfigUpdateRejectModel = new ConfigUpdateRejectModel(this.buffer)
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel(this.buffer)
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel(this.buffer)
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel(this.buffer)
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel(this.buffer)
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel(this.buffer)
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel(this.buffer)
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  // Sender models accessors

  /**
   * Get ConfigRequest model
   * @this {!Sender}
   * @returns {!ConfigRequestModel} ConfigRequest model
   */
  get ConfigRequestModel () {
    return this._ConfigRequestModel
  }

  /**
   * Get ConfigResponse model
   * @this {!Sender}
   * @returns {!ConfigResponseModel} ConfigResponse model
   */
  get ConfigResponseModel () {
    return this._ConfigResponseModel
  }

  /**
   * Get ConfigReject model
   * @this {!Sender}
   * @returns {!ConfigRejectModel} ConfigReject model
   */
  get ConfigRejectModel () {
    return this._ConfigRejectModel
  }

  /**
   * Get ConfigVersionRequest model
   * @this {!Sender}
   * @returns {!ConfigVersionRequestModel} ConfigVersionRequest model
   */
  get ConfigVersionRequestModel () {
    return this._ConfigVersionRequestModel
  }

  /**
   * Get ConfigVersionResponse model
   * @this {!Sender}
   * @returns {!ConfigVersionResponseModel} ConfigVersionResponse model
   */
  get ConfigVersionResponseModel () {
    return this._ConfigVersionResponseModel
  }

  /**
   * Get ConfigSnapshotRequest model
   * @this {!Sender}
   * @returns {!ConfigSnapshotRequestModel} ConfigSnapshotRequest model
   */
  get ConfigSnapshotRequestModel () {
    return this._ConfigSnapshotRequestModel
  }

  /**
   * Get ConfigSnapshotResponse model
   * @this {!Sender}
   * @returns {!ConfigSnapshotResponseModel} ConfigSnapshotResponse model
   */
  get ConfigSnapshotResponseModel () {
    return this._ConfigSnapshotResponseModel
  }

  /**
   * Get ConfigUpdateRequest model
   * @this {!Sender}
   * @returns {!ConfigUpdateRequestModel} ConfigUpdateRequest model
   */
  get ConfigUpdateRequestModel () {
    return this._ConfigUpdateRequestModel
  }

  /**
   * Get ConfigUpdateResponse model
   * @this {!Sender}
   * @returns {!ConfigUpdateResponseModel} ConfigUpdateResponse model
   */
  get ConfigUpdateResponseModel () {
    return this._ConfigUpdateResponseModel
  }

  /**
   * Get ConfigUpdateReject model
   * @this {!Sender}
   * @returns {!ConfigUpdateRejectModel} ConfigUpdateReject model
   */
  get ConfigUpdateRejectModel () {
    return this._ConfigUpdateRejectModel
  }

  /**
   * Get ConfigUpdateNotify model
   * @this {!Sender}
   * @returns {!ConfigUpdateNotifyModel} ConfigUpdateNotify model
   */
  get ConfigUpdateNotifyModel () {
    return this._ConfigUpdateNotifyModel
  }

  /**
   * Get ConfigRefreshRequest model
   * @this {!Sender}
   * @returns {!ConfigRefreshRequestModel} ConfigRefreshRequest model
   */
  get ConfigRefreshRequestModel () {
    return this._ConfigRefreshRequestModel
  }

  /**
   * Get ConfigRefreshResponse model
   * @this {!Sender}
   * @returns {!ConfigRefreshResponseModel} ConfigRefreshResponse model
   */
  get ConfigRefreshResponseModel () {
    return this._ConfigRefreshResponseModel
  }

  /**
   * Get ConfigHistoryRequest model
   * @this {!Sender}
   * @returns {!ConfigHistoryRequestModel} ConfigHistoryRequest model
   */
  get ConfigHistoryRequestModel () {
    return this._ConfigHistoryRequestModel
  }

  /**
   * Get ConfigHistoryResponse model
   * @this {!Sender}
   * @returns {!ConfigHistoryResponseModel} ConfigHistoryResponse model
   */
  get ConfigHistoryResponseModel () {
    return this._ConfigHistoryResponseModel
  }

  /**
   * Get ConfigRollbackRequest model
   * @this {!Sender}
   * @returns {!ConfigRollbackRequestModel} ConfigRollbackRequest model
   */
  get ConfigRollbackRequestModel () {
    return this._ConfigRollbackRequestModel
  }

  /**
   * Get ConfigRollbackResponse model
   * @this {!Sender}
   * @returns {!ConfigRollbackResponseModel} ConfigRollbackResponse model
   */
  get ConfigRollbackResponseModel () {
    return this._ConfigRollbackResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ConfigRequest) && (value.fbeType === this.ConfigRequestModel.fbeType)) {
      return this.send_ConfigRequest(value)
    }
    if ((value instanceof ConfigResponse) && (value.fbeType === this.ConfigResponseModel.fbeType)) {
      return this.send_ConfigResponse(value)
    }
    if ((value instanceof ConfigReject) && (value.fbeType === this.ConfigRejectModel.fbeType)) {
      return this.send_ConfigReject(value)
    }
    if ((value instanceof ConfigVersionRequest) && (value.fbeType === this.ConfigVersionRequestModel.fbeType)) {
      return this.send_ConfigVersionRequest(value)
    }
    if ((value instanceof ConfigVersionResponse) && (value.fbeType === this.ConfigVersionResponseModel.fbeType)) {
      return this.send_ConfigVersionResponse(value)
    }
    if ((value instanceof ConfigSnapshotRequest) && (value.fbeType === this.ConfigSnapshotRequestModel.fbeType)) {
      return this.send_ConfigSnapshotRequest(value)
    }
    if ((value instanceof ConfigSnapshotResponse) && (value.fbeType === this.ConfigSnapshotResponseModel.fbeType)) {
      return this.send_ConfigSnapshotResponse(value)
    }
    if ((value instanceof ConfigUpdateRequest) && (value.fbeType === this.ConfigUpdateRequestModel.fbeType)) {
      return this.send_ConfigUpdateRequest(value)
    }
    if ((value instanceof ConfigUpdateResponse) && (value.fbeType === this.ConfigUpdateResponseModel.fbeType)) {
      return this.send_ConfigUpdateResponse(value)
    }
    if ((value instanceof ConfigUpdateReject) && (value.fbeType === this.ConfigUpdateRejectModel.fbeType)) {
      return this.send_ConfigUpdateReject(value)
    }
    if ((value instanceof ConfigUpdateNotify) && (value.fbeType === this.ConfigUpdateNotifyModel.fbeType)) {
      return this.send_ConfigUpdateNotify(value)
    }
    if ((value instanceof ConfigRefreshRequest) && (value.fbeType === this.ConfigRefreshRequestModel.fbeType)) {
      return this.send_ConfigRefreshRequest(value)
    }
    if ((value instanceof ConfigRefreshResponse) && (value.fbeType === this.ConfigRefreshResponseModel.fbeType)) {
      return this.send_ConfigRefreshResponse(value)
    }
    if ((value instanceof ConfigHistoryRequest) && (value.fbeType === this.ConfigHistoryRequestModel.fbeType)) {
      return this.send_ConfigHistoryRequest(value)
    }
    if ((value instanceof ConfigHistoryResponse) && (value.fbeType === this.ConfigHistoryResponseModel.fbeType)) {
      return this.send_ConfigHistoryResponse(value)
    }
    if ((value instanceof ConfigRollbackRequest) && (value.fbeType === this.ConfigRollbackRequestModel.fbeType)) {
      return this.send_ConfigRollbackRequest(value)
    }
    if ((value instanceof ConfigRollbackResponse) && (value.fbeType === this.ConfigRollbackResponseModel.fbeType)) {
      return this.send_ConfigRollbackResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ConfigRequest value
   * @this {!Sender}
   * @param {!ConfigRequest} value ConfigRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRequest serialization failed!')
    console.assert(this.ConfigRequestModel.verify(), 'config.ConfigRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigResponse value
   * @this {!Sender}
   * @param {!ConfigResponse} value ConfigResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigResponse serialization failed!')
    console.assert(this.ConfigResponseModel.verify(), 'config.ConfigResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigReject value
   * @this {!Sender}
   * @param {!ConfigReject} value ConfigReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRejectModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigReject serialization failed!')
    console.assert(this.ConfigRejectModel.verify(), 'config.ConfigReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionRequest value
   * @this {!Sender}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigVersionRequest serialization failed!')
    console.assert(this.ConfigVersionRequestModel.verify(), 'config.ConfigVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionResponse value
   * @this {!Sender}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigVersionResponse serialization failed!')
    console.assert(this.ConfigVersionResponseModel.verify(), 'config.ConfigVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigSnapshotRequest value
   * @this {!Sender}
   * @param {!ConfigSnapshotRequest} value ConfigSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigSnapshotRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigSnapshotRequest serialization failed!')
    console.assert(this.ConfigSnapshotRequestModel.verify(), 'config.ConfigSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigSnapshotResponse value
   * @this {!Sender}
   * @param {!ConfigSnapshotResponse} value ConfigSnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigSnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigSnapshotResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigSnapshotResponse serialization failed!')
    console.assert(this.ConfigSnapshotResponseModel.verify(), 'config.ConfigSnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateRequest value
   * @this {!Sender}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateRequest serialization failed!')
    console.assert(this.ConfigUpdateRequestModel.verify(), 'config.ConfigUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateResponse value
   * @this {!Sender}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateResponse serialization failed!')
    console.assert(this.ConfigUpdateResponseModel.verify(), 'config.ConfigUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateReject value
   * @this {!Sender}
   * @param {!ConfigUpdateReject} value ConfigUpdateReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRejectModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateReject serialization failed!')
    console.assert(this.ConfigUpdateRejectModel.verify(), 'config.ConfigUpdateReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateNotify value
   * @this {!Sender}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateNotifyModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateNotify serialization failed!')
    console.assert(this.ConfigUpdateNotifyModel.verify(), 'config.ConfigUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshRequest value
   * @this {!Sender}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRefreshRequest serialization failed!')
    console.assert(this.ConfigRefreshRequestModel.verify(), 'config.ConfigRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshResponse value
   * @this {!Sender}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRefreshResponse serialization failed!')
    console.assert(this.ConfigRefreshResponseModel.verify(), 'config.ConfigRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryRequest value
   * @this {!Sender}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigHistoryRequest serialization failed!')
    console.assert(this.ConfigHistoryRequestModel.verify(), 'config.ConfigHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryResponse value
   * @this {!Sender}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigHistoryResponse serialization failed!')
    console.assert(this.ConfigHistoryResponseModel.verify(), 'config.ConfigHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackRequest value
   * @this {!Sender}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackRequestModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRollbackRequest serialization failed!')
    console.assert(this.ConfigRollbackRequestModel.verify(), 'config.ConfigRollbackRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackResponse value
   * @this {!Sender}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackResponseModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRollbackResponse serialization failed!')
    console.assert(this.ConfigRollbackResponseModel.verify(), 'config.ConfigRollbackResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'config.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding config receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize config receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._ConfigRequestValue = new ConfigRequest()
    this._ConfigRequestModel = new ConfigRequestModel()
    this._ConfigResponseValue = new ConfigResponse()
    this._ConfigResponseModel = new ConfigResponseModel()
    this._ConfigRejectValue = new ConfigReject()
    this._ConfigRejectModel = new ConfigRejectModel()
    this._ConfigVersionRequestValue = new ConfigVersionRequest()
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseValue = new ConfigVersionResponse()
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel()
    this._ConfigSnapshotRequestValue = new ConfigSnapshotRequest()
    this._ConfigSnapshotRequestModel = new ConfigSnapshotRequestModel()
    this._ConfigSnapshotResponseValue = new ConfigSnapshotResponse()
    this._ConfigSnapshotResponseModel = new ConfigSnapshotResponseModel()
    this._ConfigUpdateRequestValue = new ConfigUpdateRequest()
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseValue = new ConfigUpdateResponse()
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateRejectValue = new ConfigUpdateReject()
    this._ConfigUpdateRejectModel = new ConfigUpdateRejectModel()
    this._ConfigUpdateNotifyValue = new ConfigUpdateNotify()
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel()
    this._ConfigRefreshRequestValue = new ConfigRefreshRequest()
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseValue = new ConfigRefreshResponse()
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestValue = new ConfigHistoryRequest()
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseValue = new ConfigHistoryResponse()
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestValue = new ConfigRollbackRequest()
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseValue = new ConfigRollbackResponse()
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ConfigRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRequest} value ConfigRequest received value
   */
  onReceive_ConfigRequest (value) {}  // eslint-disable-line

  /**
   * ConfigResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigResponse} value ConfigResponse received value
   */
  onReceive_ConfigResponse (value) {}  // eslint-disable-line

  /**
   * ConfigReject receive handler
   * @this {!Receiver}
   * @param {!ConfigReject} value ConfigReject received value
   */
  onReceive_ConfigReject (value) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest received value
   */
  onReceive_ConfigVersionRequest (value) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse received value
   */
  onReceive_ConfigVersionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigSnapshotRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigSnapshotRequest} value ConfigSnapshotRequest received value
   */
  onReceive_ConfigSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * ConfigSnapshotResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigSnapshotResponse} value ConfigSnapshotResponse received value
   */
  onReceive_ConfigSnapshotResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest received value
   */
  onReceive_ConfigUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse received value
   */
  onReceive_ConfigUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateReject receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateReject} value ConfigUpdateReject received value
   */
  onReceive_ConfigUpdateReject (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify receive handler
   * @this {!Receiver}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify received value
   */
  onReceive_ConfigUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest received value
   */
  onReceive_ConfigRefreshRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse received value
   */
  onReceive_ConfigRefreshResponse (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest received value
   */
  onReceive_ConfigHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse received value
   */
  onReceive_ConfigHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest receive handler
   * @this {!Receiver}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest received value
   */
  onReceive_ConfigRollbackRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse receive handler
   * @this {!Receiver}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse received value
   */
  onReceive_ConfigRollbackResponse (value) {}  // eslint-disable-line

  /**
   * config receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ConfigRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestModel.verify(), 'config.ConfigRequest validation failed!')
        let deserialized = this._ConfigRequestModel.deserialize(this._ConfigRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRequestValue)
        this.onReceive_ConfigRequest(this._ConfigRequestValue)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseModel.verify(), 'config.ConfigResponse validation failed!')
        let deserialized = this._ConfigResponseModel.deserialize(this._ConfigResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigResponseValue)
        this.onReceive_ConfigResponse(this._ConfigResponseValue)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectModel.verify(), 'config.ConfigReject validation failed!')
        let deserialized = this._ConfigRejectModel.deserialize(this._ConfigRejectValue)
        console.assert((deserialized.size > 0), 'config.ConfigReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRejectValue)
        this.onReceive_ConfigReject(this._ConfigRejectValue)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestModel.verify(), 'config.ConfigVersionRequest validation failed!')
        let deserialized = this._ConfigVersionRequestModel.deserialize(this._ConfigVersionRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionRequestValue)
        this.onReceive_ConfigVersionRequest(this._ConfigVersionRequestValue)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseModel.verify(), 'config.ConfigVersionResponse validation failed!')
        let deserialized = this._ConfigVersionResponseModel.deserialize(this._ConfigVersionResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionResponseValue)
        this.onReceive_ConfigVersionResponse(this._ConfigVersionResponseValue)
        return true
      }
      case ConfigSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotRequestModel.verify(), 'config.ConfigSnapshotRequest validation failed!')
        let deserialized = this._ConfigSnapshotRequestModel.deserialize(this._ConfigSnapshotRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigSnapshotRequestValue)
        this.onReceive_ConfigSnapshotRequest(this._ConfigSnapshotRequestValue)
        return true
      }
      case ConfigSnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigSnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotResponseModel.verify(), 'config.ConfigSnapshotResponse validation failed!')
        let deserialized = this._ConfigSnapshotResponseModel.deserialize(this._ConfigSnapshotResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigSnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigSnapshotResponseValue)
        this.onReceive_ConfigSnapshotResponse(this._ConfigSnapshotResponseValue)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestModel.verify(), 'config.ConfigUpdateRequest validation failed!')
        let deserialized = this._ConfigUpdateRequestModel.deserialize(this._ConfigUpdateRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRequestValue)
        this.onReceive_ConfigUpdateRequest(this._ConfigUpdateRequestValue)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseModel.verify(), 'config.ConfigUpdateResponse validation failed!')
        let deserialized = this._ConfigUpdateResponseModel.deserialize(this._ConfigUpdateResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateResponseValue)
        this.onReceive_ConfigUpdateResponse(this._ConfigUpdateResponseValue)
        return true
      }
      case ConfigUpdateRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRejectModel.verify(), 'config.ConfigUpdateReject validation failed!')
        let deserialized = this._ConfigUpdateRejectModel.deserialize(this._ConfigUpdateRejectValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRejectValue)
        this.onReceive_ConfigUpdateReject(this._ConfigUpdateRejectValue)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyModel.verify(), 'config.ConfigUpdateNotify validation failed!')
        let deserialized = this._ConfigUpdateNotifyModel.deserialize(this._ConfigUpdateNotifyValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateNotifyValue)
        this.onReceive_ConfigUpdateNotify(this._ConfigUpdateNotifyValue)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestModel.verify(), 'config.ConfigRefreshRequest validation failed!')
        let deserialized = this._ConfigRefreshRequestModel.deserialize(this._ConfigRefreshRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshRequestValue)
        this.onReceive_ConfigRefreshRequest(this._ConfigRefreshRequestValue)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseModel.verify(), 'config.ConfigRefreshResponse validation failed!')
        let deserialized = this._ConfigRefreshResponseModel.deserialize(this._ConfigRefreshResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshResponseValue)
        this.onReceive_ConfigRefreshResponse(this._ConfigRefreshResponseValue)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestModel.verify(), 'config.ConfigHistoryRequest validation failed!')
        let deserialized = this._ConfigHistoryRequestModel.deserialize(this._ConfigHistoryRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryRequestValue)
        this.onReceive_ConfigHistoryRequest(this._ConfigHistoryRequestValue)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseModel.verify(), 'config.ConfigHistoryResponse validation failed!')
        let deserialized = this._ConfigHistoryResponseModel.deserialize(this._ConfigHistoryResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryResponseValue)
        this.onReceive_ConfigHistoryResponse(this._ConfigHistoryResponseValue)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestModel.verify(), 'config.ConfigRollbackRequest validation failed!')
        let deserialized = this._ConfigRollbackRequestModel.deserialize(this._ConfigRollbackRequestValue)
        console.assert((deserialized.size > 0), 'config.ConfigRollbackRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackRequestValue)
        this.onReceive_ConfigRollbackRequest(this._ConfigRollbackRequestValue)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseModel.verify(), 'config.ConfigRollbackResponse validation failed!')
        let deserialized = this._ConfigRollbackResponseModel.deserialize(this._ConfigRollbackResponseValue)
        console.assert((deserialized.size > 0), 'config.ConfigRollbackResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackResponseValue)
        this.onReceive_ConfigRollbackResponse(this._ConfigRollbackResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding config proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize config proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._ConfigRequestModel = new ConfigRequestModel()
    this._ConfigResponseModel = new ConfigResponseModel()
    this._ConfigRejectModel = new ConfigRejectModel()
    this._ConfigVersionRequestModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseModel = new ConfigVersionResponseModel()
    this._ConfigSnapshotRequestModel = new ConfigSnapshotRequestModel()
    this._ConfigSnapshotResponseModel = new ConfigSnapshotResponseModel()
    this._ConfigUpdateRequestModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateRejectModel = new ConfigUpdateRejectModel()
    this._ConfigUpdateNotifyModel = new ConfigUpdateNotifyModel()
    this._ConfigRefreshRequestModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseModel = new ConfigRollbackResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  // Proxy handlers

  /**
   * ConfigRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRequest} model ConfigRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigResponse} model ConfigResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigReject proxy handler
   * @this {!Proxy}
   * @param {!ConfigReject} model ConfigReject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigReject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigVersionRequest} model ConfigVersionRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigVersionRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigVersionResponse} model ConfigVersionResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigVersionResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigSnapshotRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigSnapshotRequest} model ConfigSnapshotRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigSnapshotRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigSnapshotResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigSnapshotResponse} model ConfigSnapshotResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigSnapshotResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateRequest} model ConfigUpdateRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateResponse} model ConfigUpdateResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateReject proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateReject} model ConfigUpdateReject model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateReject (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify proxy handler
   * @this {!Proxy}
   * @param {!ConfigUpdateNotify} model ConfigUpdateNotify model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigUpdateNotify (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRefreshRequest} model ConfigRefreshRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRefreshRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigRefreshResponse} model ConfigRefreshResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRefreshResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigHistoryRequest} model ConfigHistoryRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigHistoryRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigHistoryResponse} model ConfigHistoryResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigHistoryResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest proxy handler
   * @this {!Proxy}
   * @param {!ConfigRollbackRequest} model ConfigRollbackRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRollbackRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse proxy handler
   * @this {!Proxy}
   * @param {!ConfigRollbackResponse} model ConfigRollbackResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ConfigRollbackResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * config receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ConfigRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestModel.verify(), 'config.ConfigRequest validation failed!')

        let fbeBegin = this._ConfigRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRequest(this._ConfigRequestModel, type, buffer, offset, size)
        this._ConfigRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseModel.verify(), 'config.ConfigResponse validation failed!')

        let fbeBegin = this._ConfigResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigResponse(this._ConfigResponseModel, type, buffer, offset, size)
        this._ConfigResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectModel.verify(), 'config.ConfigReject validation failed!')

        let fbeBegin = this._ConfigRejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigReject(this._ConfigRejectModel, type, buffer, offset, size)
        this._ConfigRejectModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigVersionRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestModel.verify(), 'config.ConfigVersionRequest validation failed!')

        let fbeBegin = this._ConfigVersionRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigVersionRequest(this._ConfigVersionRequestModel, type, buffer, offset, size)
        this._ConfigVersionRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigVersionResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseModel.verify(), 'config.ConfigVersionResponse validation failed!')

        let fbeBegin = this._ConfigVersionResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigVersionResponse(this._ConfigVersionResponseModel, type, buffer, offset, size)
        this._ConfigVersionResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigSnapshotRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigSnapshotRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotRequestModel.verify(), 'config.ConfigSnapshotRequest validation failed!')

        let fbeBegin = this._ConfigSnapshotRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigSnapshotRequest(this._ConfigSnapshotRequestModel, type, buffer, offset, size)
        this._ConfigSnapshotRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigSnapshotResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigSnapshotResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotResponseModel.verify(), 'config.ConfigSnapshotResponse validation failed!')

        let fbeBegin = this._ConfigSnapshotResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigSnapshotResponse(this._ConfigSnapshotResponseModel, type, buffer, offset, size)
        this._ConfigSnapshotResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestModel.verify(), 'config.ConfigUpdateRequest validation failed!')

        let fbeBegin = this._ConfigUpdateRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateRequest(this._ConfigUpdateRequestModel, type, buffer, offset, size)
        this._ConfigUpdateRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseModel.verify(), 'config.ConfigUpdateResponse validation failed!')

        let fbeBegin = this._ConfigUpdateResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateResponse(this._ConfigUpdateResponseModel, type, buffer, offset, size)
        this._ConfigUpdateResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateRejectModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateRejectModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRejectModel.verify(), 'config.ConfigUpdateReject validation failed!')

        let fbeBegin = this._ConfigUpdateRejectModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateReject(this._ConfigUpdateRejectModel, type, buffer, offset, size)
        this._ConfigUpdateRejectModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigUpdateNotifyModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyModel.verify(), 'config.ConfigUpdateNotify validation failed!')

        let fbeBegin = this._ConfigUpdateNotifyModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigUpdateNotify(this._ConfigUpdateNotifyModel, type, buffer, offset, size)
        this._ConfigUpdateNotifyModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRefreshRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestModel.verify(), 'config.ConfigRefreshRequest validation failed!')

        let fbeBegin = this._ConfigRefreshRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRefreshRequest(this._ConfigRefreshRequestModel, type, buffer, offset, size)
        this._ConfigRefreshRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRefreshResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseModel.verify(), 'config.ConfigRefreshResponse validation failed!')

        let fbeBegin = this._ConfigRefreshResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRefreshResponse(this._ConfigRefreshResponseModel, type, buffer, offset, size)
        this._ConfigRefreshResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigHistoryRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestModel.verify(), 'config.ConfigHistoryRequest validation failed!')

        let fbeBegin = this._ConfigHistoryRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigHistoryRequest(this._ConfigHistoryRequestModel, type, buffer, offset, size)
        this._ConfigHistoryRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigHistoryResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseModel.verify(), 'config.ConfigHistoryResponse validation failed!')

        let fbeBegin = this._ConfigHistoryResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigHistoryResponse(this._ConfigHistoryResponseModel, type, buffer, offset, size)
        this._ConfigHistoryResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRollbackRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestModel.verify(), 'config.ConfigRollbackRequest validation failed!')

        let fbeBegin = this._ConfigRollbackRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRollbackRequest(this._ConfigRollbackRequestModel, type, buffer, offset, size)
        this._ConfigRollbackRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ConfigRollbackResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseModel.verify(), 'config.ConfigRollbackResponse validation failed!')

        let fbeBegin = this._ConfigRollbackResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ConfigRollbackResponse(this._ConfigRollbackResponseModel, type, buffer, offset, size)
        this._ConfigRollbackResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding config client
 */
class Client extends fbe.Client {
  /**
   * Initialize config client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._ConfigRequestSenderModel = new ConfigRequestModel(this.sendBuffer)
    this._ConfigRequestReceiverValue = new ConfigRequest()
    this._ConfigRequestReceiverModel = new ConfigRequestModel()
    this._ConfigResponseSenderModel = new ConfigResponseModel(this.sendBuffer)
    this._ConfigResponseReceiverValue = new ConfigResponse()
    this._ConfigResponseReceiverModel = new ConfigResponseModel()
    this._ConfigRejectSenderModel = new ConfigRejectModel(this.sendBuffer)
    this._ConfigRejectReceiverValue = new ConfigReject()
    this._ConfigRejectReceiverModel = new ConfigRejectModel()
    this._ConfigVersionRequestSenderModel = new ConfigVersionRequestModel(this.sendBuffer)
    this._ConfigVersionRequestReceiverValue = new ConfigVersionRequest()
    this._ConfigVersionRequestReceiverModel = new ConfigVersionRequestModel()
    this._ConfigVersionResponseSenderModel = new ConfigVersionResponseModel(this.sendBuffer)
    this._ConfigVersionResponseReceiverValue = new ConfigVersionResponse()
    this._ConfigVersionResponseReceiverModel = new ConfigVersionResponseModel()
    this._ConfigSnapshotRequestSenderModel = new ConfigSnapshotRequestModel(this.sendBuffer)
    this._ConfigSnapshotRequestReceiverValue = new ConfigSnapshotRequest()
    this._ConfigSnapshotRequestReceiverModel = new ConfigSnapshotRequestModel()
    this._ConfigSnapshotResponseSenderModel = new ConfigSnapshotResponseModel(this.sendBuffer)
    this._ConfigSnapshotResponseReceiverValue = new ConfigSnapshotResponse()
    this._ConfigSnapshotResponseReceiverModel = new ConfigSnapshotResponseModel()
    this._ConfigUpdateRequestSenderModel = new ConfigUpdateRequestModel(this.sendBuffer)
    this._ConfigUpdateRequestReceiverValue = new ConfigUpdateRequest()
    this._ConfigUpdateRequestReceiverModel = new ConfigUpdateRequestModel()
    this._ConfigUpdateResponseSenderModel = new ConfigUpdateResponseModel(this.sendBuffer)
    this._ConfigUpdateResponseReceiverValue = new ConfigUpdateResponse()
    this._ConfigUpdateResponseReceiverModel = new ConfigUpdateResponseModel()
    this._ConfigUpdateRejectSenderModel = new ConfigUpdateRejectModel(this.sendBuffer)
    this._ConfigUpdateRejectReceiverValue = new ConfigUpdateReject()
    this._ConfigUpdateRejectReceiverModel = new ConfigUpdateRejectModel()
    this._ConfigUpdateNotifySenderModel = new ConfigUpdateNotifyModel(this.sendBuffer)
    this._ConfigUpdateNotifyReceiverValue = new ConfigUpdateNotify()
    this._ConfigUpdateNotifyReceiverModel = new ConfigUpdateNotifyModel()
    this._ConfigRefreshRequestSenderModel = new ConfigRefreshRequestModel(this.sendBuffer)
    this._ConfigRefreshRequestReceiverValue = new ConfigRefreshRequest()
    this._ConfigRefreshRequestReceiverModel = new ConfigRefreshRequestModel()
    this._ConfigRefreshResponseSenderModel = new ConfigRefreshResponseModel(this.sendBuffer)
    this._ConfigRefreshResponseReceiverValue = new ConfigRefreshResponse()
    this._ConfigRefreshResponseReceiverModel = new ConfigRefreshResponseModel()
    this._ConfigHistoryRequestSenderModel = new ConfigHistoryRequestModel(this.sendBuffer)
    this._ConfigHistoryRequestReceiverValue = new ConfigHistoryRequest()
    this._ConfigHistoryRequestReceiverModel = new ConfigHistoryRequestModel()
    this._ConfigHistoryResponseSenderModel = new ConfigHistoryResponseModel(this.sendBuffer)
    this._ConfigHistoryResponseReceiverValue = new ConfigHistoryResponse()
    this._ConfigHistoryResponseReceiverModel = new ConfigHistoryResponseModel()
    this._ConfigRollbackRequestSenderModel = new ConfigRollbackRequestModel(this.sendBuffer)
    this._ConfigRollbackRequestReceiverValue = new ConfigRollbackRequest()
    this._ConfigRollbackRequestReceiverModel = new ConfigRollbackRequestModel()
    this._ConfigRollbackResponseSenderModel = new ConfigRollbackResponseModel(this.sendBuffer)
    this._ConfigRollbackResponseReceiverValue = new ConfigRollbackResponse()
    this._ConfigRollbackResponseReceiverModel = new ConfigRollbackResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  // Sender models accessors

  /**
   * Get ConfigRequest model
   * @this {!Client}
   * @returns {!ConfigRequestModel} ConfigRequest sender model
   */
  get ConfigRequestSenderModel () {
    return this._ConfigRequestSenderModel
  }

  /**
   * Get ConfigResponse model
   * @this {!Client}
   * @returns {!ConfigResponseModel} ConfigResponse sender model
   */
  get ConfigResponseSenderModel () {
    return this._ConfigResponseSenderModel
  }

  /**
   * Get ConfigReject model
   * @this {!Client}
   * @returns {!ConfigRejectModel} ConfigReject sender model
   */
  get ConfigRejectSenderModel () {
    return this._ConfigRejectSenderModel
  }

  /**
   * Get ConfigVersionRequest model
   * @this {!Client}
   * @returns {!ConfigVersionRequestModel} ConfigVersionRequest sender model
   */
  get ConfigVersionRequestSenderModel () {
    return this._ConfigVersionRequestSenderModel
  }

  /**
   * Get ConfigVersionResponse model
   * @this {!Client}
   * @returns {!ConfigVersionResponseModel} ConfigVersionResponse sender model
   */
  get ConfigVersionResponseSenderModel () {
    return this._ConfigVersionResponseSenderModel
  }

  /**
   * Get ConfigSnapshotRequest model
   * @this {!Client}
   * @returns {!ConfigSnapshotRequestModel} ConfigSnapshotRequest sender model
   */
  get ConfigSnapshotRequestSenderModel () {
    return this._ConfigSnapshotRequestSenderModel
  }

  /**
   * Get ConfigSnapshotResponse model
   * @this {!Client}
   * @returns {!ConfigSnapshotResponseModel} ConfigSnapshotResponse sender model
   */
  get ConfigSnapshotResponseSenderModel () {
    return this._ConfigSnapshotResponseSenderModel
  }

  /**
   * Get ConfigUpdateRequest model
   * @this {!Client}
   * @returns {!ConfigUpdateRequestModel} ConfigUpdateRequest sender model
   */
  get ConfigUpdateRequestSenderModel () {
    return this._ConfigUpdateRequestSenderModel
  }

  /**
   * Get ConfigUpdateResponse model
   * @this {!Client}
   * @returns {!ConfigUpdateResponseModel} ConfigUpdateResponse sender model
   */
  get ConfigUpdateResponseSenderModel () {
    return this._ConfigUpdateResponseSenderModel
  }

  /**
   * Get ConfigUpdateReject model
   * @this {!Client}
   * @returns {!ConfigUpdateRejectModel} ConfigUpdateReject sender model
   */
  get ConfigUpdateRejectSenderModel () {
    return this._ConfigUpdateRejectSenderModel
  }

  /**
   * Get ConfigUpdateNotify model
   * @this {!Client}
   * @returns {!ConfigUpdateNotifyModel} ConfigUpdateNotify sender model
   */
  get ConfigUpdateNotifySenderModel () {
    return this._ConfigUpdateNotifySenderModel
  }

  /**
   * Get ConfigRefreshRequest model
   * @this {!Client}
   * @returns {!ConfigRefreshRequestModel} ConfigRefreshRequest sender model
   */
  get ConfigRefreshRequestSenderModel () {
    return this._ConfigRefreshRequestSenderModel
  }

  /**
   * Get ConfigRefreshResponse model
   * @this {!Client}
   * @returns {!ConfigRefreshResponseModel} ConfigRefreshResponse sender model
   */
  get ConfigRefreshResponseSenderModel () {
    return this._ConfigRefreshResponseSenderModel
  }

  /**
   * Get ConfigHistoryRequest model
   * @this {!Client}
   * @returns {!ConfigHistoryRequestModel} ConfigHistoryRequest sender model
   */
  get ConfigHistoryRequestSenderModel () {
    return this._ConfigHistoryRequestSenderModel
  }

  /**
   * Get ConfigHistoryResponse model
   * @this {!Client}
   * @returns {!ConfigHistoryResponseModel} ConfigHistoryResponse sender model
   */
  get ConfigHistoryResponseSenderModel () {
    return this._ConfigHistoryResponseSenderModel
  }

  /**
   * Get ConfigRollbackRequest model
   * @this {!Client}
   * @returns {!ConfigRollbackRequestModel} ConfigRollbackRequest sender model
   */
  get ConfigRollbackRequestSenderModel () {
    return this._ConfigRollbackRequestSenderModel
  }

  /**
   * Get ConfigRollbackResponse model
   * @this {!Client}
   * @returns {!ConfigRollbackResponseModel} ConfigRollbackResponse sender model
   */
  get ConfigRollbackResponseSenderModel () {
    return this._ConfigRollbackResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ConfigRequest) && (value.fbeType === this.ConfigRequestSenderModel.fbeType)) {
      return this.send_ConfigRequest(value)
    }
    if ((value instanceof ConfigResponse) && (value.fbeType === this.ConfigResponseSenderModel.fbeType)) {
      return this.send_ConfigResponse(value)
    }
    if ((value instanceof ConfigReject) && (value.fbeType === this.ConfigRejectSenderModel.fbeType)) {
      return this.send_ConfigReject(value)
    }
    if ((value instanceof ConfigVersionRequest) && (value.fbeType === this.ConfigVersionRequestSenderModel.fbeType)) {
      return this.send_ConfigVersionRequest(value)
    }
    if ((value instanceof ConfigVersionResponse) && (value.fbeType === this.ConfigVersionResponseSenderModel.fbeType)) {
      return this.send_ConfigVersionResponse(value)
    }
    if ((value instanceof ConfigSnapshotRequest) && (value.fbeType === this.ConfigSnapshotRequestSenderModel.fbeType)) {
      return this.send_ConfigSnapshotRequest(value)
    }
    if ((value instanceof ConfigSnapshotResponse) && (value.fbeType === this.ConfigSnapshotResponseSenderModel.fbeType)) {
      return this.send_ConfigSnapshotResponse(value)
    }
    if ((value instanceof ConfigUpdateRequest) && (value.fbeType === this.ConfigUpdateRequestSenderModel.fbeType)) {
      return this.send_ConfigUpdateRequest(value)
    }
    if ((value instanceof ConfigUpdateResponse) && (value.fbeType === this.ConfigUpdateResponseSenderModel.fbeType)) {
      return this.send_ConfigUpdateResponse(value)
    }
    if ((value instanceof ConfigUpdateReject) && (value.fbeType === this.ConfigUpdateRejectSenderModel.fbeType)) {
      return this.send_ConfigUpdateReject(value)
    }
    if ((value instanceof ConfigUpdateNotify) && (value.fbeType === this.ConfigUpdateNotifySenderModel.fbeType)) {
      return this.send_ConfigUpdateNotify(value)
    }
    if ((value instanceof ConfigRefreshRequest) && (value.fbeType === this.ConfigRefreshRequestSenderModel.fbeType)) {
      return this.send_ConfigRefreshRequest(value)
    }
    if ((value instanceof ConfigRefreshResponse) && (value.fbeType === this.ConfigRefreshResponseSenderModel.fbeType)) {
      return this.send_ConfigRefreshResponse(value)
    }
    if ((value instanceof ConfigHistoryRequest) && (value.fbeType === this.ConfigHistoryRequestSenderModel.fbeType)) {
      return this.send_ConfigHistoryRequest(value)
    }
    if ((value instanceof ConfigHistoryResponse) && (value.fbeType === this.ConfigHistoryResponseSenderModel.fbeType)) {
      return this.send_ConfigHistoryResponse(value)
    }
    if ((value instanceof ConfigRollbackRequest) && (value.fbeType === this.ConfigRollbackRequestSenderModel.fbeType)) {
      return this.send_ConfigRollbackRequest(value)
    }
    if ((value instanceof ConfigRollbackResponse) && (value.fbeType === this.ConfigRollbackResponseSenderModel.fbeType)) {
      return this.send_ConfigRollbackResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ConfigRequest value
   * @this {!Client}
   * @param {!ConfigRequest} value ConfigRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRequest serialization failed!')
    console.assert(this.ConfigRequestSenderModel.verify(), 'config.ConfigRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigResponse value
   * @this {!Client}
   * @param {!ConfigResponse} value ConfigResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigResponse serialization failed!')
    console.assert(this.ConfigResponseSenderModel.verify(), 'config.ConfigResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigReject value
   * @this {!Client}
   * @param {!ConfigReject} value ConfigReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigReject serialization failed!')
    console.assert(this.ConfigRejectSenderModel.verify(), 'config.ConfigReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionRequest value
   * @this {!Client}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigVersionRequest serialization failed!')
    console.assert(this.ConfigVersionRequestSenderModel.verify(), 'config.ConfigVersionRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigVersionResponse value
   * @this {!Client}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigVersionResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigVersionResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigVersionResponse serialization failed!')
    console.assert(this.ConfigVersionResponseSenderModel.verify(), 'config.ConfigVersionResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigSnapshotRequest value
   * @this {!Client}
   * @param {!ConfigSnapshotRequest} value ConfigSnapshotRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigSnapshotRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigSnapshotRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigSnapshotRequest serialization failed!')
    console.assert(this.ConfigSnapshotRequestSenderModel.verify(), 'config.ConfigSnapshotRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigSnapshotResponse value
   * @this {!Client}
   * @param {!ConfigSnapshotResponse} value ConfigSnapshotResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigSnapshotResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigSnapshotResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigSnapshotResponse serialization failed!')
    console.assert(this.ConfigSnapshotResponseSenderModel.verify(), 'config.ConfigSnapshotResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateRequest value
   * @this {!Client}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateRequest serialization failed!')
    console.assert(this.ConfigUpdateRequestSenderModel.verify(), 'config.ConfigUpdateRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateResponse value
   * @this {!Client}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateResponse serialization failed!')
    console.assert(this.ConfigUpdateResponseSenderModel.verify(), 'config.ConfigUpdateResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateReject value
   * @this {!Client}
   * @param {!ConfigUpdateReject} value ConfigUpdateReject value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateReject (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateRejectSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateReject serialization failed!')
    console.assert(this.ConfigUpdateRejectSenderModel.verify(), 'config.ConfigUpdateReject validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigUpdateNotify value
   * @this {!Client}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigUpdateNotify (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigUpdateNotifySenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigUpdateNotify serialization failed!')
    console.assert(this.ConfigUpdateNotifySenderModel.verify(), 'config.ConfigUpdateNotify validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshRequest value
   * @this {!Client}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRefreshRequest serialization failed!')
    console.assert(this.ConfigRefreshRequestSenderModel.verify(), 'config.ConfigRefreshRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRefreshResponse value
   * @this {!Client}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRefreshResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRefreshResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRefreshResponse serialization failed!')
    console.assert(this.ConfigRefreshResponseSenderModel.verify(), 'config.ConfigRefreshResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryRequest value
   * @this {!Client}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigHistoryRequest serialization failed!')
    console.assert(this.ConfigHistoryRequestSenderModel.verify(), 'config.ConfigHistoryRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigHistoryResponse value
   * @this {!Client}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigHistoryResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigHistoryResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigHistoryResponse serialization failed!')
    console.assert(this.ConfigHistoryResponseSenderModel.verify(), 'config.ConfigHistoryResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackRequest value
   * @this {!Client}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRollbackRequest serialization failed!')
    console.assert(this.ConfigRollbackRequestSenderModel.verify(), 'config.ConfigRollbackRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ConfigRollbackResponse value
   * @this {!Client}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ConfigRollbackResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ConfigRollbackResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'config.ConfigRollbackResponse serialization failed!')
    console.assert(this.ConfigRollbackResponseSenderModel.verify(), 'config.ConfigRollbackResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'config.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ConfigRequest receive handler
   * @this {!Client}
   * @param {!ConfigRequest} value ConfigRequest received value
   */
  onReceive_ConfigRequest (value) {}  // eslint-disable-line

  /**
   * ConfigResponse receive handler
   * @this {!Client}
   * @param {!ConfigResponse} value ConfigResponse received value
   */
  onReceive_ConfigResponse (value) {}  // eslint-disable-line

  /**
   * ConfigReject receive handler
   * @this {!Client}
   * @param {!ConfigReject} value ConfigReject received value
   */
  onReceive_ConfigReject (value) {}  // eslint-disable-line

  /**
   * ConfigVersionRequest receive handler
   * @this {!Client}
   * @param {!ConfigVersionRequest} value ConfigVersionRequest received value
   */
  onReceive_ConfigVersionRequest (value) {}  // eslint-disable-line

  /**
   * ConfigVersionResponse receive handler
   * @this {!Client}
   * @param {!ConfigVersionResponse} value ConfigVersionResponse received value
   */
  onReceive_ConfigVersionResponse (value) {}  // eslint-disable-line

  /**
   * ConfigSnapshotRequest receive handler
   * @this {!Client}
   * @param {!ConfigSnapshotRequest} value ConfigSnapshotRequest received value
   */
  onReceive_ConfigSnapshotRequest (value) {}  // eslint-disable-line

  /**
   * ConfigSnapshotResponse receive handler
   * @this {!Client}
   * @param {!ConfigSnapshotResponse} value ConfigSnapshotResponse received value
   */
  onReceive_ConfigSnapshotResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateRequest receive handler
   * @this {!Client}
   * @param {!ConfigUpdateRequest} value ConfigUpdateRequest received value
   */
  onReceive_ConfigUpdateRequest (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateResponse receive handler
   * @this {!Client}
   * @param {!ConfigUpdateResponse} value ConfigUpdateResponse received value
   */
  onReceive_ConfigUpdateResponse (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateReject receive handler
   * @this {!Client}
   * @param {!ConfigUpdateReject} value ConfigUpdateReject received value
   */
  onReceive_ConfigUpdateReject (value) {}  // eslint-disable-line

  /**
   * ConfigUpdateNotify receive handler
   * @this {!Client}
   * @param {!ConfigUpdateNotify} value ConfigUpdateNotify received value
   */
  onReceive_ConfigUpdateNotify (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshRequest receive handler
   * @this {!Client}
   * @param {!ConfigRefreshRequest} value ConfigRefreshRequest received value
   */
  onReceive_ConfigRefreshRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRefreshResponse receive handler
   * @this {!Client}
   * @param {!ConfigRefreshResponse} value ConfigRefreshResponse received value
   */
  onReceive_ConfigRefreshResponse (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryRequest receive handler
   * @this {!Client}
   * @param {!ConfigHistoryRequest} value ConfigHistoryRequest received value
   */
  onReceive_ConfigHistoryRequest (value) {}  // eslint-disable-line

  /**
   * ConfigHistoryResponse receive handler
   * @this {!Client}
   * @param {!ConfigHistoryResponse} value ConfigHistoryResponse received value
   */
  onReceive_ConfigHistoryResponse (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackRequest receive handler
   * @this {!Client}
   * @param {!ConfigRollbackRequest} value ConfigRollbackRequest received value
   */
  onReceive_ConfigRollbackRequest (value) {}  // eslint-disable-line

  /**
   * ConfigRollbackResponse receive handler
   * @this {!Client}
   * @param {!ConfigRollbackResponse} value ConfigRollbackResponse received value
   */
  onReceive_ConfigRollbackResponse (value) {}  // eslint-disable-line

  /**
   * config receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ConfigRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRequestReceiverModel.verify(), 'config.ConfigRequest validation failed!')
        let deserialized = this._ConfigRequestReceiverModel.deserialize(this._ConfigRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRequestReceiverValue)
        this.onReceive_ConfigRequest(this._ConfigRequestReceiverValue)
        return true
      }
      case ConfigResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigResponseReceiverModel.verify(), 'config.ConfigResponse validation failed!')
        let deserialized = this._ConfigResponseReceiverModel.deserialize(this._ConfigResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigResponseReceiverValue)
        this.onReceive_ConfigResponse(this._ConfigResponseReceiverValue)
        return true
      }
      case ConfigRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRejectReceiverModel.verify(), 'config.ConfigReject validation failed!')
        let deserialized = this._ConfigRejectReceiverModel.deserialize(this._ConfigRejectReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRejectReceiverValue)
        this.onReceive_ConfigReject(this._ConfigRejectReceiverValue)
        return true
      }
      case ConfigVersionRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionRequestReceiverModel.verify(), 'config.ConfigVersionRequest validation failed!')
        let deserialized = this._ConfigVersionRequestReceiverModel.deserialize(this._ConfigVersionRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigVersionRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionRequestReceiverValue)
        this.onReceive_ConfigVersionRequest(this._ConfigVersionRequestReceiverValue)
        return true
      }
      case ConfigVersionResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigVersionResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigVersionResponseReceiverModel.verify(), 'config.ConfigVersionResponse validation failed!')
        let deserialized = this._ConfigVersionResponseReceiverModel.deserialize(this._ConfigVersionResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigVersionResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigVersionResponseReceiverValue)
        this.onReceive_ConfigVersionResponse(this._ConfigVersionResponseReceiverValue)
        return true
      }
      case ConfigSnapshotRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigSnapshotRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotRequestReceiverModel.verify(), 'config.ConfigSnapshotRequest validation failed!')
        let deserialized = this._ConfigSnapshotRequestReceiverModel.deserialize(this._ConfigSnapshotRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigSnapshotRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigSnapshotRequestReceiverValue)
        this.onReceive_ConfigSnapshotRequest(this._ConfigSnapshotRequestReceiverValue)
        return true
      }
      case ConfigSnapshotResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigSnapshotResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigSnapshotResponseReceiverModel.verify(), 'config.ConfigSnapshotResponse validation failed!')
        let deserialized = this._ConfigSnapshotResponseReceiverModel.deserialize(this._ConfigSnapshotResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigSnapshotResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigSnapshotResponseReceiverValue)
        this.onReceive_ConfigSnapshotResponse(this._ConfigSnapshotResponseReceiverValue)
        return true
      }
      case ConfigUpdateRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRequestReceiverModel.verify(), 'config.ConfigUpdateRequest validation failed!')
        let deserialized = this._ConfigUpdateRequestReceiverModel.deserialize(this._ConfigUpdateRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRequestReceiverValue)
        this.onReceive_ConfigUpdateRequest(this._ConfigUpdateRequestReceiverValue)
        return true
      }
      case ConfigUpdateResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateResponseReceiverModel.verify(), 'config.ConfigUpdateResponse validation failed!')
        let deserialized = this._ConfigUpdateResponseReceiverModel.deserialize(this._ConfigUpdateResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateResponseReceiverValue)
        this.onReceive_ConfigUpdateResponse(this._ConfigUpdateResponseReceiverValue)
        return true
      }
      case ConfigUpdateRejectModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateRejectReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateRejectReceiverModel.verify(), 'config.ConfigUpdateReject validation failed!')
        let deserialized = this._ConfigUpdateRejectReceiverModel.deserialize(this._ConfigUpdateRejectReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateReject deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateRejectReceiverValue)
        this.onReceive_ConfigUpdateReject(this._ConfigUpdateRejectReceiverValue)
        return true
      }
      case ConfigUpdateNotifyModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigUpdateNotifyReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigUpdateNotifyReceiverModel.verify(), 'config.ConfigUpdateNotify validation failed!')
        let deserialized = this._ConfigUpdateNotifyReceiverModel.deserialize(this._ConfigUpdateNotifyReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigUpdateNotify deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigUpdateNotifyReceiverValue)
        this.onReceive_ConfigUpdateNotify(this._ConfigUpdateNotifyReceiverValue)
        return true
      }
      case ConfigRefreshRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshRequestReceiverModel.verify(), 'config.ConfigRefreshRequest validation failed!')
        let deserialized = this._ConfigRefreshRequestReceiverModel.deserialize(this._ConfigRefreshRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigRefreshRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshRequestReceiverValue)
        this.onReceive_ConfigRefreshRequest(this._ConfigRefreshRequestReceiverValue)
        return true
      }
      case ConfigRefreshResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRefreshResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRefreshResponseReceiverModel.verify(), 'config.ConfigRefreshResponse validation failed!')
        let deserialized = this._ConfigRefreshResponseReceiverModel.deserialize(this._ConfigRefreshResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigRefreshResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRefreshResponseReceiverValue)
        this.onReceive_ConfigRefreshResponse(this._ConfigRefreshResponseReceiverValue)
        return true
      }
      case ConfigHistoryRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryRequestReceiverModel.verify(), 'config.ConfigHistoryRequest validation failed!')
        let deserialized = this._ConfigHistoryRequestReceiverModel.deserialize(this._ConfigHistoryRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigHistoryRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryRequestReceiverValue)
        this.onReceive_ConfigHistoryRequest(this._ConfigHistoryRequestReceiverValue)
        return true
      }
      case ConfigHistoryResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigHistoryResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigHistoryResponseReceiverModel.verify(), 'config.ConfigHistoryResponse validation failed!')
        let deserialized = this._ConfigHistoryResponseReceiverModel.deserialize(this._ConfigHistoryResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigHistoryResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigHistoryResponseReceiverValue)
        this.onReceive_ConfigHistoryResponse(this._ConfigHistoryResponseReceiverValue)
        return true
      }
      case ConfigRollbackRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackRequestReceiverModel.verify(), 'config.ConfigRollbackRequest validation failed!')
        let deserialized = this._ConfigRollbackRequestReceiverModel.deserialize(this._ConfigRollbackRequestReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigRollbackRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackRequestReceiverValue)
        this.onReceive_ConfigRollbackRequest(this._ConfigRollbackRequestReceiverValue)
        return true
      }
      case ConfigRollbackResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ConfigRollbackResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ConfigRollbackResponseReceiverModel.verify(), 'config.ConfigRollbackResponse validation failed!')
        let deserialized = this._ConfigRollbackResponseReceiverModel.deserialize(this._ConfigRollbackResponseReceiverValue)
        console.assert((deserialized.size > 0), 'config.ConfigRollbackResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ConfigRollbackResponseReceiverValue)
        this.onReceive_ConfigRollbackResponse(this._ConfigRollbackResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
