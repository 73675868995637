import { authStore } from '@store/auth';
import { ApplicationInsightsRef, getApplicationInsightsRef } from '@components/ApplicationInsights/service';
import { Reject } from '@api/fbe/core/Reject';
import { RejectCode } from '@api/fbe/core/RejectCode';
import StorageHelper from '@utils/StorageHelper';
import { LS_WS_CONNECTION_KEY } from '@constants';

type PageData = {
    title: string;
};

export function rejectHandledByErrorTracker(reject: Reject, newStatus?: boolean): boolean {
    if (newStatus !== undefined) {
        (reject as any)._ErrorsTracker_handled = newStatus;
    }
    return (reject as any)._ErrorsTracker_handled === true;
}

const checkShouldLogout = (err: Reject) => {
    const reason = err.Reason;
    const code = err.Code.toJSON();
    const cookieToken = StorageHelper.getFromCookie('SessionData');

    if (!cookieToken || [RejectCode.INVALID_SESSION.toJSON(), RejectCode.INVALID_AUTH_TOKEN.toJSON()].includes(code)) {
        authStore.logout({ message: reason, code: code });
    } else {
        if (
            [RejectCode.SECURITY_NOT_AUTHENICATED.toJSON(), RejectCode.SECURITY_NOT_REGISTERED.toJSON()].includes(code)
        ) {
            authStore.setAuth(true);
            authStore.authHandler({
                wsUrl: StorageHelper.getFromLocal(LS_WS_CONNECTION_KEY),
                token: cookieToken.AuthToken,
                login: cookieToken.Login,
            });
        }
    }
};

// used by ErrorsTracker.wrapApi
interface IStoreWithErrorTracker {
    errorTracker: ErrorsTracker;
}

export class ErrorsTracker {
    private page: PageData;
    private appInsightsRef: ApplicationInsightsRef;

    constructor(props: PageData, appInsightsRef: ApplicationInsightsRef = getApplicationInsightsRef()) {
        this.page = { ...props };
        this.appInsightsRef = appInsightsRef;
    }

    apiReject(method: string, reject: Reject) {
        rejectHandledByErrorTracker(reject, true);

        const reason = reject.Reason;
        const code = reject.Code.toJSON();

        /*
        TODO Define api reject behaviour
        if (code === RejectCode.SECURITY_NOT_ALLOWED.toJSON()) {
            Message(MessageType.info, 'Rights updated');
            authStore.getUserRights(authStore.login);
        }
        */

        console.error('ErrorsTracker.apiReject: ' + reason, { method, reason, code, reject });
        if (this.appInsightsRef.appInsights)
            this.appInsightsRef.appInsights.trackException({
                exception: { name: `API error (${this.page.title})`, message: method + ' request failed: ' + reason },
            });
    }

    apiRequestError(method: string, message: { toString(): string }) {
        console.error('ErrorsTracker.apiRequestError', { method, message });
        if (this.appInsightsRef.appInsights)
            this.appInsightsRef.appInsights.trackException({
                exception: {
                    name: `API ${method} request exception (${this.page.title})`,
                    message: message.toString(),
                },
            });
    }

    /** handle api errors */
    static wrapApi() {
        return function (
            classCtor: IStoreWithErrorTracker,
            propName: string,
            desc: TypedPropertyDescriptor<any>,
        ): TypedPropertyDescriptor<any> {
            const originalValue = desc.value as Function;

            desc.value = async function (this: any, ...args: any) {
                try {
                    return await originalValue.apply(this, args);
                } catch (err) {
                    if (err instanceof Reject && rejectHandledByErrorTracker(err)) {
                        checkShouldLogout(err);
                    } else {
                        this.errorTracker.apiRequestError(propName, err);
                    }

                    // propagate error to app
                    throw err;
                }
            };

            return desc;
        };
    }
}
