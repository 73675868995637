
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * ChartBarPeriodType enum
 */
class ChartBarPeriodType {
  /**
   * Initialize enum with a given value
   * @param {ChartBarPeriodType|number|Int64|UInt64=} value Enum value, defaults is 0
   * @constructor
   */
  constructor (value = 0) {
    if (value instanceof ChartBarPeriodType) {
      this.value = value.value
    } else {
      this.value = value
    }
  }

  /**
   * Is this enum equal to other one?
   * @this {!ChartBarPeriodType}
   * @param {!ChartBarPeriodType} other Other enum
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ChartBarPeriodType)) {
      throw new TypeError('Instance of ChartBarPeriodType is required!')
    }
    return this.value === other.value
  }

  /**
   * Get enum value
   * @this {!ChartBarPeriodType}
   * @returns {!number|!Int64|!UInt64} Enum value
   */
  valueOf () {
    return this.value
  }

  /**
   * Convert enum to string
   * @this {!ChartBarPeriodType}
   * @returns {!string} Enum value string
   */
  toString () {
    if (this.value === ChartBarPeriodType.M1.value) {
      return 'M1'
    }
    if (this.value === ChartBarPeriodType.M5.value) {
      return 'M5'
    }
    if (this.value === ChartBarPeriodType.M15.value) {
      return 'M15'
    }
    if (this.value === ChartBarPeriodType.M30.value) {
      return 'M30'
    }
    if (this.value === ChartBarPeriodType.H1.value) {
      return 'H1'
    }
    if (this.value === ChartBarPeriodType.H4.value) {
      return 'H4'
    }
    if (this.value === ChartBarPeriodType.D1.value) {
      return 'D1'
    }
    if (this.value === ChartBarPeriodType.W1.value) {
      return 'W1'
    }
    if (this.value === ChartBarPeriodType.MN.value) {
      return 'MN'
    }
    return '<unknown>'
  }

  /**
   * Inspect enum
   * @this {!ChartBarPeriodType}
   * @returns {!string} Enum value string
   */
  [util.inspect.custom] () {
    return this.toString()
  }

  /**
   * Convert enum to JSON
   * @this {!ChartBarPeriodType}
   * @returns {!number} Enum value for JSON
   */
  toJSON () {
    return this.value
  }

  /**
   * Create enum from object value
   * @param {!number} other Object value
   * @returns {!ChartBarPeriodType} Created enum
   */
  static fromObject (other) {
    return new ChartBarPeriodType(other)
  }
}

// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.M1 = new ChartBarPeriodType(0 + 0)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.M5 = new ChartBarPeriodType(0 + 1)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.M15 = new ChartBarPeriodType(0 + 2)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.M30 = new ChartBarPeriodType(0 + 3)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.H1 = new ChartBarPeriodType(0 + 4)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.H4 = new ChartBarPeriodType(0 + 5)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.D1 = new ChartBarPeriodType(0 + 6)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.W1 = new ChartBarPeriodType(0 + 7)
// noinspection PointlessArithmeticExpressionJS
ChartBarPeriodType.MN = new ChartBarPeriodType(0 + 8)

export { ChartBarPeriodType };

/**
 * Fast Binary Encoding ChartBarPeriodType field model
 */
class FieldModelChartBarPeriodType extends fbe.FieldModel {
  /**
   * Get the field size
   * @this {!FieldModelChartBarPeriodType}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 1
  }

  /**
   * Get the value
   * @this {!FieldModelChartBarPeriodType}
   * @param {ChartBarPeriodType=} defaults Default value, defaults is new ChartBarPeriodType()
   * @returns {!ChartBarPeriodType} Result value
   */
  get (defaults = new ChartBarPeriodType()) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return defaults
    }

    return new ChartBarPeriodType(this.readByte(this.fbeOffset))
  }

  /**
   * Set the value
   * @this {!FieldModelChartBarPeriodType}
   * @param {!ChartBarPeriodType} value Value
   */
  set (value) {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return
    }

    this.writeByte(this.fbeOffset, value.value)
  }
}

export { FieldModelChartBarPeriodType };
