import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, CheckboxOptionType, Dropdown, Menu, Popover, Space, Tooltip } from 'antd';
import { ColumnHeightOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import { keyMap, t } from '@localization';
import './style.scss';
import type { SizeType } from '@models';
import { LS_DISABLED_COLS_PREFIX, typedMemo } from '@utils';
import { CustomColumnType, TableColsSettings } from '../types';
import { stringifyDataIndex } from '../utils';
import { useLocalStorage } from '@utils/hooks';
import cn from 'classnames';
import { AppLayoutContext } from '@App/layouts/AppLayout';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const CheckboxGroup = Checkbox.Group;

type Props<ContextDataT> = {
    onSizeChange(size: SizeType): void;
    defaultSize: SizeType;
    updateData?: () => void;
    toolbar?: JSX.Element[];
    activeColumnsSetter: Dispatch<string[]>;
    reorderColumns: Dispatch<CustomColumnType<ContextDataT>[]>;
    columns: CustomColumnType<ContextDataT>[];
    exportData?: any[];
    title: string;
    colsSettings?: TableColsSettings;
    setLSColsOrder: (data: string | string[]) => {};
};

const renderMenuDropdown: (param: (size: SizeType) => void, defaultSize: SizeType) => JSX.Element = (
    onChange: (size: SizeType) => void,
    defaultSize: SizeType,
) => (
    <Menu
        selectable
        defaultSelectedKeys={[defaultSize ?? 'small']}
        onClick={(e) => onChange(e.key as SizeType)}
        items={[
            { label: t(keyMap.dropdown.large), key: 'large' },
            { label: t(keyMap.dropdown.medium), key: 'middle' },
            { label: t(keyMap.dropdown.small), key: 'small' },
        ]}
    />
);

interface OptionType extends CheckboxOptionType {
    key: string;
}

export const TableHeader = typedMemo(<ContextDataT,>(props: Props<ContextDataT>) => {
    const {
        columns,
        activeColumnsSetter,
        exportData,
        onSizeChange,
        defaultSize,
        title,
        updateData,
        toolbar,
        colsSettings,
        reorderColumns,
        setLSColsOrder,
    } = props;
    const { isSidebarCollapsed } = useContext(AppLayoutContext);
    const options = useMemo<OptionType[]>(
        () =>
            columns
                .map((el) =>
                    el.title
                        ? {
                              label: el.title,
                              value: stringifyDataIndex(el.dataIndex),
                              key: stringifyDataIndex(el.dataIndex),
                          }
                        : null,
                )
                .filter((el) => !!el) as OptionType[],
        [columns],
    );
    const [popVisible, setPopVisible] = useState<boolean>(false);
    const [LSDisabledCols, setLSDisabledCols] = useLocalStorage(
        `${colsSettings?.key || title}${LS_DISABLED_COLS_PREFIX}`,
        colsSettings?.disabledCols.join('.'),
    );
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(
        LSDisabledCols && Array.isArray(LSDisabledCols)
            ? options.filter((el) => !LSDisabledCols.includes(el.key)).map((el) => el.value)
            : options
                  .filter((el) =>
                      colsSettings?.disabledCols.length ? !colsSettings.disabledCols.includes(el.key) : true,
                  )
                  .map((el) => el.value),
    );
    const [indeterminate, setIndeterminate] = useState<boolean>(
        !!checkedList.length && checkedList.length < options.length,
    );
    const [checkAll, setCheckAll] = useState<boolean>(checkedList.length === options.length);

    useEffect(() => {
        // Case when some cols renamed - delete deprecated cols from localstorage
        if (Array.isArray(LSDisabledCols)) {
            const tableColsKeys = options.map((item) => item.key);
            const deprecatedLSCols = LSDisabledCols.filter((item) => !tableColsKeys.includes(item));
            if (deprecatedLSCols.length) {
                setLSDisabledCols(LSDisabledCols.filter((item) => deprecatedLSCols.includes(item)));
            }
        }
    }, []);

    const SortableItem = SortableElement(({ value }) => (
        <tr>
            <td>
                <Checkbox value={value.value}>{value.label}</Checkbox>
            </td>
        </tr>
    ));
    const SortableList = SortableContainer(({ items }) => (
        <table>
            <tbody>
                {items.map((value, index) => (
                    <SortableItem key={value.key} index={index} value={value} />
                ))}
            </tbody>
        </table>
    ));

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? options.map((el) => el.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    };

    const handleSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const reorderedArr = arrayMove(columns, oldIndex, newIndex);
            reorderColumns(reorderedArr);
            setLSColsOrder(reorderedArr.map((col) => stringifyDataIndex(col.dataIndex)));
        }
    };

    useEffect(() => {
        const enabledColKeys = checkedList
            .map((item) => options.find((opt) => opt.value === item)?.key || '')
            .filter((key) => !!key);
        const disabledColKeys = options.filter((el) => !checkedList.includes(el.value)).map((el) => el.key);
        setLSDisabledCols(disabledColKeys);
        activeColumnsSetter(enabledColKeys);
    }, [checkedList, activeColumnsSetter, options, setLSDisabledCols, colsSettings]);

    return (
        <div className='table-header'>
            <Space align='baseline' className='header-space'>
                <h1 className='table-header-h1'>{title}</h1>
                {exportData ? <Button className='table-header-btn_export'>{t(keyMap.button.export)}</Button> : null}
            </Space>
            <Space align='baseline' className='toolBar-wrapper'>
                {toolbar || null}
                <Space>
                    {updateData ? (
                        <Tooltip title={t(keyMap.tooltip.refresh)}>
                            <Button
                                onClick={updateData}
                                icon={<ReloadOutlined />}
                                className={cn(
                                    'btn-no-outline',
                                    'table-header-btn',
                                    isSidebarCollapsed ? 'table-header-btn_collapsed' : 'table-header-btn_open',
                                )}
                            />
                        </Tooltip>
                    ) : null}
                    <Tooltip title={t(keyMap.tooltip.density)}>
                        <Dropdown overlay={renderMenuDropdown(onSizeChange, defaultSize)} trigger={['click']}>
                            <Button
                                icon={<ColumnHeightOutlined />}
                                className={cn(
                                    'btn-no-outline',
                                    'table-header-btn',
                                    isSidebarCollapsed ? 'table-header-btn_collapsed' : 'table-header-btn_open',
                                )}
                            />
                        </Dropdown>
                    </Tooltip>
                    <Popover
                        content={
                            <CheckboxGroup
                                className='table-header-checkbox-group'
                                value={checkedList}
                                onChange={onChange}
                            >
                                <SortableList
                                    items={options}
                                    axis='y'
                                    lockAxis='y'
                                    lockToContainerEdges
                                    helperClass='table-header-sortable'
                                    onSortEnd={handleSortEnd}
                                />
                            </CheckboxGroup>
                        }
                        title={
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                {t(keyMap.tooltip.checkAll)}
                            </Checkbox>
                        }
                        trigger='click'
                        visible={popVisible}
                        onVisibleChange={(visible) => setPopVisible(visible)}
                        placement='bottomLeft'
                    >
                        <Tooltip title={t(keyMap.tooltip.settings)}>
                            <Button
                                onClick={() => setPopVisible(!popVisible)}
                                icon={<SettingOutlined />}
                                className={cn(
                                    'btn-no-outline',
                                    'table-header-btn',
                                    isSidebarCollapsed ? 'table-header-btn_collapsed' : 'table-header-btn_open',
                                )}
                            />
                        </Tooltip>
                    </Popover>
                </Space>
            </Space>
        </div>
    );
});
