
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {DaysOfWeek} from '../core/DaysOfWeek';
import {FieldModelDaysOfWeek} from '../core/DaysOfWeek';
import {TimezoneInfo} from './TimezoneInfo';
import {FieldModelTimezoneInfo} from './TimezoneInfo';

/**
 * CalendarRegularRule struct
 */
class CalendarRegularRule {
  /**
   * Initialize struct
   * @param {!string=} name
   * @param {!DaysOfWeek=} days
   * @param {!Array=} symbolNames
   * @param {!Array=} symbolGroupNames
   * @param {!boolean=} isDefault
   * @param {!number=} marketOpen
   * @param {!number=} marketClose
   * @param {!number=} tradeOpen
   * @param {!number=} tradeClose
   * @param {!string=} comment
   * @param {!Array=} offTimeRulesIds
   * @param {!TimezoneInfo=} timezoneInfo
   * @constructor
   */
  constructor (name = '', days = new DaysOfWeek(), symbolNames = [], symbolGroupNames = [], isDefault = false, marketOpen = new UInt64(0, 0), marketClose = new UInt64(0, 0), tradeOpen = new UInt64(0, 0), tradeClose = new UInt64(0, 0), comment = '', offTimeRulesIds = [], timezoneInfo = new TimezoneInfo()) {
    this.Name = name
    this.Days = days
    this.SymbolNames = symbolNames
    this.SymbolGroupNames = symbolGroupNames
    this.IsDefault = isDefault
    this.MarketOpen = marketOpen
    this.MarketClose = marketClose
    this.TradeOpen = tradeOpen
    this.TradeClose = tradeClose
    this.Comment = comment
    this.OffTimeRulesIds = offTimeRulesIds
    this.TimezoneInfo = timezoneInfo
  }

  /**
   * Copy struct (shallow copy)
   * @this {!CalendarRegularRule}
   * @param {!CalendarRegularRule} other Other struct
   * @returns {!CalendarRegularRule} This struct
   */
  copy (other) {
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Days != null) {
      this.Days = DaysOfWeek.fromObject(other.Days)
    } else {
      this.Days = null
    }
    if (other.SymbolNames != null) {
      this.SymbolNames = []
      for (let item of other.SymbolNames) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.SymbolNames.push(tempItem)
        } else {
          this.SymbolNames.push(null)
        }
      }
    } else {
      this.SymbolNames = null
    }
    if (other.SymbolGroupNames != null) {
      this.SymbolGroupNames = []
      for (let item of other.SymbolGroupNames) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.SymbolGroupNames.push(tempItem)
        } else {
          this.SymbolGroupNames.push(null)
        }
      }
    } else {
      this.SymbolGroupNames = null
    }
    if (other.IsDefault != null) {
      this.IsDefault = other.IsDefault
    } else {
      this.IsDefault = null
    }
    if (other.MarketOpen != null) {
      this.MarketOpen = UInt64.fromNumber(other.MarketOpen)
    } else {
      this.MarketOpen = null
    }
    if (other.MarketClose != null) {
      this.MarketClose = UInt64.fromNumber(other.MarketClose)
    } else {
      this.MarketClose = null
    }
    if (other.TradeOpen != null) {
      this.TradeOpen = UInt64.fromNumber(other.TradeOpen)
    } else {
      this.TradeOpen = null
    }
    if (other.TradeClose != null) {
      this.TradeClose = UInt64.fromNumber(other.TradeClose)
    } else {
      this.TradeClose = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.OffTimeRulesIds != null) {
      this.OffTimeRulesIds = []
      for (let item of other.OffTimeRulesIds) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.OffTimeRulesIds.push(tempItem)
        } else {
          this.OffTimeRulesIds.push(null)
        }
      }
    } else {
      this.OffTimeRulesIds = null
    }
    if (other.TimezoneInfo != null) {
      this.TimezoneInfo = TimezoneInfo.fromObject(other.TimezoneInfo)
    } else {
      this.TimezoneInfo = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!CalendarRegularRule}
   * @returns {!CalendarRegularRule} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new CalendarRegularRuleModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new CalendarRegularRuleModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!CalendarRegularRule}
   * @param {!CalendarRegularRule} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof CalendarRegularRule)) {
      throw new TypeError('Instance of CalendarRegularRule is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!CalendarRegularRule}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Name: ((this.Name != null) ? this.Name : null),
      Days: ((this.Days != null) ? this.Days : null),
      SymbolNames: ((this.SymbolNames != null) ? Array.from(this.SymbolNames, item => ((item != null) ? item : null)) : null),
      SymbolGroupNames: ((this.SymbolGroupNames != null) ? Array.from(this.SymbolGroupNames, item => ((item != null) ? item : null)) : null),
      IsDefault: ((this.IsDefault != null) ? this.IsDefault : null),
      MarketOpen: ((this.MarketOpen != null) ? this.MarketOpen.toNumber() : null),
      MarketClose: ((this.MarketClose != null) ? this.MarketClose.toNumber() : null),
      TradeOpen: ((this.TradeOpen != null) ? this.TradeOpen.toNumber() : null),
      TradeClose: ((this.TradeClose != null) ? this.TradeClose.toNumber() : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      OffTimeRulesIds: ((this.OffTimeRulesIds != null) ? Array.from(this.OffTimeRulesIds, item => ((item != null) ? item.toString() : null)) : null),
      TimezoneInfo: ((this.TimezoneInfo != null) ? this.TimezoneInfo : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return CalendarRegularRule.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!CalendarRegularRule} other Object value
   * @returns {!CalendarRegularRule} Created struct
   */
  static fromObject (other) {
    return new CalendarRegularRule().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!CalendarRegularRule}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return CalendarRegularRule.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!CalendarRegularRule}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5124
  }
}

export { CalendarRegularRule };

/**
 * Fast Binary Encoding CalendarRegularRule field model
 */
class FieldModelCalendarRegularRule extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Name = new fbe.FieldModelString(buffer, 4 + 4)
    this.Days = new FieldModelDaysOfWeek(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.SymbolNames = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.Days.fbeOffset + this.Days.fbeSize), buffer, this.Days.fbeOffset + this.Days.fbeSize)
    this.SymbolGroupNames = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.SymbolNames.fbeOffset + this.SymbolNames.fbeSize), buffer, this.SymbolNames.fbeOffset + this.SymbolNames.fbeSize)
    this.IsDefault = new fbe.FieldModelBool(buffer, this.SymbolGroupNames.fbeOffset + this.SymbolGroupNames.fbeSize)
    this.MarketOpen = new fbe.FieldModelUInt64(buffer, this.IsDefault.fbeOffset + this.IsDefault.fbeSize)
    this.MarketClose = new fbe.FieldModelUInt64(buffer, this.MarketOpen.fbeOffset + this.MarketOpen.fbeSize)
    this.TradeOpen = new fbe.FieldModelUInt64(buffer, this.MarketClose.fbeOffset + this.MarketClose.fbeSize)
    this.TradeClose = new fbe.FieldModelUInt64(buffer, this.TradeOpen.fbeOffset + this.TradeOpen.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.TradeClose.fbeOffset + this.TradeClose.fbeSize)
    this.OffTimeRulesIds = new fbe.FieldModelVector(new fbe.FieldModelUUID(buffer, this.Comment.fbeOffset + this.Comment.fbeSize), buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.TimezoneInfo = new FieldModelTimezoneInfo(buffer, this.OffTimeRulesIds.fbeOffset + this.OffTimeRulesIds.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Name.fbeSize + this.Days.fbeSize + this.SymbolNames.fbeSize + this.SymbolGroupNames.fbeSize + this.IsDefault.fbeSize + this.MarketOpen.fbeSize + this.MarketClose.fbeSize + this.TradeOpen.fbeSize + this.TradeClose.fbeSize + this.Comment.fbeSize + this.OffTimeRulesIds.fbeSize + this.TimezoneInfo.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Name.fbeExtra + this.Days.fbeExtra + this.SymbolNames.fbeExtra + this.SymbolGroupNames.fbeExtra + this.IsDefault.fbeExtra + this.MarketOpen.fbeExtra + this.MarketClose.fbeExtra + this.TradeOpen.fbeExtra + this.TradeClose.fbeExtra + this.Comment.fbeExtra + this.OffTimeRulesIds.fbeExtra + this.TimezoneInfo.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelCalendarRegularRule.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5124
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelCalendarRegularRule}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelCalendarRegularRule}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Days.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Days.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Days.fbeSize

    if ((fbeCurrentSize + this.SymbolNames.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolNames.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolNames.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupNames.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolGroupNames.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupNames.fbeSize

    if ((fbeCurrentSize + this.IsDefault.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsDefault.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDefault.fbeSize

    if ((fbeCurrentSize + this.MarketOpen.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarketOpen.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOpen.fbeSize

    if ((fbeCurrentSize + this.MarketClose.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarketClose.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketClose.fbeSize

    if ((fbeCurrentSize + this.TradeOpen.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeOpen.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeOpen.fbeSize

    if ((fbeCurrentSize + this.TradeClose.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeClose.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeClose.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.OffTimeRulesIds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OffTimeRulesIds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OffTimeRulesIds.fbeSize

    if ((fbeCurrentSize + this.TimezoneInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimezoneInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneInfo.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelCalendarRegularRule}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelCalendarRegularRule}
   * @param {!CalendarRegularRule} fbeValue Default value, defaults is new CalendarRegularRule()
   * @returns {!CalendarRegularRule} CalendarRegularRule value
   */
  get (fbeValue = new CalendarRegularRule()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelCalendarRegularRule}
   * @param {!CalendarRegularRule} fbeValue CalendarRegularRule value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Days.fbeSize) <= fbeStructSize) {
      fbeValue.Days = this.Days.get()
    } else {
      fbeValue.Days = new DaysOfWeek()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Days.fbeSize

    if ((fbeCurrentSize + this.SymbolNames.fbeSize) <= fbeStructSize) {
      this.SymbolNames.get(fbeValue.SymbolNames)
    } else {
      fbeValue.SymbolNames.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolNames.fbeSize

    if ((fbeCurrentSize + this.SymbolGroupNames.fbeSize) <= fbeStructSize) {
      this.SymbolGroupNames.get(fbeValue.SymbolGroupNames)
    } else {
      fbeValue.SymbolGroupNames.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolGroupNames.fbeSize

    if ((fbeCurrentSize + this.IsDefault.fbeSize) <= fbeStructSize) {
      fbeValue.IsDefault = this.IsDefault.get()
    } else {
      fbeValue.IsDefault = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDefault.fbeSize

    if ((fbeCurrentSize + this.MarketOpen.fbeSize) <= fbeStructSize) {
      fbeValue.MarketOpen = this.MarketOpen.get()
    } else {
      fbeValue.MarketOpen = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketOpen.fbeSize

    if ((fbeCurrentSize + this.MarketClose.fbeSize) <= fbeStructSize) {
      fbeValue.MarketClose = this.MarketClose.get()
    } else {
      fbeValue.MarketClose = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarketClose.fbeSize

    if ((fbeCurrentSize + this.TradeOpen.fbeSize) <= fbeStructSize) {
      fbeValue.TradeOpen = this.TradeOpen.get()
    } else {
      fbeValue.TradeOpen = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeOpen.fbeSize

    if ((fbeCurrentSize + this.TradeClose.fbeSize) <= fbeStructSize) {
      fbeValue.TradeClose = this.TradeClose.get()
    } else {
      fbeValue.TradeClose = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeClose.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.OffTimeRulesIds.fbeSize) <= fbeStructSize) {
      this.OffTimeRulesIds.get(fbeValue.OffTimeRulesIds)
    } else {
      fbeValue.OffTimeRulesIds.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OffTimeRulesIds.fbeSize

    if ((fbeCurrentSize + this.TimezoneInfo.fbeSize) <= fbeStructSize) {
      fbeValue.TimezoneInfo = this.TimezoneInfo.get()
    } else {
      fbeValue.TimezoneInfo = new TimezoneInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneInfo.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelCalendarRegularRule}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelCalendarRegularRule}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelCalendarRegularRule}
   * @param {!CalendarRegularRule} fbeValue CalendarRegularRule value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelCalendarRegularRule}
   * @param {CalendarRegularRule} fbeValue CalendarRegularRule value
   */
  setFields (fbeValue) {
    this.Name.set(fbeValue.Name)
    this.Days.set(fbeValue.Days)
    this.SymbolNames.set(fbeValue.SymbolNames)
    this.SymbolGroupNames.set(fbeValue.SymbolGroupNames)
    this.IsDefault.set(fbeValue.IsDefault)
    this.MarketOpen.set(fbeValue.MarketOpen)
    this.MarketClose.set(fbeValue.MarketClose)
    this.TradeOpen.set(fbeValue.TradeOpen)
    this.TradeClose.set(fbeValue.TradeClose)
    this.Comment.set(fbeValue.Comment)
    this.OffTimeRulesIds.set(fbeValue.OffTimeRulesIds)
    this.TimezoneInfo.set(fbeValue.TimezoneInfo)
  }
}

export { FieldModelCalendarRegularRule };

/**
 * Fast Binary Encoding CalendarRegularRule model
 */
class CalendarRegularRuleModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelCalendarRegularRule(this.buffer, 4)
  }

  /**
   * Get the CalendarRegularRule model
   * @this {!CalendarRegularRuleModel}
   * @returns {!FieldModelCalendarRegularRule} model CalendarRegularRule model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!CalendarRegularRuleModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!CalendarRegularRuleModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return CalendarRegularRuleModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!CalendarRegularRuleModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelCalendarRegularRule.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!CalendarRegularRuleModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!CalendarRegularRuleModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!CalendarRegularRuleModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!CalendarRegularRuleModel}
   * @param {!CalendarRegularRule} value CalendarRegularRule value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!CalendarRegularRuleModel}
   * @param {!CalendarRegularRule} value CalendarRegularRule value, defaults is new CalendarRegularRule()
   * @return {!object} Deserialized CalendarRegularRule value and its size
   */
  deserialize (value = new CalendarRegularRule()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new CalendarRegularRule(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new CalendarRegularRule(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!CalendarRegularRuleModel}
   * @param {!number} prev Previous CalendarRegularRule model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { CalendarRegularRuleModel };
