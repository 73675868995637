
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FiltrationOfNullable} from './FiltrationOfNullable';
import {FieldModelFiltrationOfNullable} from './FiltrationOfNullable';

/**
 * FilterInt32 struct
 */
class FilterInt32 {
  /**
   * Initialize struct
   * @param {number=} from
   * @param {number=} to
   * @param {!Set=} exactValues
   * @param {FiltrationOfNullable=} filtrationOfNullable
   * @constructor
   */
  constructor (from = null, to = null, exactValues = new Set(), filtrationOfNullable = null) {
    this.From = from
    this.To = to
    this.ExactValues = exactValues
    this.FiltrationOfNullable = filtrationOfNullable
  }

  /**
   * Copy struct (shallow copy)
   * @this {!FilterInt32}
   * @param {!FilterInt32} other Other struct
   * @returns {!FilterInt32} This struct
   */
  copy (other) {
    if (other.From != null) {
      this.From = other.From
    } else {
      this.From = null
    }
    if (other.To != null) {
      this.To = other.To
    } else {
      this.To = null
    }
    if (other.ExactValues != null) {
      this.ExactValues = new Set()
      for (let item of other.ExactValues) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.ExactValues.add(tempItem)
        } else {
          this.ExactValues.add(null)
        }
      }
    } else {
      this.ExactValues = null
    }
    if (other.FiltrationOfNullable != null) {
      this.FiltrationOfNullable = FiltrationOfNullable.fromObject(other.FiltrationOfNullable)
    } else {
      this.FiltrationOfNullable = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!FilterInt32}
   * @returns {!FilterInt32} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new FilterInt32Model(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new FilterInt32Model(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!FilterInt32}
   * @param {!FilterInt32} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof FilterInt32)) {
      throw new TypeError('Instance of FilterInt32 is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!FilterInt32}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      From: ((this.From != null) ? this.From : null),
      To: ((this.To != null) ? this.To : null),
      ExactValues: ((this.ExactValues != null) ? Array.from(this.ExactValues, item => ((item != null) ? item : null)) : null),
      FiltrationOfNullable: ((this.FiltrationOfNullable != null) ? this.FiltrationOfNullable : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return FilterInt32.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!FilterInt32} other Object value
   * @returns {!FilterInt32} Created struct
   */
  static fromObject (other) {
    return new FilterInt32().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!FilterInt32}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return FilterInt32.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!FilterInt32}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 105
  }
}

export { FilterInt32 };

/**
 * Fast Binary Encoding FilterInt32 field model
 */
class FieldModelFilterInt32 extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.From = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, 4 + 4), buffer, 4 + 4)
    this.To = new fbe.FieldModelOptional(new fbe.FieldModelInt32(buffer, this.From.fbeOffset + this.From.fbeSize), buffer, this.From.fbeOffset + this.From.fbeSize)
    this.ExactValues = new fbe.FieldModelSet(new fbe.FieldModelInt32(buffer, this.To.fbeOffset + this.To.fbeSize), buffer, this.To.fbeOffset + this.To.fbeSize)
    this.FiltrationOfNullable = new fbe.FieldModelOptional(new FieldModelFiltrationOfNullable(buffer, this.ExactValues.fbeOffset + this.ExactValues.fbeSize), buffer, this.ExactValues.fbeOffset + this.ExactValues.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.From.fbeSize + this.To.fbeSize + this.ExactValues.fbeSize + this.FiltrationOfNullable.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.From.fbeExtra + this.To.fbeExtra + this.ExactValues.fbeExtra + this.FiltrationOfNullable.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelFilterInt32.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 105
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelFilterInt32}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelFilterInt32}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.From.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.From.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.From.fbeSize

    if ((fbeCurrentSize + this.To.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.To.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.To.fbeSize

    if ((fbeCurrentSize + this.ExactValues.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExactValues.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExactValues.fbeSize

    if ((fbeCurrentSize + this.FiltrationOfNullable.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FiltrationOfNullable.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FiltrationOfNullable.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelFilterInt32}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelFilterInt32}
   * @param {!FilterInt32} fbeValue Default value, defaults is new FilterInt32()
   * @returns {!FilterInt32} FilterInt32 value
   */
  get (fbeValue = new FilterInt32()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelFilterInt32}
   * @param {!FilterInt32} fbeValue FilterInt32 value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.From.fbeSize) <= fbeStructSize) {
      fbeValue.From = this.From.get()
    } else {
      fbeValue.From = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.From.fbeSize

    if ((fbeCurrentSize + this.To.fbeSize) <= fbeStructSize) {
      fbeValue.To = this.To.get()
    } else {
      fbeValue.To = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.To.fbeSize

    if ((fbeCurrentSize + this.ExactValues.fbeSize) <= fbeStructSize) {
      this.ExactValues.get(fbeValue.ExactValues)
    } else {
      fbeValue.ExactValues.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExactValues.fbeSize

    if ((fbeCurrentSize + this.FiltrationOfNullable.fbeSize) <= fbeStructSize) {
      fbeValue.FiltrationOfNullable = this.FiltrationOfNullable.get()
    } else {
      fbeValue.FiltrationOfNullable = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FiltrationOfNullable.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelFilterInt32}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelFilterInt32}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelFilterInt32}
   * @param {!FilterInt32} fbeValue FilterInt32 value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelFilterInt32}
   * @param {FilterInt32} fbeValue FilterInt32 value
   */
  setFields (fbeValue) {
    this.From.set(fbeValue.From)
    this.To.set(fbeValue.To)
    this.ExactValues.set(fbeValue.ExactValues)
    this.FiltrationOfNullable.set(fbeValue.FiltrationOfNullable)
  }
}

export { FieldModelFilterInt32 };

/**
 * Fast Binary Encoding FilterInt32 model
 */
class FilterInt32Model extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelFilterInt32(this.buffer, 4)
  }

  /**
   * Get the FilterInt32 model
   * @this {!FilterInt32Model}
   * @returns {!FieldModelFilterInt32} model FilterInt32 model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!FilterInt32Model}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!FilterInt32Model}
   * @returns {!number} Model type
   */
  get fbeType () {
    return FilterInt32Model.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!FilterInt32Model}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelFilterInt32.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!FilterInt32Model}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!FilterInt32Model}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!FilterInt32Model}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!FilterInt32Model}
   * @param {!FilterInt32} value FilterInt32 value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!FilterInt32Model}
   * @param {!FilterInt32} value FilterInt32 value, defaults is new FilterInt32()
   * @return {!object} Deserialized FilterInt32 value and its size
   */
  deserialize (value = new FilterInt32()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new FilterInt32(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new FilterInt32(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!FilterInt32Model}
   * @param {!number} prev Previous FilterInt32 model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { FilterInt32Model };
